import React from 'react'
import {
    Grid, Typography,
} from '@material-ui/core'
import { makeStyles } from '@material-ui/styles'

const useStyles = makeStyles(theme => ({
    guide: {
        fontSize: '18px',
        textAlign: 'center',
    },
}));

const StepSix = props => {

    const classes = useStyles();

    return (
        <>
            <Grid container spacing={1} style={{ padding: '24px', width: 'calc(90%)', margin: '0 auto' }}>
                <Grid item xs={12}>
                    <Typography className={classes.guide}>
                        <strong>Step 6: </strong>
                        Within the Web Connector.
                    </Typography>
                    <Typography className={classes.guide}>Select "Add Application" in the lower right.</Typography>
                    <Typography className={classes.guide}>Browse to and select the file downloaded in Step 3.</Typography>
                </Grid>
                <Grid item xs={12} style={{ textAlign: 'center' }}>
                    <img src='/images/step6.png' alt='image' width='100%' />
                </Grid>
            </Grid>
        </>
    )
}

export default StepSix;