// const dev = 'https://dev-squadware.centralus.cloudapp.azure.com/';
// const stage = 'https://dev-squadware.centralus.cloudapp.azure.com/';
// const prod = 'https://squadware.centralus.cloudapp.azure.com/';


// const baseUIUrl = () => {
//     switch (process.env.NODE_ENV) {
//         case 'prod': {
//             return prod;
//         }
//         case 'stage': {
//             return stage;
//         }
//         case 'dev': {
//             return dev;
//         }
//         default: {
//             return stage;
//         }
//     }
// }


const constants = {
    // url: {
    //     SIGN_IN: baseUIUrl() + 'sign-in'
    // },
    msg:{
        GENERAL_ERROR:'An error occurred, please try again later',
        SAVED_SUCCESS:'Saved successfully.'
    }

}





export default constants;
