import { TableCell } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import clsx from 'clsx';
import moment from 'moment';
import React, { useMemo } from 'react';
import { useDrop } from 'react-dnd';
import { useSelector } from 'react-redux';
import RoutesInDate from './RoutesInDate';
import { getHoliday } from 'common/holidays';

const useStyles = makeStyles(theme => ({
  box: {
    verticalAlign: 'top',
    cursor: 'pointer',
    '&:hover': {
      backgroundColor: '#e8e6e6',
    },
    padding: 4
  },
  highlight: {
    backgroundColor: '#e8e6e6'
  },
  boxDate: {
    width: '100%',
    paddingTop: 25,
    position: 'relative',
    minHeight: '100px',
  },
  day: {
    position: 'absolute',
    top: '0'
  },
  content: {
    position: 'relative',
    display: 'flex',
    flexDirection: 'column',
  },
}));

const DropBox = props => {
  const { day, isToday, routes, contact, onClick, handleDropItem } = props;
  const classes = useStyles();

  const { viewType, distances } = useSelector(state => state.SchedulesReducer);

  const [{ isOver }, drop] = useDrop({
    accept: 'route',
    drop: item => handleDropItem(item, day),
    collect: monitor => ({
      isOver: monitor.isOver()
    })
  });

  const getStatus = route => {
    let status = [];
    if (route) {
      if (!route.routeFull) {
        status.push('empty');
      }
      if (route.routeFull) {
        status.push('full');
      }
      if (route.routeCompletedDate) {
        status.push('complete');
      }
      if (route.recurringRouteParentId) {
        status.push('recurring');
      }
    }
    return status;
  }

  const getColor = value => {
    let color = null;
    if (value == null)
      return color;
    if (contact) {
      color = 'red';
      if (contact.YellowMiles && contact.YellowMiles >= 0) {
        if (contact.RedMiles) {
          if (value < contact.RedMiles && value >= contact.YellowMiles) color = 'yellow';
          if(value < contact.YellowMiles) color = 'green';
        } else {
          if (value >= contact.YellowMiles) color = 'yellow'; else color = 'green'
        }
      } else {
        if (contact.RedMiles) {
          if (value >= contact.RedMiles) color = 'red'; else  color = 'green';
        } else {
          color = 'green';
        }
      }
    }
    return color;
  }

  const holiday = useMemo(
    () => getHoliday(day),
    [day]
  );

  return (
    <TableCell
      ref={drop}
      className={clsx(classes.box, isToday || holiday || isOver ? classes.highlight : null)}
      onClick={onClick}>
      <div className={classes.boxDate}>
        <div className={classes.day} style={day.getDate() === 1 ? {
            fontSize: '16px',
            fontWeight: 'bold'
        } : null}>
          {day.getDate() === 1 ? moment(day).format('MMM D') : day.getDate()}
        </div>
        {holiday && <div><b>{holiday}</b></div>}
        <div className={classes.content}>
          {routes && routes.map((r, i) => {
            let checkJobComplete = false;
            const cnt = r.routeJobs.filter(x=> x.job != null).length;
            if(cnt > 0){
              checkJobComplete = r.routeJobs.filter(x=> x.job != null && x.job.completedDate != null).length === cnt;
            }
            const distance = checkJobComplete ? null : distances?.find(x => x.routeId === r.id)?.distance ?? null;

            return (
              <RoutesInDate
                key={`routeInDate-${i}`}
                routeId={r.id}
                jobsData={r.routeJobs}
                isCommercial={r.isCommercial}
                viewType={viewType}
                status={getStatus(r)}
                distance={distance}
                color={checkJobComplete ? "gray" : getColor(distance)}
                contact={contact}
              />
            )
          })}
        </div>
      </div>
    </TableCell>
  );
};

export default DropBox;
