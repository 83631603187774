import React from 'react'
import { withStyles } from '@material-ui/styles'
import { colors, Checkbox } from '@material-ui/core'

const GreenCheckbox = withStyles({
    root: {
        color: colors.green[600]
    },
    checked: {},
})(props => <Checkbox color="default" {...props} />);

export default GreenCheckbox;