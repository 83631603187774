import React, { useEffect, useState } from 'react'
import {
    CardContent, CircularProgress
} from '@material-ui/core'
import { useParams } from 'react-router-dom'
import axios from "../../../utils/axios";
import apiConfig from 'apiConfig'
import JobMultipleInvoicePdf from 'views/CustomerDetails/components/JobAddresses/components/JobMultipleInvoicePdf';
import localStorage from 'utils/localStorage'
import { useTheme } from "@material-ui/core/styles";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import { pdf } from '@react-pdf/renderer';
import * as PDFMerger from 'pdf-merger-js';

const MultipleInvoice = props => {
    const [loadingData, setLoadingData] = useState(true);
    const { id } = useParams()
    const [draftPdf] = useState("draft");
    const fullInfo = localStorage.getFullInfo();
    const [invoice, setInvoice] = useState();
    const [mergedPdfUrl, setMergedPdfUrl] = useState();
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('xs'));

    useEffect(() => {
        const attachmentsStr = localStorage.getItem('attachments');
        localStorage.removeItem('attachments');
        const attachments = (attachmentsStr && JSON.parse(attachmentsStr)) || [];
        if(id === draftPdf) {
            axios.get(apiConfig.url.BASE_URL + apiConfig.url.COMPANY_CONTRACT_FORM.replace('{id}', fullInfo.cid))
                .then(res => {
                    setInvoice(res.data.invoiceForm ? res.data.invoiceForm.replaceAll('\n', '\n ') : '');
                })
                .finally(() => setLoadingData(false));
        } else {
            Promise.all(
                id.split(',').map(jobId => axios.get(apiConfig.url.BASE_URL + apiConfig.url.JOB_INVOICE_PDF, {params: {jobId, isQuote: false, isInvoice: true, sortBy : 'DateCreated', sortType : 'ASC'} }))
            )
                .then(async res => {
                    if(res){
                        const invoicePdf = await pdf(<JobMultipleInvoicePdf jobInfoPdf={res.map(x => x.data)} draftPdf={invoice} />).toBlob();
                        let merger = new PDFMerger();
                        let hasPrimary = attachments.some(a => a.isPrimary);
                        if (!hasPrimary) await merger.add(invoicePdf);
                        for (const attachment of attachments) {
                            if (attachment.isPrimary) {
                                await merger.add(invoicePdf);
                                continue;
                            }
                            if (attachment.contentType !== 'application/pdf') continue;
                            try {
                                await merger.add(attachment.path);
                            } catch (e) {
                                console.error(e);
                                continue;
                            }
                        }
                        const mergedPdf = await merger.saveAsBlob();
                        const url = URL.createObjectURL(mergedPdf);
                        setMergedPdfUrl(url);
                    }
                })
                .catch(err => {
                    console.log("err", err);
                })
                .finally(() => setLoadingData(false));
        }
    }, [])

    useEffect(() => {
        if (mergedPdfUrl && isMobile) {
            window.location.href = mergedPdfUrl;
        }
    }, [mergedPdfUrl, isMobile]);

    return (
        <>
            {!loadingData 
                ? <iframe
                    src={mergedPdfUrl}
                    title="pdf-viewer"
                    style={{ position: 'absolute', border: 0, height: '100vh', width: '100%' }}
                ></iframe>
                : <CardContent><CircularProgress size={24}/></CardContent>
            }
        </>
    )
}

export default MultipleInvoice;
