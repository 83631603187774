import React, { useState, useEffect } from 'react'
import moment from 'moment'
import { makeStyles } from '@material-ui/styles'
import useMediaQuery from '@material-ui/core/useMediaQuery'
import { Alert } from '@material-ui/lab'
import {
    Card, CardHeader, CardContent,
    Divider, Typography, ButtonGroup, Button,
    colors, FormControlLabel,
    TableContainer, Table, TableHead,
    TableBody, TableRow, TableCell,
    Tooltip, Grid, TextField, Collapse, CircularProgress,
    Snackbar
} from '@material-ui/core'
import { KeyboardArrowDown } from '@material-ui/icons'
import { GreenCheckbox } from 'components'
import { useSelector, useDispatch } from 'react-redux'
import apiConfig from 'apiConfig'
import axios from 'utils/axios'
import {useParams} from "react-router-dom";
import ModalCombine from "./components/ModalCombine";

const useStyles = makeStyles(theme => ({
    filter: {
        display: 'flex',
        alignItems: 'center',
    },
    unactiveBtn: {
        color: colors.green[600]
    },
    activeBtn: {
        color: theme.palette.white,
        backgroundColor: colors.green[600],
        '&:hover': {
            backgroundColor: colors.green[900]
        }
    },
    button: {
        color: theme.palette.white,
        padding: '6px 60px',
        marginRight: '8px',
        backgroundColor: colors.green[600],
        '&:hover': {
            backgroundColor: colors.green[900]
        }
    },
    thead: {
        '& tr th': {
            borderBottom: '1px solid #888888',
            '&:first-child': {
                width: '1%'
            }
        }
    },
    jobs: {
        display: 'flex'
    },
    checkbox: {
        margin: '0'
    },
    sticky: {
        marginBottom: theme.spacing(2),
        position: 'sticky',
        top: '60px',
        zIndex: 3
    },
    stickyHead: {
        position: 'relative',
        cursor: 'pointer'
    },
    address: {
        fontWeight: 500
    },
    btnAction: {
        color: theme.palette.white,
        backgroundColor: colors.green[600],
        '&:hover': {
            backgroundColor: colors.green[900]
        }
    },
}))

const stages = ['None', 'To Bid', 'To be Scheduled', 'Scheduled', 'Bid Completed', 'Job Completed', 'Cancelled', 'CustomerService']

let mounted = false;
const CombineJobs = props => {
    const {states, information, addressId, setOpenModal, fuData, isCustomeService, name, zIndexCustom = 3} = props
    const classes = useStyles();
    const { expanded } = useSelector(state => state.JobsReducer);
    const dispatch = useDispatch();

    const md_down = useMediaQuery('(max-width: 960px)');

    const address = JSON.parse(sessionStorage.getItem('sw-address'));

    const [showAll, setShowAll] = useState(true);
    const [completedBids, setCompletedBids] = useState(false);
    const [completedJobs, setCompletedJobs] = useState(false);
    const [jobs, setJobs] = useState();
    const [combineJobs, setCombineJobs] = useState([]);
    const [isCombine, setIsCombine] = useState(false);
    const [isProgress, setIsProgress] = useState(true);
    const [openSnack, setOpenSnack] = useState(false);
    const [isSuccess, setIsSuccess] = useState({ failed: false, msg: '' });
    const [newJob, setNewJob] = useState(null);
    const [showModalCombine, setShowModalCombine] = useState(false);
    const { jobId } = useParams();
    const toggleExpanded = () => {
        dispatch({ type: 'CHANGE_EXPANDED' })
    }

    const onActive = type => {
        switch (type) {
            case 'showAll':
                setShowAll(true);
                setCompletedBids(false);
                setCompletedJobs(false);
                break;
            case 'completedBids':
                setShowAll(false);
                setCompletedBids(true);
                setCompletedJobs(false);
                break;
            case 'completedJobs':
                setShowAll(false);
                setCompletedBids(false);
                setCompletedJobs(true);
                break;
            default:
                break;
        }
        setIsProgress(true);
    }

    const onCheckCombine = (event, id) => {
        if (event.target.checked) {
            setCombineJobs([...combineJobs, id])
        } else {
            const _combineJobs = [...combineJobs];
            _combineJobs.splice(_combineJobs.indexOf(id), 1);
            setCombineJobs(_combineJobs);
        }
    }
    const onCombineJobs = () => {
        setIsCombine(true);
        axios.post(apiConfig.url.BASE_URL + apiConfig.url.CUSTOMER_JOB_COMBINES, combineJobs)
            .then((res) => {
                setIsSuccess({ failed: false, msg: 'Combine successfully.' });
                setOpenSnack(true);
                setIsProgress(true);
                setCombineJobs([]);
                setNewJob(res.data);
                setShowModalCombine(true);
            })
            .catch(() => {
                setIsSuccess({ failed: true, msg: 'Combine failed, please try again later.' });
                setOpenSnack(true);
            })
            .finally(() => setIsCombine(false))
    }

    const redirectToNewJob = () => {
        if (newJob) {
            const path = `/customers/${encodeURIComponent(name)}/${newJob.customerId}/jobs/${newJob.id}/information`;
            const win = window.open(path, "_blank");
            win.focus();
            setShowModalCombine(false);
        }
    }

    useEffect((type) => {
        mounted = true;
        if (addressId && isProgress && (completedBids || completedJobs || showAll)) {
            axios.get(apiConfig.url.BASE_URL + apiConfig.url.CUSTOMER_JOBS + addressId)
                .then(res => {
                    if (completedJobs) {
                        res.data.data = res.data.data.filter(item => {
                            return !!item.completed;
                        })
                    }
                    if (completedBids) {
                        res.data.data = res.data.data.filter(item => {
                            return !!item.bidCompleted;
                        })
                    }
                    mounted && setJobs(res.data.data)
                })
                .finally(() => mounted && setIsProgress(false))
        }
        if(isProgress){
            setIsProgress(!!jobId.trim())
        }

        return () => mounted = false;
    }, [isProgress, completedBids, completedJobs, showAll, addressId])

    return (
        <>
            <Grid container spacing={3} className={classes.sticky}>
                {/*<Grid className={classes.btnBox} item xs={12}>*/}
                {/*    <Button*/}
                {/*        disabled={!information.id}*/}
                {/*        variant={'contained'}*/}
                {/*        style={{ marginRight: '10px' }} className={classes.btnAction}*/}
                {/*        onClick={() => {*/}
                {/*        }}>*/}
                {/*        Print*/}
                {/*    </Button>*/}
                {/*    <Button*/}
                {/*        disabled={!information.id}*/}
                {/*        variant={'contained'}*/}
                {/*        style={{ marginRight: '10px' }} className={classes.btnAction}*/}
                {/*        onClick={() => {*/}
                {/*        }}>*/}
                {/*        Email*/}
                {/*    </Button>*/}
                {/*    <Button*/}
                {/*        disabled={!information.id}*/}
                {/*        variant={'contained'}*/}
                {/*        style={{ marginRight: '10px' }} className={classes.btnAction}*/}
                {/*        onClick={() => {*/}
                {/*        }}>*/}
                {/*        Copy*/}
                {/*    </Button>*/}
                {/*    <Button*/}
                {/*        variant={'contained'}*/}
                {/*        disabled={!information.id}*/}
                {/*        className={classes.btnAction}*/}
                {/*        onClick={setOpenModal}>*/}
                {/*        {fuData ? 'View Follow Up' : 'Schedule Follow Up'}*/}
                {/*    </Button>*/}
                {/*</Grid>*/}
            </Grid>
            <Card style={{ marginTop: '-18px', zIndex: zIndexCustom }} className={classes.sticky}>
                <CardHeader title={isCustomeService ? <> <span>Job Details</span><span style={{color: 'red'}}> - Customer Service</span> </>  : 'Job Details'}
                    className={classes.stickyHead}
                    action={<KeyboardArrowDown />}
                    onClick={toggleExpanded} />
                <Divider />
                <Collapse in={expanded} timeout='auto' unmountOnExit>
                    <CardContent>
                        <Grid container spacing={3}>
                            <Grid item xs={12} sm={4}>
                                <Card style={{ height: '100%' }}>
                                    <CardHeader title='Job Address' />
                                    <Divider />
                                    <CardContent style={{ padding: '16px' }}>
                                        {
                                            information && information.jobAddress &&
                                            (
                                                <>
                                                    <Typography
                                                        className={classes.address}>{information && information.jobAddress.name || ''}</Typography>
                                                    <Typography
                                                        className={classes.address}>{information && information.jobAddress.address1 || ''}</Typography>
                                                    <Typography
                                                        className={classes.address}>{information && information.jobAddress.address2 || ''}</Typography>
                                                    <Typography className={classes.address}>
                                                        {(information && information.jobAddress.city && `${information && information.jobAddress.city}, `) || ''}
                                                        {(`${states && (states.find(x => x.id === information.jobAddress.stateId)?.name || '')} `) || ''}
                                                        {information && information.jobAddress.zipCode || ''}
                                                    </Typography>
                                                </>
                                            )
                                        }
                                    </CardContent>
                                </Card>
                            </Grid>
                            <Grid item xs={12} sm={8}>
                                <Tooltip
                                    disableFocusListener
                                    arrow
                                    title={
                                        <Typography style={{ color: 'white' }}>
                                            Job Description is only editable in tabs with a save button.
                                        </Typography>
                                    }>
                                    <TextField
                                        fullWidth
                                        disabled
                                        multiline
                                        rows={7}
                                        label='Job Description'
                                        name='description'
                                        variant='outlined'
                                        value={information.description || ''}
                                    />
                                </Tooltip>
                                {/*</MuiThemeProvider>*/}
                            </Grid>
                        </Grid>
                    </CardContent>
                </Collapse>
            </Card>
            <Card>
                <CardHeader title='Combine Jobs' />
                <Divider />
                <CardContent>
                    <div className={classes.filter}>
                        <ButtonGroup>
                            <Button
                                className={(showAll && classes.activeBtn) || classes.unactiveBtn}
                                onClick={() => onActive('showAll')}>
                                Show All
                            </Button>
                            <Button
                                className={(completedBids && classes.activeBtn) || classes.unactiveBtn}
                                onClick={() => onActive('completedBids')}>
                                Completed Bids
                            </Button>
                            <Button
                                className={(completedJobs && classes.activeBtn) || classes.unactiveBtn}
                                onClick={() => onActive('completedJobs')}>
                                Completed Jobs
                            </Button>
                        </ButtonGroup>
                    </div>
                </CardContent>
                <Divider />
                <CardContent>
                    <div style={md_down ? { flexDirection: 'column' } : null} className={classes.jobs}>
                        <div style={{ margin: md_down ? '0 0 20px 0' : '0 50px 0 0' }}>
                            <div style={{ display: 'flex', alignItems: 'center' }}>
                                <Button
                                    disabled={combineJobs.length < 2 || isCombine}
                                    className={classes.button}
                                    onClick={onCombineJobs}>
                                    Combine
                                </Button>
                                {isCombine && <CircularProgress size={24} />}
                            </div>
                            <Typography>Combine selected and create a copy to be scheduled.</Typography>
                        </div>
                        {isProgress ?
                            (
                                <div style={{ width: '100%', textAlign: 'center', padding: '8px' }}>
                                    <CircularProgress size={24} />
                                </div>
                            ) :
                            (Array.isArray(jobs) && jobs.length === 0 ?
                                (
                                    <Typography style={{ width: '100%', textAlign: 'center' }}>No data found</Typography>
                                ) :
                                (
                                    <TableContainer>
                                        <Table>
                                            <TableHead className={classes.thead}>
                                                <TableRow>
                                                    <TableCell align='center'>Stage</TableCell>
                                                    <TableCell align='center'>Date</TableCell>
                                                    <TableCell align='center'>Job Description</TableCell>
                                                    <TableCell align='center'>Total</TableCell>
                                                    <TableCell align='center'
                                                        style={{ width: '1px' }}>Combine</TableCell>
                                                </TableRow>
                                            </TableHead>
                                            <TableBody>
                                                {Array.isArray(jobs) && jobs.map((job, index) => (
                                                    <TableRow key={job.id}>
                                                        <TableCell style={{ whiteSpace: 'nowrap' }}
                                                            align='center'>{stages[job.bidStageId] || 'Unknow'}</TableCell>
                                                        <TableCell
                                                            align='center'>{(job.completed && moment(job.completed).format('MM/DD/YYYY')) || 'Unknow'}</TableCell>
                                                        <TableCell
                                                            align='center'>{job.description || ''}</TableCell>
                                                        <TableCell
                                                            align='center'>{(job.cost && `$${job.cost}`) || '$0.0'}</TableCell>
                                                        <TableCell align='center'>
                                                            <FormControlLabel
                                                                className={classes.checkbox}
                                                                onChange={event => onCheckCombine(event, job.id, index)}
                                                                control={<GreenCheckbox />}
                                                            />
                                                        </TableCell>
                                                    </TableRow>
                                                ))}
                                            </TableBody>
                                        </Table>
                                    </TableContainer>
                                )
                            )}
                    </div>
                </CardContent>
            </Card>
            <div style={{ height: '1px', marginTop: '20px' }}></div>

            <ModalCombine openConfirm={showModalCombine} closeConfirm={() => setShowModalCombine(false)} onConfirm={redirectToNewJob} />
            <Snackbar
                open={openSnack}
                anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
                autoHideDuration={2000}
                onClose={() => setOpenSnack(false)}>
                <Alert
                    elevation={6} variant="filled" severity={isSuccess.failed ? 'error' : 'success'}>
                    <Typography
                        color="inherit"
                        variant="h6">
                        {isSuccess.msg}
                    </Typography>
                </Alert>
            </Snackbar>
        </>
    )
}

export default CombineJobs;
