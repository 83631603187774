import React, { useState, useEffect, useRef } from 'react'
import { useHistory, useLocation, useParams } from 'react-router-dom'
import { useSelector, useDispatch } from 'react-redux'
import { TextField, CircularProgress, IconButton, Snackbar, Typography, useMediaQuery } from '@material-ui/core'
import { Alert } from '@material-ui/lab'
import { makeStyles } from '@material-ui/styles'
import ArrowBackIosRoundedIcon from '@material-ui/icons/ArrowBackIosRounded'
import ArrowForwardIosRoundedIcon from '@material-ui/icons/ArrowForwardIosRounded';
import JobItem from './JobItem'
import moment from 'moment'
import apiConfig from 'apiConfig'
import axios from 'utils/axios'

const useStyles = makeStyles(theme => ({
    collapse: {
        width: 350,
        flexShrink: 0,
        overflow: 'hidden',
        transition: 'width 200ms ease-in'
    },
    expandIcon: {
        padding: 0,
    },
    expand: {
        width: 350
    },
    noexpand: {
        width: theme.spacing(4)
    },
    sourceType: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        marginBottom: 16,
        marginTop: 10,
        marginLeft: 10
    },
    overBox: {
        flex: 1,
        overflowY: 'auto',
        border: '1px solid #cbcbcb',
        borderRadius: 6,
        minHeight: 40,
        '&::-webkit-scrollbar': {
            width: '10px'
        },
        '&::-webkit-scrollbar-track': {
            backgroundColor: '#e4e4e4',
            borderRadius: '60px'
        },
        '&::-webkit-scrollbar-thumb': {
            backgroundColor: '#9c9c9c',
            borderRadius: '60px'
        }
    },
    routeSource: {
        maxHeight: '100%',
        width: 345,
        flexShrink: 0,
        height: '100%',
        display: 'flex',
        flexDirection: 'column'
    }
}));

const pageSize = 30;

const Jobs = ({ date, setSelectedJob, selectedJob }) => {
    const classes = useStyles();
    const dispatch = useDispatch();
    const sm = useMediaQuery("(max-width: 769px)")
    const location = useLocation();
    const history = useHistory();
    const { routes } = useSelector(state => state.RoutesReducer);

    const [loading, setLoading] = useState(false);
    const [expandSource, setExpandSource] = useState(true);
    const [pageJob, setPageJob] = useState(1);
    const [totalPageJob, setTotalPageJob] = useState(1);
    const [gettingDistance, setGettingDistance] = useState(null);
    const [jobs, setJobs] = useState([]);

    const locateInRoute = (jobSplitId) => {
        axios.get(apiConfig.url.BASE_URL + apiConfig.url.ROUTE_DISTANCE_MONTH, { params: { jobSplitId, routeDate: moment(date).startOf('month').toDate() } })
            .then(res => {
                dispatch({ type: 'GET_DISTANCE', distances: res.data?.routeDistances })
            }).finally(() => {
                setGettingDistance(null);
            })
    }

    const getJobs = (page) => {
        if (loading) return;
        setLoading(true);
        const pageNum = page || pageJob;
        if (pageNum > totalPageJob) {
            setLoading(false);
            return;
        }
        axios.get(apiConfig.url.BASE_URL + apiConfig.url.TO_BE_SCHEDULED_JOBS + '&pageNumber=' + pageNum + '&pageSize=' + pageSize)
            .then(res => {
                res.data.data.map(job => {
                    job['job'] = {
                        city: job.city || '',
                        price: job.price,
                        estimatedHours: job.estimatedHours
                    }
                });
                setJobs([...jobs, ...res.data.data]);
                setTotalPageJob(res.data.pageCount);
                pageNum === 1 ? dispatch({ type: 'INIT_JOB', routeJobs: res.data.data }) 
                              : dispatch({ type: 'LOAD_MORE_JOB', routeJobs: res.data.data });
                if (pageNum * 1 <= res.data.pageCount * 1) {
                    setPageJob(pageNum + 1);
                    dispatch({ type: 'CHANGE_PAGE', pageNum: (pageNum + 1) });              
                }
            })
            .catch(() => {
            }).finally(() => {
                setLoading(false);
            })
    }

    useEffect(() => {
        dispatch({ type: "CHANGE_WIDTH", expanded: expandSource })
    }, [expandSource])

    useEffect(() => {
        getJobs(1);
        return () => dispatch({ type: 'GET_DISTANCE', distances: [] });
    }, [])

    useEffect(() => {
        const stateRoute = location.state;
        if (stateRoute && stateRoute.jobId) {
            setSelectedJob(stateRoute.jobId);
            history.replace();
        }
    }, [location]);

    return (
        <div className={`${classes.collapse} ${expandSource ? classes.expand : classes.noexpand}`}
            style={sm ? { width: 0 } : null}>
            {!expandSource &&
                <IconButton className={classes.expandIcon} onClick={() => setExpandSource(true)}>
                    <ArrowForwardIosRoundedIcon />
                </IconButton>
            }
            <div className={classes.routeSource} style={!expandSource ? { display: 'none' } : null}>
                <div className={classes.sourceType}>
                    Jobs
                    <IconButton className={classes.expandIcon} onClick={() => setExpandSource(false)}>
                        <ArrowBackIosRoundedIcon />
                    </IconButton>
                </div>
                <div  className={classes.overBox}
                    style={{ backgroundColor: '#ffffff' }} onScroll={(e) => {
                        if (e.target !== e.currentTarget) return;
                        const bottom = e.target.scrollHeight - e.target.scrollTop - 10 <= e.target.clientHeight;
                        if (bottom) getJobs();
                    }}>
                    <div className={classes.sources}>
                        {jobs && jobs.map((job, index) => (
                            <JobItem
                                key={`${job.jobSplitId}-${index}`}
                                isSelected={selectedJob === job.jobId}
                                gettingDistance={gettingDistance === job.jobId}
                                source={job}
                                changeSelected={() => setSelectedJob(job.jobId)}
                                locateInRoute={() => {
                                    setGettingDistance(job.jobId);
                                    locateInRoute(job.jobSplitId);
                                }}
                            />
                        ))}
                        {loading &&
                            <div style={{ height: '40px', marginTop: '16px', textAlign: 'center' }}>
                                <CircularProgress size={24} />
                            </div>
                        }
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Jobs;
