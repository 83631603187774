import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import PropTypes from 'prop-types'
import { useDrop } from 'react-dnd'
import ResizableBox from './ResizableBox'
import { useSelector, useDispatch } from 'react-redux'
import apiConfig from 'apiConfig'
import axios from 'utils/axios'
import { formatTwoPDecimal } from "utils/formatNumber";

const DropBox = props => {

    const { date } = useParams();

    const { className, routeId, startTime, error, canDrag, topJobId, changeTopJob } = props;

    const dispatch = useDispatch();

    const { routes } = useSelector(state => state.RoutesReducer);
    const { routeJobs } = useSelector(state => state.JobsReducer);

    const [job, setJob] = useState();
    const [hourOver, setHourOver] = useState();
    const [inforJob, setInforJob] = useState('');

    const [{ isOver }, drop] = useDrop({
        accept: 'Job',
        drop: item => handleDropItem(item, routeId, startTime),
        hover: (item, monitor) => onCheckHover(item),
        collect: (monitor) => ({
            isOver: monitor.isOver()
        })
    });

    const handleDropItem = ({ id, oldRouteId }, routeId, startTime) => {
        dispatch({ type: 'CHANGE_STATUS_JOB', status: false });
        if (oldRouteId === undefined) {
            const route = routes.find(route => route.id === routeId);
            const rj = routeJobs.find(job => job.jobId === id)
            const job = {
                ...rj,
                job: {
                    estimatedHours: rj.estimatedHours,
                    price: rj.price,
                    city: rj.city || '',
                    jobId: rj.jobId,
                    customerId: rj.customerId,
                    customerName: rj.customerName,
                    equipment: rj.equipment,
                    description: rj.description,
                    totalSplitJobs: rj.totalSplitJobs
                }
            };

            const localStartDate = new Date((new Date(date)).toISOString().replace("Z", ""));
            localStartDate.setHours(Math.floor(startTime));
            localStartDate.setMinutes(startTime - Math.floor(startTime) === 0.5 ? 30 : 0);
            const jobStart = new Date(localStartDate).toISOString();

            const localEndDate = new Date((new Date(date)).toISOString().replace("Z", ""));
            job.estimatedHours = formatTwoPDecimal(job.estimatedHours / (route.routeEmployees.length === 0 ? 1 : route.routeEmployees.length));
            const hour = startTime + (job.estimatedHours);
            localEndDate.setHours(Math.floor(hour));
            localEndDate.setMinutes(Math.round((hour - Math.floor(hour)) * 60));
            const jobEnd = new Date(localEndDate).toISOString();

            job.jobStart = jobStart;
            job.jobEnd = jobEnd;

            const newRouteData = {
                ...route,
                routeEquipment: !route.routeEquipment ? [] : [...route.routeEquipment],
                routeEmployees: !route.routeEmployees ? [] : [...route.routeEmployees],
                routeJobs: route.routeJobs ? [...route.routeJobs, job] : [job],
            }
            const requestForRoute = axios.put(apiConfig.url.BASE_URL + apiConfig.url.ROUTE + `${date}/` + routeId, newRouteData);
            const requestForJob = axios.patch(apiConfig.url.BASE_URL + 'job', { id, jobStage: 1 });
            dispatch({ type: 'IS_UPDATE', status: true });
            Promise.all([requestForRoute, requestForJob])
                .then(values => {
                    dispatch({ type: 'UPDATE_JOB_ROUTE', routeId: routeId, routeJobs: values[0].data.routeJobs });
                    dispatch({ type: 'REMOVE_JOB', jobId: id });
                    dispatch({ type: 'CHANGE_JOB_STAGE', jobId: id, jobStage: 3 });
                    error({ failed: false, msg: 'Update route successfuly.' })
                })
                .catch((e) => {
                    error({ failed: true, msg: 'Update route failed, please try again later.' })
                })
                .finally(() => dispatch({ type: 'IS_UPDATE', status: false }))
        } else if (oldRouteId !== routeId) {
            const route = routes.find(route => route.id === oldRouteId);
            const newRoute = routes.find(route => route.id === routeId);
            const job = { ...route.routeJobs.find(job => job.jobId === id) };

            const localStartDate = new Date((new Date(date)).toISOString().replace("Z", ""));
            localStartDate.setHours(Math.floor(startTime));
            localStartDate.setMinutes(startTime - Math.floor(startTime) === 0.5 ? 30 : 0);
            const jobStart = new Date(localStartDate).toISOString();

            const localEndDate = new Date((new Date(date)).toISOString().replace("Z", ""));
            job.estimatedHours = formatTwoPDecimal(job.job.estimatedHours / (newRoute.routeEmployees.length === 0 ? 1 : newRoute.routeEmployees.length));
            const hour = startTime + (job.estimatedHours);
            localEndDate.setHours(Math.floor(hour));
            localEndDate.setMinutes(Math.round((hour - Math.floor(hour)) * 60));
            const jobEnd = new Date(localEndDate).toISOString();

            job.jobStart = jobStart;
            job.jobEnd = jobEnd;

            const oldRoute = routes.find(route => route.id === oldRouteId);
            const oldRouteData = {
                ...oldRoute,
                routeEquipment: !route.routeEquipment ? [] : [...route.routeEquipment],
                routeEmployees: !route.routeEmployees ? [] : [...route.routeEmployees],
                routeJobs: oldRoute.routeJobs.filter(job => job.jobId !== id)
            };
            const newRouteData = {
                ...newRoute,
                routeEquipment: !newRoute.routeEquipment ? [] : [...newRoute.routeEquipment],
                routeEmployees: !newRoute.routeEmployees ? [] : [...newRoute.routeEmployees],
                routeJobs: newRoute.routeJobs ? [...newRoute.routeJobs, job] : [job]
            };
            const requestForOldRoute = axios.put(apiConfig.url.BASE_URL + apiConfig.url.ROUTE + `${date}/` + oldRouteId, oldRouteData);
            const requestForNewRoute = axios.put(apiConfig.url.BASE_URL + apiConfig.url.ROUTE + `${date}/` + routeId, newRouteData);

            dispatch({ type: 'IS_UPDATE', status: true });
            Promise.all([requestForOldRoute, requestForNewRoute])
                .then(values => {
                    axios.patch(apiConfig.url.BASE_URL + 'job', { id, jobStage: 3 }).then(r => {
                        dispatch({ type: 'CHANGE_JOB_ROUTE', oldRouteId, routeId, job });
                        error({ failed: false, msg: 'Update route successfuly..' })
                    }).catch(() => {
                        error({ failed: true, msg: 'Update route failed, please try again later.' })
                    }).finally(() => dispatch({ type: 'IS_UPDATE', status: false }))
                })
                .catch(() => error({ failed: true, msg: 'Update route failed, please try again later.' }))
        } else if (oldRouteId === routeId) {
            const route = routes.find(route => route.id === routeId);

            const job = { ...route.routeJobs.find(job => job.jobId === id) };

            const localStartDate = new Date((new Date(date)).toISOString().replace("Z", ""));
            localStartDate.setHours(Math.floor(startTime));
            localStartDate.setMinutes(startTime - Math.floor(startTime) === 0.5 ? 30 : 0);
            const jobStart = new Date(localStartDate).toISOString();

            const localEndDate = new Date((new Date(date)).toISOString().replace("Z", ""));
            job.estimatedHours = formatTwoPDecimal(job.job.estimatedHours / (route.routeEmployees.length === 0 ? 1 : route.routeEmployees.length));
            const hour = startTime + (job.estimatedHours);
            localEndDate.setHours(Math.floor(hour));
            localEndDate.setMinutes(Math.round((hour - Math.floor(hour)) * 60));
            const jobEnd = new Date(localEndDate).toISOString();

            job.jobStart = jobStart;
            job.jobEnd = jobEnd;

            const routeJob = route.routeJobs.filter(job => job.jobId !== id)
            const newRouteData = {
                ...route,
                routeEquipment: !route.routeEquipment ? [] : [...route.routeEquipment],
                routeEmployees: !route.routeEmployees ? [] : [...route.routeEmployees],
                routeJobs: route.routeJobs ? [...routeJob, job] : [job]
            };
            const requestForRoute = axios.put(apiConfig.url.BASE_URL + apiConfig.url.ROUTE + `${date}/` + routeId, newRouteData);
            dispatch({ type: 'IS_UPDATE', status: true });
            Promise.all([requestForRoute])
                .then(values => {
                    dispatch({ type: 'CHANGE_JOB_HOURS', oldRouteId, routeId, job });
                    error({ failed: false, msg: 'Update route successfuly..' })
                })
                .catch(() => error({ failed: true, msg: 'Update route failed, please try again later.' }))
                .finally(() => dispatch({ type: 'IS_UPDATE', status: false }))
        }
    }

    const onCheckHover = (item) => {
        setHourOver(item.hours);
    }

    useEffect(() => {
        const route = routes.find(r => r.id === routeId);
        if (route && route.routeJobs) {
            const job = route.routeJobs.find(job => {
                const datetime = new Date(job.jobStart);
                const hours = datetime.getHours();
                const minute = datetime.getMinutes();
                const minutes = minute > 44 ? 1 : (minute < 15 ? 0 : 0.5);
                return (hours + minutes) === startTime;
            });
            setJob(job)
        }
    }, [routes])

    useEffect(() => {
        if (job) {
            const _job = job;
            const idenSplits = _job.job && _job.job.totalSplitJobs > 1 ? `  (1/${_job.job.totalSplitJobs})` : '';
            const city = _job.job && _job.job.city ? `${_job.job.city},` : ''
            const info = _job.job ?  `${_job.job.customerName}, ${city} ${Math.round(_job.job.estimatedHours * 100) / 100} hours, $${_job.job.price.toFixed(2)}${idenSplits}` : '';
            setInforJob(info)
        } else {
            setInforJob('')
        }
    }, [job])

    return (
        <div ref={job || !canDrag ? null : drop}
            className={className}>
            <ResizableBox
                routes={routes}
                startTime={startTime}
                routeId={routeId}
                job={job}
                isOver={isOver}
                hourOver={hourOver}
                canDrag={canDrag}
                topJobId={topJobId}
                changeTopJob={() => changeTopJob(job.id)}
            >
                {inforJob}
            </ResizableBox>
        </div>
    )
}

DropBox.propTypes = {
    className: PropTypes.string,
    routeId: PropTypes.string,
    startTime: PropTypes.number,
    error: PropTypes.func,
    topJobId: PropTypes.string,
    changeTopJob: PropTypes.func
}

DropBox.defaultProps = {
    className: '',
}

export default DropBox
