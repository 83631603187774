import React, { useEffect } from 'react'
import PropTypes from 'prop-types'
import { useDrag } from 'react-dnd'
import { Tooltip, Typography } from "@material-ui/core";
import { createTheme, MuiThemeProvider } from "@material-ui/core/styles";
import OpenInNewIcon from '@material-ui/icons/OpenInNew';
import { useDispatch, useSelector } from "react-redux";

const customThemeTooltip = createTheme({
    overrides: {
        MuiTooltip: {
            tooltip: {
                fontSize: '14px',
                maxWidth: 'none'
            }
        }
    }
})
const DraggableBox = props => {
    const dispatch = useDispatch();
    const { displayType, data, className, source, employees, canDrag, dragType, tooltip, onClick } = props;
    const { dragging } = useSelector(state => state.EmployeesReducer);

    const [{ type, isDragging }, drag] = useDrag({
        item: source,
        canDrag: canDrag,
        collect: (monitor) => {
            return {
                isDragging: monitor.isDragging() ? true : false,
                type: monitor.isDragging() ? (dragType === 'employee' ? 'employees' : 'equipments') : '',
            }
        }
    })
    const handleMouseEnter = () => {
        setTimeout(() => {
            if (!dragging) {
                dispatch({ type: 'DRAGGING_EMPLOYEES', isHover: true, dayOff: employees.dayOff, routeId: source.oldRouteId })
            }
        }, 0)

    }
    const handleMouseLeave = () => {
        setTimeout(() => {
            if (!dragging) {
                dispatch({ type: 'DRAGGING_EMPLOYEES', isHover: false, dayOff: [] })
            }
        }, 0)
    }
    useEffect(() => {
        if (dragType === 'employee') {
            dispatch({ type: 'DRAGGING_EMPLOYEES', status: isDragging, dayOff: isDragging ? employees.dayOff : [], routeId: null })
        }
    }, [isDragging])
    useEffect(() => {
        if (type) {
            dispatch({ type: 'DRAG_ITEM', onDragType: type })
        }
    }, [type]);
    return (
        <>
            {
                employees && dragType === 'employee' ?
                    <span
                        onClick={e => {
                            e.stopPropagation();
                            canDrag && onClick()
                        }}
                        onMouseEnter={!isDragging && handleMouseEnter}
                        onMouseLeave={!isDragging && handleMouseLeave}
                        ref={drag}
                        style={{
                            display: isDragging ? 'none' : 'inline-flex',
                            cursor: canDrag ? 'pointer' : 'not-allowed',
                            lineHeight: '25px'
                        }}
                        display={displayType}
                        className={className}
                    >
                        <MuiThemeProvider theme={customThemeTooltip}>
                            <Tooltip arrow title={tooltip}>
                                <span
                                    style={{
                                        display: isDragging ? 'none' : 'inline',
                                        cursor: canDrag ? 'pointer' : 'not-allowed',
                                        marginRight: '5px'
                                    }}
                                    display={displayType}
                                >
                                    {data}
                                </span>
                            </Tooltip>
                        </MuiThemeProvider>
                        <MuiThemeProvider theme={customThemeTooltip}>
                            <Tooltip arrow title={'View'}>
                                <a style={{ color: 'white', lineHeight: '8px' }}
                                    href={`/employees/${encodeURIComponent(employees.firstName + ' ' + employees.lastName)}/${employees.employeeId}/schedule`}
                                    onClick={e => e.stopPropagation()}>
                                    <OpenInNewIcon style={{ fontSize: '20px' }} />
                                </a>
                            </Tooltip>
                        </MuiThemeProvider>
                    </span>
                    :
                    <span
                        ref={drag}
                        onClick={e => {
                            e.stopPropagation();
                            canDrag && onClick()
                        }}
                        style={{
                            display: isDragging ? 'none' : 'inline',
                            cursor: canDrag ? 'pointer' : 'not-allowed',
                            padding: '4px 8px'
                        }}
                        display={displayType}
                        className={className}>
                        {data}
                    </span>
            }
        </>
    )
}

DraggableBox.propTypes = {
    displayType: PropTypes.string,
    data: PropTypes.string,
    className: PropTypes.string,
    source: PropTypes.object,
    onClick: PropTypes.func
}
DraggableBox.defaultProps = {
    displayType: 'inline',
    data: '',
    className: '',
    source: {}
}

export default DraggableBox;
