import axios from "./utils/axios";
import apiConfig from "./apiConfig";

export const FeatureFlags = {
    EXPIRING_DOCUMENTS: 'document-expiration',
    TODAYS_ROUTE: 'todays-route-redesign',
    COMMUNICATION_CENTER: 'communication-center',
    BIDS_TO_DO: 'bids-to-do', // search all variable isEnableBidsToDo to remove FF
    WORK_ORDER:'work-order',
    VISUAL_MAP: 'visual-map',
    CUSTOM_FORM:'custom-form',
    TASKS:'tasks',
};

export const checkFeature = (feature) => {
    return new Promise((resolve, reject) => {
        if(!feature) resolve(false);

        const prefixFeatureName = "ff-"+feature;

        // check Session Storage first
        const checkFeatureSessionStorage = sessionStorage.getItem(prefixFeatureName);
        if (checkFeatureSessionStorage !== null) { // if existing -> return
            resolve(checkFeatureSessionStorage == 'true')
        } else {
            // if not existing Session Storage -> call API to get value
            axios
                .get(apiConfig.url.BASE_URL + apiConfig.url.CHECK_FEATURE + feature)
                .then(res => {
                    sessionStorage.setItem( // set Session Storage by feature
                        prefixFeatureName,
                        res.data == 'True'
                    );
                    resolve(res.data == 'True');
                }).catch((ex) => {
                resolve(false);
            });
        }
    })
}
