import React, {useEffect, useMemo, useRef, useState} from 'react'
import PropTypes from 'prop-types'
import { Typography, Tooltip, colors } from '@material-ui/core'
import { createTheme, MuiThemeProvider } from '@material-ui/core/styles'
import { makeStyles } from '@material-ui/styles'
import { useDrag } from 'react-dnd'
import { useDispatch, useSelector } from 'react-redux'
import moment from "moment";
import OpenInNewIcon from '@material-ui/icons/OpenInNew';
import RoomIcon from '@material-ui/icons/Room';

const useStyles = makeStyles(theme => ({
    dragJob: {
        height: '40px',
        padding: '10px',
        cursor: 'pointer',
        display: 'flex',
        alignItems: 'center',
        '& p': {
            whiteSpace: 'nowrap',
            width: '100%',
            overflowX: 'scroll',
            '-ms-overflow-style': 'none',  // IE and Edge
            scrollbarWidth: 'none',  // Firefox
            '&::-webkit-scrollbar': {
                display: 'none'  // Chrome, Safari and Opera
            }
        },
        '&:hover': {
            '& p': {
                color: theme.palette.white
            },
            '& .employee': {
                backgroundColor: theme.palette.white,
                '& p': {
                    color: '#263238'
                }
            },
            '& .job': {
                backgroundColor: colors.green[500],
                color: theme.palette.white,
                '& p': {
                    color: '#263238'
                }
            },
            backgroundColor: colors.green[500]
        }
    },
    buttons: {
        position: 'absolute',
        right: 0
    },
    button: {
        padding: '2px 8px',
        backgroundColor: colors.green[500],
        '& p': {
            color: theme.palette.white
        }
    },
    jobLink: {
        color: '#263238',
        background: "transparent",
        '& i': {
            color: colors.green[500],
        }
    },
    jobMap: {
        marginLeft: 'auto',
        color: '#263238',
        background: "transparent",
        '& i': {
            color: colors.green[500],
        }
    }
}));

const customThemeTooltip = createTheme({
    overrides: {
        MuiTooltip: {
            tooltip: {
                fontSize: '14px',
                maxWidth: 'none'
            }
        }
    }
});

const DraggableSource = props => {
    const classes = useStyles();

    const { isSelected, type, source, changeSelected, locateInRoute, setDrag } = props;

    const dispatch = useDispatch();

    const { routes } = useSelector(state => state.RoutesReducer);

    const [{ isDragging }, drag] = useDrag({
        item: {
            id: source.jobId || source.employeeId || source.id,
            hours: type === 'Job' ? source.estimatedHours : null,
            type: type
        },
        collect: (monitor) => ({
            isDragging: monitor.isDragging() ? true : false,
        })
    });

    const fullName = `${source.firstName || ''}${source.lastName ? ` ${source.lastName}` : ' '}`.replace('N/A', '');

    const getValue = () => {
        if (type === 'Employee') {
            let days = [];
            source.dayOff.forEach(day => {
                days.push(`${moment(day.start).format('hh:mm A')} - ${moment(day.end).format('hh:mm A')}`)
            })
            const dayString = days.length > 0 ? ` | Unavailable: ${days.join(', ')}` : '';
            const phone = (source.phones && source.phones[0]) ? (', ' + source.phones[0].formattedPhoneNumber) : (source.phoneNumber ? ', ' + source.phoneNumber : '');
            return `${fullName}${phone} ${dayString}`;
        }
        if (type === 'Job') {
            const idenSplits = source.totalSplitJobs > 1 ? `  (1/${source.totalSplitJobs})` : '';
            return `${source.customerName}, ${source.city || ''}, ${source.job.estimatedHours} hours, $${source.price ? source.price.toFixed(2) : '0.00'}${idenSplits}`;
        }
        if (type === 'Equipment') {
            return source.assetTag;
        }
    }

    const getTooltip = () => {
        return (
            <>
                <Typography>
                    {source.city || ''}, ${source.price ? source.price.toFixed(2) : '0.00'}
                </Typography>
                <Typography>
                    <em>Hours: </em> {source.estimatedHours}
                </Typography>
                <Typography>
                    <em>Customer: </em> {source.customerName}
                </Typography>
                <Typography>
                    <em>Equipment: </em> {source.equipment || ''}
                </Typography>
                <Typography>
                    <em>Description: </em> {(source && source.description) || ''}
                    {/*<em>Description: </em> {(source && source.jobDescription) || ''}*/}
                </Typography>
            </>
        )
    }

    useEffect(() => {
        setDrag(isDragging);
        if (type === 'Job') {
            dispatch({ type: 'CHANGE_STATUS_JOB', status: isDragging })
        } else if (type === 'Employee') {
            dispatch({ type: 'DRAGGING_EMPLOYEES', status: isDragging, dayOff: isDragging ? source.dayOff : [] })
        }
    }, [isDragging])

    return useMemo(() => (
        <div
            ref={drag}
            style={isSelected ? { backgroundColor: '#4caf50' } : null}
            className={classes.dragJob}
            onClick={changeSelected}>
            {type === 'Job' ?
                (
                    <MuiThemeProvider theme={customThemeTooltip}>
                        <Tooltip arrow title={getTooltip()} enterDelay={1000}>
                            <Typography style={{ marginRight: 66, color: isSelected ? '#ffffff' : '' }}>
                                {getValue()}
                            </Typography>
                        </Tooltip>
                    </MuiThemeProvider>
                ) :
                (
                    <Typography style={{ color: isSelected ? '#ffffff' : '' }}>
                        {getValue()}
                    </Typography>
                )
            }

            {type === 'Employee' &&
                <MuiThemeProvider theme={customThemeTooltip}>
                    <Tooltip arrow title={'View Availability'}>
                        <a style={isSelected ? { color: '#ffffff', backgroundColor: '#4caf50', marginLeft: 'auto' } : { marginLeft: 'auto' }}
                            className={`${classes.button} ${classes.jobLink} job`}
                            href={`/employees/${encodeURIComponent(fullName)}/${source.id}/schedule`}
                            target='_blank'>
                            <OpenInNewIcon style={{ fontSize: 20 }} />
                        </a>
                    </Tooltip>
                </MuiThemeProvider>
            }
            {type === 'Job' &&
                <div className={classes.buttons}>
                    <MuiThemeProvider theme={customThemeTooltip}>
                        <Tooltip arrow title={'Locate On Route'}>
                            <a style={isSelected ? { color: '#ffffff', backgroundColor: '#4caf50' } : null}
                                className={`${classes.button} ${classes.jobMap} job`}
                                onClick={locateInRoute}
                                target='_blank'>
                                <RoomIcon style={{ fontSize: 24 }} />
                            </a>
                        </Tooltip>
                    </MuiThemeProvider>
                    <MuiThemeProvider theme={customThemeTooltip}>
                        <Tooltip arrow title={'View'}>
                            <a style={isSelected ? { color: '#ffffff', backgroundColor: '#4caf50' } : null}
                                className={`${classes.button} ${classes.jobLink} job`}
                                href={`/customers/${encodeURIComponent(source.customerName)}/${source.customerId}/jobs/${source.jobId}/information`}>
                                <OpenInNewIcon style={{ fontSize: 20 }} />
                            </a>
                        </Tooltip>
                    </MuiThemeProvider>
                </div>
            }
        </div>
    ),[source, routes])
}

DraggableSource.propTypes = {
    isSelected: PropTypes.bool.isRequired,
    type: PropTypes.string.isRequired,
    source: PropTypes.object.isRequired,
    changeSelected: PropTypes.func
}

DraggableSource.defaultProps = {
    isSelected: false,
    type: 'Job',
    source: {},
    changeSelected: () => {
    }
}

export default DraggableSource;
