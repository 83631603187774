import {
  Button,
  CardActions,
  CircularProgress,
  IconButton,
  Snackbar,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Typography,
  colors,
} from "@material-ui/core";
import { AddBox, Delete } from "@material-ui/icons";
import { Alert } from "@material-ui/lab";
import { makeStyles } from "@material-ui/styles";
import apiConfig from "apiConfig";
import { errorMessages } from "common/constants";
import { ConfirmModal, FormErrorCallback } from "components";
import { Formik } from "formik";
import React, { useEffect, useRef, useState } from "react";
import axios from "utils/axios";
import * as Yup from "yup";

const useStyles = makeStyles((theme) => ({
  headRow: {
    "& th": {
      borderBottom: "1px solid #888888",
      fontSize: "16px",
      padding: "8px 16px",
    },
  },
  addIcon: {
    padding: "0",
    "& span svg": {
      fontSize: "40px",
      color: colors.green[600],
      "&:hover": {
        color: colors.green[900],
      },
    },
  },
  btn: {
    whiteSpace: "nowrap",
    color: theme.palette.white,
    backgroundColor: colors.green[600],
    "&:hover": {
      backgroundColor: colors.green[900],
    },
  },
  btnDis: {
    whiteSpace: "nowrap",
    color: "rgba(0, 0, 0, 0.26)",
    backgroundColor: "rgb(217 217 217)",
    pointerEvents: "none",
  },
}));

const emailSchema = Yup.object().shape({
  email: Yup.string()
    .nullable()
    .required("Email is required."),
});

const OptionalEmailsTable = ({ adminId , reloadData }) => {
  const classes = useStyles();

  const formRef = useRef(null);

  const [emailDomain, setEmailDomain] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [emails, setEmails] = useState([]);
  const [isAdding, setIsAdding] = useState(false);
  const [isSaving, setIsSaving] = useState(false);
  const [openConfirm, setOpenConfirm] = useState(false);
  const [deletingEmail, setDeletingEmail] = useState(null);
  const [isDeleting, setIsDeleting] = useState(false);

  const [isStatus, setIsStatus] = useState({ failed: false, msg: "" });
  const [openSnackbar, setOpenSnackbar] = useState(false);

  const loadEmails = () => {
    setIsLoading(true);
    return axios
      .get(apiConfig.url.BASE_URL + apiConfig.url.COMPANY_EMAIL_LIST)
      .then((res) => {
        if (res.data) {
          setEmails(res.data);
        }
      })
      .finally(() => setIsLoading(false));
  };

  const loadEmailDomain = () => {
    axios
      .get(apiConfig.url.BASE_URL + apiConfig.url.COMPANY_INFO + adminId)
      .then((res) => {
        if (res.data?.email) {
          const domain = res.data.email.split("@")[1];
          if (domain) setEmailDomain(domain);
        }
      });
  };

  const addEmail = () => {
    setIsAdding(true);
  };

  const deleteEmail = () => {
    if (!deletingEmail?.id) {
      setIsAdding(false);
      setOpenConfirm(false);
      if (formRef?.current) formRef.current.resetForm();
      setIsStatus({ failed: false, msg: "Record Deleted" });
      setOpenSnackbar(true);
      return;
    }

    setIsDeleting(true);
    axios
      .delete(
        apiConfig.url.BASE_URL +
          apiConfig.url.COMPANY_EMAIL_DELETE.replace("{id}", deletingEmail.id)
      )
      .then((res) => {
        setDeletingEmail(null);
        setOpenConfirm(false);
        let _emails = [...emails];
        const deleteIdx = emails.indexOf(deletingEmail);
        if (deleteIdx >= 0) _emails.splice(deleteIdx, 1);
        setEmails(_emails);
        setIsStatus({ failed: false, msg: "Record Deleted" });
        setOpenSnackbar(true);
      })
      .finally(() => {setIsDeleting(false);reloadData && reloadData(); });
  };

  useEffect(() => {
    loadEmails();
    loadEmailDomain();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <Formik
        innerRef={formRef}
        initialValues={{ email: "" }}
        validationSchema={emailSchema}
        onSubmit={(values, { resetForm }) => {
          setIsSaving(true);
          axios
            .post(
              apiConfig.url.BASE_URL + apiConfig.url.COMPANY_EMAIL_ADD,
              values
            )
            .then((res) => {
              if (res.data) {
                setIsAdding(false);
                setIsSaving(false);
                setEmails([res.data, ...emails]);
                resetForm();
                setIsStatus({ failed: false, msg: "Record Saved" });
                setOpenSnackbar(true);
                reloadData && reloadData();
              }
            })
            .catch((err) => {
              setIsStatus({
                failed: true,
                msg: "An error occurred, please try again later",
              });
              setOpenSnackbar(true);
            });
        }}
      >
        {({
          values,
          errors,
          touched,
          submitCount,
          isValid,
          setFieldValue,
          setFieldTouched,
          submitForm,
        }) => (
          <div>
            <FormErrorCallback
              submitCount={submitCount}
              isValid={isValid}
              onSubmissionError={() => {
                setIsStatus({
                  failed: true,
                  msg: errorMessages.FIELD_CHECK,
                });
                setOpenSnackbar(true);
              }}
            />
            <TableContainer>
              <Table>
                <TableHead>
                  <TableRow className={classes.headRow}>
                    <TableCell>Email Address</TableCell>
                    <TableCell></TableCell>
                    <TableCell style={{ width: "1px" }}></TableCell>
                    <TableCell align="right" style={{ width: "1px" }}>
                      <IconButton
                        className={classes.addIcon}
                        onClick={addEmail}
                      >
                        <AddBox />
                      </IconButton>
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {isLoading ? (
                    <TableRow>
                      <TableCell colSpan={4} align="center">
                        <CircularProgress size={50} />
                      </TableCell>
                    </TableRow>
                  ) : (
                    <>
                      {isAdding && (
                        <TableRow>
                          <TableCell>
                            <div
                              style={{
                                display: "flex",
                                alignItems: "center",
                              }}
                            >
                              <TextField
                                name="email"
                                variant="outlined"
                                error={errors.email && touched.email}
                                helperText={
                                  errors.email && touched.email && errors.email
                                }
                                onBlur={() => setFieldTouched("email")}
                                onChange={(event) =>
                                  setFieldValue("email", event.target.value)
                                }
                                value={values.email || ""}
                              />
                              <Typography style={{ marginLeft: "8px" }}>
                                @{emailDomain}
                              </Typography>
                            </div>
                          </TableCell>
                          <TableCell></TableCell>
                          <TableCell>
                            <CardActions>
                              <Button
                                className={
                                  isSaving ? classes.btnDis : classes.btn
                                }
                                variant="contained"
                                onClick={submitForm}
                              >
                                Save
                              </Button>
                              {isSaving && <CircularProgress size={24} />}
                            </CardActions>
                          </TableCell>
                          <TableCell>
                            <IconButton
                              onClick={() => {
                                setOpenConfirm(true);
                              }}
                            >
                              <Delete />
                            </IconButton>
                          </TableCell>
                        </TableRow>
                      )}
                      {emails.map((item) => (
                        <TableRow>
                          <TableCell>{item.email}</TableCell>
                          <TableCell></TableCell>
                          <TableCell>
                            <CardActions>
                              <Button
                                className={classes.btnDis}
                                variant="contained"
                              >
                                Save
                              </Button>
                            </CardActions>
                          </TableCell>
                          <TableCell>
                            <IconButton
                              onClick={() => {
                                setDeletingEmail(item);
                                setOpenConfirm(true);
                              }}
                            >
                              <Delete />
                            </IconButton>
                          </TableCell>
                        </TableRow>
                      ))}
                    </>
                  )}
                </TableBody>
              </Table>
            </TableContainer>
          </div>
        )}
      </Formik>

      <ConfirmModal
        openConfirm={openConfirm}
        closeConfirm={() => {
          setOpenConfirm(false);
          setDeletingEmail(null);
        }}
        onConfirm={deleteEmail}
        isProgress={isDeleting}
      />

      <Snackbar
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
        open={openSnackbar}
        autoHideDuration={3000}
        onClose={() => setOpenSnackbar(false)}
      >
        <Alert
          elevation={6}
          variant="filled"
          severity={isStatus.failed ? "error" : "success"}
        >
          <Typography color="inherit" variant="h6">
            {isStatus.msg}
          </Typography>
        </Alert>
      </Snackbar>
    </>
  );
};

export default OptionalEmailsTable;
