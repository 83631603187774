import React, { useEffect, useMemo, useRef, useState } from 'react'
import PropTypes from 'prop-types'
import { Typography, Tooltip, colors, CircularProgress } from '@material-ui/core'
import { createTheme, MuiThemeProvider } from '@material-ui/core/styles'
import { makeStyles } from '@material-ui/styles'
import { useDispatch, useSelector } from 'react-redux'
import OpenInNewIcon from '@material-ui/icons/OpenInNew';
import RoomIcon from '@material-ui/icons/Room';
import clsx from 'clsx'

const useStyles = makeStyles(theme => ({
    dragJob: {
        padding: 8,
        cursor: 'pointer',
        display: 'flex',
        alignItems: 'center',
        position: 'relative',
        borderBottom: '1px solid #cbcbcb',
        '& p': {
            whiteSpace: 'nowrap',
            width: '100%',
            overflowX: 'scroll',
            '-ms-overflow-style': 'none',  // IE and Edge
            scrollbarWidth: 'none',  // Firefox
            '&::-webkit-scrollbar': {
                display: 'none'  // Chrome, Safari and Opera
            }
        },
        '&:hover': {
            '& p': {
                color: theme.palette.white
            },
            '& .employee': {
                backgroundColor: theme.palette.white,
                '& p': {
                    color: '#263238'
                }
            },
            '& .job': {
                backgroundColor: colors.green[500],
                color: theme.palette.white,
                '& p': {
                    color: '#263238'
                }
            },
            backgroundColor: colors.green[500]
        }
    },
    customerService: {
        backgroundColor: '#ffef9f',
    },
    buttons: {
        display: 'flex',
        alignItems: 'center',
    },
    button: {
        backgroundColor: colors.green[500],
        '& p': {
            color: theme.palette.white
        }
    },
    jobLink: {
        display: 'inline-block',
        height: 20,
        padding: 0,
        color: '#263238',
        background: "transparent",
        '& i': {
            color: colors.green[500],
        }
    },
    jobMap: {
        display: 'inline-block',
        height: 23,
        marginLeft: 'auto',
        color: '#263238',
        background: "transparent",
        '& i': {
            color: colors.green[500],
        }
    },
    data: {
        display: 'block',
        lineHeight: '20px'
    },
}));

const customThemeTooltip = createTheme({
    overrides: {
        MuiTooltip: {
            tooltip: {
                fontSize: '14px',
                maxWidth: 'none'
            }
        }
    }
});

const JobItem = props => {
    const classes = useStyles();
    const selectedRef = useRef(null);
    const { isSelected, gettingDistance, source, changeSelected, locateInRoute } = props;

    const { routes } = useSelector(state => state.RoutesReducer);

    useEffect(() => {
        selectedRef && 
        selectedRef.current && 
        selectedRef.current.scrollIntoView({ block: 'center', behavior: 'smooth' });
    }, [selectedRef])

    const getValue = () => {
        const values = [];
        const idenSplits = source.totalSplitJobs > 1 ? `  (1/${source.totalSplitJobs})` : '';
        const infor = `${source.customerName}, ${source.job.estimatedHours} hours, $${source.price ? source.price.toFixed(2) : '0.00'}${idenSplits}`;
        const city = `${source.address1 || ''}`;
        const address = `${source.city || ''}, ${source.state || ''}, ${source.zipCode || ''}`;

        infor && values.push(infor)
        source.addressName && values.push(source.addressName);
        city && values.push(city)
        address && values.push(address)

        return values;
    }

    const getTooltip = () => {
        return (
            <>
                <Typography style={{ display: 'flex', justifyContent: 'space-between' }}>
                    <span>
                        {source.address1}
                    </span>
                    <span style={{ marginLeft: '.5rem' }}>
                        {source.totalSplitJobs > 1 ? `(1/${source.totalSplitJobs})` : ''}
                    </span>
                </Typography>
                <Typography>
                    {source.address1}
                </Typography>
                <Typography>
                    {source.city || ''}, ${source.price ? source.price.toFixed(2) : '0.00'}
                </Typography>
                <Typography>
                    <em>Hours: </em> {source.estimatedHours}
                </Typography>
                <Typography>
                    <em>Customer: </em> {source.customerName}
                </Typography>
                <Typography>
                    <em>Equipment: </em> {source.equipment || ''}
                </Typography>
                <Typography style={{maxWidth: '500px'}}>
                    <em>Description: </em> {(source && source.description && source.description.substr(0,Math.min(source.description.length,200))) || ''}
                </Typography>
            </>
        )
    }

    return useMemo(() => (
        <div
            ref={isSelected ? selectedRef : null}
            style={isSelected ? { backgroundColor: '#4caf50' } : null}
            className={clsx(classes.dragJob, source.isCustomerService ? classes.customerService : null)}
            onClick={changeSelected}>
            <MuiThemeProvider theme={customThemeTooltip}>
                <Tooltip arrow title={getTooltip()} enterDelay={1000}>
                    <Typography style={{ color: isSelected ? '#ffffff' : '' }}>
                        {getValue().map(t => (
                            <span key={t} className={classes.data}>{t}</span>
                        ))}
                    </Typography>
                </Tooltip>
            </MuiThemeProvider>

            <div className={classes.buttons}>

               {source.flexibleScheduling && 
                (<MuiThemeProvider theme={customThemeTooltip}>
                    <Tooltip arrow title={'Anytime Today/Flexible'}>
                        <a style={isSelected ? { color: '#ffffff', backgroundColor: '#4caf50' } : null}
                            className={`${classes.button} ${classes.jobMap} job`}
                            target='_blank'>
                            <i className="far fa-random" style={{fontSize : 20}} />
                        </a>
                    </Tooltip>
                </MuiThemeProvider>
                )}
                <MuiThemeProvider theme={customThemeTooltip}>
                    <Tooltip arrow title={'Locate On Route'}>
                        <a style={isSelected ? { color: '#ffffff', backgroundColor: '#4caf50' } : null}
                            className={`${classes.button} ${classes.jobMap} job`}
                            onClick={!gettingDistance ? locateInRoute : null}
                            target='_blank'>
                            {gettingDistance ? <CircularProgress size={24} /> : <RoomIcon style={{ fontSize: 24 }} />}
                        </a>
                    </Tooltip>
                </MuiThemeProvider>
                <MuiThemeProvider theme={customThemeTooltip}>
                    <Tooltip arrow title={'View'}>
                        <a style={isSelected ? { color: '#ffffff', backgroundColor: '#4caf50' } : null}
                            className={`${classes.button} ${classes.jobLink} job`}
                            href={`/customers/${encodeURIComponent(source.customerName)}/${source.customerId}/jobs/${source.jobId}/information`}>
                            <OpenInNewIcon style={{ fontSize: 20 }} />
                        </a>
                    </Tooltip>
                </MuiThemeProvider>
            </div>
        </div>
    ), [source, routes, isSelected, gettingDistance])
}

JobItem.propTypes = {
    isSelected: PropTypes.bool.isRequired,
    gettingDistance: PropTypes.bool.isRequired,
    source: PropTypes.object.isRequired,
    changeSelected: PropTypes.func
}

JobItem.defaultProps = {
    isSelected: false,
    gettingDistance: false,
    source: {},
    changeSelected: () => {
    }
}

export default JobItem;
