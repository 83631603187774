import {
    Button,
    Card,
    CardActions,
    CardHeader,
    colors,
    Divider,
    Modal,
} from "@material-ui/core";
import React from "react";
import {makeStyles} from "@material-ui/styles";
const useStyles = makeStyles(theme => ({
    styleModal: {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        outline: 'none',
        boxShadow: theme.shadows[20],
        width: "550px",
        maxHeight: '100%',
        overflowY: 'auto',
        maxWidth: '100%'
    },
    conButton: {
        padding: '16px',
        justifyContent: 'flex-end'
    },
    button: {
        flexShrink: '0',
        backgroundColor: colors.green[600],
        '&:hover': {
            backgroundColor: colors.green[900]
        },
    }
}))
const ModalAllEmployees = props => {
    const classes = useStyles();

    const {openConfirm, closeConfirm, onConfirm,onConfirmOne, lstEmployees} = props;

    let txtEmployees = '';
    lstEmployees.forEach(x => {
        txtEmployees += txtEmployees ? (', '+ x.firstName + ' ' + x.lastName) : (x.firstName + ' ' + x.lastName);
    });
    return (
        <Modal open={openConfirm}>
            <Card className={classes.styleModal}>
                <CardHeader style={{padding: '28px'}}
                            title={`Would you like to also change the status of (${txtEmployees}) as well.`}/>
                <Divider/>
                <CardActions className={classes.conButton}>
                    <Button
                        variant="contained"
                        size="large"
                        onClick={closeConfirm}>
                        Cancel
                    </Button>
                    <Button
                        variant="contained"
                        size="large"
                        color="primary"
                        type="submit"
                        onClick={onConfirmOne}
                        className={classes.button}
                    >
                        JUST THIS USER
                    </Button>
                    <Button
                        variant="contained"
                        size="large"
                        color="primary"
                        type="submit"
                        onClick={onConfirm}
                        className={classes.button}>
                        YES
                    </Button>
                    {/*{isSaving && <CircularProgress size={24}/>}*/}
                </CardActions>
            </Card>
        </Modal>
    )
}
export default ModalAllEmployees
