import { math } from 'azure-maps-control';

/** A control that displays a scale bar relative to the pixel resolution at the center of the map. */
export class ScaleBarControl {
  /****************************
   * Constructor
   ***************************/

  /**
    * A control that displays a scale bar relative to the pixel resolution at the center of the map.
    * @param options Options for defining how the control is rendered and functions.
    */
  constructor(options) {
    this._map = null;
    this._scaleBar = null;
    this._options = {
      units: 'imperial',
      maxBarLength: 100
    };

    this._options = Object.assign(this._options, options || {});
  }

  /****************************
   * Public Methods
   ***************************/

  /**
   * Action to perform when the control is added to the map.
   * @param map The map the control was added to.
   * @param options The control options used when adding the control to the map.
   * @returns The HTML Element that represents to control.
   */
  onAdd(map, options) {
    this._map = map;

    // Add the CSS style for the control to the DOM
    const style = document.createElement('style');
    style.innerHTML = '.azmaps-scaleBar{font-size:10px;color:black;text-align:right;flex-direction:column;line-height:1;}.azmaps-scaleBar .bar{height:3px;background-color:#4499ff;border:1px solid white;box-sizing:content-box;}';
    document.head.appendChild(style);

    const sb = document.createElement('div');
    sb.className = 'azure-maps-control-container azmaps-scaleBar';
    this._scaleBar = sb;

    this._map.events.add('move', this._updateScaleBar.bind(this));
    this._updateScaleBar();
    return sb;
  }

  /**
   * Action to perform when control is removed from the map.
   */
  onRemove() {
    if (this._map) {
      this._map.events.remove('move', this._updateScaleBar.bind(this));
      this._map = null;
    }
    if (this._scaleBar) {
      this._scaleBar.remove();
      this._scaleBar = null;
    }
  }

  /****************************
   * Private Methods
   ***************************/

  /**
   * Updates the layout of the scalebar.
   */
  _updateScaleBar() {
    if (!this._map) return;
    const camera = this._map.getCamera();

    // Get the center pixel.
    const cp = this._map.positionsToPixels([camera.center]);

    // Calculate two coordinates that are seperated by the maxBarLength pixel distance from the center pixel.
    const pos = this._map.pixelsToPositions([[0, cp[0][1]], [this._options.maxBarLength, cp[0][1]]]);

    // Calculate the strightline distance between the positions.
    let units = this._options.units?.toLowerCase();
    if (units === 'imperial') {
      units = 'miles';
    } else if (units === 'metric') {
      units = 'kilometers';
    }

    let trueDistance = math.getDistanceTo(pos[0], pos[1], units);

    // Round the true distance to a nicer number.
    let niceDistance = this._getRoundNumber(trueDistance);
    let isSmall = 0;
    if (niceDistance < 2) {
      units = this._options.units?.toLowerCase();
      if (units === 'imperial') {
        // Convert to yards.
        trueDistance *= 1760;
        niceDistance = this._getRoundNumber(trueDistance);
        isSmall = 2;
        if (niceDistance < 15) {
          // Convert to feet.
          trueDistance *= 3;
          niceDistance = this._getRoundNumber(trueDistance);
          isSmall = 1;
        }
      } else if (units === 'metric') {
        // Convert to meters.
        trueDistance *= 1000;
        niceDistance = this._getRoundNumber(trueDistance);
        isSmall = 1;
      }
    }

    // Calculate the distanceRatio between the true and nice distances and scale the scale bar size accordingly.
    const distanceRatio = niceDistance / trueDistance;

    //Update the width of the scale bar by scaling the maxBarLength option by the distance ratio.
    this._scaleBar.style.width = this._options.maxBarLength * distanceRatio + 'px';

    // Update the text of the scale bar.
    this._scaleBar.innerHTML = `<div>${this._createDistanceString(niceDistance, isSmall)}</div><div class="bar"></div>`;
  }

  /**
   * Rounds a number to a nice value.
   * @param num the number to round.
   */
  _getRoundNumber(num) {
    if (num >= 2) {
      // Convert the number to a round value string and get the number of characters.
      // Then use this to calculate the power of 10 increment of the number.
      const pow10 = Math.pow(10, (Math.floor(num) + '').length - 1);
      let i = num / pow10;

      // Shift the number to the closest nice number.
      if (i >= 10) {
        i = 10;
      } else if (i >= 5) {
        i = 5;
      } else if (i >= 3) {
        i = 3;
      } else if (i >= 2) {
        i = 2;
      } else {
        i = 1;
      }

      return pow10 * i;
    }

    return Math.round(100 * num) / 100;
  }

  /**
   * Create the string to display the distance information.
   * @param num The distance value.
   * @param isSmall Specifies if the number is a small value (meters or feet).
   */
  _createDistanceString(num, isSmall) {
    switch (this._options.units.toLowerCase()) {
      case 'feet':
      case 'foot':
      case 'ft':
        return num + ' ft';
      case 'kilometers':
      case 'kilometer':
      case 'kilometres':
      case 'kilometre':
      case 'km':
      case 'kms':
        return num + ' km';
      case 'miles':
      case 'mile':
      case 'mi':
        return num + ' mi';
      case 'nauticalmiles':
      case 'nauticalmile':
      case 'nms':
      case 'nm':
        return num + ' nm';
      case 'yards':
      case 'yard':
      case 'yds':
      case 'yrd':
      case 'yrds':
        return num + ' yds';
      case 'metric':
        if (isSmall === 1) {
          return num + ' m';
        } else {
          return num + ' km';
        }
      case 'imperial':
        if (isSmall === 2) {
          return num + ' yrds';
        } else if (isSmall === 1) {
          return num + ' ft';
        } else {
          return num + ' mi';
        }
      case 'meters':
      case 'metres':
      case 'm':
      default:
        return num + ' m';
    }
  }
}
