import React, { useState, useEffect, useRef } from 'react'
import PropTypes from 'prop-types'
import { makeStyles } from '@material-ui/styles'
import {
    Typography, TextField, colors
} from '@material-ui/core'
import { Clear } from '@material-ui/icons'

const useStyles = makeStyles(theme => ({
    selectedBox: {
        display: 'flex',
        overflowX: 'auto',
        border: '1px solid #bbbbbb',
        borderRadius: '4px',
        padding: '15px 40px 15px 14px',
        position: 'relative',
        cursor: 'pointer'
    },
    clearIcon: {
        position: 'absolute',
        top: 14,
        right: 10,
        color: '#797979',
    },
    options: {
        position: 'absolute',
        width: '100%',
        padding: '14px',
        top: '107%',
        opacity: 0,
        visibility: 'hidden',
        backgroundColor: '#ffffff',
        zIndex: 2,
        borderRadius: '4px',
        boxShadow: '0 0 20px 0 #bbbbbb'
    },
    overOptions: {
        height: '170px',
        overflowY: 'auto'
    },
    option: {
        padding: '5px',
        cursor: 'pointer',
        '&:hover': {
            backgroundColor: colors.green[600],
            color: '#ffffff'
        }
    },
}));

let mounted = false;
const SaleSelect = (props) => {
    const classes = useStyles();
    const selectRef = useRef();

    const { open, onToggle, options, selectedOptions, onChange, removeSelected, setOpenModal, fuData } = props;

    const [filText, setFilText] = useState('');
    const [filterOptions, setFilterOptions] = useState(options);

    const onChangeFilter = event => {
        const { value } = event.target;
        setFilText(value);
    }
    const removeCommission = event => {
        event.stopPropagation();
        removeSelected()
    }

    useEffect(() => {
        setFilterOptions(options);
    }, [options])

    useEffect(() => {
        const filters = options.filter(op => op.fullName.toLowerCase().indexOf(filText.toLowerCase()) > -1);
        filters.length > 0 ? setFilterOptions(filters) : setFilterOptions(options)
    }, [filText])

    useEffect(() => {
        if (open) {
            setFilText('')
        }
        const clickOutsideSelect = (event) => {
            if (open && selectRef.current && !selectRef.current.contains(event.target)) {
                onToggle();
            }
        }
        document.addEventListener('click', clickOutsideSelect);
        return () => document.removeEventListener('click', clickOutsideSelect)
    }, [open])

    return (
        <div ref={selectRef} style={{ position: 'relative' }}>
            <div className={classes.selectedBox} onClick={onToggle}>
                {Array.isArray(selectedOptions) && selectedOptions.length > 0 ?
                    <Typography>{selectedOptions.map(op => op.fullName).join(', ')}</Typography>
                    :
                    <Typography>Commission</Typography>
                }
                <Clear className={classes.clearIcon} onClick={removeCommission} />
            </div>
            <div className={classes.options} style={open ? { opacity: 1, visibility: 'visible' } : null}>
                <TextField
                    fullWidth
                    placeholder='Search commission'
                    variant='outlined'
                    value={filText}
                    onChange={onChangeFilter}
                    // onKeyDown={onKeyDown}
                />
                <div className={classes.overOptions}>
                    {filterOptions.map(op => (
                        <Typography key={op.id} className={classes.option} onClick={() => onChange(op)}
                            style={selectedOptions.findIndex(opt => opt.id === op.id) > -1 ? { display: 'none' } : null}>
                            {op.fullName}
                        </Typography>
                    ))}
                </div>
            </div>
        </div>
    )
}

SaleSelect.propTypes = {
    open: PropTypes.bool,
    onToggle: PropTypes.func,
    options: PropTypes.array,
    selectedOptions: PropTypes.array,
    onChange: PropTypes.func,
    removeSelected: PropTypes.func
}

export default SaleSelect;
