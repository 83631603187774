import React, {useEffect, useState} from 'react'
import PropTypes from 'prop-types'
import {makeStyles} from '@material-ui/styles'
import {Alert} from '@material-ui/lab'
import {
    Modal, Card, CardHeader, CardContent, CardActions,
    Grid, Typography, Button, Divider,
    colors, Snackbar, CircularProgress, FormControlLabel
} from '@material-ui/core'
import {FormErrorCallback, GreenCheckbox} from 'components'
import apiConfig from 'apiConfig'
import axios from 'utils/axios'
import {Formik} from "formik";
import {errorMessages} from 'common/constants'
import localStorage from 'utils/localStorage'
import { formatNumber } from 'utils/formatNumber'

const useStyles = makeStyles(theme => ({
    styleModal: {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        outline: 'none',
        boxShadow: theme.shadows[20],
        width: 500,
        maxHeight: '100%',
        overflowY: 'auto',
        maxWidth: '100%',
        [theme.breakpoints.down('sm')]: {
            width: 500
        },
    },
    conButton: {
        padding: '16px',
        justifyContent: 'right'
    },
    buttonSave: {
        flexShrink: '0',
        backgroundColor: colors.green[600],
        '&:hover': {
            backgroundColor: colors.green[900]
        },
    },
    lineItem: {
        display: 'flex',
        alignItems: 'center',
        marginLeft: -20,
        paddingLeft: 20,
        borderRadius: 5,
        '&:hover': {
            background: 'whitesmoke !important'
        }
    }
}))

const CopyJobModal = props => {
    const classes = useStyles();
    const {setTitle, setNewJob, open, onCloseModal, job, setShowModalCopy, setReloadJobs} = props;

    const [initValue, setInitValue] = useState({
        hasPurchaseOrder: false,
        hasJobSiteNote: false,
        hasDiscount: false,
        hasSalesperson: false,
        hasAttachments: true,
        hasFlexibleScheduling: false,
        prices: {}
    });
    const [isStatus, setIsStatus] = useState({failed: false, msg: ''});
    const [openSnackbar, setOpenSnackBar] = useState(false);
    const [isProgress, setIsProgress] = useState(false);
    const [loading, setLoading] = useState(false);
    const [jobItems, setJobItems] = useState();
    const fullInfo = localStorage.getFullInfo();

    const fetchJobItems = () => {
        setLoading(true);
        axios.get(apiConfig.url.BASE_URL + apiConfig.url.CUSTOMER_PRICE_ITEMS.replace('{jobId}', job.id), {
            params:
                {
                    sortBy: 'Quantity',
                    sortType: 'ASC',
                }
        })
            .then(res => {
                setJobItems(res.data);
            })
            .catch(err => console.log(err))
            .finally(() => {
                setLoading(false);
            })
    }

    useEffect(() => {
        if (job && job.jobItems) {
            setJobItems(job.jobItems);
        } else if(job && job.id) {
            fetchJobItems();
        }
    }, [job])

    useEffect(() => {
        if (jobItems) {
            const companyPriceItems = jobItems?.filter(item => item.companyPriceItemId) ?? [];
            if (!companyPriceItems.length) return;
            setLoading(true);
            axios.get(apiConfig.url.BASE_URL + apiConfig.url.COMPANY_PRICES_ITEM.replace('{companyId}', fullInfo.cid), {
                params: { pageSize: 9999 }
            })
                .then(value => {
                    let _initValue = { ...initValue, prices: {} };
                    for (const item of companyPriceItems) {
                        const companyPriceItem = value.data.data.find(x => x.id === item.companyPriceItemId);
                        item.companyPriceItem = companyPriceItem;
                        if (companyPriceItem?.active && Number(item.price) !== Number(companyPriceItem.price)) {
                            _initValue = {
                                ..._initValue,
                                prices: {
                                    ..._initValue.prices,
                                    [item.id]: Number(item.price)
                                }
                            };
                        }
                    }
                    setInitValue(_initValue);
                })
                .finally(() => setLoading(false));
        }
    }, [jobItems]);

    return (
        <>
            <Modal open={open}>
                <div>
                    <Formik
                        initialValues={initValue}
                        enableReinitialize
                        onSubmit={values => {
                            setIsProgress(true);
                            const data = { ...values,
                                jobId : job.id,
                            }
                            axios.post(apiConfig.url.BASE_URL + apiConfig.url.CUSTOMER_JOB_COPY.replace('{jobId}', job.id), data)
                                .then(res => {
                                    setTitle('The selected job has been copied.')
                                    setNewJob(res.data);
                                    setShowModalCopy(true);
                                    onCloseModal();
                                    setReloadJobs();
                                })
                                .catch((ex) => {
                                    setIsStatus({failed: true, msg: 'Copy failed, please try again later'});
                                    setOpenSnackBar(true);
                                })
                                .finally(() => setIsProgress(false))
                        }}
                    >
                        {({
                              values,
                              submitCount,
                              isValid,
                              setFieldValue,
                              handleSubmit,
                              resetForm,
                              submitForm
                          }) => (
                            <form onSubmit={handleSubmit}>
                                <FormErrorCallback
                                    submitCount={submitCount}
                                    isValid={isValid}
                                    onSubmissionError={() => {
                                        setIsStatus({failed: true, msg: errorMessages.FIELD_CHECK});
                                        setOpenSnackBar(true);
                                    }}
                                />
                                <Card className={classes.styleModal} style={ Object.keys(values.prices).length ? { width: 700 } : null }>
                                    <CardHeader title='Which fields would you like to copy?'/>
                                    <Divider/>
                                    <CardContent>
                                    {loading
                                        ? <CircularProgress size={30} />
                                        : <>
                                            <Grid item xs={12}>
                                                <FormControlLabel
                                                    name='hasPurchaseOrder'
                                                    className={classes.taxCheckbox}
                                                    label='Purchase Order Number'
                                                    checked={values.hasPurchaseOrder || false}
                                                    control={
                                                        <GreenCheckbox/>
                                                    }
                                                    onChange={(event) => setFieldValue('hasPurchaseOrder', event.target.checked)}
                                                />
                                            </Grid>
                                            <Grid item xs={12}>
                                                <FormControlLabel
                                                    className={classes.taxCheckbox}
                                                    name={'hasJobSiteNote'}
                                                    label='Today Only Notes'
                                                    checked={values.hasJobSiteNote || false}
                                                    control={
                                                        <GreenCheckbox/>
                                                    }
                                                    onChange={(event) => setFieldValue('hasJobSiteNote', event.target.checked)}
                                                />
                                            </Grid>
                                            <Grid item xs={12}>
                                                <FormControlLabel
                                                    className={classes.taxCheckbox}
                                                    name={'hasDiscount'}
                                                    label='Discount'
                                                    checked={values.hasDiscount || false}
                                                    control={
                                                        <GreenCheckbox/>
                                                    }
                                                    onChange={(event) => setFieldValue('hasDiscount', event.target.checked)}
                                                />
                                            </Grid>
                                            <Grid item xs={12}>
                                                <FormControlLabel
                                                    className={classes.taxCheckbox}
                                                    name={'hasSalesperson'}
                                                    label='Commissioned Salesperson'
                                                    checked={values.hasSalesperson || false}
                                                    control={
                                                        <GreenCheckbox/>
                                                    }
                                                    onChange={(event) => setFieldValue('hasSalesperson', event.target.checked)}
                                                />
                                            </Grid>
                                            <Grid item xs={12}>
                                                <FormControlLabel
                                                    className={classes.taxCheckbox}
                                                    name={'hasAttachments'}
                                                    label='Jobsite Attachments'
                                                    checked={values.hasAttachments || false}
                                                    control={
                                                        <GreenCheckbox/>
                                                    }
                                                    onChange={(event) => setFieldValue('hasAttachments', event.target.checked)}
                                                />
                                            </Grid>
                                            {
                                                !!Object.keys(values.prices).length && (
                                                    <>
                                                        <Grid item xs={12}>
                                                            <ul>
                                                                <li style={{ listStyleType: 'none' }}>
                                                                    <div style={{ display: 'flex' }}>
                                                                        <div style={{ flex: 1, fontWeight: 500 }}>Items</div>
                                                                        <div style={{ flexBasis: 120, fontWeight: 500 }}>Job Item Price</div>
                                                                        <div style={{ flexBasis: 130, fontWeight: 500 }}>Master Item Price</div>
                                                                    </div>
                                                                </li>
                                                                {jobItems.map(item => (
                                                                    <li key={item.id} style={{ marginLeft: 20 }}>
                                                                        <div
                                                                            className={classes.lineItem}
                                                                            style={
                                                                                item.companyPriceItem && Number(item.price) !== Number(item.companyPriceItem.price)
                                                                                    ? { background: 'lemonchiffon' }
                                                                                    : null
                                                                            }>
                                                                            <div style={{ flex: '1', marginRight: 10 }}>{item.description}</div>
                                                                            <div style={{ flexBasis: 120 }}>
                                                                                <FormControlLabel
                                                                                    name={`prices.${item.id}`}
                                                                                    label={'$' + formatNumber(item.price, 2)}
                                                                                    checked={values.prices[item.id] === Number(item.price) || !values.prices.hasOwnProperty(item.id)}
                                                                                    control={
                                                                                        <GreenCheckbox/>
                                                                                    }
                                                                                    onChange={(event) => setFieldValue('prices', { ...values.prices, [item.id]: Number(item.price) })}
                                                                                />
                                                                            </div>
                                                                            <div style={{ flexBasis: 130 }}>
                                                                                {item.companyPriceItem &&
                                                                                    <FormControlLabel
                                                                                        disabled={Number(item.price) === Number(item.companyPriceItem.price)}
                                                                                        name={`prices.${item.id}`}
                                                                                        label={'$' + formatNumber(item.companyPriceItem.price, 2)}
                                                                                        checked={values.prices[item.id] === Number(item.companyPriceItem.price)}
                                                                                        control={
                                                                                            <GreenCheckbox/>
                                                                                        }
                                                                                        onChange={(event) => setFieldValue('prices', { ...values.prices, [item.id]: Number(item.companyPriceItem.price) })}
                                                                                    />
                                                                                }
                                                                            </div>
                                                                        </div>
                                                                    </li>
                                                                ))}
                                                            </ul>
                                                        </Grid>
                                                    </>
                                                )
                                            }
                                            <Grid item xs={12}>
                                                <FormControlLabel
                                                    className={classes.taxCheckbox}
                                                    name={'hasFlexibleScheduling'}
                                                    label='Anytime Today/Flexible'
                                                    checked={values.hasFlexibleScheduling || false}
                                                    control={
                                                        <GreenCheckbox/>
                                                    }
                                                    onChange={(event) => setFieldValue('hasFlexibleScheduling', event.target.checked)}
                                                />
                                            </Grid>
                                        </>
                                    }
                                    </CardContent>
                                    <Divider/>
                                    <CardActions className={classes.conButton}>
                                            <Button
                                                variant="contained"
                                                size="large"
                                                onClick={() => {
                                                    onCloseModal()
                                                }}>
                                                Close
                                            </Button>
                                            <Button
                                                style={{marginLeft: '6px'}}
                                                disabled={isProgress}
                                                variant="contained"
                                                color="primary"
                                                size="large"
                                                onClick={() => {
                                                    submitForm().then(r => {
                                                    })
                                                }}
                                                className={classes.buttonSave}>
                                                Copy & Schedule
                                            </Button>
                                            {isProgress && <CircularProgress size={24}/>}
                                    </CardActions>
                                </Card>
                            </form>
                        )}
                    </Formik>
                </div>
            </Modal>
            <Snackbar anchorOrigin={{vertical: 'top', horizontal: 'center'}}
                      open={openSnackbar}
                      autoHideDuration={3000}
                      onClose={() => setOpenSnackBar(false)}>
                <Alert elevation={6} variant="filled" severity={isStatus.failed ? 'error' : 'success'}>
                    <Typography
                        color="inherit"
                        variant="h6">
                        {isStatus.msg}
                    </Typography>
                </Alert>
            </Snackbar>
        </>
    )
}
CopyJobModal.propTypes = {
    onCloseModal: PropTypes.func.isRequired,
    onLoad: PropTypes.func.isRequired,
}
CopyJobModal.defaultProps = {
    onCloseModal: () => {
    },
    onLoad: () => {
    },
}
export default CopyJobModal;
