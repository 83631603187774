import React, { useState, useEffect, useRef } from 'react';
import { useHistory } from 'react-router-dom'
import { makeStyles } from '@material-ui/styles';
import {
    Grid,
    Button,
    colors,
    Typography,
} from '@material-ui/core';
import axios from 'utils/axios';
import apiConfig from 'apiConfig';
import Snackbar from '@material-ui/core/Snackbar';
import Alert from '@material-ui/lab/Alert';
import CircularProgress from '@material-ui/core/CircularProgress';
import { Formik, Form } from 'formik';
import * as Yup from 'yup';
import { User } from './components/InitData'
import { Page, FormErrorCallback } from 'components';
import { Compensation, Information, Security } from '../AddUser/components';
import { isDate } from "moment";
import { errorMessages } from 'common/constants'

const useStyles = makeStyles(theme => ({
    root: {
        maxWidth: '100%',
        padding: theme.spacing(4),
        height: '100%',
        overflowY: 'auto',
    },
    circularProgress: {
        marginTop: '30px'
    },
    dateField: {
        '& + &': {
            marginLeft: theme.spacing(2)
        }
    },
    saveBtnWrapper: {
        [theme.breakpoints.down('sm')]: {
            textAlign: 'center',
        },
        '& button': {
            color: theme.palette.white,
            backgroundColor: colors.green[600],
            '&:hover': {
                backgroundColor: colors.green[900]
            },
            marginTop: '10px'
        }
    },
    removePhoneBtn: {
        marginTop: '8px',
    },
    snackBar: {
        width: '100%',
        '& > * + *': {
            marginTop: theme.spacing(2),
        },
    },
    stickyBtn: {
        marginBottom: 0,
        position: '-webkit-sticky',
        position: 'sticky',
        backgroundColor: '#f4f6f8',
        top: '-35px',
        zIndex: 2,
    },
    saveBtn: {
        color: theme.palette.white,
        backgroundColor: colors.green[600],
        '&:hover': {
            backgroundColor: colors.green[900]
        }
    }
}));

const validationSchema = Yup.object().shape({
    firstName: Yup.string().required('Required.'),
    lastName: Yup.string().required('Required.'),
    address: Yup.object({
        address1: Yup.string().required('Required.'),
        city: Yup.string().required('Required.'),
        state: Yup.object({
            id: Yup.string().required('Required.')
        }),
        zipCode: Yup.string()
            .required('Required.')
            .matches(/(^\d{5}$)|(^\d{5}-\d{4}$)/, 'Zip code is invalid.')
    }),
    email: Yup.string()
        .required('Required.')
        .email('Email is invalid.'),
    phones: Yup.array()
        .of(Yup.object({
            phoneNumber: Yup.string()
                .required('Required.')
                .matches(/\W\d{3}\W\s\w{3}\W\w{4}$|\W\d{3}\W\s\w{3}\W\w{4}x\d{1,6}|\W\d{3}\W\s\w{3}\W\w{4}x/, 'Invalid phone number.')
        })),
    //hiredDate: Yup.string().nullable().required('Required.'),
    security: Yup.object({
        // username: Yup.string().required('Required.'),
        password: Yup.string().required('Required.'),
        passwordConfirm: Yup.string()
            .required('Required.')
            .when('password', {
                is: val => (val && val.length > 0),
                then: Yup.string()
                    .oneOf(
                        [Yup.ref("password")],
                        "Passwords don't match"
                    )
            }),
        roleIds: Yup.array().of(Yup.string().required('Required.'))
    }),
    salaries: Yup.object().shape({
        salary: Yup.string().nullable()
            .when('salaryCheckBox', {
                is: true,
                then: Yup.string().required('This field is required.'),
            }),
        hourly: Yup.string().nullable()
            .when('hourlyCheckBox', {
                is: true,
                then: Yup.string().required('This field is required.'),
            }),
        laborPercent: Yup.string().nullable()
            .when('laborCommission', {
                is: true,
                then: Yup.string().required('This field is required.'),
            }),
        salePercentNewSale: Yup.string().nullable()
            .when('saleCommission', {
                is: true,
                then: Yup.string().required('This field is required.'),
            }),
        salePercentRetSale: Yup.string().nullable()
            .when('saleCommission', {
                is: true,
                then: Yup.string().required('This field is required.'),
            }),
        upsalePercent: Yup.string().nullable()
            .when('upsaleCommission', {
                is: true,
                then: Yup.string().required('This field is required.'),
            }),
        startDate: Yup.date()
            .typeError('Invalid date.')
            .when(
                'endDate',
                (endDate, schema) => {
                    let d = new Date(endDate)
                    if (endDate && !isNaN(d.getTime()) && isDate(d)) {
                        // d = d.setDate(d.getDate())
                        console.log(d)
                        return schema.max(new Date(d), 'Start date cannot be after the end date.')
                    }
                    return schema;
                },
            ).when(['salaryCheckBox', 'hourlyCheckBox', 'laborCommission', 'saleCommission', 'upsaleCommission'], {
                is: (a, b, c, d) => a || b || c || d,
                then: Yup.date().required('This field is required.'),
            })
            .nullable(),
        endDate: Yup.date()
            .typeError('Invalid date.')
            .when(
                'startDate',
                (startDate, schema) => {
                    let d = new Date(startDate)
                    if (startDate && !isNaN(d.getTime()) && isDate(d)) {
                        // d = d.setDate(d.getDate())
                        return schema.min(new Date(d), 'End date cannot be less than start date.')
                    }
                    return schema;
                },
            )
            .nullable()
    }, ['startDate', 'endDate'])
});
let _avatarFormData;
let userAvatar = null;
let userUploadFile = null;

const AddUser = props => {

    const classes = useStyles();
    const history = useHistory();
    const isMounted = useRef(false);
    const addressInputRef = useRef();

    const [openSnackbar, setOpenSnackBar] = useState(false);
    const [isSaving, setIsSaving] = useState(false);
    const [isStatus, setIsStatus] = useState({ failed: false, msg: '' });
    const [isRefresh, setIsRefresh] = useState(false);
    const [errorHourlyRate, setErrorHourlyRate] = useState(false);
    const [errorLaborCommission, setErrorLaborCommission] = useState(false);
    const [formData, setFormData] = useState(User);
    const [roles, setRoles] = useState();

    const onChangeAvatar = (avatar, image) => {
        if (avatar) {
            userAvatar = avatar;
            userUploadFile = image;
        } else {
            userAvatar = null;
            userUploadFile = null;
            _avatarFormData = null;
        }
    }
    const submitAvatar = (userId) => {
        if (userAvatar) {
            userAvatar.getImageScaledToCanvas().toBlob((blob) => {
                _avatarFormData = new FormData()
                _avatarFormData.append("file", blob, userUploadFile.name);
                _avatarFormData.append('id', userId);
                axios.put(apiConfig.url.USER_URL + apiConfig.url.EMPLOYEE_ADD_IMAGE, _avatarFormData)
                    .then(res => {
                        onChangeAvatar();
                    })
                    .catch(err => {
                        console.log(err);
                    });
            }, userUploadFile.type, 100)
        }
    }

    const closeSnackbar = () => {
        setOpenSnackBar(false);
    };

    const validateCompensation = (values) => {
        if (values.salaries && values.salaries.hourlyCheckBox && (!values.salaries.hourlyAllHours && !values.salaries.hourlySpecificHours)) {
            setErrorHourlyRate(true);
        }
        if (values.salaries && values.salaries.laborCommission && (!values.salaries.laborCommissionAllJobs && !values.salaries.laborCommissionSpecificJobs)) {
            setErrorLaborCommission(true);
        }
    }

    const fetchUser = () => {
        axios.get(apiConfig.url.USER_URL + apiConfig.url.EMPLOYEE_ADD_GET).then(res => {
            if (isMounted.current) {
                setFormData({
                    ...formData,
                    phones: [{
                        id: "00000000-0000-0000-0000-000000000000",
                        formattedPhoneNumber: "",
                        phoneNumber: "",
                        type: null,
                        typeId: res.data.phoneTypeOptions[0].id
                    }],
                    languageOptions: res.data.languageOptions,
                    states: res.data.stateOptions,
                    phoneTypes: res.data.phoneTypeOptions
                });
            }
        });
    };

    const fetchRoles = () => {
        axios.get(apiConfig.url.AUTH_URL + apiConfig.url.EMPLOYEE_ROLES).then(res => {
            isMounted.current && setRoles(res.data.roles)
        })
    };

    const addUser = (values) => {
        axios.put(apiConfig.url.USER_URL + apiConfig.url.EMPLOYEE_ADD, values)
            .then(res => {
                localStorage.setItem('isCreateUser', true);
                //submit avatar and don't wait for it
                submitAvatar(res.data.id);
                const fullName = `${values.firstName} ${values.lastName}`;
                history.push(`/employees/${encodeURIComponent(fullName)}/${res.data.id}/information`)
            })
            .catch((err) => {
                const message = err.response.data ? err.response.data.messages : null;
                setIsStatus({ failed: true, msg: message ? message.username : 'An error occurred, please try again later' });
                setOpenSnackBar(true);
            })
            .finally(e => setIsSaving(false));
    }

    useEffect(() => {
        isMounted.current = true;
        fetchUser();
        fetchRoles();

        return () => isMounted.current = false;
    }, [])

    if (!formData.phoneTypes || !roles) {
        return (
            <Grid
                container
                spacing={0}
                align="center"
                justifyContent="center"
                direction="column">
                <Grid item>
                    <CircularProgress className={classes.circularProgress} size={50} />
                </Grid>
            </Grid>

        )
    }

    return (
        <Page
            className={classes.root}
            title="Add New Employee"
        >

            <Formik
                enableReinitialize
                initialValues={formData}
                validationSchema={validationSchema}
                onSubmit={(values, { resetForm }) => {
                    if (!values.security.roleIds || values.security.roleIds.length === 0) {
                        setIsStatus({ failed: true, msg: 'You must specify at least one role' });
                        setOpenSnackBar(true);
                        return;
                    }
                    
                    if (values.salaries && values.salaries.hourlyCheckBox && (!values.salaries.hourlyAllHours && !values.salaries.hourlySpecificHours)) {
                        setErrorHourlyRate(true);
                        return;
                    }
                    if (values.salaries && values.salaries.laborCommission && (!values.salaries.laborCommissionAllJobs && !values.salaries.laborCommissionSpecificJobs)) {
                        setErrorLaborCommission(true);
                        return;
                    }
                    if (!values.salaries.salaryCheckBox && !values.salaries.hourlyCheckBox && !values.salaries.laborCommission && !values.salaries.saleCommission && !values.salaries.upsaleCommission) {
                        values = { ...values, salaries: null }
                    }
                    setIsSaving(true);
                    const { coordinate, isValid, forceSave, verifyFn } = addressInputRef.current;
                    if (isValid || forceSave) {
                        values = {
                            ...values,
                            address: {
                                ...values.address,
                                latitude: coordinate?.latitude,
                                longitude: coordinate?.longitude
                            }
                        };
                        addUser(values)
                    } else {
                        verifyFn(true).then(data => data ? addUser(data) : setIsSaving(false))
                    }
                }}
            >
                {({
                    values,
                    errors,
                    touched,
                    submitCount,
                    isValid,
                    handleChange,
                    setFieldValue,
                    setFieldTouched,
                    handleSubmit,
                }) => (
                    <Form onSubmit={handleSubmit}>
                        <FormErrorCallback
                            submitCount={submitCount}
                            isValid={isValid}
                            onSubmissionError={() => {
                                setIsStatus({ failed: true, msg: errorMessages.FIELD_CHECK });
                                setOpenSnackBar(true);
                            }}
                        />
                       <Grid className={classes.stickyBtn} container spacing={4}>
                                <Grid item xs={12}>
                                    <Button
                                        onClick={() => validateCompensation(values)}
                                        className={classes.saveBtn}
                                        type="submit"
                                        disabled={isSaving}
                                        variant="contained">
                                        Save
                                    </Button>
                                    {isSaving && <CircularProgress size={24} />}
                                </Grid>
                        </Grid>
                        <Information
                            isRefresh={isRefresh}
                            values={values}
                            errors={errors}
                            touched={touched}
                            setFieldTouched={setFieldTouched}
                            setFieldValue={setFieldValue}
                            handleChange={handleChange}
                            onChangeAvatar={onChangeAvatar}
                            addressInputRef={addressInputRef}
                        />
                        <Security
                            roles={roles}
                            values={values}
                            errors={errors}
                            touched={touched}
                            setFieldTouched={setFieldTouched}
                            setFieldValue={setFieldValue}
                        />

                        <Compensation
                            values={values.salaries || {}}
                            errors={errors.salaries || {}}
                            touched={touched.salaries || {}}
                            setFieldTouched={setFieldTouched}
                            setFieldValue={setFieldValue}
                            errorHourlyRate={errorHourlyRate}
                            errorLaborCommission={errorLaborCommission}
                            setErrorHourlyRate={setErrorHourlyRate}
                            setErrorLaborCommission={setErrorLaborCommission}
                        />

                        
                    </Form>
                )}
            </Formik>

            <div className={classes.snackBar}>
                <Snackbar anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'center'
                }} open={openSnackbar}
                    autoHideDuration={8000}
                    onClose={closeSnackbar}
                >
                    <Alert
                        elevation={6} variant="filled" severity={isStatus.failed ? "error" : "success"}>
                        <Typography
                            color="inherit"
                            variant="h6">
                            {isStatus.msg}
                        </Typography>
                    </Alert>
                </Snackbar>
            </div>
        </Page>
    );
};

export default AddUser;
