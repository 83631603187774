import React from 'react'
import { Grid, TextField, Button, IconButton } from '@material-ui/core'
import { makeStyles } from '@material-ui/styles'
import useMediaQuery from '@material-ui/core/useMediaQuery'
import DeleteIcon from '@material-ui/icons/Delete';
import NumberFormat from 'react-number-format';

const useStyles = makeStyles(theme => ({
    root: {},
    removeButton: {
        [theme.breakpoints.down('xs')]: {
            display: 'none'
        }
    },
    cssPhone: {
        flex: 1
    },
    removeIcon: {
        display: 'none',
        padding: 0,
        marginLeft: -12,
        [theme.breakpoints.down('xs')]: {
            display: 'block'
        }
    }
}))

const Phone = props => {

    const classes = useStyles();
    const xs = useMediaQuery(theme => theme.breakpoints.down('xs'));

    const { index, length, phone, errors, touched, setFieldTouched, phoneTypes, onPhoneRemove, onPhoneUpdate } = props;

    return (
        <Grid item xs={12}>
            <Grid container spacing={3}>
                <Grid
                    item
                    className={classes.cssPhone}>
                    <TextField
                        fullWidth
                        label="Phone Type"
                        name="typeId"
                        onChange={onPhoneUpdate}
                        select
                        SelectProps={{ native: true }}
                        variant="outlined">
                        {phoneTypes.map(pt => (
                            <option
                                key={pt.id}
                                value={pt.id}
                            >
                                {pt.type}
                            </option>
                        ))}
                    </TextField>
                </Grid>

                <Grid
                    item
                    xs={6}>
                    <NumberFormat
                        customInput={TextField}
                        fullWidth
                        error={(errors.phones && errors.phones[index] && touched.phones && touched.phones[index]) ? true : false}
                        helperText={(errors.phones && errors.phones[index] && touched.phones && touched.phones[index]) && errors.phones[index].phoneNumber}
                        label="Phone Number"
                        name="phoneNumber"
                        onBlur={() => {
                            const phones = touched.phones ? [...touched.phones] : [];
                            phones[index] = { phoneNumber: true };
                            setFieldTouched('phones', phones);
                        }}
                        onChange={onPhoneUpdate}
                        value={phone.phoneNumber}
                        variant="outlined"
                        format="(###) ###-####x######"
                    />

                </Grid>

                {length > 1 && <Grid
                        item
                        style={{alignSelf: 'center'}}
                        xs={'auto'}>
                            <IconButton
                                onClick={onPhoneRemove}
                                className={classes.removeIcon}>
                                <DeleteIcon fontSize="inherit" />
                            </IconButton>
                            <Button className={classes.removeButton} onClick={onPhoneRemove} color="primary" variant="outlined">Remove</Button>
                    </Grid>
                }
            </Grid>
        </Grid>
    )
}

export default Phone;