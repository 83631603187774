import React from 'react'
import PropTypes from 'prop-types'
import { useFormikContext } from 'formik';

const FormErrorCallback = props => {
    const { submitCount, isValid, onSubmissionError } = props;
    const formik = useFormikContext();
    const effect = () => {
        if (submitCount > 0 && !isValid) {
            onSubmissionError(formik.errors);
        }
    };
    React.useEffect(effect, [submitCount]);
    return null;
}

FormErrorCallback.propTypes = {
    submitCount: PropTypes.number.isRequired,
    isValid: PropTypes.bool.isRequired,
    onSubmissionError: PropTypes.func
}

FormErrorCallback.defaultProps = {
    onSubmissionError: () => { }
}

export default FormErrorCallback