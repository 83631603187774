import axios from 'utils/axios';

function get(url) {
  return axios.get(url, {
    responseType: "arraybuffer"
  })
    .then(response => {
      var prefix = "data:" + response.headers["content-type"] + ";base64,";
      var img = Buffer.from(response.data, 'binary').toString('base64');
      return prefix + img;
    })

}

export default { get: get };
