import React, {useEffect, useState} from 'react'
import {
    Typography, colors, Snackbar, Card, CardHeader, CardContent, Divider, Collapse,
    Table, TableBody, TableCell, TableHead, TableRow, IconButton, Tooltip, TableFooter
} from '@material-ui/core'
import {AddBox, ArrowDownward, ArrowUpward, ExpandMore} from '@material-ui/icons'
import {makeStyles} from '@material-ui/styles'
import axiosNoAuth from 'utils/axiosNoAuth'
import { Alert } from '@material-ui/lab'
import moment from 'moment';
import GetAppIcon from '@material-ui/icons/GetApp';
import AddAttachment from './AddAttachment'
import axios from "../../../utils/axios";
import apiConfig from "../../../apiConfig";
import {Pagination} from "../../../components";
const useStyles = makeStyles(theme => ({
    attachments: {
        marginBottom: 14,
        borderRadius: 4,
        border: '1px solid #c7c7c7',
        position: 'relative',
    },
    overAttachments: {
        height: 108,
        overflowY: 'auto',
        '&::-webkit-scrollbar': {
            width: '5px'
        },
        '&::-webkit-scrollbar-track': {
            backgroundColor: '#e4e4e4'
        },
        '&::-webkit-scrollbar-thumb': {
            backgroundColor: '#9c9c9c',
            borderRadius: '60px'
        }
    },
    label: {
        position: 'absolute',
        top: -10,
        left: 8,
        backgroundColor: '#ffffff',
        fontSize: 12,
        paddingLeft: 4,
        lineHeight: '14px'
    },
    attachment: {
        padding: '4px 8px',
        display: 'block',
        fontWeight: 500,
        borderBottom: '1px solid #c7c7c7',
        cursor: 'pointer',
        '&:hover': {
            backgroundColor: colors.green[600],
            color: '#ffffff'
        }
    },
    textField: {marginTop: 14},
    openImage: {
        color: "#000000"
    },
    expiredName: {
        color: '#e60216'
    },
    fab: {
       width: '36px',
       height: '36px'
    },
    addStyle: {
        '& span svg': {
            color: colors.green[600],
            '&:hover': {
                color: colors.green[900]
            }
        }
    },
    paginate: {
        marginTop: theme.spacing(3),
        display: 'flex',
        justifyContent: 'center'
    },
    customTableCell: {
        borderBottom: '1px solid #969696',
        cursor: 'pointer',
        '& span': {
            display: 'flex',
            cursor: 'pointer',
        }
    },
    iconSort: {
        marginBottom: '-5px',
        marginLeft: '10px'
    }
}));
let pageNumber = 1;
let forcePage = 0;
const JobAttachments = ({job, onAddAttachment, isEnableTodayRoute}) => {
    const classes = useStyles();

    
    const [isSuccess, setIsSuccess] = useState({ failed: false, msg: '' });
    const [openSnack, setOpenSnack] = useState(false);
    const [expand, setExpand] = useState(false);
    const [openEdit, setOpenEdit] = useState(false);
    const [attachment, setAttachment] = useState([]);
    const [sortBy, setSortBy] = useState('AttachmentType');
    const [sortType, setSortType] = useState('ASC');
    const [pageCount, setPageCount] = useState(0);
    
    const handleEditOpen = () => {
        setOpenEdit(true);
    }
    const handleEditClose = () => {
        setOpenEdit(false);
    }

    const getDocument = () => {
        if(isEnableTodayRoute != null && isEnableTodayRoute){
            axios.get(apiConfig.url.BASE_URL + apiConfig.url.TODAY_ROUTE_DOCUMENT_NEW.replace('{customerId}', job.customerId), {
                params:
                    {
                        sortBy: sortBy,
                        sortType: sortType,
                        limit: 0,
                        pageSize: 24,
                        pageNumber: pageNumber,
                    }
            })
                .then(res => {
                    setAttachment(res.data.data);
                    setPageCount(res.data.pageCount);
                })
        } else {
            axios.get(apiConfig.url.BASE_URL + apiConfig.url.TODAY_ROUTE_DOCUMENT.replace('{customerId}', job.customerId))
                .then(res => {
                    setAttachment(res.data);
                })
        }
    }

    const onDownloadFile = (url, fileName) => {
        axiosNoAuth.get(url, {
            headers: {},
            responseType: 'blob',
        })
            .then((response) => {
                const url = window.URL.createObjectURL(new Blob([response.data]));
                const link = document.createElement('a');
                link.href = url;
                link.setAttribute('download', fileName);
                document.body.appendChild(link);
                link.click();
            })
            .catch(() => {
                setIsSuccess({ failed: true, msg: 'Download file failed.' });
                setOpenSnack(true);
            })
    }
    const loadDocument = (type) => {
        setSortBy(type)
        setSortType(type == sortBy ? (sortType === 'ASC' ? 'DESC' : 'ASC') : 'ASC');
        pageNumber = 1;
        forcePage = 0;
    }
    useEffect(() => {
        getDocument();
    }, [sortBy, sortType])

    const onPageChange = (page) => {
        pageNumber = page.selected + 1;
        forcePage = page.selected;
        getDocument();
    };
    const getSortIcon = (type) => {
        return (
            sortBy == type &&
            (
                sortType == 'ASC' ?
                    <ArrowUpward className={classes.iconSort}/>
                    :
                    <ArrowDownward className={classes.iconSort}/>
            )
        )
    }
    return (
        <>
            <Card>
                <CardHeader title='Attachments'
                    className={classes.expandHead}
                    action={<ExpandMore />}
                    onClick={() => setExpand(!expand)} />
                <Divider />
                <Collapse in={expand} timeout='auto' unmountOnExit>
                    <CardContent style={{ display: 'flex' }}>
                    <Table className={classes.table} aria-label="simple table">
                        <TableHead className={classes.customTableHead}>
                            <TableRow className={classes.cusFontsize}>
                                <TableCell onClick={() => loadDocument('AttachmentType')} className={classes.customTableCell}><span>Attachment Type{ getSortIcon('AttachmentType')}</span></TableCell>
                                <TableCell onClick={() => loadDocument('FileName')} className={classes.customTableCell}><span>File Name{ getSortIcon('FileName')}</span></TableCell>
                                <TableCell onClick={() => loadDocument('Note')} className={classes.customTableCell}><span>Note{ getSortIcon('Note')}</span></TableCell>
                                <TableCell onClick={() => loadDocument('UploadedBy')} className={classes.customTableCell}><span>Uploaded By{ getSortIcon('UploadedBy')}</span></TableCell>
                                <TableCell onClick={() => loadDocument('DateUploaded')} className={classes.customTableCell}><span>Date Uploaded{ getSortIcon('DateUploaded')}</span></TableCell>
                                <TableCell onClick={() => loadDocument('ExpirationDate')} className={classes.customTableCell}><span>Expiration Date{ getSortIcon('ExpirationDate')}</span></TableCell>
                                <TableCell onClick={() => loadDocument('Size')} className={classes.customTableCell}><span>Size{ getSortIcon('Size')}</span></TableCell>
                                <TableCell style={{ width: '1%', textAlign: 'center' }} className={classes.customTableCell}>
                                    <Tooltip title="Add Job Attachment">
                                        <IconButton onClick={handleEditOpen} className={classes.addStyle}>
                                            <AddBox fontSize='large' />
                                        </IconButton>
                                    </Tooltip>
                                    
                                </TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {attachment && attachment.length > 0 ? attachment.map((file, index) => (
                                <>
                                {console.log(file)}
                                <TableRow key={file.Id}>
                                    <TableCell>{file.attachmentType}</TableCell>
                                    <TableCell>
                                        <div className={classes.thumbnail}>
                                            <a className={classes.openImage} href={file.path} target="_blank">
                                                {file.fileName}
                                                {file.expirationDate && moment(file.expirationDate).startOf('day') <= moment().startOf('day') ? <span className={classes.expiredName}> - Expired</span> : ""}
                                            </a>
                                        </div>
                                    </TableCell>
                                    <TableCell>{file.note}</TableCell>
                                    <TableCell>{file.uploadedBy}</TableCell>
                                    <TableCell>{moment(file.dateUploaded).format('MM/DD/YYYY')}</TableCell>
                                    <TableCell>{file.expirationDate ? moment(file.expirationDate).format('MM/DD/YYYY') : ""}</TableCell>
                                    <TableCell>{file.sizeM}</TableCell>
                                    <TableCell className={classes.actionTable}>
                                        <IconButton aria-label="download" onClick={() => onDownloadFile(file.path, file.fileName)}>
                                            <GetAppIcon />
                                        </IconButton>
                                    </TableCell>
                                </TableRow>
                                </>
                                
                            )) : (
                                <TableRow>
                                    <TableCell colSpan={7}>
                                        <Typography className={classes.notifSearch}>No attachments found.</Typography>
                                    </TableCell>
                                </TableRow>
                            )}
                        </TableBody>
                        <TableFooter>
                            <TableRow>
                                <TableCell colSpan={7}>
                                    {
                                        <div style={pageCount === 0 ? { display: 'none' } : null} className={classes.paginate}>
                                            <Pagination pageCount={pageCount} forcePage={forcePage} onPageChange={onPageChange} />
                                        </div>
                                    }
                                </TableCell>
                            </TableRow>
                        </TableFooter>
                    </Table>

                    </CardContent>
                </Collapse>
            </Card>

            <AddAttachment
                onUpdateAttachments={getDocument}
                onClose={handleEditClose}
                jobId={job.jobId}
                customerId={job.customerId}
                open={openEdit} />
            
            <Snackbar
                open={openSnack}
                anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
                autoHideDuration={2000}
                onClose={() => setOpenSnack(false)}>
                <Alert
                    elevation={6} variant="filled" severity={isSuccess.failed ? 'error' : 'success'}>
                    <Typography
                        color="inherit"
                        variant="h6">
                        {isSuccess.msg}
                    </Typography>
                </Alert>
            </Snackbar>
        </>
    )
}

export default JobAttachments;
