import React, {useEffect, useState} from 'react'
import {Typography, TextField, colors, Chip, Snackbar, Link,Card, CardHeader, CardContent, Divider,Collapse,Grid} from '@material-ui/core'
import { ExpandMore } from '@material-ui/icons'
import {makeStyles} from '@material-ui/styles'
import {MultiSelect} from 'components'
import {Autocomplete} from "@material-ui/lab";
import {Add, AddAlarm} from "@material-ui/icons";
import axiosNoAuth from 'utils/axiosNoAuth'
import { Alert } from '@material-ui/lab'

const useStyles = makeStyles(theme => ({
    attachments: {
        marginBottom: 14,
        borderRadius: 4,
        border: '1px solid #c7c7c7',
        position: 'relative',
    },
    overAttachments: {
        height: 108,
        overflowY: 'auto',
        '&::-webkit-scrollbar': {
            width: '5px'
        },
        '&::-webkit-scrollbar-track': {
            backgroundColor: '#e4e4e4'
        },
        '&::-webkit-scrollbar-thumb': {
            backgroundColor: '#9c9c9c',
            borderRadius: '60px'
        }
    },
    label: {
        position: 'absolute',
        top: -10,
        left: 8,
        backgroundColor: '#ffffff',
        fontSize: 12,
        paddingLeft: 4,
        lineHeight: '14px'
    },
    attachment: {
        padding: '4px 8px',
        display: 'block',
        fontWeight: 500,
        borderBottom: '1px solid #c7c7c7',
        cursor: 'pointer',
        '&:hover': {
            backgroundColor: colors.green[600],
            color: '#ffffff'
        }
    },
    textField: {marginTop: 14},
    openImage: {
        color: "#000000"
    }
}));

const JobNote = ({job,isEnableTodayRoute}) => {
    const classes = useStyles();

    const [openSelect, setOpenSelect] = useState(false);
    const [equipments, setEquipments] = useState([]);
    const [isSuccess, setIsSuccess] = useState({ failed: false, msg: '' });
    const [openSnack, setOpenSnack] = useState(false);
    const [expand, setExpand] = useState(false);
    useEffect(() => {
        if (job.jobEquipment) {
            setEquipments(job.jobEquipment.split(';'))
        }

    }, [job])

    const onDownloadFile = (url, fileName) => {
        axiosNoAuth.get(url, {
            headers: {},
            responseType: 'blob',
        })
            .then((response) => {
                const url = window.URL.createObjectURL(new Blob([response.data]));
                const link = document.createElement('a');
                link.href = url;
                link.setAttribute('download', fileName);
                document.body.appendChild(link);
                link.click();
            })
            .catch(() => {
                setIsSuccess({ failed: true, msg: 'Download file failed.' });
                setOpenSnack(true);
            })

    }

    return (
        <>
        {
            (isEnableTodayRoute != null && isEnableTodayRoute) ? 
            <>
                <Card>
                    <CardHeader title='Notes'
                        className={classes.expandHead}
                        action={<ExpandMore />}
                        onClick={() => setExpand(!expand)} />
                    <Divider />
                    <Collapse in={expand} timeout='auto' unmountOnExit>
                        <CardContent style={{ display: 'flex' }}>
                        <Grid container spacing={2}>
                            <Grid item md={6} sm={12}>
                                <TextField style={{ marginBottom: 14 }}
                                    fullWidth disabled label='Job Description' variant='outlined' multiline rows={8} value={job.jobDescription}/>
                                <TextField fullWidth disabled label='Split Description' variant='outlined'
                                    multiline rows={8} value={job.jobSplitDescription}/>
                            </Grid>
                            <Grid item md={6} sm={12}>
                                <Autocomplete disabled multiple
                                    id="tags-filled" options={[]} defaultValue={equipments}
                                    value={equipments} freeSolo disableClearable
                                    disableCloseOnSelect disabledItemsFocusable
                                    renderTags={(value, getTagProps) =>
                                        value.map((option, index) => (
                                            <Chip deleteIcon={<></>} variant="outlined" label={option} {...getTagProps({ index })} />
                                        ))
                                    }
                                    renderInput={(params) => (
                                        <TextField {...params} variant="outlined" label="Equipment Needed" />
                                    )}
                                />
                                <TextField className={classes.textField}
                                    fullWidth disabled label='Field Tech Note'
                                    variant='outlined' multiline
                                    rows={3} value={job.fieldTechNote}
                                />
                                <TextField className={classes.textField}
                                    fullWidth disabled
                                    label='Permanent Note' variant='outlined' multiline
                                    rows={3} value={job.permanentNote}
                                />
                                <TextField className={classes.textField}
                                    fullWidth disabled label='Today Only Note'
                                    variant='outlined' multiline
                                    rows={3} value={job.todayOnlyNote}
                                />
                            </Grid>
                        </Grid>
                        </CardContent>
                    </Collapse>
                </Card>
            </>
            :
            <>
                <div className={classes.attachments}>
                    <Typography className={classes.label}>Attachments</Typography>
                    <div className={classes.overAttachments}>
                        {job.documents && job.documents.map(file => (
                            <Link href={file.path} target={'_blank'}>
                                <Typography className={classes.attachment}>
                                    {file.fileName}
                                </Typography>
                            </Link>

                        ))}
                    </div>
                </div>
                {/*<MultiSelect*/}
                {/*    disabled*/}
                {/*    open={openSelect}*/}
                {/*    onToggle={() => setOpenSelect(!openSelect)}*/}
                {/*    selectedOptions={equipments}*/}
                {/*    label='Field Tech Note'*/}
                {/*/>*/}
                <Autocomplete
                    disabled
                    multiple
                    id="tags-filled"
                    options={[]}
                    defaultValue={equipments}
                    value={equipments}
                    freeSolo
                    disableClearable
                    disableCloseOnSelect
                    disabledItemsFocusable
                    renderTags={(value, getTagProps) =>
                        value.map((option, index) => (
                            <Chip deleteIcon={<></>} variant="outlined" label={option} {...getTagProps({ index })} />
                        ))
                    }
                    renderInput={(params) => (
                        <TextField {...params} variant="outlined" label="Equipment Needed" />
                    )}
                />
                <TextField
                    className={classes.textField}
                    fullWidth
                    disabled
                    label='Field Tech Note'
                    variant='outlined'
                    multiline
                    rows={3}
                    value={job.fieldTechNote}
                />
                <TextField
                    className={classes.textField}
                    fullWidth
                    disabled
                    label='
                    Permanent Note'
                    variant='outlined'
                    multiline
                    rows={3}
                    value={job.permanentNote}
                />
                <TextField
                    className={classes.textField}
                    fullWidth
                    disabled
                    label='Today Only Note'
                    variant='outlined'
                    multiline
                    rows={3}
                    value={job.todayOnlyNote}
                />
            </>
        }
            <Snackbar
                open={openSnack}
                anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
                autoHideDuration={2000}
                onClose={() => setOpenSnack(false)}>
                <Alert
                    elevation={6} variant="filled" severity={isSuccess.failed ? 'error' : 'success'}>
                    <Typography
                        color="inherit"
                        variant="h6">
                        {isSuccess.msg}
                    </Typography>
                </Alert>
            </Snackbar>
        </>
    )
}

export default JobNote;
