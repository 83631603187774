import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { makeStyles } from '@material-ui/styles'
import {
    Modal, Card, CardHeader, CardContent, Divider,
    TextField, Button, Grid, Typography,
    colors, CardActions
} from '@material-ui/core'
import { QueryBuilderRounded } from '@material-ui/icons'
import { KeyboardTimePicker } from "@material-ui/pickers";
import moment from 'moment';
import { useSelector } from 'react-redux'

const useStyles = makeStyles(theme => ({
    styleModal: {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        outline: 'none',
        boxShadow: theme.shadows[20],
        width: 500,
        maxHeight: '90%',
        overflowY: 'auto',
        maxWidth: '100%',
        [theme.breakpoints.down('sm')]: {
            width: 500
        },
    },
    button: {
        color: theme.palette.white,
        backgroundColor: colors.green[600],
        '&:hover': {
            backgroundColor: colors.green[900]
        },
    },
    actions: {
        justifyContent: 'flex-end',
        paddingRight: 16
    },
    typo: {
        fontWeight: 500
    },
}));

const ScheduledModal = ({ editedRoute, open, onClose, scheduled }) => {

    const classes = useStyles();

    const { routes } = useSelector(state => state.RoutesReducer)

    const [disabledBtn, setDisabledBtn] = useState(false)
    const [moveTo, setMoveTo] = useState('0');
    const [time, setTime] = useState(null);
    const [titleModal, setTitleModal] = useState('');

    const handleSchedule = () => {
        // Unschedule
        if (moveTo === '0') {
            scheduled({ type: editedRoute.type, item: editedRoute.item })
            return;
        }

        // re-schedule
        if (editedRoute.type === 'job') {
            const d = new Date(time);
            const minutes = d.getMinutes() >= 15 ? 30 : 0
            const starttime = d.getHours() + minutes / 60;
            scheduled({
                type: editedRoute.type,
                routeOrder: parseInt(moveTo),
                item: editedRoute.item,
                starttime
            })
            return;
        }
        scheduled({
            type: editedRoute.type,
            routeOrder: parseInt(moveTo),
            item: editedRoute.item
        })
    }

    useEffect(() => {
        if (moveTo === '0' || editedRoute.type !== 'job') {
            setDisabledBtn(false)
            return
        }
        const d = new Date(time);
        const minutes = d.getMinutes() >= 15 ? 30 : 0
        const st = d.getHours() + minutes / 60;
        const { routeJobs } = routes.find(r => r.routeOrder === parseInt(moveTo));
        const isExistedJob = routeJobs && routeJobs.find(job => {
            const d = new Date(job.jobStart)
            const starttime = d.getHours() + d.getMinutes() / 60;
            return starttime === st
        })
        setDisabledBtn(isExistedJob)
    }, [time, moveTo])

    useEffect(() => {
        if (editedRoute) {
            const { item } = editedRoute;
            item.jobStart && setTime(new Date(item.jobStart));
            editedRoute.type === 'job' && setTitleModal(' - Job');
            editedRoute.type === 'employee' && setTitleModal(' - Employee');
            editedRoute.type === 'equipment' && setTitleModal(' - Equipment')
        }
    }, [editedRoute])

    return (
        <div>
            <Modal open={open} onClose={onClose}>
                <Card className={classes.styleModal}>
                    <CardHeader title={`Reschedule${titleModal}`} />
                    <Divider />
                    <CardContent>
                        <Grid container spacing={2}>
                            <Grid item xs={3} md={2}>
                                <Grid item xs={2} style={{ display: 'flex', alignItems: 'center' }}>
                                    <Typography className={classes.typo}>
                                        {editedRoute && editedRoute.type === 'job' && 'Description'}
                                        {editedRoute && editedRoute.type === 'employee' && 'Name'}
                                        {editedRoute && editedRoute.type === 'equipment' && 'Description'}
                                    </Typography>
                                </Grid>
                            </Grid>
                            <Grid item xs={9} md={10}>
                                <Typography className={classes.typo}>
                                    {editedRoute && editedRoute.type === 'job' && `${editedRoute.item.description || ''}`}
                                    {editedRoute && editedRoute.type === 'employee' && `${editedRoute.item.firstName || ''} ${editedRoute.item.lastName || ''}`}
                                    {editedRoute && editedRoute.type === 'equipment' && `${editedRoute.item.assetTag}`}
                                </Typography>
                            </Grid>
                            {editedRoute && editedRoute.type === 'job' &&
                                <>
                                    <Grid item xs={3} md={2} style={{ display: 'flex', alignItems: 'center' }}>
                                        <Typography className={classes.typo}>Hours</Typography>
                                    </Grid>
                                    <Grid item xs={9} md={10}>
                                        <div className={classes.group}>
                                            <Typography className={classes.typo}>{editedRoute && editedRoute.item.estimatedHours || ''}</Typography>
                                        </div>
                                    </Grid>
                                </>
                            }
                            <Grid item xs={3} md={2} style={{ display: 'flex', alignItems: 'center' }}>
                                <Typography className={classes.typo}>Move to </Typography>
                            </Grid>
                            <Grid item xs={9} md={10}>
                                <TextField
                                    fullWidth
                                    variant='outlined'
                                    select
                                    SelectProps={{ native: true }}
                                    value={moveTo || 0}
                                    onChange={e => setMoveTo(e.target.value)}>
                                    {editedRoute && editedRoute.routeOrders.map(order => (
                                        <option key={order.value} value={order.value} disabled={order.disabled}>
                                            {order.name}
                                        </option>
                                    ))}
                                </TextField>
                            </Grid>
                            {editedRoute && editedRoute.item.jobStart &&
                                <>
                                    <Grid item xs={3} md={2} style={{ display: 'flex', alignItems: 'center' }}>
                                        <Typography className={classes.typo}>Time </Typography>
                                    </Grid>
                                    <Grid item xs={9} md={10}>
                                        <KeyboardTimePicker
                                            error={time && !moment(time).isValid()}
                                            helperText={time && !moment(time).isValid() && 'Invalid Time Format'}
                                            fullWidth
                                            disabled={moveTo === '0'}
                                            placeholder="00:00"
                                            inputVariant='outlined'
                                            ampm
                                            keyboardIcon={<QueryBuilderRounded />}
                                            mask="__:__"
                                            value={time}
                                            onChange={date => setTime(date)}
                                        />
                                    </Grid>
                                </>
                            }
                        </Grid>
                    </CardContent>
                    <Divider />
                    <CardActions className={classes.actions}>
                        <Button onClick={onClose} variant="contained">Close</Button>
                        <Button style={{ marginLeft: 10 }}
                            disabled={disabledBtn}
                            className={classes.button}
                            variant="contained"
                            color="primary"
                            onClick={handleSchedule}>
                            Schedule
                        </Button>
                    </CardActions>
                </Card>
            </Modal>
        </div>
    )
}

ScheduledModal.propTypes = {
    editedRoute: PropTypes.object,
    open: PropTypes.bool,
    onClose: PropTypes.func,
    scheduled: PropTypes.func
}

export default ScheduledModal;
