import React from "react";
import PropTypes from "prop-types";
import { Tabs, Tab, Divider, colors } from "@material-ui/core";
import { Redirect } from "react-router-dom";
import { makeStyles } from "@material-ui/styles";
import { Page } from "components";
import localStorage from "utils/localStorage";
import { AccessControl } from "components/AccessControl/AccessControl";
import { PERMISSIONS } from "common/permissions";
import  Communications  from "../Admin/components/Communications";
const useStyles = makeStyles((theme) => ({
  root: {
    height: "100%",
    padding: "0 32px 32px",
  },
  tabs: {
    marginTop: theme.spacing(2),
  },
  tab: {
    padding: "6px 16px",
    [theme.breakpoints.up("sm")]: {
      minWidth: "auto",
    },
  },
  divider: {
    backgroundColor: colors.grey[300],
  },
  content: {
    marginTop: theme.spacing(3),
  },
}));

const CommunicationCenter = (props) => {
  const { match, history } = props; 
  const { name, id, tab } = match.params;
  const classes = useStyles();

  const account = localStorage.getUserInfo();
  const fullInfo = localStorage.getFullInfo();

  const handleTabsChange = (event, value) => {
    history.push(value);
  };
  const tabs = [
    { value: "jobmessages", label: "Job Messages" },
    { value: "autoreminders", label: "Auto Reminders" },
    { value: "emailmarketing", label: "Email Marketing" },
  ];
  if (!tab) {
    return <Redirect to={`/admin/communication-center/${(account && account.displayName) ? account.displayName : ' '}/${fullInfo.cid}/jobmessages`} />;
  }
  if (!tabs.find(t => t.value === tab)) {
    return <Redirect to="/errors/error-404" />;
  }
  return (
    <AccessControl requiredPermission={PERMISSIONS.page.admin} redirect>
      <Page
        className={classes.root}
        title={name === " " ? "Admin Detail" : `Admin Detail`}
      >
        <Tabs
          className={classes.tabs}
          onChange={handleTabsChange}
          scrollButtons="auto"
          value={tab}
          variant="scrollable"
        >
          {tabs.map((tab) => {

            return (
              <Tab
                className={classes.tab}
                key={tab.value}
                label={tab.label}
                value={tab.value}
              />
            );
          })}
        </Tabs>
        <Divider className={classes.divider} />
        <div className={classes.content}>
          {tab === "jobmessages" && <Communications adminId={id} />}
        </div>
      </Page>
    </AccessControl>
  );
};

CommunicationCenter.propTypes = {
  history: PropTypes.object.isRequired,
  match: PropTypes.object.isRequired,
};

export default CommunicationCenter;
