import React from 'react'
import PropTypes from 'prop-types'
import {
    Modal,
    Card,
    CardContent,
    CardActions,
    Button,
    colors,
    Typography,
    Divider,
    CardHeader
} from '@material-ui/core'
import { makeStyles } from '@material-ui/styles'

const useStyles = makeStyles(theme => ({
    styleModal: {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        outline: 'none',
        boxShadow: theme.shadows[20],
        width: 600,
        maxHeight: '100%',
        overflowY: 'auto',
        maxWidth: '100%',
        [theme.breakpoints.down('sm')]: {
            width: 500
        },
    },
    conButton: {
        padding: '16px',
        justifyContent: 'flex-end'
    },
    button: {
        flexShrink: '0',
        backgroundColor: colors.green[600],
        '&:hover': {
            backgroundColor: colors.green[900]
        },
    },
}))

const DeleteSplitConfirmModal = props => {
    const classes = useStyles();

    const { openConfirm, closeConfirm, onConfirm, scheduledDate } = props;

    return (
        <Modal
            open={openConfirm}
            onClose={closeConfirm}
        >
            <Card className={classes.styleModal}>
                <CardHeader title="Warning" />
                <Divider />
                <CardContent style={{ padding: '20px 16px' }}>
                    <Typography style={{ fontSize: '16px' }}>
                        <span>This job split is scheduled for {scheduledDate}. Please inform anyone schedule on that route about the change.</span>
                    </Typography>
                </CardContent>
                <Divider />
                <CardActions className={classes.conButton}>
                    <Button
                        variant="contained"
                        size="large"
                        onClick={closeConfirm}>
                        Cancel
                    </Button>

                    <Button
                        variant="contained"
                        color="primary"
                        size="large"
                        onClick={() => {
                            onConfirm()
                        }}
                        className={classes.button}>
                        Continue
                    </Button>
                </CardActions>
            </Card>
        </Modal>
    )
}

DeleteSplitConfirmModal.propTypes = {
    scheduledDate: PropTypes.string,
    openConfirm: PropTypes.bool.isRequired,
    closeConfirm: PropTypes.func.isRequired,
    onConfirm: PropTypes.func
}

DeleteSplitConfirmModal.defaultProps = {
    onConfirm: () => { },
    closeConfirm: () => { }
}

export default DeleteSplitConfirmModal;
