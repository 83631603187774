import React, { useState, useEffect, useRef } from "react"
import PropTypes from "prop-types"
import { IconButton, Typography } from '@material-ui/core'
import { makeStyles } from '@material-ui/styles'
import { TimePicker } from '@material-ui/pickers'
import { Schedule } from '@material-ui/icons'
import moment from "moment"

const useStyles = makeStyles(theme => ({
    boxTime: {
        height: '100%',
        position: 'relative',
        display: 'flex',
        justifyContent: 'flex-start',
        alignItems: 'center',
        padding: 14,
        border: '1px solid #0000003b',
        borderRadius: 4,
        '&:hover': {
            border: '1px solid #000000'
        },
        paddingRight: 48
    },
    label: {
        position: 'absolute',
        top: -8,
        left: -8,
        margin: 0,
        padding: '0 3px',
        backgroundColor: '#ffffff',
        fontSize: 14,
        lineHeight: '14px',
        color: '#263238',
        transform: 'scale(0.75)'
    },
    inputTime: {
        width: 20,
        border: 'none',
        outline: 'none',
        textAlign: 'center',
        fontSize: 15
    },
    inputAMPM: {
        width: 23,
        border: 'none',
        outline: 'none',
        textAlign: 'center',
        fontSize: 15,
        marginLeft: 5
    },
    seprateTime: {
        fontSize: 16,
        fontWeight: 500,
        padding: '0px 2px'
    },
    clockIcon: {
        position: 'absolute',
        top: '50%',
        transform: 'translateY(-50%)',
        right: 0,
        fontSize: 24,
        cursor: 'pointer'
    },
    disabled: {
        pointerEvents: 'none'
    }
}))

const isValid = (val) => {
    let reg = /^\d+$/g;
    return reg.test(val);
};

const InputTime = ({ error, disabled, label, ampm, value, onChange, className, style, clearValue }) => {

    const classes = useStyles();
    const hourRef = useRef();
    const minuteRef = useRef();
    const ampmRef = useRef();

    const [openTimePicker, setOpenTimePicker] = useState(false);
    const [time, setTime] = useState({ hour: '', minute: '', ampm });

    const onChangeTime = event => {
        const { name, value } = event.target;
        if (value && !isValid(value)) { return };
        const valInt = value !== '' ? parseInt(value) : null;

        name === 'hour' && valInt < 13 &&
            setTime({
                ...time,
                hour: valInt ?
                    (valInt < 10 ? `0${valInt}` : `${valInt}`) : ''
            });
        name === 'minute' && valInt < 60 &&
            setTime({
                ...time,
                minute: (valInt !== '' && valInt !== null) ?
                    (valInt < 10 ? `0${valInt}` : `${valInt}`) : ''
            });

        if (name === 'hour') {
            valInt > 1 && minuteRef.current.focus();
            !value && !time.minute && onChange(null);
            value && time.minute && onChange(`${valInt}:${time.minute} ${time.ampm}`)
        }
        if (name === 'minute') {
            valInt > 5 && ampmRef.current.focus();
            !time.hour && !value && onChange(null);
            time.hour && value && onChange(`${time.hour}:${valInt} ${time.ampm}`)
        }
    }
    const onChangeAMPM = event => {
        const { value } = event.target;
        const reg_ampm = /a|p|A|P/g;
        const val_ampm = value.length === 1 ? value : value[2]
        if (reg_ampm.test(val_ampm)) {
            setTime({ ...time, ampm: `${val_ampm}m`.toUpperCase() });
            time.hour && time.minute && onChange(`${time.hour}:${time.minute} ${val_ampm}m`)
        }
    }
    const onKeyDownTime = event => {
        const { name } = event.target;
        switch (event.keyCode) {
            // press on Left arrow
            case 37:
                event.preventDefault();
                name === 'ampm' ? minuteRef.current.focus() : hourRef.current.focus();
                break;
            // press on Right arrow
            case 39:
                event.preventDefault();
                name === 'hour' ? minuteRef.current.focus() : ampmRef.current.focus();
                break;
            // press on Up arrow
            case 38:
                event.preventDefault();
                name === 'ampm' && setTime({ ...time, ampm: time.ampm === 'AM' ? 'PM' : 'AM' });
                time.hour && time.minute && onChange(`${time.hour}:${time.minute} ${time.ampm === 'AM' ? 'PM' : 'AM'}`);
                break;
            // press on Down arrow
            case 40:
                event.preventDefault();
                name === 'ampm' && setTime({ ...time, ampm: time.ampm === 'AM' ? 'PM' : 'AM' });
                time.hour && time.minute && onChange(`${time.hour}:${time.minute} ${time.ampm === 'AM' ? 'PM' : 'AM'}`);
                break;
            default:
                break;
        }
    }
    const onHandleChangeTime = day => {
        const time = moment(day).format('LT');
        const [hour, backtime] = time.split(':')
        const [minute, ampm] = backtime.split(' ');
        setTime({
            hour: hour.length === 1 ? `0${hour}` : hour,
            minute: minute.length === 1 ? `0${minute}` : minute,
            ampm
        })
        onChange(`${hour}:${minute} ${ampm}`);
    }
    const clear = () => {
        setTime({ hour: '', minute: '', ampm })
    }
    if(clearValue){
        clearValue(clear)
    }

    useEffect(() => {
        // get init time
        const date = new Date(value);
        if (value && !isNaN(date.getTime())) {
            let hour = date.getHours();
            let minute = date.getMinutes();
            const ampm = hour < 12 ? "AM" : 'PM';
            hour = hour < 13 ? hour : hour - 12;
            setTime({
                hour: hour === 0 ? '12' : (hour < 10 ? `0${hour}` : hour),
                minute: minute < 10 ? `0${minute}` : minute,
                ampm
            })
        } else {
            setTime({ hour: '', minute: '', ampm })
        }
    }, [value])


    return (
        <div className={`${classes.boxTime} ${disabled ? classes.disabled : ''} ${className}`}
            style={{
                ...style,
                opacity: disabled ? 0.5 : 1,
                borderColor: error ? '#e53935' : '#0000003b'
            }}>
            <Typography className={classes.label}>{label}</Typography>
            <input
                ref={hourRef}
                className={classes.inputTime}
                name="hour"
                placeholder="--"
                onFocus={event => event.target.setSelectionRange(0, 3)}
                onKeyDown={onKeyDownTime}
                value={time.hour}
                onChange={onChangeTime}
            />
            <span className={classes.seprateTime}>:</span>
            <input
                ref={minuteRef}
                className={classes.inputTime}
                name="minute"
                placeholder="--"
                onFocus={event => event.target.select()}
                onKeyDown={onKeyDownTime}
                value={time.minute}
                onChange={onChangeTime}
            />
            <input
                ref={ampmRef}
                className={classes.inputAMPM}
                name="ampm"
                onFocus={event => event.target.select()}
                onKeyDown={onKeyDownTime}
                value={time.ampm}
                onChange={onChangeAMPM}
            />
            <IconButton className={classes.clockIcon} onClick={() => setOpenTimePicker(true)}>
                <Schedule />
            </IconButton>

            <TimePicker
                style={{ display: 'none' }}
                open={openTimePicker}
                onClose={() => setOpenTimePicker(false)}
                value={value ? new Date(value) : new Date('01/01/1970 06:00 '+ ampm)}
                onChange={onHandleChangeTime}
            />
        </div>
    );
};

InputTime.propTypes = {
    disabled: PropTypes.bool,
    error: PropTypes.bool,
    ampm: PropTypes.string,
    label: PropTypes.string,
    value: PropTypes.string,
    onChange: PropTypes.func.isRequired,
    className: PropTypes.string,
    style: PropTypes.object,
    clearValue: PropTypes.func
};
InputTime.defaultProps = {
    disabled: false,
    error: false,
    ampm: "AM",
    onChange: () => { },
    className: ''
};

export default InputTime;
