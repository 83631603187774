import React ,{ useState } from 'react';
import { makeStyles } from '@material-ui/styles';
import {
  Card,
  CardHeader,
  CardContent,
  Grid,
  TextField,
  Divider,
  FormControlLabel,
  Typography
} from '@material-ui/core';
import { GreenCheckbox, GreenRadio } from 'components';

const useStyles = makeStyles(theme => ({
  defaultCompany: {
    paddingTop: '0px !important',
    '& > label': {
      margin: '-16px auto'
    }
  },
  labelRoles: {
    position: 'absolute',
    top: '-10px',
    left: '10px',
    backgroundColor: '#ffffff',
    padding: '0 3px'
  },
  rolesContainer: {
    width: '100%',
    border: '1px solid #b3b3b3',
    borderRadius: '4px',
    position: 'relative',
    [theme.breakpoints.down('xs')]: {
        width: '100%'
    }
  },
  overBox: {
      width: '100%',
      // height: '200px',
      overflowY: 'auto',
      padding: '16px 0px',
      '&::-webkit-scrollbar': {
          width: '5px'
      },
      '&::-webkit-scrollbar-track': {
          backgroundColor: '#e4e4e4'
      },
      '&::-webkit-scrollbar-thumb': {
          backgroundColor: '#9c9c9c',
          borderRadius: '60px'
      }
  },

}));



const Security = props => {

  const { roles, values, errors, touched, setFieldTouched, setFieldValue } = props;
  const classes = useStyles();
  const _roles = [...roles];
  // _roles.unshift({
  //   id: '',
  //   name: '',
  //   isSelected: null
  // })
  
  return (
    <Grid container spacing={4}>
      <Grid item md={12} lg={3}></Grid>
      <Grid item md={12} lg={9}>
        <Card>
          <CardHeader title="Security" />
          <Divider />
          <CardContent>
            <Grid
              className={classes.container}
              container
              spacing={3}>
              <Grid
                item
                xs={12}>
                <FormControlLabel
                  className={classes.taxCheckbox}
                  name='excludeCheckBox'
                  label='Exclude From Route Resources'
                  checked={values.excludeFromRouteResources || false}
                  control={
                    <GreenCheckbox />
                  }
                  onChange={event => setFieldValue('excludeFromRouteResources', event.target.checked)}
                />
              </Grid>
              <Grid item md={12} xs={12}>
                      <div className={classes.rolesContainer}>
                          <Typography className={classes.labelRoles}>Select Role</Typography>
                          <div className={classes.overBox} name="roleIds">
                            {_roles.length > 0 &&
                              _roles.map((role, index) => (
                                <Grid className={classes.defaultCompany} item xs={12} style={ index !== (_roles.length - 1) ? { marginBottom: '10px' } : {}}>
                                  <FormControlLabel
                                    control={<GreenRadio />}
                                    label={role.name}
                                    name={role.name}
                                    checked={values.security?.roleIds?.[0] === role.id}
                                    onChange={(event) => {
                                      const { name, value, type, id } = event.target;
                                      const security = { ...values.security };
                                      const index = _roles.map(function(x) {return x.name; }).indexOf(name);
                                      const role = _roles[index];
                                      if(!event.target.checked) {
                                        if(security.roleIds  && security.roleIds.length > 0){
                                          var idx = security.roleIds.indexOf(role.id);
                                          if (idx !== -1) {
                                            security.roleIds.splice(idx, 1);
                                          }
                                        }
                                        if(role.name === 'Field Technician')
                                          setFieldValue('excludeFromRouteResources', true);
                                      } else {
                                        setFieldValue('excludeFromRouteResources', role.name !== 'Field Technician');
                                        security.roleIds = [role.id];
                                      }
                                      setFieldValue('security', security);
                                    }}
                                  />
                                </Grid>
                              ))
                            }
                          </div>
                      </div>
                    </Grid>
              <Grid
                item
                md={12}
                xs={12}>
                <TextField
                  fullWidth
                  error={errors.security && errors.security.password && touched.security && touched.security.password}
                  helperText={(errors.security && errors.security.password && touched.security && touched.security.password) && errors.security.password}
                  label="Password"
                  name="password"
                  type='password'
                  value={values.security.password}
                  onBlur={() => {
                    const security = touched.security ? { ...touched.security } : {};
                    security.password = true;
                    setFieldTouched('security', security)
                  }}
                  onChange={event => {
                    const security = { ...values.security };
                    security.password = event.target.value;
                    setFieldValue('security', security);
                  }}
                  variant="outlined"
                />
              </Grid>

              <Grid
                item
                md={12}
                xs={12}>
                <TextField
                  fullWidth
                  error={errors.security && errors.security.passwordConfirm && touched.security && touched.security.passwordConfirm}
                  helperText={(errors.security && errors.security.passwordConfirm && touched.security && touched.security.passwordConfirm) && errors.security.passwordConfirm}
                  label="Confirm Password"
                  name="passwordConfirm"
                  type='password'
                  value={values.security.passwordConfirm}
                  onBlur={() => {
                    const security = touched.security ? { ...touched.security } : {};
                    security.passwordConfirm = true;
                    setFieldTouched('security', security)
                  }}
                  onChange={event => {
                    const security = { ...values.security };
                    security.passwordConfirm = event.target.value;
                    setFieldValue('security', security);
                  }}
                  variant="outlined"
                />
              </Grid>
            </Grid>
          </CardContent>
        </Card>
      </Grid>
    </Grid>
  );
};



export default Security;
