import React, { useState, useEffect } from 'react'
import { makeStyles } from '@material-ui/styles'
import { Alert } from '@material-ui/lab';
import {
    Modal,
    Button,
    colors,
    Card,
    CardContent,
    CardActions,
    Typography,
    Snackbar,
    Grid,
    TextField,
    FormControlLabel,
    CircularProgress
} from '@material-ui/core'
import {Dropzone, GreenCheckbox, KeyboardDatePicker} from 'components'
import axios from 'utils/axios'
import apiConfig from 'apiConfig'
import moment from "moment";
import { useDispatch } from 'react-redux';

const useStyles = makeStyles(theme => ({
    root: {},
    styleModal: {
        position: 'absolute',
        top: '40%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        outline: 'none',
        boxShadow: theme.shadows[20],
        width: 700,
        maxHeight: '100%',
        overflowY: 'auto',
        maxWidth: '100%',
        [theme.breakpoints.down('sm')]: {
            width: 500
        },
    },
    btnUpFile: {
        flexShrink: '0',
        color: theme.palette.white,
        backgroundColor: colors.green[600],
        '&:hover': {
            backgroundColor: colors.green[900]
        },
    },
    actionCard: {
        justifyContent: 'flex-end',
        borderTop: '1px solid #eeeeee'
    }
}));

const AddAttachment = props => {

    const classes = useStyles();
    const dispatch = useDispatch();

    const { customerId, open, onClose, onUpdateAttachments, documentOldId } = props;

    const [att, setAtt] = useState();
    const [files, setFiles] = useState([]);
    const [openSnack, setOpenSnack] = useState(false);
    const [isSuccess, setIsSuccess] = useState({ failed: false, msg: '' });
    const [note, setNote] = useState("");
    const [checked, setChecked] = useState(false);
    const [isProgress, setIsProgress] = useState(false);
    const [expirationDate, setExpirationDate] = useState();

    const onChangeFile = files => {
        setFiles(files);
    }

    const onUploadAttachments = () => {

        files.forEach(file => {
            const formData = new FormData();
            formData.append('id', customerId);
            formData.append('file', file);
            formData.append('note', note);
            if(documentOldId) {
                formData.append('DocumentOldId', documentOldId);
            }
            if (expirationDate) formData.append('ExpirationDate', expirationDate);
            formData.append('isDisplayToFieldTech', checked);
            setIsProgress(true)
            axios.put(apiConfig.url.CUSTOMER_URL + apiConfig.url.CUSTOMERS_ADD_DOCUMENT, formData)
                .then(res => {
                    setAtt(res.data)
                    setIsSuccess({ failed: false, msg: 'Add files successfully.' });
                    setOpenSnack(true);
                    dispatch({ type: 'RELOAD_CUSTOMERS_COUNTER', reloadCustomer: true });
                })
                .catch(err => {
                    setIsSuccess({ failed: true, msg: 'Add files failed, please try again later.' });
                    setOpenSnack(true);
                })
                .finally(() => {
                    setIsProgress(false);
                    onCloseModal()
                })
        });  
    }

    const onCloseModal = () => {
        setFiles([]);
        setNote('');
        setChecked(false);
        setExpirationDate();
        onClose();
    }

    const handleClose = () => {
        setOpenSnack(false);
    }

    useEffect(() => {
        if (att) {
            onUpdateAttachments(att);
        }
    }, [att])

    return (
        <>
            <Modal open={open} onClose={onCloseModal}>
                <Card className={classes.styleModal}>
                    <CardContent>
                        <Dropzone onChange={files => onChangeFile(files)} />

                        <Grid container spacing={2} style={{ marginTop: '16px' }}>
                            <Grid item xs={12}>
                                <TextField
                                    fullWidth
                                    multiline
                                    rows={5}
                                    label='Note'
                                    name='note'
                                    onChange={event => setNote(event.target.value)}
                                    value={note}
                                    variant='outlined'
                                />
                            </Grid>
                            <Grid item xs={6}>
                                {!documentOldId && 
                                <FormControlLabel
                                    control={
                                        <GreenCheckbox
                                            checked={checked}
                                            onChange={e => setChecked(e.target.checked)}
                                        />
                                    }
                                    label="Display to field techs"
                                />}
                            </Grid>
                            <Grid item xs={6} style={{textAlign: "right"}}>
                                <KeyboardDatePicker
                                    style={{width: '180px', float: 'right'}}
                                    label='Expiration Date'
                                    onChange={(e) => {
                                        setExpirationDate(e)
                                    }}
                                    onBlur={(e, v) => {
                                        setExpirationDate(e.target.value)
                                    }}
                                    value={expirationDate}
                                />
                            </Grid>
                        </Grid>
                    </CardContent>
                    <CardActions className={classes.actionCard}>
                        <Button
                            onClick={onCloseModal}
                            variant="contained">
                            Close
                        </Button>
                            <Button
                                onClick={onUploadAttachments}
                                variant="contained"
                                color="primary"
                                disabled={files.length === 0 ? true : isProgress}
                                className={classes.btnUpFile}>
                                Add files
                            </Button>
                            {isProgress && <CircularProgress size={30} />}
                    </CardActions>
                </Card>
            </Modal>


            <Snackbar
                open={openSnack}
                anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
                autoHideDuration={2000}
                onClose={handleClose}>
                <Alert
                    elevation={6} variant="filled" severity={isSuccess.failed ? 'error' : 'success'}>
                    <Typography
                        color="inherit"
                        variant="h6">
                        {isSuccess.msg}
                    </Typography>
                </Alert>
            </Snackbar>
        </>
    )
}

AddAttachment.defaultProps = {
    open: false,
    onClose: () => { }
};

export default AddAttachment;
