import React, { useState } from 'react'
import {
    Grid, Typography, Button, colors, CircularProgress
} from '@material-ui/core'
import Snackbar from '@material-ui/core/Snackbar';
import Alert from '@material-ui/lab/Alert';
import { makeStyles } from '@material-ui/styles'
import localStorage from 'utils/localStorage'
import apiConfig from 'apiConfig'
import axios from 'utils/axios'

const useStyles = makeStyles(theme => ({
    guide: {
        fontSize: '18px',
        textAlign: 'center',
    },
    btns: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        '& button': {
            marginRight: 8
        }
    },
    btn: {
        flexShrink: '0',
        color: theme.palette.white,
        backgroundColor: colors.green[600],
        '&:hover': {
            backgroundColor: colors.green[900]
        },
    },
}));

const StepThree = props => {

    const classes = useStyles();
    const companyId = localStorage.getCompanyId()

    const [openSnackbar, setOpenSnackBar] = useState(false);
    const [isStatus, setIsStatus] = useState({ failed: false, msg: '' });
    const [isDownload, setIsDownload] = useState(false);

    const downloadGWCFile = () => {
        setIsDownload(true);
        axios.get(apiConfig.url.BASE_URL + apiConfig.url.COMPANY_GET_GWC_FILE.replace('{companyId}', companyId))
            .then(res => {
                const elem = document.createElement('a');
                elem.setAttribute('href', 'data:attachment/gwc;charset=utf-8,' + encodeURI(res.data));
                elem.setAttribute('target', '_blank');
                elem.setAttribute('download', 'webconnector.qwc')
                elem.click();
            })
            .catch(() => {
                setIsStatus({ failed: true, msg: 'An error occurred, please try again later' });
                setOpenSnackBar(true);
            })
            .finally(() => setIsDownload(false))
    }

    return (
        <>
            <Grid container spacing={1}>
                <Grid item xs={12} style={{ margin: '0 20%' }}>
                    <Typography className={classes.guide}>
                        <strong>Step 3: </strong>
                        Download the web connector file and save it to a location on your computer.
                    </Typography>
                </Grid>
                <Grid item xs={12} className={classes.btns}>
                    <Button disabled={isDownload} className={classes.btn}
                        onClick={downloadGWCFile}>
                        Download
                    </Button>
                    {isDownload && <CircularProgress size={24} />}
                </Grid>
            </Grid>

            <Snackbar anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
                open={openSnackbar}
                autoHideDuration={3000}
                onClose={() => setOpenSnackBar(false)}>
                <Alert elevation={6} variant="filled" severity={isStatus.failed ? 'error' : 'success'}>
                    <Typography
                        color="inherit"
                        variant="h6">
                        {isStatus.msg}
                    </Typography>
                </Alert>
            </Snackbar>
        </>
    )
}

export default StepThree;