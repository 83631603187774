import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { makeStyles } from '@material-ui/styles'
import {
    Modal, Card, CardHeader, CardContent, CardActions,
    Divider, FormControlLabel, Button, colors,
    TableContainer, Table, TableHead, TableRow, TableCell, TableBody,
    IconButton,
    useMediaQuery,
    Typography,
    CircularProgress,
    Snackbar
} from '@material-ui/core'
import DeleteIcon from '@material-ui/icons/Delete'
import GetAppIcon from '@material-ui/icons/GetApp'
import AddBoxIcon from '@material-ui/icons/AddBox'
import { ConfirmModal, GreenCheckbox } from 'components'
import moment from 'moment'
import axios from 'utils/axios'
import axiosNoAuth from 'utils/axiosNoAuth'
import apiConfig from 'apiConfig'
import AddAttachment from './AddAttachment'
import { Alert } from '@material-ui/lab'
import { useDispatch } from 'react-redux'

const useStyles = makeStyles(theme => ({
    styleModal: {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        outline: 'none',
        boxShadow: theme.shadows[20],
        width: '80vw',
        maxHeight: '100%',
        overflowY: 'auto',
        maxWidth: '100%',
        [theme.breakpoints.down('sm')]: {
            width: '100%'
        },
    },
    conButton: {
        padding: '16px',
        justifyContent: 'right'
    },
    addStyle: {
        '& span svg': {
            color: colors.green[600],
            '&:hover': {
                color: colors.green[900]
            }
        }
    },
    btnDis: {
        whiteSpace: 'nowrap',
        color: 'rgba(0, 0, 0, 0.26)',
        backgroundColor: 'rgb(217 217 217)',
        pointerEvents: 'none'
    },
    responsiveTable: {
        width: '100%',
        overflowX: 'auto'
    },
    cusFontsize: {
        '& th': {
            fontSize: '15px'
        }
    },
    customTableCell: {
        borderBottom: '1px solid #969696'
    },
    thumbnail: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center'
    },
    openImage: {
        color: '#263238',
        '&:hover': {
            opacity: 0.7
        }
    },
    message: {
        fontSize: theme.spacing(2),
        fontWeight: 500
    },
}))

const AttachmentsModal = props => {
    const classes = useStyles();
    const matches = useMediaQuery('(min-width:992px)');
    const dispatch = useDispatch();
    const { open, onClose, addressId } = props;

    const [listFile, setListFile] = useState([]);
    const [locateFile, setLocateFile] = useState();
    const [openConfirm, setOpenConfirm] = useState(false);
    const [openEdit, setOpenEdit] = useState(false);
    const [isDisable, setDisable] = useState(false);
    const [isLoading, setIsLoading] = useState(true);
    const [isSuccess, setIsSuccess] = useState({ failed: false, msg: '' });
    const [isProgress, setIsProgress] = useState(false);
    const [openSnack, setOpenSnack] = useState(false);

    const isDisplayToFieldTech = id => {
        const file = listFile.find(f => f.id === id);
        setDisable(true);
        axios.patch(apiConfig.url.CUSTOMER_URL + apiConfig.url.CUSTOMER_ADDRESS_UPDATE_DOCUMENT + id, { 'isDisplayToFieldTech': !file.isDisplayToFieldTech })
            .then(res => {
                file.isDisplayToFieldTech = !file.isDisplayToFieldTech;
                setIsSuccess({ failed: false, msg: 'Update file success.' });
                setOpenSnack(true);
            })
            .catch(() => {
                setIsSuccess({ failed: true, msg: 'Update file failed, please try again later.' });
                setOpenSnack(true);
            })
            .finally(() => setDisable(false))
    }

    const onDownloadFile = (url, fileName) => {
        axiosNoAuth.get(url, {
            headers: {},
            responseType: 'blob',
        })
            .then((response) => {
                const url = window.URL.createObjectURL(new Blob([response.data]));
                const link = document.createElement('a');
                link.href = url;
                link.setAttribute('download', fileName);
                document.body.appendChild(link);
                link.click();
            })
            .catch(() => {
                setIsSuccess({ failed: true, msg: 'Download file failed.' });
                setOpenSnack(true);
            })

    }

    const onDeleteFile = () => {
        setIsProgress(true);
        axios.delete(apiConfig.url.CUSTOMER_URL + apiConfig.url.CUSTOMER_ADDRESS_DELETE_DOCUMENT + locateFile.id)
            .then(() => {
                setIsSuccess({ failed: false, msg: 'Remove files success.' });
                setOpenSnack(true);
                dispatch({ type: 'RELOAD_CUSTOMERS_COUNTER', reloadCustomer: true });
                const del_files = [...listFile];
                del_files.splice(locateFile.index, 1);
                setListFile(del_files);
            })
            .catch(() => {
                setIsSuccess({ failed: true, msg: 'Remove file failed, please try again later.' });
                setOpenSnack(true);
            })
            .finally(() => {
                setIsProgress(false);
                setOpenConfirm(false);
            })
    }

    const handleEditOpen = () => {
        setOpenEdit(true);
    }
    const handleEditClose = () => {
        setOpenEdit(false);
    }

    const onUpdateFiles = file => {
        setListFile([...listFile, file])
    }

    useEffect(() => {
        if (addressId && open) {
            setIsLoading(true);
            axios.get(apiConfig.url.CUSTOMER_URL + apiConfig.url.CUSTOMER_ADDRESS_DOCUMENT_LIST + addressId)
                .then(res => {
                    setListFile(res.data.documents);
                    setIsLoading(false);
                })
        }
    }, [open]);

    return (
        <>
            <Modal open={open} onClose={onClose}>
                <Card className={classes.styleModal}>
                    <CardHeader title='Attachments' />
                    <Divider />
                    <CardContent>
                        <TableContainer className={classes.responsiveTable}>
                            <Table className={classes.table} aria-label="simple table">
                                <TableHead className={classes.customTableHead}>
                                    <TableRow className={classes.cusFontsize}>
                                        <TableCell style={{ width: '15%' }} className={classes.customTableCell}>File Name</TableCell>
                                        <TableCell style={{ width: '20%' }} className={classes.customTableCell}>Note</TableCell>
                                        <TableCell className={classes.customTableCell}>Uploaded By</TableCell>
                                        <TableCell className={classes.customTableCell}>Date Uploaded</TableCell>
                                        <TableCell className={classes.customTableCell}>Expiration Date</TableCell>
                                        {matches ? <TableCell className={classes.customTableCell}>Size</TableCell> : null}
                                        <TableCell align='center' className={classes.customTableCell}>Display to Field Techs</TableCell>
                                        <TableCell align='right' className={classes.customTableCell} style={{ width: '80px' }}>
                                            <IconButton className={isDisable ? classes.btnDis : classes.addStyle} onClick={() => setOpenEdit(true)}
                                                style={isDisable ? { backgroundColor: '#fff' } : null}>
                                                <AddBoxIcon fontSize='large' />
                                            </IconButton>
                                        </TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {isLoading
                                        ? (<TableRow>
                                                    <TableCell colSpan={10} align="center">
                                                        <CircularProgress size={24} />
                                                    </TableCell>
                                                </TableRow>)
                                        : (listFile && listFile.length !== 0 ? listFile.map((file, index) => (
                                            <TableRow key={file.id}>
                                                <TableCell>
                                                    <div className={classes.thumbnail}>
                                                        <a className={classes.openImage} href={file.documentUrl} target='_blank'>
                                                            {file.filename}<span style={{color: 'red', display: file.expirationDate && moment(file.expirationDate) < moment().endOf('day') ? 'inline-block' : 'none', paddingLeft: '5px'}}>- Expired</span>
                                                        </a>
                                                        <img width='30px' height='auto' src='/images/thumbnail/file.png' alt='thumb' />
                                                    </div>
                                                </TableCell>
                                                <TableCell>{file.note}</TableCell>
                                                <TableCell>{file.uploadedBy}</TableCell>
                                                <TableCell>{moment(file.dateUploaded).format('MM/DD/YYYY')}</TableCell>
                                                <TableCell>{file.expirationDate && moment(file.expirationDate).format('MM/DD/YYYY')}</TableCell>
                                                {matches ? <TableCell>{file.size}</TableCell> : null}
                                                <TableCell align='center'>
                                                    <FormControlLabel
                                                        onChange={() => isDisplayToFieldTech(file.id)}
                                                        disabled={isDisable}
                                                        control={
                                                            <GreenCheckbox
                                                                checked={file.isDisplayToFieldTech}
                                                                value="tech"
                                                            />
                                                        }
                                                    />
                                                </TableCell>
                                                <TableCell style={{ whiteSpace: 'nowrap' }}>
                                                    <IconButton onClick={() => onDownloadFile(file.documentUrl, file.filename)}>
                                                        <GetAppIcon />
                                                    </IconButton>


                                                    <IconButton aria-label="delete" onClick={() => {
                                                        setLocateFile({ index: index, id: file.id });
                                                        setOpenConfirm(true)
                                                    }}>
                                                        <DeleteIcon />
                                                    </IconButton>
                                                </TableCell>
                                            </TableRow>
                                        )) : (
                                                <TableRow>
                                                    <TableCell colSpan={10}>
                                                        <Typography className={classes.message}>No data found...</Typography>
                                                    </TableCell>
                                                </TableRow>
                                            )
                                    )}
                                </TableBody>
                            </Table>
                        </TableContainer>
                    </CardContent>
                    <Divider />
                    <CardActions className={classes.conButton}>
                        <Button variant="contained" size="large" onClick={onClose}>
                            Close
                        </Button>
                    </CardActions>
                </Card>
            </Modal>
            <AddAttachment
                onUpdateAttachments={onUpdateFiles}
                onClose={handleEditClose}
                addressId={addressId}
                open={openEdit}
            />
            <ConfirmModal
                openConfirm={openConfirm}
                closeConfirm={() => setOpenConfirm(false)}
                onConfirm={onDeleteFile}
                isProgress={isProgress}
            />
            <Snackbar
                open={openSnack}
                anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
                autoHideDuration={2000}
                onClose={() => setOpenSnack(false)}>
                <Alert
                    elevation={6} variant="filled" severity={isSuccess.failed ? 'error' : 'success'}>
                    <Typography
                        color="inherit"
                        variant="h6">
                        {isSuccess.msg}
                    </Typography>
                </Alert>
            </Snackbar>
        </>
    )
}

AttachmentsModal.propTypes = {
    open: PropTypes.bool,
    onClose: PropTypes.func,
    addressId: PropTypes.string,
}

export default AttachmentsModal;
