import React, { useState, useEffect, useRef } from "react";
import PropTypes from "prop-types";
import { useHistory } from "react-router-dom";
import { makeStyles, withStyles } from "@material-ui/styles";
import {
  Grid,
  Card,
  CardContent,
  Typography,
  Button,
  colors,
  TableContainer,
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  CircularProgress,
  FormControlLabel,
  Tooltip,
  TableFooter
} from "@material-ui/core";
import JobCard from "./JobCard";
import ContactsModal from "./ContactsModal";
import { AddressLink, ConfirmModal, GreenCheckbox } from "components";
import MuiAccordion from "@material-ui/core/Accordion";
import MuiAccordionSummary from "@material-ui/core/AccordionSummary";
import MuiAccordionDetails from "@material-ui/core/AccordionDetails";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import axios from "utils/axios";
import apiConfig from "apiConfig";
import { createTheme, MuiThemeProvider } from "@material-ui/core/styles";
import AttachmentsModal from "./AttachmentsModal";
import {ArrowDownward, ArrowUpward} from "@material-ui/icons";
import Skeleton from "react-loading-skeleton";

const useStyles = makeStyles(theme => ({
  cardContent: {
    padding: "16px"
  },
  btnBox: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "flex-start",
    alignItems: "flex-end",
    [theme.breakpoints.down('sm')]: {
      alignItems: "flex-start",
    },
    [theme.breakpoints.down('xs')]: {
      '& > button': {
        width: '100%'
      }
    },
  },
  btn: {
    width: "145px",
    color: theme.palette.white,
    backgroundColor: colors.green[600],
    "&:hover": {
      backgroundColor: colors.green[900]
    }
  },
  name: {
    fontSize: "18px",
    marginBottom: "20px",
    [theme.breakpoints.down('xs')]: {
      marginBottom: 0
    },
  },
  infor: {
    fontSize: "16px"
  },
  jobs: {
    width: "100%",
    fontSize: "18px",
    fontWeight: "bold",
    textAlign: "center"
  },
  headCell: {
    padding: "8px 16px",
    borderBottom: "1px solid #969696",
    cursor: 'pointer',
    '& span' : {
      display: 'flex'
    }
  },
  showall: {
    marginTop: "8px",
    cursor: "pointer",
    color: colors.green[600],
    "&:hover": {
      color: colors.green[900]
    }
  },
  sendText: {
    margin: 0,
    "& span": {
      padding: 0
    }
  },
  actionBox: {
    width: "100%",
    height: "100%",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    position: "absolute",
    top: 0,
    left: 0,
    zIndex: 2,
    backgroundColor: "#0000001f"
  },
  iconSort: {
    marginBottom: '-5px',
    marginLeft: '10px'
  },
  customTableCell: {
    borderBottom: '1px solid #969696',
    cursor: 'pointer'
  },
  addressLink: {
    textDecoration: 'underline',
    color:'#0000EE'
  }
}));

const useDelayValue = (val, delay) => {
  delay = delay || 500;
  const [debounced, setDebounced] = useState(val);

  useEffect(() => {
    const handler = setTimeout(() => {
      setDebounced(val);
    }, delay);

    return () => clearTimeout(handler);
  }, [val, delay]);

  return debounced;
}

const Accordion = withStyles({
  root: {
    boxShadow: "none",
    "&:not(:last-child)": {
      borderBottom: 0
    },
    "&:before": {
      display: "none"
    },
    "&$expanded": {
      margin: "auto"
    }
  },
  expanded: {}
})(MuiAccordion);

const AccordionSummary = withStyles({
  root: {
    position: "relative",
    borderBottom: "1px solid #e2e2e2",
    marginBottom: -1,
    minHeight: 56,
    "&$expanded": {
      minHeight: 56
    }
  },
  content: {
    flexGrow: 'unset',
    "&$expanded": {
      margin: "12px 0"
    }
  },
  expanded: {}
})(MuiAccordionSummary);

const AccordionDetails = withStyles(theme => ({
  root: {
    display: "block",
    padding: 0,
    "& p": {
      padding: "16px",
      fontSize: "16px",
      fontWeight: 500
    }
  }
}))(MuiAccordionDetails);

let mounted = false;

const customThemeTooltip = createTheme({
  overrides: {
    MuiTooltip: {
      tooltip: {
        fontSize: "14px",
        maxWidth: "none"
      }
    }
  }
});

const JobAddressCard = props => {
  const classes = useStyles();
  const history = useHistory();
  const {
    jobAddress,
    expandJob,
    onChangeAccordion,
    states,
    update,
    updateJobAddress,
    remove,
    removeJob,
    phoneTypes,
    index,
    onCheckCombineOutside,
    reloadJobsTest,
    redirectToNewJob,
    onScheduleJob
  } = props;

  const { address, jobCount } = jobAddress;

  const cardRef = useRef(null);

  const search = window.location.search;
  const params = new URLSearchParams(search);
  const activeAttachments = params.get('active_attachments');

  useEffect(() => {
    if(activeAttachments && activeAttachments == address.id) {
      setOpenAttachmentsModal(true);
    }
  }, [activeAttachments]);

  const { contacts } = address;
  contacts.sort((contact1, contact2) => {
    return contact1.name > contact2.name
      ? 1
      : contact1.name < contact2.name
      ? -1
      : 0;
  });
  const primaryIndex = contacts.findIndex(con => con.isPrimary);
  if (primaryIndex > -1) {
    const primaryContact = contacts[primaryIndex];
    contacts.splice(primaryIndex, 1);
    contacts.unshift(primaryContact);
  }
  const contact =
    (contacts && contacts.find(contact => contact.isPrimary)) || contacts[0];

  const stateId = address.stateId || null;
  const state = (stateId ? states.find(state => state.id === stateId)?.name : "") ?? "";
  const nameAddress = address.name || "--";

  const [combineJobs, setCombineJobs] = useState([]);
  const [jobsCount, setJobsCount] = useState(jobCount);
  const [jobs, setJobs] = useState([]);
  const [jobsSorted, setJobsSorted] = useState([]);
  const [isProgress, setIsProgress] = useState(false);
  const [isRemoveJob, setIsRemoveJob] = useState(false);
  const [openModal, setOpenModal] = useState(false);
  const [jobId, setJobId] = useState();
  const [confirm, setConfirm] = useState({
    open: false,
    title: null,
    onConfirm: null
  });
  const [pageCount, setPageCount] = useState(0);
  const [openAttachmentsModal, setOpenAttachmentsModal] = useState(false);
  const [pageNum, setPageNum] = useState(1);
  const [sortBy, setSortBy] = useState('Date');
  const [sortType, setSortType] = useState('DESC');
  const [intersectionObserver, setIntersectionObserver] = useState();
  const [_cardVisible, setCardVisible] = useState(false);
  const cardVisible = useDelayValue(_cardVisible);

  const updateAddress = () => {
    updateJobAddress();
    setOpenModal(false);
  };

  const onCheckCombine = (event, job) => {
    if (event.target.checked) {
      setCombineJobs([...combineJobs, job]);
    } else {
      const _combineJobs = [...combineJobs];
      const indexJob = _combineJobs.findIndex(j => j.id === job.id);
      _combineJobs.splice(indexJob, 1);
      setCombineJobs(_combineJobs);
    }
    onCheckCombineOutside(event, job);
  };
  const onRemoveJob = () => {
    setIsRemoveJob(true);
    axios
      .delete(
        apiConfig.url.BASE_URL + apiConfig.url.CUSTOMER_JOB_DELETE + jobId
      )
      .then(() => {
        const indexJob = jobs.findIndex(job => job.id === jobId);
        const _jobs = [...jobs];
        const _combineJobs = [...combineJobs];
        _jobs.splice(indexJob, 1);
        _combineJobs.splice(_combineJobs.indexOf(jobId), 1);
        setJobs(_jobs);
        setCombineJobs(_combineJobs);
        setJobsCount(jobsCount - 1);
        removeJob({ failed: false, msg: "Record Deleted" });
      })
      .catch(() => {
        removeJob({
          failed: true,
          msg: "Delete failed, please try again later."
        });
      })
      .finally(() => {
        setIsRemoveJob(false);
        setConfirm({ open: false, title: null, onConfirm: null });
        setJobId(null);
      });
  };
  const redirectToJob = jobId => {
    sessionStorage.setItem(
      "sw-address",
      JSON.stringify({ ...address, stateName: state })
    );
    const path = history.location.pathname.replace(
      "/job-addresses",
      `/jobs/${jobId}/information`
    );
    history.push(path);
  };

  let forcePage = 0;
  let pageSize = 10;

  const loadMore = (page) => {
    setPageNum(page);
    fetchJob(page);
  }

  const loadAll = () => {
    pageSize = pageCount * 10;
    fetchJob(1,true);
  }

  useEffect(() => {
    if (jobId) {
      setConfirm({
        open: true,
        title: "Are you sure you want to delete this item?",
        onConfirm: onRemoveJob
      });
    }
  }, [jobId]);

  const getSortIcon = (type) => {
    return (
        sortBy == type &&
        (
            sortType == 'ASC' ?
                <ArrowUpward className={classes.iconSort}/>
                :
                <ArrowDownward className={classes.iconSort}/>
        )
    )
  }

  const fetchJob = (page, loadAll = false) => {
    setIsProgress(true);
    axios
        .get(
            apiConfig.url.BASE_URL + apiConfig.url.CUSTOMER_JOBS + address.id, {
              params: {
                pageNumber: page,
                pageSize: pageSize,
                sortBy: sortBy,
                sortType: sortType,
                limit: 0,
              }
            }
        )
      .then(res => {
        res.data.data.map(function(e){
          e.address_id = address.id;
        });
        setJobs(loadAll ? res.data.data : page === 1 ? res.data.data : [...jobs, ...res.data.data]);
        setPageCount(res.data.pageCount);
      })
      .finally(() => {
        setIsProgress(false);
      });
  };
  const loadDocument = (type) => {
    setSortBy(type)
    setSortType(type == sortBy ? (sortType === 'ASC' ? 'DESC' : 'ASC') : 'ASC');
  }
  useEffect(() => {
    let _job =  jobs.map(job => {
      let date = '';
      if (job.bidStageId == '1') {
        if(job.dateCreated){
          date = (job.dateCreated)
        }
      }
      if (job.bidStageId == '2') {
        if(job.bidCompleted){
          date = (job.bidCompleted)
        }
      }
      if (job.bidStageId == '3') {
        date = ('');
      }
      if (job.bidStageId == '4') {
        if(job.scheduledDate){
          date = (job.scheduledDate)
        }
      }
      if (job.bidStageId == '5') {
        if(job.completedDate){
          date = (job.completedDate)
        }
      }
      if (job.bidStageId == '6') {
        if(job.scheduledDate){
          date = (job.scheduledDate)
        }
      }
      return  {...job, date : date}
    })
    // _job = _job.sort((a, b) => new Date(b.date || '01/01/1970') - new Date(a.date || '01/01/1970'));
    setJobsSorted(_job);

  },[jobs])

  useEffect(() => {
    mounted = true;
    if (expandJob && mounted) {
      if (jobs.length == 0) {
        fetchJob(1);
      }
    }
    return () => (mounted = false);
  }, [expandJob]);

  useEffect(() => {
    if (expandJob) {
      fetchJob(1,true);
    }
  }, [sortBy, sortType])

  useEffect(() => {
    mounted = true;
    if (reloadJobsTest && mounted && combineJobs && combineJobs.length > 0) {
      if(reloadJobsTest.id == combineJobs[0].id) {
        fetchJob(1);
        setJobsCount(jobsCount + 1);
      }
    }
    return () => (mounted = false);
  }, [reloadJobsTest]);

  useEffect(() => {
    if (cardVisible) intersectionObserver.unobserve(cardRef.current);
  }, [cardVisible]);

  useEffect(() => {
    const cardEl = cardRef.current;
    const observer = new IntersectionObserver(function(entries) {
      // isIntersecting is true when element and viewport are overlapping
      // isIntersecting is false when element and viewport don't overlap
      setCardVisible(entries[0].isIntersecting);
    }, { threshold: [0] });

    setIntersectionObserver(observer);
    
    observer.observe(cardEl);

    return () => observer.unobserve(cardEl);
  }, []);

  if (!cardVisible) {
    return (
      <Card style={{ marginTop: index == 0 ? "10px" : "24px" }} ref={cardRef}>
        <CardContent className={classes.cardContent}>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={5}>
              <Skeleton height={21} width="40%" style={{ marginBottom: 20 }} />
              <Skeleton count={2} width="50%" />
            </Grid>
            <Grid item xs={12} sm={5}>
              <Skeleton height={21} width="40%" style={{ marginBottom: 20 }} />
              <Skeleton width="30%" />
              <Skeleton width={100} />
              <Skeleton width={200} />
              <Skeleton width={80} />
              <Skeleton width={120} style={{ marginTop: 21 }} />
            </Grid>
          </Grid>
        </CardContent>
        <div></div>
      </Card>
    );
  }

  return (
    <Card style={{ marginTop: index == 0 ? "10px" : "24px" }} ref={cardRef}>
      <CardContent className={classes.cardContent}>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={5}>
            <Typography className={classes.name}>
              <strong>
                {nameAddress}
                <MuiThemeProvider theme={customThemeTooltip}>
                  <Tooltip arrow title={"Edit job addresses"}>
                    <a onClick={update} target="_blank">
                      <i
                        style={{
                          marginLeft: "10px",
                          fontSize: "16px",
                          color: "#263238",
                          cursor: "pointer"
                        }}
                        className="fa fa-pencil"
                      />
                    </a>
                  </Tooltip>
                </MuiThemeProvider>
              </strong>
            </Typography>
            <AddressLink address={{ ...address, state: { name: state } }} className={classes.infor} />
          </Grid>
          <Grid item xs={12} sm={5}>
            <Typography className={classes.name}>
              <strong>Primary Contact</strong>
            </Typography>
            <Typography>
              <strong>{(contact && contact.name) || ""}</strong>
            </Typography>
            <Typography>{(contact && contact.phone) || ""}</Typography>
            <Typography>{(contact && contact.email) || ""}</Typography>
            <Typography>
              <FormControlLabel
                disabled
                className={classes.sendText}
                label="Send Text"
                checked={(contact && contact.isSendText) || false}
                control={<GreenCheckbox />}
              />
            </Typography>
            <Typography
              className={classes.showall}
              onClick={() => setOpenModal(true)}
            >
              Show all contacts...
            </Typography>
          </Grid>
          <Grid item xs={12} sm={2} className={classes.btnBox}>
            <Button
              style={{ marginBottom: "8px" }}
              className={classes.btn}
              onClick={() => redirectToJob(" ")}
            >
              Add Job
            </Button>
            <Button style={{ marginBottom: "8px" }} className={classes.btn} onClick={remove}>
              Remove Address
            </Button>
            <Button className={classes.btn} onClick={() => setOpenAttachmentsModal(true)}>
              Attachments
            </Button>
          </Grid>
        </Grid>
      </CardContent>
      <Accordion
        style={jobCount === 0 ? { display: "none" } : null}
        expanded={expandJob}
        onChange={onChangeAccordion}
        className={classes.accordion}
      >
        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
          <Typography className={classes.jobs}>Jobs ({jobsCount})</Typography>
        </AccordionSummary>
        <AccordionDetails>
          {jobs && jobs.length === 0 ? (
            !isProgress && <Typography>No data found</Typography>
          ) : (
            <>
              
              <TableContainer>
                {isProgress && (
                  <div className={classes.actionBox}>
                    <CircularProgress size={32} />
                  </div>
                )}
                <Table>
                  <TableHead>
                    <TableRow>
                      <TableCell onClick={() => loadDocument('Stage')} className={classes.headCell}>
                        <span>Stage{getSortIcon('Stage')}</span>
                      </TableCell>
                      <TableCell onClick={() => loadDocument('Date')} className={classes.headCell}>
                        <span>Stage Date{getSortIcon('Date')}</span>
                      </TableCell>
                      <TableCell onClick={() => loadDocument('DateCreated')} className={classes.headCell}>
                        <span>Date Created{getSortIcon('DateCreated')}</span>
                      </TableCell>
                      <TableCell className={classes.headCell} onClick={() => loadDocument('InvoiceNumber')}>
                        <span>Invoice Number{getSortIcon('InvoiceNumber')}</span>
                      </TableCell>
                      <TableCell className={classes.headCell} onClick={() => loadDocument('PurchaseOrder')}>
                        <span>Purchase Order{getSortIcon('PurchaseOrder')}</span>
                      </TableCell>
                      <TableCell onClick={() => loadDocument('JobDescription')} className={classes.headCell}>
                        <span>Job Description{getSortIcon('JobDescription')}</span>
                      </TableCell>
                      <TableCell onClick={() => loadDocument('Total')} className={classes.headCell}>
                        <span>Total{getSortIcon('Total')}</span>
                      </TableCell>
                      <TableCell
                          style={{width: "1px"}}
                          className={classes.headCell}
                      >
                        Select
                      </TableCell>
                      <TableCell
                        style={{ width: "1px" }}
                        className={classes.headCell}
                      ></TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {jobsSorted &&
                    jobsSorted.map((job, index) => (
                        <JobCard
                          selected={combineJobs}
                          data={JSON.stringify({ ...address, state })}
                          key={job.id}
                          job={job}
                          remove={() => setJobId(job.id)}
                          onCheckCombine={event => onCheckCombine(event, job)}
                        />
                      ))}
                  </TableBody>
                  <TableFooter>
                    <TableRow>
                      <TableCell colSpan={7} align={"center"}>
                        <div style={{marginTop: "16px auto"}}>
                          {pageNum < pageCount
                          && <><Button
                              color="primary"
                              variant="outlined"
                              disabled={isProgress} onClick={() => loadMore(pageNum + 1)}>Load more</Button>

                            <Button
                                style={{marginLeft: '20px'}}
                                color="primary"
                                variant="outlined"
                                disabled={isProgress} onClick={() => loadAll()}>Load all</Button>
                            {/*<Pagination*/}
                            {/*  pageCount={pageCount}*/}
                            {/*  forcePage={forcePage}*/}
                            {/*  onPageChange={onPageChange}*/}
                            {/*/>*/}
                          </>}
                        </div>
                      </TableCell>
                    </TableRow>
                  </TableFooter>
                </Table>
              </TableContainer>
            </>
          )}
          {isProgress && jobs.length == 0 && (
            <div style={{ textAlign: "center", padding: "8px" }}>
              <CircularProgress size={24} />
            </div>
          )}
        </AccordionDetails>
      </Accordion>

      <ContactsModal
        open={openModal}
        onClose={() => setOpenModal(false)}
        address={address}
        update={updateAddress}
        phoneTypes={phoneTypes}
      />

      <ConfirmModal
        openConfirm={confirm.open}
        title={confirm.title}
        isProgress={isRemoveJob}
        closeConfirm={() => {
          setConfirm({ open: false, title: null, onConfirm: null });
          jobId && setJobId(null);
        }}
        onConfirm={confirm.onConfirm}
      />

      <AttachmentsModal
        open={openAttachmentsModal}
        onClose={() => setOpenAttachmentsModal(false)}
        addressId={address.id}
      />
    </Card>
  );
};

JobAddressCard.propTypes = {
  jobAddress: PropTypes.object,
  expandJob: PropTypes.bool,
  onChangeAccordion: PropTypes.func,
  states: PropTypes.array,
  jobs: PropTypes.array,
  remove: PropTypes.func,
  removeJob: PropTypes.func,
  updateJobAddress: PropTypes.func,
  onCheckCombineOutside: PropTypes.func,
  reloadJobsTest: PropTypes.bool
};

export default JobAddressCard;
