import React, {useState} from "react";
import {
    Button,
    Card,
    CardActions,
    CardContent,
    CardHeader,
    colors,
    Divider,
    FormControlLabel,
    Grid,
    IconButton,
    Modal,
    Paper,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableRow,
    TextField
} from "@material-ui/core";
import {makeStyles} from "@material-ui/styles";
import AddIcon from "@material-ui/icons/Add";
import {GreenCheckbox, GreenRadio} from "../../../../components";
import CloseIcon from "@material-ui/icons/Close";
import {AddBox, DeleteOutlined, FileCopyOutlined} from "@material-ui/icons";
import {KeyboardDatePicker, MuiPickersUtilsProvider} from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";

const useStyles = makeStyles(theme => ({
    styleModal: {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        outline: 'none',
        boxShadow: theme.shadows[20],
        width: "70%",
        maxHeight: '100%',
        overflowY: 'auto',
        maxWidth: '100%',
        [theme.breakpoints.down('sm')]: {
            width: "100%"
        },
        fontSize: '16px'
    },
    conButton: {
        padding: '16px',
        justifyContent: 'flex-end'
    },
    buttonSave: {
        flexShrink: '0',
        backgroundColor: colors.green[600],
        '&:hover': {
            backgroundColor: colors.green[900]
        },
    },
    timeCheckbox: {
        marginLeft: '16px',
        width: '100%'
    },
    time: {
        width: '100%',
        marginRight: '16px'
    },
    btn: {
        fontSize: 12,
        color: theme.palette.white,
        backgroundColor: colors.green[600],
        whiteSpace: 'nowrap',
        marginLeft: 8,
        '&:hover': {
            backgroundColor: colors.green[900]
        }
    },
    textField: {
        fontSize: '16px'
    }
}))


const ModalPreview = props => {
    const classes = useStyles();

    const {openConfirm, closeConfirm, formData} = props;

    const [formDataEdit, setFormData] = useState();

    const setFieldTouched = (index, field) => {

    }

    return (
        <Modal open={openConfirm}
               style={{minWidth: '500px'}}>
            <Card className={classes.styleModal}>
                <CardContent style={{padding: '50px 100px'}}>
                    <Grid container spacing={3}>
                        <Grid item xs={12}>
                            <div style={{
                                fontSize: '32px',
                                color: '#69a84f',
                                textTransform: 'uppercase',
                                fontWeight: "bold"
                            }}>{formData.formName}</div>
                            <p style={{
                                fontSize: '28px',
                                color: '#69a84f',
                            }}>{formData.formDescription}</p>
                            <hr
                                style={{
                                background: 'rgb(191,202,210)',
                                height: "1px",
                                border: "none",
                                marginTop: '10px'
                                }}
                                />
                        </Grid>
                        <Grid item xs={12}>
                            {
                                formData.questions && formData.questions.length > 0 && formData.questions.map((question, index) => (
                                    <>
                                        <Grid container spacing={3} style={{marginBottom: '16px'}}>
                                            <Grid item xs={12}>
                                                <p>{index + 1 + '. ' + question.questionName}</p>
                                            </Grid>
                                            <Grid item xs={12}>
                                                {
                                                    question.questionControlType == 'textbox' &&
                                                    <TextField
                                                        InputProps={{
                                                            classes: {
                                                                input: classes.textField,
                                                            },
                                                        }}
                                                        style={{fontSize: '16px'}}
                                                        fullWidth
                                                        label={''}
                                                        name='answer'
                                                        variant='outlined'
                                                        onBlur={() => {
                                                            setFieldTouched(index, 'answer');
                                                        }}
                                                        onChange={event => {
                                                            let data = {...formData};
                                                            data.questions[index].answer = event.target.value
                                                            setFormData(data)
                                                        }}
                                                        value={question.answer.answer || ''}
                                                    />
                                                }
                                                {
                                                    question.questionControlType == 'radiobutton' &&
                                                    <>
                                                        {
                                                            question.answer && question.answer && question.answer.answer.length && question.answer.answer.map((option, indexOption) => (
                                                                <>
                                                                    <div style={{
                                                                        display: 'block',
                                                                        // padding: '12px'
                                                                    }}>
                                                                        <FormControlLabel
                                                                            name={'option_'+index}
                                                                            style={{fontSize:'16px'}}
                                                                            label={<span style={{ fontSize: '16px' }}>{option.label}</span>}
                                                                            control={
                                                                                <GreenRadio/>
                                                                            }
                                                                        />
                                                                    </div>
                                                                </>
                                                            ))
                                                        }
                                                    </>

                                                }
                                                {
                                                    question.questionControlType == 'checkbox' &&
                                                    <>
                                                        {
                                                            question.answer && question.answer && question.answer.answer.length && question.answer.answer.map((option, indexOption) => (
                                                                <>
                                                                    <div style={{
                                                                        display: 'block',
                                                                        // padding: '12px'
                                                                    }}>
                                                                        <FormControlLabel
                                                                            style={{fontSize: '16px'}}
                                                                            name=''
                                                                            className={classes.taxCheckbox}
                                                                            label={<span style={{ fontSize: '16px' }}>{option.label}</span>}
                                                                            control={
                                                                                <GreenCheckbox/>
                                                                            }
                                                                        />
                                                                    </div>
                                                                </>
                                                            ))
                                                        }
                                                    </>

                                                }
                                                {
                                                    question.questionControlType == 'datepicker' &&
                                                    <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                                        <KeyboardDatePicker
                                                            InputProps={{
                                                                classes: {
                                                                    input: classes.textField,
                                                                },
                                                            }}
                                                            style={{fontSize:'16px'}}
                                                            fullWidth
                                                            inputVariant="outlined"
                                                            label='Month/Date/Year'
                                                            format="MM/dd/yyyy"
                                                            value={question.answer.answer || null}
                                                            InputAdornmentProps={{position: "end"}}
                                                            onChange={date => {
                                                                let data = {...formData};
                                                                data.questions[index].answer = date
                                                                setFormData(data)
                                                            }}
                                                        />
                                                    </MuiPickersUtilsProvider>
                                                }
                                                {
                                                    question.questionControlType == 'table' &&
                                                    <Grid item xs={12}>
                                                        <TableContainer component={Paper}>
                                                            <Table className={classes.table}
                                                                   aria-label="simple table">
                                                                <TableBody>
                                                                    {question.answer.answer.rows && new Array(parseInt(question.answer.answer.rows)).fill(0).map((row, indexRow) => (
                                                                        <TableRow key={index}>
                                                                            {question.answer.answer.columns && new Array(parseInt(question.answer.answer.columns)).fill(0).map((row, indexColum) => (
                                                                                <TableCell
                                                                                    style={{border: "1px solid #cccccc", padding: '0'}}
                                                                                    component="th"
                                                                                    scope="row">
                                                                                    <TextField
                                                                                        style={{fontSize: '16px', backgroundColor: indexRow == 0 ? '#e3e3e3' : (indexRow % 2 == 0 ? "#f1f1f1": 'initial')}}
                                                                                        InputProps={{
                                                                                            classes: {
                                                                                                input: classes.textField,
                                                                                            },
                                                                                            style: {
                                                                                                borderRadius: "0"
                                                                                            }
                                                                                        }}
                                                                                        fullWidth
                                                                                        variant='outlined'
                                                                                        value={(question.answer.answer.headers && indexRow == 0 && question.answer.answer.headers[indexColum]) || ''}
                                                                                    />
                                                                                </TableCell>
                                                                            ))}
                                                                        </TableRow>
                                                                    ))}
                                                                </TableBody>
                                                            </Table>
                                                        </TableContainer>
                                                    </Grid>
                                                }
                                                {
                                                    question.questionControlType == 'textarea' &&
                                                    <TextField
                                                        InputProps={{
                                                            classes: {
                                                                input: classes.textField,
                                                            },
                                                        }}

                                                        multiline={true}
                                                        rows={5}
                                                        fullWidth
                                                        label={''}
                                                        name='answer'
                                                        variant='outlined'
                                                        onChange={event => {
                                                            let data = {...formData};
                                                            data.questions[index].answer = event.target.value
                                                            setFormData(data)
                                                        }}
                                                        value={question.answer.answer || ''}
                                                    />
                                                }
                                            </Grid>
                                        </Grid>
                                    </>
                                ))
                            }
                        </Grid>

                    </Grid>
                </CardContent>
                <CardActions className={classes.conButton}>
                    <div style={{float: 'right'}}>
                        <Button
                            variant="contained"
                            size="large"
                            onClick={() => {
                                closeConfirm()
                            }}>
                            Close
                        </Button>
                    </div>

                </CardActions>
            </Card>
        </Modal>
    )
}
export default ModalPreview
