import { Card, CardContent, CardHeader, CircularProgress, Collapse, 
    Divider, FormControlLabel, IconButton, Table, TableBody, 
    TableCell, TableContainer, TableHead, TableRow, Typography, useMediaQuery,
    Tooltip, colors, Button
} from '@material-ui/core';
import { Delete, GetApp, KeyboardArrowDown, KeyboardArrowUp } from '@material-ui/icons';
import { makeStyles } from '@material-ui/styles';
import { GreenCheckbox, Pagination } from 'components';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import OpenInNewIcon from '@material-ui/icons/OpenInNew';
import AddAttachmentCustomer from '../../../CustomerDetails/components/Attachments/components/AddAttachment'
import AddAttachmentCustomerAddress from '../../../CustomerDetails/components/JobAddresses/components/AddAttachment'
import AddAttachmentJob from '../../../CustomerJobs/components/Attachments/components/AddAttachment'
import AddAttachmentEmployeeDetails from '../../../EmployeeDetails/components/Attachments/components/AddAttachment'

const useStyles = makeStyles(theme => ({
    cardHeader: {
        cursor: 'pointer'
    },
    loading: {
        textAlign: 'center',
    },
    noData: {
        fontSize: theme.spacing(2),
        fontWeight: 500
    },
    table: {
        minWidth: 1000
    },
    cusFontsize: {
        '& th': {
            fontSize: 15
        }
    },
    customTableCell: {
        borderBottom: '1px solid #969696'
    },
    thumbnail: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center'
    },
    openImage: {
        color: '#263238',
        '&:hover': {
            opacity: 0.7
        }
    },
    paginate: {
        padding: '10px',
        '& ul': {
            justifyContent: 'center'
        }
    },
    boxCustomers: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center'
    },
    btnAction: {
        color: theme.palette.white,
        backgroundColor: colors.green[600],
        "&:hover": {
          backgroundColor: colors.green[900]
        }
    },
}));

const DocumentsCard = props => {

    const {
        type,
        title,
        totalCount,
        loading,
        disabled,
        data,
        pageCount,
        onDownload,
        onDelete,
        onUpdate,
        onPageChange,
        isEnableExpiringDocuments,
        onReload
    } = props;

    const classes = useStyles();
    const matches = useMediaQuery('(min-width:992px)');

    const [expand, setExpand] = useState(true);
    
    useEffect(() => {
        setExpand(loading || totalCount > 0);
    }, [loading]);


    const [openEditCustomerDocument, setOpenEditCustomerDocument] = useState(false);
    const [openEditCustomerAddressDocument, setOpenEditCustomerAddressDocument] = useState(false);
    const [openEditJobDocument, setOpenEditJobDocument] = useState(false);
    const [openEditEmployeeDetailDocument, setOpenEditEmployeeDetailDocument] = useState(false);
    const [paramId, setParamId] = useState();
    const [documentId, setDocumentId] = useState();
    const [addressId, setAddressId] = useState();
    const [jobId, setJobId] = useState();
    const handleEditOpen = () => {
        if(type == 0) {
            setOpenEditCustomerDocument(true);
        }
        if(type == 1) {
            setOpenEditCustomerAddressDocument(true);
        }
        if(type == 2) {
            setOpenEditJobDocument(true);
        }
        if(type == 3) {
            setOpenEditEmployeeDetailDocument(true);
        }
    }
    const handleEditClose = () => {
        if(type == 0) {
            setOpenEditCustomerDocument(false);
        }
        if(type == 1) {
            setOpenEditCustomerAddressDocument(false);
        }
        if(type == 2) {
            setOpenEditJobDocument(false);
        }
        if(type == 3) {
            setOpenEditEmployeeDetailDocument(false);
        }
    }
    const onUpdateFiles = file => {
        onReload(type);
        resetParams();
    }

    function resetParams() {
        setParamId();
        setDocumentId();
        setAddressId();
        setJobId();
    }

    function handleRenew(file) {
        setDocumentId(file.id);
        if(file.customerId) {
            setParamId(file.customerId);
        } else {
            setParamId(file.userId);
        }
        if(file.address && file.address.id) {
            setAddressId(file.address.id);
        }
        if(file.jobId) {
            setJobId(file.jobId);
        }
        handleEditOpen()
    }

    return (
        <>
            <AddAttachmentCustomer
                onUpdateAttachments={onUpdateFiles}
                onClose={handleEditClose}
                customerId={paramId}
                documentOldId={documentId}
                open={openEditCustomerDocument} 
            />
            <AddAttachmentCustomerAddress
                onUpdateAttachments={onUpdateFiles}
                onClose={handleEditClose}
                addressId={addressId}
                documentOldId={documentId}
                customerId={paramId}
                open={openEditCustomerAddressDocument}
            />
            <AddAttachmentJob
                onUpdateAttachments={onUpdateFiles}
                onClose={handleEditClose}
                jobId={jobId}
                customerId={paramId}
                documentOldId={documentId}
                open={openEditJobDocument} 
            />
            <AddAttachmentEmployeeDetails
                userId={paramId}
                onUploadFile={onUpdateFiles}
                onClose={handleEditClose}
                documentOldId={documentId}
                open={openEditEmployeeDetailDocument}
            />
            <Card>
                <CardHeader
                    className={classes.cardHeader}
                    title={`${title} (${totalCount ?? 0})`}
                    action={expand ? <KeyboardArrowUp /> : <KeyboardArrowDown />}
                    onClick={() => setExpand(!expand)}
                />
                <Divider />
                <Collapse in={expand} timeout='auto' unmountOnExit>
                    <CardContent>
                        {
                            loading
                            ? <div className={classes.loading}><CircularProgress size={30} /></div>
                            : <TableContainer>
                                <Table className={classes.table}>
                                    <TableHead className={classes.customTableHead}>
                                        <TableRow className={classes.cusFontsize}>
                                            <TableCell style={{ width: (type == 3 ? '12%' : '23%') }} className={classes.customTableCell}>{type == 3 ? "Employee" : "Customer"}</TableCell>
                                            <TableCell style={{ width: '22%' }} className={classes.customTableCell}>File Name</TableCell>
                                            {type !== 3 && <TableCell style={{ width: '25%' }} className={classes.customTableCell}>Note</TableCell>}
                                            <TableCell className={classes.customTableCell}>Uploaded By</TableCell>
                                            <TableCell className={classes.customTableCell}>Date Uploaded</TableCell>
                                            <TableCell className={classes.customTableCell}>Expiration Date</TableCell>
                                            {matches ? <TableCell className={classes.customTableCell}>Size</TableCell> : null}
                                            <TableCell className={classes.customTableCell} style={{ width: '1%' }} />
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {data?.length ? data.map((file, index) => (
                                            <TableRow key={file.id}>
                                                {type == 3
                                                ? <TableCell>
                                                    <div className={classes.boxCustomers}>
                                                        {`${file.firstName || ''} ${file.lastName || ''}`}
                                                        <Tooltip arrow title={'View'}>
                                                            <a 
                                                                style={{ cursor: 'pointer', color: 'black' }}
                                                                href={`/employees/${encodeURIComponent(file.firstName) || ''} ${file.lastName || ''}/${file.userId}/attachments`}
                                                            >
                                                                <OpenInNewIcon style={{ fontSize: 20 }} />
                                                            </a>
                                                        </Tooltip>
                                                    </div>
                                                </TableCell>
                                                : <TableCell>
                                                    <div className={classes.boxCustomers}>
                                                        {`${file.customerName || ''}`}
                                                        {`${type == 1 || type == 2 && file.address ? `\n`+file.address.address1 : ""}`}
                                                        {`${type == 1 || type == 2 && file.address ? `\n`+file.address.city+"," : ""}`} {`${type == 1 || type == 2 && file.address && file.address.state ? file.address.state.code : ""}`} {`${type == 1 || type == 2 && file.address ? file.address.zipCode : ""}`}
                                                        {type == 0
                                                            ? <Tooltip arrow title={'View'}>
                                                                    <a 
                                                                        style={{ cursor: 'pointer', color: 'black' }}
                                                                        href={`/customers/${encodeURIComponent(file.customerName) || ''}/${file.customerId || ''}/attachments`}
                                                                    >
                                                                        <OpenInNewIcon style={{ fontSize: 20 }} />
                                                                    </a>
                                                                </Tooltip>
                                                            : null
                                                        }
                                                        {type == 1
                                                            ? <Tooltip arrow title={'View'}>
                                                                    <a 
                                                                        style={{ cursor: 'pointer', color: 'black' }}
                                                                        href={`/customers/${encodeURIComponent(file.customerName) || ''}/${file.customerId || ''}/job-addresses?active_attachments=${file.jobAddressId || ''}`}
                                                                    >
                                                                        <OpenInNewIcon style={{ fontSize: 20 }} />
                                                                    </a>
                                                                </Tooltip>
                                                            : null
                                                        }
                                                        {type == 2
                                                            ? <Tooltip arrow title={'View'}>
                                                                    <a 
                                                                        style={{ cursor: 'pointer', color: 'black' }}
                                                                        href={`/customers/${encodeURIComponent(file.customerName) || ''}/${file.customerId || ''}/jobs/${file.jobId || ''}/attachments`}
                                                                    >
                                                                        <OpenInNewIcon style={{ fontSize: 20 }} />
                                                                    </a>
                                                                </Tooltip>
                                                            : null
                                                        }
                                                    </div>
                                                </TableCell>
                                                }
                                                <TableCell>
                                                    <div className={classes.thumbnail}>
                                                        <a className={classes.openImage} href={file.documentUrl} target='_blank'>
                                                            {file.filename}
                                                        </a>
                                                        <img width='30px' height='auto' src='/images/thumbnail/file.png' alt='thumb' />
                                                    </div>
                                                </TableCell>
                                                {type !== 3 && <TableCell>{file.note}</TableCell>}
                                                <TableCell>{file.uploadedBy}</TableCell>
                                                <TableCell>{moment(file.dateUploaded).format('MM/DD/YYYY')}</TableCell>
                                                <TableCell>{moment(file.expirationDate).format('MM/DD/YYYY')}</TableCell>
                                                {matches ? <TableCell>{file.size}</TableCell> : null}
                                                
                                                <TableCell style={{ whiteSpace: 'nowrap' }}>
                                                    {isEnableExpiringDocuments
                                                    && <Button
                                                        variant={"contained"}
                                                        className={classes.btnAction}
                                                        onClick={() => handleRenew(file)}
                                                        style={{ marginRight: 10 }}
                                                    >
                                                        RENEW
                                                    </Button>
                                                    }
                                                    {isEnableExpiringDocuments
                                                    && <Tooltip title="Remove From Expiring Documents"><Button
                                                        variant={"contained"}
                                                        className={classes.btnAction}
                                                        onClick={() => onDelete(file.id, 1, file.filename)}
                                                    >
                                                        REMOVE
                                                    </Button></Tooltip>
                                                    }
                                                    <IconButton onClick={() => onDownload(file.documentUrl, file.filename)}>
                                                        <GetApp />
                                                    </IconButton>
                                                    <IconButton aria-label="delete" onClick={() => onDelete(file.id, 2, file.filename)}>
                                                        <Delete />
                                                    </IconButton>
                                                </TableCell>
                                            </TableRow>
                                        )) : (
                                                <TableRow>
                                                    <TableCell colSpan={10}>
                                                        <Typography className={classes.noData}>No data found...</Typography>
                                                    </TableCell>
                                                </TableRow>
                                            )}
                                    </TableBody>
                                </Table>
                            </TableContainer>
                        }

                        {
                            pageCount > 1 &&
                            <div className={classes.paginate}>
                                <Pagination pageCount={pageCount} onPageChange={onPageChange} />
                            </div>
                        }
                    </CardContent>
                </Collapse>
            </Card>
        </>
    );
};

export default DocumentsCard;
