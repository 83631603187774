import React, { useRef, useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import { Typography } from '@material-ui/core'
import { makeStyles } from '@material-ui/styles'
import { ExpandMore, ExpandLess } from '@material-ui/icons'

const useStyles = makeStyles(theme => ({
    expandBox: {
        position: 'relative',
        padding: '8px 20px 8px 16px',
        cursor: 'pointer',
        borderBottom: '1px solid #bfbfbf'
    },
    expandIcon: {
        position: 'absolute',
        top: 8,
        right: 0,
        fontSize: 20
    },
    hiddenEquipment: {
        position: 'absolute',
        visibility: 'hidden'
    },
    equipmentString: {
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        fontWeight: 500
    },
}));

const ExpandEquipment = ({ expand, onExpand, equipmentString }) => {

    const classes = useStyles();
    const hiddenTypo = useRef();

    const [expandable, setExpandable] = useState(true);

    useEffect(() => {
        if (hiddenTypo && hiddenTypo.current) {
            const height = hiddenTypo.current.offsetHeight;
            setExpandable(height > 21)
        }
    }, [])

    return (
        <div className={classes.expandBox} onClick={onExpand}
            style={!expandable ? { pointerEvents: 'none' } : null}>
            <Typography ref={hiddenTypo} className={classes.hiddenEquipment}>{equipmentString}</Typography>
            <Typography className={classes.equipmentString}
                style={{ whiteSpace: expand ? 'normal' : 'nowrap' }}>
                {equipmentString}
            </Typography>
            {expandable && (expand ?
                <ExpandLess className={classes.expandIcon} /> :
                <ExpandMore className={classes.expandIcon} />)}
        </div>
    )
}

ExpandEquipment.propTypes = {
    expand: PropTypes.bool,
    onExpand: PropTypes.func,
    equipmentString: PropTypes.string
}

export default ExpandEquipment;
