import React from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/styles';
import {
  Grid,
  Card,
  CardContent,
  Avatar,
  Typography,
  Button,
  colors
} from '@material-ui/core';
import useMediaQuery from '@material-ui/core/useMediaQuery';

const useStyles = makeStyles(theme => ({
  root: {},
  content: {
    display: 'flex',
    alignItems: 'center',
    '&:last-child': {
      paddingBottom: 'none'
    }
  },
  contentLeft: {
    display: 'flex',
    alignItems: 'center',
    gap: theme.spacing(2),
    [theme.breakpoints.down('xs')]: {
      flexDirection: 'column'
    }
  },
  avatar: {
    height: 120,
    width: 120,
    [theme.breakpoints.down('sm')]: {
      height: 100,
      width: 100
    }
  },
  infor: {
    [theme.breakpoints.down('xs')]: {
      textAlign: 'center'
    }
  },
  alignButton: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center'
  },
  viewBtn: {
    color: theme.palette.white,
    backgroundColor: colors.green[600],
    '&:hover': {
      backgroundColor: colors.green[900]
    }
  },
}));

const CustomerCard = props => {
  const { className, customer, ...rest } = props;
  const xs = useMediaQuery(theme => theme.breakpoints.down('xs'));
  const classes = useStyles();
  const company = customer.companyName && customer.companyName.replace('N/A', ' ') || ' ';
  const contact = customer.contacts.find(x => x.isPrimary)
  return (
    <Link to={`/customers/${encodeURIComponent(company)}/${customer.id}/information`}>
      <Card {...rest}>
        <CardContent className={classes.content}>
          <Grid container spacing={3} className={classes.cusFont}>
            <Grid item xs={12} md={6} className={classes.contentLeft}>
              <Avatar
                variant={xs ? '' : 'rounded'}
                className={classes.avatar}
                src={customer.avatar || ''} />
              <div className={classes.infor}>
                <Typography gutterBottom variant='h4'>
                  {customer.companyName}
                </Typography>
                {
                  !xs && <Typography gutterBottom>
                    <span>
                      <strong>Contact: </strong> {
                        contact ?
                          `${contact && contact.firstName || ''} ${contact && contact.lastName || ''}`
                          :
                          customer.companyName.replace('N/A', '')
                      }
                    </span>
                  </Typography>
                }
                <Typography gutterBottom>
                  {
                    !xs && <strong>Address: </strong>
                  }
                  {`${customer.address && customer.address.address1 || ''}`}
                </Typography>
                <Typography style={{ paddingLeft: xs ? 0 : '60px' }} gutterBottom>
                  {`${customer.address && customer.address.address1 && ((customer.address.city || "") + ", " + (customer.address.state && customer.address.state.code || '')) + " " + customer.address.zipCode || ''}`}
                </Typography>
              </div>
            </Grid>

            <Grid item xs={12} md={5} className={classes.infor}>
              {
                xs && <Typography gutterBottom>
                  <span>
                    <strong>Contact: </strong> {
                      contact ?
                        `${contact && contact.firstName || ''} ${contact && contact.lastName || ''}`
                        :
                        customer.companyName.replace('N/A', '')
                    }
                  </span>
                </Typography>
              }
              <Typography gutterBottom>
                <strong>Email: </strong>{`${contact && contact.email || ''}`}
              </Typography>
              <Typography gutterBottom>
                <strong>Phone: </strong>
                {contact && contact.phones &&
                  `${contact.phones[0] ? contact.phones[0].formattedPhoneNumber : ''}`
                }
              </Typography>
              {
                customer.addressJobSite && customer.addressJobSite.length > 0 && !xs && 
                (
                  <>
                    <Typography gutterBottom>
                    <strong>Jobsite Address : </strong>
                      {`${customer.addressJobSite[0].address1 || ''}`}
                    </Typography>
                    <Typography style={{ paddingLeft: xs ? 0 : '60px' }} gutterBottom>
                      {`${customer.addressJobSite.length > 0 && customer.addressJobSite[0].address1 && ((customer.addressJobSite[0].city || "") + ", " + (customer.addressJobSite[0].state && customer.addressJobSite[0].state.code || '')) + " " + customer.addressJobSite[0].zipCode || ''}`}
                    </Typography>
                  </>
                )
              }
              
              {
                customer.isDeleted &&
                <Typography gutterBottom><strong>Customer Status: </strong><span style={{ color: 'red' }}>Archived</span></Typography>
              }
            </Grid>
          </Grid>
        </CardContent>
      </Card >
    </Link>
  );
};

CustomerCard.propTypes = {
  className: PropTypes.string,
  customer: PropTypes.object.isRequired
};

export default CustomerCard;
