const initState = {
    schedules: []
};

const ScheduleReducer = (state = initState, action) => {
    const schedules = [...state.schedules];
    switch (action.type) {
        case 'INIT_SCHEDULE':
            return {
                ...state,
                schedules: action.schedules
            }
        case 'ADD_SCHEDULE':
            schedules.unshift(action.schedule);
            return {
                ...state,
                schedules: schedules
            }
        case 'UPDATE_SCHEDULE':
            let schedule = schedules.indexOf(schedules.find(x => x.id === action.id));
            schedules[schedule] = action.schedule;

            return {
                ...state,
                schedules: schedules
            }
        case 'DEL_SCHEDULE':
            schedules.splice(action.index, 1);
            return {
                ...state,
                schedules: schedules
            }
        default: 
            return state;
    }
}

export default ScheduleReducer;
