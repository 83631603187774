import moment from 'moment';

export const User = {
    firstName: '',
    middleName: '',
    lastName: '',
    fullName: '',
    comments: '',
    language: null,
    languageId: null,
    address: {
        name: '',
        address1: '',
        address2: '',
        address3: '',
        city: "",
        state: {
            id: '',
            code: '',
            name: ''
        },
        country: '',
        zipCode: ''
    },
    phones: null,
    birthDate: '',
    hiredDate: '',
    firedDate: null,
    routeScheduleViewPreference: null,
    askTheSealId: '',
    comSale: null,
    salaries: {
        salaryCheckBox: false,
        hourlyCheckBox: false,
        salary: '',
        hourly: '',
        hourlyAllHours: true,
        hourlySpecificHours: false,
        laborCommission: false,
        laborPercent: '',
        laborCommissionAllJobs: true,
        laborCommissionSpecificJobs: false,
        saleCommission: false,
        salePercentNewSale: "",
        salePercentRetSale: "",
        startDate: null,
        endDate: null,
        hasValue: false,
        userId: '00000000-0000-0000-0000-000000000000'
    },
    security: {
        username: '',
        password: '',
        passwordConfirm: '',
        terminationDate: '',
        role: ''
    },
    dateCreated: moment(new Date()).format('MM/DD/YYYY'),
    email: '',
    languageOptions: null,
    states: null,
    phoneTypes: null,
}
