import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import { TextField, IconButton } from '@material-ui/core'
import { Event } from '@material-ui/icons'
import NumberFormat from 'react-number-format'
import { makeStyles } from '@material-ui/styles'
import { DatePicker } from '@material-ui/pickers'
import moment from 'moment'

const useStyles = makeStyles(theme => ({
    date: {
        width: '100%',
        position: 'relative',
    },
    icon: {
        position: 'absolute',
        top: 3,
        right: 0
    },
}))

const RouteKeyboardDate = ({ label, name, value, onChange, className, style }) => {
    const classes = useStyles();

    const [date, setDate] = useState();
    const [openPicker, setOpenPicker] = useState(false);

    const dateValid = !isNaN(new Date(date).getTime());

    const onChangeDate = event => {
        setDate(event.target.value);
        if (!event.target.value) {
            onChange(null)
        }
    }
    const onHandleChangeDate = date => {
        setDate(moment(date).format('MM/DD/YYYY'));
    }

    useEffect(() => {
        if (date && date.indexOf(' ') === -1 && dateValid) {
            const dateArray = date.split('/');
            onChange(`${dateArray[2]}-${dateArray[0]}-${dateArray[1]}`)
        }
    }, [date]);

    useEffect(() => {
        if(value){
            const dateArray = value.split('-');
            setDate(`${dateArray[1]}/${dateArray[2]}/${dateArray[0]}`);
        }
    }, [value])

    return (
        <>
            <div className={`${classes.date} ${className}`} style={style}>
                <NumberFormat
                    customInput={TextField}
                    format='##/##/####'
                    fullWidth
                    error={date && !dateValid}
                    helperText={date && !dateValid ? 'Invalid format date' : ''}
                    label={label}
                    name={name}
                    variant='outlined'
                    placeholder='MM/DD/YYYY'
                    value={date || ''}
                    onChange={event => onChangeDate(event)}
                />
                <IconButton className={classes.icon}
                    onClick={() => setOpenPicker(true)}>
                    <Event />
                </IconButton>
            </div>

            <DatePicker
                style={{ display: 'none' }}
                open={openPicker}
                onClose={() => setOpenPicker(false)}
                format="MM/DD/YYYY"
                value={ moment.utc(value) || null}
                onChange={onHandleChangeDate}
            />
        </>
    )
}

RouteKeyboardDate.propTypes = {
    label: PropTypes.string,
    name: PropTypes.string,
    onChange: PropTypes.func,
    className: PropTypes.string,
    style: PropTypes.object
}
RouteKeyboardDate.defaultProps = {
    onChange: () => { },
    className: ''
}

export default RouteKeyboardDate;
