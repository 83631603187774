import React from 'react'
import { Typography } from '@material-ui/core'
import { Page } from 'components'

const ExpiredSchedules = props => {
    return (
        <Page title='Expirring Recurring Routes' >
            <Typography>Expirring Recurring Routes</Typography>
        </Page>
    )
}

export default ExpiredSchedules;