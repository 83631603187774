import React, {useEffect, useState} from 'react'
import PropTypes from 'prop-types'
import {Link, Redirect, useHistory, useParams} from 'react-router-dom'
import {makeStyles, withStyles} from '@material-ui/styles'
import {
    Button,
    Card,
    CardActions,
    CardContent,
    CardHeader, CircularProgress,
    colors,
    Divider, Grid,
    ListItem,
    ListItemText,
    Modal,
    Tabs
} from '@material-ui/core'
import {useDispatch} from 'react-redux'
import moment from 'moment'
import apiConfig from 'apiConfig'
import axios from 'utils/axios'
import {FixedSizeList} from "react-window";
import {formatPhoneNumber} from "../../../../utils/formatNumber";
import Fab from "@material-ui/core/Fab";
import AddIcon from "@material-ui/icons/Add";

const useStyles = makeStyles(theme => ({
    styleModal: {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        outline: 'none',
        boxShadow: theme.shadows[20],
        width: 700,
        maxHeight: '90%',
        overflowY: 'auto',
        maxWidth: '100%',
        [theme.breakpoints.down('sm')]: {
            width: 500
        },
    },
    overBox: {
        overflowY: 'auto',
        border: '1px solid #cbcbcb',
        borderRadius: 6,
        minHeight: 40,
        maxHeight: 230,
        '&::-webkit-scrollbar': {
            width: '10px'
        },
        '&::-webkit-scrollbar-track': {
            backgroundColor: '#e4e4e4',
            borderRadius: '60px'
        },
        '&::-webkit-scrollbar-thumb': {
            backgroundColor: '#9c9c9c',
            borderRadius: '60px'
        }
    },
    button: {
        color: theme.palette.white,
        backgroundColor: colors.green[600],
        '&:hover': {
            backgroundColor: colors.green[900]
        },
    },
    tabs: {
        '& .MuiTabs-scroller': {
            backgroundColor: '#ffffff'
        }
    },
    linkAddress: {
        '&:hover': {
            backgroundColor: colors.green[600],
            '& div': {
                color: '#ffffff'
            }
        }
    },
    addressLink: {
        color: '#000000',
        '&:hover': {
            color: '#ffffff'
        }
    },
    circularProgress: {
        marginTop: '30px',
        margin: '0 auto'
    },
}))

const NoBorderTabs = withStyles({
    root: {
        borderBottom: '0',
        marginBottom: 10
    },
})(Tabs)

const AddressModal = ({ defaultType, open, onClose, selectedCustomer }) => {;
    const history = useHistory();
    const classes = useStyles();
    const dispatch = useDispatch();
    const { date } = useParams();
    const [selected, setSelected] = useState({});
    const [jobAddresses, setJobAddresses] = useState([]);
    const [customer, setCustomer] = useState();
    const [loading, setLoaading] = useState(false);


    const fetchAddress = () => {
        setLoaading(true);
        axios.get(apiConfig.url.BASE_URL + apiConfig.url.CUSTOMER_JOB_ADDRESSES + selectedCustomer.id)
            .then(res => {
                setJobAddresses(res.data.addresses)
            }).finally(() => {
            setLoaading(false);
        })
    };

    function renderRow(props) {
        const { index, style, data } = props;
        return (
            <ListItem className={classes.linkAddress} button style={style} key={index} onClick={() => redirectToJob(' ', index)}>
                <div className={classes.addressLink}>
                    {`${data[index].address.address1}`}
                </div>
                {/*<ListItemText onClick={() => {redirectNewJob(data[index].id)}} primary={``} />*/}
            </ListItem>
        );
    }

    useEffect(() => {


    }, [])

    const handleClose = () => {
        setSelected({});
        onClose();
    }
    const redirectToJob = (jobId, index) => {
        onClose();
        const { address } = jobAddresses[index];
        const state = (address.stateId ? customer.states.find(state => state.id === address.stateId)?.name : "") ?? "";
        sessionStorage.setItem(
            "sw-address",
            JSON.stringify({ ...address, stateName: state })
        );
        const path = `/customers/${encodeURIComponent(selectedCustomer.customerName ?? selectedCustomer.companyName)}/${selectedCustomer.id}/jobs/${jobId}/information`;
        history.push(path);
    };
    useEffect(() => {
        if(selectedCustomer){
            fetchAddress();
            axios.get(apiConfig.url.CUSTOMER_URL + apiConfig.url.CUSTOMERS_GET + selectedCustomer.id)
                .then(res => {
                    setCustomer(res.data)
                })
        }
    },[selectedCustomer])

    return (
        <Modal open={open} onClose={onClose}>
            <Card className={classes.styleModal}>
                <CardHeader title="Select address" />
                <Divider />
                <CardContent style={{textAlign: 'center'}}>
                    {
                        loading ?
                            <CircularProgress className={classes.circularProgress} size={50}/>
                            :
                            <FixedSizeList height={500} width={'100%'} itemSize={46} itemCount={jobAddresses.length}
                                           itemData={jobAddresses}>
                                {renderRow}
                            </FixedSizeList>
                    }
                </CardContent>
                <Divider />
                <CardActions style={{ justifyContent: 'flex-end' }}>
                    <Button onClick={handleClose} variant="contained">
                        Close
                    </Button>
                </CardActions>
            </Card>
        </Modal>
    )
}

AddressModal.propTypes = {
    defaultType: PropTypes.string,
    open: PropTypes.bool,
    onClose: PropTypes.func,
    handleSelectedSource: PropTypes.func
}

export default AddressModal
