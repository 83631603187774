const initState = {
    givedayoffs: []
};

const ScheduleReducer = (state = initState, action) => {
    const dayoffs = [...state.givedayoffs];
    switch (action.type) {
        case 'INIT_DAYOFF':
            return {
                ...state,
                givedayoffs: action.dayoffs
            }
        case 'ADD_DAYOFF':
            dayoffs.push(action.dayoff)
            return {
                ...state,
                givedayoffs: dayoffs
            }
        case 'UPDATE_DAYOFF':
            dayoffs[action.index] = action.dayoff;

            return {
                ...state,
                givedayoffs: dayoffs
            }
        case 'DEL_DAYOFF':
            dayoffs.splice(action.index, 1);

            return {
                ...state,
                givedayoffs: dayoffs
            }
        default: 
            return state;
    }
}

export default ScheduleReducer;