import { useCallback, useState } from 'react';
import axios from 'utils/axios'
import apiConfig from 'apiConfig'

export const useAskTheSeal = () => {
  const [askTheSealResponse, setAskTheSealResponse] = useState(null)
  const endpoint = (type, jobId, splitId) => {
    if (splitId) {
      return `${apiConfig.url.BASE_URL}Job/AskTheSeal?jobId=${jobId}&type=${type}&jobSplitId=${splitId}`
    } else {
      return `${apiConfig.url.BASE_URL}Job/AskTheSeal?jobId=${jobId}&type=${type}`
    }
  }

  const getAtsId = async (companyId) => {
    const res = await axios.get(`${apiConfig.url.BASE_URL}Company/${companyId}/Integrations/AskTheSeal`)
    return res
  }

  const getAskTheSealInfo = useCallback(
    async (jobId) => {
      const res = await axios.get('info', jobId)
      setAskTheSealResponse(res)
      return res
    }, [askTheSealResponse]
  )

  const postAskTheSealReminder = async (jobId, jobSplitId) => {
    const res = await axios.get(endpoint('scheduled', jobId, jobSplitId))

    if (res.data.isSuccess) {
      return true
    }
    return false
  }

  const postAskTheSealReview = async (jobId) => {
    const res = await axios.get(endpoint('completed', jobId))

    if (res.data.isSuccess) {
      return true
    }
    return false
  }

  const isButtonsEnabled = async (jobId) => {
    const job = await axios.get(apiConfig.url.BASE_URL + apiConfig.url.CUSTOMER_JOB + jobId)

    let customer;
    try {
      customer = await axios.get(apiConfig.url.CUSTOMER_URL + `${job.data.customerId}`);
    } catch {
      return false;
    }

    const primaryContact = customer.data.contacts.find(contact => contact.isPrimary)
    const scheduledFound = job.data.jobSplits.find((item) => {
      return item.scheduleDate
    })

    if (scheduledFound && primaryContact) {
      return true
    }

    return false
  }

  const isPrimaryContactSet = async (jobId) => {
    const job = await axios.get(apiConfig.url.BASE_URL + apiConfig.url.CUSTOMER_JOB + jobId)
    const customer = await axios.get(apiConfig.url.CUSTOMER_URL + `${job.data.customerId}`)
    const primaryContact = customer.data.contacts.find(contact => contact.isPrimary)

    if (primaryContact) {
      return true
    }

    return false
  }

  return {
    isButtonsEnabled,
    postAskTheSealReminder,
    postAskTheSealReview,
    getAskTheSealInfo,
    isPrimaryContactSet,
    getAtsId,
  }
}