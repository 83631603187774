import React, { useEffect, useState } from 'react'
import {
    Card, CardContent, Grid, Divider,
    Typography, Button, colors, Collapse, CircularProgress, CardActions,
    TextField
} from '@material-ui/core'
import { makeStyles } from '@material-ui/styles'
import useMediaQuery from '@material-ui/core/useMediaQuery'
import { KeyboardArrowDown, KeyboardArrowUp } from "@material-ui/icons";
import axios from "utils/axios";
import apiConfig from "apiConfig";
import Snackbar from "@material-ui/core/Snackbar";
import Alert from "@material-ui/lab/Alert";
import moment from "moment";
import {formatNumber, formatTwoPDecimal} from "utils/formatNumber"
import { Link } from "react-router-dom";
import CeleroPaymentInfoModal from './CeleroPaymentInfoModal';
import localStorage from 'utils/localStorage';
import { PERMISSIONS } from 'common/permissions';
import NumberFormat from 'react-number-format';

const useStyles = makeStyles(theme => ({
    paymentHead: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        padding: '8px 16px',
        cursor: 'pointer'
    },
    label: {
        display: 'flex'
    },
    balance: {
        fontSize: theme.spacing(3),
        fontWeight: 500,
        '&:first-child': {
            marginRight: '24px'
        },
        '& span:first-child': {
            marginRight: '24px'
        }
    },
    button: {
        width: '190px',
        color: theme.palette.white,
        backgroundColor: colors.green[600],
        '&:hover': {
            backgroundColor: colors.green[900]
        },
        '&:disabled': {
            whiteSpace: 'nowrap',
            color: 'rgba(0, 0, 0, 0.26)',
            backgroundColor: 'rgb(217 217 217)',
            pointerEvents: 'none'
        }
    },
    cardTitle: {
        fontSize: '16px',
        fontWeight: 500,
        marginRight: theme.spacing(3)
    },
    typo: {
        fontSize: '16px',
        fontWeight: 500,
        '& span:nth-child(1)': {
            display: 'inline-block',
            //width: '50%'
        }
    },
    typoRight: {
        fontSize: '16px',
        fontWeight: 500,
        '& span:nth-child(1)': {
            display: 'inline-block',
            //width: '50%'
        },
        textAlign: 'right'
    },
    contact: {
        borderRight: '1px solid #eeeeee',
    },
    inforPay: {
        borderRight: '1px solid #eeeeee'
    },
    actionPay: {
        '& button': {
            display: 'block'
        }
    },
    addTip: {
        marginTop: 12
    },
    tipDate: {
        marginTop: 84
    },
}));

const Payment = props => {

    const classes = useStyles();
    const { data, load, reloadData, jobId, invoiceGenerated, setFieldValue } = props;
    const ipad = useMediaQuery('(max-width: 769px)');
    const ipPlus = useMediaQuery('(max-width: 415px)');
    const [expanded, setExpanded] = useState(true);
    //const [activeQuickbooks, setActiveQuickbooks] = useState(true);

    const [openSnackbar, setOpenSnackBar] = useState(false);
    const [isStatus, setIsStatus] = useState({ failed: false, msg: '' });
    const [posting, setPosting] = useState(false);
    const [postingInvoiceSent, setPostingInvoiceSent] = useState(false);
    const [stages, setStages] = useState([]);
    const [stage, setStage] = useState();
    const [total, setTotal] = useState();
    const [subtotal, setSubtotal] = useState();
    const [totalTax, setTotalTax] = useState();
    const [tip, setTip] = useState();
    const [invoice, setInvoice] = useState();
    const [payment, setPayment] = useState();
    const [makeCompleting, setMakeCompleting] = useState(false);
    const [jobSplits, setJobSplits] = useState();
    const [generated, setGenerated] = useState(invoiceGenerated);
    const [dateSent, setDateSent] = useState(null);
    const [celeroPaymentInfo, setCeleroPaymentInfo] = useState(null);
    const [encryptedPaymentInfo, setEncryptedPaymentInfo] = useState(null);
    const [loadingCeleroPaymentInfo, setLoadingCeleroPaymentInfo] = useState(true);
    const [openCeleroPaymentInfo, setOpenCeleroPaymentInfo] = useState(false);
    const [celeroCompanyInfo, setCeleroCompanyInfo] = useState(null);
    const userRoles = localStorage.getUserRoles();
    const isFieldTechnician = !userRoles || (userRoles.length === 1 && userRoles[0] === PERMISSIONS.FIELD_TECHNICIAN);

    //const contact = address && address.contacts.find(x => x.isPrimary);

    const toggleExpanded = () => {
        setExpanded(!expanded);
    }


    const getInvoice = () => {
        axios.get(apiConfig.url.BASE_URL + apiConfig.url.CUSTOMER_JOB_INVOICE, { params: { jobId: data.id } })
            .then(res => {
                if (res.data) {
                    setInvoice(res.data);
                    if (res.data.invoice?.dateSent) setDateSent(res.data.invoice?.dateSent);
                }
            })
    }

    const getCeleroPaymentInfo = () => {
        return axios.get(apiConfig.url.BASE_URL + apiConfig.url.PAYMENT_PAYMENT_INFO.replace('{jobId}', data.id))
            .then(res => {
                setCeleroPaymentInfo(res.data);
                return res.data;
            })
            .finally(() => setLoadingCeleroPaymentInfo(false));
    };

    const getCeleroCompanyInfo =() => {
        axios.get(apiConfig.url.BASE_URL + apiConfig.url.COMPANY_CELERO_INFO)
        .then(res => {
            if (res.data) {
                setCeleroCompanyInfo(res.data);
            }
        });
    }

    const showPaymentInfo = paymentInfo => {
        if (celeroPaymentInfo || paymentInfo) {
            if (encryptedPaymentInfo || paymentInfo)
                setOpenCeleroPaymentInfo(true);
            else {
                setLoadingCeleroPaymentInfo(true);
                axios.get(apiConfig.url.BASE_URL + apiConfig.url.PAYMENT_REQUEST_PAYMENT_URL.replace('{jobId}', data.id))
                    .then(res => {
                        if (res.data) {
                            setEncryptedPaymentInfo(res.data);
                            setOpenCeleroPaymentInfo(true);
                        }
                    })
                    .catch(err => {
                        setIsStatus({ failed: true, msg: 'An error occurred. Please try again later.' });
                        setOpenSnackBar(true);
                    })
                    .finally(() => setLoadingCeleroPaymentInfo(false));
            }
        }
    };

    const createPaymentInfo = () => {
        setLoadingCeleroPaymentInfo(true);
        axios.post(apiConfig.url.BASE_URL + apiConfig.url.PAYMENT_CREATE_PAYMENT.replace('{jobId}', data.id), {})
            .then(res => {
                if (res.data) {
                    setEncryptedPaymentInfo(res.data);
                    return getCeleroPaymentInfo();
                }
            })
            .then(paymentInfo => showPaymentInfo(paymentInfo))
            .catch(err => {
                setIsStatus({ failed: true, msg: 'An error occurred. Please try again later.' });
                setOpenSnackBar(true);
            })
            .finally(() => setLoadingCeleroPaymentInfo(false));
    };

    const markInvoiceSent = () => {
        setPostingInvoiceSent(true);
        axios.patch(apiConfig.url.BASE_URL + apiConfig.url.CUSTOMER_JOB_MARK_INVOICE_SENT, { jobId: data.id, dateSent: new Date() })
            .then(res => {
                setIsStatus({ failed: false, msg: 'Invoice Marked as Sent' });
                setOpenSnackBar(true);
                setDateSent(moment());
            })
            .catch(err => {
                setIsStatus({ failed: true, msg: 'Mark invoice sent failed.' });
                setOpenSnackBar(true);
            })
            .finally(() => setPostingInvoiceSent(false));
    }

    const postQuickBook = () => {
        const payload = {
            queuedToQuickbooks: !!data.queuedToQuickbooks ? null : new Date(),
            postedToQuickbooks: data.postedToQuickbooks
        }
        let invoiceReq = Promise.resolve();
        // invoice not generated
        if ((!invoice || !invoice.invoice) && !invoiceGenerated && !generated) {
            invoiceReq = axios.get(
                apiConfig.url.BASE_URL + apiConfig.url.JOB_INVOICE_PDF,
                {
                    params: { jobId: data.id, isQuote: false, isInvoice: true }
                }
            )
        }
        setPosting(true);
        invoiceReq.then(x =>
            axios.post(apiConfig.url.BASE_URL + apiConfig.url.CUSTOMERS_POST_TO_QUICKBOOK.replace('{jobId}', data.id), payload)
        )
            .then(res => {
                reloadData();
                setIsStatus({ failed: false, msg: 'Record Posted to Quickbooks' });
                setOpenSnackBar(true);
            })
            .catch(err => {
                setIsStatus({ failed: true, msg: 'Post to quickbook failed.' });
                setOpenSnackBar(true);
            })
            .finally(() => setPosting(false));
    }

    useEffect(() => {
        if (invoice && stages) {
            let total = 0;
            let totalTax = 0;
            invoice.jobPriceItems && invoice.jobPriceItems.map((x) => {
                if(x.taxable){
                    totalTax += x.total
                }
                total += x.total;
            })
            setTotalTax(totalTax);
            setTotal(total);

            let discountDollars = invoice.discountDollars ?? 0;
            if (invoice.discountPercent % 1 === 0) {
                discountDollars = total * (invoice.discountPercent / 100);
            }

            setSubtotal(Number((total - discountDollars).toFixed(2)));

            let tip = 0;
            let payment = 0;
            invoice.payments && invoice.payments.map((x) => {
                tip += x.tip;
                payment += x.amount * 1;
            })
            setTip(tip)
            setPayment(payment)

            const st = stages.find(x => x.order === invoice.bidStageId)
            setStage(st ? st.description : "");
        }
    }, [invoice, stages])

    useEffect(() => {
        if (data) {
            if (data.jobSplits) {
                getInvoice();
                setJobSplits(data.jobSplits.find(x => x.isPrimaryJob == true))
                getCeleroPaymentInfo();
            }
        }
    }, [data.id, load])

    useEffect(() => {
        if (data.tipChange == null) setFieldValue('tipChange', data.tip);
    }, [data]);

    // useEffect(() => {
    //     if(data){
    //         let total = 0;
    //         data.jobSplits.map((x) => {
    //             total += x.total;
    //         })
    //         setTotal(total)
    //     }
    // },[data])

    // useEffect(() => {
    //     if(payments){
    //         let tip = 0;
    //         let payment = 0;
    //         payments.map((x) => {
    //             tip += x.amount;
    //             payment += x.checkNumber * 1;
    //         })
    //         setTip(tip)
    //
    //         setPayment(payment)
    //     }
    // },[payments])

    useEffect(() => {
        getCeleroCompanyInfo();
        axios.get(apiConfig.url.BASE_URL + apiConfig.url.CUSTOMERS_JOB_STATE)
            .then(res => {
                setStages(res.data);
            })

    }, [])
    return (
        <Card style={{ marginBottom: '16px' }}>
            <CardContent style={ipad ? { alignItems: 'flex-start' } : null}
                className={classes.paymentHead}
                onClick={toggleExpanded}
            >
                <div style={{ flex: '1', display: 'flex', flexDirection: ipad ? 'column' : 'row', justifyContent: 'space-between' }}>
                    <div style={{ marginBottom: ipad ? '8px' : '0px', flexDirection: ipPlus ? 'column' : 'row', alignItems: ipPlus ? 'flex-start' : 'center', marginRight: 'auto' }}
                        className={classes.label}>
                        <Typography className={classes.cardTitle}>Customer Invoice</Typography>
                    </div>
                    <div style={ipPlus ? { display: 'block', width: '100%' } : { display: 'flex' }}>
                        <Typography style={ipPlus ? { display: 'flex', justifyContent: 'space-between', margin: 0 } : null}
                            className={classes.balance}>
                            <span style={ipPlus ? { margin: 0 } : null}>Total Due</span>
                            <span style={ipPlus ? { margin: 0 } : null}>${invoice && data ? formatNumber(subtotal + ((totalTax - totalTax / 100 * invoice.discountPercent) / 100 * invoice.taxPercent) + (data.tip || 0) + (data.convenienceFee || 0), 2) : 0}</span>
                        </Typography>
                        <Typography style={ipPlus ? { display: 'flex', justifyContent: 'space-between', margin: 0 } : null}
                            className={classes.balance}>
                            <span style={ipPlus ? { margin: 0 } : null}>Balance Due</span>
                            <span style={ipPlus ? { margin: 0 } : null}>
                                ${invoice && data ?
                            formatNumber(subtotal + ((totalTax - totalTax / 100 * invoice.discountPercent) / 100 * invoice.taxPercent) + (data.convenienceFee || 0) + (data.tip || 0) - payment, 2) : '0'}</span>
                        </Typography>
                    </div>
                </div>
                <div style={{ height: '100%', paddingLeft: '20px', cursor: 'pointer' }}>
                    {
                        expanded ? <KeyboardArrowUp /> : <KeyboardArrowDown />
                    }
                </div>
            </CardContent>
            <Divider />
            <Collapse in={expanded} timeout='auto' unmountOnExit>
                <CardContent style={{ paddingBottom: 16 }}>
                    <Grid container spacing={2}>
                        <Grid style={ipPlus ? { border: 'none' } : null}
                            className={classes.contact} item xs={ipad ? (ipPlus ? 12 : 4) : 3}>
                            {
                                invoice && ((invoice.contactFirstName + ' ' + invoice.contactLastName) !== invoice.companyName) &&
                                <Typography className={classes.typo}>{(invoice.contactFirstName || '') + ' ' + (invoice.contactLastName || '')}</Typography>
                            }
                            <Typography className={classes.typo}>{invoice && invoice.companyName}</Typography>
                            <Typography className={classes.typo}>{invoice && invoice.address1 || ''}</Typography>
                            <Typography className={classes.typo}>{invoice && invoice.address2 || ''}</Typography>
                            <Typography className={classes.typo}>{invoice && invoice.state && `${invoice.city || ''}, ${invoice.state.name || ''}, ${invoice.zipCode || ''}`}</Typography>
                            <div className={classes.addTip}>
                                {invoice && (
                                    <NumberFormat
                                        customInput={TextField}
                                        allowNegative={false}
                                        prefix='$'
                                        fullWidth
                                        thousandSeparator
                                        decimalScale={2}
                                        label='Add Tip'
                                        name='tipChange'
                                        variant='outlined'
                                        value={data.tipChange || ''}
                                        onChange={(e) => setFieldValue('tipChange', Number(e.target.value.replace(/[^\d.]/g, '')))}
                                    />
                                )}
                            </div>
                        </Grid>
                        <Grid style={ipad ? { border: 'none', padding: '8px' } : { padding: '8px 16px' }}
                            className={classes.inforPay} item xs={ipad ? (ipPlus ? 12 : 8) : 6} container spacing={3}>
                            <Grid item xs={6}>
                                <Typography style={ipad ? { display: 'flex', justifyContent: 'space-between' } : null}
                                    className={classes.typo}>
                                    <span>Date Scheduled</span>
                                </Typography>
                                <Typography style={ipad ? { display: 'flex', justifyContent: 'space-between' } : null}
                                    className={classes.typo}>
                                    <span>Stage</span>
                                </Typography>
                                <Typography style={ipad ? { display: 'flex', justifyContent: 'space-between' } : null}
                                    className={classes.typo}>
                                    <span>Sub-Total</span>
                                </Typography>
                                <Typography style={ipad ? { display: 'flex', justifyContent: 'space-between' } : null}
                                    className={classes.typo}>
                                    <span>Tax</span>
                                </Typography>
                                <Typography style={ipad ? { display: 'flex', justifyContent: 'space-between' } : null}
                                    className={classes.typo}>
                                    <span>Tip - Date Added/Applied</span>
                                </Typography>
                                <Typography style={ipad ? { display: 'flex', justifyContent: 'space-between' } : null}
                                    className={classes.typo}>
                                    <span>Convenience Fee</span>
                                </Typography>
                                <Typography style={ipad ? { display: 'flex', justifyContent: 'space-between' } : null}
                                    className={classes.typo}>
                                    <span>Total Due</span>
                                </Typography>
                            </Grid>
                            <Grid item xs={3}>
                                <Typography className={classes.typo}>
                                    <span>{invoice && invoice.scheduleDate && moment(invoice.scheduleDate).format('MM/DD/YYYY')}</span>
                                </Typography>
                                <Typography className={classes.typo}>
                                    <span>{stage}</span>
                                </Typography>
                                <Typography className={classes.typoRight}>
                                    <span>${invoice ? formatNumber(subtotal, 2) : '0.00'}</span>
                                </Typography>
                                <Typography className={classes.typoRight}>
                                    <span>${invoice && totalTax ? formatNumber((totalTax - totalTax / 100 * invoice.discountPercent) / 100 * invoice.taxPercent, 2) : '0.00'}</span>
                                </Typography>
                                <Typography className={classes.typoRight}>
                                    <span>${data?.tipChange != null ? formatNumber(data.tipChange, 2) : '0.00'}</span>
                                </Typography>
                                <Typography className={classes.typoRight}>
                                    <span>${formatNumber(data && data.convenienceFee, 2)}</span>
                                </Typography>
                                <Typography className={classes.typoRight}>
                                    <span>${invoice && data ? formatNumber(subtotal + ((totalTax - totalTax / 100 * invoice.discountPercent) / 100 * invoice.taxPercent) + (data.tipChange || 0) + (data.convenienceFee || 0), 2) : '0.00'}</span>
                                </Typography>
                            </Grid>
                            <Grid item xs={3}>
                                <div className={classes.tipDate}>{data.tipDate && moment(data.tipDate).format('MM/DD/YY')}</div>
                            </Grid>
                        </Grid>
                        <Grid style={ipad ? { display: 'flex', flexDirection: 'column', alignItems: 'flex-end' } : { paddingLeft: '16px' }}
                            className={classes.actionPay} item xs={ipad ? 12 : 3}>
                            <CardActions>
                                <Button
                                    disabled={isFieldTechnician}
                                    style={(ipad && !ipPlus) ? { margin: 0 } : null}
                                    className={classes.button}
                                    onClick={() => {
                                        setGenerated(true);
                                        window.open(window.location.origin + "/invoice/pdf/"+(data?.id ?? "" ), '_blank')
                                    }}
                                >
                                    <Link
                                        style={{ color: 'inherit', textDecoration: 'none' }}
                                        component="a"
                                        to={"/invoice/pdf/"+(data?.id ?? "" )}
                                        target="_blank"
                                    >
                                        View Invoice
                                    </Link>
                                </Button>
                            </CardActions>
                            { celeroCompanyInfo && celeroCompanyInfo.enabled && <CardActions>
                                    <Button
                                        className={classes.button}
                                        disabled={loadingCeleroPaymentInfo || (data && data.qbId && !data.editSequence)}
                                        onClick={() => celeroPaymentInfo ? showPaymentInfo() : createPaymentInfo()}>
                                        Celero Payment Info
                                    </Button>
                                    {loadingCeleroPaymentInfo ? <CircularProgress size={24} /> : null}
                                </CardActions>
                            }
                            <CardActions style={{alignItems: 'baseline', flexWrap: 'wrap', maxWidth: ipad ? 250 : 'unset', justifyContent: ipad ? 'end' : 'start'}}>
                                <Button
                                    disabled={isFieldTechnician || ((!invoice || !invoice.invoice) && !invoiceGenerated && !generated || dateSent)}
                                    style={(ipad && !ipPlus) ? { margin: 0 } : null}
                                    className={classes.button}
                                    onClick={() => {
                                        markInvoiceSent();
                                    }}
                                >{dateSent ? "Invoice Sent" : "Mark Invoice Sent"}</Button>
                                {postingInvoiceSent && <CircularProgress size={24} style={{alignSelf: 'center'}} />}
                                {dateSent && <span >{moment(dateSent).format('MM/DD/yyyy')}</span>}
                            </CardActions>
                            <CardActions style={{alignItems: 'baseline', flexWrap: 'wrap', maxWidth: ipad ? 250 : 'unset', justifyContent: ipad ? 'end' : 'start'}}>
                                <Button
                                    disabled={isFieldTechnician || posting}
                                    style={{ backgroundColor: data && !!data.queuedToQuickbooks ? '#1b5e20' : '#43a047' }}
                                    className={classes.button}
                                    onClick={() => {
                                        postQuickBook();
                                    }}
                                >{
                                        data && !!data.queuedToQuickbooks ? (!!data.postedToQuickbooks ? 'Posted To Quickbooks' : 'Queued to Quickbooks') :
                                            'Post to Quickbooks'
                                    }</Button>
                                {posting && <CircularProgress size={24} />}
                                {data.queuedToQuickbooks && <span >{moment(data.queuedToQuickbooks).format('MM/DD/yyyy')}</span>}
                            </CardActions>
                            {
                                jobSplits && jobSplits.queuedToQuickbooks &&
                                <Typography style={{ marginLeft: '10px' }}><strong>{jobSplits && jobSplits.postedToQuickbooks ? 'Posted Date' : 'Pending Date'}: </strong>
                                    {jobSplits.postedToQuickbooks ? moment(jobSplits.postedToQuickbooks).format("MM/DD/YYYY") : moment(jobSplits.queuedToQuickbooks).format('MM/DD/YYYY')}
                                </Typography>
                            }
                        </Grid>
                    </Grid>

                </CardContent>
            </Collapse>

            <CeleroPaymentInfoModal
                open={openCeleroPaymentInfo}
                celeroPaymentInfo={celeroPaymentInfo}
                encryptedPaymentInfo={encryptedPaymentInfo}
                setOpenSnackBar={setOpenSnackBar}
                setIsStatus={setIsStatus}
                onClose={result => {
                    setOpenCeleroPaymentInfo(false);
                    if (result) setCeleroPaymentInfo(result);
                }}
            />

            <Snackbar anchorOrigin={{ vertical: 'top', horizontal: 'center' }} open={openSnackbar}
                autoHideDuration={3000}
                onClose={() => setOpenSnackBar(false)}>
                <Alert
                    elevation={6} variant="filled" severity={isStatus.failed ? 'error' : 'success'}>
                    <Typography
                        color="inherit"
                        variant="h6">
                        {isStatus.msg}
                    </Typography>
                </Alert>
            </Snackbar>
        </Card>
    )
}

export default Payment;
