import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import AvatarEdit from 'react-avatar-editor'
import RotateLeftIcon from '@material-ui/icons/RotateLeft';
import RotateRightIcon from '@material-ui/icons/RotateRight';
import CloudUploadIcon from '@material-ui/icons/CloudUpload';
import { Alert } from '@material-ui/lab';
import {
    Modal,
    Card,
    CardHeader,
    CardContent,
    CardActions,
    Divider,
    Button,
    colors,
    Slider,
    Grid,
    IconButton,
    Snackbar,
    Typography,
    CircularProgress
} from '@material-ui/core'
import { makeStyles } from '@material-ui/styles'
import axios from '../../utils/axios'
import apiConfig from '../../apiConfig'
import constants from '../../constants'


const useStyles = makeStyles(theme => ({
    styleModal: {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        outline: 'none',
        boxShadow: theme.shadows[20],
        width: 700,
        maxHeight: '100%',
        overflowY: 'auto',
        maxWidth: '100%',
        [theme.breakpoints.down('sm')]: {
            width: 500
        },
    },
    conButton: {
        padding: '16px',
        justifyContent: 'flex-end'
    },
    button: {
        flexShrink: '0',
        backgroundColor: colors.green[600],
        '&:hover': {
            backgroundColor: colors.green[900]
        },
    },
    iconUpload: {
        color: colors.green[600],
        fontSize: theme.spacing(6),
        marginRight: theme.spacing(2)
    },
    resAvatar: {
        display: 'flex',
        justifyContent: 'center',
        marginTop: theme.spacing(2),
        marginBottom: theme.spacing(2)
    },
    btnUpload: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        padding: theme.spacing(2),
        border: '1px dashed #b7b5b5',
        fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
        cursor: 'pointer',
        fontSize: '20px',
    },
    styleCanvas: {
        marginTop: theme.spacing(2)
    },
    editAvatar: {
        display: 'flex',
        alignItems: 'center'
    },
    cusSlider: {
        width: '50%',
        marginLeft: '15px'
    }
}))

const AvatarEditor = props => {

    const classes = useStyles();
    const { id, open, onClose, onGetAvatar, type , avatarImage } = props;

    const [isStatus, setIsStatus] = useState(false);
    const [openSnackbar, setOpenSnackBar] = useState(false);
    const [image, setImage] = useState(null);
    const [size, setSize] = useState({ width: 300, height: 300 });
    const [rotate, setRotate] = useState(0);
    const [scale, setScale] = useState(10);
    const [isProgress, setIsProgress] = useState(false);
    let avatar = null;
    //let editor = null;
    const closeSnackbar = () => {
        setOpenSnackBar(false);
    }

    const setUploadImage = event => {
        setImage(event.target.files[0]);
    }

    const onChangeScale = (event, value) => {
        setScale(value)
    }

    const onRotateLeft = () => {
        setRotate(rotate - 90)
    }

    const onRotateRight = () => {
        setRotate(rotate + 90)
    }

    const setEditor = editorRef => {
        avatar = editorRef;
    }


    const submit = (type, formData) => {
        switch (type) {
            case 'employee-update-avatar':
                setIsProgress(true);
                axios.put(apiConfig.url.USER_URL + apiConfig.url.EMPLOYEE_ADD_IMAGE, formData)
                    .then(res => {
                        setIsStatus(true);
                        setOpenSnackBar(true);
                        onGetAvatar();
                    })
                    .catch(err => {
                        setIsStatus(false);
                        setOpenSnackBar(true);
                    })
                    .finally(() => {
                        setIsProgress(false);
                        onClose();
                    })
                break;
            case 'customer-update-avatar':
                setIsProgress(true);
                axios.put(apiConfig.url.CUSTOMER_URL + apiConfig.url.CUSTOMERS_ADD_IMAGE, formData)
                    .then(res => {
                        setIsStatus(true);
                        setOpenSnackBar(true);
                        onGetAvatar();
                    })
                    .catch(err => {
                        setIsStatus(false);
                        setOpenSnackBar(true);
                    })
                    .finally(() => {
                        setIsProgress(false);
                        onClose();
                    })
                break;
            case 'admin-update-avatar':
                setIsProgress(true);
                axios.post(apiConfig.url.BASE_URL + apiConfig.url.ADMIN_ADD_IMAGE + `/${id}/UploadLogo` , formData)
                    .then(res => {
                        setIsStatus(true);
                        setOpenSnackBar(true);
                        onGetAvatar();
                    })
                    .catch(err => {
                        setIsStatus(false);
                        setOpenSnackBar(true);
                    })
                    .finally(() => {
                        setIsProgress(false);
                        onClose();
                    })
                break;
            default:
                break;
        }
    }

    const onSave = () => {
        if (type === 'new-avatar') {
            onGetAvatar(avatar, image);
            onClose();
            return;
        }
        
        (type === 'admin-update-avatar'
            ? avatar.getImage()
            : avatar.getImageScaledToCanvas())
            .toBlob((blob) => {
                const formData = new FormData();
                if(type !== 'admin-update-avatar') {
                    formData.append('id', id);
                    formData.append("file", blob, image.name);
                }else{
                    formData.append("logo", blob, image.name);
                }
                submit(type, formData);
            }, image.type, 100)
    }

    useEffect(() => {
        if (open) {
            if(avatarImage) {
                (async () => {
                    var imgExt = avatarImage.split(/[#?]/)[0].split(".").pop().trim();
                    const response = await fetch(avatarImage);
                    const blob = await response.blob();
                    const file = new File([blob], `${id}.${imgExt}`, { type: blob.type })
                    setImage(file);
                })()
            } else {
                setImage(null);
            }
        }
    }, [open, avatarImage, id])



    useEffect(() => {
        if (type !== 'admin-update-avatar' || !image) {
            setSize({ width: 300, height: 300 });
            return;
        }
        var url = URL.createObjectURL(image);
        var img = new Image();
        img.src = url;

        img.onload = function() {
            if (img.height > 300) {
                setSize({
                    width: img.width * 300 / img.height,
                    height: 300
                });
            } else {
                setSize({
                    width: img.width,
                    height: img.height
                });
            }
            URL.revokeObjectURL(img.src);
        };
    }, [image, type]);

    return (
        <>
            <Modal open={open} onClose={onClose} style={{maxWidth: '100%'}}>
                <Card className={classes.styleModal}>
                    <CardHeader title="Change avatar" />
                    <Divider />
                    <CardContent>
                        <div>
                            <input
                                onChange={setUploadImage}
                                type='file'
                                accept='image/*'
                                id='upload-image'
                                style={{ display: 'none' }} />
                            <label className={classes.btnUpload} htmlFor='upload-image'>
                                <CloudUploadIcon className={classes.iconUpload} />
                                <span>Upload Image</span>
                            </label>
                        </div>
                        <div className={classes.resAvatar}>
                            <AvatarEdit
                                ref={setEditor}
                                style={{ pointerEvents: image ? '' : 'none' }}
                                disableHiDPIScaling={type !== 'admin-update-avatar'}
                                image={image}
                                width={size.width}
                                height={size.height}
                                border={0}
                                borderRadius={type !== 'admin-update-avatar' ? 150 : undefined}
                                color={[255, 255, 255, 0.6]}
                                scale={scale / 10}
                                rotate={rotate}
                            />
                        </div>
                        <Grid container spacing={2}>
                            <Grid item xs className={classes.editAvatar}>
                                {type !== 'admin-update-avatar' && <>
                                    <Typography>Zoom : </Typography>
                                    <Slider
                                        className={classes.cusSlider}
                                        disabled={!image}
                                        min={10}
                                        max={30}
                                        onChange={onChangeScale} />
                                </>}
                            </Grid>
                            <Grid item xs className={classes.editAvatar} style={{ justifyContent: 'flex-end' }}>
                                <Typography>Rotate : </Typography>
                                <IconButton disabled={!image} onClick={onRotateLeft}>
                                    <RotateLeftIcon />
                                </IconButton>
                                <IconButton disabled={!image} onClick={onRotateRight}>
                                    <RotateRightIcon />
                                </IconButton>
                            </Grid>
                        </Grid>
                    </CardContent>
                    <Divider />
                    <CardActions className={classes.conButton}>
                        <Button
                            variant="contained"
                            size="large"
                            onClick={onClose}>
                            Close
                        </Button>
                            <Button
                                variant="contained"
                                color="primary"
                                size="large"
                                onClick={onSave}
                                disabled={image ? isProgress : true}
                                className={classes.button}>
                                Save
                            </Button>
                            {isProgress && <CircularProgress size={30} />}

                    </CardActions>
                </Card>
            </Modal>
            <div className={classes.snackBar} style={{ display: type === 'new-avatar' ? 'none' : 'block' }}>
                <Snackbar anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'center'
                }} open={openSnackbar}
                    autoHideDuration={3000}
                    onClose={closeSnackbar}
                >
                    {isStatus
                        ?
                        <Alert
                            elevation={6} variant="filled" severity="success">
                            <Typography
                                color="inherit"
                                variant="h6">
                                {constants.msg.SAVED_SUCCESS}
                            </Typography>
                        </Alert>
                        :
                        <Alert
                            elevation={6} variant="filled" severity="error">
                            <Typography
                                color="inherit"
                                variant="h6">
                                {constants.msg.GENERAL_ERROR}
                            </Typography>
                        </Alert>
                    }
                </Snackbar>
            </div>
        </>
    )
}

AvatarEditor.propTypes = {
    open: PropTypes.bool.isRequired,
    onClose: PropTypes.func.isRequired,
    onGetAvatar: PropTypes.func,
    type: PropTypes.string.isRequired
}

AvatarEditor.defaultProps = {
    onGetAvatar: () => { },
}

export default AvatarEditor;
