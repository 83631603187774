const initState = {
    countSales: 0,
    countDups: 0,
    countTaxs: 0,
    reloadCustomer: false
}

const CountReducer = (state = initState, action) => {
    switch (action.type) {
        case 'CHANGE_COUNT_SALES':
            return {
                ...state,
                countSales: action.count
            }
        case 'CHANGE_COUNT_DUPS':
            return {
                ...state,
                countDups: action.count
            }
        case 'CHANGE_COUNT_TAXS':
            return {
                ...state,
                countTaxs: action.count
            }
        case 'RELOAD_CUSTOMERS_COUNTER':
            // debugger
            return {
                ...state,
                reloadCustomer: !state.reloadCustomer
            }
        default:
            return state
    }
}

export default CountReducer;
