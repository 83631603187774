import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { Link } from 'react-router-dom'
import {
    CardContent,
    Grid,
    Button,
    colors,
    Typography,
} from '@material-ui/core'
import { makeStyles } from '@material-ui/styles'
import useMediaQuery from '@material-ui/core/useMediaQuery'
import axios from "utils/axios";
import apiConfig from "apiConfig";
import * as moment from "moment";
import { AddressLink } from 'components'

const useStyles = makeStyles(theme => ({
    sale: {
        height: '100%',
        borderBottom: '1px solid #eeeeee',
        '&:last-child': {
            borderBottom: 'none'
        }
    },
    actionBtn: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-evenly'
    },
    btn: {
        '& button': {
            fontSize: '12px'
        }
    },
    button: {
        fontSize: '12px',
        padding: '5px 10px',
        color: theme.palette.white,
        backgroundColor: colors.green[600],
        '&:hover': {
            backgroundColor: colors.green[900]
        }
    },
    jobDescription: {
        overflowWrap: "break-word"
    },
    addressLink: {
        textDecoration: 'underline'
    }
}))

const ListBids = props => {
    const classes = useStyles();
    const {data} = props
    const sm = useMediaQuery('(max-width: 415px)');
    const sm_down = useMediaQuery('(min-width: 1281px)');



    return (
        <>
            {data.map((bid, index) => (
                <CardContent className={classes.sale} key={index}>
                    <Grid container spacing={1}>
                        <Grid item xs={12} sm={4} md={4} lg={4}>
                            <Typography gutterBottom variant='h4'>{bid.companyName}</Typography>
                            <Typography gutterBottom>
                                {bid.jobsiteContactName}
                            </Typography>
                            <Typography gutterBottom>
                                <AddressLink
                                    address={{
                                        address1: bid.jobsiteAddress,
                                        city: bid.jobsiteCity,
                                        state: { name: bid.jobsiteState },
                                        zipCode: bid.jobsiteZip
                                    }}
                                />
                            </Typography>
                            <Typography gutterBottom>
                                {bid.jobsiteContactPhone}
                            </Typography>
                            <Typography gutterBottom>
                                {bid.jobsiteContactEmail}
                            </Typography>
                        </Grid>
                        <Grid item xs={12} sm={4} md={4} lg={4}>
                            <Typography gutterBottom class={classes.jobDescription}>
                                <strong>Job Description: </strong>{` ${bid.jobDescription}`}
                            </Typography>
                        </Grid>
                        <Grid item xs={12} sm={4} md={4} lg={4}>
                            <Typography gutterBottom>
                                <strong>Initial Call: </strong>{moment(bid.initialCallDate).format('MM/DD/YYYY')}
                            </Typography>
                            <Typography gutterBottom>
                                <strong>Bid Appt:  </strong>{bid.bidAppointmentDate && moment(bid.bidAppointmentDate).format('MM/DD/YYYY') != '01/01/0001' ? moment(bid.bidAppointmentDate).format('MM/DD/YYYY') : 'Not Scheduled'}
                            </Typography>
                            <Grid container style={{ marginTop: '37px' }} className={classes.actionBtn} spacing={1}>
                                <Link to={`/customers/${encodeURIComponent(bid.companyName)}/${bid.companyId}/information`}
                                    style={{ marginBottom: '10px' }}
                                >
                                    <Button
                                        style={{ marginBottom: '0px', width: '100%' }}
                                        className={classes.button}
                                        variant="contained"
                                    >
                                        View customer
                                    </Button>
                                </Link>
                                <Link
                                    to={`/customers/${encodeURIComponent(bid.companyName)}/${bid.companyId}/jobs/${bid.jobId}/information`}>
                                    <Button
                                        style={{ width: '100%' }}
                                        className={classes.button}
                                        variant="contained">
                                        View Job
                                    </Button>
                                </Link>
                            </Grid>


                        </Grid>
                    </Grid>
                </CardContent>
            ))
            }
        </>
    )
}

ListBids.propTypes = {
    data: PropTypes.array
}

export default ListBids;
