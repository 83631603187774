export const INIT_ROUTES = 'INIT_ROUTES';
export const ADD_ROUTE = 'ADD_ROUTE';
export const UPDATE_ROUTE = 'UPDATE_ROUTE';
export const REMOVE_ROUTE = 'REMOVE_ROUTE';
export const ADD_JOB_ROUTE = 'ADD_JOB_ROUTE';
export const CHANGE_JOB_ROUTE = 'CHANGE_JOB_ROUTE';
export const CHANGE_JOB_HOURS = 'CHANGE_JOB_HOURS';
export const CHANGE_JOB_HOUR = 'CHANGE_JOB_HOUR';
export const CHANGE_EMP_ROUTE = 'CHANGE_EMP_ROUTE';
export const CHANGE_EQP_ROUTE = 'CHANGE_EQP_ROUTE';
export const ADD_EMP_ROUTE = 'ADD_EMP_ROUTE';
export const ADD_EQP_ROUTE = 'ADD_EQP_ROUTE';
export const REMOVE_JOB_ROUTE = 'REMOVE_JOB_ROUTE';
export const REMOVE_EMP_ROUTE = 'REMOVE_EMP_ROUTE';
export const REMOVE_EQP_ROUTE = 'REMOVE_EQP_ROUTE';
export const DRAG_ITEM = 'DRAG_ITEM';
export const INIT_ROLE = 'INIT_ROLE';
export const INIT_COMPANY = 'INIT_COMPANY';
export const CHANGE_COMPANY = 'CHANGE_COMPANY';
export const CHANGE_ORDER = 'CHANGE_ORDER';
