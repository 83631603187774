import React, { useEffect, useState } from 'react'
import { CardContent, CircularProgress } from '@material-ui/core'

import { BlobProvider } from '@react-pdf/renderer';
import { useParams } from 'react-router-dom'
import axios from "../../../utils/axios";
import apiConfig from 'apiConfig'
import WorkorderPdf from './WorkorderPdf';
import localStorage from 'utils/localStorage'
import { PDFViewer } from '@react-pdf/renderer';
import { useTheme } from "@material-ui/core/styles";
import useMediaQuery from "@material-ui/core/useMediaQuery";

const Workorder = props => {
    const [data, setData] = useState([]);
    const [loadingData, setLoadingData] = useState(false);
    const { id } = useParams()
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('xs'));

    useEffect(() => {
        setLoadingData(true);
        axios.get(apiConfig.url.BASE_URL + apiConfig.url.JOB_WORK_ORDER_PDF, {params: {jobId: id} })
                .then(res => {
                    if(res.data){
                        setData(res.data);
                    }
                })
                .catch(err => {
                    console.log("err", err);
                })
                .finally(() => setLoadingData(false));
    }, [])

    return (
        <>
            {isMobile
                ? !loadingData 
                    ? <BlobProvider document={<WorkorderPdf data={data} />}>
                        {({ blob, url, loading, error }) => {
                            if (!loading && !error && url) {
                                window.location.href = url;
                            }
                            return null;
                        }}
                    </BlobProvider>
                    : <CardContent><CircularProgress size={24}/></CardContent>
                : !loadingData 
                    ? <PDFViewer style={{ position: 'absolute', border: 0, height: '100vh', width: '100%' }}>
                        <WorkorderPdf data={data} />
                    </PDFViewer>
                    : <CardContent><CircularProgress size={24}/></CardContent>
            }
        </>
    )
}

export default Workorder;
