import React, {useEffect, useRef, useState} from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
// import { Bar } from 'react-chartjs-2';
import ListSales from './ListSales'
import { makeStyles, styled } from '@material-ui/styles';
import {
    Card,
    CardHeader,
    CardContent,
    CardActions,
    Divider,
    Button,
    IconButton,
    Collapse
} from '@material-ui/core';
import ArrowRightIcon from '@material-ui/icons/ArrowRight';
import {Link} from "react-router-dom";
import axios from "../../../../utils/axios";
import apiConfig from "../../../../apiConfig";
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import useMediaQuery from '@material-ui/core/useMediaQuery'

// import { data, options } from './chart';

const useStyles = makeStyles(() => ({
    root: {},
    chartContainer: {
        height: 400,
        position: 'relative'
    },
    actions: {
        justifyContent: 'flex-end'
    }
}));

const ExpandMore = styled((props) => {
    const { expand, ...other } = props;
    return <IconButton {...other} />;
  })(({ theme, expand }) => ({
    transform: !expand ? 'rotate(0deg)' : 'rotate(180deg)',
    marginLeft: 'auto',
    transition: theme.transitions.create('transform', {
      duration: theme.transitions.duration.shortest,
    }),
  }));

const LatestSales = props => {
    const {className, SalesFollowUps, ...rest} = props;
    const sm = useMediaQuery('(max-width: 600px)');
    const [expanded, setExpanded] = useState(false);

    const handleExpandClick = () => {
        setExpanded(!expanded);
    };

    const [load, setLoad] = useState(false)

    const classes = useStyles();

    const onLoad = () => {
        setLoad(!load)
    }
    return (
        <Card
            {...rest}
            className={clsx(classes.root, className)}
            style={{position: 'relative'}}
            // style={{height: '100%', position: 'relative'}}
        >
            <CardHeader
                title="SALES FOLLOW UPS"
                action={
                sm && <ExpandMore
                    expand={expanded}
                    onClick={handleExpandClick}
                    aria-expanded={expanded}
                    aria-label="show more"
                >
                    <ExpandMoreIcon />
                </ExpandMore>
                }
                style={ sm ? { padding: '12px 16px 8px' } : null } />
            <Divider/>
            {
                sm
                ? <Collapse in={expanded} timeout="auto" unmountOnExit>
                    <div style={{ display: 'flex', flexDirection: 'column' }}>
                        <ListSales onLoad={onLoad} data={SalesFollowUps.slice(0,3)}/>
                    </div>
                    </Collapse>
                : <ListSales onLoad={onLoad} data={SalesFollowUps.slice(0,3)}/>
            }
            <Divider  style={{width: '100%', position : 'initial', bottom: '48px'}}/>
            <CardActions className={classes.actions}
                         style={{width: '100%', bottom: 0}}
                // style={{width: '100%', position: 'absolute', bottom: 0}}
            >
                <Link  to={`/customers/salefollowups`}>
                    <Button
                        color="primary"
                        size="small"
                        variant="text"
                    >
                        View All <ArrowRightIcon/>
                    </Button>
                </Link>

            </CardActions>
        </Card>
    );
};

LatestSales.propTypes = {
    className: PropTypes.string
};

export default LatestSales;
