import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { makeStyles } from '@material-ui/styles'
import {
    Modal, Card, CardHeader, CardContent, CardActions,
    Grid, Divider, FormControlLabel, Button, colors, CircularProgress
} from '@material-ui/core'
import { GreenCheckbox } from 'components'
import apiConfig from 'apiConfig'
import axios from 'utils/axios'
import localStorage from 'utils/localStorage'
import { formatNumber } from 'utils/formatNumber'

const useStyles = makeStyles(theme => ({
    styleModal: {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        outline: 'none',
        boxShadow: theme.shadows[20],
        width: 500,
        maxHeight: '100%',
        overflowY: 'auto',
        maxWidth: '100%',
        [theme.breakpoints.down('sm')]: {
            width: 500
        },
    },
    conButton: {
        padding: '16px',
        justifyContent: 'right'
    },
    buttonSave: {
        marginLeft: 6,
        flexShrink: '0',
        backgroundColor: colors.green[600],
        '&:hover': {
            backgroundColor: colors.green[900]
        },
    },
    lineItem: {
        display: 'flex',
        alignItems: 'center',
        marginLeft: -20,
        paddingLeft: 20,
        borderRadius: 5,
        '&:hover': {
            background: 'whitesmoke !important'
        }
    }
}))

const CombineJobModal = props => {
    const classes = useStyles();
    const { open, onClose, jobs, onCopyIncludes, isProgress } = props;

    const [includes, setIncludes] = useState({
        hasJobSiteNote: true,
        hasDiscount: true,
        hasSalesperson: true,
        hasAttachments: true,
        hasFlexibleScheduling: true,
        prices: {}
    });
    const [jobItems, setJobItems] = useState([]);
    const [loading, setLoading] = useState(false);
    const fullInfo = localStorage.getFullInfo();

    const onChangeIncludes = event => {
        const { name, checked } = event.target;
        setIncludes({ ...includes, [name]: checked });
    }

    const onChangePrices = (itemId, price) => {
        setIncludes({
            ...includes,
            prices: {
                ...includes.prices,
                [itemId]: Number(price)
            }
        });
    }

    const onCloseModal = () => {

        // onClose()
    }

    useEffect(() => {
        if(!open){
            setIncludes({
                hasJobSiteNote: true,
                hasDiscount: true,
                hasSalesperson: true,
                hasAttachments: true,
                hasFlexibleScheduling: true,
                prices: {...includes.prices}
            });
        }
    }, [open])

    useEffect(() => {
        if (jobs?.length >= 2) {
            setLoading(true);
            let companyPriceItems = [];
            Promise.all(
                jobs.map(job => axios.get(
                    apiConfig.url.BASE_URL + apiConfig.url.CUSTOMER_PRICE_ITEMS.replace('{jobId}', job.id),
                    {
                        params: {
                            sortBy: 'Quantity',
                            sortType: 'ASC',
                        }
                    }
                ))
            )
                .then(responses => {
                    let items = [];
                    for (const res of responses) {
                        if (res.data) items = [...items, ...res.data];
                    }
                    setJobItems(items);

                    companyPriceItems = items?.filter(item => item.companyPriceItemId) ?? [];
                    if (!companyPriceItems.length) return;
                    return axios.get(apiConfig.url.BASE_URL + apiConfig.url.COMPANY_PRICES_ITEM.replace('{companyId}', fullInfo.cid), {
                        params: { pageSize: 9999 }
                    })
                })
                .then(value => {
                    let _initValue = { ...includes, prices: {} };
                    for (const item of companyPriceItems) {
                        const companyPriceItem = value.data.data.find(x => x.id === item.companyPriceItemId);
                        item.companyPriceItem = companyPriceItem;
                        if (companyPriceItem?.active && Number(item.price) !== Number(companyPriceItem.price)) {
                            _initValue = {
                                ..._initValue,
                                prices: {
                                    ..._initValue.prices,
                                    [item.id]: Number(item.price)
                                }
                            };
                        }
                    }
                    setIncludes(_initValue);
                })
                .catch(err => console.log(err))
                .finally(() => {
                    setLoading(false);
                })
        }
    }, [jobs]);

    return (
        <>
            <Modal open={open} onClose={onCloseModal}>
                <Card className={classes.styleModal} style={ Object.keys(includes.prices).length ? { width: 700 } : null }>
                    <CardHeader title='Which fields would you like to include?' />
                    <Divider />
                    <CardContent>
                    {loading
                        ? <CircularProgress size={30} />
                        : <>
                            <Grid item xs={12}>
                                <FormControlLabel
                                    name="hasJobSiteNote"
                                    label="Today Only Notes"
                                    checked={includes.hasJobSiteNote}
                                    control={
                                        <GreenCheckbox />
                                    }
                                    onChange={onChangeIncludes}
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <FormControlLabel
                                    name="hasDiscount"
                                    label="Discount"
                                    checked={includes.hasDiscount}
                                    control={
                                        <GreenCheckbox />
                                    }
                                    onChange={onChangeIncludes}
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <FormControlLabel
                                    name="hasSalesperson"
                                    label="Commissioned Salesperson"
                                    checked={includes.hasSalesperson}
                                    control={
                                        <GreenCheckbox />
                                    }
                                    onChange={onChangeIncludes}
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <FormControlLabel
                                    name="hasAttachments"
                                    label="Jobsite Attachments"
                                    checked={includes.hasAttachments}
                                    control={
                                        <GreenCheckbox />
                                    }
                                    onChange={onChangeIncludes}
                                />
                            </Grid>
                            {
                                !!Object.keys(includes.prices).length && (
                                    <>
                                        <Grid item xs={12}>
                                            <ul>
                                                <li style={{ listStyleType: 'none' }}>
                                                    <div style={{ display: 'flex' }}>
                                                        <div style={{ flex: 1, fontWeight: 500 }}>Items</div>
                                                        <div style={{ flexBasis: 120, fontWeight: 500 }}>Job Item Price</div>
                                                        <div style={{ flexBasis: 130, fontWeight: 500 }}>Master Item Price</div>
                                                    </div>
                                                </li>
                                                {jobItems.map(item => (
                                                    <li key={item.id} style={{ marginLeft: 20 }}>
                                                        <div
                                                            className={classes.lineItem}
                                                            style={
                                                                item.companyPriceItem && Number(item.price) !== Number(item.companyPriceItem.price)
                                                                    ? { background: 'lemonchiffon' }
                                                                    : null
                                                            }>
                                                            <div style={{ flex: '1', marginRight: 10 }}>{item.description}</div>
                                                            <div style={{ flexBasis: 120 }}>
                                                                <FormControlLabel
                                                                    name={`prices.${item.id}`}
                                                                    label={'$' + formatNumber(item.price, 2)}
                                                                    checked={includes.prices[item.id] === Number(item.price) || !includes.prices.hasOwnProperty(item.id)}
                                                                    control={
                                                                        <GreenCheckbox/>
                                                                    }
                                                                    onChange={(event) => onChangePrices(item.id, item.price)}
                                                                />
                                                            </div>
                                                            <div style={{ flexBasis: 130 }}>
                                                                {item.companyPriceItem &&
                                                                    <FormControlLabel
                                                                        disabled={Number(item.price) === Number(item.companyPriceItem.price)}
                                                                        name={`prices.${item.id}`}
                                                                        label={'$' + formatNumber(item.companyPriceItem.price, 2)}
                                                                        checked={includes.prices[item.id] === Number(item.companyPriceItem.price)}
                                                                        control={
                                                                            <GreenCheckbox/>
                                                                        }
                                                                        onChange={(event) => onChangePrices(item.id, item.companyPriceItem.price)}
                                                                    />
                                                                }
                                                            </div>
                                                        </div>
                                                    </li>
                                                ))}
                                            </ul>
                                        </Grid>
                                    </>
                                )
                            }
                            <Grid item xs={12}>
                                <FormControlLabel
                                    className={classes.taxCheckbox}
                                    name={'hasFlexibleScheduling'}
                                    label='Anytime Today/Flexible'
                                    checked={includes.hasFlexibleScheduling || false}
                                    control={
                                        <GreenCheckbox/>
                                    }
                                    onChange={onChangeIncludes}
                                />
                            </Grid>
                        </>
                    }
                    </CardContent>
                    <Divider />
                    <CardActions className={classes.conButton}>
                        <Button variant="contained" size="large" onClick={onClose}>
                            Close
                        </Button>
                        <Button className={classes.buttonSave} variant="contained" color="primary" size="large" disabled={loading || isProgress}
                            onClick={() => {
                                onCopyIncludes(includes);
                                // onCloseModal()
                            }}>
                            Combine
                        </Button>
                        {isProgress && <CircularProgress size={24}/>}
                    </CardActions>
                </Card>
            </Modal>
        </>
    )
}

CombineJobModal.propTypes = {
    open: PropTypes.bool,
    onClose: PropTypes.func,
    onCopyIncludes: PropTypes.func
}

export default CombineJobModal;
