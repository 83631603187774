import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import { TableRow } from '@material-ui/core'
import * as moment from 'moment'
import { useSelector } from 'react-redux'
import DropBox from './DropBox'

const DaysInWeek = ({ weekData, days, contact, redirectToRoutesSchedules, handleDropItem }) => {

    const { schedules, distances } = useSelector(state => state.SchedulesReducer);
    const [listDays, setListDay] = useState([]);

    useEffect(() => {
        if (days) {
            const day = days.map(day => {
                return moment(day).format('YYYY-MM-DD')
            })
            setListDay(day);
        }
    }, [days])

    return (
        <TableRow style={{ VerticalAlign: 'top' }}>
            {weekData.map(day => {
                const dayString = moment(day).format('YYYY-MM-DD');
                let routes = (listDays.length > 0 && listDays.indexOf(dayString)) !== -1 ?
                    schedules && schedules.filter(rs => rs.routeDate === dayString) : null
                if (routes && routes.length > 0) {
                    if (distances?.length > 0) {
                        routes = routes.map(r => {
                            var dis = distances.find(x => x.routeId == r.id) || 0;
                            return {...r, distance: dis.distance}
                        })
                        routes = routes.sort((a, b) => parseFloat(a.distance) - parseFloat(b.distance))
                    } else{
                        routes = routes.sort((a, b) => parseFloat(a.routeOrder) - parseFloat(b.routeOrder))
                    }
                    routes = routes.sort((a, b) => a.routeFull - b.routeFull);
                }

                return (
                    <DropBox
                        key={day}
                        day={day}
                        isToday={dayString === moment(new Date()).format('YYYY-MM-DD')}
                        routes={routes}
                        contact={contact}
                        onClick={() => redirectToRoutesSchedules(dayString)}
                        handleDropItem={handleDropItem}
                    />
                )
            })}
        </TableRow>
    )
}

DaysInWeek.propTypes = {
    weekData: PropTypes.array.isRequired,
    days: PropTypes.array.isRequired,
}

DaysInWeek.defaultProps = {
    weekData: [],
    days: []
}

export default DaysInWeek;
