import React from 'react'
import PropTypes from 'prop-types'
import {Card, CardContent, Grid, Typography, Button, colors, Tooltip} from '@material-ui/core'
import useMediaQuery from '@material-ui/core/useMediaQuery'
import { makeStyles } from '@material-ui/styles'
import { Link } from 'react-router-dom'
import moment from "moment";
import {createTheme, MuiThemeProvider} from "@material-ui/core/styles";

const useStyles = makeStyles(theme => ({
    button: {
        whiteSpace: 'nowrap',
        padding: '5px 10px',
        color: theme.palette.white,
        backgroundColor: colors.green[600],
        '&:hover': {
            backgroundColor: colors.green[900]
        }
    },
    actionBtn: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-evenly'
    },
}));
const customThemeTooltip = createTheme({
    overrides: {
        MuiTooltip: {
            tooltip: {
                fontSize: '14px',
                maxWidth: 'none'
            }
        }
    }
});
const SaleFollowUpsCard = props => {

    const classes = useStyles();
    const sm_down = useMediaQuery('(max-width: 959px)');

    const { customer } = props;

    return (
        <Card>
            <CardContent>
                <Grid container spacing={3}>
                    <Grid item xs={12} sm={6} md={6}>
                        <Typography gutterBottom variant='h4'>
                            {customer.companyName}
                            <MuiThemeProvider theme={customThemeTooltip}>
                                <Tooltip arrow title={'View Customer'}>
                                    <a
                                        href={`/customers/${encodeURIComponent(customer.companyName)}/${customer.companyId}/information`}
                                        target='_blank'>
                                        <i style={{
                                            marginLeft: '10px',
                                            fontSize: '18px',
                                            color: '#263238'
                                        }} className="fas fa-external-link" />
                                    </a>
                                </Tooltip>
                            </MuiThemeProvider>
                        </Typography>
                        <Typography gutterBottom>
                            <strong>Address: </strong>{`${customer.jobsiteAddress}, ${customer.jobsiteCity}, ${customer.jobsiteState}`}
                        </Typography>
                        <Typography gutterBottom>
                            <strong>Phone: </strong>{customer.jobsiteContactPhone}
                        </Typography>
                        <Typography gutterBottom>
                            <strong>Job Description: </strong>{customer.jobDescription}
                        </Typography>
                        <Typography gutterBottom>
                            <strong>Note: </strong>{customer.jobSiteNote}
                        </Typography>
                    </Grid>
                    <Grid item xs={12} sm={6} md={6}>
                        <Typography gutterBottom variant='h4'>
                            Job
                            <MuiThemeProvider theme={customThemeTooltip}>
                                <Tooltip arrow title={'View Job'}>
                                    <a
                                        href={`/customers/${encodeURIComponent(customer.companyName)}/${customer.companyId}/jobs/${customer.jobId}/information`}
                                        target='_blank'>
                                        <i style={{
                                            marginLeft: '10px',
                                            fontSize: '18px',
                                            color: '#263238'
                                        }} className="fas fa-external-link" />
                                    </a>
                                </Tooltip>
                            </MuiThemeProvider>
                        </Typography>
                        {/*<Typography gutterBottom>*/}
                        {/*    <strong>Created By: </strong>{customer.jobsiteContactName}*/}
                        {/*</Typography>*/}
                        <Typography gutterBottom>
                            Initial Call: {moment(customer.initialCallDate).format('MM/DD/YYYY')}
                        </Typography>
                        <Typography gutterBottom>
                            Bid Appointment: {customer.bidAppointmentDate != '0001-01-01T00:00:00'
                        &&  moment(customer.bidAppointmentDate+'.000Z').format('MM/DD/YYYY h:mm A')}
                        </Typography>
                        <Typography gutterBottom>
                            Job description: {customer.jobDescription}
                        </Typography>
                    </Grid>
                    {/*<Grid className={classes.actionBtn} item xs={12} md={2}>*/}
                    {/*    <Link  target='_blank'*/}
                    {/*           to={`/customers/${customer.companyName}/${customer.companyId}/information`}*/}
                    {/*    >*/}
                    {/*    <Button style={sm_down ? { marginBottom: '10px', width: '140px', maxWidth: '150px' } : {width: '140px', maxWidth: '150px'}} className={classes.button}>*/}
                    {/*        View Customer*/}
                    {/*    </Button>*/}
                    {/*    </Link>*/}
                    {/*    <Link  target='_blank' to={`/customers/${customer.companyName}/${customer.companyId}/jobs/${customer.jobId}/information`}>*/}
                    {/*        <Button*/}
                    {/*            style={{ width: '140px', maxWidth: '150px'}}*/}
                    {/*            className={classes.button}*/}
                    {/*            variant="contained">*/}
                    {/*            View Job*/}
                    {/*        </Button>*/}
                    {/*    </Link>*/}
                    {/*</Grid>*/}
                </Grid>
            </CardContent>
        </Card>
    )
}

SaleFollowUpsCard.propTypes = {
    customer: PropTypes.object.isRequired
}

export default SaleFollowUpsCard;
