import React, { useEffect, useState, useRef } from 'react'
import PropTypes from 'prop-types'
import {makeStyles} from '@material-ui/styles'
import {Alert, Autocomplete} from '@material-ui/lab'
import {
    Modal, Card, CardHeader, CardContent, CardActions,
    Grid, Typography, Button, Divider,
    colors, Snackbar, CircularProgress, FormControlLabel,
    TextField, TableContainer, Table, TableBody, TableRow, TableCell, Tabs, Tab, Chip
} from '@material-ui/core'
import {FormErrorCallback, GreenCheckbox} from 'components'
import apiConfig from 'apiConfig'
import axios from 'utils/axios'
import {Formik} from "formik";
import {errorMessages} from 'common/constants'
import { DragHandle } from '@material-ui/icons'
import { SortableContainer, SortableElement, SortableHandle } from 'react-sortable-hoc'
import arrayMove from 'array-move'
import OpenInNew from "@material-ui/icons/OpenInNew";
import { Document, pdf, Page } from '@react-pdf/renderer';
import localStorage from 'utils/localStorage'
import CancelRoundedIcon from "@material-ui/icons/CancelRounded";
import { Link } from 'react-router-dom'

const useStyles = makeStyles(theme => ({
    styleModal: {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        outline: 'none',
        boxShadow: theme.shadows[20],
        width: 500,
        maxHeight: '100%',
        overflowY: 'auto',
        maxWidth: '100%',
        [theme.breakpoints.down('sm')]: {
            width: 500
        },
    },
    conButton: {
        padding: '16px'
    },
    buttonSave: {
        flexShrink: '0',
        backgroundColor: colors.green[600],
        '&:hover': {
            backgroundColor: colors.green[900]
        },
    },
    buttonCancel: {
        marginLeft: 'auto !important'
    },
    rootAttachments: {
        marginTop: 10,
        paddingTop: 10,
        display: 'flex',
        [theme.breakpoints.down('xs')]: {
            display: 'block'
        }
    },
    rootChildAttachments: {
        width: '100%',
        padding: '16px 8px',
        border: '1px solid #b3b3b3',
        borderRadius: '4px',
        position: 'relative',
        [theme.breakpoints.down('xs')]: {
            width: '100%'
        }
    },
    labelAttachments: {
        position: 'absolute',
        top: '-10px',
        left: '10px',
        backgroundColor: '#ffffff',
        padding: '0 3px',
        color: '#263238',
        fontSize: 14
    },
    bodyRow: {
        '& td': {
            padding: '0px'
        }
    },
    checkbox: {
        margin: 0
    },
    customersEmailExplain: {
        color: '#263238',
        fontSize: 14,
        textAlign: 'right'
    }
}))

const PdfModal = props => {
    const classes = useStyles();
    const {open, onCloseModal, selectedEmployeeId} = props;

    const [lstUserCanBill, setLstUserCanBill] = useState([]);

    const [selectEmployeeId, setSelectEmployeeId] = useState(null);
    const [allBidders, setAllBidders] = useState(false);
    const [todayBidders, setTodayBidders] = useState(true);
    const [pastDueBidders, setPastDueBidders] = useState(false);
    const [upcomingBidders, setUpcomingBidders] = useState(false);

    const [loadingGetBiders, setLoadingGetBiders] = useState(false);

    const fetchCanBill = () => {
        const user = localStorage.getFullInfo();
        setLoadingGetBiders(true);
        axios.get(apiConfig.url.USER_URL + apiConfig.url.LIST_CAN_BILL, {})
            .then(res => {
                const userId = user.nameid; // "a017d8ea-df45-4b69-b903-dea3d162f646"; // user.nameid
                const found = res.data && res.data.users.length > 0 && res.data.users.some(el => el.id === userId);
                if(found && !selectedEmployeeId) {
                    setSelectEmployeeId(userId);
                } else if(selectedEmployeeId) {
                    setSelectEmployeeId(selectedEmployeeId)
                } else {
                    setAllBidders(true);
                }
                setLstUserCanBill(res.data.users);
            }).finally(() => {
                setLoadingGetBiders(false);
            })
    };

    useEffect(() => {
        if(open)
            fetchCanBill();
    }, [open])

    function onAllBidders(event) {
        setSelectEmployeeId(null);
        setAllBidders(event.target.checked)
    }

    function handleOnCloseModal() {
        onCloseModal();
        setSelectEmployeeId(null);
        setLstUserCanBill([]);
        setAllBidders(false);
        setPastDueBidders(false);
        setUpcomingBidders(false);
        setLoadingGetBiders(false);
    }

    return (
        <>
            <Modal open={open}>
                <Card className={classes.styleModal}>
                    <CardHeader
                        title={"Print Bids To Do"}
                    />
                    <Divider/>
                    <CardContent>
                        <Grid item xs={12} sm={12} md={12} style={{display: 'flex', alignItems: 'center'}}>
                            <TextField
                                style={{width: loadingGetBiders ? '90%' : '100%'}}
                                variant='outlined'
                                select
                                label={selectEmployeeId ? '' : 'Select Salesperson'}
                                SelectProps={{native: true}}
                                value={selectEmployeeId || ''}
                                onClick={e => e.stopPropagation()}
                                onChange={(event) => {
                                    setSelectEmployeeId(event.target.value)
                                }}
                                disabled={allBidders || false}
                            >
                                <option key='' value=''></option>
                                {lstUserCanBill.map(st => (
                                    <option key={`${st.id}`} value={st.id}>
                                        {st.fullName}
                                    </option>
                                ))}
                            </TextField>
                            {loadingGetBiders && <CircularProgress size={24}/>}
                        </Grid>
                        <Grid item xs={12} sm={12} md={12} style={{ paddingLeft: '20px' }}>
                            <FormControlLabel
                                checked={allBidders || false}
                                label={"All Salespersons"}
                                name={"All Salespersons"}
                                onChange={(event) => onAllBidders(event)}
                                control={<GreenCheckbox />}
                            />
                        </Grid>
                    </CardContent>
                    <Divider/>
                    <CardContent>
                        <Grid item xs={12} sm={12} md={12} style={{ paddingLeft: '20px' }}>
                            <FormControlLabel
                                checked={todayBidders || false}
                                label={"Today's Bids"}
                                name={"Today's Bids"}
                                onChange={(event) => {
                                    setTodayBidders(event.target.checked)
                                }}
                                control={<GreenCheckbox />}
                            />
                        </Grid>
                        <Grid item xs={12} sm={12} md={12} style={{ paddingLeft: '20px' }}>
                            <FormControlLabel
                                checked={pastDueBidders || false}
                                label={"Past Due Bids"}
                                name={"Past Due Bids"}
                                onChange={(event) => {
                                    setPastDueBidders(event.target.checked)
                                }}
                                control={<GreenCheckbox />}
                            />
                        </Grid>
                        <Grid item xs={12} sm={12} md={12} style={{ paddingLeft: '20px' }}>
                            <FormControlLabel
                                checked={upcomingBidders || false}
                                label={"Upcoming Bids"}
                                name={"Upcoming Bids"}
                                onChange={(event) => {
                                    setUpcomingBidders(event.target.checked)
                                }}
                                control={<GreenCheckbox />}
                            />
                        </Grid>
                    </CardContent>
                    <Divider/>
                    <CardActions className={classes.conButton}>
                        <Button
                            variant="contained"
                            size="large"
                            className={classes.buttonCancel}
                            onClick={() => { handleOnCloseModal()}}>
                            CANCEL
                        </Button>
                        <Button
                            style={{marginLeft: '6px'}}
                            disabled={(!selectEmployeeId && !allBidders) || (!todayBidders && !pastDueBidders && !upcomingBidders)}
                            variant="contained"
                            color="primary"
                            size="large"
                            className={classes.buttonSave}
                            onClick={() => {
                                window.open(window.location.origin + "/bidtodo/pdf?selectEmployeeId="+selectEmployeeId+"&allBidders="+allBidders+"&todayBidders="+todayBidders+"&pastDueBidders="+pastDueBidders+"&upcomingBidders="+upcomingBidders, '_blank');
                            }}
                            >
                                GENERATE
                        </Button>
                    </CardActions>
                </Card>
            </Modal>
        </>
    )
}
PdfModal.propTypes = {
    onCloseModal: PropTypes.func.isRequired
}
PdfModal.defaultProps = {
    onCloseModal: () => {
    }
}
export default PdfModal;
