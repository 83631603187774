import React, {useEffect, useRef, useState} from "react";
import {
    Button, CardActions,
    CircularProgress,
    colors, FormControlLabel,
    IconButton,
    TableCell,
    TableRow,
    TextField, Tooltip,
    Typography
} from "@material-ui/core";
import {KeyboardDatePicker} from "@material-ui/pickers";
import NumberFormat from "react-number-format";
import {Delete} from "@material-ui/icons";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import {makeStyles} from "@material-ui/styles";
import moment from "moment";
import {GreenCheckbox} from "../../../../../../components";
import DeleteIcon from "@material-ui/icons/Delete";
import {formatNumber} from "utils/formatNumber"

const useStyles = makeStyles(theme => ({
    btnDis: {
        whiteSpace: 'nowrap',
        color: 'rgba(0, 0, 0, 0.26)',
        backgroundColor: 'rgb(217 217 217)',
        pointerEvents: 'none'
    },
    title: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        padding: '8px 16px'
    },
    cardTitle: {
        fontSize: '16px',
        fontWeight: 500,
        width: '255px',
        marginRight: theme.spacing(3)
    },
    headCell: {
        borderBottom: '1px solid #888888',
        fontSize: '16px',
        padding: '8px 16px'
    },
    button: {
        cursor: 'pointer',
        color: theme.palette.white,
        backgroundColor: colors.green[600],
        '&:hover': {
            backgroundColor: colors.green[900]
        }
    },
    add: {
        padding: '0',
        '& span svg': {
            fontSize: '40px',
            color: colors.green[600],
            '&:hover': {
                color: colors.green[900]
            }
        }
    },
    removeBtn: {
        '& span svg': {
            fontSize: '32px'
        }
    },
}))

const CommissionLine = (props) => {
    const classes = useStyles();
    const mounted = useRef(false);
    const ipPlus = useMediaQuery('(max-width: 415px)');
    const {
        index,
        disabled,
        com,
        onChangeCommission,
        employees,
        openConfirmDelete,
        setLocateItem,
        onSaveCustomerPayment,
        description,
        showDesc,
        paySplitType,
        errorItem,
        employeesAll,
        commissionsShorted
    } = props
    const [change, setChange] = useState(false);
    const emp = employeesAll ? employeesAll.find(x => x.id === com.employeeId) : '';
    useEffect(() => {
        mounted.current = true;
        if (mounted.current) {
            setChange(com.change);
        }
        return () => mounted.current = true;
    }, [com.change])

    return (
        <>
            <TableRow key={index}
                      onClick={() => showDesc(description)}
                      style={{cursor: 'pointer'}}
            >
                <TableCell style={{display: 'table-cell', verticalAlign: 'middle', paddingRight: 0}}>
                    {
                        com.isScheduled === true ?
                            (emp && <p style={{display: 'inline-block'}}>{emp.firstName + ' ' + emp.lastName}</p>)
                            :
                            (
                                employees && employees.length > 0 &&
                                <TextField
                                    error={!com.employeeId && errorItem && errorItem[`prices[${index}].employeeId`]}
                                    helperText={!com.employeeId && errorItem && errorItem[`prices[${index}].employeeId`]}
                                    fullWidth
                                    style={{display: 'contents'}}
                                    disabled={disabled}
                                    name='employeeId'
                                    variant='outlined'
                                    select
                                    value={com.employeeId || ''}
                                    onChange={e => onChangeCommission(e, index)}
                                    SelectProps={{native: true}}>
                                    <option key={''} value={''}></option>
                                    {employees && employees.map((em, index) => (
                                        ((commissionsShorted.filter(x=>x.employeeId == em.id) == 0 || com.employeeId == em.id) &&
                                        <option key={index} value={em.id}>{em.firstName + ' ' + em.lastName}</option>)
                                    
                                    ))}
                                </TextField>
                            )
                    }


                </TableCell>
                <TableCell style={{padding: '0'}}>
                    {
                        <Tooltip arrow title={'View employee compensation'}>
                            <a
                                style={{
                                    color: emp ? '#47a3ff' : '#969696',
                                    cursor: emp ? 'pointer' : 'not-allowed'
                                }}
                                onClick={(e) => {
                                    if (!emp) {
                                        e.preventDefault()
                                    }
                                }}
                                href={emp ? encodeURI(`employees/${emp.firstName}_${emp.lastName}/${emp.id}/salary`) : ''}
                                target='_blank'>
                                <i style={{
                                    marginLeft: '10px',
                                    fontSize: '16px',
                                }} className="fas fa-external-link"/>
                            </a>
                        </Tooltip>
                    }
                </TableCell>
                <TableCell align='center'>
                    <FormControlLabel
                        disabled={disabled || com.laborPercent == null}
                        className={classes.checkbox}
                        name='allocateLaborCommision'
                        checked={com.allocateLaborCommision}
                        onChange={e => onChangeCommission(e, index)}
                        control={
                            <GreenCheckbox/>
                        }
                        label={''}/>
                </TableCell>

                <TableCell>
                    <p>${parseFloat(com.hoursOnSite || 0).toFixed(2)}</p>
                </TableCell>
                <TableCell>
                    <NumberFormat
                        disabled={disabled || paySplitType != 3}
                        customInput={TextField}
                        allowNegative={false}
                        prefix='$'
                        thousandSeparator
                        decimalScale={2}
                        fullWidth
                        //style={even || !manually ? {display: 'none'} : null}
                        variant='outlined'
                        name='amountAllocated'
                        value={com.amountAllocated ? parseFloat(com.amountAllocated.toString().replace(/[\$,]/g, '')).toFixed(2) : ''}
                        onChange={e => onChangeCommission(e, index)}
                    />
                </TableCell>
                <TableCell align='center'>
                    <NumberFormat
                        disabled={disabled || paySplitType != 3}
                        inputProps={{maxLength: 8}}
                        customInput={TextField}
                        allowNegative={false}
                        thousandSeparator
                        suffix='%'
                        fullWidth
                        variant='outlined'
                        name='commissionPercent'
                        value={com.commissionPercent || ''}
                        onChange={e => onChangeCommission(e, index)}
                    />
                </TableCell>
                <TableCell align='center'>
                    <NumberFormat
                        disabled
                        inputProps={{maxLength: 8}}
                        customInput={TextField}
                        allowNegative={false}
                        prefix='$'
                        thousandSeparator
                        decimalScale={2}
                        fullWidth
                        variant='outlined'
                        name='amountDueToPerson'
                        value={com.amountDueToPerson ? parseFloat(com.amountDueToPerson).toFixed(2) : ''}
                    />
                </TableCell>
                <TableCell>
                    <NumberFormat
                        disabled={disabled || paySplitType != 3}
                        inputProps={{maxLength: 8}}
                        customInput={TextField}
                        allowNegative={false}
                        prefix='$'
                        thousandSeparator
                        decimalScale={2}
                        fullWidth
                        variant='outlined'
                        name='tip'
                        value={com.tip ? parseFloat(com.tip.toString().replace(/[\$,]/g, '')).toFixed(2) : ''}
                        onChange={e => onChangeCommission(e, index)}
                    />
                </TableCell>
                <TableCell style={{padding: '16px 8px'}} align='center'>
                    <CardActions>
                        <Button
                            size={'small'}
                            style={{margin: ipPlus ? '8px 0' : '0 16px 0 0'}}
                            className={disabled || !com.change ? classes.btnDis : classes.button}
                            onClick={() => setTimeout(() => {
                                {
                                    setLocateItem({
                                        index: index,
                                        id: com.id,
                                        add: com.add
                                    });
                                    setChange(true);
                                    onSaveCustomerPayment(com, index, true);
                                }
                            }, 0)}
                        >Save</Button>
                        {com.saving && change && <CircularProgress size={24}/>}
                    </CardActions>
                </TableCell>
                <TableCell style={{padding: '16px 8px'}} align='center'>
                    <IconButton disabled={disabled} className={classes.removeBtn} onClick={() => {
                        setLocateItem({
                            index: index,
                            id: com.id,
                            add: com.add,
                        });
                        openConfirmDelete(true);
                    }}>
                        <Delete/>
                    </IconButton>
                </TableCell>
            </TableRow>
        </>
    );
}
export default CommissionLine;
