import React, {useState} from 'react'
import {colors, IconButton, TableCell, TableRow} from '@material-ui/core'
import useMediaQuery from '@material-ui/core/useMediaQuery'
import {makeStyles} from '@material-ui/styles'
import {createTheme} from "@material-ui/core/styles";
import DeleteIcon from "@material-ui/icons/Delete";
import {useDispatch} from "react-redux";
import moment from "moment";
import localStorage from "../../../utils/localStorage";

const useStyles = makeStyles(theme => ({
    sale: {
        position: "relative",
        borderBottom: '1px solid #eeeeee',
        '&:last-child': {
            borderBottom: 'none'
        }
    },
    actionBtn: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-evenly',
        '& button': {
            fontSize: "12px"
        }
    },
    button: {
        padding: '5px 10px',
        color: theme.palette.white,
        backgroundColor: colors.green[600],
        '&:hover': {
            backgroundColor: colors.green[900]
        }
    },
    actionBox: {
        width: '100%',
        height: '100%',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        position: 'absolute',
        top: 0,
        left: 0,
        zIndex: 2,
        backgroundColor: '#0000001f'
    },
    row: {
        '&:hover': {
            backgroundColor: colors.green[600]
        }
    }
}));
const customThemeTooltip = createTheme({
    overrides: {
        MuiTooltip: {
            tooltip: {
                fontSize: '14px',
                maxWidth: 'none'
            }
        }
    }
});
const EmployeeTasksCard = props => {

    const classes = useStyles();
    const [loading, setLoading] = useState(false);


    const {task, index, onReload , onDeleteFile, selectedRow, selectedId, hasManager, hasCompleted } = props;
    const openEdit = () => {
        setLoading(true);
    }
    const user = localStorage.getFullInfo();

    const hasEdit  = (hasManager || (!hasManager && task.assignedUserId == user.nameid && !task.assignerId));

    return (
        <>
            {task &&
            <TableRow className={classes.row} style={selectedId && selectedId.id == task.id ? {backgroundColor:  colors.green[600]} : {}} onClick={(e) => {
                selectedRow(task);
                e.stopPropagation();
            }}>
                <TableCell>{task.assignee}</TableCell>
                {
                    !hasCompleted && <TableCell style={task.urgency === 'High' ? { color: colors.red['A400'] } : null}>{task.urgency}</TableCell>
                }
                <TableCell>{task.description}</TableCell>
                <TableCell>{moment(task.dateDue).format('MM/DD/YYYY h:mm A')}</TableCell>
                <TableCell>{task.assignedUserId != task.assignerId ? task.assigner: ''} </TableCell>
                {hasCompleted && <TableCell>{task.dateCompleted && moment.utc(task.dateCompleted).local().format('MM/DD/YYYY h:mm A')}</TableCell>}
                {
                    !hasCompleted && <TableCell align='right'>
                        <IconButton
                            aria-label="delete"
                            disabled={!hasEdit}
                            onClick={(e) => {
                                onDeleteFile({ index: index, id: task.id });
                                e.stopPropagation();
                            }}>
                            <DeleteIcon />
                        </IconButton>
                    </TableCell>
                }
            </TableRow>
            }
        </>
    )
}
export default EmployeeTasksCard;
