import React, { useState, useEffect } from 'react'
import clsx from 'clsx'
import PropTypes from 'prop-types'
import { makeStyles } from '@material-ui/styles'
import moment from 'moment'
import { Alert } from '@material-ui/lab'
import {
  Card, CardHeader, CardContent, Divider,
  Grid, TextField, colors, Typography, Snackbar,
  CircularProgress, Button, FormControlLabel, CardActions
} from '@material-ui/core'
import { KeyboardDatePicker, GreenCheckbox, GreenRadio } from 'components'
import { Formik } from 'formik'
import * as Yup from 'yup'
import axios from 'utils/axios';
import apiConfig from 'apiConfig';
import constants from 'constants.js'
import localStorage from 'utils/localStorage'
import { checkRoles, PERMISSIONS } from "common/permissions";
import ModalForceUpdate from "./ModalForceUpdate";

const useStyles = makeStyles(theme => ({
  root: {},
  cardPadding:
  {
    marginTop: '24px',
    marginBottom: '24px'
  },
  content: {
    padding: 0
  },
  inner: {
    minWidth: 1150
  },
  paddingUsername: {
    paddingLeft: '14px'
  },
  boxPassword: {
    padding: theme.spacing(2)
  },
  boxRole: {
    padding: theme.spacing(2)
  },
  boxTitle: {
    paddingBottom: '0px !important'
  },
  cssTitle: {
    fontWeight: 500
  },
  actionSecurity: {
    justifyContent: 'flex-start',
    padding: '16px'
  },
  button: {
    backgroundColor: colors.green[600],
    '&:hover': {
      backgroundColor: colors.green[900]
    }
  },
  btnSave: {
    paddingTop: '0px !important'
  },
  circularProgress: {
    marginTop: theme.spacing(3)
  },
  defaultCompany: {
    paddingTop: '0px !important',
    '& > label': {
      margin: '-16px auto'
    }
  },
  labelRoles: {
    position: 'absolute',
    top: '-10px',
    left: '10px',
    backgroundColor: '#ffffff',
    padding: '0 3px',
    fontWeight: 500
  },
  rolesContainer: {
    width: '100%',
    border: '1px solid #b3b3b3',
    borderRadius: '4px',
    position: 'relative',
    [theme.breakpoints.down('xs')]: {
        width: '100%'
    }
  },
  overBox: {
      width: '100%',
      // height: '200px',
      overflowY: 'auto',
      padding: '16px 0px',
      '&::-webkit-scrollbar': {
          width: '5px'
      },
      '&::-webkit-scrollbar-track': {
          backgroundColor: '#e4e4e4'
      },
      '&::-webkit-scrollbar-thumb': {
          backgroundColor: '#9c9c9c',
          borderRadius: '60px'
      }
  },
}));

const Security = props => {

  const { userId, className, ...rest } = props;
  const classes = useStyles();

  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [isSuccess, setIsSuccess] = useState({ failed: false, msg: '' })
  const [user, setUser] = useState();
  const [userCompanies, setUserCompanies] = useState();
  const [isLoading, setIsLoading] = useState(true);
  const [isProgressPass, setIsProgressPass] = useState(false);
  const [isProgressRole, setIsProgressRole] = useState(false);
  const [isProgressTer, setIsProgressTer] = useState(false);

  const userRole = localStorage.getUserRole();
  const isHasRoleShowEmployeeRole = checkRoles(userRole, PERMISSIONS.control.showEmployeeRole);
  const isHasRoleShowEmployeeEmployementDates = checkRoles(userRole, PERMISSIONS.control.showEmployeeEmployementDates);
  const [openForceModel, setOpenForceModal] = useState(false);
  const [scheduledDates, setScheduledDates] = useState([]);

  const onSaveField = (values, type) => {
    switch (type) {
      case 'password':
        if (!values.password || !values.passwordConfirm) {
          setIsSuccess({ failed: true, msg: 'Please enter new password.' });
          setOpenSnackbar(true);
          break;
        } else if (values.password !== values.passwordConfirm) {
          setIsSuccess({ failed: true, msg: 'Passwords do not match.' });
          setOpenSnackbar(true);
          break;
        }
        const pwdPostData = {
          password: values.password,
          userId: userId
        };
        setIsProgressPass(true);
        axios.post(apiConfig.url.AUTH_URL + apiConfig.url.EMPLOYEE_UPDATE_PASSWORD, pwdPostData)
          .then(response => {
            setIsSuccess({ failed: false, msg: constants.msg.SAVED_SUCCESS });
            setOpenSnackbar(true)
          })
          .catch(error => {
            setIsSuccess({ failed: true, msg: constants.msg.GENERAL_ERROR });
            setOpenSnackbar(true)
          })
          .finally(() => setIsProgressPass(false))
        break;
      case 'role':
        var roleIdsParams = []
        user && user.roles && user.roles.map((item, index) => ( 
          item.isSelected == true && roleIdsParams.push(item.id)
        ))
        const rolePostData = {
          roleIds: roleIdsParams,
          userId: userId,
          isDefaultCompany: values.isDefaultCompany
        };
        setIsProgressRole(true);
        axios.post(apiConfig.url.AUTH_URL + apiConfig.url.EMPLOYEE_ROLE_UPDATE, rolePostData)
          .then(response => {
            if (values.isDefaultCompany && !user.isDefaultCompany) {
              setUser({...user, isDefaultCompany: true})
            }
            setIsSuccess({ failed: false, msg: constants.msg.SAVED_SUCCESS });
            setOpenSnackbar(true)
          })
          .catch(error => {
            setIsSuccess({ failed: true, msg: constants.msg.GENERAL_ERROR });
            setOpenSnackbar(true)
          })
          .finally(() => setIsProgressRole(false))

        break;
      case 'termination':
        if (!values.dateTerminate) {
        }
        const terminationPostData = {
          dateTerminate: values.dateTerminate,
          hiredDate: values.hiredDate,
          userId: userId
        };
        setIsProgressTer(true);
        axios.post(apiConfig.url.AUTH_URL + apiConfig.url.EMPLOYEE_DISABLE_USER, terminationPostData)
            .then(response => {
              if (response.data.scheduledDates && response.data.scheduledDates.length > 0) {
                setScheduledDates(response.data.scheduledDates);
                setOpenForceModal(true);
              } else {
                setIsSuccess({failed: false, msg: constants.msg.SAVED_SUCCESS});
                setOpenSnackbar(true)
              }
            })
            .catch(error => {
              setIsSuccess({failed: true, msg: constants.msg.GENERAL_ERROR});
              setOpenSnackbar(true)
            })
            .finally(() => setIsProgressTer(false))
        break;
      default:
        break;
    }
  }

  const fetchSecurityProfile = () => {
    return axios.get(apiConfig.url.AUTH_URL + apiConfig.url.EMPLOYEE_SECURITY_PROFILE + userId);
  };

  const fetchUserCompanies = () => {
    return axios.get(apiConfig.url.BASE_URL + apiConfig.url.COMPANY_MULTI.replace("{userId}", userId));
  }

  const forceSecurityUpdate = (values) => {
    setOpenForceModal(false);
    const terminationPostData = {
      dateTerminate: values.dateTerminate,
      hiredDate: values.hiredDate,
      userId: userId,
      forceSave: true
    };
    setIsProgressTer(true);
    axios.post(apiConfig.url.AUTH_URL + apiConfig.url.EMPLOYEE_DISABLE_USER, terminationPostData)
        .then(res => {
          setIsSuccess({failed: false, msg: constants.msg.SAVED_SUCCESS});
          setOpenSnackbar(true)
        })
        .catch(() => {
          setIsSuccess({failed: true, msg: constants.msg.GENERAL_ERROR});
            setOpenSnackbar(true);
        })
        .finally(() => setIsProgressTer(false))
  }

  useEffect(() => {
    Promise.all([fetchSecurityProfile(), fetchUserCompanies()]).then(([res1, res2]) => {
      setUser(res1.data);
      setUserCompanies(res2.data)
    }).catch(err => {
      if (err) {
        setIsSuccess({ failed: true, msg: 'Something went wrong loading the page.' })
        setOpenSnackbar(true);
      }
    }).finally(() => setIsLoading(false));
  }, []);

  const onChangeRoles = (event, setFieldValue, values) => {
    if(!event.target.checked) {
      if(values.rolesMultiple.filter(function(item) { return item.isSelected }).length == 1) {
        setIsSuccess({ failed: true, msg: 'You must specify at least one role!' });
        setOpenSnackbar(true);
      } else {
        const { name, value, type, id } = event.target;
        const index = user.roles.map(function(x) {return x.name; }).indexOf(name);
        user.roles[index].isSelected = !user.roles[index].isSelected;
        setUser({...user, roles: user.roles})
      }
    } else {
      const { name, value, type, id } = event.target;
      user.roles.forEach(role => role.isSelected = role.name === name);
      setUser({...user, roles: user.roles})
    }
  }

  if (!user) {
    return (
      <>
        {isLoading && <Grid
            container
            spacing={0}
            align="center"
            justifyContent="center"
            direction="column">
            <Grid item>
              <CircularProgress className={classes.circularProgress} size={50} />
            </Grid>
          </Grid>
        }
        <Snackbar anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
          open={openSnackbar}
          autoHideDuration={2000}
          onClose={() => setOpenSnackbar(false)}>
          <Alert
            elevation={6} variant="filled" severity={isSuccess.failed ? 'error' : 'success'}>
            <Typography
              color="inherit"
              variant="h6">
              {isSuccess.msg}
            </Typography>
          </Alert>
        </Snackbar>
      </>
    )
  }

  return (
    <div
      {...rest}
      style={{ paddingBottom: '1px' }}
      className={clsx(classes.root, className)}
    >
      <Formik
        enableReinitialize
        initialValues={{
          username: user.userName,
          password: '',
          passwordConfirm: '',
          rolesMultiple: user.roles,
          isDefaultCompany: user.isDefaultCompany || (userCompanies?.length || 0) < 2,
          dateTerminate: (user.dateTerminate && moment(user.dateTerminate).format('MM/DD/YYYY')) || '',
          hiredDate: (user.hiredDate && moment(user.hiredDate).format('MM/DD/YYYY')) || ''
        }}
        validationSchema={Yup.object().shape({
          password: Yup.string()
            .when('passwordConfirm', {
              is: val => (val && val.length > 0 ? true : false),
              then: Yup.string().required('This field is required.'),
              otherwise: Yup.string()
            }),
          passwordConfirm: Yup.string()
            .when('password', {
              is: val => (val && val.length > 0),
              then: Yup.string()
                .required('This field is required.')
                .oneOf(
                  [Yup.ref("password")],
                  "Passwords don't match"
                )
            })
        }, ['password', 'passwordConfirm'])}
      >
        {({
          values,
          errors,
          setFieldValue,
          handleChange,
        }) => (

          <form>
            <Card
              {...rest}
              className={clsx(classes.root, className, classes.cardPadding)}>
              <CardHeader title="Password" />
              <Divider />

              <CardContent>
                <Grid
                  container
                  spacing={4}>
                  <Grid container>

                    <Grid item className={classes.boxPassword} md={6} xs={12}>
                      <TextField
                        fullWidth
                        error={errors.password ? true : false}
                        helperText={errors.password && errors.password}
                        label="Password"
                        name="password"
                        autoComplete="new-password"
                        type="password"
                        variant="outlined"
                        value={values.password}
                        onChange={handleChange}
                      />
                    </Grid>

                    <Grid item className={classes.boxPassword} md={6} xs={12}>
                      <TextField
                        fullWidth
                        error={errors.passwordConfirm ? true : false}
                        helperText={errors.passwordConfirm && errors.passwordConfirm}
                        label="Confirm Password"
                        name="passwordConfirm"
                        type="password"
                        variant="outlined"
                        value={values.passwordConfirm}
                        onChange={handleChange}
                      />
                    </Grid>

                    <Grid style={{ marginLeft: '16px', marginBottom: '20px' }} item xs={12}>
                      <CardActions>
                        <Button
                            onClick={() => onSaveField(values, 'password')}
                            variant="contained"
                            color="primary"
                            size="large"
                            disabled={isProgressPass}
                            className={classes.button}>
                          Save
                        </Button>
                        {isProgressPass && <CircularProgress size={30} />}
                      </CardActions>
                    </Grid>
                  </Grid>
                </Grid>
              </CardContent>
            </Card>


            {isHasRoleShowEmployeeRole &&
              <Card {...rest}
                className={clsx(classes.root, className, classes.cardPadding)}>
                <CardHeader title="Role" />
                <Divider />
                <CardContent>
                  <Grid container spacing={4}>
                    <Grid
                      item
                      md={12}
                      xs={12}>
                      <div className={classes.rolesContainer}>
                          <Typography className={classes.labelRoles}>Select Role</Typography>
                          <div className={classes.overBox}>
                            {user.roles?.length > 1 &&
                              user.roles.map((role, index) => (
                                <Grid className={classes.defaultCompany} item xs={12} style={ index != (user.roles?.length - 1) ? { marginBottom: '10px' } : {}}>
                                  <FormControlLabel
                                    disabled={user?.isDisabledRole || false}
                                    control={<GreenRadio />}
                                    label={role.name}
                                    name={role.name}
                                    checked={role.isSelected || false}
                                    onChange={(event) => onChangeRoles(event, setFieldValue, values)}
                                  />
                                </Grid>
                              ))
                            }
                          </div>
                      </div>
                    </Grid>

                    {userCompanies?.length > 1 &&
                      <Grid className={classes.defaultCompany} item xs={12}>
                        <FormControlLabel
                          control={<GreenCheckbox />}
                          label='Default Company'
                          name="isDefaultCompany"
                          disabled={user?.isDefaultCompany || user?.isDisabledRole || false}
                          checked={values.isDefaultCompany || false}
                          onChange={handleChange}
                        />
                      </Grid>
                    }

                    <Grid className={classes.btnSave} item xs={12}>
                      <CardActions>
                      <Button
                        onClick={() => onSaveField(values, 'role')}
                        variant="contained"
                        color="primary"
                        size="large"
                        disabled={isProgressRole || user?.isDisabledRole || false}
                        className={classes.button}>
                        Save
                      </Button>
                      {isProgressRole && <CircularProgress size={30} />}
                      </CardActions>
                    </Grid>
                  </Grid>
                </CardContent>
              </Card>
            }



            {isHasRoleShowEmployeeEmployementDates &&
              <Card
                {...rest}
                className={clsx(classes.root, className, classes.cardPadding)}>
                <CardHeader title='Employment Dates' />
                <Divider />
                <CardContent>
                  <Grid container spacing={4}>
                    <Grid className={classes.groupDatePicker} item md={12} xs={12}>
                      <KeyboardDatePicker
                        //error={(errors.hiredDate && touched.hiredDate) ? true : false}
                        //helperText={(errors.hiredDate && touched.hiredDate) && errors.hiredDate}
                        //onBlur={() => setFieldTouched('hiredDate')}
                        label='Hired Date'
                        value={values.hiredDate || null}
                        onChange={date => setFieldValue('hiredDate', date)}
                      />
                    </Grid>
                    <Grid item md={12} xs={12}>
                      <KeyboardDatePicker
                        label='Termination Date'
                        value={values.dateTerminate || null}
                        onChange={date => setFieldValue('dateTerminate', date)}
                      />
                    </Grid>

                    <Grid className={classes.btnSave} item xs={12}>
                      <CardActions>
                      <Button
                        onClick={() => onSaveField(values, 'termination')}
                        variant="contained"
                        color="primary"
                        size="large"
                        disabled={isProgressTer}
                        className={classes.button}>
                        Save
                      </Button>
                      {isProgressTer && <CircularProgress size={30} />}
                      </CardActions>
                    </Grid>
                  </Grid>
                </CardContent>
                <ModalForceUpdate
                    open={openForceModel}
                    onClose={() => setOpenForceModal(false)}
                    date={scheduledDates}
                    forceUpdate={() => forceSecurityUpdate(values)}
                />
              </Card>
            }
          </form>
        )
        }
      </Formik >



      <Snackbar anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
        open={openSnackbar}
        autoHideDuration={2000}
        onClose={() => setOpenSnackbar(false)}>
        <Alert
          elevation={6} variant="filled" severity={isSuccess.failed ? 'error' : 'success'}>
          <Typography
            color="inherit"
            variant="h6">
            {isSuccess.msg}
          </Typography>
        </Alert>
      </Snackbar>
    </div >
  );
};

Security.propTypes = {
  className: PropTypes.string
};

export default Security;
