import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Card, CardHeader, CircularProgress, Collapse, Divider, Grid, Typography,TableContainer, Table, TableHead,TableRow, TableCell,TableBody } from '@material-ui/core';
import { KeyboardArrowDown, KeyboardArrowUp,ArrowDownward, ArrowUpward } from '@material-ui/icons';
import { makeStyles } from '@material-ui/styles';
import { Page, SearchInput ,Pagination } from 'components';
import { CustomerCards } from 'views/CustomerList/components';
import apiConfig from 'apiConfig';
import axios from 'utils/axios';
import { EmployeeCards } from 'views/EmployeeList/components';
import {Link} from "react-router-dom";
import moment from 'moment';
import qs from 'query-string';
import Axios from 'axios';

const useStyles = makeStyles(theme => ({
    root: {
        padding: theme.spacing(3)
    },
    card: {
        marginTop: theme.spacing(3)
    },
    collapse: {
        padding: theme.spacing(3)
    },
    noData: {
        fontSize: theme.spacing(2),
        fontWeight: 500,
        textAlign: 'center'
    },
    tableContainer: {
        marginTop: theme.spacing(3),
        overflowX: 'auto'
    },
    headCell: {
        borderBottom: '1px solid #888888',
        fontSize: '16px',
        padding: '8px 16px',
        '& span': {
            display: 'flex',
            alignItems: 'center'
        }
    },
    paginate: {
        marginTop: theme.spacing(3),
        display: 'flex',
        justifyContent: 'center'
    },
    jobCompletion: {
        cursor: 'pointer',
        '& td': {
            padding: '0'
        },
        '&:hover': {
            backgroundColor: '#43a047',
            '& td': {
                color: '#ffffff',
                '& p': {
                    color: '#ffffff'
                },
                '& a': {
                    color: '#ffffff'
                }
            }
        }
    },
    linkTo: {
        width: '100%',
        height: '100%',
        color: 'black',
        padding: '16px',
        display: 'block'
    },
    customTableCell: {
        borderBottom: '1px solid #969696',
        cursor: 'pointer'
    },
}));

const useSearchDelay = (val, delay) => {
    delay = delay || 1;
    const [debounced, setDebounced] = useState(val)

    useEffect(() => {
        const handler = setTimeout(() => {
            setDebounced(val)
        }, delay)

        return () => clearTimeout(handler)
    }, [val, delay])

    return debounced;
}

const Search = props => {
    const { location, history } = props;
    const classes = useStyles();

    const [query, setQuery] = useState(new URLSearchParams(location.search).get('query'));
    const [type] = useState(new URLSearchParams(location.search).get('type'));
    const [archived] = useState(new URLSearchParams(location.search).get('archived') == 'true') ;
    const [addressSearch] = useState(new URLSearchParams(location.search).get('isAddressSearch') == 'true') ;
    const [searchField] = useState(new URLSearchParams(location.search).get('searchField'));
    let searchQuery = useSearchDelay(query);

    const [expandedCustomers, setExpandedCustomers] = useState(true);
    const [expandedEmployees, setExpandedEmployees] = useState(true);
    const [expandedJobs, setExpandedJobs] = useState(true);

    const [loadingCustomer, setLoadingCustomer] = useState(false);
    const [loadingEmployee, setLoadingEmployee] = useState(false);
    const [loadingJob, setLoadingJob] = useState(false);
    const [customers, setCustomers] = useState();
    const [employees, setEmployees] = useState();
    const [jobs, setJobs] = useState();

    const [customerPage, setCustomerPage] = useState({ pageNumber: 1, forcePage: 0 });
    const [employeePage, setEmployeePage] = useState({ pageNumber: 1, forcePage: 0 });
    const [jobPage, setJobPage] = useState({ pageNumber: 1, forcePage: 0 });
    const [pageCount, setPageCount] = useState(0);
    const txtStatusJobCompleted = 'Job Completed';
    const txtStatusScheduled = 'Scheduled';
    const stages = ['None', 'To Bid', 'Bid Completed', 'To Be Scheduled', txtStatusScheduled, txtStatusJobCompleted, 'Cancelled', 'Customer Service'];
    const [fetchCancelToken, setCancelToken] = useState();
    const [isHideArchived, setHideArchived] = useState(archived);
    const [isAddressSearch, setAddressSearch] = useState(addressSearch);
    const [searchFieldValue, setSearchField] = useState(searchField);
    const [fetchCancelTokenCustomer, setCancelTokenCustomer] = useState();
    const [fetchCancelTokenEmployees, setCancelTokenEmployees] = useState();

    const onSearch = () => {
        if(searchFieldValue !== "8"){
            fetchCustomers();
            fetchEmployees();
            setJobs(null);
            setCustomerPage({ pageNumber: 1, forcePage: 0 });
            setEmployeePage({ pageNumber: 1, forcePage: 0 });
        }
        if(searchFieldValue === "8"){
            fetchJobs();
            fetchCustomers();
            setEmployeePage(null);
            setJobPage({ pageNumber: 1, forcePage: 0 });
        }
    };

    const onHandleArchivedChange = (event) => {
        setHideArchived(event.target.checked);
    }

    const onHandleAddressSearchChange = (event) => {
        setAddressSearch(event.target.checked);
    }

    const onHandleSearchFieldChange = (event) => {
        if (event.target.value == '8'){
            if (!isNaN(parseFloat(searchQuery))){
                setQuery(searchQuery);
            }
            else
            {
                setQuery('');
            }
        }
        setSearchField(event.target.value);
    }


    const onClear = () => {
        setCustomerPage({ pageNumber: 1, forcePage: 0 });
        setEmployeePage({ pageNumber: 1, forcePage: 0 });
        setJobPage({ pageNumber: 1, forcePage: 0 });
        setQuery('');
        setHideArchived(false);
        setAddressSearch(false);
        setSearchField('0');
    };

    const onChange = event => {
        if (searchFieldValue == '8'){
            if (!isNaN(parseFloat(event.target.value))){
                setQuery(event.target.value);
            }
            else
            {
                setQuery(searchQuery);
            }
        }
        else
        {
            setQuery(event.target.value);
        }
    };

    const onCustomerPageChange = (page) => {
        setCustomerPage({
            pageNumber: page.selected + 1,
            forcePage: page.selected
        });
        fetchCustomers();
    };
    const onEmployeePageChange = (page) => {
        setEmployeePage({
            pageNumber: page.selected + 1,
            forcePage: page.selected
        });
        fetchEmployees();
    };

    const onJobPageChange = (page) => {
        setJobPage({
            pageNumber: page.selected + 1,
            forcePage: page.selected
        });
        fetchJobs();
    };

    const getOpenCompletedJob = (job) => {
        const { jobId, customerName, customerId } = job;

        return `/customers/${encodeURIComponent(customerName)}/${customerId}/jobs/${jobId}/completed`;
    }

    const fetchCustomers = () => {
        if (typeof fetchCancelTokenCustomer != typeof undefined) {
            fetchCancelTokenCustomer.cancel("Operation canceled due to new request.");
        }
        const cancelToken = Axios.CancelToken.source();
        setCancelTokenCustomer(cancelToken);
        let url = apiConfig.url.BASE_URL + apiConfig.url.CUSTOMERS + customerPage.pageNumber;
        const params = { query: searchQuery };
        params.isHideArchived = isHideArchived;
        params.isAddressSearch = isAddressSearch;
        params.searchField =  searchFieldValue;
        setLoadingCustomer(true);
        axios.get(url, { params ,
            cancelToken: cancelToken.token})
            .then(res => {
                if (res.data)
                    setCustomers(res.data)
            })
            .finally(() => setLoadingCustomer(false))
    };
    const fetchEmployees = () => {
        if (typeof fetchCancelTokenEmployees != typeof undefined) {
            fetchCancelTokenEmployees.cancel("Operation canceled due to new request.");
        }
        const cancelToken = Axios.CancelToken.source();
        setCancelTokenEmployees(cancelToken);
        const url = apiConfig.url.USER_URL + apiConfig.url.EMPLOYEES + (employeePage?.pageNumber ?? 1);
        const params = { query: searchQuery };
        setLoadingEmployee(true);
        axios.get(url, { params, cancelToken: cancelToken.token })
            .then(res => {
                if (res.data){
                    const activeUser = res.data.users.filter(x => x.termDate).sort((a, b) => a.fullName.localeCompare(b.fullName))
                    const termUser = res.data.users.filter(x => !x.termDate).sort((a, b) => a.fullName.localeCompare(b.fullName))
                    setEmployees({ ...res.data, users: [...termUser, ...activeUser] })
                }
            })
            .finally(() => setLoadingEmployee(false));
    };
    const fetchJobs = () => {
        if (typeof fetchCancelToken != typeof undefined) {
            fetchCancelToken.cancel("Operation canceled due to new request.");
        }
        const cancelToken = Axios.CancelToken.source();
        setCancelToken(cancelToken);
        if(Number.isInteger(parseFloat(searchQuery))){
            const url = apiConfig.url.BASE_URL + apiConfig.url.CUSTOMER_JOB_COMPLETION;
            let val = parseInt(searchQuery);
            const params = {
                includeCompleted: true,
                includeScheduled: true,
                isGlobal: true,
                invoiceNumber : val,
                pageNumber: jobPage.pageNumber
            };
            setLoadingJob(true);
            axios.get(url, { params,
                cancelToken: cancelToken.token })
                .then(res => {
                    if (res.data){
                        setJobs(res.data);
                        setPageCount(res.data.pageCount);
                    }
                })
                .finally(() => setLoadingJob(false));
        }
    };

    // useEffect(() => {
    //     history.push({ pathname: '/search', search: qs.stringify({ query: searchQuery, type: type , archived : archived , isAddressSearch : isAddressSearch , searchField : searchFieldValue }) });
    //     onSearch();
    // }, [searchQuery,isHideArchived,isAddressSearch,searchFieldValue]);

    useEffect(() => {
        fetchCustomers();
        fetchEmployees();
    }, []);

    return (
        <Page className={classes.root} title="Search">
            <SearchInput
                autoFocus={true}
                className={classes.searchInput}
                placeholder="Search customers and employees..."
                value={query || ''}
                onSearch={onSearch}
                onClear={onClear}
                onChange={onChange}
                hideArchived={type == 'customer'}
                archived={isHideArchived}
                handleArchivedChange={onHandleArchivedChange}
                addressSearch={isAddressSearch}
                handleAddressSearchChange={onHandleAddressSearchChange}
                searchField={searchFieldValue}
                handleSearchFieldChange={onHandleSearchFieldChange}
            />
            <div style={{ display: 'flex', flexDirection: type === 'customer' ? 'column-reverse' : 'column' }}>
                <Card className={classes.card}>
                    <CardHeader title={`Employees (${employees?.totalCount ?? 0})`}
                                action={expandedEmployees ? <KeyboardArrowUp/> : <KeyboardArrowDown/>}
                                onClick={() => setExpandedEmployees(!expandedEmployees)}
                    />
                    {expandedEmployees && <Divider />}
                    <Collapse className={classes.collapse} in={expandedEmployees} timeout='auto' unmountOnExit>
                        {
                            loadingEmployee
                            ? <Grid
                                container
                                spacing={0}
                                align="center"
                                justifyContent="center"
                                direction="column">
                                <Grid item>
                                    <CircularProgress className={classes.circularProgress} size={30} />
                                </Grid>
                            </Grid>
                            : (employees?.users?.length > 0
                                ? <EmployeeCards usersData={employees} forcePage={employeePage.forcePage} onPageChange={onEmployeePageChange} />
                                : <div>
                                    <Typography className={classes.noData}>No data found.</Typography>
                                </div>
                            )
                        }
                    </Collapse>
                </Card>
                <Card className={classes.card}>
                    <CardHeader title={`Customers (${customers?.totalCount ?? 0})`}
                                action={expandedCustomers ? <KeyboardArrowUp/> : <KeyboardArrowDown/>}
                                onClick={() => setExpandedCustomers(!expandedCustomers)}
                    />
                    {expandedCustomers && <Divider />}
                    <Collapse className={classes.collapse} in={expandedCustomers} timeout='auto' unmountOnExit>
                        {
                            loadingCustomer
                            ? <Grid
                                container
                                spacing={0}
                                align="center"
                                justifyContent="center"
                                direction="column">
                                <Grid item>
                                    <CircularProgress className={classes.circularProgress} size={30} />
                                </Grid>
                            </Grid>
                            : (customers?.customers?.length > 0
                                ? <CustomerCards customersData={customers} forcePage={customerPage.forcePage} onPageChange={onCustomerPageChange} />
                                : <div>
                                    <Typography className={classes.noData}>No data found.</Typography>
                                </div>
                            )
                        }
                    </Collapse>
                </Card>
                <Card className={classes.card}>
                    <CardHeader title={`Completed Jobs (${jobs?.total ?? 0})`}
                                action={expandedJobs ? <KeyboardArrowUp/> : <KeyboardArrowDown/>}
                                onClick={() => setExpandedJobs(!expandedJobs)}
                    />
                    {expandedJobs && <Divider />}
                    <Collapse className={classes.collapse} in={expandedJobs} timeout='auto' unmountOnExit>
                        {
                            loadingJob
                            ? <Grid
                                container
                                spacing={0}
                                align="center"
                                justifyContent="center"
                                direction="column">
                                <Grid item>
                                    <CircularProgress className={classes.circularProgress} size={30} />
                                </Grid>
                            </Grid>
                            : (jobs?.data?.length > 0
                                ?   
                                    <>
                                        <TableContainer className={classes.tableContainer}>
                                            <Table style={{ minWidth: '1400px', height: '100%' }}>
                                                <TableHead>
                                                    <TableRow>
                                                        <TableCell align='center' className={classes.headCell}>
                                                            <span>Status</span>
                                                        </TableCell>
                                                        <TableCell align='center' className={classes.headCell}>
                                                            <span>Date</span>
                                                        </TableCell>
                                                        <TableCell align='center' className={classes.headCell}>
                                                            <span>Route #</span>
                                                        </TableCell>
                                                        <TableCell align='center' className={classes.headCell}>
                                                            <span>Customer </span>
                                                        </TableCell>
                                                        <TableCell align='center' className={classes.headCell}>
                                                            <span>Field Technicians</span>
                                                        </TableCell>
                                                        <TableCell align='center' className={classes.headCell}>
                                                            <span>Invoice Sent </span>
                                                        </TableCell>
                                                        <TableCell align='center' className={classes.headCell}>
                                                            <span>Invoice Number </span>
                                                        </TableCell>
                                                        <TableCell align='center' className={classes.headCell}>
                                                            <span>Payment Received </span>
                                                        </TableCell>
                                                        <TableCell align='center' className={classes.headCell}>
                                                            <span>Post to Quickbooks </span>
                                                        </TableCell>
                                                        <TableCell align='center' className={classes.headCell}>
                                                            <span>Commissions Assigned </span>
                                                        </TableCell>
                                                        <TableCell align='center' className={classes.headCell}>
                                                            <span>Unpaid Balance </span>
                                                        </TableCell>
                                                    </TableRow>
                                                </TableHead>
                                                <TableBody>
                                                    {jobs.data.map((job) => (
                                                        <TableRow key={job.jobId} className={classes.jobCompletion}>
                                                            <TableCell>
                                                                <Link className={classes.linkTo} to={() => getOpenCompletedJob(job)}>
                                                                {stages[job.status] || ''}
                                                                </Link>
                                                            </TableCell>
                                                            <TableCell>
                                                                <Link className={classes.linkTo} to={() => getOpenCompletedJob(job)}>
                                                                    {stages[job.status] == txtStatusJobCompleted ? (job.completedDate ? moment.utc(job.completedDate).local().format('MM/DD/YYYY') : '') : ''}
                                                                    {stages[job.status] == txtStatusScheduled ? (job.scheduledDate ? moment.utc(job.scheduledDate).local().format('MM/DD/YYYY') : '') : ''}
                                                                </Link>
                                                            </TableCell>
                                                            <TableCell>
                                                                <Link className={classes.linkTo} to={() => getOpenCompletedJob(job)}>
                                                                {job.routeOrder || ''}
                                                                </Link>
                                                                </TableCell>
                                                            <TableCell>
                                                                <Link className={classes.linkTo} to={() => getOpenCompletedJob(job)}>
                                                                <Typography>
                                                                    {job.customerName || ''}</Typography>
                                                                <Typography>{(job.customerAddress && job.customerAddress.address1) || ''}</Typography>
                                                                <Typography>
                                                                    {(job.customerAddress && `${job.customerAddress.city || ''}, ${job.customerAddress.state && job.customerAddress.state.name || ''} ${job.customerAddress.zipCode || ''}`) || ''}
                                                                </Typography>
                                                                </Link>
                                                            </TableCell>
                                                            <TableCell>
                                                                <Link className={classes.linkTo} to={() => getOpenCompletedJob(job)}>
                                                                {job.routeEmployees.map((e, i) => (
                                                                    <p key={i}>{e.firstName || ''} {e.lastName || ''}</p>
                                                                ))}
                                                                </Link>
                                                            </TableCell>
                                                            <TableCell>
                                                                <Link className={classes.linkTo} to={() => getOpenCompletedJob(job)}>
                                                                {job.invoiceSent ? moment(job.invoiceSent).format('MM/DD/YYYY') : ''}
                                                                </Link>
                                                                </TableCell>
                                                            <TableCell>
                                                                <Link className={classes.linkTo} to={() => getOpenCompletedJob(job)}>
                                                                {job.invoiceNumber || ''}
                                                                </Link>
                                                                </TableCell>    
                                                            <TableCell>
                                                                <Link className={classes.linkTo} to={() => getOpenCompletedJob(job)}>
                                                                {job.paymentReceived ? 'Yes' : 'No'}
                                                                </Link>
                                                                </TableCell>
                                                            <TableCell>
                                                                <Link className={classes.linkTo} to={() => getOpenCompletedJob(job)}>
                                                                {job.postToQuickbooks}
                                                                </Link>
                                                            </TableCell>
                                                            <TableCell>
                                                                <Link className={classes.linkTo} to={() => getOpenCompletedJob(job)}>
                                                                {job.commissionsAssigned}
                                                                </Link>
                                                            </TableCell>
                                                            <TableCell>
                                                                <Link className={classes.linkTo} to={() => getOpenCompletedJob(job)}>
                                                                ${job.unpaidBalance ? parseFloat(job.unpaidBalance).toFixed(2).replace(/(\d)(?=(\d{3})+\.\d{2})/g, '$1,') : '0.00'}
                                                                </Link>
                                                                </TableCell>
                                                        </TableRow>
                                                    ))}
                                                </TableBody>
                                            </Table>
                                        </TableContainer> 
                                        <div style={pageCount <= 1 ? { display: 'none' } : null} className={classes.paginate}>
                                        <Pagination pageCount={pageCount} forcePage={jobPage.forcePage} onPageChange={onJobPageChange} />
                                        </div>   
                                    </>
                                : <div>
                                    <Typography className={classes.noData}>No data found.</Typography>
                                </div>
                            )
                        }
                    </Collapse>
                </Card>
            </div>
        </Page>
    );
};

Search.propTypes = {
  query: PropTypes.string
};

export default Search;