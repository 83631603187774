import React, { useState } from "react";
import PropTypes from "prop-types";
import { Link as RouterLink } from "react-router-dom";
import clsx from "clsx";
import { makeStyles } from "@material-ui/styles";
import {
  AppBar,
  Toolbar,
  Typography,
  Tooltip
} from "@material-ui/core";
import Snackbar from "@material-ui/core/Snackbar";
import Alert from "@material-ui/lab/Alert";

const useStyles = makeStyles(theme => ({
  root: {
    boxShadow: "none"
  },
  flexGrow: {
    flexGrow: 1
  },
  logoText: {
    color: "#fff"
  }
}));

const Topbar = props => {
  const classes = useStyles();
  const [isStatus, setIsStatus] = useState({ failed: false, msg: "" });
  const [openSnackbar, setOpenSnackBar] = useState(false);

  return (
    <AppBar className={clsx(classes.root)}>
      <Toolbar>
        <RouterLink to="/">
          <Tooltip
            arrow
            title={
              <>
                <Typography color="inherit">Dashboard</Typography>
              </>
            }
          >
            <Typography variant="h4" className={classes.logoText}>
              SQUEEGEE SQUAD
            </Typography>
          </Tooltip>
        </RouterLink>
      </Toolbar>
      <Snackbar
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
        open={openSnackbar}
        autoHideDuration={3000}
        onClose={() => setOpenSnackBar(false)}
      >
        <Alert
          elevation={6}
          variant="filled"
          severity={isStatus.failed ? "error" : "success"}
        >
          <Typography color="inherit" variant="h6">
            {isStatus.msg}
          </Typography>
        </Alert>
      </Snackbar>
    </AppBar>
  );
};

Topbar.propTypes = {
  className: PropTypes.string,
  onSidebarOpen: PropTypes.func
};

export default Topbar;
