const totalhours = (d1, d2) => {
    const sd = new Date(d1);
    const ed = new Date(d2);
    if (sd.getTime() !== 0 && ed.getTime() !== 0 && !isNaN(sd.getTime()) && !isNaN(ed.getTime())) {
        const t = ed.getTime() - sd.getTime();
        const h = t > 0 ? Math.round(t/36000)/100 : 0
        return h;
    }
    return 0;
}

export default totalhours;
