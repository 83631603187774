const initState = {
    companyId: '',
    reloadLogHour: false,
    reloadLogHourSideBar: false,
    reloadTaskSideBar: false,
}

const AppReducer = (state = initState, action) => {
    switch (action.type) {
        case 'INIT_COMPANY':
            return {
                ...state,
                companyId: action.companyId
            }
        case 'CHANGE_COMPANY':

            return {
                ...state,
                companyId: action.companyId
            }
        case 'RELOAD_LOG_HOUR':
            return {
                ...state,
                reloadLogHour: !state.reloadLogHour
            }
        case 'RELOAD_LOG_HOUR_SIDEBAR':
            return {
                ...state,
                reloadLogHourSideBar: !state.reloadLogHourSideBar
            }
        case 'RELOAD_EMPLOYEES_TASK_COUNTER':
            return {
                ...state,
                reloadTaskSideBar: !state.reloadTaskSideBar
            }
        default:
            return state
    }
}

export default AppReducer;
