import React, {useEffect, useState} from 'react'
import moment from 'moment'
import { makeStyles } from '@material-ui/styles'
import { Document, Page, Text, View, StyleSheet, PDFDownloadLink, Font, Image } from '@react-pdf/renderer';
import axios from "../../../../../utils/axios";
import apiConfig from "../../../../../apiConfig";
import { width } from '@material-ui/system';
import { checkFeature, FeatureFlags } from "FeatureService";

const useStyles = makeStyles(theme => ({
    
}));

const styles = StyleSheet.create({
    invoiceSection: {
        fontFamily: 'Roboto', fontWeight: 'bold', fontSize: 20
    },
    sectionOne: {
        flexDirection: "row",
        justifyContent: 'space-between',
        width: '100%',
        alignItems: 'center'
    },
    textBold: {
        fontFamily: 'Roboto', fontWeight: 'bold', fontSize: '10px'
    },
    sectionDateAndInvoice: {
        width: '50%', height: 'auto', flexDirection: 'row'
    },
    sectionChildDateAndInvoice: {
        fontFamily: 'Roboto', fontWeight: 'medium', fontSize: 10, width: '50%', border: 1, textAlign: 'center', padding: 6
    },
    sectionTwo: {
        marginTop: 50, 
        flexDirection: "row",
        justifyContent: 'space-between',
        width: '100%',
        alignItems: 'center'
    },
    sectionThree: {
        marginTop: 10, 
        flexDirection: "row",
        justifyContent: 'space-between',
        width: '100%',
        alignItems: 'center'
    },
    dueDate: {
        fontFamily: 'Roboto', fontWeight: 'medium', fontSize: 10, width: '100%', border: 1, textAlign: 'center', padding: 6
    },
    sectionTable: {
        width: '100%', height: 'auto', flexDirection: 'row'
    }
});

const BidsTodoPdf = (props) => {
    const classes = useStyles();
    const [isEnableBidsToDo, setIsEnableBidsToDo] = useState();
    checkFeature(FeatureFlags.BIDS_TO_DO).then(setIsEnableBidsToDo);

    Font.register({
        family: 'Roboto',
        fonts: [
            {
                src: `https://cdn.jsdelivr.net/npm/roboto-fontface@0.10.0/fonts/roboto/Roboto-Regular.woff`,
                fontWeight: 'medium'
            },
            {
                src: `https://cdn.jsdelivr.net/npm/roboto-fontface@0.10.0/fonts/roboto/Roboto-Bold.woff`,
                fontWeight: 'bold'
            }
        ]
    })

    return (
        <>
            <Document>
                {isEnableBidsToDo
                ? <Page size="A4" style={{ padding: 20, paddingTop: 20 }}>
                    <View wrap={false} style={{ width: '100%', flexDirection: 'row' }}>
                        <View style={{ width: '29%', height: 'auto', alignItems: 'center' }}>
                            <Image src="/images/quote_invoice/Logos/Logo1.jpg" width="100%" height="100%" />
                        </View>
                        <View style={{ width: '1%' }} />
                        <View style={{ width: '40%', height: 'auto', alignItems: 'center' }}>
                            <Text style={{ fontFamily: 'Roboto', fontWeight: 'bold', fontSize: 12, color: 'black', marginTop: 5 }}>
                                Your Neighborhood Window Cleaners
                            </Text>
                        </View>
                        <View style={{ width: '1%' }} />
                        <View style={{ width: '29%', height: 'auto', alignItems: 'right' }}>
                            <Text style={{ fontFamily: 'Roboto', fontWeight: 'light', fontSize: 9, color: 'black', marginTop: 5, textAlign: 'right' }}>
                                Printed at: {moment().format('lll')}
                            </Text>
                        </View>
                    </View>

                    {/* <View wrap={false} style={{ width: '100%', flexDirection: 'row', marginTop: 10, alignItems: 'center' }}>
                        <View style={{ width: '100%' }}>
                            <View style={{ flexDirection: 'row' }}>
                                <Text style={{ fontFamily: 'Roboto', fontWeight: 'bold', fontSize: 10, color: 'black', width: '100%' }}>
                                    Current Quotes To Do : {props.data && props.data.length > 0 ? props.data.length : "0"}
                                </Text>
                            </View>
                        </View>
                    </View> */}
                    
                    {props.todayBidders === 'true'
                    ? <View>
                        <View wrap={false} style={{ width: '100%', flexDirection: 'row', marginTop: 10, alignItems: 'center' }}>
                            <View style={{ width: '100%' }}>
                                <View style={{ flexDirection: 'row' }}>
                                    <Text style={{ fontFamily: 'Roboto', fontWeight: 'medium', fontSize: 13, color: 'black', width: '100%' }}>
                                        Today's Bids ({props.data.todays && props.data.todays.length})
                                    </Text>
                                </View>
                            </View>
                        </View>

                        <View style={{ borderBottomWidth: 1, borderBottomColor: 'gray' }}/>

                        <View wrap={false} style={{ width: '100%', marginTop: 10, flexDirection: 'row', height: 'auto', borderWidth: 1, borderColor: 'gray' }}>
                            <View style={{ width: '40%', borderRightWidth: 1, borderRightColor: 'gray', padding: 10 }}>
                                <Text style={{ fontFamily: 'Roboto', fontWeight: 'bold', fontSize: 10, color: 'black', width: '100%' }}>
                                    Customer
                                </Text>
                            </View>
                            <View style={{ width: '20%', borderRightWidth: 1, borderRightColor: 'gray', padding: 10 }}>
                                <Text style={{ fontFamily: 'Roboto', fontWeight: 'bold', fontSize: 10, color: 'black', width: '100%' }}>
                                    Job
                                </Text>
                            </View>
                            <View style={{ width: '25%', borderRightWidth: 1, borderRightColor: 'gray', padding: 10 }}>
                                <Text style={{ fontFamily: 'Roboto', fontWeight: 'bold', fontSize: 10, color: 'black', width: '100%' }}>
                                    Job Notes
                                </Text>
                            </View>
                            <View style={{ width: '15%', padding: 10 }}>
                                <Text style={{ fontFamily: 'Roboto', fontWeight: 'bold', fontSize: 10, color: 'black', width: '100%' }}>
                                    Date of Initial Call/Bid Appointment Time
                                </Text>
                            </View>
                        </View>

                        {props.data.todays && props.data.todays.length > 0 && props.data.todays.map(function (item, index) {
                            return <View key={index} wrap={false} style={{ width: '100%', flexDirection: 'row', height: 'auto', borderWidth: 1, borderColor: 'gray', marginTop: -1 }}>
                                <View style={{ width: '40%', borderRightWidth: 1, borderRightColor: 'gray', padding: 10 }}>
                                    <Text style={{ fontFamily: 'Roboto', fontWeight: 'medium', fontSize: 10, color: 'black', width: '100%' }}>
                                        <Text style={{ fontWeight: 'bold' }}>Customer Name:</Text> {item.companyName || ""}
                                    </Text>
                                    {item.jobsiteContactName ? (
                                        <>
                                            <Text style={{ fontFamily: 'Roboto', fontWeight: 'medium', fontSize: 10, color: 'black', width: '100%' }}>
                                                <Text style={{ fontWeight: 'bold' }}>Jobsite Primary Contact:</Text> {item.jobsiteContactName}
                                            </Text>
                                            <Text style={{ fontFamily: 'Roboto', fontWeight: 'medium', fontSize: 10, color: 'black', width: '100%' }}>
                                                {item.jobsiteContactPhoneType || ""} {item.jobsiteContactPhone || ""}
                                            </Text>
                                            <Text style={{ fontFamily: 'Roboto', fontWeight: 'medium', fontSize: 10, color: 'black', width: '100%' }}>
                                                {item.jobsiteContactEmail || ""}
                                            </Text>
                                        </>
                                    ) : null}
                                </View>
                                <View style={{ width: '20%', borderRightWidth: 1, borderRightColor: 'gray', padding: 10 }}>
                                    <Text style={{ fontFamily: 'Roboto', fontWeight: 'medium', fontSize: 10, color: 'black', width: '100%' }}>
                                        {item.importSource || ""}
                                    </Text>
                                    <Text style={{ fontFamily: 'Roboto', fontWeight: 'medium', fontSize: 10, color: 'black', width: '100%' }}>
                                        {item.jobsiteAddress || ""}
                                    </Text>
                                    <Text style={{ fontFamily: 'Roboto', fontWeight: 'medium', fontSize: 10, color: 'black', width: '100%' }}>
                                        {item.jobsiteCity+", " || ""} {item.jobsiteState || ""} {item.jobsiteZip || ""}
                                    </Text>
                                </View>
                                <View style={{ width: '25%', borderRightWidth: 1, borderRightColor: 'gray', padding: 10 }}>
                                    <Text style={{ fontFamily: 'Roboto', fontWeight: 'medium', fontSize: 10, color: 'black', width: '100%' }}>
                                        {item.jobDescription || ""}
                                    </Text>
                                    <Text style={{ fontFamily: 'Roboto', fontWeight: 'medium', fontSize: 10, color: 'black', width: '100%' }}>
                                        {item.jobNote || ""}
                                    </Text>
                                    <Text style={{ fontFamily: 'Roboto', fontWeight: 'medium', fontSize: 10, color: 'black', width: '100%' }}>
                                        {item.jobSiteNote || ""}
                                    </Text>
                                    <Text style={{ fontFamily: 'Roboto', fontWeight: 'medium', fontSize: 10, color: 'black', width: '100%' }}>
                                        {item.equipment || ""}
                                    </Text>
                                </View>
                                <View style={{ width: '15%', padding: 10 }}>
                                    <Text style={{ fontFamily: 'Roboto', fontWeight: 'medium', fontSize: 10, color: 'black', width: '100%' }}>
                                        {moment(item.initialCallDate).format('MM/DD/YYYY h:mm A')}
                                    </Text>
                                    <Text style={{ fontFamily: 'Roboto', fontWeight: 'medium', fontSize: 10, color: 'black', width: '100%' }}>
                                        {item.bidAppointmentDate != '0001-01-01T00:00:00'
                                            ?  moment(item.bidAppointmentDate+'.000Z').format('MM/DD/YYYY h:mm A')
                                            : "No Bid Scheduled"
                                        }
                                    </Text>
                                </View>
                            </View>
                        })}
                    </View>
                    : null }

                    {props.pastDueBidders === 'true'
                    ? <View>
                        <View wrap={false} style={{ width: '100%', flexDirection: 'row', marginTop: 10, alignItems: 'center' }}>
                            <View style={{ width: '100%' }}>
                                <View style={{ flexDirection: 'row' }}>
                                    <Text style={{ fontFamily: 'Roboto', fontWeight: 'medium', fontSize: 13, color: 'black', width: '100%' }}>
                                        Past Due Bids ({props.data.fastDue && props.data.fastDue.length})
                                    </Text>
                                </View>
                            </View>
                        </View>

                        <View style={{ borderBottomWidth: 1, borderBottomColor: 'gray' }}/>

                        <View wrap={false} style={{ width: '100%', marginTop: 10, flexDirection: 'row', height: 'auto', borderWidth: 1, borderColor: 'gray' }}>
                            <View style={{ width: '40%', borderRightWidth: 1, borderRightColor: 'gray', padding: 10 }}>
                                <Text style={{ fontFamily: 'Roboto', fontWeight: 'bold', fontSize: 10, color: 'black', width: '100%' }}>
                                    Customer
                                </Text>
                            </View>
                            <View style={{ width: '20%', borderRightWidth: 1, borderRightColor: 'gray', padding: 10 }}>
                                <Text style={{ fontFamily: 'Roboto', fontWeight: 'bold', fontSize: 10, color: 'black', width: '100%' }}>
                                    Job
                                </Text>
                            </View>
                            <View style={{ width: '25%', borderRightWidth: 1, borderRightColor: 'gray', padding: 10 }}>
                                <Text style={{ fontFamily: 'Roboto', fontWeight: 'bold', fontSize: 10, color: 'black', width: '100%' }}>
                                    Job Notes
                                </Text>
                            </View>
                            <View style={{ width: '15%', padding: 10 }}>
                                <Text style={{ fontFamily: 'Roboto', fontWeight: 'bold', fontSize: 10, color: 'black', width: '100%' }}>
                                    Date of Initial Call/Bid Appointment Time
                                </Text>
                            </View>
                        </View>

                        {props.data.fastDue && props.data.fastDue.length > 0 && props.data.fastDue.map(function (item, index) {
                            return <View key={index} wrap={false} style={{ width: '100%', flexDirection: 'row', height: 'auto', borderWidth: 1, borderColor: 'gray', marginTop: -1 }}>
                                <View style={{ width: '40%', borderRightWidth: 1, borderRightColor: 'gray', padding: 10 }}>
                                    <Text style={{ fontFamily: 'Roboto', fontWeight: 'medium', fontSize: 10, color: 'black', width: '100%' }}>
                                        <Text style={{ fontWeight: 'bold' }}>Customer Name:</Text> {item.companyName || ""}
                                    </Text>
                                    {item.jobsiteContactName ? (
                                        <>
                                            <Text style={{ fontFamily: 'Roboto', fontWeight: 'medium', fontSize: 10, color: 'black', width: '100%' }}>
                                                <Text style={{ fontWeight: 'bold' }}>Jobsite Primary Contact:</Text> {item.jobsiteContactName}
                                            </Text>
                                            <Text style={{ fontFamily: 'Roboto', fontWeight: 'medium', fontSize: 10, color: 'black', width: '100%' }}>
                                                {item.jobsiteContactPhoneType || ""} {item.jobsiteContactPhone || ""}
                                            </Text>
                                            <Text style={{ fontFamily: 'Roboto', fontWeight: 'medium', fontSize: 10, color: 'black', width: '100%' }}>
                                                {item.jobsiteContactEmail || ""}
                                            </Text>
                                        </>
                                    ) : null}
                                </View>
                                <View style={{ width: '20%', borderRightWidth: 1, borderRightColor: 'gray', padding: 10 }}>
                                    <Text style={{ fontFamily: 'Roboto', fontWeight: 'medium', fontSize: 10, color: 'black', width: '100%' }}>
                                        {item.importSource || ""}
                                    </Text>
                                    <Text style={{ fontFamily: 'Roboto', fontWeight: 'medium', fontSize: 10, color: 'black', width: '100%' }}>
                                        {item.jobsiteAddress || ""}
                                    </Text>
                                    <Text style={{ fontFamily: 'Roboto', fontWeight: 'medium', fontSize: 10, color: 'black', width: '100%' }}>
                                        {item.jobsiteCity+", " || ""} {item.jobsiteState || ""} {item.jobsiteZip || ""}
                                    </Text>
                                </View>
                                <View style={{ width: '25%', borderRightWidth: 1, borderRightColor: 'gray', padding: 10 }}>
                                    <Text style={{ fontFamily: 'Roboto', fontWeight: 'medium', fontSize: 10, color: 'black', width: '100%' }}>
                                        {item.jobDescription || ""}
                                    </Text>
                                    <Text style={{ fontFamily: 'Roboto', fontWeight: 'medium', fontSize: 10, color: 'black', width: '100%' }}>
                                        {item.jobNote || ""}
                                    </Text>
                                    <Text style={{ fontFamily: 'Roboto', fontWeight: 'medium', fontSize: 10, color: 'black', width: '100%' }}>
                                        {item.jobSiteNote || ""}
                                    </Text>
                                    <Text style={{ fontFamily: 'Roboto', fontWeight: 'medium', fontSize: 10, color: 'black', width: '100%' }}>
                                        {item.equipment || ""}
                                    </Text>
                                </View>
                                <View style={{ width: '15%', padding: 10 }}>
                                    <Text style={{ fontFamily: 'Roboto', fontWeight: 'medium', fontSize: 10, color: 'black', width: '100%' }}>
                                        {moment(item.initialCallDate).format('MM/DD/YYYY h:mm A')}
                                    </Text>
                                    <Text style={{ fontFamily: 'Roboto', fontWeight: 'medium', fontSize: 10, color: 'black', width: '100%' }}>
                                        {item.bidAppointmentDate != '0001-01-01T00:00:00'
                                            ?  moment(item.bidAppointmentDate+'.000Z').format('MM/DD/YYYY h:mm A')
                                            : "No Bid Scheduled"
                                        }
                                    </Text>
                                </View>
                            </View>
                        })}
                    </View>
                    : null }

                    {props.upcomingBidders === 'true'
                    ?<View>
                        <View wrap={false} style={{ width: '100%', flexDirection: 'row', marginTop: 10, alignItems: 'center' }}>
                            <View style={{ width: '100%' }}>
                                <View style={{ flexDirection: 'row' }}>
                                    <Text style={{ fontFamily: 'Roboto', fontWeight: 'medium', fontSize: 13, color: 'black', width: '100%' }}>
                                    Upcoming Bids ({props.data.upcoming && props.data.upcoming.length})
                                    </Text>
                                </View>
                            </View>
                        </View>

                        <View style={{ borderBottomWidth: 1, borderBottomColor: 'gray' }}/>

                        <View wrap={false} style={{ width: '100%', marginTop: 10, flexDirection: 'row', height: 'auto', borderWidth: 1, borderColor: 'gray' }}>
                            <View style={{ width: '40%', borderRightWidth: 1, borderRightColor: 'gray', padding: 10 }}>
                                <Text style={{ fontFamily: 'Roboto', fontWeight: 'bold', fontSize: 10, color: 'black', width: '100%' }}>
                                    Customer
                                </Text>
                            </View>
                            <View style={{ width: '20%', borderRightWidth: 1, borderRightColor: 'gray', padding: 10 }}>
                                <Text style={{ fontFamily: 'Roboto', fontWeight: 'bold', fontSize: 10, color: 'black', width: '100%' }}>
                                    Job
                                </Text>
                            </View>
                            <View style={{ width: '25%', borderRightWidth: 1, borderRightColor: 'gray', padding: 10 }}>
                                <Text style={{ fontFamily: 'Roboto', fontWeight: 'bold', fontSize: 10, color: 'black', width: '100%' }}>
                                    Job Notes
                                </Text>
                            </View>
                            <View style={{ width: '15%', padding: 10 }}>
                                <Text style={{ fontFamily: 'Roboto', fontWeight: 'bold', fontSize: 10, color: 'black', width: '100%' }}>
                                    Date of Initial Call/Bid Appointment Time
                                </Text>
                            </View>
                        </View>

                        {props.data.upcoming && props.data.upcoming.length > 0 && props.data.upcoming.map(function (item, index) {
                            return <View key={index} wrap={false} style={{ width: '100%', flexDirection: 'row', height: 'auto', borderWidth: 1, borderColor: 'gray', marginTop: -1 }}>
                                <View style={{ width: '40%', borderRightWidth: 1, borderRightColor: 'gray', padding: 10 }}>
                                    <Text style={{ fontFamily: 'Roboto', fontWeight: 'medium', fontSize: 10, color: 'black', width: '100%' }}>
                                        <Text style={{ fontWeight: 'bold' }}>Customer Name:</Text> {item.companyName || ""}
                                    </Text>
                                    {item.jobsiteContactName ? (
                                        <>
                                            <Text style={{ fontFamily: 'Roboto', fontWeight: 'medium', fontSize: 10, color: 'black', width: '100%' }}>
                                                <Text style={{ fontWeight: 'bold' }}>Jobsite Primary Contact:</Text> {item.jobsiteContactName}
                                            </Text>
                                            <Text style={{ fontFamily: 'Roboto', fontWeight: 'medium', fontSize: 10, color: 'black', width: '100%' }}>
                                                {item.jobsiteContactPhoneType || ""} {item.jobsiteContactPhone || ""}
                                            </Text>
                                            <Text style={{ fontFamily: 'Roboto', fontWeight: 'medium', fontSize: 10, color: 'black', width: '100%' }}>
                                                {item.jobsiteContactEmail || ""}
                                            </Text>
                                        </>
                                    ) : null}
                                </View>
                                <View style={{ width: '20%', borderRightWidth: 1, borderRightColor: 'gray', padding: 10 }}>
                                    <Text style={{ fontFamily: 'Roboto', fontWeight: 'medium', fontSize: 10, color: 'black', width: '100%' }}>
                                        {item.importSource || ""}
                                    </Text>
                                    <Text style={{ fontFamily: 'Roboto', fontWeight: 'medium', fontSize: 10, color: 'black', width: '100%' }}>
                                        {item.jobsiteAddress || ""}
                                    </Text>
                                    <Text style={{ fontFamily: 'Roboto', fontWeight: 'medium', fontSize: 10, color: 'black', width: '100%' }}>
                                        {item.jobsiteCity+", " || ""} {item.jobsiteState || ""} {item.jobsiteZip || ""}
                                    </Text>
                                </View>
                                <View style={{ width: '25%', borderRightWidth: 1, borderRightColor: 'gray', padding: 10 }}>
                                    <Text style={{ fontFamily: 'Roboto', fontWeight: 'medium', fontSize: 10, color: 'black', width: '100%' }}>
                                        {item.jobDescription || ""}
                                    </Text>
                                    <Text style={{ fontFamily: 'Roboto', fontWeight: 'medium', fontSize: 10, color: 'black', width: '100%' }}>
                                        {item.jobNote || ""}
                                    </Text>
                                    <Text style={{ fontFamily: 'Roboto', fontWeight: 'medium', fontSize: 10, color: 'black', width: '100%' }}>
                                        {item.jobSiteNote || ""}
                                    </Text>
                                    <Text style={{ fontFamily: 'Roboto', fontWeight: 'medium', fontSize: 10, color: 'black', width: '100%' }}>
                                        {item.equipment || ""}
                                    </Text>
                                </View>
                                <View style={{ width: '15%', padding: 10 }}>
                                    <Text style={{ fontFamily: 'Roboto', fontWeight: 'medium', fontSize: 10, color: 'black', width: '100%' }}>
                                        {moment(item.initialCallDate).format('MM/DD/YYYY h:mm A')}
                                    </Text>
                                    <Text style={{ fontFamily: 'Roboto', fontWeight: 'medium', fontSize: 10, color: 'black', width: '100%' }}>
                                        {item.bidAppointmentDate != '0001-01-01T00:00:00'
                                            ?  moment(item.bidAppointmentDate+'.000Z').format('MM/DD/YYYY h:mm A')
                                            : "No Bid Scheduled"
                                        }
                                    </Text>
                                </View>
                            </View>
                        })}
                    </View>
                    : null }
                </Page>
                : <Page size="A4" style={{ padding: 20, paddingTop: 20 }}>
                    <View wrap={false} style={{ width: '100%', flexDirection: 'row' }}>
                        <View style={{ width: '29%', height: 'auto', alignItems: 'center' }}>
                            <Image src="/images/quote_invoice/Logos/Logo1.jpg" width="100%" height="100%" />
                        </View>
                        <View style={{ width: '1%' }} />
                        <View style={{ width: '40%', height: 'auto', alignItems: 'center' }}>
                            <Text style={{ fontFamily: 'Roboto', fontWeight: 'bold', fontSize: 12, color: 'black', marginTop: 5 }}>
                                Your Neighborhood Window Cleaners
                            </Text>
                        </View>
                        <View style={{ width: '1%' }} />
                        <View style={{ width: '29%', height: 'auto', alignItems: 'center' }}>
                            
                        </View>
                    </View>

                    <View wrap={false} style={{ width: '100%', flexDirection: 'row', marginTop: 10, alignItems: 'center' }}>
                        <View style={{ width: '100%' }}>
                            <View style={{ flexDirection: 'row' }}>
                                <Text style={{ fontFamily: 'Roboto', fontWeight: 'bold', fontSize: 10, color: 'black', width: '100%' }}>
                                    Current Quotes To Do : {props.data && props.data.length > 0 ? props.data.length : "0"}
                                </Text>
                            </View>
                        </View>
                    </View>

                    <View wrap={false} style={{ width: '100%', flexDirection: 'row', marginTop: 10, alignItems: 'center' }}>
                        <View style={{ width: '100%' }}>
                            <View style={{ flexDirection: 'row' }}>
                                <Text style={{ fontFamily: 'Roboto', fontWeight: 'medium', fontSize: 13, color: 'black', width: '100%' }}>
                                    Bids Not Done
                                </Text>
                            </View>
                        </View>
                    </View>

                    <View style={{ borderBottomWidth: 1, borderBottomColor: 'gray' }}/>

                    <View wrap={false} style={{ width: '100%', marginTop: 10, flexDirection: 'row', height: 'auto', borderWidth: 1, borderColor: 'gray' }}>
                        <View style={{ width: '40%', borderRightWidth: 1, borderRightColor: 'gray', padding: 10 }}>
                            <Text style={{ fontFamily: 'Roboto', fontWeight: 'bold', fontSize: 10, color: 'black', width: '100%' }}>
                                Customer
                            </Text>
                        </View>
                        <View style={{ width: '20%', borderRightWidth: 1, borderRightColor: 'gray', padding: 10 }}>
                            <Text style={{ fontFamily: 'Roboto', fontWeight: 'bold', fontSize: 10, color: 'black', width: '100%' }}>
                                Job
                            </Text>
                        </View>
                        <View style={{ width: '25%', borderRightWidth: 1, borderRightColor: 'gray', padding: 10 }}>
                            <Text style={{ fontFamily: 'Roboto', fontWeight: 'bold', fontSize: 10, color: 'black', width: '100%' }}>
                                Job Notes
                            </Text>
                        </View>
                        <View style={{ width: '15%', padding: 10 }}>
                            <Text style={{ fontFamily: 'Roboto', fontWeight: 'bold', fontSize: 10, color: 'black', width: '100%' }}>
                                Date of Initial Call/Bid Appointment Time
                            </Text>
                        </View>
                    </View>

                    {props.data && props.data.length > 0 && props.data.map(function (item, index) {
                        return <View key={index} wrap={false} style={{ width: '100%', flexDirection: 'row', height: 'auto', borderWidth: 1, borderColor: 'gray', marginTop: -1 }}>
                            <View style={{ width: '40%', borderRightWidth: 1, borderRightColor: 'gray', padding: 10 }}>
                                    <Text style={{ fontFamily: 'Roboto', fontWeight: 'medium', fontSize: 10, color: 'black', width: '100%' }}>
                                        <Text style={{ fontWeight: 'bold' }}>Customer Name:</Text> {item.companyName || ""}
                                    </Text>
                                    {item.jobsiteContactName ? (
                                        <>
                                            <Text style={{ fontFamily: 'Roboto', fontWeight: 'medium', fontSize: 10, color: 'black', width: '100%' }}>
                                                <Text style={{ fontWeight: 'bold' }}>Jobsite Primary Contact:</Text> {item.jobsiteContactName}
                                            </Text>
                                            <Text style={{ fontFamily: 'Roboto', fontWeight: 'medium', fontSize: 10, color: 'black', width: '100%' }}>
                                                {item.jobsiteContactPhoneType || ""} {item.jobsiteContactPhone || ""}
                                            </Text>
                                            <Text style={{ fontFamily: 'Roboto', fontWeight: 'medium', fontSize: 10, color: 'black', width: '100%' }}>
                                                {item.jobsiteContactEmail || ""}
                                            </Text>
                                        </>
                                    ) : null}
                                </View>
                            <View style={{ width: '20%', borderRightWidth: 1, borderRightColor: 'gray', padding: 10 }}>
                                <Text style={{ fontFamily: 'Roboto', fontWeight: 'medium', fontSize: 10, color: 'black', width: '100%' }}>
                                    {item.importSource || ""}
                                </Text>
                                <Text style={{ fontFamily: 'Roboto', fontWeight: 'medium', fontSize: 10, color: 'black', width: '100%' }}>
                                    {item.jobsiteAddress || ""}
                                </Text>
                                <Text style={{ fontFamily: 'Roboto', fontWeight: 'medium', fontSize: 10, color: 'black', width: '100%' }}>
                                    {item.jobsiteCity+", " || ""} {item.jobsiteState || ""} {item.jobsiteZip || ""}
                                </Text>
                            </View>
                            <View style={{ width: '25%', borderRightWidth: 1, borderRightColor: 'gray', padding: 10 }}>
                                <Text style={{ fontFamily: 'Roboto', fontWeight: 'medium', fontSize: 10, color: 'black', width: '100%' }}>
                                    {item.jobDescription || ""}
                                </Text>
                                <Text style={{ fontFamily: 'Roboto', fontWeight: 'medium', fontSize: 10, color: 'black', width: '100%' }}>
                                    {item.jobNote || ""}
                                </Text>
                                <Text style={{ fontFamily: 'Roboto', fontWeight: 'medium', fontSize: 10, color: 'black', width: '100%' }}>
                                    {item.jobSiteNote || ""}
                                </Text>
                                <Text style={{ fontFamily: 'Roboto', fontWeight: 'medium', fontSize: 10, color: 'black', width: '100%' }}>
                                    {item.equipment || ""}
                                </Text>
                            </View>
                            <View style={{ width: '15%', padding: 10 }}>
                                <Text style={{ fontFamily: 'Roboto', fontWeight: 'medium', fontSize: 10, color: 'black', width: '100%' }}>
                                    {moment(item.initialCallDate).format('MM/DD/YYYY h:mm A')}
                                </Text>
                                <Text style={{ fontFamily: 'Roboto', fontWeight: 'medium', fontSize: 10, color: 'black', width: '100%' }}>
                                    {item.bidAppointmentDate != '0001-01-01T00:00:00'
                                        ?  moment(item.bidAppointmentDate+'.000Z').format('MM/DD/YYYY h:mm A')
                                        : "No Bid Scheduled"
                                    }
                                </Text>
                            </View>
                        </View>
                    })}

                </Page>
                }
            </Document>
        </>
    )
}

BidsTodoPdf.propTypes = {
    
}

export default BidsTodoPdf;
