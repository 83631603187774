import React, {useEffect, useState} from 'react'
import PropTypes from 'prop-types'
import {Link} from 'react-router-dom'
import {makeStyles} from '@material-ui/styles'
import {
    Button,
    Card,
    CardActions,
    CardContent,
    CardHeader, CircularProgress,
    colors,
    Divider,
    ListItem,
    ListItemText,
    Modal,
} from '@material-ui/core'
import apiConfig from 'apiConfig'
import axios from 'utils/axios'
import {FixedSizeList} from "react-window";
import AddIcon from "@material-ui/icons/Add";
import { Pagination, SearchInput } from 'components'
import { useSearchDelay } from 'utils/useSearchDelay'

const useStyles = makeStyles(theme => ({
    styleModal: {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        outline: 'none',
        boxShadow: theme.shadows[20],
        width: 800,
        maxHeight: '90%',
        overflowY: 'auto',
        maxWidth: '100%',
        [theme.breakpoints.down('sm')]: {
            width: 500
        },
    },
    btn: {
        color: theme.palette.white,
        backgroundColor: colors.green[600],
        '&:hover': {
            backgroundColor: colors.green[900]
        }
    },
    searchInput: {
        marginBottom: theme.spacing(3)
    },
    paginate: {
        marginTop: theme.spacing(3),
        marginBottom: theme.spacing(3),
        display: 'flex',
        justifyContent: 'center'
    }
}))

let pageNumber = 1;
let forcePage = 0;

const CustomersModal = ({ open, onClose, openModalAddress, setSelectedCustomer }) => {;

    const classes = useStyles();
    const [customersData, setCustomersData] = useState();
    const [loading, setLoading] = useState(true);
    const [value, setValue] = useState('');
    let searchQuery = useSearchDelay(value);

    const onClear = () => {
        forcePage = 0;
        pageNumber = 1;
        setValue('')
    }

    const onPageChange = (page) => {
        pageNumber = page.selected + 1;
        forcePage = page.selected;
        fetchCustomers();
    };

    const fetchCustomers = () => {
        setLoading(true);
        let url = apiConfig.url.BASE_URL + apiConfig.url.CUSTOMERS + pageNumber;
        const params = {};
        if (searchQuery) {
            params.query = searchQuery;
        }
        axios.get(url, {params})
            .then(res => {
                setCustomersData(res.data)
            })
            .finally(() => {
                setLoading(false);
            })
    };

    function renderRow(props) {
        const { index, style, data } = props;
        return (
            <ListItem button style={style} key={index} onClick={() => {
                setSelectedCustomer(data[index]);
                openModalAddress(true);
            }}>
                <ListItemText  primary={`${data[index].companyName}`} />
            </ListItem>
        );
    }

    const handleClose = () => {
        onClose();
    }

    useEffect(() => {
        fetchCustomers();
    },[searchQuery])

    return (
        <Modal open={open} onClose={onClose}>
            <Card className={classes.styleModal}>
                <CardHeader title="Select customer" />
                <Divider />
                <CardContent style={{textAlign: 'center'}}>
                    <SearchInput
                        className={classes.searchInput}
                        placeholder="Search customers..."
                        onClear={onClear}
                        value={value}
                        onChange={event => setValue(event.target.value)}
                    />
                    {
                        loading ?
                            <CircularProgress className={classes.circularProgress} size={50}/>
                            :
                            <>
                                <FixedSizeList height={500} width={'100%'} itemSize={46} itemCount={customersData?.customers?.length}
                                            itemData={customersData?.customers}>
                                    {renderRow}
                                </FixedSizeList>
                                <div style={(customersData?.pageCount ?? 0) <= 1 ? { display: 'none' } : null} className={classes.paginate}>
                                    <Pagination pageCount={(customersData?.pageCount ?? 0)} forcePage={forcePage} onPageChange={onPageChange} />
                                </div>
                            </>
                            
                    }
                    <Button
                        onClick={() => onClose()}
                        style={{ marginBottom: "8px", display: 'block' }}
                        className={classes.btn}
                    >
                        <Link to="/customers/add-customer">
                            <span style={{display: 'flex', alignItems: 'center', color: '#ffffff' }}><AddIcon /> Add Customer</span>
                        </Link>
                    </Button>
                </CardContent>
                <Divider />
                <CardActions style={{ justifyContent: 'flex-end' }}>
                    <Button onClick={handleClose} variant="contained">
                        Close
                    </Button>
                </CardActions>
            </Card>
        </Modal>
    )
}

CustomersModal.propTypes = {
    defaultType: PropTypes.string,
    open: PropTypes.bool,
    onClose: PropTypes.func,
    handleSelectedSource: PropTypes.func
}

export default CustomersModal
