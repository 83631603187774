import React, { useState, useEffect } from "react";
import { withRouter } from "react-router-dom";
import PropTypes from "prop-types";
import validate from "validate.js";
import { makeStyles } from "@material-ui/styles";
import { Button, TextField, Card, CardHeader, CardContent, Divider, CircularProgress, Typography } from "@material-ui/core";
import axios from "utils/axios";
import apiConfig from "apiConfig";
import { useParams } from "react-router-dom";
import Alert from "@material-ui/lab/Alert";
import Snackbar from "@material-ui/core/Snackbar";
import { baseUrl } from "apiConfig";

const schema = {
  password: {
    presence: { allowEmpty: false, message: "is required" },
  },
  repeatpassword: {
    presence: { allowEmpty: false, message: "is required" },
  },
};

const useStyles = makeStyles((theme) => ({
  root: {
    height: "100%",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    padding: theme.spacing(1, 2),
  },
  grid: {
    height: "100%",
  },

  form: {
    width: "500px",
    maxWidth: "100%",
    paddingLeft: 50,
    paddingRight: 50,
    paddingBottom: 50,
    flexBasis: 700,
    [theme.breakpoints.down("sm")]: {
      paddingLeft: theme.spacing(2),
      paddingRight: theme.spacing(2),
    },
  },
  title: {
    marginTop: theme.spacing(3),
  },

  textField: {
    marginTop: theme.spacing(2),
  },
  signInButton: {
    margin: theme.spacing(2, 0),
  },
  cardHeaderTitle: {
    textAlign: "center",
  },
}));

const ResetPassword = (props) => {
  const { history } = props;
  const classes = useStyles();

  const { userId, code } = useParams();
  const [isStatus, setIsStatus] = useState({ failed: false, msg: "" });
  const [openSnackbar, setOpenSnackBar] = useState(false);
  const [formState, setFormState] = useState({
    isValid: false,
    values: {
      password: "",
      repeatpassword: "",
    },
    touched: {},
    errors: {},
  });
  const [isProgress, setIsProgress] = useState(false);

  useEffect(() => {
    var errors = validate(formState.values, schema);

    setFormState((formState) => ({
      ...formState,
      isValid: errors ? false : true,
      errors: errors || {},
    }));
  }, [formState.values]);

  const handleChange = (event) => {
    event.persist();

    setFormState((formState) => ({
      ...formState,
      values: {
        ...formState.values,
        [event.target.name]: event.target.value,
      },
      touched: {
        ...formState.touched,
        [event.target.name]: true,
      },
    }));
  };

  const resetPass = (event) => {
    event.preventDefault();
    setIsProgress(true);

    //no need to post if values don't match
    if (formState.values.repeatpassword !== "" && formState.values.password !== "" && formState.values.repeatpassword !== formState.values.password) {
      setIsStatus({
        failed: true,
        msg: "Passwords do not match",
      });
      setOpenSnackBar(true);
      setIsProgress(false);
      return;
    }

    let postData = {
      UserId: userId,
      PasswordResetToken: code,
      Password: formState.values.repeatpassword,
    };

    setIsProgress(true);
    axios
      .post(apiConfig.url.AUTH_URL + apiConfig.url.RESET_PASSWORD, postData)
      .then((response) => {
        setIsStatus({
          failed: false,
          msg: "Successfully Reset Password",
        });
        setOpenSnackBar(true);
        setTimeout(() => {
          window.location.href = baseUrl() + "sign-in";
        }, 1000);
      })
      .catch((ex) => {
        setIsStatus({
          failed: true,
          msg: ex.response.status >= 400 ? "Error resetting password" : ex.message,
        });

        setIsProgress(false);
      });
  };

  return (
    <div className={classes.root}>
      <Card>
        <CardHeader
          className={classes.cardHeaderTitle}
          classes={{
            title: classes.cardHeaderTitle,
          }}
          title={"Reset Password"}
        />
        <Divider />
        <CardContent>
          <div className={classes.content}>
            <div className={classes.contentBody}>
              <form className={classes.form} onSubmit={resetPass}>
                <TextField
                  className={classes.textField}
                  fullWidth
                  label="New Password"
                  name="password"
                  onChange={handleChange}
                  type="password"
                  value={formState.values.password || ""}
                  variant="outlined"
                />
                <TextField
                  className={classes.textField}
                  fullWidth
                  label="Repeat New Password"
                  name="repeatpassword"
                  onChange={handleChange}
                  type="password"
                  value={formState.values.repeatpassword || ""}
                  variant="outlined"
                />

                <div style={{ textAlign: "center" }}>
                  <Button className={classes.signInButton} color="primary" disabled={!formState.isValid || isProgress} fullWidth size="large" type="submit" variant="contained">
                    Reset Password
                  </Button>
                  {isProgress && <CircularProgress size={24} />}
                </div>
              </form>
            </div>
          </div>
        </CardContent>
      </Card>
      <Snackbar anchorOrigin={{ vertical: "top", horizontal: "center" }} open={openSnackbar} autoHideDuration={5000} onClose={() => setOpenSnackBar(false)}>
        <Alert elevation={6} variant="filled" severity={isStatus.failed ? "error" : "success"}>
          <Typography color="inherit" variant="h6">
            {isStatus.msg}
          </Typography>
        </Alert>
      </Snackbar>
    </div>
  );
};

ResetPassword.propTypes = {
  history: PropTypes.object,
};

export default withRouter(ResetPassword);
