import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import {
    Table, TableHead, TableBody, TableRow, TableCell, Snackbar, Typography, CircularProgress
} from '@material-ui/core'
import * as moment from 'moment'
import { makeStyles } from '@material-ui/styles'
import { useSelector } from "react-redux";
import DropBox from './DropBox'
import ConfirmRescheduleModal from './ConfirmRescheduleModal'
import { Alert } from '@material-ui/lab'
import axios from "utils/axios";
import apiConfig from "apiConfig";

const useStyles = makeStyles(theme => ({
    styleTable: {
        minWidth: '750px',
        minHeight: '100%',
        '& thead tr th,tbody tr td': {
            border: '1px solid #eeeeee'
        },
        '& thead tr th,tbody tr$updating td': {
            border: 'none'
        }
    },
    dayOfWeek: {
        // width: 'calc(100% / 7)'
    },
    link: {
        color: '#263238',
        display: 'block'
    },
    updating: {
        width: '100%',
        height: '100%',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        position: 'absolute',
        top: 0,
        left: 0,
        zIndex: 2,
        backgroundColor: '#0000001f'
    }
}));

let mounted = false;
const WeekCalendar = props => {

    const classes = useStyles();
    
    const { index, days, contact, redirectToRoutesSchedules } = props;
    const { schedules, distances } = useSelector(state => state.SchedulesReducer);
    const [listDays, setListDay] = useState([]);
    const [updating, setUpdating] = useState(false);
    const [scheduleRoute, setScheduleRoute] = useState();
    const [scheduleToDate, setScheduleToDate] = useState();
    const [conflictingEmployees, setConflictingEmployees] = useState([]);
    const [isStatus, setIsStatus] = useState({ failed: false, msg: '' });
    const [openSnackbar, setOpenSnackbar] = useState(false);
    const [openConfirmRescheduleModal, setOpenConfirmRescheduleModal] = useState(false);

    const handleDropItem = (item, day) => {
        const updateRoute = schedules?.find(x => x.id === item.routeId);
        if (moment(day).format('YYYY-MM-DD') === moment(updateRoute.routeDate).format('YYYY-MM-DD')) return;

        setScheduleRoute(updateRoute);
        const routesInDate = schedules?.filter(x => moment(day).format('YYYY-MM-DD') === moment(x.routeDate).format('YYYY-MM-DD')) ?? [];
        const employeeIdsInDate = routesInDate
            .reduce((employees, route) => [...employees, ...route.routeEmployees], [])
            .map(re => re.employeeId);

        setConflictingEmployees(updateRoute?.routeEmployees.filter(re => employeeIdsInDate.includes(re.employeeId)) ?? []);
        setScheduleToDate(moment(day).format('MM-DD-YYYY'));
        setOpenConfirmRescheduleModal(true);
    };

    const handleReschedule = (moveEmployees) => {
        setOpenConfirmRescheduleModal(false);
        setUpdating(true);
        axios.put(
            apiConfig.url.BASE_URL + apiConfig.url.ROUTE_DATE.replace('{routeId}', scheduleRoute.id),
            {
                routeDate: scheduleToDate,
                moveEmployees,
                moveEmployeeIds: conflictingEmployees.map(e => e.employeeId)
            }
        )
            .then(res => {
                scheduleRoute.routeDate = moment(res.data.routeDate).format('YYYY-MM-DD');
                scheduleRoute.routeOrder = res.data.routeOrder;
                setIsStatus({ failed: false, msg: 'Update route successfully.' });
                setOpenSnackbar(true);
            })
            .catch(() => {
                setIsStatus({ failed: true, msg: 'Update route failed, please try again later.' });
                setOpenSnackbar(true);
            })
            .finally(() => setUpdating(false));
    }

    useEffect(() => {
    }, [distances])

    useEffect(() => {
        mounted = true;
        if (days) {
            const day = days.map(day => {
                return moment(day).format('YYYY-MM-DD')
            })
            mounted && setListDay(day);
        }

        return () => mounted = false;
    }, [days])

    return (
        <>
            <Table stickyHeader className={classes.styleTable}>
                <TableHead>
                    <TableRow>
                        <TableCell className={classes.dayOfWeek}>Sunday</TableCell>
                        <TableCell className={classes.dayOfWeek}>Monday</TableCell>
                        <TableCell className={classes.dayOfWeek}>Tuesday</TableCell>
                        <TableCell className={classes.dayOfWeek}>Wednesday</TableCell>
                        <TableCell className={classes.dayOfWeek}>Thursday</TableCell>
                        <TableCell className={classes.dayOfWeek}>Friday</TableCell>
                        <TableCell className={classes.dayOfWeek}>Saturday</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody style={{ position: 'relative' }}>
                    <TableRow>
                        {days.slice(7 * index, 7 + (7 * index)).map((day, index) => {

                            const dayString = moment(day).format('YYYY-MM-DD');
                            let routes = (listDays.length > 0 && listDays.indexOf(dayString)) !== -1 ?
                                schedules && schedules.filter(rs => rs.routeDate === dayString) : null
                            if (routes && routes.length > 0) {
                                if (distances?.length > 0) {
                                    routes = routes.map(r => {
                                        var dis = distances.find(x => x.routeId == r.id) || 0;
                                        return {...r, distance: dis.distance}
                                    })
                                    routes = routes.sort((a, b) => parseFloat(a.distance) - parseFloat(b.distance))
                                } else {
                                    routes = routes.sort((a, b) => parseFloat(a.routeOrder) - parseFloat(b.routeOrder))
                                }
                                routes = routes.sort((a, b) => a.routeFull - b.routeFull);
                            }

                            return (
                                <DropBox
                                    key={day}
                                    day={day}
                                    isToday={dayString === moment(new Date()).format('YYYY-MM-DD')}
                                    routes={routes}
                                    contact={contact}
                                    onClick={() => redirectToRoutesSchedules(dayString)}
                                    handleDropItem={handleDropItem}
                                />
                            )
                        })}
                    </TableRow>
                    {
                        updating && (
                            <TableRow className={classes.updating}>
                                <TableCell>
                                    <CircularProgress size={32} />
                                </TableCell>
                            </TableRow>
                        )
                    }
                </TableBody>
            </Table>

            <ConfirmRescheduleModal
                open={openConfirmRescheduleModal}
                date={scheduleToDate}
                employees={conflictingEmployees}
                onConfirm={handleReschedule}
                onClose={() => setOpenConfirmRescheduleModal(false)}
            />

            <Snackbar
                open={openSnackbar}
                onClose={() => setOpenSnackbar(false)}
                autoHideDuration={2000}
                anchorOrigin={{ vertical: "top", horizontal: "center" }}
            >
                <Alert
                    elevation={6}
                    variant="filled"
                    severity={isStatus.failed ? "error" : "success"}
                >
                    <Typography color="inherit" variant="h6">
                        {isStatus.msg}
                    </Typography>
                </Alert>
            </Snackbar>
        </>
    )
}

WeekCalendar.propTypes = {
    index: PropTypes.number.isRequired,
    days: PropTypes.array.isRequired
}

WeekCalendar.defaultProps = {
    week: []
}

export default WeekCalendar;
