import React from 'react'
import {
    Grid, Typography,
} from '@material-ui/core'
import { makeStyles } from '@material-ui/styles'

const useStyles = makeStyles(theme => ({
    guide: {
        fontSize: '18px',
        textAlign: 'center',
    },
    link: {
        textDecoration: 'underline'
    }
}));

const StepOne = props => {

    const classes = useStyles();

    return (
        <>
            <Grid container spacing={1}>
                <Grid item xs={12} style={{ margin: '0 20%' }}>
                    <Typography className={classes.guide}>
                        <strong>Step 1: </strong>
                        If you do not currently have the QuickBooks Web Connector Software.
                    </Typography>
                    <Typography className={classes.guide}>Download it <a className={classes.link} href='https://static.developer.intuit.com/resources/quickbooksWebconnector/QBWebConnector2_3R0_00215.zip' target='_blank'>HERE</a></Typography>
                </Grid>
            </Grid>
        </>
    )
}

export default StepOne;