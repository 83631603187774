import React, {Fragment, useEffect, useState} from 'react'
import {makeStyles} from '@material-ui/styles'
import {Alert} from '@material-ui/lab';
import {
    Button,
    Card,
    CardActions,
    CardContent,
    CardHeader,
    CircularProgress,
    colors,
    Divider,
    FormControlLabel,
    Grid,
    InputLabel,
    MenuItem,
    Modal,
    Paper,
    Select,
    Snackbar,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableRow,
    TextField,
    Typography
} from '@material-ui/core'
import {GreenCheckbox, GreenRadio} from 'components'
import axios from 'utils/axios'
import apiConfig from 'apiConfig'
import {useDispatch} from 'react-redux';
import localStorage from "../../../../../utils/localStorage";
import {KeyboardDatePicker, MuiPickersUtilsProvider} from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
import FormControl from "@material-ui/core/FormControl";

const useStyles = makeStyles(theme => ({
    root: {},
    styleModal: {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        outline: 'none',
        boxShadow: theme.shadows[20],
        width: 1000,
        maxHeight: '100%',
        overflowY: 'auto',
        maxWidth: '100%',
        [theme.breakpoints.down('sm')]: {
            width: 500
        },
    },
    btnUpFile: {
        flexShrink: '0',
        color: theme.palette.white,
        backgroundColor: colors.green[600],
        '&:hover': {
            backgroundColor: colors.green[900]
        },
    },
    actionCard: {
        justifyContent: 'flex-end',
        borderTop: '1px solid #eeeeee'
    },
    textField: {
        fontSize: '16px'
    },
    select: {
        backgroundColor: "#ffffff",
        fontSize: theme.spacing(2),
        "& div": {
            paddingTop: "10px",
            paddingBottom: "10px",
            width: "auto",
            minWith: "90px"
        }
    },
}));

const AddCustomform = props => {

    const classes = useStyles();

    const { jobId, customFormId, open, onClose } = props;

    const [openSnack, setOpenSnack] = useState(false);
    const [isSuccess, setIsSuccess] = useState({ failed: false, msg: '' });
    const [selectedCustomForm, setSelectedCustomForm] = useState(null);
    const [forms, setForms] = useState([]);
    const [isSaving, setIsSaving] = useState(false);
    const [loading, setLoading] = useState(false);

    const onCloseModal = () => {
        onClose();
        setSelectedCustomForm(null);
    }

    const isJsonString = (str) => {
        try {
            JSON.parse(str);
        } catch (e) {
            return false;
        }
        return true;
    }

    const fetchCustomForms = () => {
        setLoading(true);
        axios.get(apiConfig.url.BASE_URL + apiConfig.url.GET_ACTIVE_CUSTOM_FORMS).then(res => {
            if (res.data) {
                setForms(res.data.map(data => {
                    let questions = data.customFormQuestions.map(item => {
                        let _answer = item.answer ? (isJsonString(item.answer.answer) ? JSON.parse(item.answer.answer) : item.answer.answer) : '' ;
                        if(item.controlType == 'table'){
                            _answer.userAnswer = Array(parseInt(_answer.rows)).fill(Array(parseInt(_answer.columns)).fill(''));
                        }
                        let a =  {
                            id: item.id,
                            questionName: item.question,
                            questionType: item.customFormControlTypeId,
                            questionControlType : item.controlType,
                            answer: {...item.answer, answer: _answer}
                        }
                        return a;
                    })
                    return {
                        id: data.customFormCompany.id,
                        formName: data.customFormCompany.formName,
                        formDescription: data.customFormCompany.description,
                        questions: questions
                    }
                }));
            }
        })
        .finally(() => {
            setLoading(false)
        });
    }

    const getCustomFormUserAnswers = () => {
        setLoading(true);
        Promise.all([
            axios.get(apiConfig.url.BASE_URL + apiConfig.url.GET_CUSTOM_FORM_TEMPLATE.replace('{id}', customFormId)),
            axios.get(apiConfig.url.BASE_URL + apiConfig.url.CUSTOM_FORM_GET_USER_ANSWERS, {
                params: {
                    jobId,
                    customFormId
                }
            })
        ])
            .then(([resInfo, resAnswers]) => {
                let questions = resInfo.data.customFormQuestions.map(item => {
                    const answer = resAnswers.data.find(a => a.customFormQuestionId === item.id);
                    let _answer = answer ? (isJsonString(answer.answer) ? JSON.parse(answer.answer) : answer.answer) : '' ;
                    let a =  {
                        id: item.id,
                        questionName: item.question,
                        questionType: item.customFormControlTypeId,
                        questionControlType : item.controlType,
                        answer: {...item.answer, id: answer.id, answer: _answer}
                    }
                    return a;
                })
                let data = {
                    id: resInfo.data.customFormCompany.id,
                    formName: resInfo.data.customFormCompany.formName,
                    formDescription: resInfo.data.customFormCompany.description,
                    questions: questions
                }
                setSelectedCustomForm(data);
            })
            .catch(err => {

            })
            .finally(() => {
                setLoading(false)
            });
    }

    const saveAnswer = () => {
        if (!selectedCustomForm) return;
        const data = {
            CustomerCustomFormId: selectedCustomForm.id,
            CustomFormUserAnswerQuestions: selectedCustomForm.questions.map((question) => {
                return {
                    Id: question.answer.id,
                    CustomFormQuestionId: question.answer.customFormQuestionId,
                    Answer: JSON.stringify(question.answer.answer)
                }
            })
        };
        setIsSaving(true);
        axios.put(apiConfig.url.BASE_URL + apiConfig.url.CUSTOM_FORM_SAVE_USER_ANSWER.replace('{jobId}', jobId), data)
            .then(res => {
                setIsSuccess({failed: false, msg: 'Save successfully.'});
                setOpenSnack(true);
                // change URL after update success
            })
            .catch(err => {
                setIsSuccess({failed: true, msg: 'An error occurred, please try again later.'});
                setOpenSnack(true);
            })
            .finally(e => {
                setIsSaving(false);
                onCloseModal();
            });
    }

    const handleClose = () => {
        setOpenSnack(false);
    }

    useEffect(() => {
        if (open) {
            if (customFormId) getCustomFormUserAnswers();
            else fetchCustomForms();
        }
    }, [open, customFormId]);

    useEffect(() => {
        if (!selectedCustomForm) {
            setSelectedCustomForm(null);
        }
    }, [selectedCustomForm])

    return (
        <>
            <Modal open={open} onClose={onCloseModal}>
                <Card className={classes.styleModal}>
                    <CardHeader title='Custom Form' />
                    <Divider />
                    <CardContent>
                        {loading
                            ? (
                                <Grid container spacing={2}>
                                    <Grid item xs={12} style={{textAlign: 'center'}}>
                                        <CircularProgress size={50} />
                                    </Grid>
                                </Grid>
                            )
                            : (
                                !customFormId &&
                                <Grid container spacing={2}>
                                    <Grid item xs={12}>
                                        <FormControl style={{width: '100%'}} variant="outlined" className={classes.formControl}>
                                            <InputLabel id="label-custom-form">Custom Form</InputLabel>
                                            <Select
                                                labelId="label-custom-form"
                                                value={selectedCustomForm?.id ?? ''}
                                                onChange={e => {
                                                    setSelectedCustomForm(forms.find(x => x.id === e.target.value));
                                                }}
                                                label="Custom Form"
                                                inputProps={{
                                                    id: "select-custom-form"
                                                }}
                                            >
                                                <MenuItem value="">
                                                    <em>None</em>
                                                </MenuItem>
                                                {forms.map(x =>
                                                    <MenuItem key={x.id} value={x.id}>
                                                        {x.formName}
                                                    </MenuItem>)
                                                }
                                            </Select>
                                        </FormControl>
                                    </Grid>
                                </Grid>
                            )
                        }
                        { selectedCustomForm &&
                            <Grid container spacing={2}>
                            <Grid item xs={12}>
                                <div style={{
                                    fontSize: '32px',
                                    color: '#69a84f',
                                    textTransform: 'uppercase',
                                    fontWeight: "bold"
                                }}>{selectedCustomForm.formName}</div>
                                <p style={{
                                    fontSize: '28px',
                                    color: '#69a84f',
                                }}>{selectedCustomForm.formDescription}</p>
                                <hr
                                    style={{
                                        background: 'rgb(191,202,210)',
                                        height: "1px",
                                        border: "none",
                                        marginTop: '10px'
                                    }}
                                />
                            </Grid>
                            <Grid item xs={12}>
                                {
                                    selectedCustomForm?.questions?.map((question, index) => (
                                        <Fragment key={index}>
                                            <Grid container spacing={2}>
                                                <Grid item xs={12}>
                                                    <p>{index + 1 + '. ' + question.questionName}</p>
                                                </Grid>
                                                <Grid item xs={12}>
                                                    {
                                                        question.questionControlType == 'textbox' &&
                                                        <TextField
                                                            InputProps={{
                                                                classes: {
                                                                    input: classes.textField,
                                                                },
                                                            }}
                                                            style={{fontSize: '16px'}}
                                                            fullWidth
                                                            label={''}
                                                            name='answer'
                                                            variant='outlined'
                                                            onBlur={() => {
                                                                // setFieldTouched(index, 'answer');
                                                            }}
                                                            onChange={event => {
                                                                let data = {...selectedCustomForm};
                                                                data.questions[index].answer.answer = event.target.value
                                                                setSelectedCustomForm(data)
                                                            }}
                                                            value={question.answer.answer || ''}
                                                        />
                                                    }
                                                    {
                                                        question.questionControlType == 'radiobutton' &&
                                                        <>
                                                            {
                                                                question.answer && question.answer && question.answer.answer.length && question.answer.answer.map((option, indexOption) => (
                                                                        <div
                                                                            key={indexOption}
                                                                            style={{
                                                                            display: 'block',
                                                                            // padding: '12px'
                                                                        }}>
                                                                            <FormControlLabel
                                                                                name={'option_'+index}
                                                                                style={{fontSize:'16px'}}
                                                                                label={<span style={{ fontSize: '16px' }}>{option.label}</span>}
                                                                                control={
                                                                                    <GreenRadio/>
                                                                                }
                                                                                onChange={() => {
                                                                                    let data = {...selectedCustomForm};
                                                                                    let options = [...data.questions[index].answer.answer];
                                                                                    options = options.map(op => {
                                                                                        return {...op, checked: false}
                                                                                    })
                                                                                    let _op = {...options[indexOption], checked: true}
                                                                                    options[indexOption] = _op;
                                                                                    data.questions[index].answer.answer = options;
                                                                                    setSelectedCustomForm(data)
                                                                                }}
                                                                                checked={option && option.checked == true}
                                                                            />
                                                                        </div>
                                                                ))
                                                            }
                                                        </>

                                                    }
                                                    {
                                                        question.questionControlType == 'checkbox' &&
                                                        <>
                                                            {
                                                                question.answer && question.answer && question.answer.answer.length && question.answer.answer.map((option, indexOption) => (
                                                                    <>
                                                                        <div
                                                                            key={indexOption}
                                                                            style={{
                                                                            display: 'block',
                                                                            // padding: '12px'
                                                                        }}>
                                                                            <FormControlLabel
                                                                                style={{fontSize: '16px'}}
                                                                                name=''
                                                                                className={classes.taxCheckbox}
                                                                                label={<span style={{ fontSize: '16px' }}>{option.label}</span>}
                                                                                control={
                                                                                    <GreenCheckbox/>
                                                                                }
                                                                                onChange={() => {
                                                                                    let data = {...selectedCustomForm};
                                                                                    let options = [...data.questions[index].answer.answer];
                                                                                    let _op = {...options[indexOption], checked: true}
                                                                                    options[indexOption] = _op;
                                                                                    data.questions[index].answer.answer = options;
                                                                                    setSelectedCustomForm(data)
                                                                                }}
                                                                                checked={option[indexOption] && option[indexOption].checked === true}
                                                                            />
                                                                        </div>
                                                                    </>
                                                                ))
                                                            }
                                                        </>

                                                    }
                                                    {
                                                        question.questionControlType == 'datepicker' &&
                                                        <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                                            <KeyboardDatePicker
                                                                InputProps={{
                                                                    classes: {
                                                                        input: classes.textField,
                                                                    },
                                                                }}
                                                                style={{fontSize: '16px'}}
                                                                fullWidth
                                                                inputVariant="outlined"
                                                                label='Month/Date/Year'
                                                                format="MM/dd/yyyy"
                                                                value={question.answer.answer || null}
                                                                InputAdornmentProps={{position: "end"}}
                                                                onChange={date => {
                                                                    let data = {...selectedCustomForm};
                                                                    data.questions[index].answer.answer = date
                                                                    setSelectedCustomForm(data)
                                                                }}
                                                            />
                                                        </MuiPickersUtilsProvider>
                                                    }
                                                    {
                                                        question.questionControlType == 'table' &&
                                                        <Grid item xs={12}>
                                                            <TableContainer component={Paper}>
                                                                <Table className={classes.table}
                                                                       aria-label="simple table">
                                                                    <TableBody>
                                                                        {question.answer.answer.rows && new Array(parseInt(question.answer.answer.rows)).fill(0).map((row, indexRow) => (
                                                                            <TableRow key={indexRow}>
                                                                                {question.answer.answer.columns && new Array(parseInt(question.answer.answer.columns)).fill(0).map((columns, indexColum) => (
                                                                                    <TableCell
                                                                                        key={indexColum}
                                                                                        style={{border: "1px solid #cccccc", padding: '0'}}
                                                                                        component="th"
                                                                                        scope="row">
                                                                                        <TextField
                                                                                            style={{fontSize: '16px', backgroundColor: indexRow == 0 ? '#e3e3e3' : (indexRow % 2 == 0 ? "#f1f1f1": 'initial')}}
                                                                                            InputProps={{
                                                                                                classes: {
                                                                                                    input: classes.textField,
                                                                                                },
                                                                                                style: {
                                                                                                    borderRadius: "0"
                                                                                                }
                                                                                            }}
                                                                                            fullWidth
                                                                                            variant='outlined'
                                                                                            value={(indexRow == 0 && question.answer.answer.headers ? question.answer.answer.headers[indexColum] : question.answer.answer.userAnswer[indexRow][indexColum])}
                                                                                            onChange={(e) => {
                                                                                                let value = e.target.value;
                                                                                                let data = {...selectedCustomForm};
                                                                                                let userAnswer = data.questions[index].answer.answer.userAnswer;
                                                                                                let _row = [...userAnswer[indexRow]];
                                                                                                _row[indexColum] = value;
                                                                                                userAnswer[indexRow] = _row;
                                                                                                data.questions[index].answer.answer.userAnswer = userAnswer;
                                                                                                setSelectedCustomForm(data);
                                                                                            }}
                                                                                        />
                                                                                    </TableCell>
                                                                                ))}
                                                                            </TableRow>
                                                                        ))}
                                                                    </TableBody>
                                                                </Table>
                                                            </TableContainer>
                                                        </Grid>
                                                    }
                                                    {
                                                        question.questionControlType == 'textarea' &&
                                                        <TextField
                                                            InputProps={{
                                                                classes: {
                                                                    input: classes.textField,
                                                                },
                                                            }}

                                                            multiline={true}
                                                            rows={5}
                                                            fullWidth
                                                            label={''}
                                                            name='answer'
                                                            variant='outlined'
                                                            onChange={event => {
                                                                let data = {...selectedCustomForm};
                                                                data.questions[index].answer.answer = event.target.value
                                                                setSelectedCustomForm(data)
                                                            }}
                                                            value={question.answer.answer || ''}
                                                        />
                                                    }
                                                </Grid>
                                            </Grid>
                                        </Fragment>
                                    ))
                                }
                            </Grid>

                        </Grid>
                        }

                    </CardContent>
                    <CardActions className={classes.actionCard}>
                        <Button
                            onClick={onCloseModal}
                            variant="contained">
                            Close
                        </Button>

                        <Button
                            onClick={saveAnswer}
                            variant="contained"
                            color="primary"
                            disabled={isSaving || !selectedCustomForm}
                            className={classes.btnUpFile}>
                            Save
                        </Button>
                        {isSaving && <CircularProgress size={30} />}
                    </CardActions>
                </Card>
            </Modal>


            <Snackbar
                open={openSnack}
                anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
                autoHideDuration={2000}
                onClose={handleClose}>
                <Alert
                    elevation={6} variant="filled" severity={isSuccess.failed ? 'error' : 'success'}>
                    <Typography
                        color="inherit"
                        variant="h6">
                        {isSuccess.msg}
                    </Typography>
                </Alert>
            </Snackbar>
        </>
    )
}

AddCustomform.defaultProps = {
    open: false,
    onClose: () => { }
};

export default AddCustomform;
