import React, {useEffect, useState} from 'react'
import PropTypes from 'prop-types'
import NumberFormat from 'react-number-format'
import {
    IconButton,
    TextField,
    FormControlLabel,
    Tooltip,
    Grid, Divider,
} from '@material-ui/core'
import { makeStyles } from '@material-ui/styles'
import FilterNoneIcon from '@material-ui/icons/FilterNone';
import DeleteIcon from '@material-ui/icons/Delete';
import { GreenCheckbox } from 'components'
import { Autocomplete } from "@material-ui/lab";

const useStyles = makeStyles(theme => ({
    lineItem: {
    },
    bodyCell: {
        borderBottom: 'none',
        verticalAlign: 'top'
    },
    taxable: {
        margin: '0'
    },
    btnDis: {
        whiteSpace: 'nowrap',
        color: 'rgba(0, 0, 0, 0.26)',
        backgroundColor: 'rgb(217 217 217)',
        pointerEvents: 'none'
    },
}))

const PriceLineMobile = props => {
    const classes = useStyles();
    const { item, onChangeItem, onChangeDesc, onCopyItem, onRemoveItem, onDirty, lineItem, index, disabled, length, errors, touched ,indexItem, postedToQuickbooks } = props;
    const { dirty, quantity, description, price, taxable, upsale, companyPriceItemId } = item;
    const [value, setValue] = useState({ title: description, price: '', companyPriceItemId: null });
    const [pr, setPr] = useState('')
    const [change, setChange] = useState(false)
    let total = parseFloat(quantity * price).toFixed(2);

    useEffect(() => {
        setValue({ title: description, price: '', companyPriceItemId: null })
        setPr(price ? parseFloat(price).toFixed(2) : '')
    }, [price])
    useEffect(() => {
        setValue({ title: item.description, price: item.price, companyPriceItemId: item.companyPriceItemId })
        if (!dirty && (!!quantity || !!description || !!price)) onDirty(true);
        else if (dirty && !quantity && !description && !price) onDirty(false);
    }, [item])
    useEffect(() => {
        const focus  = document.getElementById('focusQuantity');
        if(focus) focus.focus()
    }, [])
    return (
        <div className={classes.lineItem}>
            <Divider style={{ margin: '0 -16px' }} />
            <Grid container spacing={2}>
                <Grid item xs={12}>
                    <Autocomplete
                        disabled={disabled}
                        freeSolo
                        autoHighlight
                        value={value}
                        disableClearable
                        options={lineItem}
                        getOptionLabel={(option) => option.title}
                        onChange={(e, value, q) => {
                            if (typeof (value) === 'object') {
                                setPr(parseFloat(value.price));
                                setValue(value);
                                setChange(false);
                                onChangeDesc(value.title, value.price, value.taxable, value.id)
                            } else {
                                setValue({ title: value, price: '' })
                            }
                        }}
                        onInputChange={(event, newValue) => {
                            setChange(true);
                        }}
                        renderInput={(params) => (
                            <TextField
                                {...params}
                                label="Description"
                                name='description'
                                margin="normal"
                                variant="outlined"
                                InputProps={{ ...params.InputProps, type: 'search' }}
                                onInput={(e) => {
                                    if (e.target.value.length > 100){
                                        e.target.value = e.target.value.slice(0,100);
                                    }
                                }}
                                onBlur={(e) => {
                                    onChangeDesc(e.target.value, pr, taxable, change ? null : companyPriceItemId)
                                }}
                                error={
                                    errors.jobItems &&
                                    errors.jobItems[indexItem] &&
                                    errors.jobItems[indexItem].description &&
                                    touched.jobItems &&
                                    touched.jobItems[indexItem]
                                }
                                helperText={
                                    (
                                        errors.jobItems &&
                                        errors.jobItems[indexItem] &&
                                        errors.jobItems[indexItem].description &&
                                        touched.jobItems &&
                                        touched.jobItems[indexItem]
                                    )
                                    &&
                                    errors.jobItems[indexItem].description
                                }
                            />
                        )}
                    />
                </Grid>
                <Grid item xs={4}>
                    <NumberFormat
                        id={index === length && !item.id ? 'focusQuantity' : ''}
                        customInput={TextField}
                        allowNegative={false}
                        fullWidth
                        disabled={disabled}
                        label="Quantity"
                        name='quantity'
                        variant='outlined'
                        value={quantity || ''}
                        onChange={onChangeItem}
                        error={
                            errors.jobItems &&
                            errors.jobItems[indexItem] &&
                            errors.jobItems[indexItem].quantity &&
                            touched.jobItems &&
                            touched.jobItems[indexItem]
                        }
                        helperText={
                            (
                                errors.jobItems &&
                                errors.jobItems[indexItem] &&
                                errors.jobItems[indexItem].quantity &&
                                touched.jobItems &&
                                touched.jobItems[indexItem]
                            )
                            &&
                            errors.jobItems[indexItem].quantity
                        }
                    />
                </Grid>
                <Grid item xs={4}>
                    <NumberFormat
                        customInput={TextField}
                        allowNegative={false}
                        prefix='$'
                        decimalScale={2}
                        fullWidth
                        disabled={disabled}
                        onChange={(event) => {
                            onChangeItem(event)
                            setPr(event.target.value)
                        }}
                        variant='outlined'
                        label="Price"
                        value={pr || ''}
                        name='price'
                        onBlur={(event) => {
                            setPr(`$${parseFloat(event.target.value.replace('$', '')).toFixed(2)}`)
                        }}
                        error={
                            errors.jobItems &&
                            errors.jobItems[indexItem] &&
                            errors.jobItems[indexItem].price &&
                            touched.jobItems &&
                            touched.jobItems[indexItem]
                        }
                        helperText={
                            (
                                errors.jobItems &&
                                errors.jobItems[indexItem] &&
                                errors.jobItems[indexItem].price &&
                                touched.jobItems &&
                                touched.jobItems[indexItem]
                            )
                            &&
                            errors.jobItems[indexItem].price
                        }
                    />
                </Grid>
                <Grid item xs={4}>
                    <NumberFormat
                        customInput={TextField}
                        allowNegative={false}
                        prefix='$'
                        thousandSeparator
                        fullWidth
                        disabled
                        label="Total"
                        name='total'
                        variant='outlined'
                        value={total}
                    />
                </Grid>
                <Grid item xs>
                    <FormControlLabel
                        disabled={disabled}
                        className={classes.taxable}
                        checked={taxable || false}
                        label="Taxable"
                        name='taxable'
                        control={
                            <GreenCheckbox
                                value="taxable"
                            />
                        }
                        onChange={onChangeItem}
                    />
                </Grid>
                <Grid item xs>
                    <FormControlLabel
                        className={classes.taxable}
                        disabled={disabled}
                        checked={upsale || false}
                        label="Upsale"
                        name='upsale'
                        control={
                            <GreenCheckbox
                                value="taxable"
                            />
                        }
                        onChange={onChangeItem}
                    />
                </Grid>
                <Grid item xs style={{ whiteSpace: 'nowrap' }}>
                    <Tooltip title='Copy' arrow>
                        <IconButton onClick={onCopyItem} disabled={disabled}>
                            <FilterNoneIcon />
                        </IconButton>
                    </Tooltip>
                    <Tooltip title='Delete' arrow>
                        <IconButton onClick={onRemoveItem} disabled={disabled || postedToQuickbooks}>
                            <DeleteIcon />
                        </IconButton>
                    </Tooltip>
                </Grid>
            </Grid>
        </div>
    )
}

PriceLineMobile.propTypes = {
    item: PropTypes.object,
    onChangeItem: PropTypes.func,
    onChangeDesc: PropTypes.func,
    onCopyItem: PropTypes.func,
    onRemoveItem: PropTypes.func,
    isDelete: PropTypes.bool,
}

export default PriceLineMobile;
