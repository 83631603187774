import React, { useEffect, useRef, useState } from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import { makeStyles, withStyles } from '@material-ui/styles';
import { Tabs, Tab, Typography, Tooltip } from '@material-ui/core';
import { MuiThemeProvider, createTheme } from '@material-ui/core/styles'
import { KeyboardArrowDown } from '@material-ui/icons'
import { Page } from 'components';
import { RoutesBox, RoutesSource } from './components'
import { useDispatch, useSelector } from "react-redux";

const useStyles = makeStyles(theme => ({
    root: {
        height: 'calc(100vh - 67px)',
        padding: theme.spacing(3),
        display: 'flex',
        flexFlow: 'column',
        overflowY: 'hidden',
        paddingBottom: '15px'
    },
    arrowDown: {
        position: 'sticky',
        top: '-24px',
        zIndex: 99,
        backgroundColor: '#f4f6f8',
        cursor: 'pointer',
        transition: 'all .3s ease-out',
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        padding: '8px 16px',
        marginTop: '16px',
        backgroundColor: '#ffffff',
        borderTopLeftRadius: '4px',
        borderTopRightRadius: '4px',
        borderBottom: '1px solid #eeeeee'
    },
    selectionTitle: {
        fontSize: '16px',
        fontWeight: 500
    },
    tabs: {
        '& .MuiTabs-scroller': {
            backgroundColor: '#ffffff'
        }
    },
    source: {
        position: 'sticky',
        top: '4px',
        zIndex: 99,
        backgroundColor: '#f4f6f8',
        maxHeight: 'max-content',
        overflow: 'hidden',
        transition: 'all .3s ease-out'
    },
    content: {
        marginTop: theme.spacing(3),
        position: 'relative'
    },
    routeScroll: {
        height: 'auto',
        overflowY: 'scroll',
        position: 'relative',
        scrollBehavior: 'smooth',
        paddingRight: 24,
        marginRight: -24
    }
}));

const customTooltipTheme = createTheme({
    overrides: {
        MuiTooltip: {
            tooltip: {
                fontSize: '14px',
                maxWidth: 'none'
            }
        }
    }
})

const NoBorderTabs = withStyles({
    root: {
        borderBottom: '0',
    },
})(Tabs)

const Routes = ({ match }) => {

    const classes = useStyles();
    const { date } = match.params;
    const dispatch = useDispatch();

    const [tab, setTab] = useState('to-be-scheduled');
    const [expand, setExpand] = useState(true);
    const { onDragType } = useSelector(state => state.RoutesReducer)
    const handleTabsChange = (event, value) => {
        dispatch({ type: 'DRAG_ITEM', onDragType: value })
    };
    const [drag, setIsDrag] = useState(false);

    const tabs = [
        { value: 'to-be-scheduled', label: 'To be Scheduled' },
        { value: 'available-employees', label: 'Available Employees' },
        { value: 'available-equipments', label: 'Available Equipment' }
    ];
    const setDrag = (drag) => {
        setIsDrag(drag);
    }

    const resetDistance = () => {
        dispatch({ type: 'CHANGE_ORDER', orderType: '' });
        dispatch({ type: 'SHOW_DISTANCE', showDistance: false })
    }

    useEffect(() => {
        // debugger
        if (onDragType) {
            setTab(onDragType)
        }
    }, [onDragType])

    return (
        <Page
            className={classes.root}
            title="Management Schedules">
            <div>
                <Link to={`/schedules/${date}`} onClick={resetDistance}>
                    <Typography style={{ display: 'inline' }}>Back to Calendar</Typography>
                </Link>
            </div>
            <div>
                <div style={!expand ? {
                    borderBottomLeftRadius: '4px',
                    borderBottomRightRadius: '4px'
                } : null}
                    className={classes.arrowDown} onClick={() => setExpand(!expand)}>
                    <Typography
                        className={classes.selectionTitle}>
                        Route Resources
                    </Typography>
                    <MuiThemeProvider theme={customTooltipTheme}>
                        <Tooltip title={expand ? 'Collapse Selections' : 'Expand Selections'} placement='bottom' arrow>
                            <KeyboardArrowDown style={{ transform: expand ? 'rotate(-180deg)' : 'rotate(0deg)' }} />
                        </Tooltip>
                    </MuiThemeProvider>
                </div>
                <div className={classes.source} style={!expand ? { maxHeight: '0' } : null}>
                    <NoBorderTabs
                        className={classes.tabs}
                        onChange={handleTabsChange}
                        scrollButtons="auto"
                        value={tab}
                        variant="scrollable">
                        {tabs.map(tab => (
                            <Tab
                                key={tab.value}
                                label={tab.label}
                                value={tab.value}
                            />
                        ))}
                    </NoBorderTabs>
                    <RoutesSource
                        tab={tab}
                        setDrag={setDrag}
                    />
                </div>
            </div>
            <div className={classes.routeScroll} id='route-scroll'>
                <div className={classes.content}>
                    <div style={{
                        display: drag ? 'block' : 'none', position: 'sticky',
                        backgroundColor: 'transparent', height: '25px', width: '100%', top: '0', left: '0', zIndex: '1', marginTop: '-25px'
                    }}>
                    </div>
                    <RoutesBox date={date} />
                    <div style={{
                        display: drag ? 'block' : 'none', position: 'sticky',
                        backgroundColor: 'transparent', height: '25px', width: '100%', bottom: '0', left: '0', marginTop: '-25px'
                    }}>
                    </div>
                </div>
            </div>
        </Page>
    );
};

Routes.propTypes = {
    history: PropTypes.object.isRequired,
    match: PropTypes.object.isRequired
};

export default Routes;
