import React from 'react'
import PropTypes from 'prop-types'
import { makeStyles } from '@material-ui/styles'
import {TableRow, TableCell, Typography, TextField, Button, colors, CardActions, Tooltip} from '@material-ui/core'
import {Link} from "react-router-dom";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import {createTheme, MuiThemeProvider} from "@material-ui/core/styles";

const useStyles = makeStyles(theme => ({
    btn: {
        // padding: '8px',
        width: '140px',
        color: theme.palette.white,
        backgroundColor: colors.green[600],
        '&:hover': {
            backgroundColor: colors.green[900]
        }
    },
    styleAction: {
        flexDirection: 'column',
        justifyContent: 'flex-end',
        paddingRight: theme.spacing(2)
    },
    button: {
        // padding: '8px',
        marginTop: '10px',
        display: 'block',
        whiteSpace: 'nowrap',

        color: theme.palette.white,
        backgroundColor: colors.green[600],
        '&:hover': {
            backgroundColor: colors.green[900]
        }
    },
    actionBtn: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-evenly'
    }
}))
const customThemeTooltip = createTheme({
    overrides: {
        MuiTooltip: {
            tooltip: {
                fontSize: '14px',
                maxWidth: 'none'
            }
        }
    }
});
const MissTaxGroup = props => {
    const sm_down = useMediaQuery('(max-width: 959px)');

    const classes = useStyles();

    const { missTax, taxGroups, onChangeTax, onSave, oldCustomer } = props;

    return (
        <TableRow key={missTax.id}>
            <TableCell>
                <Typography>{missTax.companyName || ''}
                    <MuiThemeProvider theme={customThemeTooltip}>
                        <Tooltip arrow title={'View Customer'}>
                            <a
                                href={`/customers/${encodeURIComponent(missTax.companyName)}/${missTax.id}/information`}
                                target='_blank'>
                                <i style={{
                                    marginLeft: '10px',
                                    fontSize: '16px',
                                    color: '#263238'
                                }} className="fas fa-external-link" />
                            </a>
                        </Tooltip>
                    </MuiThemeProvider>
                </Typography>
                <Typography>{`${missTax.firstName || ''} ${missTax.lastName || ''}`}</Typography>
                <Typography>{(missTax.phones && missTax.phones[0]) && missTax.phones[0].phoneNumber || ''}</Typography>
                <Typography>{missTax.email || ''}</Typography>
            </TableCell>
            <TableCell>
                <Typography>{missTax.address && missTax.address.name}</Typography>
                <Typography>{missTax.address && missTax.address.address1 || ''}</Typography>
                <Typography>
                    {(missTax.address && missTax.address) ?
                        `${missTax.address.city || ''} ${missTax.address.state && missTax.address.state.name || ''} ${missTax.address.zipCode || ''}`
                        :
                        ''
                    }
                </Typography>
                <Typography>
                    <strong>Address Type: </strong>
                    {missTax.address && missTax.address.addressType === 1 ? 'Billing Address' : 'Jobsite Address'}
                </Typography>
            </TableCell>
            <TableCell>

            </TableCell>
            <TableCell>
                <TextField
                    fullWidth
                    name='tax'
                    label='Tax Group'
                    select
                    SelectProps={{ native: true }}
                    value={missTax.address.taxGroupId || ''}
                    onChange={e => onChangeTax(e)}
                    variant='outlined'
                >
                    <option value=''></option>
                    {taxGroups.map(tax => (
                        <option
                            key={tax.id}
                            value={tax.id}
                        >
                            {tax.name}
                        </option>
                    ))}
                </TextField>
            </TableCell>
            <TableCell align='center' style={{ whiteSpace: "nowrap" }}>
                <CardActions className={classes.styleAction}>
                    <Button
                        onClick={() => onSave(missTax)}
                        disabled={oldCustomer.address.taxGroupId === missTax.address.taxGroupId || !missTax.address.taxGroupId}
                        className={classes.btn}
                        variant="contained">
                        Save
                    </Button>
                    {/*<Link  target='_blank'*/}
                    {/*       to={`/customers/${oldCustomer.companyName}/${oldCustomer.id}/information`}*/}
                    {/*       style={{ width: '140px', display: 'block', marginLeft: '0px'}}*/}
                    {/*>*/}
                    {/*    <Button style={sm_down ? { marginBottom: '10px', width: '140px', maxWidth: '150px' } : {width: '140px', maxWidth: '150px'}} className={classes.button}>*/}
                    {/*        View Customer*/}
                    {/*    </Button>*/}
                    {/*</Link>*/}
                </CardActions>

            </TableCell>
        </TableRow>
    )
}

MissTaxGroup.propTypes = {
    missTax: PropTypes.object.isRequired,
    taxGroups: PropTypes.array.isRequired,
    onChangeTax: PropTypes.func.isRequired,
    onSave: PropTypes.func
}

MissTaxGroup.defaultProps = {
    onSave: () => { }
}

export default MissTaxGroup;
