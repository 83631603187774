const monthString = [
    'January',
    'February',
    'March',
    'April',
    'May',
    'June',
    'July',
    'August',
    'September',
    'Octorber',
    'November',
    'December',
];

export function getYearList(startYear = 2000) {
    const currentYear = new Date().getFullYear();
    const years = [];
    while (startYear <= currentYear + 50) {
        years.push(startYear++);
    }
    return years;
}

export function getDaysInMonth(year, month) {
    // month is in the range 0..11
    const firstDayInFirstWeek = new Date(year, month, 1).getDay();
    const lastDay = new Date(year, month + 1, 0).getDate();

    const days = [];
    for (let i = 0; i < firstDayInFirstWeek; i++) {
        days[i] = ''
    }

    for (let i = 1; i <= lastDay; i++) {
        days.push(new Date(year, month, i));
    }

    return days;
}

export function getStringDate(year, month) {
    const d = new Date(year, month, 1);
    return monthString[d.getMonth()] + ' ' + d.getFullYear();
}

export function isEqual(d1, d2) {
    if (d1 instanceof Date && d2 instanceof Date) {
        return d1.getFullYear() === d2.getFullYear() &&
            d1.getMonth() === d2.getMonth() &&
            d1.getDate() === d2.getDate()
    }

    return false;
}

export function isGreater(d1, d2) {
    if (d1 instanceof Date && d2 instanceof Date) {
        return d1.getTime() > d2.getTime()
    }

    return false;
}

export function isBelongToRangeDate(d1, d2, d) {
    if (d1 instanceof Date && d2 instanceof Date && d instanceof Date) {
        return d.getTime() > d1.getTime() && d.getTime() < d2.getTime()
    }

    return false;
}

export function increaseDate(d, count) {
    if (d instanceof Date) {
        const year = d.getFullYear();
        const month = d.getMonth();
        const day = d.getDate();

        return new Date(year, month, day + count)
    }
}

export function subtractTwoDate(d1, d2) {
    if (d1 instanceof Date && d2 instanceof Date) {
        const diff_miliseconds = Math.abs(d2 - d1);
        return diff_miliseconds / 86400000;
    }
}

export function getPeriodPayrollDate(periodStartDate, periodDuration) {
    if (periodStartDate instanceof Date) {
        const dn = new Date();
        // calculate dateRange
        const d2 = new Date(dn.getFullYear(), dn.getMonth(), dn.getDate());
        const d1 = new Date(periodStartDate.getFullYear(), periodStartDate.getMonth(), periodStartDate.getDate());
        const diff_dates = subtractTwoDate(d1, d2);
        let numEndPeriod = Math.floor(diff_dates / (periodDuration * 7));
        numEndPeriod = numEndPeriod > 0 ? numEndPeriod - 1 : 0;
        // end
        return increaseDate(d1, numEndPeriod * periodDuration * 7)
    }
    
    return null;
}