import React, {useEffect, useState} from 'react'
import PropTypes from 'prop-types'
import { makeStyles } from '@material-ui/styles'
import {
    Modal,
    Button,
    colors,
    Card,
    CardHeader,
    CardContent,
    CardActions,
    Divider,
    Table,
    TableHead,
    TableRow,
    TableCell,
    TableBody, Grid, CircularProgress, Collapse, Typography,
} from '@material-ui/core'
import {Pagination} from "../../../../../components";
import axios from "../../../../../utils/axios";
import apiConfig from "../../../../../apiConfig";
import moment from "moment";
import localStorage from "../../../../../utils/localStorage";

const useStyles = makeStyles(theme => ({
    styleModal: {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        outline: 'none',
        boxShadow: theme.shadows[20],
        width: 900,
        maxHeight: '100%',
        overflowY: 'auto',
        maxWidth: '100%',
        [theme.breakpoints.down('sm')]: {
            width: 500
        },
    },
    content: {
        height: '470px',
        display: 'flex',
        alignItems: 'center'
    },
    btn: {
        flexShrink: '0',
        color: theme.palette.white,
        backgroundColor: colors.green[600],
        '&:hover': {
            backgroundColor: colors.green[900]
        },
    },
    actionCard: {
        padding: '8px 16px',
        justifyContent: 'right',
    },
    paginate: {
        maxWidth: '100%',
        padding: '10px',
        '& ul': {
            justifyContent: 'center'
        }
    },
    chip: {
        fontSize: '13px',
        cursor: 'pointer',
        padding: '4px 8px'
    },
    chipWraper: {
        marginBottom: '20px',
        width: 'fit-content',
        display: 'flex',
        padding: '4px 4px',
        borderRadius: '20px',
        border: '1px solid #cccccc'
    },
    chipActive: {
        background: '#134ADB',
        color: 'white',
        borderRadius: '20px',
    }
}));

let forcePage = 0;
let pageNumber = 1;

const LogConnector = ({open, onClose }) => {

    const classes = useStyles();

    const [selectDate, setSelectDate] = useState(1);
    const [pageCount, setPageCount] = useState(2);
    const [logConnector, setLogConnector] = useState([]);
    const [loadLog, setLoadLog] = useState(false);

    const onPageChange = page => {
        pageNumber = page.selected + 1;
        forcePage = page.selected;
        getAllLog();
    }

    const getAllLog = () => {
        setLoadLog(true);
        axios.get(apiConfig.url.BASE_URL + apiConfig.url.QUICK_BOOK_LOGS,{
            params:
                {
                    Days: selectDate,
                    pageSize: 24,
                    PageNumber: pageNumber,
                }
        } ).then((data) => {
            setLogConnector(data.data.data);
            setPageCount(data.data.pageCount)
        }).catch(() => {

        }).finally(() => {setLoadLog(false)})
    }
    useEffect(() => {
        getAllLog();
    }, [selectDate])

const changeDate = (date) => {
        !loadLog && setSelectDate(date);
    }
    return (
        <Modal open={open} onClose={() => onClose()}>
            <Card className={classes.styleModal}>
                <CardHeader title='View web connector log' />
                <Divider />
                <CardContent>
                    <div class={classes.chipWraper}>
                        {/*<span class={`${classes.chip} ${selectDate === 0.5 ?  classes.chipActive : ''}`}*/}
                        {/*      onClick={() => changeDate(0.5)}>30 minutes</span>*/}
                        {/*<span class={`${classes.chip} ${selectDate === 1 ?  classes.chipActive : ''}`}*/}
                        {/*      onClick={() => changeDate(1)}>1 hour</span>*/}
                        {/*<span class={`${classes.chip} ${selectDate === 6 ?  classes.chipActive : ''}`}*/}
                        {/*      onClick={() => changeDate(6)}>6 hour</span>*/}
                        <span class={`${classes.chip} ${selectDate === 1 ?  classes.chipActive : ''}`}
                              onClick={() => changeDate(1)}>1 day</span>
                        <span class={`${classes.chip} ${selectDate === 3 ?  classes.chipActive : ''}`}
                              onClick={() => changeDate(3)}>3 days</span>
                        <span class={`${classes.chip} ${selectDate === 7 ?  classes.chipActive : ''}`}
                              onClick={() => changeDate(7)}>7 days</span>
                        <span class={`${classes.chip} ${selectDate === 30 ?  classes.chipActive : ''}`}
                              onClick={() => changeDate(30)}>30 days</span>
                        {/*<span className={`${classes.chip} ${selectDate === 7200 ? classes.chipActive : ''}`}*/}
                        {/*      onClick={() => changeDate(7200)}>300 days</span>*/}
                    </div>
                    <Table>
                        <TableHead>
                            <TableRow className={classes.headRow}>
                                <TableCell style={{width: '170px'}}>Log date</TableCell>
                                <TableCell>Message</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody style={{textAlign: 'center'}}>
                            {
                                loadLog ?
                                    <TableRow>
                                        <TableCell colSpan={2} style={{textAlign: 'center'}}>
                                            <Grid
                                                style={{width: '100%'}}
                                                container
                                                spacing={0}
                                                align="center"
                                                justifyContent="center"
                                                direction="column">
                                                <Grid item>
                                                    <CircularProgress className={classes.circularProgress} size={50}/>
                                                </Grid>
                                            </Grid>
                                        </TableCell>
                                    </TableRow>
                                    :
                                   (logConnector.length > 0 ?
                                        (
                                            logConnector.map(item => (
                                                <TableRow>
                                                    <TableCell>{moment(item.logDate).format('YYYY-MM-DD HH:mm:ss')}</TableCell>
                                                    <TableCell>{item.message}</TableCell>
                                                </TableRow>
                                            ))
                                        )
                                        :
                                        (
                                        !loadLog &&
                                            <TableRow>
                                                <TableCell colSpan={2} style={{textAlign: 'center'}}>
                                        <Grid container justifyContent='center'>
                                        <Typography className={classes.noData}>No data found.</Typography>
                                        </Grid>
                                                </TableCell>
                                            </TableRow>
                                        )

                                    )
                            }
                        </TableBody>
                    </Table>
                    <div style={pageCount === 0 ? { display: 'none' } : null} className={classes.paginate}>
                        <Pagination pageCount={pageCount} forcePage={forcePage} onPageChange={onPageChange} />
                    </div>
                </CardContent>
                <Divider />
                <CardActions className={classes.actionCard}>
                    <div>
                        <Button
                            variant="contained"
                            onClick={() => onClose()}>
                            Close
                        </Button>

                    </div>
                </CardActions>
            </Card>
        </Modal>
    )
}

LogConnector.propTypes = {
    logConnector: PropTypes.object,
    updateQuickBooks: PropTypes.func,
    open: PropTypes.bool,
    onClose: PropTypes.func
}

export default LogConnector;
