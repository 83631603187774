import React from 'react'
import PropTypes from 'prop-types'
import {
    Modal,
    Card,
    CardContent,
    CardActions,
    Button,
    colors,
    Typography,
    Divider,
    CardHeader,
    CircularProgress
} from '@material-ui/core'
import { makeStyles } from '@material-ui/styles'

const useStyles = makeStyles(theme => ({
    styleModal: {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        outline: 'none',
        boxShadow: theme.shadows[20],
        width: 600,
        maxHeight: '100%',
        overflowY: 'auto',
        maxWidth: '100%',
        [theme.breakpoints.down('sm')]: {
            width: 500
        },
    },
    conButton: {
        padding: '16px',
        justifyContent: 'flex-end'
    },
    button: {
        flexShrink: '0',
        backgroundColor: colors.green[600],
        '&:hover': {
            backgroundColor: colors.green[900]
        },
    },
}))

const ConfirmModal = props => {
    const classes = useStyles();

    const { title, cancelButtonText, okButtonText, isOk, isProgress, openConfirm, closeConfirm, onConfirm } = props;


    return (
        <Modal
            open={openConfirm}
            onClose={closeConfirm}
        >
            <Card className={classes.styleModal}>
                <CardHeader title="Warning" />
                <Divider />
                <CardContent style={{ padding: '20px 16px' }}>
                    <Typography style={{ fontSize: '16px' }}>
                        {title && title.split('<br>').map((t, i) => {
                            return t ? <span key={t+i}>{t}</span> : <br key={t+i}/>
                        })}
                    </Typography>
                </CardContent>
                <Divider />
                <CardActions className={classes.conButton}>
                    <Button
                        variant="contained"
                        size="large"
                        onClick={closeConfirm}>
                        {cancelButtonText}
                    </Button>

                    <Button style={!isOk ? { display: 'none' } : null}
                        disabled={!!isProgress}
                        variant="contained"
                        color="primary"
                        size="large"
                        onClick={() => {
                            onConfirm()
                        }}
                        className={classes.button}>
                        {okButtonText}
                    </Button>
                    {isProgress && <CircularProgress style={!isOk ? { display: 'none' } : null} size={24} />}
                </CardActions>
            </Card>
        </Modal>
    )
}

ConfirmModal.propTypes = {
    title: PropTypes.string,
    cancelButtonText: PropTypes.string,
    okButtonText: PropTypes.string,
    isOk: PropTypes.bool,
    isProgress: PropTypes.bool,
    openConfirm: PropTypes.bool.isRequired,
    closeConfirm: PropTypes.func.isRequired,
    onConfirm: PropTypes.func
}

ConfirmModal.defaultProps = {
    title: 'Are you sure you want to delete this item?',
    cancelButtonText: 'Cancel',
    okButtonText: 'OK',
    isOk: true,
    isProgress: false,
    onConfirm: () => { },
    closeConfirm: () => { }
}

export default ConfirmModal;
