import React from 'react'
import PropTypes from 'prop-types'
import { makeStyles } from '@material-ui/styles'
import {
    Modal, Card, CardHeader, CardContent, CardActions,
    Typography, Button, Divider,
    colors,
} from '@material-ui/core'
import moment from "moment";

const useStyles = makeStyles(theme => ({
    styleModal: {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        outline: 'none',
        boxShadow: theme.shadows[20],
        width: 500,
        maxHeight: '100%',
        overflowY: 'auto',
        maxWidth: '100%',
        [theme.breakpoints.down('sm')]: {
            width: 500
        },
    },
    conButton: {
        padding: '16px',
        justifyContent: 'flex-end'
    },
    buttonSave: {
        flexShrink: '0',
        backgroundColor: colors.green[600],
        '&:hover': {
            backgroundColor: colors.green[900]
        },
    },
}))

const ModalForceUpdate = ({ open, onClose, date, forceUpdate }) => {
    const classes = useStyles();
    date = date.map(d =>{
        return moment(new Date(d)).format('MM/DD/YYYY')
    })
    return (
        <Modal open={open} onClose={onClose}>
            <Card className={classes.styleModal}>
                <CardHeader title="Warning" />
                <Divider />
                <CardContent>
                    <Typography>
                        This employee is already scheduled on {date && date.length > 0 ? date.join(', ') : ''}.
                    </Typography>
                    <Typography>
                        Would you like to remove this employee from the assigned route(s) now?
                    </Typography>
                </CardContent>
                <Divider />
                <CardActions className={classes.conButton}>
                    <Button
                        onClick={onClose}
                        variant="contained"
                        size="large">
                        Close
                    </Button>
                    <Button
                        variant="contained"
                        color="primary"
                        size="large"
                        onClick={forceUpdate}
                        className={classes.buttonSave}>
                        Yes
                    </Button>
                </CardActions>
            </Card>
        </Modal>
    )
}

ModalForceUpdate.propTypes = {
    open: PropTypes.bool,
    onClose: PropTypes.func,
    date: PropTypes.array,
    forceUpdate: PropTypes.func
}

export default ModalForceUpdate;
