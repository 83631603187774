import React, { useState, useEffect, useRef } from 'react'
import {Link} from 'react-router-dom'
import {
    TableContainer, Table, TableHead, TableBody, Grid,
    TableRow, TableCell, Card, CardHeader, CardContent, CircularProgress,
    TextField, Typography, Divider, colors, Button, IconButton, CardActions
} from '@material-ui/core'
import Snackbar from '@material-ui/core/Snackbar';
import Alert from '@material-ui/lab/Alert';
import { Event } from '@material-ui/icons'
import { makeStyles } from '@material-ui/styles'
import { Page, DateRangePicker } from 'components'
import localStorage from 'utils/localStorage'
import axios from "utils/axios";
import apiConfig from "apiConfig";
import { formatNumber } from "utils/formatNumber";
import { AccessControl } from "components/AccessControl/AccessControl";
import { PERMISSIONS, checkRoles } from "common/permissions";
import moment, { isDate } from "moment";
import * as FileSaver from 'file-saver';
import * as XLSX from 'xlsx';
import { KeyboardDatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';

const useStyles = makeStyles(theme => ({
    root: {
        padding: theme.spacing(5)
    },
    circularProgress: {
        marginTop: '30px',
    },
    link: {
        fontSize: theme.spacing(2),
        marginBottom: theme.spacing(2),
        '& a': {
            color: '#263238',
            '&:hover': {
                textDecoration: 'underline'
            }
        }
    },
    payroll: {
        '& td': {
            padding: '0',
        },
        '&:hover': {
            cursor: 'pointer',
            backgroundColor: colors.green[600],
            '& td a': {
                color: '#ffffff'
            }
        }
    },
    btnAction: {
        margin: '0 4px',
        color: theme.palette.white,
        backgroundColor: colors.green[600],
        '&:hover': {
            backgroundColor: colors.green[900]
        }
    },
    contentHead: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        position: 'relative',
        [theme.breakpoints.down('md')]: {
            flexDirection: 'column'
        },
    },
    actionBtns: {
        [theme.breakpoints.up('md')]: {
            position: 'absolute',
            right: 0
        },
        [theme.breakpoints.down('sm')]: {
            marginTop: 8
        },
    },
    printIcon: {
        position: 'absolute',
        top: '-58px',
        right: 0,
        fontSize: '32px'
    },
    divider: {
        margin: '0 50px'
    },
    dates: {
        display: 'flex',
        position: 'relative',
        width: '150px',
        '& button': {
            position: 'absolute',
            right: 0,
            top: 2,
        },
        '& > div': {
            marginLeft: 5,
            marginRight: 5
        }
    },
    groupRadio: {
        marginBottom: 10,
    },
    radio: {
        width: 150,
        marginLeft: 0,
        '& span': {
            marginRight: '3px',
            padding: 0
        }
    },
    tableCell: {
        fontSize: '16px',
        borderBottom: '1px solid #969696',
        padding: '8px 16px'
    },
    inputHours: {
        width: '50px',
        margin: '0 16px',
        '& div input': {
            padding: '10px'
        }
    },
    tableLink: {
        height: '100%',
        width: '100%',
        color: 'black',
        padding: '16px',
        display: 'block',
        '& hover': {
            color: 'white'
        }
    }
}))

var csvData = [];
const fileType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
const fileExtension = '.xlsx';

let mounted = false;
const EmployeesPayroll = ({ history, location, match }) => {
    const { search } = location;
    const classes = useStyles();

    const startDate = new URLSearchParams(search).get("start")
    const endDate = new URLSearchParams(search).get("end")

    const dateStartRef = useRef('');
    const dateEndRef = useRef('');
    const userRole = localStorage.getUserRole();
    const isHasRoleOnPage = checkRoles(userRole, PERMISSIONS.page.payRolls);

    const [openDateRange, setOpenDateRange] = useState(false);
    const [payPeriodList, setPayPeriodList] = useState([]);
    const [pay, setPay] = useState({startDate: startDate,endDate: endDate});
    const [loading, setLoading] = useState(false);
    const [payrolls, setPayrolls] = useState([]);
    const [hasPeriod, setHasPeriod] = useState(false);
    const [lastPay, setLastPay] = useState({});
    const companyId = localStorage.getCompanyId();
    const usrData = JSON.parse(localStorage.getItem("sw-usr"));
    const handleChangeDateRange = (pay) => {
        if(pay.startDate)
        history.push(`/employees/payroll-list?start=${pay && pay.startDate}&end=${pay && pay.endDate}`)
        setPay(pay);
        // clear location.state when change pay
        clearLocationState();
    }

    const clearLocationState = () => {
        if (location.state) {
            let _location = { ...location };
            delete _location.state;
            history.replace({ ..._location });
        }
    }

    const getLinkPayroll = (payroll) => {
        const { startDate, endDate } = pay;
        const s = new Date(startDate);
        const e = new Date(endDate);

        const sy = s.getFullYear();
        const sm = s.getMonth() + 1 > 9 ? s.getMonth() + 1 : `0${s.getMonth() + 1}`;
        const sd = s.getDate() > 9 ? s.getDate() : `0${s.getDate()}`;

        const ey = e.getFullYear();
        const em = e.getMonth() + 1 > 9 ? e.getMonth() + 1 : `0${e.getMonth() + 1}`;
        const ed = e.getDate() > 9 ? e.getDate() : `0${e.getDate()}`;

        const startdate = `${sy}-${sm}-${sd}`;
        const enddate = `${ey}-${em}-${ed}`;
        return encodeURI(`/employees/payroll-detail/${payroll.firstName + ' ' + payroll.lastName}/${payroll.employeeId}/${startdate}/${enddate}`);
    }

    const setDateRange = () => {
        const { startDate, endDate } = pay;
        const s = startDate ? new Date(startDate).toDateString().split(' ') : null;
        const e = endDate ? new Date(endDate).toDateString().split(' ') : null;

        if (dateStartRef && dateStartRef.current) {
            dateStartRef.current.value = s ? s[1] + ' ' + parseInt(s[2]) : '';
        }

        if (dateEndRef && dateEndRef.current) {
            dateEndRef.current.value = e ? e[1] + ' ' + parseInt(e[2]) : '';
        }
    }

    const formatDollar = value => {
        if (!value) return 0;
        return parseFloat(value).toFixed(2).replace(/(\d)(?=(\d{3})+\.\d{2})/g, '$1,');
    }

    useEffect(() => {
        if (startDate && endDate) {
            setPay({ startDate, endDate });
        }
    }, [startDate, endDate]);

    useEffect(() => {
        mounted = true;
        axios.get(apiConfig.url.BASE_URL + apiConfig.url.COMPANY_INFO + companyId)
            .then(res => {
                if (res.data.periodStartDate) {
                    setHasPeriod(true);
                    if (!pay.startDate) {
                        const startDate = moment().startOf('week').add(-res.data.periodDuration, 'weeks');
                        const endDate = moment().startOf('week');
                        if (new Date(res.data.periodStartDate).getDay() === 0) {
                            setPay({
                                startDate: startDate.toISOString(),
                                endDate: endDate.add(-1, 'days').toISOString()
                            });
                        } else {
                            setPay({
                                startDate: startDate.add(1, 'days').toISOString(),
                                endDate: endDate.toISOString()
                            });
                        }
                    }

                    let periodList = [];
                    let periodStartDate = res.data.periodStartDate;
                    while (moment(periodStartDate) < moment().add(1, 'year')) {
                        periodList.push({
                            startDate: moment(periodStartDate)
                                .startOf('day')
                                .toISOString(),
                            endDate: moment(periodStartDate)
                                .startOf('day')
                                .add(res.data.periodDuration, 'weeks')
                                .add(-1, 'day')
                                .toISOString()
                        });

                        periodStartDate = moment(periodStartDate).add(res.data.periodDuration, 'weeks');
                    }

                    setPayPeriodList(periodList);
                } else {
                    setHasPeriod(false);
                }
            })
        return () => mounted = false;
    }, [])

    useEffect(() => {
        if(lastPay.startDate){
            setPay({startDate: lastPay.startDate,endDate: lastPay.endDate})
        }
    },[lastPay])

    useEffect(() => {
        if (pay.startDate && isHasRoleOnPage) {
            handleChangeDateRange(pay);
            setLoading(true);
            setDateRange();
            axios.get(apiConfig.url.BASE_URL + apiConfig.url.LOG_HOUR_PAYROLL, {
                params: {
                    fromDate: pay.startDate,
                    toDate: pay.endDate
                }
            })
                .then(res => {
                    if (mounted) {
                        const sortData = res.data.sort((a, b) => (a.lastName || '').localeCompare(b.lastName || ''));
                        setPayrolls(sortData.sort((a, b) => a.firstName.localeCompare(b.firstName)));
                        getContentPayRollCsv(res.data)
                    }
                }).catch(ex => {
                }).finally(() => {
                    setLoading(false);
                })
        }
    }, [pay]);

    if (!pay) {
        return (
            <AccessControl requiredPermission={PERMISSIONS.page.payRolls} redirect>
                <Grid
                    container
                    spacing={0}
                    align="center"
                    justifyContent="center"
                    direction="column">
                    <Grid item>
                        <CircularProgress className={classes.circularProgress} size={50} />
                    </Grid>
                </Grid>
            </AccessControl>
        )
    }

    const getHeaderPayRollCsv = () => {
        var date = new Date().toISOString();
        csvData.push(["Payroll Report", "Printed:", moment(date).format('MM/DD/YYYY'), moment(date).format('HH:mm:ss')]);
        csvData.push(["Period Starting On", (pay && pay.startDate && moment(pay.startDate).format('MM/DD/YYYY')), "Ending On", (pay && pay.endDate && moment(pay.endDate).format('MM/DD/YYYY'))]);
        csvData.push(["Name", "Total Hours Logged", "Hourly Labor Hours Logged", "Hourly Labor Pay", "Commission Labor Pay", "Salary Labor Pay", "Total Labor (Salary, Hourly, Commission)", "Average Hourly Rate", "Overtime Hours", "Overtime Pay", "Tips", "Sales Commissions", "Upsales Commissions"])
    }

    const getContentPayRollCsv = (payrollsData) => {
        csvData = [];
        if (csvData && csvData.length == 0) {
            getHeaderPayRollCsv();
        }
        payrollsData.map((pay) => {
            csvData.push([
                (pay.firstName + ' ' + pay.lastName),
                parseFloat(formatNumber(pay.totalHoursLogged, 2).replace(/,/g, '')),
                parseFloat(formatNumber(pay.paidHoursLogged, 2).replace(/,/g, '')),
                parseFloat(formatNumber(pay.hourlyLaborPay, 2).replace(/,/g, '')),
                parseFloat(formatNumber(pay.commissionLaborPay, 2).replace(/,/g, '')),
                parseFloat(formatNumber(pay.salaryLaborPay, 2).replace(/,/g, '')),
                parseFloat(formatNumber(pay.totalLabor, 2).replace(/,/g, '')),
                parseFloat(formatNumber(pay.averageHourlyRate, 2).replace(/,/g, '')),
                parseFloat(formatNumber(pay.overtimeHours, 2).replace(/,/g, '')),
                parseFloat(formatNumber(pay.overtimePay, 2).replace(/,/g, '')),
                parseFloat(formatNumber(pay.tips, 2).replace(/,/g, '')),
                parseFloat(formatNumber(pay.salesCommissions, 2).replace(/,/g, '')),
                parseFloat(formatNumber(pay.upsalesCommissions, 2).replace(/,/g, ''))
            ])
        })
    }

    const exportToCSV = (csvData, fileName) => {
        const ws = XLSX.utils.aoa_to_sheet(csvData);
        const wb = { Sheets: { 'Payroll_template': ws }, SheetNames: ['Payroll_template'] };
        const excelBuffer = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });
        const data = new Blob([excelBuffer], {type: fileType});
        FileSaver.saveAs(data, fileName + fileExtension);
    }

    return (
        <AccessControl requiredPermission={PERMISSIONS.page.payRolls} redirect>
            <Page title='Employees - Payroll' style={{minHeight: '100%', height: '100%'}}>
                <div className={classes.root} style={{minHeight: '100%'}}>
                    <Typography className={classes.link}>
                        <Link to='/employees'>Back</Link>
                    </Typography>
                    <Card style={{height: 'calc(100vh - 181px)'}}>
                        <CardHeader title="Payroll" />
                        <Divider />
                        <CardContent>
                            <div className={classes.contentHead}>
                                <div className={classes.dates}>
                                    <TextField
                                        inputRef={dateStartRef}
                                        fullWidth
                                        variant='outlined'
                                        disabled
                                    />
                                    <IconButton onClick={() => setOpenDateRange(true)}
                                        disabled={Object.keys(pay).length === 0 || (!hasPeriod && !loading && startDate && endDate)}>
                                        <Event />
                                    </IconButton>
                                </div>
                                <div className={classes.dates}>
                                    <TextField
                                        inputRef={dateEndRef}
                                        fullWidth
                                        variant='outlined'
                                        disabled
                                    />
                                    <IconButton onClick={() => setOpenDateRange(true)}
                                        disabled={Object.keys(pay).length === 0 || (!hasPeriod && !loading && startDate && endDate)}>
                                        <Event />
                                    </IconButton>
                                </div>
                                <div className={classes.actionBtns}>
                                    <CardActions>
                                        {loading
                                            ? <Button disabled={true} className={classes.btnAction}>
                                                Loading
                                            </Button>
                                            : <Button disabled={!hasPeriod && !loading && startDate && endDate} className={classes.btnAction} onClick={(e) => exportToCSV(csvData,'Payroll_template')}>
                                                Export
                                            </Button>
                                        }
                                    </CardActions>
                                </div>
                            </div>

                            <TableContainer>
                                <Table stickyHeader style={{ marginTop: '24px', width: '100%', height: 'calc(100vh - 342px)', display: 'block' }}>
                                    <TableHead>
                                        <TableRow >
                                            <TableCell align='center' className={classes.tableCell}>Name</TableCell>
                                            <TableCell align='center' className={classes.tableCell}>Total Hours
                                                Logged</TableCell>
                                            <TableCell align='center' className={classes.tableCell}>Hourly Labor Hours
                                                Logged</TableCell>
                                            <TableCell align='center' className={classes.tableCell}>Hourly Labor
                                                Pay</TableCell>
                                            <TableCell align='center' className={classes.tableCell}>Commission Labor
                                                Pay</TableCell>
                                            <TableCell align='center' className={classes.tableCell}>Salary Labor
                                                Pay</TableCell>
                                            <TableCell align='center' className={classes.tableCell}>
                                                Total Labor
                                                <small style={{ display: 'block' }}>(Salary, Hourly, Commission)</small>
                                            </TableCell>
                                            <TableCell align='center' className={classes.tableCell}>Overtime Pay</TableCell>
                                            <TableCell align='center' className={classes.tableCell}>Tips</TableCell>
                                            <TableCell align='center' className={classes.tableCell}>Sales
                                                Commissions</TableCell>
                                            <TableCell align='center' className={classes.tableCell}>Upsales
                                                Commissions</TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        { !hasPeriod && !loading && startDate && endDate &&
                                            <TableRow style={{ height: '60px'}}>
                                                <TableCell colSpan={11} align='center'>
                                                    <Grid
                                                        container
                                                        spacing={0}
                                                        align="center"
                                                        justifyContent="center"
                                                        direction="column">
                                                        <Grid item>
                                                            <Typography style={{color: 'red'}}>
                                                                Please define a pay period before using the <Link style={{textDecoration: "underline"}} to={`/admin/${usrData && usrData.displayName}/${companyId}/information`}>Payroll</Link> screen.</Typography>
                                                        </Grid>
                                                    </Grid>
                                                </TableCell>
                                            </TableRow>
                                        }
                                        {(
                                                loading ? (
                                                    <TableRow style={{ height: '60px'}} className={classes.payroll}>
                                                        <TableCell colSpan={11} align='center'>
                                                            <Grid
                                                                container
                                                                spacing={0}
                                                                align="center"
                                                                justifyContent="center"
                                                                direction="column">
                                                                <Grid item>
                                                                    <CircularProgress className={classes.circularProgress}
                                                                        size={50} />
                                                                </Grid>
                                                            </Grid>
                                                        </TableCell>
                                                    </TableRow>) : <>
                                                    {hasPeriod &&
                                                        payrolls.map(pay => (
                                                            <TableRow className={classes.payroll} key={pay.employeeId}>
                                                                <TableCell align='center' style={{ whiteSpace: 'nowrap' }}>
                                                                    <Link className={classes.tableLink} to={() => getLinkPayroll(pay)}>
                                                                    {`${pay.lastName || ''}, ${pay.firstName || ''}`}
                                                                    </Link>
                                                                </TableCell>
                                                                <TableCell align='center' style={{padding: '0'}}>
                                                                    <Link className={classes.tableLink} to={() => getLinkPayroll(pay)}>
                                                                    {formatNumber(pay.totalHoursLogged, 2)}
                                                                    </Link>
                                                                </TableCell>
                                                                <TableCell align='center'>
                                                                    <Link className={classes.tableLink} to={() => getLinkPayroll(pay)}>
                                                                    {formatNumber(pay.paidHoursLogged, 2)}
                                                                    </Link>
                                                                    </TableCell>
                                                                <TableCell align='center'>
                                                                    <Link className={classes.tableLink} to={() => getLinkPayroll(pay)}>
                                                                    ${formatDollar(pay.hourlyLaborPay, 2)}
                                                                    </Link>
                                                                    </TableCell>
                                                                <TableCell align='center'>
                                                                    <Link className={classes.tableLink} to={() => getLinkPayroll(pay)}>
                                                                    ${formatDollar(pay.commissionLaborPay, 2)}
                                                                    </Link>
                                                                    </TableCell>
                                                                <TableCell align='center'>
                                                                    <Link className={classes.tableLink} to={() => getLinkPayroll(pay)}>
                                                                    ${formatDollar(pay.salaryLaborPay, 2)}
                                                                    </Link>
                                                                    </TableCell>
                                                                <TableCell align='center'>
                                                                    <Link className={classes.tableLink} to={() => getLinkPayroll(pay)}>
                                                                    ${formatDollar(pay.totalLabor, 2)}
                                                                    </Link>
                                                                    </TableCell>
                                                                <TableCell align='center'>
                                                                    <Link className={classes.tableLink} to={() => getLinkPayroll(pay)}>
                                                                    ${formatDollar(pay.overtimePay, 2)}
                                                                    </Link>
                                                                    </TableCell>
                                                                <TableCell align='center'>
                                                                    <Link className={classes.tableLink} to={() => getLinkPayroll(pay)}>
                                                                    ${formatDollar(pay.tips, 2)}
                                                                    </Link>
                                                                    </TableCell>
                                                                <TableCell align='center'>
                                                                    <Link className={classes.tableLink} to={() => getLinkPayroll(pay)}>
                                                                    ${formatDollar(pay.salesCommissions, 2)}
                                                                    </Link>
                                                                    </TableCell>
                                                                <TableCell align='center'>
                                                                    <Link className={classes.tableLink} to={() => getLinkPayroll(pay)}>
                                                                    ${formatDollar(pay.upsalesCommissions, 2)}
                                                                    </Link>
                                                                    </TableCell>
                                                            </TableRow>
                                                        ))
                                                    }
                                                </>
                                            )
                                        }
                                    </TableBody>
                                </Table>
                            </TableContainer>
                        </CardContent>
                    </Card>
                </div>

                {pay.startDate && pay.endDate && (
                    <DateRangePicker
                        open={openDateRange}
                        close={() => setOpenDateRange(false)}
                        payPeriodList={payPeriodList}
                        startDate={new Date(moment(pay.startDate).utc().format('YYYY-MM-DDTHH:mm:ss'))}
                        endDate={new Date(moment(pay.endDate).utc().format('YYYY-MM-DDTHH:mm:ss'))}
                        onChangeRange={handleChangeDateRange}
                    />
                )}

            </Page>
        </AccessControl>
    )
}

export default EmployeesPayroll;
