import axios from "axios";
import localStorage from "../utils/localStorage";
import { baseUrl } from "apiConfig";
import {appInsights} from "../AppInsights";

const getToken = () => {
  var value = localStorage.getToken();
  if (!value) {
    redirectToSignIn();
  } else {
    redirectToDashboard();
  }
  return value;
};

const redirectToSignIn = () => {
  var validUrls = [baseUrl() + "sign-in", 
                    baseUrl() + "sign-in/forgot-password"]

  // not sure if we want to ensure there's a guid after the reset-password or if starting with the right path is fine
  var isValidReset = window.location.href.startsWith(baseUrl() + "sign-in/reset-password/");
  var isNotValid = validUrls.indexOf(window.location.href) === -1 && !isValidReset;

  if(isNotValid){
    window.location.href = baseUrl() + "sign-in";
  }
};

const redirectToDashboard = () => {
  if (window.location.href === baseUrl() + "sign-in") {
    window.location.href = baseUrl();
  }
};

const instance = axios.create({
  // headers: { Authorization: getToken() }
});

instance.interceptors.request.use(config => {
  config.headers["Authorization"] = getToken();
  return config;
});

instance.interceptors.response.use(
  response => {
    appInsights.trackDependencyData({
      data: JSON.stringify({
        request: response.request.response,
        response: response.data
      }),
      name: response.config.method + ' ' + response.config.url,
      responseCode: response.status,
      method: response.config.method,
      id: new Date().getTime().toString(),
      properties: {
        request: response.request.response,
        response: response.data
      },
      success: response.statusText == 'OK'
    });
    return response
  },
  error => {
    if (error.response?.status === 401) {
      redirectToSignIn();
    }
    throw error;
  }
)

export default instance;
