import { makeStyles } from "@material-ui/styles";
import { ControlPosition, data } from "azure-maps-control";
import React, { useEffect, useMemo, useState } from "react";
import {
  AzureMap,
  AzureMapDataSourceProvider,
  AzureMapHtmlMarker,
} from "react-azure-maps";

import { Room } from "@material-ui/icons";
import apiConfig from "apiConfig";
import axios from "utils/axios";
import {
  DefaultAzureMapControls,
  DefaultAzureMapOptions,
} from "../../../../Routes2/Constants";
import { ScaleBarControl } from "../../../../Routes2/components/Map/MapControls";

const useStyles = makeStyles((theme) => ({
  map: {
    width: "100%",
    height: "100%",
  },
}));

const customControls = [
  {
    control: new ScaleBarControl({
      units: "imperial",
      maxBarLength: 120,
    }),
    controlOptions: {
      position: ControlPosition.BottomRight,
    },
  },
];

const MapComponent = ({ coordinates, onCoordinateClick }) => {
  const classes = useStyles();

  const [azureMapOptions, setAzureMapOptions] = useState(
    DefaultAzureMapOptions
  );

  const onClick = (e, jobId) => {
    // console.log("You click on: ", e);
    onCoordinateClick(jobId);
  };

  const azureHtmlMapMarkerOptions = ({ latitude, longitude }) => {
    const position = new data.Position(longitude, latitude);
    return { position };
  };

  const eventToMarker = (jobId) => [
    { eventName: "click", callback: (e) => onClick(e, jobId) },
  ];

  const renderHTMLPoint = (coordinate) => {
    const rendId = Math.random();
    return (
      <AzureMapHtmlMarker
        key={rendId}
        id={coordinate.jobId}
        options={{ ...azureHtmlMapMarkerOptions(coordinate) }}
        markerContent={
          <div style={{ display: "flex", cursor: "pointer" }}>
            <Room style={{ fontSize: 24, marginBottom: -2 }} />
          </div>
        }
        events={eventToMarker(coordinate.jobId)}
      />
    );
  };

  const memoizedHtmlMarkerRender = useMemo(
    () =>
      coordinates
        .filter((x) => x.latitude != null && x.longitude != null)
        .map(renderHTMLPoint),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [coordinates]
  );

  useEffect(() => {
    const subscriptionKey = sessionStorage.getItem(
      "AZURE_MAPS_SUBSCRIPTION_KEY"
    );
    if (subscriptionKey == null) {
      Promise.all([
        axios.get(
          apiConfig.url.BASE_URL + apiConfig.url.AZURE_MAPS_SUBSCRIPTION_KEY
        ),
        axios.get(apiConfig.url.BASE_URL + apiConfig.url.COMPANY_GET_ADDRESS),
      ]).then(([res1, res2]) => {
        if (res1 && res1.data) {
          sessionStorage.setItem("AZURE_MAPS_SUBSCRIPTION_KEY", res1.data);
          const options = { ...azureMapOptions };
          options.authOptions.subscriptionKey = res1.data;
          if (res2?.data) {
            options.center = [res2.data.longitude, res2.data.latitude];
            options.zoom = 10;
          }
          setAzureMapOptions(options);
        }
      });
    } else {
      axios
        .get(apiConfig.url.BASE_URL + apiConfig.url.COMPANY_GET_ADDRESS)
        .then((res) => {
          const options = { ...azureMapOptions };
          options.authOptions.subscriptionKey = subscriptionKey;
          if (res?.data) {
            options.center = [res.data.longitude, res.data.latitude];
            options.zoom = 10;
          }
          setAzureMapOptions(options);
        });
    }
  }, []);

  return (
    <div className={classes.map}>
      {azureMapOptions.authOptions.subscriptionKey ? (
        <AzureMap
          options={azureMapOptions}
          controls={DefaultAzureMapControls}
          customControls={customControls}
        >
          <AzureMapDataSourceProvider
            events={{
              dataadded: (e) => {
                console.log("Data on source added", e.constructor);
              },
            }}
            id="AzureMapDataSourceProvider"
            options={{ cluster: true, clusterRadius: 2 }}
          >
            {memoizedHtmlMarkerRender}
          </AzureMapDataSourceProvider>
        </AzureMap>
      ) : (
        <span>Loading ...</span>
      )}
    </div>
  );
};

export default MapComponent;
