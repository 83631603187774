import React from 'react'
import { Typography } from '@material-ui/core'
import { Page } from "components";


const PrivacyPolicy = () => {
    return (
        
            <Page title={'Privacy Policy'}>
                <div>
                <Typography>
                  TBD - Please refer to franchise agreement for Privacy Policy
                </Typography>

                </div>
            </Page>
    )
}

export default PrivacyPolicy;

