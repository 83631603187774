import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import { Typography } from '@material-ui/core'
import { makeStyles } from '@material-ui/styles'

const useStyles = makeStyles(theme => ({
    routeTile: {
        width: '100%',
        height: '200px',
        display: 'flex',
        justifyContent: 'space-between'
    },
    cities: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
        '& p': {
            padding: '3px 0 3px 10px'
        }
    },
    titles: {
        textAlign: 'right'
    },
    titleJob: {
        height: '60px',
        lineHeight: '60px',
        paddingRight: '10px'
    },
    title: {
        height: '40px',
        lineHeight: '40px',
        paddingRight: '10px',
    },
    cityList: {
        height: '100%',
        overFlowY: 'scroll'
    }
}))

const RouteTitle = props => {

    const classes = useStyles();

    const { route } = props;

    const [cities, setCities] = useState([]);
    const [totalPrice, setTotalPrice] = useState();

    useEffect(() => {
        if (route.routeJobs) {
            let total = 0;

            const cts = route.routeJobs.length > 0 && route.routeJobs.map(job => {
                total += job.job.price;
                return (job.job.city || '')
            }) || [];

            setCities(cts);
            setTotalPrice(total)
        }
    }, [route.routeJobs])

    return (
        <div className={classes.routeTile}>
            <div className={classes.cities}>
                <div className={classes.cityList}>
                    {cities.map((city, i) => (
                        <Typography key={`${city}-${i}`}>{city}</Typography>
                    ))}
                </div>
                <Typography>{!isNaN(totalPrice) ? `Total: $${totalPrice.toFixed(2)}` : ''}</Typography>
            </div>
            <div className={classes.titles}>
                <Typography className={classes.titleJob}>Jobs</Typography>
                <Typography className={classes.title}>Employees</Typography>
                <Typography className={classes.title}>Equipment</Typography>
                <Typography className={classes.title}>Route Notes</Typography>
            </div>
        </div>
    )
}

RouteTitle.propTypes = {
    route: PropTypes.object
}

export default RouteTitle;
