import React, { useEffect, useState } from 'react'
import { makeStyles } from '@material-ui/styles'
import {
    Card,
    CardHeader,
    CardContent,
    Divider,
    Table,
    TableHead,
    TableBody,
    TableRow,
    TableCell,
    colors,
    Typography,
    Button, Grid, CircularProgress, Tooltip
} from '@material-ui/core'
import axios from "utils/axios";
import apiConfig from "apiConfig";
import {ConfirmModal, Pagination} from "components";
import {createTheme, MuiThemeProvider} from "@material-ui/core/styles";
import {useDispatch} from "react-redux";

const useStyles = makeStyles(theme => ({
    root: {},
    resTable: {
        overflowX: 'auto'
    },
    tableCell: {
        borderBottom: '1px solid #969696'
    },
    tableCellAction: {
        whiteSpace: 'nowrap',
        '& a': {
            display: 'block',
            width: '100%',
            marginBottom: theme.spacing(2),
            '& button': {
                width: '100%'
            }
        },
        '& a:last-child': {
            marginBottom: '0'
        }
    },
    btn: {
        display: 'block',
        margin: '4px auto',
        width: '100%',
        color: theme.palette.white,
        backgroundColor: colors.green[600],
        '&:hover': {
            backgroundColor: colors.green[900]
        }
    },
    paginate: {
        marginTop: '20px'
    }
}));

let mounted = false;

const customThemeTooltip = createTheme({
    overrides: {
        MuiTooltip: {
            tooltip: {
                fontSize: '14px',
                maxWidth: 'none'
            }
        }
    }
});

let pageNumber = 1;
let forcePage = 0;

const Duplicate = props => {
    const dispatch = useDispatch()
    const { onReload } = props
    const classes = useStyles();

    const [duplicates, setDuplicates] = useState();
    const [load, setLoad] = useState([false]);
    const [openConfirm, setOpenConfirm] = useState(false);
    const [openConfirmAdd, setOpenConfirmAdd] = useState(false);
    const [openConfirmAddExisting, setOpenConfirmAddExisting] = useState(false);
    const [value, setValue] = useState();
    const [loadingData, setLoadingData] = useState(false);
    const [pageCount, setPageCount] = useState(0);
    const addDuplicate = (id) => {
        if (value) {
            axios.patch(apiConfig.url.CUSTOMER_URL + apiConfig.url.CUSTOMERS_LIST_DUPLICATE_ADD + '?id=' + value)
                .then(res => {
                    setOpenConfirmAdd(false)
                    setLoad(!load);
                    dispatch({type: 'RELOAD_CUSTOMERS_COUNTER', reloadCustomer: true});
                    //onReload();
                })
        }
    }
    const addDuplicateExisting = (id) => {
        if (value) {
            axios.patch(apiConfig.url.CUSTOMER_URL + apiConfig.url.CUSTOMERS_LIST_DUPLICATE_ADD_EXISTING + '?id=' + value)
                .then(res => {
                    setOpenConfirmAddExisting(false)
                    setLoad(!load);
                    dispatch({type: 'RELOAD_CUSTOMERS_COUNTER', reloadCustomer: true});
                    //onReload();
                })
        }
    }
    const deleteDuplicate = () => {
        if (value) {
            axios.delete(apiConfig.url.CUSTOMER_URL + apiConfig.url.CUSTOMERS_LIST_DUPLICATE_DELETE + '?id=' + value)
                .then(res => {
                    setOpenConfirm(false)
                    setLoad(!load);
                    dispatch({type: 'RELOAD_CUSTOMERS_COUNTER', reloadCustomer: true});
                    //onReload();
                })
        }
    }
    const onPageChange = (page) => {
        pageNumber = page.selected + 1;
        forcePage = page.selected;
        setLoad(!load);
    };

    useEffect(() => {
        setLoadingData(true);
        mounted = true;
        axios.get(apiConfig.url.CUSTOMER_URL + apiConfig.url.CUSTOMERS_LIST_DUPLICATE,{
            params:
                {
                    limit: 0,
                    pageSize: 24,
                    pageNumber: pageNumber,
                }
        })
            .then(res => {
                mounted && setDuplicates(res.data.customersDuplicate)
                mounted && setPageCount(res.data.pageCount)
            })
            .finally(() => setLoadingData(false));

        return () => mounted = false;
    }, [load])

    return (
        <>
            <Card {...props}>
                <CardHeader title='Duplicate' />
                <Divider />
                {loadingData
                    ? <CardContent className={classes.resTable}>
                        <Grid container justifyContent="center" alignItems="center">
                            <Grid item>
                                <CircularProgress className={classes.circularProgress} size={50} />
                            </Grid>
                        </Grid>
                    </CardContent>
                    : duplicates && duplicates.length > 0
                        ? <CardContent className={classes.resTable}>
                                <Table style={{ minWidth: '1000px' }}>
                                    <TableHead>
                                        <TableRow>
                                            <TableCell className={classes.tableCell}>New Customer</TableCell>
                                            <TableCell className={classes.tableCell}>Potential Duplicate Of</TableCell>
                                            <TableCell className={classes.tableCell}>Requested Job</TableCell>
                                            <TableCell align='center' className={classes.tableCell} style={{ width: '1%' }}>Action To
                                                Take</TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {
                                            duplicates && duplicates.map(item => (
                                                <TableRow key={item.id}>
                                                    <TableCell>
                                                        <Typography>{item.newCustomer && item.newCustomer.companyName}
                                                            {/* <MuiThemeProvider theme={customThemeTooltip}>
                                                                <Tooltip arrow title={'View New Customer'}>
                                                                    <a
                                                                        href={
                                                                            item.newAddress && item.newAddress.addressType === 1 ?
                                                                            `/customers/${encodeURIComponent(item.newCustomer.companyName)}/${item.newCustomerId}/information`
                                                                            :
                                                                            `/customers/${encodeURIComponent(item.newCustomer.companyName)}/${item.newCustomerId}/job-addresses`
                                                                        }
                                                                        target='_blank'>
                                                                        <i style={{
                                                                            marginLeft: '10px',
                                                                            fontSize: '16px',
                                                                            color: '#263238'
                                                                        }} className="fas fa-external-link" />
                                                                    </a>
                                                                </Tooltip>
                                                            </MuiThemeProvider> */}
                                                        </Typography>
                                                        <Typography>{`${item.newCustomer && item.newCustomer.firstName || ''} ${item.newCustomer && item.newCustomer.lastName || ''}`}</Typography>
                                                        <Typography>{item.newAddress && item.newAddress.address1 || ''}</Typography>
                                                        <Typography>{item.newAddress && item.newAddress.address2 || ''}</Typography>
                                                        <Typography>{`${item.newAddress && item.newAddress.city || ''} ${item.newAddress && item.newAddress.state.name || ''} ${item.newAddress && item.newAddress.zipCode || ''}`}</Typography>
                                                        <Typography>{item.newPhone && item.newPhone.phoneNumber || ''}</Typography>
                                                        <Typography>{item.newCustomer && item.newCustomer.email || ''}</Typography>
                                                        <Typography>
                                                            <span>Address Type: </span>
                                                            {item.newAddress && item.newAddress.addressType === 1 ? 'Billing Address' : 'Jobsite Address'}
                                                        </Typography>
                                                    </TableCell>
                                                    <TableCell>
                                                        <Typography>{item.oldCustomer && item.oldCustomer.companyName || ''}
                                                                <MuiThemeProvider theme={customThemeTooltip}>
                                                                    <Tooltip arrow title={'View Original Customer'}>
                                                                        <a
                                                                            href={`/customers/${encodeURIComponent(item.oldCustomer && item.oldCustomer.companyName ? item.oldCustomer.companyName : '')}/${item.oldCustomerId}/information`}
                                                                            target='_blank'>
                                                                            <i style={{
                                                                                marginLeft: '10px',
                                                                                fontSize: '16px',
                                                                                color: '#263238'
                                                                            }} className="fas fa-external-link" />
                                                                        </a>
                                                                    </Tooltip>
                                                                </MuiThemeProvider>
                                                        </Typography>
                                                        <Typography>{`${item.oldCustomer && item.oldCustomer.firstName || ''} ${item.oldCustomer && item.oldCustomer.lastName || ''}`}</Typography>
                                                        <Typography>{item.oldAddress && item.oldAddress.address1 || ''}</Typography>
                                                        <Typography>{item.oldAddress && item.oldAddress.address2 || ''}</Typography>
                                                        <Typography>{`${item.oldAddress && item.oldAddress.city || ''} ${item.oldAddress && item.oldAddress.state.name || ''} ${item.oldAddress && item.oldAddress.zipCode || ''}`}</Typography>
                                                        <Typography>{item.oldPhone && item.oldPhone.phoneNumber || ''}</Typography>
                                                        <Typography>{item.oldCustomer && item.oldCustomer.email || ''}</Typography>
                                                        <Typography>
                                                            <span>Address Type: </span>
                                                            {item.newAddress && item.newAddress.addressType === 1 ? 'Billing Address' : 'Jobsite Address'}
                                                        </Typography>
                                                    </TableCell>
                                                    <TableCell>
                                                        <Typography>{item.jobDescription || ''}</Typography>
                                                    </TableCell>
                                                    <TableCell className={classes.tableCellAction}>
                                                        <Button
                                                            className={classes.btn}
                                                            variant="contained"
                                                            onClick={() => {
                                                                setValue(item.id);
                                                                setOpenConfirmAdd(true);
                                                            }}
                                                        >
                                                            Add as New Customer
                                                        </Button>
                                                        <Button
                                                            className={classes.btn}
                                                            variant="contained"
                                                            onClick={()=>{
                                                                setValue(item.id);
                                                                setOpenConfirmAddExisting(true);
                                                            }}>
                                                            Add Job to existing Customer
                                                        </Button>
                                                        <Button
                                                            onClick={() => {
                                                                setValue(item.id);
                                                                setOpenConfirm(true);
                                                            }}
                                                            className={classes.btn}
                                                            variant="contained">
                                                            Delete New Customer
                                                        </Button>
                                                    </TableCell>
                                                </TableRow>
                                            ))
                                        }
            
                                    </TableBody>
                                </Table>
                            <div style={pageCount < 2 ? {display: 'none'} : null} className={classes.paginate}>
                                <Pagination pageCount={pageCount} forcePage={forcePage} onPageChange={onPageChange}/>
                            </div>
                            </CardContent>
                        : <CardContent>
                            <Typography style={{fontSize: '16px'}}>No data found.</Typography>
                        </CardContent>
                }
            </Card>
            <ConfirmModal

                openConfirm={openConfirm}
                closeConfirm={() => setOpenConfirm(false)}
                onConfirm={deleteDuplicate}
            />
            <ConfirmModal
                title={'Are you sure you want to add this item?'}
                openConfirm={openConfirmAdd}
                closeConfirm={() => setOpenConfirmAdd(false)}
                onConfirm={addDuplicate}
            />
            <ConfirmModal
                title={'Are you sure you want to add this item?'}
                openConfirm={openConfirmAddExisting}
                closeConfirm={() => setOpenConfirmAddExisting(false)}
                onConfirm={addDuplicateExisting}
            />
        </>
    )
}

export default Duplicate;
