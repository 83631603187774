import {
    Button,
    Card,
    CardActions,
    CardContent,
    CardHeader,
    colors,
    Divider,
    FormControlLabel,
    Grid,
    IconButton,
    Paper,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableRow,
    TextField,
    Tooltip
} from '@material-ui/core';
import React, {Fragment, useEffect, useRef, useState} from 'react';
import axios from 'utils/axios';
import apiConfig from 'apiConfig';
import {ConfirmModal, GreenCheckbox, GreenRadio} from 'components';
import CircularProgress from "@material-ui/core/CircularProgress";
import {AddBox, CheckBox, CheckBoxOutlineBlank, Delete, DeleteOutlined, FileCopyOutlined} from "@material-ui/icons";
import AddIcon from "@material-ui/icons/Add";
import {makeStyles} from "@material-ui/styles";
import ModalPreview from "./ModalPreview";


const useStyles = makeStyles(theme => ({
    saveBtn: {
        color: theme.palette.white,
        backgroundColor: colors.green[600],
        '&:hover': {
            backgroundColor: colors.green[900]
        }
    },
    circularProgress: {
        marginTop: '30px',
    },
    stickyBtn: {
        marginBottom: 0,
        position: '-webkit-sticky',
        position: 'sticky',
        backgroundColor: '#f4f6f8',
        top: '-13px',
        zIndex: 2,
    },
    checkbox: {
        margin: 0,
        '& .MuiIconButton-root': {
            padding: 0
        },
        '& .MuiTypography-root': {
            whiteSpace: 'nowrap'
        }
    },
    addIcon: {
        padding: '0',
        '& span svg': {
            fontSize: '40px',
            color: colors.green[600],
            '&:hover': {
                color: colors.green[900]
            }
        }
    },
}));

const initValue = {
    formName: '',
    formDescription: '',
    questions: [{
        questionName: '',
        questionType: '077c97a7-5b66-448c-a4e1-d4b57ecac3e2',
        questionControlType: 'textbox',
        answer: '',
    }]
}

const CustomFormAdd = props => {
    const classes = useStyles();
    const {
        redirectToList,
        selectedId,
        onDelete,
        setReload,
        setIsStatus,
        setOpenSnackBar
    } = props;
    const [isSaving, setIsSaving] = useState(false);
    const [errors, setErrors] = useState({});
    const [touched, setTouched] = useState({});
    const [formData, setFormData] = useState(JSON.parse(JSON.stringify(initValue)));
    const [questionType, setQuestionType] = useState([]);
    const [fetchQuestion, setFetchQuestion] = useState(false);
    const [showModalPreview, setShowModalPreview] = useState(false);
    const [openModalConfirm, setOpenModalConfirm] = useState(false);
    const [hasEdit, setHasEdit] = useState(false);

    const dragItem = useRef();
    const dragOverItem = useRef();

    const fetchQuestionType = () => {
        axios.get(apiConfig.url.BASE_URL + apiConfig.url.GET_CUSTOM_FORM_CONTROLS_TYPES)
            .then(res => {
                const questionTypeOrder = {
                    text: 0,
                    textarea: 1,
                    radiobutton: 2,
                    checkbox: 3,
                    datepicker: 4,
                    table: 5
                };
                res.data = res.data.sort((a, b) => questionTypeOrder[a.controlType] - questionTypeOrder[b.controlType]);
                setQuestionType(res.data);
            })
            .catch(err => {

            })
            .finally();
    };

    const handleSort = () => {
        let data = {...formData};
        let _questions = data.questions;
        const draggedItemContent = _questions.splice(dragItem.current, 1)[0];
        _questions.splice(dragOverItem.current, 0, draggedItemContent);
        dragItem.current = null
        dragOverItem.current = null
        data.questions = _questions;
        setFormData(data);
    }

    const onAddQuestion = index => {
        let data = {...formData}
        data.questions.splice(index, 0, {
            questionName: '',
            questionType: '077c97a7-5b66-448c-a4e1-d4b57ecac3e2',
            questionControlType : 'textbox',
            answer: {
                answer: ''
            }
        })
        setFormData(data)
        setHasEdit(true);
    }

    const removeAnswer = (index, answerIndex) => {
        let data = {...formData};
        data.questions[index].answer.answer.splice(answerIndex, 1);
        setFormData(data);
        setHasEdit(true);
    }

    const openModalPreview = () => {
        setShowModalPreview(true);
    }

    const removeQuestion = (index) => {
        let data = {...formData};
        data.questions.splice(index,1);
        setFormData(data);
        setHasEdit(true);
    }

    const copyQuestion = (index) => {
        let data = {...formData};
        let question = Object.assign({}, data.questions[index]);
        data.questions = [...data.questions, {...question, id: '', answer: {...question.answer, id: ''}}];
        setFormData(data);
        setHasEdit(true);
    }

    const addAnswer = (index, type) => {
        let data = {...formData};
        if (type == 2 || type == 3) {
            if (data.questions[index].answer && data.questions[index].answer.answer) {
                data.questions[index].answer.answer.push({
                    label: '',
                })
            } else {
                data.questions[index].answer.answer = [{
                    label: '',
                }]
            }
        }
        setFormData(data)
        setHasEdit(true);
    }

    const submitForm = () => {
        setIsSaving(true);
        const data = {
            Id: formData.id,
            FormName: formData.formName,
            Description: formData.formDescription,
            IsActive: formData.isActive,
            CustomFormQuestions: formData.questions.map((item) => {
                    return {
                        Id: item.id,
                        CustomFormControlTypeId: item.questionType,
                        Question: item.questionName,
                        Answer: {...item.answer,
                            CustomerCustomFormId: formData.id,
                            CustomFormQuestionId: item.id,
                            answer: typeof(item.answer.answer) == 'string' ? item.answer.answer : JSON.stringify(item.answer.answer)
                        }
                    }
                })
        };
        if (selectedId) {
            axios.put(apiConfig.url.BASE_URL + apiConfig.url.CUSTOM_UPDATE_FORM_TEMPLATES.replace('{id}', selectedId), data)
                .then(res => {
                    setIsStatus({failed: false, msg: 'Update successfully.'});
                    setOpenSnackBar(true);
                    setReload();
                    // change URL after update success
                    redirectToList();
                })
                .catch(err => {
                    setIsStatus({failed: true, msg: 'An error occurred, please try again later.'});
                    setOpenSnackBar(true);
                })
                .finally(e => setIsSaving(false));
        } else {
            axios.post(apiConfig.url.BASE_URL + apiConfig.url.CUSTOM_ADD_FORM_TEMPLATES, data)
                .then(res => {
                    setIsStatus({failed: false, msg: 'Save successfully.'});
                    setOpenSnackBar(true);
                    setReload();
                    // change URL after update success
                    redirectToList();
                })
                .catch(err => {
                    setIsStatus({failed: true, msg: 'An error occurred, please try again later.'});
                    setOpenSnackBar(true);
                })
                .finally(e => setIsSaving(false));
        }

    }
    const isJsonString = (str) => {
        try {
            JSON.parse(str);
        } catch (e) {
            return false;
        }
        return true;
    }
    useEffect(() => {
        if(selectedId){
            setFetchQuestion(true)
            axios.get(apiConfig.url.BASE_URL + apiConfig.url.GET_CUSTOM_FORM_TEMPLATE.replace('{id}', selectedId))
                .then(res => {
                    let questions = res.data.customFormQuestions.map(item => {
                        let _answer = item.answer ? (isJsonString(item.answer.answer) ? JSON.parse(item.answer.answer) : item.answer.answer) : '' ;
                        let a =  {
                            id: item.id,
                            questionName: item.question,
                            questionType: item.customFormControlTypeId,
                            questionControlType : item.controlType,
                            answer: {...item.answer, answer: _answer}
                        }
                        return a;
                    })
                    let data = {
                        id: res.data.customFormCompany.id,
                        formName: res.data.customFormCompany.formName,
                        formDescription: res.data.customFormCompany.description,
                        isActive: res.data.customFormCompany.isActive,
                        questions: questions
                    }
                    setFormData(data);
                })
                .catch(err => {

                })
                .finally(() => {
                    setFetchQuestion(false)
                });
        }
    },[selectedId])
    useEffect(() => {
        fetchQuestionType();
    }, [])
    // useEffect(() => {
    //     fetchCustomFormCompany();
    // }, [reload])

    if (selectedId && fetchQuestion) {
        return (
            <Grid
                container
                spacing={0}
                align="center"
                justifyContent="center"
                direction="column">
                <Grid item>
                    <CircularProgress className={classes.circularProgress} size={50}/>
                </Grid>
            </Grid>
        )
    }

    return (
        <>
            <Grid className={classes.stickyBtn} container spacing={4}>
                <Grid item xs={12}>
                    <CardActions>
                        <Button
                            onClick={() => {
                                if (hasEdit) {
                                    setOpenModalConfirm(true)
                                }else{
                                    redirectToList()
                                }

                            }}
                            // className={classes.saveBtn}
                            // disabled={isSaving}
                            variant="contained">
                            Close
                        </Button>
                        <Button
                            onClick={() => {
                                submitForm()
                            }}
                            className={classes.saveBtn}
                            disabled={isSaving}
                            variant="contained">
                            Save
                        </Button>
                        <Button
                            onClick={() => {
                                openModalPreview()
                            }}
                            className={classes.saveBtn}
                            disabled={isSaving}
                            variant="contained">
                            Preview
                        </Button>
                        {isSaving && <CircularProgress size={24}/>}
                    </CardActions>
                </Grid>
            </Grid>
            <Grid container spacing={4}>
                <Grid container item md={12} lg={12}>
                    <Card style={{width: '100%'}}>
                        <CardHeader title={selectedId ? "Edit Form" : "Add Form"}/>
                        <Divider/>
                        <CardContent>
                            <Grid container spacing={3}>
                                <Grid item md={11} xs={12}>
                                    <Card >
                                        <CardContent style={{
                                            backgroundColor: colors.green[100],
                                        }}>
                                            <Grid container spacing={3} >
                                                <Grid item xs={'auto'} style={{ flex: '1' }}>
                                                    <TextField
                                                        InputProps={{
                                                            style: {
                                                                backgroundColor: "#ffffff"
                                                            }
                                                        }}
                                                        error={
                                                            errors.formName &&
                                                            touched.formName
                                                        }
                                                        helperText={
                                                            errors.formName &&
                                                            touched.formName &&
                                                            errors.formName}
                                                        fullWidth
                                                        label={'Form Name'}
                                                        name='formName'
                                                        variant='outlined'
                                                        onBlur={() => {
                                                            setTouched({...touched, formName: true});
                                                        }}
                                                        onChange={event => {
                                                            setHasEdit(true);
                                                            setFormData({...formData, formName: event.target.value})
                                                        }}
                                                        value={formData.formName || ''}
                                                    />
                                                </Grid>
                                                <Grid item>
                                                    <IconButton
                                                        style={{cursor: 'pointer', color: colors.green[600]}}
                                                        onClick={() => {
                                                            setHasEdit(true);
                                                            setFormData({...formData, isActive: !formData.isActive})
                                                        }}>
                                                        {formData.isActive ? <CheckBox /> : <CheckBoxOutlineBlank /> }
                                                    </IconButton>
                                                </Grid>
                                                <Grid item>
                                                    <IconButton aria-label="delete" onClick={() => onDelete(formData.id)}>
                                                        <Delete />
                                                    </IconButton>
                                                </Grid>
                                                <Grid item xs={12}>
                                                    <TextField
                                                        InputProps={{
                                                            style: {
                                                                backgroundColor: "#ffffff"
                                                            }
                                                        }}
                                                        error={
                                                            errors.formDescription &&
                                                            touched.formDescription
                                                        }
                                                        helperText={
                                                            errors.formDescription &&
                                                            touched.formDescription &&
                                                            errors.formDescription}
                                                        fullWidth
                                                        label={'Form Description'}
                                                        name='formDescription'
                                                        variant='outlined'
                                                        onBlur={() => {
                                                            setTouched({...touched, formDescription: true});
                                                        }}
                                                        onChange={event => {
                                                            setHasEdit(true);
                                                            setFormData({...formData, formDescription: event.target.value})
                                                        }}
                                                        value={formData.formDescription || ''}
                                                    />
                                                </Grid>
                                            </Grid>
                                        </CardContent>
                                    </Card>
                                </Grid>
                                <Grid item md={1} xs={12}
                                      style={{display: "flex", alignItems: 'end', justifyContent: 'center'}}>
                                         <Tooltip arrow title={"Add Question"}>
                                            <Button
                                                style={{
                                                    padding: '6px',
                                                    minWidth: '0',
                                                    borderRadius: '20px',
                                                }}
                                                variant='contained'
                                                onClick={() => onAddQuestion(0)}
                                                className={classes.saveBtn}>
                                                <AddIcon/>
                                            </Button>
                                         </Tooltip>
                                </Grid>
                                <Grid item xs={12}>
                                    <Grid container spacing={3}>
                                        {
                                            formData.questions && formData.questions.length > 0 && formData.questions.map((question, index) => (
                                                question &&
                                                <Fragment key={index}>
                                                    <Grid item md={11} xs={12}>
                                                        <Card draggable
                                                            onDragStart={(e) => (dragItem.current = index)}
                                                            onDragEnter={(e) => (dragOverItem.current = index)}
                                                            onDragEnd={handleSort}
                                                            onDragOver={(e) => e.preventDefault()}
                                                            style={{border: '1px solid #cccccc', borderRadius: '4px', cursor:'move' }}>
                                                            <CardContent>
                                                                <Grid container spacing={3}>
                                                                    <Grid item md={8} xs={12} >
                                                                        <TextField
                                                                            error={
                                                                                errors.questions &&
                                                                                errors.questions[index].questionName &&
                                                                                touched.questions &&
                                                                                touched.questions[index].questionName
                                                                            }
                                                                            helperText={
                                                                                errors.questions &&
                                                                                errors.questions[index].questionName &&
                                                                                touched.questions &&
                                                                                touched.questions[index].questionName &&
                                                                                errors.questions[index].questionName}
                                                                            fullWidth
                                                                            label={'Question'}
                                                                            name='question'
                                                                            variant='outlined'
                                                                            onChange={event => {
                                                                                let data = {...formData};
                                                                                data.questions[index] = {...question, questionName: event.target.value}
                                                                                setFormData(data);
                                                                                setHasEdit(true);
                                                                            }}
                                                                            value={question.questionName || ''}
                                                                            style={{marginBottom: '16px'}}
                                                                        />

                                                                        {questionType && questionType.length > 0 &&
                                                                            <TextField
                                                                                fullWidth
                                                                                label="Select Question Type"
                                                                                name="questionTypeId"
                                                                                onChange={(event) => {
                                                                                    let data = {...formData};
                                                                                    data.questions[index].questionType = event.target.value;
                                                                                    let type = questionType.find(x=>x.id == event.target.value).controlType;
                                                                                    data.questions[index].questionControlType = type;
                                                                                    if (type == 'textbox') {
                                                                                        let answer = {...data.questions[index].answer, answer: ''}
                                                                                        data.questions[index].answer = answer
                                                                                    }
                                                                                    if (type == 'radiobutton') {
                                                                                        let answer = {...data.questions[index].answer, answer: [
                                                                                                {
                                                                                                    "id": 1,
                                                                                                    "label": ""
                                                                                                }
                                                                                            ]}
                                                                                        data.questions[index].answer = answer
                                                                                    }

                                                                                    if (type == 'checkbox') {
                                                                                        let answer = {...data.questions[index].answer, answer: [
                                                                                                {
                                                                                                    "id": 1,
                                                                                                    "label": ""
                                                                                                }
                                                                                            ]}
                                                                                        data.questions[index].answer = answer
                                                                                    }
                                                                                    if (type == 'datepicker') {
                                                                                        let answer = {
                                                                                            ...data.questions[index].answer,
                                                                                            answer: ''
                                                                                        }
                                                                                        data.questions[index].answer = answer
                                                                                    }
                                                                                    if (type == 'table') {
                                                                                        let answer = {
                                                                                            ...data.questions[index].answer,
                                                                                            answer: {
                                                                                                rows: '',
                                                                                                columns: ''
                                                                                            }
                                                                                        }
                                                                                        data.questions[index].answer = answer
                                                                                    }
                                                                                    if (type == 'textarea') {
                                                                                        let answer = {
                                                                                            ...data.questions[index].answer,
                                                                                            answer: ''
                                                                                        }
                                                                                        data.questions[index].answer = answer
                                                                                    }
                                                                                    setFormData(data);
                                                                                    setHasEdit(true);
                                                                                }}
                                                                                select
                                                                                SelectProps={{native: true}}
                                                                                value={question.questionType || ''}
                                                                                variant="outlined"
                                                                                style={{marginBottom: '16px'}}>
                                                                                {questionType.map(pt => (
                                                                                    <option
                                                                                        key={pt.id}
                                                                                        value={pt.id}
                                                                                    >
                                                                                        {pt.displayName}
                                                                                    </option>
                                                                                ))}
                                                                            </TextField>
                                                                        }
                                                                        <Grid item md={12} xs={12}>
                                                                        {
                                                                            question.questionControlType == 'radiobutton' &&
                                                                            <>
                                                                                {
                                                                                    question.answer && Array.isArray(question.answer.answer) && question.answer.answer.map((option, indexOption) => (
                                                                                        <div key={indexOption} style={{
                                                                                            display: 'block',
                                                                                            padding: '12px'
                                                                                        }}>
                                                                                            <FormControlLabel
                                                                                                label=''
                                                                                                disabled={true}
                                                                                                control={
                                                                                                    <GreenRadio/>
                                                                                                }
                                                                                            />
                                                                                            <TextField
                                                                                                label={'Option '+ (indexOption + 1)}
                                                                                                style={{width: 'calc(100% - 135px)'}}
                                                                                                variant='outlined'
                                                                                                onBlur={() => {
                                                                                                    // setFieldTouched(index, 'answer');
                                                                                                }}
                                                                                                onChange={event => {
                                                                                                    let data = {...formData};
                                                                                                    let _option = {...option, label: event.target.value}
                                                                                                    let _answer = {...question.answer};
                                                                                                    let _options = [..._answer.answer]
                                                                                                    _options[indexOption] = _option;
                                                                                                    _answer.answer = _options;
                                                                                                    data.questions[index] = {...question, answer : _answer}
                                                                                                    setFormData(data)
                                                                                                    setHasEdit(true);
                                                                                                }}
                                                                                                value={option.label || ''}
                                                                                            />
                                                                                            <IconButton onClick={() => {
                                                                                                removeAnswer(index, indexOption);
                                                                                            }}>
                                                                                                <Delete/>
                                                                                            </IconButton>
                                                                                            {question.answer.answer.length === indexOption + 1 &&
                                                                                                <IconButton className={classes.addIcon}
                                                                                                    onClick={() => {
                                                                                                        addAnswer(index, 2);
                                                                                                    }}>
                                                                                                    <AddBox/>
                                                                                                </IconButton>
                                                                                            }
                                                                                        </div>
                                                                                    ))
                                                                                }
                                                                            </>

                                                                        }
                                                                        {
                                                                            question.questionControlType == 'checkbox' &&
                                                                            <>
                                                                                {
                                                                                    question.answer && Array.isArray(question.answer.answer) && question.answer.answer.map((option, indexOption) => (
                                                                                        <div key={indexOption} style={{
                                                                                            display: 'block',
                                                                                            padding: '12px'
                                                                                        }}>
                                                                                            <FormControlLabel
                                                                                                disabled={true}
                                                                                                control={
                                                                                                    <GreenCheckbox/>
                                                                                                }
                                                                                            />
                                                                                            <TextField
                                                                                                label={'Option '+ (indexOption + 1)}
                                                                                                style={{width: 'calc(100% - 135px)'}}
                                                                                                variant='outlined'
                                                                                                onBlur={() => {
                                                                                                    // setFieldTouched(index, 'answer');
                                                                                                }}
                                                                                                onChange={event => {
                                                                                                    let data = {...formData};
                                                                                                    let _option = {...option, label: event.target.value}
                                                                                                    let _answer = {...question.answer};
                                                                                                    let _options = [..._answer.answer]
                                                                                                    _options[indexOption] = _option;
                                                                                                    _answer.answer = _options;
                                                                                                    data.questions[index] = {...question, answer : _answer}
                                                                                                    setFormData(data)
                                                                                                    setHasEdit(true);
                                                                                                }}
                                                                                                value={option.label || ''}
                                                                                            />
                                                                                            <IconButton onClick={() => {
                                                                                                removeAnswer(index, indexOption);
                                                                                            }}>
                                                                                                <Delete/>
                                                                                            </IconButton>
                                                                                            {question.answer.answer.length === indexOption + 1 &&
                                                                                                <IconButton className={classes.addIcon}
                                                                                                    onClick={() => {
                                                                                                        addAnswer(index, 3);
                                                                                                    }}>
                                                                                                    <AddBox/>
                                                                                                </IconButton>
                                                                                            }
                                                                                        </div>
                                                                                    ))
                                                                                }
                                                                            </>

                                                                        }
                                                                        {
                                                                            question.questionControlType == 'table' &&
                                                                            <>
                                                                                <Grid container spacing={3}>
                                                                                    <Grid item xs={6}>
                                                                                        <TextField
                                                                                            fullWidth
                                                                                            label={'Rows'}
                                                                                            name='answer'
                                                                                            variant='outlined'
                                                                                            value={question.answer.answer.rows || ''}
                                                                                            onChange={event => {
                                                                                                if (isNaN(event.target.value)) return;
                                                                                                let data = {...formData};
                                                                                                let answer = data.questions[index].answer;
                                                                                                let a = {...answer.answer, rows: event.target.value};
                                                                                                answer = {...answer, answer: a}
                                                                                                data.questions[index].answer = answer;
                                                                                                setFormData(data)
                                                                                                setHasEdit(true);
                                                                                            }}
                                                                                        />
                                                                                    </Grid>
                                                                                    <Grid item xs={6}>
                                                                                        <TextField
                                                                                            fullWidth
                                                                                            label={'Colums'}
                                                                                            name='answer'
                                                                                            variant='outlined'
                                                                                            value={question.answer.answer.columns || ''}
                                                                                            onChange={event => {
                                                                                                if (isNaN(event.target.value)) return;
                                                                                                let data = {...formData};
                                                                                                let answer = data.questions[index].answer;
                                                                                                let a = {
                                                                                                    ...answer.answer,
                                                                                                    columns: event.target.value
                                                                                                };
                                                                                                answer = {...answer, answer: a}
                                                                                                data.questions[index].answer = answer;
                                                                                                setFormData(data)
                                                                                                setHasEdit(true);
                                                                                            }}
                                                                                        />
                                                                                    </Grid>
                                                                                    <Grid item xs={12}>
                                                                                        <TableContainer component={Paper}>
                                                                                            <Table className={classes.table}
                                                                                                aria-label="">
                                                                                                <TableBody>
                                                                                                    {question.answer.answer.rows && new Array(parseInt(question.answer.answer.rows)).fill(0).map((row, indexRow) => (
                                                                                                        <TableRow key={indexRow}>
                                                                                                            {question.answer.answer.columns && new Array(parseInt(question.answer.answer.columns)).fill(0).map((row, indexColum) => (
                                                                                                                <TableCell
                                                                                                                    key={indexColum}
                                                                                                                    style={{border: "1px solid #cccccc", padding: '0'}}
                                                                                                                    component="th"
                                                                                                                    scope="row">
                                                                                                                    <TextField
                                                                                                                        style={{borderRadius: 0 , backgroundColor: indexRow == 0 ? '#e3e3e3' : (indexRow % 2 == 0 ? "#f1f1f1": 'initial')}}
                                                                                                                        disabled={indexRow !== 0}
                                                                                                                        fullWidth
                                                                                                                        InputProps={{
                                                                                                                            style: {
                                                                                                                                borderRadius: "0",
                                                                                                                                textAlign: indexRow == 0 ? 'center': 'left'
                                                                                                                            }
                                                                                                                        }}
                                                                                                                        variant='outlined'
                                                                                                                        value={(question.answer.answer.headers && indexRow == 0 && question.answer.answer.headers[indexColum]) || ''}
                                                                                                                        onChange={event => {
                                                                                                                            if(indexRow !== 0) return;
                                                                                                                            let data = {...formData};
                                                                                                                            let answer = data.questions[index].answer;
                                                                                                                            let a = {
                                                                                                                                ...answer.answer
                                                                                                                            };
                                                                                                                            let headers = [...a.headers ?? []]
                                                                                                                            headers[indexColum] = event.target.value;
                                                                                                                            a.headers = headers;
                                                                                                                            answer = {...answer, answer: a}
                                                                                                                            data.questions[index].answer = answer;
                                                                                                                            setFormData(data);
                                                                                                                            setHasEdit(true);
                                                                                                                        }}
                                                                                                                    />
                                                                                                                </TableCell>
                                                                                                            ))}
                                                                                                        </TableRow>
                                                                                                    ))}
                                                                                                </TableBody>
                                                                                            </Table>
                                                                                        </TableContainer>
                                                                                    </Grid>
                                                                                </Grid>
                                                                            </>
                                                                        }
                                                                        </Grid>
                                                                    </Grid>
                                                                    <Grid item md={4} xs={12}>
                                                                    <CardActions style={{display:'flex', justifyContent: 'flex-end', alignItems: 'flex-end' , marginBottom: '6px'}}>
                                                                        <Tooltip arrow title={"Copy Question"}>
                                                                            <IconButton onClick={() => {
                                                                                copyQuestion(index);
                                                                            }}>
                                                                                <FileCopyOutlined/>
                                                                            </IconButton>
                                                                        </Tooltip>
                                                                        <Tooltip arrow title={"Remove Question"}>
                                                                            <IconButton onClick={() => {
                                                                                removeQuestion(index);
                                                                            }}>
                                                                                <DeleteOutlined/>
                                                                            </IconButton>
                                                                        </Tooltip>
                                                                    </CardActions>
                                                                    </Grid>
                                                                </Grid>
                                                            </CardContent>
                                                        </Card>
                                                    </Grid>
                                                    <Grid item md={1} xs={12}
                                                        style={{display: "flex", alignItems: 'end', justifyContent: 'center'}}>
                                                            <Tooltip arrow title={"Add Question"}>
                                                                <Button
                                                                    style={{
                                                                        padding: '6px',
                                                                        minWidth: '0',
                                                                        borderRadius: '20px',
                                                                    }}
                                                                    variant='contained'
                                                                    onClick={() => onAddQuestion(index + 1)}
                                                                    className={classes.saveBtn}>
                                                                    <AddIcon/>
                                                                </Button>
                                                            </Tooltip>
                                                    </Grid>
                                                </Fragment>
                                            ))
                                        }
                                    </Grid>
                                </Grid>
                            </Grid>
                        </CardContent>
                    </Card>
                </Grid>
            </Grid>
            {/*<ConfirmModal*/}
            {/*    openConfirm={openConfirm}*/}
            {/*    title={'You are about to change the customer\'s Quickbooks identifier.  This will create a new Quickbooks account for this customer.  Do you want to continue?'}*/}
            {/*    okButtonText={'Continue'}*/}
            {/*    isProgress={isGenerating}*/}
            {/*    closeConfirm={() => setOpenConfirm(false)}*/}
            {/*    onConfirm={() => generateQBName(setFieldValue)}*/}
            {/*/>*/}
            <ModalPreview
                openConfirm={showModalPreview}
                closeConfirm={() => setShowModalPreview(false)}
                formData={formData}
            />
            <ConfirmModal
                title={'All unsaved works will be deleted, are you sure?'}
                openConfirm={openModalConfirm}
                onConfirm={() => redirectToList()}
                closeConfirm={() => setOpenModalConfirm(false)}
            />
        </>
    );
};

export default CustomFormAdd;
