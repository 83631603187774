import React, { useState } from 'react';
import NumberFormat from 'react-number-format';
import {
    Card,
    CardHeader,
    CardContent,
    Grid,
    TextField,
    Divider, FormControlLabel, FormControl, FormLabel, FormGroup
} from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import { GreenCheckbox } from "components";
import { KeyboardDatePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
import { isDate } from "moment";

const useStyles = makeStyles(theme => ({
    root: {
        maxWidth: '100%',
        marginTop: '24px'
    },
    checkBokLabel: {
        textAlign: 'end'
    },
    checkboxGroup: {
        paddingTop: '8px !important'
    },
    taxCheckbox: {
        width: '100%',
        justifyContent: 'space-between'
    },
    textError: {
        fontSize: '11px',
        fontweight: '400',
        paddingTop: '4px',
        fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif'
    },
    blank: {
        display: 'block',
        height: 77,
        [theme.breakpoints.down('sm')]: {
            display: 'none'
        }
    },
    saleCommissionGroup: {
        width: '100%',
        display: 'flex',
        alignItems: 'center'
    }
}));

const Compensation = props => {

    const {
        values,
        errors,
        touched,
        setFieldTouched,
        setFieldValue,
        errorHourlyRate,
        errorLaborCommission,
        setErrorHourlyRate,
        setErrorLaborCommission
    } = props;
    const classes = useStyles();
    const [currentSalary, setCurrentSalary] = useState(false);

    const onChange = (event, setFieldValue, values) => {
        const { name, value, type } = event.target;
        if (type !== 'checkbox') {
            const salary = { ...values, [name]: value }
            setFieldValue('salaries', salary)
        }
        if (type === 'checkbox' && !event.target.checked) {
            switch (name) {
                case 'salaryCheckBox':
                    setFieldValue('salaries', { ...values, [name]: event.target.checked, salary: '' });
                    break;
                case 'hourlyCheckBox':
                    setFieldValue('salaries', {
                        ...values,
                        [name]: event.target.checked,
                        hourly: '',
                    });
                    setErrorHourlyRate(false)
                    break;
                case 'laborCommission':
                    setFieldValue('salaries', {
                        ...values,
                        [name]: event.target.checked,
                        laborPercent: '',
                    });
                    setErrorLaborCommission(false)
                    break;
                case 'saleCommission':
                    setFieldValue('salaries', {
                        ...values,
                        [name]: event.target.checked,
                        salePercentNewSale: '',
                        salePercentRetSale: ''
                    });
                    break;
                case 'upsaleCommission':
                    setFieldValue('salaries', {
                        ...values,
                        [name]: event.target.checked,
                        upsalePercent: ''
                    });
                    break;
                case 'hourlyAllHours':
                    if (values.hourlySpecificHours) {
                        setFieldValue('salaries', { ...values, [name]: event.target.checked });
                    }
                    break;
                case 'hourlySpecificHours':
                    if (values.hourlyAllHours) {
                        setFieldValue('salaries', { ...values, [name]: event.target.checked });
                    }
                    break;
                case 'laborCommissionAllJobs':
                    if (values.laborCommissionSpecificJobs) {
                        setFieldValue('salaries', { ...values, [name]: event.target.checked });
                    }
                    break;
                case 'laborCommissionSpecificJobs':
                    if (values.laborCommissionAllJobs) {
                        setFieldValue('salaries', { ...values, [name]: event.target.checked });
                    }
                    break;
            }
        }
        if (type === 'checkbox' && event.target.checked) {
            switch (name) {
                case 'hourlyCheckBox':
                    setFieldValue('salaries', {
                        ...values,
                        [name]: event.target.checked,
                    });
                    break;
                case 'laborCommission':
                    setFieldValue('salaries', {
                        ...values,
                        [name]: event.target.checked,
                    });
                    break;
                case 'salaryCheckBox':
                case 'saleCommission':
                    setFieldValue('salaries', { ...values, [name]: event.target.checked });
                    break;
                case 'upsaleCommission':
                    setFieldValue('salaries', { ...values, [name]: event.target.checked });
                    break;
                case 'hourlyAllHours':
                    setErrorHourlyRate(false);
                    setFieldValue('salaries', { ...values, [name]: event.target.checked, hourlySpecificHours: false });
                    break;
                case 'hourlySpecificHours':
                    setErrorHourlyRate(false);
                    setFieldValue('salaries', { ...values, [name]: event.target.checked, hourlyAllHours: false });
                    break;
                case 'laborCommissionAllJobs':
                    setErrorLaborCommission(false);
                    setFieldValue('salaries', {
                        ...values,
                        [name]: event.target.checked,
                        laborCommissionSpecificJobs: false
                    });
                    break;
                case 'laborCommissionSpecificJobs':
                    setErrorLaborCommission(false);
                    setFieldValue('salaries', { ...values, [name]: event.target.checked, laborCommissionAllJobs: false });
                    break;
            }
        }
    }
    // const classes = useStyles();

    return (
        <Grid container spacing={4}>
            <Grid item md={12} lg={3}></Grid>
            <Grid item md={12} lg={9}>
                <Card>
                    <CardHeader title="Compensation" />
                    <Divider />
                    <CardContent>
                        <Grid
                            className={classes.container}
                            container
                            spacing={3}
                        >
                            <Grid item xs={3} className={classes.checkBokLabel}>
                                <FormControlLabel
                                    disabled={currentSalary}
                                    className={classes.taxCheckbox}
                                    name={'salaryCheckBox'}
                                    label='Salary'
                                    checked={values.salaryCheckBox || false}
                                    labelPlacement="start"
                                    control={
                                        <GreenCheckbox />
                                    }
                                    onChange={(event) => onChange(event, setFieldValue, values)}
                                />
                            </Grid>
                            <Grid item xs={9}>
                                <NumberFormat
                                    customInput={TextField}
                                    prefix='$'
                                    thousandSeparator
                                    allowNegative={false}
                                    fullWidth
                                    disabled={currentSalary || !values.salaryCheckBox}
                                    error={values.salaryCheckBox && errors.salary && touched.salary}
                                    helperText={(values.salaryCheckBox && errors.salary && touched.salary) && errors.salary}
                                    label="Weekly Salary"
                                    name="salary"
                                    onBlur={() => {
                                        const salary = { ...touched, salary: true }
                                        setFieldTouched('salaries', salary);
                                    }}
                                    onValueChange={val => {
                                        const salary = { ...values, salary: val.value }
                                        setFieldValue('salaries', salary)
                                    }}
                                    value={values.salary || ''}
                                    variant="outlined"
                                />
                            </Grid>
                            <Grid item xs={3} className={classes.checkBokLabel}>
                                <FormControlLabel
                                    disabled={currentSalary}
                                    className={classes.taxCheckbox}
                                    name='hourlyCheckBox'
                                    label='Hourly'
                                    checked={values.hourlyCheckBox || false}
                                    labelPlacement="start"
                                    control={
                                        <GreenCheckbox />
                                    }
                                    onChange={(event) => onChange(event, setFieldValue, values)}
                                />
                            </Grid>
                            <Grid item xs={9}>
                                <NumberFormat
                                    customInput={TextField}
                                    prefix='$'
                                    thousandSeparator
                                    allowNegative={false}
                                    fullWidth
                                    disabled={currentSalary || !values.hourlyCheckBox}
                                    error={values.hourlyCheckBox && errors.hourly && touched.hourly}
                                    helperText={(values.hourlyCheckBox && errors.hourly && touched.hourly) && errors.hourly}
                                    label="Hourly Rate"
                                    name="hourly"
                                    onBlur={() => {
                                        const salary = { ...touched, hourly: true }
                                        setFieldTouched('salaries', salary);
                                    }}
                                    onValueChange={val => {
                                        const salary = { ...values, hourly: val.value }
                                        setFieldValue('salaries', salary)
                                    }}
                                    value={values.hourly || ''}
                                    variant="outlined" />
                            </Grid>
                            <Grid item xs={12} className={classes.checkboxGroup}>
                                <FormControl component="fieldset" style={{
                                    border: errorHourlyRate ? '1px solid rgba(255, 0, 0, 0.38)' : '1px solid rgba(0, 0, 0, 0.38)',
                                    padding: '0 10px',
                                    borderRadius: '4px',
                                    width: '100%'
                                }}>
                                    <FormLabel style={{
                                        color: errorHourlyRate ? '#e53935' : !values.hourlyCheckBox ? 'rgba(0, 0, 0, 0.38)' : 'initial',
                                        fontSize: '11px'
                                    }}
                                        component="legend">Hourly Rate Default Actions</FormLabel>
                                    <FormGroup aria-label="position">
                                        <FormControlLabel
                                            style={{ textAlign: 'left' }}
                                            disabled={currentSalary || !values.hourlyCheckBox}
                                            value="top"
                                            control={
                                                <GreenCheckbox />
                                            }
                                            label="Apply Hourly Rate to all hours logged"
                                            labelPlacement="end"
                                            checked={values.hourlyAllHours || false}
                                            name='hourlyAllHours'
                                            onChange={(event) => {
                                                onChange(event, setFieldValue, values)
                                            }}
                                        />
                                        <FormControlLabel
                                            style={{ textAlign: 'left' }}
                                            disabled={currentSalary || !values.hourlyCheckBox}
                                            value="top"
                                            control={
                                                <GreenCheckbox />
                                            }
                                            label="Only apply Hourly Rate when specific to the period logged by the user"
                                            labelPlacement="end"
                                            checked={values.hourlySpecificHours || false}
                                            name='hourlySpecificHours'
                                            onChange={(event) => onChange(event, setFieldValue, values)}
                                        />
                                    </FormGroup>
                                </FormControl>
                                {
                                    errorHourlyRate &&
                                    <p className={classes.textError}
                                        style={{ color: '#e53935' }}>This field is required.</p>
                                }
                            </Grid>
                            <Grid item xs={3} className={classes.checkBokLabel}>
                                <FormControlLabel
                                    disabled={currentSalary}
                                    className={classes.taxCheckbox}
                                    name='laborCommission'
                                    label='Labor Commission'
                                    checked={values.laborCommission || false}
                                    labelPlacement="start"
                                    control={
                                        <GreenCheckbox />
                                    }
                                    onChange={(event) => {
                                        // setFieldTouched('laborPercent', true);
                                        onChange(event, setFieldValue, values)
                                    }}
                                />
                            </Grid>
                            <Grid item xs={9}>
                                <NumberFormat
                                    customInput={TextField}
                                    suffix='%'
                                    thousandSeparator
                                    allowNegative={false}
                                    disabled={currentSalary || !values.laborCommission}
                                    fullWidth
                                    error={values.laborCommission && errors.laborPercent && touched.laborPercent}
                                    helperText={(values.laborCommission && errors.laborPercent && touched.laborPercent) && errors.laborPercent}
                                    label="Commission Rate"
                                    name="laborPercent"
                                    onBlur={() => {
                                        const salary = { ...touched, laborPercent: true }
                                        setFieldTouched('salaries', salary);
                                    }}
                                    onValueChange={val => {
                                        const salary = { ...values, laborPercent: val.value }
                                        setFieldValue('salaries', salary)
                                    }}
                                    value={values.laborPercent || ''}
                                    variant="outlined"
                                />
                            </Grid>

                            <Grid item xs={12} className={classes.checkboxGroup}>
                                <FormControl component="fieldset" style={{
                                    border: errorLaborCommission ? '1px solid rgba(255, 0, 0, 0.38)' : '1px solid rgba(0, 0, 0, 0.38)',
                                    padding: '0 10px',
                                    borderRadius: '4px',
                                    width: '100%'
                                }}>
                                    <FormLabel style={{
                                        color: errorLaborCommission ? '#e53935' : !values.laborCommission ? 'rgba(0, 0, 0, 0.38)' : 'initial',
                                        fontSize: '11px'
                                    }}
                                        component="legend">Labor Commission Default Actions</FormLabel>
                                    <FormGroup aria-label="position">
                                        <FormControlLabel
                                            style={{ textAlign: 'left' }}
                                            disabled={currentSalary || !values.laborCommission}
                                            value="top"
                                            control={
                                                <GreenCheckbox />
                                            }
                                            label="Apply Commission Rate to all assigned jobs"
                                            labelPlacement="end"
                                            checked={values.laborCommissionAllJobs || false}
                                            name='laborCommissionAllJobs'
                                            onChange={(event) => onChange(event, setFieldValue, values)}
                                        />
                                        <FormControlLabel
                                            style={{ textAlign: 'left' }}
                                            disabled={currentSalary || !values.laborCommission}
                                            value="top"
                                            control={
                                                <GreenCheckbox />
                                            }
                                            label="Only apply Commission Rate when selected on Job Completion screen"
                                            labelPlacement="end"
                                            checked={values.laborCommissionSpecificJobs || false}
                                            name='laborCommissionSpecificJobs'
                                            onChange={(event) => onChange(event, setFieldValue, values)}
                                        />
                                    </FormGroup>
                                </FormControl>
                                {
                                    errorLaborCommission &&
                                    <p className={classes.textError}
                                        style={{ color: '#e53935', fontSize: '11px' }}>This
                                        field is required.</p>
                                }
                            </Grid>
                            <Grid item xs={3} className={classes.checkBokLabel}>
                                <FormControlLabel
                                    disabled={currentSalary}
                                    className={classes.taxCheckbox}
                                    name={'saleCommission'}
                                    label='Sale Commission'
                                    checked={values.saleCommission || false}
                                    labelPlacement="start"
                                    control={
                                        <GreenCheckbox />
                                    }
                                    onChange={(event) => onChange(event, setFieldValue, values)}
                                />
                            </Grid>
                            <Grid item xs={9} container spacing={3}
                                style={{ paddingRight: 0, paddingLeft: 24 }}>
                                <div className={classes.saleCommissionGroup}>
                                    <NumberFormat
                                        customInput={TextField}
                                        suffix='%'
                                        thousandSeparator
                                        allowNegative={false}
                                        fullWidth
                                        error={values.saleCommission && errors.salePercentNewSale && touched.salePercentNewSale}
                                        helperText={(values.saleCommission && errors.salePercentNewSale && touched.salePercentNewSale) && errors.salePercentNewSale}
                                        label="New Customer"
                                        name="salePercentNewSale"
                                        onBlur={() => {
                                            const salary = { ...touched, salePercentNewSale: true }
                                            setFieldTouched('salaries', salary);
                                        }}
                                        onChange={event => {
                                            const salary = { ...values, salePercentNewSale: event.target.value }
                                            setFieldValue('salaries', salary)
                                        }}
                                        value={values.salePercentNewSale || ''}
                                        variant="outlined"
                                    />
                                    <div style={{ padding: 12 }}></div>
                                    <NumberFormat
                                        customInput={TextField}
                                        suffix='%'
                                        thousandSeparator
                                        allowNegative={false}
                                        fullWidth
                                        error={values.saleCommission && errors.salePercentRetSale && touched.salePercentRetSale}
                                        helperText={(values.saleCommission && errors.salePercentRetSale && touched.salePercentRetSale) && errors.salePercentRetSale}
                                        label="Returning Customer"
                                        name="salePercentRetSale"
                                        onBlur={() => {
                                            const salary = { ...touched, salePercentRetSale: true }
                                            setFieldTouched('salaries', salary);
                                        }}
                                        onChange={event => {
                                            const salary = { ...values, salePercentRetSale: event.target.value }
                                            setFieldValue('salaries', salary)
                                        }}
                                        value={values.salePercentRetSale || ''}
                                        variant="outlined"
                                    />
                                </div>
                            </Grid>
                            <Grid item xs={3} className={classes.checkBokLabel}>
                                <FormControlLabel
                                    disabled={currentSalary}
                                    className={classes.taxCheckbox}
                                    name={'upsaleCommission'}
                                    label='Upsale Commission'
                                    checked={values.upsaleCommission || false}
                                    labelPlacement="start"
                                    control={
                                        <GreenCheckbox />
                                    }
                                    onChange={(event) => onChange(event, setFieldValue, values)}
                                />
                            </Grid>
                            <Grid item xs={9}>
                                <NumberFormat
                                    customInput={TextField}
                                    suffix='%'
                                    thousandSeparator
                                    allowNegative={false}
                                    fullWidth
                                    error={values.upsaleCommission && errors.upsalePercent && touched.upsalePercent}
                                    helperText={(values.upsaleCommission && errors.upsalePercent && touched.upsalePercent) && errors.upsalePercent}
                                    label="Commission Rate"
                                    name="upsalePercent"
                                    onBlur={() => {
                                        const salary = { ...touched, upsalePercent: true }
                                        setFieldTouched('salaries', salary);
                                    }}
                                    onChange={event => {
                                        const salary = { ...values, upsalePercent: event.target.value }
                                        setFieldValue('salaries', salary)
                                    }}
                                    value={values.upsalePercent || ''}
                                    variant="outlined"
                                />
                            </Grid>
                            <Grid item xs={6}>
                                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                    <KeyboardDatePicker
                                        error={(
                                            (values.salaryCheckBox && values.salary) ||
                                            (values.hourlyCheckBox && values.hourly) ||
                                            (values.laborCommission && values.laborPercent) ||
                                            (values.saleCommission && values.salePercentNewSale && values.salePercentRetSale) ||
                                            (values.upsaleCommission && values.upsalePercent)
                                        ) && errors.startDate && touched.startDate}
                                        helperText={((
                                            (values.salaryCheckBox && values.salary) ||
                                            (values.hourlyCheckBox && values.hourly) ||
                                            (values.laborCommission && values.laborPercent) ||
                                            (values.saleCommission && values.salePercentNewSale && values.salePercentRetSale) ||
                                            (values.upsaleCommission && values.upsalePercent)
                                        ) && errors.startDate && touched.startDate) && errors.startDate}
                                        disabled={
                                            currentSalary ||
                                            !(
                                                (values.salaryCheckBox && values.salary) ||
                                                (values.hourlyCheckBox && values.hourly) ||
                                                (values.laborCommission && values.laborPercent) ||
                                                (values.saleCommission && values.salePercentNewSale && values.salePercentRetSale) ||
                                                (values.upsaleCommission && values.upsalePercent)
                                            )}
                                        minDate={false}
                                        maxDate={values.endDate ? (isDate(values.endDate) ? values.endDate : new Date(values.endDate)) : undefined}
                                        fullWidth
                                        inputVariant="outlined"
                                        label='Start Date'
                                        format="MM/dd/yyyy"
                                        value={values.startDate || null}
                                        onChange={date => {
                                            const salary = { ...values, startDate: date }
                                            setFieldValue('salaries', salary)
                                        }}
                                    />
                                </MuiPickersUtilsProvider>
                            </Grid>
                            <Grid item xs={6}>
                                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                    <KeyboardDatePicker
                                        error={(
                                            (values.salaryCheckBox && values.salary) ||
                                            (values.hourlyCheckBox && values.hourly) ||
                                            (values.laborCommission && values.laborPercent) ||
                                            (values.saleCommission && values.salePercentNewSale && values.salePercentRetSale) ||
                                            (values.upsaleCommission && values.upsalePercent)
                                        ) && errors.endDate && touched.endDate}
                                        helperText={((
                                            (values.salaryCheckBox && values.salary) ||
                                            (values.hourlyCheckBox && values.hourly) ||
                                            (values.laborCommission && values.laborPercent) ||
                                            (values.saleCommission && values.salePercentNewSale && values.salePercentRetSale) ||
                                            (values.upsaleCommission && values.upsalePercent)
                                        ) && errors.endDate && touched.endDate) && errors.endDate}
                                        disabled={!(
                                            (values.salaryCheckBox && values.salary) ||
                                            (values.hourlyCheckBox && values.hourly) ||
                                            (values.laborCommission && values.laborPercent) ||
                                            (values.saleCommission && values.salePercentNewSale && values.salePercentRetSale) ||
                                            (values.upsaleCommission && values.upsalePercent)
                                        )}
                                        fullWidth
                                        inputVariant="outlined"
                                        label='End Date'
                                        format="MM/dd/yyyy"
                                        value={values.endDate || null}
                                        InputAdornmentProps={{ position: "end" }}
                                        minDate={values.startDate ? (isDate(values.startDate) ? values.startDate : new Date(values.startDate)) : new Date().setDate((new Date()).getDate() + 1)}
                                        onChange={date => {
                                            const salary = { ...values, endDate: date }
                                            setFieldValue('salaries', salary)
                                        }}
                                    />
                                </MuiPickersUtilsProvider>
                            </Grid>
                        </Grid>
                    </CardContent>


                </Card>
            </Grid>
        </Grid>
    );
};


export default Compensation;
