import React, {useEffect, useState} from 'react'
import PropTypes from 'prop-types'
import {
    Modal, Card, CardContent, CardActions,
    Button, colors, Divider,
    CardHeader, TextField
} from '@material-ui/core'
import {makeStyles} from '@material-ui/styles'

const useStyles = makeStyles(theme => ({
    styleModal: {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        outline: 'none',
        boxShadow: theme.shadows[20],
        width: 600,
        maxHeight: '100%',
        overflowY: 'auto',
        maxWidth: '100%',
        [theme.breakpoints.down('sm')]: {
            width: 500
        },
    },
    conButton: {
        padding: '16px',
        justifyContent: 'space-between'
    },
    button: {
        flexShrink: '0',
        backgroundColor: colors.green[600],
        '&:hover': {
            backgroundColor: colors.green[900]
        },
    },
}))

const ConfirmTemplate = props => {
    const classes = useStyles();

    const {template, openConfirm, closeConfirm, onConfirm} = props;

    const [tem, setTem] = useState('');
    const [error, setError] = useState(false);

    useEffect(() => {
        setTem(template)
    }, [template])

    return (
        <Modal
            open={openConfirm}
            onClose={closeConfirm}
        >
            <Card className={classes.styleModal}>
                <CardHeader title="Enter a template name."/>
                <Divider/>
                <CardContent>
                    <TextField
                        error={error}
                        helperText={error ? 'Please enter template name' : ''}
                        onBlur={() => {
                            setError(!tem)
                        }}
                        fullWidth
                        variant='outlined'
                        value={tem}
                        onChange={e => setTem(e.target.value)}
                    />
                </CardContent>
                <Divider/>
                <CardActions className={classes.conButton}>
                    <Button
                        variant="contained"
                        size="large"
                        onClick={closeConfirm}>
                        Cancel
                    </Button>
                    <div>
                        <Button
                            variant="contained"
                            color="primary"
                            size="large"
                            onClick={() => {
                                if (!tem.trim()) {
                                    setError(true)
                                    return;
                                }
                                else{
                                    onConfirm(tem)
                                }

                            }}
                            className={classes.button}>
                            Save template
                        </Button>
                    </div>
                </CardActions>
            </Card>
        </Modal>
    )
}

ConfirmTemplate.propTypes = {
    template: PropTypes.string,
    openConfirm: PropTypes.bool.isRequired,
    closeConfirm: PropTypes.func.isRequired,
    onConfirm: PropTypes.func.isRequired
}

ConfirmTemplate.defaultProps = {
    closeConfirm: () => {
    },
    onConfirm: () => {
    }
}

export default ConfirmTemplate;
