import { Button, Card, CardActions, CardContent, CardHeader, colors, Divider, Modal, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import clsx from 'clsx';
import React, { useMemo } from 'react';

const useStyles = makeStyles(theme => ({
  styleModal: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    outline: 'none',
    boxShadow: theme.shadows[20],
    width: 600,
    maxHeight: '100%',
    overflowY: 'auto',
    maxWidth: '100%',
    [theme.breakpoints.down('sm')]: {
      width: 500
    },
  },
  largeModal: {
    width: 800
  },
  message: {
    fontSize: 16
  },
  conButton: {
    padding: '16px',
    justifyContent: 'flex-end'
  },
  button: {
    flexShrink: '0',
    backgroundColor: colors.green[600],
    '&:hover': {
      backgroundColor: colors.green[900]
    },
  },
}));

const ConfirmRescheduleModal = props => {

  const { open, date, employees, onConfirm, onClose } = props;

  const classes = useStyles();

  const message = useMemo(() => {
    const msg = `You are rescheduling this route to ${date}. `;
    if (employees.length === 0) {
      return msg + 'Do you want to continue?';
    }

    return (
      <>
        {msg}The following employees now have conflicting schedules
        <ul style={{ paddingLeft: 20 }}>
          {employees.map(employee => <li>{employee.firstName} {employee.lastName}</li>)}
        </ul>
      </>
    );
  }, [date, employees]);

  return (
    <Modal
      open={open}
      onClose={onClose}
    >
      <Card className={clsx(classes.styleModal, employees.length > 0 ? classes.largeModal : null)}>
        <CardHeader title="Warning" />
        <Divider />
        <CardContent>
          <Typography className={classes.message}>{message}</Typography>
        </CardContent>
        <Divider />
        <CardActions className={classes.conButton}>
          <Button
            variant="contained"
            size="large"
            onClick={onClose}
          >
            Cancel
          </Button>
          {
            employees.length === 0
              ? <Button
                  variant="contained"
                  color="primary"
                  size="large"
                  className={classes.button}
                  onClick={() => onConfirm(false)}
                >
                  Continue
                </Button>
              : (
                <>
                  <Button
                    variant="contained"
                    color="primary"
                    size="large"
                    className={classes.button}
                    onClick={() => onConfirm(false)}
                  >
                    Keep Employees On Old Route
                  </Button>
                  <Button
                    variant="contained"
                    color="primary"
                    size="large"
                    className={classes.button}
                    onClick={() => onConfirm(true)}
                  >
                    Move Employees To This Route
                  </Button>
                </>
              )
          }
          
        </CardActions>
      </Card>
    </Modal>
  );
};

export default ConfirmRescheduleModal;
