import React, { useEffect, useRef, useState } from 'react'
import PropTypes from 'prop-types'
import NumberFormat from 'react-number-format'
import { makeStyles } from '@material-ui/styles'
import { Alert } from '@material-ui/lab'
import {
    Modal, Button, colors, Collapse,
    Card, CardContent, CardActions, IconButton,
    Typography, Snackbar, Grid, TextField,
    CircularProgress, CardHeader, Divider, FormControlLabel
} from '@material-ui/core'
import { ExpandMore } from '@material-ui/icons'
import {FormErrorCallback, GreenCheckbox, AddressInput} from 'components'
import { Formik } from 'formik'
import * as yup from 'yup'
import { initAddress } from 'mockup/JobAddress'
import axios from 'utils/axios'
import apiConfig from 'apiConfig'
import {formatPhoneNumber} from "../../../../../utils/formatNumber";

const useStyles = makeStyles(theme => ({
    root: {},
    styleModal: {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        outline: 'none',
        boxShadow: theme.shadows[20],
        width: 720,
        maxHeight: '90%',
        overflowY: 'auto',
        maxWidth: '100%',
        [theme.breakpoints.down('sm')]: {
            width: 500
        },
    },
    checkbox: {
        margin: 0
    },
    textCheckbox: {
        margin: 0,
        '& span': {
            padding: '1px 0',
            '&:last-child': {
                whiteSpace: 'nowrap'
            }
        }
    },
    actionCard: {
        display: 'flex',
        justifyContent: 'flex-end'
    },
    btn: {
        color: theme.palette.white,
        backgroundColor: colors.green[600],
        '&:hover': {
            backgroundColor: colors.green[900]
        },
    },
    btnCenter: {
        marginTop: '-16px',
    },
    btnBox: {
        display: 'flex',
        justifyContent: "flex-end",
        alignItems: "center",
    },
    contactTypo: {
        fontSize: '16px',
        fontWeight: 500
    },
    collapse: {
        width: '100%',
        padding: '8px'
    },
    iconBtn: {
        padding: 0,
        marginTop: 12
    },
    inputControl: {
        minHeight: '70px'
    }
}));

const validationSchema = yup.object().shape({
    name: yup.string().nullable().required('Required.'),
    address: yup.object({
        address1: yup.string().nullable().required('Required.'),
        city: yup.string().nullable().required('Required.'),
        zipCode: yup.string().nullable().required('Required.')
            .matches(/(^\d{5}$)|(^\d{5}-\d{4}$)/, 'Zip code is invalid.'),
    }),
    contacts: yup.array()
        .of(yup.object({
            name: yup.string().nullable(),//.required('Required.'),
            // lastName: yup.string().required('Required.'),
            // phoneType: yup.string().required('Required.'),
            phone: yup.string().nullable()//.required('Required.')
                .matches(/\W\d{3}\W\s\w{3}\W\w{4}$|\W\d{3}\W\s\w{3}\W\w{4}x\d{1,6}|\W\d{3}\W\s\w{3}\W\w{4}x/, 'Invalid phone number.'),
            email: yup.string().nullable()
                .email('Email is invalid.')
        }))/*,
    qboTaxExempt: yup.string().nullable().when('isTaxExempt', {
        is: true,
        then: yup.string().required('Required.')
    }),  */
})

let mounted = false;
const AddJobAddress = props => {

    const classes = useStyles();

    const { open, onClose, company, customer, taxGroups, updatedAddress, add, update, jobAddresses , taxExempt } = props;
    const { id, phoneTypes, states, address, contacts } = customer;
    let contactsFilter = contacts.filter(contact => contact.isPrimary);
    const primaryContact = contactsFilter && contactsFilter.length ? contactsFilter.map(contact => {
        return {
            ...contact, phones: contact.phones.map(phone => {
                    return {...phone, phoneNumber: formatPhoneNumber(phone.phoneNumber)}
                }
            )
        }
    }) : [];

    const addressInputRef = useRef();
    const form = useRef();

    const [openSnack, setOpenSnack] = useState(false);
    const [isSuccess, setIsSuccess] = useState({ failed: false, msg: '' });
    const [jobAddress, setJobAddress] = useState({ ...initAddress, customerId: id, address: {}, states });
    const [saving, setSaving] = useState(false);
    const [isCopy, setIsCopy] = useState({ address: false, contacts: false });
    const [expandContacts, setExpandContacts] = useState(0);
    const [isLookupAddress, setIsLookupAddress] = useState(false);
    const [lookupPending, setLookupPending] = useState(false);
    const [isTaxExempt, setIsTaxExempt] = useState(false);

    const onChangeContact = (values, setFieldValue, event, index) => {
        const { name, value, type } = event.target;
        const contacts = [...values.contacts];
        if (type === "checkbox") {
            contacts[index] = { ...contacts[index], [name]: event.target.checked };
        } else {
            contacts[index] = { ...contacts[index], [name]: name === 'phone' ? value?.trim() : value };
        }
        setFieldValue('contacts', contacts);
    }
    const addJobsite = values => {
        const data = {
            ...values,
            ...values.address,
            name: values.name,
            stateId: values.address.state?.id,
            taxGroupId: values.taxGroupId === 'pending' ? null : values.taxGroupId,
            qboTaxExempt: values.qboTaxExempt === '' ? 0 : values.qboTaxExempt
        };
        axios.post(apiConfig.url.BASE_URL + apiConfig.url.CUSTOMER_JOB_ADDRESSES_ADD, data)
            .then(res => {
                if (res.data) {
                    if (lookupPending) {
                        axios.post(apiConfig.url.BASE_URL + apiConfig.url.QBO_ADDRESS_LOOKUP + res.data)
                            .catch(err => console.error(err));
                    }
    
                    onClose();
                    setIsCopy({ address: false, contacts: false })
                    setJobAddress({ ...initAddress, customerId: id, address: {}, states })
                    setIsLookupAddress(false);
                    setLookupPending(false);
                    setIsSuccess({ failed: false, msg: 'Add successfully.' });
                    setOpenSnack(true);
                    add({ ...data, id: res.data })
                } else {
                    setIsSuccess({ failed: true, msg: 'An error occurred, please try again later' });
                    setOpenSnack(true);
                }
            })
            .catch(() => {
                setIsSuccess({ failed: true, msg: 'An error occurred, please try again later' });
                setOpenSnack(true);
            })
            .finally(() => setSaving(false))
    }
    const updateJobsite = (values) => {
        const data = {
            ...values,
            ...values.address,
            name: values.name,
            stateId: values.address.state?.id,
            taxGroupId: values.taxGroupId === 'pending' ? null : values.taxGroupId,
            qboTaxExempt: values.qboTaxExempt === '' ? 0 : values.qboTaxExempt
        };
        axios.put(apiConfig.url.BASE_URL + apiConfig.url.CUSTOMER_JOB_ADDRESSES_UPDATE, data)
            .then(() => {
                onClose();
                setIsCopy({ address: false, contacts: false })
                setJobAddress({ ...initAddress, customerId: id, address: {}, states });
                setIsLookupAddress(false);
                setLookupPending(false);
                setIsSuccess({ failed: false, msg: 'Record Updated' });
                setOpenSnack(true);
                update(data);
            })
            .catch(() => {
                setIsSuccess({ failed: true, msg: 'An error occurred, please try again later' });
                setOpenSnack(true);
            })
            .finally(() => setSaving(false))
    }
    
    const preverify = values => {
        return new Promise((resolve, reject) => {
            if (values.address && jobAddresses.find(x => {
                return x.address != null &&
                x.address.id !== values.id &&
                x.address.address1?.trim().toLowerCase() === values.address.address1?.trim().toLowerCase() &&
                x.address.address2?.trim().toLowerCase() === values.address.address2?.trim().toLowerCase() &&
                x.address.city?.trim().toLowerCase() === values.address.city?.trim().toLowerCase() &&
                x.address.stateId?.trim().toLowerCase() === values.address.state?.id?.trim().toLowerCase() &&
                x.address.zipCode?.trim().toLowerCase() === values.address.zipCode?.trim().toLowerCase()
            })) {
                setIsSuccess({ failed: true, msg: 'Address already exists' });
                setOpenSnack(true);
                return resolve(false);
            }
    
            return resolve(true);
        });
    }
    const copyBillingAddress = (values) => {
        setIsCopy({ ...isCopy, address: true });

        setJobAddress({
            ...jobAddress,
            taxGroupId: address?.taxGroupId ||'',
            name:  address?.name || '',
            address: {
                address1: address?.address1 || '',
                address2: address?.address2 ||  '',
                city: address?.city || '',
                state: {
                    id:  address?.state?.id || '',
                    name:  address?.state?.name || '',
                    code:  address?.state?.code || '',
                },
                zipCode: address?.zipCode || ''
            },
            states
        })
    }

    const isDisabledTaxGroup = taxGroup => {
        if (company?.useQuickbooksOnline) return taxGroup.qboId == null;
        else return taxGroup.qboId != null;
    };

    
    const copyBillingContacts = (values, lstContacts, touched) => {

        const cts = primaryContact.map((c, index) => {
            return {
                name: `${c?.firstName || ''}${c.lastName ? ' ' + c.lastName : ''}`,
                phone: (c.phones && c.phones[0] && c.phones[0].phoneNumber) || '',
                phoneTypeId: (c.phones && c.phones[0] && c.phones[0].typeId) || '',
                email: c?.email || '',
                isSendText: (c.phones && c.phones[0] && c.phones[0].isSendText) || false,
                isPrimary: c?.isPrimary || false
            }
        });

        setIsCopy({ ...isCopy, contacts: true });
        setJobAddress({ ...values, contacts: cts })
    }

    const automaticLookup = setFieldValue => {
        if (jobAddress?.id) {
            setIsLookupAddress(true);
            axios.post(apiConfig.url.BASE_URL + apiConfig.url.QBO_ADDRESS_LOOKUP + jobAddress.id)
                .then(res => {
                    setLookupPending(true);
                    setFieldValue('taxGroupId', 'pending');
                    setIsSuccess({ failed: false, msg: 'Address tax group lookup requested' });
                    setOpenSnack(true);
                })
                .catch(() => {
                    setIsSuccess({ failed: true, msg: 'An error occurred, please try again later' });
                    setOpenSnack(true);
                })
                .finally(() => setIsLookupAddress(false))
            setIsLookupAddress(false);
        } else {
            setLookupPending(true);
            setFieldValue('taxGroupId', 'pending');
            setIsSuccess({ failed: false, msg: 'Address tax group lookup requested' });
            setOpenSnack(true);
        }
    }

    const handleClose = () => {
        setIsCopy({ address: false, contacts: false })
        setJobAddress({ ...initAddress, customerId: id })
        setIsLookupAddress(false);
        setLookupPending(false);
        onClose();
    }

    useEffect(() => {
        mounted = true;
        if (updatedAddress && mounted) {
            setJobAddress({
                ...updatedAddress,
                customerId: id,
                name: updatedAddress.name ?? '',
                address: {
                    address1: updatedAddress.address1 ?? '',
                    address2: updatedAddress.address2 ?? '',
                    city: updatedAddress.city ?? '',
                    state: { id: updatedAddress.stateId ?? '' },
                    zipCode: updatedAddress.zipCode ?? '',
                    latitude: updatedAddress.latitude ?? updatedAddress.Latitude,
                    longitude: updatedAddress.longitude ?? updatedAddress.Longitude
                },
                states
            })
        } else if (!updatedAddress && mounted) {
            setJobAddress({ ...initAddress, customerId: id, address: {}, states });
        }

        if (updatedAddress?.id) {
            axios.get(
                apiConfig.url.BASE_URL + apiConfig.url.CUSTOMER_JOB_ADDRESS.replace('{addressId}', updatedAddress.id),
                {
                    params: { customerId: id }
                }
            )
                .then(res => {
                    if (mounted && form?.current && res.data) {
                        form.current.setFieldValue('qboTaxExempt', res.data.qboTaxExempt == 0 ? '' : res.data.qboTaxExempt);
                        setIsTaxExempt(res.data.qboTaxExempt != 0);
                        form.current.setFieldValue('isTaxExempt', isTaxExempt);
                        form.current.setFieldValue('taxGroupId', res.data.taxGroupId);
                    }
                })
        }

        return () => mounted = false;
    }, [updatedAddress, open])

    return (
        <>
            <Modal open={open} onClose={handleClose}>
                <div>
                    <Formik
                        enableReinitialize
                        initialValues={jobAddress}
                        validationSchema={validationSchema}
                        innerRef={f => form.current = f}
                        onSubmit={values => {
                            preverify(values).then(res => {
                                if (res) {
                                    setSaving(true);
                                    const { isValid, forceSave, verifyFn } = addressInputRef.current;
                                    if (updatedAddress) {
                                        if (isValid || forceSave) {
                                            updateJobsite(values);
                                        } else {
                                            verifyFn(true).then(data => data ? updateJobsite(data) : setSaving(false))
                                        }
                                    } else {
                                        if (isValid || forceSave) {
                                            addJobsite(values);
                                        } else {
                                            verifyFn(true).then(data => data ? addJobsite(data) : setSaving(false))
                                        }
                                    }
                                }
                            });
                        }}
                    >
                        {({
                            values, errors, touched, setErrors,
                            setFieldValue, setFieldTouched, setTouched,
                            handleSubmit, submitCount, isValid
                        }) => (
                            <form onSubmit={handleSubmit}>
                                <FormErrorCallback
                                    submitCount={submitCount}
                                    isValid={isValid}
                                    onSubmissionError={() => {
                                        console.log(errors)
                                        setIsSuccess({ failed: true, msg: 'Please fill up the fields.' })
                                        setOpenSnack(true)
                                    }}
                                />
                                <Card className={classes.styleModal}>
                                    <CardHeader title={updatedAddress ? 'Edit Job Address' : 'Add Job Address'} />
                                    <Divider />
                                    <CardContent>
                                        <Grid container spacing={3}>
                                            <Grid item xs={12}>
                                                <Grid container spacing={3} alignItems="center">
                                                    <Grid item style={{ flex: 1 }}>
                                                        <TextField
                                                            fullWidth
                                                            error={errors.name && touched.name ? true : false}
                                                            helperText={errors.name && touched.name && errors.name}
                                                            label='Address Name'
                                                            name='name'
                                                            variant='outlined'
                                                            value={values.name || ''}
                                                            onChange={event => {
                                                                setFieldValue('name', event.target.value)
                                                            }}
                                                            onBlur={() => setFieldTouched('name')}
                                                        />
                                                    </Grid>
                                                    <Grid item>
                                                        <Button
                                                            fullWidth
                                                            variant='contained'
                                                            className={classes.btn}
                                                            disabled={isCopy.address}
                                                            onClick={() => copyBillingAddress(values)}>
                                                            Copy billing address
                                                        </Button>
                                                    </Grid>
                                                </Grid>
                                                
                                            </Grid>
                                            {/* <Grid item xs={4} className={classes.btnBox}>
                                                
                                            </Grid> */}
                                            <Grid item xs={12}>
                                                <AddressInput
                                                    ref={addressInputRef}
                                                    errors={errors}
                                                    values={values}
                                                    touched={touched}
                                                    setFieldValue={setFieldValue}
                                                    setFieldTouched={setFieldTouched}
                                                    preverify={preverify}
                                                />
                                            </Grid>
                                            <Grid item xs={12}>
                                                <Grid container spacing={3} alignItems="center">
                                                    <Grid item style={{ flex: 1 }}>
                                                        <TextField
                                                            fullWidth
                                                            label='Tax Group'
                                                            name='taxGroupId'
                                                            variant='outlined'
                                                            select
                                                            SelectProps={{ native: true }}
                                                            value={isTaxExempt ? '' : (values.taxGroupId || '')}
                                                            disabled={isLookupAddress || lookupPending || isTaxExempt}
                                                            onChange={event => setFieldValue('taxGroupId', event.target.value)}>
                                                            <option value=''></option>
                                                            {lookupPending && <option value="pending">Lookup Pending</option>}
                                                            {taxGroups.map(tax => (
                                                                <option key={tax.id} value={tax.id} disabled={tax.disabled}>
                                                                    {tax.name}
                                                                </option>
                                                            ))}
                                                        </TextField>
                                                    </Grid>
                                                    {company?.useQuickbooksOnline && (
                                                        <Grid item>
                                                            <Button
                                                                fullWidth
                                                                variant='contained'
                                                                className={classes.btn}
                                                                disabled={isLookupAddress || lookupPending || isTaxExempt}
                                                                onClick={() => automaticLookup(setFieldValue)}>
                                                                Automatic Lookup
                                                            </Button>
                                                        </Grid>
                                                    )}
                                                    {company?.useQuickbooksOnline && isLookupAddress && <CircularProgress size={30} />}
                                                </Grid>
                                            </Grid>
                                            {company?.useQuickbooksOnline && ( 
                                            <Grid item xs={12}>
                                                <Grid container alignItems="center">
                                                    <Grid item xs={12} style={{ paddingBottom: '12px'}}>
                                                        <FormControlLabel
                                                            className={classes.textCheckbox}
                                                            // labelPlacement={'bottom'}
                                                            name='isTaxExempt'
                                                            checked={isTaxExempt}
                                                            onChange={event => {
                                                                setIsTaxExempt(event.target.checked);
                                                                setFieldValue('qboTaxExempt', '')
                                                                setFieldValue('taxGroupId', '')
                                                                setFieldValue('isTaxExempt', event.target.checked)
                                                            }}
                                                            control={
                                                                <GreenCheckbox />
                                                            }
                                                            label='Tax Exempt'
                                                        />
                                                    </Grid>
                                                    <Grid item>
                                                        <TextField
                                                            fullWidth
                                                            error={isTaxExempt && errors.qboTaxExempt && touched.qboTaxExempt ? true : false}
                                                            helperText={isTaxExempt && errors.qboTaxExempt && touched.qboTaxExempt && errors.qboTaxExempt}
                                                            label='Tax Exempt Reasons'
                                                            name='qboTaxExempt'
                                                            variant='outlined'
                                                            select
                                                            SelectProps={{ native: true }}
                                                            value={values.qboTaxExempt || ''}
                                                            disabled={!isTaxExempt}
                                                            onChange={event => setFieldValue('qboTaxExempt', event.target.value)}>
                                                            <option value=''></option>
                                                            {taxExempt.map(tax => (
                                                                <option key={tax.id} value={tax.id}>
                                                                    {tax.name}
                                                                </option>
                                                            ))}
                                                        </TextField>
                                                    </Grid>
                                                </Grid>
                                            </Grid>
                                            )}

                                            <Grid item xs={12}>
                                                <Typography className={classes.contactTypo}>Add Primary Contact</Typography>
                                            </Grid>
                                            <Grid item xs={12} className={classes.btnBox}>
                                                <Button variant='contained' className={[classes.btn, classes.btnCenter]}
                                                    disabled={contacts.length === 0 || isCopy.contacts}
                                                    onClick={() => copyBillingContacts(values, values.contacts, touched.contacts)}>
                                                    Copy billing contacts
                                                </Button>
                                            </Grid>
                                            {values.contacts && values.contacts.map((contact, index) => (

                                                contact.isPrimary &&

                                                <React.Fragment key={`contact-${index}`}>
                                                    {values.contacts.length > 1 && (
                                                        <Grid item xs={1}>
                                                            <IconButton
                                                                onClick={() => setExpandContacts(expandContacts === index ? null : index)}
                                                                className={classes.iconBtn}>
                                                                <ExpandMore />
                                                            </IconButton>
                                                        </Grid>
                                                    )}
                                                    <Grid item xs={values.contacts.length > 1 ? 5 : 6}>
                                                        <TextField
                                                            fullWidth
                                                            error={
                                                                errors.contacts && errors.contacts[index] && errors.contacts[index].name &&
                                                                    touched.contacts && touched.contacts[index] && touched.contacts[index].name ?
                                                                    true : false
                                                            }
                                                            helperText={
                                                                errors.contacts && errors.contacts[index] && errors.contacts[index].name &&
                                                                touched.contacts && touched.contacts[index] && touched.contacts[index].name &&
                                                                errors.contacts[index].name
                                                            }
                                                            label='Full Name'
                                                            name='name'
                                                            variant='outlined'
                                                            value={contact.name || ''}
                                                            onChange={event => onChangeContact(values, setFieldValue, event, index)}
                                                            onBlur={() => {
                                                                const contacts = touched.contacts ? [...touched.contacts] : [];
                                                                contacts[index] = {
                                                                    ...contacts[index],
                                                                    name: true
                                                                };
                                                                setFieldTouched('contacts', contacts);
                                                            }}
                                                        />
                                                    </Grid>
                                                    <Grid item xs={values.contacts.length > 1 ? 5 : 6}>
                                                        <TextField
                                                            fullWidth
                                                            error={
                                                                errors.contacts && errors.contacts[index] && errors.contacts[index].email &&
                                                                    touched.contacts && touched.contacts[index] && touched.contacts[index].email ?
                                                                    true : false
                                                            }
                                                            helperText={
                                                                errors.contacts && errors.contacts[index] && errors.contacts[index].email &&
                                                                touched.contacts && touched.contacts[index] && touched.contacts[index].email &&
                                                                errors.contacts[index].email
                                                            }
                                                            label='Email'
                                                            name='email'
                                                            variant='outlined'
                                                            value={contact.email || ''}
                                                            onChange={event => onChangeContact(values, setFieldValue, event, index)}
                                                            onBlur={() => {
                                                                const contacts = touched.contacts ? [...touched.contacts] : [];
                                                                contacts[index] = {
                                                                    ...contacts[index],
                                                                    email: true
                                                                };
                                                                setFieldTouched('contacts', contacts);
                                                            }}
                                                        />
                                                    </Grid>
                                                    <Collapse in={expandContacts === index} timeout='auto'
                                                        unmountOnExit
                                                        className={classes.collapse}>
                                                        <Grid container spacing={3}>
                                                            {values.contacts.length > 1 &&
                                                                <Grid item xs={1}></Grid>}
                                                            <Grid item xs={12}
                                                                sm={values.contacts.length > 1 ? 3 : 4}>
                                                                <TextField
                                                                    fullWidth
                                                                    error={
                                                                        errors.contacts && errors.contacts[index] && errors.contacts[index].phoneTypeId &&
                                                                            touched.contacts && touched.contacts[index] && touched.contacts[index].phoneTypeId ?
                                                                            true : false
                                                                    }
                                                                    helperText={
                                                                        errors.contacts && errors.contacts[index] && errors.contacts[index].phoneTypeId &&
                                                                        touched.contacts && touched.contacts[index] && touched.contacts[index].phoneTypeId &&
                                                                        errors.contacts[index].phoneTypeId
                                                                    }
                                                                    label='Phone Type'
                                                                    name='phoneTypeId'
                                                                    variant='outlined'
                                                                    select
                                                                    SelectProps={{ native: true }}
                                                                    value={contact.phoneTypeId || ''}
                                                                    onChange={event => onChangeContact(values, setFieldValue, event, index)}
                                                                    onBlur={(event) => {
                                                                        const contacts = touched.contacts ? [...touched.contacts] : [];
                                                                        contacts[index] = {
                                                                            ...contacts[index],
                                                                            phoneTypeId: event.target.value
                                                                        };
                                                                        setFieldTouched('contacts', contacts);
                                                                    }}
                                                                >
                                                                    <option value=''></option>
                                                                    {phoneTypes.map(type => (
                                                                        <option key={type.id} value={type.id}>
                                                                            {type.type}
                                                                        </option>
                                                                    ))}
                                                                </TextField>
                                                            </Grid>
                                                            <Grid item xs={10} sm={6}>
                                                                <NumberFormat
                                                                    customInput={TextField}
                                                                    format='(###) ###-####x######'
                                                                    fullWidth
                                                                    error={
                                                                        errors.contacts && errors.contacts[index] && errors.contacts[index].phone &&
                                                                            touched.contacts && touched.contacts[index] && touched.contacts[index].phone ?
                                                                            true : false
                                                                    }
                                                                    helperText={
                                                                        errors.contacts && errors.contacts[index] && errors.contacts[index].phone &&
                                                                        touched.contacts && touched.contacts[index] && touched.contacts[index].phone &&
                                                                        errors.contacts[index].phone
                                                                    }
                                                                    label='Phone Number'
                                                                    name='phone'
                                                                    variant='outlined'
                                                                    value={contact.phone || ''}
                                                                    onChange={event => onChangeContact(values, setFieldValue, event, index)}
                                                                    onBlur={() => {
                                                                        const contacts = touched.contacts ? [...touched.contacts] : [];
                                                                        contacts[index] = {
                                                                            ...contacts[index],
                                                                            phone: true
                                                                        };
                                                                        setFieldTouched('contacts', contacts);
                                                                    }}
                                                                />
                                                            </Grid>
                                                            <Grid item xs={2} style={{
                                                                display: 'flex',
                                                                justifyContent: 'center'
                                                            }}>
                                                                <FormControlLabel
                                                                    className={classes.textCheckbox}
                                                                    labelPlacement={'bottom'}
                                                                    name='isSendText'
                                                                    checked={contact.isSendText}
                                                                    onChange={event => {
                                                                        onChangeContact(values, setFieldValue, event, index)
                                                                        const contacts = touched.contacts ? [...touched.contacts] : [];
                                                                        contacts[index] = {
                                                                            ...contacts[index],
                                                                            isSendText: true
                                                                        };
                                                                        setFieldTouched('contacts', contacts);
                                                                    }}
                                                                    control={
                                                                        <GreenCheckbox />
                                                                    }
                                                                    label='Send Text'
                                                                />
                                                            </Grid>
                                                        </Grid>
                                                    </Collapse>
                                                </React.Fragment>

                                            ))}
                                        </Grid>
                                    </CardContent>
                                    <Divider />
                                    <CardActions className={classes.actionCard}>
                                        <Button
                                            onClick={handleClose}
                                            variant="contained">
                                            Close
                                        </Button>
                                        <Button
                                            disabled={saving}
                                            type='submit'
                                            variant="contained"
                                            color="primary"
                                            className={classes.btn}>
                                            Save
                                        </Button>
                                        {saving && <CircularProgress size={30} />}
                                    </CardActions>
                                </Card>
                            </form>
                        )}
                    </Formik>
                </div>
            </Modal>

            <Snackbar
                open={openSnack}
                anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
                autoHideDuration={2000}
                onClose={() => setOpenSnack(false)}>
                <Alert
                    elevation={6} variant="filled" severity={isSuccess.failed ? 'error' : 'success'}>
                    <Typography
                        color="inherit"
                        variant="h6">
                        {isSuccess.msg}
                    </Typography>
                </Alert>
            </Snackbar>
        </>
    )
}

AddJobAddress.propTypes = {
    type: PropTypes.string,
    open: PropTypes.bool,
    onClose: PropTypes.func,
    customer: PropTypes.object,
    taxGroups: PropTypes.arrayOf(Object),
    address: PropTypes.object,
    add: PropTypes.func,
    update: PropTypes.func
}
AddJobAddress.defaultProps = {
    open: false,
    onClose: () => { }
};

export default AddJobAddress;
