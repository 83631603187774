import { data } from "azure-maps-control";
import "azure-maps-drawing-tools/dist/atlas-drawing.min.css";
import React, { useContext, useEffect } from "react";
import { AzureMapsContext } from "react-azure-maps";
import MapComponent from "./MapComponent";

let isFirstTimeCoordinatesChanges = true;

const MapController = (props) => {
  const { coordinates } = props;
  const { mapRef, isMapReady } = useContext(AzureMapsContext);

  useEffect(() => {
    if (
      isFirstTimeCoordinatesChanges &&
      mapRef &&
      isMapReady &&
      coordinates.length > 0
    ) {
      isFirstTimeCoordinatesChanges = false;
      const positions = coordinates
        .filter((x) => x.latitude != null && x.longitude != null)
        .reduce(
          (res, coord) => [...res, [coord.longitude, coord.latitude]],
          []
        );
      const bbox = data.BoundingBox.fromPositions(positions);

      if (bbox && bbox[0] === bbox[2] && bbox[1] === bbox[3]) {
        mapRef.setCamera({
          center: [bbox[0], bbox[1]],
          zoom: 10,
        });
      } else {
        mapRef.setCamera({
          bounds: bbox,
          padding: 50,
        });
      }
    }
  }, [mapRef, isMapReady, coordinates]);

  useEffect(() => () => (isFirstTimeCoordinatesChanges = true), []);

  return <MapComponent {...props} />;
};

export default MapController;
