import React from 'react'
import PropTypes from 'prop-types'
import { makeStyles } from '@material-ui/styles'
import {
    Modal, Card, CardHeader, CardContent, CardActions,
    Typography, Button, Divider,
    colors,
} from '@material-ui/core'
import moment from "moment";

const useStyles = makeStyles(theme => ({
    styleModal: {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        outline: 'none',
        boxShadow: theme.shadows[20],
        width: 500,
        maxHeight: '100%',
        overflowY: 'auto',
        maxWidth: '100%',
        [theme.breakpoints.down('sm')]: {
            width: 500
        },
    },
    conButton: {
        padding: '16px',
        justifyContent: 'flex-end'
    },
    buttonSave: {
        flexShrink: '0',
        backgroundColor: colors.green[600],
        '&:hover': {
            backgroundColor: colors.green[900]
        },
    },
}))

const ModalForceSave = ({ open, onClose, address, forceSave, cancelSave }) => {
    const classes = useStyles();
    return (
        <Modal open={open} onClose={onClose}>
            <Card className={classes.styleModal}>
                <CardHeader title="The selected address cannot be verified. Would you like to the address anyway?" />
                <CardContent>
                    <Typography style={{fontSize: '16px', textAlign: 'center'}}>
                        {`${address.address1 || ''}`}<br />
                        {`${address.city || ''}, ${address.state && (address.state.code || '')} ${address.zipCode || ''}`}
                    </Typography>
                </CardContent>
                <Divider />
                <CardActions className={classes.conButton}>
                    <Button
                        variant="contained"
                        size="large"
                        onClick={cancelSave}
                    >
                        No
                    </Button>
                    <Button
                        variant="contained"
                        color="primary"
                        size="large"
                        onClick={forceSave}
                        className={classes.buttonSave}>
                        Yes
                    </Button>
                </CardActions>
            </Card>
        </Modal>
    )
}

ModalForceSave.propTypes = {
    open: PropTypes.bool,
    onClose: PropTypes.func,
    date: PropTypes.array,
    forceUpdate: PropTypes.func
}

export default ModalForceSave;
