import React, {useEffect, useState} from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/styles';
import {
  Card,
  CardContent,
  Grid,
  Typography,
  Avatar
} from '@material-ui/core';
import { FormatListNumbered } from '@material-ui/icons';
import axios from "../../../../utils/axios";
import apiConfig from "../../../../apiConfig";

const useStyles = makeStyles(theme => ({
  root: {
    height: '100%'
  },
  content: {
    alignItems: 'center',
    display: 'flex'
  },
  title: {
    fontWeight: 700
  },
  avatar: {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.primary.contrastText,
    height: 56,
    width: 56
  },
  icon: {
    height: 32,
    width: 32
  },
  progress: {
    marginTop: theme.spacing(3)
  }
}));

const BidsTodo = props => {
  const { className, ...rest } = props;
  const [bidCount, setBidCount] = useState(0);

  const classes = useStyles();
useEffect(() => {
  axios.get(apiConfig.url.BASE_URL + apiConfig.url.DASHBOARD_BILL_TO_DO_COUNT)
      .then(res => {
        setBidCount(res.data);
      })
}, [])
  return (
    <Card
      {...rest}
      className={clsx(classes.root, className)}
    >
      <CardContent>
        <Grid container justifyContent="space-between">
          <Grid item>
            <Typography
              className={classes.title}
              color="textSecondary"
              gutterBottom
              variant="body2">
              Bids To Do
            </Typography>
            <Typography variant="h3">{bidCount}</Typography>
          </Grid>
          <Grid item>
            <Avatar className={classes.avatar}>
              <FormatListNumbered className={classes.icon} />
            </Avatar>
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  );
};

BidsTodo.propTypes = {
  className: PropTypes.string
};

export default BidsTodo;
