import React, {useState, useEffect} from 'react'
import {makeStyles} from '@material-ui/styles'
import {
    Typography,
    Grid,
    CircularProgress,
    TextField,
    FormControlLabel,
    Button,
    colors,
} from '@material-ui/core'
import SaleFollowUpsCard from './components'
import salesData from 'mockup/salesData'
import axios from "../../../../utils/axios";
import apiConfig from "../../../../apiConfig";
import {GreenRadio, Pagination} from 'components';
import SaleFollowUpsCardOld from './components/SaleFollowUpsCardOld'
import {useDispatch} from "react-redux";

const useStyles = makeStyles(theme => ({
    root: {},
    tableCell: {
        cursor: 'pointer',
        '& p': {
            whiteSpace: 'nowrap',
            fontWeight: 500
        },
        '& svg': {
            fontSize: '14px',
            transition: 'all 100ms ease-out'
        }
    },
    conTable: {
        overflowX: 'auto'
    },
    customSearch: {
        marginTop: theme.spacing(2),
        marginBottom: theme.spacing(2),
    },
    noData: {
        textAlign: 'center',
        fontSize: theme.spacing(2),
        fontWeight: 500
    },
    filter: {
        padding: '20px 0'
    },
    btnAction: {
        color: theme.palette.white,
        backgroundColor: colors.green[600],
        "&:hover": {
            backgroundColor: colors.green[900]
        }
    },
    paginate: {
        marginTop: theme.spacing(3),
        display: 'flex',
        justifyContent: 'center'
    }
}));

const lstFilter = [
    {
        id: 'companyName',
        name: 'Customer Name'
    },
    {
        id: 'jobsiteCity',
        name: 'Jobsite Address City'
    },
    {
        id: 'initialCallDate',
        name: 'Initial call'
    },
    {
        id: 'bidAppointmentDate',
        name: 'Bid Appointment'
    }
]

const lstShort = [
    {
        id: 'asc',
        name: 'ASC'
    },
    {
        id: 'desc',
        name: 'DESC'
    }
]

let pageNumber = 1;
let forcePage = 0;

const BidToDoOld = props => {

    const classes = useStyles();

    const [listSalesFollowUps, setListSalesFollowUps] = useState([]);
    const [sort, setSort] = useState({name: 'companyName', type: 'asc'})
    const [load, setLoad] = useState(false);
    const [pageCount, setPageCount] = useState(0);

    const dispatch = useDispatch();

    const onChangeSort = name => {
        setSort({...sort, name: name, asc: true})
        // debugger
        // if (!showSort[name]) {
        //     setShowSort({...showSort, [name]: true})
        // }
        // if (sort.name === name) {
        // } else {
        //     setSort({name, asc: true})
        // }
    }

    const onChangeDirection = type => {
        setSort({...sort, type})
    }

    const onPageChange = (page) => {
        pageNumber = page.selected + 1;
        forcePage = page.selected;
        fetchJobs();
    };
    const fetchJobs = () => {
        setLoad(true);
        axios.get(apiConfig.url.BASE_URL + apiConfig.url.DASHBOARD_BILL_TO_DO, {
            params:
                {
                    limit: 0,
                    pageSize: 24,
                    pageNumber: pageNumber,
                    sortBy: sort.name,
                    sortType: sort.type
                }
        })
            .then(res => {
                setListSalesFollowUps(res.data.data);
                setPageCount(res.data.pageCount);
            }).finally(() => {
            setLoad(false);
        })
    };

    const reload = () => {
        // setLoad(!load);
        dispatch({type: 'RELOAD_CUSTOMERS_COUNTER', reloadCustomer: true});
    }
    
    useEffect(() => {
        fetchJobs();
    }, [sort])

    if (listSalesFollowUps.length == 0 && !load) {
        return (
            <Grid container justifyContent='center'>
                <Typography className={classes.noData}>No data found.</Typography>
            </Grid>
        )
    }

    return (
        <>
            {listSalesFollowUps.length > 0 &&
            <div className={classes.filter}>
                <Grid container spacing={3}>
                    <Grid item md={6}>
                        <TextField
                            variant='outlined'
                            select
                            label={'Sort by'}
                            SelectProps={{native: true}}
                            value={sort.name || ''}
                            onClick={e => e.stopPropagation()}
                            onChange={(event) => {
                                onChangeSort(event.target.value)
                            }}
                        >
                            {lstFilter.map(st => (
                                <option key={`${st.id}`} value={st.id}>
                                    {st.name}
                                </option>
                            ))}
                        </TextField>
                        {/*<TextField*/}

                        {/*    variant='outlined'*/}
                        {/*    select*/}
                        {/*    label={'Sort order'}*/}
                        {/*    SelectProps={{native: true}}*/}
                        {/*    value={sort || ''}*/}
                        {/*    onClick={e => e.stopPropagation()}*/}
                        {/*    onChange={(event) => {*/}
                        {/*        setSort(event.target.value)*/}
                        {/*    }}*/}
                        {/*>*/}
                        {/*    {lstShort.map(st => (*/}
                        {/*        <option key={`${st.id}`} value={st.id}>*/}
                        {/*            {st.name}*/}
                        {/*        </option>*/}
                        {/*    ))}*/}
                        {/*</TextField>*/}
                        <FormControlLabel
                            style={{paddingLeft: '20px', paddingTop: '4px'}}
                            value="bottom"
                            checked={sort.type == 'asc'}
                            control={<GreenRadio/>}
                            label="Ascending"
                            labelPlacement="right"
                            onChange={() => onChangeDirection('asc')}
                        />
                        <FormControlLabel
                            style={{paddingLeft: '4px', paddingTop: '5px'}}
                            value="bottom"
                            checked={sort.type == 'desc'}
                            control={<GreenRadio/>}
                            label="Descending"
                            labelPlacement="right"
                            onChange={() => onChangeDirection('desc')}
                        />
                    </Grid>
                    <Grid item md={6} style={{textAlign: 'end', paddingLeft: '12px', paddingTop: '20px'}}>
                        <Button
                            onClick={() => {
                                window.open(window.location.origin + "/bidtodo/pdf/", '_blank');
                            }}
                            variant={"contained"}
                            className={classes.btnAction}
                            disabled={false}
                        >
                            Print
                        </Button>
                    </Grid>
                </Grid>
            </div>
            }
            {
                load ?
                    <Grid
                        container
                        spacing={0}
                        align="center"
                        justifyContent="center"
                        direction="column">
                        <Grid item>
                            <CircularProgress className={classes.circularProgress} size={50}/>
                        </Grid>
                    </Grid>
                    :
                    <>
                        {listSalesFollowUps.map((sale, inx) => <SaleFollowUpsCardOld key={inx} customer={sale} onReload={reload} />)}

                        <div style={pageCount == 0 ? {display: 'none'} : null} className={classes.paginate}>
                            <Pagination pageCount={pageCount} forcePage={forcePage} onPageChange={onPageChange}/>
                        </div>
                    </>
            }


        </>
    )
}

export default BidToDoOld;
