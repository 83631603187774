import {
  Accordion as MuiAccordion,
  AccordionDetails as MuiAccordionDetails,
  AccordionSummary as MuiAccordionSummary,
  Card,
  CardContent,
  CardHeader,
  Collapse,
  Divider,
  Tooltip,
  Typography
} from '@material-ui/core';
import { KeyboardArrowDown, KeyboardArrowRight } from '@material-ui/icons';
import { makeStyles, styled } from '@material-ui/styles';
import React, { Fragment, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import calendar from 'assets/images/calendar.svg';
import calendarAlt from 'assets/images/calendar-alt.svg';
import moment from 'moment';

const JobStage = {
  0: 'Unknown',
  1: 'To Bid',
  2: 'Bid Completed',
  3: 'To Be Scheduled',
  4: 'Scheduled',
  5: 'Job Completed',
  6: 'Cancelled',
  7: 'Customer Service'
};

const useStyle = makeStyles(theme => ({
  container: {
    height: '100%',
    overflow: 'auto'
  },
  route: {
    marginBottom: '.75rem'
  },
  header: {
    cursor: 'pointer',
    paddingTop: '.5rem',
    paddingBottom: '.5rem',
    '& .MuiCardHeader-action': {
      marginTop: 0
    }
  },
  headerContent: {
    display: 'flex',
    alignItems: 'center'
  },
  routeOrder: {
    marginRight: '1rem'
  },
  routeIcons: {
    display: 'inline-flex',
    marginRight: '2rem'
  },
  routeContent: {
    padding: '.5rem'
  }
}));

const Accordion = styled((props) => (
  <MuiAccordion disableGutters elevation={0} square {...props} />
))(({ theme }) => ({
  '&.Mui-expanded': {
    margin: 0
  },
  '&:not(:last-child)': {
    borderBottom: 0,
  },
  '&:before': {
    display: 'none',
  },
}));

const AccordionSummary = styled((props) => (
  <MuiAccordionSummary
    expandIcon={<KeyboardArrowRight sx={{ fontSize: '0.9rem' }} />}
    {...props}
  />
))(({ theme }) => ({
  background: 'transparent',
  flexDirection: 'row-reverse',
  padding: 0,
  minHeight: 'unset',
  '&.Mui-expanded': {
    minHeight: 'unset',
    margin: 0
  },
  '& .MuiAccordionSummary-expandIcon': {
    padding: 0,
    margin: 0,
    '&.Mui-expanded': {
      transform: 'rotate(90deg)'
    }
  },
  '& .MuiAccordionSummary-content': {
    marginLeft: theme.spacing(1),
    marginTop: 0,
    marginBottom: 0
  },
}));

const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
  paddingLeft: theme.spacing(8),
  paddingBottom: 0,
  flexDirection: 'column'
}));

const RouteSummary = () => {

  const classes = useStyle();

  const { routes } = useSelector(state => state.RoutesReducer);

  const [routeData, setRouteData] = useState({});

  const toggleExpand = (routeId, section) => {
    const rd = routeData[routeId];
    if (rd) {
      switch (section) {
        case 'jobs':
          rd.expandedJobs = !rd.expandedJobs;
          break;

        case 'cleaners':
          rd.expandedCleaners = !rd.expandedCleaners;
          break;

        case 'equipment':
          rd.expandedEquipment = !rd.expandedEquipment;
          break;

        case 'notes':
          rd.expandedNotes = !rd.expandedNotes;
          break;
      
        default:
          rd.expanded = !rd.expanded;
          break;
      }

      setRouteData({ ...routeData, [routeId]: { ...rd } });
    }
  };

  useEffect(() => {
    setRouteData(routes.reduce((acc, route) => {
      acc[route.id] = {
        expanded: true,
        expandedJobs: true,
        expandedCleaners: true,
        expandedEquipment: true,
        expandedNotes: true,
        cities: [...new Set(route.routeJobs.map(rj => rj.job?.city).filter(Boolean))].join(', '),
        routeTotal: route.routeJobs.reduce((total, rj) => total += (rj.job?.price ?? 0), 0)
      };
      return acc;
    }, {}));
  }, [routes]);

  return (
    <Card className={classes.container}>
      <CardContent>
        {
          routes.map(route => (
            <Card key={route.id} className={classes.route}>
              <CardHeader
                title={
                  <div className={classes.headerContent}>
                    <span className={classes.routeOrder}>Route {route.routeOrder}</span>
                    <div className={classes.routeIcons}>
                      {route.routeFull
                        ? <Tooltip title="Route Full" arrow>
                          <img id={'full' + route.routeOrder}
                            className={classes.routeIcon}
                            src={calendarAlt}
                            alt='calendar'
                          />
                        </Tooltip>
                        : <Tooltip title="Route Not Full" arrow>
                          <img id={'full' + route.routeOrder}
                            className={classes.routeIcon}
                            src={calendar}
                            alt='calendar'
                          />
                        </Tooltip>
                      }
                      {route.recurringRouteParentId &&
                        <Tooltip title="Recurring Route" arrow>
                          <div className={classes.routeIcon}>
                            <span className="fa-stack fa-1x" style={{ height: '1.4em', width: '1.5em', verticalAlign: 'center', fontSize: 21, color: '#000' }}>
                              <i style={{ fontSize: '1.4em' }} className="fal fa-calendar fa-stack-1x" />
                              <i className="fal fa-redo-alt fa-stack-1x"
                                style={{ fontSize: '1.4em', width: '0.45m', height: '0.4em', bottom: '-0.3em' }} />
                            </span>
                          </div>
                        </Tooltip>
                      }
                    </div>
                    <Typography>
                      {routeData[route.id]?.cities && `${routeData[route.id]?.cities} - `}
                      ${(!isNaN(routeData[route.id]?.routeTotal) ? routeData[route.id]?.routeTotal : 0).toFixed(2)}
                    </Typography>
                  </div>
                }
                className={classes.header}
                action={<span className={classes}><KeyboardArrowDown /></span>}
                onClick={() => toggleExpand(route.id)}
              />
              <Divider />
              <Collapse in={routeData[route.id]?.expanded} timeout='auto' unmountOnExit>
                <CardContent className={classes.routeContent}>
                  <Accordion expanded={routeData[route.id]?.expandedJobs} onChange={() => toggleExpand(route.id, 'jobs')}>
                    <AccordionSummary aria-controls="panel-content" id="panel-jobs">
                      <Typography>Jobs</Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                      {
                        route.routeJobs.map(rj => (
                          <Fragment key={rj.id}>
                            <Typography>
                              - {rj.job?.description}&nbsp;
                              - ${((rj.job?.price ?? 0) + (rj.job?.discountDollars ?? 0)).toFixed(2)}&nbsp;
                              - {moment(rj.jobStart).format('h:mm A')}
                            </Typography>
                            <Typography>{JobStage[rj.job?.bidStageId]}</Typography>
                            <Typography>{rj.job?.customerName}</Typography>
                            <Typography>{rj.job?.address1}</Typography>
                            <Typography>{rj.job?.city}, {rj.job?.state} {rj.job?.zipCode}</Typography>
                            <Typography>&nbsp;</Typography>
                          </Fragment>
                        ))
                      }
                    </AccordionDetails>
                  </Accordion>
                  <Accordion expanded={routeData[route.id]?.expandedCleaners} onChange={() => toggleExpand(route.id, 'cleaners')}>
                    <AccordionSummary aria-controls="panel-content" id="panel-cleaners">
                      <Typography>Cleaners</Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                      {route.routeEmployees.map(re => (
                        <Typography key={re.employeeId}>- {re.firstName} {re.lastName} ({re.phoneType}) {re.phoneNumber}</Typography>
                      ))}
                      {route.routeEmployees.length > 0 ? <Typography>&nbsp;</Typography> : null}
                    </AccordionDetails>
                  </Accordion>
                  <Accordion expanded={routeData[route.id]?.expandedEquipment} onChange={() => toggleExpand(route.id, 'equipment')}>
                    <AccordionSummary aria-controls="panel-content" id="panel-equipment">
                      <Typography>Equipment</Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                      {route.routeJobs.length > 0 && '- ' + route.routeJobs.map(rj => rj.job?.equipment?.trim().replaceAll(',', '')).filter(Boolean).join(', ')}
                      {route.routeEquipment.map(re => (
                        <Typography key={re.id}>- {re.assetTag}</Typography>
                      ))}
                      {route.routeEquipment.length > 0 ? <Typography>&nbsp;</Typography> : null}
                    </AccordionDetails>
                  </Accordion>
                  <Accordion expanded={routeData[route.id]?.expandedNotes} onChange={() => toggleExpand(route.id, 'notes')}>
                    <AccordionSummary aria-controls="panel-content" id="panel-notes">
                      <Typography>Notes</Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                      {route.routeNote && <Typography>- {route.routeNote}</Typography>}
                    </AccordionDetails>
                  </Accordion>
                </CardContent>
              </Collapse>
            </Card>
          ))
        }
      </CardContent>
    </Card>
  );
};

export default RouteSummary;
