import React, { useEffect, useState, useRef } from 'react'
import {
    TableContainer, Table, TableBody, TableRow, TableCell, colors, Typography, FormControlLabel, Link, CardContent, CircularProgress
} from '@material-ui/core'
import { DragHandle } from '@material-ui/icons'
import { makeStyles } from '@material-ui/styles'
import { SortableContainer, SortableElement, SortableHandle } from 'react-sortable-hoc'
import arrayMove from 'array-move'
import OpenInNew from "@material-ui/icons/OpenInNew";
import {GreenCheckbox} from 'components'
import { Document, Page, Text, View, StyleSheet, PDFViewer } from '@react-pdf/renderer';
import { useParams } from 'react-router-dom'
import axios from "../../../utils/axios";
import apiConfig from 'apiConfig'
import RoutesExpandedPdf from "views/Routes/components/RoutesBox/components/RoutesExpandedPdf";

const styles = StyleSheet.create({
    page: {
      flexDirection: 'row',
      backgroundColor: '#E4E4E4'
    },
    section: {
      margin: 10,
      padding: 10,
      flexGrow: 1
    }
});

const useStyles = makeStyles(theme => ({
    
}));

const RoutesExpandedRoutes = props => {
    const classes = useStyles();
    const [dataPdfRoute, setDataPdfRoute] = useState([]);
    const [loadingData, setLoadingData] = useState(false);
    const { date } = useParams()

    useEffect(() => {
        setLoadingData(true);
        axios
			.get(apiConfig.url.BASE_URL + apiConfig.url.GET_EXPANDED_ROUTES_PRINT + `${date}`)
			.then(res => {
                res.data.forEach(obj => {
                    if(obj && obj.route && obj.route.jobs){
                        obj.route.jobs = obj.route.jobs.sort((rj1, rj2) => new Date(rj1.jobStart) - new Date(rj2.jobStart))
                    }
                });
				setDataPdfRoute(res.data);
			})
            .catch(err => {
                console.log("err", err);
            })
            .finally(() => setLoadingData(false));
    }, [])

    return (
        <>
            {!loadingData 
                ? <PDFViewer width={'100%'} height={'100%'}>
                    <RoutesExpandedPdf routeInfoPdf={dataPdfRoute} />
                </PDFViewer>
                : <CardContent><CircularProgress size={24}/></CardContent>
            }
        </>
    )
}

export default RoutesExpandedRoutes;
