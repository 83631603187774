import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import { TextField, IconButton } from '@material-ui/core'
import { Event } from '@material-ui/icons'
import NumberFormat from 'react-number-format'
import { makeStyles } from '@material-ui/styles'
import { DatePicker } from '@material-ui/pickers'
import moment from 'moment'

const useStyles = makeStyles(theme => ({
    date: {
        width: '100%',
        position: 'relative',
    },
    icon: {
        position: 'absolute',
        top: 3,
        right: 0
    },
}))

const KeyboardDatePicker = ({ disabled, label, name, minDate, maxDate, value, onChange, onBlur, onClose, onKeyDown, getInputRef, className, style, error, helperText, size }) => {

    const classes = useStyles();

    const [defaultDate, setDefaultDate] = useState()
    const [date, setDate] = useState();
    const [openPicker, setOpenPicker] = useState(false);

    const dateValid = moment(date).isValid();

    const onChangeDate = event => {
        setDate(event.target.value);
        if (!event.target.value) {
            onChange(null)
        }
    }
    const onHandleChangeDate = date => {
        setDate(moment(date).format('MM/DD/YYYY'));
    }

    useEffect(() => {
        if (date && date.indexOf(' ') === -1 && dateValid) {
            onChange(new Date(date).toISOString())
        }
    }, [date]);

    useEffect(() => {
        setDate(value ? moment(value).format('MM/DD/YYYY') : null);
    }, [value])

    return (
        <>
            <div className={`${classes.date} ${className}`} style={style}>
                <NumberFormat
                    customInput={TextField}
                    format='##/##/####'
                    fullWidth
                    size={size}
                    getInputRef={getInputRef}
                    disabled={disabled}
                    error={error || (date && !dateValid) || false}
                    helperText={helperText || (date && !dateValid ? 'Invalid format date' : '')}
                    label={label}
                    name={name}
                    variant='outlined'
                    placeholder='MM/DD/YYYY'
                    value={date || ''}
                    onChange={event => onChangeDate(event)}
                    onBlur={onBlur}
                    onKeyDown={onKeyDown}
                />
                <IconButton disabled={disabled} className={classes.icon} size={size}
                    onClick={() => setOpenPicker(true)}>
                    <Event />
                </IconButton>
            </div>

            <DatePicker
                style={{ display: 'none' }}
                open={openPicker}
                minDate={minDate || new Date(1900, 0, 1)}
                maxDate={maxDate || new Date(2100, 0, 1)}
                onClose={() => {
                    setOpenPicker(false);
                    onClose && onClose();
                }}
                format="MM/DD/YYYY"
                value={date ? new Date(date) : (defaultDate ? new Date(defaultDate) : new Date())}
                onChange={onHandleChangeDate}
            />
        </>
    )
}

KeyboardDatePicker.propTypes = {
    disabled: PropTypes.bool,
    label: PropTypes.string,
    name: PropTypes.string,
    minDate: PropTypes.object,
    maxDate: PropTypes.object,
    onChange: PropTypes.func,
    onBlur: PropTypes.func,
    onKeyDown: PropTypes.func,
    getInputRef: PropTypes.func,
    className: PropTypes.string,
    style: PropTypes.object,
    error: PropTypes.bool,
}
KeyboardDatePicker.defaultProps = {
    disabled: false,
    onChange: () => { },
    className: ''
}

export default KeyboardDatePicker;
