import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import { Collapse, colors, Typography } from '@material-ui/core'
import { makeStyles } from '@material-ui/styles'

const useStyles = makeStyles(theme => ({
    routeTile: {
        width: '100%',
        height: 'calc(100% - 40px)',
        display: 'flex',
        justifyContent: 'space-between'
    },
    cities: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
        '& p': {
            padding: '3px 0 3px 10px'
        }
    },
    titles: {
        textAlign: 'right'
    },
    titleJob: {
        minHeight: '61px',
        lineHeight: '50px',
        minHeight: '61px',
        lineHeight: '50px',
        paddingRight: '10px'
    },
    title: {
        height: '40px',
        lineHeight: '40px',
        paddingRight: '10px',
    },
    cityList: {
        height: '100%',
        overflowY: 'auto'
    }
}))

const RouteTitle = props => {

    const classes = useStyles();

    const { route, expandContent } = props;

    const [cities, setCities] = useState([]);
    const [totalPrice, setTotalPrice] = useState();

    useEffect(() => {
        if (route.routeJobs) {
            let total = 0;

            const cts = route.routeJobs.length > 0 && route.routeJobs.map(job => {
                total += job.job.price;
                return (job.job.city || '')
            }) || [];

            setCities(cts);
            route.totalPrice = total;
        }
    }, [route.routeJobs])

    return (
        <Collapse in={expandContent} unmountOnExit>
            <div className={classes.routeTile}>
                <div className={classes.cities}>
                    <div className={classes.cityList}>
                        {cities.map((city, i) => (
                            <Typography key={`${city}-${i}`}>{city}</Typography>
                        ))}
                    </div>
                    <Typography style={{ whiteSpace: 'nowrap' }}>
                        {!isNaN(route.totalPrice) ? `Total: $${route.totalPrice.toFixed(2)}` : ''}
                    </Typography>
                </div>
                <div className={classes.titles}>
                    <Typography className={classes.titleJob} style={{ height: route.lineJobCount * 55 + 46 }}>Jobs</Typography>
                    <Typography className={classes.title}>Employees</Typography>
                    <Typography className={classes.title}>Equipment</Typography>
                    <Typography className={classes.title}>Notes</Typography>
                </div>
            </div>
        </Collapse>
    )
}

RouteTitle.propTypes = {
    route: PropTypes.object
}

export default RouteTitle;
