import React, { useMemo } from 'react'
import {
    TableRow, TableCell,
    IconButton, colors, TextField, Button, CircularProgress, CardActions
} from '@material-ui/core'
import { Delete } from '@material-ui/icons'
import { makeStyles } from '@material-ui/styles'

const useStyles = makeStyles(theme => ({
    headRow: {
        '& th': {
            borderBottom: '1px solid #888888',
            fontSize: '16px',
        }
    },
    bodyRow: {
        '& td': {
            padding: '8px 16px'
        }
    },
    cardHeader: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        padding: '8px 16px'
    },
    addIcon: {
        padding: '0',
        '& span svg': {
            fontSize: '40px',
            color: colors.green[600],
            '&:hover': {
                color: colors.green[900]
            }
        }
    },
    btn: {
        color: theme.palette.white,
        backgroundColor: colors.green[600],
        '&:hover': {
            backgroundColor: colors.green[900]
        },
    },
    btnDis: {
        color: 'rgba(0, 0, 0, 0.26)',
        backgroundColor: 'rgb(217 217 217)',
        pointerEvents: 'none'
    },
}));


const EquipmentList = (props) => {

    const classes = useStyles();
    const { index, equipment, onChangeEquipment, equipmentChanging, errors, touched, setFieldTouched, saveRow, isSaving, setOpenConfirm, setCanSave, remove, setLocateFile, locateFile } = props;

    return useMemo(() => (
        <TableRow className={classes.bodyRow}>
            <TableCell>
                <TextField
                    fullWidth
                    name={`equipments.${index}.assetTag`}
                    variant='outlined'
                    value={equipment.assetTag}
                    onChange={onChangeEquipment}
                    onBlur={() => setFieldTouched(`equipments.${index}.assetTag`)}
                    error={
                        errors &&
                        errors.equipments &&
                        errors.equipments[index] &&
                        errors.equipments[index].assetTag &&
                        touched &&
                        touched.equipments &&
                        touched.equipments[index] &&
                        touched.equipments[index].assetTag
                    }
                    helperText={
                        (
                            errors &&
                            errors.equipments &&
                            errors.equipments[index] &&
                            errors.equipments[index].assetTag &&
                            touched &&
                            touched.equipments &&
                            touched.equipments[index] &&
                            touched.equipments[index].assetTag
                        ) && errors.equipments[index].assetTag
                    }
                />
            </TableCell>
            <TableCell>
                <TextField
                    fullWidth
                    name={`equipments.${index}.description`}
                    variant='outlined'
                    value={equipment.description}
                    onChange={(event) => {
                        onChangeEquipment(event);
                        setCanSave(true)
                    }}
                    onBlur={() => setFieldTouched(`equipments.${index}.description`)}
                    error={
                        errors &&
                        errors.equipments &&
                        errors.equipments[index] &&
                        errors.equipments[index].description &&
                        touched &&
                        touched.equipments &&
                        touched.equipments[index] &&
                        touched.equipments[index].description
                    }
                    helperText={
                        (
                            errors &&
                            errors.equipments &&
                            errors.equipments[index] &&
                            errors.equipments[index].description &&
                            touched &&
                            touched.equipments &&
                            touched.equipments[index] &&
                            touched.equipments[index].description
                        ) && errors.equipments[index].description
                    }
                />
            </TableCell>
            <TableCell>
                <CardActions>
                <Button className={!equipmentChanging ? classes.btnDis : classes.btn}
                    onClick={() => {
                        setFieldTouched(`equipments.${index}.assetTag`);
                        setFieldTouched(`equipments.${index}.description`);
                        setLocateFile({
                            index: index,
                            id: equipment.id,
                            delete: remove,
                            add: equipment.add
                        });
                        saveRow(errors, touched, index, equipment);
                    }}>
                    Save
                </Button>
                {isSaving && locateFile.index === index && <CircularProgress size={24} />}
                </CardActions>
            </TableCell>
            <TableCell>
                <IconButton onClick={() => {
                    setLocateFile({
                        index: index,
                        id: equipment.id,
                        delete: remove,
                        add: equipment.add
                    });
                    setOpenConfirm(true)
                }}>
                    <Delete />
                </IconButton>
            </TableCell>
        </TableRow>
    ), [equipment, errors, touched, isSaving, equipmentChanging])
}

export default EquipmentList;
