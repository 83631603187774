import {
    INIT_ROUTES, ADD_ROUTE, REMOVE_ROUTE,
    ADD_JOB_ROUTE, CHANGE_JOB_ROUTE, CHANGE_JOB_HOURS,
    CHANGE_EMP_ROUTE, CHANGE_EQP_ROUTE,
    ADD_EMP_ROUTE, ADD_EQP_ROUTE, REMOVE_JOB_ROUTE,
    REMOVE_EMP_ROUTE, REMOVE_EQP_ROUTE, UPDATE_ROUTE, DRAG_ITEM, CHANGE_ORDER
} from '../actions/action-types'

const initState = {
    isUpdate: false,
    isAdd: false,
    routes: [],
    onDragType: '',
    orderType: '',
    expanded: true,
    showDistance: false,
    locateOnRouteCoord: null,
    reloadRoutes: false
}

const RoutesReducer = (state = initState, action) => {

    const { oldRouteId, routeId, job, employees, equipment, route, routeJobs, routeNote, locateOnRouteCoord, reloadRoutes } = action;
    const routes = [...state.routes];
    const oldRouteIndex = routes.findIndex(route => route.id === oldRouteId)
    const routeIndex = routes.findIndex(route => route.id === routeId);

    switch (action.type) {
        case 'IS_UPDATE':
            return {
                ...state,
                isUpdate: action.status
            }
        case "IS_ADD_ROUTE":
            return {
                ...state,
                isAdd: action.isAdd
            }
        case CHANGE_ORDER:
            return {
                ...state,
                orderType: action.orderType
            }
        case 'CHANGE_WIDTH':
            return {
                ...state,
                expanded: action.expanded
            }
        case 'CLEAR_DISTANCE':
            let routeDistance = routes.map(route => {
                delete route.distance;
                const routeJobs = route.routeJobs && route.routeJobs.map(job => {
                    delete job.distance
                    return job;
                }) || []
                return { ...route, routeJobs }
            })
            return {
                ...state,
                showDistance: false,
                orderType: '',
                routes: routeDistance
            }
        case 'SHOW_DISTANCE':
            return {
                ...state,
                showDistance: action.showDistance
            }
        case INIT_ROUTES:
            return {
                ...state,
                routes: action.routes
            }

        case ADD_ROUTE:
            routes.push(action.route)
            return { ...state, routes, isAdd: true }

        case UPDATE_ROUTE:
            routes[routeIndex] = route;
            return { ...state, routes }

        case REMOVE_ROUTE:
            const rt = routes.filter(route => route.id !== routeId)
            return { ...state, routes: rt }

        case ADD_JOB_ROUTE:
            routes[routeIndex].routeJobs = routes[routeIndex].routeJobs ?
                [...routes[routeIndex].routeJobs, job] : [job];
            let rj = routes.map(x => {
                x.distance = undefined
                return x
            })

            return { ...state, routes: rj }

        case 'UPDATE_JOB_ROUTE':
            routes[routeIndex].routeJobs = routeJobs
            let routerJobUpdate = routes.map(x => {
                x.distance = undefined
                return x
            })

            return { ...state, routes: routerJobUpdate }

        case ADD_EMP_ROUTE:
            routes[routeIndex].routeEmployees = routes[routeIndex].routeEmployees ?
                [...routes[routeIndex].routeEmployees, ...employees] : employees;
            return { ...state, routes }

        case ADD_EQP_ROUTE:
            routes[routeIndex].routeEquipment = routes[routeIndex].routeEquipment ?
                [...routes[routeIndex].routeEquipment, ...equipment] : equipment;
            return { ...state, routes }

        case CHANGE_JOB_ROUTE:
            routes[oldRouteIndex].routeJobs = routes[oldRouteIndex].routeJobs.filter(j => j.jobSplitId !== job.jobSplitId);
            routes[routeIndex].routeJobs = routes[routeIndex].routeJobs ?
                [...routes[routeIndex].routeJobs, job] : [job];
            let changeJobRoute = routes.map(x => {
                x.distance = undefined
                return x
            })
            return { ...state, routes: changeJobRoute }
        case CHANGE_JOB_HOURS:
            routes[routeIndex].routeJobs = routes[routeIndex].routeJobs.filter(j => j.jobSplitId !== job.jobSplitId);
            routes[routeIndex].routeJobs = routes[routeIndex].routeJobs ?
                [...routes[routeIndex].routeJobs, job] : [job];
            return { ...state, routes }

        case CHANGE_EMP_ROUTE:
            routes[oldRouteIndex].routeEmployees = routes[oldRouteIndex].routeEmployees.filter(emp => emp.employeeId !== employees[0].employeeId);
            routes[routeIndex].routeEmployees = routes[routeIndex].routeEmployees ?
                [...routes[routeIndex].routeEmployees, ...employees] : employees;
            return { ...state, routes }

        case CHANGE_EQP_ROUTE:
            routes[oldRouteIndex].routeEquipment = routes[oldRouteIndex].routeEquipment.filter(eqp => eqp.id !== equipment.id);
            routes[routeIndex].routeEquipment = routes[routeIndex].routeEquipment ?
                [...routes[routeIndex].routeEquipment, equipment] : [equipment];
            return { ...state, routes }

        case REMOVE_JOB_ROUTE:
            routes[routeIndex].routeJobs = routes[routeIndex].routeJobs.filter(j => j.jobSplitId !== action.jobSplitId);
            let r = routes.map(x => {
                x.distance = undefined
                return x
            })
            return { ...state, routes: r }

        case REMOVE_EMP_ROUTE:
            routes[routeIndex].routeEmployees = routes[routeIndex].routeEmployees.filter(emp => emp.employeeId !== action.employeeId);
            return { ...state, routes }

        case REMOVE_EQP_ROUTE:
            routes[routeIndex].routeEquipment = routes[routeIndex].routeEquipment.filter(eqp => eqp.id !== action.id);
            return { ...state, routes }
        case DRAG_ITEM:
            return { ...state, onDragType: action.onDragType }
        case "UPDATE_NOTE":
            routes[routeIndex] = { ...routes[routeIndex], routeNote };
            return { ...state, routes }
        case "LOCATE_ON_ROUTE_COORD":
            return { ...state, locateOnRouteCoord }
        case "RELOAD_ROUTES":
            return { ...state, reloadRoutes }
        default:
            return state;
    }
}

export default RoutesReducer;
