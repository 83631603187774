import React, { useState, useEffect } from "react";
import { makeStyles } from "@material-ui/styles";
import { Grid } from "@material-ui/core";
import { SearchInput, Page } from "components";
import {
  WorkCompleted,
  ScheduledAmount,
  BidsToDo,
  TotalProfit,
  LatestSales,
  UsersByDevice,
  LatestProducts,
  LatestOrders
} from "./components";
import ListBids from "./components/BidsToDo/ListBids";
import LatestBids from "./components/BidsToDo/LatestBids";
import localStorage from "utils/localStorage";
import { AccessControl } from "components/AccessControl/AccessControl";
import { PERMISSIONS } from "common/permissions";
import axios from "../../utils/axios";
import apiConfig from "../../apiConfig";
import qs from "query-string";
import {useAppInsightsContext} from "@microsoft/applicationinsights-react-js";
import { withAITracking } from '@microsoft/applicationinsights-react-js';
import { reactPlugin } from './../../AppInsights';

const useStyles = makeStyles(theme => ({
  root: {
    padding: theme.spacing(4)
  }
}));

const useSearchDelay = (val, delay) => {
  const [debounced, setDebounced] = useState(val);
  delay = delay || 500;

  useEffect(() => {
    const searchTimout = setTimeout(() => {
      setDebounced(val);
    }, delay);
    return () => clearTimeout(searchTimout);
  }, [val, delay]);

  return debounced;
};

const Dashboard = ({ history }) => {

  const appInsights = useAppInsightsContext();
  // const metricData = {
  //   name: "Dashboard loaded",
  // };
  // const additionalProperties = { "Component Name": 'Dashboard' };
  // appInsights.trackMetric({name: "Dashboard loaded", average: 42});

  const classes = useStyles();

  const [val, setVal] = useState("");
  const [SalesFollowUps, setSalesFollowUps] = useState([]);
  let searchValue = useSearchDelay(val);

  const onChange = event => {
    setVal(event.target.value);
  };
  const onClear = () => {
    setVal("");
  };

  useEffect(() => {
    if (searchValue) {
      history.push({ pathname: "/search", search: qs.stringify({ query: searchValue }) });
    }
  }, [searchValue]);
  useEffect(() => {
    axios.get(apiConfig.url.BASE_URL + apiConfig.url.DASHBOARD_FOLLOW_UP_CONTACT)
        .then(res => {
          setSalesFollowUps(res.data);
        })
        .catch(err => {
        })
    appInsights.trackEvent({ name: 'dashboard loaded'});
  },[])
  return (
    <AccessControl requiredPermission={PERMISSIONS.page.dashboard} redirect>
      <Page title="Squadware">
        <div className={classes.root}>
          <Grid container spacing={4}>
            <Grid item xs={12}>
              <SearchInput
                placeholder="Search customers and employees..."
                value={val}
                onChange={onChange}
                onClear={onClear}
              />
            </Grid>
            <Grid item lg={3} sm={6} xl={3} xs={12}>
              <BidsToDo />
            </Grid>
            <Grid item lg={3} sm={6} xl={3} xs={12}>
              <WorkCompleted SalesFollowUps={SalesFollowUps} />
            </Grid>
            <Grid item lg={3} sm={6} xl={3} xs={12}>
              <ScheduledAmount />
            </Grid>
            <Grid item lg={3} sm={6} xl={3} xs={12}>
              <TotalProfit />
            </Grid>
            <Grid item lg={6} md={12} xl={6} xs={12}>
              <LatestBids />
            </Grid>
            <Grid item lg={6} md={12} xl={6} xs={12}>
              <LatestSales SalesFollowUps={SalesFollowUps}/>
            </Grid>
          </Grid>
        </div>
      </Page>
    </AccessControl>
  );
};

export default withAITracking(reactPlugin, Dashboard);
