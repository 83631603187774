import React, { useEffect, useState } from "react";
import clsx from "clsx";
import PropTypes from "prop-types";
import { makeStyles, useTheme } from "@material-ui/styles";
import {
  CardActions,
  Divider,
  Drawer,
  IconButton,
  TextField,
  Tooltip,
  Typography,
  useMediaQuery,
} from "@material-ui/core";
import { Profile, SidebarNav } from "./components";
import sidebarPages, {
  fieldTechnicianEstimatorSidebarPages,
  fieldTechnicianSidebarPages,
  superAdminSidebarPages,
} from "./SidebarPages";
import { Footer } from "../";
import axios from "utils/axios";
import apiConfig from "apiConfig";
import { useDispatch, useSelector } from "react-redux";
import localStorage from "utils/localStorage";
import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";
import {checkMultiRoles, PERMISSIONS} from "common/permissions";
import { increaseDate } from "utils/date-time";
import InputIcon from "@material-ui/icons/Input";
import CircularProgress from "@material-ui/core/CircularProgress";
import moment from "moment";
import Snackbar from "@material-ui/core/Snackbar";
import Alert from "@material-ui/lab/Alert";
import ModalAllEmployees from "../../../../views/EmployeeRoutes/components/ModalAllEmployees";
import { checkFeature, FeatureFlags } from "FeatureService";
import { OpenInBrowser } from "@material-ui/icons";

const sidebars = {
  "Field Technician": fieldTechnicianSidebarPages,
  "System Admin": superAdminSidebarPages,
};

const useStyles = makeStyles((theme) => ({
  drawer: {
    width: 265,
    flexShrink: 0,
    whiteSpace: "nowrap",
    boxSizing: "border-box",
    overflowX: "hidden",
    overflowY: "auto",
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
    [theme.breakpoints.up("lg")]: {
      marginTop: 64,
      height: "calc(100% - 64px)",
    },
  },
  collapse: {
    [theme.breakpoints.up("lg")]: {
      width: 77,
    },
  },
  root: {
    backgroundColor: theme.palette.white,
    display: "flex",
    flexDirection: "column",
    height: "100%",
    padding: theme.spacing(2),
  },
  divider: {
    margin: theme.spacing(2, 0),
  },
  nav: {
    //marginBottom: theme.spacing(2)
  },
  otherItems: {
    marginTop: -8
  },
  signOutButton: {
    display: "flex",
    width: "100%",
    height: "44px",
    borderRadius: "4px",
    fontSize: "24px",
    justifyContent: "left",
    paddingLeft: "10px",
    fontWeight: "500",
    color: "#546e7a",
  },
  logoutText: {
    marginLeft: "8px",
    fontWeight: "500",
    color: "#36464e",
  },
  statusSelect: {
    width: "calc(100% - 24px)",
  },
}));

let mounted;
const Sidebar = (props) => {
  const classes = useStyles();
  const theme = useTheme();
  const isDesktop = useMediaQuery(theme.breakpoints.up("lg"), {
    defaultMatches: true,
  });
  const { open, variant, onOpen, onClose, className, ...rest } = props;
  const { reloadCustomer } = useSelector((state) => state.CountReducer);
  const [status, setStatus] = useState([]);
  const [selectStatus, setSelectStatus] = useState();
  const [isStatus, setIsStatus] = useState({ failed: false, msg: "" });
  const [openSnackbar, setOpenSnackBar] = useState(false);
  const [clocking, setClocking] = useState(false);

  const [openConfirmAllEmployees, setOpenConfirmAllEmployees] = useState(false);
  const [listEmployeesActive, setListEmployeesActive] = useState([]);

  const userRoles = localStorage.getUserRoles();
  const isHasRoleClockInClockOut = checkMultiRoles(
      userRoles,
    PERMISSIONS.control.clockInClockOut
  );

  const [counts, setCounts] = useState(null);
  const [countsTask, setCountsTask] = useState(null);
  const [bidtodo, setBidtodo] = useState();
  const [sidebarData, setSidebarData] = useState();
  const [selectCurrentStatus, setSelectCurrentStatus] = useState();
  const [fieldTech, setFieldTech] = useState(false);

  const dispatch = useDispatch();
  const { reloadLogHourSideBar,reloadTaskSideBar } = useSelector((state) => state.AppReducer);

  const fetchCounts = () => {
    axios
      .get(apiConfig.url.CUSTOMER_URL + apiConfig.url.CUSTOMERS_GET_COUNT)
      .then((res) => {
        mounted && setCounts(res.data.counts);
      })
      .catch((err) => console.log(err));
  };
  const fetchCountsTask = () => {
    axios
        .get(apiConfig.url.BASE_URL + apiConfig.url.EMPLOYEE_COUNT_TASK)
        .then((res) => {
          mounted && setCountsTask(res.data);
        })
        .catch((err) => console.log(err));
  };

  const getPeriodDateString = (periodstartdate, periodduration) => {
    let periodDate = null;
    if (periodstartdate) {
      const date = periodstartdate.split("-");
      const sd = new Date(
        parseInt(date[0]),
        parseInt(date[1]),
        parseInt(date[2])
      );
      const periodEndDate = increaseDate(sd, periodduration * 7);

      const enddate = `${periodEndDate.getFullYear()}-${periodEndDate.getMonth() +
        1}-${periodEndDate.getDate()}`;
      periodDate = { startdate: periodstartdate, enddate };
    } else {
      const periodEndDate = new Date();
      const periodStartDate = increaseDate(periodEndDate, -7);
      const startdate = `${periodStartDate.getFullYear()}-${periodStartDate.getMonth() +
        1}-${periodStartDate.getDate()}`;
      const enddate = `${periodEndDate.getFullYear()}-${periodEndDate.getMonth() +
        1}-${periodEndDate.getDate()}`;
      periodDate = { startdate, enddate };
    }

    return periodDate;
  };
  const getEmployeeStatus = () => {
    return axios.get(
      apiConfig.url.BASE_URL + apiConfig.url.HOURS_LOG_EMPLOYEE_STATUS
    );
  };
  const loadJob = (id = null) => {
    const user = localStorage.getFullInfo();
    Promise.all([
      axios.get(
        apiConfig.url.BASE_URL +
          apiConfig.url.EMPLOYEE_ROUTE_JOB.replace(
            "{userId}",
            user.nameid
          ).replace(
            "{date}",
            moment().startOf('day').toISOString()
          )
      ),
      getEmployeeStatus(),
    ])
      .then(([resJob, resStatus]) => {
        if (resJob.data) {
          let orderedJob = resJob.data.sort(
            (a, b) => new Date(a.scheduledTime) - new Date(b.scheduledTime)
          );
          let lstStatus = [...resStatus.data];
          let jobsiteStatusIdx = lstStatus.findIndex((s) => s.id == 2);
          var uniqJobs = orderedJob.filter(
            (job, index, self) =>
              self.findIndex((s) => s.jobSplitId === job.jobSplitId) === index
          );
          if (uniqJobs.length > 0) {
            lstStatus.splice(
              jobsiteStatusIdx,
              1,
              ...uniqJobs.map((d) => ({
                id: 2,
                description: `Job Site${
                  d.jobsiteAddress1 ? ` - ${d.jobsiteAddress1}` : ""
                }`,
                jobSplitId: d.jobSplitId,
              }))
            );
          }
          setStatus(lstStatus);
        } else {
          let lstStatus = [...resStatus.data];
          setStatus(lstStatus);
          setSelectStatus("6_");
        }
      })
      .finally(() => {
        getRouteDetail();
      });
  };

  const onEmployeeStatusChanged = (all, event) => {
    setOpenConfirmAllEmployees(false);
    if (!isHasRoleClockInClockOut) {
      return;
    }
    setClocking(true);
    let value = event && event.target.value;
    let arr = value
      ? value.split("_")
      : (selectCurrentStatus && selectCurrentStatus.split("_")) ||
        selectStatus.split("_");
    let lstPromise = [];
    if (all) {
      listEmployeesActive.forEach((id) => {
        const data = {
          employeeId: id.id,
          date: moment().startOf('day').toISOString(),
          hoursLogEmployeeStatusId: arr[0],
          jobSplitId: arr[1] || null,
        };
        lstPromise.push(
          axios.put(
            apiConfig.url.BASE_URL + apiConfig.url.HOURS_LOG_EMPLOYEE_STATUS,
            data
          )
        );
      });
    } else {
      const user = localStorage.getFullInfo();
      const data = {
        employeeId: user.nameid,
        date: moment().startOf('day').toISOString(),
        hoursLogEmployeeStatusId: arr[0],
        jobSplitId: arr[1] || null,
      };
      lstPromise.push(
        axios.put(
          apiConfig.url.BASE_URL + apiConfig.url.HOURS_LOG_EMPLOYEE_STATUS,
          data
        )
      );
    }
    Promise.all(lstPromise)
      .then((res) => {
        if (!res.find((x) => !x.data.isSuccess)) {
          setIsStatus({ failed: false, msg: "Record Updated" });
          setOpenSnackBar(true);
          setSelectStatus(value || selectCurrentStatus);
          dispatch({ type: "RELOAD_LOG_HOUR" });
        } else {
          setIsStatus({
            failed: true,
            msg: "Payroll has not been enabled yet. Please contact the administrator.",
          });
          setOpenSnackBar(true);
        }
      })
      .catch(() => {
        setIsStatus({
          failed: true,
          msg: "Update failed, please try again later.",
        });
        setOpenSnackBar(true);
      })
      .finally(() => {
        setClocking(false);
      });
  };

  const clockInEmployees = (event) => {
    if (listEmployeesActive && listEmployeesActive.length > 1) {
      setOpenConfirmAllEmployees(true);
    } else {
      onEmployeeStatusChanged(false, event);
    }
  };

  const getRouteDetail = () => {
    const user = localStorage.getFullInfo();
    axios
      .get(
        apiConfig.url.BASE_URL +
          apiConfig.url.EMPLOYEE_ROUTE_DETAIL.replace(
            "{userId}",
            user.nameid
          ).replace(
            "{date}",
            moment().startOf('day').toISOString()
          )
      )
      .then((res) => {
        if (res.data.hoursLog && !res.data.hoursLog.workEnd) {
          setSelectStatus(
            `${res.data.hoursLog.hoursLogEmployeeStatusId}_${res.data.hoursLog
              .jobSplitId || ""}`
          );
        } else {
          setSelectStatus("6_"); // hard coded - Off The Clock
        }
        if (res.data.coWorkers) {
          let lst = res.data.coWorkers.map((x) => {
            return {
              ...x,
              id: x.employeeIdString,
              name: x.firstName + x.lastName,
            };
          });
          setListEmployeesActive(lst);
        }
      })
      .finally(() => {});
  };

  const handleToggleDrawer = () => {
    if (open) onClose();
    else onOpen();
  };

  const logout = () => {
    localStorage.clearAll();
    sessionStorage.clear();
    window.location.reload();
  };
  useEffect(() => {
    loadJob();
  }, [reloadLogHourSideBar]);

  useEffect(() => {
    mounted = true;
    const userRole = localStorage.getUserRole();
    if (userRole !== PERMISSIONS.FIELD_TECHNICIAN) {
      fetchCounts();
      fetchCountsTask();
      axios
        .get(apiConfig.url.BASE_URL + apiConfig.url.DASHBOARD_BILL_TO_DO_COUNT)
        .then((res) => {
          mounted && setBidtodo(res.data);
        });
    }

    return () => (mounted = false);
  }, [reloadCustomer]);

  useEffect(() => {
    mounted = true;

    (async () => {
      if (mounted) {
        const userRoles = localStorage.getUserRoles();

        let pages = fieldTechnicianSidebarPages;
        if(userRoles.includes(PERMISSIONS.FIELD_TECHNICIAN_ESTIMATOR)){
          pages = fieldTechnicianEstimatorSidebarPages;
        }
        if(userRoles.includes(PERMISSIONS.COMPANY_ADMINISTRATOR) || userRoles.includes(PERMISSIONS.OFFICE_STAFF)){
          pages = sidebarPages;
        }
        if(userRoles.includes(PERMISSIONS.SUPER_ADMIN)){
          pages = superAdminSidebarPages;
        }

        const data = [...pages];
        const customerPages = data[0].pages.find(
          (page) => page.title === "Customers"
        );
        const employeesPages = data[0].pages.find(
            (page) => page.title === "Employees" || page.title === "Employee"
        );

        const isEnableTasks = await checkFeature(
          FeatureFlags.TASKS
        );
        // get information of the logged user for redirect
        if (userRoles.length == 1 && (userRoles[0] === PERMISSIONS.FIELD_TECHNICIAN || userRoles[0] === PERMISSIONS.FIELD_TECHNICIAN_ESTIMATOR)) {
          const {
            displayname,
            nameid,
            periodstartdate,
            periodduration,
          } = localStorage.getFullInfo();
          // href for the employee detail
          const manageUrl = `/employees/${encodeURIComponent(displayname)}/${nameid}/information`;
          // href for the payroll detail
          // const { startdate, enddate } = getPeriodDateString(
          //   periodstartdate,
          //   periodduration
          // );
          const startDate = moment().startOf('week').add(-periodduration, 'weeks');
          const endDate = moment().startOf('week');
          var date_object = {};
          if (new Date(periodstartdate).getDay() === 0) {
              date_object.startDate = startDate.toISOString();
              date_object.endDate = endDate.add(-1, 'days').toISOString();
          } else {
            date_object.startDate = startDate.add(1, 'days').toISOString();
            date_object.endDate = endDate.toISOString();
          }
          const s = new Date(date_object.startDate);
          const e = new Date(date_object.endDate);

          const sy = s.getFullYear();
          const sm = s.getMonth() + 1 > 9 ? s.getMonth() + 1 : `0${s.getMonth() + 1}`;
          const sd = s.getDate() > 9 ? s.getDate() : `0${s.getDate()}`;

          const ey = e.getFullYear();
          const em = e.getMonth() + 1 > 9 ? e.getMonth() + 1 : `0${e.getMonth() + 1}`;
          const ed = e.getDate() > 9 ? e.getDate() : `0${e.getDate()}`;

          const startdate = `${sy}-${sm}-${sd}`;
          const enddate = `${ey}-${em}-${ed}`;
          const payrollUrl = `/employees/payroll-detail/${encodeURIComponent(displayname)}/${nameid}/${startdate}/${enddate}`;

          if (employeesPages) {
            employeesPages.href = manageUrl;
            const payrollPage = employeesPages.children.find(x => x.href === '/employees/payroll-detail');
            if (payrollPage) payrollPage.href = payrollUrl;
          }
        }

        const isEnableExpiringDocuments = await checkFeature(
          FeatureFlags.EXPIRING_DOCUMENTS
        );


        if(!userRoles.includes(PERMISSIONS.FIELD_TECHNICIAN) && !userRoles.includes(PERMISSIONS.FIELD_TECHNICIAN_ESTIMATOR))
        {
          setFieldTech(false);
        }
        else
        {
          setFieldTech(true);
        }

        if(employeesPages && countsTask){
          if (isEnableTasks) {
            const tasksPage = employeesPages.children.find(x => x.href === '/employees/tasks');
            if (tasksPage) tasksPage.title = `Tasks (${countsTask || "0"})`;
          }
          if(employeesPages && !isEnableTasks){
            employeesPages.children = employeesPages.children.filter(
                (c) => c.href !== "/employees/tasks"
            );
          }
        }
        if (counts && bidtodo !== undefined && pages) {
          if (customerPages) {
            const bidsPage = customerPages.children.find(x => x.href === '/customers/bidtodo');
            if (bidsPage) bidsPage.title = `Bids to do (${bidtodo || "0"})`;

            const salesPage = customerPages.children.find(x => x.href === '/customers/salefollowups');
            if (salesPage) salesPage.title = `Sales Follow Ups (${counts[2].count || "0"})`;

            const documentsPage = customerPages.children.find(x => x.href === '/customers/expiringdocuments');
            if (documentsPage) documentsPage.title = `Expiring Documents (${counts[3].count || "0"})`;

            const duplicatesPage = customerPages.children.find(x => x.href === '/customers/duplicate');
            if (duplicatesPage) duplicatesPage.title = `Duplicates (${counts[1].count || "0"})`;

            const missingTaxGroupPage = customerPages.children.find(x => x.href === '/customers/missingtaxgroup');
            if (missingTaxGroupPage) missingTaxGroupPage.title = `Missing Tax Groups (${counts[0].count || "0"})`;
          }
        }
        if (customerPages && !isEnableExpiringDocuments) {
          customerPages.children = customerPages.children.filter(
            (c) => c.href !== "/customers/expiringdocuments"
          );
        }
        //admin sibar used feature flag
        const isEnableCommunicationCenter = await checkFeature(
          FeatureFlags.COMMUNICATION_CENTER
        );
        const isEnableCustomForm = await checkFeature(FeatureFlags.CUSTOM_FORM);
        const adminPages = data[0].pages.find((page) => page.title === "Admin");
        if (pages && adminPages) {
          if (!isEnableCommunicationCenter) {
            adminPages.children = adminPages.children.filter(
              (c) => c.href !== "/admin/communication-center"
            );
          }
          if(!isEnableCustomForm){
            adminPages.children = adminPages.children.filter(
              (c) => c.href !== "/admin/custom-forms"
            );
          }
        }
        setSidebarData(data);
      }
    })();

    return () => (mounted = false);
  }, [counts, bidtodo, countsTask]);

  if (sidebarData) {
    return (
      <Drawer
        anchor="left"
        classes={{ paper: clsx(classes.drawer, !open ? classes.collapse : "") }}
        onClose={onClose}
        open={open}
        variant={variant}
      >
        <div {...rest} className={clsx(classes.root, className)}>
          <Profile onClose={!isDesktop ? onClose : null} open={open} />
          <Divider className={classes.divider} />
          <div>
            {(open || !isDesktop) && status && status.length > 0 && (
              <>
                <CardActions>
                  <TextField
                    className={classes.statusSelect}
                    // size={"small"}
                    name="status"
                    SelectProps={{ native: true }}
                    variant="outlined"
                    select
                    label="Clock In/Clock Out"
                    //disabled={!currentDate}
                    value={selectStatus}
                    onChange={(event) => {
                      clockInEmployees(event);
                      setSelectCurrentStatus(event.target.value);
                    }}
                  >
                    {status &&
                      status.map((st) => (
                        <option
                          key={`status-${st.id}-${st.jobSplitId}`}
                          value={`${st.id}_${st.jobSplitId || ""}`}
                        >
                          {st.description}
                        </option>
                      ))}
                  </TextField>
                  {clocking && (
                    <CircularProgress style={{ width: "24px" }} size={24} />
                  )}
                </CardActions>
              </>
            )}
          </div>
          <SidebarNav
            style={{ marginBottom: "0" }}
            className={classes.nav}
            pages={sidebarData}
            openDrawer={open}
            onClose={onClose}
          />
          <div className={classes.otherItems}>
            {!fieldTech && (
              <div>
                <Tooltip
                  arrow
                  placement="right"
                  title={
                    (!open && isDesktop && (
                      <Typography color="inherit">Squeegee Squad Dashboard</Typography>
                    )) ||
                    ""
                  }
                >
                  <IconButton
                    className={classes.signOutButton}
                    style={open ? { paddingLeft: 8 } : null}
                    color="inherit"
                    href="https://sites.google.com/squeegeesquad.com/dashboard/home"
                    target="_blank"
                  >
                    <OpenInBrowser />
                    {(open || !isDesktop) && (
                      <Typography className={classes.logoutText}>Squeegee Squad Dashboard</Typography>
                    )}
                  </IconButton>
                </Tooltip>
              </div>
            )}
            <div>
              <Tooltip
                arrow
                placement="right"
                title={
                  (!open && isDesktop && (
                    <Typography color="inherit">Logout</Typography>
                  )) ||
                  ""
                }
              >
                <IconButton
                  className={classes.signOutButton}
                  style={open ? { paddingLeft: 8 } : null}
                  color="inherit"
                  onClick={() => logout()}
                >
                  <InputIcon />
                  {(open || !isDesktop) && (
                    <Typography className={classes.logoutText}>Logout</Typography>
                  )}
                </IconButton>
              </Tooltip>
            </div>
          </div>
          <Footer
            style={{ marginTop: "auto" }}
            drawerOpen={open}
            toggleDrawer={handleToggleDrawer}
          />
        </div>
        <Snackbar
          anchorOrigin={{ vertical: "top", horizontal: "center" }}
          style={{ top: "64px" }}
          open={openSnackbar}
          autoHideDuration={3000}
          onClose={() => setOpenSnackBar(false)}
        >
          <Alert
            elevation={6}
            variant="filled"
            severity={isStatus.failed ? "error" : "success"}
          >
            <Typography color="inherit" variant="h6">
              {isStatus.msg}
            </Typography>
          </Alert>
        </Snackbar>

        <ModalAllEmployees
          openConfirm={openConfirmAllEmployees}
          closeConfirm={() => {
            setOpenConfirmAllEmployees(false);
          }}
          onConfirmOne={() => onEmployeeStatusChanged(false)}
          onConfirm={() => onEmployeeStatusChanged(true)}
          lstEmployees={listEmployeesActive}
        ></ModalAllEmployees>
      </Drawer>
    );
  } else {
    return (
      <Drawer
        anchor="left"
        classes={{ paper: clsx(classes.drawer, !open ? classes.collapse : "") }}
        onClose={onClose}
        open={open}
        variant={variant}
      >
        <div {...rest} className={clsx(classes.root, className)}>
          <Skeleton circle={true} height={open ? 100 : 48} width={open ? 100 : 48} />
          <Skeleton count={7} style={{ margin: "10px 0" }} />
        </div>
      </Drawer>
    );
  }
};

Sidebar.propTypes = {
  className: PropTypes.string,
  onClose: PropTypes.func,
  open: PropTypes.bool.isRequired,
  variant: PropTypes.string.isRequired,
};

export default Sidebar;
