import React, { useState, useEffect, useRef } from 'react'
import PropTypes from 'prop-types'
import { makeStyles } from '@material-ui/styles'
import {
    Typography, TextField, colors
} from '@material-ui/core'
import { ArrowDropDown } from '@material-ui/icons'
import Selected from './Selected'

const useStyles = makeStyles(theme => ({
    selectedBox: {
        border: '1px solid #bbbbbb',
        borderRadius: '4px',
        padding: '15px 40px 15px 14px',
        position: 'relative',
        cursor: 'pointer'
    },
    selectedOver: {
        width: '100%',
        display: 'flex',
        overflowX: 'auto',
        paddingBottom: '2px',
        '&::-webkit-scrollbar': {
            height: '5px'
        },
        '&::-webkit-scrollbar-track': {
            backgroundColor: '#e4e4e4',
            borderRadius: '60px'
        },
        '&::-webkit-scrollbar-thumb': {
            backgroundColor: '#9c9c9c',
            borderRadius: '60px'
        }
    },
    arrowDropDown: {
        position: 'absolute',
        top: 14,
        right: 10,
        color: '#797979',
    },
    options: {
        position: 'absolute',
        width: '100%',
        padding: '14px',
        top: '107%',
        opacity: 0,
        visibility: 'hidden',
        backgroundColor: '#ffffff',
        zIndex: 2,
        borderRadius: '4px',
        boxShadow: '0 0 20px 0 #bbbbbb'
    },
    overOptions: {
        height: '170px',
        overflowY: 'auto'
    },
    option: {
        padding: '5px',
        cursor: 'pointer',
        '&:hover': {
            backgroundColor: colors.green[600],
            color: '#ffffff'
        }
    },
}));

const MultiSelect = (props) => {
    const classes = useStyles();
    const selectRef = useRef();
    const inputReference = useRef();
    const { disabled, open, onToggle, selectedOptions, onChange, removeSelected } = props;

    const [filText, setFilText] = useState('');

    const onKeyUp = event => {
        if (event.keyCode === 13 && filText) {
            event.preventDefault();
            onChange(filText);
            setFilText('');
        }
    }
    const onChangeFilter = event => {
        const { value } = event.target;
        setFilText(value);
    }
    const setOnToggle = e => {
        e.stopPropagation();
        onToggle()
        setTimeout(()=> {
            inputReference.current.focus();
        }, 0)
    }

    useEffect(() => {
        if (open) {
            setFilText('')
        }
        const clickOutsideSelect = (event) => {
            if (open && selectRef.current && !selectRef.current.contains(event.target)) {
                onToggle();
            }
        }
        document.addEventListener('click', clickOutsideSelect);
        return () => document.removeEventListener('click', clickOutsideSelect)
    }, [open])

    return (
        <div ref={selectRef} style={{ position: 'relative', pointerEvents: disabled ? 'none' : 'auto', opacity: disabled ? 0.5 : 1 }}>
            <div className={classes.selectedBox} onClick={setOnToggle}>
                <div className={classes.selectedOver}>
                    {Array.isArray(selectedOptions) && selectedOptions.length > 0 ?
                        selectedOptions.map(op => (
                            <Selected key={op} value={op} remove={removeSelected} />
                        )) : (
                            <Typography>Equipment Needed</Typography>
                        )
                    }
                </div>
                <ArrowDropDown className={classes.arrowDropDown} />
            </div>
            <div className={classes.options} style={open ? { opacity: 1, visibility: 'visible' } : null}>
                <TextField
                    inputRef={inputReference}
                    fullWidth
                    placeholder='Equipment Needed'
                    variant='outlined'
                    value={filText}
                    onClick={e => e.stopPropagation()}
                    onChange={onChangeFilter}
                    onKeyUp={onKeyUp}
                    onBlur={() => {
                        onToggle()
                    }}
                />
            </div>
        </div>
    )
}

MultiSelect.propTypes = {
    disabled: PropTypes.bool,
    open: PropTypes.bool,
    onToggle: PropTypes.func,
    selectedOptions: PropTypes.array,
    onChange: PropTypes.func,
    removeSelected: PropTypes.func
}

export default MultiSelect;
