import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/styles';
import { Alert } from '@material-ui/lab'
import {
    Card, CardHeader, CardContent,
    Divider, FormControlLabel, Grid,
    Typography, TextField, colors,
    Button, CircularProgress, Snackbar, CardActions
} from '@material-ui/core';
import { GreenCheckbox } from 'components'
import apiConfig from 'apiConfig'
import axios from 'utils/axios'

const useStyles = makeStyles(theme => ({
    titleNote: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        [theme.breakpoints.down('sm')]: {
            flexDirection: 'column',
            alignItems: 'flex-start'
        }
    },
    saveBtn: {
        color: theme.palette.white,
        backgroundColor: colors.green[600],
        '&:hover': {
            backgroundColor: colors.green[900]
        }
    },
    stickyBtn: {
        marginBottom: 0,
        position: '-webkit-sticky',
        position: 'sticky',
        backgroundColor: '#f4f6f8',
        top: '-35px',
        zIndex: 2,
    },
}));

let mounted = false;
const Notes = props => {

    const classes = useStyles();

    const { customerId } = props;

    const [note, setNote] = useState({
        id: customerId,
        showNoteOnOpen: false,
        customerNote: '',
        routeNote: ''
    });
    const [isProgress, setIsProgress] = useState(false);
    const [openSnack, setOpenSnack] = useState(false);
    const [isSuccess, setIsSuccess] = useState({ failed: '', msg: '' });

    const onUpdateNote = () => {
        setIsProgress(true)
        axios.post(apiConfig.url.CUSTOMER_URL + apiConfig.url.CUSTOMERS_NOTE_UPDATE, note)
            .then(() => {
                setIsSuccess({ failed: false, msg: 'Record Updated' });
                setOpenSnack(true);
            })
            .catch(() => {
                setIsSuccess({ failed: true, msg: 'Update failed, please try it again.' });
                setOpenSnack(true);
            })
            .finally(() => setIsProgress(false))
    }

    useEffect(() => {
        mounted = true;
        axios.get(apiConfig.url.CUSTOMER_URL + apiConfig.url.CUSTOMERS_GET + customerId)
            .then(res => {
                if (mounted) {
                    setNote({
                        ...note,
                        customerNote: res.data.customerNote,
                        routeNote: res.data.routeNote,
                        showNoteOnOpen: res.data.showNoteOnOpen
                    })
                }
            })

        return () => mounted = false;
    }, [])

    return (
        <>
            <Grid className={classes.stickyBtn} container spacing={4}>
                <Grid item xs={12}>
                    <CardActions>
                        <Button
                            onClick={onUpdateNote}
                            className={classes.saveBtn}
                            disabled={isProgress}
                            variant="contained">
                            Save
                        </Button>
                        {isProgress && <CircularProgress size={24}/>}
                    </CardActions>
                </Grid>
            </Grid>
            <Card>
                <CardHeader title="Notes" />
                <Divider />
                <CardContent>
                    <Grid container spacing={2}>
                        <Grid item xs={12} style={{ paddingBottom: '0' }} className={classes.titleNote}>
                            <Typography>Customer Note - Office Staff only. Techs won't see this note</Typography>
                            <FormControlLabel
                                control={
                                    <GreenCheckbox
                                        checked={note.showNoteOnOpen}
                                        onChange={e => setNote({ ...note, showNoteOnOpen: e.target.checked })}
                                    />
                                }
                                label="Pop up when customer record opened?"
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <TextField
                                fullWidth
                                multiline
                                rows={10}
                                name="customerNote"
                                value={note.customerNote}
                                onChange={e => setNote({ ...note, customerNote: e.target.value })}
                                variant="outlined" />
                        </Grid>

                        <Grid item xs={12} style={{ paddingBottom: '0' }} className={classes.titleNote}>
                            <Typography>Field Tech Note - All field technicians will see this.</Typography>
                        </Grid>
                        <Grid item xs={12}>
                            <TextField
                                fullWidth
                                multiline
                                rows={10}
                                name="routeNote"
                                value={note.routeNote}
                                onChange={e => setNote({ ...note, routeNote: e.target.value })}
                                variant="outlined" />
                        </Grid>
                    </Grid>
                </CardContent>
            </Card>

            <Snackbar
                open={openSnack}
                anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
                autoHideDuration={2000}
                onClose={() => setOpenSnack(false)}>
                <Alert
                    elevation={6} variant="filled" severity={isSuccess.failed ? 'error' : 'success'}>
                    <Typography
                        color="inherit"
                        variant="h6">
                        {isSuccess.msg}
                    </Typography>
                </Alert>
            </Snackbar>
        </>
    );
};

Notes.propTypes = {
    className: PropTypes.string
};

export default Notes;
