import React, { useState, useEffect } from 'react'
import { useHistory, useLocation, useParams } from 'react-router-dom'
import { useSelector, useDispatch } from 'react-redux'
import { TextField, CircularProgress, IconButton, Snackbar, Typography, useMediaQuery } from '@material-ui/core'
import { Alert } from '@material-ui/lab'
import { makeStyles } from '@material-ui/styles'
import ArrowBackIosRoundedIcon from '@material-ui/icons/ArrowBackIosRounded'
import ArrowForwardIosRoundedIcon from '@material-ui/icons/ArrowForwardIosRounded';
import DraggableSource from './components/DraggableSource'
import moment from 'moment'
import { useDrop } from 'react-dnd'
import { formatTwoPDecimal } from "utils/formatNumber";

import apiConfig from 'apiConfig'
import axios from 'utils/axios'

const useStyles = makeStyles(theme => ({
    collapse: {
        width: '100%',
        flexShrink: 0,
        overflow: 'hidden',
        transition: 'width 200ms ease-in',
        height: '100%'
    },
    collapseText:{
        mozTransform: 'translateX(-50%) translateY(-50%) rotate(-90deg)',
        webkitTransform: 'translateX(-50%) translateY(-50%) rotate(-90deg)',
        transform: 'translateX(-33%) translateY(0%) rotate(90deg)',
        position: 'absolute',
        top: '81px',
        left: '-9px',
        color:'#263238',
    },
    collapseContainer:{
        position: 'relative',
        width: '150px',
        height: '150px'
    },
    expandIcon: {
        padding: 0,
    },
    expand: {
        width: '100%'
    },
    noexpand: {
        width: 24
    },
    sourceType: {
        display: 'flex',
        alignItems: 'center',
        marginBottom: 16,
        marginTop: 10,
        marginLeft: 10
    },
    overBox: {
        flex: 1,
        overflowY: 'auto',
        border: '1px solid #cbcbcb',
        borderRadius: 6,
        minHeight: 40,
        maxHeight: 'calc(100%)',
        '&::-webkit-scrollbar': {
            width: '10px'
        },
        '&::-webkit-scrollbar-track': {
            backgroundColor: '#e4e4e4',
            borderRadius: '60px'
        },
        '&::-webkit-scrollbar-thumb': {
            backgroundColor: '#9c9c9c',
            borderRadius: '60px'
        }
    },
    routeSource: {
        maxHeight: '100%',
        width: 'calc(100% - 5px)',
        flexShrink: 0,
        height: '100%',
        display: 'flex',
        flexDirection: 'column',
    }
}));

const pageSize = 30;

const RouteSources = ({ setDrag, recurJob, setExpand , disableJobResizing }) => {
    const classes = useStyles();
    const { date } = useParams();
    const dispatch = useDispatch();
    const sm = useMediaQuery("(max-width: 769px)");
    const location = useLocation();
    const history = useHistory();

    const { routes, onDragType } = useSelector(state => state.RoutesReducer);
    const { routeJobs, loadingJob , pageNum } = useSelector(state => state.JobsReducer);
    const { routeEmployees } = useSelector(state => state.EmployeesReducer);
    const { routeEquipment } = useSelector(state => state.EquipmentsReducer);
    const [isStatus, setStatus] = useState({ failed: false, msg: '' });
    const [openSnackbar, setOpenSnackbar] = useState(false);
    const [loading, setLoading] = useState(false);
    const [sourceType, setSourceType] = useState("jobs");
    const [expandSource, setExpandSource] = useState(true);
    const [pageJob, setPageJob] = useState(pageNum);
    const [pageEquipment, setPageEquipment] = useState(1);
    const [pageEmployees, setPageEmployees] = useState(1);
    const [totalPageJob, setTotalPageJob] = useState(1);
    const [totalPageEquipment, setTotalPageEquipment] = useState(1);
    const [totalPageEmployees, setTotalPageEmployees] = useState(1);
    const [selected, setSelected] = useState({
        Job: null,
        Employee: null,
        Equipment: null
    });
    const tobeScheduledJobs = routeJobs && routeJobs.filter(job => job.jobStage !== 1) || null;

    // handle drag and drop
    const [{ isOverJob }, dropJob] = useDrop({
        accept: 'Job',
        drop: item => handleDropBackJobItem(item),
        collect: (monitor) => ({
            isOverJob: monitor.isOver()
        })
    });
    const [{ isOverEmp }, dropEmployee] = useDrop({
        accept: 'Employee',
        drop: item => handleDropBackEmployeeItem(item),
        collect: (monitor) => ({
            isOverEmp: monitor.isOver()
        })
    });
    const [{ isOverEqp }, dropEquipment] = useDrop({
        accept: 'Equipment',
        drop: item => handleDropBackEquipmentItem(item),
        collect: (monitor) => ({
            isOverEqp: monitor.isOver()
        })
    });

    const drop = {
        'jobs': dropJob,
        'employees': dropEmployee,
        'equipments': dropEquipment
    }
    const isOver = {
        'jobs': isOverJob,
        'employees': isOverEmp,
        'equipments': isOverEqp
    }

    const handleDropBackJobItem = (item) => {
        if (item.oldRouteId !== undefined) {
            if (item.isRecurringJob) {
                recurJob(recur => updateRoute(item, recur), null, true);
            } else {
                updateRoute(item);
            }
        }
    }

    const updateRoute = ({ oldRouteId, id }, recur = false) => {
        if (oldRouteId !== undefined) {
            const route = routes.find(route => route.id === oldRouteId);
            const routeRemove = route.routeJobs.find(job => job.jobSplitId === id);
            routeRemove.estimatedHours = routeRemove.job.estimatedHours;
            routeRemove.customerName = routeRemove.job.customerName;
            routeRemove.equipment = routeRemove.job.equipment;
            routeRemove.city = routeRemove.job.city || '';
            routeRemove.address1 = routeRemove.job.address1 || '';
            routeRemove.state = routeRemove.job.state || '';
            routeRemove.zipCode = routeRemove.job.zipCode || '';
            routeRemove.latitude = routeRemove.job.latitude;
            routeRemove.longitude = routeRemove.job.longitude;
            routeRemove.price = routeRemove.job.price;
            routeRemove.totalSplitJobs = routeRemove.job.totalSplitJobs;
            routeRemove.isCustomerService = routeRemove.job.isCustomerService;

            const routeData = {
                ...route,
                routeEquipment: !route.routeEquipment ? [] : [...route.routeEquipment],
                routeEmployees: !route.routeEmployees ? [] : [...route.routeEmployees],
                routeJobs: route.routeJobs.filter(job => job.jobSplitId !== id)
            };

            let requestForRoute;
            if (recur) {
                requestForRoute = axios.delete(apiConfig.url.BASE_URL + apiConfig.url.RECUR_CLONE.replace('{routeId}', oldRouteId) + `?jobSplitId=${id}&allRecurringRoutes=true`);
            } else {
                requestForRoute = axios.put(apiConfig.url.BASE_URL + apiConfig.url.ROUTE + `${date}/` + oldRouteId, routeData);
            }
            // const requestForJob = axios.patch(apiConfig.url.BASE_URL + 'job', { id, jobStage: 3 });

            dispatch({ type: 'IS_UPDATE', status: true });
            Promise.all([requestForRoute])
                .then(values => {
                    dispatch({ type: 'REMOVE_JOB_ROUTE', routeId: oldRouteId, jobSplitId: id });
                    dispatch({ type: 'CHANGE_JOB_STAGE', jobSplitId: id, jobStage: 3 })

                    dispatch({ type: 'ADD_JOB', jobSplitId: id, routeRemove: routeRemove })

                    if (route.routeJobs.length === 0) {
                        if (recur) {
                            dispatch({ type: 'REMOVE_ROUTE', routeId: oldRouteId });
                        } else {
                            dispatch({ type: "UPDATE_ROUTE", routeId: oldRouteId, route: { ...route, recurring: false, recurringRouteParentId: null } });
                        }
                    }
                    
                })
                .catch(() => {
                    setStatus({ failed: true, msg: 'Delete failed, please try again later.' });
                    setOpenSnackbar(true)
                })
                .finally(() => dispatch({ type: 'IS_UPDATE', status: false }))
        }
    }
    const handleDropBackEmployeeItem = ({ oldRouteId, id }) => {
        if (oldRouteId !== undefined) {
            const route = routes.find(route => route.id === oldRouteId);
            const rj = route.routeJobs;
            rj.map(job => {
                const localEndDate = new Date(job.jobStart);
                job.estimatedHours = formatTwoPDecimal(job.estimatedHours * (disableJobResizing ? 1 : (route.routeEmployees.length === 0 ? 1 : route.routeEmployees.length) / (route.routeEmployees.length - 1 <= 0 ? 1 : route.routeEmployees.length - 1)));
                const hour = localEndDate.getHours() + Math.round((localEndDate.getMinutes() / 60) * 100) / 100 + (job.estimatedHours);
                localEndDate.setHours(Math.floor(hour));
                localEndDate.setMinutes(Math.round((hour - Math.floor(hour)) * 60));
                job.jobEnd = new Date(localEndDate).toISOString();
            })

            const routeData = {
                ...route,
                routeEquipment: !route.routeEquipment ? [] : [...route.routeEquipment],
                routeEmployees: route.routeEmployees.filter(emp => emp.employeeId !== id),
                routeJobs: !route.routeJobs ? [] : [...rj]
            };
            dispatch({ type: 'IS_UPDATE', status: true });
            axios.put(apiConfig.url.BASE_URL + apiConfig.url.ROUTE + `${date}/` + oldRouteId, routeData)
                .then(() => {
                    let routeEmployees = route.routeEmployees.find(emp => emp.employeeId === id);
                    dispatch({ type: 'REMOVE_EMP_ROUTE', routeId: oldRouteId, employeeId: id });
                    routeEmployees.id = routeEmployees.employeeId
                    dispatch({ type: 'ADD_EMPLOYEE', employee: routeEmployees })
                })
                .catch(() => {
                    setStatus({ failed: true, msg: 'Delete failed, please try again later.' });
                    setOpenSnackbar(true)
                })
                .finally(() => dispatch({ type: 'IS_UPDATE', status: false }))
        }
    }
    const handleDropBackEquipmentItem = ({ oldRouteId, id }) => {
        if (oldRouteId !== undefined) {
            const route = routes.find(route => route.id === oldRouteId);
            const addEquipment = route.routeEquipment.find(eqp => eqp.id === id)
            const routeData = {
                ...route,
                routeEquipment: route.routeEquipment.filter(eqp => eqp.id !== id),
                routeEmployees: !route.routeEmployees ? [] : [...route.routeEmployees],
                routeJobs: !route.routeJobs ? [] : [...route.routeJobs]
            };
            dispatch({ type: 'IS_UPDATE', status: true });
            axios.put(apiConfig.url.BASE_URL + apiConfig.url.ROUTE + `${date}/` + oldRouteId, routeData)
                .then(() => {
                    dispatch({ type: 'REMOVE_EQP_ROUTE', routeId: oldRouteId, id });
                    dispatch({ type: 'ADD_EQUIPMENT', addEquipment: addEquipment })
                })
                .catch(() => {
                    setStatus({ failed: true, msg: 'Delete failed, please try again later.' });
                    setOpenSnackbar(true)
                })
                .finally(() => dispatch({ type: 'IS_UPDATE', status: false }))
        }
    }

    useEffect(() => {
        const stateRoute = location.state;
        if (stateRoute && stateRoute.jobId) {
            setSelected({
                ...selected,
                Job: stateRoute.jobId
            })
            history.replace();
        }
    }, [location])

    const onChangeSelected = (type, id) => {
        setSelected({
            ...selected,
            [type]: id
        })
    }

    const locateInRoute = ({ jobSplitId, latitude, longitude }) => {
        dispatch({ type: 'SHOW_DISTANCE', showDistance: false })
        dispatch({ type: 'IS_UPDATE', status: true });
        if (latitude != null && longitude != null) {
            dispatch({ type: 'LOCATE_ON_ROUTE_COORD', locateOnRouteCoord: { latitude, longitude } });
        }
        axios.get(apiConfig.url.BASE_URL + apiConfig.url.ROUTE_DISTANCE, { params: { jobSplitId, routeDate: date } })
            .then(res => {
                const routeDistance = routes.map(item => {
                    const distance = res.data.routeDistances.find(x => x.routeId === item.id);
                    const routeDistance = item.routeJobs.map(job => {
                        if (distance) {
                            const distanceJob = distance.jobDistances.find(y => y.jobSplitId === job.jobSplitId);
                            return { ...job, distance: distanceJob && distanceJob.distance ? parseFloat(distanceJob.distance).toFixed(2) : undefined }
                        } else {
                            return { ...job, distance: undefined }
                        }

                    })
                    return { ...item, routeJobs: routeDistance, distance: distance ? parseFloat(distance.distance).toFixed(2) : undefined }
                })

                latitude = res.data.coordinate.latitude;
                longitude = res.data.coordinate.longitude;

                dispatch({ type: 'CHANGE_ORDER', orderType: 'DISTANCE' })
                dispatch({ type: 'INIT_ROUTES', routes: routeDistance })
                dispatch({ type: 'LOCATE_ON_ROUTE_COORD', locateOnRouteCoord: { latitude, longitude } })
            }).finally(() => {
                dispatch({ type: 'IS_UPDATE', status: false });
            })
    }

    const getJobs = (page) => {
        if (loadingJob) return;
        const pageNum = page || pageJob;
        if (pageNum > totalPageJob) {
            dispatch({ type: 'LOADING_JOB', loadingJob: false })
            return
        }
        axios.get(apiConfig.url.BASE_URL + apiConfig.url.TO_BE_SCHEDULED_JOBS + '&pageNumber=' + pageNum + '&pageSize=' + pageSize, { params: { date: moment(date).utc().toISOString() } })
            .then(res => {
                res.data.data.map(job => {
                    job['job'] = {
                        city: job.city || '',
                        price: job.price,
                        estimatedHours: job.estimatedHours
                    }
                });
                pageNum === 1 ?
                    dispatch({ type: 'INIT_JOB', routeJobs: res.data.data }) :
                    dispatch({ type: 'LOAD_MORE_JOB', routeJobs: res.data.data });
                setTotalPageJob(res.data.pageCount);
                if (pageNum * 1 <= res.data.pageCount * 1) {
                    setPageJob(pageNum + 1);
                    dispatch({ type: 'CHANGE_PAGE', pageNum: (pageNum + 1) });      
                }
            })
            .catch(() => {
                dispatch({ type: 'LOAD_MORE_JOB', routeJobs: [] })
            }).finally(() => {
                setLoading(false);
                dispatch({ type: 'LOADING_JOB', loadingJob: false })
            })
    }

    const getEquipments = (page) => {
        const pageNum = page || pageEquipment;
        if (pageNum > totalPageEquipment || loadingJob) {
            dispatch({ type: 'LOADING_JOB', loadingJob: false })
            return
        }
        axios.get(apiConfig.url.BASE_URL + apiConfig.url.EQUIPMENTS_FOR_ROUTE + date + '&pageNumber=' + pageNum)
            .then(res => {
                pageNum === 1 ?
                    dispatch({ type: 'INIT_EQUIPMENT', routeEquipment: res.data.data }) :
                    dispatch({ type: 'LOAD_MORE_EQUIPMENT', routeEquipment: res.data.data })
                setTotalPageEquipment(res.data.pageCount);
                if (pageNum * 1 <= res.data.pageCount * 1) {
                    setPageEquipment(pageNum + 1);
                }
            })
            .catch(() => {
                dispatch({ type: 'LOAD_MORE_EQUIPMENT', routeEquipment: [] });
            })
            .finally(() => {
                setLoading(false);
                dispatch({ type: 'LOADING_JOB', loadingJob: false })
            })
    }

    const getEmployees = (page) => {
        if (loadingJob) return;
        const pageNum = page || pageEmployees;
        if (pageNum > totalPageEmployees || loadingJob) {
            dispatch({ type: 'LOADING_JOB', loadingJob: false })
            return
        }
        axios.get(apiConfig.url.USER_URL + apiConfig.url.COMPANY_EMPLOYEES_ROUTE,
            { params: { date: moment(date).utc().toISOString(), pageNumber: pageNum, pageSize: pageSize } })
            .then(res => {
                pageNum === 1 ?
                    dispatch({ type: 'INIT_EMPLOYEE', employees: res.data.users }) :
                    dispatch({ type: 'LOAD_MORE_EMPLOYEES', routeEmployees: res.data.users })
                setTotalPageEmployees(res.data.pageCount);
                if (pageNum * 1 <= res.data.pageCount * 1) {
                    setPageEmployees(pageNum + 1);
                }
            })
            .catch(() => {
                dispatch({ type: 'LOAD_MORE_EMPLOYEES', routeEmployees: [] });
            })
            .finally(() => {
                setLoading(false);
                dispatch({ type: 'LOADING_JOB', loadingJob: false })
            })
    }

    useEffect(() => {
        //sourceType === 1 && dispatch({ type: 'INIT_JOB', routeJobs: [] })
        sourceType === 2 && dispatch({ type: 'INIT_EQUIPMENT', routeEquipment: [] })
        sourceType === 3 && dispatch({ type: 'INIT_EMPLOYEE', employees: [] })
        setLoading(true);

        //getJobs(1);
        getEquipments(1);
        getEmployees(1);
    }, [date])

    useEffect(() => {
        if (!onDragType) return;
        setSourceType(onDragType)
    }, [onDragType])

    useEffect(() => {
        dispatch({ type: "CHANGE_WIDTH", expanded: expandSource });
        setExpand(expandSource);
    }, [expandSource])

    return (
        <div className={`${classes.collapse} ${expandSource ? classes.expand : classes.noexpand}`}
            style={sm ? { width: 0 } : null}>
            {!expandSource && 
                <div className={`${classes.collapseContainer}`}>
                    <IconButton className={classes.expandIcon} onClick={() => setExpandSource(true)}>
                        <ArrowForwardIosRoundedIcon />
                    </IconButton>
                    <div className={`${classes.collapseText}`}>Route Resources</div>
                </div>
            }
            <div className={classes.routeSource} style={!expandSource ? { display: 'none' } : null}>
                <div className={classes.sourceType}>
                    <TextField style={{ marginRight: 10 }}
                        label={"Route Resources"}
                        fullWidth
                        variant='outlined'
                        select
                        SelectProps={{ native: true }}
                        value={sourceType}
                        onClick={e => e.stopPropagation()}
                        onChange={e => setSourceType(e.target.value)}>
                        <option value='jobs'>Jobs</option>
                        <option value='employees'>Employees</option>
                        <option value='equipments'>Equipment</option>
                    </TextField>
                    <IconButton className={classes.expandIcon} onClick={() => setExpandSource(false)}>
                        <ArrowBackIosRoundedIcon />
                    </IconButton>
                </div>
                <div ref={drop[sourceType]} className={classes.overBox}
                    style={{ backgroundColor: isOver[sourceType] ? '#c5c5c5' : '#ffffff' }} onScroll={(e) => {
                        if (e.target !== e.currentTarget) return;
                        const bottom = Math.abs(e.target.scrollHeight - e.target.scrollTop - e.target.clientHeight) <= 0.5;
                        if (bottom) {
                            dispatch({ type: 'LOADING_JOB', loadingJob: true })
                            if (sourceType === 'jobs') {
                                getJobs();
                            }
                            if (sourceType === 'employees') {
                                getEmployees();
                            }
                            if (sourceType === 'equipments') {
                                getEquipments();
                            }
                        }
                    }}>
                    <div className={classes.sources}>
                        {sourceType === 'jobs' && (
                            <>
                                {!tobeScheduledJobs || loading &&
                                    <div style={{ height: '40px', marginTop: '16px', textAlign: 'center' }}>
                                        <CircularProgress size={24} />
                                    </div>
                                }
                                {tobeScheduledJobs && tobeScheduledJobs.map((job, index) => (
                                    <DraggableSource
                                        setDrag={setDrag}
                                        key={`${job.jobSplitId}-${index}`}
                                        isSelected={selected.Job === job.jobId}
                                        type='Job'
                                        source={job}
                                        changeSelected={() => onChangeSelected('Job', job.jobId)}
                                        locateInRoute={() => {
                                            locateInRoute(job, moment(date).utc().toISOString())
                                        }}
                                    />
                                ))}
                                {
                                    loadingJob &&
                                    <div style={{ height: '40px', marginTop: '16px', textAlign: 'center' }}>
                                        <CircularProgress size={24} />
                                    </div>
                                }
                            </>
                        )}
                        {sourceType === 'employees' && (
                            <>
                                {!routeEmployees || loading &&
                                    <div style={{ height: '40px', marginTop: '16px', textAlign: 'center' }}>
                                        <CircularProgress size={24} />
                                    </div>
                                }
                                {Array.isArray(routeEmployees) && routeEmployees.map((emp, index) => (
                                    <DraggableSource
                                        date={date}
                                        setDrag={setDrag}
                                        key={`${emp.id}-${index}`}
                                        isSelected={selected.Employee === emp.id}
                                        type='Employee'
                                        source={emp}
                                        changeSelected={() => onChangeSelected('Employee', emp.id)}
                                    />
                                ))}
                                {
                                    loadingJob &&
                                    <div style={{ height: '40px', marginTop: '16px', textAlign: 'center' }}>
                                        <CircularProgress size={24} />
                                    </div>
                                }
                            </>
                        )}
                        {sourceType === 'equipments' && (
                            <>
                                {!routeEquipment || loading &&
                                    <div style={{ height: '40px', marginTop: '16px', textAlign: 'center' }}>
                                        <CircularProgress size={24} />
                                    </div>
                                }
                                {Array.isArray(routeEquipment) && routeEquipment.map((eqp, index) => (
                                    <DraggableSource
                                        setDrag={setDrag}
                                        key={`${eqp.id}-${index}`}
                                        isSelected={selected.Equipment === eqp.id}
                                        type='Equipment'
                                        source={eqp}
                                        changeSelected={() => onChangeSelected('Equipment', eqp.id)}
                                    />
                                ))}
                                {
                                    loadingJob &&
                                    <div style={{ height: '40px', marginTop: '16px', textAlign: 'center' }}>
                                        <CircularProgress size={24} />
                                    </div>
                                }
                            </>
                        )}
                    </div>
                </div>
            </div>

            <Snackbar open={openSnackbar}
                onClose={() => setOpenSnackbar(false)}
                autoHideDuration={2000}
                anchorOrigin={{ vertical: 'top', horizontal: 'center' }}>
                <Alert elevation={6} variant='filled' severity={isStatus.failed ? 'error' : 'success'}>
                    <Typography color="inherit" variant="h6">
                        {isStatus.msg}
                    </Typography>
                </Alert>
            </Snackbar>
        </div>
    )
}

export default RouteSources;
