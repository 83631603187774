import { TableBody } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import React from 'react';
import { SortableContainer } from 'react-sortable-hoc';
import SortableItem from './SortableItem';

const useStyles = makeStyles((theme) => ({
  tableBody: {
    borderBottom: '1px solid #888888',
  },
}));

const SortableList = SortableContainer(({ priceDetails, ...props }) => {
  const classes = useStyles();
  return (
    <TableBody className={classes.tableBody}>
      {priceDetails.map((item, index) => (
        <SortableItem index={index} key={`price-${index}`} dataIndex={index} item={item} {...props} />
      ))}
    </TableBody>
  );
});

export default SortableList;
