import {
  Card,
  CardContent,
  CardHeader,
  Collapse,
  Divider,
  Grid,
} from '@material-ui/core';
import { KeyboardArrowDown, KeyboardArrowUp } from '@material-ui/icons';
import { makeStyles } from '@material-ui/styles';
import apiConfig from 'apiConfig';
import moment from 'moment';
import React, { useEffect, useMemo, useState } from 'react';
import axios from 'utils/axios';
import { formatNumber } from 'utils/formatNumber';

const useStyles = makeStyles((theme) => ({
  title: {
    cursor: 'pointer',
    '& svg': {
      marginRight: theme.spacing(1),
    },
  },
  contentTitle: {
    marginBottom: theme.spacing(2),
    textAlign: 'left'

  },
}));

const Costs = (props) => {
  const { data } = props;

  const classes = useStyles();

  const [expanded, setExpanded] = useState(true);
  const [price, setPrice] = useState(0);
  const [discount, setDiscount] = useState(0);
  const [commissionLaborPaid, setCommissionLaborPaid] = useState(0);
  const [salesCommissionsPaid, setSalesCommissionsPaid] = useState(0);
  const [upsalesCommissionsPaid, setUpsalesCommissionsPaid] = useState(0);
  const [totalHourlyHoursLogged, setTotalHourlyHoursLogged] = useState(0);

  const subtotal = useMemo(

    () => formatNumber(price) - formatNumber(discount),

    [price, discount]
  );


  const total = useMemo(
    () => subtotal + +formatNumber(data?.tip),
    [subtotal, data]
  );

  const wageTotal = useMemo(
    () => commissionLaborPaid + totalHourlyHoursLogged,

    [commissionLaborPaid, totalHourlyHoursLogged]
  );

  const toggleExpanded = () => {
    setExpanded(!expanded);
  };

  const getCommissionLabors = () => {
    Promise.all(
      data.jobSplits.map(
        split => axios.get(
          apiConfig.url.BASE_URL + apiConfig.url.CUSTOMER_JOB_COMMISSION_LABOR_LIST,
          {
            params: {
              jobSplitId: split.id
            }
          }
        )
      )
    ).then(responses => {
      let _commissionLaborPaid = 0;
      responses.filter(res => res.data).forEach(res => {
        res.data.forEach(data => {
          _commissionLaborPaid += (data.amountDueToPerson ?? 0);
        });
      });

      setCommissionLaborPaid(_commissionLaborPaid);
    });
  };

  const getSalesCommissions = () => {
    let _salesCommissionsPaid = 0;
    axios.get(apiConfig.url.BASE_URL + apiConfig.url.CUSTOMER_JOB_COMMISSION_SALE_LIST,
      {
        params: {
          jobSplitId: data.id
        }
      })
      .then(res => {
        if (res?.data) {
          res.data.forEach(data => {
            _salesCommissionsPaid += (data.amountDueToPerson ?? 0);
          });
        }

        setSalesCommissionsPaid(_salesCommissionsPaid);
      });
  };

  const getUpsalesCommissions = () => {
    let _upsalesCommissionsPaid = 0;
    axios.get(apiConfig.url.BASE_URL + apiConfig.url.CUSTOMER_JOB_COMMISSION_UPSALE_LIST,
      {
        params: {
          jobSplitId: data.id
        }
      })
      .then(res => {
        if (res?.data) {
          res.data.forEach(data => {
            _upsalesCommissionsPaid += (data.amountDueToPerson ?? 0);
          });
        }

        setUpsalesCommissionsPaid(_upsalesCommissionsPaid);
      });
  };

  const getHourlyHoursLogged = () => {
    Promise.all(
      data.jobSplits.map(
        split => axios.get(apiConfig.url.BASE_URL + apiConfig.url.EMPLOYEE_GET_LOGHOURS_JOBSPLITID.replace('{jobSplitId}', split.id))
      )
    ).then(responses => {
      let _totalHourlyHoursLogged = 0;
      responses.filter(res => res.data).forEach(res => {
        res.data.forEach(data => {
          let _totalHours = 0;
          data.entries.filter(x => x.appliedHourlyRate).forEach(entry => {
            const totalHours = moment.duration(moment(entry.workEnd).diff(entry.workStart)).asHours();
            _totalHours += totalHours;
          });

          _totalHourlyHoursLogged += _totalHours * data.hourlyRate;

        });
      });

      setTotalHourlyHoursLogged(_totalHourlyHoursLogged);
    });
  };

  useEffect(() => {
    if (data?.id) {
      let _price = 0;
      if (data.jobItems?.length) {
        for (const item of data.jobItems) {
          _price += item.price * item.quantity;
        }

        setPrice(_price);
      }

      let _discount = 0;
      if (data.discountPercent % 1 === 0) {
        _discount = (_price * data.discountPercent) / 100;
      } else {
        _discount = data.discountDollars || 0;
      }
      setDiscount(_discount);

      getCommissionLabors();
      getSalesCommissions();
      getUpsalesCommissions();
      getHourlyHoursLogged();
    }
  }, [data]);

  return (
    <Card style={{ marginBottom: "16px" }}>
      <CardHeader
        className={classes.title}
        onClick={toggleExpanded}
        title="Costs"
        action={expanded ? <KeyboardArrowUp /> : <KeyboardArrowDown />}
      ></CardHeader>
      <Divider />
      <Collapse in={expanded} timeout="auto" unmountOnExit>
        <CardContent>
          <Grid container>
            <Grid item xs={12} sm={5} md={6}>
              <table style={{ marginBottom: 32, width: 150 }}>
                <tr>
                  <th colSpan={2}><h2 className={classes.contentTitle}>Charges</h2></th>
                </tr>
                <tr>
                  <td>Price:</td>
                  <td align="right">${formatNumber(price, 2)}</td>
                </tr>
                <tr>
                  <td>Discount:</td>
                  <td align="right">${formatNumber(discount, 2)}</td>
                </tr>
                <tr>
                  <td><b>Subtotal:</b></td>
                  <td align="right"><b>${formatNumber(subtotal, 2)}</b></td>
                </tr>
                <tr>
                  <td>Tips:</td>
                  <td align="right">${formatNumber(data?.tip, 2)}</td>
                </tr>
                <tr>
                  <td>Total:</td>
                  <td align="right">${formatNumber(subtotal + (data?.tip ?? 0), 2)}</td>
                </tr>
              </table>

              <table style={{ width: 150 }}>
                <tr>
                  <th colSpan={2}><h2 className={classes.contentTitle}>Summary</h2></th>
                </tr>
                <tr>
                  <td>Revenue:</td>
                  <td align="right">${formatNumber(subtotal, 2)}</td>
                </tr>
                <tr>
                  <td>Wages:</td>
                  <td align="right">${formatNumber(wageTotal, 2)}</td>
                </tr>
                <tr>
                  <td><b>Profit:</b></td>
                  <td align="right"><b>${formatNumber(subtotal - wageTotal, 2)}</b></td>
                </tr>
                <tr>
                  <td><b>Margin:</b></td>
                  <td align="right">
                    <b>
                      {subtotal === 0
                        ? 0
                        : formatNumber((subtotal - wageTotal) / subtotal * 100, 2)}%
                    </b>
                  </td>
                </tr>
              </table>

            </Grid>

            <Grid item xs={12} sm={7} md={6}>
              <table style={{ width: 300 }}>
                <tr>
                  <th colSpan={2}><h2 className={classes.contentTitle}>Labor</h2></th>
                </tr>
                <tr>
                  <td>Total Commission Labor Paid:</td>
                  <td align="right">${formatNumber(commissionLaborPaid, 2)}</td>
                </tr>
                <tr>
                  <td>Total Sales Commissions Paid:</td>
                  <td align="right">${formatNumber(salesCommissionsPaid, 2)}</td>
                </tr>
                <tr>
                  <td>Total Upsales Commissions Paid:</td>
                  <td align="right">${formatNumber(upsalesCommissionsPaid, 2)}</td>
                </tr>
                <tr>
                  <td>Total Hourly Hours Logged:</td>
                  <td align="right">${formatNumber(totalHourlyHoursLogged, 2)}</td>
                </tr>
                <tr>
                  <td><b>Wage Total:</b></td>
                  <td align="right"><b>${formatNumber(wageTotal, 2)}</b></td>
                </tr>
              </table>

            </Grid>
          </Grid>
        </CardContent>
      </Collapse>
    </Card>
  );
};

export default Costs;
