import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/styles';
import {
  Grid, Card, CardContent, Avatar, Typography,
} from '@material-ui/core';

const useStyles = makeStyles(theme => ({
  content: {
    display: 'flex',
    alignItems: 'center',
    '&:last-child': {
      paddingBottom: 'none'
    }
  },
  contentLeft: {
    display: 'flex',
  },
  conAvatar: {
    marginRight: theme.spacing(2)
  },
  avatar: {
    height: 120,
    width: 120,
    [theme.breakpoints.down('sm')]: {
      height: 100,
      width: 100
    }
  },
  mainName: {
    fontSize: '20px',
    fontWeight: 500
  }
}));

const CompanyCard = ({ company, edit }) => {

  const classes = useStyles();

  const { logoUrl, name, addresses, contact, phones ,email } = company;

  return (
    <Card style={{ cursor: 'pointer' }} onClick={edit}>
      <CardContent className={classes.content}>
        <Grid container spacing={3} className={classes.cusFont}>
          <Grid item xs={12} md={6} className={classes.contentLeft}>
            <div className={classes.conAvatar}>
              <Avatar
                variant='rounded'
                className={classes.avatar}
                src={logoUrl} />
            </div>
            <div className={classes.infor}>
              <Typography className={classes.mainName} gutterBottom>
                <strong>{name || 'N/A'}</strong>
              </Typography>
              <Typography>{(addresses && addresses[0].address1) || ''}</Typography>
              <Typography>{(addresses && addresses[0].address2) || ''}</Typography>
              <Typography>
                {addresses && `${addresses[0].city || ''}, ${addresses[0].state?.code || ''} ${addresses[0].zipCode || ''}`}
              </Typography>
            </div>
          </Grid>

          <Grid item xs={12} md={5}>
            <Typography className={classes.mainName} gutterBottom>
              <strong>{(contact && contact.name) || ''}</strong>
            </Typography>
            <Typography>
              <strong>Email: </strong>
              {(email) || ''}
            </Typography>
            <Typography>
              <strong>Phone: </strong>
              {(phones && phones.map(x=>x.phoneNumber).join(',')) || ''}
            </Typography>
          </Grid>
        </Grid>
      </CardContent>
    </Card >
  );
};

CompanyCard.propTypes = {
  company: PropTypes.object,
  edit: PropTypes.func
};

export default CompanyCard;
