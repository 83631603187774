import { data, internal, math, HtmlMarker } from 'azure-maps-control';

/** A control that uses the browser's geolocation API to locate the user on the map. */
export class GeolocationControl extends internal.EventEmitter {
  /****************************
   * Constructor
   ***************************/

  /**
   * A control that displays a scale bar relative to the pixel resolution at the center of the map.
   * @param options Options for defining how the control is rendered and functions.
   */
  constructor(options) {
    super();

    /****************************
     * Private Properties
     ***************************/
    this._map = null;
    this._container = null;
    this._button = null;
    this._options = {
      style: 'light',
      positionOptions: {
        enableHighAccuracy: false,
        maximumAge: 0,
        timeout: 6000 
      },
      showUserLocation: true,
      trackUserLocation: false,
      markerColor: 'DodgerBlue',
      maxZoom: 15,
      calculateMissingValues: false,
      updateMapCamera: true
    };
    this._darkColor = '#011c2c';
    this._hclStyle = null;

    /** Resource array values: 0 - enableTracking, 1 - disableTracking, 2 - myLocation, 3 - title */
    this._resource = null;
    this._gpsMarker = null;

    this._watchId = null;
    this._isActive = false;
    this._updateMapCamera = true;
    this._lastKnownPosition = null;

    

    if (options) {
      if (options.positionOptions) {
        this._options.positionOptions = Object.assign(this._options.positionOptions, options.positionOptions);
      }
      if (options.style) {
        this._options.style = options.style;
      }
      if (options.markerColor) {
        this._options.markerColor = options.markerColor;
      }
      if (typeof options.showUserLocation === 'boolean') {
        this._options.showUserLocation = options.showUserLocation;
      }
      if (typeof options.trackUserLocation === 'boolean') {
        this._options.trackUserLocation = options.trackUserLocation;
      }
      if (typeof options.maxZoom === 'number') {
        this._options.maxZoom = Math.min(Math.max(options.maxZoom, 0), 24);
      }
      if (typeof options.calculateMissingValues === 'boolean') {
        this._options.calculateMissingValues = options.calculateMissingValues;
      }
      if (typeof options.updateMapCamera === 'boolean') {
        this._options.updateMapCamera = options.updateMapCamera;
        this._updateMapCamera = options.updateMapCamera;
      }
    }
  }

  /****************************
   * Public Methods
   ***************************/

  /**
   * Disposes the control.
   */
  dispose() {
    if (this._map) {
      this._map.controls.remove(this);
    }
    Object.keys(this).forEach(k => {
      this[k] = null;
    });
  }

  /** 
   * Get sthe last known position from the geolocation control.
   */
  getLastKnownPosition() {
    return JSON.parse(JSON.stringify(this._lastKnownPosition));
  }

  /**
   * Action to perform when the control is added to the map.
   * @param map The map the control was added to.
   * @param options The control options used when adding the control to the map.
   * @returns The HTML Element that represents the control.
   */
  onAdd(map, options) {
    this._map = map;

    const mcl = map.getMapContainer().classList;
    if (mcl.contains('high-contrast-dark')) {
      this._hclStyle = 'dark';
    } else if (mcl.contains('high-contrast-light')) {
      this._hclStyle = 'light';
    }

    this._resource = this._getTranslations(this._map.getStyle().language);

    // Create different color icons and merge into CSS.
    const gc = GeolocationControl;
    const grayIcon = gc._iconTemplate.replace('{color}', 'Gray');
    const blueIcon = gc._iconTemplate.replace('{color}', 'DeepSkyBlue');
    const css = gc._gpsBtnCss.replace(/{grayIcon}/g, grayIcon).replace(/{blueIcon}/g, blueIcon);

    // Add the CSS style for the control to the DOM.
    const style = document.createElement('style');
    style.innerHTML = css;
    document.body.appendChild(style);

    // Create the button.
    const c = document.createElement('div');
    c.classList.add('azure-maps-control-container');
    c.setAttribute('aria-label', this._resource[0]);
    c.style.flexDirection = 'column';

    // Hide the button by default. 
    c.style.display = 'none';
    this._container = c;

    const b = document.createElement('button');
    b.classList.add('azmaps-gpsBtn');
    b.classList.add('azmaps-gpsDisabled');
    b.setAttribute('title', this._resource[0]);
    b.setAttribute('alt', this._resource[0]);
    b.setAttribute('type', 'button');
    b.addEventListener('click', this._toggleBtn.bind(this));
    this._button = b;

    this._updateState();
    this.setOptions(this._options);
    c.appendChild(b);

    // Check that geolocation is supported.
    gc.isSupported().then(supported => {
      if (supported) {
        // Show the button when we know geolocation is supported.
        this._container.style.display = '';
      } else {
        // Device doesn't support getting position.
        this._invokeEvent('geolocationerror', {
          code: 2,
          message: 'Geolocation API not supported by device.',
          PERMISSION_DENIED: 1,
          POSITION_UNAVAILABLE: 2,
          TIMEOUT: 3
        });
      }
    });
    this._map.events.add('movestart', this._mapMoveStarted.bind(this));
    this._map.events.add('moveend', this._mapMoveEnded.bind(this));

    this.setOptions(this._options);

    return c;
  }

  /**
   * Action to perform when control is removed from the map.
   */
  onRemove() {
    if (this._container) {
      this._container.remove();
    }
    if (this._options.style === 'auto') {
      this._map.events.remove('styledata', this._mapStyleChanged.bind(this));
    }

    this._map.events.remove('movestart', this._mapMoveStarted.bind(this));
    this._map.events.remove('moveend', this._mapMoveEnded.bind(this));

    if (typeof this._watchId !== 'undefined') {
      navigator.geolocation.clearWatch(this._watchId);
    }

    if (this._gpsMarker) {
      this._map.markers.remove(this._gpsMarker);
    }

    this._map = null;
  }

  /** 
   * Gets the options of the geolocation control.
   */
  getOptions() {
    return Object.assign({}, this._options);
  }

  /**
   * Sets the options of the geolocation control.
   * @param options The options.
   */
  setOptions(options) {
    if (options) {
      let color = 'white';
      if (this._hclStyle) {
        if (this._hclStyle === 'dark') {
          color = this._darkColor;
        }
      } else {
        if (this._options.style === 'auto') {
          // @ts-ignore
          this._map.events.remove('styledata', this._mapStyleChanged.bind(this));
        }
        this._options.style = options.style;
        switch (options.style) {
          case 'dark':
            color = this._darkColor;
            break;
          case 'auto':
            // Color will change between light and dark depending on map style.
            this._map.events.add('styledata', this._mapStyleChanged.bind(this));
            color = this._getColorFromMapStyle();
            break;
          case 'light':
          default:
            break;
        }
      }

      this._button.style.backgroundColor = color;

      if (options.markerColor) {
        this._options.markerColor = options.markerColor;

        if (this._gpsMarker) {
          this._gpsMarker.setOptions({
            color: options.markerColor
          });
        }
      }

      if (typeof options.maxZoom === 'number') {
        this._options.maxZoom = Math.min(Math.max(options.maxZoom, 0), 24);
      }

      if (typeof options.calculateMissingValues === 'boolean') {
        this._options.calculateMissingValues = options.calculateMissingValues;
      }

      if (typeof options.updateMapCamera === 'boolean') {
        this._options.updateMapCamera = options.updateMapCamera;
        this._updateMapCamera = options.updateMapCamera;
      }

      if (typeof options.showUserLocation === 'boolean') {
        this._options.showUserLocation = options.showUserLocation;

        if (this._gpsMarker) {
          this._gpsMarker.setOptions({
            visible: this._isActive && options.showUserLocation
          });
        } else if (this._lastKnownPosition) {
          this._onGpsSuccess();
        }
      }

      if (typeof options.trackUserLocation === 'boolean') {
        this._options.trackUserLocation = options.trackUserLocation;
      }

      if (options.positionOptions) {
        let opt = {};

        if (options.positionOptions.enableHighAccuracy) {
          opt.enableHighAccuracy = options.positionOptions.enableHighAccuracy;
        }

        if (typeof options.positionOptions.maximumAge === 'number') {
          opt.maximumAge = options.positionOptions.maximumAge;
        }

        if (typeof options.positionOptions.timeout === 'number') {
          opt.timeout = options.positionOptions.timeout;
        }

        if (Object.keys(opt).length > 0) {
          this._options.positionOptions = Object.assign(this._options.positionOptions, opt);
          this._stopTracking();
          this._updateState();
        }
      }
    }
  }

  /**
   * Toggles the state of the Geolocation control button. If a boolean state is not passed in, will toggle to opposite of current state. 
   * @param isActive The state to toggle to. If not specified, will toggle to opposite of current state.
   */
   toggle(isActive) {
    this._isActive = typeof isActive === 'boolean' ? isActive : !this._isActive;
    if (this._isActive && this._options.trackUserLocation && this._lastKnownPosition) {
      this._onGpsSuccess();
    }

    this._updateMapCamera = this._options.updateMapCamera;
    this._updateState();
  }

  /** 
   * Checks to see if the geolocation API is supported in the browser.
   */
  static async isSupported() {
    if (window.navigator.permissions) {
      // navigator.permissions has incomplete browser support
      // http://caniuse.com/#feat=permissions-api
      // Test for the case where a browser disables Geolocation because of an insecure origin.

      const p = await window.navigator.permissions.query({ name: 'geolocation' });
      return p.state !== 'denied';
    }
    return !!window.navigator.geolocation;
  }

  /**
   * Private Methods
   */

  /**
   * Toggles the state of the control.
   */
  _toggleBtn() {
    this.toggle();
  }

  /**
   * An event handler for when the map style changes. Used when control style is set to auto.
   */
  _mapStyleChanged() {
    if (this._button && !this._hclStyle) {
      this._button.style.backgroundColor = this._getColorFromMapStyle();
    }
  }

  /**
   * An event handler for when the map starts to move.
   * When this happens, we don't want the map camera to automatically move if tracking.
   */
  _mapMoveStarted() {
    this._updateMapCamera = false;
  }

  /**
   * An event handler for when the map stops to moving.
   * When this happens, we don't want the map camera to automatically move if tracking.
   */
  _mapMoveEnded() {
    this._updateMapCamera = this._options.updateMapCamera;
  }

  /**
   * Retrieves the background color for the button based on the map style. This is used when style is set to auto.
   */
  _getColorFromMapStyle() {
    // When the style is dark (i.e. satellite, night), show the dark colored theme.
    if (['satellite', 'satellite_road_labels', 'grayscale_dark', 'night'].indexOf(this._map.getStyle().style) > -1) {
      return this._darkColor;
    }
    return 'white';
  }

  /**
   * Removes the geolocation watcher used for tracking.
   */
  _stopTracking() {
    if (typeof this._watchId === 'number') {
      navigator.geolocation.clearWatch(this._watchId);
      this._watchId = null;
    }
  }

  /**
   * Updates the state of the button.
   */
  _updateState() {
    if (!this._isActive || this._options.trackUserLocation) {
      this._stopTracking();
    }
    if (this._gpsMarker) {
      this._gpsMarker.setOptions({
        visible: this._isActive && this._options.showUserLocation
      });
    }

    let ariaLabel = this._resource[2];
    let removeClass = 'azmaps-gpsEnabled';
    let addClass = 'azmaps-gpsDisabled';

    if (this._isActive) {
      removeClass = 'azmaps-gpsDisabled';
      addClass = 'azmaps-gpsEnabled';

      if (this._options.trackUserLocation) {
        if (typeof this._watchId !== 'number') {
          this._watchId = navigator.geolocation.watchPosition(this._onGpsSuccess.bind(this), () => {
            // Fallback to low accuracy results.
            navigator.geolocation.getCurrentPosition(
              this._onGpsSuccess.bind(this),
              this._onGpsError.bind(this),
              Object.assign({}, this._options.positionOptions, { enableHighAccuracy: false })
            );
          }, Object.assign({}, this._options.positionOptions, { enableHighAccuracy: true }));
        }

        ariaLabel = this._resource[1];
      } else {
        // True high accuracy first then fall back if needed.
        navigator.geolocation.getCurrentPosition(this._onGpsSuccess.bind(this), () => {
          navigator.geolocation.getCurrentPosition(
            this._onGpsSuccess.bind(this),
            this._onGpsError.bind(this),
            Object.assign({}, this._options.positionOptions, { enableHighAccuracy: false })
          );
        }, Object.assign({}, this._options.positionOptions, { enableHighAccuracy: true }));
      }
    } else {
      if (this._options.trackUserLocation) {
        ariaLabel = this._resource[0];
      }
    }

    this._button.setAttribute('title', ariaLabel);
    this._button.setAttribute('alt', ariaLabel);

    this._button.classList.remove(removeClass);
    this._button.classList.add(addClass);
  }

  /**
   * Callback for when an error occurs when getting the users location.
   * @param position The GPS position information.
   */
  _onGpsSuccess(position) {
    let lastKnownPosition = this._lastKnownPosition;
    let gpsMarker = this._gpsMarker;
    let pos = null;

    if (position) {
      pos = [position.coords.longitude, position.coords.latitude];

      let geopos = {
        timestamp: new Date(position.timestamp),
        _timestamp: position.timestamp
      };

      Object.assign(geopos, position.coords);

      if (this._options.calculateMissingValues && lastKnownPosition) {
        if (typeof position.coords.speed !== 'number') {
          let dt = position.timestamp - lastKnownPosition.properties._timestamp;
          let dx = math.getDistanceTo(lastKnownPosition.geometry.coordinates, pos);
          geopos.speed = dx / (dt * 0.001);
        }

        if (typeof position.coords.heading !== 'number') {
          geopos.heading = math.getHeading(lastKnownPosition.geometry.coordinates, pos);
        }
      }

      lastKnownPosition = new data.Feature(new data.Point(pos), geopos);
      this._lastKnownPosition = lastKnownPosition;
    }

    if (lastKnownPosition) {
      if (!pos) {
        pos = lastKnownPosition.geometry.coordinates;
      }

      if (this._isActive) {
        const icon = this._getMarkerIcon();

        if (this._options.showUserLocation) {
          if (!gpsMarker) {
            this._gpsMarker = new HtmlMarker({
              position: pos,
              htmlContent: icon,
              color: this._options.markerColor
            });

            this._map.markers.add(this._gpsMarker);
          } else {
            gpsMarker.setOptions({
              position: pos,
              htmlContent: icon,
              visible: this._isActive && this._options.showUserLocation
            });
          }
        } else {
          gpsMarker.setOptions({
            visible: false
          });
        }

        this._gpsMarker.marker.setPitchAlignment('map');
        if (this._updateMapCamera) {
          const opt = {
            center: pos
          };

          // Only adjust zoom if the user is zoomed out too much.
          if (this._map.getCamera().zoom < 15) {
            opt.zoom = 15;
          }

          this._map.setCamera(opt);
        }
      }

      this._invokeEvent('geolocationsuccess', lastKnownPosition);
    }
  }

  /**
   * Callback for when an error occurs when getting the users location.
   * @param error The error that occured.
   */
  _onGpsError(error) {
    this._watchId = null;
    this._isActive = false;
    this._updateState();
    this._invokeEvent('geolocationerror', error);
  }

  /**
   * Generates the mark icon HTML.
   */
  _getMarkerIcon() {
    let icon = GeolocationControl._gpsDotIcon;
    let h = this._lastKnownPosition.properties.heading;

    if (this._options.trackUserLocation && h !== null && !isNaN(h)) {
      h = Math.round(h);
      // TODO: update when markers support rotation.
      const transform = `-webkit-transform:rotate(${h}deg);transform:rotate(${h}deg)`;
      icon = GeolocationControl._gpsArrowIcon.replace('{transform}', transform);
    }

    return icon;
  }

  /**
   * Returns the set of translation text resources needed for the control for a given language.
   * Array values: 0 - enableTracking, 1 - disableTracking, 2 - myLocation, 3 - title
   * @param lang The language code to retrieve the text resources for.
   * @returns An object containing text resources in the specified language.
   */
  _getTranslations(lang) {
    if (lang && lang.indexOf('-') > 0) {
      lang = lang.substring(0, lang.indexOf('-'));
    }
    const t = GeolocationControl._translations;
    let r = t[lang];

    if (!r) {
      r = t['en'];
    }

    return r;
  }
}

GeolocationControl._gpsArrowIcon = '<div style="{transform}"><svg xmlns="http://www.w3.org/2000/svg" width="28" height="28" viewBox="0 0 28 28"><g transform="translate(2 2)"><polygon points="12,0 0,24 12,17 24,24" stroke-width="2" stroke="white" fill="{color}"/></g></svg></div>';
GeolocationControl._gpsDotIcon = '<div class="azmaps-gpsPulseIcon" style="background-color:{color}"></div>';
GeolocationControl._iconTemplate = "data:image/svg+xml;utf8,<svg version='1.1' xmlns='http://www.w3.org/2000/svg' x='0' y='0' viewBox='0 0 561 561' xml:space='preserve'><g fill='{color}'><path d='M280.5,178.5c-56.1,0-102,45.9-102,102c0,56.1,45.9,102,102,102c56.1,0,102-45.9,102-102C382.5,224.4,336.6,178.5,280.5,178.5z M507.45,255C494.7,147.9,410.55,63.75,306,53.55V0h-51v53.55C147.9,63.75,63.75,147.9,53.55,255H0v51h53.55C66.3,413.1,150.45,497.25,255,507.45V561h51v-53.55C413.1,494.7,497.25,410.55,507.45,306H561v-51H507.45z M280.5,459C181.05,459,102,379.95,102,280.5S181.05,102,280.5,102S459,181.05,459,280.5S379.95,459,280.5,459z'/></g></svg>";
GeolocationControl._gpsBtnCss = '.azmaps-gpsBtn{margin:0;padding:0;border:none;border-collapse:collapse;width:32px;height:32px;text-align:center;cursor:pointer;line-height:32px;background-repeat:no-repeat;background-size:20px;background-position:center center;z-index:200;box-shadow:0px 0px 4px rgba(0,0,0,0.16);}.azmaps-gpsDisabled{background-image:url("{grayIcon}");}.azmaps-gpsDisabled:hover{background-image:url("{blueIcon}");filter:brightness(90%);}.azmaps-gpsEnabled{background-image:url("{blueIcon}");}.azmaps-gpsEnabled:hover{background-image:url("{blueIcon}");filter:brightness(90%);}.azmaps-gpsPulseIcon{display:block;width:15px;height:15px;border-radius:50%;background:orange;border:2px solid white;cursor:pointer;box-shadow:0 0 0 rgba(0, 204, 255, 0.6);animation:pulse 2s infinite;}@keyframes pulse {0% {box-shadow:0 0 0 0 rgba(0, 204, 255, 0.6);}70% {box-shadow:0 0 0 20px rgba(0, 204, 255, 0);}100% {box-shadow:0 0 0 0 rgba(0, 204, 255, 0);}}';
GeolocationControl._translations = {
  // Afrikaans
  'af': ['begin dop', 'stop die dop', 'my plek', 'ligginggewing beheer'],
  // Arabic
  'ar': ['بدء تتبع', 'تتبع توقف', 'موقعي', 'السيطرة تحديد الموقع الجغرافي'],
  // Basque
  'eu': ['Hasi segimendua', 'Stop jarraipena', 'Nire kokapena', 'Geokokapen kontrol'],
  // Bulgarian
  'bg': ['Започнете да проследявате', 'Спиране на проследяването', 'Моето място', 'контрол за геолокация'],
  // Chinese
  'zh': ['开始跟踪', '停止追踪', '我的位置', '地理位置控制'],
  // Croatian
  'hr': ['Započnite praćenje', 'zaustavljanje praćenje', 'Moja lokacija', 'kontrola Geolocation'],
  // Czech
  'cs': ['začít sledovat', 'Zastavit sledování', 'Moje lokace', 'ovládání Geolocation'],
  // Danish
  'da': ['Start sporing', 'Stop sporing', 'min placering', 'Geolocation kontrol'],
  // Dutch
  'nl': ['beginnen met het bijhouden', 'stop volgen', 'Mijn locatie', 'Geolocation controle'],
  // Estonian
  'et': ['Alusta jälgimist', 'Stopp jälgimise', 'Minu asukoht', 'Geolocation kontrolli'],
  // Finnish
  'fi': ['Aloita seuranta', 'Lopeta seuranta', 'Minun sijaintini', 'Geolocation ohjaus'],
  // French
  'fr': ['Démarrer le suivi', "suivi d'arrêt", 'Ma position', 'le contrôle de géolocalisation'],
  // Galician
  'gl': ['comezar a controlar', 'seguimento parada', 'A miña localización', 'control de xeolocalización'],
  // German
  'de': ['starten Sie Tracking', 'Stop-Tracking', 'Mein Standort', 'Geolokalisierung Steuer'],
  // Greek
  'el': ['Ξεκινήστε την παρακολούθηση', 'Διακοπή παρακολούθησης', 'Η τοποθεσία μου', 'ελέγχου geolocation'],
  // Hindi
  'hi': ['ट्रैक करना शुरू', 'बंद करो ट्रैकिंग', 'मेरा स्थान', 'जियोलोकेशन नियंत्रण'],
  // Hungarian
  'hu': ['követés indítása', 'követés leállítása', 'Saját hely', 'Geolocation ellenőrzés'],
  // Indonesian
  'id': ['Mulai pelacakan', 'berhenti pelacakan', 'Lokasi saya', 'kontrol geolocation'],
  // Italian
  'it': ['Inizia il monitoraggio', 'monitoraggio arresto', 'La mia posizione', 'controllo geolocalizzazione'],
  // Japanese
  'ja': ['追跡を開始', '追跡を停止', '私の場所', 'ジオロケーション制御'],
  // Kazakh
  'kk': ['қадағалау бастау', 'қадағалау тоқтату', 'Менің орналасуы', 'геоорын бақылау'],
  // Korean
  'ko': ['추적 시작', '정지 추적', '내 위치', '위치 정보 제어'],
  // Spanish
  'es': ['iniciar el seguimiento', 'Detener el seguimiento', 'Mi ubicacion', 'control de geolocalización'],
  // Latvian
  'lv': ['Sākt izsekošana', 'Stop izsekošana', 'Mana atrašanās vieta', 'Geolocation kontrole'],
  // Lithuanian
  'lt': ['pradėti stebėti', 'Sustabdyti sekimo', 'Mano vieta', 'Geografinė padėtis kontrolė'],
  // Malay
  'ms': ['mula menjejaki', 'Stop pengesanan', 'Lokasi saya', 'kawalan geolokasi'],
  // Norwegian
  'nb': ['begynne å spore', 'stopp sporing', 'Min posisjon', 'geolocation kontroll'],
  // Polish
  'pl': ['rozpocząć śledzenie', 'Zatrzymaj śledzenie', 'Moja lokacja', 'kontrola Geolokalizacja'],
  // Portuguese
  'pt': ['começar a controlar', 'rastreamento parada', 'Minha localização', 'controle de geolocalização'],
  // Romanian
  'ro': ['Pornire urmărire', 'Oprire urmărire', 'Locatia mea', 'controlul de geolocalizare'],
  // Russian
  'ru': ['Начать отслеживание', 'остановка отслеживания', 'Мое местонахождение', 'контроль геолокации'],
  // Serbian
  'sr': ['Старт трацкинг', 'стоп праћење', 'Моја локација', 'kontrola геолоцатион'],
  // Slovak
  'sk': ['začať sledovať', 'zastaviť sledovanie', 'moja poloha', 'ovládanie Geolocation'],
  // Slovenian
  'sl': ['Začni sledenje', 'Stop za sledenje', 'moja lokacija', 'nadzor Geolocation'],
  // Swedish
  'sv': ['börja spåra', 'Stoppa spårning', 'Min plats', 'geolocation kontroll'],
  // Thai
  'th': ['เริ่มการติดตาม', 'ติดตามหยุด', 'ตำแหน่งของฉัน', 'ควบคุม Geolocation'],
  // Turkish
  'tr': ['izlemeyi başlat', 'Dur izleme', 'Benim konumum', 'Coğrafi Konum kontrolü'],
  // Ukrainian
  'uk': ['почати відстеження', 'зупинка відстеження', 'моє місце розташування', 'контроль геолокації'],
  // Vietnamese
  'vi': ['Bắt đầu theo dõi', 'dừng theo dõi', 'vị trí của tôi', 'kiểm soát định vị'],
  // English
  'en': ['Start tracking', 'Stop tracking', 'My location', 'Geolocation control']
};
