import React, { useEffect, useState, useRef } from 'react'
import PropTypes from 'prop-types'
import {makeStyles} from '@material-ui/styles'
import {Alert, Autocomplete} from '@material-ui/lab'
import {
    Modal, Card, CardHeader, CardContent, CardActions,Typography, Button, Divider,
    colors, Snackbar, CircularProgress, FormControlLabel,
    TextField, TableContainer, Table, TableBody, TableRow, TableCell, Link, Tabs, Tab, Chip, Grid
} from '@material-ui/core'
import {GreenCheckbox} from 'components'
import apiConfig from 'apiConfig'
import axios from 'utils/axios'
import { DragHandle } from '@material-ui/icons'
import { SortableContainer, SortableElement, SortableHandle } from 'react-sortable-hoc'
import arrayMove from 'array-move'
import OpenInNew from "@material-ui/icons/OpenInNew";
import {pdf } from '@react-pdf/renderer';

import localStorage from 'utils/localStorage'
import CancelRoundedIcon from "@material-ui/icons/CancelRounded";
import JobQuotePdf from 'views/CustomerDetails/components/JobAddresses/components/JobQuotePdf'
import SignatureCanvas from 'react-signature-canvas'
import { KeyboardDatePicker } from 'components'

const useStyles = makeStyles(theme => ({
    styleModal: {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        outline: 'none',
        boxShadow: theme.shadows[20],
        width: 500,
        maxHeight: '100%',
        overflowY: 'auto',
        maxWidth: '100%',
        [theme.breakpoints.down('sm')]: {
            width: 500
        },
    },
    conButton: {
        padding: '16px'
    },
    buttonSave: {
        flexShrink: '0',
        backgroundColor: colors.green[600],
        '&:hover': {
            backgroundColor: colors.green[900]
        },
    },
    buttonCancel: {
        marginLeft: 'auto !important'
    },
    rootAttachments: {
        marginTop: 10,
        paddingTop: 10,
        display: 'flex',
        [theme.breakpoints.down('xs')]: {
            display: 'block'
        }
    },
    rootChildAttachments: {
        width: '100%',
        padding: '16px 8px',
        border: '1px solid #b3b3b3',
        borderRadius: '4px',
        position: 'relative',
        [theme.breakpoints.down('xs')]: {
            width: '100%'
        }
    },
    labelAttachments: {
        position: 'absolute',
        top: '-10px',
        left: '10px',
        backgroundColor: '#ffffff',
        padding: '0 3px',
        color: '#263238',
        fontSize: 14
    },
    bodyRow: {
        '& td': {
            padding: '0px'
        }
    },
    checkbox: {
        margin: 0
    },
    customersEmailExplain: {
        color: '#263238',
        fontSize: 14,
        textAlign: 'right'
    }
}))

const ModalSignWorkOrder = props => {
    const classes = useStyles();
    const {open, onCloseModal, place, onSelectedSign, isLoading, dataImageBase64UpdateOne,
        dataImageBase64UpdateTwo,
        dataImageBase64UpdateThree,
        dataImageBase64UpdateFour,
        dataImageBase64UpdateFive
    } = props;
    const [isProgress, setIsProgress] = useState(false);

    const [isStatus, setIsStatus] = useState({ failed: false, msg: '' });
    const [openSnackbar, setOpenSnackBar] = useState(false);
    const [imageURL, setImageURL] = useState(null);
    const [imageURLPrintName, setImageURLPrintName] = useState(null);
    const sigCanvas = useRef({});
    const sigCanvasPrintName = useRef({});
    const clear = () => sigCanvas.current.clear();
    const save = () =>
    setImageURL(sigCanvas.current.getTrimmedCanvas().toDataURL("image/png"));
    const clearPrintName = () => sigCanvasPrintName.current.clear();
    const savePrintName = () =>
    setImageURL(sigCanvasPrintName.current.getTrimmedCanvas().toDataURL("image/png"));
    const [date, setDate] = useState(new Date().toISOString());

    const getBase64FromUrl = async (url) => {
        const data = await fetch(url);
        const blob = await data.blob();
        return new Promise((resolve) => {
          const reader = new FileReader();
          reader.readAsDataURL(blob); 
          reader.onloadend = () => {
            const base64data = reader.result;   
            resolve(base64data);
          }
        });
    }

    useEffect(() => {
        if(open && !isLoading) {
            if(place == 1) {
                if(props.dataImageBase64UpdateOne) {
                    getBase64FromUrl(props.dataImageBase64UpdateOne)
                        .then(res => {
                            setTimeout(() => {
                                if(res && sigCanvas.current && sigCanvas.current.fromDataURL != null) {
                                    sigCanvas.current.fromDataURL(res);
                                }
                            });
                        })
                }
            } else if (place == 2) {
                if(props.dataImageBase64UpdateTwo) {
                    getBase64FromUrl(props.dataImageBase64UpdateTwo)
                        .then(res => {
                            setTimeout(() => {
                                if(res && sigCanvas.current && sigCanvas.current.fromDataURL != null) {
                                    sigCanvas.current.fromDataURL(res);
                                }
                            });
                        })
                }
                if(props.dataImageBase64UpdateThree) {
                    getBase64FromUrl(props.dataImageBase64UpdateThree)
                        .then(res => {
                            setTimeout(() => {
                                if(res && sigCanvasPrintName.current && sigCanvasPrintName.current.fromDataURL != null) {
                                    sigCanvasPrintName.current.fromDataURL(res);
                                }
                            });
                        })
                }
            } else if (place == 3) {
                if(props.dataImageBase64UpdateFour) {
                    getBase64FromUrl(props.dataImageBase64UpdateFour)
                        .then(res => {
                            setTimeout(() => {
                                if(res && sigCanvas.current && sigCanvas.current.fromDataURL != null) {
                                    sigCanvas.current.fromDataURL(res);
                                }
                            });
                        })
                }
                if(props.dataImageBase64UpdateFive) {
                    getBase64FromUrl(props.dataImageBase64UpdateFive)
                        .then(res => {
                            setTimeout(() => {
                                if(res && sigCanvasPrintName.current && sigCanvasPrintName.current.fromDataURL != null) {
                                    sigCanvasPrintName.current.fromDataURL(res);
                                }
                            });
                        })
                }
            }
        }
    }, [open, props])

    return (
        <>
            <Modal open={open}>
                <Card className={classes.styleModal}>
                    <CardHeader
                        title={
                            <Tabs
                                value={'SignHere'}
                            >
                                <Tab label='Sign Modal' value='SignHere' />
                            </Tabs>
                        }
                        style={{ padding: 0 }}
                    />
                    <Divider/>
                    {/* sign libary */}
                    <Grid item style={{ width: '90%', margin: '0 auto', marginTop: 10 }}>
                        <Typography style={{  }}>
                            Sign Here
                        </Typography>
                    </Grid>
                    <Grid item style={{ width: '90%', height: 200, border: '1px solid black', margin: '0 auto', borderRadius: 5, marginTop: 5, marginBottom: 5 }}>
                        <SignatureCanvas 
                            ref={sigCanvas}
                            penColor='black'
                            dotSize={0.5}
                            canvasProps={{width: 450, height: 200, className: 'sigCanvas'}} 
                        />
                    </Grid>
                    <Grid item style={{ width: '90%', margin: '0 auto' }}>
                        <CardActions style={{ padding: 0, marginTop: 20, marginBottom: 20 }}>
                            <KeyboardDatePicker
                                disabled={true}
                                style={{ width: '49%' }}
                                value={date}
                                onChange={date => {
                                    setDate(date);
                                }}
                            />
                            <div style={{ width: '2%' }} />
                            <Button
                                style={{ width: '49%' }}
                                variant="contained"
                                size="large"
                                className={classes.buttonCancel}
                                onClick={clear}
                                disabled={isLoading}
                            >
                                CLEAR SIGNATURE
                            </Button>
                        </CardActions>
                    </Grid>
                    <Grid item style={{ width: '90%', margin: '0 auto' }}>
                        <Typography style={{  }}>
                            Printed Name/Title if any...
                        </Typography>
                    </Grid>
                    {/* {place != 1 ?  */}
                    <Grid item style={{ width: '90%', height: 200, border: '1px solid black', margin: '0 auto', borderRadius: 5, marginTop: 5, marginBottom: 20 }}>
                        <SignatureCanvas 
                            ref={sigCanvasPrintName}
                            penColor='black'
                            dotSize={0.5}
                            canvasProps={{width: 450, height: 200, className: 'sigCanvas'}} 
                        />
                    </Grid>
                    <Grid item style={{ width: '90%', margin: '0 auto' }}>
                        <CardActions style={{ padding: 0, marginTop: 20, marginBottom: 20 }}>
                            <div style={{ width: '49%' }} />
                            <div style={{ width: '2%' }} />
                            <Button
                                style={{ width: '49%' }}
                                variant="contained"
                                size="large"
                                className={classes.buttonCancel}
                                onClick={clearPrintName}
                                disabled={isLoading}
                            >
                                CLEAR PRINTED NAME
                            </Button>
                        </CardActions>
                    </Grid>
                    {/* : null} */}
                    <Divider/>
                    <CardActions className={classes.conButton}>
                        <Button
                            variant="contained"
                            size="large"
                            className={classes.buttonCancel}
                            onClick={() => {
                                onCloseModal()
                            }}
                            disabled={isLoading}
                        >
                            CANCEL
                        </Button>
                        <Button
                            variant="contained"
                            size="large"
                            color="primary"
                            className={classes.buttonSave}
                            onClick={() => {
                                if(place == 1) {
                                    onSelectedSign(place, 'once', sigCanvas.current.getTrimmedCanvas().toDataURL("image/png"), sigCanvasPrintName.current.getTrimmedCanvas().toDataURL("image/png"), date);
                                } else {
                                    onSelectedSign(place, 'once', sigCanvas.current.getTrimmedCanvas().toDataURL("image/png"), sigCanvasPrintName.current.getTrimmedCanvas().toDataURL("image/png"), date);
                                }
                            }}
                            disabled={isLoading}
                        >
                            APPLY ONCE 
                        </Button>
                        <Button
                            variant="contained"
                            size="large"
                            color="primary"
                            className={classes.buttonSave}
                            onClick={() => {
                                if(place == 1) {
                                    onSelectedSign(place, 'all', sigCanvas.current.getTrimmedCanvas().toDataURL("image/png"), sigCanvasPrintName.current.getTrimmedCanvas().toDataURL("image/png"), date);
                                } else {
                                    onSelectedSign(place, 'all', sigCanvas.current.getTrimmedCanvas().toDataURL("image/png"), sigCanvasPrintName.current.getTrimmedCanvas().toDataURL("image/png"), date);
                                }
                            }}
                            disabled={isLoading}
                        >
                            APPLY TO ALL
                        </Button>
                        {isLoading && <CircularProgress size={24}/>}
                    </CardActions>
                </Card>
            </Modal>
            <Snackbar anchorOrigin={{ vertical: 'top', horizontal: 'center' }} open={openSnackbar}
                autoHideDuration={3000}
                onClose={() => setOpenSnackBar(false)}>
                <Alert
                    elevation={6} variant="filled" severity={isStatus.failed ? 'error' : 'success'}>
                    <Typography
                        color="inherit"
                        variant="h6">
                        {isStatus.msg}
                    </Typography>
                </Alert>
            </Snackbar>
        </>
    )
}
ModalSignWorkOrder.propTypes = {
    onCloseModal: PropTypes.func.isRequired
}
ModalSignWorkOrder.defaultProps = {
    onCloseModal: () => {
    }
}
export default ModalSignWorkOrder;
