import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import PropTypes from 'prop-types'
import {
    Grid, Typography, TextField, Button,
    CircularProgress, colors, Snackbar
} from '@material-ui/core'
import { Alert } from '@material-ui/lab'
import { makeStyles } from '@material-ui/styles'
import axios from "utils/axios";
import apiConfig from "apiConfig";

const useStyles = makeStyles(theme => ({
    guide: {
        fontSize: '18px',
        textAlign: 'center',
    },
    btn: {
        flexShrink: '0',
        color: theme.palette.white,
        backgroundColor: colors.green[600],
        '&:hover': {
            backgroundColor: colors.green[900]
        },
    },
    btnDis: {
        flexShrink: '0',
        color: 'rgba(0, 0, 0, 0.26)',
        backgroundColor: 'rgb(217 217 217)',
        pointerEvents: 'none',
    },
    passMsg: {
        fontSize: 12,
        color: colors.red[700]
    }
}));

let isRemove = true;
const StepTwo = ({ quickBooks, updateQuickBooks }) => {

    const classes = useStyles();
    const { id } = useParams();
    const quickBooksId = id.split('-')[0];

    const [username, setUsername] = useState(`quickbooks_${quickBooksId}`)
    const [password, setPassword] = useState();
    const [isStatus, setIsStatus] = useState({ failed: false, msg: '' });
    const [isSaving, setIsSaving] = useState(false);
    const [openSnackbar, setOpenSnackBar] = useState(false);

    const handleClick = () => {
        if (isRemove && quickBooks.id) {
            setPassword('');
            isRemove = false;
        }
    }

    const handleBlur = () => {
        if (quickBooks.id && !password) {
            setPassword('********');
            isRemove = true;
        }
    }

    const handleChangePassword = () => {
        setIsSaving(true);
        if (quickBooks.userName) {
            const pwdPostData = { userId: quickBooks.id, password };

            axios.post(apiConfig.url.AUTH_URL + apiConfig.url.EMPLOYEE_UPDATE_PASSWORD, pwdPostData)
                .then(res => {
                    setIsStatus({ failed: false, msg: "Password Updated" });
                    setOpenSnackBar(true)
                })
                .catch(() => {
                    setIsStatus({ failed: true, msg: "Update failed, please try again later." });
                    setOpenSnackBar(true)
                })
                .finally(() => setIsSaving(false))
        } else {
            const pwdPostData = { password };

            axios.put(apiConfig.url.USER_URL + apiConfig.url.QUICK_BOOKS, pwdPostData)
                .then(res => {
                    updateQuickBooks(res.data)
                    setIsStatus({ failed: false, msg: "Password Updated" });
                    setOpenSnackBar(true)
                })
                .catch(() => {
                    setIsStatus({ failed: true, msg: "Update failed, please try again later." });
                    setOpenSnackBar(true)
                })
                .finally(() => setIsSaving(false))
        }
    }

    useEffect(() => {
        setUsername(quickBooks.userName);
        quickBooks.id && setPassword('********');
    }, [quickBooks])

    return (
        <>
            <Grid container spacing={1}>
                <Grid item xs={12}>
                    <Typography className={classes.guide}>
                        <strong>Step 2: </strong>
                        Username and Password
                    </Typography>
                </Grid>
                <Grid item xs={12} style={{ margin: '0 20%' }}>
                    <TextField
                        fullWidth
                        disabled
                        label='Username'
                        name='username'
                        variant='outlined'
                        value={username}
                    />
                </Grid>
                <Grid item xs={12} style={{ margin: '0 20%' }}>
                    <TextField
                        className={classes.passField}
                        fullWidth
                        label='Password'
                        type="password"
                        name='password'
                        variant='outlined'
                        value={password || ''}
                        onClick={handleClick}
                        onBlur={handleBlur}
                        onChange={event => setPassword(event.target.value)}
                    />
                </Grid>
                <Grid item xs={12} style={{ margin: '0 20%' }}>
                    {quickBooks.id && (
                        <Typography className={classes.passMsg}>
                            This user has been generated. Please be careful changing the password.
                        </Typography>
                    )}
                </Grid>
                <Grid item xs={12} style={{ textAlign: 'right', margin: '0 20%' }}>
                    <Button className={(isSaving || !password || password === '********') ? classes.btnDis : classes.btn}
                        onClick={handleChangePassword}>
                        Save
                    </Button>
                    {isSaving && <CircularProgress size={24} />}
                </Grid>
            </Grid>

            <Snackbar anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
                open={openSnackbar}
                autoHideDuration={3000}
                onClose={() => setOpenSnackBar(false)}>
                <Alert elevation={6} variant="filled" severity={isStatus.failed ? 'error' : 'success'}>
                    <Typography
                        color="inherit"
                        variant="h6">
                        {isStatus.msg}
                    </Typography>
                </Alert>
            </Snackbar>
        </>
    )
}

StepTwo.propTypes = {
    quickBooks: PropTypes.object,
    updateQuickBooks: PropTypes.func
}

export default StepTwo;