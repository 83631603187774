import React, { useState } from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { makeStyles, useTheme } from '@material-ui/styles';
import { Topbar } from './components';

const useStyles = makeStyles(theme => ({
  root: {
    scrollBehavior: 'smooth',
    paddingTop: 56,
    height: '100%',
    transition: theme.transitions.create('padding-left', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
    [theme.breakpoints.up('sm')]: {
      paddingTop: 64
    }
  },
  shiftContent: {
    paddingLeft: 240
  },
  collapsed: {
    paddingLeft: 77
  },
  content: {
    height: '100%',
    overflowY: 'scroll'
  }
}));

const MainPayment = props => {
  const { children } = props;

  const classes = useStyles();
  const theme = useTheme();
  return (
    <div
      className={clsx({
        [classes.root]: true
      })}
    >
      <Topbar/>
      <main id='content' className={classes.content}>
        {children}   
      </main>
    </div>
  );
};

MainPayment.propTypes = {
  children: PropTypes.node
};

export default MainPayment;
