import {
    Button,
    Card,
    CardActions,
    CardContent,
    CardHeader,
    CircularProgress,
    Divider,
    FormControlLabel,
    Grid,
    Modal,
    Snackbar,
    TextField,
    Typography,
    colors
} from '@material-ui/core';
import { Alert } from '@material-ui/lab';
import { makeStyles } from '@material-ui/styles';
import clsx from 'clsx';
import { errorMessages } from 'common/constants';
import { FormErrorCallback, GreenCheckbox, GreenRadio } from 'components';
import { Formik } from 'formik';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react'
import NumberFormat from 'react-number-format';
import * as Yup from 'yup';
import apiConfig from 'apiConfig';
import axios from 'utils/axios';

const celeroSchema = Yup.object().shape({
    publicAPIKey: Yup.string().when("enabled", {
                        is: true,
                        then: Yup.string().required("Required.")
                    }),
    privateAPIKey: Yup.string().when("enabled", {
                        is: true,
                        then: Yup.string().required("Required.")
                    }),
    enabled: Yup.boolean(),
    achEnabled: Yup.boolean(),
    paysFeesType: Yup.number(),
    defaultFeePassthrough: Yup.boolean(),
    defaultFeePercent: Yup.number()
        .min(0, 'Must be a decimal between 0 and 10')
        .max(10, 'Must be a decimal between 0 and 10'),
    defaultFeeFlat: Yup.number()
});

const initValue = {
    publicAPIKey: '',
    privateAPIKey: '',
    enabled: false,
    achEnabled: true,
    paysFeesType: 1,
    defaultFeePassthrough: false,
    defaultFeePercent: 0,
    defaultFeeFlat: 0
};

const useStyles = makeStyles(theme => ({
    styleModal: {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        outline: 'none',
        boxShadow: theme.shadows[20],
        width: 700,
        maxHeight: '100%',
        overflowY: 'auto',
        maxWidth: '100%',
        [theme.breakpoints.down('sm')]: {
            width: 500
        },
    },
    content: {
        display: 'flex',
        alignItems: 'center'
    },
    guide: {
        fontSize: '18px',
        textAlign: 'center',
    },
    btn: {
        flexShrink: '0',
        color: theme.palette.white,
        backgroundColor: colors.green[600],
        '&:hover': {
            backgroundColor: colors.green[900]
        },
    },
    btnDis: {
        color: 'rgba(0, 0, 0, 0.26)',
        backgroundColor: 'rgb(217 217 217)',
        pointerEvents: 'none',
    },
    actionCard: {
        padding: '8px 16px',
        justifyContent: 'flex-end',
        alignItems: 'center'
    }
}));

const CeleroModal = ({ open, onClose }) => {

    const classes = useStyles();

    const [saving, setSaving] = useState(false);
    const [config, setConfig] = useState({ ...initValue });
    const [openSnackbar, setOpenSnackBar] = useState(false);
    const [isStatus, setIsStatus] = useState({ failed: false, msg: '' });

    const onChangePaysFeesType = (value, setFieldValue) => {
        setFieldValue('paysFeesType', value);
        if (value === 1) {
            setFieldValue('defaultFeePassthrough', false);
            setFieldValue('defaultFeePercent', 0);
            setFieldValue('defaultFeeFlat', 0);
        }
    };

    const onChangeDefaultFeeFlat = (event, setFieldValue) => {
        const { value } = event.target;
        setFieldValue('defaultFeeFlat', value ? parseFloat(value.replace(/[$,]/g, '')) : 0);
    };

    useEffect(() => {
        axios.get(apiConfig.url.BASE_URL + apiConfig.url.COMPANY_CELERO_INFO)
            .then(res => {
                if (res.data) {
                    const {
                        defaultFeePassthrough,
                        defaultFeePercent,
                        defaultFeeFlat
                    } = res.data;
                    setConfig({
                        ...res.data,
                        paysFeesType: defaultFeePassthrough || defaultFeePercent || defaultFeeFlat ? 2 : 1
                    });
                }
            });
    }, []);

    return (
        <Modal open={open} onClose={onClose}>
            <Formik
                initialValues={config}
                validationSchema={celeroSchema}
                onSubmit={values => {
                    setSaving(true);
                    axios.put(apiConfig.url.BASE_URL + apiConfig.url.COMPANY_CELERO_INFO, values)
                        .then(res => {
                            if (res.data) {
                                setIsStatus({ failed: false, msg: 'Update successfully.' });
                            } else {
                                setIsStatus({ failed: true, msg: 'Update failed, please try again later.' });
                            }
                            setOpenSnackBar(true);
                        })
                        .catch(e => {
                            console.error(e);
                            setIsStatus({ failed: true, msg: 'Update failed, please try again later.' });
                            setOpenSnackBar(true);
                        })
                        .finally(() => setSaving(false));
                }}
            >
                {({
                    values,
                    errors,
                    touched,
                    submitCount,
                    isValid,
                    setFieldValue,
                    handleChange,
                    handleBlur,
                    handleSubmit
                }) => (
                    <form onSubmit={handleSubmit}>
                        <FormErrorCallback
                            submitCount={submitCount}
                            isValid={isValid}
                            onSubmissionError={() => {
                                setIsStatus({ failed: true, msg: errorMessages.FIELD_CHECK });
                                setOpenSnackBar(true);
                            }}
                        />
                        <Card className={classes.styleModal}>
                            <CardHeader title="Configure Celero" />
                            <Divider />
                            <CardContent className={classes.content}>
                                <Grid container spacing={1}>
                                    <Grid item xs={12}>
                                        <Typography className={classes.guide}>
                                            <strong>API Configuration</strong>
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={12} style={{ margin: '0 20%' }}>
                                        <TextField
                                            fullWidth
                                            error={errors.publicAPIKey && touched.publicAPIKey}
                                            helperText={(errors.publicAPIKey && touched.publicAPIKey) && errors.publicAPIKey}
                                            label="Public API Key"
                                            name="publicAPIKey"
                                            variant="outlined"
                                            value={values.publicAPIKey ?? ''}
                                            onChange={handleChange}
                                        />
                                    </Grid>
                                    <Grid item xs={12} style={{ margin: '0 20%' }}>
                                        <TextField
                                            fullWidth
                                            error={errors.privateAPIKey && touched.privateAPIKey}
                                            helperText={(errors.privateAPIKey && touched.privateAPIKey) && errors.privateAPIKey}
                                            label="Private API Key"
                                            name="privateAPIKey"
                                            variant="outlined"
                                            value={values.privateAPIKey}
                                            onChange={handleChange}
                                        />
                                    </Grid>
                                    <Grid item xs={12} style={{ margin: '0 20%' }}>
                                        <FormControlLabel
                                            label="Enabled"
                                            name="enabled"
                                            checked={values.enabled}
                                            onChange={handleChange}
                                            control={
                                                <GreenCheckbox />
                                            }
                                        />
                                    </Grid>
                                    <Grid item xs={12} style={{ margin: '0 20%' }}>
                                        <FormControlLabel
                                            label="Allow ACH"
                                            name="achEnabled"
                                            disabled={true}
                                            checked={false}
                                            onChange={handleChange}
                                            control={
                                                <GreenCheckbox />
                                            }
                                        />
                                    </Grid>
                                    {/* <Grid item xs={12}>
                                        <Typography className={classes.guide}>
                                            <strong>Default Fee Configuration</strong>
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={12} style={{ margin: '0 20%' }}>
                                        <FormControlLabel
                                            label="Customer Pays Fees"
                                            name="paysFeesType"
                                            value={1}
                                            checked={values.paysFeesType === 1}
                                            onChange={() => onChangePaysFeesType(1, setFieldValue)}
                                            control={
                                                <GreenRadio />
                                            }
                                        />
                                        <FormControlLabel
                                            label="Company Pays Fees"
                                            name="paysFeesType"
                                            value={2}
                                            checked={values.paysFeesType === 2}
                                            onChange={() => onChangePaysFeesType(2, setFieldValue)}
                                            control={
                                                <GreenRadio />
                                            }
                                        />
                                    </Grid>
                                    <Grid item xs={12} style={{ margin: '0 20%' }}>
                                        <FormControlLabel
                                            label="Customer pays variable fee - Fee amount will not be known until credit card number is entered and will not be displayed on invoice"
                                            name="defaultFeePassthrough"
                                            disabled={values.paysFeesType === 1}
                                            checked={values.defaultFeePassthrough}
                                            onChange={handleChange}
                                            control={
                                                <GreenCheckbox />
                                            }
                                        />
                                    </Grid>
                                    <Grid item xs={12} style={{ margin: '0 20%' }}>
                                        <NumberFormat
                                            label="Convenience Fee Percent"
                                            name="defaultFeePercent"
                                            variant="outlined"
                                            disabled={values.paysFeesType === 1}
                                            value={values.defaultFeePercent}
                                            decimalScale={2}
                                            decimalSeparator="."
                                            min={0}
                                            max={10}
                                            onChange={handleChange}
                                            onBlur={handleBlur}
                                            customInput={TextField}
                                            error={errors.defaultFeePercent && touched.defaultFeePercent}
                                            helperText={touched.defaultFeePercent && errors.defaultFeePercent}
                                        />
                                    </Grid>
                                    <Grid item xs={12} style={{ margin: '0 20%' }}>
                                        <NumberFormat
                                            label="Convenience Fee Amount"
                                            name="defaultFeeFlat"
                                            variant="outlined"
                                            disabled={values.paysFeesType === 1}
                                            value={values.defaultFeeFlat}
                                            decimalScale={2}
                                            decimalSeparator="."
                                            thousandSeparator
                                            allowNegative={false}
                                            prefix="$"
                                            onChange={event => onChangeDefaultFeeFlat(event, setFieldValue)}
                                            onBlur={handleBlur}
                                            customInput={TextField}
                                            error={errors.defaultFeeFlat && touched.defaultFeeFlat}
                                            helperText={touched.defaultFeeFlat && errors.defaultFeeFlat}
                                        />
                                    </Grid> */}
                                </Grid>
                            </CardContent>
                            <Divider />
                            <CardActions className={classes.actionCard}>
                                <Button className={classes.btn} onClick={onClose} style={{ marginRight: 8 }}>
                                    Cancel
                                </Button>
                                <Button className={clsx(classes.btn, { [classes.btnDis]: saving })} disabled={saving} type="submit">
                                    Save
                                </Button>
                                {saving && <CircularProgress size={24} />}
                            </CardActions>
                            <Snackbar anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
                                open={openSnackbar}
                                autoHideDuration={3000}
                                onClose={() => setOpenSnackBar(false)}>
                                <Alert elevation={6} variant="filled" severity={isStatus.failed ? 'error' : 'success'}>
                                    <Typography
                                        color="inherit"
                                        variant="h6">
                                        {isStatus.msg}
                                    </Typography>
                                </Alert>
                            </Snackbar>
                        </Card>
                    </form>
                )}
            </Formik>
        </Modal>
    )
}

CeleroModal.propTypes = {
    open: PropTypes.bool,
    onClose: PropTypes.func
}

export default CeleroModal;
