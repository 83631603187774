import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import {
    TableRow, TableCell, FormControlLabel,
    TextField, IconButton, Tooltip, colors
} from '@material-ui/core'
import { makeStyles } from '@material-ui/styles'
import NumberFormat from 'react-number-format'
import { GreenCheckbox } from 'components'
import { AddBox, Delete } from '@material-ui/icons'
import { Autocomplete } from "@material-ui/lab";

const useStyles = makeStyles(theme => ({
    taxable: {
        margin: '0'
    },
    addStyle: {
        padding: '0',
        '& span svg': {
            color: colors.green[600],
            '&:hover': {
                color: colors.green[900]
            }
        }
    },
    delStyle: { padding: 0 },
    btnDis: {
        display: 'block',
        color: 'rgba(0, 0, 0, 0.26)',
        backgroundColor: 'rgb(217 217 217)',
        pointerEvents: 'none',
    },
}));

const JobLinePrice = ({ item, isUpsale, isAdd, addUpsale, onChangeUpsale, removeUpsale, style, lineItem, onChangeDesc, defaultTax, disabled }) => {
    const classes = useStyles();
    const { quantity, description, price, taxable, upsale, companyPriceItemId } = item;
    const [value, setValue] = useState({ title: description, price: '', companyPriceItemId: null });
    const [change, setChange] = useState(false)
    const [pr, setPr] = useState(price)

    let total = parseFloat(quantity * price).toFixed(2);
    useEffect(() => {
        setPr(price)
        setValue({ title: description, price: '', companyPriceItemId: null })
    }, [price])
    useEffect(() => {
        setValue({ title: item.description, price: item.price, companyPriceItemId: item.companyPriceItemId })
    }, [item])
    return (
        <>
            {
                Object.keys(item).length > 0 &&
                <TableRow style={style}>
                    <TableCell>
                        <NumberFormat
                            customInput={TextField}
                            allowNegative={false}
                            fullWidth
                            disabled={disabled || !isUpsale}
                            name='quantity'
                            variant='outlined'
                            value={quantity || ''}
                            onChange={onChangeUpsale}
                        />
                    </TableCell>
                    <TableCell>
                        <Autocomplete
                            // freeSolo
                            autoHighlight
                            // autoSelect={true}
                            value={value}
                            disabled={disabled || !isUpsale}
                            disableClearable
                            options={lineItem}
                            getOptionLabel={(option) => option.title}
                            onChange={(e, value, q) => {
                                if (typeof (value) === 'object') {
                                    setPr(parseFloat(value.price));
                                    setValue(value);
                                    setChange(false);
                                    onChangeDesc(value.title, value.price, defaultTax ? value.taxable : false, value.id)
                                } else {
                                    setValue({ title: value, price: '' })
                                }
                            }}
                            onInputChange={(event, newValue) => {
                                setChange(true);
                            }}
                            renderInput={(params) => (
                                <TextField
                                    {...params}
                                    name='description'
                                    variant="outlined"
                                    InputProps={{ ...params.InputProps, type: 'search' }}
                                    onBlur={(e) => {
                                        onChangeDesc(e.target.value, pr, taxable, change ? null : companyPriceItemId)
                                    }}
                                />
                            )}
                        />
                    </TableCell>
                    <TableCell>
                        <NumberFormat
                            customInput={TextField}
                            allowNegative={false}
                            prefix='$'
                            thousandSeparator
                            fullWidth
                            disabled={disabled || !isUpsale}
                            name='price'
                            variant='outlined'
                            value={price || ''}
                            onChange={onChangeUpsale}
                        />
                    </TableCell>
                    <TableCell>
                        <NumberFormat
                            customInput={TextField}
                            allowNegative={false}
                            prefix='$'
                            thousandSeparator
                            fullWidth
                            disabled
                            name='total'
                            variant='outlined'
                            value={total}
                        />
                    </TableCell>
                    <TableCell align='center'>
                        <FormControlLabel
                            className={classes.taxable}
                            disabled={disabled || !isUpsale}
                            name='taxable'
                            checked={taxable}
                            onChange={onChangeUpsale}
                            control={
                                <GreenCheckbox value="taxable" />
                            }
                        />
                    </TableCell>
                    <TableCell align='center'>
                        <FormControlLabel
                            className={classes.taxable}
                            disabled={disabled || !isUpsale}
                            name='upsale'
                            checked={upsale}
                            onChange={onChangeUpsale}
                            control={
                                <GreenCheckbox value="upsale" />
                            }
                        />
                    </TableCell>
                    <TableCell align='center'>
                        {isUpsale && (
                            <Tooltip title='Remove Upsale' arrow>
                                <IconButton onClick={removeUpsale} className={classes.delStyle} disabled={disabled}>
                                    <Delete fontSize='large' />
                                </IconButton>
                            </Tooltip>
                        )}
                        {isAdd && (
                            <Tooltip title='Add Upsale' arrow>
                                <IconButton onClick={addUpsale} className={disabled ? classes.btnDis : classes.addStyle}
                                    style={disabled ? { backgroundColor: 'transparent' } : null}>
                                    <AddBox fontSize='large' />
                                </IconButton>
                            </Tooltip>
                        )}
                    </TableCell>
                </TableRow>
            }
        </>
    )
}

JobLinePrice.propTypes = {
    item: PropTypes.object.isRequired,
    isUpsale: PropTypes.bool,
    isAdd: PropTypes.bool,
    addUpsale: PropTypes.func,
    onChangeUpsale: PropTypes.func,
    removeUpsale: PropTypes.func,
    style: PropTypes.object
}
JobLinePrice.defaultProps = {
    item: {},
    isUpsale: false,
    isAdd: false
}

export default JobLinePrice;
