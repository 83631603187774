import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import { makeStyles } from '@material-ui/styles'
import { Alert } from '@material-ui/lab'
import {
    Modal, Card, CardHeader, CardContent, CardActions,
    Grid, Typography, TextField, Button, Divider,
    colors, Snackbar, CircularProgress, FormControlLabel
} from '@material-ui/core'
import { FormErrorCallback, GreenCheckbox, InputTime, KeyboardDatePicker } from 'components'
import { useDispatch } from 'react-redux'
import apiConfig from 'apiConfig'
import axios from 'utils/axios'
import * as Yup from "yup";
import { Formik } from "formik";
import * as moment from 'moment'
import { errorMessages } from 'common/constants'

const useStyles = makeStyles(theme => ({
    styleModal: {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        outline: 'none',
        boxShadow: theme.shadows[20],
        width: 500,
        maxHeight: '100%',
        overflowY: 'auto',
        maxWidth: '100%',
        [theme.breakpoints.down('sm')]: {
            width: 500
        },
    },
    conButton: {
        padding: '16px',
        justifyContent: 'space-between'
    },
    buttonSave: {
        flexShrink: '0',
        backgroundColor: colors.green[600],
        '&:hover': {
            backgroundColor: colors.green[900]
        },
    },
    timeCheckbox: {
        marginLeft: '16px',
        width: '100%'
    },
    time: {
        width: '100%',
        marginRight: '16px'
    }
}))
const followUpSchema = Yup.object().shape({
    jobDescription: Yup.string()
        .nullable(),
    followUpDate: Yup.string().nullable().required('This field is required.'),
    note: Yup.string()
        .nullable().required('This field is required.'),
});


const ScheduleFollowUpModal = props => {
    const classes = useStyles();
    const { fuData, status, open, onCloseModal, onload } = props;

    const initValue = {
        jobDescription: status.description,
        followUpDate: '',
        note: '',
    };

    const [oldDate, setOldDate] = useState();
    const [isStatus, setIsStatus] = useState({ failed: false, msg: '' });
    const [openSnackbar, setOpenSnackBar] = useState(false);
    const [completed, setCompleted] = useState(true);
    const [isProgressDate, setIsProgressDate] = useState(false);
    const [isProgress, setIsProgress] = useState(false);
    const [followUp, setFollowUp] = useState(initValue);

    const markComplete = () => {
        setIsProgressDate(true)
        const data = { ...followUp, dateCompleted: (new Date()) }
        axios.put(apiConfig.url.BASE_URL + apiConfig.url.CUSTOMER_JOB_FOLLOW_UP.replace('{jobId}', status.id), data)
            .then(res => {
                setIsStatus({ failed: false, msg: 'Record Saved' });
                setOpenSnackBar(true);
                setCompleted(true)
                onload()
            })
            .catch(err => {
                setIsStatus({ failed: true, msg: 'An error occurred, please try again later' });
                setOpenSnackBar(true);
            })
            .finally(e => setIsProgressDate(false));
    }

    useEffect(() => {
        if (fuData) {
            fuData.jobDescription = status.description;
            fuData.followUpDate = fuData.followUpDate + '+00:00';
            setFollowUp(fuData);
            setOldDate(fuData.followUpDate);
            setCompleted(!!fuData.dateCompleted)
        }
    }, [fuData])
    useEffect(() => {
        // getData()
        if (fuData && !!fuData.dateCompleted) {
            setCompleted(true)
        }
    }, [open])

    useEffect(() => {
        if(status && !fuData){
            setFollowUp({
                jobDescription: status.description,
                followUpDate: '',
                note: '',
            })
        }
    }, [status])
    return (
        <>
            <Modal open={open}>
                <div>
                    <Formik
                        initialValues={followUp}
                        validationSchema={followUpSchema}
                        enableReinitialize
                        onSubmit={values => {
                            setIsProgress(true);

                            if (oldDate && moment(oldDate).format('MM/DD/YYYY') !== moment(values.followUpDate).format('MM/DD/YYYY')) {
                                values.dateCompleted = null
                            }
                            if (values.id) {
                                axios.put(apiConfig.url.BASE_URL + apiConfig.url.CUSTOMER_JOB_FOLLOW_UP.replace('{jobId}', status.id), values)
                                    .then(res => {
                                        setIsStatus({ failed: false, msg: 'Record Saved' });
                                        setOpenSnackBar(true);
                                        onCloseModal();
                                        onload()
                                    })
                                    .catch(err => {

                                        setIsStatus({ failed: true, msg: 'An error occurred, please try again later' });
                                        setOpenSnackBar(true);
                                    })
                                    .finally(e => setIsProgress(false));
                            } else {
                                const data = { ...values, companyId: status.companyId }
                                axios.post(apiConfig.url.BASE_URL + apiConfig.url.CUSTOMER_JOB_FOLLOW_UP.replace('{jobId}', status.id), data)
                                    .then(res => {
                                        setIsStatus({ failed: false, msg: 'Record Saved' });
                                        setOpenSnackBar(true);
                                        onCloseModal();
                                        onload()
                                    })
                                    .catch(err => {
                                        setIsStatus({ failed: true, msg: 'An error occurred, please try again later' });
                                        setOpenSnackBar(true);
                                    })
                                    .finally(e => setIsProgress(false));
                            }

                        }}
                    >
                        {({
                            values,
                            errors,
                            touched,
                            submitCount,
                            isValid,
                            setFieldValue,
                            setFieldTouched,
                            handleSubmit,
                            resetForm,
                            submitForm
                        }) => (
                            <form onSubmit={handleSubmit}>
                                <FormErrorCallback
                                    submitCount={submitCount}
                                    isValid={isValid}
                                    onSubmissionError={() => {
                                        setIsStatus({ failed: true, msg: errorMessages.FIELD_CHECK });
                                        setOpenSnackBar(true);
                                    }}
                                />
                                <Card className={classes.styleModal}>
                                    <CardHeader title='Schedule Follow Up' />
                                    <Divider />
                                    <CardContent>
                                        <Grid container spacing={4}>
                                            <Grid item xs={12}>
                                                <TextField
                                                    InputProps={{
                                                        readOnly: true,
                                                    }}
                                                    fullWidth
                                                    name='jobDescription'
                                                    variant='outlined'
                                                    label='Job Description'
                                                    value={values.jobDescription || ''}
                                                >
                                                </TextField>
                                            </Grid>
                                            <Grid item xs={12}>
                                                <KeyboardDatePicker
                                                    label='Follow Up Date'
                                                    value={values.followUpDate || ''}
                                                    error={errors.followUpDate && touched.followUpDate}
                                                    helperText={(errors.followUpDate && touched.followUpDate) && errors.followUpDate}
                                                    onBlur={() => setFieldTouched('followUpDate')}
                                                    onChange={date => setFieldValue('followUpDate', date)}
                                                />
                                            </Grid>
                                            <Grid item xs={12}>
                                                <TextField
                                                    fullWidth
                                                    multiline
                                                    rows={3}
                                                    variant='outlined'
                                                    label='Note'
                                                    name='note'
                                                    value={values.note || ''}
                                                    error={errors.note && touched.note}
                                                    helperText={(errors.note && touched.note) && errors.note}
                                                    onBlur={() => setFieldTouched('note')}
                                                    onChange={event => {
                                                        setFieldValue('note', event.target.value)
                                                    }}
                                                />
                                            </Grid>
                                        </Grid>
                                    </CardContent>
                                    <Divider />
                                    <CardActions className={classes.conButton}>
                                        <div style={{ float: 'left' }}>
                                            <Button
                                                Alignment={'left'}
                                                className={classes.buttonSave}
                                                variant="contained"
                                                color="primary"
                                                disabled={completed}
                                                size="large"
                                                onClick={markComplete}>
                                                Mark complete
                                            </Button>
                                            {isProgressDate && <CircularProgress size={24} />}
                                        </div>
                                        <div style={{display: 'flex'}}>
                                            <Button
                                                variant="contained"
                                                size="large"
                                                onClick={() => { onCloseModal() }}>
                                                Close
                                            </Button>
                                            <Button
                                                style={{ marginLeft: '6px' }}
                                                disabled={isProgress}
                                                variant="contained"
                                                color="primary"
                                                size="large"
                                                onClick={() => {
                                                    submitForm().then(r => {
                                                    })
                                                }}
                                                className={classes.buttonSave}>
                                                Save
                                            </Button>
                                            {isProgress && <CircularProgress size={24} />}
                                        </div>
                                    </CardActions>
                                </Card>
                            </form>
                        )}
                    </Formik>
                </div>
            </Modal>
            <Snackbar anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
                open={openSnackbar}
                autoHideDuration={3000}
                onClose={() => setOpenSnackBar(false)}>
                <Alert elevation={6} variant="filled" severity={isStatus.failed ? 'error' : 'success'}>
                    <Typography
                        color="inherit"
                        variant="h6">
                        {isStatus.msg}
                    </Typography>
                </Alert>
            </Snackbar>
        </>
    )
}
ScheduleFollowUpModal.propTypes = {
    // userId: PropTypes.string.isRequired,
    // open: PropTypes.bool.isRequired,
    onCloseModal: PropTypes.func.isRequired,
    onLoad: PropTypes.func.isRequired,
    // dayOff: PropTypes.object,
}
ScheduleFollowUpModal.defaultProps = {
    // open: false,
    onCloseModal: () => {
    },
    onLoad: () => {
    },
}
export default ScheduleFollowUpModal;
