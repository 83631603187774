import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/styles';
import { Grid } from '@material-ui/core';
import CustomerCard from '../CustomerCard'
import { Pagination } from 'components';


const useStyles = makeStyles(theme => ({
  root: {},
  content: {
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column',
    textAlgin: 'center'
  },
  name: {
    marginTop: theme.spacing(1)
  },
  avatar: {
    height: 100,
    width: 100
  },
  paginate: {
    marginTop: theme.spacing(3),
    display: 'flex',
    justifyContent: 'center'
  }

}));
const CustomerCards = props => {
  const { customersData, forcePage, onPageChange } = props;

  const classes = useStyles();

  return (

    <div className={classes.content}>
      <Grid
        container
        spacing={3}>
        {customersData.customers.map(customer => (
          <Grid item xs={12} key={customer.id}>
            <CustomerCard customer={customer} ></CustomerCard>
          </Grid>
        ))}
      </Grid>
      <div style={customersData.pageCount <= 1 ? { display: 'none' } : null} className={classes.paginate}>
        <Pagination pageCount={customersData.pageCount} forcePage={forcePage} onPageChange={onPageChange} />
      </div>
    </div>

  );
};

CustomerCards.propTypes = {
  className: PropTypes.string,
  customersData: PropTypes.object.isRequired
};

export default CustomerCards;
