import { Grid, Snackbar, Typography } from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import DocumentsCard from './DocumentsCard';
import axios from 'utils/axios';
import axiosNoAuth from 'utils/axiosNoAuth';
import apiConfig from 'apiConfig';
import { Alert } from '@material-ui/lab';
import { ConfirmModal } from 'components';
import { useDispatch } from 'react-redux';
import { checkFeature, FeatureFlags } from 'FeatureService';

const defaultAttachmentData = {
    loading: true,
    disabled: false,
    totalCount: 0,
    data: [],
    pageNumber: 1,
    pageCount: 0
};

const urls = [
    {
        GET: apiConfig.url.CUSTOMER_URL + apiConfig.url.CUSTOMER_GET_EXPIRING_CUSTOMER_DOCUMENTS,
        PATCH: apiConfig.url.CUSTOMER_URL + apiConfig.url.CUSTOMERS_DOCUMENT_UPDATE,
        DELETE: apiConfig.url.CUSTOMER_URL + apiConfig.url.CUSTOMERS_DELETE_DOCUMENT,
        DELETE_FROM_EXPIRING_DOCUMENTS: apiConfig.url.CUSTOMER_URL + apiConfig.url.CUSTOMERS_DELETE_DOCUMENT_FROM_EXPIRING_DOCUMENTS
    },
    {
        GET: apiConfig.url.CUSTOMER_URL + apiConfig.url.CUSTOMER_GET_EXPIRING_CUSTOMER_ADDRESS_DOCUMENTS,
        PATCH: apiConfig.url.CUSTOMER_URL + apiConfig.url.CUSTOMER_ADDRESS_UPDATE_DOCUMENT,
        DELETE: apiConfig.url.CUSTOMER_URL + apiConfig.url.CUSTOMER_ADDRESS_DELETE_DOCUMENT,
        DELETE_FROM_EXPIRING_DOCUMENTS: apiConfig.url.CUSTOMER_URL + apiConfig.url.CUSTOMER_ADDRESS_DELETE_DOCUMENT_FROM_EXPIRING_DOCUMENTS
    },
    {
        GET: apiConfig.url.CUSTOMER_URL + apiConfig.url.CUSTOMER_GET_EXPIRING_JOB_DOCUMENTS,
        PATCH: apiConfig.url.CUSTOMER_URL + apiConfig.url.JOB_UPDATE_DOCUMENT,
        DELETE: apiConfig.url.CUSTOMER_URL + apiConfig.url.JOB_DELETE_DOCUMENT,
        DELETE_FROM_EXPIRING_DOCUMENTS: apiConfig.url.CUSTOMER_URL + apiConfig.url.JOB_DELETE_DOCUMENT_FROM_EXPIRING_DOCUMENTS
    },
    {
        GET: apiConfig.url.USER_URL + apiConfig.url.CUSTOMER_GET_EXPIRING_USER_DOCUMENTS,
        DELETE: apiConfig.url.USER_URL + apiConfig.url.EMPLOYEE_DELETE_DOCUMENT,
        DELETE_FROM_EXPIRING_DOCUMENTS: apiConfig.url.USER_URL + apiConfig.url.EMPLOYEE_DELETE_DOCUMENT_FROM_EXPIRING_DOCUMENTS,
    }
];

const ExpiringDocuments = props => {

    const dispatch = useDispatch();

    const [customerAttachments, setCustomerAttachments] = useState({ ...defaultAttachmentData, type: 0 });
    const [customerAddressAttachments, setCustomerAddressAttachments] = useState({ ...defaultAttachmentData, type: 1 });
    const [jobAttachments, setJobAttachments] = useState({ ...defaultAttachmentData, type: 2 });
    const [userAttachments, setUserAttachments] = useState({ ...defaultAttachmentData, type: 3 });
    const [isSuccess, setIsSuccess] = useState({ failed: false, msg: '' });
    const [openSnack, setOpenSnack] = useState(false);
    const [locateFile, setLocateFile] = useState();
    const [openConfirm, setOpenConfirm] = useState(false);
    const [isProgress, setIsProgress] = useState(false);
    const [typeDeleteFile, setTypeDeleteFile] = useState();
    const [messageDelete, setMessageDelete] = useState(null);

    const [isEnableExpiringDocuments, setIsEnableExpiringDocuments] = useState();
    checkFeature(FeatureFlags.EXPIRING_DOCUMENTS).then(setIsEnableExpiringDocuments);

    const getState = type => {
        switch (type) {
            case 0:
                return [customerAttachments, setCustomerAttachments];
            case 1:
                return [customerAddressAttachments, setCustomerAddressAttachments];
            case 2:
                return [jobAttachments, setJobAttachments];
            case 3:
                return [userAttachments, setUserAttachments];
            default:
                return [];
        }
    }

    const fetchAttachments = type => {
        const [state, setState] = getState(type);
        setState({ ...state, loading: true });
        axios.get(urls[type].GET + state.pageNumber)
            .then(({ data }) => setState({ ...state, ...data, loading: false }))
            .catch(() => setState({ ...state, loading: false }));
    };

    const onDownload = (url, fileName) => {
        axiosNoAuth.get(url, {
            headers: {},
            responseType: 'blob',
        })
            .then((response) => {
                const url = window.URL.createObjectURL(new Blob([response.data]));
                const link = document.createElement('a');
                link.href = url;
                link.setAttribute('download', fileName);
                document.body.appendChild(link);
                link.click();
            })
            .catch(() => {
                setIsSuccess({ failed: true, msg: 'Download file failed.' });
                setOpenSnack(true);
            });
    };

    const onDeleteFile = () => {
        setIsProgress(true);
        axios.delete(urls[locateFile.type].DELETE + locateFile.id)
            .then(() => {
                setIsSuccess({ failed: false, msg: 'Remove files success.' });
                setOpenSnack(true);

                const [state, setState] = getState(locateFile.type);
                const remaining = [...state.data];
                const index = remaining.findIndex(d => d.id === locateFile.id);
                if (index !== -1) {
                    remaining.splice(index, 1);
                    setState({ ...state, data: remaining, totalCount: state.totalCount - 1 });
                    dispatch({ type: 'RELOAD_CUSTOMERS_COUNTER', reloadCustomer: true });
                }
            })
            .catch(() => {
                setIsSuccess({ failed: true, msg: 'Remove file failed, please try again later.' });
                setOpenSnack(true);
            })
            .finally(() => {
                setIsProgress(false);
                setOpenConfirm(false);
            })
    }

    const onDeleteFromList = () => {
        setIsProgress(true);
        if(isEnableExpiringDocuments) {
            axios.patch(urls[locateFile.type].DELETE_FROM_EXPIRING_DOCUMENTS + locateFile.id)
                .then(() => {
                    setIsSuccess({ failed: false, msg: 'Remove files success.' });
                    setOpenSnack(true);

                    const [state, setState] = getState(locateFile.type);
                    const remaining = [...state.data];
                    const index = remaining.findIndex(d => d.id === locateFile.id);
                    if (index !== -1) {
                        remaining.splice(index, 1);
                        setState({ ...state, data: remaining, totalCount: state.totalCount - 1 });
                        dispatch({ type: 'RELOAD_CUSTOMERS_COUNTER', reloadCustomer: true });
                    }
                })
                .catch(() => {
                    setIsSuccess({ failed: true, msg: 'Remove file failed, please try again later.' });
                    setOpenSnack(true);
                })
                .finally(() => {
                    setIsProgress(false);
                    setOpenConfirm(false);
                })
        }
    }

    const isDisplayToFieldTech = (type, file) => {
        const [state, setState] = getState(type);
        setState({ ...state, disabled: true });
        axios.patch(urls[type].PATCH + file.id,
            {'isDisplayToFieldTech': !file.isDisplayToFieldTech, 'documentId': file.id  }
            )
            .then(res => {
                file.isDisplayToFieldTech = !file.isDisplayToFieldTech;
                setIsSuccess({ failed: false, msg: 'Update file success.' });
                setOpenSnack(true);
            })
            .catch(() => {
                setIsSuccess({ failed: true, msg: 'Update file failed, please try again later.' });
                setOpenSnack(true);
            })
            .finally(() => setState({ ...state, disabled: false }));
    }

    useEffect(() => {
        fetchAttachments(0);
    }, [customerAttachments.pageNumber]);

    useEffect(() => {
        fetchAttachments(1);
    }, [customerAddressAttachments.pageNumber]);

    useEffect(() => {
        fetchAttachments(2);
    }, [jobAttachments.pageNumber]);

    useEffect(() => {
        fetchAttachments(3);
    }, [userAttachments.pageNumber]);

    function handleAddMessageDelete(typeDelete, filename) {
        setMessageDelete(typeDelete == 1 
            ? "Are you sure you want to stop renewing "+filename+"?" 
            : "Are you sure you want to delete "+filename+" from the system?")
    }

    return (
        <>
            <Grid container spacing={3} direction='column'>
                <Grid item>
                    <DocumentsCard
                        title='Customer Attachments'
                        {...customerAttachments}
                        onDownload={onDownload}
                        onDelete={(id,typeDelete, filename) => {
                            setLocateFile({ id, type: 0 });
                            handleAddMessageDelete(typeDelete, filename);
                            setTypeDeleteFile(typeDelete);
                            setOpenConfirm(true);
                        }}
                        onUpdate={file => isDisplayToFieldTech(0, file)}
                        onPageChange={page => setCustomerAttachments({ ...customerAttachments, pageNumber: page.selected + 1 })}
                        isEnableExpiringDocuments={isEnableExpiringDocuments}
                        onReload={type => {
                            fetchAttachments(type)
                        }}
                    />
                </Grid>
                <Grid item>
                    <DocumentsCard
                        title='Jobsite Attachments'
                        {...customerAddressAttachments}
                        onDownload={onDownload}
                        onDelete={(id,typeDelete, filename) => {
                            setLocateFile({ id, type: 1, filename });
                            handleAddMessageDelete(typeDelete, filename);
                            setTypeDeleteFile(typeDelete);
                            setOpenConfirm(true);
                        }}
                        onUpdate={file => isDisplayToFieldTech(1, file)}
                        onPageChange={page => setCustomerAddressAttachments({ ...customerAddressAttachments, pageNumber: page.selected + 1 })}
                        isEnableExpiringDocuments={isEnableExpiringDocuments}
                        onReload={type => {
                            fetchAttachments(type)
                        }}
                    />
                </Grid>
                <Grid item>
                    <DocumentsCard
                        title='Job Attachments'
                        {...jobAttachments}
                        onDownload={onDownload}
                        onDelete={(id,typeDelete, filename) => {
                            setLocateFile({ id, type: 2, filename });
                            handleAddMessageDelete(typeDelete, filename);
                            setTypeDeleteFile(typeDelete);
                            setOpenConfirm(true);
                        }}
                        onUpdate={file => isDisplayToFieldTech(2, file)}
                        onPageChange={page => setJobAttachments({ ...jobAttachments, pageNumber: page.selected + 1 })}
                        isEnableExpiringDocuments={isEnableExpiringDocuments}
                        onReload={type => {
                            fetchAttachments(type)
                        }}
                    />
                </Grid>
                <Grid item>
                    <DocumentsCard
                        title='Employee Attachments'
                        {...userAttachments}
                        onDownload={onDownload}
                        onDelete={(id,typeDelete, filename) => {
                            setLocateFile({ id, type: 3, filename });
                            handleAddMessageDelete(typeDelete, filename);
                            setTypeDeleteFile(typeDelete);
                            setOpenConfirm(true);
                        }}
                        onPageChange={page => setUserAttachments({ ...userAttachments, pageNumber: page.selected + 1 })}
                        isEnableExpiringDocuments={isEnableExpiringDocuments}
                        onReload={type => {
                            fetchAttachments(type)
                        }}
                    />
                </Grid>
            </Grid>

            {isEnableExpiringDocuments
            ? <ConfirmModal
                openConfirm={openConfirm}
                closeConfirm={() => setOpenConfirm(false)}
                onConfirm={typeDeleteFile == 1 ?  onDeleteFromList : onDeleteFile }
                isProgress={isProgress}
                title={messageDelete}
            />
            : <ConfirmModal
                    openConfirm={openConfirm}
                    closeConfirm={() => setOpenConfirm(false)}
                    onConfirm={typeDeleteFile == 1 ?  onDeleteFromList : onDeleteFile }
                    isProgress={isProgress}
                />
            }

            <Snackbar
                open={openSnack}
                anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
                autoHideDuration={2000}
                onClose={() => setOpenSnack(false)}>
                <Alert
                    elevation={6} variant="filled" severity={isSuccess.failed ? 'error' : 'success'}>
                    <Typography
                        color="inherit"
                        variant="h6">
                        {isSuccess.msg}
                    </Typography>
                </Alert>
            </Snackbar>
        </>
    );
};

export default ExpiringDocuments;
