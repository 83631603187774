import {
    Card,
    CardContent,
    CircularProgress,
    colors,
    IconButton,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Typography,
} from '@material-ui/core';
import {AddBox, Delete, ArrowDownward, ArrowUpward , CheckBoxOutlineBlank , CheckBox} from '@material-ui/icons';
import {makeStyles} from '@material-ui/styles';
import {Pagination} from 'components';
import moment from 'moment';
import React, {useState} from 'react';

const useStyles = makeStyles(theme => ({
    loading: {
        textAlign: 'center',
    },
    noData: {
        fontSize: theme.spacing(2),
        fontWeight: 500
    },
    table: {
        minWidth: 1000
    },
    cusFontsize: {
        '& th': {
            fontSize: 15
        }
    },
    customTableCell: {
        paddingTop: 0,
        paddingBottom: 0,
        borderBottom: '1px solid #969696',
        cursor: 'pointer'
    },
    paginate: {
        padding: '10px',
        '& ul': {
            justifyContent: 'center'
        }
    },
    customRow: {
        cursor: 'pointer',
        '& td': {
            paddingTop: 0,
            paddingBottom: 0
        },
        '&:hover': {
            backgroundColor: '#fafafa',
        }
    },
    iconSort: {
        marginBottom: '-5px',
        marginLeft: '10px'
    },
    addIcon: {
        padding: '0',
        '& span svg': {
            fontSize: '48px',
            color: colors.green[600],
            '&:hover': {
                color: colors.green[900]
            }
        }
    },
}));

const CustomFormCard = props => {

    const {
        loading,
        data,
        pageCount,
        onDelete,
        onPageChange,
        onSortChange,
        onActive,
        onOpenModalAdd,
        openEditModal
    } = props;

    const classes = useStyles();

    const [sortBy, setSortBy] = useState('TemplateName');
    const [sortType, setSortType] = useState('ASC');

    const loadDocument = (type) => {
        setSortBy(type);
        var t = type == sortBy ? (sortType === 'ASC' ? 'DESC' : 'ASC') : 'ASC';
        setSortType(t);
        onSortChange(type,t);
    }

    const getSortIcon = (type) => {
        return (
            sortBy == type &&
            (
                sortType == 'ASC' ?
                    <ArrowUpward className={classes.iconSort}/>
                    :
                    <ArrowDownward className={classes.iconSort}/>
            )
        )
    }

    return (
        <>
            <Card>
            <CardContent>
                {
                    loading
                        ? <div className={classes.loading}><CircularProgress size={30}/></div>
                        : <TableContainer>
                            <Table className={classes.table}>
                                <TableHead className={classes.customTableHead}>
                                    <TableRow className={classes.cusFontsize}>
                                        <TableCell onClick={() => loadDocument('TemplateName')} style={{width: '23%'}} className={classes.customTableCell}>
                                            <span>Template Name{ getSortIcon('TemplateName')}</span></TableCell>
                                        <TableCell onClick={() => loadDocument('Description')} style={{width: '22%'}}
                                                   className={classes.customTableCell}><span>Description{ getSortIcon('Description')}</span></TableCell>
                                        <TableCell onClick={() => loadDocument('CreatedDate')} style={{width: '25%'}} className={classes.customTableCell}>
                                            <span>Created Date{ getSortIcon('CreatedDate')}</span></TableCell>
                                        <TableCell onClick={() => loadDocument('CreatedBy')} className={classes.customTableCell}>
                                            <span>Created By{ getSortIcon('CreatedBy')}</span></TableCell>
                                        <TableCell className={classes.customTableCell} align="center">
                                            <span>Active</span>
                                        </TableCell>
                                        <TableCell className={classes.customTableCell} style={{width: '1%'}}>
                                            <IconButton className={classes.addIcon} onClick={onOpenModalAdd}>
                                                <AddBox />
                                            </IconButton>
                                        </TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {data?.length ? data.map((file, index) => (
                                        <TableRow style={{cursor: 'pointer'}} key={file.id} className={classes.customRow}>
                                            <TableCell onClick={() => {openEditModal(file.id, index);}} >{file.formName}</TableCell>
                                            <TableCell onClick={() => {openEditModal(file.id, index);}}>{file.description}</TableCell>
                                            <TableCell onClick={() => {openEditModal(file.id, index);}}>{moment(file.dateCreated).format('MM/DD/YYYY')}</TableCell>
                                            <TableCell onClick={() => {openEditModal(file.id, index);}}>{file.createdBy}</TableCell>
                                            <TableCell align="center">
                                                <IconButton
                                                    style={{cursor: 'pointer', color: file.isActive ? colors.green[600] : 'initial'}}
                                                    onClick={() => onActive(file.id, file.isActive)}>
                                                   {file.isActive ? <CheckBox/> : <CheckBoxOutlineBlank/> }
                                                </IconButton>
                                            </TableCell>
                                            <TableCell align="center">
                                                <IconButton aria-label="delete"
                                                            onClick={() => onDelete(file.id)}>
                                                    <Delete/>
                                                </IconButton>
                                            </TableCell>
                                        </TableRow>
                                    )) : (
                                        <TableRow>
                                            <TableCell colSpan={10}>
                                                <Typography className={classes.noData}>No data found...</Typography>
                                            </TableCell>
                                        </TableRow>
                                    )}
                                </TableBody>
                            </Table>
                        </TableContainer>
                }
                {
                    pageCount > 1 &&
                    <div className={classes.paginate}>
                        <Pagination pageCount={pageCount} onPageChange={onPageChange}/>
                    </div>
                }
            </CardContent>
            </Card>
        </>
    );
};

export default CustomFormCard;
