import React, { useState, useEffect } from 'react'
import { makeStyles } from '@material-ui/styles'
import { Alert } from '@material-ui/lab'
import {
    Modal,
    Button,
    colors,
    Card,
    CardContent,
    CardActions,
    Typography,
    Snackbar,
    CircularProgress,
    Grid
} from '@material-ui/core'
import { Dropzone } from 'components'
import axios from 'utils/axios';
import apiConfig from 'apiConfig';
import { KeyboardDatePicker } from '@material-ui/pickers';
import { useDispatch } from 'react-redux'

const useStyles = makeStyles(theme => ({
    root: {},
    styleModal: {
        position: 'absolute',
        top: '40%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        outline: 'none',
        boxShadow: theme.shadows[20],
        width: 700,
        maxHeight: '100%',
        overflowY: 'auto',
        maxWidth: '100%',
        [theme.breakpoints.down('sm')]: {
            width: 500
        },
    },
    btnUpFile: {
        flexShrink: '0',
        color: theme.palette.white,
        backgroundColor: colors.green[600],
        '&:hover': {
            backgroundColor: colors.green[900]
        },
    },
    actionCard: {
        justifyContent: 'flex-end',
        borderTop: '1px solid #eeeeee'
    }
}));

const AddAttachment = props => {

    const classes = useStyles();
    const dispatch = useDispatch();
    const { userId, open, onClose, onUploadFile, documentOldId } = props;

    const [files, setFiles] = useState([]);
    const [att, setAtt] = useState();
    const [isProgress, setIsProgress] = useState(false);
    const [isStatus, setIsStatus] = useState({ failed: false, msg: '' })
    const [openSnack, setOpenSnack] = useState(false)
    const [expirationDate , setExpirationDate ] = useState();
    const onChangeFile = files => {
        setFiles(files);
    }

    const onUploadAttachments = () => {
        files.forEach(file => {
            const formData = new FormData();
            formData.append('id', userId);
            formData.append('file', file);
            if (expirationDate) {
                formData.append('ExpirationDate', expirationDate.toISOString());
            }
            if(documentOldId) {
                formData.append('DocumentOldId', documentOldId);
            }
            setIsProgress(true)
            axios.put(apiConfig.url.USER_URL + apiConfig.url.EMPLOYEE_ADD_DOCUMENT, formData)
                .then(res => {
                    setIsStatus({ failed: false, msg: 'Files Successfully Added' });
                    setOpenSnack(true);
                    setAtt(res.data);
                    dispatch({ type: 'RELOAD_CUSTOMERS_COUNTER', reloadCustomer: true });
                })
                .catch(err => {
                    setIsStatus({ failed: true, msg: 'Add file failed, please try again later.' });
                    setOpenSnack(true);
                })
                .finally(() => {
                    setIsProgress(false);
                    onReset();
                })
        });
    }

    const onReset = () => {
        setFiles([]);
        setExpirationDate();
        onClose();
    }

    useEffect(() => {
        if (att) {
            onUploadFile(att)
        }
    }, [att])

    return (
        <>
            <Modal open={open} onClose={onReset}>
                <Card className={classes.styleModal}>
                    <CardContent>
                        <Dropzone onChange={files => onChangeFile(files)} />
                        <Grid container spacing={2} style={{ marginTop: '16px' }}>
                            <Grid item xs={6}>
                                
                            </Grid>
                            <Grid item xs={6} style={{ textAlign: 'right' }}>
                                <KeyboardDatePicker
                                    inputVariant="outlined"
                                    label='Expiration Date'
                                    name='expirationDate'
                                    format="MM/DD/YYYY"
                                    value={expirationDate || null}
                                    InputAdornmentProps={{ position: "end" }}
                                    onChange={date => setExpirationDate(date)}
                                />
                            </Grid>
                        </Grid>
                    </CardContent>
                    <CardActions className={classes.actionCard}>
                        <Button
                            onClick={onReset}
                            variant="contained">
                            Close
                        </Button>
                        <Button
                            disabled={files.length === 0 ? true : (isProgress ? true : false)}
                            onClick={onUploadAttachments}
                            variant="contained"
                            color="primary"
                            className={classes.btnUpFile}>
                            Add files
                        </Button>
                        {isProgress && <CircularProgress size={24} />}
                    </CardActions>
                </Card>
            </Modal>

            <Snackbar
                open={openSnack}
                anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
                autoHideDuration={2000}
                onClose={() => setOpenSnack(false)}>
                <Alert
                    elevation={6} variant="filled" severity={isStatus.failed ? 'error' : 'success'}>
                    <Typography
                        color="inherit"
                        variant="h6">
                        {isStatus.msg}
                    </Typography>
                </Alert>
            </Snackbar>
        </>
    )
}

AddAttachment.defaultProps = {
    open: false,
    onClose: () => { }
};

export default AddAttachment;