import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/styles';
import DeleteIcon from '@material-ui/icons/Delete';
import GetAppIcon from '@material-ui/icons/GetApp';
import useMediaQuery from '@material-ui/core/useMediaQuery'
import Fab from '@material-ui/core/Fab';
import { Alert } from '@material-ui/lab';
import axiosNoAuth from 'utils/axiosNoAuth'
import AddIcon from '@material-ui/icons/Add';
import {
    Table, TableBody, TableCell, TableHead, TableRow,
    colors, Card, CardHeader, CardContent, Divider,
    Typography, Snackbar, IconButton, CircularProgress, Grid
} from '@material-ui/core';
import { AddAttachment } from './components'
import localStorage from 'utils/localStorage'
import { PERMISSIONS } from "common/permissions";
import {ConfirmModal, Pagination} from 'components'
import axios from 'utils/axios';
import apiConfig from 'apiConfig';
import moment from 'moment';
import { useDispatch } from 'react-redux';
import {AddBox, ArrowDownward, ArrowUpward} from '@material-ui/icons'

const useStyles = makeStyles(theme => ({
    root: {},
    cardReadOnly: {
        pointerEvents: 'none'
    },
    customTableCell: {
        borderBottom: '1px solid #969696',
        cursor: 'pointer'
    },
    conUpFile: {
        marginTop: theme.spacing(2),
    },
    inputFile: {
        display: 'none'
    },
    btnUpFile: {
        flexShrink: '0',
        height: '50px',
        color: theme.palette.white,
        backgroundColor: colors.green[600],
        '&:hover': {
            backgroundColor: colors.green[900]
        },
        paddingLeft: theme.spacing(3),
        paddingRight: theme.spacing(3)
    },
    fab: {
        position: 'fixed',
        bottom: theme.spacing(4),
        right: theme.spacing(4),
    },
    responsiveTable: {
        width: '100%',
        overflowY: 'auto'
    },
    actionTable: {
        whiteSpace: 'nowrap'
    },
    cusFontsize: {
        '& th': {
            fontSize: '15px'
        }
    },
    openImage: {
        color: '#263238',
        '&:hover': {
            opacity: 0.7
        }
    },
    linkDown: {
        height: '24px',
        color: '#546e7a'
    },
    notifSearch: {
        fontSize: theme.spacing(2)
    },
    thumbnail: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center'
    },
    circularProgress: {
        marginTop: '30px',
    },
    expiredName: {
        color: '#e60216'
    },
    addStyle: {
        '& span svg': {
            color: colors.green[600],
            '&:hover': {
                color: colors.green[900]
            }
        }
    },
    iconSort: {
        marginBottom: '-5px',
        marginLeft: '10px'
    }
}));
let pageNumber = 1;
let forcePage = 0;
let mounted = false;
const Attachments = props => {

    const matches = useMediaQuery('(min-width:992px)');
    const dispatch = useDispatch();
    const readOnly = localStorage.getUserRole() === PERMISSIONS.FIELD_TECHNICIAN;

    const { userId } = props;

    const [openAttachmentModal, setOpenAttachmentModal] = useState(false);
    const [openSnack, setOpenSnack] = useState(false);
    const [isSuccess, setIsSuccess] = useState({ failed: false, msg: '' });
    const [listFile, setListFile] = useState([]);
    const [loading, setLoading] = useState();
    const [locateFile, setLocateFile] = useState();
    const [openConfirm, setOpenConfirm] = useState(false);
    const [isProgress, setIsProgress] = useState(false);
    const [sortBy, setSortBy] = useState('FileName');
    const [sortType, setSortType] = useState('ASC');
    const [pageCount, setPageCount] = useState(0);

    const classes = useStyles();

    const onUploadFile = att => {
        setListFile([...listFile, att])
    }

    const onDeleteFile = () => {
        setIsProgress(true)
        axios.delete(apiConfig.url.USER_URL + apiConfig.url.EMPLOYEE_DELETE_DOCUMENT + locateFile.id)
            .then(() => {
                setIsSuccess({ failed: false, msg: 'Remove file successfully.' });
                setOpenSnack(true);
                dispatch({ type: 'RELOAD_CUSTOMERS_COUNTER', reloadCustomer: true });
                const del_files = [...listFile];
                del_files.splice(locateFile.index, 1);
                setListFile(del_files);
                setIsProgress(false)
                setOpenConfirm(false)
            })
            .catch(() => {
                setIsSuccess({ failed: true, msg: 'Remove file failed, please try again later.' });
                setOpenSnack(true);
                setOpenConfirm(false)
            })

    }

    const onDownloadFile = (url, fileName) => {
        axiosNoAuth.get(url, {
            responseType: 'blob',
        })
            .then((response) => {
                const url = window.URL.createObjectURL(new Blob([response.data]));
                const link = document.createElement('a');
                link.href = url;
                link.setAttribute('download', fileName);
                document.body.appendChild(link);
                link.click();
            })
            .catch(() => {
                setIsSuccess({ failed: true, msg: 'Download file failed, please try again later.' });
                setOpenSnack(true);
            })

    }

    const handleEditOpen = () => {
        setOpenAttachmentModal(true);
    }
    const handleEditClose = () => {
        setOpenAttachmentModal(false);
    }

    const loadDocument = (type) => {
        setSortBy(type)
        setSortType(type == sortBy ? (sortType === 'ASC' ? 'DESC' : 'ASC') : 'ASC');
        pageNumber = 1;
        forcePage = 0;
    }
    const getSortIcon = (type) => {
            return (
                sortBy == type &&
                (
                    sortType == 'ASC' ?
                        <ArrowUpward className={classes.iconSort}/>
                        :
                        <ArrowDownward className={classes.iconSort}/>
                )
            )
    }

    const onPageChange = (page) => {
        pageNumber = page.selected + 1;
        forcePage = page.selected;
        fetchAttachments();
    };

    const fetchAttachments = () => {
        setLoading(true);
        axios.get(apiConfig.url.USER_URL + apiConfig.url.EMPLOYEE_DOCUMENT_LIST,
            {
                params:
                    {
                        userid: userId,
                        sortBy: sortBy,
                        sortType: sortType,
                        limit: 0,
                        pageSize: 24,
                        pageNumber: pageNumber,
                    }
            }
            )
            .then(res => {
                 setListFile(res.data.data);
                 setPageCount(res.data.pageCount);
            })
            .catch(err => console.log(err))
            .finally(e => setLoading(false))
    }
    useEffect(() => {
        fetchAttachments();
    }, [sortBy, sortType])

    // if (loading) {
    //     return (
    //         <Grid
    //             container
    //             spacing={0}
    //             align="center"
    //             justifyContent="center"
    //             direction="column">
    //             <Grid item>
    //                 <CircularProgress className={classes.circularProgress} size={50} />
    //             </Grid>
    //         </Grid>
    //     )
    // }

    return (
        <div className={readOnly ? classes.cardReadOnly : null}>

            <AddAttachment
                userId={userId}
                onUploadFile={onUploadFile}
                onClose={handleEditClose}
                open={openAttachmentModal}
            />

            <Card>
                <CardHeader title="Attachments" />
                <Divider />
                <CardContent className={classes.responsiveTable}>
                    <Table className={classes.table} aria-label="simple table">
                        <TableHead className={classes.customTableHead}>
                            <TableRow className={classes.cusFontsize}>
                                <TableCell onClick={() => loadDocument('FileName')} className={classes.customTableCell}> <span>File Name{ getSortIcon('FileName')}</span></TableCell>
                                <TableCell onClick={() => loadDocument('UploadedBy')} className={classes.customTableCell}><span>Uploaded By{ getSortIcon('UploadedBy')}</span></TableCell>
                                <TableCell onClick={() => loadDocument('DateUploaded')} className={classes.customTableCell}><span>Date Uploaded{ getSortIcon('DateUploaded')}</span></TableCell>
                                <TableCell onClick={() => loadDocument('ExpirationDate')} className={classes.customTableCell}><span>Expiration Date{ getSortIcon('ExpirationDate')}</span></TableCell>
                                {matches ? <TableCell onClick={() => loadDocument('Size')} className={classes.customTableCell}><span>Size {getSortIcon('Size')}</span></TableCell> : null}
                                <TableCell style={{ width: '1%', textAlign: 'center' }} className={classes.customTableCell}>
                                    <IconButton onClick={handleEditOpen} className={classes.addStyle}>
                                        <AddBox fontSize='large' />
                                    </IconButton>
                                </TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {
                                loading &&
                                <TableRow>
                                    <TableCell colSpan={5} style={{textAlign:"center"}}>
                                        <CircularProgress className={classes.circularProgress} size={50} />
                                    </TableCell>
                                </TableRow>
                            }
                            {!loading && listFile && listFile.length ? listFile.map((file, index) => (
                                <TableRow key={file.id}>
                                    <TableCell>
                                        <div className={classes.thumbnail}>
                                            <a className={classes.openImage} href={file.documentUrl} target="_blank">
                                                {file.filename}
                                                {file.expirationDate && moment(file.expirationDate).startOf('day') <= moment().startOf('day') ? <span className={classes.expiredName}>- Expired</span> : ""}
                                            </a>
                                            <img width='30px' height='auto' src='/images/thumbnail/file.png' alt='thumb' />
                                        </div>
                                    </TableCell>
                                    <TableCell>{file.uploadedBy}</TableCell>
                                    <TableCell>{moment(file.dateUploaded).format('MM/DD/YYYY')}</TableCell>
                                    <TableCell>{file.expirationDate ? moment(file.expirationDate).format('MM/DD/YYYY') : ""}</TableCell>
                                    {matches ? <TableCell>{file.size}</TableCell> : null}
                                    <TableCell className={classes.actionTable}>
                                        <IconButton aria-label="download" onClick={() => onDownloadFile(file.documentUrl, file.filename)}>
                                            <GetAppIcon />
                                        </IconButton>
                                        <IconButton aria-label="delete" onClick={() => {
                                            setLocateFile({ index: index, id: file.id });
                                            setOpenConfirm(true)
                                        }}>
                                            <DeleteIcon />
                                        </IconButton>
                                    </TableCell>
                                </TableRow>
                            )) : (
                                !loading &&
                                <TableRow>
                                    <TableCell colSpan={5}>
                                        <Typography className={classes.notifSearch}>No attachments found.</Typography>
                                    </TableCell>
                                </TableRow>
                            )}
                        </TableBody>
                    </Table>
                    {
                        !loading &&
                        <div style={pageCount === 0 ? { display: 'none' } : null} className={classes.paginate}>
                            <Pagination pageCount={pageCount} forcePage={forcePage} onPageChange={onPageChange} />
                        </div>

                    }

                </CardContent>
            </Card>

            <ConfirmModal
                isProgress={isProgress}
                openConfirm={openConfirm}
                closeConfirm={() => setOpenConfirm(false)}
                onConfirm={onDeleteFile}
            />

            <Snackbar
                open={openSnack}
                anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
                autoHideDuration={2000}
                onClose={() => setOpenSnack(false)}>
                <Alert
                    elevation={6} variant="filled" severity={isSuccess.failed ? 'error' : 'success'}>
                    <Typography
                        color="inherit"
                        variant="h6">
                        {isSuccess.msg}
                    </Typography>
                </Alert>
            </Snackbar>
        </div>
    );
};

Attachments.propTypes = {
    className: PropTypes.string
};

export default Attachments;
