import React, { useEffect, useState } from 'react'
import { makeStyles } from '@material-ui/styles'
import {
    Card, CardHeader, CardContent, Grid, Divider,
    TextField, colors, Typography, Collapse
} from '@material-ui/core'
import { KeyboardArrowDown, KeyboardArrowUp } from '@material-ui/icons'
import {AddressLink, ConfirmModal} from 'components'
import { useSelector, useDispatch } from 'react-redux'
import { Payment, CustomerPayment } from './components'
import { Formik } from 'formik'
import * as yup from 'yup'
import axios from "utils/axios";
import apiConfig from "apiConfig";
import Alert from "@material-ui/lab/Alert";
import Snackbar from "@material-ui/core/Snackbar";
import { useParams } from "react-router-dom";
import Costs from './components/Costs'

const useStyles = makeStyles(theme => ({
    btnBox: {
        backgroundColor: '#f4f6f8',
    },
    saveBtn: {
        color: theme.palette.white,
        backgroundColor: colors.green[600],
        '&:hover': {
            backgroundColor: colors.green[900]
        }
    },
    radioGroup: {
        display: 'flex',
        flexDirection: 'column'
    },
    radio: {
        '& span': {
            whiteSpace: 'nowrap'
        }
    },
    sticky: {
        marginBottom: theme.spacing(2),
        position: 'sticky',
        zIndex: 3
    },
    stickyHead: {
        position: 'relative',
        cursor: 'pointer'
    },
    address: {
        fontWeight: 500
    },
    btnAction: {
        color: theme.palette.white,
        backgroundColor: colors.green[600],
        '&:hover': {
            backgroundColor: colors.green[900]
        }
    },
    addressLink: {
        textDecoration: 'underline',
        color:'#0000EE'
    }
}));

const validationSchema = yup.object().shape({});

let mounted = false;
const Completed = props => {
    const { disabled, states, information, setOpenModal, fuData, load, reloadData, bindSubmitForm, setIsSaving, isCustomeService, invoiceGenerated, offsetTop, zIndexCustom = 3} = props;
    const { name, id, jobId } = useParams();
    const classes = useStyles();
    const { expanded } = useSelector(state => state.JobsReducer);
    const dispatch = useDispatch();

    // const initialValue = {
    //     description: information.description
    // }
    const [isStatus, setIsStatus] = useState({ failed: false, msg: '' });

    const [openConfirm, setOpenConfirm] = useState(false);
    const [openSnackbar, setOpenSnackBar] = useState(false);

    const toggleExpanded = () => {
        dispatch({ type: 'CHANGE_EXPANDED' })
    }

    return (
        <div style={{ paddingBottom: '16px' }}>
            <Formik
                initialValues={information}
                validationSchema={validationSchema}
                enableReinitialize
                onSubmit={(values) => {
                    setIsSaving(true);
                    const data = {
                        ...information,
                        description: values.description,
                        tip: values.tipChange
                    }
                    axios.put(apiConfig.url.BASE_URL + apiConfig.url.CUSTOMER_JOB_UPDATE, data)
                        .then(res => {
                            sessionStorage.setItem('sw-information', JSON.stringify(data));
                            setIsStatus({ failed: false, msg: 'Record Updated' });
                            setOpenSnackBar(true);
                            reloadData();
                        })
                        .catch(() => {
                            setIsStatus({ failed: true, msg: 'Update failed, please try again later' });
                            setOpenSnackBar(true);
                        })
                        .finally(() => setIsSaving(false))
                }}
            >
                {({
                    values, errors, touched,
                    setFieldTouched, setFieldValue,
                    isValid, submitCount, handleSubmit, submitForm
                }) => {
                    bindSubmitForm(submitForm);
                    return (
                    <form onSubmit={handleSubmit}>
                        <Grid container spacing={3} className={classes.sticky} style={{ zIndex: zIndexCustom, top: offsetTop }}>
                            <Grid style={{ margin: '-12px 0' }}
                                item xs={12} container spacing={3}>
                                <Card style={{ width: '100%' }}>
                                    <CardHeader title={isCustomeService ? <> <span>Job Details</span><span style={{color: 'red'}}> - Customer Service</span> </>  : 'Job Details'}
                                        className={classes.stickyHead}
                                        action={expanded ? <KeyboardArrowUp style={{ marginRight: '8px' }} /> : <KeyboardArrowDown style={{ marginRight: '8px' }} />}
                                        onClick={toggleExpanded} />
                                    <Divider />
                                    <Collapse in={expanded} timeout='auto' unmountOnExit>
                                        <CardContent>
                                            <Grid container spacing={3}>
                                                <Grid item xs={12} sm={4}>
                                                    <Card style={{ height: '100%' }}>
                                                        <CardHeader title='Job Address' />
                                                        <Divider />
                                                        <CardContent style={{ padding: '16px' }}>
                                                            {
                                                                information && information.jobAddress &&
                                                                (
                                                                    <>
                                                                        <Typography className={classes.address}>{information.jobAddress.name || ''}</Typography>
                                                                        <AddressLink
                                                                            address={{
                                                                                ...information.jobAddress,
                                                                                state: { name: states?.find(x => x.id === information.jobAddress.stateId)?.name }
                                                                            }}
                                                                            className={classes.address}
                                                                        />
                                                                    </>
                                                                )
                                                            }
                                                        </CardContent>
                                                    </Card>
                                                </Grid>
                                                <Grid item xs={12} sm={8}>
                                                    <TextField
                                                        fullWidth
                                                        disabled={disabled}
                                                        multiline
                                                        rows={7}
                                                        label='Job Description'
                                                        name='description'
                                                        variant='outlined'
                                                        value={values.description || ''}
                                                        onChange={event => setFieldValue('description', event.target.value)}
                                                    />
                                                </Grid>
                                            </Grid>
                                        </CardContent>
                                    </Collapse>
                                </Card>
                            </Grid>
                        </Grid>
                        <Payment jobId={jobId} data={values} load={load} reloadData={reloadData} invoiceGenerated={invoiceGenerated} setFieldValue={setFieldValue} />
                        <CustomerPayment id={id} jobId={jobId} data={information} reload={reloadData}/>
                        <Costs data={information} />

                        <ConfirmModal
                            openConfirm={openConfirm}
                            closeConfirm={() => setOpenConfirm(false)}
                        />
                    </form>
                )}}
            </Formik>

            <Snackbar anchorOrigin={{ vertical: 'top', horizontal: 'center' }} open={openSnackbar}
                autoHideDuration={3000}
                onClose={() => setOpenSnackBar(false)}>
                <Alert
                    elevation={6} variant="filled" severity={isStatus.failed ? 'error' : 'success'}>
                    <Typography
                        color="inherit"
                        variant="h6">
                        {isStatus.msg}
                    </Typography>
                </Alert>
            </Snackbar>
        </div>
    )
}

export default Completed;
