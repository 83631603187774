import React from 'react'
import { Typography } from '@material-ui/core'
import { Page } from "components";
import { AccessControl } from "components/AccessControl/AccessControl";
import { PERMISSIONS } from "common/permissions";

const Help = () => {
    return (
        <AccessControl requiredPermission={PERMISSIONS.page.help} redirect>
            <Page title={'Help'}>
                <div>
                    <Typography>Help</Typography>
                </div>
            </Page>
        </AccessControl>
    )
}

export default Help;
