import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { makeStyles, useTheme } from '@material-ui/styles';
import { Typography, Link, IconButton, useMediaQuery } from '@material-ui/core';
import { KeyboardArrowLeft, KeyboardArrowRight } from '@material-ui/icons';

const useStyles = makeStyles(theme => ({
  footer: {
    marginLeft:'23px',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center'
  },
  collapsed: {
    marginLeft: 0,
    flexDirection: 'column'
  }
}));

const Footer = props => {
  const { className, drawerOpen, toggleDrawer, ...rest } = props;

  const classes = useStyles();
  const theme = useTheme();
  const isDesktop = useMediaQuery(theme.breakpoints.up('lg'), {
    defaultMatches: true
  });

  return (
    <div
      {...rest}
      className={clsx(classes.footer, className, !drawerOpen ? classes.collapsed : null)}>
      <Typography variant="body1">
        &copy;{' '}
        {(drawerOpen || !isDesktop) && <Link
          component="a"
          href="https://squadwareone.com/"
          target="_blank">
          SquadWare
        </Link>}
        {(drawerOpen || !isDesktop) && ' '}{new Date().getFullYear()}
      </Typography>
      {isDesktop && <IconButton onClick={toggleDrawer}>
        {drawerOpen ? <KeyboardArrowLeft /> : <KeyboardArrowRight />}
      </IconButton>}
    </div>
  );
};

Footer.propTypes = {
  className: PropTypes.string
};

export default Footer;
