import React from "react";
import { Page } from "components";
import { makeStyles } from "@material-ui/styles";
import { Typography } from "@material-ui/core";

const useStyles = makeStyles(theme => ({
  root: {
    padding: theme.spacing(4)
  }
}));

export const NoAccessPage = () => {
  const classes = useStyles();

  return (
    <Page title="Access Denied">
      <div className={classes.root}>
        <Typography component={"h1"} variant={"h1"}>
          The page you were looking for either does not exist or you do not have
          the correct permissions.
        </Typography>
      </div>
    </Page>
  );
};
