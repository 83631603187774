const initState = {
    // routeEmployees: [
    //     { employeeId: 'e601389b-dc1d-4492-a3fd-d3e98ddbea20', firstName: 'Northwest Metro', lastName: '', phone: '(111) 222-3333' },
    //     { employeeId: '4ee64aa3-e60c-418f-a507-0ab2d19536ab', firstName: 'Kevin', lastName: 'Adam', phone: '(111) 222-3333' },
    //     { employeeId: '5926072e-2d59-4ab9-b827-cf2392cdb8f5', firstName: 'Valerie', lastName: 'Ahola', phone: '(111) 222-3333' },
    //     { employeeId: '38393c83-360a-4ebc-992e-ec1b7b8fbfc7', firstName: 'Joe', lastName: 'Allen', phone: '(111) 222-3333' },
    //     { employeeId: 'a5863593-4947-4673-a602-6f66654777e2', firstName: 'Corey', lastName: 'Anderlie', phone: '(111) 222-3333' },
    //     { employeeId: 'bfa65bbf-6188-4ca5-82bd-3aa960daa3f6', firstName: 'Dane', lastName: 'Anderson', phone: '(111) 222-3333' },
    //     { employeeId: '02b570a8-7256-4d1a-99ac-6dd11ad311bb', firstName: 'Jordan', lastName: 'Anderson', phone: '(111) 222-3333' },
    //     { employeeId: 'a2bc5d3f-25d7-4ac0-b814-c0042e925c6c', firstName: 'Ned', lastName: 'Anderson', phone: '(111) 222-3333' },
    //     { employeeId: 'bf30807b-c1f6-4235-a4b7-fd24a18ce313', firstName: 'Titus', lastName: 'Anderson', phone: '(111) 222-3333' },
    //     { employeeId: '073f1b1f-b93c-4cf8-89bb-91855b14ec5f', firstName: 'Devon', lastName: 'Andrews', phone: '(111) 222-3333' },
    // ]
    dragging: false,
    isHover: false,
    dayOff: [],
    routeId: '',
    loadingEmployee: false
}

const EmployeesReducer = (state = initState, action) => {
    let routeEmployees = state.routeEmployees && [...state.routeEmployees] || null;
    switch (action.type) {
        case 'INIT_EMPLOYEE':
            const employees = action.employees.sort((a, b) => a.lastName.localeCompare(b.lastName))
            return {
                ...state,
                routeEmployees: employees
            }
        case 'ADD_EMPLOYEE':
            routeEmployees.push(action.employee)
            const re = routeEmployees.sort((a, b) => a.lastName.localeCompare(b.lastName))
            return {
                ...state,
                routeEmployees: re
            }
        case 'LOAD_MORE_EMPLOYEES':
            return {
                ...state,
                routeEmployees: [...routeEmployees, ...action.routeEmployees]
            }
        case 'LOADING_EMPLOYEE':
            return {
                ...state,
                loadingEmployee: action.loadingEmployee
            }
        case 'REMOVE_EMPLOYEE':
            routeEmployees = routeEmployees.filter(x => !action.employeeIds.includes(x.id))
            return {
                ...state,
                routeEmployees: routeEmployees
            }
        case 'DRAGGING_EMPLOYEES':
            return {
                ...state,
                dragging: action.status,
                dayOff: action.dayOff,
                isHover: action.isHover,
                routeId: action.routeId,
            }
        default:
            return state;
    }
}

export default EmployeesReducer;
