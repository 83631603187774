import React, { useEffect, useState } from 'react'
import { CardContent, CircularProgress } from '@material-ui/core'

import { BlobProvider } from '@react-pdf/renderer';
import { useParams } from 'react-router-dom'
import axios from "../../../utils/axios";
import apiConfig from 'apiConfig'
import WorkorderHtml from './WorkorderHtml';
import localStorage from 'utils/localStorage'
import { PDFViewer } from '@react-pdf/renderer';
import { useTheme } from "@material-ui/core/styles";
import useMediaQuery from "@material-ui/core/useMediaQuery";

const Workorder = props => {
    const [data, setData] = useState([]);
    const [loadingData, setLoadingData] = useState(false);
    const { id } = useParams()
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('xs'));

    useEffect(() => {
        setLoadingData(true);
        axios.get(apiConfig.url.BASE_URL + apiConfig.url.JOB_WORK_ORDER_PDF, {params: {jobId: id} })
                .then(res => {
                    if(res.data){
                        setData(res.data);
                    }
                })
                .catch(err => {
                    console.log("err", err);
                })
                .finally(() => setLoadingData(false));
    }, [])

    return (
        <>
            {isMobile
                ? !loadingData 
                    ? <WorkorderHtml data={data} jobId={id} />
                    : <CardContent><CircularProgress size={24}/></CardContent>
                : !loadingData 
                    ? <WorkorderHtml data={data} jobId={id} />
                    : <CardContent><CircularProgress size={24}/></CardContent>
            }
        </>
    )
}

export default Workorder;
