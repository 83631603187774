import React, {useEffect, useState} from 'react'
import moment from 'moment'
import { makeStyles } from '@material-ui/styles'
import { Document, Page, Text, View, StyleSheet, PDFDownloadLink, Font, Image } from '@react-pdf/renderer';
import axios from "../../../../../utils/axios";
import apiConfig from "../../../../../apiConfig";
import { formatPhoneNumber } from 'utils/formatNumber';

const useStyles = makeStyles(theme => ({
    
}));

const styles = StyleSheet.create({
    invoiceSection: {
        fontFamily: 'Roboto', fontWeight: 'bold', fontSize: 20
    },
    sectionOne: {
        flexDirection: "row",
        justifyContent: 'space-between',
        width: '100%',
        alignItems: 'center'
    },
    textBold: {
        fontFamily: 'Roboto', fontWeight: 'bold', fontSize: '10px'
    },
    sectionDateAndInvoice: {
        width: '50%', height: 'auto', flexDirection: 'row'
    },
    sectionChildDateAndInvoice: {
        fontFamily: 'Roboto', fontWeight: 'medium', fontSize: 10, width: '50%', border: 1, textAlign: 'center', padding: 6
    },
    sectionTwo: {
        marginTop: 50, 
        flexDirection: "row",
        justifyContent: 'space-between',
        width: '100%',
        alignItems: 'center'
    },
    sectionThree: {
        marginTop: 10, 
        flexDirection: "row",
        justifyContent: 'space-between',
        width: '100%',
        alignItems: 'center'
    },
    dueDate: {
        fontFamily: 'Roboto', fontWeight: 'medium', fontSize: 10, width: '100%', border: 1, textAlign: 'center', padding: 6
    },
    sectionTable: {
        width: '100%', height: 'auto', flexDirection: 'row'
    }
});

const JobQuotePdf = (props) => {
    const classes = useStyles();
    const [data, setData] = useState([]);

    useEffect(() => {
        if(props.jobInfoPdf) {
            setData(props.jobInfoPdf);
        }
    }, [props])

    Font.register({
        family: 'Roboto',
        fonts: [
            {
                src: `https://cdn.jsdelivr.net/npm/roboto-fontface@0.10.0/fonts/roboto/Roboto-Regular.woff`,
                fontWeight: 'medium'
            },
            {
                src: `https://cdn.jsdelivr.net/npm/roboto-fontface@0.10.0/fonts/roboto/Roboto-Bold.woff`,
                fontWeight: 'bold'
            }
        ]
    })

    return (
        <>
            <Document>
                <Page size="A4" style={{ padding: 20, paddingTop: 20 }}>
                    <View wrap={false} style={{ width: '100%', flexDirection: 'row' }}>
                        <View style={{ width: '70%' }}>
                            <View>
                                {props.jobInfoPdf?.company?.logoUrl?.includes('companylogo')
                                    ? <Image
                                        src={props.jobInfoPdf.company.logoUrl}
                                        style={{ maxHeight: 80, objectFit: 'contain', objectPosition: 'top left' }}
                                    />
                                    : <View style={{ height: 80 }} />
                                }
                            </View>
                            <View style={{ flexDirection: 'row' }}>
                                <View style={{ width: '42.5%', alignItems: 'center' }}>
                                    <Text style={{ fontFamily: 'Roboto', fontWeight: 'bold', fontSize: 10, color: '#006DB1', marginTop: 5 }}>
                                        {props.jobInfoPdf && props.jobInfoPdf.company ? props.jobInfoPdf.company.website : ""}
                                    </Text>
                                    <Text style={{ fontFamily: 'Roboto', fontWeight: 'bold', fontSize: 10, color: '#006DB1' }}>
                                        {props.jobInfoPdf && props.jobInfoPdf.company ? formatPhoneNumber(props.jobInfoPdf.company.primaryPhoneNumber) : ""}
                                    </Text>
                                    {props.jobInfoPdf?.company?.quoteShowLegalName && (
                                        <Text style={{ fontFamily: 'Roboto', fontWeight: 'medium', fontSize: 8, color: 'black' }}>
                                            Legal Name: {props.jobInfoPdf && props.jobInfoPdf.company ? props.jobInfoPdf.company.legalName : ""}
                                        </Text>
                                    )}
                                </View>
                                <View style={{ width: '1%' }} />
                                <View style={{ width: '56.5%', alignItems: 'center', justifyContent: 'flex-end' }}>
                                    <Text style={{ fontFamily: 'Roboto', fontWeight: 'bold', fontSize: 12, color: 'black' }}>
                                        {props.jobInfoPdf?.company?.quoteTitleHeader ?? ''}
                                    </Text>
                                </View>
                            </View>
                        </View>
                        <View style={{ width: '1%' }} />
                        <View style={{ width: '29%', height: 'auto', alignItems: 'center' }}>
                            <View style={{ width: '100%', height: 110, border: 2, borderColor: '#006DB1', padding: 3, backgroundColor: '#E9ECF8' }}>
                                <View style={{ width: '100%', height: '50%' }}>
                                    <View style={{ width: '100%', height: '100%', border: 1, borderColor: 'black', padding: 3 }}>
                                        <View style={{ width: '100%', height: '100%', border: 1, borderColor: 'black', padding: 3, alignItems: 'center' }}>
                                            <Text style={{ fontFamily: 'Roboto', fontWeight: 'bold', fontSize: 14, color: 'black' }}>
                                                FREE ESTIMATE
                                            </Text>
                                            <Text style={{ fontFamily: 'Roboto', fontWeight: 'bold', fontSize: 10, color: 'black', marginTop: 7 }}>
                                                GOOD FOR 90 DAYS
                                            </Text>
                                        </View>
                                    </View>
                                </View>
                                <View style={{ width: '100%', height: '50%', marginTop: 5 }}>
                                    <View style={{ flexDirection: 'row' }}>
                                        <Text style={{ fontFamily: 'Roboto', fontWeight: 'bold', fontSize: 8, color: 'black', marginTop: 5, width: '40%' }}>
                                            Estimate Date:
                                        </Text>
                                        <View style={{ borderBottomWidth: 1, borderBottomColor: 'black', width: '60%' }}>
                                            <Text style={{ fontFamily: 'Roboto', fontWeight: 'medium', fontSize: 9, color: 'black', marginTop: 5, width: '40%' }}>
                                                {props.jobInfoPdf && props.jobInfoPdf.job && props.jobInfoPdf.job.bidCompleted != null ? moment(props.jobInfoPdf.job.bidCompleted).format('MM/DD/YYYY') : ""}
                                            </Text>
                                        </View>
                                    </View>
                                    <View style={{ flexDirection: 'row' }}>
                                        <Text style={{ fontFamily: 'Roboto', fontWeight: 'bold', fontSize: 8, color: 'black', marginTop: 5, width: '40%' }}>
                                            Sent By:
                                        </Text>
                                        {props.jobInfoPdf 
                                            && props.jobInfoPdf.job 
                                            && props.jobInfoPdf.job.salespersons 
                                            && props.jobInfoPdf.job.salespersons.length > 0 
                                            ? <View style={{ width: '60%' }}>
                                                    <Text style={{ fontFamily: 'Roboto', fontWeight: 'medium', fontSize: 9, color: 'black', marginTop: 5, width: '100%', borderBottomWidth: 1, borderBottomColor: 'black', }}>
                                                        {props.jobInfoPdf 
                                                            && props.jobInfoPdf.job 
                                                            && props.jobInfoPdf.job.salespersons 
                                                            && props.jobInfoPdf.job.salespersons[0]
                                                            ?  props.jobInfoPdf.job.salespersons[0].firstName+" "+props.jobInfoPdf.job.salespersons[0].lastName
                                                            : ""
                                                        }
                                                    </Text>
                                                    {props.jobInfoPdf 
                                                        && props.jobInfoPdf.job 
                                                        && props.jobInfoPdf.job.salespersons 
                                                        && props.jobInfoPdf.job.salespersons[1]
                                                        ?
                                                        <Text style={{ fontFamily: 'Roboto', fontWeight: 'medium', fontSize: 9, color: 'black', marginTop: 5, width: '100%', borderBottomWidth: 1, borderBottomColor: 'black', }}>
                                                            {props.jobInfoPdf.job.salespersons[1].firstName+" "+props.jobInfoPdf.job.salespersons[1].lastName}
                                                        </Text>
                                                        : null 
                                                    }                                                 
                                                </View>
                                            : <View style={{ width: '60%' }}>
                                                <Text style={{ fontFamily: 'Roboto', fontWeight: 'medium', fontSize: 9, color: 'black', marginTop: 13, width: '100%', borderBottomWidth: 1, borderBottomColor: 'black', }}>
                                                    {props.jobInfoPdf && props.jobInfoPdf.job && props.jobInfoPdf.job.estimator 
                                                        ?  (props.jobInfoPdf.job.estimator.firstName != null ? props.jobInfoPdf.job.estimator.firstName : "")+" "+(props.jobInfoPdf.job.estimator.lastName != null ? props.jobInfoPdf.job.estimator.lastName : "")
                                                        : ""
                                                    }
                                                </Text>
                                            </View>
                                        }
                                    </View>
                                </View>
                            </View>
                        </View>
                    </View>

                    <View style={{ width: '100%' }}>
                        <Text style={{ fontFamily: 'Roboto', fontWeight: 'bold', fontSize: 9, color: 'black', marginTop: 5 }}>
                            {props.jobInfoPdf?.company?.quoteTitleBody ?? ''}
                        </Text>
                    </View>

                    <View wrap={false} style={{ width: '100%', flexDirection: 'row', marginTop: 10, alignItems: 'center' }}>
                        <View style={{ width: '60%' }}>
                            <View style={{ flexDirection: 'row' }}>
                                <Text style={{ fontFamily: 'Roboto', fontWeight: 'bold', fontSize: 10, color: 'black', width: '30%' }}>
                                    Customer/Contact
                                </Text>
                                <View style={{ borderBottomWidth: 1, borderBottomColor: 'black', width: '70%' }}>
                                    <Text style={{ fontFamily: 'Roboto', fontWeight: 'medium', fontSize: 9, color: 'black' }}>
                                        {props.jobInfoPdf && props.jobInfoPdf.customer && props.jobInfoPdf.customer.primaryContact ? props.jobInfoPdf.customer.primaryContact.firstName+" "+props.jobInfoPdf.customer.primaryContact.lastName : ""}
                                    </Text>
                                </View>
                            </View>
                            <View style={{ flexDirection: 'row', marginTop: 5 }}>
                                <Text style={{ fontFamily: 'Roboto', fontWeight: 'bold', fontSize: 10, color: 'black', width: '15%' }}>
                                    Address
                                </Text>
                                <View style={{ borderBottomWidth: 1, borderBottomColor: 'black', width: '85%' }}>
                                    <Text style={{ fontFamily: 'Roboto', fontWeight: 'medium', fontSize: 9, color: 'black' }}>
                                        {props.jobInfoPdf && props.jobInfoPdf.customer && props.jobInfoPdf.customer.address ? props.jobInfoPdf.customer.address.name : ""}
                                    </Text>
                                </View>
                            </View>
                            <View style={{ flexDirection: 'row', marginTop: 7 }}>
                                <View style={{ width: '15%' }} />
                                <View style={{ borderBottomWidth: 1, borderBottomColor: 'black', width: '85%' }}>
                                    <Text style={{ fontFamily: 'Roboto', fontWeight: 'medium', fontSize: 9, color: 'black' }}>
                                        {props.jobInfoPdf && props.jobInfoPdf.customer && props.jobInfoPdf.customer.address && props.jobInfoPdf.customer.address.address1 ? props.jobInfoPdf.customer.address.address1 : ""}
                                    </Text>
                                </View>
                            </View>
                            <View style={{ flexDirection: 'row', marginTop: 7 }}>
                                <View style={{ width: '15%' }} />
                                <View style={{ borderBottomWidth: 1, borderBottomColor: 'black', width: '85%' }}>
                                    <Text style={{ fontFamily: 'Roboto', fontWeight: 'medium', fontSize: 9, color: 'black' }}>
                                        {props.jobInfoPdf && props.jobInfoPdf.customer && props.jobInfoPdf.customer.address ? props.jobInfoPdf.customer.address.city+", " : ""}
                                        {props.jobInfoPdf && props.jobInfoPdf.customer && props.jobInfoPdf.customer.address ? props.jobInfoPdf.customer.address.state.name+" " : ""}
                                        {props.jobInfoPdf && props.jobInfoPdf.customer && props.jobInfoPdf.customer.address ? props.jobInfoPdf.customer.address.zipCode : ""}
                                    </Text>
                                </View>
                            </View>
                        </View>
                        <View style={{ width: '1%' }} />
                        <View style={{ width: '39%' }}>
                            <View style={{ flexDirection: 'row' }}>
                                <Text style={{ fontFamily: 'Roboto', fontWeight: 'bold', fontSize: 10, color: 'black', width: '26%', textAlign: 'right' }}>
                                    Company
                                </Text>
                                <View style={{ width: '4%' }} />
                                <View style={{ borderBottomWidth: 1, borderBottomColor: 'black', width: '70%' }}>
                                    <Text style={{ fontFamily: 'Roboto', fontWeight: 'medium', fontSize: 9, color: 'black' }}>
                                        {props.jobInfoPdf 
                                            && props.jobInfoPdf.customer 
                                            && props.jobInfoPdf.customer.companyName 
                                            && props.jobInfoPdf.customer.customerType == 0 
                                                ? props.jobInfoPdf.customer.companyName 
                                                : ""
                                        }
                                    </Text>
                                </View>
                            </View>
                            <View style={{ flexDirection: 'row', marginTop: 5 }}>
                                <Text style={{ fontFamily: 'Roboto', fontWeight: 'bold', fontSize: 10, color: 'black', width: '26%', textAlign: 'right' }}>
                                    Contact
                                </Text>
                                <View style={{ width: '4%' }} />
                                <View style={{ borderBottomWidth: 1, borderBottomColor: 'black', width: '70%' }}>
                                    <Text style={{ fontFamily: 'Roboto', fontWeight: 'medium', fontSize: 9, color: 'black' }}>
                                        {props.jobInfoPdf && props.jobInfoPdf.customer && props.jobInfoPdf.customer.primaryContact ? props.jobInfoPdf.customer.primaryContact.firstName+" "+props.jobInfoPdf.customer.primaryContact.lastName : ""}
                                    </Text>
                                </View>
                            </View>
                            <View style={{ flexDirection: 'row', marginTop: 7 }}>
                                <Text style={{ fontFamily: 'Roboto', fontWeight: 'bold', fontSize: 10, color: 'black', width: '26%', textAlign: 'right' }}>
                                    Phone
                                </Text>
                                <View style={{ width: '4%' }} />
                                <View style={{ borderBottomWidth: 1, borderBottomColor: 'black', width: '70%' }}>
                                    <Text style={{ fontFamily: 'Roboto', fontWeight: 'medium', fontSize: 9, color: 'black' }}>
                                        {props.jobInfoPdf && props.jobInfoPdf.customer && props.jobInfoPdf.customer.primaryContact && props.jobInfoPdf.customer.primaryContact.phones.length > 0 ? formatPhoneNumber(props.jobInfoPdf.customer.primaryContact.phones[0].phoneNumber) : ""}
                                    </Text>
                                </View>
                            </View>
                            <View style={{ flexDirection: 'row', marginTop: 7 }}>
                                <Text style={{ fontFamily: 'Roboto', fontWeight: 'bold', fontSize: 10, color: 'black', width: '26%', textAlign: 'right' }}>
                                    Email
                                </Text>
                                <View style={{ width: '4%' }} />
                                <View style={{ borderBottomWidth: 1, borderBottomColor: 'black', width: '70%' }}>
                                    <Text style={{ fontFamily: 'Roboto', fontWeight: 'medium', fontSize: 9, color: 'black' }}>
                                        {props.jobInfoPdf && props.jobInfoPdf.customer && props.jobInfoPdf.customer.primaryContact ? props.jobInfoPdf.customer.primaryContact.email : ""}
                                    </Text>
                                </View>
                            </View>
                        </View>
                    </View>

                    <View wrap={true} style={{ padding: 5, marginTop: 10 }}>
                        <View style={{ flexDirection: 'row' }}>
                            <View style={{ width: '50%', border: 1, borderColor: '#006DB1', padding: 5, borderRight: 0 }}>
                                {/* <View style={{ width: '50%', borderRight: 1 }}> */}
                                    <View style={{ flexDirection: 'row' }}>
                                        <Text style={{ fontFamily: 'Roboto', fontWeight: 'bold', fontSize: 10, color: 'black', width: '30%' }}>
                                            Job Description:
                                        </Text>
                                        <Text style={{ fontFamily: 'Roboto', fontWeight: 'medium', fontSize: 9, color: 'black', width: '70%' }}>
                                            {props.jobInfoPdf && props.jobInfoPdf.job ? props.jobInfoPdf.job.description : ""}
                                        </Text>
                                    </View>
                                    <View style={{ flexDirection: 'row', marginTop: 5}}>
                                        <Text style={{ fontFamily: 'Roboto', fontWeight: 'bold', fontSize: 10, color: 'black', width: '30%' }}>
                                            Job Address:
                                        </Text>
                                        <Text style={{ fontFamily: 'Roboto', fontWeight: 'medium', fontSize: 9, color: 'black', width: '70%' }}>
                                            {props.jobInfoPdf && props.jobInfoPdf.job && props.jobInfoPdf.job.jobAddress && props.jobInfoPdf.job.jobAddress ? props.jobInfoPdf.job.jobAddress.name : ""}
                                        </Text>
                                    </View>
                                    <View style={{ flexDirection: 'row' }}>
                                        <View style={{ width: '30%' }} />
                                        <Text style={{ fontFamily: 'Roboto', fontWeight: 'medium', fontSize: 9, color: 'black',  width: '70%' }}>
                                            {props.jobInfoPdf && props.jobInfoPdf.job && props.jobInfoPdf.job.jobAddress && props.jobInfoPdf.job.jobAddress.address1 ? props.jobInfoPdf.job.jobAddress.address1 : ""}
                                        </Text>
                                    </View>
                                    <View style={{ flexDirection: 'row' }}>
                                        <View style={{ width: '30%' }} />
                                        <Text style={{ fontFamily: 'Roboto', fontWeight: 'medium', fontSize: 9, color: 'black', width: '70%' }}>
                                            {props.jobInfoPdf && props.jobInfoPdf.job && props.jobInfoPdf.job.jobAddress ? props.jobInfoPdf.job.jobAddress.city+", " : ""}
                                            {props.jobInfoPdf && props.jobInfoPdf.job && props.jobInfoPdf.job.jobAddress ? props.jobInfoPdf.job.jobAddress.state.name+" " : ""}
                                            {props.jobInfoPdf && props.jobInfoPdf.job && props.jobInfoPdf.job.jobAddress ? props.jobInfoPdf.job.jobAddress.zipCode : ""}
                                        </Text>
                                    </View>
                                {/* </View> */}
                            </View>
                            <View style={{ width: '50%', border: 1, borderColor: '#006DB1', padding: 5 }}>
                                {/* <View style={{ width: '50%', borderRight: 1 }}> */}
                                    <View style={{ flexDirection: 'row' }}>
                                        <Text style={{ fontFamily: 'Roboto', fontWeight: 'bold', fontSize: 10, color: 'black', width: '30%' }}>
                                            Name:
                                        </Text>
                                        <Text style={{ fontFamily: 'Roboto', fontWeight: 'medium', fontSize: 9, color: 'black', width: '70%' }}>
                                        {props.jobInfoPdf && props.jobInfoPdf.job && props.jobInfoPdf.job.jobAddress && props.jobInfoPdf.job.jobAddress.primaryContact ? props.jobInfoPdf.job.jobAddress.primaryContact.name : ""}
                                        </Text>
                                    </View>
                                    <View style={{ flexDirection: 'row', marginTop: 5}}>
                                        <Text style={{ fontFamily: 'Roboto', fontWeight: 'bold', fontSize: 10, color: 'black', width: '30%' }}>
                                            Phone:
                                        </Text>
                                        <Text style={{ fontFamily: 'Roboto', fontWeight: 'medium', fontSize: 9, color: 'black', width: '70%' }}>
                                            {props.jobInfoPdf && props.jobInfoPdf.job && props.jobInfoPdf.job.jobAddress && props.jobInfoPdf.job.jobAddress.primaryContact ? formatPhoneNumber(props.jobInfoPdf.job.jobAddress.primaryContact.phone) : ""}
                                        </Text>
                                    </View>
                                    <View style={{ flexDirection: 'row', marginTop: 5}}>
                                        <Text style={{ fontFamily: 'Roboto', fontWeight: 'bold', fontSize: 10, color: 'black', width: '30%' }}>
                                            Email:
                                        </Text>
                                        <Text style={{ fontFamily: 'Roboto', fontWeight: 'medium', fontSize: 9, color: 'black', width: '70%' }}>
                                            {props.jobInfoPdf && props.jobInfoPdf.job && props.jobInfoPdf.job.jobAddress && props.jobInfoPdf.job.jobAddress.primaryContact ? props.jobInfoPdf.job.jobAddress.primaryContact.email : ""}
                                        </Text>
                                    </View>
                                {/* </View> */}
                            </View>
                        </View>
                        <View style={{ width: '100%', border: 1, borderColor: '#006DB1', padding: 5, borderTop: 0, marginTop: -1 }}>
                            <View style={{ width: '100%', flexDirection: 'row' }}>
                                <View style={{ flexDirection: 'row', width: '35%' }}>
                                    <Text style={{ fontFamily: 'Roboto', fontWeight: 'bold', fontSize: 10, color: 'black', width: '40%' }}>
                                        Scheduled For:
                                    </Text>
                                    <Text style={{ fontFamily: 'Roboto', fontWeight: 'medium', fontSize: 9, color: 'black' }}>
                                        
                                    </Text>
                                </View>
                                <View style={{ flexDirection: 'row', width: '35%' }}>
                                    <Text style={{ fontFamily: 'Roboto', fontWeight: 'bold', fontSize: 10, color: 'black', width: '40%' }}>
                                        Quoted By:
                                    </Text>
                                    <Text style={{ fontFamily: 'Roboto', fontWeight: 'medium', fontSize: 9, color: 'black' }}>
                                        
                                    </Text>
                                </View>
                                <View style={{ flexDirection: 'row', width: '30%' }}>
                                    <Text style={{ fontFamily: 'Roboto', fontWeight: 'bold', fontSize: 10, color: 'black', width: '40%' }}>
                                        Quote Date:
                                    </Text>
                                    <Text style={{ fontFamily: 'Roboto', fontWeight: 'medium', fontSize: 9, color: 'black' }}>
                                        {props.jobInfoPdf && props.jobInfoPdf.job && props.jobInfoPdf.job.bidAppointment != null ? moment(props.jobInfoPdf.job.bidAppointment).format('MM/DD/YYYY') : ""}
                                    </Text>
                                </View>
                            </View>
                        </View>
                        <View style={{ width: '100%', border: 1, borderColor: '#006DB1', borderTop: 0, marginTop: -1 }}>
                            <View style={{ width: '100%', height: 'auto', flexDirection: 'row' }}>
                                <Text style={{ fontSize: 10, width: '15%', border: 1, borderColor: '#006DB1', borderTop: 0, textAlign: 'center', padding: 6, borderRight: 0, borderLeft: 0, fontFamily: 'Roboto', fontWeight: 'bold', fontSize: '10px' }}>Quantity</Text>
                                <Text style={{ fontSize: 10, width: '55%', border: 1, borderColor: '#006DB1', borderTop: 0, textAlign: 'left', padding: 6, borderRight: 0, fontFamily: 'Roboto', fontWeight: 'bold', fontSize: '10px' }}>Description</Text>
                                <Text style={{ fontSize: 10, width: '15%', border: 1, borderColor: '#006DB1', borderTop: 0, textAlign: 'center', padding: 6, borderRight: 0, fontFamily: 'Roboto', fontWeight: 'bold', fontSize: '10px' }}>Unit Price</Text>
                                <Text style={{ fontSize: 10, width: '15%', border: 1, borderColor: '#006DB1', borderTop: 0, textAlign: 'center', padding: 6, fontFamily: 'Roboto', fontWeight: 'bold', fontSize: '10px', borderRight: 0, }}>Qnty Total</Text>
                            </View>

                            {props.jobInfoPdf && props.jobInfoPdf.jobPriceItems && props.jobInfoPdf.job.displayOnPrintOutsEmails && props.jobInfoPdf.jobPriceItems.map(function (value, index, array) {
                                return <View key={index} style={styles.sectionTable}>
                                    <Text style={{ fontFamily: 'Roboto', fontWeight: 'medium', fontSize: 10, width: '15%', border: 1, borderColor: '#006DB1', textAlign: 'center', padding: 6, borderTop: 0, borderRight: 0, borderLeft: 0, }}>
                                        {value.quantity}
                                    </Text>
                                    <Text style={{ fontFamily: 'Roboto', fontWeight: 'medium', fontSize: 10, width: '55%', border: 1, borderColor: '#006DB1', textAlign: 'left', padding: 6, borderRight: 0, borderTop: 0 }}>
                                        {value.description}
                                    </Text>
                                    <Text style={{ fontFamily: 'Roboto', fontWeight: 'medium', fontSize: 10, width: '15%', border: 1, borderColor: '#006DB1', textAlign: 'right', padding: 6, borderRight: 0, borderTop: 0 }}>
                                        {(value && value.price && `$${value.price.toFixed(2)}`) || '$0.00'}
                                    </Text>
                                    <Text style={{ fontFamily: 'Roboto', fontWeight: 'medium', fontSize: 10, width: '15%', border: 1, borderColor: '#006DB1', textAlign: 'right', padding: 6, borderTop: 0, borderRight: 0, }}>
                                        {(value && value.total && `$${value.total.toFixed(2)}`) || '$0.00'}
                                    </Text>
                                </View>
                            })}

                            {props.jobInfoPdf && props.jobInfoPdf.job && props.jobInfoPdf.job.discountPercent > 0 && props.jobInfoPdf.job.discountDollars > 0
                            ? <View style={styles.sectionTable}>
                                <Text style={{ width: '15%' }}></Text>
                                <Text style={{ width: '55%'}}></Text>
                                <Text style={{ width: '15%', border: 1, borderColor: '#006DB1', borderBottom: 0, borderLeft: 0, borderRight: 0, borderTop: 0, fontSize: 10,textAlign: 'right', padding: 6, fontFamily: 'Roboto', fontWeight: 'bold', fontSize: '10px' }}>
                                    Discount ({props.jobInfoPdf.job.discountPercent}%)
                                </Text>
                                <Text style={{ width: '15%', border: 1, borderColor: '#006DB1', borderTop: 0, fontSize: 10,textAlign: 'right', padding: 6, fontFamily: 'Roboto', fontWeight: 'medium', borderRight: 0 }}>
                                    {(props.jobInfoPdf && props.jobInfoPdf.job && `$${props.jobInfoPdf.job.discountDollars.toFixed(2)}`) || '$0.00'}
                                </Text>
                            </View>
                            : null }

                            <View style={styles.sectionTable}>
                                <Text style={{ width: '15%' }}></Text>
                                <Text style={{ width: '55%'}}></Text>
                                <Text style={{ width: '15%', border: 1, borderColor: '#006DB1', borderBottom: 0, borderLeft: 0, borderRight: 0, borderTop: 0, fontSize: 10,textAlign: 'right', padding: 6, fontFamily: 'Roboto', fontWeight: 'bold', fontSize: '10px' }}>
                                    Sub-Total
                                </Text>
                                <Text style={{ width: '15%', border: 1, borderColor: '#006DB1', borderTop: 0, fontSize: 10,textAlign: 'right', padding: 6, fontFamily: 'Roboto', fontWeight: 'medium', borderRight: 0 }}>
                                    {(props.jobInfoPdf && props.jobInfoPdf.subTotal && `$${props.jobInfoPdf.subTotal.toFixed(2)}`) || '$0.00'}
                                </Text>
                            </View>
                            
                            <View style={styles.sectionTable}>
                                <Text style={{ width: '15%' }}></Text>
                                <Text style={{ width: '55%'}}></Text>
                                <Text style={{ width: '15%', border: 1, borderColor: '#006DB1', borderBottom: 0, borderLeft: 0, borderRight: 0, borderTop: 0, fontSize: 10,textAlign: 'right', padding: 6, fontFamily: 'Roboto', fontWeight: 'bold', fontSize: '10px' }}>
                                    Tax ({props.jobInfoPdf && props.jobInfoPdf.job ? props.jobInfoPdf.job.taxPercent : "0"}%)
                                </Text>
                                <Text style={{ width: '15%', border: 1, borderColor: '#006DB1', borderTop: 0, fontSize: 10,textAlign: 'right', padding: 6, fontFamily: 'Roboto', fontWeight: 'medium', borderRight: 0 }}>
                                    {(props.jobInfoPdf && props.jobInfoPdf.taxDollar && `$${props.jobInfoPdf.taxDollar.toFixed(2)}`) || '$0.00'}
                                </Text>
                            </View>
                            <View style={styles.sectionTable}>
                                <Text style={{ width: '15%' }}></Text>
                                <Text style={{ width: '55%'}}></Text>
                                <Text style={{ width: '15%', border: 1, borderColor: '#006DB1', borderBottom: 0, borderLeft: 0, borderRight: 0, borderTop: 0, fontSize: 10,textAlign: 'right', padding: 6, fontFamily: 'Roboto', fontWeight: 'bold', fontSize: '10px' }}>
                                    Total
                                </Text>
                                <Text style={{ width: '15%', border: 1, borderColor: '#006DB1', borderTop: 0, fontSize: 10,textAlign: 'right', padding: 6, fontFamily: 'Roboto', fontWeight: 'medium', borderRight: 0, borderBottom: 0 }}>
                                    {(props.jobInfoPdf && props.jobInfoPdf.totalAmount && `$${props.jobInfoPdf.totalAmount.toFixed(2)}`) || '$0.00'}
                                </Text>
                            </View>
                        </View>
                    </View>

                    <View wrap={false} style={{ padding: 5, marginTop: 5, paddingTop: 0 }}>
                        <View style={{ width: '100%', height: 'auto', border: 2, borderColor: '#006DB1' }}>
                            <View style={{ backgroundColor: '#FBE9D6', paddingTop: 5, paddingBottom: 5 }}>
                                {props.jobInfoPdf?.company?.quoteForm?.split('\n').map(function (value, index) {
                                        return <View key={index} style={{ flexDirection: 'row' }}>
                                                    <View style={{ width: '5%', marginTop: '-3px' }}>
                                                        <Image src="/images/quote_invoice/dot-24-458488.png" width={5} height={5} />
                                                    </View>
                                                    <View style={{ width: '95%' }}>
                                                        <Text style={{ fontFamily: 'Roboto', fontWeight: 'medium', fontSize: 8, color: 'black', marginTop: 5 }}>
                                                            {value ? value : ""}
                                                        </Text>
                                                    </View>
                                                </View>
                                    }) ?? null
                                }
                                {props.draftPdf
                                    ? props.draftPdf.split('\n').map(function (value, index) {
                                        return <View key={index} style={{ flexDirection: 'row' }}>
                                                    <View style={{ width: '5%', marginTop: '-3px' }}>
                                                        <Image src="/images/quote_invoice/dot-24-458488.png" width={5} height={5} />
                                                    </View>
                                                    <View style={{ width: '95%' }}>
                                                        <Text style={{ fontFamily: 'Roboto', fontWeight: 'medium', fontSize: 8, color: 'black', marginTop: 5 }}>
                                                            {value ? value : ""}
                                                        </Text>
                                                    </View>
                                                </View>
                                    })
                                    : null
                                }
                            </View>
                        </View>
                    </View>

                    <View wrap={false} style={{ padding: 5, paddingTop: 0, marginTop: 5 }}>
                        <View style={{ width: '100%', alignItems: 'center' }}>
                            <View style={{ width: '70%', flexDirection: 'row' }}>
                                <View style={{ width: '49%', height: '100%' }}>
                                    {props.jobInfoPdf?.company?.logoUrl?.includes('companylogo')
                                        ? <Image
                                            src={props.jobInfoPdf.company.logoUrl}
                                            style={{ maxHeight: 80, objectFit: 'contain', objectPosition: 'top left' }}
                                        />
                                        : <View style={{ height: 80 }} />
                                    }
                                </View>
                                <View style={{ width: '2%' }} />
                                <View style={{ width: '49%' }}>
                                    <Text style={{ fontFamily: 'Roboto', fontWeight: 'bold', fontSize: 9, color: 'black', marginTop: 5 }}>
                                        {props.jobInfoPdf && props.jobInfoPdf.company && props.jobInfoPdf.company.addresses && props.jobInfoPdf.company.addresses.length > 0 ? (props.jobInfoPdf.company.addresses[0].address1 || '') : ""}
                                    </Text>
                                    {props.jobInfoPdf && props.jobInfoPdf.company && props.jobInfoPdf.company.addresses && props.jobInfoPdf.company.addresses.length > 0 && props.jobInfoPdf.company.addresses[0].address2 && props.jobInfoPdf.company.addresses[0].address2 != ""
                                        ? <Text style={{ fontFamily: 'Roboto', fontWeight: 'bold', fontSize: 9, color: 'black', marginTop: 5 }}>
                                            {props.jobInfoPdf.company.addresses[0].address2}
                                        </Text>
                                        : null 
                                    }
                                    <Text style={{ fontFamily: 'Roboto', fontWeight: 'bold', fontSize: 9, color: 'black', marginTop: 5 }}>
                                        {props.jobInfoPdf && props.jobInfoPdf.company && props.jobInfoPdf.company.addresses && props.jobInfoPdf.company.addresses.length > 0 ? props.jobInfoPdf.company.addresses[0].city+", " : ""}
                                        {props.jobInfoPdf && props.jobInfoPdf.company && props.jobInfoPdf.company.addresses && props.jobInfoPdf.company.addresses.length > 0 ? props.jobInfoPdf.company.addresses[0].state.name+" " : ""}
                                        {props.jobInfoPdf && props.jobInfoPdf.company && props.jobInfoPdf.company.addresses && props.jobInfoPdf.company.addresses.length > 0 ? props.jobInfoPdf.company.addresses[0].zipCode : ""}
                                    </Text>
                                    <Text style={{ fontFamily: 'Roboto', fontWeight: 'bold', fontSize: 9, color: 'black', marginTop: 5 }}>
                                        Phone: {props.jobInfoPdf && props.jobInfoPdf.company ? props.jobInfoPdf.company.primaryPhoneNumber : ""}
                                    </Text>
                                    <Text style={{ fontFamily: 'Roboto', fontWeight: 'bold', fontSize: 9, color: 'black', marginTop: 5 }}>
                                        Email: {props.jobInfoPdf && props.jobInfoPdf.company ? props.jobInfoPdf.company.email : ""}
                                    </Text>
                                </View>
                            </View>
                        </View>
                    </View>

                    <View wrap={false} style={{ padding: 5, paddingTop: 0 }}>
                        <View style={{ width: '100%', alignItems: 'center' }}>
                            <Text style={{ fontFamily: 'Roboto', fontWeight: 'bold', fontSize: 17.5, color: 'black' }}>
                                {props.jobInfoPdf && props.jobInfoPdf.company ? props.jobInfoPdf.company.website : ""}
                            </Text>
                        </View>
                    </View>

                </Page>
            </Document>
        </>
    )
}

JobQuotePdf.propTypes = {
    
}

export default JobQuotePdf;
