import {
  Button,
  Card,
  CardActions,
  CardHeader,
  colors,
  Divider,
  Modal,
} from "@material-ui/core";
import React from "react";
import { makeStyles } from "@material-ui/styles";

const useStyles = makeStyles((theme) => ({
  styleModal: {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    outline: "none",
    boxShadow: theme.shadows[20],
    width: "500px",
    maxHeight: "100%",
    overflowY: "auto",
    maxWidth: "100%",
  },
  conButton: {
    padding: "16px",
    justifyContent: "flex-end",
  },
  btn: {
    fontSize: 12,
    color: theme.palette.white,
    backgroundColor: colors.green[600],
    whiteSpace: "nowrap",
    marginLeft: 8,
    "&:hover": {
      backgroundColor: colors.green[900],
    },
  },
  buttonSave: {
    flexShrink: "0",
    backgroundColor: colors.green[600],
    "&:hover": {
      backgroundColor: colors.green[900],
    },
  },
}));

const ModalCopy = (props) => {
  const classes = useStyles();

  const { openConfirm, closeConfirm, onConfirm, onScheduleJob } = props;

  return (
    <Modal open={openConfirm}>
      <Card className={classes.styleModal}>
        <CardHeader
          style={{ padding: "28px" }}
          title="The selected job has been copied."
        />
        <Divider />
        <CardActions className={classes.conButton}>
          <Button
            variant="contained"
            size="large"
            onClick={() => {
              closeConfirm();
            }}
          >
            Close
          </Button>
          <Button
            style={{ marginLeft: "6px" }}
            variant="contained"
            color="primary"
            size="large"
            onClick={onConfirm}
            className={classes.buttonSave}
          >
            View Job
          </Button>
          <Button
            style={{ marginLeft: "6px" }}
            variant="contained"
            color="primary"
            size="large"
            className={classes.buttonSave}
            onClick={onScheduleJob}
          >
            Schedule Job
          </Button>
        </CardActions>
      </Card>
    </Modal>
  );
};
export default ModalCopy;
