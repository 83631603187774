import { Button, Card, CardActions, CardContent, CardHeader, CircularProgress, Divider, FormControlLabel, Grid, Modal, TextField, colors } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import clsx from 'clsx';
import { errorMessages } from 'common/constants';
import { FormErrorCallback, GreenCheckbox } from 'components';
import { Formik } from 'formik';
import moment from 'moment';
import React, { useMemo, useState } from 'react';
import NumberFormat from 'react-number-format';
import { formatNumber } from 'utils/formatNumber';
import * as Yup from 'yup';
import axios from 'utils/axios';
import apiConfig from 'apiConfig';

const celeroSchema = Yup.object().shape({
  celeroPaymentRequest: Yup.object().shape({
    fee_Surcharge: Yup.number()
    .min(0, 'Must be greater than $0')
    .test({
      name: 'max',
      exclusive: false,
      params: { },
      message: 'Must be less than 10% of the amount',
      test: function (value) {
          return value <= parseFloat(this.parent.amount * 0.1);
      },
    }),
    fee_Percent:Yup.number()
    .min(0, 'Must be a decimal between 0 and 10')
    .max(10, 'Must be a decimal between 0 and 10'),
  })
});

const useStyles = makeStyles(theme => ({
  styleModal: {
      position: 'absolute',
      top: '50%',
      left: '50%',
      transform: 'translate(-50%, -50%)',
      outline: 'none',
      boxShadow: theme.shadows[20],
      width: 700,
      maxHeight: '100%',
      overflowY: 'auto',
      maxWidth: '100%',
      [theme.breakpoints.down('sm')]: {
          width: 500
      },
  },
  content: {
      display: 'flex',
      alignItems: 'center'
  },
  title: {
      textAlign: 'right'
  },
  btn: {
      flexShrink: '0',
      color: theme.palette.white,
      backgroundColor: colors.green[600],
      '&:hover': {
          backgroundColor: colors.green[900]
      },
  },
  btnDis: {
      color: 'rgba(0, 0, 0, 0.26)',
      backgroundColor: 'rgb(217 217 217)',
      pointerEvents: 'none',
  },
  actionCard: {
      padding: '8px 16px',
      justifyContent: 'flex-end',
      alignItems: 'center'
  }
}));

const CeleroPaymentInfoModal = ({ open, celeroPaymentInfo, encryptedPaymentInfo, setOpenSnackBar, setIsStatus, onClose }) => {

  const classes = useStyles();

  const [saving, setSaving] = useState(false);

  const paymentLink = useMemo(() => `${window.location.origin}/payment/${encryptedPaymentInfo}`, [encryptedPaymentInfo]);

  const onChangeCustomerPaysFee = (event, setFieldValue) => {
    setFieldValue('celeroPaymentRequest.fee_Variable', event.target.checked);
    setFieldValue('celeroPaymentRequest.fee_Surcharge', 0);
    setFieldValue('celeroPaymentRequest.fee_Percent', 0);
  };

  const onChangeSurcharge = (event, setFieldValue) => {
    const { value } = event.target;
    setFieldValue('celeroPaymentRequest.fee_Surcharge', value ? parseFloat(value.replace(/[$,]/g, '')) : 0);
  };

  const onChangePercent = (event, setFieldValue) => {
    const { value } = event.target;
    setFieldValue('celeroPaymentRequest.fee_Percent', value ? parseFloat(value.replace(/[%,]/g, '')) : 0);
  };

  return (
    <Modal open={open} onClose={onClose}>
      <Formik
        initialValues={celeroPaymentInfo}
        validationSchema={celeroSchema}
        onSubmit={values => {
          setSaving(true);
          const { jobId } = celeroPaymentInfo.celeroPaymentRequest;
          const data = { ...values.celeroPaymentRequest };
          axios.post(apiConfig.url.BASE_URL + apiConfig.url.PAYMENT_CREATE_PAYMENT.replace('{jobId}', jobId), data)
            .then(res => {
              if (res.data) {
                setIsStatus({ failed: false, msg: 'Save successfully.' });
                setOpenSnackBar(true);
                onClose({ ...values });
              }
            })
            .catch(err => {
              setIsStatus({ failed: true, msg: 'An error occurred. Please try again later.' });
              setOpenSnackBar(true);
            })
            .finally(() => setSaving(false));
        }}
      >
        {({
          values,
          errors,
          touched,
          submitCount,
          isValid,
          setFieldValue,
          handleChange,
          handleBlur,
          handleSubmit
        }) => (
          <form onSubmit={handleSubmit}>
            <FormErrorCallback
              submitCount={submitCount}
              isValid={isValid}
              onSubmissionError={() => {
                setIsStatus({ failed: true, msg: errorMessages.FIELD_CHECK });
                setOpenSnackBar(true);
              }}
            />
            <Card className={classes.styleModal}>
              <CardHeader title="Celero Payment Request Info" />
              <Divider />
              <CardContent className={classes.content}>
                {(() => {
                  const {
                    amount,
                    tax_Amount,
                    fee_Percent,
                    fee_Surcharge,
                    fee_Variable,
                    dateRequested,
                    dateCompleted,
                    status
                  } = values.celeroPaymentRequest ?? {};
                  return (
                    <Grid container spacing={1} alignItems="center">
                      <Grid item xs={4} md={3} className={classes.title}>Amount:</Grid>
                      <Grid item xs={8} md={9}>${formatNumber(amount, 2)}</Grid>
                      <Grid item xs={4} md={3} className={classes.title}>Tax:</Grid>
                      <Grid item xs={8} md={9}>${formatNumber(tax_Amount, 2)}</Grid>
                      {/* <Grid item xs={4} md={3} className={classes.title}>Calculated Fees:</Grid>
                      <Grid item xs={8} md={9}>${formatNumber(amount * (fee_Percent / 100) + fee_Surcharge, 2)}</Grid> */}
                      <Grid item xs={4} md={3} className={classes.title}>Date Requested:</Grid>
                      <Grid item xs={8} md={9}>{dateRequested && moment(dateRequested).format('MM/DD/YYYY')}</Grid>
                      <Grid item xs={4} md={3} className={classes.title}>Date Completed:</Grid>
                      <Grid item xs={8} md={9}>{dateCompleted && moment(dateCompleted).format('MM/DD/YYYY')}</Grid>
                      <Grid item xs={4} md={3} className={classes.title}>Status:</Grid>
                      <Grid item xs={8} md={9}>{status}</Grid>
                      <Grid item xs={4} md={3} className={classes.title}>Payment Link:</Grid>
                      <Grid item xs={8} md={9}>
                        <TextField
                          fullWidth
                          value={paymentLink}
                          size="small"
                          variant="outlined"
                          readOnly={true}
                        />
                      </Grid>
                      <Grid item xs={4} md={3}></Grid>
                      <Grid item xs={8} md={9}>
                        <Button
                          className={classes.btn}
                          onClick={() => {
                            navigator.clipboard.writeText(paymentLink);
                            setIsStatus({ failed: false, msg: 'Copied.' });
                            setOpenSnackBar(true);
                          }}>
                          Copy Link
                        </Button>
                      </Grid>
                      {/* <Grid item xs={12} style={{ margin: '0 20%' }}>
                        <FormControlLabel
                          label="Customer Pays Fee"
                          name="celeroPaymentRequest.fee_Variable"
                          checked={fee_Variable}
                          onChange={event => onChangeCustomerPaysFee(event, setFieldValue)}
                          control={
                            <GreenCheckbox />
                          }
                        />
                      </Grid>
                      <Grid item xs={12} style={{ margin: '0 20%' }}>
                        <NumberFormat
                          label="Convenience Fees"
                          name="celeroPaymentRequest.fee_Surcharge"
                          variant="outlined"
                          disabled={fee_Variable}
                          value={fee_Variable ? 0 : fee_Surcharge}
                          decimalScale={2}
                          decimalSeparator="."
                          thousandSeparator
                          min={0}
                          max={amount * 0.1}
                          allowNegative={false}
                          prefix="$"
                          onChange={event => onChangeSurcharge(event, setFieldValue)}
                          onBlur={handleBlur}
                          customInput={TextField}
                          error={errors.celeroPaymentRequest?.fee_Surcharge && touched.celeroPaymentRequest?.fee_Surcharge}
                          helperText={touched.celeroPaymentRequest?.fee_Surcharge && errors.celeroPaymentRequest?.fee_Surcharge}
                        />
                      </Grid>
                      <Grid item xs={12} style={{ margin: '0 20%' }}>
                        <NumberFormat
                          label="Convenience Percent"
                          name="celeroPaymentRequest.fee_Percent"
                          variant="outlined"
                          disabled={fee_Variable}
                          value={fee_Variable ? 0 : fee_Percent}
                          decimalSeparator="."
                          thousandSeparator
                          min={0}
                          max={10}
                          allowNegative={false}
                          suffix="%"
                          onChange={event => onChangePercent(event, setFieldValue)}
                          onBlur={handleBlur}
                          customInput={TextField}
                          error={errors.celeroPaymentRequest?.fee_Percent && touched.celeroPaymentRequest?.fee_Percent}
                          helperText={touched.celeroPaymentRequest?.fee_Percent && errors.celeroPaymentRequest?.fee_Percent}
                        />
                      </Grid> */}
                    </Grid>
                  );
                })()}
              </CardContent>
              <Divider />
              <CardActions className={classes.actionCard}>
                <Button className={classes.btn} onClick={onClose} style={{ marginRight: 8 }}>
                  Cancel
                </Button>
                <Button className={clsx(classes.btn, { [classes.btnDis]: saving })} disabled={saving} type="submit">
                  Save
                </Button>
                {saving && <CircularProgress size={24} />}
              </CardActions>
            </Card>
          </form>
        )}
      </Formik>
    </Modal>
  );
};

export default CeleroPaymentInfoModal;
