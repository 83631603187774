import React, { useCallback, useState, useEffect, useRef, useMemo } from 'react'
import PropTypes from 'prop-types'
import { useLocation, useParams } from 'react-router-dom'
import RouteContent from './components/RouteContent'
import RouteTitle from './components/RouteTitle'
import useMediaQuery from "@material-ui/core/useMediaQuery";
import {
    CircularProgress, Typography,
    Tooltip, Snackbar, colors, IconButton , Card
} from '@material-ui/core'
import { Alert } from "@material-ui/lab"
import { makeStyles } from '@material-ui/styles'
import { useSelector, useDispatch } from 'react-redux'
import moment from 'moment'
import calendar from 'assets/images/calendar.svg'
import calendarAlt from 'assets/images/calendar-alt.svg'
import { times } from 'mockup/Routes'

import axios from "utils/axios";
import apiConfig from "apiConfig";

import { checkFeature, FeatureFlags } from 'FeatureService'
import { Map } from './components/Map'
import { RouteColors } from './Constants'
import { ExpandLess, ExpandMore } from '@material-ui/icons'
import { Resizable } from 're-resizable'

const useStyles = makeStyles(theme => ({
    routesOver: {
        width: "100%",
        overflowX: "visible",
        position: "relative",
        '&:hover $collapseExpandButton': {
            opacity: 1
        },
        '&:last-child $routesContent': {
            marginBottom: 0
        }
    },
    actionBox: {
        width: "100%",
        height: "100%",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        position: "absolute",
        top: 0,
        left: 0,
        zIndex: 2,
        backgroundColor: "#0000001f"
    },
    routesContent: {
        display: "flex",
        border: "1px solid #c7c7c7",
        borderRadius: "4px",
        marginBottom: theme.spacing(1)
    },
    routesContentLeft: {
        width: "210px",
        flexShrink: 0,
        borderRight: "1px solid #c7c7c7",
        cursor: 'pointer'
    },
    routeTitle: {
        height: "40px",
        lineHeight: "24px",
        padding: "8px",
    },
    routesContentRight: {
        width: "100%",
        overflow: "hidden"
    },
    noData: {
        textAlign: "center",
        fontWeight: 500,
        fontSize: 16
    },
    routeIcon: {
        '& i': {
            fontSize: '1em'
        },
        width: 25,
        height: 30
    },
    routeTitleLeft: {
        height: 40,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        borderBottom: '1px solid #c7c7c7',
        paddingRight: 8,
    },
    selectedRoute: {
        backgroundColor: '#d0d0d0',
    },
    routeTitleOrder: {
        fontSize: '20px',
        fontWeight: 400,
        paddingLeft: '3px',
    },
    collapseExpandButton: {
        position: 'absolute',
        top: 1,
        right: 1,
        padding: 6,
        background: 'white',
        boxShadow: '0 1px 6px 0 #20212451',
        opacity: 0.4,
        transition: 'all .3s ease',
        '&:hover': {
            background: 'white'
        }
    },
    colExpand:{
        position: 'absolute',
        height: "100%",
        top: "0px",
        right: "-3px",
        display:"flex",
        alignItems:"center",
        justifyContent:"center"
    },
    iconColExpand:{
        border: "0px",
        borderLeft: "1px solid #9c9c9c",
        borderRight: "1px solid #9c9c9c",
        height: "35px",
        width: "6px",
    }
}));

const RoutesContent = ({ statusRoutes, selectedRoute, changeSelectedRoute, recurJob, showJobNotes, loadMap, mapVisible, drawingManagerOptions , colorInput , disableJobResizing }) => {
    const classes = useStyles();
    const location = useLocation();
    const dispatch = useDispatch();
    const { date } = useParams();
    const itemsRef = useRef([]);
    const resizableRef = useRef();
    const v_ipad = useMediaQuery("(max-width: 769px)");

    const { routes, orderType, isUpdate, isAdd, expanded, reloadRoutes } = useSelector(state => state.RoutesReducer);

    const [routeCounts, setRouteCounts] = useState(routes.length);
    const [filterRoutes, setFilterRoutes] = useState();
    const [loading, setLoading] = useState(false);
    const [isStatus, setIsStatus] = useState({ failed: false, msg: "" });
    const [openSnackbar, setOpenSnackbar] = useState(false);

    const [enableVisualMap, setEnableVisualMap] = useState(false);
    const [expandContent, setExpandContent] = useState({});

    const mapCoordinates = useMemo(
        () => [...(filterRoutes ?? [])]
            .sort((r1, r2) => (selectedRoute?.id === r1.id) - (selectedRoute?.id === r2.id))
            .reduce(
                (coords, route) => coords.concat(
                    route.routeJobs
                        .sort((rj1, rj2) => new Date(rj1.jobStart) - new Date(rj2.jobStart))
                        .map((routeJob, index) => ({
                            routeNumber: route.routeOrder,
                            jobNumber: selectedRoute?.id === route.id ? (index + 1) : null,
                            latitude: routeJob.job?.latitude,
                            longitude: routeJob.job?.longitude,
                            jobSplitId: routeJob.jobSplitId,
                            routeId: route.id
                        }))
                ),
                []
            ),
        [filterRoutes, selectedRoute]
    );

    const filter = (status) => {
        if (!routes || routes.length <= 0) {
            setFilterRoutes([]);
            return;
        }
        if (orderType !== "DISTANCE") {
            let routeActive = [...routes.filter(x => !x.routeFull)];
            let routeCompleted = [...routes.filter(x => x.routeFull)];
            routeActive = routeActive.map(x => {
                delete x.distance;
                return x;
            });
            routeCompleted = routeCompleted.map(x => {
                delete x.distance;
                return x;
            });
            if (status === "availability") {
                routeActive = routeActive.map(x => {
                    if (x.routeJobs.length > 1) {
                        x["totalHour"] = x.routeJobs.reduce((r, a) => {
                            return r + a.estimatedHours;
                        }, 0);
                    } else if (x.routeJobs.length === 1) {
                        x["totalHour"] = x.routeJobs[0].estimatedHours;
                    } else {
                        x["totalHour"] = 0;
                    }
                    return x;
                });
                routeActive = routeActive.sort(
                    (a, b) => parseFloat(a.totalHour) - parseFloat(b.totalHour)
                );
                setFilterRoutes([...routeActive, ...routeCompleted]);
            }
        }
        if (status === "route-number" && !orderType) {
            const rt = routes.sort(
                (a, b) => parseFloat(a.routeOrder) - parseFloat(b.routeOrder)
            );
            setFilterRoutes([...rt]);
        }

        if (
            (status === "distance" || status === "") &&
            orderType === "DISTANCE"
        ) {
            let routeHasJob = routes.filter(x => x.routeJobs.length > 0);
            let routeNoJob = routes.filter(x => x.routeJobs.length === 0);
            routeHasJob = routeHasJob.sort(
                (a, b) => parseFloat(a.distance) - parseFloat(b.distance)
            );
            setFilterRoutes([...routeHasJob, ...routeNoJob]);
        }
    }

    const handleErrorOfRoute = error => {
        setIsStatus(error);
        setOpenSnackbar(true);
    };

    useEffect(() => {
        filter(statusRoutes);
    }, [statusRoutes])

    useEffect(() => {
        if (!routes) return;
        filter(statusRoutes);
        setRouteCounts(routes.length)
    }, [routes]);

    useEffect(() => {
        if (!isAdd || !itemsRef.current || itemsRef.current.length === 0) return;
        itemsRef.current[routeCounts - 1].firstChild.scrollLeft = 800;
        dispatch({ type: "IS_ADD_ROUTE", isAdd: false })
    }, [routeCounts])

    useEffect(() => {
        setTimeout(() => {
            if (itemsRef.current) {
                itemsRef.current.forEach(ref => {
                    if (ref) {
                        ref.firstChild.scrollLeft = 800;
                    }
                });
            }
        });
    }, [orderType]);

    // GET route by date
    useEffect(() => {
        setLoading(true);
        axios.get(apiConfig.url.BASE_URL + apiConfig.url.ROUTE + `${date}/list`)
            .then(res => {
                res.data = res.data.filter(route => {
                    return (
                        moment(route.routeDate).format("DD/MM/YYYY") ===
                        moment(date).format("DD/MM/YYYY")
                    );
                });
                res.data.forEach(route => {
                    route.routeJobs = route.routeJobs.filter(job => {
                        return (
                            moment.utc(job.jobStart) >= moment(date).startOf("dates") &&
                            moment.utc(job.jobStart) <= moment(date).endOf("dates")
                        );
                    });
                    route.routeJobs.map((item, index) => {
                        route.routeJobs[index].price = item.job ? item.job.price : 0;
                        const duration = moment.duration(
                            moment
                                .utc(item.jobEnd)
                                .local()
                                .diff(moment.utc(item.jobStart).local())
                        );
                        route.routeJobs[index].estimatedHours = duration.asMinutes() / 60;

                        route.routeJobs[index].description = item.job
                            ? item.job.description
                            : "";
                        route.routeJobs[index].city = item.job && item.job.city ? item.job.city : "";
                        route.routeJobs[index].jobStart = moment
                            .utc(item.jobStart)
                            .local()
                            .toISOString();
                        route.routeJobs[index].jobEnd = moment
                            .utc(item.jobEnd)
                            .local()
                            .toISOString();
                    });
                });

                dispatch({ type: "INIT_ROUTES", routes: res.data });

                if (location?.state?.jobId) {
                    const initialSelectedRoute = res.data.find(
                        route => route.routeJobs.some(
                            routeJob => routeJob?.job?.jobId === location.state.jobId && routeJob.job.isPrimaryJob
                        )
                    );
                    if (initialSelectedRoute) {
                        changeSelectedRoute(initialSelectedRoute);
                    }
                }
            })
            .finally(() => setLoading(false));
    }, [date, reloadRoutes]);

    useEffect(() => {
        if (orderType === "DISTANCE") {
            dispatch({ type: "SHOW_DISTANCE", showDistance: true });
        }
    }, [orderType])

    // Set default scrollbar
    const widthRef = useCallback(node => {
        if (itemsRef.current) {
            itemsRef.current.map(ref => {
                if (ref) {
                    ref.firstChild.scrollLeft = 800;
                }
            });
        }
        setTimeout(
            () => window.dispatchEvent(new Event('resize')),
            200
        );
    }, [expanded, loading])

    useEffect(() => {
        (async () => {
            const isEnableVisualMap = await checkFeature(FeatureFlags.VISUAL_MAP);
            setEnableVisualMap(isEnableVisualMap);
        })();

        return setFilterRoutes(undefined);
    }, []);


    useEffect(() => {
        resizableRef.current.updateSize({ width: mapVisible && !v_ipad ? '50%' : '100%' });
        setTimeout(
            () => window.dispatchEvent(new Event('resize')),
            0
        );
    }, [mapVisible]);

    return (
        <>
            <Card innerRef={widthRef} elevation={0} style={{ position: "relative", display: 'flex', width: '100%', minWidth: 0, maxHeight: '100%' }}>
                <Resizable
                    ref={resizableRef}
                    defaultSize={{
                        width: mapVisible && !v_ipad ? '50%' : '100%',
                    }}
                    minHeight="100%"
                    enable={{
                        top:false,
                        right:mapVisible ? true : false,
                        bottom:false,
                        left:false,
                        topRight:false,
                        bottomRight:false,
                        bottomLeft:false,
                        topLeft:false
                    }}
                    style={{ marginRight: 5 }}
                    onResize={() => window.dispatchEvent(new Event('resize'))}
                >
                    <div style={{ height: '100%', overflowY: 'auto', marginRight: mapVisible ? 5 : 0 }}>
                        {loading ? (
                            <div style={{ textAlign: "center", padding: 14 }}>
                                <CircularProgress size={24} />
                            </div>
                        ) : (<>
                            {isUpdate && (
                                <div className={classes.actionBox}>
                                    <CircularProgress size={32} />
                                </div>
                            )}
                            {!filterRoutes ||
                                (filterRoutes.length === 0 && (
                                    <Typography className={classes.noData}>
                                        No route found.
                                    </Typography>
                                ))}
                            {Array.isArray(filterRoutes) &&
                                filterRoutes.length > 0 &&
                                filterRoutes.map((route, index) => {

                                    const isSelected = selectedRoute && selectedRoute.id === route.id;
                                    return (
                                        <div key={route.id} className={classes.routesOver}>
                                            <div key={route.id}
                                                className={classes.routesContent}
                                                style={route.routeFull ? {
                                                    background: '#e0e0e0',
                                                    opacity: .74
                                                } : null}
                                            >
                                                {!v_ipad && (
                                                    <div className={`${classes.routesContentLeft} ${isSelected ? classes.selectedRoute : null}`}
                                                        onClick={() => changeSelectedRoute(route)}>

                                                        <div className={classes.routeTitleLeft} style={(expandContent[route.id] ?? true) ? null : { borderBottomColor: 'transparent' }}>
                                                            <Typography className={classes.routeTitle} style={{ backgroundColor: mapVisible ? RouteColors[(route.routeOrder % 30)] : null }}>

                                                                Route
                                                                <strong className={classes.routeTitleOrder}>{route.routeOrder}</strong>
                                                            </Typography>
                                                            {route.distance !== undefined && route.routeJobs.length > 0 &&
                                                                <Typography style={{ fontSize: '12px' }}>
                                                                    <span>Closest Job</span>
                                                                    <br />
                                                                    <span>{`${Math.round(route.distance)} Miles`}</span>
                                                                </Typography>
                                                            }

                                                            {(expandContent[route.id] ?? true)
                                                                ? null
                                                                : <span style={{ fontSize: 12 }}>{!isNaN(route.totalPrice) ? `$${route.totalPrice.toFixed(2)}` : ''}</span>
                                                            }


                                                            <Typography style={{ display: 'flex' }}>
                                                                {route.routeFull &&
                                                                    <Tooltip title="Route Full" arrow>
                                                                        <img id={'full' + route.routeOrder}
                                                                            className={classes.routeIcon}
                                                                            src={calendarAlt}
                                                                            alt='calendar'
                                                                        />
                                                                    </Tooltip>
                                                                }
                                                                {
                                                                    !route.routeFull &&
                                                                    <Tooltip title="Route Not Full" arrow>
                                                                        <img id={'full' + route.routeOrder}
                                                                            className={classes.routeIcon}
                                                                            src={calendar}
                                                                            alt='calendar'
                                                                        />
                                                                    </Tooltip>
                                                                }
                                                                {route.recurringRouteParentId &&
                                                                    <Tooltip title="Recurring Route" arrow>
                                                                        <div className={classes.routeIcon}>
                                                                            <span className="fa-stack fa-1x" style={{ height: '1.4em', width: '1.5em', verticalAlign: 'center', fontSize: 21, color: '#000' }}>
                                                                                <i style={{ fontSize: '1.4em' }} className="fal fa-calendar fa-stack-1x" />
                                                                                <i className="fal fa-redo-alt fa-stack-1x"
                                                                                    style={{ fontSize: '1.4em', width: '0.45m', height: '0.4em', bottom: '-0.3em' }} />
                                                                            </span>
                                                                        </div>
                                                                    </Tooltip>
                                                                }
                                                            </Typography>

                                                        </div>

                                                        <RouteTitle route={route} expandContent={expandContent[route.id] ?? true} />

                                                    </div>
                                                )}
                                                <div
                                                    ref={el => (itemsRef.current[index] = el)}
                                                    className={classes.routesContentRight}
                                                >
                                                    <RouteContent
                                                        route={route}
                                                        error={handleErrorOfRoute}
                                                        changeSelectedRoute={changeSelectedRoute}
                                                        time={times}
                                                        isSelected={isSelected}
                                                        recurJob={recurJob}
                                                        showJobNotes={showJobNotes}
                                                        colorInput={colorInput}
                                                        expandContent={expandContent[route.id] ?? true}
                                                        disableJobResizing={disableJobResizing}
                                                    />
                                                </div>
                                            </div>

                                            <IconButton
                                                className={classes.collapseExpandButton}
                                                aria-label="Collapse Expand Button"
                                                onClick={() => setExpandContent({ ...expandContent, [route.id]: !(expandContent[route.id] ?? true) })}>
                                                {(expandContent[route.id] ?? true) ? <ExpandLess /> : <ExpandMore />}
                                            </IconButton>
                                        </div>
                                    );
                                })}
                        </>)}
                    </div>
                    {mapVisible && !v_ipad && (
                        <div class={classes.colExpand} >
                            <span class={classes.iconColExpand}></span>   
                        </div>
                    )}
                </Resizable>
                {enableVisualMap && !v_ipad && loadMap && <div style={{ width: '100%', height: '100%', marginLeft: 4, display: mapVisible ? 'block' : 'none' }}>
                    <Map coordinates={mapCoordinates} drawingManagerOptions={drawingManagerOptions} />
                </div>}
            </Card>

            <Snackbar
                open={openSnackbar}
                onClose={() => setOpenSnackbar(false)}
                autoHideDuration={2000}
                anchorOrigin={{ vertical: "top", horizontal: "center" }}
            >
                <Alert
                    elevation={6}
                    variant="filled"
                    severity={isStatus.failed ? "error" : "success"}
                >
                    <Typography color="inherit" variant="h6">
                        {isStatus.msg}
                    </Typography>
                </Alert>
            </Snackbar>
        </>
    )
}

RoutesContent.propTypes = {
    isDragging: PropTypes.bool,
    statusRoutes: PropTypes.string,
    selectedRoute: PropTypes.object,
    changeSelectedRoute: PropTypes.func
}

export default RoutesContent;
