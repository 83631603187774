import React, { forwardRef, useEffect, useImperativeHandle, useState } from 'react';
import { Button, CircularProgress, colors, Grid, Snackbar, TextField, Typography } from '@material-ui/core';
import { Alert, Autocomplete, createFilterOptions } from '@material-ui/lab';
import { makeStyles } from '@material-ui/styles';
import { useSearchDelay } from 'utils/useSearchDelay';
import { isValidPattern } from 'common/function';
import { ADDRESS_PATTERN } from 'common/patterns';

import axios from 'utils/axios';
import apiConfig from 'apiConfig';
import AddressSuggestedModal from 'components/AddressSuggestedModal';
import ModalForceSave from 'components/ModalForceSave';

const useStyles = makeStyles(theme => ({
  zipWrap: {
    [theme.breakpoints.up('md')]: {
      flexGrow: 1,
      flexBasis: 'initial',
      maxWidth: 'initial'
    }
  },
  verifyWrap: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    [theme.breakpoints.up('md')]: {
      flexBasis: 'initial',
      maxWidth: 'initial'
    }
  },
  saveBtn: {
    color: theme.palette.white,
    backgroundColor: colors.green[600],
    '&:hover': {
      backgroundColor: colors.green[900]
    }
  },
  snackBar: {
    width: '100%',
    '& > * + *': {
      marginTop: theme.spacing(2),
    },
  }
}));

const AddressInput = forwardRef(({
  errors,
  values,
  touched,
  setFieldValue,
  setFieldTouched,
  preverify
}, ref) => {
  const classes = useStyles();

  const [addressInput, setAddressInput] = useState('');
  const [loadingSuggestion, setLoadingSuggestion] = useState(false);
  const [openSuggestion, setOpenSuggestion] = useState(false);
  const [suggestions, setSuggestions] = useState([]);
  const [isValid, setIsValid] = useState(!!(values.address?.latitude && values.address?.longitude));
  const [forceSave, setForceSave] = useState(false);
  const [isSuggestionSelected, setIsSuggestionSelected] = useState(false);
  const [stateBlur, setStateBlur] = useState(false);
  const [verifying, setVerifying] = useState(false);
  const [coordinate, setCoordinate] = useState({
    latitude: values.address?.latitude,
    longitude: values.address?.longitude,
  });
  const [addressSuggested, setAddressSuggested] = useState([]);
  const [openSuggestedModal, setOpenSuggestedModal] = useState(false);
  const [openModalForceSave, setOpenModalForceSave] = useState(false);
  const [openSnackbar, setOpenSnackBar] = useState(false);
  const [isStatus, setIsStatus] = useState({ failed: false, msg: '' });

  const suggestAddressQuery = useSearchDelay(addressInput);

  const onVerify = () => {
    const { address1, city, state } = values.address;
    setFieldTouched('address', { address1: true, city: true, zipCode: true });
    setStateBlur(true);
    if (address1 && city && state?.id) {
      if (typeof preverify === 'function') preverify(values).then(res => res && verifyAddress());
      else verifyAddress();
    }
  }

  const verifyAddress = (external = false) => {
    const data = {
      name: values.address?.name ?? '',
      address1: values.address?.address1 ?? '',
      address2: values.address?.address2 ?? '',
      city: values.address?.city ?? '',
      stateId:values.address?.state?.id ?? '',
      zipCode: values.address?.zipCode ?? '',
    };
    if (!external) setVerifying(true);
    return axios
      .post(apiConfig.url.BASE_URL + apiConfig.url.CUSTOMER_JOB_ADDRESSES_VERIFY, data)
      .then(res => {
        if (res.data.isValid) {
          setCoordinate({
            latitude: res.data.metadata.latitude,
            longitude: res.data.metadata.longitude,
          });
          setIsValid(true);
          if (external) {
            values.address.latitude = res.data.metadata.latitude;
            values.address.longitude = res.data.metadata.longitude;
            return values;
          } else {
            if (res.data.suggestions.length > 0) {
              setAddressSuggested(res.data.suggestions);
              setOpenSuggestedModal(true);
            }
            setIsStatus({ failed: false, msg: "Address is valid." });
            setOpenSnackBar(true);
          }
        } else {
          setOpenModalForceSave(true);
          setIsStatus({ failed: true, msg: "Address is invalid." });
          setOpenSnackBar(true);
        }
      })
      .catch(() => {
        setIsStatus({
          failed: true,
          msg: "An error was happend, please try again later.",
        });
        setOpenSnackBar(true);
      })
      .finally(() => {
        if (!external) setVerifying(false);
      });
  };

  const onSelectAddressSuggested = addressSelected => {
    const address = {
        ...values.address,
        name: addressSelected?.name ?? values.address.name ?? '',
        address1: addressSelected?.address1 ?? '',
        address2: addressSelected?.address2 ?? '',
        city: addressSelected?.city ?? '',
        state: values.states.find(s => s.id === addressSelected?.stateId),
        zipCode: addressSelected?.zipCode ?? ''
    };
    setFieldValue('address', address);
    setIsValid(true);
}

  const onForceSave = () => {
    setForceSave(true);
    setOpenModalForceSave(false);
  }

  const onCancelForceSave = () => {
    const address = { ...values.address, address1: '', city: '', state: '', zipCode: '' };
    setFieldValue('address', address);
    setAddressInput('');
    setSuggestions([]);
    setOpenModalForceSave(false);
  }

  useEffect(() => {
    if (isSuggestionSelected) {
      setIsSuggestionSelected(false);
      return void 0;
    }

    // only start calling autocomplete after a series of alphanumeric, a space and at least one more alphanumeric
    if (!isValidPattern(ADDRESS_PATTERN, suggestAddressQuery)) {
      setSuggestions([]);
      setOpenSuggestion(false);
      return void 0;
    }

    let active = true;
    setLoadingSuggestion(true);
    axios
      .get(
        apiConfig.url.BASE_URL + apiConfig.url.ADDRESS_AUTOCOMPLETE,
        { params: { prefix: suggestAddressQuery }}
      )
      .then(res => {
        if (active) {
          if (res.data && res.data.length > 0) {
            setSuggestions(res.data);
            setOpenSuggestion(true);
          } else {
            setSuggestions([]);
            setOpenSuggestion(false);
          }
        }
      })
      .finally(() => setLoadingSuggestion(false));

    return () => active = false;
  }, [suggestAddressQuery]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (!loadingSuggestion && suggestions.length === 0) {
      setOpenSuggestion(false);
    }
  }, [loadingSuggestion, suggestions]);

  useImperativeHandle(ref, () => ({
    coordinate,
    isValid,
    forceSave,
    verifyFn: verifyAddress,
    setStateBlurFn: setStateBlur
  }));

  return (
    <>
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <Autocomplete
            freeSolo
            id="address1"
            loading={loadingSuggestion}
            open={openSuggestion}
            value={values.address?.address1 ?? ''}
            options={suggestions}
            onOpen={() => setOpenSuggestion(true)}
            onClose={() => setOpenSuggestion(false)}
            getOptionLabel={option => option.text || (values.address?.address1 ?? '')}
            onFocus={() => setAddressInput(values.address?.address1)}
            onChange={(event, value) => {
              if (!value) setAddressInput('');
              setIsValid(false);
              setForceSave(false);
              if (value && typeof value === 'object') {
                const state = values.states.find(x => x.code === value.state);
                const address = {
                  ...values.address,
                  address1: value.street_line,
                  address2: '',
                  city: value.city,
                  state: state || '',
                  zipCode: value.zip_code
                };
                setAddressInput(value.street_line);
                setFieldValue('address', address);
                setIsSuggestionSelected(true);
              }
            }}
            renderInput={params => (
              <TextField
                {...params}
                label="Address1"
                variant="outlined"
                InputProps={{
                  ...params.InputProps,
                  endAdornment: (
                    <>
                      {loadingSuggestion ? <CircularProgress color="inherit" size={20} /> : null}
                      {params.InputProps.endAdornment}
                    </>
                  )
                }}
                error={errors.address?.address1 && touched.address?.address1}
                helperText={touched.address?.address1 && errors.address?.address1}
                onChange={(event, value) => {
                  setForceSave(false);
                  setAddressInput(event.target.value);
                  setFieldValue('address.address1', event.target.value);
                }}
              />
            )}
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            fullWidth
            id="address2"
            label="Address2"
            name="address2"
            variant="outlined"
            value={values.address?.address2 ?? ''}
            onChange={event =>  setFieldValue('address.address2', event.target.value)}
          />
        </Grid>
        <Grid item xs={12} md={4}>
          <TextField
            fullWidth
            id="city"
            label="City"
            name="city"
            value={values.address?.city ?? ''}
            variant="outlined"
            error={errors.address?.city && touched.address?.city}
            helperText={touched.address?.city && errors.address?.city}
            onBlur={() => setFieldTouched('address.city', true)}
            onChange={event => {
              setFieldValue('address.city', event.target.value);
              setIsValid(false);
              setForceSave(false);
            }}
          />
        </Grid>
        <Grid item xs={12} md={4}>
          {
            values.states?.length &&
            <Autocomplete
              fullWidth
              autoHighlight
              autoSelect
              id="state"
              name="state"
              value={values.states?.find(s => s.id === values.address?.state?.id) ?? null}
              options={values.states}
              getOptionLabel={option => option.name}
              filterOptions={createFilterOptions({
                matchFrom: 'any',
                stringify: option => `${option.code} ${option.name}`
              })}
              onBlur={() => setStateBlur(!values.address?.state?.id)}
              renderInput={params => (
                <TextField
                  {...params}
                  label="State"
                  variant="outlined"
                  error={stateBlur && !values.address?.state?.id}
                  helperText={stateBlur && !values.address?.state?.id ? 'This field is required.' : ''}
                />
              )}
              onChange={(event, value) => {
                const state = value ? values.states.find(s => s.id === value.id) : null;
                setFieldValue('address.state', state);
                setIsValid(false);
                setForceSave(false);
              }}
            />
          }
        </Grid>
        <Grid item xs={12} className={classes.zipWrap}>
          <TextField
            fullWidth
            id="zipCode"
            label="Zip"
            name="zipCode"
            value={values.address?.zipCode ?? ''}
            variant="outlined"
            error={touched.address?.zipCode && errors.address?.zipCode}
            helperText={touched.address?.zipCode && errors.address?.zipCode}
            inputProps={{
              size: 5
            }}
            onChange={event => {
              const value = event.target.value ?? '';
              if (value && value.length < 6 && !/^\d+$/.test(value)) return;
              if (value.length === 6 && value[5] !== '-') return;
              if (value.length > 6 && !/^\d+$/.test(value.substring(6))) return;
              if (value.length > 10) return;
              setFieldValue('address.zipCode', value);
              setIsValid(false);
              setForceSave(false);
            }}
          />
        </Grid>
        <Grid item xs={12} className={classes.verifyWrap}>
          <Button
            id="verifyBtn"
            variant="contained"
            disabled={isValid || verifying}
            onClick={onVerify}
            className={classes.saveBtn}
          >
            Verify
          </Button>
          {verifying && <CircularProgress size={24} />}
        </Grid>
      </Grid>
      <AddressSuggestedModal
        open={openSuggestedModal}
        onClose={() => setOpenSuggestedModal(false)}
        states={values.states}
        addresses={addressSuggested}
        handleSelect={(address) => onSelectAddressSuggested(address, values, setFieldValue)}
      />
      <ModalForceSave
        open={openModalForceSave}
        address={values.address}
        forceSave={() => onForceSave(values, setFieldValue)}
        cancelSave={() => onCancelForceSave(values, setFieldValue)}
      />

      <div className={classes.snackBar}>
        <Snackbar
          anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
          open={openSnackbar}
          autoHideDuration={3000}
          onClose={() => setOpenSnackBar(false)}
        >
          <Alert
            elevation={6}
            variant="filled"
            severity={isStatus.failed ? 'error' : 'success'}
          >
            <Typography color="inherit" variant="h6">
              {isStatus.msg}
            </Typography>
          </Alert>
        </Snackbar>
      </div>
    </>
  );
});

export default AddressInput;
