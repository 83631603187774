import React from 'react'
import { Typography } from '@material-ui/core'
import { Page } from "components";


const TermsOfService = () => {
    return (
        
            <Page title={'Terms Of Service'}>
                <div>
                <Typography>
                TBD - Please refer to franchise agreement for Terms of Service
                </Typography>

                </div>
            </Page>
    )
}

export default TermsOfService;

