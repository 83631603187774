import { Button, Card, CardContent, CircularProgress, ClickAwayListener, colors, IconButton, Snackbar, Tab, Tabs, Tooltip, Typography } from '@material-ui/core';
import { AddBox, MenuRounded, NavigateBeforeRounded, NavigateNextRounded } from '@material-ui/icons';
import { Alert, ToggleButton, ToggleButtonGroup } from '@material-ui/lab';
import { makeStyles } from '@material-ui/styles';
import apiConfig from 'apiConfig';
import clsx from 'clsx';
import { ConfirmModal, KeyboardDatePicker, Page } from 'components';
import { route } from 'mockup/Routes';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useParams } from 'react-router-dom';
import axios from 'utils/axios';
import CloneModal from 'views/Routes2/components/Modals/CloneModal';
import ConfirmRecurJobModal from 'views/Routes2/components/Modals/ConfirmRecurJobModal';
import RecurModal from 'views/Routes2/components/Modals/RecurModal';
import RouteNoteModal from 'views/Routes2/components/Modals/RouteNoteModal';
import RouteSummary from 'views/Routes2/components/RouteSummary';
import { RouteContent } from './components';

const useStyles = makeStyles(theme => ({
  root: {
    padding: theme.spacing(1),
    paddingTop: theme.spacing(0.5),
    height: '100%',
    display: 'flex',
    flexDirection: 'column'
  },
  card: {
    marginTop: theme.spacing(0.5),
    flex: '1',
    display: 'flex',
    flexDirection: 'column'
  },
  cardContentHeader: {
    padding: `${theme.spacing(1)}px`
  },
  cardContentRoute: {
    padding: '0 !important',
    flex: '1',
    position: 'relative'
  },
  routeHeader: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    position: 'relative'
  },
  routeDate: {
    display: 'flex',
    alignItems: 'center',
    marginLeft: theme.spacing(0.5),
    marginRight: theme.spacing(0.5)
  },
  datepicker: {
    marginLeft: theme.spacing(0.5),
    marginRight: theme.spacing(0.5)
  },
  routeIcons: {
    display: 'flex',
    marginRight: 6
  },
  recurIcon: {
    width: '0.875em',
    height: '1em',
    color: '#263238',
    marginLeft: 6,
    '& .fa-calendar': {
      width: '0.875em'
    },
    '& .fa-redo-alt': {
      fontSize: '0.4em',
      bottom: '-0.8em'
    }
  },
  routeActions: {
    position: 'absolute',
    top: '100%',
    left: 0,
    display: 'grid',
    gridTemplateColumns: 'auto auto',
    padding: theme.spacing(0.5),
    border: '1px solid rgba(82, 63, 105, 0.06)',
    borderRadius: '0.5rem',
    boxShadow: '0 10px 15px -3px rgb(0 0 0 / 10%), 0 4px 6px -4px rgb(0 0 0 / 10%)',
    backgroundColor: theme.palette.white,
    zIndex: 99,
    '&.hidden': {
      display: 'none'
    }
  },
  viewType: {
    gridColumn: '1 / span 2',
    margin: 5,
    width: 200,
    '& button': {
      flex: 1
    }
  },
  actionButton: {
    width: 95,
    whiteSpace: 'nowrap',
    margin: 5,
    color: theme.palette.white,
    backgroundColor: colors.green[600],
    '&:hover': {
      backgroundColor: colors.green[900]
    },
    padding: '3px 8px',
    fontSize: 12
  },
  routes: {
    display: 'flex',
    justifyContent: 'space-between'
  },
  tabs: {
    minHeight: 'unset'
  },
  addButton: {
    marginLeft: theme.spacing(1)
  },
  updateBox: {
    position: 'absolute',
    width: '100%',
    height: '100%',
    top: 0,
    left: 0,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: '#0000001f'
  },
  iconButton: {
    padding: 0,
    color: colors.green[500],
    '&:hover': {
      color: colors.green[900],
      backgroundColor: 'transparent'
    },
    '& span svg': {
      fontSize: 38
    }
  }
}));

const RoutesMobile = ({ history }) => {

  const classes = useStyles();
  const { date } = useParams();
  const dispatch = useDispatch();

  const { routes, isUpdate } = useSelector(state => state.RoutesReducer);

  const [loading, setLoading] = useState(false);
  const [showActionsMenu, setShowActionsMenu] = useState(false);
  const [viewType, setViewType] = useState('schedule');
  const [selectedRoute, setSelectedRoute] = useState();
  const [openModal, setOpenModal] = useState(false);
  const [openModalRecur, setOpenModalRecur] = useState(false);
  const [openModalRouteNote, setOpenModalRouteNote] = useState(false);
  const [openConfirmRecurJobModal, setOpenConfirmRecurJobModal] = useState({ open: false });
  const [openConfirmRemoveRouteModal, setOpenConfirmRemoveRouteModal] = useState({ open: false });
  const [openJobNotesModal, setOpenJobNotesModal] = useState({ open: false, content: '' });
  const [recurJobCallback, setRecurJobCallback] = useState();
  const [isStatus, setIsStatus] = useState({ failed: false, msg: '' });
  const [openSnackbar, setOpenSnackbar] = useState(false);

  const previousDate = () => {
    const currDate = moment(date);
    const prevDate = currDate.subtract(1, 'days');
    history.push(prevDate.format('YYYY-MM-DD'));
  };
  const nextDate = () => {
    const currDate = moment(date);
    const nextDate = currDate.add(1, 'days');
    history.push(nextDate.format('YYYY-MM-DD'));
  };

  const resetDistance = () => {
    dispatch({ type: 'CHANGE_ORDER', orderType: '' });
    dispatch({ type: 'SHOW_DISTANCE', showDistance: false });
  };

  const handleAction = (type) => {
    switch (type) {
      case 'commercial':
        updateCommercial();
        break;
      case 'completion':
        handleCompletion();
        break;
      case 'recur':
        setOpenModalRecur(true);
        break;
      case 'clone':
        setOpenModal(true);
        break;
      case 'full':
      case 'open':
        updateFull(type);
        break;
      case 'print':
        handleRedirectRoutePdf();
        break;
      case 'remove':
        onRemoveRoute();
        break;
      case 'note':
        setOpenModalRouteNote(true);
        break;
      default:
        break;
    }

    setShowActionsMenu(false);
  };

  const updateCommercial = () => {
    dispatch({ type: 'IS_UPDATE', status: true });
    const newRoute = { ...selectedRoute, isCommercial: !selectedRoute.isCommercial };
    axios.put(apiConfig.url.BASE_URL + apiConfig.url.ROUTE + `${date}/` + selectedRoute.id, newRoute)
      .then(res => {
        setSelectedRoute({ ...selectedRoute, isCommercial: newRoute.isCommercial });
        dispatch({ type: 'UPDATE_ROUTE', routeId: selectedRoute.id, route: newRoute });
        handleShowError({ failed: false, msg: 'Update route successfuly.' });
      })
      .catch(() => handleShowError({ failed: true, msg: 'Update route failed, please try again later.' }))
      .finally(() => dispatch({ type: 'IS_UPDATE', status: false }));
  };

  const handleCompletion = () => {
    const d = date.replaceAll('-', '/');
    history.push({
      pathname: '/job-completion',
      state: {
        date: d,
        routeId: selectedRoute.id
      }
    });
  };

  const updateFull = (type) => {
    if (!selectedRoute) return;
    const newRoute = { ...selectedRoute, routeFull: type === 'full' };
    dispatch({ type: 'IS_UPDATE', status: true });
    axios.put(apiConfig.url.BASE_URL + apiConfig.url.ROUTE + `${date}/` + selectedRoute.id, newRoute)
      .then(res => {
        setSelectedRoute({ ...selectedRoute, routeFull: res.data.routeFull });
        dispatch({ type: 'UPDATE_ROUTE', routeId: selectedRoute.id, route: newRoute });
        handleShowError({ failed: false, msg: 'Update route successfuly.' });
      })
      .catch(() => handleShowError({ failed: true, msg: 'Update route failed, please try again later.' }))
      .finally(() => dispatch({ type: 'IS_UPDATE', status: false }));
  };

  const onRecurRoute = route => {
    dispatch({ type: 'ADD_ROUTE', route: { ...route, index: routes.length } });
  };

  const onCloneRoute = route => {
    dispatch({ type: 'ADD_ROUTE', route: { ...route, index: routes.length } });
  };

  const onRemoveRoute = (forceRemove) => {
    if (!forceRemove && (
      selectedRoute.routeEmployees.length > 0 ||
      selectedRoute.routeJobs.length > 0 ||
      selectedRoute.routeEquipment.length > 0)) {
      setOpenConfirmRemoveRouteModal({ open: true });
      return;
    }

    setOpenConfirmRemoveRouteModal({ open: false });
    dispatch({ type: 'IS_UPDATE', status: true });
    axios.delete(apiConfig.url.BASE_URL + apiConfig.url.ROUTE + selectedRoute.id)
      .then(() => {
        setIsStatus({ failed: false, msg: 'Delete successfully!' });
        setOpenSnackbar(true);
        setSelectedRoute();
        dispatch({ type: 'REMOVE_ROUTE', routeId: selectedRoute.id });
        if (selectedRoute.routeJobs.length > 0) {
          selectedRoute.routeJobs.forEach(rj => {
            const routeRemove = { ...rj, ...(rj.job ?? {}) };
            dispatch({ type: 'ADD_JOB', routeRemove });
          });
        }
      })
      .catch(() => {
        setIsStatus({ failed: true, msg: 'Delete failed, please try again later.' });
        setOpenSnackbar(true);
      })
      .finally(() => dispatch({ type: 'IS_UPDATE', status: false }));
  };

  const handleRedirectRoutePdf = () => {
    window.open('/route/pdf/' + selectedRoute.id, '_blank');
  };

  const printExpanded = () => {
    window.open('/expanded-routes/pdf/' + date, '_blank');
  };

  const printAllRoutes = () => {
    window.open('/all-routes/pdf/' + date, '_blank');
  };

  const handleRecurJob = (callback, recurrable = true, isDelete = false) => {
    setRecurJobCallback(() => callback);
    setOpenConfirmRecurJobModal({ open: true, recurrable, removing: isDelete });
  };

  const handleAddRoute = () => {
    dispatch({ type: 'IS_UPDATE', status: true });
    axios.post(apiConfig.url.BASE_URL + apiConfig.url.ROUTE + date, route)
      .then(res => {
        dispatch({
          type: 'ADD_ROUTE',
          route: { ...res.data, index: routes.length }
        });
      })
      .catch(() => {
        handleShowError({
          failed: true,
          msg: 'Add failed, please try again later.'
        });
      })
      .finally(() => dispatch({ type: 'IS_UPDATE', status: false }));
  };

  const handleChangeRoute = (event, routeOrder) => {
    setSelectedRoute(routes.find(route => route.routeOrder === routeOrder));
  };

  const handleShowError = error => {
    setIsStatus(error);
    setOpenSnackbar(true);
  };

  useEffect(() => {
    if (selectedRoute) {
      const routeIndex = routes?.findIndex(route => route.id === selectedRoute?.id);
      setSelectedRoute(routes?.[routeIndex]);
      return;
    }
    if (routes?.length) setSelectedRoute(routes[0]);
    else setSelectedRoute(undefined);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [routes]);

  useEffect(() => {
    setSelectedRoute(undefined);
  }, [date]);

  useEffect(() => {
    setLoading(true);
    axios.get(apiConfig.url.BASE_URL + apiConfig.url.ROUTE + date + '/list')
      .then(res => {
        res.data = res.data.filter(route =>
          moment(route.routeDate).format('DD/MM/YYYY') ===
          moment(date).format('DD/MM/YYYY'));

        res.data
          .sort((route1, route2) => route1.routeOrder - route2.routeOrder)
          .forEach(route => {
            route.routeJobs = route.routeJobs.filter(rj =>
              moment.utc(rj.jobStart) >= moment(date).startOf('dates') &&
              moment.utc(rj.jobStart) <= moment(date).endOf('dates'));

            route.routeJobs.forEach((rj, index) => {
              rj.price = rj.job?.price ?? 0;
              const duration = moment.duration(
                moment
                  .utc(rj.jobEnd)
                  .local()
                  .diff(moment.utc(rj.jobStart).local())
              );
              rj.estimatedHours = duration.asMinutes() / 60;
              rj.description = rj.job?.description ?? '';
              rj.city = rj.job?.city ?? '';
              rj.jobStart = moment.utc(rj.jobStart).local().toISOString();
              rj.jobEnd = moment.utc(rj.jobEnd).local().toISOString();
            });
          });

        dispatch({ type: 'INIT_ROUTES', routes: res.data });
      })
      .finally(() => setLoading(false));
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [date]);
  
  return (
    <Page className={classes.root} title="Management Schedules">
      <Link to={`/schedules/${date}`} onClick={resetDistance}>
        <Typography style={{ display: 'inline' }}>Back to Calendar</Typography>
      </Link>
      <Card className={classes.card}>
        <CardContent className={classes.cardContentHeader}>
          <div className={classes.routeHeader}>
            <ClickAwayListener onClickAway={() => setShowActionsMenu(false)}>
              <div>
                <IconButton className={classes.iconButton} onClick={() => setShowActionsMenu(!showActionsMenu)}>
                  <MenuRounded />
                </IconButton>
                <div className={clsx(classes.routeActions, showActionsMenu ? '' : 'hidden')}>
                  <ToggleButtonGroup
                    className={classes.viewType}
                    color="primary"
                    size="small"
                    value={viewType}
                    exclusive
                    onChange={(_, value) => value && setViewType(value)}
                    aria-label="View Type"
                  >
                    <ToggleButton value="schedule">Schedule</ToggleButton>
                    <ToggleButton value="summary">Summary</ToggleButton>
                  </ToggleButtonGroup>
                  {
                    viewType === 'schedule' && (
                      <>
                        <Button
                          variant="contained"
                          className={classes.actionButton}
                          disabled={!selectedRoute}
                          onClick={() => handleAction('commercial')}>
                          Commercial
                        </Button>
                        <Button
                          variant="contained"
                          className={classes.actionButton}
                          disabled={!selectedRoute}
                          onClick={() => handleAction('completion')}>
                          Completion
                        </Button>
                        <Button
                          variant="contained"
                          className={classes.actionButton}
                          disabled={!selectedRoute}
                          onClick={() => handleAction('recur')}>
                          Recur
                        </Button>
                        <Button
                          variant="contained"
                          className={classes.actionButton}
                          disabled={!selectedRoute}
                          onClick={() => handleAction('clone')}>
                          Clone
                        </Button>
                        <Button
                          variant="contained"
                          className={classes.actionButton}
                          disabled={!selectedRoute}
                          onClick={() => handleAction(selectedRoute.routeFull ? 'open' : 'full')}>
                          {selectedRoute && selectedRoute.routeFull ? 'Open Route' : 'Mark Full'}
                        </Button>
                        <Button
                          variant="contained"
                          className={classes.actionButton}
                          disabled={!selectedRoute}
                          onClick={() => handleAction('print')}>
                          Print
                        </Button>
                        <Button
                          variant="contained"
                          className={classes.actionButton}
                          disabled={!selectedRoute || !!selectedRoute.recurringRouteParentId}
                          onClick={() => handleAction('remove')}>
                          Remove
                        </Button>
                        <Button
                          variant="contained"
                          className={classes.actionButton}
                          disabled={!selectedRoute}
                          onClick={() => handleAction('note')}>
                          Route Note
                        </Button>
                      </>
                    )
                  }
                </div>
              </div>
            </ClickAwayListener>
            <div className={classes.routeDate}>
              <IconButton size="small" onClick={previousDate}>
                <NavigateBeforeRounded />
              </IconButton>
              <div className={classes.datepicker}>
                <KeyboardDatePicker
                  size="small"
                  value={date}
                  onChange={day => day && history.push(moment(day).format('YYYY-MM-DD'))}
                />
              </div>
              <IconButton size="small" onClick={nextDate}>
                <NavigateNextRounded />
              </IconButton>
            </div>
            <div className={classes.routeIcons}>
              {
                viewType === 'schedule' && selectedRoute && (
                  <>
                    {
                      selectedRoute.routeFull && (
                        <Tooltip title="Route Full" arrow>
                          <span>
                            <i className="fal fa-calendar-alt fa-2x" />
                          </span>
                        </Tooltip>
                      )
                    }
                    {
                      !selectedRoute.routeFull && (
                        <Tooltip title="Route Not Full" arrow>
                          <span>
                            <i className="fal fa-calendar fa-2x" />
                          </span>
                        </Tooltip>
                      )
                    }
                    {
                      selectedRoute.recurringRouteParentId && (
                        <Tooltip title="Recurring Route" arrow>
                          <span className={clsx(classes.recurIcon, 'fa-stack', 'fa-2x')}>
                            <i className="fal fa-calendar fa-stack-1x" />
                            <i className="fal fa-redo-alt fa-stack-1x" />
                          </span>
                        </Tooltip>
                      )
                    }
                  </>
                )
              }
            </div>
          </div>
          {
            viewType === 'schedule' && (
              <div className={classes.routes}>
                <Tabs
                  className={classes.tabs}
                  scrollButtons="auto"
                  variant="scrollable"
                  value={selectedRoute?.routeOrder ?? 1}
                  onChange={handleChangeRoute}>
                  {!loading && routes.map(route => (
                    <Tab
                      key={route.routeOrder}
                      label={`Route ${route.routeOrder}`}
                      value={route.routeOrder}
                      className={classes.tabs}
                    />
                  ))}
                </Tabs>
                <Tooltip title="Add Route" arrow>
                  <IconButton
                    className={clsx(classes.iconButton, classes.addButton)}
                    onClick={handleAddRoute}
                    disabled={isUpdate}>
                    <AddBox />
                  </IconButton>
                </Tooltip>
              </div>
            )
          }
        </CardContent>
        {
          viewType === 'summary' && <RouteSummary />
        }
        {
          viewType === 'schedule' && (
            <CardContent className={classes.cardContentRoute}>
              <RouteContent route={selectedRoute} loading={loading} onError={handleShowError} />
              {
                isUpdate && (
                  <div className={classes.updateBox}>
                    <CircularProgress size={32} />
                  </div>
                )
              }
            </CardContent>
          )
        }
      </Card>

      <RecurModal
        router={selectedRoute}
        open={openModalRecur}
        onClose={() => setOpenModalRecur(false)}
        addCloneRoute={onRecurRoute}
      />

      <CloneModal
        router={selectedRoute}
        open={openModal}
        onClose={() => setOpenModal(false)}
        addCloneRoute={onCloneRoute}
      />

      <RouteNoteModal
        open={openModalRouteNote}
        onClose={() => setOpenModalRouteNote(false)}
        router={selectedRoute}
      />

      <ConfirmRecurJobModal
        open={openConfirmRecurJobModal.open}
        recurrable={openConfirmRecurJobModal.recurrable}
        removing={openConfirmRecurJobModal.removing}
        onConfirm={recur => {
          if (recurJobCallback && typeof recurJobCallback === 'function') recurJobCallback(recur);
          setOpenConfirmRecurJobModal({ open: false });
        }}
        closeConfirm={() => setOpenConfirmRecurJobModal({ open: false })}
      />

      <ConfirmModal
        title={openJobNotesModal.content}
        isOk={false}
        openConfirm={openJobNotesModal.open}
        closeConfirm={() => setOpenJobNotesModal({ open: false, content: '' })}
        cancelButtonText='Close'
      />

      <ConfirmModal
        title="There are jobs on the selected route. These jobs will be marked To Be Scheduled."
        openConfirm={openConfirmRemoveRouteModal.open}
        onConfirm={() => onRemoveRoute(true)}
        closeConfirm={() => setOpenConfirmRemoveRouteModal({ open: false })}
        isProgress={isUpdate}
      />

      <Snackbar
        open={openSnackbar}
        onClose={() => setOpenSnackbar(false)}
        autoHideDuration={3000}
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
      >
        <Alert
          elevation={6}
          variant="filled"
          security={isStatus.failed ? 'error' : 'success'}
        >
          <Typography color="inherit" variant="h6">
            {isStatus.msg}
          </Typography>
        </Alert>
      </Snackbar>
    </Page>
  );
};

export default RoutesMobile;
