const initState = {
    viewType: 'month',
    index: 0,
    schedules: [],
    distances: []
}

const SchedulesReducer = (state = initState, action) => {
    switch (action.type) {
        case 'INIT_SCHEDULES':
            return {
                ...state,
                schedules: action.schedules
            }
        case 'CHANGE_VIEW_TYPE':
            return {
                ...state,
                viewType: action.viewType
            }
        case 'CHANGE_WEEK':
            return {
                ...state,
                index: action.index
            }
        case 'GET_DISTANCE':
            return {
                ...state,
                distances: action.distances
            }
        default:
            return state
    }
}

export default SchedulesReducer;