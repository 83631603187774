import React, { useState, useEffect } from 'react'
import { makeStyles } from '@material-ui/styles'
import { Alert } from '@material-ui/lab'
import {
    Modal, Card, CardHeader, CardContent, CardActions,
    Grid, Typography, TextField, FormControlLabel,
    Button, Divider, colors,
    Snackbar, CircularProgress
} from '@material-ui/core'
import useMediaQuery from '@material-ui/core/useMediaQuery'
import { GreenCheckbox, KeyboardDatePicker } from 'components'
import { useDispatch } from 'react-redux'
import axios from 'utils/axios'
import apiConfig from 'apiConfig'
import ForceSchedule from "./ForceSchedule";

const useStyles = makeStyles(theme => ({
    root: {},
    styleModal: {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        outline: 'none',
        boxShadow: theme.shadows[20],
        width: 700,
        maxHeight: '100%',
        overflowY: 'auto',
        maxWidth: '100%',
        [theme.breakpoints.down('sm')]: {
            width: 500
        },
    },
    styleCheckbox: {
        display: 'flex',
        alignItems: 'center',
        [theme.breakpoints.down('sm')]: {
            '& label span:last-child': {
                display: 'none'
            }
        },
    },
    conButton: {
        padding: '16px',
        justifyContent: 'flex-end'
    },
    button: {
        flexShrink: '0',
        backgroundColor: colors.green[600],
        '&:hover': {
            backgroundColor: colors.green[900]
        },
    },
    containerDate: {
        display: 'flex',
        marginBottom: 16
    }
}));

const AddAvailability = props => {
    const classes = useStyles();
    const down_sm = useMediaQuery('(max-width: 960px)');

    const { userId, open, onClose, schedule, lstSchedule } = props;

    const dispatch = useDispatch();
    const [isProgress, setIsProgress] = useState(false);
    const [openForceModel, setOpenForceModal] = useState(false);

    const initForm = {
        startDate: null,
        endDate: null,
        sunday: {
            isChecked: false,
            comment: ''
        },
        monday: {
            isChecked: false,
            comment: ''
        },
        tuesday: {
            isChecked: false,
            comment: ''
        },
        wednesday: {
            isChecked: false,
            comment: ''
        },
        thursday: {
            isChecked: false,
            comment: ''
        },
        friday: {
            isChecked: false,
            comment: ''
        },
        saturday: {
            isChecked: false,
            comment: ''
        },
    }

    const [openSnack, setOpenSnack] = useState(false);
    const [status, setStatus] = useState({ isFailed: false, msg: '' })
    const [formState, setFormState] = useState(initForm);

    const handleFieldChange = event => {
        const { name, value, type } = event.target;
        if (type === 'checkbox') {
            event.target.checked ?
                setFormState({ ...formState, [name]: { isChecked: true } })
                :
                setFormState({ ...formState, [name]: { isChecked: false } })
        } else {
            setFormState({
                ...formState,
                [name]: { isChecked: true, comment: value }
            })
        }
    }

    const onChangeDate = (date, typeDate) => {
        setFormState({ ...formState, [typeDate]: date })
    }
    const minDate = enddate => {
        const date = new Date(enddate);
        // date.setDate(date.getDate() + 1);
        date.setDate(date.getDate());
        return date;
    }
    const maxDate = startdate => {
        const date = new Date(startdate);
        date.setDate(date.getDate());
        // date.setDate(date.getDate() - 1);
        return date;
    }

    const resetModal = () => {
        setFormState(initForm)
    }

    const handleClose = () => {
        setOpenSnack(false);
    }

    const hasDayInWeek = () => {
        const days = ['sunday', 'monday', 'tuesday', 'wednesday', 'thursday', 'friday', 'saturday'];
        let res = false;

        days.forEach(day => {
            if (!formState[day] || formState[day].isChecked !== false) {
                res = true;
                return;
            }
        });

        return res;
    }

    const onCloseModal = () => {
        resetModal();
        onClose();
    }

    const mapPostData = (forceSave = false) => {
        // debugger;

        let postData = {
            forceSave : forceSave,
            id: formState.id,
            userId: userId,
            startDate: formState.startDate,
            endDate: formState.endDate,
            sunday: {
                isChecked: formState.sunday.isChecked,
                comment: formState.sunday.comment
            },
            monday: {
                isChecked: formState.monday.isChecked,
                comment: formState.monday.comment
            },
            tuesday: {
                isChecked: formState.tuesday.isChecked,
                comment: formState.tuesday.comment
            },
            wednesday: {
                isChecked: formState.wednesday.isChecked,
                comment: formState.wednesday.comment
            },
            thursday: {
                isChecked: formState.thursday.isChecked,
                comment: formState.thursday.comment
            },
            friday: {
                isChecked: formState.friday.isChecked,
                comment: formState.friday.comment
            },
            saturday: {
                isChecked: formState.saturday.isChecked,
                comment: formState.saturday.comment
            }
        };
        return postData;
    }

    const onSave = () => {
        if (!formState.startDate || !hasDayInWeek()) {
            setStatus({ isFailed: true, msg: 'Start date is required. At least 1 day of the week is selected' })
            setOpenSnack(true);
            return;
        }


        for (let i = 0; i < lstSchedule.length; i++) {
            if (formState.id && formState.id == lstSchedule[i].id) continue;
            if (formState.startDate && formState.endDate) {
                if (new Date(formState.startDate) <= new Date(lstSchedule[i].startDate) && new Date(formState.endDate) >= new Date(lstSchedule[i].startDate)
                    || (new Date(formState.startDate) >= new Date(lstSchedule[i].startDate) && new Date(formState.startDate) <= new Date(lstSchedule[i].endDate))
                    || (new Date(formState.endDate) >= new Date(lstSchedule[i].startDate) && new Date(formState.endDate) <= new Date(lstSchedule[i].endDate))
                ) {
                    setStatus({
                        isFailed: true,
                        msg: 'Schedule already exists, please choose another start or end date.'
                    })
                    setOpenSnack(true);
                    return;
                }
            }
            if (formState.startDate && !formState.endDate) {
                if (new Date(formState.startDate) <= new Date(lstSchedule[i].endDate)) {
                    setStatus({
                        isFailed: true,
                        msg: 'Schedule already exists, please choose another start or end date.'
                    })
                    setOpenSnack(true);
                    return;
                }
            }
        }

        if (schedule !== null) {
            // debugger;
            setIsProgress(true);
            axios.post(apiConfig.url.USER_URL + apiConfig.url.EMPLOYEE_AVAILABILITY_UPDATE, mapPostData())
                .then(res => {
                    if (res.data.isScheduled) {
                        setOpenForceModal(true);
                    }else {
                        dispatch({type: 'UPDATE_SCHEDULE', schedule: formState, id: schedule.schedule.id});
                        setStatus({isFailed: false, msg: 'Update successfuly!'});
                        setOpenSnack(true);
                        resetModal();
                        onClose();
                    }
                })
                .catch(err => {
                    setStatus({ isFailed: true, msg: 'Update failed, please try again later.' })
                    setOpenSnack(true);
                })
                .finally(() => setIsProgress(false))
        } else {
            setIsProgress(true);
            axios.put(apiConfig.url.USER_URL + apiConfig.url.EMPLOYEE_AVAILABILITY_PUT, mapPostData())
                .then(res => {
                        formState.id = res.data.id;
                        dispatch({ type: 'ADD_SCHEDULE', schedule: formState });
                        setStatus({ isFailed: false, msg: 'Create availability successfuly!' })
                        setOpenSnack(true);
                        resetModal();
                        onClose();
                })
                .catch(() => {
                    setStatus({ isFailed: true, msg: 'Create availability failed, please try again later.' })
                    setOpenSnack(true);
                })
                .finally(() => setIsProgress(false))
        }
    }

    const forceAddSchedule = () => {
        setOpenForceModal(false);
        setIsProgress(true);
        axios.post(apiConfig.url.USER_URL + apiConfig.url.EMPLOYEE_AVAILABILITY_UPDATE, mapPostData(true))
            .then(res => {
                dispatch({type: 'UPDATE_SCHEDULE', schedule: formState, id: schedule.schedule.id});
                setStatus({isFailed: false, msg: 'Update successfuly!'});
                setOpenSnack(true);
                resetModal();
                onClose();
            })
            .catch(() => {
                setStatus({ isFailed: true, msg: 'Update failed, please try again later.' })
                setOpenSnack(true);
            })
            .finally(() => setIsProgress(false))
    }

    useEffect(() => {
        if (schedule) {
            setFormState(schedule.schedule);
        } else {
            resetModal();
        }
    }, [schedule])

    return (
        <>
            <Modal open={open} onClose={onCloseModal}>
                <Card className={classes.styleModal}>
                    <CardHeader title="Add Availability" />
                    <Divider />
                    <CardContent>
                        <Grid container spacing={2} item className={classes.containerDate}>
                            <Grid item xs={12} sm>
                                <KeyboardDatePicker
                                    label='Start Date'
                                    value={formState.startDate || ''}
                                    maxDate={formState.endDate && maxDate(formState.endDate)}
                                    onChange={date => onChangeDate(date, 'startDate')}
                                />
                            </Grid>
                            <Grid item xs={12} sm>
                                <KeyboardDatePicker
                                    label='End Date'
                                    value={formState.endDate || ''}
                                    minDate={formState.startDate && minDate(formState.startDate)}
                                    onChange={date => onChangeDate(date, 'endDate')}
                                />
                            </Grid>
                        </Grid>
                        <Grid container spacing={1}>
                            {/* sunday */}
                            <Grid container spacing={2} item>
                                <Grid item xs={down_sm ? 1 : 2} className={classes.styleCheckbox}>
                                    <FormControlLabel
                                        name='sunday'
                                        control={
                                            <GreenCheckbox
                                                checked={formState.sunday ? formState.sunday.isChecked : false}
                                                onChange={handleFieldChange}
                                                value="sunday"
                                            />
                                        }
                                        label='Sunday'
                                    />
                                </Grid>
                                <Grid item xs>
                                    <TextField
                                        fullWidth
                                        disabled={formState.sunday ? (formState.sunday.isChecked === false ? true : false) : true}
                                        placeholder="Please type the reason"
                                        name="sunday"
                                        onChange={handleFieldChange}
                                        value={formState.sunday ? (formState.sunday.comment || '') : ''}
                                        variant="outlined"
                                    />
                                </Grid>
                            </Grid>
                            {/* monday */}
                            <Grid container spacing={2} item>
                                <Grid item xs={down_sm ? 1 : 2} className={classes.styleCheckbox}>
                                    <FormControlLabel
                                        name='monday'
                                        control={
                                            <GreenCheckbox
                                                checked={formState.monday ? formState.monday.isChecked : false}
                                                onChange={handleFieldChange}
                                                value="monday"
                                            />
                                        }
                                        label="Monday"
                                    />
                                </Grid>
                                <Grid item xs>
                                    <TextField
                                        fullWidth
                                        disabled={formState.monday ? (formState.monday.isChecked === false ? true : false) : true}
                                        placeholder="Please type the reason"
                                        name="monday"
                                        onChange={handleFieldChange}
                                        value={formState.monday ? (formState.monday.comment || '') : ''}
                                        variant="outlined"
                                    />
                                </Grid>
                            </Grid>
                            {/* tuesday */}
                            <Grid container spacing={2} item>
                                <Grid item xs={down_sm ? 1 : 2} className={classes.styleCheckbox}>
                                    <FormControlLabel
                                        name='tuesday'
                                        control={
                                            <GreenCheckbox
                                                checked={formState.tuesday ? (formState.tuesday.isChecked) : false}
                                                onChange={handleFieldChange}
                                                value="tuesday"
                                            />
                                        }
                                        label="Tuesday"
                                    />
                                </Grid>
                                <Grid item xs>
                                    <TextField
                                        fullWidth
                                        disabled={formState.tuesday ? (formState.tuesday.isChecked === false ? true : false) : true}
                                        placeholder="Please type the reason"
                                        name="tuesday"
                                        onChange={handleFieldChange}
                                        value={formState.tuesday ? (formState.tuesday.comment || '') : ''}
                                        variant="outlined"
                                    />
                                </Grid>
                            </Grid>
                            {/* wednesday */}
                            <Grid container spacing={2} item>
                                <Grid item xs={down_sm ? 1 : 2} className={classes.styleCheckbox}>
                                    <FormControlLabel
                                        name='wednesday'
                                        control={
                                            <GreenCheckbox
                                                checked={formState.wednesday ? formState.wednesday.isChecked : false}
                                                onChange={handleFieldChange}
                                                value="wednesday"
                                            />
                                        }
                                        label="Wednesday"
                                    />
                                </Grid>
                                <Grid item xs>
                                    <TextField
                                        fullWidth
                                        disabled={formState.wednesday ? (formState.wednesday.isChecked === false ? true : false) : true}
                                        placeholder="Please type the reason"
                                        name="wednesday"
                                        onChange={handleFieldChange}
                                        value={formState.wednesday ? (formState.wednesday.comment || '') : ''}
                                        variant="outlined"
                                    />
                                </Grid>
                            </Grid>
                            {/* thursday */}
                            <Grid container spacing={2} item>
                                <Grid item xs={down_sm ? 1 : 2} className={classes.styleCheckbox}>
                                    <FormControlLabel
                                        name='thursday'
                                        control={
                                            <GreenCheckbox
                                                checked={formState.thursday ? formState.thursday.isChecked : false}
                                                onChange={handleFieldChange}
                                                value="thursday"
                                            />
                                        }
                                        label="Thursday"
                                    />
                                </Grid>
                                <Grid item xs>
                                    <TextField
                                        fullWidth
                                        disabled={formState.thursday ? (formState.thursday.isChecked === false ? true : false) : true}
                                        placeholder="Please type the reason"
                                        name="thursday"
                                        onChange={handleFieldChange}
                                        value={formState.thursday ? (formState.thursday.comment || '') : ''}
                                        variant="outlined"
                                    />
                                </Grid>
                            </Grid>
                            {/* friday */}
                            <Grid container spacing={2} item>
                                <Grid item xs={down_sm ? 1 : 2} className={classes.styleCheckbox}>
                                    <FormControlLabel
                                        name='friday'
                                        control={
                                            <GreenCheckbox
                                                checked={formState.friday ? formState.friday.isChecked : false}
                                                onChange={handleFieldChange}
                                                value="friday"
                                            />
                                        }
                                        label="Friday"
                                    />
                                </Grid>
                                <Grid item xs>
                                    <TextField
                                        fullWidth
                                        disabled={formState.friday ? (formState.friday.isChecked === false ? true : false) : true}
                                        placeholder="Please type the reason"
                                        name="friday"
                                        onChange={handleFieldChange}
                                        value={formState.friday ? (formState.friday.comment || '') : ''}
                                        variant="outlined"
                                    />
                                </Grid>
                            </Grid>
                            {/* saturday */}
                            <Grid container spacing={2} item>
                                <Grid item xs={down_sm ? 1 : 2} className={classes.styleCheckbox}>
                                    <FormControlLabel
                                        name='saturday'
                                        control={
                                            <GreenCheckbox
                                                checked={formState.saturday ? formState.saturday.isChecked : false}
                                                onChange={handleFieldChange}
                                                value="saturday"
                                            />
                                        }
                                        label="Saturday"
                                    />
                                </Grid>
                                <Grid item xs>
                                    <TextField
                                        fullWidth
                                        disabled={formState.saturday ? (formState.saturday.isChecked === false ? true : false) : true}
                                        placeholder="Please type the reason"
                                        name="saturday"
                                        onChange={handleFieldChange}
                                        value={formState.saturday ? (formState.saturday.comment || '') : ''}
                                        variant="outlined"
                                    />
                                </Grid>
                            </Grid>
                        </Grid>
                    </CardContent>
                    <Divider />
                    <CardActions className={classes.conButton}>
                        <Button
                            variant="contained"
                            size="large"
                            onClick={onCloseModal}>
                            Close
                        </Button>
                        <div>
                            <CardActions>
                            <Button
                                variant="contained"
                                color="primary"
                                size="large"
                                disabled={isProgress}
                                onClick={onSave}
                                className={classes.button}>
                                Save
                            </Button>
                            {isProgress && <CircularProgress size={30} />}
                            </CardActions>
                        </div>
                    </CardActions>
                </Card>
            </Modal>
            <ForceSchedule
                open={openForceModel}
                onClose={() => setOpenForceModal(false)}
                forceAddSchedule={forceAddSchedule}
            >
            </ForceSchedule>
            <Snackbar
                open={openSnack}
                anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
                autoHideDuration={2000}
                onClose={handleClose}>
                <Alert
                    elevation={6} variant="filled" severity={status.isFailed ? 'error' : 'success'}>
                    <Typography
                        color="inherit"
                        variant="h6">
                        {status.msg}
                    </Typography>
                </Alert>
            </Snackbar>
        </>
    )
}

AddAvailability.defaultProps = {
    open: false,
    onClose: () => { }
};

export default AddAvailability;
