import { CardContent, CircularProgress } from '@material-ui/core';
import { PDFViewer } from '@react-pdf/renderer';
import apiConfig from 'apiConfig';
import React, { useEffect, useState } from 'react';
import axios from 'utils/axios';
import localStorage from 'utils/localStorage';
import PricingPdf from 'views/Admin/components/Pricing/PricingPdf';

const PriceLineItems = () => {
  const [dataPdfPriceLineItems, setDataPdfPriceLineItems] = useState([]);
  const [loadingData, setLoadingData] = useState(false);

  const fullInfo = localStorage.getFullInfo();

  useEffect(() => {
    setLoadingData(true);
    Promise.all([
      axios.get(apiConfig.url.BASE_URL + apiConfig.url.COMPANY_PRICES_QUICKBOOK_LIST.replace('{companyId}', fullInfo.cid)),
      axios.get(apiConfig.url.BASE_URL + apiConfig.url.COMPANY_PRICES_ITEM.replace('{companyId}', fullInfo.cid), {
        params: {
          pageSize: 9999,
          pageNumber: 1,
        },
      }),
    ])
      .then(([quickbooksItemsResponse, priceItemsResponse]) => {
        priceItemsResponse.data.data.forEach((item) => {
          const qb = quickbooksItemsResponse.data.find((x) => x.id === item.qbItemId);
          item.quickbooksName = qb ? qb.description : '';
        });
        setDataPdfPriceLineItems(priceItemsResponse.data.data);
      })
      .catch((err) => {
        console.log('err', err);
      })
      .finally(() => setLoadingData(false));
  }, []);

  return !loadingData ? (
    <PDFViewer width={'100%'} height={'100%'}>
      <PricingPdf items={dataPdfPriceLineItems} />
    </PDFViewer>
  ) : (
    <CardContent>
      <CircularProgress size={24} />
    </CardContent>
  );
};

export default PriceLineItems;
