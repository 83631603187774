import {
    Button,
    Card,
    CardActions, CardContent,
    CardHeader, CircularProgress,
    colors,
    Divider,
    Modal, Table, TableBody, TableCell, TableContainer, TableFooter, TableHead, TableRow, Typography,
} from "@material-ui/core";
import React, {useEffect, useState} from "react";
import {makeStyles} from "@material-ui/styles";
import axios from "../../../utils/axios";
import apiConfig from "../../../apiConfig";
import JobCardHistory from "./JobCardHistory";

const useStyles = makeStyles(theme => ({
    styleModal: {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        outline: 'none',
        boxShadow: theme.shadows[20],
        width: "80%",
        maxHeight: '100%',
        overflowY: 'auto',
        maxWidth: '100%'
    },
    conButton: {
        padding: '16px',
        justifyContent: 'flex-end'
    },
    button: {
        flexShrink: '0',
        backgroundColor: colors.green[600],
        '&:hover': {
            backgroundColor: colors.green[900]
        },
    },
    actionBox: {
        width: "100%",
        height: "100%",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        position: "absolute",
        top: 0,
        left: 0,
        zIndex: 2,
        backgroundColor: "#0000001f"
    },
}))
let forcePage = 0;
let pageSize = 10;

const ModalShowHistory = props => {
    const {open, onClose, job} = props;
    const {customerId, customerLastName, customerFirstName} = job;
    const classes = useStyles();
    const [isProgress, setIsProgress] = useState(false);
    const [pageCount, setPageCount] = useState(0);
    const [jobs, setJobs] = useState([]);
    const [jobsSorted, setJobsSorted] = useState([]);
    const [pageNum, setPageNum] = useState(1);
    const fetchJob = (page, loadAll = false) => {
        setIsProgress(true);
        axios
            .get(
                apiConfig.url.BASE_URL + apiConfig.url.CUSTOMER_JOBS + job.addressId +
                `?pageNumber=${page}&pageSize=${pageSize}`
            )
            .then(res => {
                res.data.data.map(function(e){
                    e.address_id = job.addressId;
                });
                setJobs(loadAll ? res.data.data : page === 1 ? res.data.data : [...jobs, ...res.data.data]);
                setPageCount(res.data.pageCount);
            })
            .finally(() => {
                setIsProgress(false);
            });
    };
    const loadAll = () => {
        pageSize = pageCount * 10;
        fetchJob(1,true);
    }
    const loadMore = (page) => {
        setPageNum(page);
        fetchJob(page);
    }
    useEffect(() => {
        let _job =  jobs.map(job => {
            let date = '';
            if (job.bidStageId == '1') {
                if(job.dateCreated){
                    date = (job.dateCreated)
                }
            }
            if (job.bidStageId == '2') {
                if(job.bidCompleted){
                    date = (job.bidCompleted)
                }
            }
            if (job.bidStageId == '3') {
                date = ('');
            }
            if (job.bidStageId == '4') {
                if(job.scheduledDate){
                    date = (job.scheduledDate)
                }
            }
            if (job.bidStageId == '5') {
                if(job.completedDate){
                    date = (job.completedDate)
                }
            }
            if (job.bidStageId == '6') {
                if(job.scheduledDate){
                    date = (job.scheduledDate)
                }
            }
            return  {...job, date : date}
        })
        _job = _job.sort((a, b) => new Date(b.date || '01/01/1970') - new Date(a.date || '01/01/1970'));
        setJobsSorted(_job);

    },[jobs])
    useEffect(() => {
        if(open){
            fetchJob(1, false)
        }
    }, [open])

    return (
        <Modal open={open}>
            <Card className={classes.styleModal}>
                <CardHeader style={{padding: '28px'}}
                            title={`Job History`}/>
                <Divider/>
                <CardContent style={{minHeight: '60px' , maxHeight: '500px' , overflowY: 'auto' }}>
                    {jobs && jobs.length === 0 && !isProgress ? (
                         <Typography>No data found</Typography>
                    ) : (
                        <>
                            <TableContainer>
                                {isProgress && (
                                    <div className={classes.actionBox}>
                                        <CircularProgress size={32} />
                                    </div>
                                )}
                                {jobs && jobs.length > 0 &&
                                <Table>
                                    <TableHead>
                                        <TableRow>
                                            <TableCell className={classes.headCell}>Stage</TableCell>
                                            <TableCell className={classes.headCell}>
                                                Date
                                            </TableCell>
                                            <TableCell className={classes.headCell}>
                                                Purchase Order
                                            </TableCell>
                                            <TableCell className={classes.headCell}>
                                                Job Description
                                            </TableCell>
                                            <TableCell className={classes.headCell}>Total</TableCell>

                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {jobsSorted &&
                                        jobsSorted.map((job, index) => (
                                            <JobCardHistory
                                                key={job.id}
                                                job={job}
                                                customerId={customerId}
                                                customerLastName={customerLastName}
                                                customerFirstName={customerFirstName}
                                            />
                                        ))}
                                    </TableBody>
                                    <TableFooter>
                                        <TableRow>
                                            <TableCell colSpan={7} align={"center"}>
                                                <div style={{marginTop: "16px auto"}}>
                                                    {pageNum < pageCount
                                                    && <><Button
                                                        color="primary"
                                                        variant="outlined"
                                                        disabled={isProgress} onClick={() => loadMore(pageNum + 1)}>Load more</Button>

                                                        <Button
                                                            style={{marginLeft: '20px'}}
                                                            color="primary"
                                                            variant="outlined"
                                                            disabled={isProgress} onClick={() => loadAll()}>Load all</Button>
                                                    </>}
                                                </div>
                                            </TableCell>
                                        </TableRow>
                                    </TableFooter>
                                </Table>
                                }

                            </TableContainer>
                        </>
                    )}
                </CardContent>
                <CardActions className={classes.conButton}>
                    <Button
                        variant="contained"
                        size="medium"
                        onClick={() => {
                            onClose();
                            setJobs([]);
                        }}>
                        Close
                    </Button>
                </CardActions>
            </Card>
        </Modal>
    )
}
export default ModalShowHistory
