import axios from "../../../../../utils/axios";
import apiConfig from "../../../../../apiConfig";
import {
    Button,
    Card,
    CardActions,
    CardContent,
    CardHeader,
    CircularProgress, colors,
    Divider,
    Grid,
    Modal,
    TextField
} from "@material-ui/core";
import {KeyboardDatePicker} from "../../../../../components";
import React, {useState} from "react";
import {makeStyles} from "@material-ui/styles";

const useStyles = makeStyles(theme => ({
    styleModal: {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        outline: 'none',
        boxShadow: theme.shadows[20],
        width: "50%",
        maxHeight: '100%',
        overflowY: 'auto',
        maxWidth: '100%',
        [theme.breakpoints.down('sm')]: {
            width: "100%"
        },
    },
    conButton: {
        padding: '16px',
        justifyContent: 'space-between'
    },
    buttonSave: {
        flexShrink: '0',
        backgroundColor: colors.green[600],
        '&:hover': {
            backgroundColor: colors.green[900]
        },
    },
    timeCheckbox: {
        marginLeft: '16px',
        width: '100%'
    },
    time: {
        width: '100%',
        marginRight: '16px'
    }
}))


const ModalNotice = props => {
    const classes = useStyles();

    const {note, openModal, updateCloseModal} = props;
    const [show, setShow] = useState(openModal);
    const updateStatus = () => {
        // axios.post(apiConfig.url.BASE_URL + apiConfig.url.)
    }
    const markComplete = () => {
        setShow(!show);
        updateCloseModal();
    }
    const onCloseModal = () =>{
        setShow(!show);

    }
    return (
        <Modal open={show} style={{minWidth: '100%'}}>
            <Card className={classes.styleModal}>
                <CardHeader title='Customer Note'/>
                <Divider/>
                <CardContent>
                    <Grid container spacing={4}>
                        <Grid item xs={12}>
                            <TextField
                                InputProps={{
                                    readOnly: true,
                                }}
                                multiline
                                rows={10}
                                fullWidth
                                variant='outlined'
                                value={note || ''}
                            >
                            </TextField>
                        </Grid>
                    </Grid>
                </CardContent>
                <Divider/>
                <CardActions className={classes.conButton}>
                    <div style={{float: 'left'}}>
                        <Button
                            Alignment={'left'}
                            className={classes.buttonSave}
                            variant="contained"
                            color="primary"
                            size="large"
                            onClick={markComplete}>
                            Dont show again
                        </Button>
                    </div>
                    <div>
                        <Button
                            variant="contained"
                            size="large"
                            onClick={()=>{onCloseModal()}}>
                            Close
                        </Button>
                    </div>
                </CardActions>
            </Card>
        </Modal>
    )
}
export default ModalNotice
