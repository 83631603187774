import React from 'react'
import PropTypes from 'prop-types'
import { Typography, Collapse } from '@material-ui/core'
import { makeStyles } from '@material-ui/styles'
import { ExpandMore, ExpandLess } from '@material-ui/icons'

const useStyles = makeStyles(theme => ({
    expandBox: {
        position: 'relative',
        padding: '8px 20px 8px 16px',
        cursor: 'pointer',
        borderBottom: '1px solid #bfbfbf',
        '&:last-child': {
            borderBottom: 'none'
        }
    },
    expandIcon: {
        position: 'absolute',
        top: 8,
        right: 0,
        fontSize: 20
    },
    workerName: { fontWeight: 500 },
    workerInfor: {
        paddingRight: 32,
        justifyContent: 'space-between'
    },
}));

const ExpandWorker = ({ expand, onExpand, worker }) => {

    const classes = useStyles();

    const { firstName, lastName, phoneNumber, email, state, zipCode } = worker;

    return (
        <div className={classes.expandBox} onClick={onExpand}>
            <Typography className={classes.workerName}>
                {lastName} {firstName}
                {expand ?
                    <ExpandLess className={classes.expandIcon} /> :
                    <ExpandMore className={classes.expandIcon} />
                }
            </Typography>
            <Collapse in={expand} timeout='auto' unmountOnExit>
                <Typography>{phoneNumber}</Typography>
                <Typography>{email}</Typography>
                <Typography>
                    {state}  {zipCode}
                </Typography>
            </Collapse>
        </div>
    )
}

ExpandWorker.propTypes = {
    expand: PropTypes.bool,
    onExpand: PropTypes.func,
    worker: PropTypes.object
}

export default ExpandWorker;
