import { CircularProgress, ClickAwayListener, colors, IconButton, Tooltip, Typography } from '@material-ui/core';
import { AddBoxRounded, ArrowBackIosRounded, ArrowForwardIosRounded, EditRounded, OpenInNew } from '@material-ui/icons';
import { makeStyles, styled } from '@material-ui/styles';
import apiConfig from 'apiConfig';
import clsx from 'clsx';
import { AddressLink } from 'components';
import _ from 'lodash';
import { times } from 'mockup/Routes';
import moment from 'moment';
import React, { Fragment, useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import axios from 'utils/axios';
import { formatNumber, formatTwoPDecimal } from 'utils/formatNumber';
import ScheduledModal from 'views/Routes2/components/Modals/ScheduledModal';
import SourcesModal from 'views/Routes2/components/Modals/SourcesModal';

const JOB_WIDTH = 170;
const TIME_HEIGHT = 20;

const useStyles = makeStyles(theme => ({
  loading: {
    textAlign: 'center',
    padding: theme.spacing(2)
  },
  noData: {
    textAlign: 'center',
    fontWeight: 500,
    fontSize: 16,
    marginTop: theme.spacing(2)
  },
  routeContentContainer: {
    width: '100%',
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    '--border-color': '#dee3e9'
  },
  routeFull: {
    backgroundColor: '#eeeeee',
    opacity: .8,
    '--border-color': '#bac1ca'
  },
  routeJobsContainer: {
    flex: 1,
    position: 'relative',
    borderTop: '1px solid var(--border-color)',
  },
  routeJobs: {
    position: 'absolute',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
    overflow: 'auto',
    display: 'flex',
    alignItems: 'flex-start'
  },
  times: {
    display: 'flex',
    flexDirection: 'column',
    flexBasis: 65
  },
  time: {
    textAlign: 'end',
    paddingRight: 10,
    borderStyle: 'solid',
    borderColor: 'var(--border-color)',
    borderWidth: '1px 1px 0 0',
    lineHeight: `${TIME_HEIGHT * 2 + 1}px`,
    '&:first-child': {
      borderTop: 'none'
    }
  },
  jobBoxes: {
    display: 'flex',
    flexDirection: 'column',
    flex: '1',
    position: 'relative'
  },
  jobBox: {
    lineHeight: `${TIME_HEIGHT}px`,
    borderTop: '1px solid var(--border-color)',
    '&:first-child': {
      border: 'none'
    }
  },
  selectedJobBox: {
    backgroundColor: '#c7c7c7'
  },
  jobs: {
    position: 'absolute',
    top: 0,
    left: 0,
    width: '100%',
    height: 'fit-content',
    minHeight: '100%',
    overflow: 'auto hidden',
    scrollBehavior: 'smooth'
  },
  job: {
    position: 'absolute',
    width: JOB_WIDTH - 2,
    margin: 1,
    padding: 6,
    backgroundColor: colors.green[500],
    borderRadius: 8,
    color: theme.palette.white,
    whiteSpace: 'pre-line',
    overflow: 'hidden',
    textOverflow: 'ellipsis'
  },
  scrollButton: {
    position: 'absolute',
    top: 'calc(50% - 24px)',
    opacity: '50%',
    backgroundColor: '#1f1f1f20 !important'
  },
  routeDetails: {
    flexBasis: 'fit-content',
    display: 'flex',
    alignItems: 'flex-start',
    borderTop: '1px solid var(--border-color)',
    padding: `${theme.spacing(.25)}px ${theme.spacing(1)}px`
  },
  detailLabel: {
    lineHeight: '30px'
  },
  details: {
    display: 'flex',
    flexWrap: 'wrap',
    padding: 1,
    margin: theme.spacing(-.25)
  },
  detail: {
    display: 'inline-flex',
    cursor: 'pointer',
    padding: `${theme.spacing(.5)}px ${theme.spacing(1)}px`,
    backgroundColor: colors.green[500],
    color: theme.palette.white,
    borderRadius: theme.spacing(.5),
    margin: theme.spacing(.25),
    lineHeight: `${TIME_HEIGHT}px`
  },
  routeNotes: {
    maxHeight: 84,
    overflow: 'auto',
    marginLeft: theme.spacing(2),
    '& span': {
      whiteSpace: 'nowrap',
      '&:first-child': {
        marginRight: theme.spacing(0.5)
      },
      '&:nth-child(2)': {
        textDecoration: 'underline',
        color: '#0000ee'
      }
    },
    '&.see-less': {
      display: 'flex',
      '& span:first-child': {
        overflow: 'hidden',
        textOverflow: 'ellipsis'
      }
    },
    '&.see-more': {
      display: 'block',
      '& span:first-child': {
        whiteSpace: 'normal'
      }
    }
  },
  commerical: {
    '&$job, &$detail': {
      backgroundColor: '#8a2be2'
    }
  },
  linkButton: {
    color: theme.palette.white,
    lineHeight: '8px',
    marginLeft: theme.spacing(.5)
  },
  linkButtonIcon: {
    fontSize: 20
  },
  iconButton: {
    padding: 0,
    marginTop: theme.spacing(-.5),
    marginBottom: theme.spacing(-.5),
    color: colors.green[500],
    '&:hover': {
      color: colors.green[900],
      backgroundColor: 'transparent'
    },
    '& span svg': {
      fontSize: 38
    }
  },
  tooltipBox: {
    '& div:first-child': {
      display: 'flex',
      justifyContent: 'space-between'
    }
  },
  jobSplitNum: {
    marginLeft: 10
  },
  tooltipActions: {
    marginLeft: 10,
    display: 'flex',
    alignItems: 'center',
    '& a': {
      color: theme.palette.white,
      fontSize: 20,
      marginLeft: 5
    }
  },
  editIcon: {
    padding: 0,
    '&:hover': {
      backgroundColor: 'transparent'
    },
    '& span svg': {
      color: theme.palette.white,
      fontSize: 20
    }
  },
  addressLink: {
    color: 'white'
  }
}));

const HtmlTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
  pointerEvents: 'all'
}));

const RouteContent = ({ route, loading, onError }) => {

  const classes = useStyles();
  const { date } = useParams();
  const dispatch = useDispatch();

  const { routes } = useSelector(state => state.RoutesReducer);
  const { routeJobs } = useSelector(state => state.JobsReducer);
  const { routeEmployees } = useSelector(state => state.EmployeesReducer);
  const { routeEquipment } = useSelector(state => state.EquipmentsReducer);

  const collisionJobs = [];

  const [lineJobs, setLineJobs] = useState([]);
  const [selectedHour, setSelectedHour] = useState();
  const [defaultType, setDefaultType] = useState('jobs');
  const [editedRoute, setEditedRoute] = useState();
  const [maxJobHeight, setMaxJobHeight] = useState(0);
  const [isRouteNoteOverflow, setIsRouteNoteOverflow] = useState(false);
  const [isRouteNoteSeeMore, setIsRouteNoteSeeMore] = useState(false);
  const [showTooltipJobInfo, setShowTooltipJobInfo] = useState({});
  const [openResourcesModal, setOpenResourcesModal] = useState(false);
  const [openScheduledModal, setOpenScheduledModal] = useState(false);

  const routeJobsRef = useRef();
  const routeNoteRef = useRef();
  const jobsRef = useRef();

  const getJobBoxClass = index => {
    if (selectedHour !== index) return classes.jobBox;
    return clsx(classes.jobBox, classes.selectedJobBox);
  };

  const getFullName = employee => {
    if (employee) {
      const days = employee.dayOff.map(
        day => `${moment(day.start).format('hh:mm A')} - ${moment(day.end).format('hh:mm A')}`
      );
      const dayString = days.length > 0 ? ` | Unavailable: ${days.join(', ')}` : '';
      return `${employee.firstName || ''}${employee.lastName ? ` ${employee.lastName}` : ' '} ${dayString}`.replace('N/A', '');
    }

    return '';
  };

  const getJobDuration = job => moment.duration(moment(job.jobEnd).diff(moment(job.jobStart))).asHours();

  const getJobTop = job => moment.duration(moment(job.jobStart).format('HH:mm:ss')).asHours() * (TIME_HEIGHT * 2 + 2);

  const getJobHeight = job => Math.max(0, getJobDuration(job) * (TIME_HEIGHT * 2 + 2) - 3);

  const getJobInfo = job => {
    if (!job.job) return '';

    return [
      job.job.customerName,
      job.job.address1,
      [job.job.city, `${getJobDuration(job)} Hours`].filter(Boolean).join(', '),
      '$' + (job.job.price + job.job.discountDollars).toFixed(2)
    ].filter(Boolean).join('\n');
  };

  const getTooltipJobInfo = job => (
    <div className={classes.tooltipBox} onClick={e => e.stopPropagation()}>
      <div>
        <Typography color="inherit">
          {job.job?.city ? job.job?.city + ', ' : ''}
          ${formatNumber(job.job?.price ?? 0, 2)}
        </Typography>
        <Typography color="inherit" className={classes.jobSplitNum}>
          {job.job?.totalSplitJobs > 1 ? `1/${job.job.totalSplitJobs}` : ''}
        </Typography>
        <div className={classes.tooltipActions}>
          <IconButton className={classes.editIcon} onClick={e => e.stopPropagation() || handleEditRoute('job', job)}>
            <EditRounded />
          </IconButton>
          <a
            href={`/customers/${encodeURIComponent(job.job?.customerName ?? '')}/${job.job?.customerId ?? ''}/jobs/${job.job?.jobId ?? ''}/information`}
            onClick={e => e.stopPropagation()}>
            <i className="fas fa-external-link" />
          </a>
        </div>
      </div>
      <Typography color="inherit">
        <em>Start time: </em>{moment(job.jobStart).format('LT')}
      </Typography>
      <Typography color="inherit">
        <em>Hours: </em>{Math.round((job.job?.estimatedHours ?? 0) * 100) / 100}
      </Typography>
      <Typography color="inherit">
        <em>Customer: </em>{job.job?.customerName ?? ''}
      </Typography>
      <Typography color="inherit">
        <em>Address: </em>
        {job.job
          ? <AddressLink
              address={{
                ...job.job,
                state: { name: job.job.state }
              }}
              className={classes.addressLink}
              inline
            />
          : ''}
      </Typography>
      <Typography color="inherit">
        <em>Equipment: </em>{job.job?.equipment?.replace(';', ', ') ?? ''}
      </Typography>
      <Typography color="inherit">
        <em>Description: </em>{job.job?.description ?? ''}
      </Typography>
      <Typography color="inherit">
        <span>
          {
            job.routeJobStatus === 1 && (
              <>
                <i className="far fa-house-return" /> <span>Job started</span>
              </>
            )
          }
          {
            job.routeJobStatus === 2 && (
              <>
                <i className="far fa-walking" /> <span>On the way</span>
              </>
            )
          }
          {
            job.routeJobStatus === 3 && (
              <>
                <i className="far fa-house-leaving" /> <span>Job completed</span>
              </>
            )
          }
        </span>
      </Typography>
    </div>
  );

  const handleShowJobInfo = job => {
    setShowTooltipJobInfo({ [job.jobSplitId]: true });
  }

  const handleHideJobInfo = () => {
    setShowTooltipJobInfo({});
  };

  const handleDropBackJobItem = ({ oldRouteId, id }) => {
    if (oldRouteId !== undefined) {
      const route = routes.find(route => route.id === oldRouteId);
      const routeRemove = route.routeJobs.find(job => job.jobSplitId === id);
      routeRemove.estimatedHours = routeRemove.job.estimatedHours;
      routeRemove.customerName = routeRemove.job.customerName;
      routeRemove.equipment = routeRemove.job.equipment;
      routeRemove.city = routeRemove.job.city || '';
      routeRemove.address1 = routeRemove.job.address1 || '';
      routeRemove.state = routeRemove.job.state;
      routeRemove.zipCode = routeRemove.job.zipCode;
      routeRemove.price = routeRemove.job.price;
      routeRemove.totalSplitJobs = routeRemove.job.totalSplitJobs;

      const routeData = {
        ...route,
        routeEquipment: !route.routeEquipment ? [] : [...route.routeEquipment],
        routeEmployees: !route.routeEmployees ? [] : [...route.routeEmployees],
        routeJobs: route.routeJobs.filter(job => job.jobSplitId !== id)
      };
      const requestForRoute = axios.put(apiConfig.url.BASE_URL + apiConfig.url.ROUTE + `${date}/` + oldRouteId, routeData);

      dispatch({ type: 'IS_UPDATE', status: true });
      Promise.all([requestForRoute])
        .then(values => {
          dispatch({ type: 'REMOVE_JOB_ROUTE', routeId: oldRouteId, jobSplitId: id });
          dispatch({ type: 'CHANGE_JOB_STAGE', jobSplitId: id, jobStage: 3 });

          dispatch({ type: 'ADD_JOB', jobSplitId: id, routeRemove: routeRemove });
        })
        .catch(() => onError({ failed: true, msg: 'Delete failed, please try again later.' }))
        .finally(() => dispatch({ type: 'IS_UPDATE', status: false }));
    }
  };

  const handleDropBackEmployeeItem = ({ oldRouteId, id }) => {
    if (oldRouteId !== undefined) {
      const route = routes.find(route => route.id === oldRouteId);
      const rj = route.routeJobs;
      rj.forEach(job => {
        const localEndDate = new Date(job.jobStart);
        job.estimatedHours = formatTwoPDecimal(job.estimatedHours * ((route.routeEmployees.length === 0 ? 1 : route.routeEmployees.length) / (route.routeEmployees.length - 1 <= 0 ? 1 : route.routeEmployees.length - 1)));
        const hour = localEndDate.getHours() + Math.round((localEndDate.getMinutes() / 60) * 100) / 100 + (job.estimatedHours);
        localEndDate.setHours(Math.floor(hour));
        localEndDate.setMinutes(Math.round((hour - Math.floor(hour)) * 60));
        job.jobEnd = new Date(localEndDate).toISOString();
      })

      const routeData = {
        ...route,
        routeEquipment: !route.routeEquipment ? [] : [...route.routeEquipment],
        routeEmployees: route.routeEmployees.filter(emp => emp.employeeId !== id),
        routeJobs: !route.routeJobs ? [] : [...rj]
      };
      dispatch({ type: 'IS_UPDATE', status: true });
      axios.put(apiConfig.url.BASE_URL + apiConfig.url.ROUTE + `${date}/` + oldRouteId, routeData)
        .then(() => {
          let routeEmployees = route.routeEmployees.find(emp => emp.employeeId === id);
          dispatch({ type: 'REMOVE_EMP_ROUTE', routeId: oldRouteId, employeeId: id });
          routeEmployees.id = routeEmployees.employeeId;
          dispatch({ type: 'ADD_EMPLOYEE', employee: routeEmployees });
        })
        .catch(() => onError({ failed: true, msg: 'Delete failed, please try again later.' }))
        .finally(() => dispatch({ type: 'IS_UPDATE', status: false }));
    }
  };

  const handleDropBackEquipmentItem = ({ oldRouteId, id }) => {
    if (oldRouteId !== undefined) {
      const route = routes.find(route => route.id === oldRouteId);
      const addEquipment = route.routeEquipment.find(eqp => eqp.id === id);
      const routeData = {
        ...route,
        routeEquipment: route.routeEquipment.filter(eqp => eqp.id !== id),
        routeEmployees: !route.routeEmployees ? [] : [...route.routeEmployees],
        routeJobs: !route.routeJobs ? [] : [...route.routeJobs]
      };
      dispatch({ type: 'IS_UPDATE', status: true });
      axios.put(apiConfig.url.BASE_URL + apiConfig.url.ROUTE + `${date}/` + oldRouteId, routeData)
        .then(() => {
          dispatch({ type: 'REMOVE_EQP_ROUTE', routeId: oldRouteId, id });
          dispatch({ type: 'ADD_EQUIPMENT', addEquipment: addEquipment });
        })
        .catch(() => onError({ failed: true, msg: 'Delete failed, please try again later.' }))
        .finally(() => dispatch({ type: 'IS_UPDATE', status: false }));
    }
  };

  const onDropItem = ({ id, oldRouteId }, routeId, startTime) => {
    dispatch({ type: 'CHANGE_STATUS_JOB', status: false });
    if (oldRouteId === undefined) {
      const route = routes.find(route => route.id === routeId);
      const rjs = routeJobs.filter(job => id.includes(job.jobSplitId));
      const jobs = rjs.map(rj => {
        const job = {
          ...rj,
          job: {
            estimatedHours: rj.estimatedHours,
            price: rj.price,
            city: rj.city || '',
            address1: rj.address1 || '',
            state: rj.state || '',
            zipCode: rj.zipCode || '',
            jobId: rj.jobId,
            customerId: rj.customerId,
            customerName: rj.customerName,
            equipment: rj.equipment,
            description: rj.description,
            totalSplitJobs: rj.totalSplitJobs
          }
        };

        const localStartDate = new Date((new Date(date)).toISOString().replace('Z', ''));
        localStartDate.setHours(Math.floor(startTime));
        localStartDate.setMinutes(startTime - Math.floor(startTime) === 0.5 ? 30 : 0);
        const jobStart = new Date(localStartDate).toISOString();

        const localEndDate = new Date((new Date(date)).toISOString().replace('Z', ''));
        job.estimatedHours = formatTwoPDecimal(job.estimatedHours / (route.routeEmployees.length === 0 ? 1 : route.routeEmployees.length));
        const hour = startTime + (job.estimatedHours);
        localEndDate.setHours(Math.floor(hour));
        localEndDate.setMinutes(Math.round((hour - Math.floor(hour)) * 60));
        const jobEnd = new Date(localEndDate).toISOString();

        job.jobStart = jobStart;
        job.jobEnd = jobEnd;

        return job;
      });

      const newRouteData = {
        ...route,
        routeEquipment: !route.routeEquipment ? [] : [...route.routeEquipment],
        routeEmployees: !route.routeEmployees ? [] : [...route.routeEmployees],
        routeJobs: route.routeJobs ? [...route.routeJobs, ...jobs] : jobs,
      }
      const requestForRoute = axios.put(apiConfig.url.BASE_URL + apiConfig.url.ROUTE + `${date}/` + routeId, newRouteData);
      dispatch({ type: 'IS_UPDATE', status: true });
      Promise.all([requestForRoute])
        .then(values => {
          dispatch({ type: 'UPDATE_JOB_ROUTE', routeId: routeId, routeJobs: values[0].data.routeJobs });
          dispatch({ type: 'REMOVE_JOB', jobSplitId: id });
          dispatch({ type: 'CHANGE_JOB_STAGE', jobSplitId: id, jobStage: 3 });
          onError({ failed: false, msg: 'Update route successfuly.' })
        })
        .catch((e) => {
          onError({ failed: true, msg: 'Update route failed, please try again later.' })
        })
        .finally(() => dispatch({ type: 'IS_UPDATE', status: false }))
    } else if (oldRouteId !== routeId) {
      const route = routes.find(route => route.id === oldRouteId);
      const newRoute = routes.find(route => route.id === routeId);
      const job = { ...route.routeJobs.find(job => id.includes(job.jobSplitId)) };

      const localStartDate = new Date((new Date(date)).toISOString().replace('Z', ''));
      localStartDate.setHours(Math.floor(startTime));
      localStartDate.setMinutes(startTime - Math.floor(startTime) === 0.5 ? 30 : 0);
      const jobStart = new Date(localStartDate).toISOString();

      const localEndDate = new Date((new Date(date)).toISOString().replace('Z', ''));
      job.estimatedHours = formatTwoPDecimal(job.job.estimatedHours / (newRoute.routeEmployees.length === 0 ? 1 : newRoute.routeEmployees.length));
      const hour = startTime + (job.estimatedHours);
      localEndDate.setHours(Math.floor(hour));
      localEndDate.setMinutes(Math.round((hour - Math.floor(hour)) * 60));
      const jobEnd = new Date(localEndDate).toISOString();

      job.jobStart = jobStart;
      job.jobEnd = jobEnd;
      delete job.id;

      const oldRoute = routes.find(route => route.id === oldRouteId);
      const oldRouteData = {
        ...oldRoute,
        routeEquipment: !route.routeEquipment ? [] : [...route.routeEquipment],
        routeEmployees: !route.routeEmployees ? [] : [...route.routeEmployees],
        routeJobs: oldRoute.routeJobs.filter(job => job.jobSplitId !== id)
      };
      const newRouteData = {
        ...newRoute,
        routeEquipment: !newRoute.routeEquipment ? [] : [...newRoute.routeEquipment],
        routeEmployees: !newRoute.routeEmployees ? [] : [...newRoute.routeEmployees],
        routeJobs: newRoute.routeJobs ? [...newRoute.routeJobs, job] : [job]
      };
      const requestForOldRoute = axios.put(apiConfig.url.BASE_URL + apiConfig.url.ROUTE + `${date}/` + oldRouteId, oldRouteData);
      const requestForNewRoute = axios.put(apiConfig.url.BASE_URL + apiConfig.url.ROUTE + `${date}/` + routeId, newRouteData);

      dispatch({ type: 'IS_UPDATE', status: true });
      Promise.all([requestForOldRoute, requestForNewRoute])
        .then(values => {
          axios.patch(apiConfig.url.BASE_URL + 'job', { id, jobStage: 3 }).then(r => {
            dispatch({ type: 'CHANGE_JOB_ROUTE', oldRouteId, routeId, job });
            onError({ failed: false, msg: 'Update route successfuly..' })
          }).catch(() => {
            onError({ failed: true, msg: 'Update route failed, please try again later.' })
          }).finally(() => dispatch({ type: 'IS_UPDATE', status: false }))
        })
        .catch(() => onError({ failed: true, msg: 'Update route failed, please try again later.' }))
    } else if (oldRouteId === routeId) {
      const route = routes.find(route => route.id === routeId);

      const job = { ...route.routeJobs.find(job => id.includes(job.jobSplitId)) };

      const localStartDate = new Date((new Date(date)).toISOString().replace('Z', ''));
      localStartDate.setHours(Math.floor(startTime));
      localStartDate.setMinutes(startTime - Math.floor(startTime) === 0.5 ? 30 : 0);
      const jobStart = new Date(localStartDate).toISOString();

      const localEndDate = new Date((new Date(date)).toISOString().replace('Z', ''));
      job.estimatedHours = formatTwoPDecimal(job.job.estimatedHours / (route.routeEmployees.length === 0 ? 1 : route.routeEmployees.length));
      const hour = startTime + (job.estimatedHours);
      localEndDate.setHours(Math.floor(hour));
      localEndDate.setMinutes(Math.round((hour - Math.floor(hour)) * 60));
      const jobEnd = new Date(localEndDate).toISOString();

      job.jobStart = jobStart;
      job.jobEnd = jobEnd;

      const routeJob = route.routeJobs.filter(job => job.jobSplitId !== id)
      const newRouteData = {
        ...route,
        routeEquipment: !route.routeEquipment ? [] : [...route.routeEquipment],
        routeEmployees: !route.routeEmployees ? [] : [...route.routeEmployees],
        routeJobs: route.routeJobs ? [...routeJob, job] : [job]
      };
      const requestForRoute = axios.put(apiConfig.url.BASE_URL + apiConfig.url.ROUTE + `${date}/` + routeId, newRouteData);
      dispatch({ type: 'IS_UPDATE', status: true });
      Promise.all([requestForRoute])
      .then(values => {
        dispatch({ type: 'CHANGE_JOB_HOURS', oldRouteId, routeId, job });
        onError({ failed: false, msg: 'Update route successfuly..' })
      })
      .catch(() => onError({ failed: true, msg: 'Update route failed, please try again later.' }))
      .finally(() => dispatch({ type: 'IS_UPDATE', status: false }))
    }
  };

  const onDropEmployee = ({ id, oldRouteId }, routeId) => {
    const route = routes.find(route => route.id === routeId);
    if (oldRouteId === undefined) {
      const eps = routeEmployees.filter(emp => id.includes(emp.id));
      let employees = eps.map(ep => ({
        ...ep,
        employeeId: ep.id,
        phoneNumber: ep.phones && ep.phones[0] ? ep.phones[0].formattedPhoneNumber : ''
      }));

      const routeJob = route.routeJobs;
      routeJob.forEach(job => {
        const localEndDate = new Date(job.jobStart);
        job.estimatedHours = formatTwoPDecimal(job.job.estimatedHours / (route.routeEmployees.length === 0 ? 1 : route.routeEmployees.length + 1));

        // job.estimatedHours = formatTwoPDecimal(job.estimatedHours * ( route.routeEmployees.length / (route.routeEmployees.length + 1)));
        const hour = localEndDate.getHours() + Math.round((localEndDate.getMinutes() / 60) * 100) / 100 + (job.estimatedHours);
        localEndDate.setHours(Math.floor(hour));
        localEndDate.setMinutes(Math.round((hour - Math.floor(hour)) * 60));
        // localEndDate.setHours((hour) - Math.floor(hour) > 0.5 ? Math.floor(hour) + 1 : Math.floor(hour));
        // localEndDate.setMinutes((hour) - Math.floor(hour) > 0 && (hour) - Math.floor(hour) <= 0.5 ? 30 : 0);
        job.jobEnd = new Date(localEndDate).toISOString();
      })
      const newRouteData = {
        ...route,
        routeEquipment: !route.routeEquipment ? [] : [...route.routeEquipment],
        routeEmployees: route.routeEmployees ? [...route.routeEmployees, ...employees] : employees,
        // set routeJobs is null to not update in server side
        routeJobs: routeJob,
      };
      dispatch({ type: 'IS_UPDATE', status: true });
      axios.put(apiConfig.url.BASE_URL + apiConfig.url.ROUTE + `${date}/` + routeId, newRouteData)
        .then(res => {
          dispatch({ type: 'ADD_EMP_ROUTE', routeId, employees });
          dispatch({ type: 'REMOVE_EMPLOYEE', employeeIds: id })
          onError({ failed: false, msg: 'Update route successfuly.' })
        })
        .catch(() => onError({ failed: true, msg: 'Update route failed, please try again later.' }))
        .finally(() => dispatch({ type: 'IS_UPDATE', status: false }))
    } else if (oldRouteId !== routeId) {
      const oldRoute = routes.find(route => route.id === oldRouteId);
      const route = routes.find(route => route.id === routeId);
      const employee = oldRoute.routeEmployees.find(emp => id.includes(emp.employeeId));

      // get old routes
      const routeJobOld = oldRoute.routeJobs;
      routeJobOld.forEach(job => {
        const localEndDate = new Date(job.jobStart);
        job.estimatedHours = formatTwoPDecimal(job.estimatedHours * (oldRoute.routeEmployees.length === 0 ? 1 : oldRoute.routeEmployees.length / (oldRoute.routeEmployees.length - 1 <= 0 ? 1 : oldRoute.routeEmployees.length - 1)));
        const hour = localEndDate.getHours() + Math.round((localEndDate.getMinutes() / 60) * 100) / 100 + (job.estimatedHours);
        localEndDate.setHours(Math.floor(hour));
        localEndDate.setMinutes(Math.round((hour - Math.floor(hour)) * 60));
        job.jobEnd = new Date(localEndDate).toISOString();
      })

      const oldRouteData = {
        ...oldRoute,
        routeEquipment: !oldRoute.routeEquipment ? [] : [...oldRoute.routeEquipment],
        routeEmployees: oldRoute.routeEmployees.filter(emp => !id.includes(emp.employeeId)),
        routeJobs: !route.routeJobs ? [] : [...routeJobOld]
      };

      // get new routes
      const routeJobNew = route.routeJobs;
      routeJobNew.forEach(job => {
        const localEndDate = new Date(job.jobStart);
        job.estimatedHours = formatTwoPDecimal(job.estimatedHours * (route.routeEmployees.length === 0 ? 1 : route.routeEmployees.length / (route.routeEmployees.length + 1)));
        const hour = localEndDate.getHours() + Math.round((localEndDate.getMinutes() / 60) * 100) / 100 + (job.estimatedHours);
        localEndDate.setHours(Math.floor(hour));
        localEndDate.setMinutes(Math.round((hour - Math.floor(hour)) * 60));
        job.jobEnd = new Date(localEndDate).toISOString();
      })

      const newRouteData = {
        ...route,
        routeEquipment: !route.routeEquipment ? [] : [...route.routeEquipment],
        routeEmployees: route.routeEmployees ? [...route.routeEmployees, employee] : [employee],
        routeJobs: !route.routeJobs ? [] : [...routeJobNew]
      };
      const requestForOldRoute = axios.put(apiConfig.url.BASE_URL + apiConfig.url.ROUTE + `${date}/` + oldRouteId, oldRouteData);
      const requestForNewRoute = axios.put(apiConfig.url.BASE_URL + apiConfig.url.ROUTE + `${date}/` + routeId, newRouteData);
      dispatch({ type: 'IS_UPDATE', status: true });
      Promise.all([requestForOldRoute, requestForNewRoute])
        .then(values => {
          dispatch({ type: 'CHANGE_EMP_ROUTE', oldRouteId, routeId, employees: [employee] });
          onError({ failed: false, msg: 'Update route successfuly.' })
        })
        .catch(() => onError({ failed: true, msg: 'Update route failed, please try again later.' }))
        .finally(() => dispatch({ type: 'IS_UPDATE', status: false }))
    }
  };

  const onDropEquipment = ({ id, oldRouteId }, routeId) => {
    const route = routes.find(route => route.id === routeId);
    if (oldRouteId === undefined) {
      const equipment = routeEquipment.filter(eqp => id.includes(eqp.id));
      const newRouteData = {
        ...route,
        routeEquipment: route.routeEquipment ? [...route.routeEquipment, ...equipment] : equipment,
        routeEmployees: !route.routeEmployees ? [] : [...route.routeEmployees],
        // set routeJobs is null to not update in server side
        routeJobs: null,
      };
      dispatch({ type: 'IS_UPDATE', status: true });
      axios.put(apiConfig.url.BASE_URL + apiConfig.url.ROUTE + `${date}/` + routeId, newRouteData)
        .then(res => {
          dispatch({ type: 'ADD_EQP_ROUTE', routeId, equipment });
          dispatch({ type: 'REMOVE_EQUIPMENT', routeId, equipment });
          onError({ failed: false, msg: 'Update route successfuly.' })
        })
        .catch(() => onError({ failed: true, msg: 'Update route failed, please try again later.' }))
        .finally(() => dispatch({ type: 'IS_UPDATE', status: false }))
    } else if (oldRouteId !== routeId) {
      const oldRoute = routes.find(route => route.id === oldRouteId);
      const equipment = oldRoute.routeEquipment.find(eqp => id.includes(eqp.id));
      const oldRouteData = {
        ...oldRoute,
        routeEquipment: oldRoute.routeEquipment.filter(eqp => !id.includes(eqp.id))
      };
      const newRouteData = {
        ...route,
        routeEquipment: route.routeEquipment ? [...route.routeEquipment, equipment] : [equipment]
      };
      const requestForOldRoute = axios.put(apiConfig.url.BASE_URL + apiConfig.url.ROUTE + `${date}/` + oldRouteId, oldRouteData);
      const requestForNewRoute = axios.put(apiConfig.url.BASE_URL + apiConfig.url.ROUTE + `${date}/` + routeId, newRouteData);
      dispatch({ type: 'IS_UPDATE', status: true });
      Promise.all([requestForOldRoute, requestForNewRoute])
        .then(values => {
          dispatch({ type: 'CHANGE_EQP_ROUTE', oldRouteId, routeId, equipment });
          onError({ failed: false, msg: 'Update route successfuly.' })
        })
        .catch(() => onError({ failed: true, msg: 'Update route failed, please try again later.' }))
        .finally(() => dispatch({ type: 'IS_UPDATE', status: false }))
    }
  };

  const handleJobBoxSelect = event => {
    if (route.routeFull) return;
    const top = (event.clientY - event.target.getBoundingClientRect().top) / (TIME_HEIGHT * 2 + 2);
    setSelectedHour(Math.min(23.5, Math.floor(top / 0.5) * 0.5));
    handleShowResourcesModal('jobs');
  };

  const handleShowResourcesModal = type => {
    if (route.routeFull) return;
    setDefaultType(type);
    setOpenResourcesModal(true);
  };

  const handleSelectedResource = ({ type, id }) => {
    if (type !== defaultType) return;
    switch (type) {
      case 'jobs':
        if (selectedHour) {
          onDropItem({ id, oldRouteId: undefined }, route.id, selectedHour);
        }
        break;
      case 'employees':
        onDropEmployee({ id, oldRouteId: undefined }, route.id);
        break;
      case 'equipments':
        onDropEquipment({ id, oldRouteId: undefined }, route.id);
        break;
      default:
        break;
    }
  };

  const handleEditRoute = (type, item) => {
    const checkDisableRouteOption = type === 'employee' || type === 'equipment';
    const routeOrders = routes.map(r => {
      return {
        value: r.routeOrder,
        name: `Route ${r.routeOrder}`,
        disabled: checkDisableRouteOption && r.routeOrder === route.routeOrder
      }
    })
    setEditedRoute({
      type,
      item,
      order: route.routeOrder,
      routeOrders: [{ value: 0, name: 'Unschedule' }].concat(routeOrders)
    });
    setOpenScheduledModal(true);
  };

  const handleUpdateRoute = data => {
    setOpenScheduledModal(false);
    if (!data.routeOrder) {
      data.type === 'job' && handleDropBackJobItem({ oldRouteId: route.id, id: data.item.jobSplitId });
      data.type === 'employee' && handleDropBackEmployeeItem({ oldRouteId: route.id, id: data.item.employeeId });
      data.type === 'equipment' && handleDropBackEquipmentItem({ oldRouteId: route.id, id: data.item.id });
      return;
    }

    const newRoute = routes.find(r => r.routeOrder === data.routeOrder);
    switch (data.type) {
      case 'job':
        onDropItem({ id: data.item.jobSplitId, oldRouteId: route.id }, newRoute.id, data.starttime);
        break;
      case 'employee':
        onDropEmployee({ id: data.item.employeeId, oldRouteId: route.id }, newRoute.id);
        break;
      case 'equipment':
        onDropEquipment({ id: data.item.id, oldRouteId: route.id }, newRoute.id);
        break;
      default:
        break;
    }
  };

  const handleCollisionJobs = jobs => {
    if (jobs.length === 0) return;

    let line = -1;
    let lastJob = undefined;
    do {
      lastJob = collisionJobs[++line]?.slice(-1).pop();
    } while (lastJob && moment(lastJob.jobEnd) > moment(jobs[0].jobStart));

    collisionJobs[line] = collisionJobs[line] ?? [];
    collisionJobs[line].push(jobs.shift());

    handleCollisionJobs(jobs);
  };

  useEffect(() => {
    if (!route) {
      setMaxJobHeight(0);
      return;
    }

    let maxHeight = 0;
    route.routeJobs.forEach(rj => maxHeight = Math.max(maxHeight, getJobTop(rj) + getJobHeight(rj) + 2));
    setMaxJobHeight(maxHeight);
    // check jobs was duplicate time
    const jobs = _.clone(route.routeJobs.sort((rj1, rj2) => moment(rj1.jobStart) - moment(rj2.jobStart)));
    handleCollisionJobs(jobs);
    setLineJobs(collisionJobs);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [route?.routeJobs]);

  useEffect(() => {
    if (routeJobsRef?.current) {
      routeJobsRef.current.scrollTop = 336;
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [routeJobsRef?.current, route?.routeOrder]);

  useEffect(() => {
    if (!route || !routeNoteRef?.current) return;
    setIsRouteNoteOverflow(false);
    setTimeout(() => {
      const width = routeNoteRef.current.getBoundingClientRect().width;
      const childWidth = routeNoteRef.current.firstChild.getBoundingClientRect().width;
      setIsRouteNoteOverflow(width < childWidth);
    }, 0);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [route?.routeNote]);

  if (loading) return (
    <div className={classes.loading}>
      <CircularProgress size={24} />
    </div>
  );

  if (!route) return (
    <Typography className={classes.noData}>
      No route found.
    </Typography>
  );

  return (
    <>
      <div className={clsx(classes.routeContentContainer, route.routeFull ? classes.routeFull : null)}>
        <div className={classes.routeJobsContainer}>
          <div ref={routeJobsRef} className={classes.routeJobs}>
            <div className={classes.times}>
              {
                times.map((time, index) => (
                  <span key={index} className={classes.time}>
                    {time.replace(':00', '')}
                  </span>
                ))
              }
            </div>
            <div className={classes.jobBoxes}>
              {
                times.map((time, index) => (
                  <Fragment key={index}>
                    <span className={getJobBoxClass(index)}>&nbsp;</span>
                    <span className={getJobBoxClass(index + 0.5)}>&nbsp;</span>
                  </Fragment>
                ))
              }
              <div ref={jobsRef} className={classes.jobs} onClick={handleJobBoxSelect}>
                <ClickAwayListener onClickAway={handleHideJobInfo}>
                  <div>
                    {
                      lineJobs.map((lineJob, index) => (
                        lineJob.map(job => (
                          <HtmlTooltip
                            key={job.jobSplitId}
                            open={!!showTooltipJobInfo[job.jobSplitId]}
                            onClose={handleHideJobInfo}
                            title={getTooltipJobInfo(job)}
                            disableFocusListener
                            disableHoverListener
                            disableTouchListener
                            arrow>
                            <div
                              className={clsx(classes.job, route.isCommercial ? classes.commerical : null)}
                              style={{
                                top: getJobTop(job),
                                left: index * JOB_WIDTH,
                                height: getJobHeight(job)
                              }}
                              onClick={e => e.stopPropagation() || handleShowJobInfo(job)}>
                              {getJobInfo(job)}
                            </div>
                          </HtmlTooltip>
                        ))
                      ))
                    }
                    <div style={{ marginTop: maxJobHeight }}></div>
                  </div>
                </ClickAwayListener>

                <div style={{
                  position: 'absolute',
                  left: lineJobs.length * JOB_WIDTH,
                  width: 100,
                  height: 1
                }}></div>
              </div>
            </div>
          </div>
          {
            jobsRef?.current?.clientWidth < lineJobs.length * JOB_WIDTH + 100
              && <>
                <IconButton className={classes.scrollButton} style={{ left: 65 }} onClick={() => jobsRef.current.scrollLeft -= 100}>
                  <ArrowBackIosRounded />
                </IconButton>
                <IconButton className={classes.scrollButton} style={{ right: 0 }} onClick={() => jobsRef.current.scrollLeft += 100}>
                  <ArrowForwardIosRounded />
                </IconButton>
              </>
          }
        </div>
        <div className={classes.routeDetails}>
          <Typography className={classes.detailLabel}>Employees</Typography>
          <IconButton className={classes.iconButton} onClick={() => handleShowResourcesModal('employees')}>
            <AddBoxRounded />
          </IconButton>
          <div className={classes.details}>
            {
              route.routeEmployees?.map(em => (
                <span
                  key={em.employeeId}
                  className={clsx(classes.detail, route.isCommercial ? classes.commerical : null)}
                  onClick={() => handleEditRoute('employee', em)}>
                  {getFullName(em)}
                  <Tooltip title="View" arrow>
                    <a
                      className={classes.linkButton}
                      href={`/employees/${encodeURIComponent(em.firstName + ' ' + em.lastName)}/${em.employeeId}/schedule`}
                      onClick={e => e.stopPropagation()}>
                      <OpenInNew className={classes.linkButtonIcon} />
                    </a>
                  </Tooltip>
                </span>
              ))
            }
          </div>
        </div>
        <div className={classes.routeDetails}>
          <Typography className={classes.detailLabel}>Equipment</Typography>
          <IconButton className={classes.iconButton} onClick={() => handleShowResourcesModal('equipments')}>
            <AddBoxRounded />
          </IconButton>
          <div className={classes.details}>
            {
              route.routeEquipment?.map(eq => (
                <span
                  key={eq.id}
                  className={clsx(classes.detail, route.isCommercial ? classes.commerical : null)}
                  onClick={() => handleEditRoute('equipment', eq)}>
                  {eq.assetTag}
                </span>
              ))
            }
          </div>
        </div>
        <div className={classes.routeDetails}>
          <Typography>Notes</Typography>
          <Typography
            ref={routeNoteRef}
            className={clsx(
              classes.routeNotes,
              isRouteNoteOverflow ? (isRouteNoteSeeMore ? 'see-more' : 'see-less') : ''
            )}>
            <span>{route.routeNote}</span>
            {isRouteNoteOverflow && <span onClick={() => setIsRouteNoteSeeMore(!isRouteNoteSeeMore)}>
              {isRouteNoteSeeMore ? 'see less' : 'see more'}
            </span>}
          </Typography>
        </div>
      </div>

      <SourcesModal
        defaultType={defaultType}
        open={openResourcesModal}
        onClose={() => setOpenResourcesModal(false)}
        handleSelectedSource={handleSelectedResource}
      />

      <ScheduledModal
        editedRoute={editedRoute}
        open={openScheduledModal}
        onClose={() => setOpenScheduledModal(false)}
        scheduled={handleUpdateRoute}
      />
    </>
  );
};

export default RouteContent;
