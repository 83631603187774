import React, { useRef, useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import { IconButton, Button, colors } from '@material-ui/core'
import { Menu } from '@material-ui/icons'
import { makeStyles } from '@material-ui/styles'
import CloneModal from "./Modals/CloneModal";
import RecurModal from "./Modals/RecurModal";
import RouteNoteModal from './Modals/RouteNoteModal';

const useStyles = makeStyles(theme => ({
    actionsMenu: {
        position: 'absolute',
        top: -25,
        right: -25,
        zIndex: 99
    },
    actions: {
        opacity: 0,
        visibility: 'hidden',
        transition: 'all 300ms ease-in',
        boxShadow: '0 0px 5px 0px #808080',
        backgroundColor: '#ffffff',
        padding: 8,
        borderRadius: 4,
        position: 'absolute',
        top: 0,
        right: '100%',
    },
    iconBtn: {
        color: '#ffffff',
        backgroundColor: '#134ADB',
        '&:hover': {
            backgroundColor: '#134ADB',
        }
    },
    groupBtn: {
        width: 228,
        display: 'flex',
        justifyContent: 'space-between',
        marginBottom: 8
    },
    btn: {
        width: 110,
        color: theme.palette.white,
        backgroundColor: colors.green[600],
        whiteSpace: 'nowrap',
        '&:hover': {
            backgroundColor: colors.green[900]
        },
    },
    btnPurple: {
        width: 110,
        color: theme.palette.white,
        backgroundColor: '#8a2be2',
        "&:hover": {
            backgroundColor: colors.purple[900]
        },
    },
}));

const ActionsMenu = ({ action, router, onClone, onRecur, handleCompletion, loadingPdfRoute }) => {
    const classes = useStyles();
    const menuRef = useRef();

    const [openModal, setOpenModal] = useState(false);
    const [openModalRecur, setOpenModalRecur] = useState(false);
    const [openMenu, setOpenMenu] = useState(false);
    const [openModalRouteNote, setOpenModalRouteNote] = useState(false);

    const handleToogle = () => {
        setOpenMenu(!openMenu)
    }
    const handleAction = type => {
        setOpenMenu(false);
        action(type, router)
    }

    useEffect(() => {
        const clickOutsie = event => {
            if (openMenu && menuRef.current && !menuRef.current.contains(event.target)) {
                setOpenMenu(false)
            }
        }

        document.addEventListener('click', clickOutsie);

        return () => document.removeEventListener('click', clickOutsie)
    }, [openMenu])

    return (
        <>
            <div ref={menuRef} className={classes.actionsMenu}>
                <IconButton className={classes.iconBtn} onClick={handleToogle}>
                    <Menu />
                </IconButton>
                <div className={classes.actions}
                    style={openMenu ? { opacity: 1, visibility: 'visible' } : null}>
                    <div className={classes.groupBtn}>
                        <Button variant={'contained'}
                                onClick={() => {
                                    setOpenMenu(false);
                                    action('commercial', router)
                                }}
                                className={router.isCommercial ? classes.btnPurple : classes.btn}>Commercial</Button>
                        <Button variant={'contained'}
                            className={classes.btn}
                            onClick={() => handleAction('remove')}
                            disabled={
                                router.routeEmployees.length > 0 ||
                                router.routeEquipment.length > 0 ||
                                router.routeJobs.length > 0
                            }
                        >Remove</Button>
                    </div>
                    <div className={classes.groupBtn}>
                        <Button variant={'contained'} className={classes.btn} onClick={() => handleAction('recur')}>Recur</Button>
                        <Button variant={'contained'} className={classes.btn} onClick={() => handleAction(router.routeFull ? 'open' : 'full')}>{router.routeFull ? 'Open Route' : 'Mark Full'}</Button>
                    </div>
                    <div className={classes.groupBtn}>
                        {
                            !loadingPdfRoute ?
                                <Button variant={'contained'} className={classes.btn} onClick={() => handleAction("print")}>Print</Button>
                                :
                                <Button variant={'contained'} disabled={true} className={classes.btn} onClick={() => handleAction('print')}>Loading</Button>
                        }

                        <Button variant={'contained'} className={classes.btn} onClick={() => handleAction('clone')}>Clone</Button>
                    </div>
                    <div className={classes.groupBtn} style={{ marginBottom: 0 }}>
                        <Button variant={'contained'} className={classes.btn} onClick={() => setOpenModalRouteNote(true)}>Route Note</Button>
                        <Button variant={'contained'} className={classes.btn} onClick={() => handleAction('completion')}>Completion</Button>
                    </div>
                </div>
            </div>
            <CloneModal
                router={router}
                open={openModal}
                onClose={() => setOpenModal(false)}
                addCloneRoute={onClone}
            />

            <RecurModal
                router={router}
                open={openModalRecur}
                onClose={() => setOpenModalRecur(false)}
                addCloneRoute={onRecur}
            />

            <RouteNoteModal
                open={openModalRouteNote}
                onClose={() => setOpenModalRouteNote(false)}
                router={router}
            />
        </>)
}

ActionsMenu.propTypes = {
    action: PropTypes.func.isRequired
}
ActionsMenu.defaultProps = {
    action: () => { },
}

export default ActionsMenu;
