import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { makeStyles } from '@material-ui/styles'
import {
    Modal, Button, colors,
    Card, CardHeader, CardContent, CardActions, Divider, Typography,
} from '@material-ui/core'
import StepOne from './StepOne'
import StepTwo from './StepTwo'
import StepThree from './StepThree'
import StepFour from './StepFour'
import StepFive from './StepFive'
import StepSix from './StepSix'

const useStyles = makeStyles(theme => ({
    styleModal: {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        outline: 'none',
        boxShadow: theme.shadows[20],
        width: 700,
        maxHeight: '100%',
        overflowY: 'auto',
        maxWidth: '100%',
        [theme.breakpoints.down('sm')]: {
            width: 500
        },
    },
    content: {
        height: '470px',
        display: 'flex',
        alignItems: 'center'
    },
    btn: {
        flexShrink: '0',
        color: theme.palette.white,
        backgroundColor: colors.green[600],
        '&:hover': {
            backgroundColor: colors.green[900]
        },
    },
    actionCard: {
        padding: '8px 16px',
        justifyContent: 'space-between',
    }
}));

const QuickbooksModal = ({ quickBooks, updateQuickBooks, open, onClose }) => {

    const classes = useStyles();

    const [step, setStep] = useState(0);

    const nextStep = () => {
        setStep(step + 1)
    }
    const backStep = () => {
        setStep(step - 1)
    }

    const resetModal = () => {
        setStep(0);
        onClose()
    }

    return (
        <Modal open={open} onClose={resetModal}>
            <Card className={classes.styleModal}>
                <CardHeader title='Configure Web Connector Wizard' />
                <Divider />
                <CardContent className={classes.content}>
                    {step === 0 && <StepOne />}
                    {step === 1 && <StepTwo quickBooks={quickBooks} updateQuickBooks={updateQuickBooks} />}
                    {step === 2 && <StepThree />}
                    {step === 3 && <StepFour />}
                    {step === 4 && <StepFive />}
                    {step === 5 && <StepSix />}
                </CardContent>
                <Divider />
                <CardActions className={classes.actionCard}>
                    <Typography style={{ fontWeight: 500 }}>{`Step ${step + 1} of 6`}</Typography>
                    <div>
                        <Button className={classes.btn}
                            onClick={resetModal}
                            style={{ marginRight: step === 0 ? '8px' : '0' }}>
                            Cancel
                        </Button>
                        <Button className={classes.btn} style={{ display: step === 0 ? 'none' : 'inline', margin: '0 8px' }}
                            onClick={backStep}>
                            Back
                        </Button>
                        <Button className={classes.btn} onClick={step === 5 ? resetModal : nextStep}>
                            {step === 5 ? 'Done' : 'Next'}
                        </Button>
                    </div>
                </CardActions>
            </Card>
        </Modal>
    )
}

QuickbooksModal.propTypes = {
    quickBooks: PropTypes.object,
    updateQuickBooks: PropTypes.func,
    open: PropTypes.bool,
    onClose: PropTypes.func
}

export default QuickbooksModal;