import React, { Component } from 'react';
import { Router } from 'react-router-dom';
import { createBrowserHistory } from 'history';
import { Chart } from 'react-chartjs-2';
import { ThemeProvider } from '@material-ui/styles';
import validate from 'validate.js';

import { chartjs } from './helpers';
import theme from './theme';
import 'react-perfect-scrollbar/dist/css/styles.css';
import './assets/scss/index.scss';
import validators from './common/validators';
import Routes from './Routes';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import MomentUtils from '@date-io/moment';
import { reactPlugin } from "./AppInsights";
import { AppInsightsContext } from "@microsoft/applicationinsights-react-js";
import { AppInsightsErrorBoundary } from "@microsoft/applicationinsights-react-js";
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';

const browserHistory = createBrowserHistory({
  basename: process.env.PUBLIC_URL
});
Chart.helpers.extend(Chart.elements.Rectangle.prototype, {
  draw: chartjs.draw
});

validate.validators = {
  ...validate.validators,
  ...validators
};

export default class App extends Component {
    render() {
        return (
            <AppInsightsErrorBoundary onError={() => {
            }} appInsights={reactPlugin}>
                <ThemeProvider theme={theme}>
                    <MuiPickersUtilsProvider utils={MomentUtils}>
                        <DndProvider backend={HTML5Backend}>
                            <Router history={browserHistory}>
                                <AppInsightsContext.Provider value={reactPlugin}>
                                    <Routes/>
                                </AppInsightsContext.Provider>
                            </Router>
                        </DndProvider>
                    </MuiPickersUtilsProvider>
                </ThemeProvider>
            </AppInsightsErrorBoundary>
        );
    }
}
