import { formatNumber } from "utils/formatNumber";

const isValid = (date) => {
  return !isNaN(new Date(date).getTime());
};
const hours = (starttime, endtime) => {
  if (!isValid(starttime) || !isValid(endtime)) {
    return;
  }
  return (
    (new Date(endtime).getTime() - new Date(starttime).getTime()) / 3600000
  );
};

const parseFloatNum = (str) => {
  const trimmed = str && typeof str.trim === "function" ? str.trim() : null;
  if (!trimmed) {
    return null;
  }
  const num = parseFloat(trimmed);
  const isNumber = !isNaN(num);
  const isFullyParsedNum = isNumber && num.toString() === trimmed;
  return isFullyParsedNum ? num : null;
};

const parseSearchParams = (search) => {
  if (!search) return {};
  const params = Object.fromEntries(new URLSearchParams(search));
  return Object.keys(params).reduce((result, key) => {
    const val = params[key];
    if (val === "true") {
      result[key] = true;
    } else if (val === "false") {
      result[key] = false;
    } else {
      const num = parseFloatNum(val);
      result[key] = num === null ? val : num;
    }
    return result;
  }, {});
};

const isValidPattern = (pattern, text) => {
  return pattern.test(text);
};

const getSubTotal = (jobItems, job) => {
  const total = jobItems?.reduce((amount, item) => amount += item.price * item.quantity ?? 0, 0).toFixed(2);
  return job.discountPercent && job.discountPercent % 1 === 0
    ? formatNumber(total * ((100 - job.discountPercent) / 100), 2)
    : formatNumber(total - (job.discountDollars ?? 0), 2);
}

export { hours, parseSearchParams, isValidPattern , getSubTotal };
