import React, {useEffect, useState} from 'react'
import {makeStyles} from '@material-ui/styles'
import {
    Card,
    CardContent,
    CardHeader,
    CircularProgress,
    Collapse,
    colors,
    Divider,
    Grid,
    IconButton,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow, TextField,
    Typography
} from '@material-ui/core'
import EmployeeTasksCard from './components';
import {ConfirmModal, Page, Pagination} from 'components';
import axios from "utils/axios";
import apiConfig from "apiConfig";
import {useDispatch} from "react-redux";
import {AddBox, ArrowDownward, ArrowUpward, KeyboardArrowDown, KeyboardArrowUp} from "@material-ui/icons";
import EmployeesTaskModal from "./components/EmployeesTaskModal";
import Alert from "@material-ui/lab/Alert";
import Snackbar from "@material-ui/core/Snackbar";
import localStorage from "../../utils/localStorage";

const useStyles = makeStyles(theme => ({
    root: {
        padding: theme.spacing(3)
    },
    tableCell: {
        cursor: 'pointer',
        '& p': {
            whiteSpace: 'nowrap',
            fontWeight: 500
        },
        '& svg': {
            fontSize: '14px',
            transition: 'all 100ms ease-out'
        }
    },
    iconSort: {
        marginBottom: '-5px',
        marginLeft: '10px'
    },
    addIcon: {
        padding: '0',
        '& span svg': {
            fontSize: '40px',
            color: colors.green[600],
            '&:hover': {
                color: colors.green[900]
            }
        }
    },
    conTable: {
        overflowX: 'auto'
    },
    customSearch: {
        marginTop: theme.spacing(2),
        marginBottom: theme.spacing(2),
    },
    noData: {
        textAlign: 'center',
        fontSize: theme.spacing(2),
        fontWeight: 500,
        padding: '20px'
    },
    paginate: {
        marginTop: theme.spacing(3),
        display: 'flex',
        justifyContent: 'center',
        marginBottom: '20px'
    }
}));
let pageNumberToday = 1;
let pageNumberBefore = 1;
let pageNumberAfter = 1;
let forcePageToday = 0;
let forcePageBefore = 0;
let forcePageAfter = 0;
const EmployeeTasks = props => {

    const queryParameters = new URLSearchParams(window.location.search)
    const openAdd = queryParameters.get("modal")
    const selectedId = queryParameters.get("id")

    const classes = useStyles();
    const [totalToday, setTotalToday] = useState(0);
    const [totalBefore, setTotalBefore] = useState(0);
    const [totalAfter, setTotalAfter] = useState(0);
    const [loadToday, setLoadToday] = useState(false);
    const [loadBefore, setLoadBefore] = useState(false);
    const [loadAfter, setLoadAfter] = useState(false);
    const [pageCountToday, setPageCountToday] = useState(0);
    const [pageCountBefore, setPageCountBefore] = useState(0);
    const [pageCountAfter, setPageCountAfter] = useState(0);
    const [listSalesFollowUps, setListSalesFollowUps] = useState([]);
    const [listSalesFollowUpsToday, setListSalesFollowUpsToday] = useState([]);
    const [listSalesFollowUpsBefore, setListSalesFollowUpsBefore] = useState([]);
    const [listSalesFollowUpsAfter, setListSalesFollowUpsAfter] = useState([]);
    const [showSort, setShowSort] = useState({});
    const [load, setLoad] = useState(false);
    const [expandedToday, setExpandedToday] = useState(true);
    const [expandedBefore, setExpandedBefore] = useState(true);
    const [expandedAfter, setExpandedAfter] = useState(false);
    const [openModalAdd, setOpenModalAdd] = useState(openAdd ? openAdd : false);
    const [isStatus, setIsStatus] = useState({failed: false, msg: ''});
    const [openSnack, setOpenSnack] = useState(false);

    const [isProgress, setIsProgress] = useState(false);
    const [locatedFile, setLocateFile] = useState();
    const [openConfirmDelete, setOpenConfirmDelete] = useState(false);
    const [selectedRow, setSelectedRow] = useState();
    const [taskUrgencies, setTaskUrgencies] = useState([]);
    const [usersData, setUsersData] = useState([]);
    const [sortByToday, setSortByToday] = useState('DueDate');
    const [sortByUpcoming, setSortByUpcoming] = useState('DueDate');
    const [sortByCompleted, setSortByCompleted] = useState('DateCompleted');
    const [sortTypeToday, setSortTypeToday] = useState('ASC');
    const [sortTypeUpcoming, setSortTypeUpcoming] = useState('ASC');
    const [sortTypeCompleted, setSortTypeCompleted] = useState('ASC');
    const user = localStorage.getFullInfo();

    const [sort, setSort] = useState({name: 'dueDate', asc: true})
    const dates = ['dueDate', 'createdDate', 'lastJob']
    const {onReload} = props
    const dispatch = useDispatch();

    const managerRole = ["Company Administrator", "System Admin", "Area Director", "Office Staff"];
    const userRole = localStorage.getUserRoles();

    let hasManager = false;
    userRole.forEach((x, i) => {
        if(managerRole.find(y=>y === x)){
            hasManager = true;
        }
    })

    const [slectedEmployees, setSlectedEmployees] = useState( user.nameid);

    const onChangeSortToday = name => {
        setSortByToday(name);
        setSortTypeToday(name === sortByToday ? (sortTypeToday === 'ASC' ? 'DESC' : 'ASC') : 'ASC');
        pageNumberToday = 1;
        forcePageToday = 0;
    }

    const onChangeSortUpcoming = name => {
        setSortByUpcoming(name);
        setSortTypeUpcoming(name === sortByUpcoming ? (sortTypeUpcoming === 'ASC' ? 'DESC' : 'ASC') : 'ASC');
        pageNumberBefore = 1;
        forcePageBefore = 0;
    }

    const onChangeSortCompleted = name => {
        setSortByCompleted(name);
        setSortTypeCompleted(name === sortByCompleted ? (sortTypeCompleted === 'ASC' ? 'DESC' : 'ASC') : 'ASC');
        pageNumberAfter = 1;
        forcePageAfter = 0;
    }

    const onPageChangeToday = (page) => {
        pageNumberToday = page.selected + 1;
        forcePageToday = page.selected;
        fetchFollowUpsToday();
    };
    const onPageChangeBefore = (page) => {
        pageNumberBefore = page.selected + 1;
        forcePageBefore = page.selected;
        fetchFollowUpsBefore();
    };
    const onPageChangeAfter = (page) => {
        pageNumberAfter = page.selected + 1;
        forcePageAfter = page.selected;
        fetchFollowUpsAfter();
    };
    const fetchFollowUpsToday = () => {
        setLoadToday(true);
        axios.get(apiConfig.url.BASE_URL + apiConfig.url.EMPLOYEE_TASK_TODAY, {
            params:
                {
                    pageNumber: pageNumberToday,
                    pageSize: 24,
                    AssignedUserId: slectedEmployees.trim(),
                    SortBy: sortByToday,
                    SortType: sortTypeToday
                }
        })
            .then(res => {
                setListSalesFollowUpsToday(res.data.data || []);
                setTotalToday(res.data.total);
                setExpandedToday(res.data.total > 0);
                setPageCountToday(res.data.pageCount);

                if (selectedId) {
                    const selectedTask = res.data.data?.find(x => x.id === selectedId);
                    if (selectedTask) {
                        setSelectedRow(selectedTask);
                        setOpenModalAdd(true);
                    }
                }
            }).finally(() => {
            setLoadToday(false);
        })
    }
    const fetchFollowUpsBefore = () => {
        setLoadBefore(true);
        axios.get(apiConfig.url.BASE_URL + apiConfig.url.EMPLOYEE_TASK_UPCOMING, {
            params:
                {
                    pageNumber: pageNumberBefore,
                    pageSize: 24,
                    AssignedUserId: slectedEmployees,
                    SortBy: sortByUpcoming,
                    SortType: sortTypeUpcoming
                }
        })
            .then(res => {
                setListSalesFollowUpsBefore(res.data.data || []);
                setTotalBefore(res.data.total)
                setExpandedBefore(res.data.total > 0);
                setPageCountBefore(res.data.pageCount);

                if (selectedId) {
                    const selectedTask = res.data.data?.find(x => x.id === selectedId);
                    if (selectedTask) {
                        setSelectedRow(selectedTask);
                        setOpenModalAdd(true);
                    }
                }
            }).finally(() => {
            setLoadBefore(false);
        })
    }
    const fetchFollowUpsAfter = () => {
        setLoadAfter(true);
        axios.get(apiConfig.url.BASE_URL + apiConfig.url.EMPLOYEE_TASK_COMPLETED, {
            params:
                {
                    pageNumber: pageNumberAfter,
                    pageSize: 24,
                    AssignedUserId: slectedEmployees,
                    SortBy: sortByCompleted,
                    SortType: sortTypeCompleted
                }
        })
            .then(res => {
                setListSalesFollowUpsAfter(res.data.data || []);
                setTotalAfter(res.data.total);
                setExpandedAfter(res.data.total > 0);
                setPageCountAfter(res.data.pageCount);

                if (selectedId) {
                    const selectedTask = res.data.data?.find(x => x.id === selectedId);
                    if (selectedTask) {
                        setSelectedRow(selectedTask);
                        setOpenModalAdd(true);
                    }
                }
            }).finally(() => {
            setLoadAfter(false);
        })
    };

    const getTaskUrgencies = () => {
        axios.get(apiConfig.url.BASE_URL + apiConfig.url.EMPLOYEE_TASK_URGENCIES)
            .then(res => {
                setTaskUrgencies(res.data || []);

            }).finally(() => {
        })
    }

    const onDeleteFile = (locatedFile) => {
        setIsProgress(true)
        axios.delete(apiConfig.url.BASE_URL + apiConfig.url.EMPLOYEE_DELETE_TASK + '?taskId='+locatedFile.id)
            .then(() => {
                setIsStatus({ failed: false, msg: 'Remove successfully.' });
                setOpenSnack(true);
                dispatch({ type: 'RELOAD_EMPLOYEES_TASK_COUNTER', reloadTaskSideBar: true });
                const del_files = locatedFile.type == 1 ? [...listSalesFollowUpsToday] : [...listSalesFollowUpsBefore];
                del_files.splice(locatedFile.index, 1);
                locatedFile.type == 1 ? setListSalesFollowUpsToday(del_files) : setListSalesFollowUpsBefore(del_files);
                setIsProgress(false)
                setOpenConfirmDelete(false);
                if (locatedFile.type == 1) {
                    setTotalToday(totalToday - 1);
                }
                if (locatedFile.type == 2) {
                    setTotalBefore(totalBefore - 1);
                }
            })
            .catch(() => {
                setIsStatus({ failed: true, msg: 'Remove failed, please try again later.' });
                setOpenSnack(true);
                setOpenConfirmDelete(false);
            })
    }

    const fetchUsers = () => {
        let url = apiConfig.url.USER_URL + 'list?pageSize=1000&includeAdmin=true';
        axios.get(url)
            .then(res => {
                const activeUser = res.data.users.filter(x => x.termDate)
                    .sort((a, b) => a.fullName.localeCompare(b.fullName))
                    .sort((a, b) => a.id === user.nameid ? -1 : 0)
                const termUser = res.data.users.filter(x => !x.termDate)
                    .sort((a, b) => a.fullName.localeCompare(b.fullName))
                    .sort((a, b) => a.id === user.nameid ? -1 : 0)
                setUsersData([...termUser, ...activeUser])
            })
    };


    const reload = () => {
        setLoad(!load);
        dispatch({type: 'RELOAD_CUSTOMERS_COUNTER', reloadCustomer: true});
        //onReload();
    }

    const getSortIconToday = (type) =>
        sortByToday === type &&
        (
            sortTypeToday === 'ASC'
                ? <ArrowUpward className={classes.iconSort}/>
                : <ArrowDownward className={classes.iconSort}/>
        )

    const getSortIconUpcoming = (type) =>
        sortByUpcoming === type &&
        (
            sortTypeUpcoming === 'ASC'
                ? <ArrowUpward className={classes.iconSort}/>
                : <ArrowDownward className={classes.iconSort}/>
        )

    const getSortIconCompleted = (type) =>
        sortByCompleted === type &&
        (
            sortTypeCompleted === 'ASC'
                ? <ArrowUpward className={classes.iconSort}/>
                : <ArrowDownward className={classes.iconSort}/>
        )

    useEffect(() => {
        fetchUsers();
        getTaskUrgencies();
    }, [])

    useEffect(() => {
        fetchFollowUpsToday();
        fetchFollowUpsAfter();
        fetchFollowUpsBefore();
    }, [sort, load, slectedEmployees])

    useEffect(() => {
        fetchFollowUpsToday();
    }, [sortByToday, sortTypeToday]);

    useEffect(() => {
        fetchFollowUpsBefore();
    }, [sortByUpcoming, sortTypeUpcoming]);

    useEffect(() => {
        fetchFollowUpsAfter();
    }, [sortByCompleted, sortTypeCompleted]);

    return (
        <Page className={classes.root}>
            <div>
                {hasManager && usersData && usersData.length > 0 &&
                <TextField
                    style={{width: '300px', marginBottom: 24 }}
                    className={classes.statusSelect}
                    name="AssignedUserId"
                    SelectProps={{native: true}}
                    variant="outlined"
                    select
                    label="Assignee"
                    value={slectedEmployees || ' '}
                    onChange={(event) => {
                        setSlectedEmployees(event.target.value.trim())
                    }}
                >
                    <option value={' '}>All</option>
                    {
                        usersData.map((st) => (
                            <option
                                key={st.id}
                                value={st.id}
                            >
                                {`${st.fullName}`}
                            </option>
                        ))
                    }
                </TextField>
                }
                <Card>
                    <CardHeader title={`Today's Task (${totalToday})`} className={classes.cardHeader}
                                action={expandedToday ? <KeyboardArrowUp/> : <KeyboardArrowDown/>}
                                onClick={() => setExpandedToday(!expandedToday)}
                    />
                    <Divider />
                    <Collapse in={expandedToday} timeout='auto' unmountOnExit>

                        <CardContent>
                            <TableContainer>
                                <Table>
                                    <TableHead>
                                        <TableRow className={classes.headRow}>
                                            <TableCell align='left' onClick={() => onChangeSortToday('Assignee')} style={{minWidth: 'auto'}}>
                                                <span>Assignee {getSortIconToday('Assignee')}</span>
                                            </TableCell>
                                            <TableCell align='left' onClick={() => onChangeSortToday('Urgency')} style={{ width: 'auto', minWidth: '120px' }}>
                                                <span>Urgency {getSortIconToday('Urgency')}</span>
                                            </TableCell>
                                            <TableCell align='left' onClick={() => onChangeSortToday('Description')} style={{ width: 'auto' }}>
                                                <span>Task {getSortIconToday('Description')}</span>
                                            </TableCell>
                                            <TableCell align='left' onClick={() => onChangeSortToday('DueDate')} style={{minWidth: 'auto'}}>
                                                <span>Due Date {getSortIconToday('DueDate')}</span>
                                            </TableCell>
                                            <TableCell align='left' onClick={() => onChangeSortToday('Assigner')} style={{minWidth: 'auto'}}>
                                                <span>Assigner {getSortIconToday('Assigner')}</span>
                                            </TableCell>
                                            <TableCell align='right' style={{ width: 'auto' }}>
                                                <IconButton className={classes.addIcon}
                                                            onClick={() => {
                                                                setOpenModalAdd(true);
                                                                setSelectedRow({});
                                                            }}>
                                                    <AddBox />
                                                </IconButton>
                                            </TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {
                                            loadToday ?
                                                <TableRow className={classes.headRow}>
                                                    <TableCell colSpan={7}>
                                                <Grid
                                                    container
                                                    spacing={0}
                                                    align="center"
                                                    justifyContent="center"
                                                    direction="column">
                                                    <Grid item>
                                                        <CircularProgress className={classes.circularProgress} size={50}/>
                                                    </Grid>
                                                </Grid>
                                                    </TableCell>
                                                </TableRow>
                                                :
                                                (listSalesFollowUpsToday.length > 0 ?
                                                        (
                                                            <>
                                                                {
                                                                    listSalesFollowUpsToday.map((task, index) => (
                                                                        <EmployeeTasksCard
                                                                            selectedId={selectedRow}
                                                                            selectedRow={(data) => {
                                                                                setSelectedRow(data);
                                                                                setOpenModalAdd(true);
                                                                            }}
                                                                            onDeleteFile={(data) => {
                                                                                setLocateFile({...data, type: 1});
                                                                                setOpenConfirmDelete(true);
                                                                            }}
                                                                            onReload={reload}
                                                                            key={task.id}
                                                                            task={task}
                                                                            hasManager={hasManager}
                                                                            index={index}
                                                                        />
                                                                    ))
                                                                }
                                                                <TableRow className={classes.headRow}>
                                                                    <TableCell colSpan={7}>
                                                                    <div style={pageCountToday <= 1 ? {display: 'none'} : null} className={classes.paginate}>
                                                                        <Pagination pageCount={pageCountToday} forcePage={forcePageToday} onPageChange={onPageChangeToday}/>
                                                                    </div>
                                                                    </TableCell>
                                                                </TableRow>
                                                            </>
                                                        )
                                                        :
                                                        (
                                                            !loadToday &&
                                                            <TableRow className={classes.headRow}>
                                                                <TableCell colSpan={7}>
                                                            <Grid container justifyContent='center'>
                                                                <Typography className={classes.noData}>No data found.</Typography>
                                                            </Grid>
                                                                </TableCell>
                                                            </TableRow>
                                                        )
                                                )}
                                    </TableBody>
                                </Table>
                            </TableContainer>
                        </CardContent>
                    </Collapse>
                </Card>

                <Card style={{ marginTop: '24px' }}>
                    <CardHeader title={`Upcoming Tasks (${totalBefore})`} className={classes.cardHeader}
                                action={expandedBefore ? <KeyboardArrowUp/> : <KeyboardArrowDown/>}
                                onClick={() => setExpandedBefore(!expandedBefore)}
                    />
                    <Divider />
                    <Collapse in={expandedBefore} timeout='auto' unmountOnExit>

                        <CardContent>
                            <TableContainer>
                                <Table>
                                    <TableHead>
                                        <TableRow className={classes.headRow}>
                                            <TableCell align='left' onClick={() => onChangeSortUpcoming('Assignee')} style={{minWidth: 'auto'}}>
                                                <span>Assignee {getSortIconUpcoming('Assignee')}</span>
                                            </TableCell>
                                            <TableCell align='left' onClick={() => onChangeSortUpcoming('Urgency')} style={{ width: 'auto', minWidth: '120px' }}>
                                                <span>Urgency {getSortIconUpcoming('Urgency')}</span>
                                            </TableCell>
                                            <TableCell align='left' onClick={() => onChangeSortUpcoming('Description')} style={{ width: 'auto' }}>
                                                <span>Task {getSortIconUpcoming('Description')}</span>
                                            </TableCell>
                                            <TableCell align='left' onClick={() => onChangeSortUpcoming('DueDate')} style={{minWidth: 'auto'}}>
                                                <span>Due Date {getSortIconUpcoming('DueDate')}</span>
                                            </TableCell>
                                            <TableCell align='left' onClick={() => onChangeSortUpcoming('Assigner')} style={{minWidth: 'auto'}}>
                                                <span>Assigner {getSortIconUpcoming('Assigner')}</span>
                                            </TableCell>
                                            <TableCell align='right' style={{ width: 'auto' }}>
                                                <IconButton className={classes.addIcon}
                                                            onClick={() => {
                                                                setOpenModalAdd(true);
                                                                setSelectedRow({});
                                                            }}>
                                                    <AddBox />
                                                </IconButton>
                                            </TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {
                                            loadBefore ?
                                                <TableRow className={classes.headRow}>
                                                    <TableCell colSpan={7}>
                                                <Grid
                                                    container
                                                    spacing={0}
                                                    align="center"
                                                    justifyContent="center"
                                                    direction="column">
                                                    <Grid item>
                                                        <CircularProgress className={classes.circularProgress} size={50}/>
                                                    </Grid>
                                                </Grid>
                                                    </TableCell>
                                                </TableRow>
                                                :
                                                (listSalesFollowUpsBefore.length > 0 ?
                                                        (
                                                            <>
                                                                {
                                                                    listSalesFollowUpsBefore.map((task, index) => <EmployeeTasksCard
                                                                        onDeleteFile={(data) => {
                                                                            setLocateFile({...data, type: 2});
                                                                            setOpenConfirmDelete(true);
                                                                        }}
                                                                        selectedRow={(data) => {
                                                                            setSelectedRow(data);
                                                                            setOpenModalAdd(true);
                                                                        }}
                                                                        onReload={reload}
                                                                        key={task.id}
                                                                        task={task}
                                                                        hasManager={hasManager}
                                                                        index={index}
                                                                    />)
                                                                }
                                                                <TableRow className={classes.headRow}>
                                                                    <TableCell colSpan={7}>
                                                                    <div style={pageCountBefore <= 1 ? {display: 'none'} : null} className={classes.paginate}>
                                                                        <Pagination pageCount={pageCountBefore} forcePage={forcePageBefore} onPageChange={onPageChangeBefore}/>
                                                                    </div>
                                                                    </TableCell>
                                                                </TableRow>
                                                            </>
                                                        )
                                                        :
                                                        (
                                                            !loadBefore &&
                                                            <TableRow className={classes.headRow}>
                                                                <TableCell colSpan={7}>
                                                                    <Grid container justifyContent='center'>
                                                                        <Typography className={classes.noData}>No data found.</Typography>
                                                                    </Grid>
                                                                </TableCell>
                                                            </TableRow>
                                                        )
                                                )}
                                    </TableBody>
                                </Table>
                            </TableContainer>
                        </CardContent>


                    </Collapse>
                </Card>
                <Card style={{ marginTop: '24px' }}>
                    <CardHeader title={`Completed`} className={classes.cardHeader}
                                action={expandedAfter ? <KeyboardArrowUp/> : <KeyboardArrowDown/>}
                                onClick={() => setExpandedAfter(!expandedAfter)}
                    />
                    <Divider />
                    <Collapse in={expandedAfter} timeout='auto' unmountOnExit>

                        <CardContent>
                            <TableContainer>
                                <Table>
                                    <TableHead>
                                        <TableRow className={classes.headRow}>
                                            <TableCell align='left' onClick={() => onChangeSortCompleted('Assignee')} style={{minWidth: 'auto'}}>
                                                <span>Assignee {getSortIconCompleted('Assignee')}</span>
                                            </TableCell>
                                            {/*<TableCell align='left' style={{ width: 'auto', minWidth: '120px' }}>Urgency</TableCell>*/}
                                            <TableCell align='left' onClick={() => onChangeSortCompleted('Description')} style={{ width: 'auto' }}>
                                                <span>Task {getSortIconCompleted('Description')}</span>
                                            </TableCell>
                                            <TableCell align='left' onClick={() => onChangeSortCompleted('DueDate')} style={{minWidth: 'auto'}}>
                                                <span>Due Date {getSortIconCompleted('DueDate')}</span>
                                            </TableCell>
                                            <TableCell align='left' onClick={() => onChangeSortCompleted('Assigner')} style={{minWidth: 'auto'}}>
                                                <span>Assigner {getSortIconCompleted('Assigner')}</span>
                                            </TableCell>
                                            <TableCell align='left' onClick={() => onChangeSortCompleted('DateCompleted')} style={{ width: 'auto' }}>
                                                <span>Date Completed {getSortIconCompleted('DateCompleted')}</span>
                                            </TableCell>
                                            {/*<TableCell align='left' style={{ width: 'auto' }}>*/}
                                            {/*<IconButton className={classes.addIcon}*/}
                                            {/*            onClick={() => setOpenModalAdd(true)}>*/}
                                            {/*    <AddBox />*/}
                                            {/*</IconButton>*/}
                                            {/*</TableCell>*/}
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {
                                            loadAfter ?
                                                <TableRow className={classes.headRow}>
                                                    <TableCell colSpan={5}>
                                                        <Grid
                                                            container
                                                            spacing={0}
                                                            align="center"
                                                            justifyContent="center"
                                                            direction="column">
                                                            <Grid item>
                                                                <CircularProgress className={classes.circularProgress} size={50}/>
                                                            </Grid>
                                                        </Grid>
                                                    </TableCell>
                                                </TableRow>
                                                :
                                                (listSalesFollowUpsAfter.length > 0 ?
                                                        (
                                                            <>
                                                                {
                                                                    listSalesFollowUpsAfter.map((task) => <EmployeeTasksCard
                                                                        hasCompleted={true}
                                                                        onDeleteFile={(data) => {
                                                                            setLocateFile(data);
                                                                            setOpenConfirmDelete(true);
                                                                        }}
                                                                        selectedRow={(data) => {
                                                                            setSelectedRow(data);
                                                                            setOpenModalAdd(true);
                                                                        }}
                                                                        onReload={reload}
                                                                        key={task.id}
                                                                        task={task}
                                                                        hasManager={hasManager}
                                                                    />)
                                                                }
                                                                <TableRow className={classes.headRow}>
                                                                    <TableCell colSpan={7}>
                                                                        <div style={pageCountAfter <= 1 ? {display: 'none'} : null} className={classes.paginate}>
                                                                            <Pagination pageCount={pageCountAfter} forcePage={forcePageAfter} onPageChange={onPageChangeAfter}/>
                                                                        </div>
                                                                    </TableCell>
                                                                </TableRow>
                                                            </>
                                                        )
                                                        :
                                                        (
                                                            !loadAfter &&
                                                            <TableRow className={classes.headRow}>
                                                                <TableCell colSpan={7}>
                                                                    <Grid container justifyContent='center'>
                                                                        <Typography className={classes.noData}>No data found.</Typography>
                                                                    </Grid>
                                                                </TableCell>
                                                            </TableRow>
                                                        )
                                                )}
                                    </TableBody>
                                </Table>
                            </TableContainer>
                        </CardContent>


                    </Collapse>
                </Card>
                <EmployeesTaskModal
                    usersData={usersData}
                    taskUrgencies={taskUrgencies}
                    selected={selectedRow}
                    onCloseModal={() => {
                        setOpenModalAdd(false);
                    }}
                    open={openModalAdd}
                    onload={() => {
                        setLoad(!load);
                    }}
                />
                <ConfirmModal
                    isProgress={isProgress}
                    openConfirm={openConfirmDelete}
                    closeConfirm={() => setOpenConfirmDelete(false)}
                    onConfirm={() =>onDeleteFile(locatedFile)}
                />
                <Snackbar anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
                          open={openSnack}
                          autoHideDuration={3000}
                          onClose={() => setOpenSnack(false)}>
                    <Alert elevation={6} variant="filled" severity={isStatus.failed ? 'error' : 'success'}>
                        <Typography
                            color="inherit"
                            variant="h6">
                            {isStatus.msg}
                        </Typography>
                    </Alert>
                </Snackbar>
            </div>
        </Page>
    )
}

export default EmployeeTasks;
