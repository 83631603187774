import React from "react";
import { colors } from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";
import { Page } from "components";
import localStorage from "utils/localStorage";
import { AccessControl } from "components/AccessControl/AccessControl";
import { PERMISSIONS } from "common/permissions";
import CustomForm from "views/Admin/components/CustomForms/CustomForm";
const useStyles = makeStyles((theme) => ({
  root: {
    height: "100%",
    padding: "0 32px 32px",
  },
  tabs: {
    marginTop: theme.spacing(2),
  },
  tab: {
    padding: "6px 16px",
    [theme.breakpoints.up("sm")]: {
      minWidth: "auto",
    },
  },
  divider: {
    backgroundColor: colors.grey[300],
  },
  content: {
    marginTop: theme.spacing(3),
  },
}));

const CustomForms = (props) => {
  const { match, history } = props; 
  const { name, id, tab } = match.params;
  const classes = useStyles();

  const account = localStorage.getUserInfo();
  const fullInfo = localStorage.getFullInfo();

  return (
    <AccessControl requiredPermission={PERMISSIONS.page.admin} redirect>
      <Page
        className={classes.root}
        title="Custom Forms"
      >
        <div className={classes.content}>
          <CustomForm adminId={id} />
        </div>
      </Page>
    </AccessControl>
  );
};

export default CustomForms;
