import React, { useRef, useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { Typography, Tooltip, Snackbar } from '@material-ui/core'
import { Alert } from '@material-ui/lab'
import { createTheme, MuiThemeProvider } from '@material-ui/core/styles'
import { makeStyles } from '@material-ui/styles'
import moment from 'moment'
import { useDrag } from 'react-dnd'
import { useSelector, useDispatch } from 'react-redux'
import apiConfig from 'apiConfig'
import axios from 'utils/axios'
import { useParams } from "react-router-dom";
import { formatNumber } from "utils/formatNumber";

const useStyles = makeStyles(theme => ({
    content: {
        display: '-webkit-box',
        width: '100%',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        color: theme.palette.white,
        padding: '3px',
        lineHeight: '22px',
        paddingRight: '40px',
        '-webkit-line-clamp': 2,
        '-webkit-box-orient': 'vertical',
        maxHeight: '50px',
        height: 'auto'

    },
    hiddenContent: {
        display: 'inline-block',
        whiteSpace: 'nowrap',
    },
    resize: {
        position: 'absolute',
        top: 0,
        right: 0,
        width: '3px',
        height: '100%',
        backgroundColor: '#000000',
        cursor: 'e-resize'
    },
    statusJob: {
        position: 'absolute',
        color: 'white',
        right: '25px',
        top: '0px', height: '100%',
        '& svg': {
            position: 'absolute',
            margin: 'auto',
            top: '0',
            bottom: '0',
            left: '-24px',
            right: '0'
        }
    },
    tooltipBox: {
        position: 'relative'
    },
    tooltip2: {
        display: 'flex',
        position: 'relative',
        justifyContent: 'center',
        alignItems: 'center',
        '&::before': {
            border: 'solid',
            borderColor: '#444 transparent',
            borderWidth: '0px 6px 12px 6px',
            content: `""`,
            left: '48%',
            bottom: '-10px',
            position: 'absolute',
            zIndex: '2',
        },
        '&::after': {
            fontSize: '14px',
            display: 'flex',
            content: 'attr(miles)',
            background: 'rgba(0,0,0,0.7)',
            color: '#fff',
            margin: '0 auto',
            padding: '5px',
            zIndex: '2',
            position: 'absolute',
            justifyContent: 'center',
            left: 0,
            right: 0,
            width: 'max-content',
            borderRadius: '4px',
            bottom: '-37px'
        }
    },
    jobSplitNum: {
        position: 'absolute',
        top: 0,
        right: 0
    },
    jobStyle: {
        position: 'absolute',
        overflow: 'hidden',
        borderRadius: '15px',
        padding: '0 8px',
        border: '1px solid #ffffff',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        minHeight: '30px',
        position: 'relative',
        zIndex: 1
    },
    jobIndex: {
        zIndex: 0
    },
    topJobIndex: {
        zIndex: 2
    }
}));

const customThemeTooltip = createTheme({
    overrides: {
        MuiTooltip: {
            tooltip: {
                fontSize: '14px',
                maxWidth: 'none'
            }
        }
    }
})

let mounted = false;
const ResizableBox = props => {
    const { date } = useParams();
    const classes = useStyles();
    const content = useRef();
    const resizeRef = useRef();
    const dispatch = useDispatch();

    const { routes, routeId, job, isOver, hourOver, style, startTime, canDrag, topJobId, changeTopJob } = props;

    const { isDragging } = useSelector(state => state.JobsReducer);
    const { showDistance } = useSelector(state => state.RoutesReducer);

    const [isStatus, setStatus] = useState({ failed: false, msg: '' });
    const [openSnackbar, setOpenSnackbar] = useState(false);
    const [width, setWidth] = useState(0);
    const [isDrag, setIsDrag] = useState(true);
    const [currentJob, setCurrentJob] = useState(job);
    const [isCommercial, setIsCommercial] = useState(false);

    const [{ status, type }, drag] = useDrag({
        item: { id: job && job.jobId || '', hours: job && job.estimatedHours || 1, type: 'Job', oldRouteId: routeId },
        canDrag: canDrag,
        collect: (monitor) => ({
            type: monitor.isDragging() ? 'to-be-scheduled' : '',
            status: monitor.isDragging() ? true : false
        })
    });

    const handleOnDrag = event => {
        if (window.innerHeight - event.clientY <= 50) {
            const routeContainer = document.getElementById('route-scroll');
            routeContainer.scrollTop += 15
        }
    }

    useEffect(() => {
        if (!canDrag) return;
        const resizeEle = resizeRef.current;
        let curX;
        const mousedown = e => {
            window.addEventListener('mousemove', mousemove);
            window.addEventListener('mouseup', mouseup);
            curX = e.clientX;
            setIsDrag(false);
        }
        const mousemove = e => {
            const newX = e.clientX - curX;
            setWidth(width + newX);
        }
        const mouseup = e => {
            window.removeEventListener('mousemove', mousemove);
            setIsDrag(true);
        }

        resizeEle.addEventListener('mousedown', mousedown);
        resizeEle.addEventListener('mouseup', mouseup);

        return () => {
            resizeEle.removeEventListener('mousedown', mousedown);
            resizeEle.removeEventListener('mouseup', mouseup);
        }
    }, [width])

    useEffect(() => {
        mounted = true;
        mounted && dispatch({ type: 'CHANGE_STATUS_JOB', status: status })
        return () => mounted = false;
    }, [status]);
    useEffect(() => {
        mounted = true;
        dispatch({ type: 'DRAG_ITEM', onDragType: type })
        return () => mounted = false;
    }, [type]);

    // change estimatedHours after resize job's hour
    useEffect(() => {
        mounted = true;
        if (isDrag && width) {
            const range = width % 50;
            const hrs = range < 25 ? (width - range) / 100 : (width + (50 - range)) / 100;
            dispatch({ type: 'IS_UPDATE', status: true });


            const route = routes.find(route => route.id === routeId);
            const job = { ...currentJob };

            // const job = { ...job };

            const localEndDate = new Date((new Date(date)).toISOString().replace("Z", ""));
            const hour = startTime + (hrs);
            localEndDate.setHours((hour) - Math.floor(hour) > 0.5 ? Math.floor(hour) + 1 : Math.floor(hour));
            localEndDate.setMinutes((hour) - Math.floor(hour) > 0 && (hour) - Math.floor(hour) <= 0.5 ? 30 : 0);
            const jobEnd = new Date(localEndDate).toISOString();
            job.jobEnd = jobEnd;
            job.estimatedHours = hrs;
            job.job.estimatedHours = route.routeEmployees.length > 1 ? job.job.estimatedHours : hrs;

            const routeJob = route.routeJobs.filter(job => job.id !== currentJob.id)
            const newRouteData = {
                ...route,
                routeEquipment: !route.routeEquipment ? [] : [...route.routeEquipment],
                routeEmployees: !route.routeEmployees ? [] : [...route.routeEmployees],
                routeJobs: route.routeJobs ? [...routeJob, job] : [job],
            }
            axios.put(apiConfig.url.BASE_URL + apiConfig.url.ROUTE + `${date}/` + routeId, newRouteData)
                .then(() => {
                    if (mounted) {
                        setWidth(hrs * 100);
                        dispatch({ type: 'CHANGE_JOB_HOURS', oldRouteId: routeId, routeId, job });
                    }
                })
                .catch(() => {
                    if (mounted) {
                        setWidth(job.estimatedHours * 100);
                        setStatus({ failed: true, msg: 'Update failed, please try again later.' });
                        setOpenSnackbar(true)
                    }
                })
                .finally(() => mounted && dispatch({ type: 'IS_UPDATE', status: false }))
        }

        return () => mounted = false;
    }, [isDrag])

    // set width of the job after render
    useEffect(() => {
        mounted = true;
        if (job && mounted) {
            setCurrentJob(job);

            const route = routes.find(route => route.id === routeId);
            let hourDiff = 0;
            if (route.routeEmployees.length > 1) {
                hourDiff = moment.duration(moment(job.jobEnd).diff(moment(job.jobStart))).asHours();
            }
            const hours = route.routeEmployees.length > 1 ? hourDiff : (job.job && job.job.estimatedHours);

            setWidth(hours * 100);
            setIsCommercial(route.isCommercial)
        }
        return () => mounted = false;
    }, [job, routes])

    return (
        <>
            <MuiThemeProvider theme={customThemeTooltip}>
                <div style={{ position: 'absolute' }}>
                    <div miles={job ? (isNaN(job.distance) ? `0 Miles` : `${formatNumber(job.distance, 1)} Miles`) : ''}
                        className={showDistance && job ? classes.tooltip2 : null}>
                        <div onDrag={handleOnDrag} onClick={changeTopJob}
                            className={`${classes.jobStyle} ${isDragging ? classes.jobIndex : (job && job.id === topJobId ? classes.topJobIndex : '')}`}
                            ref={job ? (isDrag ? drag : null) : null}
                            style={{
                                ...style,
                                display: job ? (status ? 'none' : 'block') : (isOver ? 'block' : 'none'),
                                width: job ? `${width}px` : `${hourOver * 100}px`,
                                backgroundColor: isOver ? '#a5a5a5' : (isCommercial ? '#8a2be2' : '#4caf50'),
                                cursor: canDrag ? 'pointer' : 'not-allowed'
                            }}
                        >
                            <Tooltip
                                arrow
                                title={status ? '' :
                                    <div className={classes.tooltipBox}>
                                        <Typography className={classes.jobSplitNum}>
                                            {job && job.job && job.job.totalSplitJobs > 1 ? `1/${job.job.totalSplitJobs}` : ''}
                                        </Typography>
                                        <Typography color="inherit">
                                            {`${job && job.job && job.job.city || ''}, ${(job && job.job && `$${job.job.price.toFixed(2)}`) || ''}`}
                                        </Typography>
                                        <Typography>
                                            <em>Start time: </em> {job ? moment(job.jobStart).format('LT') : ''}
                                        </Typography>
                                        <Typography>
                                            <em>Hours: </em> {job && job.job ? Math.round(job.job.estimatedHours * 100) / 100 : ''}
                                        </Typography>
                                        <Typography>
                                            <em>Customer: </em> {(job && job.job) ? job.job.customerName : ''}
                                        </Typography>
                                        <Typography>
                                            <em>Equipment: </em> {(job && job.job && job.job.equipment) ? job.job.equipment.replace(";", ", ") : ''}
                                        </Typography>
                                        <Typography>
                                            <em>Description: </em> {job ? job.job && job.job.description : ''}
                                        </Typography>
                                        <Typography>
                                            <span>
                                                {
                                                    job && job.routeJobStatus === 2 && (
                                                        <>
                                                            <i className="far fa-walking" /> <span>On the way</span>
                                                        </>
                                                    )
                                                }
                                                {
                                                    job && job.routeJobStatus === 1 && (
                                                        <>
                                                            <i className="far fa-house-return" /> <span>Job Started</span>
                                                        </>
                                                    )
                                                }
                                                {
                                                    job && job.routeJobStatus === 3 && (
                                                        <>
                                                            <i className="far fa-house-leave" /><span>Job completed</span>
                                                        </>
                                                    )
                                                }
                                            </span>
                                        </Typography>
                                    </div>
                                }>
                                <span ref={content} className={classes.content}>
                                    {props.children}
                                </span>
                            </Tooltip>
                            <span className={classes.statusJob}>
                                {
                                    job && job.routeJobStatus === 2 &&
                                    <i className="far fa-walking" />
                                }
                                {
                                    job && job.routeJobStatus === 1 &&
                                    <i className="far fa-house-return" />
                                }
                                {
                                    job && job.routeJobStatus === 3 &&
                                    <i className="far fa-house-leave" />
                                }

                            </span>
                            <Tooltip arrow title={'View'}>
                                <a
                                    style={{
                                        position: 'absolute',
                                        color: 'white',
                                        right: '10px',
                                        top: '0px',
                                        height: '100%'
                                    }}
                                    href={`/customers/${job && job.job ? encodeURIComponent(job.job.customerName) : ''}/${job && job.job ? job.job.customerId : ''}/jobs/${job && job.job ? job.job.jobId : ''}/information`}>
                                    <i style={{
                                        position: 'absolute',
                                        margin: 'auto',
                                        top: '0',
                                        bottom: '0',
                                        left: '-14px',
                                        right: '0'
                                    }} className="fas fa-external-link" />
                                </a>
                            </Tooltip>
                            {
                                canDrag &&
                                <span ref={resizeRef} className={classes.resize}></span>
                            }
                        </div>
                    </div>
                </div>
            </MuiThemeProvider>

            <Snackbar open={openSnackbar}
                onClose={() => setOpenSnackbar(false)}
                autoHideDuration={2000}
                anchorOrigin={{ vertical: 'top', horizontal: 'center' }}>
                <Alert elevation={6} variant='filled' severity={isStatus.failed ? 'error' : 'success'}>
                    <Typography color="inherit" variant="h6">
                        {isStatus.msg}
                    </Typography>
                </Alert>
            </Snackbar>
        </>
    )
}

ResizableBox.propTypes = {
    routeIndex: PropTypes.number,
    job: PropTypes.object,
    hours: PropTypes.number,
    isOver: PropTypes.bool,
    hourOver: PropTypes.number,
    style: PropTypes.object,
    topJobId: PropTypes.string,
    changeTopJob: PropTypes.func
}

ResizableBox.defaultProps = {
    style: {}
}

export default ResizableBox;
