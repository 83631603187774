import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { makeStyles } from '@material-ui/styles'
import {
    Modal, Card, CardHeader, CardContent, Divider,
    Typography, Button,
    colors, CardActions, TextField, CircularProgress
} from '@material-ui/core'
import Snackbar from '@material-ui/core/Snackbar';
import Alert from '@material-ui/lab/Alert';
import apiConfig from 'apiConfig'
import axios from 'utils/axios'
import { useDispatch } from 'react-redux'

const useStyles = makeStyles(theme => ({
    styleModal: {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        outline: 'none',
        boxShadow: theme.shadows[20],
        width: 700,
        maxHeight: '90%',
        overflowY: 'auto',
        maxWidth: '100%',
        [theme.breakpoints.down('sm')]: {
            width: 500
        },
    },
    button: {
        color: theme.palette.white,
        backgroundColor: colors.green[600],
        '&:hover': {
            backgroundColor: colors.green[900]
        },
    },
    actions: {
        justifyContent: 'flex-end',
        paddingRight: 16
    },
}));

const RouteNoteModal = ({ open, onClose, router }) => {

    const classes = useStyles();
    const dispatch = useDispatch();

    const [routeNote, setRouteNote] = useState('');
    const [isSaving, setIsSaving] = useState(false);
    const [openSnackbar, setOpenSnackBar] = useState(false);
    const [isStatus, setIsStatus] = useState({ failed: false, msg: '' });

    const onCloseModal = () => {
        setRouteNote(router.routeNote);
        onClose();
    }

    const handleUpdateRouteNote = () => {
        setIsSaving(true);
        axios.put(apiConfig.url.BASE_URL + apiConfig.url.ROUTE_NOTE.replace('{routeId}', router.id), { routeNote })
            .then(res => {
                setIsStatus({ failed: false, msg: 'Route Note Updated' });
                setOpenSnackBar(true);
                dispatch({ type: 'UPDATE_NOTE', routeId: router.id, routeNote })
                onClose();
            })
            .catch(() => {
                setIsStatus({ failed: true, msg: 'Update route note failed, please try again later.' });
                setOpenSnackBar(true);
                onCloseModal();
            })
            .finally(() => setIsSaving(false))
    }

    useEffect(() => {
        if (router) {
            setRouteNote(router.routeNote)
        }
    }, [router])

    return (
        <div>
            <Modal open={open} onClose={onCloseModal}>
                <Card className={classes.styleModal}>
                    <CardHeader title="Route Note" />
                    <Divider />
                    <CardContent>
                        <TextField
                            fullWidth
                            variant='outlined'
                            label='Route Note'
                            rows={10}
                            multiline
                            value={routeNote}
                            onChange={event => setRouteNote(event.target.value)}
                        />
                    </CardContent>
                    <Divider />
                    <CardActions className={classes.actions}>
                        <Button onClick={onCloseModal} variant="contained">Close</Button>
                        <Button style={{ marginLeft: 10 }}
                            disabled={isSaving}
                            className={classes.button}
                            variant="contained"
                            color="primary"
                            onClick={handleUpdateRouteNote}>
                            Save
                        </Button>
                        {isSaving && <CircularProgress size={24} />}
                    </CardActions>
                </Card>
            </Modal>

            <Snackbar anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
                open={openSnackbar}
                autoHideDuration={5000}
                onClose={() => setOpenSnackBar(false)}>
                <Alert elevation={6} variant="filled" severity={isStatus.failed ? 'error' : 'success'}>
                    <Typography
                        color="inherit"
                        variant="h6">
                        {isStatus.msg}
                    </Typography>
                </Alert>
            </Snackbar>
        </div>
    )
}

RouteNoteModal.propTypes = {
    open: PropTypes.bool,
    onClose: PropTypes.func,
    router: PropTypes.object
}

export default RouteNoteModal;