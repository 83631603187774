import React, { useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import RoomIcon from '@material-ui/icons/Room';
import { makeStyles } from '@material-ui/styles';
import {
  AzureMap,
  AzureMapDataSourceProvider,
  AzureMapHtmlMarker
} from 'react-azure-maps';
import { data, getImageTemplate, ControlPosition } from 'azure-maps-control';

import axios from 'utils/axios';
import apiConfig from 'apiConfig';
import { GeolocationControl, ScaleBarControl } from './MapControls';
import { DefaultAzureMapControls, DefaultAzureMapOptions, RouteColors } from '../../Constants';

const useStyles = makeStyles(theme => ({
  map: {
    width: '100%',
    height: '100%'
  }
}));

const customControls = [
  {
    control: new GeolocationControl({
      style: 'auto'
    }),
    controlOptions: {
      position: ControlPosition.TopRight
    }
  },
  {
    control: new ScaleBarControl({
      units: 'imperial',
      maxBarLength: 120
    }),
    controlOptions: {
      position: ControlPosition.BottomRight
    }
  }
];

const onClick = (e) => {
  console.log('You click on: ', e);
};

function azureHtmlMapMarkerOptions({ latitude, longitude, routeNumber, jobNumber }) {
  const position = new data.Position(longitude, latitude);
  return {
    position,
    text: jobNumber,
    color: RouteColors[routeNumber]
  };
}

function routeResourceMarkerOptions({ latitude, longitude }) {
  const position = new data.Position(longitude, latitude);
  return { position };
}

const eventToMarker = [{ eventName: 'click', callback: onClick }];

function renderHTMLPoint(coordinate) {
  const rendId = Math.random();
  return (
    <AzureMapHtmlMarker
      key={rendId}
      options={{ ...azureHtmlMapMarkerOptions(coordinate) }}
      events={eventToMarker}
    />
  );
}

function renderRouteResourcePoint(coordinate) {
  const rendId = Math.random();
  return (
    <AzureMapHtmlMarker
      key={`RouteResource-${rendId}`}
      options={{ ...routeResourceMarkerOptions(coordinate) }}
      markerContent={<div style={{ display: 'flex' }}><RoomIcon style={{ fontSize: 24, marginBottom: -2 }} /></div>}
      events={eventToMarker}
    />
  );
}

const MapComponent = ({ coordinates }) => {
  const classes = useStyles();

  const [azureMapOptions, setAzureMapOptions] = useState(DefaultAzureMapOptions);
  const { locateOnRouteCoord } = useSelector(state => state.RoutesReducer);
  const { routeJobs } = useSelector(state => state.JobsReducer);

  const memoizedHtmlMarkerRender = useMemo(
    () => coordinates.filter(x => x.latitude != null && x.longitude != null).map(renderHTMLPoint),
    [coordinates]
  );

  const routeResourcesMarkerRender = useMemo(
    () => routeJobs?.filter(x => x.latitude != null && x.longitude != null).map(renderRouteResourcePoint) ?? [],
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [routeJobs, coordinates]
  );

  useEffect(() => {
    const subscriptionKey = sessionStorage.getItem('AZURE_MAPS_SUBSCRIPTION_KEY');
    if (subscriptionKey == null) {
      Promise.all([
        axios.get(apiConfig.url.BASE_URL + apiConfig.url.AZURE_MAPS_SUBSCRIPTION_KEY),
        axios.get(apiConfig.url.BASE_URL + apiConfig.url.COMPANY_GET_ADDRESS)
      ]).then(([res1, res2]) => {
        if (res1 && res1.data) {
          sessionStorage.setItem('AZURE_MAPS_SUBSCRIPTION_KEY', res1.data);
          const options = { ...azureMapOptions };
          options.authOptions.subscriptionKey = res1.data;
          if (res2?.data) {
            options.center = [res2.data.longitude, res2.data.latitude];
            options.zoom = 10;
          }
          setAzureMapOptions(options);
        }
      });
    } else {
      axios.get(apiConfig.url.BASE_URL + apiConfig.url.COMPANY_GET_ADDRESS).then(res => {
        const options = { ...azureMapOptions };
        options.authOptions.subscriptionKey = subscriptionKey;
        if (res?.data) {
          options.center = [res.data.longitude, res.data.latitude];
          options.zoom = 10;
        }
        setAzureMapOptions(options);
      });
    }
  }, []);

  return (
    <div className={classes.map}>
      {
        azureMapOptions.authOptions.subscriptionKey ? (
          <AzureMap
            options={azureMapOptions}
            controls={DefaultAzureMapControls}
            customControls={customControls}
          >
            <AzureMapDataSourceProvider
              events={{
                dataadded: (e) => {
                  console.log('Data on source added', e.constructor);
                },
              }}
              id="AzureMapDataSourceProvider"
              options={{ cluster: true, clusterRadius: 2 }}
            >
              {memoizedHtmlMarkerRender}
              {routeResourcesMarkerRender}
              {locateOnRouteCoord && <AzureMapHtmlMarker
                key={`LocateOnRoute-${Math.random()}`}
                options={{
                  position: new data.Position(locateOnRouteCoord.longitude, locateOnRouteCoord.latitude),
                  color: RouteColors[0]
                }}
                markerContent={<div style={{ display: 'flex' }} dangerouslySetInnerHTML={{ __html: getImageTemplate('pin') }} />}
                events={eventToMarker}
              />}
            </AzureMapDataSourceProvider>
          </AzureMap>
        ) : <span>Loading ...</span>
      }
    </div>
  );
};

export default MapComponent;
