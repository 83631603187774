import React, { useState, useEffect, useRef } from 'react'
import {
    Card, CardHeader, CardContent, Divider, Typography, Tooltip,
    TableContainer, Table, TableHead, TableBody, colors,
    TableRow, TableCell, IconButton, FormControlLabel, Grid,
    TextField, Collapse, Button, CircularProgress, CardActions
} from '@material-ui/core'
import {ArrowDownward, ArrowUpward, KeyboardArrowDown, KeyboardArrowUp} from '@material-ui/icons'
import { DatePicker } from '@material-ui/pickers'
import moment from 'moment'
import { createTheme, MuiThemeProvider } from '@material-ui/core/styles'
import AddBoxIcon from '@material-ui/icons/AddBox'
import { GreenCheckbox, ConfirmModal } from 'components'
import { makeStyles } from '@material-ui/styles'
import useMediaQuery from '@material-ui/core/useMediaQuery'
import axios from "utils/axios";
import apiConfig from "apiConfig";
import CommissionLine from "./CommissionLine";
import * as Yup from "yup";
import Snackbar from "@material-ui/core/Snackbar";
import Alert from "@material-ui/lab/Alert";
import clsx from 'clsx'

const useStyles = makeStyles(theme => ({
    headCell: {
        borderBottom: '1px solid #888888',
        fontSize: '16px',
        padding: '8px 16px',
        '& span': {
            display: 'flex',
            alignItems: 'center'
        }
    },
    headerActionCell: {
        [theme.breakpoints.down('sm')]: {
            width: '0 !important',
            minWidth: '0 !important',
            '& > *': {
                display: 'none !important'
            }
        }
    },
    typo: {
        fontSize: '16px',
        fontWeight: 500,
        marginRight: '24px'
    },
    add: {
        padding: '0',
        '& span svg': {
            fontSize: '40px',
            color: colors.green[600],
            '&:hover': {
                color: colors.green[900]
            }
        }
    },
    removeBtn: {
        '& span svg': {
            fontSize: '32px'
        }
    },
    commissionHead: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        padding: '8px 16px'
    },
    button: {
        color: theme.palette.white,
        backgroundColor: colors.green[600],
        '&:hover': {
            backgroundColor: colors.green[900]
        }
    },
    btn: {
        color: theme.palette.white,
        backgroundColor: colors.green[600],
        '&:hover': {
            backgroundColor: colors.green[900]
        }
    },
    hidePicker: {
        display: 'none'
    },
    dateSelected: {
        display: 'flex',
        alignItems: 'center'
    },
    splitOptions: {
        display: 'flex',
        justifyContent: 'flex-start',
        alignItems: 'center',
        marginBottom: '8px'
    },
    checkbox: {
        margin: 0
    },
    btnDis: {
        whiteSpace: 'nowrap',
        color: 'rgba(0, 0, 0, 0.26)',
        backgroundColor: 'rgb(217 217 217)',
        pointerEvents: 'none'
    },
    customTableCell: {
        borderBottom: '1px solid #969696',
        cursor: 'pointer'
    },
    iconSort: {
        marginBottom: '-5px',
        marginLeft: '10px'
    },
    actionsSm: {
        display: 'none',
        marginTop: -16,
        marginBottom: 0,
        [theme.breakpoints.down('sm')]: {
            display: 'flex',
            justifyContent: 'end',
            alignItems: 'center',
            flexWrap: 'wrap'
        }
    }
}));

const customThemeTooltip = createTheme({
    overrides: {
        MuiTooltip: {
            tooltip: {
                fontSize: '14px',
                maxWidth: 'none'
            }
        }
    }
});

const validateRowChema = Yup.object().shape({
    employeeId: Yup.string().required("Please select employee"),
})

const validateSchema = Yup.object().shape({
    prices: Yup.array()
        .of(
            validateRowChema
        )
});
let pageNumber = 1;
let forcePage = 0;
const Commission = props => {
    const { id, jobId, employees, tip, tipDate, disabled, employeesAll } = props;
    const isMounted = useRef(false);
    const classes = useStyles();
    const ipad = useMediaQuery('(max-width: 769px)');
    const ipPlus = useMediaQuery('(max-width: 415px)');

    const [openConfirm, setOpenConfirm] = useState(false);
    const [commissions, setCommissions] = useState([]);
    const [commissionsFilter, setCommissionsFilter] = useState([]);
    const [splitId, setSplitId] = useState(null);
    const [openPicker, setOpenPicker] = useState(false);
    const [index, setIndex] = useState();

    const [even, setEven] = useState(true);
    const [manually, setManually] = useState(true);
    const [expanded, setExpanded] = useState(true);
    const [loading, setLoading] = useState(false);
    const [errorItem, setErrorItem] = useState([]);
    const [isStatus, setIsStatus] = useState({ failed: false, msg: '' });
    const [openSnackbar, setOpenSnackBar] = useState(false);
    const [touchItem, setTouchItem] = useState([]);
    const [listSplitDate, setListSplitDate] = useState([]);
    const [scheduledDate, setScheduledDate] = useState();
    const [enableSaveAll, setEnableSaveAll] = useState(false);
    const [saveItems, setSaveItems] = useState(false);
    const [locateItem, setLocateItem] = useState({});
    const [description, setDescription] = useState("");
    const [typeSaving, setTypeSaving] = useState(false);
    const [lstSplitType, setLstSplitType] = useState([]);
    const [paySplitType, setPaySplitType] = useState("");
    const [deleting, setDeleting] = useState(false);
    const [errors, setErrors] = useState(false);
    const [commissionsShorted, setCommissionsShorted] = useState([]);
    const [sortBy, setSortBy] = useState('Person');
    const [sortType, setSortType] = useState('ASC');

    const loadDocument = (type) => {
        setSortBy(type)
        setSortType(type == sortBy ? (sortType === 'ASC' ? 'DESC' : 'ASC') : 'ASC');
    }

    const getSortIcon = (type) => {
        return (
            sortBy == type &&
            (
                sortType == 'ASC' ?
                    <ArrowUpward className={classes.iconSort}/>
                    :
                    <ArrowDownward className={classes.iconSort}/>
            )
        )
    }

    const onChangeCommission = (event, index) => {
        const { name, value } = event.target;
        const _commissionsFilter = [...commissionsShorted];
        if (event.target.type === 'checkbox') {
            _commissionsFilter[index] = { ..._commissionsFilter[index], [name]: event.target.checked, change: true }
            if (name === 'allocateLaborCommision') {
                _commissionsFilter[index].amountDueToPerson = event.target.checked
                    ? (_commissionsFilter[index].amountAllocated * _commissionsFilter[index].commissionPercent / 100).toFixed(2)
                    : 0;
            }
            setCommissionsShorted(_commissionsFilter);
        } else {
            if (name === 'employeeId' && (paySplitType == '1' || paySplitType == '2')) {
                setLoading(true)
                axios.get(apiConfig.url.USER_URL + apiConfig.url.EMPLOYEE_COMPENSATION_HISTORY + value)
                    .then(res => {
                        let active = null;
                        if (res.data.salaries) {
                            active = res.data.salaries.find(s => (s.startDate && moment(s.startDate) <= moment()) &&
                                (!s.endDate || moment(s.endDate) >= moment()));
                        }
                        _commissionsFilter[index] = {
                            ..._commissionsFilter[index],
                            [name]: value,
                            allocateLaborCommision: !!active && !!active.laborPercent,
                            hoursOnSite: active ? (active.Hourly || 0) : 0,
                            commissionPercent: active ? active.formattedLaborPercent : '',
                            change: true
                        }
                        setCommissionsShorted(_commissionsFilter);
                    })
                    .finally(e => setLoading(false))
            } else if (name === 'amountAllocated' || name === 'commissionPercent') {
                _commissionsFilter[index] = { ..._commissionsFilter[index], [name]: value, change: true }
                const amountAllocated = _commissionsFilter[index].amountAllocated ? _commissionsFilter[index].amountAllocated.toString().replace(/[\$,]/g, '') : 0;
                const commissionPercent = _commissionsFilter[index].commissionPercent ? _commissionsFilter[index].commissionPercent.toString().replace(/[%,]/g, '') : 0;
                _commissionsFilter[index].amountDueToPerson = (amountAllocated * commissionPercent / 100).toFixed(2);
                setCommissionsShorted(_commissionsFilter);
            } else {
                _commissionsFilter[index] = { ..._commissionsFilter[index], [name]: value, change: true }
                setCommissionsShorted(_commissionsFilter);
            }
        }
    }

    const addCommission = () => {
        
        setCommissionsShorted([...commissionsShorted, {
            id: "",
            employeeId: "",
            jobSplitId: splitId,
            allocateLaborCommision: false,
            hoursOnSite: 0,
            amountAllocated: 0,
            commissionPercent: 0,
            amountDueToPerson: 0,
            tip: 0,
            isScheduled: false
        }]);
    }

    const onBlueItem = (key, index) => {
        // const touched = {...touchItem};
        setTouchItem((prevTouched) => {
            const prev = { ...prevTouched }
            prev[`prices[${index}].${key}`] = true;
            return { ...prev }
        });
    }

    const validateItems = (api, index, submit = false) => {
        validateSchema.validate({ prices: commissions }, { abortEarly: false }).then(() => {
            setErrorItem({});
        })
            .catch(function (err) {
                let listError = {};
                let listTouched = {};
                err.inner.forEach((e, index) => {
                    listTouched[e.path] = true;
                    listError[e.path] = e.message;
                });
                setTouchItem(listTouched);
                setErrorItem(listError)
            });
    }

    const saveAllItemPrice = () => {
        validateSchema.validate({ prices: commissionsShorted }, { abortEarly: false }).then(() => {
            setErrorItem((pre) => {
                pre = [];
                return [...pre];
            });
            //save item
            setSaveItems(true);
            const _commissions = [...commissionsShorted];
            const data = _commissions.map(item => {
                return {
                    ...item,
                    id: item.id ? item.id : "00000000-0000-0000-0000-000000000000",
                    employeeId: item.employeeId ? item.employeeId : employees[0].id,
                    jobSplitId: splitId,
                    hoursOnSite: item.hoursOnSite ? item.hoursOnSite.toString().replace(/[\$,]/g, '') : 0,
                    amountAllocated: item.amountAllocated ? item.amountAllocated.toString().replace(/[\$,]/g, '') : 0,
                    commissionPercent: item.commissionPercent ? item.commissionPercent.toString().replace(/[%,]/g, '') : 0,
                    amountDueToPerson: item.amountDueToPerson ? item.amountDueToPerson.toString().replace(/[\$,]/g, '') : 0,
                    tip: item.tip ? item.tip.toString().replace(/[\$,]/g, '') : 0,
                }
            })
            axios.put(apiConfig.url.BASE_URL + apiConfig.url.CUSTOMER_JOB_COMMISSION_LABOR_ALL, data, { params: { jobSplitId: splitId } })
                .then(res => {
                    setIsStatus({ failed: false, msg: 'All Records Saved' });
                    setOpenSnackBar(true);
                    setEnableSaveAll(false);
                    setCommissionsFilter((_price) => {
                        const price = res.data.map(item => {
                            return {
                                ...item, change: false, saving: false
                            }
                        })
                        return price
                    })
                })
                .catch(err => {
                    setIsStatus({ failed: true, msg: 'An error occurred, please try again later' });
                    setOpenSnackBar(true);
                })
                .finally(e => {
                    setSaveItems(false);
                });
            // end save
        })
            .catch(function (err) {
                let listError = [];
                let listTouched = [];
                err.inner.forEach((e, i) => {
                    listTouched[`${e.path}`] = true;
                    listError[`${e.path}`] = e.message;
                });
                setTouchItem(listTouched);
                setErrorItem(listError);
            });
    }

    const onSaveCustomerPayment = (item, index, submit = false) => {
        validateRowChema.validate(item, { abortEarly: false }).then(() => {
            setErrorItem((pre) => {
                pre = [];
                return [...pre];
            });
            if (submit) {
                setSaveItems(true);
                setCommissionsShorted((_price) => {
                    _price[index] = { ..._price[index], saving: true };
                    return [..._price]
                })

                const data = {
                    id: item.id ? item.id : "00000000-0000-0000-0000-000000000000",
                    employeeId: item.employeeId ? item.employeeId : employees[0].id,
                    jobSplitId: splitId,
                    allocateLaborCommision: item.allocateLaborCommision,
                    hoursOnSite: item.hoursOnSite ? item.hoursOnSite.toString().replace(/[\$,]/g, '') : 0,
                    amountAllocated: item.amountAllocated ? item.amountAllocated.toString().replace(/[\$,]/g, '') : 0,
                    commissionPercent: item.commissionPercent ? item.commissionPercent.toString().replace(/[%,]/g, '') : 0,
                    amountDueToPerson: item.amountDueToPerson ? item.amountDueToPerson.toString().replace(/[\$,]/g, '') : 0,
                    tip: item.tip ? item.tip.toString().replace(/[\$,]/g, '') : 0,
                    isScheduled: item.isScheduled,
                    laborPercent: item.laborPercent,
                };
                if (item.id) {
                    axios.put(apiConfig.url.BASE_URL + apiConfig.url.CUSTOMER_JOB_COMMISSION_LABOR.replace('{jobId}', jobId), data)
                        .then(res => {
                            setIsStatus({ failed: false, msg: 'Record Saved' });
                            setOpenSnackBar(true);
                            item.change = false
                            setCommissionsShorted((_price) => {
                                _price[index] = { ...res.data, change: false, saving: false };
                                return [..._price]
                            })
                        })
                        .catch(err => {
                            setIsStatus({ failed: true, msg: 'An error occurred, please try again later' });
                            setOpenSnackBar(true);
                        })
                        .finally(e => {
                            setSaveItems(false);
                            // setCommissionsFilter((_price) => {
                            //     _price[index] = { ..._price[index], change: false, saving: false };
                            //     return [..._price]
                            // })
                        });
                } else {
                    axios.post(apiConfig.url.BASE_URL + apiConfig.url.CUSTOMER_JOB_COMMISSION_LABOR, data)
                        .then(res => {
                            setIsStatus({ failed: false, msg: 'Record Saved' });
                            setOpenSnackBar(true);
                            item.change = false
                            setCommissionsShorted((_price) => {
                                _price[index] = { ...res.data, change: false, saving: false };
                                return [..._price]
                            })
                            // setReloadSource(!reloadSource)
                        })
                        .catch(err => {
                            setIsStatus({ failed: true, msg: 'An error occurred, please try again later' });
                            setOpenSnackBar(true);
                        })
                        .finally(e => {
                            setSaveItems(false);
                            // setCommissionsFilter((_price) => {
                            //     _price[index] = { ..._price[index], change: false, saving: false };
                            //     return [..._price]
                            // })
                        });
                }
            }
        })
            .catch(function (err) {
                let listError = [];
                let listTouched = [];
                err.inner.forEach((e, i) => {
                    listTouched[`prices[${index}].${e.path}`] = true;
                    listError[`prices[${index}].${e.path}`] = e.message;
                });
                setTouchItem(listTouched);
                setErrorItem(listError);
            });
    }
    const removeCommission = () => {
        if (locateItem.id) {
            setDeleting(true)
            axios.delete(apiConfig.url.BASE_URL + apiConfig.url.CUSTOMER_JOB_COMMISSION_LABOR + '?id=' + locateItem.id)
                .then(value => {
                    const coms = [...commissionsShorted];
                    coms.splice(locateItem.index, 1);
                    setCommissionsFilter(coms);
                    setOpenConfirm(false);
                    setIsStatus({ failed: false, msg: 'Record Removed' });
                    setOpenSnackBar(true);
                }).finally(() => setDeleting(false))
        } else {
            const coms = [...commissionsShorted];
            coms.splice(locateItem.index, 1);
            setCommissionsFilter(coms);
            setOpenConfirm(false);
            setIsStatus({ failed: false, msg: 'Record Removed' });
            setOpenSnackBar(true);
        }
    }

    const onHandleChangeDate = date => {
        setSplitId(new Date(date).toISOString())
    }

    // const previousDate = () => {
    //     const currDate = new Date(splitid);
    //     const prevDate = currDate.setDate(currDate.getDate() - 1);
    //     setSplitDate(moment(prevDate).format('MM/DD/YYYY'));
    // }
    //
    // const nextDate = () => {
    //     const currDate = new Date(splitDate);
    //     const nextDate = currDate.setDate(currDate.getDate() + 1);
    //     setSplitDate(moment(nextDate).format('MM/DD/YYYY'));
    // }

    const toggleExpanded = () => {
        setExpanded(!expanded);
    }

    const openConfirmDelete = (data) => {
        setOpenConfirm(data);
    }

    const getCustomerPayment = () => {
        setLoading(true);
        axios.get(apiConfig.url.BASE_URL + apiConfig.url.CUSTOMER_JOB_SPLIT.replace('{jobId}', jobId))
            .then(value => {
                setCommissions(value.data.jobSplitItems)

            }).finally(() => {
                setLoading(false);
            })
    }

    const getCommissionSale = (jobSplitId) => {
        setLoading(true);
        axios.get(apiConfig.url.BASE_URL + apiConfig.url.CUSTOMER_JOB_COMMISSION_LABOR_LIST, {
            params: {
                jobSplitId: jobSplitId,
                sortBy: sortBy,
                sortType: sortType,
            } }
            )
            .then(value => {
                setCommissionsFilter(value.data);
            }).finally(() => {
                setLoading(false);
            })
    }

    const onChangePayment = (event, index) => {
        const { name, value } = event.target;
        const coms = [...commissions];
        coms[index] = { ...coms[index], [name]: value, change: true }
        setCommissions(coms)
    }

    const showDesc = (desc) => {
        setDescription(desc);
    }

    const updateSplitType = (type) => {
        setTypeSaving(true);
        const data = {
            jobSplitId: splitId,
            commissionLaborSplitType: type
        }
        axios.put(apiConfig.url.BASE_URL + apiConfig.url.CUSTOMER_LABOR_SPLIT_TYPE, data)
            .then(value => {
                setPaySplitType(type);
                setIsStatus({ failed: false, msg: 'Update split type successfully!' });
                setOpenSnackBar(true);

                if (type == 1 || type == 2) {
                    const splitTotal = commissions?.find(x => x.id === splitId)?.total;
                    const totalHoursLogged = commissionsShorted?.reduce((hours, item) => hours += item.hoursOnSite ?? 0, 0);
                    if (type == 1 || (type == 2 && splitTotal && totalHoursLogged > 0)) {
                        const data = commissionsShorted.map(item => {
                            const mapped = {
                                ...item,
                                id: item.id ? item.id : "00000000-0000-0000-0000-000000000000",
                                employeeId: item.employeeId ? item.employeeId : employees[0].id,
                                jobSplitId: splitId,
                                amountAllocated: type == 1
                                    ? (splitTotal / commissionsShorted.length).toFixed(2)
                                    : item.hoursOnSite
                                        ? (item.hoursOnSite / totalHoursLogged * splitTotal).toFixed(2)
                                        : 0,
                                commissionPercent: item.commissionPercent ? item.commissionPercent.toString().replace(/[%,]/g, '') : 0,
                                tip: type == 1
                                    ? (tip / commissionsShorted.length).toFixed(2)
                                    : item.hoursOnSite
                                        ? (item.hoursOnSite / totalHoursLogged * tip).toFixed(2)
                                        : 0,
                            }
                            mapped.amountDueToPerson = mapped.allocateLaborCommision ? (mapped.amountAllocated * mapped.commissionPercent / 100).toFixed(2) : 0;
                            return mapped;
                        })
                        return axios.put(apiConfig.url.BASE_URL + apiConfig.url.CUSTOMER_JOB_COMMISSION_LABOR_ALL, data, { params: { jobSplitId: splitId } })
                            .then(res => {
                                setIsStatus({ failed: false, msg: 'All Records Saved' });
                                setOpenSnackBar(true);
                                setCommissionsFilter((_price) => {
                                    const price = res.data.map(item => {
                                        return {
                                            ...item, change: false, saving: false
                                        }
                                    })
                                    return price
                                })
                            })
                    }
                }
            })
            .catch(ex => {
                setIsStatus({ failed: true, msg: 'Update split type fail!' });
                setOpenSnackBar(true);
            })
            .finally(() => {
                setTypeSaving(false);
            })
    }
    const getCommissionSpaySplitType = () => {
        axios.get(apiConfig.url.BASE_URL + apiConfig.url.CUSTOMER_PAY_SPLIT_TYPE)
            .then(value => {
                setLstSplitType(value.data);
            }).finally(() => {
            })
    }

    useEffect(() => {
        getCommissionSale(splitId);
    }, [sortBy, sortType])

    useEffect(() => {
        const _commissions = commissions.map(com => {
            return {
                ...com,
                hoursOnSite: even || manually ? '' : com.hoursOnSite,
                amountAllocatedToPerson: even || !manually ? '' : com.amountAllocatedToPerson
            }
        });
        setCommissions(_commissions)
    }, [even, manually])

    useEffect(() => {
        if (isMounted.current) {
            setEnableSaveAll(false);
            if (splitId) {
                getCommissionSale(splitId);
            }
            //const commissionsFilter = splitDate ? commissions.filter(x => x.id === splitDate || !x.id) : commissions;
        }
        return () => isMounted.current = true;
    }, [splitId])

    useEffect(() => {
        if (commissionsFilter && employees.length > 0) {
            setEnableSaveAll(false);
            // let sorted = [...commissionsFilter];
            // for (let i = 0; i < commissionsFilter.length; i++) {
            //     let em = employees.find(x => x.id === commissionsFilter[i].employeeId);
            //     sorted[i] = {
            //         ...commissionsFilter[i],
            //         shorting: `${commissionsFilter[i].isScheduled ? '0' : '1'}${em ? em.firstName : ''}${em ? em.lastName : ''}`
            //     }
            // }
            // sorted = sorted.sort((a, b) => a.shorting.localeCompare(b.shorting));
            setCommissionsShorted(commissionsFilter);
        }
    }, [commissionsFilter, employees])
    useEffect(() => {
        if (commissionsShorted && commissionsShorted.length > 0) {
            setEnableSaveAll(false);
            for (let i = 0; i < commissionsShorted.length; i++) {
                if (commissionsShorted[i].change) {
                    setEnableSaveAll(true);
                    break;
                }
            }
            // console.log(commissionsShorted)
        }
    }, [commissionsShorted])

    useEffect(() => {
        if (isMounted.current) {
            setListSplitDate(commissions);
            if (commissions) {
                let primaryJob = commissions.find(x => x.isPrimaryJob);
                if (primaryJob) {
                    setPaySplitType(primaryJob && primaryJob.commissionLaborSplitType ? primaryJob.commissionLaborSplitType : 3)
                    setSplitId(primaryJob.id)
                    setScheduledDate(primaryJob.scheduledDate);
                }
            }
        }
        return () => isMounted.current = true;
    }, [commissions])

    useEffect(() => {
        getCommissionSpaySplitType();
        getCustomerPayment();
    }, [])
    return (
        <Card style={{ marginBottom: '16px' }}>
            <CardHeader style={{ cursor: 'pointer' }} title='Commission Labor Pay Assignment'
                action={expanded ? <KeyboardArrowUp /> :
                    <KeyboardArrowDown />}
                onClick={toggleExpanded}
            />
            <Divider />
            <Collapse in={expanded} timeout='auto' unmountOnExit>
                <CardContent>
                    <Grid container spacing={2} style={{ marginBottom: '8px' }}>
                        <Grid item xs={ipad ? 12 : 6} className={classes.dateSelected}>
                            <Typography style={{ whiteSpace: 'nowrap', fontSize: '16px', fontWeight: 500 }}>Split Date</Typography>
                            <TextField
                                style={{ paddingLeft: '16px' }}
                                fullWidth
                                name='person'
                                variant='outlined'
                                select
                                onChange={e => {
                                    const job = commissions.find(x => x.id == e.target.value);
                                    setPaySplitType(job && job.commissionLaborSplitType ? job.commissionLaborSplitType : 3)
                                    setSplitId(e.target.value)
                                }}
                                value={splitId || ''}
                                SelectProps={{ native: true }}>
                                {listSplitDate && listSplitDate.map((em, index) => (
                                    em.id && (<option key={index} value={em.id}>{em.scheduledDate ?
                                        (moment(em.scheduledDate).format('MM/DD/YYYY') + ' - ' + em.description + (em.total ? ` - $${em.total}` : '')) :
                                        ((em.description || '') + (em.total ? ` - $${em.total}` : ''))}</option>)
                                ))}
                            </TextField>
                        </Grid>
                        <Grid item xs={ipad ? 10 : 3}>

                        </Grid>
                        <Grid item xs={ipad ? 12 : 3}>
                            <CardActions style={{ justifyContent: 'flex-end', padding: 0 }}>
                                {
                                    lstSplitType && lstSplitType.length > 0 &&
                                    <TextField
                                        disabled={disabled}
                                        onClick={e => e.stopPropagation()}
                                        style={{ width: '250px' }}
                                        name='paysaplittype'
                                        variant='outlined'
                                        select
                                        label='Distribute amounts by'
                                        onChange={x => {
                                            updateSplitType(x.target.value)
                                        }}
                                        value={paySplitType || 3}
                                        SelectProps={{ native: true }}>
                                        {lstSplitType.map((em, index) => (
                                            <option key={index} value={em.id}>{em.description}</option>
                                        ))}
                                    </TextField>
                                }
                                {typeSaving && <CircularProgress size={24} />}
                            </CardActions>
                        </Grid>
                    </Grid>
                    <Grid container spacing={2} className={classes.actionsSm}>
                        <Grid item>
                            <Button
                                size={'small'}
                                className={(disabled || !enableSaveAll || (saveItems && !locateItem)) ? classes.btnDis : classes.button}
                                onClick={() => {
                                    setLocateItem(null)
                                    saveAllItemPrice()
                                }}
                                variant="contained">
                                Save All
                            </Button>
                            {saveItems && !locateItem && <CircularProgress size={24} />}
                        </Grid>
                        <Grid item>
                            <MuiThemeProvider theme={customThemeTooltip}>
                                <Tooltip title='Add Labor Payment' placement='bottom' arrow>
                                    <IconButton className={disabled ? classes.btnDis : classes.add} onClick={addCommission}
                                        style={disabled ? { backgroundColor: 'transparent' } : null}>
                                        <AddBoxIcon />
                                    </IconButton>
                                </Tooltip>
                            </MuiThemeProvider>
                        </Grid>
                    </Grid>
                    <TableContainer>
                        <Table style={{ minWidth: '1000px' }}>
                            <TableHead>
                                <TableRow>
                                    <TableCell onClick={() => loadDocument('Person')}
                                               style={{ minWidth: '200px', width: '20%' }} align='center' className={classes.headCell}>
                                        <span>Person{ getSortIcon('Person')}</span>
                                        </TableCell>
                                    <TableCell style={{ width: '1%', padding: 0 }} align='center' className={classes.headCell}></TableCell>
                                    <TableCell onClick={() => loadDocument('Allocate')}
                                               style={{ width: '1%', minWidth: '142px' }} align='center' className={classes.headCell}>
                                        <span>Allocate Labor Commission{ getSortIcon('Allocate')}</span>
                                        </TableCell>
                                    {/*<TableCell align='center' className={classes.headCell}>Exclude from Allocation</TableCell>*/}
                                    <TableCell onClick={() => loadDocument('Hours')}
                                               style={{ width: '1%', minWidth: '180px' }} align='center' className={classes.headCell}>
                                        <span>Hours On Site{ getSortIcon('Hours')}</span>
                                        </TableCell>
                                    <TableCell onClick={() => loadDocument('JobAmountAllocated')}
                                               style={{ width: '17%' }} align='center' className={classes.headCell}>
                                        <span>Job Amount Allocated to Person{ getSortIcon('JobAmountAllocated')}</span>
                                        </TableCell>
                                    <TableCell onClick={() => loadDocument('Commission')}
                                               style={{ width: '17%' }} align='center' className={classes.headCell}>
                                        <span>Commission %{ getSortIcon('Commission')}</span>
                                        </TableCell>
                                    <TableCell onClick={() => loadDocument('JobAmountDue')}
                                               style={{ width: '17%' }} align='center'
                                               className={classes.headCell}>
                                        <span>Job Amount Due to Person{ getSortIcon('JobAmountDue')}</span>
                                        </TableCell>
                                    <TableCell onClick={() => loadDocument('Tip')}  style={{ width: '1%', minWidth: '120px' }} align='center'
                                        className={classes.headCell}>
                                        <span>Tip ({(tipDate || scheduledDate) && moment(tipDate || scheduledDate).format('MM/DD/YY')}){ getSortIcon('Tip')}</span>
                                        </TableCell>

                                    <TableCell className={clsx(classes.headCell, classes.headerActionCell)} align='center'
                                        style={{ width: '113px', minWidth: '113px', padding: '16px 8px' }}>
                                        <div style={ipPlus ? {
                                            display: 'flex',
                                            alignItems: 'flex-start',
                                            flexDirection: 'column'
                                        } : { display: 'flex', alignItems: 'center' }}>
                                            <Button
                                                size={'small'} style={{ margin: ipPlus ? '8px 0' : '0 16px 0 0' }}
                                                className={(disabled || !enableSaveAll || (saveItems && !locateItem)) ? classes.btnDis : classes.button}
                                                onClick={() => {
                                                    setLocateItem(null)
                                                    saveAllItemPrice()
                                                }}
                                                variant="contained">
                                                Save All
                                            </Button>
                                            {saveItems && !locateItem && <CircularProgress size={24} />}
                                        </div>
                                    </TableCell>
                                    <TableCell align='center' style={{ width: '1%' }} className={clsx(classes.headCell, classes.headerActionCell)}>
                                        <MuiThemeProvider theme={customThemeTooltip}>
                                            <Tooltip title='Add Labor Payment' placement='bottom' arrow>
                                                <IconButton className={disabled ? classes.btnDis : classes.add} onClick={addCommission}
                                                    style={disabled ? { backgroundColor: 'transparent' } : null}>
                                                    <AddBoxIcon />
                                                </IconButton>
                                            </Tooltip>
                                        </MuiThemeProvider>
                                    </TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {commissionsShorted.map((com, index) => (
                                    <CommissionLine
                                        key={com.id + index}
                                        disabled={disabled}
                                        item={com}
                                        index={index}
                                        com={com}
                                        showDesc={showDesc}
                                        onHandleChangeDate={onHandleChangeDate}
                                        onChangeCommission={onChangeCommission}
                                        employees={employees}
                                        commissionsShorted={commissionsShorted}
                                        employeesAll={employeesAll}
                                        setIndex={setIndex}
                                        openConfirmDelete={openConfirmDelete}
                                        onSaveCustomerPayment={onSaveCustomerPayment}
                                        errorItem={errorItem}
                                        touchItem={touchItem}
                                        onBlueItem={onBlueItem}
                                        validateItems={validateItems}
                                        setLocateItem={({ index, id, add }) => setLocateItem({ index, id, add })}
                                        paySplitType={paySplitType}
                                    />
                                ))}
                            </TableBody>
                        </Table>
                    </TableContainer>
                </CardContent>
            </Collapse>
            <ConfirmModal
                openConfirm={openConfirm}
                closeConfirm={() => setOpenConfirm(false)}
                onConfirm={removeCommission}
                isProgress={deleting}
            />

            <DatePicker
                style={{ display: 'none' }}
                open={openPicker}
                onClose={() => setOpenPicker(false)}
                format="MM/DD/YYYY"
                onChange={onHandleChangeDate}
            />
            <Snackbar anchorOrigin={{ vertical: 'top', horizontal: 'center' }} open={openSnackbar}
                autoHideDuration={3000}
                onClose={() => setOpenSnackBar(false)}>
                <Alert
                    elevation={6} variant="filled" severity={isStatus.failed ? 'error' : 'success'}>
                    <Typography
                        color="inherit"
                        variant="h6">
                        {isStatus.msg}
                    </Typography>
                </Alert>
            </Snackbar>
        </Card>
    )
}

export default Commission;
