import {
    Button,
    Card,
    CardActions,
    CardHeader,
     colors,
    Divider,
    Modal,
} from "@material-ui/core";
import React, {useState} from "react";
import {makeStyles} from "@material-ui/styles";

const useStyles = makeStyles(theme => ({
    styleModal: {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        outline: 'none',
        boxShadow: theme.shadows[20],
        width: "500px",
        maxHeight: '100%',
        overflowY: 'auto',
        maxWidth: '100%',
        [theme.breakpoints.down('sm')]: {
            width: "100%"
        },
    },
    conButton: {
        padding: '16px',
        justifyContent: 'flex-end'
    },
    buttonSave: {
        flexShrink: '0',
        backgroundColor: colors.green[600],
        '&:hover': {
            backgroundColor: colors.green[900]
        },
    },
    timeCheckbox: {
        marginLeft: '16px',
        width: '100%'
    },
    time: {
        width: '100%',
        marginRight: '16px'
    },
    btn: {
        fontSize: 12,
        color: theme.palette.white,
        backgroundColor: colors.green[600],
        whiteSpace: 'nowrap',
        marginLeft: 8,
        '&:hover': {
            backgroundColor: colors.green[900]
        }
    },
}))


const ExistsTime = props => {
    const classes = useStyles();

    const {openConfirm, closeConfirm, onConfirm} = props;

    return (
        <Modal open={openConfirm}
               style={{minWidth: '500px'}}>
            <Card className={classes.styleModal}>
                <CardHeader style={{padding: '28px'}} title='This time has already been logged.'/>
                <Divider/>
                <CardActions className={classes.conButton}>
                    <div style={{float: 'right'}}>
                        <Button
                            variant="contained"
                            size="large"
                            onClick={() => {
                                closeConfirm()
                            }}>
                            Close
                        </Button>
                    </div>
                </CardActions>
            </Card>
        </Modal>
    )
}
export default ExistsTime
