import React from 'react'
import { Typography } from '@material-ui/core'
import { Page } from "components";


const Disconnect = () => {
    return (
        
            <Page title={'Disconnect'}>
                <div>
                <Typography>
                  Please ensure you have turned off Quickbooks Online integration in the Admin section of Squadware One. <br/>If this was unintentional, use the Authorize Connection button in the Admin section of Squadware one to re-enable Quickbooks online integration.
                </Typography>

                </div>
            </Page>
    )
}

export default Disconnect;

