import React, { useEffect, useRef, useMemo, useState } from 'react'
import PropTypes from 'prop-types'
import NumberFormat from 'react-number-format'
import { makeStyles } from '@material-ui/styles'
import {
    TableRow, TableCell, IconButton,
    Typography, Button, colors, Tooltip,
    TextField, Avatar, FormControlLabel, CircularProgress, Snackbar, CardActions, Icon
} from '@material-ui/core'
import { GreenCheckbox, InputTime } from 'components'
import AddBoxIcon from '@material-ui/icons/AddBox'
import OpenInNewIcon from '@material-ui/icons/OpenInNew';
import IndeterminateCheckBoxIcon from '@material-ui/icons/IndeterminateCheckBox'
import totalhours from 'helpers/date';
import { Alert } from "@material-ui/lab";
import moonSolid from 'assets/images/moon-solid.svg'

const useStyles = makeStyles(theme => ({
    employee: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        '& h5': {
            marginLeft: '10px',
            whiteSpace: 'pre-wrap'
        }
    },
    info: {
        display: 'flex',
        alignItems: 'center',
    },
    linkEmp: {
        color: colors.green[500]
    },
    iconBtn: {
        padding: '0',
        '& span svg': {
            fontSize: '40px',
            color: colors.green[600],
            '&:hover': {
                color: colors.green[900]
            }
        }
    },
    iconBtnDisabled: {
        pointerEvents: 'none',
        padding: '0',
        '& span svg': {
            fontSize: '40px',
            color: colors.grey[400],
            '&:hover': {
                color: colors.grey[900]
            }
        }
    },
    groupBtns: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        '&:first-child': {
            marginLeft: '-4px'
        }
    },
    btn: {
        height: '30px',
        marginLeft: '5px',
        color: theme.palette.white,
        backgroundColor: colors.green[600],
        '&:hover': {
            backgroundColor: colors.green[900]
        }
    },
    btnDis: {
        height: '30px',
        marginLeft: '5px',
        color: 'rgba(0, 0, 0, 0.26)',
        backgroundColor: 'rgb(217 217 217)',
        pointerEvents: 'none',
    },
    input: {
        '& div::before': {
            display: 'none'
        },
        '& div::after': {
            display: 'none'
        },
        '& input': {
            textAlign: 'center'
        }
    },
    hours: {
        '& input': {
            color: '#263238',
            fontSize: '16px',
            fontWeight: 500
        }
    },
    rate: {
        '& input': {
            color: '#263238',
            fontWeight: 500
        }
    },
    amount: {
        '& input': {
            color: '#263238',
            fontSize: '16px',
            fontWeight: 500
        }
    },
    checkbox: {
        marginRight: '0'
    },
    selectLocation: {
        height: '50px',
        '& div': {
            height: '100%',
        },
        '& div select': {
            fontSize: '16px'
        }
    },
    required: {
        color: '#e53935',
        fontSize: 12,
        textAlign: 'left'
    },
    trUnderline: {
        position: 'relative',
        '&:after': {
            content: "''",
            position: 'absolute',
            top: '50%',
            left: 0,
            height: 1,
            width: 'calc(100% - 80px)',
            backgroundColor: '#a4a4a4'
        }
    },
    disabled: {
        pointerEvents: 'none',
        opacity: 0.7,
    }
}));

const LogHoursCard = props => {
    const isMounted = useRef(false);
    const classes = useStyles();

    const { readOnly, countEntry, entry, log, onChange, onChangeTime, onAdditional, onRemove, status, update, updating, checkError, bindClearStart, bindClearEnd } = props;
    const { jobSplitId, hoursLogEmployeeStatusId, workStart, workEnd, appliedHourlyRate, id, changed, overnightBefore, overnightAfter } = entry;
    const { avatar, employeeId, firstName, lastName, hourlyRate, isHourlyRate } = log;

    const [openSnack, setOpenSnack] = useState(false);
    const [success, setSuccess] = useState({ failed: false, msg: '' })
    const [showUnderline, setShowUnderline] = useState(false);
    const [statusJob, setStatusJob] = useState(status);
    const [errors, setErrors] = useState({
        start: '',
        end: '',
        status: false
    });

    const [change, setChange] = useState(false);

    const saveLogHours = () => {
        setErrors({
            start: workStart ? '' : 'Required',
            end: workEnd ? '' : 'Required'
        });
        if (!workStart || !workEnd) {
            return;
        }
        update(entry);
    }

    const setRemoveLogHours = () => {
        // if (workStart && workEnd && hoursLogEmployeeStatusId && !id) {
        //     setShowUnderline(true);
        // } else {
        //     onRemove();
        // }
        onRemove();
    }

    useEffect(() => {
        setChange(changed);
    }, [changed])

    useEffect(() => {
        if (isMounted.current) {
            if (!workStart && workEnd || workStart && !workEnd) {
                setErrors({
                    start: workStart ? '' : 'Required',
                    end: workEnd ? '' : 'Required'
                });
            }
        }
        return () => isMounted.current = true;
    }, [checkError])

    useEffect(() => {
        if (workStart && errors.start) {
            setErrors({ ...errors, start: '' })
        }
        if (workEnd && errors.end) {
            setErrors({ ...errors, end: '' })
        }
    }, [entry])

    const jobLoged = entry.jobSplitId && status && status.find(s => s.jobSplitId === entry.jobSplitId)
    const disabled = jobLoged && jobLoged.completedDate ? true : false

    return useMemo(
        () => (
            <>
                <TableRow className={showUnderline ? classes.trUnderline : ''}>
                    <TableCell>
                        <div className={classes.employee}>
                            <div className={classes.info}>
                                <Avatar variant='circular' src={avatar} />
                                <Typography variant='h5'>{lastName}, {firstName}</Typography>
                            </div>
                            <a className={classes.linkEmp} href={`/employees/${encodeURIComponent(firstName)} ${lastName}/${employeeId}/salary`}
                                target='_blank'>
                                <OpenInNewIcon />
                            </a>
                        </div>
                    </TableCell>
                    <TableCell>
                        { overnightBefore &&
                            <Icon classes={{root: classes.iconRoot}}>
                                <img className={classes.imageIcon} src={moonSolid}/>
                            </Icon>
                        }
                    </TableCell>
                    <TableCell align='center' style={{ verticalAlign: 'top' }}>
                        {
                            <InputTime
                                disabled={disabled}
                                clearValue={bindClearStart}
                                error={!!errors.start}
                                name='workStart'
                                value={workStart || ''}
                                onChange={time => {
                                    onChangeTime('workStart', time)
                                }}
                            />
                        }
                        {(!!errors.start) && <p className={classes.required}>{errors.start}</p>}
                    </TableCell>
                    <TableCell align='center' style={{ verticalAlign: 'top' }}>
                        <InputTime
                            disabled={disabled}
                            clearValue={bindClearEnd}
                            error={!!errors.end}
                            ampm='PM'
                            name='workEnd'
                            value={workEnd || ''}
                            onChange={time => {
                                onChangeTime('workEnd', time)
                            }}
                        />
                        {(!!errors.end) && <p className={classes.required}>{errors.end}</p>}
                    </TableCell>
                    <TableCell>
                        { overnightAfter &&
                            <Icon classes={{root: classes.iconRoot}}>
                                <img className={classes.imageIcon} src={moonSolid}/>
                            </Icon>
                        }
                    </TableCell>
                    <TableCell className={classes.hours} align='center'>
                        <TextField
                            fullWidth
                            disabled
                            variant='standard'
                            className={classes.input}
                            value={totalhours(workStart, workEnd)}
                        />
                    </TableCell>
                    <TableCell className={classes.rate} align='center'>
                        <FormControlLabel
                            disabled={disabled || (hourlyRate ? false : !isHourlyRate)}
                            className={classes.checkbox}
                            name='appliedHourlyRate'
                            checked={appliedHourlyRate || isHourlyRate || false}
                            onChange={(e) => {
                                onChange(e);
                            }}
                            control={
                                <GreenCheckbox />
                            }
                        />
                    </TableCell>
                    <TableCell className={classes.amount} align='center'>
                        <NumberFormat
                            disabled
                            customInput={TextField}
                            prefix='$'
                            thousandSeparator
                            fullWidth
                            name='paid'
                            variant='standard'
                            className={classes.input}
                            value={hourlyRate || ''}
                        />
                    </TableCell>
                    <TableCell className={classes.amount} align='center'>
                        <NumberFormat
                            customInput={TextField}
                            prefix='$'
                            thousandSeparator
                            fullWidth
                            disabled
                            variant='standard'
                            className={classes.input}
                            value={!appliedHourlyRate ? '' : ((hourlyRate || 0) * totalhours(workStart, workEnd)).toFixed(2)}
                        />
                    </TableCell>
                    <TableCell className={classes.amount} align='center'>
                        <TextField
                            className={classes.selectLocation}
                            fullWidth
                            disabled={disabled}
                            error={hoursLogEmployeeStatusId ? false : errors.status}
                            name='hoursLogEmployeeStatusId'
                            select
                            SelectProps={{ native: true }}
                            variant='outlined'
                            value={jobSplitId || hoursLogEmployeeStatusId || ''}
                            onChange={e => {
                                onChange(e);
                            }}>
                            <option value={''}></option>
                            {status && status.map((val, i) => (
                                <option key={i} value={val.jobSplitId || val.id} disabled={val && val.completedDate ? true : false}>
                                    {val.description}
                                </option>
                            ))}
                        </TextField>
                        {(!hoursLogEmployeeStatusId && errors.status) && <p className={classes.required}>Required.</p>}
                    </TableCell>
                    {!readOnly && (
                        <TableCell align='right'>
                            <div className={classes.groupBtns}>
                                <span className={showUnderline ? classes.disabled : ''}>
                                    <Tooltip title='Add Additional' placement='bottom' arrow>
                                        <IconButton
                                            className={(disabled || showUnderline) ? classes.iconBtnDisabled : classes.iconBtn}
                                            onClick={onAdditional}>
                                            <AddBoxIcon />
                                        </IconButton>
                                    </Tooltip>
                                </span>
                                <span className={(countEntry === 1 || showUnderline) ? classes.disabled : ''}>
                                    <Tooltip title='Remove Additional' placement='bottom' arrow>
                                        <IconButton
                                            className={(disabled || countEntry === 1 || showUnderline) ? classes.iconBtnDisabled : classes.iconBtn}
                                            onClick={setRemoveLogHours}>
                                            <IndeterminateCheckBoxIcon />
                                        </IconButton>
                                    </Tooltip>
                                </span>
                                <CardActions>
                                <Button className={(disabled || !change) ? classes.btnDis : classes.btn}
                                    onClick={saveLogHours}>
                                    Save
                                </Button>
                                {updating && (new Date(workStart) <= new Date(workEnd)) && <CircularProgress size={24} />}
                                </CardActions>
                            </div>
                        </TableCell>
                    )}
                </TableRow>
                <Snackbar
                    anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
                    open={openSnack}
                    autoHideDuration={2000}
                    onClose={() => setOpenSnack(false)}>
                    <Alert
                        elevation={6} variant="filled" severity={success.failed ? 'error' : 'success'}>
                        <Typography
                            color="inherit"
                            variant="h6">
                            {success.msg}
                        </Typography>
                    </Alert>
                </Snackbar>
            </>
        ), [
            countEntry,
            updating,
            status,
            entry['workStart'],
            entry['workEnd'],
            entry['changed'],
            entry['hoursLogEmployeeStatusId'],
            log['hourlyRate'],
            checkError,
            change,
            errors,
            isHourlyRate,
            appliedHourlyRate
        ]
    )
}

LogHoursCard.propTypes = {
    readOnly: PropTypes.bool,
    countEntry: PropTypes.number,
    log: PropTypes.object,
    onChange: PropTypes.func,
    onChangeTime: PropTypes.func,
    onAdditional: PropTypes.func,
    onRemoveAdditional: PropTypes.func,
    onRemove: PropTypes.func
}

LogHoursCard.defaultProps = {
    log: {},
    onChange: () => {
    },
    onChangeTime: () => {
    },
    onAdditional: () => {
    },
    onRemoveAdditional: () => {
    },
    onRemove: () => {
    }
}

export default LogHoursCard;
