import React from 'react';
import PropTypes from 'prop-types'
import clsx from 'clsx';
import { makeStyles } from '@material-ui/styles';
import { colors } from '@material-ui/core';
import ReactPaginate from 'react-paginate';

const useStyles = makeStyles(theme => ({
  root: {
    ...theme.typography.button,
    listStyle: 'none',
    display: 'flex',
    alignItems: 'center',
    flexWrap: 'wrap',
    justifyContent: 'center'
  },
  active: {},
  activeLink: {},
  break: {},
  breakLink: {},
  disabled: {},
  next: {
    marginLeft: theme.spacing(1)
  },
  nextLink: {
    padding: '6px 16px',
    outline: 'none',
    cursor: 'pointer',
    borderRadius: 4,
    '&:hover': {
      //backgroundColor:  theme.palette.colors.secondary,
    }
  },
  page: {},
  pageLink: {
    color: theme.palette.text.secondary,
    padding: theme.spacing(1),
    outline: 'none',
    cursor: 'pointer',
    width: 40,
    height: 40,
    borderRadius: '50%',
    display: 'block',
    textAlign: 'center',
    '&:hover': {
      //backgroundColor: theme.palette.colors.secondary,
      color: theme.palette.text.primary
    },
    '&$activeLink': {
      backgroundColor: colors.blueGrey[50],
      color: theme.palette.colors.secondary
    }
  },
  previous: {
    marginRight: theme.spacing(1)
  },
  previousLink: {
    padding: '6px 16px',
    outline: 'none',
    cursor: 'pointer',
    borderRadius: 4,
    // '&:hover': {
    //   backgroundColor: theme.palette.colors.secondary
    // }
  }
}));

const Pagination = props => {
  const {
    pageCount,
    pageRangeDisplayed,
    onPageChange,
    forcePage,
    className,
    activeClassName,
    ...rest
  } = props;

  const classes = useStyles();

  return (
    <ReactPaginate
    
      breakLinkClassName={classes.breakLink}
      containerClassName={clsx(classes.root, className)}
      marginPagesDisplayed={3}
      nextClassName={classes.next}
      nextLabel="next"
      nextLinkClassName={classes.nextLink}
      onPageChange={onPageChange}
      forcePage={forcePage}
      pageClassName={classes.page}
      pageCount={pageCount}
      pageLinkClassName={classes.pageLink}
      pageRangeDisplayed={pageRangeDisplayed}
      previousClassName={classes.previous}
      previousLabel="previous"
      previousLinkClassName={classes.previousLink}
      activeClassName={classes.active}
      activeLinkClassName={classes.activeLink}
      breakClassName={classes.break}
      breakLabel="..."
      {...rest}
    />
  );
};

Pagination.propTypes = {
  pageCount: PropTypes.number,
  forcePage: PropTypes.number,
  onPageChange: PropTypes.func
}

Pagination.defaultProps = {
  pageRangeDisplayed: 10,
  onPageChange: () => {}
};

export default Pagination;
