import React, { useEffect, useState } from 'react'
import {
    Card, CardHeader, CardContent, Divider,
    TextField, IconButton, colors, Typography,
    Grid, Collapse, CardActions ,Button
} from '@material-ui/core'
import { ExpandMore } from '@material-ui/icons'
import { makeStyles } from '@material-ui/styles'
import useMediaQuery from '@material-ui/core/useMediaQuery'
import { Event, NavigateBeforeRounded, NavigateNextRounded } from '@material-ui/icons'
import { Page, KeyboardDatePicker } from 'components'
import { DatePicker } from '@material-ui/pickers'
import moment from 'moment'
import { ExpandWorker, ExpandEquipment, JobItem } from './components'
import axios from "utils/axios";
import apiConfig from "apiConfig";
import localStorage from 'utils/localStorage'
import CircularProgress from "@material-ui/core/CircularProgress";
import Snackbar from "@material-ui/core/Snackbar";
import Alert from "@material-ui/lab/Alert";
import { checkRoles, PERMISSIONS } from "common/permissions";
import ModalAllEmployees from "./components/ModalAllEmployees";
import {useDispatch, useSelector} from "react-redux";
import { checkFeature, FeatureFlags } from "FeatureService";
import { KeyboardArrowDown, KeyboardArrowUp } from "@material-ui/icons";

const useStyles = makeStyles(theme => ({
    content: { padding: 32 },
    actions: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        marginBottom: 16
    },
    expandHead: {
        position: 'relative',
        cursor: 'pointer',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        padding: '8px 16px',
    },
    btn: {
        color: theme.palette.white,
        backgroundColor: colors.green[600],
        '&:hover': {
            backgroundColor: colors.green[900]
        }
    },
    clockin: { display: 'flex', alignItems: 'center' },
    date: {
        display: 'flex',
        alignItems: 'flex-start',
        justifyContent: 'center',
        [theme.breakpoints.down('xs')]: {
            order: 3
        },
        [theme.breakpoints.up('sm')]: {
            '& > button + div': {
                width: 'unset'
            }
        }
    },
    status: { display: 'flex', justifyContent: 'flex-end', alignItems: 'center' },
    container: { border: '1px solid #bfbfbf', borderRadius: 4 },
    titleContainer: {
        padding: '8px 16px',
        fontWeight: 500,
        fontSize: 15,
        borderBottom: '1px solid #bfbfbf'
    },
    jobLabel: { fontWeight: 500, textAlign: 'center' },
    jobLabelLeft: { fontWeight: 500, textAlign: 'left' },
    jobunStatus: {
        [theme.breakpoints.down('xs')]: {
            display: 'none'
        }
    },
    jobStatus: {
        [theme.breakpoints.down('sm')]: {
            display: 'none'
        }
    },
    statusSelect: {
        marginLeft: '15px',
        [theme.breakpoints.down('xs')]: {
            flex: 1
        }
    },
    employeeSelect: {
        width: 'auto',
        maxWidth: '300px',
        marginRight: 16,
        [theme.breakpoints.down('xs')]: {
            flex: 1
        }
    },
    splitHeader: {
        alignItems: 'center'
    },
    button: {
        color: theme.palette.white,
        backgroundColor: colors.green[600],
        '&:hover': {
            backgroundColor: colors.green[900]
        }
    },
    cardTitle: {
        fontSize: '16px',
        fontWeight: 500,
        marginRight: theme.spacing(3)
    },
    label: {
        display: 'flex'
    },
}));

const EmployeeRoutes = props => {

    const classes = useStyles();
    const user = localStorage.getFullInfo();
    const ipad = useMediaQuery('(max-width: 769px)');
    const ipPlus = useMediaQuery('(max-width: 415px)');
    const [date, setDate] = useState(moment().startOf('day').toISOString());
    const [lastDate, setLastDate] = useState(new Date().toISOString());
    const [openPicker, setOpenPicker] = useState(false);
    const [expand, setExpand] = useState(false);
    const [expandWorker, setExpandWorker] = useState(null);
    const [expandEquipment, setExpandEquipment] = useState(null);
    const [expandJob, setExpandJob] = useState(null);
    const [coWorkers, setCoWorkers] = useState();
    const [jobEquipment, setJobEquipment] = useState();
    const [routeEquipment, setRouteEquipment] = useState();
    const [routeNote, setRouteNote] = useState();
    const [routeId, setRouteId] = useState(null);
    const [listEmployees, setListEmployees] = useState([]);
    const [selectEmployees, setSelectEmployees] = useState();
    const [selectStatus, setSelectStatus] = useState();
    const [jobs, setJob] = useState([]);
    const [status, setStatus] = useState([]);
    const [routeJobStatus, setRouteJobStatus] = useState([]);
    const [loadingDetail, setLoadingDetail] = useState(false);
    const [loadingJob, setLoadingJob] = useState(false);
    const [loadingEmployees, setLoadingEmployees] = useState(false);
    const [isStatus, setIsStatus] = useState({ failed: false, msg: '' });
    const [openSnackbar, setOpenSnackBar] = useState(false);
    const [clocking, setClocking] = useState(false);
    const [currentDate, setCurrentDate] = useState(false);
    const [openConfirmAllEmployees, setOpenConfirmAllEmployees] = useState(false);
    const [listEmployeesActive, setListEmployeesActive] = useState([]);

    const [selectCurrentStatus, setSelectCurrentStatus] = useState();
    const dispatch = useDispatch();
    const { reloadLogHour } = useSelector(state => state.AppReducer);

    const userRole = localStorage.getUserRole();
    const isHasRoleClockInClockOut = checkRoles(userRole, PERMISSIONS.control.clockInClockOut);
    const isHasRoleShowListEmployees = checkRoles(userRole, PERMISSIONS.control.showListEmployees);
    const isHasRoleEnableDatePicker = checkRoles(userRole, PERMISSIONS.control.enableDatePicker);
    const [isEnableTodayRoute, setIsEnableTodayRoute] = useState(true);
    const [isDisabledNextDate, setIsDisabledNextDate] = useState(false);
    const [isEnablePrevDate, setIsEnablePrevDate] = useState(true);
    const userRoles = localStorage.getUserRoles();
    const isFieldTechnician = !userRoles || (userRoles.length === 1 && userRoles[0] === PERMISSIONS.FIELD_TECHNICIAN);
    const onDatePickerChange = day => {

        setDate(new Date(day).toISOString());
        
    }
    const previousDate = () => {
        const currDate = new Date(date);
        const prevDate = currDate.setDate(currDate.getDate() - 1);
        setIsEnablePrevDate(moment(prevDate).format('MM/DD/YYYY') === moment(new Date()).format('MM/DD/YYYY'));
        setDate(moment(prevDate).format('MM/DD/YYYY'));
    }
    const nextDate = () => {
        const currDate = new Date(date);
        const nextDate = currDate.setDate(currDate.getDate() + 1);
        setIsDisabledNextDate(moment(nextDate).format('MM/DD/YYYY') === moment(lastDate).format('MM/DD/YYYY'));
        setIsEnablePrevDate(false);
        setDate(moment(nextDate).format('MM/DD/YYYY'));
    }
    const handleExpandWorker = index => {
        setExpandWorker(expandWorker === index ? null : index)
    }
    const handleExpandEquipment = type => {
        setExpandEquipment(expandEquipment === type ? null : type)
    }

    const loadJob = (id = null) => {
        setLoadingJob(true);
        Promise.all([
            axios.get(apiConfig.url.BASE_URL + apiConfig.url.EMPLOYEE_ROUTE_JOB.replace("{userId}", selectEmployees).replace('{date}', moment(date).format('YYYY-MM-DD'))),
            getEmployeeStatus()
        ]).then(([resJob, resStatus]) => {
            if (resJob.data) {
                let orderedJob = resJob.data.sort((a, b) => {
                    return new Date(a.scheduledTime).getTime() - new Date(b.scheduledTime).getTime();
                }
                );
                setJob(orderedJob);
                let lstStatus = [...resStatus.data];
                let jobsiteStatusIdx = lstStatus.findIndex(s => s.id == 2);
                var uniqJobs = orderedJob.filter((job, index, self) => self.findIndex(s => s.jobSplitId === job.jobSplitId) === index);
                if (uniqJobs.length > 0) {
                    lstStatus.splice(jobsiteStatusIdx, 1, ...uniqJobs.map(d => ({
                        id: 2,
                        description: `Job Site${d.jobsiteAddress1 ? ` - ${d.jobsiteAddress1}` : ''}`,
                        jobSplitId: d.jobSplitId
                    })));
                }

                setStatus(lstStatus);
            } else {
                let lstStatus = [...resStatus.data];
                setStatus(lstStatus)
                setJob([]);
                setSelectStatus('6_');
            }
        }).finally(() => {
            setLoadingJob(false)
        });
    }

    const getDocument = (customerId, index) => {
        if(isEnableTodayRoute != null && isEnableTodayRoute){
            axios.get(apiConfig.url.BASE_URL + apiConfig.url.TODAY_ROUTE_DOCUMENT_NEW.replace('{customerId}', customerId))
            .then(res => {
                const job = [...jobs];
                job[index]['documents'] = [...res.data.jobAttachments,...res.data.jobsiteAddressAttachments,...res.data.customerAttachments];
                setJob(job);
            })
        }
        else{
            axios.get(apiConfig.url.BASE_URL + apiConfig.url.TODAY_ROUTE_DOCUMENT_NEW.replace('{customerId}', customerId), {
                params:
                    {
                        pageSize: 10000,
                        pageNumber: 1,
                    }
            })
            .then(res => {
                if(res && res.data){
                    const conditionSort = [
                        'Customer',
                        'Jobsite Address',
                        'Job'
                    ];
                    const job = [...jobs];
                    const documents = res.data.data.sort((a,b) => conditionSort.indexOf(a.attachmentType) - conditionSort.indexOf(b.attachmentType));
                    job[index]['documents'] = documents;
                    setJob(job);
                }
            })
        }
    }

    const getEmployees = () => {
        //reset data
        setListEmployees([]);
        setSelectEmployees('');
        setCoWorkers([]);
        setJob([]);
        setJobEquipment([]);
        setRouteEquipment('');
        setRouteNote('');

        setLoadingEmployees(true)
        const requests = [
            axios.get(apiConfig.url.BASE_URL + apiConfig.url.TODAY_ROUTE_EMPLOYEES.replace('{userId}', user.nameid).replace('{date}', moment(date).format('YYYY-MM-DD')))
        ];
        if (isHasRoleShowListEmployees) requests.push(axios.get(apiConfig.url.USER_URL + apiConfig.url.EMPLOYEE_LIST_ACTIVE_IN_COMPANY));
        Promise.all(requests).then(([resActive, res]) => {
            let listActive = [];
            res !== undefined && res.data.users.map(item => {
                if (!listActive.find(x => x.id === item.id)) {
                    item['name'] = item.firstName + item.lastName
                    listActive.push(item);
                }
            })
            listActive.sort((a, b) => a.lastName.localeCompare(b.lastName));

            let list = [];
            resActive.data.map(item => {
                if (!list.find(x => x.employeeId === item.employeeId)) {
                    item['name'] = item.firstName + item.lastName
                    item['id'] = item.employeeId
                    list.push(item);
                }
                if (listActive.find(x => x.id === item.employeeId)) {
                    listActive = listActive.filter(y => y.id !== item.employeeId)
                }
            })
            list.sort((a, b) => a.lastName.localeCompare(b.lastName));
            setListEmployeesActive(list);
            const listEmp = isHasRoleShowListEmployees ? list.length > 0 ? [...list, ...listActive] : listActive : [...list];
            setListEmployees(listEmp);
            if (listEmp && listEmp.length > 0 && isHasRoleShowListEmployees) {
                setSelectEmployees(listEmp[0].id);
            } else {
                setSelectEmployees(user.nameid)
            }

        })
            .catch(err => {
            }).finally(() => {
                setLoadingEmployees(false);
            })
    }

    const getRouteDetail = () => {
        setLoadingDetail(true)
        axios.get(apiConfig.url.BASE_URL + apiConfig.url.EMPLOYEE_ROUTE_DETAIL.replace('{userId}', selectEmployees).replace('{date}', moment(date).toISOString()))
            .then(res => {
                const list = [];
                res.data.coWorkers.map(item => {
                    list.push({
                        id: item.employeeId,
                        name: (item.firstName || '') + ' ' + (item.lastName || '')
                    })
                })

                setRouteId(res.data.routeId);
                // sort coWorkers list
                const coWorkers = res.data.coWorkers || [];
                const sortCoWorkers = coWorkers.sort((a, b) => a.lastName.localeCompare(b.lastName));
                setCoWorkers(sortCoWorkers.sort((a, b) => a.firstName.localeCompare(b.firstName)));

                const jobEquipment = res.data.jobEquipment.map(item => {
                    if (item) {
                        return item.replace(";", ", ");
                    }
                })
                setJobEquipment(jobEquipment.filter(e => e).join(', '));

                const routeEquipment = res.data.routeEquipment.map(item => {
                    return item.assetTag;
                })
                setRouteEquipment(routeEquipment.length > 0 ? [routeEquipment.join(", ")] : '');

                if (res.data.hoursLog && !res.data.hoursLog.workEnd) {
                    setSelectStatus(`${res.data.hoursLog.hoursLogEmployeeStatusId}_${res.data.hoursLog.jobSplitId || ''}`)
                } else {
                    setSelectStatus('6_') // hard coded - Off The Clock
                }

                setRouteNote(res.data.routeNote);
            }).finally(() => {
                setLoadingDetail(false)
            })
    }

    const getEmployeeStatus = () => {
        return axios.get(apiConfig.url.BASE_URL + apiConfig.url.HOURS_LOG_EMPLOYEE_STATUS);
    }


    const clockInEmployees = (event) => {
        if(listEmployeesActive && listEmployeesActive.length > 1){
            setOpenConfirmAllEmployees(true)
        }else{
            onEmployeeStatusChanged(false, event)
        }
    }

    const onEmployeeStatusChanged = ( all, event) => {
        setOpenConfirmAllEmployees(false);
        if (!currentDate && !isHasRoleClockInClockOut) {
            return
        }
        setClocking(true);
        let value = event && event.target.value;
        let arr = value ? value.split('_') : ((selectCurrentStatus && selectCurrentStatus.split('_')) || selectStatus.split('_'));
        let lstPromise = [];
        if(all){
            listEmployeesActive.forEach(id => {
                const data = {
                    employeeId: id.id,
                    date: moment(date),
                    hoursLogEmployeeStatusId: arr[0],
                    jobSplitId: arr[1] || null
                }
                lstPromise.push(axios.put(apiConfig.url.BASE_URL + apiConfig.url.HOURS_LOG_EMPLOYEE_STATUS, data))
            })
        }else{
            const data = {
                employeeId: selectEmployees,
                date: moment(date),
                hoursLogEmployeeStatusId: arr[0],
                jobSplitId: arr[1] || null
            }
            lstPromise.push(axios.put(apiConfig.url.BASE_URL + apiConfig.url.HOURS_LOG_EMPLOYEE_STATUS, data))
        }
            Promise.all(lstPromise)
            .then(res => {
                if (!res.find(x => !x.data.isSuccess)) {
                    setIsStatus({ failed: false, msg: 'Record Updated' });
                    setOpenSnackBar(true);
                    dispatch({ type: 'RELOAD_LOG_HOUR_SIDEBAR'});
                    setSelectStatus(arr.join('_'));
                } else {
                    setIsStatus({ failed: true, msg: 'Payroll has not been enabled yet. Please contact the administrator.' });
                    setOpenSnackBar(true);
                }
            })
            .catch(() => {
                setIsStatus({ failed: true, msg: 'Update failed, please try again later.' });
                setOpenSnackBar(true);
            })
            .finally(() => {
                setClocking(false);
            })
    }

    const onUpdateRouteJobStatus = employeeStatus => {
        setSelectStatus(employeeStatus)
    }

    useEffect(() => {
        setLastDate(new Date().setDate(new Date().getDate() + 7));
        checkFeature(FeatureFlags.TODAYS_ROUTE).then(setIsEnableTodayRoute);
        axios.get(apiConfig.url.BASE_URL + apiConfig.url.TODAY_ROUTE_STATUS)
            .then(res => setRouteJobStatus(res.data))
    }, [])

    useEffect(() => {
        getEmployees();
        let currentDate = new Date();
        setCurrentDate(
            currentDate.getFullYear() === new Date(date).getFullYear() &&
            currentDate.getMonth() === new Date(date).getMonth() &&
            currentDate.getDay() === new Date(date).getDay()
        );
        setIsEnablePrevDate(moment(date).format('MM/DD/YYYY') === moment(new Date()).format('MM/DD/YYYY'));
    }, [date])

    useEffect(() => {
        if(coWorkers){
            if(coWorkers){
                let lst = coWorkers.map(x => {
                    return {
                        ...x,
                        id: x.employeeIdString,
                        name: x.firstName + x.lastName
                    };
                })
                setListEmployeesActive(lst);
            }
        }
    }, [coWorkers])

    useEffect(() => {
        if (selectEmployees) {
            getRouteDetail();
            loadJob();
        }
    }, [selectEmployees, reloadLogHour])

    return (
        <Page title={`Employee - Today's Routes`}>
            <div className={classes.content}>
                <div className={classes.actions}>
                    <Grid container spacing={2}>
                        <Grid item xs={6} sm={3} className={classes.clockin}>
                            {
                                selectEmployees && status && status.length > 0 && (
                                    <>
                                        <CardActions>
                                            <TextField
                                                className={classes.statusSelect}
                                                // size={"small"}
                                                name='status'
                                                SelectProps={{ native: true }}
                                                variant='outlined'
                                                select
                                                label="Clock In/Clock Out"
                                                disabled={!currentDate}
                                                value={selectStatus}
                                                onChange={(event) => {
                                                    clockInEmployees(event);
                                                    setSelectCurrentStatus(event.target.value)
                                                }}
                                            >
                                                {status && status.map(st => (
                                                    <option key={`status-${st.id}-${st.jobSplitId}`}
                                                        disabled={!currentDate && !isHasRoleClockInClockOut}
                                                        value={`${st.id}_${st.jobSplitId || ''}`}>
                                                        {st.description}
                                                    </option>
                                                ))}
                                            </TextField>
                                            {clocking && <CircularProgress size={24} />}
                                        </CardActions>
                                    </>
                                )
                            }
                        </Grid>
                        <Grid item xs={12} sm={6} className={classes.date}>
                            <IconButton disabled={userRole === PERMISSIONS.FIELD_TECHNICIAN ? isEnablePrevDate : date && isHasRoleEnableDatePicker ? false : true} style={{ width: '53px' }} onClick={previousDate}>
                                <NavigateBeforeRounded />
                            </IconButton>
                            <KeyboardDatePicker
                                value={date}
                                disabled={!isHasRoleEnableDatePicker && userRole !== PERMISSIONS.FIELD_TECHNICIAN}
                                minDate={userRole === PERMISSIONS.FIELD_TECHNICIAN ? new Date() : undefined}
                                maxDate={userRole === PERMISSIONS.FIELD_TECHNICIAN ? lastDate : undefined}
                                onChange={value => {
                                    if (value !== date)
                                        setDate(value);
                                }}
                            />
                            <IconButton disabled={userRole === PERMISSIONS.FIELD_TECHNICIAN ? isDisabledNextDate : !isHasRoleEnableDatePicker && userRole !== PERMISSIONS.FIELD_TECHNICIAN} style={{ width: '53px' }} onClick={nextDate}>
                                <NavigateNextRounded />
                            </IconButton>
                        </Grid>
                        <Grid item xs={6} sm={3} className={classes.status}>
                            {
                                isHasRoleShowListEmployees && (
                                    (listEmployees && listEmployees.length > 1) ?
                                        <TextField
                                            className={classes.employeeSelect}
                                            name='employee'
                                            variant='outlined'
                                            select
                                            label="Employee"
                                            SelectProps={{ native: true }}
                                            onChange={e => {
                                                // onchangeEmployees(e.target.value);
                                                setSelectEmployees(e.target.value)
                                            }}
                                            value={selectEmployees}>
                                            {listEmployees && listEmployees.map(e => (
                                                <option
                                                    value={e.id}
                                                    key={e.id}
                                                >{(e.firstName || '') + ' ' + (e.lastName || '')}</option>
                                            ))}
                                        </TextField>
                                        :
                                        <Typography style={{
                                            display: 'inline-block',
                                            paddingRight: '30px',
                                            paddingTop: '17px'
                                        }}>{listEmployees && listEmployees[0] && ((listEmployees[0].firstName || '') + ' ' + (listEmployees[0].lastName || ''))}</Typography>
                                )
                            }

                        </Grid>
                    </Grid>
                </div>

                {
                    (loadingDetail || loadingJob || loadingEmployees) ? (
                        <div>
                            <Grid
                                container
                                spacing={0}
                                align="center"
                                justifyContent="center"
                                direction="column">
                                <Grid item>
                                    <CircularProgress className={classes.circularProgress} size={50} />
                                </Grid>
                            </Grid>
                        </div>
                    ) : (
                        (!coWorkers || (coWorkers && coWorkers.length === 0)) &&
                            (!jobs || (jobs && jobs.length === 0))
                            ? (
                                <Grid container justifyContent='center'>
                                    <Typography className={classes.noData}>No data found.</Typography>
                                </Grid>
                            ) : (
                                <>
                                    <Card>
                                        <CardContent style={ipad ? { alignItems: 'flex-start' } : null}
                                            className={classes.expandHead}
                                            onClick={() => setExpand(!expand)}
                                        >
                                            <div style={{ display: 'flex', flexDirection: ipad ? 'column' : 'row', justifyContent: 'space-between' }}>
                                                <div style={{ marginBottom: ipad ? '8px' : '0px', flexDirection: ipPlus ? 'column' : 'row', alignItems: ipPlus ? 'flex-start' : 'center', marginRight: 'auto' }}
                                                    className={classes.label}>
                                                    <Typography className={classes.cardTitle}>Route Details</Typography>
                                                </div>
                                               { routeId && <div style={ipPlus ? { display: 'block', width: '100%' } : { display: 'flex' }}>
                                                    <Button style={{ marginRight: '16px', padding: '3px 8px', fontSize: '12px' }} className={classes.button}
                                                    onClick={()=>{
                                                        window.open(`/route/pdf/${routeId}`, '_blank');
                                                    }}> print </Button>
                                                </div> }
                                            </div>
                                            <div style={{ height: '100%', paddingLeft: '20px', cursor: 'pointer' }}>
                                            {
                                                expand ? <KeyboardArrowUp /> : <KeyboardArrowDown />
                                            }
                                            </div>
                                        </CardContent>
                                        <Divider />
                                        <Collapse in={expand} timeout='auto' unmountOnExit>
                                            <CardContent style={{ display: 'flex'}}>
                                                <Grid container spacing={2}>
                                                    {
                                                        coWorkers && coWorkers.length > 0 ?
                                                            <>
                                                                <Grid item xs={12} md={6}>
                                                                    <div className={classes.container}>
                                                                        <Typography className={classes.titleContainer}>Co-Workers</Typography>
                                                                        {coWorkers && coWorkers.map((worker, index) => (
                                                                            <ExpandWorker
                                                                                key={`worker-${index}`}
                                                                                expand={expandWorker === index}
                                                                                onExpand={() => handleExpandWorker(index)}
                                                                                worker={worker}
                                                                            />
                                                                        ))}
                                                                    </div>
                                                                </Grid>
                                                                <Grid item xs={12} md={6}>
                                                                    <div className={classes.container}>
                                                                        <Typography className={classes.titleContainer}>Equipment</Typography>
                                                                        {
                                                                            jobEquipment &&
                                                                            <ExpandEquipment
                                                                                expand={expandEquipment === 'job'}
                                                                                onExpand={() => handleExpandEquipment('job')}
                                                                                equipmentString={jobEquipment}
                                                                            />
                                                                        }
                                                                        {
                                                                            routeEquipment &&
                                                                            <ExpandEquipment
                                                                                expand={expandEquipment === 'route'}
                                                                                onExpand={() => handleExpandEquipment('route')}
                                                                                equipmentString={routeEquipment}
                                                                            />
                                                                        }
                                                                        </div>
                                                                        <div style={{ marginTop: 8 }}><b>Route Note:</b> {routeNote}</div>
                                                                </Grid>
                                                            </>
                                                            :
                                                            <Grid container justifyContent='center'>
                                                                <Typography className={classes.noData}>No data
                                                                    found.</Typography>
                                                            </Grid>
                                                    }

                                                </Grid>


                                            </CardContent>
                                        </Collapse>
                                    </Card>
                                    {
                                        isEnableTodayRoute != null &&  isEnableTodayRoute ?
                                            <>
                                                 <Card style={{ marginTop: 24 }}>
                                        <CardContent >
                                        <Grid container spacing={0} alignItems='center'
                                                            style={{ margin: '0', width: 'auto' }}>
                                                            <Grid item sm={2} md={1} className={classes.jobunStatus}>
                                                                <Typography className={classes.jobLabelLeft}>
                                                                    Jobs
                                                                </Typography>
                                                            </Grid>
                                                            <Grid item sm={4} md={3}
                                                                className={classes.jobunStatus}>
                                                                <Typography className={classes.jobLabel}>
                                                                    Time
                                                                </Typography>
                                                            </Grid>
                                                            <Grid item sm={4} md={2}
                                                                className={classes.jobunStatus}>
                                                                <Typography className={classes.jobLabel}>
                                                                    Customer
                                                                </Typography>
                                                            </Grid>
                                                            <Grid item sm={4} md={2}
                                                                className={classes.jobunStatus}>
                                                                <Typography className={classes.jobLabel}>
                                                                    Man Hours
                                                                </Typography>
                                                            </Grid>
                                                            <Grid item sm={4} md={4}
                                                                className={classes.jobStatus}>
                                                                <Typography className={classes.jobLabel}>
                                                                    Status
                                                                </Typography>
                                                            </Grid>
                                                        </Grid>
                                        </CardContent>
                                        </Card>
                                            {
                                                (!jobs || (jobs && jobs.length === 0)) &&
                                                <Card style={{ marginTop: 24 }}>
                                                <CardContent >
                                                <Grid container justifyContent='center'>
                                                    <Typography className={classes.noData}>No data
                                                        found.</Typography>
                                                </Grid>
                                                </CardContent>
                                                </Card>
                                            }
                                            {
                                                jobs && jobs.length > 0 && (
                                                    <>
                                                        {jobs.map((job, index) => (
                                                            <JobItem
                                                                style={index === 0 ? { marginTop: 24 } : null}
                                                                key={`job-${index}`}
                                                                job={job}
                                                                status={routeJobStatus}
                                                                expand={expandJob === index}
                                                                isEnableTodayRoute ={isEnableTodayRoute}
                                                                isFieldTechnician={isFieldTechnician}
                                                                onExpand={() => {
                                                                    setExpandJob(expandJob === index ? null : index);
                                                                    // getDocument(job.customerId, index)
                                                                }}
                                                                // onAddAttachment={() => getDocument(job.customerId, index)}
                                                                onUpdateStatus={employeeStatus => {
                                                                    onUpdateRouteJobStatus(employeeStatus)
                                                                }}
                                                            />
                                                        ))}
                                                    </>
                                                )
                                            }
                                            </>
                                        :
                                        <>
                                            <Card style={{ marginTop: 24 }}>
                                            <CardHeader title='Jobs' />
                                            <Divider />
                                            <CardContent>
                                                {
                                                    (!jobs || (jobs && jobs.length === 0)) &&
                                                    <Grid container justifyContent='center'>
                                                        <Typography className={classes.noData}>No data
                                                            found.</Typography>
                                                    </Grid>
                                                }
                                                {
                                                    jobs && jobs.length > 0 && (
                                                        <>
                                                            <Grid container spacing={2} alignItems='center'
                                                                style={{ margin: '0 8px', width: 'auto' }}>
                                                                <Grid item sm={4} md={2}
                                                                    className={classes.jobunStatus}>
                                                                    <Typography className={classes.jobLabel}>
                                                                        Time
                                                                    </Typography>
                                                                </Grid>
                                                                <Grid item sm={4} md={3}
                                                                    className={classes.jobunStatus}>
                                                                    <Typography className={classes.jobLabel}>
                                                                        Customer
                                                                    </Typography>
                                                                </Grid>
                                                                <Grid item sm={4} md={2}
                                                                    className={classes.jobunStatus}>
                                                                    <Typography className={classes.jobLabel}>
                                                                        Man Hours
                                                                    </Typography>
                                                                </Grid>
                                                                <Grid item sm={4} md={5}
                                                                    className={classes.jobStatus}>
                                                                    <Typography className={classes.jobLabel}>
                                                                        Status
                                                                    </Typography>
                                                                </Grid>
                                                            </Grid>

                                                            {jobs.map((job, index) => (
                                                                <JobItem
                                                                    style={index === 0 ? { marginTop: 0 } : null}
                                                                    key={`job-${index}`}
                                                                    job={job}
                                                                    status={routeJobStatus}
                                                                    expand={expandJob === index}
                                                                    isEnableTodayRoute ={isEnableTodayRoute}
                                                                    isFieldTechnician={isFieldTechnician}
                                                                    onExpand={() => {
                                                                        setExpandJob(expandJob === index ? null : index);
                                                                        getDocument(job.customerId, index)
                                                                    }}
                                                                    onUpdateStatus={employeeStatus => {
                                                                        onUpdateRouteJobStatus(employeeStatus)
                                                                    }}
                                                                />
                                                            ))}
                                                        </>
                                                    )
                                                }
                                                </CardContent>
                                            </Card>
                                        </>
                                    }
                                    
                                </>
                            )
                    )
                }
            </div>

            <DatePicker
                value={new Date(date)}
                onChange={onDatePickerChange}
                onClose={() => setOpenPicker(false)}
                open={openPicker}
                style={{ display: 'none' }}
                variant="dialog"
            />
            <Snackbar anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
                open={openSnackbar}
                autoHideDuration={3000}
                onClose={() => setOpenSnackBar(false)}>
                <Alert elevation={6} variant="filled" severity={isStatus.failed ? 'error' : 'success'}>
                    <Typography
                        color="inherit"
                        variant="h6">
                        {isStatus.msg}
                    </Typography>
                </Alert>
            </Snackbar>
            <ModalAllEmployees
                openConfirm={openConfirmAllEmployees}
                closeConfirm={() => {
                    setOpenConfirmAllEmployees(false);
                }}
                onConfirmOne={() =>onEmployeeStatusChanged(false) }
                onConfirm={() => onEmployeeStatusChanged(true)}
                lstEmployees={listEmployeesActive}
            >
            </ModalAllEmployees>
        </Page>
    )
}

export default EmployeeRoutes;
