import { Document, Font, Page, Text, View } from '@react-pdf/renderer';
import moment from 'moment';
import React from 'react';
import { formatNumber } from 'utils/formatNumber';

const PricingPdf = ({ items }) => {
  Font.register({
    family: 'Roboto',
    fonts: [
      {
        src: `https://cdn.jsdelivr.net/npm/roboto-fontface@0.10.0/fonts/roboto/Roboto-Regular.woff`,
        fontWeight: 'medium',
      },
      {
        src: `https://cdn.jsdelivr.net/npm/roboto-fontface@0.10.0/fonts/roboto/Roboto-Bold.woff`,
        fontWeight: 'bold',
      },
    ],
  });

  const formatLongDescription = (description) => {
    if (!description) return '';
    let result = description;
    const longDigits = description?.match(/[0-9]{34,}/g);
    if (longDigits?.length) {
      longDigits.forEach((item) => {
        const parts = item.match(/.{1,34}/g);
        result = result.replace(item, parts.join(' '));
      });
    }
    return result;
  };

  return (
    <Document>
      <Page size="A4" style={{ padding: 20, paddingTop: 20 }}>
        <View fixed style={{ paddingLeft: 10, paddingRight: 10 }}>
          <View style={{ width: '100%', flexDirection: 'row' }}>
            <View style={{ width: '12%', height: 'auto', alignItems: 'center' }}>
              <Text style={{ fontFamily: 'Roboto', fontWeight: 'medium', fontSize: 10 }}>{moment().format('MM/DD/YYYY')}</Text>
            </View>
            <View style={{ width: '1%' }} />
            <View style={{ width: '74%', height: 'auto', alignItems: 'center' }}>
              <Text style={{ fontFamily: 'Roboto', fontWeight: 'bold', fontSize: 13 }}>Squeegee Squad</Text>
            </View>
            <View style={{ width: '1%' }} />
            <View style={{ width: '12%', height: 'auto', alignItems: 'center' }}>
              <Text
                style={{ fontFamily: 'Roboto', fontWeight: 'medium', fontSize: 10 }}
                render={({ pageNumber, totalPages }) => 'Page ' + pageNumber + ' of ' + totalPages}
              />
            </View>
          </View>
          <View style={{ width: '100%', flexDirection: 'row' }}>
            <View style={{ width: '10%', height: 'auto', alignItems: 'center' }} />
            <View style={{ width: '1%' }} />
            <View style={{ width: '80%', height: 'auto', alignItems: 'center' }}>
              <Text style={{ fontFamily: 'Roboto', fontWeight: 'bold', fontSize: 14 }}>Price Line Items Report</Text>
            </View>
            <View style={{ width: '1%' }} />
            <View style={{ width: '10%', height: 'auto', alignItems: 'center' }} />
          </View>
        </View>

        <View fixed wrap={false} style={{ width: '100%', marginTop: 10, flexDirection: 'row', height: 'auto', borderWidth: 1, borderColor: 'gray' }}>
          <View style={{ width: '38%', borderRightWidth: 1, borderRightColor: 'gray', padding: 10 }}>
            <Text style={{ fontFamily: 'Roboto', fontWeight: 'bold', fontSize: 10, color: 'black', width: '100%' }}>Description</Text>
          </View>
          <View style={{ width: '15%', borderRightWidth: 1, borderRightColor: 'gray', padding: 10 }}>
            <Text style={{ fontFamily: 'Roboto', fontWeight: 'bold', fontSize: 10, color: 'black', width: '100%' }}>Price</Text>
          </View>
          <View style={{ width: '11%', borderRightWidth: 1, borderRightColor: 'gray', padding: 10 }}>
            <Text style={{ fontFamily: 'Roboto', fontWeight: 'bold', fontSize: 10, color: 'black', width: '100%' }}>Taxable</Text>
          </View>
          <View style={{ width: '25%', borderRightWidth: 1, borderRightColor: 'gray', padding: 10 }}>
            <Text style={{ fontFamily: 'Roboto', fontWeight: 'bold', fontSize: 10, color: 'black', width: '100%' }}>Quickbooks Item</Text>
          </View>
          <View style={{ width: '11%', padding: 10 }}>
            <Text style={{ fontFamily: 'Roboto', fontWeight: 'bold', fontSize: 10, color: 'black', width: '100%' }}>Active</Text>
          </View>
        </View>

        {(items ?? []).map(function(item, index) {
          return (
            <View key={index} wrap={false} style={{ width: '100%', flexDirection: 'row', height: 'auto', borderWidth: 1, borderColor: 'gray', marginTop: -1 }}>
              <View style={{ width: '38%', borderRightWidth: 1, borderRightColor: 'gray', padding: 10 }}>
                <Text style={{ fontFamily: 'Roboto', fontWeight: 'medium', fontSize: 10, color: 'black', width: '100%' }}>
                  {formatLongDescription(item.description) || ''}
                </Text>
              </View>
              <View style={{ width: '15%', borderRightWidth: 1, borderRightColor: 'gray', padding: 10 }}>
                <Text style={{ fontFamily: 'Roboto', fontWeight: 'medium', fontSize: 10, color: 'black', width: '100%', textAlign: 'right' }}>
                  ${formatNumber(item.price, 2)}
                </Text>
              </View>
              <View style={{ width: '11%', borderRightWidth: 1, borderRightColor: 'gray', padding: 10 }}>
                <Text style={{ fontFamily: 'Roboto', fontWeight: 'medium', fontSize: 10, color: 'black', width: '100%' }}>{item.taxable ? 'Yes' : 'No'}</Text>
              </View>
              <View style={{ width: '25%', borderRightWidth: 1, borderRightColor: 'gray', padding: 10 }}>
                <Text style={{ fontFamily: 'Roboto', fontWeight: 'medium', fontSize: 10, color: 'black', width: '100%' }}>{item.quickbooksName || ''}</Text>
              </View>
              <View style={{ width: '11%', padding: 10 }}>
                <Text style={{ fontFamily: 'Roboto', fontWeight: 'medium', fontSize: 10, color: 'black', width: '100%' }}>{item.active ? 'Yes' : 'No'}</Text>
              </View>
            </View>
          );
        })}
      </Page>
    </Document>
  );
};

export default PricingPdf;
