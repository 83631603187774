import React from 'react'
import PropTypes from 'prop-types'
import { IconButton } from '@material-ui/core'
import { isEqual, isBelongToRangeDate, getStringDate } from 'utils/date-time'
import NavigateNextIcon from '@material-ui/icons/NavigateNext'
import NavigateBeforeIcon from '@material-ui/icons/NavigateBefore'
import styles from '../DateRangePicker.module.scss'

const MonthPicker = ({ days, payPeriodList, start, end, calendar, onChangeDate, prevMonth, nextMonth, showYears }) => {

    const isValidDate = (day) => {
        const startDateArray = payPeriodList.map(pay => pay.startDate);
        const d = startDateArray.find(s => {
            const start = new Date(s);
            return isEqual(day, start);
        });
        if (d) return true;
        
        return false;
    }

    return (
        <div style={{ display: showYears ? 'none' : 'block' }}>
            <div className={styles.selectedDate}>
                <IconButton onClick={prevMonth} className={styles.icon}>
                    <NavigateBeforeIcon />
                </IconButton>
                <h6>{getStringDate(calendar.year, calendar.month)}</h6>
                <IconButton onClick={nextMonth} className={styles.icon}>
                    <NavigateNextIcon />
                </IconButton>
            </div>
            <div className={styles.days}>
                <div className={styles.dayInWeek}><span>Su</span></div>
                <div className={styles.dayInWeek}><span>Mo</span></div>
                <div className={styles.dayInWeek}><span>Tu</span></div>
                <div className={styles.dayInWeek}><span>We</span></div>
                <div className={styles.dayInWeek}><span>Th</span></div>
                <div className={styles.dayInWeek}><span>Fr</span></div>
                <div className={styles.dayInWeek}><span>Sa</span></div>
                {days.map((day, i) => {

                    const selectedDate = isEqual(start, day) ? styles.selected : '';
                    const belongToRange = isBelongToRangeDate(start, end, day) ? styles.belongToRange : '';
                    const validDate = isValidDate(day) ? '' : styles.inValid;

                    const classes = [selectedDate, belongToRange, validDate].join(' ');

                    return (
                        <div key={`day-${i}`} className={styles.day}>
                            {day &&
                                <span className={classes} onClick={() => onChangeDate(day)}>
                                    {day.getDate()}
                                </span>
                            }
                        </div>
                    )
                })}
            </div>
        </div>
    )
}

MonthPicker.propTypes = {
    days: PropTypes.arrayOf(Date).isRequired,
    payPeriodList: PropTypes.arrayOf(Object),
    start: PropTypes.instanceOf(Date).isRequired,
    end: PropTypes.instanceOf(Date),
    calendar: PropTypes.object,
    onChangeDate: PropTypes.func.isRequired,
    prevMonth: PropTypes.func.isRequired,
    nextMonth: PropTypes.func.isRequired
}

export default MonthPicker;