import React from 'react'
import PropTypes from 'prop-types'
import {
    Modal,
    Card,
    CardContent,
    CardActions,
    Button,
    colors,
    Typography,
    Divider,
    CardHeader
} from '@material-ui/core'
import { makeStyles } from '@material-ui/styles'

const useStyles = makeStyles(theme => ({
    styleModal: {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        outline: 'none',
        boxShadow: theme.shadows[20],
        width: 800,
        maxHeight: '100%',
        overflowY: 'auto',
        maxWidth: '100%',
        [theme.breakpoints.down('sm')]: {
            width: 500
        },
    },
    conButton: {
        padding: '16px',
        justifyContent: 'flex-end'
    },
    button: {
        flexShrink: '0',
        backgroundColor: colors.green[600],
        '&:hover': {
            backgroundColor: colors.green[900]
        },
    },
}))

const ConfirmRecurJobModal = props => {
    const classes = useStyles();

    const { open, recurrable, removing, onConfirm, closeConfirm } = props;

    let title, yesBtn, noBtn;
    if (removing) {
        title = 'This job is recurring.  Would you like to remove all future instances of this recurring job from this recurring route?';
        yesBtn = 'Yes, remove';
        noBtn = 'No, remove this instance but keep future instances';
    } else {
        if (recurrable) {
            title = 'Would you like to recur job along with this recurring route, or just add it once here?';
            yesBtn = 'Yes, recur';
            noBtn = 'No, just once';
        } else {
            title = 'Job Splits can be added to a recurring route once but the split cannot be recurred.'
            yesBtn = null;
            noBtn = 'OK';
        }
    }

    return (
        <Modal
            open={open}
            onClose={onConfirm}
        >
            <Card className={classes.styleModal}>
                <CardHeader title="Warning" />
                <Divider />
                <CardContent style={{ padding: '20px 16px' }}>
                    <Typography style={{ fontSize: '16px' }}>
                        {title}
                    </Typography>
                </CardContent>
                <Divider />
                <CardActions className={classes.conButton}>
                    <Button
                        variant="contained"
                        size="large"
                        onClick={closeConfirm}>
                        Cancel
                    </Button>
                    {yesBtn && <Button
                        variant="contained"
                        color="primary"
                        size="large"
                        onClick={() => onConfirm(true)}
                        className={classes.button}>
                        {yesBtn}
                    </Button>}
                    {noBtn && <Button
                        variant="contained"
                        color="primary"
                        size="large"
                        onClick={() => onConfirm(false)}
                        className={classes.button}>
                        {noBtn}
                    </Button>}
                </CardActions>
            </Card>
        </Modal>
    )
}

ConfirmRecurJobModal.propTypes = {
    open: PropTypes.bool.isRequired,
    recurrable: PropTypes.bool,
    removing: PropTypes.bool.isRequired,
    onConfirm: PropTypes.func.isRequired
}

export default ConfirmRecurJobModal;
