import React, { useEffect, useState } from 'react'
import {
    Card, CardHeader, CardContent, CardActions, Divider,
    Typography, TextField, Button, colors, Snackbar, Grid, CircularProgress,
} from '@material-ui/core'
import Alert from '@material-ui/lab/Alert'
import { makeStyles } from '@material-ui/styles'
import useMediaQuery from '@material-ui/core/useMediaQuery'
import { Page } from "components";
import { AccessControl } from "components/AccessControl/AccessControl";
import { PERMISSIONS } from "common/permissions";
import localStorage from 'utils/localStorage'
import axios from "utils/axios";
import apiConfig from "apiConfig";
import constants from 'constants.js';

const useStyles = makeStyles(theme => ({
    btn: {
        display: 'block',
        color: theme.palette.white,
        backgroundColor: colors.green[600],
        '&:hover': {
            backgroundColor: colors.green[900]
        }
    },
    label: {
        fontSize: '15px',
        fontWeight: 500
    },
    transferCity: {
        display: 'flex',
        marginTop: '16px',
        [theme.breakpoints.down('xs')]: {
            display: 'block'
        }
    },
    cities: {
        width: '45%',
        border: '1px solid #b3b3b3',
        borderRadius: '4px',
        position: 'relative',
        [theme.breakpoints.down('xs')]: {
            width: '100%'
        }
    },
    overBox: {
        width: '100%',
        height: '200px',
        overflowY: 'auto',
        padding: '16px 8px 8px',
        display: 'flex',
        flexDirection: 'column'
    },
    labelCity: {
        position: 'absolute',
        top: '-10px',
        left: '10px',
        backgroundColor: '#ffffff',
        padding: '0 3px',
        fontWeight: 500
    },
    city: {
        paddingLeft: '3px',
        cursor: 'pointer'
    },
    filterCity: {
        marginBottom: '5px',
        '& .MuiInput-input': {
            paddingLeft: '4px'
        }
    },
    listItems: {
        flex: 1,
        overflowY: 'auto',
        '&::-webkit-scrollbar': {
            width: '5px'
        },
        '&::-webkit-scrollbar-track': {
            backgroundColor: '#e4e4e4'
        },
        '&::-webkit-scrollbar-thumb': {
            backgroundColor: '#9c9c9c',
            borderRadius: '60px'
        }
    },
    transferActions: {
        width: '10%',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'space-around',
        [theme.breakpoints.down('xs')]: {
            width: '100%',
            flexDirection: 'row',
            margin: '16px 0'
        },
        '& p': {
            width: '50%',
            textAlign: 'center',
            borderRadius: '4px',
            padding: '6px 0',
            backgroundColor: colors.green[600],
            color: '#ffffff',
            fontSize: '20px',
            [theme.breakpoints.down('xs')]: {
                width: '20%'
            },
            '&:hover': {
                cursor: 'pointer',
                backgroundColor: colors.green[900]
            }
        }
    }
}));

const TransferCompanyData = props => {

    const classes = useStyles();
    const ipPlus = useMediaQuery('(max-width: 415px)');
    const ipad = useMediaQuery('(max-width: 769px)');
    const user = localStorage.getFullInfo();

    const [listCompany, setListCompany] = useState(localStorage.getListCompany());
    const [fromCompany, setFromCompany] = useState();
    const [toCompany, setToCompany] = useState();
    const [qbSuffix, setQbSuffix] = useState('');
    const [openSnackbar, setOpenSnackBar] = useState(false);
    const [isStatus, setIsStatus] = useState({ failed: false, msg: '' });
    const [cities, setCities] = useState({ excluded: [], included: [] });
    const [filterValue, setFilterValue] = useState({ excluded: '', included: '' });
    const [selectedCity, setSelectedCity] = useState({ excluded: null, included: null });
    const [loadingCities, setLoadingCities] = useState(false);
    const [transfering, setTransfering] = useState(false);

    // transfer actions
    const transferOneToIncluded = () => {
        const cts = [...cities.excluded];
        const city = cts.splice(selectedCity.excluded, 1);
        setSelectedCity({ ...selectedCity, excluded: null });
        setCities({ excluded: cts, included: [...cities.included, city[0]] })
    }
    const transferAllToIncluded = () => {
        const cts = [...cities.excluded];
        setCities({ excluded: [], included: cities.included.concat(cts) })
    }
    const transferOneToExcluded = () => {
        const cts = [...cities.included];
        const city = cts.splice(selectedCity.included, 1);
        setSelectedCity({ ...selectedCity, included: null });
        setCities({ excluded: [...cities.excluded, city[0]], included: cts })
    }
    const transferAllToExcluded = () => {
        const cts = [...cities.included];
        setCities({ excluded: cities.excluded.concat(cts), included: [] })
    }
    // filter cities
    const onChangeFilter = event => {
        const { name, value } = event.target;
        setFilterValue({ ...filterValue, [name]: value });
    }

    const transfer = () => {
        setTransfering(true);
        axios
            .post(
                apiConfig.url.BASE_URL + apiConfig.url.COMPANY_TRANSFER_CUSTOMERS,
                {
                    fromCompany,
                    toCompany,
                    qbSuffix,
                    zipCodes: cities.included.map(x => x.zipCode)
                }
            )
            .then(res => {
                if (res.data) {
                    setIsStatus({ failed: false, msg: 'Transfered successfully.' });
                } else {
                    setIsStatus({ failed: true, msg: constants.msg.GENERAL_ERROR });
                }
            })
            .catch(error => {
                setIsStatus({ failed: true, msg: constants.msg.GENERAL_ERROR });

            })
            .finally(() => {
                setTransfering(false);
                setOpenSnackBar(true);
            });
    }

    useEffect(() => {
        if (!listCompany) {
            axios
                .get(apiConfig.url.BASE_URL +
                    apiConfig.url.COMPANY_MULTI.replace("{userId}", user.nameid)
                )
                .then(res => {
                    setListCompany(res.data);
                    localStorage.set("listCompany", JSON.stringify(res.data));
                });
        }
    }, []);

    useEffect(() => {
        if (fromCompany) {
            setLoadingCities(true);
            axios.get(
                apiConfig.url.BASE_URL + apiConfig.url.COMPANY_ALL_CUSTOMER_ADDRESSES,
                { params: { companyId: fromCompany } }
            ).then(res => {
                if (!res?.data) return;
                res.data.forEach(x => x.display = `${x.city}, ${x.state?.code} ${x.zipCode}`);
                setCities({ excluded: res.data, included: [] });
            }).finally(() => setLoadingCities(false));
        } else {
            setCities({ excluded: [], included: [] });
        }

        setSelectedCity({ excluded: null, included: null });
    }, [fromCompany]);

    return (
        <AccessControl requiredPermission={PERMISSIONS.page.superAdmin} redirect>
            <Page title={'Super admin - Customer Transfer'}>
                <div style={{ padding: 32 }}>
                    <Card>
                        <CardHeader title='Transfer' />
                        <Divider />
                        <CardContent>
                            <Grid container spacing={3}>
                                <Grid item xs={12}>
                                    <TextField
                                        fullWidth
                                        label='From Company'
                                        name='fromCompany'
                                        variant='outlined'
                                        select
                                        SelectProps={{ native: true }}
                                        value={fromCompany || null}
                                        onChange={event => setFromCompany(event.target.value)}>
                                        <option value=''></option>
                                        {listCompany?.map(company => (
                                            <option value={company.id}>{company.name}{company.legalName && ` (${company.legalName})`}</option>
                                        ))}
                                    </TextField>
                                </Grid>
                                <Grid item xs={12}>
                                    <TextField
                                        fullWidth
                                        label='To Company'
                                        name='toCompany'
                                        variant='outlined'
                                        select
                                        SelectProps={{ native: true }}
                                        value={toCompany || null}
                                        onChange={event => setToCompany(event.target.value)}>
                                        <option value=''></option>
                                        {listCompany?.map(company => (
                                            <option value={company.id}>{company.name}{company.legalName && ` (${company.legalName})`}</option>
                                        ))}
                                    </TextField>
                                </Grid>
                                <Grid item xs={12}>
                                    <TextField
                                        fullWidth
                                        label='QB Suffix for Transferred Customers'
                                        name='QBSuffix'
                                        variant='outlined'
                                        value={qbSuffix}
                                        onChange={event => setQbSuffix(event.target.value)}
                                    />
                                </Grid>
                            </Grid>

                            <Typography style={{ color: '#ff0000', fontWeight: 500, marginTop: '40px' }}>
                                Note that the following tax groups do not exist in the destination company.
                            </Typography>

                            <Typography className={classes.label} style={{ marginTop: '32px' }}>Choose Geography:</Typography>
                            <div className={classes.transferCity}>
                                <div className={classes.cities}>
                                    <Typography className={classes.labelCity}>Excluded Cities</Typography>
                                    <div className={classes.overBox}>
                                        <TextField
                                            fullWidth
                                            className={classes.filterCity}
                                            name='excluded'
                                            placeholder='Search cities...'
                                            variant='standard'
                                            value={filterValue.excluded}
                                            onChange={onChangeFilter}
                                        />
                                        {loadingCities &&
                                            <Grid
                                                container
                                                spacing={0}
                                                align="center"
                                                justifyContent="center"
                                                direction="column"
                                                style={{ position: 'absolute', top: 51 }}>
                                                <Grid item>
                                                    <CircularProgress size={50} />
                                                </Grid>
                                            </Grid>
                                        }
                                        <div className={classes.listItems}>
                                            {cities.excluded
                                                .map((city, index) => (
                                                    city.display?.toLowerCase()?.includes(filterValue.excluded?.toLowerCase()) &&
                                                    <Typography className={classes.city} key={index}
                                                        style={index === selectedCity.excluded ? { backgroundColor: '#43a047', color: '#ffffff' } : null}
                                                        onClick={() => setSelectedCity({ ...selectedCity, excluded: selectedCity.excluded !== index ? index : null })}>
                                                        {city.display}
                                                    </Typography>
                                                ))
                                            }
                                        </div>
                                    </div>
                                </div>
                                <div className={classes.transferActions}>
                                    <Typography style={selectedCity.excluded === null ? {
                                        pointerEvents: 'none',
                                        opacity: 0.7,
                                        color: 'rgba(0, 0, 0, 0.26)',
                                        backgroundColor: 'rgb(217 217 217)',
                                    } : null}
                                        onClick={transferOneToIncluded}>
                                        {'>'}
                                    </Typography>
                                    <Typography style={selectedCity.included === null ? {
                                        pointerEvents: 'none',
                                        opacity: 0.7,
                                        color: 'rgba(0, 0, 0, 0.26)',
                                        backgroundColor: 'rgb(217 217 217)',
                                    } : null}
                                        onClick={transferOneToExcluded}>
                                        {'<'}
                                    </Typography>
                                    <Typography style={cities.excluded.length === 0 ? {
                                        pointerEvents: 'none',
                                        opacity: 0.7,
                                        color: 'rgba(0, 0, 0, 0.26)',
                                        backgroundColor: 'rgb(217 217 217)',
                                    } : null}
                                        onClick={transferAllToIncluded}>
                                        {'>>'}
                                    </Typography>
                                    <Typography style={cities.included.length === 0 ? {
                                        pointerEvents: 'none',
                                        opacity: 0.7,
                                        color: 'rgba(0, 0, 0, 0.26)',
                                        backgroundColor: 'rgb(217 217 217)',
                                    } : null}
                                        onClick={transferAllToExcluded}>
                                        {'<<'}
                                    </Typography>
                                </div>
                                <div className={classes.cities}>
                                    <Typography className={classes.labelCity}>Included Cities</Typography>
                                    <div className={classes.overBox}>
                                        <TextField
                                            fullWidth
                                            className={classes.filterCity}
                                            name='included'
                                            placeholder='Search cities...'
                                            variant='standard'
                                            value={filterValue.included}
                                            onChange={onChangeFilter}
                                        />
                                        <div className={classes.listItems}>
                                            {cities.included
                                                .map((city, index) => (
                                                    city.display?.toLowerCase()?.includes(filterValue.included?.toLowerCase()) &&
                                                    <Typography className={classes.city} key={index}
                                                        style={index === selectedCity.included ? { backgroundColor: '#43a047', color: '#ffffff' } : null}
                                                        onClick={() => setSelectedCity({ ...selectedCity, included: selectedCity.included !== index ? index : null })}>
                                                        {city.display}
                                                    </Typography>
                                                ))
                                            }
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </CardContent>
                        <Divider />
                        <CardActions style={{ paddingLeft: '16px' }}>
                            <Button className={classes.btn} disabled={transfering} onClick={transfer}>
                                Transfer Customers
                            </Button>
                            {transfering && <CircularProgress size={30} />}
                        </CardActions>
                    </Card>

                    <Snackbar anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
                        open={openSnackbar}
                        autoHideDuration={3000}
                        onClose={() => setOpenSnackBar(false)}>
                        <Alert elevation={6} variant="filled" severity={isStatus.failed ? 'error' : 'success'}>
                            <Typography
                                color="inherit"
                                variant="h6">
                                {isStatus.msg}
                            </Typography>
                        </Alert>
                    </Snackbar>
                </div>
            </Page>
        </AccessControl>
    )
}

export default TransferCompanyData;
