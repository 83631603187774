import React, { useState, useEffect } from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/styles';
import { AvailableSchedule, AddAvailability } from './components';
import Fab from '@material-ui/core/Fab';
import AddIcon from '@material-ui/icons/Add';
import { Alert } from '@material-ui/lab';
import { ConfirmModal } from 'components'
import {
    Card, CardContent, CardHeader, Divider,
    Table, TableHead, TableBody, TableRow, TableCell,
    Typography, Grid, CircularProgress,
    Snackbar, Tooltip, withStyles, ButtonGroup,
    FormControlLabel, CardActions,IconButton,colors
} from '@material-ui/core';
import { GreenCheckbox } from 'components';
import { useSelector, useDispatch } from 'react-redux'
import axios from 'utils/axios';
import apiConfig from 'apiConfig';
import ConfirmModalFullTime from "./components/ConfirmModalFullTime";
import { DatePicker } from "@material-ui/pickers";
import { createTheme } from "@material-ui/core/styles";
import localStorage from 'utils/localStorage'
import { PERMISSIONS } from "common/permissions";
import { AddBox } from '@material-ui/icons';
import { checkFeature, FeatureFlags } from "FeatureService";

const useStyles = makeStyles(theme => ({
    cardReadOnly: {
        pointerEvents: 'none'
    },
    cusFontsize: {
        '& th': {
            fontSize: '15px'
        }
    },
    fab: {
        position: 'fixed',
        bottom: theme.spacing(4),
        right: theme.spacing(4),
    },
    type: {
        backgroundColor: theme.palette.white
    },
    responsiveTable: {
        width: '100%',
        overflowY: 'auto'
    },
    customTableCell: {
        borderBottom: '1px solid #969696'
    },
    notifSearch: {
        fontSize: theme.spacing(2)
    },
    circularProgress: {
        marginTop: theme.spacing(3)
    },
    addStyle: {
        '& span svg': {
            color: colors.green[600],
            '&:hover': {
                color: colors.green[900]
            }
        }
    }
}));

const Availability = props => {
    const { userId, className, ...rest } = props;

    const readOnly = localStorage.getUserRole() === PERMISSIONS.FIELD_TECHNICIAN;

    const dispatch = useDispatch();
    const { schedules } = useSelector(state => state.ScheduleReducer);

    const classes = useStyles();

    const [user, setUser] = useState();
    const [excludeFromRouteResources, setExcludeFromRouteResources] = useState(false);
    const [openAvailability, setOpenAvailability] = useState(false);
    const [scheduleEdit, setScheduleEdit] = useState();
    const [schedulesShort, setSchedulesShort] = useState([]);
    const [loading, setLoading] = useState();
    const [openSnack, setOpenSnack] = useState(false);
    const [openConfirm, setOpenConfirm] = useState(false);

    const [removeAvailable, setRemoveAvailable] = useState();
    const [isProgress, setIsProgress] = useState(false);
    const [fullDateItem, setFullDateItem] = useState({ item: {}, index: null });
    const [openConfirmFullTime, setOpenConfirmFullTime] = useState(false);
    const [isProgressFullTime, setIsProgressFullTime] = useState(false);
    const [isUpdateExclude, setIsUpdateExclude] = useState(false);

    const [openPicker, setOpenPicker] = useState(false);
    const [date, setDate] = useState('');
    const [status, setStatus] = useState({ isFailed: false, msg: '' })
    const [notFullTime, setNotFullTime] = useState(false);

    const [bidder, setBidder] = useState(false);
    const [isUpdateBidder, setIsUpdateBidder] = useState(false);

    const [isEnableBidsToDo, setIsEnableBidsToDo] = useState();
    checkFeature(FeatureFlags.BIDS_TO_DO).then(setIsEnableBidsToDo);

    const checkNoEndDate = (schedules) => {
        let fullTime = false;
        schedules.map((item, index) => {
            if (item.startDate && !item.endDate) {
                fullTime = true;
                setFullDateItem({ item, index });
            }
        })
        return setNotFullTime(fullTime);
    }

    // Availability
    const newScheduleModal = () => {
        if (notFullTime) {
            setOpenConfirmFullTime(true)
            return
        }

        setScheduleEdit(null)
        setOpenAvailability(true);
    }
    const updateSFU = (date) => {
        setIsProgressFullTime(true);
        const payload = { ...fullDateItem.item, endDate: (new Date(date)).toISOString() };
        axios.post(apiConfig.url.USER_URL + apiConfig.url.EMPLOYEE_AVAILABILITY_UPDATE, payload)
            .then(res => {
                dispatch({ type: 'UPDATE_SCHEDULE', schedule: payload, index: fullDateItem.index });
                setStatus({ isFailed: false, msg: 'Update successfuly!' });
                setOpenSnack(true);
                setOpenConfirmFullTime(false);
                setScheduleEdit(null);
                setOpenAvailability(true);
            })
            .catch(err => {
                setStatus({ isFailed: true, msg: 'Update failed, please try again later.' })
                setOpenSnack(true);
            })
            .finally(() => setIsProgressFullTime(false))
    }

    const editSchedule = (schedule, index) => {
        setScheduleEdit({ schedule, index });
        setOpenAvailability(true);
    }

    const setRemove = (id, index) => {
        setRemoveAvailable({ id, index });
        setOpenConfirm(true)
    }
    const onRemove = () => {
        setIsProgress(true)
        axios.delete(apiConfig.url.USER_URL + apiConfig.url.EMPLOYEE_AVAILABILITY_DELETE + removeAvailable.id)
            .then(() => {
                dispatch({ type: 'DEL_SCHEDULE', index: removeAvailable.index });
                setStatus({ failed: false, msg: 'Removed successfuly!' });
                setOpenSnack(true);
            })
            .catch(() => {
                setStatus({ failed: true, msg: 'Remove failed, please try again later.' });
                setOpenSnack(true);
            })
            .finally(() => {
                setIsProgress(false);
                setOpenConfirm(false)
            })
    }

    const fetchUser = () => {
        axios.get(apiConfig.url.USER_URL + apiConfig.url.EMPLOYEE_INFO + userId).then(res => {
            setUser(res.data)
            setExcludeFromRouteResources(res.data.excludeFromRouteResources || false);
            setBidder(res.data.canBid || false);
        });
    };

    const handleUpdateExclude = (exclude) => {
        setIsUpdateExclude(true);
        setExcludeFromRouteResources(exclude);
        user.excludeFromRouteResources = exclude;
        axios.post(apiConfig.url.USER_URL + apiConfig.url.EMPLOYEE_UPDATE, user)
            .then(res => {
                setStatus({ failed: false, msg: 'Change successfully.' });
                setOpenSnack(true);
            })
            .catch(err => {
                setStatus({ failed: true, msg: 'An error occurred, please try again later' });
                setOpenSnack(true);
            })
            .finally(() => {
                setIsUpdateExclude(false);
            });
    }

    const handleUpdateBidder = (bidder) => {
        setIsUpdateBidder(true);
        setBidder(bidder);
        user.canBid = bidder;
        axios.post(apiConfig.url.USER_URL + apiConfig.url.EMPLOYEE_UPDATE, user)
            .then(res => {
                setStatus({ failed: false, msg: 'Change successfully.' });
                setOpenSnack(true);
            })
            .catch(err => {
                setStatus({ failed: true, msg: 'An error occurred, please try again later' });
                setOpenSnack(true);
            })
            .finally(() => {
                setIsUpdateBidder(false);
            });
    }

    useEffect(() => {
        fetchUser();
    }, []);

    useEffect(() => {
        let noEndDate = [];
        let hasEndDate = [];
        schedules.map(item => {
            if (!item.endDate) {
                noEndDate.push(item)
            } else {
                hasEndDate.push(item)
            }
        })
        hasEndDate = hasEndDate.sort((a, b) => {
            return (new Date(b.endDate || '01/01/1970')) - (new Date(a.endDate || '01/01/1970'))
        });
        setSchedulesShort([...noEndDate, ...hasEndDate]);
        checkNoEndDate([...noEndDate, ...hasEndDate]);
    }, [schedules])

    useEffect(() => {
        const fetchAvailability = () => {
            setLoading(true)
            axios.get(apiConfig.url.USER_URL + apiConfig.url.EMPLOYEE_AVAILABILITY_GET + userId)
                .then(res => dispatch({ type: 'INIT_SCHEDULE', schedules: res.data.availabilities }))
                .finally(e => setLoading(false))
        }

        fetchAvailability();
    }, [userId])

    if (loading) {
        return (
            <Grid
                container
                spacing={0}
                align="center"
                justifyContent="center"
                direction="column">
                <Grid item>
                    <CircularProgress className={classes.circularProgress} size={50} />
                </Grid>
            </Grid>
        )
    }

    return (
        <div
            {...rest}
            className={clsx(classes.root, className, readOnly ? classes.cardReadOnly : null)}>

            <Card style={{ marginTop: '20px' }}>
                <CardHeader
                    title={
                        <>
                            <span style={{ marginRight: '50px' }}>Availability</span>
                            <CardActions>
                            <FormControlLabel
                                name='ExcludeFromRouteResources'
                                label='Exclude From Route Resources'
                                checked={excludeFromRouteResources}
                                disabled={isUpdateExclude}
                                onChange={(event) => handleUpdateExclude(event.target.checked)}
                                control={
                                    <GreenCheckbox />
                                }
                            />
                            {isUpdateExclude && <CircularProgress size={24} />}
                            {isEnableBidsToDo
                            ? <FormControlLabel
                                name='Salesperson'
                                label='Salesperson'
                                checked={bidder}
                                disabled={isUpdateBidder}
                                onChange={(event) => handleUpdateBidder(event.target.checked)}
                                control={
                                    <GreenCheckbox />
                                }
                                />
                            : null }
                            {isUpdateBidder && isEnableBidsToDo && <CircularProgress size={24} />}
                            </CardActions>
                        </>
                    }
                />
                <Divider />
                <CardContent className={classes.responsiveTable}>
                    <Table>
                        <TableHead>
                            <TableRow className={classes.cusFontsize}>
                                <TableCell className={classes.customTableCell}>Date</TableCell>
                                <TableCell className={classes.customTableCell} align="center">Sunday</TableCell>
                                <TableCell className={classes.customTableCell} align="center">Monday</TableCell>
                                <TableCell className={classes.customTableCell} align="center">Tuesday</TableCell>
                                <TableCell className={classes.customTableCell} align="center">Wednesday</TableCell>
                                <TableCell className={classes.customTableCell} align="center">Thursday</TableCell>
                                <TableCell className={classes.customTableCell} align="center">Friday</TableCell>
                                <TableCell className={classes.customTableCell} align="center">Saturday</TableCell>
                                <TableCell style={{ width: '1%', textAlign: 'center'  }} className={classes.customTableCell}>
                                    <IconButton onClick={newScheduleModal} className={classes.addStyle}>
                                        <AddBox fontSize='large' />
                                    </IconButton>
                                </TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {schedulesShort && schedulesShort.length !== 0 ?
                                schedulesShort.map((schedule, index) =>
                                    <AvailableSchedule
                                        key={schedule.id}
                                        schedule={schedule}
                                        editSchedule={() => editSchedule(schedule, index)}
                                        removeSchedule={() => setRemove(schedule.id, index)}
                                    />
                                ) : (
                                    <TableRow>
                                        <TableCell colSpan={8}>
                                            <Typography className={classes.notifSearch}>No data found</Typography>
                                        </TableCell>
                                    </TableRow>
                                )
                            }
                        </TableBody>
                    </Table>
                </CardContent>
                <Divider />
            </Card>


            <AddAvailability
                lstSchedule={schedulesShort}
                schedule={scheduleEdit}
                userId={userId}
                open={openAvailability}
                onClose={() => setOpenAvailability(false)}
            />

            <ConfirmModalFullTime
                openConfirm={openConfirmFullTime}
                closeConfirm={() => setOpenConfirmFullTime(false)}
                onConfirm={() => {
                    setOpenPicker(true);
                }}
                isProgress={isProgressFullTime}
            />

            <ConfirmModal
                openConfirm={openConfirm}
                closeConfirm={() => setOpenConfirm(false)}
                onConfirm={onRemove}
                isProgress={isProgress}
            />
            <DatePicker
                style={{ display: 'none' }}
                open={openPicker}
                onClose={() => setOpenPicker(false)}
                onChange={date => updateSFU(date)}
                variant='dialog'
                minDate={fullDateItem.item ? new Date(fullDateItem.item.startDate) : ''}
                value={date ? date : new Date()}
            />

            <Snackbar
                open={openSnack}
                onClose={() => setOpenSnack(false)}
                anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
                autoHideDuration={3000}>
                <Alert elevation={6} variant='filled' severity={status.failed ? 'error' : 'success'}>
                    <Typography variant='h6' color='inherit'>
                        {status.msg}
                    </Typography>
                </Alert>
            </Snackbar>
        </div>
    );
};

Availability.propTypes = {
    className: PropTypes.string
};

export default Availability;
