import React, { useState, useEffect, useRef } from 'react'
import PropTypes from 'prop-types'
import { makeStyles } from '@material-ui/styles'
import {
    Modal, Card, CardHeader, CardContent, Divider,
    Grid, Typography, FormControlLabel, Button,
    colors, CardActions, TextField, IconButton, CircularProgress
} from '@material-ui/core'
import { Clear } from '@material-ui/icons'
import moment from 'moment'
import { KeyboardDatePicker, TimePicker, KeyboardTimePicker } from '@material-ui/pickers'
import { GreenCheckbox } from 'components'
import Snackbar from "@material-ui/core/Snackbar";
import Alert from "@material-ui/lab/Alert";
import axios from "utils/axios";
import apiConfig from "apiConfig";

const useStyles = makeStyles(theme => ({
    styleModal: {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        outline: 'none',
        boxShadow: theme.shadows[20],
        width: 700,
        maxHeight: '90%',
        overflowY: 'auto',
        maxWidth: '100%',
        [theme.breakpoints.down('sm')]: {
            width: 500
        },
    },
    exclude: {
        display: 'flex',
        alignItems: 'center',
        flexWrap: 'wrap'
    },
    days: {
        display: 'flex',
        flexWrap: 'wrap'
    },
    day: {
        marginLeft: 0,
        width: '55px',
        '& span': {
            padding: 0
        }
    },
    note: {
        textAlign: 'right'
    },
    select: {
        padding: theme.spacing(1),
        boxShadow: '0 0px 12px 0px #cacaca',
        position: 'absolute',
        top: '50%',
        left: '5%',
        zIndex: 2,
        transform: 'translateY(-50%)',
        width: '90%',
        borderRadius: '4px',
        backgroundColor: '#ffffff',
        visibility: 'hidden',
        opacity: '0',
        transition: 'all .3s'
    },
    selectInput: {
        marginBottom: theme.spacing(1),
        position: 'sticky',
        top: 0,
        backgroundColor: '#ffffff'
    },
    clearIcon: {
        position: 'absolute',
        top: '3px',
        right: 0,
    },
    options: {
        maxHeight: '200px',
        overflowY: 'auto',
    },
    option: {
        padding: '8px',
        fontSize: theme.spacing(2),
        cursor: 'pointer',
        '&:hover': {
            backgroundColor: '#e0e0e0'
        }
    },
    actionCard: {
        display: 'flex',
        justifyContent: 'flex-end'
    },
    button: {
        color: theme.palette.white,
        backgroundColor: colors.green[600],
        '&:hover': {
            backgroundColor: colors.green[900]
        },
    },
}));

let mounted = false;
const SplitsModal = ({ jobId, open, onClose, onLoadSplit }) => {

    const classes = useStyles();
    const cleanersRef = useRef();
    const equipmentsRef = useRef();

    const [dates, setDates] = useState({ fromDate: null, toDate: null });
    const [excludeDays, setExcludeDays] = useState([]);
    const [selectedTime, setSelectedTime] = useState(null);
    const [durationTime, handleDurationTime] = useState(null);
    const [cleaners, setCleaners] = useState([]);
    const [allCleaners, setAllCleaners] = useState([]);
    const [openCleaners, setOpenCleaners] = useState(false);
    const [equipments, setEquipments] = useState([]);
    const [allEquipments, setAllEquipments] = useState([]);
    const [openEquipments, setOpenEquipments] = useState(false);
    const [text, setText] = useState('');
    const [isGenerate, setIsGenerate] = useState(false);
    const [isStatus, setIsStatus] = useState({ failed: false, msg: '' });
    const [openSnackbar, setOpenSnackBar] = useState(false);
    const [fromDateBlur, setFromDateBlur] = useState(false);
    const [toDateBlur, setToDateBlur] = useState(false);
    const [timeBlur, setTimeBlur] = useState(false);
    const [durationBlur, setDurationBlur] = useState(false);

    const onKeyDown = event => {
        if (event.keyCode === 13 && text) {
            event.preventDefault();
            onChangeSelect(text, 'equipment');
            setText('');
        }
    }

    // change split
    const handleRangeDateChange = (date, value) => {
        setDates({ ...dates, fromDate: value })
        if (selectedTime) {
            const time = moment(selectedTime);
            time.date(moment(value).date());
            time.month(moment(value).month());
            time.year(moment(value).year());
            setSelectedTime(time);
        }
    }
    const handleThruDateChange = (date, value) => {
        setDates({ ...dates, toDate: value })
    }
    const onChangeExcludeDays = val => {
        const dayIndex = excludeDays.findIndex(day => day === val);
        const days = [...excludeDays];
        dayIndex > -1 ? days.splice(dayIndex, 1) : days.push(val);
        setExcludeDays(days)
    }

    const handleTimeChange = value => {
        const time = moment(value);
        if (dates.fromDate) {
            time.date(moment(dates.fromDate).date());
            time.month(moment(dates.fromDate).month());
            time.year(moment(dates.fromDate).year());
        }

        setSelectedTime(time);
    }

    // get cleaners and equipments
    const getCleaners = () => {
        if (Array.isArray(cleaners) && cleaners.length > 0) {
            return cleaners.map(clean => clean.fullName).join(', ')
        }

        return ''
    }
    const getEquipments = () => {
        if (Array.isArray(equipments) && equipments.length > 0) {
            return equipments.map(eqm => eqm.description).join(', ')
        }

        return ''
    }
    const onChangeSelect = (val, type) => {
        if (type === 'cleaner') {
            if (cleaners.find(x => x.id === val.id)) {
                const _cleaners = cleaners.filter(cleaner => cleaner.id !== val.id)
                setCleaners(_cleaners);
            } else {
                setCleaners([...cleaners, val])
            }
        } else {
            if (equipments.find(x => x.id === val.id)) {
                const _equipments = equipments.filter(e => e.id !== val.id)
                setEquipments(_equipments);
            } else {
                setEquipments([...equipments, val])
            }

        }
    }
    const onCloseModal = () => {
        setDates({ fromDate: null, toDate: null });
        setExcludeDays([]);
        handleTimeChange(null);
        handleDurationTime(null);
        openCleaners && setOpenCleaners(false);
        openEquipments && setOpenEquipments(false);

        setFromDateBlur(false);
        setToDateBlur(false);
        setTimeBlur(false);
        setDurationBlur(false);

        onClose();
    }

    const generateSplitJobs = () => {
        if (
            (!dates.fromDate || !moment(dates.fromDate).isValid()) ||
            (!dates.toDate || !moment(dates.toDate).isValid()) ||
            !selectedTime ||
            (!durationTime || !moment(durationTime).isValid())
        ) {
            setFromDateBlur(true);
            setToDateBlur(true);
            setTimeBlur(true);
            setDurationBlur(true);
            return;
        }
        const duration = moment(new Date(durationTime)).format('LT');
        const timeArray = duration.split(/[:\s]/g);
        timeArray[0] = timeArray[2] === 'PM' ? parseInt(timeArray[0]) + 12 : parseInt(timeArray[0])
        const postData = {
            jobId,
            fromDate: new Date(dates.fromDate).toISOString(),
            toDate: new Date(dates.toDate).toISOString(),
            excludeDays: excludeDays,
            starttime: new Date(selectedTime).toISOString(),
            duration: timeArray[0] * 60 + parseInt(timeArray[1]),
            cleaners: Array.isArray(cleaners) ? cleaners.map(c => c.id) : [],
            equipment: Array.isArray(equipments) ? equipments.map(e => e.id) : []
        };
        setIsGenerate(true);
        axios.post(apiConfig.url.BASE_URL + apiConfig.url.CUSTOMER_SPLIT_JOB.replace('{jobId}', jobId), postData)
            .then(res => {
                const { splitJobs, unavailableEmployees, unavailableEquipment } = res.data;

                if (splitJobs.length > 0) {
                    setIsStatus({ failed: false, msg: 'Job Split Generation Complete' });
                    setOpenSnackBar(true);
                    onCloseModal();
                    onLoadSplit();
                } else if (
                    unavailableEmployees &&
                    unavailableEquipment &&
                    unavailableEmployees.length > 0 &&
                    unavailableEquipment.length > 0
                ) {
                    setIsStatus({ failed: true, msg: `Sorry, this route can not be cloned because ${unavailableEmployees[0].firstName} ${unavailableEmployees[0].lastName} and ${unavailableEquipment[0].assetTag} are not available on one or more of the selected days.` });
                    setOpenSnackBar(true)
                } else if (unavailableEmployees && unavailableEmployees.length > 0) {
                    setIsStatus({ failed: true, msg: `Sorry, this route can not be cloned because ${unavailableEmployees[0].firstName} ${unavailableEmployees[0].lastName} are not available on one or more of the selected days.` });
                    setOpenSnackBar(true)
                } else if (unavailableEquipment && unavailableEquipment.length > 0) {
                    setIsStatus({ failed: true, msg: `Sorry, this route can not be cloned because ${unavailableEquipment[0].assetTag} are not available on one or more of the selected days.` });
                    setOpenSnackBar(true)
                }
            })
            .catch(() => {
                setIsStatus({ failed: true, msg: 'An error occurred, please try again later' });
                setOpenSnackBar(true);
            })
            .finally(() => {
                setIsGenerate(false)
            })
    }

    useEffect(() => {
        mounted = true;

        const date = new Date().toISOString()
        axios.get(apiConfig.url.USER_URL + apiConfig.url.COMPANY_EMPLOYEES_AVAILABLE, { params: { date: date } })
            .then(res => {
                mounted && setAllCleaners(res.data.users);
            })
        axios.get(apiConfig.url.BASE_URL + apiConfig.url.EQUIPMENTS_FOR_ROUTE + date)
            .then(res => {
                mounted && setAllEquipments(res.data.data);
            })

        return () => mounted = false;
    }, [])

    useEffect(() => {
        const clickOutsideSelect = (event) => {
            if (openCleaners && cleanersRef.current && !cleanersRef.current.contains(event.target)) {
                setOpenCleaners(false);
            }
            if (openEquipments && equipmentsRef.current && !equipmentsRef.current.contains(event.target)) {
                setOpenEquipments(false);
            }
        }
        document.addEventListener('click', clickOutsideSelect);
        return () => document.removeEventListener('click', clickOutsideSelect)
    }, [openCleaners, openEquipments])

    return (
        <Modal open={open} onClose={onCloseModal}>
            <Card className={classes.styleModal}>
                <CardHeader title='Generate Splits Automatically' />
                <Divider />
                <CardContent>
                    <Grid container spacing={2} style={{ alignItems: 'flex-start' }}>
                        <Grid item xs={12} md={6}>
                            <KeyboardDatePicker
                                error={fromDateBlur && (!dates.fromDate || !moment(dates.fromDate).isValid())}
                                helperText={fromDateBlur && (!dates.fromDate && 'This field is required.' || !moment(dates.fromDate).isValid() && 'Invalid Date Format')}
                                onBlur={_ => setFromDateBlur(true)}
                                fullWidth
                                inputVariant="outlined"
                                label="From Date"
                                format="MM/DD/YYYY"
                                value={dates.fromDate}
                                InputAdornmentProps={{ position: "end" }}
                                onChange={(date, value) => handleRangeDateChange(date, value)}
                            />
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <KeyboardDatePicker
                                error={toDateBlur && (!dates.toDate || !moment(dates.toDate).isValid())}
                                helperText={toDateBlur && (!dates.toDate && 'This field is required.' || !moment(dates.toDate).isValid() && 'Invalid Date Format')}
                                onBlur={_ => setToDateBlur(true)}
                                fullWidth
                                inputVariant="outlined"
                                label="To Date"
                                format="MM/DD/YYYY"
                                value={dates.toDate}
                                InputAdornmentProps={{ position: "end" }}
                                onChange={(date, value) => handleThruDateChange(date, value)}
                            />
                        </Grid>
                        <Grid item xs={12} className={classes.exclude}>
                            <Typography style={{ marginRight: '5px', whiteSpace: 'nowrap' }}>Exclude Days :</Typography>
                            <div className={classes.days}>
                                <FormControlLabel
                                    className={classes.day}
                                    checked={excludeDays.findIndex(day => day === 0) > -1}
                                    onChange={() => onChangeExcludeDays(0)}
                                    control={
                                        <GreenCheckbox />
                                    }
                                    label="Sun"
                                />
                                <FormControlLabel
                                    className={classes.day}
                                    checked={excludeDays.findIndex(day => day === 1) > -1}
                                    onChange={() => onChangeExcludeDays(1)}
                                    control={
                                        <GreenCheckbox />
                                    }
                                    label="Mon"
                                />
                                <FormControlLabel
                                    className={classes.day}
                                    checked={excludeDays.findIndex(day => day === 2) > -1}
                                    onChange={() => onChangeExcludeDays(2)}
                                    control={
                                        <GreenCheckbox />
                                    }
                                    label="Tues"
                                />
                                <FormControlLabel
                                    className={classes.day}
                                    checked={excludeDays.findIndex(day => day === 3) > -1}
                                    onChange={() => onChangeExcludeDays(3)}
                                    control={
                                        <GreenCheckbox />
                                    }
                                    label="Wed"
                                />
                                <FormControlLabel
                                    className={classes.day}
                                    checked={excludeDays.findIndex(day => day === 4) > -1}
                                    onChange={() => onChangeExcludeDays(4)}
                                    control={
                                        <GreenCheckbox />
                                    }
                                    label="Thu"
                                />
                                <FormControlLabel
                                    className={classes.day}
                                    checked={excludeDays.findIndex(day => day === 5) > -1}
                                    onChange={() => onChangeExcludeDays(5)}
                                    control={
                                        <GreenCheckbox />
                                    }
                                    label="Fri"
                                />
                                <FormControlLabel
                                    className={classes.day}
                                    checked={excludeDays.findIndex(day => day === 6) > -1}
                                    onChange={() => onChangeExcludeDays(6)}
                                    control={
                                        <GreenCheckbox />
                                    }
                                    label="Sat"
                                />
                            </div>
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <TimePicker
                                error={timeBlur && !selectedTime}
                                helperText={timeBlur && !selectedTime && 'This field is required.'}
                                onBlur={_ => setTimeBlur(true)}
                                fullWidth
                                label='Start Time'
                                name='startTime'
                                inputVariant='outlined'
                                value={selectedTime}
                                onChange={handleTimeChange}
                            />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <KeyboardTimePicker
                                error={durationBlur && (!durationTime || !moment(durationTime).isValid())}
                                helperText={durationBlur && (!durationTime && 'This field is required.' || !moment(durationTime).isValid() && 'Invalid Time Format')}
                                onBlur={_ => setDurationBlur(true)}
                                fullWidth
                                label="Duration"
                                placeholder="00:00"
                                inputVariant='outlined'
                                ampm={false}
                                keyboardIcon={null}
                                mask="__:__"
                                value={durationTime}
                                onChange={date => handleDurationTime(date)}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <div style={{ position: 'relative' }}>
                                <TextField
                                    fullWidth
                                    aria-readonly='true'
                                    label='Add Cleaners'
                                    variant='outlined'
                                    onClick={() => setOpenCleaners(!openCleaners)}
                                    value={getCleaners()}
                                />
                                <IconButton
                                    onClick={() => setCleaners([])}
                                    className={classes.clearIcon}>
                                    <Clear />
                                </IconButton>
                            </div>
                        </Grid>
                        <Grid item xs={12}>
                            <div style={{ position: 'relative' }}>
                                <TextField
                                    fullWidth
                                    aria-readonly='true'
                                    label='Add Equipment'
                                    variant='outlined'
                                    onClick={() => setOpenEquipments(!openEquipments)}
                                    value={getEquipments()}
                                />
                                <IconButton
                                    onClick={() => setEquipments([])}
                                    className={classes.clearIcon}>
                                    <Clear />
                                </IconButton>
                            </div>
                        </Grid>
                        <Grid item xs={12}>
                            <Typography className={classes.note}>Note: This will add new route(s).</Typography>
                        </Grid>
                    </Grid>
                </CardContent>
                <Divider />
                <CardActions className={classes.actionCard}>
                    <Button
                        onClick={onCloseModal}
                        variant="contained">
                        Cancel
                    </Button>
                    <Button disabled={isGenerate}
                        className={classes.button}
                        variant="contained"
                        color="primary"
                        onClick={generateSplitJobs}>
                        Generate
                    </Button>
                    {isGenerate && <CircularProgress size={24} />}
                </CardActions>

                <div ref={cleanersRef} style={openCleaners ? { visibility: 'visible', opacity: 1 } : null}
                    className={classes.select}>
                    <div className={classes.options}>
                        {Array.isArray(allCleaners) && allCleaners.map((option, index) => (
                            <Typography key={index}
                                style={cleaners.find(x => x.id === option.id) ? {
                                    backgroundColor: '#43a047',
                                    color: '#ffffff',
                                    // pointerEvents: 'none',
                                    opacity: 0.7
                                } : null}
                                className={classes.option}
                                onClick={() => {
                                    // debugger
                                    onChangeSelect(option, 'cleaner')
                                }}>
                                {option.fullName}
                            </Typography>
                        ))}
                    </div>
                </div>

                <div ref={equipmentsRef} style={openEquipments ? { visibility: 'visible', opacity: 1 } : null}
                    className={classes.select}>
                    <div className={classes.options}>
                        {Array.isArray(allEquipments) && allEquipments.map((option, index) => (
                            <Typography key={index}
                                style={equipments.find(x => x.id === option.id) ? {
                                    backgroundColor: '#43a047',
                                    color: '#ffffff',
                                    // pointerEvents: 'none',
                                    opacity: 0.7
                                } : null}
                                className={classes.option}
                                onClick={() => onChangeSelect(option, 'equipment')}>
                                {option.description}
                            </Typography>
                        ))}
                    </div>
                </div>

                <Snackbar anchorOrigin={{ vertical: 'top', horizontal: 'center' }} open={openSnackbar}
                    autoHideDuration={3000}
                    onClose={() => setOpenSnackBar(false)}>
                    <Alert elevation={6} variant="filled" severity={isStatus.failed ? 'error' : 'success'}>
                        <Typography color="inherit" variant="h6">
                            {isStatus.msg}
                        </Typography>
                    </Alert>
                </Snackbar>
            </Card>
        </Modal>
    )
}

SplitsModal.propTypes = {
    open: PropTypes.bool,
    onClose: PropTypes.func
}

export default SplitsModal;
