import React, { useEffect, useMemo, useState, useRef } from 'react'
import {
    TableRow, TableCell,
    TextField, FormControlLabel, IconButton, Button, colors,
    CircularProgress, InputAdornment, CardActions
} from '@material-ui/core'
import NumberFormat from 'react-number-format'
import { Delete } from '@material-ui/icons'
import { GreenCheckbox } from 'components'
import { makeStyles } from '@material-ui/styles'

const useStyles = makeStyles(theme => ({
    btn: {
        whiteSpace: 'nowrap',
        color: theme.palette.white,
        backgroundColor: colors.green[600],
        '&:hover': {
            backgroundColor: colors.green[900]
        }
    },
    btnDis: {
        whiteSpace: 'nowrap',
        color: 'rgba(0, 0, 0, 0.26)',
        backgroundColor: 'rgb(217 217 217)',
        pointerEvents: 'none'
    },
    addIcon: {
        padding: '0',
        '& span svg': {
            fontSize: '40px',
            color: colors.green[600],
            '&:hover': {
                color: colors.green[900]
            }
        }
    },
    headRow: {
        '& th': {
            borderBottom: '1px solid #888888',
            fontSize: '16px',
            padding: '8px 16px'
        }
    },
    bodyRow: {
        '& td': {
            padding: '8px 16px'
        }
    },
    checkbox: {
        margin: 0
    },
    taxCheckbox: {
        '& span': {
            fontSize: '16px',
            fontWeight: 500
        }
    },
    note: {
        padding: '16px',
        fontSize: '16px',
        fontWeight: 500
    },
}));


const PricingLine = React.memo((props) => {

    const classes = useStyles();
    const mounted = useRef(false);

    const {
        index,
        item,
        onBlueItem,
        validateItems,
        onChangeItem,
        saveItemPrice,
        removeItem,
        errorItem,
        errorItemDesc,
        touchItem,
        touchItemDesc,
        listQuickBook,
        saveItems,
        locateItem,
        setLocateItem,
        tax
    } = props;
    const [change, setChange] = useState(false);

    useEffect(() => {
        mounted.current = true;
        if (mounted.current) {
            setChange(item.change);
        }
        return () => mounted.current = true;
    }, [item.change])

    return useMemo(
        () => (
            <>
                <TableRow className={classes.bodyRow}>
                    <TableCell>
                        <TextField
                            fullWidth
                            name='description'
                            variant='outlined'
                            value={item.description || ''}
                            onBlur={() => {
                                setTimeout(() => {
                                    onBlueItem('description', index, touchItem)
                                    validateItems(item, index)
                                }, 0)
                            }}
                            onChange={e => onChangeItem(e, index)}
                            error={errorItem[`prices[${index}].description`]}
                            helperText={
                                (
                                    errorItem[`prices[${index}].description`] &&
                                    touchItem[`prices[${index}].description`]
                                ) && errorItem[`prices[${index}].description`]}
                        />
                    </TableCell>
                    <TableCell align='center'>
                        <NumberFormat
                            customInput={TextField}
                            thousandSeparator
                            allowNegative={false}
                            prefix='$'
                            fullWidth
                            name='price'
                            variant='outlined'
                            value={item.price ? parseFloat(item.price).toFixed(2) : ''}
                            onBlur={() => {
                                onBlueItem('price', index)
                                validateItems(item, index)
                            }}
                            onValueChange={values => {
                                onChangeItem({
                                    target: {
                                        name: 'price',
                                        value: values.value
                                    }
                                }, index)
                            }}
                            error={errorItem[`prices[${index}].price`] && errorItem[`prices[${index}].price`] ? true : false}
                            helperText={touchItem[`prices[${index}].price`] && errorItem[`prices[${index}].price`]}
                        />
                    </TableCell>
                    <TableCell align='center'>
                        <FormControlLabel
                            disabled={!tax}
                            className={classes.checkbox}
                            name='taxable'
                            checked={item.taxable}
                            control={
                                <GreenCheckbox />
                            }
                            label=''
                            onChange={e => onChangeItem(e, index)}
                        />
                    </TableCell>
                    <TableCell align='center'>

                        <TextField
                            fullWidth
                            name='qbItemId'
                            variant='outlined'
                            value={item.qbItemId || ''}
                            SelectProps={{ native: true }}
                            select
                            onBlur={() => {
                                onBlueItem('qbItemId', index)
                                validateItems(item, index)
                            }}
                            onChange={e => onChangeItem(e, index)}
                            error={errorItem[`prices[${index}].qbItemId`] && errorItem[`prices[${index}].qbItemId`] ? true : false}
                            helperText={touchItem[`prices[${index}].qbItemId`] && errorItem[`prices[${index}].qbItemId`]}
                        >
                            <option value=''></option>
                            {listQuickBook && listQuickBook.map(quickBook => (
                                <option
                                    value={quickBook.id}
                                    key={quickBook.id}
                                >{quickBook.description}</option>
                            ))}
                        </TextField>
                    </TableCell>
                    <TableCell align='center'>
                        <FormControlLabel
                            className={classes.checkbox}
                            name='active'
                            checked={item.active}
                            control={
                                <GreenCheckbox />
                            }
                            label=''
                            onChange={e => onChangeItem(e, index)}
                        />
                    </TableCell>
                    <TableCell>
                        <CardActions>
                            <Button
                                    className={!item.change ? classes.btnDis : classes.btn}
                                    variant="contained"
                                    onClick={() => setTimeout(() => {
                                        {
                                            setLocateItem({
                                                index: index,
                                                id: item.id,
                                                add: item.add
                                            });
                                            setChange(true);
                                            saveItemPrice(item, index, true);
                                        }
                                    }, 0)}
                            >
                                Save
                            </Button>
                            {item.saving && change && <CircularProgress size={24}/>}
                        </CardActions>
                    </TableCell>
                    <TableCell>
                        <IconButton onClick={() => {
                            setLocateItem({
                                index: index,
                                id: item.id,
                                add: item.add,
                            });
                            removeItem(item.id, index)
                        }}>
                            <Delete />
                        </IconButton>
                    </TableCell>
                </TableRow>
            </>
        ),
        [
            index,
            item,
            errorItem[`prices[${index}].description`],
            errorItem[`prices[${index}].price`],
            errorItem[`prices[${index}].qbItemId`],
            touchItem[`prices[${index}].description`],
            touchItem[`prices[${index}].price`],
            touchItem[`prices[${index}].qbItemId`],
            change,
            tax
        ]

    )
})

export default PricingLine;
