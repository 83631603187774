import React, { useEffect, useState } from 'react'
import { CardContent, CircularProgress } from '@material-ui/core'

import { pdf } from '@react-pdf/renderer';
import { useParams } from 'react-router-dom'
import axios from "../../../utils/axios";
import apiConfig from 'apiConfig'
import JobQuotePdf from 'views/CustomerDetails/components/JobAddresses/components/JobQuotePdf';
import localStorage from 'utils/localStorage'
import { useTheme } from "@material-ui/core/styles";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import * as PDFMerger from 'pdf-merger-js'
import { BlobProvider } from '@react-pdf/renderer';

const Quote = props => {
    const [loadingData, setLoadingData] = useState(true);
    const { id } = useParams()
    const [draftPdf] = useState("draft");
    const fullInfo = localStorage.getFullInfo();
    const [quote, setQuote] = useState();
    const [mergedPdfUrl, setMergedPdfUrl] = useState();
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('xs'));

    useEffect(() => {
        const attachmentsStr = localStorage.getItem('attachments');
        localStorage.removeItem('attachments');
        const attachments = (attachmentsStr && JSON.parse(attachmentsStr)) || [];
        if(id === draftPdf) {
            axios.get(apiConfig.url.BASE_URL + apiConfig.url.COMPANY_CONTRACT_FORM.replace('{id}', fullInfo.cid))
                .then(res => {
                    setQuote(res.data.quoteForm ? res.data.quoteForm.replaceAll('\n', '\n ') : '');
                })
                .finally(() => setLoadingData(false));
        } else {
            axios.get(apiConfig.url.BASE_URL + apiConfig.url.JOB_INVOICE_PDF, {params: {jobId: id, isQuote: true, isInvoice: false} })
                .then(async res => {
                    if(res.data){
                        const quotePdf = await pdf(<JobQuotePdf jobInfoPdf={res.data} draftPdf={quote} />).toBlob();

                        let merger = new PDFMerger();

                        let hasPrimary = attachments.some(a => a.isPrimary);
                        if (!hasPrimary) await merger.add(quotePdf);

                        for (const attachment of attachments) {
                            if (attachment.isPrimary) {
                                await merger.add(quotePdf);
                                continue;
                            }
                            if (attachment.contentType !== 'application/pdf') continue;

                            try {
                                await merger.add(attachment.path);
                            } catch (e) {
                                console.error(e);
                                continue;
                            }
                        }

                        const mergedPdf = await merger.saveAsBlob();
                        const url = URL.createObjectURL(mergedPdf);
                        setMergedPdfUrl(url);
                    }
                })
                .catch(err => {
                    console.log("err", err);
                })
                .finally(() => setLoadingData(false));
        }
    }, [])

    useEffect(() => {
        if (mergedPdfUrl && isMobile) {
            window.location.href = mergedPdfUrl;
        }
    }, [mergedPdfUrl, isMobile]);

    return (
        <>
            {!loadingData
                ? 
                id === draftPdf ?
                    <BlobProvider document={<JobQuotePdf draftPdf={quote} />}>
                        {({ blob, url, loading, error }) => {
                            if (!loading && !error && url) {
                                window.location.href = url;
                            }
                            return null;
                        }}
                    </BlobProvider>
                    :  
                    <iframe
                        src={mergedPdfUrl}
                        title="pdf-viewer"
                        style={{ position: 'absolute', border: 0, height: '100vh', width: '100%' }}
                    ></iframe>
                : <CardContent><CircularProgress size={24}/></CardContent>
            }
        </>
    )
}

export default Quote;
