import React, {useEffect, useState} from 'react'
import PropTypes from 'prop-types'
import moment from 'moment'
import {
    TableRow, TableCell,
    FormControlLabel, IconButton, Link
} from '@material-ui/core'
import { useParams } from 'react-router-dom'
import { Delete } from '@material-ui/icons'
import { makeStyles } from '@material-ui/styles'
import { GreenCheckbox } from 'components'

const useStyles = makeStyles(theme => ({
    jobRow: {
        cursor: 'pointer',
        '&:hover': {
            backgroundColor: '#fafafa',
            textDecoration: 'none'
        }
    },
    combine: {
        margin: 0,
        '& span': {
            padding: '0'
        }
    },
    delete: {
        padding: '0'
    }
}));

const stages = ['None', 'To Bid', 'Bid Completed', 'To Be Scheduled', 'Scheduled', 'Job Completed', 'Cancelled', 'Customer Service']

const JobCardHistory = ({ job, remove, onCheckCombine, data, selected, customerId, customerLastName, customerFirstName }) => {
    const classes = useStyles();
    const { name, id } = useParams();
    return (
        <>
        <TableRow className={classes.jobRow} component={Link} href={`/customers/${encodeURIComponent(customerLastName)} ${encodeURIComponent(customerFirstName)}/${customerId}/jobs/${job.id}/information`} key={job.id}>
            <TableCell>
                {stages[job.bidStageId] || 'Unknow'}
            </TableCell>
            <TableCell>{job.date && moment(job.date).format('MM/DD/YYYY') || ''}</TableCell>
            <TableCell>{job.purchaseOrder || '0'}</TableCell>
            <TableCell>{job.description || ''}</TableCell>
            <TableCell>{(job.cost && `$${job.cost.toFixed(2)}`) || '$0.00'}</TableCell>
        </TableRow>
            </>
    )
}

JobCardHistory.propTypes = {
    job: PropTypes.object,
    remove: PropTypes.func,
    onCheckCombine: PropTypes.func
}

export default JobCardHistory;
