import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { useParams } from 'react-router-dom'
import { makeStyles, withStyles } from '@material-ui/styles'
import {
    Modal, Card, CardHeader, CardContent, Divider, Tabs,
    CardActions, Button, colors, CircularProgress, Tab
} from '@material-ui/core'
import DraggableSource from '../DraggableSource'
import { useSelector, useDispatch } from 'react-redux'
import moment from 'moment'
import apiConfig from 'apiConfig'
import axios from 'utils/axios'

const useStyles = makeStyles(theme => ({
    styleModal: {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        outline: 'none',
        boxShadow: theme.shadows[20],
        width: 700,
        maxHeight: '90%',
        overflowY: 'auto',
        maxWidth: '100%',
        [theme.breakpoints.down('sm')]: {
            width: 500
        },
    },
    overBox: {
        overflowY: 'auto',
        border: '1px solid #cbcbcb',
        borderRadius: 6,
        minHeight: 40,
        maxHeight: 230,
        '&::-webkit-scrollbar': {
            width: '10px'
        },
        '&::-webkit-scrollbar-track': {
            backgroundColor: '#e4e4e4',
            borderRadius: '60px'
        },
        '&::-webkit-scrollbar-thumb': {
            backgroundColor: '#9c9c9c',
            borderRadius: '60px'
        }
    },
    button: {
        color: theme.palette.white,
        backgroundColor: colors.green[600],
        '&:hover': {
            backgroundColor: colors.green[900]
        },
    },
    tabs: {
        '& .MuiTabs-scroller': {
            backgroundColor: '#ffffff'
        }
    },
}))

const NoBorderTabs = withStyles({
    root: {
        borderBottom: '0',
        marginBottom: 10
    },
})(Tabs)

const SourcesModal = ({ defaultType, open, onClose, handleSelectedSource }) => {
    const tabs = [
        { value: 'jobs', label: 'To be Scheduled' },
        { value: 'employees', label: 'Available Employees' },
        { value: 'equipments', label: 'Available Equipment' }
    ];

    const classes = useStyles();
    const dispatch = useDispatch();
    const { date } = useParams();

    const { routes } = useSelector(state => state.RoutesReducer);
    const { routeJobs, loadingJob } = useSelector(state => state.JobsReducer);
    const { routeEmployees, loadingEmployee } = useSelector(state => state.EmployeesReducer);
    const { routeEquipment, loadingEquipment } = useSelector(state => state.EquipmentsReducer);

    const [tab, setTab] = useState(defaultType);
    const [pageJob, setPageJob] = useState(1);
    const [pageEquipment, setPageEquipment] = useState(1);
    const [pageEmployees, setPageEmployees] = useState(1);
    const [totalPageJob, setTotalPageJob] = useState(1);
    const [totalPageEquipment, setTotalPageEquipment] = useState(1);
    const [totalPageEmployees, setTotalPageEmployees] = useState(1);
    const [selected, setSelected] = useState({});

    const tobeScheduledJobs = routeJobs && routeJobs.filter(job => job.jobStage !== 1) || null;

    const handleTabsChange = (event, value) => {
        setTab(value)
    };
    const onChangeSelected = (type, id) => {
        setSelected(prevState => {
            let ids = [id];
            if (prevState.id) {
                ids = [...prevState.id];
                const idx = prevState.id.indexOf(id);
                if (idx >= 0) ids.splice(idx, 1);
                else ids.push(id);
            }

            return {
                type,
                id: ids
            }
        });
    }
    const handleChangeSource = () => {
        handleSelectedSource(selected);
        setSelected({});
        onClose();
    }

    const handleClose = () => {
        setSelected({});
        onClose();
    }

    const locateInRoute = ({ jobSplitId, latitude, longitude }) => {
        onClose();
        dispatch({ type: 'SHOW_DISTANCE', showDistance: false })
        dispatch({ type: 'IS_UPDATE', status: true });
        if (latitude != null && longitude != null) {
            dispatch({ type: 'LOCATE_ON_ROUTE_COORD', locateOnRouteCoord: { latitude, longitude } });
        }
        axios.get(apiConfig.url.BASE_URL + apiConfig.url.ROUTE_DISTANCE, { params: { jobSplitId, routeDate: date } })
            .then(res => {
                const routeDistance = routes.map(item => {
                    const distance = res.data.routeDistances.find(x => x.routeId === item.id);
                    const routeDistance = item.routeJobs.map(job => {
                        if (distance) {
                            const distanceJob = distance.jobDistances.find(y => y.jobSplitId === job.jobSplitId);
                            return { ...job, distance: distanceJob.distance ? parseFloat(distanceJob.distance).toFixed(2) : undefined }
                        } else {
                            return { ...job, distance: undefined }
                        }

                    })
                    return { ...item, routeJobs: routeDistance, distance: distance ? parseFloat(distance.distance).toFixed(2) : undefined }
                })

                latitude = res.data.coordinate.latitude;
                longitude = res.data.coordinate.longitude;

                dispatch({ type: 'CHANGE_ORDER', orderType: 'DISTANCE' })
                dispatch({ type: 'INIT_ROUTES', routes: routeDistance })
                dispatch({ type: 'LOCATE_ON_ROUTE_COORD', locateOnRouteCoord: { latitude, longitude } })
            }).finally(() => {
                dispatch({ type: 'IS_UPDATE', status: false });
            })
    }

    const getJobs = (page) => {
        if (loadingJob) return;
        const pageNum = page || pageJob;
        if (pageNum > totalPageJob) return;
        dispatch({ type: 'LOADING_JOB', loadingJob: true });
        axios.get(apiConfig.url.BASE_URL + apiConfig.url.TO_BE_SCHEDULED_JOBS + '&pageNumber=' + pageNum, { params: { date: moment(date).utc().toISOString() } })
            .then(res => {
                res.data.data.map(job => {
                    job['job'] = {
                        city: job.city || '',
                        price: job.price,
                        estimatedHours: job.estimatedHours
                    }
                });
                pageNum === 1 ?
                    dispatch({ type: 'INIT_JOB', routeJobs: res.data.data }) :
                    dispatch({ type: 'LOAD_MORE_JOB', routeJobs: res.data.data })
                setTotalPageJob(res.data.pageCount);
                if (pageNum * 1 <= res.data.pageCount * 1) {
                    setPageJob(pageNum + 1);
                }
            })
            .catch(() => {
                dispatch({ type: 'LOAD_MORE_JOB', routeJobs: [] })
            }).finally(() => {
                dispatch({ type: 'LOADING_JOB', loadingJob: false })
            })
    }

    const getEquipments = (page) => {
        if (loadingEquipment) return;
        const pageNum = page || pageEquipment;
        if (pageNum > totalPageEquipment) return;
        dispatch({ type: 'LOADING_EQUIPMENT', loadingEquipment: true });
        axios.get(apiConfig.url.BASE_URL + apiConfig.url.EQUIPMENTS_FOR_ROUTE + date + '&pageNumber=' + pageNum)
            .then(res => {
                pageNum === 1 ?
                    dispatch({ type: 'INIT_EQUIPMENT', routeEquipment: res.data.data }) :
                    dispatch({ type: 'LOAD_MORE_EQUIPMENT', routeEquipment: res.data.data })
                setTotalPageEquipment(res.data.pageCount);
                if (pageNum * 1 <= res.data.pageCount * 1) {
                    setPageEquipment(pageNum + 1);
                }
            })
            .catch(() => {
                dispatch({ type: 'LOAD_MORE_EQUIPMENT', routeEquipment: [] });
            })
            .finally(() => {
                dispatch({ type: 'LOADING_EQUIPMENT', loadingEquipment: false })
            })
    }

    const getEmployees = (page) => {
        if (loadingEmployee) return;
        const pageNum = page || pageEmployees;
        if (pageNum > totalPageEmployees) return;
        dispatch({ type: 'LOADING_EMPLOYEE', loadingEmployee: true });
        axios.get(apiConfig.url.USER_URL + apiConfig.url.COMPANY_EMPLOYEES_ROUTE,
            { params: { date: moment(date).utc().toISOString(), pageNumber: pageNum } })
            .then(res => {
                pageNum === 1 ?
                    dispatch({ type: 'INIT_EMPLOYEE', employees: res.data.users }) :
                    dispatch({ type: 'LOAD_MORE_EMPLOYEES', routeEmployees: res.data.users })
                setTotalPageEmployees(res.data.pageCount);
                if (pageNum * 1 <= res.data.pageCount * 1) {
                    setPageEmployees(pageNum + 1);
                }
            })
            .catch(() => {
                dispatch({ type: 'LOAD_MORE_EMPLOYEES', routeEmployees: [] });
            })
            .finally(() => {
                dispatch({ type: 'LOADING_EMPLOYEE', loadingEmployee: false })
            })
    }

    useEffect(() => {
        setTab(defaultType)
    }, [defaultType])

    useEffect(() => {
        if (!routeJobs?.length) getJobs(1);
        if (!routeEmployees?.length) getEmployees(1);
        if (!routeEquipment?.length) getEquipments(1);
    }, []);

    return (
        <Modal open={open} onClose={onClose}>
            <Card className={classes.styleModal}>
                <CardHeader title="Route Sources" />
                <Divider />
                <CardContent>
                    <NoBorderTabs
                        className={classes.tabs}
                        onChange={handleTabsChange}
                        scrollButtons="auto"
                        value={tab}
                        variant="scrollable">
                        {tabs.map(tab => (
                            <Tab
                                key={tab.value}
                                label={tab.label}
                                value={tab.value}
                            />
                        ))}
                    </NoBorderTabs>

                    <div className={classes.overBox} onScroll={(e) => {
                        if (e.target !== e.currentTarget) return;
                        const bottom = Math.round(e.target.scrollHeight - e.target.scrollTop) === e.target.clientHeight;
                        if (bottom) {
                            if (tab === 'jobs') {
                                getJobs();
                            }
                            if (tab === 'employees') {
                                getEmployees();
                            }
                            if (tab === 'equipments') {
                                getEquipments();
                            }
                        }
                    }}>
                        <div>
                            {tab === 'jobs' && (
                                <>
                                    {!tobeScheduledJobs &&
                                        <div style={{ height: '40px', marginTop: '16px', textAlign: 'center' }}>
                                            <CircularProgress size={24} />
                                        </div>
                                    }
                                    {tobeScheduledJobs && tobeScheduledJobs.map((job, index) => (
                                        <DraggableSource
                                            key={`${job.jobSplitId}-${index}`}
                                            isSelected={selected.type === "jobs" && selected.id.includes(job.jobSplitId)}
                                            type='Job'
                                            source={job}
                                            changeSelected={() => onChangeSelected('jobs', job.jobSplitId)}
                                            locateInRoute={() => {
                                                locateInRoute(job, moment(date).utc().toISOString())
                                            }}
                                        />
                                    ))}
                                    {
                                        loadingJob &&
                                        <div style={{ height: '40px', marginTop: '16px', textAlign: 'center' }}>
                                            <CircularProgress size={24} />
                                        </div>
                                    }
                                </>
                            )}
                            {tab === 'employees' && (
                                <>
                                    {!routeEmployees &&
                                        <div style={{ height: '40px', marginTop: '16px', textAlign: 'center' }}>
                                            <CircularProgress size={24} />
                                        </div>
                                    }
                                    {Array.isArray(routeEmployees) && routeEmployees.map((emp, index) => (
                                        <DraggableSource
                                            key={`${emp.id}-${index}`}
                                            isSelected={selected.type === "employees" && selected.id.includes(emp.id)}
                                            type='Employee'
                                            source={emp}
                                            changeSelected={() => onChangeSelected('employees', emp.id)}
                                            date={date}
                                        />
                                    ))}
                                    {
                                        loadingEmployee &&
                                        <div style={{ height: '40px', marginTop: '16px', textAlign: 'center' }}>
                                            <CircularProgress size={24} />
                                        </div>
                                    }
                                </>
                            )}
                            {tab === 'equipments' && (
                                <>
                                    {!routeEquipment &&
                                        <div style={{ height: '40px', marginTop: '16px', textAlign: 'center' }}>
                                            <CircularProgress size={24} />
                                        </div>
                                    }
                                    {Array.isArray(routeEquipment) && routeEquipment.map((eqp, index) => (
                                        <DraggableSource
                                            key={`${eqp.id}-${index}`}
                                            isSelected={selected.type === "equipments" && selected.id.includes(eqp.id)}
                                            type='Equipment'
                                            source={eqp}
                                            changeSelected={() => onChangeSelected('equipments', eqp.id)}
                                        />
                                    ))}
                                    {
                                        loadingEquipment &&
                                        <div style={{ height: '40px', marginTop: '16px', textAlign: 'center' }}>
                                            <CircularProgress size={24} />
                                        </div>
                                    }
                                </>
                            )}
                        </div>
                    </div>
                </CardContent>
                <Divider />
                <CardActions style={{ justifyContent: 'flex-end' }}>
                    <Button onClick={handleClose} variant="contained">
                        Close
                    </Button>
                    <Button className={classes.button}
                        disabled={!selected.id?.length}
                        variant="contained"
                        color="primary"
                        onClick={handleChangeSource}>
                        Select
                    </Button>
                </CardActions>
            </Card>
        </Modal>
    )
}

SourcesModal.propTypes = {
    defaultType: PropTypes.string,
    open: PropTypes.bool,
    onClose: PropTypes.func,
    handleSelectedSource: PropTypes.func
}

export default SourcesModal
