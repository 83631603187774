import React, { useState, useEffect } from 'react'
import { useParams } from 'react-router-dom'
import { makeStyles } from '@material-ui/styles'
import {
    Card, CardHeader, CardContent, CardActions,
    Divider, Grid, Button, Collapse,
    FormControlLabel, TextField, CircularProgress,
    Typography, colors, Tooltip, Snackbar
} from '@material-ui/core'
import { Alert } from '@material-ui/lab'
import { KeyboardArrowDown } from '@material-ui/icons'
import useMediaQuery from '@material-ui/core/useMediaQuery'
import { SplitTable, SplitsModal, DeleteSplitConfirmModal } from './components'
import { GreenCheckbox, ConfirmModal, AddressLink } from 'components'
import { useSelector, useDispatch } from 'react-redux'
import axios from 'utils/axios'
import apiConfig from 'apiConfig'
import * as moment from 'moment'
import currency from 'currency.js'
import NumberFormat from "react-number-format";

const useStyles = makeStyles(theme => ({
    button: {
        color: theme.palette.white,
        backgroundColor: colors.green[600],
        '&:hover': {
            backgroundColor: colors.green[900]
        }
    },
    btnDis: {
        whiteSpace: 'nowrap',
        color: 'rgba(0, 0, 0, 0.26)',
        backgroundColor: 'rgb(217 217 217)',
        pointerEvents: 'none'
    },
    sticky: {
        marginBottom: theme.spacing(2),
        position: 'sticky',
        zIndex: 3
    },
    stickyHead: {
        position: 'relative',
        cursor: 'pointer'
    },
    address: {
        fontWeight: 500
    },
    splitHeader: {
        alignItems: 'center'
    },
    warningText: {
        color: '#ff0000',
        fontWeight: 500,
        marginRight: '8px'
    },
    btnAction: {
        color: theme.palette.white,
        backgroundColor: colors.green[600],
        '&:hover': {
            backgroundColor: colors.green[900]
        }
    },
    addressLink: {
        textDecoration: 'underline',
        color:'#0000EE'
    }
}))

const initSplit = {
    description: '',
    scheduleDate: null,
    completedDate: null,
    total: '',
    jobId: '',
}

let mounted = false;
let pageNumber = 1;
let forcePage = 0;
const SplitJob = props => {
    const { disabled, states, information, openFollowUp, fuData, isCustomeService, zIndexCustom = 3, updateIsEqually, changeSplitsEqually, reloadInfomation, offsetTop } = props
    const classes = useStyles();
    const dispatch = useDispatch();
    const ipad = useMediaQuery('(max-width: 769px)');
    const { jobId } = useParams();

    const { expanded } = useSelector(state => state.JobsReducer);
    const address = JSON.parse(sessionStorage.getItem('sw-address'));
    //const information = JSON.parse(sessionStorage.getItem('sw-information'));
    const [splits, setSplits] = useState([]);
    const [openSnackbar, setOpenSnackBar] = useState(false);
    const [isSaving, setIsSaving] = useState(false);
    const [isStatus, setIsStatus] = useState({ failed: false, msg: '' });
    const [openModal, setOpenModal] = useState(false);
    const [isEqually, setIsEqually] = useState(false);
    const [confirm, setConfirm] = useState({ open: false, title: '' });
    const [warningDelete, setWarningDelete] = useState({ open: false, scheduledDate: '' });
    const [isWarning, setIsWarning] = useState(false);
    const [index, setIndex] = useState();
    const [loadSplit, setLoadSplit] = useState(false);
    const [changeAmount, setChangeAmount] = useState(false);
    const [jobTotal, setJobTotal] = useState(0);
    const [jobSplitTotal, setJobSplitTotal] = useState(0)
    const [sortBy, setSortBy] = useState('SplitDescription');
    const [sortType, setSortType] = useState('ASC');

    const onChangeSplit = (event, index) => {
        setChangeAmount(true);
        const { name, value } = event.target;
        const spls = [...splits];
        spls[index] = { ...splits[index], [name]: name === 'total' ? (value ? parseFloat(value.replace(/[$,]/g, '')) : 0) : value };
        setSplits(spls);
    }
    const onChangeSplitDate = (name, value, index) => {
        const spls = [...splits];
        spls[index] = { ...splits[index], [name]: value };
        setSplits(spls);
    }
    const remove = index => {
        setIndex(index);
        const split = splits[index];
        if (split?.scheduledDate) {
            setWarningDelete({ open: true, scheduledDate: moment(split.scheduledDate).format('MM/DD/yyyy') })
        } else {
            setConfirm({ open: true, title: 'Are you sure you want to delete this item?' })
        }
    }
    const onAddSplit = () => {

        initSplit.total = '';
        if (isEqually) {
            splits.map(item => {
                item.total = currency(jobTotal).divide(splits.length + 1).value
            })
            initSplit.total = currency(jobTotal).divide(splits.length + 1).value;
        }
        setSplits([...splits, { ...initSplit, jobId }])
    }
    const onRemoveSplit = () => {
        const spls = [...splits];
        spls.splice(index, 1);
        if (isEqually) {
            spls.map(item => {
                item.total = currency(jobTotal).divide(spls.length).value
            })
        }
        if (spls.length === 1) {
            spls[0].total = jobTotal
        }
        setSplits(spls);
        setConfirm({ ...confirm, open: false })
        setWarningDelete({ ...warningDelete, open: false })
    }

    const saveManuallySplit = () => {
        setIsSaving(true);
        const payload = splits.map(item => {
            let i = { ...item, total: item.total === '' ? 0 : item.total }
            return i;
        })
        axios.post(apiConfig.url.BASE_URL + apiConfig.url.CUSTOMER_JOB + jobId + apiConfig.url.CUSTOMER_JOB_SPLIT_UPDATE, payload)
            .then(res => {
                setLoadSplit(!loadSplit)
                setIsStatus({ failed: false, msg: 'Split job Complete' });
                setOpenSnackBar(true)
                reloadInfomation();
            })
            .catch(() => {
                setIsStatus({ failed: true, msg: 'Split job failed, please try again later.' });
                setOpenSnackBar(true)
            })
            .finally(() => setIsSaving(false))
    }

    const toggleExpanded = () => {
        dispatch({ type: 'CHANGE_EXPANDED' })
    }

    const updateJobSplit = () => {
        const split = splits.map(item => {
            return {
                ...item, total: Math.round(jobSplitTotal / splits.length, 2)
            }
        })
        setSplits(split)
    }

    const fetchJobSplits = () => {
        axios.get(apiConfig.url.BASE_URL + apiConfig.url.CUSTOMER_JOB + jobId + apiConfig.url.CUSTOMER_JOB_SPLITS,{
            params:
                {
                    sortBy: sortBy,
                    sortType: sortType
                }
        })
            .then(res => {
                if (mounted) {
                    setJobTotal(res.data && res.data.jobTotal || 0)
                    if (res.data && res.data.jobSplitItems.length > 0) {
                        setSplits(res.data.jobSplitItems);
                        return
                    }
                    setSplits([])
                }
            })
    }

    useEffect(() => {
        //mounted = false;
        fetchJobSplits();
        mounted = true;
    }, [loadSplit])

    const loadDocument = (type) => {
        setSortBy(type)
        setSortType(type == sortBy ? (sortType === 'ASC' ? 'DESC' : 'ASC') : 'ASC');
        pageNumber = 1;
        forcePage = 0;
    }
    useEffect(() => {
        fetchJobSplits();
    }, [sortBy, sortType])

    useEffect(() => {
        let splitTotal = 0;
        splits.map(split => {
            splitTotal += split.total * 1 ? split.total * 1 : 0;
        })
        setJobSplitTotal(splitTotal);
        if (Math.round(splitTotal * 100) / 100 != jobTotal * 1) {
            mounted && changeAmount && setIsWarning(true)
        } else {
            mounted && changeAmount && setIsWarning(false)
        }
        mounted = true;
    }, [splits])

    useEffect(() => {
        if (information) {
            setIsEqually(!!information.distributeSplitsEqually)
            if (information.distributeSplitsEqually) {
                //updateJobSplit()
            }
            if (isEqually) setLoadSplit(!loadSplit)
            //setLoadSplit(!loadSplit)
        }
    }, [information])

    useEffect(() => {

    }, [isEqually])

    return (
        <>
            <Card style={{ marginTop: '-18px', zIndex: zIndexCustom, top: offsetTop }} className={classes.sticky}>
                <CardHeader title={isCustomeService ? <> <span>Job Details</span><span style={{ color: 'red' }}> - Customer Service</span> </> : 'Job Details'}
                    className={classes.stickyHead}
                    action={<KeyboardArrowDown />}
                    onClick={toggleExpanded} />
                <Divider />
                <Collapse in={expanded} timeout='auto' unmountOnExit>
                    <CardContent>
                        <Grid container spacing={3}>
                            <Grid item xs={12} sm={4}>
                                <Card style={{ height: '100%' }}>
                                    <CardHeader title='Job Address' />
                                    <Divider />
                                    <CardContent style={{ padding: '16px' }}>
                                        {
                                            information && information.jobAddress &&
                                            (
                                                <>
                                                    <Typography
                                                        className={classes.address}>{information && information.jobAddress.name || ''}</Typography>
                                                    <AddressLink
                                                        address={{
                                                            ...information.jobAddress,
                                                            state: { name: states?.find(x => x.id === information.jobAddress.stateId)?.name }
                                                        }}
                                                        className={classes.address}
                                                    />
                                                </>
                                            )
                                        }
                                    </CardContent>
                                </Card>
                            </Grid>
                            <Grid item xs={12} sm={8}>
                                <Tooltip
                                    disableFocusListener
                                    arrow
                                    title={
                                        <Typography style={{ color: 'white' }}>
                                            Job Description is only editable in tabs with a save button.
                                        </Typography>
                                    }>
                                    <TextField
                                        fullWidth
                                        disabled
                                        multiline
                                        rows={7}
                                        label='Job Description'
                                        name='description'
                                        variant='outlined'
                                        value={information.description || ''}
                                    />
                                </Tooltip>
                            </Grid>
                        </Grid>
                    </CardContent>
                </Collapse>
            </Card>
            <Card>
                <CardHeader title='Add Splits Manually' />
                <Divider />
                <CardContent style={{ position: 'relative' }}>
                    <div className={classes.splitHeader} style={!ipad ? { display: 'flex', position: 'absolute', top: '-47px', right: 0 } : null}>
                        <Typography className={classes.warningText}>
                            {isWarning ? `The subtotal for this job doesn't match the total in the splits.` : (jobId === ' ' ? 'No have any job to split.' : '')}
                        </Typography>
                        <Button
                            style={{ marginRight: '16px' }}
                            className={(disabled || splits.length > 1 || jobId === ' ') ? classes.btnDis : classes.button}
                            onClick={() => setOpenModal(true)}>
                            Automatic Splits
                        </Button>
                        <FormControlLabel
                            disabled={disabled}
                            checked={information.distributeSplitsEqually || false}
                            onChange={event => {
                                updateIsEqually(event.target.checked)
                                setIsEqually(event.target.checked);
                            }}
                            control={
                                <GreenCheckbox />
                            }
                            label='Distribute amounts equally'
                        />
                        {changeSplitsEqually && <CircularProgress style={{ marginRight: '10px' }} size={24} />}
                    </div>
                    <div style={{ display: 'flex', alignItems: "center", justifyContent: 'center' }}>
                        <NumberFormat
                            style={{ width: '300px', maxWidth: '100%', margin: '12px' }}
                            disabled={true}
                            customInput={TextField}
                            allowNegative={false}
                            prefix='$'
                            thousandSeparator
                            fixedDecimalScale={true}
                            decimalScale={2}
                            fullWidth
                            className={classes.splitField}
                            label='Job total'
                            name='total'
                            variant='outlined'
                            value={jobTotal}
                        />
                        <NumberFormat
                            style={{ width: '300px', maxWidth: '100%', margin: '12px' }}
                            disabled={true}
                            customInput={TextField}
                            allowNegative={false}
                            prefix='$'
                            thousandSeparator
                            decimalScale={2}
                            fixedDecimalScale={true}
                            fullWidth
                            className={classes.splitField}
                            label='Job split total'
                            name='total'
                            variant='outlined'
                            value={jobSplitTotal}
                        />
                    </div>
                    <Grid container spacing={4}>
                        <Grid item xs={12}>
                            <SplitTable
                                isEqual={!!information.distributeSplitsEqually || false}
                                information={information}
                                disabled={disabled || jobId === ' '}
                                splits={splits}
                                add={onAddSplit}
                                update={onChangeSplit}
                                remove={remove}
                                updateDate={onChangeSplitDate}
                                sortBy={sortBy}
                                sortType={sortType}
                                loadDocument={loadDocument}
                            />
                        </Grid>
                    </Grid>
                </CardContent>
                <Divider />
                <CardActions style={{ justifyContent: 'flex-end', paddingRight: '16px' }}>
                    <Button className={(disabled || jobId === ' ' || isSaving) ? classes.btnDis : classes.button}
                        onClick={saveManuallySplit}>
                        Save
                    </Button>
                    {isSaving && <CircularProgress size={24} />}
                </CardActions>
            </Card>

            <ConfirmModal
                title={confirm.title}
                openConfirm={confirm.open}
                closeConfirm={() => setConfirm({ ...confirm, open: false })}
                onConfirm={onRemoveSplit}
            />

            <DeleteSplitConfirmModal
                scheduledDate={warningDelete.scheduledDate}
                openConfirm={warningDelete.open}
                closeConfirm={() => setWarningDelete({ ...warningDelete, open: false })}
                onConfirm={onRemoveSplit}
            />

            <SplitsModal jobId={jobId} open={openModal} onClose={() => {
                setOpenModal(false)
            }}
                onLoadSplit={() => setLoadSplit(!loadSplit)}
            />

            <Snackbar anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
                open={openSnackbar}
                autoHideDuration={3000}
                onClose={() => setOpenSnackBar(false)} >
                <Alert
                    elevation={6} variant="filled" severity={isStatus.failed ? 'error' : 'success'}>
                    <Typography
                        color="inherit"
                        variant="h6">
                        {isStatus.msg}
                    </Typography>
                </Alert>
            </Snackbar>

            <div style={{ height: '1px', marginTop: '20px' }}></div>
        </>
    )
}

export default SplitJob;
