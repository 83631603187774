import React, { useEffect, useState, useRef } from 'react'
import {
    TableContainer, Table, TableBody, TableRow, TableCell, colors, Typography, FormControlLabel, Link, CardContent, CircularProgress
} from '@material-ui/core'
import { DragHandle } from '@material-ui/icons'
import { makeStyles } from '@material-ui/styles'
import { SortableContainer, SortableElement, SortableHandle } from 'react-sortable-hoc'
import arrayMove from 'array-move'
import OpenInNew from "@material-ui/icons/OpenInNew";
import {GreenCheckbox} from 'components'
import { Document, Page, Text, View, StyleSheet, PDFViewer } from '@react-pdf/renderer';
import { Redirect, useParams } from 'react-router-dom'
import axios from "../../../utils/axios";
import apiConfig from 'apiConfig'
import BidsTodoPdf from 'views/CustomerDetails/components/JobAddresses/components/BidsTodoPdf';
import { useTheme } from "@material-ui/core/styles";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import { BlobProvider } from '@react-pdf/renderer';
import { checkFeature, FeatureFlags } from "FeatureService";

const styles = StyleSheet.create({
    page: {
      flexDirection: 'row',
      backgroundColor: '#E4E4E4'
    },
    section: {
      margin: 10,
      padding: 10,
      flexGrow: 1
    }
});

const useStyles = makeStyles(theme => ({
    
}));

const BidsTodo = props => {
    const classes = useStyles();
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('xs'));
    const search = window.location.search;
    const params = new URLSearchParams(search);
    const selectEmployeeId = params.get('selectEmployeeId');
    const allBidders = params.get('allBidders');
    const todayBidders = params.get('todayBidders');
    const pastDueBidders = params.get('pastDueBidders');
    const upcomingBidders = params.get('upcomingBidders');
    const [data, setData] = useState([]);
    const [loadingData, setLoadingData] = useState(true);
    const [isError, setIsError] = useState(false);

    const [isEnableBidsToDo, setIsEnableBidsToDo] = useState();

    useEffect(() => {

        (async () => {
            const isEnableBidsToDo = await checkFeature(FeatureFlags.BIDS_TO_DO);
            setIsEnableBidsToDo(isEnableBidsToDo)
            if(isEnableBidsToDo) {
                axios.get(apiConfig.url.BASE_URL + apiConfig.url.DASHBOARD_BILL_TO_DO_PDF, {params: {
                    // limit: 200, 
                    // pageNumber: 1, 
                    // pageSize: 1000
                    employeeId: selectEmployeeId && selectEmployeeId === 'null' ? "" : selectEmployeeId,
                    todayBids: todayBidders,
                    pastDueBids: pastDueBidders,
                    upcomingBids: upcomingBidders
                }})
                    .then(res => {
                        if(res.data){
                            setData(res.data);
                        }
                    })
                    .catch(err => {
                        setIsError(true);
                    })
                    .finally(() => setLoadingData(false));
            } else {
                axios.get(apiConfig.url.BASE_URL + apiConfig.url.DASHBOARD_BILL_TO_DO, {params: {limit: 500, pageNumber: 1, pageSize: 1000}})
                    .then(res => {
                        if(res.data.data){
                            setData(res.data.data);
                        }
                    })
                    .catch(err => {
                        console.log("err", err);
                    })
                    .finally(() => setLoadingData(false));
            }
        })();
    }, [])

    useEffect(() => {
        if(isError)
            window.location.href = "/errors/error-404";
    }, [isError])

    if(isEnableBidsToDo) {
        if (!selectEmployeeId || !allBidders || !todayBidders || !pastDueBidders || !upcomingBidders) {
            return <Redirect to="/errors/error-404" />;
        } else {
            if(todayBidders === "false" && pastDueBidders === "false" && upcomingBidders === "false") {
                return <Redirect to="/errors/error-404" />;
            }
        }
    }

    return (
        <>
            {
                isEnableBidsToDo
                ? isMobile
                    ? !loadingData 
                        ? <BlobProvider document={<BidsTodoPdf data={data} todayBidders={todayBidders} pastDueBidders={pastDueBidders} upcomingBidders={upcomingBidders} />}>
                            {({ blob, url, loading, error }) => {
                                if (!loading && !error && url) {
                                    window.location.href = url;
                                }
                                return null;
                            }}
                        </BlobProvider>
                        : <CardContent><CircularProgress size={24}/></CardContent>
                    : !loadingData 
                        ? <PDFViewer style={{ position: 'absolute', border: 0, height: '100vh', width: '100%' }}>
                            <BidsTodoPdf data={data} todayBidders={todayBidders} pastDueBidders={pastDueBidders} upcomingBidders={upcomingBidders} />
                        </PDFViewer>
                        : <CardContent><CircularProgress size={24}/></CardContent>
                : !loadingData 
                    ? <PDFViewer width={'100%'} height={'100%'}>
                        <BidsTodoPdf data={data} />
                    </PDFViewer>
                    : <CardContent><CircularProgress size={24}/></CardContent>
            }
        </>
    )
}

export default BidsTodo;
