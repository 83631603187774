import { useEffect, useState } from 'react';

export const useSearchDelay = (val, delay) => {
  delay = delay || 500;
  const [debounced, setDebounced] = useState(val);

  useEffect(() => {
    const handler = setTimeout(() => {
      setDebounced(val);
    }, delay);

    return () => clearTimeout(handler);
  }, [val, delay]);

  return debounced;
};
