import React, {useEffect, useState} from 'react'
import moment from 'moment'
import { makeStyles } from '@material-ui/styles'
import { Card, CardContent, CardHeader, CircularProgress, Collapse, 
    Divider, FormControlLabel, IconButton, Table, TableBody, 
    TableCell, TableContainer, TableHead, TableRow, Typography, useMediaQuery,
    Tooltip, colors, Button, Grid
} from '@material-ui/core';
import SignatureCanvas from 'react-signature-canvas'
import {NoteAdd} from "@material-ui/icons";
import ModalSignWorkOrder from 'views/EmployeeRoutes/components/ModalSignWorkOrder';
import axios from "../../../utils/axios";
import apiConfig from 'apiConfig'
import Snackbar from '@material-ui/core/Snackbar';
import Alert from '@material-ui/lab/Alert';

const useStyles = makeStyles(theme => ({
    snackBar: {
        width: '100%',
        '& > * + *': {
            marginTop: theme.spacing(2),
        },
    },
    buttonPrintpdf: {
        flexShrink: '0',
        backgroundColor: colors.green[600],
        '&:hover': {
            backgroundColor: colors.green[900]
        },
    },
}));


const WorkorderPdf = (props) => {
    const classes = useStyles();
    const [openModalSignWorkOrder, setOpenModalSignWorkOrder] = useState(false);
    const [place, setPlace] = useState(null);

    const [dataPlaceOne, setDataPlaceOne] = useState({});
    const [dataPlaceTwo, setDataPlaceTwo] = useState({});
    const [dataPlaceThree, setDataPlaceThree] = useState({});

    function openModalSign(place) {
        setPlace(place);
        setOpenModalSignWorkOrder(true);
    }

    function handleApplySign(place, type, selectedSign, name, date) {
        if(type === 'once') {
            if(place == 1) {
                setDataPlaceOne({place, type, selectedSign, name, date})
                var signaturesData = [];
                if(dataPlaceOne) {
                    signaturesData.push({
                        signaturePosition: 1,
                        fileBase64: selectedSign
                    })
                }

                const data = {
                    pdfSentId: props.data && props.data.pdfSentId,
                    signatures: signaturesData
                }

                submitForm(data);
            } else if(place == 2) {
                setDataPlaceTwo({place, type, selectedSign, name, date})
                var signaturesData = [];
                if(dataPlaceTwo) {
                    signaturesData.push(
                        {
                            signaturePosition: 2,
                            fileBase64: selectedSign
                        },
                        { // 3 Printed Name / Title
                            signaturePosition: 3,
                            fileBase64: name
                        }
                    )
                }
                
                const data = {
                    pdfSentId: props.data && props.data.pdfSentId,
                    signatures: signaturesData
                }

                submitForm(data);
            } else if(place == 3) {
                setDataPlaceThree({place, type, selectedSign, name, date})
                var signaturesData = [];
                if(dataPlaceThree) {
                    signaturesData.push(
                        {
                            signaturePosition: 4,
                            fileBase64: selectedSign
                        },
                        { // 5 Printed Name / Title
                            signaturePosition: 5,
                            fileBase64: name
                        }
                    )
                }

                const data = {
                    pdfSentId: props.data && props.data.pdfSentId,
                    signatures: signaturesData
                }

                submitForm(data);
            }
        } else {
            setDataPlaceOne({place: 1, type: 'once', selectedSign, name, date})
            setDataPlaceTwo({place: 2, type: 'once', selectedSign, name, date})
            setDataPlaceThree({place: 3, type: 'once', selectedSign, name, date})

            var signaturesData = [];
            if(dataPlaceOne) {
                signaturesData.push({
                    signaturePosition: 1,
                    fileBase64: selectedSign
                })
            }
            if(dataPlaceTwo) {
                signaturesData.push(
                    {
                        signaturePosition: 2,
                        fileBase64: selectedSign
                    },
                    { // 3 Printed Name / Title
                        signaturePosition: 3,
                        fileBase64: name
                    }
                )
            }
            if(dataPlaceThree) {
                signaturesData.push(
                    {
                        signaturePosition: 4,
                        fileBase64: selectedSign
                    },
                    { // 5 Printed Name / Title
                        signaturePosition: 5,
                        fileBase64: name
                    }
                )
            }

            const data = {
                pdfSentId: props.data && props.data.pdfSentId,
                signatures: signaturesData
            }

            submitForm(data);
        }
    }

    const [isStatus, setIsStatus] = useState(false);
    const [openSnackbar, setOpenSnackBar] = useState(false);
    const [isLoading, setIsLoading] = useState(false);

    function submitForm(data) {
        setIsLoading(true);
        axios.post(apiConfig.url.CUSTOMER_URL + apiConfig.url.DOCUMENT_SIGNATURE, data)
            .then(res => {
                setIsStatus({ failed: false, msg: 'Save successfully!' });
                setOpenSnackBar(true);
                setOpenModalSignWorkOrder(false);
            })
            .catch(err => {
                setIsStatus({ failed: true, msg: 'An error occurred, please try again later.' });
                setOpenSnackBar(true);
            })
            .finally(() => setIsLoading(false));
    }

    const closeSnackbar = () => {
        setOpenSnackBar(false);
    };

    function printPdf() {
        window.open("/workorder/pdf/" + props.jobId, '_blank');
    }

    return (
        <>
            <div style={{ width: 1000, margin: '0 auto', backgroundColor: 'white', padding: 50 }}>
                <Grid  style={{ width: '100%', display: 'flex', paddingLeft: 5, paddingRight: 5 }}>
                    <Grid style={{ width: '29%', height: 'auto', alignSelf: 'center', textAlign: 'center' }}>
                        {props.data?.company?.logoUrl?.includes('companylogo')
                                ? <img
                                    src={props.data.company.logoUrl}
                                    style={{ height: '85%', width:'85%'}}
                                />
                                : <img style={{ height: '85%', width:'85%' }} />
                            }
                        <Typography style={{ fontFamily: 'Roboto', fontWeight: 'bold', fontSize: 10, color: '#006DB1', marginTop: 5 }}>
                            {props.data && props.data.company ? props.data.company.website : ""}
                        </Typography>
                        { props.data && props.data.company && props.data.company.quoteShowLegalName && <Typography style={{ fontFamily: 'Roboto', fontWeight: 'medium', fontSize: 8, color: 'black', marginTop: 5 }}>
                            Licensee: {props.data && props.data.company ? props.data.company.legalName : ""}
                        </Typography>
                        }
                    </Grid>
                    <Grid style={{ width: '1%' }} />
                    <Grid style={{ width: '40%', height: 'auto', alignItems: 'center' }}>
                        <Typography style={{ fontFamily: 'Roboto', fontWeight: 'bold', fontSize: 15, color: '#006DB1', textAlign: 'center' }}>
                            {props.data && props.data.company ? props.data.company.primaryPhoneNumber : ""}
                        </Typography>
                        {props.data && props.data.company && props.data.company.quoteTitleHeader && <Typography style={{ fontFamily: 'Roboto', fontWeight: 'bold', fontSize: 12, color: 'black', marginTop: 5, textAlign: 'center' }}>
                            {props.data.company.quoteTitleHeader || ""}
                        </Typography>
                        }
                        <Typography style={{ fontFamily: 'Roboto', fontWeight: 'bold', fontSize: 9, color: 'black', marginTop: 5 }}>
                          {props.data && props.data.company && props.data.company.quoteTitleBody}
                        </Typography>
                    </Grid>
                    <Grid style={{ width: '1%' }} />
                    <Grid style={{ width: '29%', height: 'auto', alignItems: 'center' }}>
                        <Grid style={{ width: '100%', height: '76%', border: '2px solid #006DB1', padding: 3, backgroundColor: '#E9ECF8' }}>
                            <Grid style={{ width: '100%', height: '50%', alignItems: 'center', justifyContent: 'center' }}>
                                <Grid style={{ width: '60%', height: '100%', border: '1px solid black', padding: 1, margin: '0 auto' }}>
                                    <Grid style={{ width: '100%', height: '100%', border: '1px solid black', textAlign: 'center', alignContent: 'center', display: 'grid' }}>
                                        <Typography style={{ fontFamily: 'Roboto', fontWeight: 'bold', fontSize: 15, color: 'black', justifyContent: 'center' }}>
                                            Work Order
                                        </Typography>
                                    </Grid>
                                </Grid>
                            </Grid>
                            <Grid style={{ width: '100%', height: '50%'}}>
                                <Grid style={{ display: 'flex' }}>
                                    <Typography style={{ fontFamily: 'Roboto', fontWeight: 'bold', fontSize: 8, color: 'black', width: '25%' }}>
                                        Service Date:
                                    </Typography>
                                    <Grid style={{ borderBottom: '1px solid black', width: '70%' }}>
                                        <Typography style={{ fontFamily: 'Roboto', fontWeight: 'medium', fontSize: 9, color: 'black', width: '40%' }}>
                                            {props.data && props.data.scheduledDate ? moment(props.data.scheduledDate).format('MM/DD/YYYY') : ""}
                                        </Typography>
                                    </Grid>
                                </Grid>
                                <Grid style={{ display: 'flex' }}>
                                    <Typography style={{ fontFamily: 'Roboto', fontWeight: 'bold', fontSize: 8, color: 'black', width: '15%' }}>
                                        Sent By:
                                    </Typography>
                                    <Grid style={{ width: '85%' }}>
                                        <Typography style={{ fontFamily: 'Roboto', fontWeight: 'medium', fontSize: 9, color: 'black', width: '100%', borderBottom: '1px solid black' }}>
                                            {props.data && props.data.sentBy ? props.data.sentBy : ""}
                                        </Typography>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid style={{ display: 'flex', marginTop: 7 }}>
                            <Typography style={{ fontFamily: 'Roboto', fontWeight: 'bold', fontSize: 10, color: 'black', width: '20%' }}>
                                Company
                            </Typography>
                            <Grid style={{ width: '1%' }} />
                            <Grid style={{ borderBottom: '1px solid black', width: '79%' }}>
                                <Typography style={{ fontFamily: 'Roboto', fontWeight: 'medium', fontSize: 9, color: 'black' }}>
                                    {props.data 
                                        && props.data.customer 
                                        && props.data.customer.companyName 
                                        && props.data.customer.customerType == 0 
                                            ? props.data.customer.companyName 
                                            : ""
                                    }
                                </Typography>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>

                <Grid  style={{ width: '100%', display: 'flex', paddingLeft: 5, paddingRight: 5 }}>
                    <Grid style={{ width: '70%', height: 'auto', alignItems: 'center' }}>
                        <Grid style={{ display: 'flex' }}>
                            <Typography style={{ fontFamily: 'Roboto', fontWeight: 'bold', fontSize: 10, color: 'black', width: '14%' }}>
                                Customer/Contact
                            </Typography>
                            <Grid style={{ width: '1%' }} />
                            <Grid style={{ borderBottom: '1px solid black', width: '85%' }}>
                                <Typography style={{ fontFamily: 'Roboto', fontWeight: 'medium', fontSize: 9, color: 'black' }}>
                                    {props.data && props.data.customer && props.data.customer.primaryContact ? props.data.customer.primaryContact.firstName+" "+props.data.customer.primaryContact.lastName : ""}
                                </Typography>
                            </Grid>
                        </Grid>
                        <Grid style={{ display: 'flex', marginTop: 15 }}>
                            <Typography style={{ fontFamily: 'Roboto', fontWeight: 'bold', fontSize: 10, color: 'black', width: '7%' }}>
                                Address
                            </Typography>
                            <Grid style={{ width: '1%' }} />
                            <Grid style={{ borderBottom: '1px solid black', width: '92%' }}>
                                <Typography style={{ fontFamily: 'Roboto', fontWeight: 'medium', fontSize: 9, color: 'black' }}>
                                    {props.data && props.data.customer && props.data.customer.address ? props.data.customer.address.name : ""}
                                    {props.data && props.data.customer && props.data.customer.address && props.data.customer.address.address1 ? " "+props.data.customer.address.address1 : ""}
                                    {props.data && props.data.customer && props.data.customer.address ? " "+props.data.customer.address.city+", " : ""}
                                    {props.data && props.data.customer && props.data.customer.address ? props.data.customer.address.state.name+" " : ""}
                                    {props.data && props.data.customer && props.data.customer.address ? props.data.customer.address.zipCode : ""}
                                </Typography>
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid style={{ width: '1%' }} />
                    <Grid style={{ width: '29%', height: 'auto', alignItems: 'center' }}>
                        <Grid style={{ display: 'flex' }}>
                            <Typography style={{ fontFamily: 'Roboto', fontWeight: 'bold', fontSize: 10, color: 'black', width: '15%' }}>
                                Phone
                            </Typography>
                            <Grid style={{ width: '1%' }} />
                            <Grid style={{ borderBottom: '1px solid black', width: '84%' }}>
                                <Typography style={{ fontFamily: 'Roboto', fontWeight: 'medium', fontSize: 9, color: 'black' }}>
                                    {props.data && props.data.customer && props.data.customer.primaryContact && props.data.customer.primaryContact.phones.length > 0 ? props.data.customer.primaryContact.phones[0].phoneNumber : ""}
                                </Typography>
                            </Grid>
                        </Grid>
                        <Grid style={{ display: 'flex', marginTop: 15 }}>
                            <Typography style={{ fontFamily: 'Roboto', fontWeight: 'bold', fontSize: 10, color: 'black', width: '15%' }}>
                                E-mail
                            </Typography>
                            <Grid style={{ width: '1%' }} />
                            <Grid style={{ borderBottom: '1px solid black', width: '84%' }}>
                                <Typography style={{ fontFamily: 'Roboto', fontWeight: 'medium', fontSize: 9, color: 'black' }}>
                                    {props.data && props.data.customer && props.data.customer.primaryContact ? props.data.customer.primaryContact.email : ""}
                                </Typography>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>

                <Grid  style={{ marginTop: 5, height: 'auto', display: 'flex' }}>
                    <Grid style={{ width: '5%', backgroundColor: '#F9DCC4', border: '1px solid #E26E20', alignItems: 'center', justifyContent: 'center', paddingTop: 110 }}>
                        <Typography style={{ fontFamily: 'Roboto', fontWeight: 'bold', fontSize: 13, color: '#E26E20', transform: `rotate(270deg)`, marginTop: -6 }}>
                            L
                        </Typography>
                        <Typography style={{ fontFamily: 'Roboto', fontWeight: 'bold', fontSize: 13, color: '#E26E20', transform: `rotate(270deg)`, marginTop: -6 }}>
                            A
                        </Typography>
                        <Typography style={{ fontFamily: 'Roboto', fontWeight: 'bold', fontSize: 13, color: '#E26E20', transform: `rotate(270deg)`, marginTop: -6 }}>
                            V
                        </Typography>
                        <Typography style={{ fontFamily: 'Roboto', fontWeight: 'bold', fontSize: 13, color: '#E26E20', transform: `rotate(270deg)`, marginTop: -6 }}>
                            I
                        </Typography>
                        <Typography style={{ fontFamily: 'Roboto', fontWeight: 'bold', fontSize: 13, color: '#E26E20', transform: `rotate(270deg)`, marginTop: -6 }}>
                            R
                        </Typography>
                        <Typography style={{ fontFamily: 'Roboto', fontWeight: 'bold', fontSize: 13, color: '#E26E20', transform: `rotate(270deg)`, marginTop: -6 }}>
                            R
                        </Typography>
                        <Typography style={{ fontFamily: 'Roboto', fontWeight: 'bold', fontSize: 13, color: '#E26E20', transform: `rotate(270deg)`, marginTop: -6 }}>
                            A
                        </Typography>
                    </Grid>
                    <Grid style={{ width: '95%', backgroundColor: 'white' }}>
                        <Grid style={{ height: 'auto' }}>
                            <Grid  style={{ }}>
                                <Grid style={{ display: 'flex' }}>
                                    <Grid style={{ width: '50%', border: '1px solid #006DB1', padding: 5, borderRight: 0 }}>
                                        {/* <Grid style={{ width: '50%', borderRight: 1 }}> */}
                                            <Grid style={{ display: 'flex' }}>
                                                <Typography style={{ fontFamily: 'Roboto', fontWeight: 'bold', fontSize: 10, color: 'black', width: '40%' }}>
                                                    Job Description:
                                                </Typography>
                                                <Typography style={{ fontFamily: 'Roboto', fontWeight: 'medium', fontSize: 9, color: 'black', width: '60%' }}>
                                                    {props.data && props.data.job ? props.data.job.description : ""}
                                                </Typography>
                                            </Grid>
                                            <Grid style={{ display: 'flex', marginTop: 5}}>
                                                <Typography style={{ fontFamily: 'Roboto', fontWeight: 'bold', fontSize: 10, color: 'black', width: '30%' }}>
                                                    Job Address:
                                                </Typography>
                                                <Typography style={{ fontFamily: 'Roboto', fontWeight: 'medium', fontSize: 9, color: 'black', width: '70%' }}>
                                                    {props.data && props.data.job && props.data.job.jobAddress && props.data.job.jobAddress.primaryContact ? props.data.job.jobAddress.primaryContact.name : ""}
                                                </Typography>
                                            </Grid>
                                            <Grid style={{ display: 'flex' }}>
                                                <Grid style={{ width: '30%' }} />
                                                <Typography style={{ fontFamily: 'Roboto', fontWeight: 'medium', fontSize: 9, color: 'black',  width: '70%' }}>
                                                    {props.data && props.data.job && props.data.job.jobAddress && props.data.job.jobAddress.address1 ? props.data.job.jobAddress.address1 : ""}
                                                </Typography>
                                            </Grid>
                                            <Grid style={{ display: 'flex' }}>
                                                <Grid style={{ width: '30%' }} />
                                                <Typography style={{ fontFamily: 'Roboto', fontWeight: 'medium', fontSize: 9, color: 'black', width: '70%' }}>
                                                    {props.data && props.data.job && props.data.job.jobAddress ? props.data.job.jobAddress.city+", " : ""}
                                                    {props.data && props.data.job && props.data.job.jobAddress ? props.data.job.jobAddress.state.name+" " : ""}
                                                    {props.data && props.data.job && props.data.job.jobAddress ? props.data.job.jobAddress.zipCode : ""}
                                                </Typography>
                                            </Grid>
                                        {/* </Grid> */}
                                    </Grid>
                                    <Grid style={{ width: '50%', border: '1px solid #006DB1', padding: 5 }}>
                                        {/* <Grid style={{ width: '50%', borderRight: 1 }}> */}
                                            <Grid style={{ display: 'flex' }}>
                                                <Typography style={{ fontFamily: 'Roboto', fontWeight: 'bold', fontSize: 10, color: 'black', width: '30%' }}>
                                                    Name:
                                                </Typography>
                                                <Typography style={{ fontFamily: 'Roboto', fontWeight: 'medium', fontSize: 9, color: 'black', width: '70%' }}>
                                                {props.data && props.data.job && props.data.job.jobAddress && props.data.job.jobAddress.primaryContact ? props.data.job.jobAddress.primaryContact.name : ""}
                                                </Typography>
                                            </Grid>
                                            <Grid style={{ display: 'flex', marginTop: 5}}>
                                                <Typography style={{ fontFamily: 'Roboto', fontWeight: 'bold', fontSize: 10, color: 'black', width: '30%' }}>
                                                    Phone:
                                                </Typography>
                                                <Typography style={{ fontFamily: 'Roboto', fontWeight: 'medium', fontSize: 9, color: 'black', width: '70%' }}>
                                                    {props.data && props.data.job && props.data.job.jobAddress && props.data.job.jobAddress.primaryContact ? props.data.job.jobAddress.primaryContact.phone : ""}
                                                </Typography>
                                            </Grid>
                                            <Grid style={{ display: 'flex', marginTop: 5}}>
                                                <Typography style={{ fontFamily: 'Roboto', fontWeight: 'bold', fontSize: 10, color: 'black', width: '30%' }}>
                                                    Email:
                                                </Typography>
                                                <Typography style={{ fontFamily: 'Roboto', fontWeight: 'medium', fontSize: 9, color: 'black', width: '70%' }}>
                                                    {props.data && props.data.job && props.data.job.jobAddress && props.data.job.jobAddress.primaryContact ? props.data.job.jobAddress.primaryContact.email : ""}
                                                </Typography>
                                            </Grid>
                                        {/* </Grid> */}
                                    </Grid>
                                </Grid>
                                <Grid style={{ width: '100%', border: '1px solid #006DB1', padding: 5, borderTop: 0, marginTop: -1 }}>
                                    <Grid style={{ width: '100%', display: 'flex' }}>
                                        <Grid style={{ display: 'flex', width: '35%' }}>
                                            <Typography style={{ fontFamily: 'Roboto', fontWeight: 'bold', fontSize: 10, color: 'black', width: '40%' }}>
                                                Scheduled For:
                                            </Typography>
                                            <Typography style={{ fontFamily: 'Roboto', fontWeight: 'medium', fontSize: 9, color: 'black' }}>
                                                
                                            </Typography>
                                        </Grid>
                                        <Grid style={{ display: 'flex', width: '35%' }}>
                                            <Typography style={{ fontFamily: 'Roboto', fontWeight: 'bold', fontSize: 10, color: 'black', width: '40%' }}>
                                                Quoted By:
                                            </Typography>
                                            <Typography style={{ fontFamily: 'Roboto', fontWeight: 'medium', fontSize: 9, color: 'black' }}>
                                                
                                            </Typography>
                                        </Grid>
                                        <Grid style={{ display: 'flex', width: '30%' }}>
                                            <Typography style={{ fontFamily: 'Roboto', fontWeight: 'bold', fontSize: 10, color: 'black', width: '40%' }}>
                                                Quote Date:
                                            </Typography>
                                            <Typography style={{ fontFamily: 'Roboto', fontWeight: 'medium', fontSize: 9, color: 'black' }}>
                                                {props.data && props.data.job && props.data.job.bidAppointment != null ? moment(props.data.job.bidAppointment).format('MM/DD/YYYY') : ""}
                                            </Typography>
                                        </Grid>
                                    </Grid>
                                </Grid>
                                <Grid style={{ width: '100%', border: '1px solid #006DB1', borderTop: 0, marginTop: -1 }}>
                                    <Grid style={{ width: '100%', height: 'auto', display: 'flex' }}>
                                        <Typography style={{ fontSize: 10, width: '15%', border: '1px solid #006DB1', borderTop: 0, textAlign: 'center', padding: 6, borderRight: 0, borderLeft: 0, fontFamily: 'Roboto', fontWeight: 'bold', fontSize: '10px' }}>Quantity</Typography>
                                        <Typography style={{ fontSize: 10, width: '55%', border: '1px solid #006DB1', borderTop: 0, textAlign: 'left', padding: 6, borderRight: 0, fontFamily: 'Roboto', fontWeight: 'bold', fontSize: '10px' }}>Description</Typography>
                                        <Typography style={{ fontSize: 10, width: '15%', border: '1px solid #006DB1', borderTop: 0, textAlign: 'center', padding: 6, borderRight: 0, fontFamily: 'Roboto', fontWeight: 'bold', fontSize: '10px' }}>Unit Price</Typography>
                                        <Typography style={{ fontSize: 10, width: '15%', border: '1px solid #006DB1', borderTop: 0, textAlign: 'center', padding: 6, fontFamily: 'Roboto', fontWeight: 'bold', fontSize: '10px', borderRight: 0, }}>Qnty Total</Typography>
                                    </Grid>

                                    {props.data && props.data.jobPriceItems && props.data.job.displayOnPrintOutsEmails && props.data.jobPriceItems.map(function (value, index, array) {
                                        return <Grid key={index} style={{width: '100%', height: 'auto', display: 'flex'}}>
                                            <Typography style={{ fontFamily: 'Roboto', fontWeight: 'medium', fontSize: 10, width: '15%', border: '1px solid #006DB1', textAlign: 'center', padding: 6, borderTop: 0, borderRight: 0, borderLeft: 0, }}>
                                                {value.quantity}
                                            </Typography>
                                            <Typography style={{ fontFamily: 'Roboto', fontWeight: 'medium', fontSize: 10, width: '55%', border: '1px solid #006DB1', textAlign: 'left', padding: 6, borderRight: 0, borderTop: 0 }}>
                                                {value.description}
                                            </Typography>
                                            <Typography style={{ fontFamily: 'Roboto', fontWeight: 'medium', fontSize: 10, width: '15%', border: '1px solid #006DB1', textAlign: 'right', padding: 6, borderRight: 0, borderTop: 0 }}>
                                                {(value && value.price && `$${value.price.toFixed(2)}`) || '$0.00'}
                                            </Typography>
                                            <Typography style={{ fontFamily: 'Roboto', fontWeight: 'medium', fontSize: 10, width: '15%', border: '1px solid #006DB1', textAlign: 'right', padding: 6, borderTop: 0, borderRight: 0, }}>
                                                {(value && value.total && `$${value.total.toFixed(2)}`) || '$0.00'}
                                            </Typography>
                                        </Grid>
                                    })}

                                    {props.data && props.data.job && props.data.job.discountPercent > 0 && props.data.job.discountDollars > 0
                                    ? <Grid style={{width: '100%', height: 'auto', display: 'flex'}}>
                                        <Typography style={{ width: '15%' }}></Typography>
                                        <Typography style={{ width: '55%'}}></Typography>
                                        <Typography style={{ width: '15%', border: '1px solid #006DB1', borderBottom: 0, borderLeft: 0, borderRight: 0, borderTop: 0, fontSize: 10,textAlign: 'right', padding: 6, fontFamily: 'Roboto', fontWeight: 'bold', fontSize: '10px' }}>
                                            Discount ({props.data.job.discountPercent}%)
                                        </Typography>
                                        <Typography style={{ width: '15%', border: '1px solid #006DB1', borderTop: 0, fontSize: 10,textAlign: 'right', padding: 6, fontFamily: 'Roboto', fontWeight: 'medium', borderRight: 0 }}>
                                            {(props.data && props.data.job && `$${props.data.job.discountDollars.toFixed(2)}`) || '$0.00'}
                                        </Typography>
                                    </Grid>
                                    : null }

                                    <Grid style={{width: '100%', height: 'auto', display: 'flex'}}>
                                        <Typography style={{ width: '15%' }}></Typography>
                                        <Typography style={{ width: '55%'}}></Typography>
                                        <Typography style={{ width: '15%', border: '1px solid #006DB1', borderBottom: 0, borderLeft: 0, borderRight: 0, borderTop: 0, fontSize: 10,textAlign: 'right', padding: 6, fontFamily: 'Roboto', fontWeight: 'bold', fontSize: '10px' }}>
                                            Sub-Total
                                        </Typography>
                                        <Typography style={{ width: '15%', border: '1px solid #006DB1', borderTop: 0, fontSize: 10,textAlign: 'right', padding: 6, fontFamily: 'Roboto', fontWeight: 'medium', borderRight: 0 }}>
                                            {(props.data && props.data.subTotal && `$${props.data.subTotal.toFixed(2)}`) || '$0.00'}
                                        </Typography>
                                    </Grid>
                                    
                                    <Grid style={{width: '100%', height: 'auto', display: 'flex'}}>
                                        <Typography style={{ width: '15%' }}></Typography>
                                        <Typography style={{ width: '55%'}}></Typography>
                                        <Typography style={{ width: '15%', border: '1px solid #006DB1', borderBottom: 0, borderLeft: 0, borderRight: 0, borderTop: 0, fontSize: 10,textAlign: 'right', padding: 6, fontFamily: 'Roboto', fontWeight: 'bold', fontSize: '10px' }}>
                                            Tax ({props.data && props.data.job ? props.data.job.taxPercent : "0"}%)
                                        </Typography>
                                        <Typography style={{ width: '15%', border: '1px solid #006DB1', borderTop: 0, fontSize: 10,textAlign: 'right', padding: 6, fontFamily: 'Roboto', fontWeight: 'medium', borderRight: 0 }}>
                                            {(props.data && props.data.taxDollar && `$${props.data.taxDollar.toFixed(2)}`) || '$0.00'}
                                        </Typography>
                                    </Grid>
                                    <Grid style={{width: '100%', height: 'auto', display: 'flex'}}>
                                        <Typography style={{ width: '15%' }}></Typography>
                                        <Typography style={{ width: '55%'}}></Typography>
                                        <Typography style={{ width: '15%', border: '1px solid #006DB1', borderBottom: 0, borderLeft: 0, borderRight: 0, borderTop: 0, fontSize: 10,textAlign: 'right', padding: 6, fontFamily: 'Roboto', fontWeight: 'bold', fontSize: '10px' }}>
                                            Total
                                        </Typography>
                                        <Typography style={{ width: '15%', border: '1px solid #006DB1', borderTop: 0, fontSize: 10,textAlign: 'right', padding: 6, fontFamily: 'Roboto', fontWeight: 'medium', borderRight: 0, borderBottom: 0 }}>
                                            {(props.data && props.data.totalAmount && `$${props.data.totalAmount.toFixed(2)}`) || '$0.00'}
                                        </Typography>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid style={{ height: 'auto', backgroundColor: '#E3ECF6', justifyContent: 'center', paddingLeft: 5, border: '1px solid #0D76BA' }}>
                            <Grid style={{ display: 'flex', alignItems: 'center' }}>
                                <Typography style={{ fontFamily: 'Roboto', fontWeight: 'bold', fontSize: 13, color: '#0D76BA', marginRight: 5 }}>
                                    PART 2
                                </Typography>
                                <Typography style={{ fontFamily: 'Roboto', fontWeight: 'bold', fontSize: 13, color: '#E26E20' }}>
                                    ATTENTION CUSTOMER: PLEASE READ!
                                </Typography>
                            </Grid>
                        </Grid>
                        <Grid style={{ height: 'auto', paddingLeft: 5, paddingRight: 5 }}>
                            <Grid style={{ alignItems: 'center' }}>
                                <Typography style={{ fontFamily: 'Roboto', fontWeight: 'bold', fontSize: 13, color: 'black', textAlign: 'center' }}>
                                    GLASS SAFE™ - OUR CLEANING PROCESS
                                </Typography>
                                <Typography style={{ fontFamily: 'Roboto', fontWeight: 'medium', fontSize: 10, color: 'black', textAlign: 'center', marginTop: 3 }}>
                                {props.data && props.data.company ? props.data.company.legalName : ""} window cleaning technicians are trained to clean windows according to the GANA/IWCA Bulletin 01-0116
                                </Typography>
                                <Typography style={{ fontFamily: 'Roboto', fontWeight: 'medium', fontSize: 10, color: 'black', marginTop: 3, textAlign: 'center' }}>
                                    Proper Procedures for Cleaning Architectural Glass Products.
                                </Typography>
                            </Grid>
                        </Grid>
                        <Grid style={{ marginTop: 5, height: 'auto', backgroundColor: '#4D92CA', justifyContent: 'center', paddingLeft: 5, paddingRight: 5, borderTop: 1, borderRight: 1, borderBottom: 1, borderColor: '#0D76BA' }}>
                            <Grid style={{ display: 'flex', alignItems: 'center' }}>
                                <Typography style={{ fontFamily: 'Roboto', fontWeight: 'bold', fontSize: 13, color: 'white', textAlign: 'center' }}>
                                    PART 3 » TECHNICIAN PLEASE REVIEW CLEANING PROCESSES ON BACK SIDE WITH CUSTOMER AT THIS TIME «
                                </Typography>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>

                <Grid  style={{ display: 'flex', width: '100%', marginTop: 10, paddingLeft: 5, paddingRight: 5 }}>
                    <Typography style={{ fontFamily: 'Roboto', fontWeight: 'medium', fontSize: 10, color: 'black' }}>
                        {props.data && props.data.company ? props.data.company.workOrderForm : ""} 
                    </Typography>              
                </Grid>

                <Grid  style={{ marginTop: 5 }}>
                    <Grid style={{ width: '100%', height: dataPlaceOne && dataPlaceOne.selectedSign ? 80 : (props.data && props.data.signatures && props.data.signatures.length > 0 && props.data.signatures.filter(function(item) { return item.signaturePosition == 1 } ) && props.data.signatures.filter(function(item) { return item.signaturePosition == 1 } ).length > 0 ? 80 : 30), backgroundColor: '#F5C6A1', border: '2px solid #E26E20', justifyContent: 'flex-end', paddingLeft: 5, paddingRight: 5 }}>
                        <Grid style={{ display: 'flex', alignItems: 'center', marginBottom: 5 }}>
                            <Grid style={{ display: 'flex', width: '50%', marginRight: 5, height: dataPlaceOne && dataPlaceOne.selectedSign ? 70 : (props.data && props.data.signatures && props.data.signatures.length > 0 && props.data.signatures.filter(function(item) { return item.signaturePosition == 1 } ) && props.data.signatures.filter(function(item) { return item.signaturePosition == 1 } ).length > 0 ? 70 : 24) }}>
                                <Typography style={{ fontFamily: 'Roboto', fontWeight: 'bold', fontSize: 10, color: 'black', width: '30%' }}>
                                    Customer Approval Signature
                                </Typography>
                                <Grid style={{ width: '1%' }} />
                                <Grid style={{ borderBottom: '1px solid black', width: '69%', display: 'flex' }}>
                                    <IconButton onClick={() => openModalSign(1)} style={{ widtht: 10, height: '100%', width: '10%' }}>
                                        <NoteAdd />
                                    </IconButton>
                                    <Typography style={{ fontFamily: 'Roboto', fontWeight: 'medium', fontSize: 9, color: 'black', width: '90%' }}>
                                        {dataPlaceOne && dataPlaceOne.selectedSign 
                                            ? <img src={dataPlaceOne.selectedSign} width="100%" height="65px" />
                                            : props.data && props.data.signatures && props.data.signatures.length > 0  && 
                                                <img src={
                                                    props.data.signatures.filter(function(item) { return item.signaturePosition == 1 } ) && props.data.signatures.filter(function(item) { return item.signaturePosition == 1 } ).length > 0 
                                                        ? props.data.signatures.filter(function(item) { return item.signaturePosition == 1 } )[0].signatureUrl 
                                                        : null} width="100%" height="65px" 
                                                />
                                        }
                                    </Typography>
                                </Grid>
                            </Grid>
                            <Grid style={{ display: 'flex', alignItems: 'center', width: '15%', marginRight: 5, height: dataPlaceOne && dataPlaceOne.selectedSign ? 70 : (props.data && props.data.signatures && props.data.signatures.length > 0 && props.data.signatures.filter(function(item) { return item.signaturePosition == 1 } ) && props.data.signatures.filter(function(item) { return item.signaturePosition == 1 } ).length > 0 ? 70 : 24) }}>
                                <Typography style={{ fontFamily: 'Roboto', fontWeight: 'bold', fontSize: 8, color: 'black', width: '25%' }}>
                                    Date
                                </Typography>
                                <Grid style={{ width: '1%' }} />
                                <Grid style={{ borderBottom: '1px solid black', width: '75%' }}>
                                    <Typography style={{ fontFamily: 'Roboto', fontWeight: 'medium', fontSize: 9, color: 'black' }}>
                                        {dataPlaceOne && dataPlaceOne.date 
                                            ? moment(dataPlaceOne.date).format('MM/DD/YYYY')
                                            : props.data && props.data.signatures && props.data.signatures.length > 0  && props.data.signatures.filter(function(item) { return item.signaturePosition == 1 } ) && props.data.signatures.filter(function(item) { return item.signaturePosition == 1 } ).length > 0 
                                                ? moment(props.data.signatures.filter(function(item) { return item.signaturePosition == 1 } )[0].signatureDate).format('MM/DD/YYYY')
                                                : null 
                                        }
                                    </Typography>
                                </Grid>
                            </Grid>
                            <Grid style={{ display: 'flex', alignItems: 'center', width: '35%', height: dataPlaceOne && dataPlaceOne.selectedSign ? 70 : (props.data && props.data.signatures && props.data.signatures.length > 0 && props.data.signatures.filter(function(item) { return item.signaturePosition == 1 } ) && props.data.signatures.filter(function(item) { return item.signaturePosition == 1 } ).length > 0 ? 70 : 24) }}>
                                <Typography style={{ fontFamily: 'Roboto', fontWeight: 'bold', fontSize: 8, color: 'black', width: '40%', display: 'flex' }}>
                                    Total Amount Due 
                                    <Typography style={{ fontFamily: 'Roboto', fontWeight: 'medium', fontStyle: 'regularitalic', fontSize: 7 }}>
                                        (including Tax)
                                    </Typography>: $ 
                                </Typography>
                                <Grid style={{ borderBottom: '1px solid black', width: '60%' }}>
                                    <Typography style={{ fontFamily: 'Roboto', fontWeight: 'medium', fontSize: 9, color: 'black' }}>
                                        {props.data ? props.data.jobTotal : ""}
                                    </Typography>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>

                <Grid  style={{ marginTop: 5, height: 'auto', display: 'flex' }}>
                    <Grid style={{ width: '5%', backgroundColor: '#F9DCC4', border: '1px solid #E26E20', alignItems: 'center', justifyContent: 'center', paddingTop: 55 }}>
                        <Typography style={{ fontFamily: 'Roboto', fontWeight: 'bold', fontSize: 13, color: '#E26E20', transform: `rotate(270deg)`, marginTop: -6 }}>
                            E
                        </Typography>
                        <Typography style={{ fontFamily: 'Roboto', fontWeight: 'bold', fontSize: 13, color: '#E26E20', transform: `rotate(270deg)`, marginTop: -6 }}>
                            R
                        </Typography>
                        <Typography style={{ fontFamily: 'Roboto', fontWeight: 'bold', fontSize: 13, color: '#E26E20', transform: `rotate(270deg)`, marginTop: -6 }}>
                            U
                        </Typography>
                        <Typography style={{ fontFamily: 'Roboto', fontWeight: 'bold', fontSize: 13, color: '#E26E20', transform: `rotate(270deg)`, marginTop: -6 }}>
                            T
                        </Typography>
                        <Typography style={{ fontFamily: 'Roboto', fontWeight: 'bold', fontSize: 13, color: '#E26E20', transform: `rotate(270deg)`, marginTop: -6 }}>
                            R
                        </Typography>
                        <Typography style={{ fontFamily: 'Roboto', fontWeight: 'bold', fontSize: 13, color: '#E26E20', transform: `rotate(270deg)`, marginTop: -6 }}>
                            A
                        </Typography>
                        <Typography style={{ fontFamily: 'Roboto', fontWeight: 'bold', fontSize: 13, color: '#E26E20', transform: `rotate(270deg)`, marginTop: -6 }}>
                            P
                        </Typography>
                        <Typography style={{ fontFamily: 'Roboto', fontWeight: 'bold', fontSize: 13, color: '#E26E20', transform: `rotate(270deg)`, marginTop: -6 }}>
                            E
                        </Typography>
                        <Typography style={{ fontFamily: 'Roboto', fontWeight: 'bold', fontSize: 13, color: '#E26E20', transform: `rotate(270deg)`, marginTop: -6 }}>
                            D
                        </Typography>
                    </Grid>
                    <Grid style={{ width: '95%', backgroundColor: 'white' }}>
                        <Grid style={{ height: 'auto', backgroundColor: '#E3ECF6', justifyContent: 'center', paddingLeft: 5, border: '1px solid #0D76BA' }}>
                            <Grid style={{ display: 'flex', alignItems: 'center' }}>
                                <Typography style={{ fontFamily: 'Roboto', fontWeight: 'bold', fontSize: 13, color: '#0D76BA', marginRight: 5 }}>
                                    PART 4
                                </Typography>
                                <Typography style={{ fontFamily: 'Roboto', fontWeight: 'bold', fontSize: 13, color: '#E26E20' }}>
                                    CUSTOMER WALKTHROUGH ENSURES YOUR SATISFACTION
                                </Typography>
                            </Grid>
                        </Grid>
                        <Grid style={{ height: 'auto', backgroundColor: 'white', justifyContent: 'center', padding: 5, border: '1px solid #0D76BA' }}>
                            <Grid style={{ display: 'flex', alignItems: 'center' }}>
                                <Typography style={{ fontFamily: 'Roboto', fontWeight: 'medium', fontSize: 9, color: 'black' }}>
                                    When our service is complete, please conduct a walk through with our crew to ensure you are 100% satisfied with our work. In the event of a cancellation, please
                                    call our office to reschedule; <Typography style={{ fontFamily: 'Roboto', fontWeight: 'bold', fontSize: 10, color: '#0D76BA', display: 'inline-block' }}>{props.data && props.data.company ? props.data.company.primaryPhoneNumber : ""}</Typography>. If you are not home at the time of service, {props.data && props.data.company ? props.data.company.legalName : ""} will <Typography style={{ fontFamily: 'Roboto', fontWeight: 'bold', fontSize: 10, color: 'black', display: 'inline-block' }}>NOT</Typography> use non-routine cleaning methods.
                                </Typography>
                            </Grid>
                        </Grid>
                        <Grid style={{ height: 'auto', paddingLeft: 5, paddingRight: 5, borderBottom: '1px solid #0D76BA' }}>
                            <Grid style={{ }}>
                                <Typography style={{ fontFamily: 'Roboto', fontWeight: 'bold', fontSize: 13, color: 'black' }}>
                                    Option 1
                                </Typography>
                                <Grid style={{ width: '100%', display: 'flex' }}>
                                    <Grid style={{ width: '60%' }}>
                                        <Grid style={{ display: 'flex', alignItems: 'center' }}>
                                            <Grid style={{ width: 7, height: 7, marginRight: 5 }}>
                                                <Grid style={{ width: '100%', height: '100%', border: '1px solid black' }}></Grid>
                                            </Grid>
                                            <Grid style={{ width: '95%' }}>
                                                <Typography style={{ fontFamily: 'Roboto', fontWeight: 'medium', fontSize: 9, color: 'black' }}>
                                                    Are all windows cleaned, screens cleaned & installed properly and sills cleaned to expectations?
                                                </Typography>
                                            </Grid>
                                        </Grid>
                                        <Grid style={{ display: 'flex', alignItems: 'center' }}>
                                            <Grid style={{ width: 7, height: 7, marginRight: 5 }}>
                                                <Grid style={{ width: '100%', height: '100%', border: '1px solid black' }}></Grid>
                                            </Grid>
                                            <Grid style={{ width: '95%' }}>
                                                <Typography style={{ fontFamily: 'Roboto', fontWeight: 'medium', fontSize: 9, color: 'black' }}>
                                                    Did your crew take off their shoes & protect any interior walls with towels if a ladder was used inside?
                                                </Typography>
                                            </Grid>
                                        </Grid>
                                        <Grid style={{ display: 'flex' }}>
                                            <Grid style={{ display: 'flex', alignItems: 'center', marginRight: 5, width: '50%' }}>
                                                <Grid style={{ width: 7, height: 7, marginRight: 5 }}>
                                                    <Grid style={{ width: '100%', height: '100%', border: '1px solid black' }}></Grid>
                                                </Grid>
                                                <Grid style={{ width: '95%' }}>
                                                    <Typography style={{ fontFamily: 'Roboto', fontWeight: 'medium', fontSize: 9, color: 'black' }}>
                                                        Was the crew kind, courteous & professional?
                                                    </Typography>
                                                </Grid>
                                            </Grid>
                                            <Grid style={{ display: 'flex', alignItems: 'center', width: '50%' }}>
                                                <Grid style={{ width: 7, height: 7, marginRight: 5 }}>
                                                    <Grid style={{ width: '100%', height: '100%', border: '1px solid black' }}></Grid>
                                                </Grid>
                                                <Grid style={{ width: '95%' }}>
                                                    <Typography style={{ fontFamily: 'Roboto', fontWeight: 'medium', fontSize: 9, color: 'black' }}>
                                                    Were they in uniform?
                                                    </Typography>
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                    <Grid style={{ width: '2%' }} />
                                    <Grid style={{ width: '38%' }}>
                                        <Grid style={{ display: 'flex', alignItems: 'center' }}>
                                            <Grid style={{ width: 7, height: 7, marginRight: 5 }}>
                                                <Grid style={{ width: '100%', height: '100%', border: '1px solid black' }}></Grid>
                                            </Grid>
                                            <Grid style={{ width: '95%' }}>
                                                <Typography style={{ fontFamily: 'Roboto', fontWeight: 'medium', fontSize: 9, color: 'black' }}>
                                                    Are all window treatments & furniture moved back to the original positions?
                                                </Typography>
                                            </Grid>
                                        </Grid>
                                        <Grid style={{ display: 'flex', alignItems: 'center' }}>
                                            <Grid style={{ width: 7, height: 7, marginRight: 5 }}>
                                                <Grid style={{ width: '100%', height: '100%', border: '1px solid black' }}></Grid>
                                            </Grid>
                                            <Grid style={{ width: '95%' }}>
                                                <Typography style={{ fontFamily: 'Roboto', fontWeight: 'medium', fontSize: 9, color: 'black' }}>
                                                    Has all work been performed to your expectations?
                                                </Typography>
                                            </Grid>
                                        </Grid>
                                        <Grid style={{ display: 'flex' }}>
                                            <Grid style={{ display: 'flex', alignItems: 'center', marginRight: 5, width: '70%' }}>
                                                <Grid style={{ width: '95%' }}>
                                                    <Typography style={{ fontFamily: 'Roboto', fontWeight: 'medium', fontSize: 9, color: 'black' }}>
                                                        Would you like a follow up call from a manager?
                                                    </Typography>
                                                </Grid>
                                            </Grid>
                                            <Grid style={{ display: 'flex', alignItems: 'center', width: '30%' }}>
                                                <Grid style={{ width: '100%', display: 'flex', alignItems: 'center' }}>
                                                    <Grid style={{ width: 7, height: 7, marginRight: 5 }}>
                                                        <Grid style={{ width: '100%', height: '100%', border: '1px solid black' }}></Grid>
                                                    </Grid>
                                                    <Typography style={{ fontFamily: 'Roboto', fontWeight: 'medium', fontSize: 9, color: 'black' }}>
                                                        Yes
                                                    </Typography>
                                                </Grid>
                                                
                                                <Grid style={{ width: '100%', display: 'flex', alignItems: 'center' }}>
                                                    <Grid style={{ width: 7, height: 7, marginRight: 5 }}>
                                                        <Grid style={{ width: '100%', height: '100%', border: '1px solid black' }}></Grid>
                                                    </Grid>
                                                    <Typography style={{ fontFamily: 'Roboto', fontWeight: 'medium', fontSize: 9, color: 'black' }}>
                                                        No
                                                    </Typography>
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Grid>
                            <Grid style={{ marginTop: 10 }}>
                                <Typography style={{ fontFamily: 'Roboto', fontWeight: 'bold', fontSize: 13, color: 'black' }}>
                                    Option 2
                                </Typography>
                                <Grid style={{ width: '100%', display: 'flex' }}>
                                    <Grid style={{ width: '60%' }}>
                                        <Grid style={{ display: 'flex', alignItems: 'center' }}>
                                            <Grid style={{ width: 7, height: 7, marginRight: 5 }}>
                                                <Grid style={{ width: '100%', height: '100%', border: '1px solid black' }}></Grid>
                                            </Grid>
                                            <Grid style={{ width: '95%' }}>
                                                <Typography style={{ fontFamily: 'Roboto', fontWeight: 'medium', fontSize: 9, color: 'black' }}>
                                                    I have conducted a walk through and brought any concerns to the crew’s attention.I would prefer to submit online comments.
                                                </Typography>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                    <Grid style={{ width: '2%' }} />
                                    <Grid style={{ width: '38%' }}>
                                        <Grid style={{ display: 'flex', alignItems: 'center' }}>
                                            <Grid style={{ width: 7, height: 7, marginRight: 5 }}>
                                                <Grid style={{ width: '100%', height: '100%', border: '1px solid black' }}></Grid>
                                            </Grid>
                                            <Grid style={{ width: '95%' }}>
                                                <Typography style={{ fontFamily: 'Roboto', fontWeight: 'medium', fontSize: 9, color: 'black' }}>
                                                    <Typography style={{ fontFamily: 'Roboto', fontWeight: 'bold', fontSize: 9, color: 'black', display: 'inline-block' }}>
                                                            STAFF ONLY:
                                                        </Typography> Customer was not home at the time of the walk through.
                                                </Typography>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>

                <Grid  style={{ marginTop: 5 }}>
                    <Grid style={{ width: '100%', height: 130, border: '1px solid #006DB1' }}>
                        {/* <Grid style={{ width: '100%', height: '35%', backgroundColor: '#006DB1', padding: 5 }}>
                            <Grid style={{ borderTop: 2, borderTopColor: 'white', borderBottom: 2, borderBottomColor: 'white', width: '100%', height: '100%', alignItems: 'center', paddingTop: 3, paddingBottom: 3, justifyContent: 'center' }}>
                                <Typography style={{ fontFamily: 'Roboto', fontWeight: 'bold', fontSize: 15, color: 'white' }}>
                                    FRANCHISES AVAILABLE NATIONWIDE!
                                </Typography>
                            </Grid>
                        </Grid> */}
                        <Grid style={{ width: '100%', height: '100%', backgroundColor: '#E9ECF8', display: 'flex' }}>
                            <Grid style={{ width: '1%' }} />
                            <Grid style={{ width: '68%' }}>
                                <Grid style={{ display: 'flex' }}>
                                    <Grid style={{ width: '3%'}}>
                                        <Typography style={{ fontFamily: 'Roboto', fontWeight: 'bold', fontSize: 8, color: '#0E76BA', marginTop: 5 }}>
                                            1.
                                        </Typography>
                                    </Grid>
                                    <Grid style={{ width: '97%' }}>
                                        <Typography style={{ fontFamily: 'Roboto', fontWeight: 'medium', fontSize: 8, color: 'black', marginTop: 5 }}>
                                            Fully insured company, with Squeegee Squad employees thoroughly trained by owners and management.
                                        </Typography>
                                    </Grid>
                                </Grid>
                                <Grid style={{ display: 'flex' }}>
                                    <Grid style={{ width: '3%'}}>
                                        <Typography style={{ fontFamily: 'Roboto', fontWeight: 'bold', fontSize: 8, color: '#0E76BA', marginTop: 5 }}>
                                            2.
                                        </Typography>
                                    </Grid>
                                    <Grid style={{ width: '97%' }}>
                                        <Typography style={{ fontFamily: 'Roboto', fontWeight: 'medium', fontSize: 8, color: 'black', marginTop: 5 }}>
                                            Experienced crew, always in uniform.
                                        </Typography>
                                    </Grid>
                                </Grid>
                                <Grid style={{ display: 'flex' }}>
                                    <Grid style={{ width: '3%'}}>
                                        <Typography style={{ fontFamily: 'Roboto', fontWeight: 'bold', fontSize: 8, color: '#0E76BA', marginTop: 5 }}>
                                            3.
                                        </Typography>
                                    </Grid>
                                    <Grid style={{ width: '97%' }}>
                                        <Typography style={{ fontFamily: 'Roboto', fontWeight: 'medium', fontSize: 8, color: 'black', marginTop: 5 }}>
                                            Fast, efficient workers maintaining focus on quality service and protection inside and outside of your home.
                                        </Typography>
                                    </Grid>
                                </Grid>
                                <Grid style={{ display: 'flex' }}>
                                    <Grid style={{ width: '3%'}}>
                                        <Typography style={{ fontFamily: 'Roboto', fontWeight: 'bold', fontSize: 8, color: '#0E76BA', marginTop: 5 }}>
                                            4.
                                        </Typography>
                                    </Grid>
                                    <Grid style={{ width: '97%' }}>
                                        <Typography style={{ fontFamily: 'Roboto', fontWeight: 'medium', fontSize: 8, color: 'black', marginTop: 5 }}>
                                            Safety training implemented with our staff regularly.
                                        </Typography>
                                    </Grid>
                                </Grid>
                                <Grid style={{ display: 'flex' }}>
                                    <Grid style={{ width: '3%'}}>
                                        <Typography style={{ fontFamily: 'Roboto', fontWeight: 'bold', fontSize: 8, color: '#0E76BA', marginTop: 5 }}>
                                            5.
                                        </Typography>
                                    </Grid>
                                    <Grid style={{ width: '97%' }}>
                                        <Typography style={{ fontFamily: 'Roboto', fontWeight: 'medium', fontSize: 8, color: 'black', marginTop: 5 }}>
                                            Employee background checks mandated during employee hiring process.
                                        </Typography>
                                    </Grid>
                                </Grid>
                                <Grid style={{ display: 'flex' }}>
                                    <Grid style={{ width: '3%'}}>
                                        <Typography style={{ fontFamily: 'Roboto', fontWeight: 'bold', fontSize: 8, color: '#0E76BA', marginTop: 5 }}>
                                            6.
                                        </Typography>
                                    </Grid>
                                    <Grid style={{ width: '97%' }}>
                                        <Typography style={{ fontFamily: 'Roboto', fontWeight: 'medium', fontSize: 8, color: 'black', marginTop: 5 }}>
                                            Consistently given 5-star online reviews - we get the BEST ratings in the industry
                                        </Typography>
                                    </Grid>
                                </Grid>
                            </Grid>
                            <Grid style={{ width: '1%' }} />
                            <Grid style={{ width: '29%', alignItems: 'center', justifyContent: 'center' }}>
                                {props.data?.company?.logoUrl?.includes('companylogo')
                                ? <img
                                    src={props.data.company.logoUrl}
                                    style={{ height: '100%', width:'100%'}}
                                />
                                : <img style={{ height: '100%', width:'100%' }} />
                                }
                            </Grid>
                            <Grid style={{ width: '1%' }} />
                        </Grid>
                    </Grid>
                </Grid>

                <Grid style={{ marginTop: 5, display: 'flex' }}>
                    <Grid style={{ width: '5%', backgroundColor: '#F9DCC4', border: '1px solid #E26E20', alignItems: 'center', justifyContent: 'center' }}>
                        <Typography style={{ fontFamily: 'Roboto', fontWeight: 'bold', fontSize: 13, color: '#E26E20', transform: `rotate(270deg)`, marginTop: -6 }}>
                            L
                        </Typography>
                        <Typography style={{ fontFamily: 'Roboto', fontWeight: 'bold', fontSize: 13, color: '#E26E20', transform: `rotate(270deg)`, marginTop: -6 }}>
                            A
                        </Typography>
                        <Typography style={{ fontFamily: 'Roboto', fontWeight: 'bold', fontSize: 13, color: '#E26E20', transform: `rotate(270deg)`, marginTop: -6 }}>
                            V
                        </Typography>
                        <Typography style={{ fontFamily: 'Roboto', fontWeight: 'bold', fontSize: 13, color: '#E26E20', transform: `rotate(270deg)`, marginTop: -6 }}>
                            I
                        </Typography>
                        <Typography style={{ fontFamily: 'Roboto', fontWeight: 'bold', fontSize: 13, color: '#E26E20', transform: `rotate(270deg)`, marginTop: -6 }}>
                            R
                        </Typography>
                        <Typography style={{ fontFamily: 'Roboto', fontWeight: 'bold', fontSize: 13, color: '#E26E20', transform: `rotate(270deg)`, marginTop: -6 }}>
                            R
                        </Typography>
                        <Typography style={{ fontFamily: 'Roboto', fontWeight: 'bold', fontSize: 13, color: '#E26E20', transform: `rotate(270deg)`, marginTop: -6 }}>
                            A
                        </Typography>
                    </Grid>
                    <Grid style={{ width: '95%', backgroundColor: 'white' }}>
                        <Grid style={{ height: 'auto', backgroundColor: '#E3ECF6', justifyContent: 'center', paddingLeft: 5, border: '1px solid #0D76BA', borderLeft: 0 }}>
                            <Grid style={{ display: 'flex', alignItems: 'center' }}>
                                <Typography style={{ fontFamily: 'Roboto', fontWeight: 'bold', fontSize: 13, color: '#0D76BA', marginRight: 5 }}>
                                    PART 3
                                </Typography>
                                <Typography style={{ fontFamily: 'Roboto', fontWeight: 'bold', fontSize: 13, color: '#E26E20' }}>
                                    GLASS SAFE™ - OUR CLEANING PROCESS
                                </Typography>
                            </Grid>
                        </Grid>
                        <Grid style={{ height: 'auto', borderBottom: '1px solid #0D76BA' }}>
                            <Grid style={{ paddingLeft: 5, paddingRight: 5 }}>
                                <Typography style={{ fontFamily: 'Roboto', fontWeight: 'bold', fontSize: 13, color: 'black' }}>
                                    ROUTINE GLASS CLEANING
                                </Typography>
                                <Typography style={{ fontFamily: 'Roboto', fontWeight: 'medium', fontSize: 10, color: 'black', marginTop: 3 }}>
                                    Unless requested in writing by the homeowner or client, our window cleaners will only use routine window glass cleaning methods. Routine window glass cleaning includes the use of
                                    tools and mild detergents such as: soft cloths, strip washers, squeegees, non-abrasive pads, water-fed poles, and mild detergents.
                                </Typography>
                                <Typography style={{ fontFamily: 'Roboto', fontWeight: 'medium', fontSize: 10, color: 'black', marginTop: 3 }}>
                                    • Routine cleaning methods <Typography style={{ display: 'inline-flex', fontFamily: 'Roboto', fontWeight: 'bold', textDecoration: 'underline', fontSize: 10, color: 'black', marginTop: 3 }}>
                                    will remove
                                </Typography> normal dirt and debris that can adhere between frequent window cleaning services. 
                                </Typography>
                                <Grid style={{ width: '100%', display: 'flex' }}>
                                    <Grid style={{ width: '50%' }}>
                                        <Typography style={{ fontFamily: 'Roboto', fontWeight: 'medium', fontSize: 10, color: 'black', marginTop: 3 }}>
                                            • Routine cleaning methods <Typography style={{ display: 'inline-flex', fontFamily: 'Roboto', fontWeight: 'bold', textDecoration: 'underline', fontSize: 10, color: 'black', marginTop: 3 }}>
                                            may not remove
                                </Typography> all stains and weathering that can
                                            occur due to infrequent cleaning or when windows are improperly protected during
                                            construction or remodeling such as: hard water, artillery fungus spores, sap, tar, paint,
                                            adhesives, varnish, mortar, silicone, and more.
                                        </Typography>
                                    </Grid>
                                    <Grid style={{ width: '50%', justifyContent: 'center' }}>
                                        <Grid style={{ width: '100%', alignItems: 'center' }}>
                                            <Grid style={{ display: 'flex', alignItems: 'center', width: '80%' }}>
                                                <Grid style={{ width: '20%' }}>
                                                    <img style={{ width: 70 }} src="/images/quote_invoice/Group1Images/Group1Image1.jpg" />
                                                </Grid>
                                                <Grid style={{ width: '20%' }}>
                                                    <img style={{ width: 70 }} src="/images/quote_invoice/Group1Images/Group1Image2.jpg" />
                                                </Grid>
                                                <Grid style={{ width: '20%' }}>
                                                    <img style={{ width: 70 }} src="/images/quote_invoice/Group1Images/Group1Image3.jpg" />
                                                </Grid>
                                                <Grid style={{ width: '20%' }}>
                                                    <img style={{ width: 70 }} src="/images/quote_invoice/Group1Images/Group1Image4.jpg" />
                                                </Grid>
                                                <Grid style={{ width: '20%' }}>
                                                    <img style={{ width: 70 }} src="/images/quote_invoice/Group1Images/Group1Image5.jpg" />
                                                </Grid>
                                                <Grid style={{ width: '20%' }}>
                                                    <img style={{ width: 70 }} src="/images/quote_invoice/Group1Images/Group1Image6.jpg" />
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid style={{ height: 'auto', borderBottom: '1px solid #0D76BA' }}>
                            <Grid style={{ paddingLeft: 5, paddingRight: 5 }}>
                                <Typography style={{ fontFamily: 'Roboto', fontWeight: 'bold', fontSize: 13, color: 'black' }}>
                                    NON-ROUTINE GLASS RESTORATION
                                </Typography>
                                <Typography style={{ fontFamily: 'Roboto', fontWeight: 'medium', fontSize: 10, color: 'black', marginTop: 3 }}>
                                    Window restoration using non-routine cleaning methods will only be performed if after the homeowner or client has read this disclosure, and you agree in writing to the use of nonroutine methods by signing the authorization below. Non-routine window cleaning may include, but is not limited to, use of: metal razor blades, acids, steel wool, polishing compounds,
                                    and more. 
                                </Typography>
                                <Grid style={{ width: '100%', display: 'flex' }}>
                                    <Grid style={{ width: '15%', alignItems: 'center', justifyContent: 'center' }}>
                                        <img style={{ width: '90%' }} src="/images/quote_invoice/Group2Images/Group2Image1.jpg" />
                                    </Grid>
                                    <Grid style={{ width: '85%' }}>
                                        <Typography style={{ fontFamily: 'Roboto', fontWeight: 'medium', fontSize: 10, color: 'black', marginTop: 3 }}>
                                        <Typography style={{ fontFamily: 'Roboto', fontWeight: 'bold', fontSize: 10, color: 'black', marginTop: 3, display: 'inline-flex' }}>
                                        Razor Blade Option:
                                </Typography> The Glass Association of North America neither condones nor condemns the use of metal razor blades during non-routine cleaning procedures. It
                                            is widely understood that metal razor blades may be the only effective and effi cient method to clean the following stains and damage from glass:hard water, artillery
                                            fungus spores, sap, tar, paint, adhesives, varnish, mortar, silicone, and more.
                                        </Typography>
                                        <Typography style={{ fontFamily: 'Roboto', fontWeight: 'medium', fontSize: 10, color: 'black', marginTop: 7 }}>
                                            Although metal razor blades have historically been a standard tool of the trade for professional window cleaners, many manufacturers no longer recommend razor
                                            blade use because scratches can occur on the glass when razors interact with surface defects/fabricating debris that may be present on tempered and heat treated
                                            glass. Surface defects/fabricating debris are not 
                                        </Typography>
                                    </Grid>
                                </Grid>
                                <Typography style={{ fontFamily: 'Roboto', fontWeight: 'medium', fontSize: 10, color: 'black', marginTop: 3 }}>
                                    present on all tempered and heat treated glass and occur as the result of the manufacturer’s failure to completely clean the glass
                                    during the heat treatment process. It is also possible that using metal razor blades may also result in damage to refl ective coatings on glass. Most glass manufacturers prohibit the use
                                    of razor blades when cleaning glass, however, the glass industry acknowledges that razor blades must be used to remove certain stains as referred to above. <Typography style={{ display: 'inline-flex', fontFamily: 'Roboto', fontWeight: 'bold', fontSize: 10, color: 'black', marginTop: 3 }}>
                                    We are not liable for any
                                    damages that result from scratches to the glass.
                                </Typography>
                                </Typography>
                                <Typography style={{ fontFamily: 'Roboto', fontWeight: 'medium', fontSize: 10, color: 'black', marginTop: 7 }}>
                                    Before we go ahead and attempt to clean and restore your glass with razor blades, we want to be sure that you have read this important disclosure about the use of razor blades.
                                </Typography>
                            </Grid>
                        </Grid>
                        <Grid style={{ backgroundColor: '#0E76BA', height: 'auto', padding: 5 }}>
                            <Typography style={{ fontFamily: 'Roboto', fontWeight: 'bold', fontSize: 12, color: 'white', textAlign: 'center' }}>
                                BY REQUESTING THAT WE USE RAZOR BLADES IN CLEANING YOUR GLASS, YOU AGREEE THAT WE ARE NOT LIABLE IN ANY WAY FOR ANY DAMAGES THAT OCCUR AS A RESULT OF SCRATCHES TO YOUR WINDOWS. 
                            </Typography>
                        </Grid>
                        <Grid style={{ height: 'auto', borderBottom: '1px solid #0D76BA' }}>
                            <Grid style={{ padding: 5 }}>
                                <Typography style={{ fontFamily: 'Roboto', fontWeight: 'medium', fontSize: 10, color: 'black' }}>
                                    I, _____________________ (customer), having been informed of the possible damages that may occur, and acknowledging the responsibility and liability for and damages
                                    resulting from the use of the razor blades, request that you perform cleaning services with the use of razor blades
                                </Typography>

                                <Typography style={{ fontFamily: 'Roboto', fontWeight: 'medium', fontSize: 10, color: 'black', marginTop: 10 }}>
                                    (Signature) 
                                    <IconButton onClick={() => openModalSign(2)} style={{ widtht: 10, height: '100%' }}>
                                        <NoteAdd />
                                    </IconButton>
                                    {dataPlaceTwo && dataPlaceTwo.selectedSign 
                                        ? <img src={dataPlaceTwo.selectedSign} width="15%" />
                                        :   props.data && props.data.signatures && props.data.signatures.length > 0  
                                            ? <img src={
                                                    props.data.signatures.filter(function(item) { return item.signaturePosition == 2 } ) && props.data.signatures.filter(function(item) { return item.signaturePosition == 2 } ).length > 0 
                                                        ? props.data.signatures.filter(function(item) { return item.signaturePosition == 2 } )[0].signatureUrl 
                                                        : null} width="15%" 
                                                />
                                            : "________________________________________________________________________________"
                                    }
                                    (Date) {dataPlaceTwo && dataPlaceTwo.date 
                                        ? moment(dataPlaceTwo.date).format('MM/DD/YYYY') 
                                        : props.data && props.data.signatures && props.data.signatures.length > 0  && props.data.signatures.filter(function(item) { return item.signaturePosition == 2 } ) && props.data.signatures.filter(function(item) { return item.signaturePosition == 2 } ).length > 0
                                            ? moment(props.data.signatures.filter(function(item) { return item.signaturePosition == 2 } )[0].signatureDate).format('MM/DD/YYYY') 
                                            : '________________________________________________________________________________'
                                        }
                                </Typography>

                                <Typography style={{ fontFamily: 'Roboto', fontWeight: 'medium', fontSize: 10, color: 'black', marginTop: 10 }}>
                                    (Printed Name / Title) {dataPlaceTwo && dataPlaceTwo.name 
                                            ? <img src={dataPlaceTwo.name} width="50px" />
                                            :   props.data && props.data.signatures && props.data.signatures.length > 0  
                                                ? <img src={
                                                        props.data.signatures.filter(function(item) { return item.signaturePosition == 3 } ) && props.data.signatures.filter(function(item) { return item.signaturePosition == 3 } ).length > 0 
                                                            ? props.data.signatures.filter(function(item) { return item.signaturePosition == 3 } )[0].signatureUrl 
                                                            : null} width="50px" 
                                                    />
                                                : '_______________________________________________________________________________________________________________________________________________________________________'
                                        }
                                </Typography>
                            </Grid>
                        </Grid>
                        <Grid style={{ height: 'auto', borderBottom: '1px solid #0D76BA' }}>
                            <Grid style={{ paddingLeft: 5, paddingRight: 5 }}>
                                <Typography style={{ fontFamily: 'Roboto', fontWeight: 'bold', fontSize: 13, color: 'black' }}>
                                    ALTERNATIVE NON-ROUTINE GLASS RESTORATION
                                </Typography>
                                <Grid style={{ width: '100%', display: 'flex' }}>
                                    <Grid style={{ width: '65%' }}>
                                        <Typography style={{ fontFamily: 'Roboto', fontWeight: 'medium', fontSize: 10, color: 'black', marginTop: 3 }}>
                                            Although acids, super fi ne steel wool, polishing compounds and other chemicals are generally considered safe for cleaning
                                            glass, your window manufacturer may not recommend using these alternative types of non-routine glass restoration
                                            methods. If you have refl ective coatings on the interior or exterior surfaces of your glass, then these coatings may be altered
                                            and perceived as damaged during the cleaning/restoration process using these alternative non-routine cleaning methods
                                        </Typography>
                                    </Grid>
                                    <Grid style={{ width: '35%', alignItems: 'center', justifyContent: 'center', display: 'flex' }}>
                                        <Grid style={{ width: '20%' }}>
                                            <img style={{ width: 58 }} src="/images/quote_invoice/Group2Images/Group2Image2.jpg" />
                                        </Grid>
                                        <Grid style={{ width: '20%' }}>
                                            <img style={{ width: 58 }} src="/images/quote_invoice/Group2Images/Group2Image3.jpg" />
                                        </Grid>
                                        <Grid style={{ width: '20%' }}>
                                            <img style={{ width: 58 }} src="/images/quote_invoice/Group2Images/Group2Image4.jpg" />
                                        </Grid>
                                        <Grid style={{ width: '20%' }}>
                                            <img style={{ width: 58 }} src="/images/quote_invoice/Group2Images/Group2Image5.jpg" />
                                        </Grid>
                                        <Grid style={{ width: '20%' }}>
                                            <img style={{ width: 58 }} src="/images/quote_invoice/Group2Images/Group2Image6.jpg" />
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid style={{ backgroundColor: '#0E76BA', height: 'auto', padding: 5 }}>
                            <Typography style={{ fontFamily: 'Roboto', fontWeight: 'bold', fontSize: 12, color: 'white', textAlign: 'center', textTransform: 'uppercase' }}>
                                BY ReQUeSTING THAT We USe ACID, POLISHING COMPOUNDS, ABRASIveS, OR OTHeR CHeMICALS TO ATTeMPT TO ReSTORe YOUR GLASS, YOU
                                AGRee THAT We ARe NOT LIABLe IN ANY WAY FOR ANY DAMAGeS THAT MAY OCCUR AS A ReSULT OF THeSe ATTeMPTS TO ReSTORe YOUR
                                WINDOWS
                            </Typography>
                        </Grid>
                        <Grid style={{ height: 'auto', borderBottom: '1px solid #0D76BA' }}>
                            <Grid style={{ padding: 5 }}>
                                <Typography style={{ fontFamily: 'Roboto', fontWeight: 'medium', fontSize: 10, color: 'black' }}>
                                    I, _____________________ (customer), having been informed of the possible damages that may occur, and acknowledging the responsibility and liability for and damages
                                    resulting from the use of the methods described above, request that you perform window restoration services with the methods and chemicals referred to above.
                                </Typography>

                                <Typography style={{ fontFamily: 'Roboto', fontWeight: 'medium', fontSize: 10, color: 'black', marginTop: 10 }}>
                                    (Signature)
                                    <IconButton onClick={() => openModalSign(3)} style={{ widtht: 10, height: '100%' }}>
                                        <NoteAdd />
                                    </IconButton>
                                    {dataPlaceThree && dataPlaceThree.selectedSign 
                                        ? <img src={dataPlaceThree.selectedSign} width="15%" />
                                        :   props.data && props.data.signatures && props.data.signatures.length > 0  
                                            ? <img src={
                                                    props.data.signatures.filter(function(item) { return item.signaturePosition == 4 } ) && props.data.signatures.filter(function(item) { return item.signaturePosition == 4 } ).length > 0 
                                                        ? props.data.signatures.filter(function(item) { return item.signaturePosition == 4 } )[0].signatureUrl 
                                                        : null} width="15%" 
                                                />
                                            : "________________________________________________________________________________"
                                    }
                                    (Date) {dataPlaceThree && dataPlaceThree.date 
                                        ? moment(dataPlaceThree.date).format('MM/DD/YYYY') 
                                        : props.data && props.data.signatures && props.data.signatures.length > 0  && props.data.signatures.filter(function(item) { return item.signaturePosition == 4 } ) && props.data.signatures.filter(function(item) { return item.signaturePosition == 4 } ).length > 0
                                            ? moment(props.data.signatures.filter(function(item) { return item.signaturePosition == 4 } )[0].signatureDate).format('MM/DD/YYYY') 
                                            : '________________________________________________________________________________'
                                        }
                                </Typography>

                                <Typography style={{ fontFamily: 'Roboto', fontWeight: 'medium', fontSize: 10, color: 'black', marginTop: 10 }}>
                                    (Printed Name / Title) {dataPlaceThree && dataPlaceThree.name 
                                            ? <img src={dataPlaceThree.name} width="50px" />
                                            :   props.data && props.data.signatures && props.data.signatures.length > 0  
                                                ? <img src={
                                                        props.data.signatures.filter(function(item) { return item.signaturePosition == 5 } ) && props.data.signatures.filter(function(item) { return item.signaturePosition == 5 } ).length > 0 
                                                            ? props.data.signatures.filter(function(item) { return item.signaturePosition == 5 } )[0].signatureUrl 
                                                            : null} width="50px" 
                                                    />
                                                : '_______________________________________________________________________________________________________________________________________________________________________'
                                        }
                                </Typography>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>

                <Grid style={{ marginTop: 10, width: '100%', display: 'flex', alignItems: 'center' }}>
                    <Grid style={{ width: '20%' }}>
                        {props.data?.company?.logoUrl?.includes('companylogo')
                                ? <img
                                    src={props.data.company.logoUrl}
                                    style={{ height: '100%', width:'100%'}}
                                />
                                : <img style={{ height: '100%', width:'100%' }} />
                            }
                    </Grid>
                    <Grid style={{ width: '20%', alignItems: 'center' }}>
                        <Typography style={{ fontFamily: 'Roboto', fontWeight: 'bold', fontSize: 11, color: 'black', textAlign: 'center' }}>
                            {props.data && props.data.company ? props.data.company.legalName : ""}
                        </Typography>
                    </Grid>
                    <Grid style={{ width: '25%', alignItems: 'center' }}>
                        {props.data && props.data.company ? props.data.company.website : ""}
                        {/* <img src="/images/quote_invoice/Socials.jpg" style={{ width: '100%' }} /> */}
                    </Grid>
                    <Grid style={{ width: '35%' }}>
                        {/* <Typography style={{ fontFamily: 'Roboto', fontWeight: 'bold', fontSize: 11, color: 'black', textAlign: 'right' }}>
                            Phone: 000.000.0000
                        </Typography>
                        <Typography style={{ fontFamily: 'Roboto', fontWeight: 'bold', fontSize: 11, color: 'black', textAlign: 'right' }}>
                            Email: name@squeegeesquad.com
                        </Typography> */}
                        <Typography style={{ fontFamily: 'Roboto', fontWeight: 'bold', fontSize: 11, color: 'black', textAlign: 'right' }}>
                            {props.data && props.data.company && props.data.company.addresses && props.data.company.addresses.length > 0 ? (props.data.company.addresses[0].address1 || '') : ""}
                        </Typography>
                        {props.data && props.data.company && props.data.company.addresses && props.data.company.addresses.length > 0 && props.data.company.addresses[0].address2 && props.data.company.addresses[0].address2 != ""
                            ? <Typography style={{ fontFamily: 'Roboto', fontWeight: 'bold', fontSize: 11, color: 'black', textAlign: 'right' }}>
                                {props.data.company.addresses[0].address2}
                            </Typography>
                            : null 
                        }
                        <Typography style={{ fontFamily: 'Roboto', fontWeight: 'bold', fontSize: 11, color: 'black', textAlign: 'right' }}>
                            {props.data && props.data.company && props.data.company.addresses && props.data.company.addresses.length > 0 ? props.data.company.addresses[0].city+", " : ""}
                            {props.data && props.data.company && props.data.company.addresses && props.data.company.addresses.length > 0 ? props.data.company.addresses[0].state.name+" " : ""}
                            {props.data && props.data.company && props.data.company.addresses && props.data.company.addresses.length > 0 ? props.data.company.addresses[0].zipCode : ""}
                        </Typography>
                        <Typography style={{ fontFamily: 'Roboto', fontWeight: 'bold', fontSize: 11, color: 'black', textAlign: 'right' }}>
                            {props.data && props.data.company && props.data.company.email ? "Email: "+props.data.company.email : ""}
                        </Typography>
                    </Grid>
                </Grid>
                <Grid style={{ marginTop: 10, width: '100%', display: 'flex', alignItems: 'center' }}>
                    <div style={{ width: '50%' }} />
                    <Button
                        style={{ width: '50%', float: 'right', marginTop: 20, marginBottom: 20 }}
                        variant="contained"
                        size="large"
                        color="primary"
                        onClick={printPdf}
                        className={classes.buttonPrintpdf}
                    >
                        Print PDF
                    </Button>
                </Grid>
                <ModalSignWorkOrder 
                    onCloseModal={() => setOpenModalSignWorkOrder(false)}
                    onSelectedSign={(place, type, selectedSign, name, date) => handleApplySign(place, type, selectedSign, name, date)}
                    open={openModalSignWorkOrder}
                    place={place}
                    isLoading={isLoading}
                    dataImageBase64UpdateOne={props.data && props.data.signatures && props.data.signatures.length > 0  &&  props.data.signatures.filter(function(item) { return item.signaturePosition == 1 } ) && props.data.signatures.filter(function(item) { return item.signaturePosition == 1 } ).length > 0 ? props.data.signatures.filter(function(item) { return item.signaturePosition == 1 } )[0].signatureUrl : null}
                    dataImageBase64UpdateTwo={props.data && props.data.signatures && props.data.signatures.length > 0  &&  props.data.signatures.filter(function(item) { return item.signaturePosition == 2 } ) && props.data.signatures.filter(function(item) { return item.signaturePosition == 2 } ).length > 0 ? props.data.signatures.filter(function(item) { return item.signaturePosition == 2 } )[0].signatureUrl : null}
                    dataImageBase64UpdateThree={props.data && props.data.signatures && props.data.signatures.length > 0  &&  props.data.signatures.filter(function(item) { return item.signaturePosition == 3 } ) && props.data.signatures.filter(function(item) { return item.signaturePosition == 3 } ).length > 0 ? props.data.signatures.filter(function(item) { return item.signaturePosition == 3 } )[0].signatureUrl : null}
                    dataImageBase64UpdateFour={props.data && props.data.signatures && props.data.signatures.length > 0  &&  props.data.signatures.filter(function(item) { return item.signaturePosition == 4 } ) && props.data.signatures.filter(function(item) { return item.signaturePosition == 4 } ).length > 0 ? props.data.signatures.filter(function(item) { return item.signaturePosition == 4 } )[0].signatureUrl : null}
                    dataImageBase64UpdateFive={props.data && props.data.signatures && props.data.signatures.length > 0  &&  props.data.signatures.filter(function(item) { return item.signaturePosition == 5 } ) && props.data.signatures.filter(function(item) { return item.signaturePosition == 5 } ).length > 0 ? props.data.signatures.filter(function(item) { return item.signaturePosition == 5 } )[0].signatureUrl : null}
                />
                <div className={classes.snackBar}>
                    <Snackbar anchorOrigin={{
                        vertical: 'top',
                        horizontal: 'center'
                    }} open={openSnackbar}
                        autoHideDuration={3000}
                        onClose={closeSnackbar}
                    >
                        {isStatus.failed
                            ?
                            <Alert
                                elevation={6} variant="filled" severity="error">
                                <Typography
                                    color="inherit"
                                    variant="h6">
                                    {isStatus.msg}
                                </Typography>
                            </Alert>
                            :
                            <Alert
                                elevation={6} variant="filled" severity="success">
                                <Typography
                                    color="inherit"
                                    variant="h6">
                                    {isStatus.msg}
                                </Typography>
                            </Alert>
                        }
                    </Snackbar>
                </div>
            </div>
        </>
    )
}

WorkorderPdf.propTypes = {
    
}

export default WorkorderPdf;
