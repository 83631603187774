import React, { useEffect, useState, useRef } from 'react'
import {
    Card, CardHeader, CardContent, CardActions, Divider,
    Button, TextField, Grid, colors, CircularProgress, Typography,
    Collapse , Chip
} from '@material-ui/core'
import {Autocomplete} from '@material-ui/lab'
import { KeyboardArrowDown } from '@material-ui/icons'
import { PromoTable } from './components'
import { makeStyles } from '@material-ui/styles'
import * as Yup from "yup";
import { Formik } from "formik";
import axios from "utils/axios";
import apiConfig from "apiConfig";
import { FormErrorCallback } from "components";
import Snackbar from "@material-ui/core/Snackbar";
import Alert from "@material-ui/lab/Alert";
import { errorMessages } from 'common/constants'
import { checkFeature, FeatureFlags } from 'FeatureService'
import OptionalEmailsTable from './components/OptionalEmailsTable'
import CancelRoundedIcon from "@material-ui/icons/CancelRounded";

const useStyles = makeStyles(theme => ({
    cardHeader: {
        cursor: 'pointer'
    },
    btn: {
        color: theme.palette.white,
        backgroundColor: colors.green[600],
        '&:hover': {
            backgroundColor: colors.green[900]
        }
    },
}));

const smsSchema = Yup.object().shape({
    textStatusOnTheWay: Yup.string()
        .nullable().required('This field is required.'),
    textStatusJobStarted: Yup.string()
        .nullable().required('This field is required.'),
    textStatusJobComplete: Yup.string()
        .nullable().required('This field is required.')
})
const emailSchema = Yup.object().shape({
    quoteEmailSubject: Yup.string()
        .nullable().required('This field is required.'),
    quoteEmailBody: Yup.string()
        .nullable().required('This field is required.')
})

const Communications = ({ adminId }) => {
    const classes = useStyles();
    const mounted = useRef();
    const [openSnackbar, setOpenSnackBar] = useState(false);
    const [saveEmail, setSaveEmail] = useState(false);
    const [sms, setSms] = useState();
    const [email, setEmail] = useState();
    const [isSaving, setIsSaving] = useState(false);
    const [isStatus, setIsStatus] = useState({ failed: false, msg: '' });
    const [reload, setReload] = useState(false);
    const [expandedFieldTech, setExpandedFieldTech] = useState(true);
    const [expandedEmailSetting, setExpandedEmailSetting] = useState(true);
    const [expandedPromo, setExpandedPromo] = useState(true);
    const [enableWorkOrder, setEnableWorkOrder] = useState(true)
    const [companyEmail, setCompanyEmail] = useState("");
    const [ccEmailInvoice, setCcEmailInvoice] = useState("");
    const [bccEmailInvoice, setBccEmailInvoice] = useState("");
    const [optionalEmails, setOptionalEmails] = useState([]);
    const [load, setLoad] = useState(false);
    const [ccEmailQuote, setCcEmailQuote] = useState("");
    const [bccEmailQuote, setBccEmailQuote] = useState("");
    const closeSnackbar = () => {
        setOpenSnackBar(false);
    };

    const reloadData = () => {
        setLoad(!load);
    };

    const onChangeEmailInvoiceCC = (value) => {
        var hasValid = value.find(x => {
            return !(/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(x));
        })
        if (!hasValid) {
            setCcEmailInvoice(value.join(';'))
        }
    }

    const onChangeEmailInvoiceBCC = (value) => {
        var hasValid = value.find(x => {
            return !(/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(x));
        })
        if (!hasValid) {
            setBccEmailInvoice(value.join(';'))
        }
    }

    const onChangeEmailQuoteCC = (value) => {
        var hasValid = value.find(x => {
            return !(/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(x));
        })
        if (!hasValid) {
            setCcEmailQuote(value.join(';'))
        }
    }

    const onChangeEmailQuoteBCC = (value) => {
        var hasValid = value.find(x => {
            return !(/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(x));
        })
        if (!hasValid) {
            setBccEmailQuote(value.join(';'))
        }
    }

    useEffect(() => {
        mounted.current = true;
        axios.get(apiConfig.url.BASE_URL + apiConfig.url.COMPANY_SMS.replace('{id}', adminId))
            .then(res => {
                mounted.current && setSms(res.data)
            })

        axios.get(apiConfig.url.BASE_URL + apiConfig.url.COMPANY_QUOTE.replace('{id}', adminId))
            .then(res => {
                if(mounted.current && res.data){
                    setCompanyEmail(res.data.email || '');
                    setEmail(res.data);
                    setCcEmailInvoice(res.data.invoiceEmailDefaultCC || '');
                    setBccEmailInvoice(res.data.invoiceEmailDefaultBCC || '');
                    setCcEmailQuote(res.data.quoteEmailDefaultCC || '');
                    setBccEmailQuote(res.data.quoteEmailDefaultBCC || '');
                }
            })
        
        return () => mounted.current = false;
    }, [reload])

    useEffect(() => {
        mounted.current = true;
        axios.get(apiConfig.url.BASE_URL + apiConfig.url.COMPANY_EMAIL_LIST)
            .then((res) => {
                mounted.current && res.data && setOptionalEmails([...new Set(res.data.map(e => e.email))])
            });
        return () => mounted.current = false;
    }, [load])
    useEffect(() => {
        mounted.current = true;
        (async () => {
            const isEnableWorkOrder = await checkFeature(FeatureFlags.WORK_ORDER);
            setEnableWorkOrder(isEnableWorkOrder)
            }
        )();
      return () => mounted.current = false;
    }, [])
    if (!sms || !email) {
        return (
            <Grid
                container
                spacing={0}
                align="center"
                justifyContent="center"
                direction="column">
                <Grid item>
                    <CircularProgress className={classes.circularProgress} size={50} />
                </Grid>
            </Grid>
        )
    }
    return (
        <>
            <Formik
                initialValues={sms}
                validationSchema={smsSchema}
                onSubmit={values => {
                    setIsSaving(true);
                    axios.put(apiConfig.url.BASE_URL + apiConfig.url.COMPANY_SMS.replace('{id}', adminId), values)
                        .then(res => {
                            setIsStatus({ failed: false, msg: '' });
                            setOpenSnackBar(true);
                            setReload(!reload)
                        })
                        .catch(err => {
                            setIsStatus({ failed: true, msg: 'An error occurred, please try again later' });
                            setOpenSnackBar(true);
                        })
                        .finally(e => setIsSaving(false));
                }}
            >
                {({
                    values,
                    errors,
                    touched,
                    submitCount,
                    isValid,
                    setFieldValue,
                    setFieldTouched,
                    submitForm
                }) => (
                    <form>
                        <FormErrorCallback
                            submitCount={submitCount}
                            isValid={isValid}
                            onSubmissionError={() => {
                                setIsStatus({ failed: true, msg: errorMessages.FIELD_CHECK });
                                setOpenSnackBar(true);
                            }}
                        />
                        <Card>
                            <CardHeader className={classes.cardHeader}
                                title='Field Technicians Customer SMS Settings'
                                action={<KeyboardArrowDown />}
                                onClick={() => setExpandedFieldTech(!expandedFieldTech)}
                            />
                            <Divider />
                            <Collapse in={expandedFieldTech} timeout='auto' unmountOnExit>
                                <CardContent>
                                    <Grid container spacing={3}>
                                        <Grid item xs={12}>
                                            <TextField
                                                fullWidth
                                                label={`"On The Way" status message verbiage`}
                                                name='textStatusOnTheWay'
                                                multiline
                                                rows={4}
                                                variant='outlined'
                                                error={errors.textStatusOnTheWay && touched.textStatusOnTheWay}
                                                helperText={(errors.textStatusOnTheWay && touched.textStatusOnTheWay) && errors.textStatusOnTheWay}
                                                onBlur={() => setFieldTouched('textStatusOnTheWay')}
                                                onChange={event => setFieldValue('textStatusOnTheWay', event.target.value)}
                                                value={values.textStatusOnTheWay || ''}
                                            />
                                        </Grid>
                                        <Grid item xs={12}>
                                            <TextField
                                                fullWidth
                                                label={`"Job started" status message verbiage`}
                                                name='textStatusJobStarted'
                                                multiline
                                                rows={4}
                                                variant='outlined'
                                                error={errors.textStatusJobStarted && touched.textStatusJobStarted}
                                                helperText={(errors.textStatusJobStarted && touched.textStatusOnTheWay) && errors.textStatusJobStarted}
                                                onBlur={() => setFieldTouched('textStatusJobStarted')}
                                                onChange={event => setFieldValue('textStatusJobStarted', event.target.value)}
                                                value={values.textStatusJobStarted || ''}
                                            />
                                        </Grid>
                                        <Grid item xs={12}>
                                            <TextField
                                                fullWidth
                                                label={`"Job Complete" status message verbiage`}
                                                name='textStatusJobComplete'
                                                multiline
                                                rows={4}
                                                variant='outlined'
                                                error={errors.textStatusJobComplete && touched.textStatusJobComplete}
                                                helperText={(errors.textStatusJobComplete && touched.textStatusJobComplete) && errors.textStatusJobComplete}
                                                onBlur={() => setFieldTouched('textStatusJobComplete')}
                                                onChange={event => setFieldValue('textStatusJobComplete', event.target.value)}
                                                value={values.textStatusJobComplete || ''}
                                            />
                                        </Grid>
                                    </Grid>
                                </CardContent>
                                <Divider />
                                <CardActions style={{ float: 'right', paddingRight: '16px' }}>
                                    <Button
                                        className={classes.btn}
                                        disabled={isSaving}
                                        onClick={() => {
                                            submitForm()
                                        }}
                                    >
                                        Save
                                    </Button>
                                    {isSaving && <CircularProgress size={24} />}
                                </CardActions>
                            </Collapse>
                        </Card>

                    </form>
                )}
            </Formik>

            <Formik
                initialValues={email}
                validationSchema={emailSchema}
                onSubmit={values => {
                    setSaveEmail(true);
                    values.invoiceEmailDefaultCC = ccEmailInvoice;
                    values.invoiceEmailDefaultBCC = bccEmailInvoice;
                    values.quoteEmailDefaultCC = ccEmailQuote;
                    values.quoteEmailDefaultBCC = bccEmailQuote;
                    axios.put(apiConfig.url.BASE_URL + apiConfig.url.COMPANY_QUOTE.replace('{id}', adminId), values)
                        .then(res => {
                            setIsStatus({ failed: false, msg: '' });
                            setOpenSnackBar(true);
                            setReload(!reload)
                        })
                        .catch(err => {
                            setIsStatus({ failed: true, msg: 'An error occurred, please try again later' });
                            setOpenSnackBar(true);
                        })
                        .finally(e => setSaveEmail(false));
                }}
            >
                {({
                    values,
                    errors,
                    touched,
                    submitCount,
                    isValid,
                    setFieldValue,
                    setFieldTouched,
                    submitForm
                }) => (
                    <form>
                        <FormErrorCallback
                            submitCount={submitCount}
                            isValid={isValid}
                            onSubmissionError={() => {
                                setIsStatus({ failed: true, msg: errorMessages.FIELD_CHECK });
                                setOpenSnackBar(true);
                            }}
                        />
                        <Card style={{ marginTop: '24px' }}>
                            <CardHeader className={classes.cardHeader}
                                title='Email Settings'
                                action={<KeyboardArrowDown />}
                                onClick={() => setExpandedEmailSetting(!expandedEmailSetting)}
                            />
                            <Divider />
                            <Collapse in={expandedEmailSetting} timeout='auto' unmountOnExit>
                                <CardContent>
                                    <Grid container spacing={3}>
                                        <Grid item xs={12}>
                                            <Typography variant="h5">Additional Sender Emails</Typography>
                                            <OptionalEmailsTable adminId={adminId} reloadData={reloadData}/>
                                        </Grid>
                                        <br/><br/>
                                        <Grid item xs={12}>
                                            <TextField

                                                fullWidth
                                                label='Invoices will be sent from'
                                                name='invoiceEmailDefaultFrom'
                                                variant='outlined'
                                                // error={errors.invoiceEmailDefaultFrom && touched.invoiceEmailDefaultFrom}
                                                // helperText={(errors.invoiceEmailDefaultFrom && touched.invoiceEmailDefaultFrom) && errors.invoiceEmailDefaultFrom}
                                                // onBlur={() => setFieldTouched('invoiceEmailDefaultFrom')}
                                                onChange={event => setFieldValue('invoiceEmailDefaultFrom', event.target.value)}
                                                value={values.invoiceEmailDefaultFrom || ''}
                                                select
                                                SelectProps={{ native: true }}>
                                                <option></option>
                                                { companyEmail.length > 0 && <option value={companyEmail}>{companyEmail}</option>}
                                                {optionalEmails.filter(e => e !== companyEmail).map(email => (
                                                    <option value={email} key={email}>{email}</option>
                                                ))}
                                            
                                            </TextField>
                                        </Grid>
                                        <Grid item xs={12}>
                                            <Autocomplete
                                                multiple
                                                id="tags-filled"
                                                options={[]}
                                                defaultValue={ ccEmailInvoice.length > 0 ? ccEmailInvoice.split(";") : []}
                                                value={ccEmailInvoice.length > 0 && ccEmailInvoice.length > 0 ? ccEmailInvoice.split(";") : []}
                                                freeSolo
                                                onChange={(e, newValue) => {
                                                    onChangeEmailInvoiceCC(newValue)
                                                }}
                                                onBlur={(e,v) => {
                                                    let newValue = ccEmailInvoice.length > 0 ? ccEmailInvoice.split(";") : []
                                                    if(e.target.value.trim()){
                                                        newValue.push(e.target.value)
                                                        onChangeEmailInvoiceCC(newValue)
                                                    }
                                                }}
                                                selectOnFocus={true}
                                                renderTags={(value, getTagProps) =>
                                                    value.map((option, index) => (
                                                        <Chip deleteIcon={<CancelRoundedIcon style={{ color: '#546e7a' }} />} variant="outlined" label={option} {...getTagProps({ index })} />
                                                    ))
                                                }
                                                renderInput={(params) => (
                                                    <TextField {...params} variant="outlined" label="Invoices will cc (allow multiple)" placeholder={!ccEmailInvoice.length > 0 ? ccEmailInvoice.split(";") : [] ? "Invoices will cc (allow multiple)" : ''} />
                                                )}
                                            />
                                        </Grid>
                                        <Grid item xs={12}>
                                            <Autocomplete
                                                multiple
                                                id="tags-filled"
                                                options={[]}
                                                defaultValue={bccEmailInvoice.length > 0 ? bccEmailInvoice.split(";") : []}
                                                value={bccEmailInvoice.length > 0 ? bccEmailInvoice.split(";") : []}
                                                freeSolo
                                                onChange={(e, newValue) => {
                                                    onChangeEmailInvoiceBCC(newValue)
                                                }}
                                                onBlur={(e,v) => {
                                                    let newValue = bccEmailInvoice.length > 0 ? bccEmailInvoice.split(";") : []
                                                    if(e.target.value.trim()){
                                                        newValue.push(e.target.value)
                                                        onChangeEmailInvoiceBCC(newValue)
                                                    }
                                                }}
                                                selectOnFocus={true}
                                                renderTags={(value, getTagProps) =>
                                                    value.map((option, index) => (
                                                        <Chip deleteIcon={<CancelRoundedIcon style={{ color: '#546e7a' }} />} variant="outlined" label={option} {...getTagProps({ index })} />
                                                    ))
                                                }
                                                renderInput={(params) => (
                                                    <TextField {...params} variant="outlined" label="Invoices will BCC (allow multiple)" placeholder={!bccEmailInvoice.length > 0 ? bccEmailInvoice.split(";") : [] ? "Invoices will BCC (allow multiple)" : ''} />
                                                )}
                                            />
                                        </Grid>

                                        <Grid item xs={12}>
                                            <TextField
                                                fullWidth
                                                label='Quotes will be sent from'
                                                name='quoteEmailDefaultFrom'
                                                variant='outlined'
                                                onChange={event => setFieldValue('quoteEmailDefaultFrom', event.target.value)}
                                                value={values.quoteEmailDefaultFrom || ''}
                                                select
                                                SelectProps={{ native: true }}>
                                                <option></option>
                                                {companyEmail.length > 0 && <option value={companyEmail}>{companyEmail}</option>}
                                                {optionalEmails.filter(e => e !== companyEmail).map(email => (
                                                    <option value={email} key={email}>{email}</option>
                                                ))}
                                            </TextField>
                                        </Grid>
                                        <Grid item xs={12}>
                                            <Autocomplete
                                                multiple
                                                id="tags-filled"
                                                options={[]}
                                                defaultValue={ ccEmailQuote.length > 0 ? ccEmailQuote.split(";") : []}
                                                value={ccEmailQuote.length > 0 && ccEmailQuote.length > 0 ? ccEmailQuote.split(";") : []}
                                                freeSolo
                                                onChange={(e, newValue) => {
                                                    onChangeEmailQuoteCC(newValue)
                                                }}
                                                onBlur={(e,v) => {
                                                    let newValue = ccEmailQuote.length > 0 ? ccEmailQuote.split(";") : []
                                                    if(e.target.value.trim()){
                                                        newValue.push(e.target.value)
                                                        onChangeEmailQuoteCC(newValue)
                                                    }
                                                }}
                                                selectOnFocus={true}
                                                renderTags={(value, getTagProps) =>
                                                    value.map((option, index) => (
                                                        <Chip deleteIcon={<CancelRoundedIcon style={{ color: '#546e7a' }} />} variant="outlined" label={option} {...getTagProps({ index })} />
                                                    ))
                                                }
                                                renderInput={(params) => (
                                                    <TextField {...params} variant="outlined" label="Quotes will cc (allow multiple)" placeholder={!ccEmailQuote.length > 0 ? ccEmailQuote.split(";") : [] ? "Quotes will cc (allow multiple)" : ''} />
                                                )}
                                            />
                                        </Grid>
                                        <Grid item xs={12}>
                                            <Autocomplete
                                                multiple
                                                id="tags-filled"
                                                options={[]}
                                                defaultValue={bccEmailQuote.length > 0 ? bccEmailQuote.split(";") : []}
                                                value={bccEmailQuote.length > 0 ? bccEmailQuote.split(";") : []}
                                                freeSolo
                                                onChange={(e, newValue) => {
                                                    onChangeEmailQuoteBCC(newValue)
                                                }}
                                                onBlur={(e,v) => {
                                                    let newValue = bccEmailQuote.length > 0 ? bccEmailQuote.split(";") : []
                                                    if(e.target.value.trim()){
                                                        newValue.push(e.target.value)
                                                        onChangeEmailQuoteBCC(newValue)
                                                    }
                                                }}
                                                selectOnFocus={true}
                                                renderTags={(value, getTagProps) =>
                                                    value.map((option, index) => (
                                                        <Chip deleteIcon={<CancelRoundedIcon style={{ color: '#546e7a' }} />} variant="outlined" label={option} {...getTagProps({ index })} />
                                                    ))
                                                }
                                                renderInput={(params) => (
                                                    <TextField {...params} variant="outlined" label="Quote will BCC (allow Multiple)" placeholder={!bccEmailQuote.length > 0 ? bccEmailQuote.split(";") : [] ? "Quote will BCC (allow Multiple)" : ''} />
                                                )}
                                            />
                                        </Grid>
                                        
                                        <Grid item xs={12}>
                                            <TextField
                                                fullWidth
                                                label='Invoice Email Default Subject'
                                                name='invoiceEmailSubject'
                                                variant='outlined'
                                                error={errors.invoiceEmailSubject && touched.invoiceEmailSubject}
                                                helperText={(errors.invoiceEmailSubject && touched.invoiceEmailSubject) && errors.invoiceEmailSubject}
                                                onBlur={() => setFieldTouched('invoiceEmailSubject')}
                                                onChange={event => setFieldValue('invoiceEmailSubject', event.target.value)}
                                                value={values.invoiceEmailSubject || ''}
                                            />
                                        </Grid>
                                        <Grid item xs={12}>
                                            <TextField
                                                fullWidth
                                                multiline
                                                rows={3}
                                                maxRows={10}
                                                label='Invoice Email Default Body'
                                                name='invoiceEmailBody'
                                                variant='outlined'
                                                error={errors.invoiceEmailBody && touched.invoiceEmailBody}
                                                helperText={(errors.invoiceEmailBody && touched.invoiceEmailBody) && errors.invoiceEmailBody}
                                                onBlur={() => setFieldTouched('invoiceEmailBody')}
                                                onChange={event => setFieldValue('invoiceEmailBody', event.target.value)}
                                                value={values.invoiceEmailBody || ''}
                                            />
                                        </Grid>
                                        <Grid item xs={12}>
                                            <TextField
                                                fullWidth
                                                label='Quote Email Default Subject'
                                                name='quoteEmailDefaultSubject'
                                                variant='outlined'
                                                error={errors.quoteEmailSubject && touched.quoteEmailSubject}
                                                helperText={(errors.quoteEmailSubject && touched.quoteEmailSubject) && errors.quoteEmailSubject}
                                                onBlur={() => setFieldTouched('quoteEmailSubject')}
                                                onChange={event => setFieldValue('quoteEmailSubject', event.target.value)}
                                                value={values.quoteEmailSubject || ''}
                                            />
                                        </Grid>
                                        <Grid item xs={12}>
                                            <TextField
                                                fullWidth
                                                multiline
                                                rows={3}
                                                maxRows={10}
                                                label='Quote Email Default Body'
                                                name='quoteEmailDefaultBody'
                                                variant='outlined'
                                                error={errors.quoteEmailBody && touched.quoteEmailBody}
                                                helperText={(errors.quoteEmailBody && touched.quoteEmailBody) && errors.quoteEmailBody}
                                                onBlur={() => setFieldTouched('quoteEmailBody')}
                                                onChange={event => setFieldValue('quoteEmailBody', event.target.value)}
                                                value={values.quoteEmailBody || ''}
                                            />
                                        </Grid>
                                        {enableWorkOrder && 
                                        <>
                                            <Grid item xs={12}>
                                                <TextField
                                                    fullWidth
                                                    multiline
                                                    rows={3}
                                                    maxRows={10}
                                                    label='Work Order Email Default Subject'
                                                    name='workOrderEmailDefaultSubject'
                                                    variant='outlined'
                                                    error={errors.workOrderEmailSubject && touched.workOrderEmailSubject}
                                                    helperText={(errors.workOrderEmailSubject && touched.workOrderEmailSubject) && errors.workOrderEmailSubject}
                                                    onBlur={() => setFieldTouched('workOrderEmailSubject')}
                                                    onChange={event => setFieldValue('workOrderEmailSubject', event.target.value)}
                                                    value={values.workOrderEmailSubject || ''} />
                                            </Grid>
                                            <Grid item xs={12}>
                                                <TextField
                                                    fullWidth
                                                    multiline
                                                    rows={3}
                                                    maxRows={10}
                                                    label='Work Order Email Default Body'
                                                    name='workOrderEmailDefaultBody'
                                                    variant='outlined'
                                                    error={errors.workOrderEmailBody && touched.workOrderEmailBody}
                                                    helperText={(errors.workOrderEmailBody && touched.workOrderEmailBody) && errors.workOrderEmailBody}
                                                    onBlur={() => setFieldTouched('workOrderEmailBody')}
                                                    onChange={event => setFieldValue('workOrderEmailBody', event.target.value)}
                                                    value={values.workOrderEmailBody || ''} />
                                            </Grid></>
                                        }
                                    </Grid>
                                </CardContent>
                                <Divider />
                                <CardActions style={{ float: 'right', paddingRight: '16px' }}>
                                    <Button
                                        className={classes.btn}
                                        disabled={saveEmail}
                                        onClick={() => {
                                            submitForm()
                                        }}
                                    >
                                        Save
                                    </Button>
                                    {saveEmail && <CircularProgress size={24} />}
                                </CardActions>
                            </Collapse>
                        </Card>

                    </form>
                )}
            </Formik>

            <Card style={{ marginTop: '24px' }}>
                <CardHeader className={classes.cardHeader}
                    title='Promo Attachments'
                    action={<KeyboardArrowDown />}
                    onClick={() => setExpandedPromo(!expandedPromo)}
                />
                <Divider />
                <Collapse in={expandedPromo} timeout='auto' unmountOnExit>
                    <CardContent>
                        <PromoTable adminId={adminId} />
                    </CardContent>
                </Collapse>
            </Card>

            <Snackbar anchorOrigin={{
                vertical: 'top',
                horizontal: 'center'
            }} open={openSnackbar}
                autoHideDuration={3000}
                onClose={closeSnackbar}
            >
                {isStatus.failed
                    ?
                    <Alert
                        elevation={6} variant="filled" severity="error">
                        <Typography
                            color="inherit"
                            variant="h6">
                            {isStatus.msg}
                        </Typography>
                    </Alert>
                    :
                    <Alert
                        elevation={6} variant="filled" severity="success">
                        <Typography
                            color="inherit"
                            variant="h6">
                        </Typography>
                    </Alert>
                }
            </Snackbar>

            <div style={{ height: 1, marginTop: 20 }}></div>
        </>
    )
}

export default Communications;
