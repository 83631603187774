import React, { useState, useEffect } from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/styles';
import { AvailableDayOff, AddDayOff } from './components';
import Fab from '@material-ui/core/Fab';
import AddIcon from '@material-ui/icons/Add';
import { Alert } from '@material-ui/lab';
import { ConfirmModal } from 'components'
import {
    Card,
    CardContent,
    CardHeader,
    Divider,
    Table,
    TableHead,
    TableBody,
    TableRow,
    TableCell,
    Typography,
    Grid,
    CircularProgress,
    Snackbar,IconButton,colors
} from '@material-ui/core';
import { useSelector, useDispatch } from 'react-redux'
import axios from 'utils/axios';
import apiConfig from 'apiConfig';
import localStorage from 'utils/localStorage'
import { PERMISSIONS } from "common/permissions";
import { AddBox } from '@material-ui/icons';

const useStyles = makeStyles(theme => ({
    card: {
        marginTop: '20px',
        marginBottom: '100px'
    },
    cardReadOnly: {
        pointerEvents: 'none'
    },
    cusFontsize: {
        '& th': {
            fontSize: '15px'
        }
    },
    fab: {
        position: 'fixed',
        bottom: theme.spacing(4),
        right: theme.spacing(4),
    },
    type: {
        backgroundColor: theme.palette.white
    },
    responsiveTable: {
        width: '100%',
        overflowY: 'auto'
    },
    customTableCell: {
        borderBottom: '1px solid #969696'
    },
    notifSearch: {
        fontSize: theme.spacing(2)
    },
    circularProgress: {
        marginTop: theme.spacing(3)
    },
    addStyle: {
        '& span svg': {
            color: colors.green[600],
            '&:hover': {
                color: colors.green[900]
            }
        }
    }
}));

const TimeOff = props => {

    const { userId, className, ...rest } = props;

    const readOnly = localStorage.getUserRole() === PERMISSIONS.FIELD_TECHNICIAN;

    const dispatch = useDispatch();
    const { givedayoffs } = useSelector(state => state.DayoffReducer);

    const classes = useStyles();

    const [openDayoff, setOpenDayoff] = useState(false);
    const [dayOffEdit, setDayOffEdit] = useState();
    const [loading, setLoading] = useState();
    const [status, setStatus] = useState({ failed: false, msg: '' })
    const [openSnack, setOpenSnack] = useState(false);
    const [openConfirm, setOpenConfirm] = useState(false);
    const [isProgress, setIsProgress] = useState(false);
    const [removeDayoff, setRemoveDayoff] = useState();

    // Day off
    const onNewDayOff = () => {
        setDayOffEdit(null);
        setOpenDayoff(true)
    }
    const editDayOff = (dayoff, index) => {
        setDayOffEdit({ dayoff, index });
        setOpenDayoff(true)
    }

    const setRemove = (id, index) => {
        setRemoveDayoff({ id, index });
        setOpenConfirm(true);
    }
    const onRemove = () => {
        setIsProgress(true);
        axios.delete(apiConfig.url.USER_URL + apiConfig.url.EMPLOYEE_DAYOFF_DELETE + removeDayoff.id)
            .then(() => {
                dispatch({ type: 'DEL_DAYOFF', index: removeDayoff.index });
                setStatus({ failed: false, msg: 'Record Removed' });
                setOpenSnack(true);
            })
            .catch(() => {
                setStatus({ failed: true, msg: 'Remove failed, please try again later.' });
                setOpenSnack(true);
            })
            .finally(() => {
                setIsProgress(false);
                setOpenConfirm(false)
            })
    }

    useEffect(() => {
        const fetchDayoffs = () => {
            setLoading(true)
            axios.get(apiConfig.url.USER_URL + apiConfig.url.EMPLOYEE_DAYOFF_GET + userId)
                .then(res => dispatch({ type: 'INIT_DAYOFF', dayoffs: res.data.daysOff }))
                .finally(e => setLoading(false))
        }
        fetchDayoffs();
    }, [userId])

    if (loading) {
        return (
            <Grid
                container
                spacing={0}
                align="center"
                justifyContent="center"
                direction="column">
                <Grid item>
                    <CircularProgress className={classes.circularProgress} size={50} />
                </Grid>
            </Grid>
        )
    }

    return (
        <div
            {...rest}
            className={clsx(classes.root, className, readOnly ? classes.cardReadOnly : null)}>

            <Card className={classes.card}>
                <CardHeader title="Time Off" />
                <Divider />
                <CardContent className={classes.responsiveTable}>
                    <Table>
                        <TableHead>
                            <TableRow className={classes.cusFontsize}>
                                <TableCell style={{ width: '25%' }}  className={classes.customTableCell}>Date</TableCell>
                                <TableCell style={{ width: '25%' }} className={classes.customTableCell} align="left">Hour</TableCell>
                                <TableCell style={{ width: '25%' }} className={classes.customTableCell} align="left">Type</TableCell>
                                <TableCell style={{ width: '25%' }} className={classes.customTableCell} align="left">Note</TableCell>
                                <TableCell style={{ width: '1%', textAlign: 'center' }} className={classes.customTableCell}>
                                   <IconButton onClick={onNewDayOff} className={classes.addStyle}>
                                        <AddBox fontSize='large' />
                                    </IconButton>
                                </TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {givedayoffs.length !== 0 ?
                                givedayoffs.map((dayoff, index) =>
                                    <AvailableDayOff
                                        key={index}
                                        dayoff={dayoff}
                                        update={() => editDayOff(dayoff, index)}
                                        remove={() => setRemove(dayoff.id, index)}
                                    />
                                ) : (
                                    <TableRow>
                                        <TableCell colSpan={8}>
                                            <Typography className={classes.notifSearch}>No data found.</Typography>
                                        </TableCell>
                                    </TableRow>
                                )
                            }
                        </TableBody>
                    </Table>
                </CardContent>
                <Divider />
            </Card>


            <AddDayOff
                userId={userId}
                open={openDayoff}
                onCloseModal={() => setOpenDayoff(false)}
                dayOff={dayOffEdit}
            />

            <ConfirmModal
                openConfirm={openConfirm}
                closeConfirm={() => setOpenConfirm(false)}
                onConfirm={onRemove}
                isProgress={isProgress}
            />

            <Snackbar
                open={openSnack}
                onClose={() => setOpenSnack(false)}
                anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
                autoHideDuration={3000}>
                <Alert elevation={6} variant='filled' severity={status.failed ? 'error' : 'success'}>
                    <Typography variant='h6' color='inherit'>
                        {status.msg}
                    </Typography>
                </Alert>
            </Snackbar>

        </div>
    );
};

TimeOff.propTypes = {
    className: PropTypes.string
};

export default TimeOff;
