import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Avatar } from '@material-ui/core';
import secureImg from 'utils/secureImg';

const SecureAvatar = props => {
  const { src, isReloadAvatar, onHasAvatar, className, ...rest } = props;

  const [avatarUrl, setAvatarUrl] = useState(null);

  useEffect(() => {

    const getAvatar = (src) => {
      if (src) {
        secureImg.get(src)
          .then(response => {
            setAvatarUrl(response);
            onHasAvatar(true)
          })
          .catch(() => {
            setAvatarUrl(null);
            onHasAvatar(false)
          })
      }
    }

    getAvatar(src);
  }, [isReloadAvatar, onHasAvatar]);


  return (
    <div>
      <Avatar
        {...rest}
        className={className}
        src={avatarUrl} />
    </div>
  );
};

SecureAvatar.propTypes = {
  src: PropTypes.string.isRequired
}
SecureAvatar.defaultProps = {
  src: ''
}

export default SecureAvatar;
