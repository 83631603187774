import { Card, makeStyles, Modal, Typography } from '@material-ui/core'
import React, { useEffect, useState } from 'react'
import Snackbar from '@material-ui/core/Snackbar'
import Alert from '@material-ui/lab/Alert'

const useStyles = makeStyles((theme) => ({
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  paper: {
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
  },
}));
const ATSSnackBar = ({ isOpen, type, setATSModalState, atsModalState }) => {
  const [text, setText] = useState(null)
  const classes = useStyles()

  const getText = () => {
    const types = {
      'Error': () => (
        <>
          There was a problem making your request.
        </>
      ),
      'Reminder': () => (
        <>
          Reminder sent successfully.
        </>

      ),
      'Review': () => (
        <>
          Review sent successfully.
        </>
      ),
      'default': () => null
    }

    return type ? types[type]() : types['default']()
  }

  useEffect(() => {
    isOpen && setText(getText(type))
    return () => {
      setText(null)
    }
  }, [type])

  return (

    <Snackbar anchorOrigin={{ vertical: 'top', horizontal: 'center' }} open={isOpen}
      autoHideDuration={3000}
      onClose={() => ({...atsModalState, isOpen: false})}>
      <Alert
        elevation={6} variant="filled" severity={type === 'Error' ? 'error' : 'success'}>
        <Typography
          color="inherit"
          variant="h6">
          {text}
        </Typography>
      </Alert>
    </Snackbar>
  )
}

export default ATSSnackBar