import React, { useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/styles';
import DeleteIcon from '@material-ui/icons/Delete';
import GetAppIcon from '@material-ui/icons/GetApp';
import useMediaQuery from '@material-ui/core/useMediaQuery'
import Fab from '@material-ui/core/Fab';
import AddIcon from '@material-ui/icons/Add';
import { Alert } from '@material-ui/lab'
import {
    IconButton, Table, TableContainer, TableBody, TableCell, TableHead, TableRow,
    colors, Card, CardHeader, CardContent, Divider, FormControlLabel,
    Grid, Typography, Snackbar, CircularProgress, Tooltip, Collapse, TextField
} from '@material-ui/core';
import {GreenCheckbox, ConfirmModal, Pagination, AddressLink} from 'components'
import { AddAttachment } from './components'
import moment from 'moment'
import axios from 'utils/axios';
import axiosNoAuth from 'utils/axiosNoAuth'
import apiConfig from 'apiConfig'
import { useSelector, useDispatch } from 'react-redux';
import {ArrowDownward, ArrowUpward, KeyboardArrowDown} from '@material-ui/icons'
import AddBoxIcon from "@material-ui/icons/AddBox";
import AddCustomform from "./components/AddCustomform";
import clsx from 'clsx';
import { checkFeature, FeatureFlags } from "FeatureService";

const useStyles = makeStyles(theme => ({
    root: {},
    customTableCell: {
        borderBottom: '1px solid #969696',
        cursor: 'pointer',
        '& span': {
            display: 'flex',
            alignItems: 'center'
        },
        padding: '8px',
    },
    conUpFile: {
        marginTop: theme.spacing(2),
    },
    inputFile: {
        display: 'none'
    },
    btnUpFile: {
        flexShrink: '0',
        height: '50px',
        color: theme.palette.white,
        backgroundColor: colors.green[600],
        '&:hover': {
            backgroundColor: colors.green[900]
        },
        paddingLeft: theme.spacing(3),
        paddingRight: theme.spacing(3)
    },
    fab: {
        position: 'fixed',
        bottom: theme.spacing(4),
        right: theme.spacing(4),
        zIndex: 1
    },
    table: {
        minWidth: '1000px'
    },
    responsiveTable: {
        width: '100%',
        overflowX: 'auto'
    },
    cusFontsize: {
        '& th': {
            fontSize: '15px'
        }
    },
    titleNote: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        [theme.breakpoints.down('sm')]: {
            flexDirection: 'column',
            alignItems: 'flex-start'
        }
    },
    message: {
        fontSize: theme.spacing(2),
        fontWeight: 500
    },
    thumbnail: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center'
    },
    circularProgress: {
        marginTop: theme.spacing(3)
    },
    openImage: {
        color: '#263238',
        '&:hover': {
            opacity: 0.7
        }
    },
    expiredName: {
        color: '#e60216'
    },
    sticky: {
        marginBottom: theme.spacing(2),
        position: 'sticky',
        zIndex: 3
    },
    stickyHead: {
        position: 'relative',
        cursor: 'pointer'
    },
    address: {
        fontWeight: 500
    },
    paginate: {
        padding: '10px',
        '& ul': {
            justifyContent: 'center',
        }
    },
    iconSort: {
        marginBottom: '-5px',
        marginLeft: '10px'
    },
    addBox: {
		color: colors.green[600],
		"&:hover": {
			color: colors.green[900]
		},
		"& span svg": {
			fontSize: "32px"
		},
        padding: '0px'
	},
    mTop: {
        marginBottom: theme.spacing(2),
    },
    pCell: {
        padding: '0px 8px 0px 8px'
    },
    pointer: {
        cursor: 'pointer'
    },
    addressLink: {
        textDecoration: 'underline',
        color:'#0000EE'
    }
}));
let pageNumber = 1;
let forcePage = 0;
const Attachments = props => {

    const classes = useStyles();
    const mounted = useRef(false);
    const dispatch = useDispatch();

    const matches = useMediaQuery('(min-width:992px)');

    const { information, customerId, states, isCustomeService, zIndexCustom = 3, offsetTop } = props

    const {id} = information;

    const [isSuccess, setIsSuccess] = useState({ failed: false, msg: '' });
    const [openSnack, setOpenSnack] = useState(false);
    const [openEdit, setOpenEdit] = useState(false);
    const [openEditCustomForm, setOpenEditCustomForm] = useState(false);
    const [listFile, setListFile] = useState();
    const [locateFile, setLocateFile] = useState();
    const [openConfirm, setOpenConfirm] = useState(false);
    const [isProgress, setIsProgress] = useState(false);
    const [isDisable, setDisable] = useState(false);
    const [sortBy, setSortBy] = useState('FileName');
    const [sortType, setSortType] = useState('ASC');
    const [pageCount, setPageCount] = useState(0);
    const [expandForm, setExpandForm] = useState(true);
    const [listForm, setListForm] = useState();
    const [reload, setReload] = useState(false);
    const [customFormId, setCustomForm] = useState();
    const [openConfirmForm, setOpenConfirmForm] = useState(false);
    const [isEnableCustomForm, setIsEnableCustomForm] = useState(false);
    const [expand, setExpand] = useState(true);

    const onDeleteFile = () => {
        setIsProgress(true);
        axios.delete(apiConfig.url.CUSTOMER_URL + apiConfig.url.JOB_DELETE_DOCUMENT + locateFile.id)
            .then(() => {
                setIsSuccess({ failed: false, msg: 'Remove files success.' });
                setOpenSnack(true);

                const del_files = [...listFile];
                del_files.splice(locateFile.index, 1);
                setListFile(del_files);
                dispatch({ type: 'RELOAD_CUSTOMERS_COUNTER', reloadCustomer: true });
            })
            .catch(() => {
                setIsSuccess({ failed: true, msg: 'Remove file failed, please try again later.' });
                setOpenSnack(true);
            })
            .finally(() => {
                setIsProgress(false);
                setOpenConfirm(false);
            })
    }
    const onUpdateFiles = file => {
        setListFile([...listFile, file])
    }

    const onUpdateCustomForm = form => {
        setListForm([...listForm, form])
    }

    const isDisplayToFieldTech = id => {
        const file = listFile.find(f => f.id === id);
        setDisable(true);
        axios.patch(apiConfig.url.CUSTOMER_URL + apiConfig.url.JOB_UPDATE_DOCUMENT + id,
            {'isDisplayToFieldTech': !file.isDisplayToFieldTech, 'documentId': id  }
            )
            .then(res => {
                file.isDisplayToFieldTech = !file.isDisplayToFieldTech;
                setIsSuccess({ failed: false, msg: 'Update file success.' });
                setOpenSnack(true);
            })
            .catch(() => {
                setIsSuccess({ failed: true, msg: 'Update file failed, please try again later.' });
                setOpenSnack(true);
            })
            .finally(() => setDisable(false))
    }

    const onDownloadFile = (url, fileName) => {
        axiosNoAuth.get(url, {
            headers: {},
            responseType: 'blob',
        })
            .then((response) => {
                const url = window.URL.createObjectURL(new Blob([response.data]));
                const link = document.createElement('a');
                link.href = url;
                link.setAttribute('download', fileName);
                document.body.appendChild(link);
                link.click();
            })
            .catch(() => {
                setIsSuccess({ failed: true, msg: 'Download file failed.' });
                setOpenSnack(true);
            })

    }

    const loadDocument = (type) => {
        setSortBy(type)
        setSortType(type == sortBy ? (sortType === 'ASC' ? 'DESC' : 'ASC') : 'ASC');
        pageNumber = 1;
        forcePage = 0;
    }

    const onPageChange = (page) => {
        pageNumber = page.selected + 1;
        forcePage = page.selected;
        fetAttachment();
    };

    const getSortIcon = (type) => {
        return (
            sortBy == type &&
            (
                sortType == 'ASC' ?
                    <ArrowUpward className={classes.iconSort}/>
                    :
                    <ArrowDownward className={classes.iconSort}/>
            )
        )
    }

    const handleEditOpen = () => {
        setOpenEdit(true);
    }
    const handleEditClose = () => {
        setOpenEdit(false);
    }

    const handleEditOpenCustomForm = (id) => {
        if (id) setCustomForm(id);
        else setCustomForm(undefined);
        setOpenEditCustomForm(true);
    }

    const handleEditCloseCustomForm = () => {
        setOpenEditCustomForm(false);
        setReload(!reload);
    }

    const fetAttachment = () => {
        axios.get(apiConfig.url.CUSTOMER_URL + apiConfig.url.JOB_DOCUMENT_LIST + id, {
            params:
                {
                    sortBy: sortBy,
                    sortType: sortType,
                    limit: 0,
                    pageSize: 24,
                    pageNumber: pageNumber,
                }
        }).then(res => {
            mounted.current && setListFile(res.data.data);
            setPageCount(res.data.pageCount);
        })
    }

    const fetCustomForm = () => {
        axios.get(apiConfig.url.BASE_URL + apiConfig.url.CUSTOM_FORM_USER_ANSEWR_TEMPLATES.replace('jobId', id))
        .then(res => {
            setListForm(res.data);
        })
    }

    useEffect(() => {
        mounted.current = true;
        if (id) {
            fetAttachment();
        }
        return () => mounted.current = false;
    }, [id, sortBy, sortType])

    useEffect(() => {
        checkFeature(FeatureFlags.CUSTOM_FORM).then(res => {
            setIsEnableCustomForm(res);
            if (id && res) {
                fetCustomForm();
            }
        });
        
    }, [id,reload])

    const onDeleteCustomForm = () => {
        setIsProgress(true);
        axios.delete(apiConfig.url.BASE_URL + apiConfig.url.CUSTOM_FORM_DELETE_USER_ANSEWR,{
            params:
                {
                    jobId: id,
                    customFormId: customFormId
                }
            })
            .then(() => {
                setIsSuccess({ failed: false, msg: 'Remove Custom Forms success.' });
                setOpenSnack(true);
            })
            .catch(() => {
                setIsSuccess({ failed: true, msg: 'Remove Custom Forms failed, please try again later.' });
                setOpenSnack(true);
            })
            .finally(() => {
                setIsProgress(false);
                setReload(!reload);
                setOpenConfirmForm(false);
            })
    }

    const toggleExpanded = () => {
        dispatch({ type: 'CHANGE_EXPANDED' })
    }
    const { expanded } = useSelector(state => state.JobsReducer);

    if (!listFile) {
        return (
            <Grid
                container
                spacing={0}
                align="center"
                justifyContent="center"
                direction="column">
                <Grid item>
                    <CircularProgress className={classes.circularProgress} size={50} />
                </Grid>
            </Grid>
        )
    }

    return (
        <>
            {/* <Fab
                className={classes.fab}
                color="primary"
                onClick={handleEditOpen}>
                <AddIcon />
            </Fab> */}
            <AddAttachment
                onUpdateAttachments={onUpdateFiles}
                onClose={handleEditClose}
                jobId={id}
                customerId={customerId}
                open={openEdit} />
            <AddCustomform
                onUpdateAttachments={onUpdateCustomForm}
                onClose={handleEditCloseCustomForm}
                jobId={id}
                customFormId={customFormId}
                open={openEditCustomForm} />

            <Card style={{ marginTop: '-18px', zIndex: zIndexCustom, top: offsetTop }} className={classes.sticky}>
                <CardHeader title={isCustomeService ? <> <span>Job Details</span><span style={{ color: 'red' }}> - Customer Service</span> </> : 'Job Details'}
                    className={classes.stickyHead}
                    action={<KeyboardArrowDown />}
                    onClick={toggleExpanded} />
                <Divider />
                <Collapse in={expanded} timeout='auto' unmountOnExit>
                    <CardContent>
                        <Grid container spacing={3}>
                            <Grid item xs={12} sm={4}>
                                <Card style={{ height: '100%' }}>
                                    <CardHeader title='Job Address' />
                                    <Divider />
                                    <CardContent style={{ padding: '16px' }}>
                                        {
                                            information && information.jobAddress &&
                                            (
                                                <>
                                                    <Typography className={classes.address}>{information.jobAddress.name || ''}</Typography>
                                                    <AddressLink
                                                        address={{
                                                            ...information.jobAddress,
                                                            state: { name: states?.find(x => x.id === information.jobAddress.stateId)?.name }
                                                        }}
                                                        className={classes.address}
                                                    />
                                                </>
                                            )
                                        }
                                    </CardContent>
                                </Card>
                            </Grid>
                            <Grid item xs={12} sm={8}>
                                <Tooltip
                                    disableFocusListener
                                    arrow
                                    title={
                                        <Typography style={{ color: 'white' }}>
                                            Job Description is only editable in tabs with a save button.
                                        </Typography>
                                    }>
                                    <TextField
                                        fullWidth
                                        disabled
                                        multiline
                                        rows={7}
                                        label='Job Description'
                                        name='description'
                                        variant='outlined'
                                        value={information.description || ''}
                                    />
                                </Tooltip>
                            </Grid>
                        </Grid>
                    </CardContent>
                </Collapse>
            </Card>
            <Card className={classes.mTop}>
                <CardHeader title="Attachments" 
                action={<KeyboardArrowDown />}
                onClick={() => setExpand(!expand)}
                />
                <Divider />
                <Collapse in={expand} timeout='auto' unmountOnExit>
                    <CardContent>
                        <TableContainer className={classes.responsiveTable}>
                            <Table className={classes.table} aria-label="simple table">
                                <TableHead className={classes.customTableHead}>
                                    <TableRow className={classes.cusFontsize}>
                                        <TableCell onClick={() => loadDocument('FileName')} style={{ width: '15%' }} className={classes.customTableCell}><span>File Name{ getSortIcon('FileName')}</span></TableCell>
                                        <TableCell onClick={() => loadDocument('Note')} style={{ width: '20%' }} className={classes.customTableCell}><span>Note{ getSortIcon('Note')}</span></TableCell>
                                        <TableCell onClick={() => loadDocument('UploadedBy')} className={classes.customTableCell}><span>Uploaded By{ getSortIcon('UploadedBy')}</span></TableCell>
                                        <TableCell onClick={() => loadDocument('DateUploaded')} className={classes.customTableCell}><span>Date Uploaded{ getSortIcon('DateUploaded')}</span></TableCell>
                                        <TableCell onClick={() => loadDocument('ExpirationDate')} className={classes.customTableCell}><span>Expiration Date{ getSortIcon('ExpirationDate')}</span></TableCell>
                                        {matches ? <TableCell onClick={() => loadDocument('Size')} className={classes.customTableCell}><span>Size{ getSortIcon('Size')}</span></TableCell> : null}
                                        <TableCell onClick={() => loadDocument('DisplaytoFieldTechs')} align='center' className={classes.customTableCell}><span>Display to Field Techs{ getSortIcon('DisplaytoFieldTechs')}</span></TableCell>
                                        <TableCell className={classes.customTableCell} align='center' style={{ width: '1%' }}>
                                            <Tooltip arrow title={"Add File"}>
                                                <IconButton
                                                    className={classes.addBox}
                                                    onClick={handleEditOpen}
                                                >
                                                    <AddBoxIcon />
                                                </IconButton>
                                            </Tooltip>
                                        </TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {listFile && listFile.length !== 0 ? listFile.map((file, index) => (
                                        <TableRow key={file.id}>
                                            <TableCell className={classes.pCell}>
                                                <div className={classes.thumbnail}>
                                                    <a className={classes.openImage} href={file.documentUrl} target='_blank'>
                                                        {file.filename}<span style={{color: 'red', display: moment(file.expirationDate) < moment().endOf('day') ? 'inline-block' : 'none', paddingLeft: '5px'}}>- Expired</span>
                                                    </a>
                                                    <img width='30px' height='auto' src='/images/thumbnail/file.png' alt='thumb' />
                                                </div>
                                            </TableCell>
                                            <TableCell className={classes.pCell}>{file.note}</TableCell>
                                            <TableCell className={classes.pCell}>{file.uploadedBy}</TableCell>
                                            <TableCell className={classes.pCell}>{moment(file.dateUploaded).format('MM/DD/YYYY')}</TableCell>
                                            <TableCell className={classes.pCell}>{file.expirationDate ? moment(file.expirationDate).format('MM/DD/YYYY') : ''}</TableCell>
                                            {matches ? <TableCell>{file.size}</TableCell> : null}
                                            <TableCell className={classes.pCell} align='center'>
                                                <FormControlLabel
                                                    onChange={() => isDisplayToFieldTech(file.id)}
                                                    disabled={isDisable}
                                                    control={
                                                        <GreenCheckbox
                                                            checked={file.isDisplayToFieldTech}
                                                            value="tech"
                                                        />
                                                    }
                                                />
                                            </TableCell>
                                            <TableCell className={classes.pCell} style={{ whiteSpace: 'nowrap' }}>
                                                <IconButton onClick={() => onDownloadFile(file.documentUrl, file.filename)}>
                                                    <GetAppIcon />
                                                </IconButton>


                                                <IconButton aria-label="delete" onClick={() => {
                                                    setLocateFile({ index: index, id: file.id });
                                                    setOpenConfirm(true)
                                                }}>
                                                    <DeleteIcon />
                                                </IconButton>
                                            </TableCell>
                                        </TableRow>
                                    )) : (
                                            <TableRow>
                                                <TableCell colSpan={10}>
                                                    <Typography className={classes.message}>No data found...</Typography>
                                                </TableCell>
                                            </TableRow>
                                        )}
                                </TableBody>
                            </Table>
                            {
                                <div style={pageCount === 0 ? { display: 'none' } : null} className={classes.paginate}>
                                    <Pagination pageCount={pageCount} forcePage={forcePage} onPageChange={onPageChange} />
                                </div>
                            }
                        </TableContainer>
                    </CardContent>
                </Collapse>
            </Card>
            { isEnableCustomForm &&
            <Card>
                <CardHeader title="Custom Forms"
                    action={<KeyboardArrowDown />}
                    onClick={() => setExpandForm(!expandForm)}
                />
                <Divider />
                <Collapse in={expandForm} timeout='auto' unmountOnExit>
                    <CardContent>
                        <TableContainer className={classes.responsiveTable}>
                            <Table className={classes.table} aria-label="simple table">
                                <TableHead className={classes.customTableHead}>
                                    <TableRow className={classes.cusFontsize}>
                                        <TableCell className={classes.customTableCell}><span>Template Name</span></TableCell>
                                        <TableCell className={classes.customTableCell}><span>Description</span></TableCell>
                                        <TableCell className={classes.customTableCell}><span>Created Date</span></TableCell>
                                        <TableCell className={classes.customTableCell} style={{ width: '1%' }}>
                                        <Tooltip arrow title='Add Custom Forms' onClick={() => handleEditOpenCustomForm()}>
                                                    <IconButton className={classes.addBox}
                                                        
                                                    >
                                                        <AddBoxIcon />
                                                    </IconButton>
                                                </Tooltip>
                                        </TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {listForm && listForm.length !== 0 ? listForm.map((form, index) => (
                                        <TableRow>
                                            <TableCell className={clsx(classes.pCell, classes.pointer)} onClick={() => handleEditOpenCustomForm(form.customerCustomFormId)}>{form.formName}</TableCell>
                                            <TableCell className={clsx(classes.pCell, classes.pointer)} onClick={() => handleEditOpenCustomForm(form.customerCustomFormId)}>{form.description}</TableCell>
                                            <TableCell className={clsx(classes.pCell, classes.pointer)} onClick={() => handleEditOpenCustomForm(form.customerCustomFormId)}>{form.dateCreated ? moment(form.dateCreated).format('MM/DD/YYYY') : ''}</TableCell>
                                            <TableCell className={classes.pCell} style={{ whiteSpace: 'nowrap' }}>
                                                <IconButton aria-label="delete" onClick={() => {
                                                    setCustomForm(form.customerCustomFormId);
                                                    setOpenConfirmForm(true);
                                                }}>
                                                    <DeleteIcon />
                                                </IconButton>
                                            </TableCell>
                                        </TableRow>
                                    )) : (
                                            <TableRow>
                                                <TableCell colSpan={10}>
                                                    <Typography className={classes.message}>No data found...</Typography>
                                                </TableCell>
                                            </TableRow>
                                        )}
                                </TableBody>
                            </Table>
                        </TableContainer>
                    </CardContent>
                </Collapse>
            </Card>
            }

            <ConfirmModal
                openConfirm={openConfirm}
                closeConfirm={() => setOpenConfirm(false)}
                onConfirm={onDeleteFile}
                isProgress={isProgress}
            />

            <ConfirmModal
                openConfirm={openConfirmForm}
                closeConfirm={() => setOpenConfirmForm(false)}
                onConfirm={onDeleteCustomForm}
                isProgress={isProgress}
            />

            <Snackbar
                open={openSnack}
                anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
                autoHideDuration={2000}
                onClose={() => setOpenSnack(false)}>
                <Alert
                    elevation={6} variant="filled" severity={isSuccess.failed ? 'error' : 'success'}>
                    <Typography
                        color="inherit"
                        variant="h6">
                        {isSuccess.msg}
                    </Typography>
                </Alert>
            </Snackbar>
        </>
    );
};

Attachments.propTypes = {
    className: PropTypes.string
};

export default Attachments;
