import React, { useState, useMemo } from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/styles';
import { Page } from 'components';
import { AllCustomers, BidToDo, SaleFollowUps, Duplicate, MissingTaxGroup, ExpiringDocuments } from './components';
import { Redirect } from 'react-router-dom';
import { checkFeature, FeatureFlags } from 'FeatureService';
import BidToDoOld from './components/BidToDo/BidToDoOld';

const useStyles = makeStyles(theme => ({
  root: {
    padding: theme.spacing(3)
  },
}));

const CustomerList = props => {

  const { match, location } = props;
  const classes = useStyles();
  const { tab } = match.params;

  const [isEnableExpiringDocuments, setIsEnableExpiringDocuments] = useState();
  checkFeature(FeatureFlags.EXPIRING_DOCUMENTS).then(setIsEnableExpiringDocuments);

  const [isEnableBidsToDo, setIsEnableBidsToDo] = useState();
  checkFeature(FeatureFlags.BIDS_TO_DO).then(setIsEnableBidsToDo);

  const tabs = [
    { value: 'allcustomers', label: 'All customers' },
    { value: 'bidtodo', label: `Bids to do` },
    { value: 'salefollowups', label: `Sales Follow Ups` },
    { value: 'duplicate', label: `Duplicates` },
    { value: 'missingtaxgroup', label: `Missing Tax Groups` },
    { value: 'expiringdocuments', label: 'Expiring Documents' },
  ];

  return useMemo(() => {
    if (isEnableExpiringDocuments != null && !isEnableExpiringDocuments) {
      const idx = tabs.findIndex(t => t.value === 'expiringdocuments');
      if (idx > -1) tabs.splice(idx, 1);
    }

    if (!tabs.find(t => t.value === tab)) {
      return <Redirect to="/errors/error-404" />;
    }
  
    return (
      <Page className={classes.root} title={tabs.find(x => x.value === tab).label}>
        <div>
          {tab === 'allcustomers' && <AllCustomers defaultSearch={location.state} />}
          {tab === 'bidtodo' && (isEnableBidsToDo != null ? (isEnableBidsToDo ? <BidToDo /> : <BidToDoOld />) : null)}
          {tab === 'salefollowups' && <SaleFollowUps />}
          {tab === 'duplicate' && <Duplicate />}
          {tab === 'missingtaxgroup' && <MissingTaxGroup />}
          {tab === 'expiringdocuments' && <ExpiringDocuments />}
        </div>
      </Page>
    );
  }, [tab, isEnableExpiringDocuments, isEnableBidsToDo]);

};

CustomerList.propTypes = {
  history: PropTypes.object.isRequired,
  match: PropTypes.object.isRequired
}

export default CustomerList;
