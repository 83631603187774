import React from 'react'
import moment from 'moment'
import { Document, Page, Text, View, StyleSheet, Font, Image } from '@react-pdf/renderer';
import {formatNumber} from "../../../../../utils/formatNumber";

const styles = StyleSheet.create({
    invoiceSection: {
        fontFamily: 'Roboto', fontWeight: 'bold', fontSize: 20
    },
    sectionOne: {
        flexDirection: "row",
        justifyContent: 'space-between',
        width: '100%',
        alignItems: 'center'
    },
    textBold: {
        fontFamily: 'Roboto', fontWeight: 'bold', fontSize: '10px'
    },
    sectionDateAndInvoice: {
        width: '50%', height: 'auto', flexDirection: 'row'
    },
    sectionChildDateAndInvoice: {
        fontFamily: 'Roboto', fontWeight: 'medium', fontSize: 10, width: '50%', border: 1, textAlign: 'center', padding: 6
    },
    sectionTwo: {
        marginTop: 60, 
        flexDirection: "row",
        justifyContent: 'space-between',
        width: '100%',
        alignItems: 'center'
    },
    sectionThree: {
        marginTop: 10, 
        flexDirection: "row",
        justifyContent: 'space-between',
        width: '100%',
        alignItems: 'center'
    },
    dueDate: {
        fontFamily: 'Roboto', fontWeight: 'medium', fontSize: 10, width: '100%', border: 1, textAlign: 'center', padding: 6
    },
    sectionTable: {
        width: '100%', height: 'auto', flexDirection: 'row'
    },
    jobDescription: {
        marginTop: 10, 
        flexDirection: "row",
        width: '100%',
    }
});

const JobInvoicePdf = (props) => {

    Font.register({
        family: 'Roboto',
        fonts: [
            {
                src: `https://cdn.jsdelivr.net/npm/roboto-fontface@0.10.0/fonts/roboto/Roboto-Regular.woff`,
                fontWeight: 'medium'
            },
            {
                src: `https://cdn.jsdelivr.net/npm/roboto-fontface@0.10.0/fonts/roboto/Roboto-Bold.woff`,
                fontWeight: 'bold'
            }
        ]
    })
 
 
    return (
        <>
            <Document>
                <Page size="A4" style={{ paddingTop: 20 }}>
                    {
                        props.jobInfoPdf && props.jobInfoPdf.paid &&
                        <View style={{ position: 'fixed', top: 200, left: 160, width: 200, opacity: 0.7 }}>
                            <Image src="/images/quote_invoice/paid-rectangle-stamp-1.png" />
                        </View>
                    }
                    <View style={{ padding: 30, paddingTop: 20, marginTop: props.jobInfoPdf && props.jobInfoPdf.paid ? -130 : -8 }}>
                        <View style={{flexDirection: "row",justifyContent: 'space-between', width: '100%',}}>
                            <View style={{ flexDirection: "row", width: '50%', height: 'auto', alignItems: 'flex-start' }}>
                                {props.jobInfoPdf?.company?.logoUrl?.includes('companylogo')
                                    ? <Image
                                        src={props.jobInfoPdf.company.logoUrl}
                                        style={{ maxHeight: 40, objectFit: 'contain', objectPosition: 'top left' }}
                                    />
                                    : <View style={{ height: 40 }} />
                                }
                            </View>
                            <View style={{ width: '50%', height: 'auto', alignItems: 'flex-end', verticalAlign: 'super' }}>
                                <Text style={styles.invoiceSection}>Invoice</Text>
                            </View>
                        </View>
                        <View>
                            <View style={styles.sectionOne}>
                                <View style={{ width: '50%', height: 'auto', alignItems: 'flex-start' }}>
                                    <Text style={styles.textBold}>
                                        {props.jobInfoPdf && props.jobInfoPdf.company ? props.jobInfoPdf.company.name : ""}
                                    </Text>
                                    <Text style={styles.textBold}>
                                        {props.jobInfoPdf && props.jobInfoPdf.company && props.jobInfoPdf.company.addresses && props.jobInfoPdf.company.addresses.length > 0 ? props.jobInfoPdf.company.addresses[0].address1 : ""}
                                    </Text>
                                    <Text style={styles.textBold}>
                                        {props.jobInfoPdf && props.jobInfoPdf.company && props.jobInfoPdf.company.addresses && props.jobInfoPdf.company.addresses.length > 0 ? props.jobInfoPdf.company.addresses[0].city+", " : ""}
                                        {props.jobInfoPdf && props.jobInfoPdf.company && props.jobInfoPdf.company.addresses && props.jobInfoPdf.company.addresses.length > 0 ? props.jobInfoPdf.company.addresses[0].state.name+" " : ""}
                                        {props.jobInfoPdf && props.jobInfoPdf.company && props.jobInfoPdf.company.addresses && props.jobInfoPdf.company.addresses.length > 0 ? props.jobInfoPdf.company.addresses[0].zipCode : ""}
                                    </Text>
                                </View>
                                <View style={{ width: '50%', height: 'auto', alignItems: 'flex-end' }}>
                                    <View style={styles.sectionDateAndInvoice}>
                                        <Text style={styles.sectionChildDateAndInvoice}>DATE</Text>
                                        <Text style={[styles.sectionChildDateAndInvoice, { borderLeft: 0 }]}>INVOICE #</Text>
                                    </View>
                                    <View style={styles.sectionDateAndInvoice}>
                                        <Text style={[styles.sectionChildDateAndInvoice, { borderTop: 0 }]}>
                                            {props.jobInfoPdf && props.jobInfoPdf.scheduledDate ? moment(props.jobInfoPdf.scheduledDate).format('MM/DD/YYYY') : ""}
                                        </Text>
                                        <Text style={[styles.sectionChildDateAndInvoice, { borderLeft: 0, borderTop: 0 }]}>{props.jobInfoPdf && props.jobInfoPdf.invoiceNo ? props.jobInfoPdf.invoiceNo : ""}</Text>
                                    </View>
                                </View>
                            </View>

                            <View style={styles.sectionTwo}>
                                <View style={{ width: '55%', height: 'auto', alignItems: 'flex-start' }}>
                                    <Text style={styles.textBold}>BILL TO</Text>
                                    <Text style={{ fontSize: 10, fontFamily: 'Roboto', fontWeight: 'medium' }}>{props.jobInfoPdf && props.jobInfoPdf.customer ? props.jobInfoPdf.customer.companyName : ""}</Text>
                                    <Text style={{ fontSize: 10, fontFamily: 'Roboto', fontWeight: 'medium' }}>
                                        {props.jobInfoPdf && props.jobInfoPdf.customer && props.jobInfoPdf.customer.address && props.jobInfoPdf.customer.address.address1 ? props.jobInfoPdf.customer.address.address1 : ""}
                                    </Text>
                                    <Text style={{ fontSize: 10, fontFamily: 'Roboto', fontWeight: 'medium' }}>
                                        {props.jobInfoPdf && props.jobInfoPdf.customer && props.jobInfoPdf.customer.address ? props.jobInfoPdf.customer.address.city+", " : ""}
                                        {props.jobInfoPdf && props.jobInfoPdf.customer && props.jobInfoPdf.customer.address ? props.jobInfoPdf.customer.address.state.name+" " : ""}
                                        {props.jobInfoPdf && props.jobInfoPdf.customer && props.jobInfoPdf.customer.address ? props.jobInfoPdf.customer.address.zipCode : ""}
                                    </Text>
                                    <Text style={{ fontSize: 10, fontFamily: 'Roboto', fontWeight: 'medium', marginTop: 10 }}>
                                        {props.jobInfoPdf?.customer?.primaryContact && (
                                            props.jobInfoPdf.customer.primaryContact.firstName +
                                            (props.jobInfoPdf.customer.primaryContact.phones?.length
                                                ? (' - ' + props.jobInfoPdf.customer.primaryContact.phones[0]?.phoneNumber)
                                                : '')
                                        )}
                                    </Text>
                                </View>
                                <View style={{ width: '30%', height: 'auto' }}>
                                    <Text style={styles.textBold}>JOB ADDRESS</Text>
                                    <Text style={{ fontSize: 10, fontFamily: 'Roboto', fontWeight: 'medium' }}>
                                        {props.jobInfoPdf && props.jobInfoPdf.job && props.jobInfoPdf.job.jobAddress ? props.jobInfoPdf.job.jobAddress.name : ""}
                                    </Text>
                                    <Text style={{ fontSize: 10, fontFamily: 'Roboto', fontWeight: 'medium' }}>
                                        {props.jobInfoPdf && props.jobInfoPdf.job && props.jobInfoPdf.job.jobAddress && props.jobInfoPdf.job.jobAddress.address1 ? props.jobInfoPdf.job.jobAddress.address1 : ""}
                                    </Text>
                                    <Text style={{ fontSize: 10, fontFamily: 'Roboto', fontWeight: 'medium' }}>
                                        {props.jobInfoPdf && props.jobInfoPdf.job && props.jobInfoPdf.job.jobAddress ? props.jobInfoPdf.job.jobAddress.city+", " : ""}
                                        {props.jobInfoPdf && props.jobInfoPdf.job && props.jobInfoPdf.job.jobAddress ? props.jobInfoPdf.job.jobAddress.state.name+" " : ""}
                                        {props.jobInfoPdf && props.jobInfoPdf.job && props.jobInfoPdf.job.jobAddress ? props.jobInfoPdf.job.jobAddress.zipCode : ""}
                                    </Text>
                                    <Text style={{ fontSize: 10, fontFamily: 'Roboto', fontWeight: 'medium', marginTop: 10 }}>
                                        {props.jobInfoPdf?.job?.jobAddress?.primaryContact && (
                                            props.jobInfoPdf.job.jobAddress.primaryContact.name +
                                            (props.jobInfoPdf.job.jobAddress.primaryContact.phone
                                                ? (' - ' + props.jobInfoPdf.job.jobAddress.primaryContact.phone)
                                                : '')
                                        )}
                                    </Text>
                                </View>
                                <View style={{ width: '15%', height: 'auto' }} />
                            </View>

                            <View style={styles.jobDescription}>
                                <Text style={styles.textBold}>Job Description:</Text>
                                <Text style={{ width: '60%', marginLeft: 10, fontSize: 10, fontFamily: 'Roboto', fontWeight: 'medium' }}>{props.jobInfoPdf?.job?.description ?? ''}</Text>
                            </View>

                            <View
                                style={styles.sectionThree}
                                >
                                <View style={{ width: '15%', height: 'auto' }}>
                                    <Text style={[styles.dueDate, { borderColor: 'white', color: 'white' }]}>PO #</Text>
                                    <Text style={[styles.dueDate, { borderBottom: 0 }]}>
                                        PO # {'\u00A0'} {props.jobInfoPdf && props.jobInfoPdf.job ? props.jobInfoPdf.job.purchaseOrder : ""}
                                    </Text>
                                </View>
                                <View style={{ width: '65%', height: 'auto' }} />
                                <View style={{ width: '20%', height: 'auto', marginTop: 1 }}>
                                    <Text style={[styles.dueDate, { borderBottom: 0 }]}>DUE DATE</Text>
                                    <Text style={[styles.dueDate, { borderBottom: 0 }]}>
                                        {props.jobInfoPdf && props.jobInfoPdf.dueDate ? props.jobInfoPdf.dueDate : '\u00A0'}
                                    </Text>
                                </View>
                            </View>

                            <View style={styles.sectionTable}>
                                <Text style={{ fontSize: 10, width: '60%', border: 1, textAlign: 'left', padding: 6, borderRight: 0, fontFamily: 'Roboto', fontWeight: 'bold', fontSize: '10px' }}>Description</Text>
                                <Text style={{ fontSize: 10, width: '10%', border: 1, textAlign: 'center', padding: 6, borderRight: 0, fontFamily: 'Roboto', fontWeight: 'bold', fontSize: '10px' }}>Quantity</Text>
                                <Text style={{ fontSize: 10, width: '10%', border: 1, textAlign: 'center', padding: 6, borderRight: 0, fontFamily: 'Roboto', fontWeight: 'bold', fontSize: '10px' }}>Rate</Text>
                                <Text style={{ fontSize: 10, width: '20%', border: 1, textAlign: 'center', padding: 6, fontFamily: 'Roboto', fontWeight: 'bold', fontSize: '10px' }}>Amount</Text>
                            </View>
                            {props.jobInfoPdf && props.jobInfoPdf.jobPriceItems && props.jobInfoPdf.job && props.jobInfoPdf.job.displayOnPrintOutsEmails && props.jobInfoPdf.jobPriceItems.map(function (value, index, array) {
                                return <View key={index} style={styles.sectionTable}>
                                            <Text style={{ fontFamily: 'Roboto', fontWeight: 'medium', fontSize: 10, width: '60%', border: 1, textAlign: 'left', padding: 6, borderRight: 0, borderTop: 0 }}>{value.description}</Text>
                                            <Text style={{ fontFamily: 'Roboto', fontWeight: 'medium', fontSize: 10, width: '10%', border: 1, textAlign: 'right', padding: 6, borderRight: 0, borderTop: 0 }}>{value.quantity}</Text>
                                            <Text style={{ fontFamily: 'Roboto', fontWeight: 'medium', fontSize: 10, width: '10%', border: 1, textAlign: 'right', padding: 6, borderRight: 0, borderTop: 0 }}>
                                                {(value && value.price && `$${formatNumber(value.price || 0, 2)}`) || '$0.00'}
                                            </Text>
                                            <Text style={{ fontFamily: 'Roboto', fontWeight: 'medium', fontSize: 10, width: '20%', border: 1, textAlign: 'center', padding: 6, borderTop: 0 }}>
                                                {(value && value.total && `$${formatNumber(value.total || 0,2)}`) || '$0.00'}
                                            </Text>
                                        </View>
                            })}

                            <View wrap={false} style={{ marginTop: -1 }}>
                                {props.jobInfoPdf && props.jobInfoPdf.job && props.jobInfoPdf.job.discountPercent > 0 && props.jobInfoPdf.job.discountDollars > 0
                                ? <View style={styles.sectionTable}>
                                        <Text style={{ width: '10%' }}></Text>
                                        <Text style={{ width: '50%'}}></Text>
                                        <Text style={{ width: '20%', border: 1, borderRight: 0, fontSize: 10,textAlign: 'right', padding: 6, paddingRight: 0, fontFamily: 'Roboto', fontWeight: 'bold', fontSize: '10px' }}>
                                            Discount ({props.jobInfoPdf.job.discountPercent}%)
                                        </Text>
                                        <Text style={{ width: '20%', border: 1, borderLeft: 0, fontSize: 10,textAlign: 'right', padding: 6, fontFamily: 'Roboto', fontWeight: 'medium' }}>
                                            {(props.jobInfoPdf && props.jobInfoPdf.job && `$${formatNumber(props.jobInfoPdf.job.discountDollars || 0,2)}`) || '$0.00'}
                                        </Text>
                                    </View>
                                : null }

                                <View style={styles.sectionTable}>
                                    <Text style={{ width: '10%' }}></Text>
                                    <Text style={{ width: '50%'}}></Text>
                                    <Text style={{ width: '20%', border: 1, borderRight: 0, borderTop: 0, fontSize: 10,textAlign: 'right', padding: 6, paddingRight: 0, fontFamily: 'Roboto', fontWeight: 'bold', fontSize: '10px' }}>
                                        Sales Tax ({props.jobInfoPdf && props.jobInfoPdf.job ? props.jobInfoPdf.job.taxPercent : "0"}%)
                                    </Text>
                                    <Text style={{ width: '20%', border: 1, borderLeft: 0, borderTop: 0, fontSize: 10,textAlign: 'right', padding: 6, fontFamily: 'Roboto', fontWeight: 'medium' }}>
                                        {(props.jobInfoPdf && props.jobInfoPdf.taxDollar && `$${formatNumber(props.jobInfoPdf.taxDollar || 0, 2)}`) || '$0.00'}
                                    </Text>
                                </View>

                                {props.jobInfoPdf?.tip > 0
                                ? <View style={styles.sectionTable}>
                                        <Text style={{ width: '10%' }}></Text>
                                        <Text style={{ width: '50%'}}></Text>
                                        <Text style={{ width: '20%', border: 1, borderRight: 0, borderTop: 0, fontSize: 10,textAlign: 'right', padding: 6, paddingRight: 0, fontFamily: 'Roboto', fontWeight: 'bold', fontSize: '10px' }}>
                                            Tip
                                        </Text>
                                        <Text style={{ width: '20%', border: 1, borderLeft: 0, borderTop: 0, fontSize: 10,textAlign: 'right', padding: 6, fontFamily: 'Roboto', fontWeight: 'medium' }}>
                                            ${formatNumber(props.jobInfoPdf.tip, 2)}
                                        </Text>
                                    </View>
                                : null}

                                <View style={styles.sectionTable}>
                                    <Text style={{ width: '10%' }}></Text>
                                    <Text style={{ width: '50%'}}></Text>
                                    <Text style={{ width: '20%', border: 1, borderRight: 0, borderTop: 0, fontSize: 10,textAlign: 'right', padding: 6, paddingRight: 0, fontFamily: 'Roboto', fontWeight: 'bold', fontSize: '10px' }}>
                                        Total
                                    </Text>
                                    <Text style={{ width: '20%', border: 1, borderLeft: 0, borderTop: 0, fontSize: 10,textAlign: 'right', padding: 6, fontFamily: 'Roboto', fontWeight: 'medium' }}>
                                        {(props.jobInfoPdf && props.jobInfoPdf.totalAmount && `$${formatNumber(props.jobInfoPdf.totalAmount || 0, 2)}`) || '$0.00'}
                                    </Text>
                                </View>
                            </View>

                            <View
                                style={{
                                    marginTop: 50, 
                                    width: '100%',
                                }}
                            >
                                <Text style={{ fontSize: '10px', fontFamily: 'Roboto', fontWeight: 'medium' }}>
                                    {props.jobInfoPdf && props.jobInfoPdf.company ? props.jobInfoPdf.company.invoiceForm : ""}
                                    {props.draftPdf ? props.draftPdf : ""}
                                </Text>
                            </View>
                        </View>
                    </View>
                </Page>
            </Document>
        </>
    )
}

JobInvoicePdf.propTypes = {
    
}

export default JobInvoicePdf;
