import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import {
    TableContainer, Table, TableHead, TableBody, Grid,
    TableRow, TableCell, Card, CardHeader, CardContent,
    Typography, Divider, colors, CircularProgress, IconButton
} from '@material-ui/core'
import OpenInNew from '@material-ui/icons/OpenInNew';
import { makeStyles } from '@material-ui/styles'
import { Page } from 'components'
import axios from "utils/axios";
import apiConfig from "apiConfig";
import moment from 'moment';
import { formatNumber } from "utils/formatNumber";

const useStyles = makeStyles(theme => ({
    root: {
        padding: theme.spacing(5)
    },
    circularProgress: {
        marginTop: '30px',
    },
    card: {
        position: 'relative'
    },
    linkIcon: {
        position: 'absolute',
        top: 0,
        right: 0
    },
    link: {
        cursor: 'pointer',
        fontSize: theme.spacing(2),
        marginBottom: theme.spacing(2),
        '& a': {
            color: '#263238',
            '&:hover': {
                textDecoration: 'underline'
            }
        }
    },
    payroll: {
        '&:hover': {
            cursor: 'pointer',
            backgroundColor: colors.green[600],
            '& td': {
                color: '#ffffff'
            }
        }
    },
    btnAction: {
        color: theme.palette.white,
        backgroundColor: colors.green[600],
        '&:hover': {
            backgroundColor: colors.green[900]
        }
    },
    printIcon: {
        position: 'absolute',
        top: '-58px',
        right: 0,
        fontSize: '32px'
    },
    divider: {
        margin: '0 50px'
    },
    tableCell: {
        fontSize: '16px',
        borderBottom: '1px solid #969696',
        padding: '8px 16px'
    },
    dateRange: {
        textAlign: 'center'
    },
    openEmployee: {
        color: colors.green[600],
        '&:hover': {
            color: colors.green[800]
        }
    },
}))

let mounted = false;
const stringMonths = [
    'January', 'February', 'March', 'April', 'May', 'June',
    'July', 'August', 'September', 'October', 'November', 'December'
]
const EmployeePayroll = ({ history, match }) => {

    const classes = useStyles();
    const { params } = match;

    const [loading, setLoading] = useState(false);
    const [payroll, setPayroll] = useState([]);

    const getDateRange = () => {
        const { startdate, enddate } = params;
        const s = startdate.split('-');
        const e = enddate.split('-');

        return `${stringMonths[parseInt(s[1]) - 1]} ${s[2]} - ${stringMonths[parseInt(e[1]) - 1]} ${e[2]}`;
    }

    const getDateRangeFromParams = () => {
        const { startdate, enddate } = params;
        const sd = startdate.split('-');
        const ed = enddate.split('-');
        const fromDate = new Date(parseInt(sd[0]), parseInt(sd[1] - 1), parseInt(sd[2])).toISOString();
        const toDate = new Date(parseInt(ed[0]), parseInt(ed[1] - 1), parseInt(ed[2])).toISOString();

        return { fromDate, toDate };
    }

    const redirectToJobScreen = (pay) => {
        // history.push(`/customers/${pay.firstName} ${pay.lastName}/:id/jobs/:jobId/:tab`)
    }

    const redirectBackToPayrollList = () => {
        const { startdate, enddate } = params;
        window.location.href = `/employees/payroll-list?start=${moment(startdate).toISOString()}&end=${moment(enddate).toISOString()}`;
    }

    const formatDollar = value => {
        if (!value) return 0;
        return parseFloat(value).toFixed(2).replace(/(\d)(?=(\d{3})+\.\d{2})/g, '$1,');
    }

    useEffect(() => {
        const { id } = params;
        const dateRange = getDateRangeFromParams();

        setLoading(true);
        axios.get(apiConfig.url.BASE_URL + apiConfig.url.DETAIL_PAYROLL + `employeeId=${id}&&fromDate=${dateRange.fromDate}&&toDate=${dateRange.toDate}`)
            .then(res => setPayroll(res.data))
            .finally(() => setLoading(false))
        // 
    }, [])

    return (
        <Page title='Employee - Payroll' style={{minHeight: '100%', height: '100%'}}>
            <div className={classes.root} style={{minHeight: '100%'}}>
                <Typography className={classes.link} onClick={redirectBackToPayrollList}>
                    Back
                </Typography>
                <Card className={classes.card} style={{height: 'calc(100vh - 181px)'}}>
                    <Link to={`/employees/${encodeURIComponent(params.name)}/${params.id}/information`} target='_blank'
                        className={classes.linkIcon}>
                        <IconButton className={classes.openEmployee}>
                            <OpenInNew />
                        </IconButton>
                    </Link>
                    <CardHeader title={`Employee Payroll - ${params.name}`} />
                    <Divider />
                    <CardContent>
                        <div className={classes.dateRange}>
                            <span>
                                <strong>Pay Period: </strong>
                                {getDateRange()}
                            </span>
                        </div>
                        <TableContainer>
                            <Table stickyHeader style={{ marginTop: '24px', width: '100%', height: 'calc(100vh - 308px)', display: 'block' }}>
                                <TableHead>
                                    <TableRow>
                                        <TableCell align='center' className={classes.tableCell}>Date</TableCell>
                                        <TableCell align='center' className={classes.tableCell}>Detail</TableCell>
                                        <TableCell align='center' className={classes.tableCell}>Total Hours Logged</TableCell>
                                        <TableCell align='center' className={classes.tableCell}>Paid Hours</TableCell>
                                        <TableCell align='center' className={classes.tableCell}>Salary Labor</TableCell>
                                        <TableCell align='center' className={classes.tableCell}>Hourly Labor</TableCell>
                                        <TableCell align='center' className={classes.tableCell}>Total Job Revenue</TableCell>
                                        <TableCell align='center' className={classes.tableCell}>Work Portion of Job Revenue</TableCell>
                                        <TableCell align='center' className={classes.tableCell}>Commission Labor</TableCell>
                                        <TableCell align='center' className={classes.tableCell}>Total Labor</TableCell>
                                        <TableCell align='center' className={classes.tableCell}>Average Hourly Rate</TableCell>
                                        <TableCell align='center' className={classes.tableCell}>Tips</TableCell>
                                        <TableCell align='center' className={classes.tableCell}>Sales Commissions</TableCell>
                                        <TableCell align='center' className={classes.tableCell}>Upsales Commissions</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {
                                        loading ? (
                                            <TableRow style={{ height: '60px' }} className={classes.payroll}>
                                                <TableCell colSpan={14} align='center'>
                                                    <Grid
                                                        container
                                                        spacing={0}
                                                        align="center"
                                                        justifyContent="center"
                                                        direction="column">
                                                        <Grid item>
                                                            <CircularProgress className={classes.circularProgress} size={50} />
                                                        </Grid>
                                                    </Grid>
                                                </TableCell>
                                            </TableRow>) : <>
                                            {
                                                payroll.map((pay, index) => (
                                                    <TableRow className={classes.payroll} key={`detail-payroll-${index}`} onClick={() => redirectToJobScreen(pay)}>
                                                        <TableCell align='center'>
                                                            {pay.date ? moment.utc(pay.date).local().format('MM/DD/YYYY') : ''}
                                                        </TableCell>
                                                        {(
                                                            pay.isDetail && pay.isDetail == 2 ? <>
                                                                <TableCell align='left' colSpan={13} >{pay.detail}</TableCell>
                                                                </>
                                                            : 
                                                            pay.isDetail && pay.isDetail == 1 ? 
                                                                <>
                                                                    <TableCell align='center'>{pay.detail || ''}</TableCell>
                                                                    <TableCell align='center'>{formatNumber(pay.totalHoursLogged, 2)}</TableCell>
                                                                    <TableCell align='center'>{formatNumber(pay.paidHours, 2)}</TableCell>
                                                                    <TableCell align='center'>${formatDollar(pay.salaryLaborPay, 2)}</TableCell>
                                                                    <TableCell align='center'>${formatDollar(pay.hourlyLabor, 2)}</TableCell>
                                                                    <TableCell align='center'>${formatDollar(pay.totalJobRevenue, 2)}</TableCell>
                                                                    <TableCell align='center'>${formatDollar(pay.workPortionofJobRevenue, 2)}</TableCell>
                                                                    <TableCell align='center'>${formatDollar(pay.comissionLabor, 2)}</TableCell>
                                                                    <TableCell align='center'>${formatDollar(pay.totalLabor, 2)}</TableCell>
                                                                    <TableCell align='center'>${formatDollar(pay.averageHourlyRate, 2)}</TableCell>
                                                                    <TableCell align='center'>${formatDollar(pay.tips, 2)}</TableCell>
                                                                    <TableCell align='center'>${formatDollar(pay.salesCommission, 2)}</TableCell>
                                                                    <TableCell align='center'>${formatDollar(pay.upsalesCommission, 2)}</TableCell>
                                                                </>
                                                                :
                                                                <>
                                                                <TableCell align='center'>{pay.detail || ''}</TableCell>
                                                                <TableCell align='center'>{formatNumber(pay.totalHoursLogged, 2)}</TableCell>
                                                                <TableCell align='center'>{formatNumber(pay.paidHours, 2)}</TableCell>
                                                                <TableCell align='center'></TableCell>
                                                                <TableCell align='center'>${formatDollar(pay.hourlyLabor, 2)}</TableCell>
                                                                <TableCell align='center'>${formatDollar(pay.totalJobRevenue, 2)}</TableCell>
                                                                <TableCell align='center'>${formatDollar(pay.workPortionofJobRevenue, 2)}</TableCell>
                                                                <TableCell align='center'>${formatDollar(pay.comissionLabor, 2)}</TableCell>
                                                                <TableCell align='center'></TableCell>
                                                                <TableCell align='center'></TableCell>
                                                                <TableCell align='center'>${formatDollar(pay.tips, 2)}</TableCell>
                                                                <TableCell align='center'>${formatDollar(pay.salesCommission, 2)}</TableCell>
                                                                <TableCell align='center'>${formatDollar(pay.upsalesCommission, 2)}</TableCell>
                                                            </>
                                                             
                                                        )}
                                                    </TableRow>
                                                ))
                                            }
                                        </>
                                    }

                                </TableBody>
                            </Table>
                        </TableContainer>

                    </CardContent>
                </Card>
            </div>
        </Page>
    )
}

export default EmployeePayroll;
