import React from 'react'
import { withStyles } from '@material-ui/styles'
import { colors, Radio } from '@material-ui/core'

const GreenRadioBidToDo = withStyles({
    root: {
        color: colors.green[600],
        padding: '0px',
        marginRight: '5px',
        width: '9px',
        marginRight: '8px'
    },
    checked: {},
})(props => <Radio color="default" {...props} />);

export default GreenRadioBidToDo;