import React from 'react'
import { DropzoneArea } from 'material-ui-dropzone'
import { makeStyles } from '@material-ui/styles'
import { colors } from '@material-ui/core'

const useStyles = makeStyles(theme => ({
    styleDropContainer: {
        minHeight: '100px',
        border: '1px dot #cccccc',
        marginBottom: "4px",
        backgroundColor: theme.palette.white,
        '& > div:first-child': {
            display: 'flex',
            flexDirection: 'row-reverse',
            justifyContent: 'center',
            backgroundColor: theme.palette.background.default,
            alignItems: 'center',
            paddingTop: '15px',
            paddingBottom: '15px',
            border: '1px dashed gray',
            '& svg': {
                color: colors.green[600]
            },
        },
        '& .MuiGrid-container': {
            width: '100%',
            margin: 0,
            '& .MuiGrid-item': {
                padding: '16px 0'
            }
        }
    },
    styleDropText: {
        marginLeft: theme.spacing(2),
        color: '#263238',
        fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
        userSelect: 'none'
    }
}));

const Dropzone = (props) => {

    const classes = useStyles();

    const { onChange , acceptedFiles } = props;


    return (
        <DropzoneArea
            filesLimit={3}
            acceptedFiles={acceptedFiles || []}
            maxFileSize={10000000}
            getDropRejectMessage={(e, r, t) => {
                return "An error has occurred. The file is too large. The size limit is 10MB."
            }}
            dropzoneClass={classes.styleDropContainer}
            dropzoneParagraphClass={classes.styleDropText}
            dropzoneText="Drop files here or click..."
            onChange={files => onChange(files)}
            showAlerts={true}
            alertSnackbarProps={{
                anchorOrigin: { horizontal: "center", vertical: "top" }
            }}
        />
    )
}

export default Dropzone;
