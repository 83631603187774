import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import {
    Table,
    TableHead,
    TableBody,
    TableRow,
    TableCell, CircularProgress, Snackbar, Typography,
} from '@material-ui/core'
import { makeStyles } from '@material-ui/styles'
import DaysInWeek from './DaysInWeek'
import axios from "utils/axios";
import apiConfig from "apiConfig";
import * as moment from 'moment';
import { useDispatch, useSelector } from "react-redux";
import { Alert } from '@material-ui/lab'
import ConfirmRescheduleModal from './ConfirmRescheduleModal'

const useStyles = makeStyles(theme => ({
    styleTable: {
        minWidth: '750px',
        height: '100%',
        '& thead tr th,tbody tr td': {
            border: '1px solid #eeeeee'
        },
        '& thead tr th,tbody tr$updating td': {
            border: 'none'
        }
    },
    rowWeek: {
        width: 'calc(100% / 7)'
    },
    boxDate: {
        width: '100%',
        paddingTop: '100%',
        position: 'relative'
    },
    boxContent: {
        position: 'absolute',
        top: '0'
    },
    updating: {
        width: '100%',
        height: '100%',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        position: 'absolute',
        top: 0,
        left: 0,
        zIndex: 2,
        backgroundColor: '#0000001f'
    }
}))

const MonthCalendar = props => {
    const dispatch = useDispatch();

    const classes = useStyles();
    const { indexs, days, date, contact, redirectToRoutesSchedules } = props;

    const { schedules } = useSelector(state => state.SchedulesReducer);

    const [loading, setLoading] = useState(false);
    const [updating, setUpdating] = useState(false);
    const [scheduleRoute, setScheduleRoute] = useState();
    const [scheduleToDate, setScheduleToDate] = useState();
    const [conflictingEmployees, setConflictingEmployees] = useState([]);
    const [isStatus, setIsStatus] = useState({ failed: false, msg: '' });
    const [openSnackbar, setOpenSnackbar] = useState(false);
    const [openConfirmRescheduleModal, setOpenConfirmRescheduleModal] = useState(false);

    const handleDropItem = (item, day) => {
        const updateRoute = schedules?.find(x => x.id === item.routeId);
        if (moment(day).format('YYYY-MM-DD') === moment(updateRoute.routeDate).format('YYYY-MM-DD')) return;

        setScheduleRoute(updateRoute);
        const routesInDate = schedules?.filter(x => moment(day).format('YYYY-MM-DD') === moment(x.routeDate).format('YYYY-MM-DD')) ?? [];
        const employeeIdsInDate = routesInDate
            .reduce((employees, route) => [...employees, ...route.routeEmployees], [])
            .map(re => re.employeeId);

        setConflictingEmployees(updateRoute?.routeEmployees.filter(re => employeeIdsInDate.includes(re.employeeId)) ?? []);
        setScheduleToDate(moment(day).format('MM-DD-YYYY'));
        setOpenConfirmRescheduleModal(true);
    };

    const handleReschedule = (moveEmployees) => {
        setOpenConfirmRescheduleModal(false);
        setUpdating(true);
        axios.put(
            apiConfig.url.BASE_URL + apiConfig.url.ROUTE_DATE.replace('{routeId}', scheduleRoute.id),
            {
                routeDate: scheduleToDate,
                moveEmployees,
                moveEmployeeIds: conflictingEmployees.map(e => e.employeeId)
            }
        )
            .then(res => {
                scheduleRoute.routeDate = moment(res.data.routeDate).format('YYYY-MM-DD');
                scheduleRoute.routeOrder = res.data.routeOrder;
                setIsStatus({ failed: false, msg: 'Update route successfully.' });
                setOpenSnackbar(true);
            })
            .catch(() => {
                setIsStatus({ failed: true, msg: 'Update route failed, please try again later.' });
                setOpenSnackbar(true);
            })
            .finally(() => setUpdating(false));
    }

    useEffect(() => {
        const year = moment(date).format('YYYY');
        const month = moment(date).format('MM');
        setLoading(true);
        axios.get(apiConfig.url.BASE_URL + apiConfig.url.ROUTE + `list` + `/${year}` + `/${month}`)
            .then((res) => {
                res.data.map(item => {
                    item.routeDate = moment(item.routeDate).format('YYYY-MM-DD');
                })
                dispatch({ type: 'INIT_SCHEDULES', schedules: res.data })
            })
            .finally(() => setLoading(false))
    }, [date])

    return (
        <>
            <Table stickyHeader className={classes.styleTable}>
                <TableHead>
                    <TableRow>
                        <TableCell className={classes.rowWeek}>Sunday</TableCell>
                        <TableCell className={classes.rowWeek}>Monday</TableCell>
                        <TableCell className={classes.rowWeek}>Tuesday</TableCell>
                        <TableCell className={classes.rowWeek}>Wednesday</TableCell>
                        <TableCell className={classes.rowWeek}>Thursday</TableCell>
                        <TableCell className={classes.rowWeek}>Friday</TableCell>
                        <TableCell className={classes.rowWeek}>Saturday</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody style={{ position: 'relative' }}>
                    {!loading ?
                        indexs.map(index => (
                            <DaysInWeek
                                key={index}
                                days={days}
                                weekData={days.slice(7 * index, (7 * index) + 7)}
                                contact={contact}
                                redirectToRoutesSchedules={redirectToRoutesSchedules}
                                handleDropItem={handleDropItem}
                            />
                        ))
                        :
                        <TableRow>
                            <TableCell colSpan={7} style={{ textAlign: 'center' }}>
                                <CircularProgress className={classes.circularProgress} size={50} />
                            </TableCell>
                        </TableRow>
                    }
                    {
                        updating && (
                            <TableRow className={classes.updating}>
                                <TableCell>
                                    <CircularProgress size={32} />
                                </TableCell>
                            </TableRow>
                        )
                    }
                </TableBody>
            </Table>

            <ConfirmRescheduleModal
                open={openConfirmRescheduleModal}
                date={scheduleToDate}
                employees={conflictingEmployees}
                onConfirm={handleReschedule}
                onClose={() => setOpenConfirmRescheduleModal(false)}
            />

            <Snackbar
                open={openSnackbar}
                onClose={() => setOpenSnackbar(false)}
                autoHideDuration={2000}
                anchorOrigin={{ vertical: "top", horizontal: "center" }}
            >
                <Alert
                    elevation={6}
                    variant="filled"
                    severity={isStatus.failed ? "error" : "success"}
                >
                    <Typography color="inherit" variant="h6">
                        {isStatus.msg}
                    </Typography>
                </Alert>
            </Snackbar>
        </>
    )
}

MonthCalendar.propTypes = {
    indexs: PropTypes.array.isRequired,
    days: PropTypes.array.isRequired
}

MonthCalendar.defaultProps = {
    indexs: [],
    days: []
}

export default MonthCalendar;
