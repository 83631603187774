import React, { useEffect, useMemo } from 'react'
import PropTypes from 'prop-types'
import { Typography, Tooltip, colors } from '@material-ui/core'
import { createTheme, MuiThemeProvider } from '@material-ui/core/styles'
import { makeStyles } from '@material-ui/styles'
import * as moment from "moment";
import StatusRoute from "./StatusIcon";
import { useDrag } from 'react-dnd'
import { useDispatch } from 'react-redux'

const useStyles = makeStyles(theme => ({
    widthBox: {
        position: 'relative',
        padding: '2px 0',
        zIndex: 0
    },
    ellipsisSpan: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        padding: '4px',
        borderRadius: '5px',
        color: '#ffffff',
        textAlign: 'right',
    },
    infoText: {
        fontSize: '12px',
        display: 'block',
        whiteSpace: 'pre-line',
    },
    infoText2: {
        fontSize: '13px',
        whiteSpace: 'pre-line',
    },
    bgGreen: {
        backgroundColor: colors.green[500],
    },
    bgPurple: {
        backgroundColor: '#8a2be2',
    },
    red: {
        backgroundColor: '#F72A0F',
    },
    yellow: {
        backgroundColor: '#F5D71D',
        color: 'black'
    },
    green: {
        backgroundColor: '#4caf50',
    },
    gray: {
        backgroundColor: '#b0b0b0'
    },
    job: {
        fontSize: '12px',
        textAlign: 'right',
        color: '#ffffff'
    },
    customerService: {
        border: '3px solid #ffef9f',
        color: '#ffef9f',
        '& $job': {
            color: '#ffef9f'
        }
    }
}));

const customThemeTooltip = createTheme({
    overrides: {
        MuiTooltip: {
            tooltip: {
                fontSize: '14px'
            }
        }
    }
})

const RoutesInDate = props => {

    const classes = useStyles();
    const { routeId, isCommercial, jobsData, viewType, status, distance, color, contact } = props;
    const dispatch = useDispatch();

    const [{ isDragging }, drag] = useDrag({
        item: {
            type: 'route',
            routeId
        },
        collect: (monitor) => ({
            isDragging: monitor.isDragging()
        })
    });

    function handleMergeCities(city) {
        if(city && city.length > 0) {
            var arrayCity = city.split(',').map(element => element.trim());            
            var newValue = [];
            arrayCity.map((e, i) => {
                if(arrayCity.filter(function(item){ return item === e }).length > 1) {
                    var value = e + " (x"+ arrayCity.filter(function(item){ return item === e }).length +")";
                } else {
                    var value = e;
                }
                if(e != "" && newValue.indexOf(value) === -1) {
                    newValue.push(value);
                }
            });
            return ', '+(newValue && newValue.length > 0 ? newValue.join(', ') : "");
        }
        return city;
    }

    const getInforJobs = () => {
        let city = '';
        let price = 0;

        if (jobsData && jobsData.length > 0) {
            jobsData.map(js => {
                const pr = js.job ? js.job.price : 0
                price += pr;

                city += js.job && js.job.city ? `, ${js.job.city}` : '';
            });
            price = parseFloat(price).toFixed(2);
            const d = distance != null ? parseFloat(distance.toFixed(2)) + ' <b>Miles</b>\n' : '';
            const a = contact && distance != null && contact.RedMiles && contact.RedMiles > 0 && parseFloat(distance.toFixed(2)) > 0 && ((contact.RedMiles * 2) < parseFloat(distance.toFixed(2))) ? "~" : "";
            return `${a}${d}<b>$${price}</b>${handleMergeCities(city)}`;

        }

        return '';
    }
   const createMarkup= (html) =>{
        return {__html: html};
      }

    const inforString = getInforJobs();

    const isCustomerService = useMemo(() => jobsData?.some(d => d?.job?.isCustomerService), [jobsData]);

    useEffect(() => {
        dispatch({ type: 'CHANGE_STATUS_JOB', status: isDragging });
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isDragging]);

    if (viewType === 'month') {
        return (
            <MuiThemeProvider theme={customThemeTooltip}>
                <div className={classes.widthBox}>
                    <div ref={drag} className={`${classes.ellipsisSpan} ${isCustomerService ? classes.customerService : null} ${color ? classes[color] : (isCommercial ? classes.bgPurple :classes.bgGreen)}`} style={{ visibility: isDragging ? 'hidden' : 'visible'}}>
                        <StatusRoute status={status} />
                        <Tooltip title={<span style={{ whiteSpace: 'pre-line' }} dangerouslySetInnerHTML={createMarkup(inforString)}></span>} arrow>
                            <span className={classes.infoText} dangerouslySetInnerHTML={createMarkup(inforString)}></span>
                        </Tooltip>
                    </div>
                </div>
            </MuiThemeProvider>
        )
    } else if (viewType === 'week') {
        return (
            <div className={classes.widthBox}>
                <div ref={drag} className={`${classes.ellipsisSpan} ${isCustomerService ? classes.customerService : null} ${color ? classes[color] :(isCommercial ? classes.bgPurple : classes.bgGreen)}`} style={{ visibility: isDragging ? 'hidden' : 'visible'}}>
                    <StatusRoute status={status} />
                    <div>
                        <span className={classes.infoText2} dangerouslySetInnerHTML={createMarkup(inforString)}></span>
                        {jobsData.map(j => (
                            <Typography key={j.jobId} className={classes.job}>
                                {`$${j.job && j.job.price} - ${moment(j.jobStart).format('hh A')} - ${moment(j.jobEnd).format('hh A')} - ${j.job && j.job.city || ''}`}
                            </Typography>
                        ))}
                        {jobsData.length === 0 && (
                            <Typography className={classes.job} variant='h6'>
                                $0.00
                            </Typography>
                        )}
                    </div>
                </div>
            </div>
        )
    }
}

RoutesInDate.propTypes = {
    isCommercial: PropTypes.bool,
    jobsData: PropTypes.array,
    viewType: PropTypes.string,
    status: PropTypes.array,
}

export default RoutesInDate;
