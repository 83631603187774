import React from "react";
import {checkMultiRoles} from "common/permissions";
import localStorage from "utils/localStorage";
import {NoAccessPage} from "./NoAccessPage";

/**
 * AccessControl takes a requiredPermission. Optionally you can pass the
 * redirect flag to show a NoAccess Page.
 **/
export const AccessControl = ({ requiredPermission, redirect, children }) => {
  const userRoles = localStorage.getUserRoles();
    const allowed = checkMultiRoles(userRoles, requiredPermission);

  return (
    <>
      {allowed && children}
      {!allowed && redirect && <NoAccessPage />}
    </>
  );
};
