import React, { useEffect, useState, useRef } from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/styles';
import clsx from 'clsx';
import { useHistory, useLocation } from 'react-router-dom'
import { Formik } from 'formik'
import * as Yup from 'yup'
import { AvatarEditor, ConfirmModal, FormErrorCallback, GreenCheckbox, AddressInput } from 'components';
import {
    Avatar, Button,
    Card, CardActions, CardContent, CardHeader, Collapse,
    colors, Divider, FormControlLabel,
    Grid, IconButton, TextField, Tooltip, Typography
} from '@material-ui/core'
import useMediaQuery from '@material-ui/core/useMediaQuery'
import NumberFormat from 'react-number-format'
import axios from 'utils/axios';
import apiConfig from 'apiConfig';
import Snackbar from '@material-ui/core/Snackbar';
import Alert from '@material-ui/lab/Alert';
import CircularProgress from '@material-ui/core/CircularProgress';
import ModalNotice from "./components/ModalNotice";
import DeleteIcon from "@material-ui/icons/Delete";
import { createTheme, MuiThemeProvider } from "@material-ui/core/styles";
import { AddBox, ExpandMore } from "@material-ui/icons";
import localStorage from "utils/localStorage";
import { errorMessages } from 'common/constants'

const useStyles = makeStyles(theme => ({
    root: {
        alignItems: 'flex-start'
    },
    cardProfile: {
        width: '100%',
        position: 'relative',
        '& *': {
            transition: theme.transitions.create('all', {
                duration: theme.transitions.duration.shortest,
            })
        }
    },
    cardCollapse: {
        '& $cardContent': {
            paddingTop: theme.spacing(0.75),
            paddingBottom: theme.spacing(0.75),
            height: theme.spacing(6.5),
            maxWidth: `calc(100% - ${theme.spacing(6)}px)`,
            overflow: 'hidden'
        },
        '& $profileName': {
            marginBottom: -41,
            transform: 'translateY(-51px)',
            minWidth: 0,
            whiteSpace: 'nowrap',
            marginLeft: theme.spacing(7)
        },
        '& $hoverAvatar': {
            marginRight: theme.spacing(4)
        }
    },
    cardContent: {
        height: 'calc(100% - 53.5px)'
    },
    expandAvatarIcon: {
        position: 'absolute',
        right: 0,
        boxShadow: '-10px 0 10px 0 white',
        zIndex: 10
    },
    profileName: {
        display: 'inline-block',
        fontSize: '20px',
        fontWeight: 500,
        textAlign: 'center',
        marginTop: '20px',
        width: 'auto',
        minWidth: '100%'
    },
    hoverAvatar: {
        '&:hover': {
            cursor: 'pointer'
        }
    },
    bigAvatar: {
        width: theme.spacing(20),
        height: theme.spacing(20),
        marginLeft: `calc((100% - ${theme.spacing(20)}px) / 2)`
    },
    alignRightAvatar: {
        display: 'flex',
        justifyContent: 'flex-end'
    },
    marginTopOfProgress: {
        marginTop: theme.spacing(2)
    },
    colorUploadBtn: {
        color: theme.palette.purple
    },
    btnBox: {
        display: 'flex',
        alignItems: "center",
        justifyContent: 'flex-end'
    },
    saveBtn: {
        color: theme.palette.white,
        backgroundColor: colors.green[600],
        '&:hover': {
            backgroundColor: colors.green[900]
        }
    },
    snackBar: {
        width: '100%',
        '& > * + *': {
            marginTop: theme.spacing(2),
        },
    },
    circularProgress: {
        marginTop: '30px',
    },
    groupDatePicker: {
        position: 'relative'
    },
    iconDatePicker: {
        position: 'absolute',
        right: '16px',
        top: theme.spacing(2),
    },
    borderCell: {
        borderBottom: '1px solid #b9b9b9'
    },
    stickyBtn: {
        marginBottom: 0,
        position: '-webkit-sticky',
        position: 'sticky',
        backgroundColor: '#f4f6f8',
        top: '-13px',
        zIndex: 2,
    },
    phoneRow: {
        display: 'flex',
        alignItems: 'center'
    },
    checkbox: {
        margin: 0,
        '& .MuiIconButton-root': {
            padding: 0
        },
        '& .MuiTypography-root': {
            whiteSpace: 'nowrap'
        }
    },
    addIcon: {
        padding: '0',
        '& span svg': {
            fontSize: '40px',
            color: colors.green[600],
            '&:hover': {
                color: colors.green[900]
            }
        }
    },
    DeleteIcon: {
        cursor: 'pointer'
    },
    jobItem: {
        position: 'relative',
        marginTop: 16,
        border: '1px solid #dadada'
    },
    expandIcon: {
        transition: 'all 200ms ease-in',
        cursor: 'pointer'
    },
    contactBtn: {
        paddingBottom: '0'
    },
    postQuickBooks: {
        margin: '-12px auto'
    },
    cardHeaderAction: {
        margin: "auto"
    }
}));

const customThemeTooltip = createTheme({
    overrides: {
        MuiTooltip: {
            tooltip: {
                fontSize: '14px',
                maxWidth: 'none'
            }
        }
    }
})
const customerSchema = Yup.object().shape({
    companyName: Yup.string().required('This field is required.'),
    // firstName: Yup.string()
    //     .nullable().required('This field is required.'),
    // lastName: Yup.string()
    //     .nullable().required('This field is required.'),
    contacts: Yup.array().nullable()
        .of(Yup.object({
            firstName: Yup.string()
                .nullable(),
            lastName: Yup.string()
                .nullable(),
            email: Yup.string()
                .nullable().email('Email is invalid.'),
            phones: Yup.array().of(Yup.object({
                phoneNumber: Yup.string()
                    .matches(/\W\d{3}\W\s\w{3}\W\w{4}$|\W\d{3}\W\s\w{3}\W\w{4}x\d{1,6}|\W\d{3}\W\s\w{3}\W\w{4}x/, 'Invalid phone number.')
            }))
        })),
    // email: Yup.string()
    //     .nullable().required('This field is required.').email('Email is invalid.'),
    address: Yup.object({
        name: Yup.string()
            .nullable(),
        address1: Yup.string()
            .nullable().required('This field is required.'),
        city: Yup.string()
            .nullable().required('This field is required.'),
        zipCode: Yup.string()
            .nullable()
            .required('This field is required.')
            .matches(/(^\d{5}$)|(^\d{5}-\d{4}$)/, 'Zip code is invalid.')
    }),
    qboTaxExempt: Yup.string().nullable().when('isTaxExempt', {
        is: true,
        then: Yup.string().required('This field is required.')
    })
})

const Information = props => {

    const classes = useStyles();
    const mounted = useRef(false);
    const history = useHistory();
    const { pathname } = useLocation();

    const xs = useMediaQuery(theme => theme.breakpoints.down('xs'));
    const ipPlus = useMediaQuery('(max-width: 769px)');
    const mobile = useMediaQuery('(max-width: 960px)');

    const { customerId, className, ...rest } = props;

    const addressInputRef = useRef();

    const [openAvatarModal, setOpenAvatarModal] = useState(false);
    const [openConfirm, setOpenConfirm] = useState(false);
    const [openSnackbar, setOpenSnackBar] = useState(false);
    const [isSaving, setIsSaving] = useState(false);
    const [isGenerating, setIsGenerating] = useState(false);
    const [isStatus, setIsStatus] = useState({ failed: false, msg: '' });
    const [reload, setReload] = useState(false);
    const [stateBlur, setStateBlur] = useState(false);
    const [customer, setCustomer] = useState();
    const [taxGroups, setTaxGroups] = useState();
    const [expand, setExpand] = useState([]);
    const [expandAvatar, setExpandAvatar] = useState(false);
    const [sources, setSources] = useState([]);
    const [company, setCompany] = useState();
    const [taxExempt, setTaxExempt] = useState([]);
    const [isTaxExempt, setIsTaxExempt] = useState(false);

    const cId = localStorage.getCompanyId();
    /// upload profile
    const onUploadAvatar = () => {
        setOpenAvatarModal(true);
    }
    const onGetAvatar = () => {
        setReload(!reload);
    }

    const updateCloseModal = () => {
        const data = { ...customer, showNoteOnOpen: false }
        const payload = {
            id: customer.id,
            customerNote: customer.customerNote,
            routeNote: customer.routeNote,
            showNoteOnOpen: false
        }
        axios.post(apiConfig.url.CUSTOMER_URL + apiConfig.url.CUSTOMERS_UPDATE_SHOW_NOTE, payload)
            .then(res => {
                setCustomer(data)
            })
            .catch(err => {
            })
            .finally();
    }

    const addContact = (values, setFieldValue) => {
        const contacts = [...values.contacts]
        const contact = {
            id: "00000000-0000-0000-0000-000000000000",
            isPrimary: contacts.length === 0,
            firstname: "",
            lastname: "",
            email: "",
            phones: [
                {
                    id: "00000000-0000-0000-0000-000000000000",
                    formattedPhoneNumber: "",
                    phoneNumber: "",
                    type: null,
                    typeId: customer.phoneTypes[0].id,
                    isSendText: true
                }
            ]
        }
        contacts.push(contact);
        setExpand([...expand, contacts.length - 1]);
        setFieldValue('contacts', contacts);
    }
    // phones
    const addPhone = (values, index, setFieldValue) => {
        const phone = {
            id: "00000000-0000-0000-0000-000000000000",
            formattedPhoneNumber: "",
            phoneNumber: "",
            type: null,
            typeId: customer.phoneTypes[0].id,
            isSendText: values.contacts[index].phones.length === 0 ? true : false
        }

        const contacts = [...values.contacts]
        contacts[index].phones.push(phone);
        setFieldValue('contacts', contacts);
        setExpand([...expand, index])
    };
    const handlePhoneChange = (event, values, setFieldValue, index, indexContact) => {
        const { name, value } = event.target;
        const phones = [...values.contacts[indexContact].phones];
        event.target.type === 'checkbox' ?
            phones[index] = { ...phones[index], [name]: event.target.checked }
            :
            phones[index] = { ...phones[index], [name]: value?.trim() }
        const contact = [...values.contacts]
        contact[indexContact].phones = phones
        setFieldValue('contacts', contact);
    }
    const removePhone = (values, indexContact, index, setFieldValue) => {
        const contacts = [...values.contacts]
        contacts[indexContact].phones.splice(index, 1);
        setFieldValue('contacts', contacts);
    }
    const removeContact = (values, indexContact, setFieldValue) => {
        const contacts = [...values.contacts]
        contacts.splice(indexContact, 1);
        setFieldValue('contacts', contacts);
    }

    const closeSnackbar = () => {
        setOpenSnackBar(false);
    };

    const onExpand = (index) => {
        if (expand.includes(index)) {
            const exp = [...expand];
            exp.splice(exp.indexOf(index), 1);
            setExpand(exp)
        } else {
            setExpand([...expand, index])
        }
    }

    const updateCustomer = data => {
        const { companyName } = data;
        return axios.post(apiConfig.url.CUSTOMER_URL + apiConfig.url.CUSTOMERS_UPDATE, data)
            .then(res => {
                setIsStatus({ failed: false, msg: 'Save successfully.' });
                setOpenSnackBar(true);
                setReload(!reload)
                // change URL after update success
                const pathSplit = pathname.split('/');
                pathSplit[2] = encodeURIComponent(companyName);
                history.replace(pathSplit.join('/'))
            })
            .catch(err => {
                setIsStatus({ failed: true, msg: 'An error occurred, please try again later.' });
                setOpenSnackBar(true);
            })
            .finally(e => setIsSaving(false));
    }

    const generateQBName = (setFieldValue) => {
        setIsGenerating(true);
        axios.get(apiConfig.url.CUSTOMER_URL + apiConfig.url.CUSTOMERS_GENERATE_QB_NAME, { params: { customerId } })
            .then(res => {
                setIsStatus({ failed: false, msg: 'Generate successfully.' });
                setOpenSnackBar(true);
                setOpenConfirm(false);
                setFieldValue('qbName', res.data.qbName);
            })
            .catch(err => {
                setIsStatus({ failed: true, msg: 'An error occurred, please try again later.' });
                setOpenSnackBar(true);
            })
            .finally(e => setIsGenerating(false));
    }

    const handlePostToQuickbooksChange = (setFieldValue, value) => {
        setFieldValue('postToQuickbooks', value);
        if (value) {
            axios.post(apiConfig.url.BASE_URL + apiConfig.url.QBO_EXPORT_CUSTOMER + customerId)
                .then(res => {
                    console.log(res);
                })
                .catch(err => {
                    setIsStatus({ failed: true, msg: 'An error occurred, please try again later.' });
                    setOpenSnackBar(true);
                });
        }
    };

    
    useEffect(() => {
        mounted.current = true;
        axios.get(apiConfig.url.CUSTOMER_URL + apiConfig.url.CUSTOMERS_GET + customerId)
            .then(res => {
                if (mounted.current) {
                    if (!res.data.contacts || !res.data.contacts[0] || res.data.contacts[0].length == 0) {
                        res.data.contacts = [];
                        const contact = {
                            id: "00000000-0000-0000-0000-000000000000",
                            isPrimary: res.data.contacts.length === 0,
                            firstname: "",
                            lastname: "",
                            email: "",
                            phones: [
                                {
                                    id: "00000000-0000-0000-0000-000000000000",
                                    formattedPhoneNumber: "",
                                    phoneNumber: "",
                                    type: null,
                                    typeId: res.data.phoneTypes[0].id,
                                    isSendText: true
                                }
                            ]
                        }
                        res.data.contacts.push(contact);
                    }else if (!res.data.contacts[0].phones[0] || res.data.contacts[0].phones[0].length == 0) {
                            res.data.contacts[0].phones[0] = {
                                id: "00000000-0000-0000-0000-000000000000",
                                formattedPhoneNumber: "",
                                phoneNumber: "",
                                type: null,
                                typeId: res.data.phoneTypes[0].id,
                                isSendText: true,
                            }
                    }

                    res.data.contacts.forEach(contact => contact.phones.forEach(phone => phone.phoneNumber = phone.formattedPhoneNumber));
                    res.data.isTaxExempt = res.data.qboTaxExempt && res.data.qboTaxExempt != 0 ? true : false;
                    setIsTaxExempt(res.data.isTaxExempt)
                    setCustomer(res.data);
                    setTaxGroups(res.data.taxgroups);
                }
            })
        axios.get(apiConfig.url.BASE_URL + apiConfig.url.COMPANY_INFO + cId)
            .then(res => {
                if (mounted.current) {
                    setCompany(res.data);
                }
            })
        
        axios.get(apiConfig.url.BASE_URL + apiConfig.url.QBO_GET_TAXEXEMPT)
            .then(res => {
                if (mounted.current) {
                    setTaxExempt(res.data);
                }
            })

        axios.get(apiConfig.url.BASE_URL + apiConfig.url.COMPANY_INTEGRATIONS_SOURCE.replace('{companyId}', cId), {
            params: { pageSize: 9999 }
        }).then(res => {
                if (mounted.current) {
                    res.data.data.map((item) => {
                        item.touched = {}
                    })
                    res.data.data = res.data.data.sort((a, b) => (a.description ?? '').localeCompare(b.description ?? ''))
                    setSources(res.data.data);
                }
            })
        return () => mounted.current = false;
    }, [reload])

    if (!customer || !taxGroups) {
        return (
            <Grid
                container
                spacing={0}
                align="center"
                justifyContent="center"
                direction="column">
                <Grid item>
                    <CircularProgress className={classes.circularProgress} size={50} />
                </Grid>
            </Grid>
        )
    }

    return (
        <>
            <Formik
                enableReinitialize
                initialValues={customer}
                validationSchema={customerSchema}
                onSubmit={values => {
                    if (stateBlur) {
                        return;
                    }
                    setIsSaving(true);
                    const address = { ...values.address, AddressType: 1 }
                    values = { ...values, address }
                    const { coordinate, isValid, forceSave, verifyFn } = addressInputRef.current;
                    if (isValid || forceSave) {
                        values = {
                            ...values,
                            address: {
                                ...values.address,
                                latitude: coordinate?.latitude,
                                longitude: coordinate?.longitude
                            }
                        };
                        updateCustomer(values)
                    } else {
                        verifyFn(true).then(data => data ? updateCustomer(data) : setIsSaving(false))
                    }
                }}
            >
                {({
                    values,
                    errors,
                    touched,
                    submitCount,
                    isValid,
                    setFieldValue,
                    setFieldTouched,
                    submitForm
                }) => (
                    <form>
                        <FormErrorCallback
                            submitCount={submitCount}
                            isValid={isValid}
                            onSubmissionError={() => {
                                setIsStatus({ failed: true, msg: errorMessages.FIELD_CHECK });
                                setOpenSnackBar(true);
                                const indexArray = errors.contacts && errors.contacts.map((c, i) => {
                                    if (c instanceof Object) return i;
                                }) || [];
                                setExpand(indexArray);
                            }}
                        />
                        <Grid className={classes.stickyBtn} container spacing={4}>
                            <Grid item xs={12}>
                                <CardActions>
                                <Button
                                    onClick={() => {
                                        if (!values.address.state || !values.address.state.name) {
                                            setStateBlur(true)
                                        }
                                        submitForm()
                                    }}
                                    className={classes.saveBtn}
                                    disabled={isSaving}
                                    variant="contained">
                                    Save
                                </Button>
                                {isSaving && <CircularProgress size={24} />}
                                </CardActions>
                            </Grid>
                        </Grid>

                        <Grid container className={clsx(classes.root, className)} spacing={4}>
                            <Grid container item md={12} lg={3}>
                                <Card className={clsx(classes.cardProfile, !xs || expandAvatar ? null : classes.cardCollapse)}>
                                    {
                                        xs && <IconButton className={classes.expandAvatarIcon}>
                                            <ExpandMore onClick={() => setExpandAvatar(!expandAvatar)}
                                                        style={{transform: expandAvatar ? 'rotate(180deg)' : 'rotate(0deg)'}}/>
                                        </IconButton>
                                    }
                                    <CardContent className={classes.cardContent}>
                                        <div className={classes.hoverAvatar} onClick={onUploadAvatar}>
                                            <Avatar
                                                variant='circular'
                                                src={customer.avatar ? customer.avatar : ''}
                                                alt="avatar"
                                                className={!xs || expandAvatar ? classes.bigAvatar : null}
                                            />
                                        </div>
                                        <Typography className={classes.profileName}>
                                            {customer.companyName}
                                        </Typography>
                                    </CardContent>
                                    <Collapse in={!xs || expandAvatar} timeout="auto" unmountOnExit>
                                        <Divider />
                                        <CardActions>
                                            <Button style={{ width: '100%' }} onClick={onUploadAvatar}>
                                                upload avatar
                                            </Button>
                                        </CardActions>
                                    </Collapse>
                                </Card>
                            </Grid>
                            <Grid container item md={12} lg={9}>
                                <Card style={{width: '100%'}}>
                                    <CardHeader title="Information" 
                                    classes={{
                                        action: classes.cardHeaderAction
                                      }}
                                    action={
                                        <FormControlLabel
                                            style={{ marginTop: '0px' }}
                                            className={classes.checkbox}
                                            control={<GreenCheckbox />}
                                            label='Archived'
                                            checked={values.isDeleted || false}
                                            onChange={e => setFieldValue('isDeleted', e.target.checked)}
                                        />
                                      }
                                      />
                                    <Divider />
                                    <CardContent style={{ paddingBottom: 0 }}>
                                        <Grid
                                            container
                                            spacing={3}>
                                            <Grid item md={4} xs={12}>
                                                <TextField
                                                    fullWidth
                                                    label="Customer Type"
                                                    name="customerTypes"
                                                    onChange={event => {
                                                        setFieldValue('customerType', event.target.value)
                                                    }}
                                                    select
                                                    value={values.customerType || ''}
                                                    SelectProps={{ native: true }}
                                                    variant="outlined">
                                                    <option value='Residential'>Residential</option>
                                                    <option value='Commercial'>Commercial</option>
                                                </TextField>
                                            </Grid>
                                            <Grid item md={8} xs={12}>
                                                <TextField
                                                    fullWidth
                                                    error={errors.companyName && touched.companyName}
                                                    helperText={(errors.companyName && touched.companyName) && errors.companyName}
                                                    label={values.customerType === 'Commercial' ? 'Company Name' : 'Customer Name'}
                                                    name="companyName"
                                                    onBlur={() => setFieldTouched('companyName')}
                                                    onChange={event => {
                                                        setFieldValue('companyName', event.target.value)
                                                        const address = {
                                                            ...values.address,
                                                            name: event.target.value
                                                        };
                                                        setFieldValue('address', address)
                                                    }}
                                                    value={values.companyName || ''}
                                                    variant="outlined" />
                                            </Grid>
                                        </Grid>
                                        <Grid container spacing={3} style={{ marginBottom: '16px' }}>
                                            <Grid item xs={12}>
                                                <Typography style={{ fontWeight: 500, fontSize: '16px' }}>Billing Address</Typography>
                                            </Grid>
                                            <Grid item xs={12}>
                                                <TextField
                                                    error={
                                                        errors.address &&
                                                        errors.address.name &&
                                                        touched.address &&
                                                        touched.address.name
                                                    }
                                                    helperText={(
                                                        errors.address &&
                                                        errors.address.name &&
                                                        touched.address &&
                                                        touched.address.name
                                                    ) && errors.address.name}
                                                    fullWidth
                                                    label={'Addressee Name'}
                                                    name='name'
                                                    variant='outlined'
                                                    onBlur={() => {
                                                        const address = touched.address ? { ...touched.address } : {};
                                                        address.name = true;
                                                        setFieldTouched('address', address);
                                                    }}
                                                    onChange={event => {
                                                        const address = {
                                                            ...values.address,
                                                            name: event.target.value
                                                        };
                                                        setFieldValue('address', address)
                                                    }}
                                                    value={values.address && values.address.name || ''}
                                                />
                                            </Grid>
                                            <Grid item xs={12}>
                                                <AddressInput
                                                    ref={addressInputRef}
                                                    errors={errors}
                                                    values={values}
                                                    touched={touched}
                                                    setFieldValue={setFieldValue}
                                                    setFieldTouched={setFieldTouched}
                                                />
                                            </Grid>
                                        </Grid>
                                    </CardContent>
                                </Card>

                                <Card style={{ marginTop: '16px', width: '100%' }}>
                                    {
                                        values.contacts.map((item, indexContact) => (
                                            <React.Fragment key={`cus-contact-${indexContact}`}>
                                                {
                                                    indexContact < 2 &&
                                                    <CardHeader
                                                        title={item.isPrimary ? 'Primary Contact' : indexContact === 1 ? 'Additional Contacts' : ''}
                                                        action=
                                                        {
                                                            item.isPrimary &&
                                                            <CardActions className={classes.contactBtn}>
                                                                <Button
                                                                    onClick={() => addContact(values, setFieldValue)}
                                                                    color="primary"
                                                                    variant="outlined">
                                                                    Add Contact
                                                                </Button>
                                                            </CardActions>
                                                        }
                                                    />
                                                }
                                                <Divider />
                                                <CardContent >
                                                    <div style={{ position: 'relative', display: "flex" }}>
                                                        { !ipPlus && <div style={{
                                                            width: "0px",
                                                            order: '1',
                                                            display: 'flex',
                                                            alignItems: ipPlus ? 'flex-start' : 'center',
                                                            justifyContent: 'center'
                                                        }}>
                                                        </div>}
                                                        <div style={{flex: '1', order: '2'}}>
                                                            <Grid container spacing={3} >
                                                                {ipPlus && indexContact != 0 &&
                                                                <Grid item xs={12} style={{textAlign: 'right'}}>
                                                                    <MuiThemeProvider theme={customThemeTooltip}>
                                                                        <Tooltip arrow title={'Remove Contact'}>
                                                                            <DeleteIcon
                                                                                className={classes.DeleteIcon}
                                                                                onClick={() => removeContact(values, indexContact, setFieldValue)}
                                                                                // color="primary"
                                                                                variant="outlined"
                                                                            />
                                                                        </Tooltip>
                                                                    </MuiThemeProvider>
                                                                </Grid>
                                                                }
                                                                <Grid item md={4} xs={12}>
                                                                    <TextField
                                                                        fullWidth
                                                                        error={
                                                                            errors.contacts &&
                                                                            errors.contacts[indexContact] &&
                                                                            errors.contacts[indexContact].firstName &&
                                                                            touched.contacts &&
                                                                            touched.contacts[indexContact] ?
                                                                                true : false
                                                                        }
                                                                        helperText={
                                                                            (
                                                                                errors.contacts &&
                                                                                errors.contacts[indexContact] &&
                                                                                errors.contacts[indexContact].firstName &&
                                                                                touched.contacts &&
                                                                                touched.contacts[indexContact]
                                                                            )
                                                                            &&
                                                                            errors.contacts[indexContact].firstName
                                                                        }
                                                                        label="First Name"
                                                                        name="firstName"
                                                                        onBlur={() => setFieldTouched(`contacts.[${indexContact}].firstName`)}
                                                                        onChange={event => {
                                                                            values.contacts[indexContact] = {
                                                                                ...values.contacts[indexContact],
                                                                                firstName: event.target.value
                                                                            }
                                                                            setFieldValue('contacts', values.contacts)
                                                                        }}
                                                                        value={item.firstName || ''}
                                                                        variant="outlined" />
                                                                </Grid>
                                                                <Grid item md={4} xs={12}>
                                                                    <TextField
                                                                        fullWidth
                                                                        error={
                                                                            errors.contacts &&
                                                                            errors.contacts[indexContact] &&
                                                                            errors.contacts[indexContact].lastName &&
                                                                            touched.contacts &&
                                                                            touched.contacts[indexContact] ?
                                                                                true : false
                                                                        }
                                                                        helperText={
                                                                            (
                                                                                errors.contacts &&
                                                                                errors.contacts[indexContact] &&
                                                                                errors.contacts[indexContact].lastName &&
                                                                                touched.contacts &&
                                                                                touched.contacts[indexContact]
                                                                            )
                                                                            &&
                                                                            errors.contacts[indexContact].lastName
                                                                        }
                                                                        label="Last Name"
                                                                        name="lastName"
                                                                        onBlur={() => setFieldTouched(`contacts.[${indexContact}].lastName`)}
                                                                        onChange={event => {
                                                                            values.contacts[indexContact] = {
                                                                                ...values.contacts[indexContact],
                                                                                lastName: event.target.value
                                                                            }
                                                                            setFieldValue('contacts', values.contacts)
                                                                        }}
                                                                        value={item.lastName || ''}
                                                                        variant="outlined" />
                                                                </Grid>
                                                                {ipPlus && item.phones.length > 1 &&
                                                                <Grid item xs={2} style={{paddingLeft: '24px', display: 'flex', alignItems: 'center'}}>
                                                                    {item.phones.length > 1 &&
                                                                    <ExpandMore className={classes.expandIcon}
                                                                                onClick={() => onExpand(indexContact)}
                                                                                style={{transform: expand.includes(indexContact) ? 'rotate(180deg)' : 'rotate(0deg)'}}/>}
                                                                </Grid>
                                                                }
                                                                <Grid item md={4} xs={item.phones.length > 1 ? 10 : 12}>
                                                                    <TextField
                                                                        style={{width: indexContact === 0 ? '100%' : ipPlus ? '100%' : 'calc(100% - 50px)'}}
                                                                        fullWidth
                                                                        error={
                                                                            errors.contacts &&
                                                                            errors.contacts[indexContact] &&
                                                                            errors.contacts[indexContact].email &&
                                                                            touched.contacts &&
                                                                            touched.contacts[indexContact] ?
                                                                                true : false
                                                                        }
                                                                        helperText={
                                                                            (
                                                                                errors.contacts &&
                                                                                errors.contacts[indexContact] &&
                                                                                errors.contacts[indexContact].email &&
                                                                                touched.contacts &&
                                                                                touched.contacts[indexContact]
                                                                            )
                                                                            &&
                                                                            errors.contacts[indexContact].email
                                                                        }
                                                                        label="Email"
                                                                        name="email"
                                                                        onBlur={() => setFieldTouched(`contacts.[${indexContact}].email`)}
                                                                        onChange={event => {
                                                                            values.contacts[indexContact] = {
                                                                                ...values.contacts[indexContact],
                                                                                email: event.target.value
                                                                            }
                                                                            setFieldValue('contacts', values.contacts)
                                                                        }}
                                                                        value={item.email || ''}
                                                                        variant="outlined" />
                                                                        { !ipPlus &&
                                                                        <div  style={{
                                                                            width: indexContact === 0 ? '0px' : '50px',
                                                                            height: '100%',
                                                                            order: '3',
                                                                            display: 'inline-flex',
                                                                            alignItems: 'center',
                                                                            justifyContent: 'center'
                                                                        }}>
                                                                            {
                                                                                indexContact !== 0 &&
                                                                                <MuiThemeProvider theme={customThemeTooltip}>
                                                                                    <Tooltip arrow title={'Remove Contact'}>
                                                                                        <DeleteIcon
                                                                                            className={classes.DeleteIcon}
                                                                                            onClick={() => removeContact(values, indexContact, setFieldValue)}
                                                                                            // color="primary"
                                                                                            variant="outlined"
                                                                                        />
                                                                                    </Tooltip>
                                                                                </MuiThemeProvider>
                                                                            }
                                                                        </div>
                                                                        }

                                                                </Grid>
                                                            </Grid>
                                                        </div>

                                                    </div>
                                                    <div style={{ position: 'relative', display: "flex" }}>
                                                        <div style={{flex: '1', order: '2'}}>
                                                            <Grid container spacing={3} style={{ paddingTop: '24px' }}>
                                                                {
                                                                    item.phones && item.phones.length > 0 &&
                                                                    [item.phones[0]].map((phone, index) => (
                                                                    <React.Fragment key={`cus-contact-phone-${index}`}>
                                                                        <Grid xs={ipPlus ? 8 : 4} style={{display: 'flex', padding: '12px'}}>
                                                                            { !ipPlus && <div style={{
                                                                                width: item.phones.length > 1 ? "50px" : '0px',
                                                                                // order: '1',
                                                                                display: 'flex',
                                                                                alignItems: ipPlus ? 'flex-start' : 'center',
                                                                                justifyContent: 'center'
                                                                            }}>
                                                                                {item.phones.length > 1
                                                                                && <ExpandMore className={classes.expandIcon} onClick={() => onExpand(indexContact)}
                                                                                                                       style={{ transform: expand.includes(indexContact) ? 'rotate(180deg)' : 'rotate(0deg)' }} />}
                                                                            </div>}
                                                                        {!ipPlus
                                                                            ?<div style={{width: '100px'}}>
                                                                                <FormControlLabel
                                                                                    className={classes.checkbox}
                                                                                    name='isSendText'
                                                                                    labelPlacement="top"
                                                                                    control={<GreenCheckbox />}
                                                                                    label="Send Text"
                                                                                    checked={phone.isSendText}
                                                                                    onChange={e => handlePhoneChange(e, values, setFieldValue, index, indexContact,)}
                                                                                />
                                                                            </div>
                                                                            : null }
                                                                        <div style={{width: '-webkit-fill-available'}}>
                                                                            <TextField
                                                                                fullWidth
                                                                                label="Phone Type"
                                                                                name="typeId"
                                                                                select
                                                                                SelectProps={{ native: true }}
                                                                                variant="outlined"
                                                                                value={phone.typeId || ''}
                                                                                onChange={e => handlePhoneChange(e, values, setFieldValue, index, indexContact)}>
                                                                                {customer.phoneTypes.map(pt => (
                                                                                    <option key={pt.id} value={pt.id}>
                                                                                        {pt.type}
                                                                                    </option>
                                                                                ))}
                                                                            </TextField>
                                                                        </div>
                                                                        </Grid>
                                                                        {ipPlus ? <Grid item xs={ipPlus ? 4 : 1} style={{paddingLeft: ipPlus ? '10px' : 'initial'}}>
                                                                            <FormControlLabel
                                                                                className={classes.checkbox}
                                                                                name='isSendText'
                                                                                labelPlacement="top"
                                                                                control={<GreenCheckbox />}
                                                                                label="Send Text"
                                                                                checked={phone.isSendText}
                                                                                onChange={e => handlePhoneChange(e, values, setFieldValue, index, indexContact,)}
                                                                            />
                                                                            </Grid>
                                                                            : null }
                                                                        <Grid item xs={ipPlus ? 8 : 4}>
                                                                            <NumberFormat
                                                                                customInput={TextField}
                                                                                format="(###) ###-####x######"
                                                                                fullWidth
                                                                                error={
                                                                                    errors.contacts?.[indexContact]?.phones?.[index]?.phoneNumber &&
                                                                                    touched.contacts?.[indexContact]?.phones?.[index]?.phoneNumber
                                                                                }
                                                                                helperText={(
                                                                                    errors.contacts?.[indexContact]?.phones?.[index]?.phoneNumber &&
                                                                                    touched.contacts?.[indexContact]?.phones?.[index]?.phoneNumber
                                                                                ) &&
                                                                                errors.contacts[indexContact].phones[index].phoneNumber
                                                                                }
                                                                                label="Phone Number"
                                                                                name="phoneNumber"
                                                                                onBlur={() => setFieldTouched(`contacts.[${indexContact}].phones[${index}].phoneNumber`)}
                                                                                // onBlur={() => {
                                                                                //     const phones = touched.phones ? [...touched.phones] : [];
                                                                                //     phones[index] = {phoneNumber: true};
                                                                                //     setFieldTouched('phones', phones);
                                                                                // }}
                                                                                variant="outlined"
                                                                                value={phone.phoneNumber || ''}
                                                                                onChange={e => handlePhoneChange(e, values, setFieldValue, index, indexContact)}
                                                                            />
                                                                        </Grid>
                                                                        <Grid item xs={ipPlus ? 4 : 4} style={{paddingLeft: ipPlus ? '12px' : '0'}}>
                                                                            <CardActions className={classes.conButton}>
                                                                                <MuiThemeProvider theme={customThemeTooltip}>
                                                                                    {/*{*/}
                                                                                    {/*    index === item.phones.length - 1 &&*/}
                                                                                        <Tooltip arrow title={'Add Phone'}>
                                                                                            <IconButton
                                                                                                className={classes.addIcon}
                                                                                                onClick={() => {
                                                                                                    addPhone(values, indexContact, setFieldValue)
                                                                                                }}
                                                                                            >
                                                                                                <AddBox />
                                                                                            </IconButton>
                                                                                        </Tooltip>
                                                                                    {/*}*/}
                                                                                    {
                                                                                        index > 0 &&
                                                                                        <Tooltip arrow title={'Remove Phone'}>
                                                                                            <DeleteIcon
                                                                                                className={classes.DeleteIcon}
                                                                                                onClick={() => removePhone(values, indexContact, index, setFieldValue)}
                                                                                            >
                                                                                            </DeleteIcon>
                                                                                        </Tooltip>
                                                                                    }
                                                                                </MuiThemeProvider>
                                                                            </CardActions>
                                                                        </Grid>
                                                                    </React.Fragment>
                                                                ))}
                                                            </Grid>
                                                        </div>
                                                        {
                                                            !ipPlus && <div style={{width: '0px', order: '3'}}>
                                                            </div>
                                                        }
                                                    </div>
                                                    <Collapse in={expand.includes(indexContact)} timeout='auto' unmountOnExit>
                                                        <div style={{ position: 'relative', display: "flex" }}>
                                                            { !ipPlus &&
                                                                expand.includes(indexContact) &&
                                                                <div style={{
                                                                width: "0px",
                                                                order: '1',
                                                                display: 'flex',
                                                                alignItems: ipPlus ? 'flex-start' : 'center',
                                                                justifyContent: 'center'
                                                            }}>
                                                                {/*<ExpandMore className={classes.expandIcon} onClick={() => onExpand(indexContact)}*/}
                                                                {/*            style={{ transform: expand.includes(indexContact) ? 'rotate(180deg)' : 'rotate(0deg)' }} />*/}
                                                            </div>}
                                                            <div style={{flex: '1', order: '2'}}>
                                                                <Grid container spacing={3} style={{ paddingTop: '24px' }}>
                                                                    {
                                                                        item.phones && item.phones.length > 1 && item.phones.map((phone, index) => (
                                                                            index > 0 &&
                                                                            <React.Fragment key={`cus-contact-phone-${index}`}>
                                                                                <Grid xs={ipPlus ? 8 : 4} style={{display: 'flex', padding: '12px'}}>
                                                                                    { !ipPlus && <div style={{
                                                                                        width: "50px",
                                                                                        // order: '1',
                                                                                        display: 'flex',
                                                                                        alignItems: ipPlus ? 'flex-start' : 'center',
                                                                                        justifyContent: 'center'
                                                                                    }}>
                                                                                    </div>}
                                                                                    {!ipPlus
                                                                                        ?<div style={{width: '100px'}}>
                                                                                            <FormControlLabel
                                                                                                className={classes.checkbox}
                                                                                                name='isSendText'
                                                                                                labelPlacement="top"
                                                                                                control={<GreenCheckbox />}
                                                                                                label="Send Text"
                                                                                                checked={phone.isSendText}
                                                                                                onChange={e => handlePhoneChange(e, values, setFieldValue, index, indexContact,)}
                                                                                            />
                                                                                        </div>
                                                                                        : null }
                                                                                    <div style={{width: '-webkit-fill-available'}}>
                                                                                        <TextField
                                                                                            fullWidth
                                                                                            label="Phone Type"
                                                                                            name="typeId"
                                                                                            select
                                                                                            SelectProps={{ native: true }}
                                                                                            variant="outlined"
                                                                                            value={phone.typeId || ''}
                                                                                            onChange={e => handlePhoneChange(e, values, setFieldValue, index, indexContact)}>
                                                                                            {customer.phoneTypes.map(pt => (
                                                                                                <option key={pt.id} value={pt.id}>
                                                                                                    {pt.type}
                                                                                                </option>
                                                                                            ))}
                                                                                        </TextField>
                                                                                    </div>
                                                                                </Grid>
                                                                            {ipPlus
                                                                                ?<Grid item xs={ipPlus ? 4 : 1} style={{paddingLeft: ipPlus ? '10px' : 'initial'}}>
                                                                                    <FormControlLabel
                                                                                        className={classes.checkbox}
                                                                                        name='isSendText'
                                                                                        labelPlacement="top"
                                                                                        control={<GreenCheckbox />}
                                                                                        label="Send Text"
                                                                                        checked={phone.isSendText}
                                                                                        onChange={e => handlePhoneChange(e, values, setFieldValue, index, indexContact,)}
                                                                                    />
                                                                                </Grid>
                                                                                : null }
                                                                            <Grid item xs={ipPlus ? 8 : 4}>
                                                                                <NumberFormat
                                                                                    customInput={TextField}
                                                                                    format="(###) ###-####x######"
                                                                                    fullWidth
                                                                                    error={
                                                                                        errors.contacts?.[indexContact]?.phones?.[index]?.phoneNumber &&
                                                                                        touched.contacts?.[indexContact]?.phones?.[index]?.phoneNumber
                                                                                    }
                                                                                    helperText={(
                                                                                        errors.contacts?.[indexContact]?.phones?.[index]?.phoneNumber &&
                                                                                        touched.contacts?.[indexContact]?.phones?.[index]?.phoneNumber
                                                                                    ) &&
                                                                                    errors.contacts[indexContact].phones[index].phoneNumber
                                                                                    }
                                                                                    label="Phone Number"
                                                                                    name="phoneNumber"
                                                                                    onBlur={() => setFieldTouched(`contacts.[${indexContact}].phones[${index}].phoneNumber`)}
                                                                                    // onBlur={() => {
                                                                                    //     const phones = touched.phones ? [...touched.phones] : [];
                                                                                    //     phones[index] = {phoneNumber: true};
                                                                                    //     setFieldTouched('phones', phones);
                                                                                    // }}
                                                                                    variant="outlined"
                                                                                    value={phone.phoneNumber || ''}
                                                                                    onChange={e => handlePhoneChange(e, values, setFieldValue, index, indexContact)}
                                                                                />
                                                                            </Grid>
                                                                            <Grid item xs={ipPlus ? 4 : 4} style={{paddingLeft: ipPlus ? '20px' : '0'}}>
                                                                                <CardActions className={classes.conButton}>
                                                                                    <MuiThemeProvider theme={customThemeTooltip}>
                                                                                        {/*{*/}
                                                                                        {/*    index === item.phones.length - 1 &&*/}
                                                                                        {/*    <Tooltip arrow title={'Add Phone'}>*/}
                                                                                        {/*        <IconButton*/}
                                                                                        {/*            className={classes.addIcon}*/}
                                                                                        {/*            onClick={() => addPhone(values, indexContact, setFieldValue)}*/}
                                                                                        {/*        >*/}
                                                                                        {/*            <AddBox />*/}
                                                                                        {/*        </IconButton>*/}
                                                                                        {/*    </Tooltip>*/}
                                                                                        {/*}*/}
                                                                                        {
                                                                                            index > 0 &&
                                                                                            <Tooltip arrow title={'Remove Phone'}>
                                                                                                <DeleteIcon
                                                                                                    className={classes.DeleteIcon}
                                                                                                    onClick={() => removePhone(values, indexContact, index, setFieldValue)}
                                                                                                >
                                                                                                </DeleteIcon>
                                                                                            </Tooltip>
                                                                                        }
                                                                                    </MuiThemeProvider>
                                                                                </CardActions>
                                                                            </Grid>
                                                                        </React.Fragment>
                                                                    ))}
                                                                </Grid>
                                                            </div>
                                                            {!ipPlus &&
                                                            <div style={{width: '0px', order: '3'}}>
                                                            </div>
                                                            }

                                                        </div>

                                                    </Collapse>
                                                </CardContent>
                                            </React.Fragment>
                                        ))

                                    }
                                    {
                                        values.contacts.length === 0 && (
                                            <CardContent>
                                                <CardActions className={classes.conButton}>
                                                    <Button
                                                        onClick={() => addContact(values, setFieldValue)}
                                                        color="primary"
                                                        variant="outlined">
                                                        Add Contact
                                                    </Button>
                                                </CardActions>
                                            </CardContent>
                                        )
                                    }
                                </Card>


                                <Card style={{ marginTop: '16px', width: '100%' }}>
                                    <CardHeader title='Details' />
                                    <Divider />
                                    <CardContent>
                                        <Grid container spacing={3}>
                                            <Grid item xs={12}>
                                                <TextField
                                                    fullWidth
                                                    label="How did you hear about us ?"
                                                    name="adSource"
                                                    select
                                                    SelectProps={{ native: true }}
                                                    variant="outlined"
                                                    value={values.adSourceId || ''}
                                                    onChange={event => setFieldValue('adSourceId', event.target.value)}>
                                                        <option value=''></option>
                                                    {sources && sources.map(source => (
                                                        <option key={source.id} value={source.id}>
                                                            {source.description}
                                                        </option>
                                                    ))}
                                                </TextField>
                                            </Grid>

                                            <Grid item xs={12}>
                                                <TextField
                                                    fullWidth
                                                    label="Tax Group"
                                                    name="taxGroup"
                                                    onChange={event => {
                                                        const address = { ...values.address, taxGroupId: event.target.value }
                                                        setFieldValue('address', address);
                                                    }}
                                                    select
                                                    SelectProps={{ native: true }}
                                                    variant="outlined"
                                                    value={values.address.taxGroupId || ''}>
                                                    <option value=''></option>
                                                    {taxGroups.map(tax => (
                                                        <option
                                                            key={tax.id}
                                                            value={tax.id}>
                                                            {tax.name}
                                                        </option>
                                                    ))}
                                                </TextField>
                                            </Grid>

                                            {company?.useQuickbooksOnline && ( 
                                            <Grid item xs={12}>
                                                <Grid container alignItems="center">
                                                    <Grid item xs={12} style={{ paddingBottom: '12px'}}>
                                                        <FormControlLabel
                                                            className={classes.textCheckbox}
                                                            // labelPlacement={'bottom'}
                                                            name='isTaxExempt'
                                                            checked={isTaxExempt}
                                                            onChange={event => {
                                                                setIsTaxExempt(event.target.checked);
                                                                setFieldValue('qboTaxExempt', '');
                                                                setFieldValue('isTaxExempt', event.target.checked);
                                                                // const address = { ...values.address, taxGroupId: '00000000-0000-0000-0000-000000000000' }
                                                                // setFieldValue('address', address);
                                                            }}
                                                            control={
                                                                <GreenCheckbox />
                                                            }
                                                            label='Tax Exempt'
                                                        />
                                                    </Grid>
                                                    <Grid item xs={12}>
                                                        <TextField
                                                            error={isTaxExempt && errors.qboTaxExempt && touched.qboTaxExempt ? true : false}
                                                            helperText={isTaxExempt && errors.qboTaxExempt && touched.qboTaxExempt && errors.qboTaxExempt}
                                                            fullWidth
                                                            label='Tax Exempt Reasons'
                                                            name='qboTaxExempt'
                                                            variant='outlined'
                                                            select
                                                            SelectProps={{ native: true }}
                                                            value={values.qboTaxExempt || ''}
                                                            disabled={!isTaxExempt}
                                                            onChange={event => setFieldValue('qboTaxExempt', event.target.value)}>
                                                            <option value=''></option>
                                                            {taxExempt.map(tax => (
                                                                <option key={tax.id} value={tax.id}>
                                                                    {tax.name}
                                                                </option>
                                                            ))}
                                                        </TextField>
                                                    </Grid>
                                                </Grid>
                                            </Grid>
                                            )}

                                            <Grid item xs={12}>
                                                <Grid container spacing={3} alignItems={'center'}>
                                                    <Grid item xs={12} md={'auto'} style={{ flex: 1 }}>
                                                        <TextField
                                                            disabled
                                                            fullWidth
                                                            label="QuickBooks Name"
                                                            name="quickbookName"
                                                            value={values.qbName || ''}
                                                            variant="outlined" />
                                                    </Grid>
                                                    <Grid item xs={12} md={'auto'}>
                                                        <Button
                                                            fullWidth
                                                            className={classes.saveBtn}
                                                            variant="contained"
                                                            disabled={isGenerating}
                                                            onClick={() => setOpenConfirm(true)}>
                                                            Generate New Name
                                                        </Button>
                                                    </Grid>
                                                </Grid>
                                            </Grid>

                                            <Grid item xs={12}>
                                                <FormControlLabel
                                                    className={classes.postQuickBooks}
                                                    control={<GreenCheckbox />}
                                                    label='Post to QuickBooks'
                                                    checked={values.postToQuickbooks || false}
                                                    onChange={e => handlePostToQuickbooksChange(setFieldValue, e.target.checked)}
                                                    
                                                />
                                            </Grid>
                                        </Grid>
                                    </CardContent>
                                </Card>
                            </Grid>
                        </Grid>
                        <ConfirmModal
                            openConfirm={openConfirm}
                            title={'You are about to change the customer\'s Quickbooks identifier.  This will create a new Quickbooks account for this customer.  Do you want to continue?'}
                            okButtonText={'Continue'}
                            isProgress={isGenerating}
                            closeConfirm={() => setOpenConfirm(false)}
                            onConfirm={() => generateQBName(setFieldValue)}
                        />
                    </form>
                )}
            </Formik>

            <AvatarEditor
                open={openAvatarModal}
                onClose={() => setOpenAvatarModal(false)}
                onGetAvatar={onGetAvatar}
                id={customerId}
                type="customer-update-avatar"
                avatarImage={customer.avatar?.includes(customerId) ? customer.avatar : null}
            />

            <ModalNotice
                openModal={customer.showNoteOnOpen}
                updateCloseModal={updateCloseModal}
                note={customer.customerNote}
            >

            </ModalNotice>



            <div className={classes.snackBar}>
                <Snackbar anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
                    open={openSnackbar}
                    autoHideDuration={3000}
                    onClose={closeSnackbar}>
                    <Alert
                        elevation={6} variant="filled" severity={isStatus.failed ? "error" : 'success'}>
                        <Typography
                            color="inherit"
                            variant="h6">
                            {isStatus.msg}
                        </Typography>
                    </Alert>
                </Snackbar>
            </div>
        </>
    );
};

Information.propTypes = {
    className: PropTypes.string
}

export default Information;
