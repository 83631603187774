import React, { useState } from 'react';
import PropTypes from 'prop-types';
import {
    Card, CardHeader, CardContent, CardActions, Divider,
    TableContainer, Table, TableHead, TableBody, TableRow, TableCell,
    Grid, CircularProgress, Button, colors
} from '@material-ui/core';
import LogHoursCard from './LogHoursCard';
import { makeStyles } from '@material-ui/styles';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

const useStyles = makeStyles(theme => ({
    cardHeader: {
        cursor: 'pointer'
    },
    thead: {
        '& tr th': {
            fontSize: '16px',
            borderBottom: '1px solid #969696',
            padding: theme.spacing(1),
        }
    },
    tbody: {
        '& tr td': {
            padding: theme.spacing(1),
        }
    },
    btn: {
        color: theme.palette.white,
        backgroundColor: colors.green[600],
        '&:hover': {
            backgroundColor: colors.green[900]
        }
    },
    btnDis: {
        color: 'rgba(0, 0, 0, 0.26)',
        backgroundColor: 'rgb(217 217 217)',
        pointerEvents: 'none',
    },
    expandIcon: {
        position: 'absolute',
        top: '12px',
        right: '16px',
        cursor: 'pointer',
        transition: 'rotate 0.2s ease-out',
    },
    card: {
        position: 'relative'
    },
    cardReadOnly: {
        pointerEvents: 'none'
    },
    cardContent: {
        padding: 0,
        overflow: 'hidden',
        minHeight: 0,
        transition: 'height 0.2s ease-out',
        '&:last-child': {
            paddingBottom: 0
        }
    },
    contentChild: {
        padding: '16px 16px 24px'
    }
}));

const RemainingEmployees = (props) => {
    const classes = useStyles();

    const {
        readOnly, loading, loadingAll, remainingLogHours, loadingIndex, status,
        update, onChangeLog, onChangeTime, onAdditional, onRemove, saveAll, checkError, bindClearStart, bindClearEnd, clearRemainingIndex
    } = props;
    const disabledSaveAll = remainingLogHours.find(x => x.entries.find(y => y.changed));
    const [expand, setExpand] = useState(true);

    return (
        <Card className={`${classes.card} ${readOnly ? classes.cardReadOnly : ''}`}>
            <CardHeader className={classes.cardHeader} title={readOnly ? 'Additional Logged Hours' : 'Remaining Employees'} onClick={() => setExpand(!expand)} />
            <ExpandMoreIcon
                style={{ transform: expand ? 'rotate(180deg)' : 'rotate(0deg)' }}
                className={classes.expandIcon}
                onClick={() => setExpand(!expand)}
            />
            <Divider />
            <CardContent className={classes.cardContent}
                style={{ height: expand ? 'auto' : 0 }}>
                <div className={classes.contentChild}>
                    {
                        loading ?
                            <Grid
                                container
                                spacing={0}
                                style={{ paddingTop: '10px' }}
                                align="center"
                                justifyContent="center"
                                direction="column">
                                <Grid item>
                                    <CircularProgress className={classes.circularProgress} size={50} />
                                </Grid>
                            </Grid>
                            :
                            <TableContainer>
                                <Table>
                                    <TableHead className={classes.thead}>
                                        <TableRow>
                                            <TableCell align='center' style={{ width: '300px', lineHeight: '36px' }}>Name</TableCell>
                                            <TableCell style={{ minWidth: 40 }}></TableCell>
                                            <TableCell align='center' style={{ width: '150px' }}>In</TableCell>
                                            <TableCell align='center' style={{ width: '150px' }}>Out</TableCell>
                                            <TableCell style={{ minWidth: 40 }}></TableCell>
                                            <TableCell align='center'>Total Hours</TableCell>
                                            <TableCell align='center'>Applied Hourly Rate</TableCell>
                                            <TableCell align='center'>Rate</TableCell>
                                            <TableCell align='center'>Amount</TableCell>
                                            <TableCell align='center' style={{ minWidth: '165px' }}>Status</TableCell>
                                            {!readOnly && (
                                                <TableCell align='right' style={{ width: '167px' }}>
                                                    {
                                                        remainingLogHours && remainingLogHours.length > 0 &&
                                                        <>
                                                            <CardActions>
                                                                <Button className={!disabledSaveAll ? classes.btnDis : classes.btn}
                                                                    onClick={() => saveAll('remaining')}>
                                                                    Save all
                                                                </Button>
                                                                {loadingAll && <CircularProgress size={24} />}
                                                            </CardActions>
                                                        </>
                                                    }
                                                </TableCell>
                                            )}
                                        </TableRow>
                                    </TableHead>
                                    <TableBody className={classes.tbody}>
                                        {remainingLogHours && remainingLogHours.map((log, indexEmployees) => (
                                            <React.Fragment key={log.employeeId}>
                                                {
                                                    log.entries && log.entries.map((entry, index) => {

                                                        // combine status with jobAddresses
                                                        const statusWithJobAddresses = status ? [...status] : [];
                                                        if (Array.isArray(log.jobAddresses)) {
                                                            const jobSiteAddresses = log.jobAddresses.map(jobsite => {
                                                                return {
                                                                    id: 2,
                                                                    description: `Job Site - ${jobsite.jobAddress1}`,
                                                                    jobSplitId: jobsite.jobsite
                                                                }
                                                            })
                                                            const jobSiteIndex = statusWithJobAddresses.find(s => s.id === 'Job Site');
                                                            statusWithJobAddresses.splice(jobSiteIndex, 1, ...jobSiteAddresses)
                                                        }

                                                        return (
                                                            <LogHoursCard
                                                                bindClearStart={`${indexEmployees}${index}` === clearRemainingIndex ? bindClearStart : undefined}
                                                                bindClearEnd={`${indexEmployees}${index}` === clearRemainingIndex ? bindClearEnd : undefined}
                                                                readOnly={readOnly}
                                                                countEntry={log.entries.length}
                                                                updating={`${indexEmployees}${index}` === loadingIndex}
                                                                loadingIndex={loadingIndex}
                                                                update={(job) => update('remaining', job, log.employeeId, index, indexEmployees)}
                                                                status={statusWithJobAddresses}
                                                                key={log.employeeId + index}
                                                                entry={entry}
                                                                log={log}
                                                                onChange={e => onChangeLog('remaining', e, index, indexEmployees)}
                                                                onChangeTime={(name, value) => onChangeTime('remaining', name, value, index, indexEmployees)}
                                                                onAdditional={() => onAdditional('remaining', index, indexEmployees)}
                                                                onRemove={() => onRemove('remaining', entry.id, log.employeeId, index, indexEmployees)}
                                                                checkError={entry.changed ? checkError : false}
                                                            />
                                                        )
                                                    })
                                                }
                                            </React.Fragment>
                                        ))}
                                    </TableBody>
                                </Table>
                            </TableContainer>
                    }
                </div>
            </CardContent>
        </Card>
    )
}

RemainingEmployees.propTypes = {
    readOnly: PropTypes.bool,
    loading: PropTypes.bool,
    loadingAll: PropTypes.bool,
    remainingLogHours: PropTypes.arrayOf(Object),
    loadingIndex: PropTypes.string,
    status: PropTypes.array,
    update: PropTypes.func,
    onChangeLog: PropTypes.func,
    onChangeTime: PropTypes.func,
    onAdditional: PropTypes.func,
    onRemove: PropTypes.func,
    saveAll: PropTypes.func,
}

RemainingEmployees.defaultProps = {
    update: () => { },
    onChangeLog: () => { },
    onChangeTime: () => { },
    onAdditional: () => { },
    onRemove: () => { },
    saveAll: () => { },
}

export default RemainingEmployees;
