import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/styles';
import { Grid } from '@material-ui/core';
import EmployeeCard from '../EmployeeCard'
import { Pagination } from 'components';


const useStyles = makeStyles(theme => ({
  root: {},
  content: {
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column',
    textAlgin: 'center'
  },
  name: {
    marginTop: theme.spacing(1)
  },
  avatar: {
    height: 100,
    width: 100
  },
  paginate: {
    marginTop: theme.spacing(3),
    display: 'flex',
    justifyContent: 'center'
  }

}));
const EmployeeCards = props => {
  const { usersData, forcePage, onPageChange } = props;

  const classes = useStyles();

  return (

    <div className={classes.content}>
      <Grid
        container
        spacing={3}>
        {usersData.users.map(user => (
          <Grid
            item
            key={user.id}
            xs={12}>
            <EmployeeCard user={user} ></EmployeeCard>
          </Grid>
        ))}
      </Grid>
      <div style={usersData.pageCount <= 1 ? { display: 'none' } : null} className={classes.paginate}>
        <Pagination pageCount={usersData.pageCount} forcePage={forcePage} onPageChange={onPageChange} />
      </div>
    </div>

  );
};

EmployeeCards.propTypes = {
  className: PropTypes.string,
  usersData: PropTypes.object.isRequired
};

export default EmployeeCards;
