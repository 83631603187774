import React, {useState, useEffect} from 'react'
import {makeStyles} from '@material-ui/styles'
import {
    Typography,
    Grid,
    CardHeader,
    Divider,
    Collapse,
    CardContent,
    TextField,
    CardActions,
    Button, CircularProgress, Card
} from '@material-ui/core'
import SaleFollowUpsCard from './components';
import {Pagination} from 'components';
import salesData from 'mockup/salesData';
import axios from "../../../../utils/axios";
import apiConfig from "../../../../apiConfig";
import {useDispatch} from "react-redux";
import {KeyboardArrowDown, KeyboardArrowUp} from "@material-ui/icons";
import moment from "moment";
import { TotalProfit } from 'views/Dashboard/components';

const useStyles = makeStyles(theme => ({
    root: {},
    tableCell: {
        cursor: 'pointer',
        '& p': {
            whiteSpace: 'nowrap',
            fontWeight: 500
        },
        '& svg': {
            fontSize: '14px',
            transition: 'all 100ms ease-out'
        }
    },
    conTable: {
        overflowX: 'auto'
    },
    customSearch: {
        marginTop: theme.spacing(2),
        marginBottom: theme.spacing(2),
    },
    noData: {
        textAlign: 'center',
        fontSize: theme.spacing(2),
        fontWeight: 500,
        padding: '20px'
    },
}));
let pageNumberToday = 1;
let pageNumberBefore = 1;
let pageNumberAfter = 1;
let forcePageToday = 0;
let forcePageBefore = 0;
let forcePageAfter = 0;
const SaleFollowUps = props => {

    const classes = useStyles();
    const [totalToday, setTotalToday] = useState(0);
    const [totalBefore, setTotalBefore] = useState(0);
    const [totalAfter, setTotalAfter] = useState(0);
    const [loadToday, setLoadToday] = useState(false);
    const [loadBefore, setLoadBefore] = useState(false);
    const [loadAfter, setLoadAfter] = useState(false);
    const [pageCountToday, setPageCountToday] = useState(0);
    const [pageCountBefore, setPageCountBefore] = useState(0);
    const [pageCountAfter, setPageCountAfter] = useState(0);
    const [listSalesFollowUps, setListSalesFollowUps] = useState([]);
    const [listSalesFollowUpsToday, setListSalesFollowUpsToday] = useState([]);
    const [listSalesFollowUpsBefore, setListSalesFollowUpsBefore] = useState([]);
    const [listSalesFollowUpsAfter, setListSalesFollowUpsAfter] = useState([]);
    const [showSort, setShowSort] = useState({});
    const [load, setLoad] = useState(false);
    const [expandedToday, setExpandedToday] = useState(true);
    const [expandedBefore, setExpandedBefore] = useState(true);
    const [expandedAfter, setExpandedAfter] = useState(false);

    const [sort, setSort] = useState({name: 'dueDate', asc: true})
    const dates = ['dueDate', 'createdDate', 'lastJob']
    const {onReload} = props
    const dispatch = useDispatch();

    const onChangeSort = name => {
        if (!showSort[name]) {
            setShowSort({...showSort, [name]: true})
        }
        if (sort.name === name) {
            setSort({...sort, asc: !sort.asc})
        } else {
            setSort({name, asc: true})
        }
    }

    const onPageChangeToday = (page) => {
        pageNumberToday = page.selected + 1;
        forcePageToday = page.selected;
        fetchFollowUpsToday();
    };
    const onPageChangeBefore = (page) => {
        pageNumberBefore = page.selected + 1;
        forcePageBefore = page.selected;
        fetchFollowUpsBefore();
    };
    const onPageChangeAfter = (page) => {
        pageNumberAfter = page.selected + 1;
        forcePageAfter = page.selected;
        fetchFollowUpsAfter();
    };
    const fetchFollowUpsToday = () => {
        setLoadToday(true);
        axios.get(apiConfig.url.BASE_URL + apiConfig.url.DASHBOARD_FOLLOW_UP_CONTACT_TODAY, {
            params:
                {
                    pageNumber: pageNumberToday,
                    pageSize: 24
                }
        })
            .then(res => {
                setListSalesFollowUpsToday(res.data.data || []);
                setTotalToday(res.data.total);
                setExpandedToday(res.data.total > 0);
                setPageCountToday(res.data.pageCount);
            }).finally(() => {
            setLoadToday(false);
        })
    }
    const fetchFollowUpsBefore = () => {
        setLoadBefore(true);
        axios.get(apiConfig.url.BASE_URL + apiConfig.url.DASHBOARD_FOLLOW_UP_CONTACT_FAST_DUE, {
            params:
                {
                    pageNumber: pageNumberBefore,
                    pageSize: 24
                }
        })
            .then(res => {
                setListSalesFollowUpsBefore(res.data.data || []);
                setTotalBefore(res.data.total)
                setExpandedBefore(res.data.total > 0);
                setPageCountBefore(res.data.pageCount);
            }).finally(() => {
            setLoadBefore(false);
        })
    }
    const fetchFollowUpsAfter = () => {
        setLoadAfter(true);
        axios.get(apiConfig.url.BASE_URL + apiConfig.url.DASHBOARD_FOLLOW_UP_CONTACT_UP_COMING, {
            params:
                {
                    pageNumber: pageNumberAfter,
                    pageSize: 24
                }
        })
            .then(res => {
                setListSalesFollowUpsAfter(res.data.data || []);
                setTotalAfter(res.data.total);
                setExpandedAfter(res.data.total > 0);
                setPageCountAfter(res.data.pageCount);
            }).finally(() => {
            setLoadAfter(false);
        })
    };

    const onSort = sort => {
        const listSort = [...listSalesFollowUps];
        const {name} = sort;
        if (dates.includes(name)) {
            if (sort.asc) {
                listSort.sort((a, b) => {
                    return new Date(a[name]) - new Date(b[name])
                });
                setListSalesFollowUps(listSort)
            } else {
                listSort.sort((a, b) => {
                    return new Date(b[name]) - new Date(a[name])
                });
                setListSalesFollowUps(listSort)
            }
        } else {
            if (sort.asc) {
                listSort.sort((a, b) => {
                    return a[name] < b[name] ? -1 : (a[name] > b[name] ? 1 : 0)
                });
                setListSalesFollowUps(listSort)
            } else {
                listSort.sort((a, b) => {
                    return a[name] < b[name] ? 1 : (a[name] > b[name] ? -1 : 0)
                });
                setListSalesFollowUps(listSort)
            }
        }

    }

    const reload = () => {
        setLoad(!load);
        dispatch({type: 'RELOAD_CUSTOMERS_COUNTER', reloadCustomer: true});
        //onReload();
    }

    useEffect(() => {
        // axios.get(apiConfig.url.BASE_URL + apiConfig.url.DASHBOARD_FOLLOW_UP_CONTACT_ALL)
        //     .then(res => {
        //         setListSalesFollowUps(res.data);
        //     })
        //     .catch(err => {
        //     })
    }, [load])

    useEffect(() => {
        // if(listSalesFollowUps && listSalesFollowUps.length >0){
        //     let listSalesFollowUpsToday = listSalesFollowUps.filter(x => moment(moment.utc(x.followUpDate).toDate()).isSame(moment(), 'day'));
        //     setListSalesFollowUpsToday(listSalesFollowUpsToday.sort((a, b) => new Date(a.followUpDate || '01/01/1970') - new Date(b.followUpDate || '01/01/1970')));

        //     let listSalesFollowUpsBefore = listSalesFollowUps.filter(x => moment(moment.utc(x.followUpDate).toDate()) < moment().startOf('day'));
        //     setListSalesFollowUpsBefore(listSalesFollowUpsBefore.sort((a, b) => new Date(a.followUpDate || '01/01/1970') - new Date(b.followUpDate || '01/01/1970')));

        //     let listSalesFollowUpsAfter = listSalesFollowUps.filter(x => moment(moment.utc(x.followUpDate).toDate()) < moment().add('days', 7) && moment(moment.utc(x.followUpDate).toDate()) > moment().endOf('day'))
        //     setListSalesFollowUpsAfter(listSalesFollowUpsAfter.sort((a, b) => new Date(a.followUpDate || '01/01/1970') - new Date(b.followUpDate || '01/01/1970')));
        // }
    }, [])

    useEffect(() => {
        fetchFollowUpsToday();
        fetchFollowUpsAfter();
        fetchFollowUpsBefore();
    }, [sort, load])

    return (
        <>
            <Card style={{ marginTop: '24px' }}>
                <CardHeader title={`Today's Follow Ups (${totalToday})`} className={classes.cardHeader}
                            action={expandedToday ? <KeyboardArrowUp/> : <KeyboardArrowDown/>}
                            onClick={() => setExpandedToday(!expandedToday)}
                />
                <Divider />
                <Collapse in={expandedToday} timeout='auto' unmountOnExit>
                 {
                    loadToday ?
                            <Grid
                                container
                                spacing={0}
                                align="center"
                                justifyContent="center"
                                direction="column">
                                <Grid item>
                                    <CircularProgress className={classes.circularProgress} size={50}/>
                                </Grid>
                            </Grid>
                            :
                        (listSalesFollowUpsToday.length > 0 ?
                        (
                            <>
                                {
                                    listSalesFollowUpsToday.map((sale, inx) => <SaleFollowUpsCard onReload={reload} key={inx} customer={sale}/>)
                                }
                                <div style={pageCountToday <= 1 ? {display: 'none'} : null} className={classes.paginate}>
                                    <Pagination pageCount={pageCountToday} forcePage={forcePageToday} onPageChange={onPageChangeToday}/>
                                </div>
                            </>
                        )
                        :
                        (
                            !loadToday &&
                            <Grid container justifyContent='center'>
                                <Typography className={classes.noData}>No data found.</Typography>
                            </Grid>
                        )
                    )}
                </Collapse>
            </Card>

            <Card style={{ marginTop: '24px' }}>
                <CardHeader title={`Past Due Follow Ups (${totalBefore})`} className={classes.cardHeader}
                            action={expandedBefore ? <KeyboardArrowUp/> : <KeyboardArrowDown/>}
                            onClick={() => setExpandedBefore(!expandedBefore)}
                />
                <Divider />
                <Collapse in={expandedBefore} timeout='auto' unmountOnExit>
                {
                    loadBefore ?
                        <Grid
                            container
                            spacing={0}
                            align="center"
                            justifyContent="center"
                            direction="column">
                            <Grid item>
                                <CircularProgress className={classes.circularProgress} size={50}/>
                            </Grid>
                        </Grid>
                        :
                        (listSalesFollowUpsBefore.length > 0 ?
                        (
                            <>
                            {
                                listSalesFollowUpsBefore.map((sale, inx) => <SaleFollowUpsCard onReload={reload} key={inx} customer={sale}/>)
                            }
                                <div style={pageCountBefore <= 1 ? {display: 'none'} : null} className={classes.paginate}>
                                    <Pagination pageCount={pageCountBefore} forcePage={forcePageBefore} onPageChange={onPageChangeBefore}/>
                                </div>
                            </>
                        )
                        :
                        (
                            !loadBefore &&
                            <Grid container justifyContent='center'>
                                <Typography className={classes.noData}>No data found.</Typography>
                            </Grid>
                        )
                    )}
                </Collapse>
            </Card>

            <Card style={{ marginTop: '24px' }}>
                <CardHeader title={`Upcoming Follow Ups (${totalAfter})`} className={classes.cardHeader}
                            action={expandedAfter ? <KeyboardArrowUp/> : <KeyboardArrowDown/>}
                            onClick={() => setExpandedAfter(!expandedAfter)}
                />
                <Divider />
                <Collapse in={expandedAfter} timeout='auto' unmountOnExit>
                {
                    loadAfter ?
                            <Grid
                                container
                                spacing={0}
                                align="center"
                                justifyContent="center"
                                direction="column">
                                <Grid item>
                                    <CircularProgress className={classes.circularProgress} size={50}/>
                                </Grid>
                            </Grid>
                            :
                        (listSalesFollowUpsAfter.length > 0 ?
                        (
                            <>
                               {
                               listSalesFollowUpsAfter.map((sale, inx) => <SaleFollowUpsCard onReload={reload} key={inx} customer={sale}/>)
                               }
                                <div style={pageCountAfter <= 1 ? {display: 'none'} : null} className={classes.paginate}>
                                    <Pagination pageCount={pageCountAfter} forcePage={forcePageAfter} onPageChange={onPageChangeAfter}/>
                                </div>
                            </>
                        )
                        :
                        (
                            !loadAfter &&
                            <Grid container justifyContent='center'>
                                <Typography className={classes.noData}>No data found.</Typography>
                            </Grid>
                        )
                    )}
                </Collapse>
            </Card>

        </>
    )
}

export default SaleFollowUps;
