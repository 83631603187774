import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { makeStyles } from '@material-ui/styles'
import {
    Card, CardHeader, CardContent, Divider,
    TableContainer, Table, TableHead,
    TableBody, TableRow, TableCell, FormControlLabel, Collapse, useMediaQuery
} from '@material-ui/core'
import { GreenCheckbox } from 'components'
import { formatPhoneNumber } from 'utils/formatNumber'
import { KeyboardArrowDown, KeyboardArrowUp } from '@material-ui/icons'


const useStyles = makeStyles(theme => ({
    thead: {
        '& tr th': {
            borderBottom: '1px solid #888888'
        }
    },
}))

const JobsiteContacts = (props) => {

    const classes = useStyles();
    const address = JSON.parse(sessionStorage.getItem('sw-address'));
    let {contacts} =  props
    if(!contacts){
        contacts = address.contacts
    }

    const sm = useMediaQuery(theme => theme.breakpoints.down('sm'));
    const [expand, setExpand] = useState(true);

    return (
        <Card style={{minHeight: '100%'}}>
            <CardHeader title='Jobsite Contacts'
                action={sm && (expand ? <KeyboardArrowUp /> : <KeyboardArrowDown />)}
                onClick={() => sm && setExpand(!expand)} />
            <Divider />
            <Collapse in={!sm || expand} timeout='auto' unmountOnExit>
                <CardContent>
                    <TableContainer>
                        <Table style={{ minWidth: '800px' }}>
                            <TableHead className={classes.thead}>
                                <TableRow>
                                    <TableCell style={{ width: '1px' }} align='center'>Primary</TableCell>
                                    <TableCell align='center'>Name</TableCell>
                                    <TableCell align='center'>Phone Number</TableCell>
                                    <TableCell style={{ width: '1px', whiteSpace: 'nowrap' }} align='center'>Send Text</TableCell>
                                    <TableCell align='center'>Email</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {contacts && contacts.map((con, index) => (
                                    <TableRow key={index}>
                                        <TableCell align='center'>
                                            <FormControlLabel
                                                disabled
                                                style={{ marginRight: 0 }}
                                                checked={con.isPrimary}
                                                control={
                                                    <GreenCheckbox />
                                                }
                                            />
                                        </TableCell>
                                        <TableCell align='center'>{con.name || ''}</TableCell>
                                        <TableCell align='center'>{formatPhoneNumber(con.phone)|| ''}</TableCell>
                                        <TableCell align='center'>
                                            <FormControlLabel
                                                disabled
                                                style={{ marginRight: 0 }}
                                                checked={con.isSendText}
                                                control={
                                                    <GreenCheckbox />
                                                }
                                            />
                                        </TableCell>
                                        <TableCell align='center'>{con.email || ''}</TableCell>
                                    </TableRow>
                                ))}
                            </TableBody>
                        </Table>
                    </TableContainer>
                </CardContent>
            </Collapse>
        </Card>
    )
}

JobsiteContacts.propTypes = {
    contacts: PropTypes.array
}

export default JobsiteContacts;
