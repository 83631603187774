import React from 'react'
import PropTypes from 'prop-types'
import moment from 'moment'
import { IconButton, TableRow, TableCell } from '@material-ui/core'
import DeleteIcon from '@material-ui/icons/Delete'
import EditIcon from '@material-ui/icons/Edit'
import useMediaQuery from '@material-ui/core/useMediaQuery'

const AvailableDayOff = props => {

    const matchs = useMediaQuery('(max-width: 1040px)');

    const { dayoff, update, remove } = props;

    const { start, end, timeOffType, note } = dayoff;
    
    const startDate = moment(new Date(start)).format('MM/DD/YYYY');
    const endDate = moment(new Date(end)).format('MM/DD/YYYY');
    const startTime = moment(new Date(start)).format('LT');
    const endTime = moment(new Date(end)).format('LT');

    const dateString = startDate === endDate ? startDate : startDate + ' - ' + endDate;
    const timeString = (startTime === '12:00 AM' && endTime === '11:59 PM') ? 'Full day' : `${startTime} - ${endTime}`;

    return (
        <TableRow>
            <TableCell>{dateString}</TableCell>
            <TableCell align='left'>{timeString}</TableCell>
            <TableCell align='left'>{ timeOffType && timeOffType.replace('_', ' ') || '-'}</TableCell>
            <TableCell align='left'>{note || '-'}</TableCell>
            <TableCell style={{ whiteSpace: 'nowrap' }}>
                <IconButton onClick={update}>
                    <EditIcon />
                </IconButton>
                <IconButton onClick={remove}>
                    <DeleteIcon />
                </IconButton>
            </TableCell>
        </TableRow>
    )
}

AvailableDayOff.propTypes = {
    dayoff: PropTypes.object.isRequired,
    update: PropTypes.func,
    remove: PropTypes.func
}

AvailableDayOff.defaultProps = {
    dayoff: {},
    update: () => { },
    remove: () => { }
}

export default AvailableDayOff;
