import React from 'react'
import PropTypes from 'prop-types'
import {
    Modal,
    Card,
    CardContent,
    CardActions,
    Button,
    colors,
    Typography,
    Divider,
    CardHeader,
    CircularProgress
} from '@material-ui/core'
import { makeStyles } from '@material-ui/styles'
import * as moment from 'moment'

const useStyles = makeStyles(theme => ({
    styleModal: {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        outline: 'none',
        boxShadow: theme.shadows[20],
        width: 600,
        maxHeight: '100%',
        overflowY: 'auto',
        maxWidth: '100%',
        [theme.breakpoints.down('sm')]: {
            width: 500
        },
    },
    conButton: {
        padding: '16px',
        justifyContent: 'flex-end'
    },
    button: {
        flexShrink: '0',
        backgroundColor: colors.green[600],
        '&:hover': {
            backgroundColor: colors.green[900]
        },
    },
}))

const ConfirmOvernightModal = props => {
    const classes = useStyles();

    const { data, isOk, isProgress, openConfirm, closeConfirm, onConfirm } = props;

    return (
        <Modal
            open={openConfirm}
            onClose={closeConfirm}
        >
            <Card className={classes.styleModal}>
                <CardHeader title="Overnight Hours" />
                <Divider />
                <CardContent style={{ padding: '20px 16px' }}>
                    <Typography align="center" style={{ fontSize: '16px' }}>
                        <span>The end time you logged is earlier than the start time.</span><br/>
                        <span>Do you want to log hours for the following Date/Time?</span><br/><br/>
                        <span>{data.employee}</span><br/>
                        <span>{moment(data.workStart).format('l LT')} - {moment(data.workEnd).format('l LT')}</span><br/>
                    </Typography>
                </CardContent>
                <Divider />
                <CardActions className={classes.conButton}>
                    <Button
                        variant="contained"
                        size="large"
                        onClick={closeConfirm}>
                        Cancel
                    </Button>

                    <Button style={!isOk ? { display: 'none' } : null}
                        disabled={!!isProgress}
                        variant="contained"
                        color="primary"
                        size="large"
                        onClick={() => {
                            if (data.updateData) {
                                onConfirm(data.updateData.type,
                                          data.updateData.entry,
                                          data.updateData.employeeId,
                                          data.updateData.index,
                                          data.updateData.indexEmployees)
                            }
                            if (data.saveAllData) {
                                onConfirm(data.saveAllData.type,
                                          data.saveAllData.logHoursChanged,
                                          data.saveAllData.logHoursChangedEntries)
                            }
                        }}
                        className={classes.button}>
                        Save
                    </Button>
                    {isProgress && <CircularProgress style={!isOk ? { display: 'none' } : null} size={24} />}
                </CardActions>
            </Card>
        </Modal>
    )
}

ConfirmOvernightModal.propTypes = {
    data: PropTypes.object,
    isProgress: PropTypes.bool,
    openConfirm: PropTypes.bool.isRequired,
    closeConfirm: PropTypes.func.isRequired,
    onConfirm: PropTypes.func
}

ConfirmOvernightModal.defaultProps = {
    data: {},
    isOk: true,
    isProgress: false,
    onConfirm: () => { },
    closeConfirm: () => { }
}

export default ConfirmOvernightModal;
