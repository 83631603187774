import React, {useEffect, useState} from 'react'
import PropTypes from 'prop-types'
import {Grid, Button, TextField, Typography, colors, CircularProgress, Snackbar, MuiThemeProvider, createTheme, Tooltip} from '@material-ui/core'
import {makeStyles} from '@material-ui/styles'
import useMediaQuery from '@material-ui/core/useMediaQuery'
import moment from "moment";
import axios from "../../../utils/axios";
import apiConfig from "../../../apiConfig";
import {Alert} from "@material-ui/lab";
import ModalPhoneNumber from "./ModalPhoneNumber";
import OpenInNewIcon from '@material-ui/icons/OpenInNew';

const customThemeTooltip = createTheme({
    overrides: {
        MuiTooltip: {
            tooltip: {
                fontSize: '14px',
                maxWidth: 'none'
            }
        }
    }
});

const useStyles = makeStyles(theme => ({
    item: {
        textAlign: 'center',
        [theme.breakpoints.down('xs')]: {
            display: 'flex',
            '&:not(:last-child)': {
                padding: '0 8px 0 32px !important'
            }
        }
    },
    headTypo: {
        fontWeight: 500,
        textAlign: 'center',
        display: 'none',
        [theme.breakpoints.down('xs')]: {
            display: 'block',
            width: 80,
            textAlign: 'left'
        }
    },
    bodyTypo: {textAlign: 'center'},
    btn: {
        fontSize: 12,
        color: theme.palette.white,
        backgroundColor: colors.green[600],
        whiteSpace: 'nowrap',
        '&:hover': {
            backgroundColor: colors.green[900]
        }
    },
    status: {
        '& div select': {
            padding: 14
        }
    },
    loading: {marginLeft: '6px', marginTop: "10px"},
    jobLink: {
        display: 'inline-block',
        height: 20,
        padding: 0,
        color: '#263238',
        background: "transparent",
        position: 'absolute',
        [theme.breakpoints.down('sm')]: {
            top: theme.spacing(2)
        }
    },
}));

const status = [
    {id: 1, name: 'Update Status'},
    {id: 2, name: 'On The Way'},
    {id: 3, name: 'Job Started'},
    {id: 4, name: 'Job Completed'},
]

const JobHeader = ({job, status, onUpdateStatus, onExpand,isEnableTodayRoute,isFieldTechnician}) => {

    const classes = useStyles();
    const md_down = useMediaQuery('(max-width: 961px)');
    const xs_down = useMediaQuery('(max-width: 500px)');
    const [isStatus, setIsStatus] = useState({failed: false, msg: ''});
    const [openSnackbar, setOpenSnackBar] = useState(false);
    const [isSaving, setIsSaving] = useState(false);
    const [isSavingStatus, setIsSavingStatus] = useState(false);
    const [currentJob, setCurrentJob] = useState(job);
    const [showModalPhoneNumber, setShowModalPhoneNumber] = useState(false);
    const sendText = () => {
        if (!/^\(\d{3}\) \d{3}\-\d{4}$/.test(currentJob.customerPhone)) {
            setShowModalPhoneNumber(true);
            return;
        }
        setIsSaving(true);
        axios.post(apiConfig.url.BASE_URL + apiConfig.url.TODAY_ROUTE_TEXT_CUSTOMER.replace('{routeJobId}', currentJob.id))
            .then(res => {
                if (res.data?.message) {
                    setIsStatus({failed: true, msg: res.data.message});
                    setOpenSnackBar(true)
                } else {
                    setIsStatus({failed: false, msg: 'Text Status Sent'});
                    setOpenSnackBar(true)
                    setCurrentJob({...currentJob, lastJobStatusTexted: currentJob.jobStatus})
                }
            })
            .catch(err => {
                // console.log(err);
            }).finally(() => setIsSaving(false));

    }

    const onUpdatePhoneNumber = phoneNumber => {
        setCurrentJob({...currentJob, customerPhone: phoneNumber});
        setShowModalPhoneNumber(false);
        setIsStatus({failed: false, msg: 'Phone Number Updated'});
        setOpenSnackBar(true);
    }

    const updateStatus = (value) => {
        setIsSavingStatus(true);
        axios.put(apiConfig.url.BASE_URL + apiConfig.url.TODAY_ROUTE_STATUS_UPDATE.replace('{id}', currentJob.id), {routeJobStatus: value})
            .then(res => {
                setCurrentJob({...job, jobStatus: value})
                setIsStatus({failed: false, msg: 'Status Updated'});
                setOpenSnackBar(true)
                // onUpdateStatus(`${res.data}_${res.data == 2 ? job.jobId : ''}`)
            })
            .catch(err => {
                // console.log(err);
            }).finally(() => setIsSavingStatus(false));
    }

    const disabled = job && job.completedDate ? true : false

    return (
        <>
        {
            (isEnableTodayRoute != null && isEnableTodayRoute) ? 
                <>
                    <Grid container spacing={1} alignItems='center' onClick={onExpand} style={{ margin:0}}>
                        <Grid xs={12} sm={2} md={1} theme={customThemeTooltip} className={classes.item}>
                        { !isFieldTechnician && <Typography className={classes.bodyTypo} style={{ height:20 , textAlign : 'left'}}>
                            <Tooltip arrow title={'View Job'}>
                                <a
                                    className={classes.jobLink}
                                    href={`/customers/${encodeURIComponent(job.companyName)}/${job.customerId}/jobs/${job.jobId}/information`}>
                                    <OpenInNewIcon style={{ fontSize: 20 }} />
                                </a>
                            </Tooltip>
                            </Typography>}
                        </Grid>
                        <Grid item xs={12} sm={4} md={3} className={classes.item}>
                            <Typography className={classes.headTypo}>
                                Time: 
                            </Typography>
                            <Typography
                                className={classes.bodyTypo}>{moment(currentJob.scheduledTime+'.000Z').format('hh:mm A') || ''} {
                                    currentJob.flexibleScheduling && <span className={classes.bodyTypo} style={{paddingLeft:'20px'}}>Anytime Today/Flexible</span>
                                }
                            </Typography>
                            
                        </Grid>
                        <Grid item xs={12} sm={4} md={2} className={classes.item}>
                            <Typography className={classes.headTypo}>
                                Customer:
                            </Typography>
                            <Typography
                                className={classes.bodyTypo}>{currentJob.companyName || ''}</Typography>
                        </Grid>
                        <Grid item xs={12} sm={4} md={2} className={classes.item}>
                            <Typography className={classes.headTypo}>
                                Man Hours:
                            </Typography>
                            <Typography className={classes.bodyTypo}>{currentJob.scheduledHours || ''}</Typography>
                        </Grid>
                        <Grid item xs={12} md={4} className={classes.item}>
                            <Typography className={classes.headTypo}
                                        style={md_down ? {display: 'none'} : null}>
                                Status:
                            </Typography>
                            <div style={{display: 'flex', justifyContent: 'flex-end', paddingRight: md_down ? null : 24, width: xs_down ? '100%' : null, flexDirection: xs_down ? 'column' : 'row'}}>
                                <TextField
                                    disabled={disabled}
                                    label={md_down ? 'Status' : null}
                                    className={classes.status}
                                    style={{ marginRight: xs_down ? null : 8, marginBottom: xs_down ? 8 : null }}
                                    variant='outlined'
                                    select
                                    SelectProps={{native: true}}
                                    value={currentJob.jobStatus || ''}
                                    onClick={e => e.stopPropagation()}
                                    onChange={(event) => {
                                        updateStatus(event.target.value)
                                    }}
                                >
                                    <option key="" value=""></option>
                                    {status.map(st => (
                                        <option key={`status-${st.id}`} value={st.id}>
                                            {st.description}
                                        </option>
                                    ))}
                                </TextField>
                                {isSavingStatus && <CircularProgress className={classes.loading} size={24}/>}
                                <Button variant={"contained"} className={classes.btn}
                                        disabled={(currentJob.lastJobStatusTexted == currentJob.jobStatus) || !currentJob.jobStatus || !currentJob.textability || disabled }
                                        onClick={e => {
                                            e.stopPropagation();
                                            e.preventDefault();
                                            sendText()
                                        }}>
                                    Text Status to Customer
                                </Button>
                                {isSaving && <CircularProgress className={classes.loading} size={24}/>}
                                <Snackbar
                                    open={openSnackbar}
                                    anchorOrigin={{vertical: 'top', horizontal: 'center'}}
                                    autoHideDuration={3000}
                                    onClose={() => setOpenSnackBar(false)}>
                                    <Alert
                                        elevation={6} variant="filled" severity={isStatus.failed ? 'error' : 'success'}>
                                        <Typography
                                            color="inherit"
                                            variant="h6">
                                            {isStatus.msg}
                                        </Typography>
                                    </Alert>
                                </Snackbar>
                            </div>
                        </Grid>
                    </Grid>
                </>
            :
                <>
                    <Grid container spacing={2} alignItems='center' onClick={onExpand}>
                        <Grid item xs={12} sm={4} md={2} className={classes.item}>
                            <Typography className={classes.headTypo}>
                                Time:
                            </Typography>
                            <Typography
                                className={classes.bodyTypo}>{moment(currentJob.scheduledTime+'.000Z').format('hh:mm A') || ''}</Typography>
                        </Grid>
                        <Grid item xs={12} sm={4} md={3} className={classes.item}>
                            <Typography className={classes.headTypo}>
                                Customer:
                            </Typography>
                            <Typography
                                className={classes.bodyTypo}>{currentJob.companyName || ''}</Typography>
                        </Grid>
                        <Grid item xs={12} sm={4} md={2} className={classes.item}>
                            <Typography className={classes.headTypo}>
                                Man Hours:
                            </Typography>
                            <Typography className={classes.bodyTypo}>{currentJob.scheduledHours || ''}</Typography>
                        </Grid>
                        <Grid item xs={12} md={5} className={classes.item}>
                            <Typography className={classes.headTypo}
                                        style={md_down ? {display: 'none'} : null}>
                                Status:
                            </Typography>
                            <div style={{display: 'flex', justifyContent: 'flex-end', paddingRight: md_down ? null : 24, width: xs_down ? '100%' : null, flexDirection: xs_down ? 'column' : 'row'}}>
                                <TextField
                                    disabled={disabled}
                                    label={md_down ? 'Status' : null}
                                    className={classes.status}
                                    style={{ marginRight: xs_down ? null : 8, marginBottom: xs_down ? 8 : null }}
                                    variant='outlined'
                                    select
                                    SelectProps={{native: true}}
                                    value={currentJob.jobStatus || ''}
                                    onClick={e => e.stopPropagation()}
                                    onChange={(event) => {
                                        updateStatus(event.target.value)
                                    }}
                                >
                                    <option key="" value=""></option>
                                    {status.map(st => (
                                        <option key={`status-${st.id}`} value={st.id}>
                                            {st.description}
                                        </option>
                                    ))}
                                </TextField>
                                {isSavingStatus && <CircularProgress className={classes.loading} size={24}/>}
                                <Button variant={"contained"} className={classes.btn}
                                        disabled={(currentJob.lastJobStatusTexted == currentJob.jobStatus) || !currentJob.jobStatus || !currentJob.textability || disabled }
                                        onClick={e => {
                                            e.stopPropagation();
                                            e.preventDefault();
                                            sendText()
                                        }}>
                                    Text Status to Customer
                                </Button>
                                {isSaving && <CircularProgress className={classes.loading} size={24}/>}
                                <Snackbar
                                    open={openSnackbar}
                                    anchorOrigin={{vertical: 'top', horizontal: 'center'}}
                                    autoHideDuration={3000}
                                    onClose={() => setOpenSnackBar(false)}>
                                    <Alert
                                        elevation={6} variant="filled" severity={isStatus.failed ? 'error' : 'success'}>
                                        <Typography
                                            color="inherit"
                                            variant="h6">
                                            {isStatus.msg}
                                        </Typography>
                                    </Alert>
                                </Snackbar>
                            </div>
                        </Grid>
                        <MuiThemeProvider theme={customThemeTooltip}>
                        { !isFieldTechnician && <Tooltip arrow title={'View Job'}>
                                <a
                                    className={classes.jobLink}
                                    href={`/customers/${encodeURIComponent(job.companyName)}/${job.customerId}/jobs/${job.jobId}/information`}>
                                    <OpenInNewIcon style={{ fontSize: 20 }} />
                                </a>
                            </Tooltip>
                        }
                        </MuiThemeProvider>
                    </Grid>
                </>
            }
            <ModalPhoneNumber
                openConfirm={showModalPhoneNumber}
                closeConfirm={() => setShowModalPhoneNumber(false)}
                onConfirm={onUpdatePhoneNumber}
                phoneNumber={currentJob.customerPhone}
                phoneId={currentJob.customerPhoneId}
                customerId={currentJob.customerId}
            />
        </>
    )
}

JobHeader.propTypes = {
    job: PropTypes.object,
    onUpdateStatus: PropTypes.func
}

export default JobHeader;
