import React from 'react'
import { makeStyles } from '@material-ui/styles'
import { Button, colors } from '@material-ui/core'

const useStyles = makeStyles(theme => ({
    groupBtn: {
        display: 'flex',
        flexWrap: 'wrap',
        justifyContent: 'space-between',
        padding: '10px',
        borderBottom: '1px solid #c7c7c7',
        '&:last-child': {
            borderBottom: 'none'
        }
    },
    actionBtn: {
        width: '48%'
    },
    btnBlue: {
        backgroundColor: '#0000ff',
        '&:hover': {
            backgroundColor: '#00007a'
        },
    },
    btn: {
        width: '100%',
        marginBottom: '10px',
        color: theme.palette.white,
        backgroundColor: colors.green[600],
        '&:hover': {
            backgroundColor: colors.green[900]
        },
        padding: '3px 8px',
        fontSize: '12px'
    },
    label: {
        '& span:first-child': {
            padding: '2px 5px',
        }
    }
}));

const RouteAction = (props) => {
    const { date, router, onClone, action, onRecur, handleCompletion, loadingPdfRoute } = props

    const classes = useStyles();

    const handleAction = type => {
        action(type, router)
    }

    return (
        <>
            <div className={classes.groupBtn}>
                <div className={classes.actionBtn}>
                    <Button variant={'contained'} className={`${classes.btn} ${router.recurring ? classes.btnBlue : ''}`}
                        onClick={() => handleAction('recur')}>
                        Recur
                    </Button>
                </div>
                <div className={classes.actionBtn}>
                    <Button variant={'contained'} className={classes.btn}
                        onClick={() => handleAction(router.routeFull ? 'open' : 'full', router.id)}
                    >
                        {router.routeFull ? 'Open Route' : 'Mark Full'}
                    </Button>
                </div>
                <div className={classes.actionBtn}>
                    <Button variant={'contained'} className={classes.btn}
                        onClick={() => handleAction('clone')}>
                        Clone
                    </Button>
                </div>
                <div className={classes.actionBtn}>
                    {!loadingPdfRoute
                        ? <Button
                            variant={'contained'}
                            className={classes.btn}
                            onClick={() => handleAction('print')}
                        >
                            Print
                        </Button>
                        :
                        <Button
                            variant={'contained'}
                            className={classes.btn}
                            disabled={true}
                        >
                            Loading
                        </Button>
                    }
                </div>
                <div style={{ width: '100%', display: 'flex' }}>
                    <Button variant={'contained'} className={classes.btn}
                        onClick={() => handleAction('routeNote')}>
                        Route Note
                    </Button>
                    <div style={{ padding: '0 4px' }} />
                    <Button variant={'contained'} className={classes.btn}
                        onClick={() => handleAction('completion')}>
                        Completion
                    </Button>
                </div>
            </div>
        </>
    )
}

export default RouteAction;
