import React, { useState, useEffect } from 'react'
import { useParams } from 'react-router-dom'
import PropTypes from 'prop-types'
import { makeStyles } from '@material-ui/styles'
import { DraggableSource } from './components'
import { useDrop } from 'react-dnd'
import { useSelector, useDispatch } from 'react-redux'
import { Card, CardContent, CircularProgress, Snackbar, Typography } from '@material-ui/core'
import { Alert } from '@material-ui/lab'
import apiConfig from 'apiConfig'
import axios from 'utils/axios'
import { formatTwoPDecimal } from "utils/formatNumber";
import moment from "moment";
import { Pagination } from "../../../../components";

const useStyles = makeStyles(theme => ({
    sources: {
        borderRadius: '4px',
        overflowY: 'auto',
        transition: 'all .3s ease-out',
        position: 'relative',
        '& > div': {
            borderBottom: '1px solid #d0d0d0',
            '&:last-child': {
                borderBottom: 'none'
            }
        },
        '&::-webkit-scrollbar': {
            width: '10px'
        },
        '&::-webkit-scrollbar-track': {
            backgroundColor: '#e4e4e4'
        },
        '&::-webkit-scrollbar-thumb': {
            backgroundColor: '#9c9c9c',
            borderRadius: '60px'
        }
    },
}));

const RoutesSource = ({ tab, setDrag }) => {
    const classes = useStyles();
    const dispatch = useDispatch();
    const { date } = useParams();

    const { routes } = useSelector(state => state.RoutesReducer);
    const { routeJobs, loadingJob } = useSelector(state => state.JobsReducer);
    const { routeEmployees } = useSelector(state => state.EmployeesReducer);
    const { routeEquipment } = useSelector(state => state.EquipmentsReducer);

    const [loading, setLoading] = useState(false);
    const [isStatus, setStatus] = useState({ failed: false, msg: '' });
    const [openSnackbar, setOpenSnackbar] = useState(false);
    const [pageJob, setPageJob] = useState(1);
    const [pageEquipment, setPageEquipment] = useState(1);
    const [pageEmployees, setPageEmployees] = useState(1);
    const [totalPageJob, setTotalPageJob] = useState(1);
    const [totalPageEquipment, setTotalPageEquipment] = useState(1);
    const [totalPageEmployees, setTotalPageEmployees] = useState(1);
    const [selected, setSelected] = useState({
        Job: null,
        Employee: null,
        Equipment: null
    });

    const tobeScheduledJobs = routeJobs && routeJobs.filter(job => job.jobStage !== 1) || null;

    // handle drag and drop
    const [{ isOverJob }, dropJob] = useDrop({
        accept: 'Job',
        drop: item => handleDropBackJobItem(item),
        collect: (monitor) => ({
            isOverJob: monitor.isOver()
        })
    });
    const [{ isOverEmp }, dropEmployee] = useDrop({
        accept: 'Employee',
        drop: item => handleDropBackEmployeeItem(item),
        collect: (monitor) => ({
            isOverEmp: monitor.isOver()
        })
    });
    const [{ isOverEqp }, dropEquipment] = useDrop({
        accept: 'Equipment',
        drop: item => handleDropBackEquipmentItem(item),
        collect: (monitor) => ({
            isOverEqp: monitor.isOver()
        })
    });

    const drop = {
        'to-be-scheduled': dropJob,
        'available-employees': dropEmployee,
        'available-equipments': dropEquipment
    }
    const isOver = {
        'to-be-scheduled': isOverJob,
        'available-employees': isOverEmp,
        'available-equipments': isOverEqp
    }

    const handleDropBackJobItem = ({ oldRouteId, id }) => {
        if (oldRouteId !== undefined) {
            const route = routes.find(route => route.id === oldRouteId);
            const routeRemove = route.routeJobs.find(job => job.jobId === id);
            routeRemove.estimatedHours = routeRemove.job.estimatedHours;
            routeRemove.customerName = routeRemove.job.customerName;
            routeRemove.equipment = routeRemove.job.equipment;
            routeRemove.city = routeRemove.job.city || '';
            routeRemove.price = routeRemove.job.price;
            routeRemove.totalSplitJobs = routeRemove.job.totalSplitJobs;

            const routeData = {
                ...route,
                routeEquipment: !route.routeEquipment ? [] : [...route.routeEquipment],
                routeEmployees: !route.routeEmployees ? [] : [...route.routeEmployees],
                routeJobs: route.routeJobs.filter(job => job.jobId !== id)
            };
            const requestForRoute = axios.put(apiConfig.url.BASE_URL + apiConfig.url.ROUTE + `${date}/` + oldRouteId, routeData);
            // const requestForJob = axios.patch(apiConfig.url.BASE_URL + 'job', { id, jobStage: 3 });

            dispatch({ type: 'IS_UPDATE', status: true });
            Promise.all([requestForRoute])
                .then(values => {
                    dispatch({ type: 'REMOVE_JOB_ROUTE', routeId: oldRouteId, jobSplitId: id });
                    dispatch({ type: 'CHANGE_JOB_STAGE', jobId: id, jobStage: 3 })

                    dispatch({ type: 'ADD_JOB', jobId: id, routeRemove: routeRemove })
                })
                .catch(() => {
                    setStatus({ failed: true, msg: 'Delete failed, please try again later.' });
                    setOpenSnackbar(true)
                })
                .finally(() => dispatch({ type: 'IS_UPDATE', status: false }))
        }
    }
    const handleDropBackEmployeeItem = ({ oldRouteId, id }) => {
        if (oldRouteId !== undefined) {
            const route = routes.find(route => route.id === oldRouteId);
            const rj = route.routeJobs;
            rj.map(job => {
                const localEndDate = new Date(job.jobStart);
                job.estimatedHours = formatTwoPDecimal(job.estimatedHours * ((route.routeEmployees.length === 0 ? 1 : route.routeEmployees.length) / (route.routeEmployees.length - 1 <= 0 ? 1 : route.routeEmployees.length - 1)));
                const hour = localEndDate.getHours() + Math.round((localEndDate.getMinutes() / 60) * 100) / 100 + (job.estimatedHours);
                localEndDate.setHours(Math.floor(hour));
                localEndDate.setMinutes(Math.round((hour - Math.floor(hour)) * 60));
                job.jobEnd = new Date(localEndDate).toISOString();
            })

            const routeData = {
                ...route,
                routeEquipment: !route.routeEquipment ? [] : [...route.routeEquipment],
                routeEmployees: route.routeEmployees.filter(emp => emp.employeeId !== id),
                routeJobs: !route.routeJobs ? [] : [...rj]
            };
            dispatch({ type: 'IS_UPDATE', status: true });
            axios.put(apiConfig.url.BASE_URL + apiConfig.url.ROUTE + `${date}/` + oldRouteId, routeData)
                .then(() => {
                    let routeEmployees = route.routeEmployees.find(emp => emp.employeeId === id);
                    dispatch({ type: 'REMOVE_EMP_ROUTE', routeId: oldRouteId, employeeId: id });
                    routeEmployees.id = routeEmployees.employeeId
                    dispatch({ type: 'ADD_EMPLOYEE', employee: routeEmployees })
                })
                .catch(() => {
                    setStatus({ failed: true, msg: 'Delete failed, please try again later.' });
                    setOpenSnackbar(true)
                })
                .finally(() => dispatch({ type: 'IS_UPDATE', status: false }))
        }
    }
    const handleDropBackEquipmentItem = ({ oldRouteId, id }) => {
        if (oldRouteId !== undefined) {
            const route = routes.find(route => route.id === oldRouteId);
            const addEquipment = route.routeEquipment.find(eqp => eqp.id === id)
            const routeData = {
                ...route,
                routeEquipment: route.routeEquipment.filter(eqp => eqp.id !== id),
                routeEmployees: !route.routeEmployees ? [] : [...route.routeEmployees],
                routeJobs: !route.routeJobs ? [] : [...route.routeJobs]
            };
            dispatch({ type: 'IS_UPDATE', status: true });
            axios.put(apiConfig.url.BASE_URL + apiConfig.url.ROUTE + `${date}/` + oldRouteId, routeData)
                .then(() => {
                    dispatch({ type: 'REMOVE_EQP_ROUTE', routeId: oldRouteId, id });
                    dispatch({ type: 'ADD_EQUIPMENT', addEquipment: addEquipment })
                })
                .catch(() => {
                    setStatus({ failed: true, msg: 'Delete failed, please try again later.' });
                    setOpenSnackbar(true)
                })
                .finally(() => dispatch({ type: 'IS_UPDATE', status: false }))
        }
    }

    const onChangeSelected = (type, index) => {
        setSelected({
            ...selected,
            [type]: index
        })
    }

    const locateInRoute = (jobSplitId) => {
        dispatch({ type: 'SHOW_DISTANCE', showDistance: false })
        dispatch({ type: 'IS_UPDATE', status: true });
        axios.get(apiConfig.url.BASE_URL + apiConfig.url.ROUTE_DISTANCE, { params: { jobSplitId, routeDate: date } })
            .then(res => {
                const routeDistance = routes.map(item => {
                    const distance = res.data.find(x => x.routeId === item.id);
                    const routeDistance = item.routeJobs.map(job => {
                        if (distance) {
                            const distanceJob = distance.jobDistances.find(y => y.jobSplitId === job.jobSplitId);
                            return { ...job, distance: distanceJob.distance ? parseFloat(distanceJob.distance).toFixed(2) : undefined }
                        } else {
                            return { ...job, distance: undefined }
                        }

                    })
                    return { ...item, routeJobs: routeDistance, distance: distance ? parseFloat(distance.distance).toFixed(2) : undefined }
                })

                dispatch({ type: 'CHANGE_ORDER', orderType: 'DISTANCE' })
                dispatch({ type: 'INIT_ROUTES', routes: routeDistance })
            }).finally(() => {
                dispatch({ type: 'IS_UPDATE', status: false });
            })
    }

    const getJobs = (page) => {
        const pageNum = page || pageJob;
        if (pageNum > totalPageJob || loadingJob) return;
        dispatch({ type: 'LOADING_JOB', loadingJob: true })
        axios.get(apiConfig.url.BASE_URL + apiConfig.url.TO_BE_SCHEDULED_JOBS + '&pageNumber=' + pageNum, { params: { date: moment(date).utc().toISOString() } })
            .then(res => {
                res.data.data.map(job => {
                    job['job'] = {
                        city: job.city || '',
                        price: job.price,
                        estimatedHours: job.estimatedHours
                    }
                });
                pageNum === 1 ?
                    dispatch({ type: 'INIT_JOB', routeJobs: res.data.data }) :
                    dispatch({ type: 'LOAD_MORE_JOB', routeJobs: res.data.data })
                setTotalPageJob(res.data.pageCount);
                if (pageNum * 1 <= res.data.pageCount * 1) {
                    setPageJob(pageNum + 1);
                }
            })
            .catch(() => {
                dispatch({ type: 'LOAD_MORE_JOB', routeJobs: [] })
            }).finally(() => {
                setLoading(false);
                dispatch({ type: 'LOADING_JOB', loadingJob: false })
            })
    }

    const getEquipments = (page) => {
        const pageNum = page || pageEquipment;
        if (pageNum > totalPageEquipment || loadingJob) return;
        dispatch({ type: 'LOADING_JOB', loadingJob: true })
        axios.get(apiConfig.url.BASE_URL + apiConfig.url.EQUIPMENTS_FOR_ROUTE + date + '&pageNumber=' + pageNum)
            .then(res => {
                pageNum === 1 ?
                    dispatch({ type: 'INIT_EQUIPMENT', routeEquipment: res.data.data }) :
                    dispatch({ type: 'LOAD_MORE_EQUIPMENT', routeEquipment: res.data.data })
                setTotalPageEquipment(res.data.pageCount);
                if (pageNum * 1 <= res.data.pageCount * 1) {
                    setPageEquipment(pageNum + 1);
                }
            })
            .catch(() => {
                dispatch({ type: 'LOAD_MORE_EQUIPMENT', routeEquipment: [] });
            })
            .finally(() => {
                setLoading(false);
                dispatch({ type: 'LOADING_JOB', loadingJob: false })
            })
    }

    const getEmployees = (page) => {
        const pageNum = page || pageEmployees;
        if (pageNum > totalPageEmployees || loadingJob) return;
        dispatch({ type: 'LOADING_JOB', loadingJob: true })
        axios.get(apiConfig.url.USER_URL + apiConfig.url.COMPANY_EMPLOYEES_ROUTE,
            { params: { date: moment(date).utc().toISOString(), pageNumber: pageNum } })
            .then(res => {
                pageNum === 1 ?
                    dispatch({ type: 'INIT_EMPLOYEE', employees: res.data.users }) :
                    dispatch({ type: 'LOAD_MORE_EMPLOYEES', routeEmployees: res.data.users })
                setTotalPageEmployees(res.data.pageCount);
                if (pageNum * 1 <= res.data.pageCount * 1) {
                    setPageEmployees(pageNum + 1);
                }
            })
            .catch(() => {
                dispatch({ type: 'LOAD_MORE_EMPLOYEES', routeEmployees: [] });
            })
            .finally(() => {
                setLoading(false);
                dispatch({ type: 'LOADING_JOB', loadingJob: false })
            })
    }

    useEffect(() => {
        tab === 'to-be-scheduled' && dispatch({ type: 'INIT_JOB', routeJobs: [] })
        tab === 'available-equipments' && dispatch({ type: 'INIT_EQUIPMENT', routeEquipment: [] })
        tab === 'available-employees' && dispatch({ type: 'INIT_EMPLOYEE', employees: [] })
        setLoading(true);

        getJobs(1);
        getEquipments(1);
        getEmployees(1);
    }, [date])

    useEffect(() => {
        dispatch({ type: 'INIT_JOB', routeJobs: [] })
        dispatch({ type: 'INIT_EQUIPMENT', routeEquipment: [] })
        dispatch({ type: 'INIT_EMPLOYEE', employees: [] })
        getJobs();
    }, [])

    return (
        <>
            <Card>
                <CardContent style={{ paddingBottom: '16px' }}>
                    <div style={{
                        border: '1px solid #d6d6d6',
                        borderRadius: '4px',
                        overflow: 'hidden'
                    }}>
                        <div ref={drop[tab]}
                            onScroll={(e) => {
                                if (e.target !== e.currentTarget) return;
                                const bottom = e.target.scrollHeight - e.target.scrollTop === e.target.clientHeight;
                                if (bottom) {
                                    if (tab === 'to-be-scheduled') {
                                        getJobs();
                                    }
                                    if (tab === 'available-equipments') {
                                        getEquipments();
                                    }
                                    if (tab === 'available-employees') {
                                        getEmployees();
                                    }
                                }
                            }}
                            style={{
                                backgroundColor: isOver[tab] ? '#c5c5c5' : '#ffffff',
                                minHeight: '40px', maxHeight: '160px'
                            }}
                            className={classes.sources}>
                            {tab === 'to-be-scheduled' && (
                                <>
                                    {!tobeScheduledJobs || loading &&
                                        <div style={{ height: '40px', marginTop: '16px', textAlign: 'center' }}>
                                            <CircularProgress size={24} />
                                        </div>
                                    }
                                    {tobeScheduledJobs && tobeScheduledJobs.map((job, index) => (
                                        <DraggableSource
                                            setDrag={setDrag}
                                            key={`${job.jobSplitId}-${index}`}
                                            isSelected={selected.Job === index}
                                            type='Job'
                                            source={job}
                                            changeSelected={() => onChangeSelected('Job', index)}
                                            locateInRoute={() => {
                                                locateInRoute(job.jobSplitId, moment(date).utc().toISOString())
                                            }}
                                        />
                                    ))}
                                    {
                                        loadingJob &&
                                        <div style={{ height: '40px', marginTop: '16px', textAlign: 'center' }}>
                                            <CircularProgress size={24} />
                                        </div>
                                    }
                                </>
                            )}
                            {tab === 'available-employees' && (
                                <>
                                    {!routeEmployees || loading &&
                                        <div style={{ height: '40px', marginTop: '16px', textAlign: 'center' }}>
                                            <CircularProgress size={24} />
                                        </div>
                                    }
                                    {Array.isArray(routeEmployees) && routeEmployees.map((emp, index) => (
                                        <DraggableSource
                                            setDrag={setDrag}
                                            key={`${emp.id}-${index}`}
                                            isSelected={selected.Employee === index}
                                            type='Employee'
                                            source={emp}
                                            changeSelected={() => onChangeSelected('Employee', index)}
                                        />
                                    ))}
                                    {
                                        loadingJob &&
                                        <div style={{ height: '40px', marginTop: '16px', textAlign: 'center' }}>
                                            <CircularProgress size={24} />
                                        </div>
                                    }
                                </>
                            )}
                            {tab === 'available-equipments' && (
                                <>
                                    {!routeEquipment || loading &&
                                        <div style={{ height: '40px', marginTop: '16px', textAlign: 'center' }}>
                                            <CircularProgress size={24} />
                                        </div>
                                    }
                                    {Array.isArray(routeEquipment) && routeEquipment.map((eqp, index) => (
                                        <DraggableSource
                                            setDrag={setDrag}
                                            key={`${eqp.id}-${index}`}
                                            isSelected={selected.Equipment === index}
                                            type='Equipment'
                                            source={eqp}
                                            changeSelected={() => onChangeSelected('Equipment', index)}
                                        />
                                    ))}
                                    {
                                        loadingJob &&
                                        <div style={{ height: '40px', marginTop: '16px', textAlign: 'center' }}>
                                            <CircularProgress size={24} />
                                        </div>
                                    }
                                </>
                            )}
                        </div>
                    </div>
                </CardContent>
            </Card>

            <Snackbar open={openSnackbar}
                onClose={() => setOpenSnackbar(false)}
                autoHideDuration={2000}
                anchorOrigin={{ vertical: 'top', horizontal: 'center' }}>
                <Alert elevation={6} variant='filled' severity={isStatus.failed ? 'error' : 'success'}>
                    <Typography color="inherit" variant="h6">
                        {isStatus.msg}
                    </Typography>
                </Alert>
            </Snackbar>
        </>
    )
}

RoutesSource.propTypes = {
    tab: PropTypes.string,
}

export default RoutesSource;
