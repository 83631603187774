import React, { useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/styles';
import NumberFormat from 'react-number-format';
import { AddressInput, AvatarEditor, KeyboardDatePicker } from 'components'
import {
    Avatar,
    Card,
    CardHeader,
    CardContent,
    CardActions,
    Grid,
    TextField,
    Button,
    Divider,
    Typography
} from '@material-ui/core';
import Phone from './Phone';

const useStyles = makeStyles(theme => ({
    root: {
        marginTop: '24px'
    },
    cardProfile: {
        width: '100%'
    },
    profileName: {
        textAlign: 'center',
        marginTop: '20px',
        fontSize: theme.spacing(3),
        fontWeight: 500
    },
    hoverAvatar: {
        '&:hover': {
            cursor: 'pointer'
        }
    },
    groupDatePicker: {
        paddingRight: '12px',
        position: 'relative',
        [theme.breakpoints.down('xs')]: {
            paddingRight: '0',
            paddingBottom: '24px'
        }
    },
    bigAvatar: {
        margin: 'auto',
        width: theme.spacing(20),
        height: theme.spacing(20)
    }
}));

const Information = props => {

    const classes = useStyles();

    const { isRefresh, values, errors, touched, setFieldTouched, setFieldValue, onChangeAvatar, addressInputRef } = props;

    const [openAvatarModal, setOpenAvatarModal] = useState(false);
    const [avatar, setAvatar] = useState(null);

    const states = [...values.states];
    const languages = [...values.languageOptions];

    states.unshift({
        id: '',
        code: '',
        name: ''
    });
    languages.unshift({
        id: '',
        description: ''
    });
    // handle avatar
    const onUploadAvatar = () => {
        setOpenAvatarModal(true);
    }
    const onGetAvatar = (avatar, image) => {
        const img = avatar.getImageScaledToCanvas().toDataURL();
        setAvatar(img);
        onChangeAvatar(avatar, image);
    }
    const onRemoveAvatar = () => {
        setAvatar(null);
        onChangeAvatar(null);
    }

    // phone
    const onAddUserPhone = (values, setFieldValue) => {
        const phones = [...values.phones];
        phones.push({
            id: "00000000-0000-0000-0000-000000000000",
            formattedPhoneNumber: "",
            phoneNumber: "",
            type: null,
            typeId: values.phoneTypes[0].id
        });
        setFieldValue('phones', phones)
    }
    const onPhoneUpdate = (event, values, setFieldValue, index) => {
        const { name, value } = event.target;
        const phones = [...values.phones];
        phones[index] = { ...values.phones[index], [name]: value };
        setFieldValue('phones', phones);
    }
    const onPhoneRemove = (values, setFieldValue, index) => {
        const phones = [...values.phones];
        phones.splice(index, 1);
        setFieldValue('phones', phones);
    }

    useEffect(() => {
        setAvatar(null);
    }, [isRefresh])

    return (
        <>
            <Grid container spacing={4}>
                <Grid style={{ alignItems: 'flex-start' }} container item md={12} lg={3}>
                    <Card className={classes.cardProfile}>
                        <CardContent>
                            <div className={classes.hoverAvatar} onClick={onUploadAvatar}>
                                <Avatar alt="avatar" src={avatar} className={classes.bigAvatar} />
                            </div>
                            <Typography className={classes.profileName}>
                                {`${values.firstName || ''} ${values.middleName || ''} ${values.lastName || ''}`}
                            </Typography>
                        </CardContent>
                        <Divider />
                        <CardActions>
                            <Button style={{ width: '100%' }} onClick={avatar ? onRemoveAvatar : onUploadAvatar}>
                                {avatar ? 'remove avatar' : 'upload avatar'}
                            </Button>
                        </CardActions>
                    </Card>
                </Grid>
                <Grid container item md={12} lg={9}>
                    <Card>
                        <CardHeader title="Information" />
                        <Divider />
                        <CardContent>

                            <Grid
                                className={classes.container}
                                container
                                spacing={3}>
                                <Grid
                                    item
                                    xs={12}
                                    sm={4}>
                                    <TextField
                                        fullWidth
                                        error={errors.firstName && touched.firstName}
                                        helperText={(errors.firstName && touched.firstName) && errors.firstName}
                                        label="First Name"
                                        name="firstName"
                                        onBlur={() => setFieldTouched('firstName')}
                                        onChange={event => {
                                            setFieldValue('firstName', event.target.value)
                                            const address = { ...values.address };
                                            address.name = `${event.target.value} ${values.middleName} ${values.lastName}`;
                                            setFieldValue('address', address)
                                        }}
                                        value={values.firstName}
                                        variant="outlined"
                                    />
                                </Grid>


                                <Grid
                                    item
                                    xs={12}
                                    sm={4}>
                                    <TextField
                                        fullWidth
                                        label="Middle Name"
                                        name="middleName"
                                        onChange={event => {
                                            setFieldValue('middleName', event.target.value)
                                            const address = { ...values.address };
                                            address.name = `${values.firstName || ''} ${event.target.value || ''} ${values.lastName || ''}`;
                                            setFieldValue('address', address)
                                        }}
                                        value={values.middleName}
                                        variant="outlined"
                                    />
                                </Grid>

                                <Grid
                                    item
                                    xs={12}
                                    sm={4}>
                                    <TextField
                                        fullWidth
                                        error={errors.lastName && touched.lastName}
                                        helperText={(errors.lastName && touched.lastName) && errors.lastName}
                                        label="Last Name"
                                        name="lastName"
                                        onBlur={() => setFieldTouched('lastName')}
                                        onChange={event => {
                                            setFieldValue('lastName', event.target.value)
                                            const address = { ...values.address };
                                            address.name = `${values.firstName || ''} ${values.middleName || ''} ${event.target.value || ''}`;
                                            setFieldValue('address', address)
                                        }}
                                        value={values.lastName}
                                        variant="outlined"
                                    />
                                </Grid>

                                <Grid
                                    item
                                    md={12}
                                    xs={12}>
                                    <TextField
                                        fullWidth
                                        label="Address Name"
                                        name="name"
                                        onChange={event => {
                                            const address = { ...values.address };
                                            address.name = event.target.value;
                                            setFieldValue('address', address)
                                        }}
                                        value={values.address.name}
                                        variant="outlined" />
                                </Grid>

                                <Grid item xs={12}>
                                    <AddressInput
                                        ref={addressInputRef}
                                        errors={errors}
                                        values={values}
                                        touched={touched}
                                        setFieldValue={setFieldValue}
                                        setFieldTouched={setFieldTouched}
                                    />
                                    
                                </Grid>

                                <Grid
                                    item
                                    md={12}
                                    xs={12}>
                                    <TextField
                                        fullWidth
                                        error={errors.email && touched.email}
                                        helperText={(errors.email && touched.email) && errors.email}
                                        label="Email"
                                        name="email"
                                        onBlur={() => setFieldTouched('email')}
                                        onChange={event => setFieldValue('email', event.target.value)}
                                        value={values.email}
                                        variant="outlined"
                                    />
                                </Grid>
                                {/* Add phone */}
                                {values.phones.map((phone, index) => (
                                    <Phone key={`phones-${index}`}
                                        index={index}
                                        length={values.phones.length}
                                        phone={phone}
                                        errors={errors}
                                        touched={touched}
                                        setFieldTouched={setFieldTouched}
                                        phoneTypes={values.phoneTypes}
                                        onPhoneRemove={() => onPhoneRemove(values, setFieldValue, index)}
                                        onPhoneUpdate={event => onPhoneUpdate(event, values, setFieldValue, index)}
                                    />
                                ))}

                                <Grid
                                    item
                                    md={12}
                                    xs={12}>
                                    <Button
                                        onClick={() => onAddUserPhone(values, setFieldValue)}
                                        color="primary"
                                        variant="outlined">
                                        Add Phone
                                    </Button>
                                </Grid>


                                <Grid
                                    item
                                    sm={6}
                                    xs={12}
                                    className={classes.groupDatePicker}>
                                    <KeyboardDatePicker
                                        label='Birth Date'
                                        value={values.birthDate || null}
                                        onChange={date => setFieldValue('birthDate', date)}
                                    />
                                </Grid>

                                <Grid
                                    item
                                    sm={6}
                                    xs={12}>
                                    <TextField
                                        fullWidth
                                        label="Select Language"
                                        name="language"
                                        onChange={event => {
                                            setFieldValue('language', event.target.value)
                                            setFieldValue('languageId', event.target.value)
                                        }}
                                        select
                                        value={values.languageId || ''}
                                        SelectProps={{ native: true }}
                                        variant="outlined">
                                        {languages.map(option => (
                                            <option
                                                // selected={values.language === option.description}
                                                key={option.id}
                                                value={option.id}
                                            >
                                                {option.description}
                                            </option>
                                        ))}
                                    </TextField>
                                </Grid>

                                <Grid
                                    item
                                    xs={12}
                                    className={classes.groupDatePicker}>
                                    <KeyboardDatePicker
                                        label='Hired Date'
                                        value={values.hiredDate || null}
                                        onChange={date => setFieldValue('hiredDate', date)}
                                    />
                                </Grid>

                                <Grid
                                    item xs={12}>
                                    <NumberFormat
                                        customInput={TextField}
                                        allowNegative={false}
                                        isAllowed={({ value }) => value <= 32767}
                                        fullWidth
                                        label="AskTheSeal Employee Id"
                                        name="askTheSealId"
                                        onChange={event => setFieldValue('askTheSealId', event.target.value)}
                                        value={values.askTheSealId}
                                        variant="outlined"
                                    />
                                </Grid>

                                <Grid
                                    item
                                    md={12}
                                    xs={12}>
                                    <TextField
                                        fullWidth
                                        label="Comments"
                                        name="comments"
                                        onChange={event => setFieldValue('comments', event.target.value)}
                                        multiline
                                        rows={10}
                                        value={values.comments}
                                        variant="outlined"
                                    />
                                </Grid>

                            </Grid>

                        </CardContent>

                    </Card>
                </Grid>
            </Grid>

            <AvatarEditor
                open={openAvatarModal}
                onClose={() => setOpenAvatarModal(false)}
                onGetAvatar={onGetAvatar}
                type="new-avatar"
            />

        </>
    );
};



export default Information;
