import React, { useState } from 'react'
import PropTypes from 'prop-types'
import {
    Card, CardHeader, CardContent, Divider,
    Grid, TextField, Chip, FormControlLabel, useMediaQuery, Collapse
} from '@material-ui/core'
import { makeStyles } from '@material-ui/styles'
import { Autocomplete } from "@material-ui/lab";
import CancelRoundedIcon from '@material-ui/icons/CancelRounded';
import { GreenCheckbox } from 'components';
import { KeyboardArrowDown, KeyboardArrowUp } from '@material-ui/icons';
const useStyles = makeStyles(theme => ({
    root: {},
    ".tags-filled": {
        color: "red"
    }
}));

const Notes = props => {

    const classes = useStyles();
    const { note, onChange, onCheck, onChangeEquipment, disabled } = props;
    const {
        equipment, showEquipmentOnSchedule,
        jobNote, showJobNoteOnSchedule,
        jobsiteNote, showJobsiteNoteOnSchedule
    } = note;

    const selectedOptions = equipment ? equipment.split(';') : [];

    const sm = useMediaQuery(theme => theme.breakpoints.down('sm'));
    const [expand, setExpand] = useState(true);

    return (
        <Card style={{ height: '100%' }}>
            <CardHeader title='Notes'
                action={sm && (expand ? <KeyboardArrowUp /> : <KeyboardArrowDown />)}
                onClick={() => sm && setExpand(!expand)} />
            <Divider />
            <Collapse in={!sm || expand} timeout='auto' unmountOnExit>
                <CardContent>
                    <Grid container spacing={2}>
                        <Grid item xs={12}>
                            <FormControlLabel
                                disabled={disabled}
                                className={classes.checkbox}
                                name='showEquipmentOnSchedule'
                                checked={showEquipmentOnSchedule || false}
                                onChange={onCheck}
                                control={
                                    <GreenCheckbox />
                                }
                                label='Pop up when scheduling this job?'
                            />
                            <Autocomplete
                                multiple
                                disabled={disabled}
                                id="tags-filled"
                                options={[]}
                                defaultValue={selectedOptions}
                                value={selectedOptions}
                                freeSolo
                                onChange={(e, newValue) => {
                                    onChangeEquipment(newValue)
                                }}
                                onBlur={(e,v) => {
                                    let newValue = selectedOptions
                                    if(e.target.value.trim()){
                                        newValue.push(e.target.value)
                                        onChangeEquipment(newValue)
                                    }
                                }}
                                selectOnFocus={true}
                                renderTags={(value, getTagProps) =>
                                    value.map((option, index) => (
                                        <Chip deleteIcon={<CancelRoundedIcon style={{ color: '#546e7a' }} />} variant="outlined" label={option} {...getTagProps({ index })} />
                                    ))
                                }
                                renderInput={(params) => (
                                    <TextField {...params} variant="outlined" label="Equipment Needed" placeholder={!selectedOptions ? "Equipment Needed" : ''} />
                                )}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <FormControlLabel
                                disabled={disabled}
                                className={classes.checkbox}
                                name='showJobNoteOnSchedule'
                                checked={showJobNoteOnSchedule || false}
                                onChange={onCheck}
                                control={
                                    <GreenCheckbox />
                                }
                                label='Pop up when scheduling this job?'
                            />
                            <TextField
                                fullWidth
                                disabled={disabled}
                                multiline
                                rows={3}
                                label='Permanent Note'
                                name='jobNote'
                                variant='outlined'
                                value={jobNote || ''}
                                onChange={onChange}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <FormControlLabel
                                disabled={disabled}
                                className={classes.checkbox}
                                name='showJobsiteNoteOnSchedule'
                                checked={showJobsiteNoteOnSchedule || false}
                                onChange={onCheck}
                                control={
                                    <GreenCheckbox />
                                }
                                label='Pop up when scheduling this job?'
                            />
                            <TextField
                                fullWidth
                                disabled={disabled}
                                multiline
                                rows={3}
                                label='Today Only Note'
                                name='jobsiteNote'
                                variant='outlined'
                                value={jobsiteNote || ''}
                                onChange={onChange}
                            />
                        </Grid>
                    </Grid>
                </CardContent>
            </Collapse>
        </Card>
    )
}

Notes.propTypes = {
    note: PropTypes.object,
    onChange: PropTypes.func,
    onChangeEquipment: PropTypes.func,
    clearEquipment: PropTypes.func
}

export default Notes;
