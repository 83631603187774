import React from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/styles";
import { Tooltip } from "@material-ui/core";

const useStyles = makeStyles(theme => ({
    iconMonth: {
        display: 'flex',
        float: 'left',
        marginRight: 4
    }
}));
const StatusRoute = props => {
    const classes = useStyles();
    const { status } = props;
    return (
        <>
            <span className={classes.iconMonth}>
                {status && status.includes('empty') && (
                    <>
                        <Tooltip title="Route Not Full" arrow>
                            <span style={{ display: 'inline-flex' }} >
                                <i style={{ fontSize: '1.4em' }} className="fal fa-calendar" />
                            </span>
                        </Tooltip>
                    </>
                )}
                {status && status.includes('full') && (
                    <Tooltip title="Route Full" arrow>
                        <span style={{ display: 'inline-flex' }} >
                            <i style={{ fontSize: '1.4em', color: '#F72A0F' }} className="fal fa-calendar-alt" />
                        </span>
                    </Tooltip>
                )}
                {status && status.includes('complete') && (
                    <Tooltip title="Route Complete" arrow>
                        <span style={{ display: 'inline-flex' }} >
                            <i style={{ fontSize: '1.4em' }} className="fal fa-calendar-check" />
                        </span>
                    </Tooltip>
                )}
                {status && status.includes('recurring') && (
                    <Tooltip title="Recurring Route" arrow>
                        <span className="fa-stack fa-1x"
                            style={{ height: '1.4em', width: '1.5em', verticalAlign: 'center' }}>
                            <i style={{ fontSize: '1.4em' }} className="fal fa-calendar fa-stack-1x" />
                            <i className="fal fa-redo-alt fa-stack-1x"
                                style={{ fontSize: '1.4em', width: '0.45m', height: '0.4em', bottom: '-0.3em' }} />
                        </span>
                    </Tooltip>
                )}
            </span>
        </>
    )
}


StatusRoute.propTypes = {
    status: PropTypes.array
}

export default StatusRoute
