import React, { useEffect, useRef, useState, useCallback } from "react";
import { useHistory, useParams, Link, Redirect } from "react-router-dom";
import { makeStyles } from "@material-ui/styles";
import AddBoxIcon from "@material-ui/icons/AddBox";
import NavigateBeforeRoundedIcon from "@material-ui/icons/NavigateBeforeRounded";
import NavigateNextRoundedIcon from "@material-ui/icons/NavigateNextRounded";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import {
	Button,
	Card,
	CardContent,
	CircularProgress,
	colors,
	Divider,
	IconButton,
	Snackbar,
	TextField,
	Tooltip,
	Typography
} from "@material-ui/core";
import { Alert } from "@material-ui/lab";
import {
	ActionsMenu,
	RouteAction,
	RouteContent,
	RouteKeyboardDate,
	RouteTitle
} from "./components";
import moment from "moment";
import { useDispatch, useSelector } from "react-redux";
import axios from "utils/axios";
import apiConfig from "apiConfig";
import { route } from "mockup/Routes";
import { createTheme, MuiThemeProvider } from "@material-ui/core/styles";
import { PDFDownloadLink } from '@react-pdf/renderer';
import RoutesPdf from "./components/RoutesPdf";
import { saveAs } from 'file-saver';
import { pdf } from '@react-pdf/renderer';
import CloneModal from "./components/Modals/CloneModal";
import RecurModal from "./components/Modals/RecurModal";
import RouteNoteModal from "./components/Modals/RouteNoteModal";
import RoutePdf from "./components/RoutePdf";
import calendar from 'assets/images/calendar.svg'
import calendarAlt from 'assets/images/calendar-alt.svg'

const times = [
	"12:00 AM",
	"1:00 AM",
	"2:00 AM",
	"3:00 AM",
	"4:00 AM",
	"5:00 AM",
	"6:00 AM",
	"7:00 AM",
	"8:00 AM",
	"9:00 AM",
	"10:00 AM",
	"11:00 AM",
	"12:00 PM",
	"1:00 PM",
	"2:00 PM",
	"3:00 PM",
	"4:00 PM",
	"5:00 PM",
	"6:00 PM",
	"7:00 PM",
	"8:00 PM",
	"9:00 PM",
	"10:00 PM",
	"11:00 PM"
];

const useStyles = makeStyles(theme => ({
	routeDate: {
		display: "flex",
		alignItems: "flex-start"
	},
	groupDatePicker: {
		width: "100%",
		position: "relative"
	},
	iconDatePicker: {
		position: "absolute",
		right: "16px",
		top: "50%",
		transform: "translateY(-50%)"
	},
	routesOver: {
		width: "100%",
		overflowX: "visible",
		position: "relative"
	},
	actionBox: {
		width: "100%",
		height: "100%",
		display: "flex",
		justifyContent: "center",
		alignItems: "center",
		position: "absolute",
		top: 0,
		left: 0,
		zIndex: 2,
		backgroundColor: "#0000001f"
	},
	routesContent: {
		display: "flex",
		border: "1px solid #c7c7c7",
		borderRadius: "4px",
		marginBottom: theme.spacing(1)
	},
	routesContentAction: {
		width: "230px",
		borderLeft: "1px solid #c7c7c7"
	},
	routesContentLeft: {
		width: "200px",
		borderRight: "1px solid #c7c7c7"
	},
	routeTitle: {
		height: "40px",
		lineHeight: "24px",
		padding: "8px",
	},
	routesContentRight: {
		width: "calc(100% - 430px)",
		overflowX: "auto",
		overflowY: "hidden"
	},
	overlayBox: {
		width: "2400px"
	},
	times: {
		display: "flex",
		width: "100%",
		lineHeight: "24px",
		"& span": {
			height: "40px",
			lineHeight: "24px",
			padding: "8px 5px",
			width: "100px",
			borderBottom: "1px solid #c7c7c7",
			borderRight: "0.5px solid #c7c7c7",
			"&:last-child": {
				borderRight: "none"
			}
		}
	},
	routes: {
		display: "flex",
		justifyContent: "space-between",
		alignItems: "center",
		position: "relative",
		gap: 10
	},
	addBox: {
		padding: 0,
		color: colors.green[600],
		"&:hover": {
			color: colors.green[900]
		},
		"& span svg": {
			fontSize: "48px"
		}
	},
	btn: {
		width: "100%",
		color: theme.palette.white,
		backgroundColor: colors.green[600],
		"&:hover": {
			backgroundColor: colors.green[900]
		},
		padding: "3px 8px"
	},
	btnPurple: {
		width: "100%",
		color: theme.palette.white,
		backgroundColor: '#8a2be2',
		"&:hover": {
			backgroundColor: colors.purple[900]
		},
		padding: "3px 8px"
	},
	noFound: {
		fontSize: theme.spacing(2),
		fontWeight: 500
	},
	savingRoute: {
		position: "absolute",
		zIndex: 2,
		width: "100%",
		height: "100%",
		backgroundColor: "#0000001f",
		display: "flex",
		justifyContent: "center",
		alignItems: "center"
	},
	actionsRoute: { display: "flex", alignItems: "center", right: 16 },
	filter: {
		display: "flex",
		alignItems: "center"
	},
	noData: {
		textAlign: "center",
		fontWeight: 500,
		fontSize: 16
	},
	routeIcon: {
		'& i': {
			fontSize: '1em'
		}
	},
	routeTitleLeft: {
		height: 40,
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'space-between',
		borderBottom: '1px solid #c7c7c7',
		paddingRight: 8
	},
	routeTitleOrder: {
		fontSize: '20px',
		fontWeight: 400,
		paddingLeft: '3px',
	},
	routeActionsTop: {
		padding: '5px 11px 0px',
		display: 'flex',
		'& button:first-child': {
			marginRight: 9,
			fontSize: 12
		}
	}
}));

const lstStatus = [
	{
		id: "route-number",
		name: "Route Number"
	},
	{
		id: "availability",
		name: "Availability"
	}
];

const customThemeTooltip = createTheme({
	overrides: {
		MuiTooltip: {
			tooltip: {
				fontSize: "14px",
				maxWidth: "none"
			}
		}
	}
});

const RoutesBox = () => {
	const itemsRef = useRef([]);
	const classes = useStyles();
	const v_ipad = useMediaQuery("(max-width: 769px)");

	const history = useHistory();
	const dispatch = useDispatch();
	const { date } = useParams();
	const { routes, isUpdate, orderType } = useSelector(state => state.RoutesReducer);
	const { dayOff, routeId, dragging } = useSelector(state => state.EmployeesReducer);

	const [contentWidth, setContentWidth] = useState();
	const [isStatus, setIsStatus] = useState({ failed: false, msg: "" });
	const [openSnackbar, setOpenSnackbar] = useState(false);
	const [loading, setLoading] = useState(null);
	const [status, setStatus] = useState(lstStatus);
	const [statusRoutes, setStatusRoutes] = useState("route-number");
	const [routesFilter, setRoutesFilter] = useState([]);
	const [timeOff, setTimeOff] = useState([]);
	const [routeHoverId, setRouteHoverId] = useState("");
	const [loadingPdfRoutes, setLoadingPdfRoutes] = useState(null);
	const [dataPdfRoutes, setDataPdfRoutes] = useState([]);
	const [selectedRoute, setSelectedRoute] = useState({});
	const [openModal, setOpenModal] = useState(false);
	const [openModalRecur, setOpenModalRecur] = useState(false);
	const [openModalRouteNote, setOpenModalRouteNote] = useState(false);

	const [loadingPdfRoute, setLoadingPdfRoute] = useState(false);
	const [dataPdfRoute, setDataPdfRoute] = useState([]);

	const getDataPdfRoutesAll = (routeDate, fileName) => {
		setLoadingPdfRoutes(true);
		axios
			.get(apiConfig.url.BASE_URL + apiConfig.url.ROUTE_PRINT + `${routeDate}`)
			.then(res => {
				setDataPdfRoutes(res.data);
				generatePdfDocumentAll(res.data, fileName)
			});
	};

	const generatePdfDocumentAll = async (documentData, fileName) => {
		const blob = await pdf((
			<RoutesPdf dataPdfRoutes={documentData} />
		)).toBlob();
		saveAs(blob, fileName);
		setLoadingPdfRoutes(false)
	};

	const handlePrintRoute = (id) => {
		setLoadingPdfRoute(true);
		axios
			.get(apiConfig.url.BASE_URL + apiConfig.url.GET_ONE_ROUTE_PRINT + `${id}`)
			.then(res => {
				setDataPdfRoute(res.data);
				generatePdfDocument(res.data)
			});
	}

	const handleRedirectRoutePdf = (id) => {
		window.open("/route/pdf/" + id, '_blank');
	}

	const generatePdfDocument = async (documentData) => {
		const blob = await pdf((
			<RoutePdf routeInfoPdf={documentData} />
		)).toBlob();
		saveAs(blob, "Route.pdf");
		setLoadingPdfRoute(false);
	};

	// handle changing the date
	const previousDate = () => {
		const currDate = new Date(date);
		const prevDate = currDate.setDate(currDate.getDate() - 1);
		history.push(moment.utc(prevDate).format("YYYY-MM-DD"));
	};
	const nextDate = () => {
		const currDate = new Date(date);
		const nextDate = currDate.setDate(currDate.getDate() + 1);
		history.push(moment.utc(nextDate).format("YYYY-MM-DD"));
	};

	const onCloneRoute = route => {
		dispatch({ type: "ADD_ROUTE", route: { ...route, index: routes.length } });
	};

	const onRecurRoute = route => {
		dispatch({ type: "ADD_ROUTE", route: { ...route, index: routes.length } });
	};

	const onAddRoute = () => {
		dispatch({ type: "IS_UPDATE", status: true });
		axios
			.post(apiConfig.url.BASE_URL + apiConfig.url.ROUTE + date, route)
			.then(res => {
				dispatch({
					type: "ADD_ROUTE",
					route: { ...res.data, index: routes.length }
				});
			})
			.catch(() => {
				setIsStatus({
					failed: true,
					msg: "Add failed, please try again later."
				});
				setOpenSnackbar(true);
			})
			.finally(() => dispatch({ type: "IS_UPDATE", status: false }));
	};

	const onRemoveRoute = id => {
		dispatch({ type: "IS_UPDATE", status: true });
		axios
			.delete(apiConfig.url.BASE_URL + apiConfig.url.ROUTE + id)
			.then(() => {
				setIsStatus({ failed: false, msg: "Record Deleted" });
				setOpenSnackbar(true);
				dispatch({ type: "REMOVE_ROUTE", routeId: id });
			})
			.catch(() => {
				setIsStatus({
					failed: true,
					msg: "Delete failed, please try again later."
				});
				setOpenSnackbar(true);
			})
			.finally(() => dispatch({ type: "IS_UPDATE", status: false }));
	};

	const handleErrorOfRoute = error => {
		setIsStatus(error);
		setOpenSnackbar(true);
	};

	const updateFull = (type, id) => {
		const route = routes.find(route => route.id === id);
		if (!route) return;
		const newRoute = { ...route, routeFull: type === "full" };
		dispatch({ type: "IS_UPDATE", status: true });
		axios.put(apiConfig.url.BASE_URL + apiConfig.url.ROUTE + `${date}/` + id, newRoute)
			.then(value => {
				dispatch({ type: "UPDATE_ROUTE", routeId: id, route: newRoute });
				handleErrorOfRoute({
					failed: false,
					msg: "Update route successfuly.."
				});
			})
			.catch(() =>
				handleErrorOfRoute({
					failed: true,
					msg: "Update route failed, please try again later."
				})
			)
			.finally(() => dispatch({ type: "IS_UPDATE", status: false }));
	};

	const updateCommercialRoute = (route) => {
		dispatch({ type: 'IS_UPDATE', status: true });
		const newRoute = { ...route, isCommercial: !route.isCommercial };
		axios.put(apiConfig.url.BASE_URL + apiConfig.url.ROUTE + `${date}/` + route.id, newRoute)
			.then(res => {
				dispatch({ type: "UPDATE_ROUTE", routeId: route.id, route: newRoute });
				handleErrorOfRoute({
					failed: false,
					msg: "Update route successfuly.."
				});
			})
			.catch(() =>
				handleErrorOfRoute({
					failed: true,
					msg: "Update route failed, please try again later."
				})
			)
			.finally(() => dispatch({ type: "IS_UPDATE", status: false }));
	}

	const onAction = (type, route) => {
		switch (type) {
			case "full":
			case "open":
				updateFull(type, route.id);
				return;
			case "recur":
				setOpenModalRecur(true);
				setSelectedRoute(route)
				return;
			case "clone":
				setOpenModal(true);
				setSelectedRoute(route)
				return;
			case "remove":
				onRemoveRoute(route.id);
				return;
			case "completion":
				handleCompletion(route.id);
				return;
			case "commercial":
				updateCommercialRoute(route);
				return;
			case "print":
				handleRedirectRoutePdf(route.id);
				return;
			case "routeNote":
				setOpenModalRouteNote(true);
				setSelectedRoute(route)
				break;
			default:
				return;
		}
	};

	const filterRoute = () => {
		if (!routes || routes.length <= 0) {
			setRoutesFilter([]);
			return;
		}
		if (orderType !== "DISTANCE") {
			let routeActive = [...routes.filter(x => !x.routeFull)];
			let routeCompleted = [...routes.filter(x => x.routeFull)];
			routeActive = routeActive.map(x => {
				delete x.distance;
				return x;
			});
			routeCompleted = routeCompleted.map(x => {
				delete x.distance;
				return x;
			});
			if (statusRoutes === "availability") {
				routeActive = routeActive.map(x => {
					if (x.routeJobs.length > 1) {
						x["totalHour"] = x.routeJobs.reduce((r, a) => {
							return r + a.estimatedHours;
						}, 0);
					} else if (x.routeJobs.length === 1) {
						x["totalHour"] = x.routeJobs[0].estimatedHours;
					} else {
						x["totalHour"] = 0;
					}
					return x;
				});
				routeActive = routeActive.sort(
					(a, b) => parseFloat(a.totalHour) - parseFloat(b.totalHour)
				);
				setRoutesFilter([...routeActive, ...routeCompleted]);
			}
		}
		if (statusRoutes === "route-number" && !orderType) {
			const rt = routes.sort(
				(a, b) => parseFloat(a.routeOrder) - parseFloat(b.routeOrder)
			);
			setRoutesFilter([...rt]);
		}

		if (
			(statusRoutes === "distance" || statusRoutes === "") &&
			orderType === "DISTANCE"
		) {
			let routeHasJob = routes.filter(x => x.routeJobs.length > 0);
			let routeNoJob = routes.filter(x => x.routeJobs.length === 0);
			routeHasJob = routeHasJob.sort(
				(a, b) => parseFloat(a.distance) - parseFloat(b.distance)
			);
			setRoutesFilter([...routeHasJob, ...routeNoJob]);
		}

		setTimeout(() => {
			itemsRef.current.map(ref => {
				if (ref) {
					ref.scrollLeft = 800;
					if (orderType === "DISTANCE") {
						dispatch({ type: "SHOW_DISTANCE", showDistance: true });
					}
				}
			});
		}, 0);
	};

	const onDragEmployees = (dayOff, id = null) => {
		setRouteHoverId(id);
		if (date) {
			let tf = [...timeOff];
			times.forEach((time, index) => {
				let d = new Date(`${date} ${time}`);
				tf[index] = false;
				dayOff.forEach(off => {
					if (
						moment(off.start).format("YYYY-MM-DD") === date &&
						moment(off.end).format("YYYY-MM-DD")
					) {
						if (new Date(off.start) <= d && new Date(off.end) > d) {
							tf[index] = true;
						}
					}
				});
			});
			setTimeOff(tf);
		}
	};
	const handleCompletion = (id) => {
		const d = date.replace('-', '/');
		history.push({
			pathname: '/job-completion',
			state: {
				date: d,
				routeId: id
			}
		});
	}
	useEffect(() => {
		onDragEmployees(dayOff, routeId);
	}, [dayOff, dragging]);

	useEffect(() => {
		if (statusRoutes !== "distance" && orderType === "DISTANCE") {
			dispatch({ type: "CHANGE_ORDER", orderType: "" });
		}
		if (statusRoutes && orderType === "") {
			if (status[status.length - 1].id === "distance") {
				let l = status.slice(0, status.length - 1);
				setStatus(l);
			}
			// alert(3)
			filterRoute();
		}
	}, [statusRoutes]);

	useEffect(() => {
		if (orderType === "DISTANCE") {
			if (status[status.length - 1].id !== "distance") {
				let l = [
					...status,
					{
						id: "distance",
						name: "Distance"
					}
				];
				setStatus(l);
			}
			setStatusRoutes("distance");
		} else {
			// alert(2)
			filterRoute();
		}
	}, [orderType]);

	useEffect(() => {
		if (routes) {
			// alert(1)
			// debugger
			filterRoute();
		}
	}, [routes]);
	// GET route by date
	useEffect(() => {
		setLoading(true);
		axios
			.get(apiConfig.url.BASE_URL + apiConfig.url.ROUTE + `${date}/list`)
			.then(res => {
				res.data = res.data.filter(route => {
					return (
						moment(route.routeDate).format("DD/MM/YYYY") ===
						moment(date).format("DD/MM/YYYY")
					);
				});
				res.data.forEach(route => {
					route.routeJobs = route.routeJobs.filter(job => {
						return (
							moment(job.jobStart) >= moment(date).startOf("dates") &&
							moment(job.jobStart) <= moment(date).endOf("dates")
						);
					});
					route.routeJobs.map((item, index) => {
						route.routeJobs[index].price = item.job ? item.job.price : 0;
						const duration = moment.duration(
							moment
								.utc(item.jobEnd)
								.local()
								.diff(moment.utc(item.jobStart).local())
						);
						route.routeJobs[index].estimatedHours = duration.asMinutes() / 60;

						route.routeJobs[index].description = item.job
							? item.job.description
							: "";
						route.routeJobs[index].city = item.job && item.job.city ? item.job.city : "";
						route.routeJobs[index].jobStart = moment
							.utc(item.jobStart)
							.local()
							.toISOString();
						route.routeJobs[index].jobEnd = moment
							.utc(item.jobEnd)
							.local()
							.toISOString();
					});
				});

				dispatch({ type: "INIT_ROUTES", routes: res.data });
			})
			.finally(() => setLoading(false));
	}, [date]);

	// Set default scrollbar
	const widthRef = useCallback(node => {
		if (node !== null) {
			const width = node.offsetWidth;
			const defaultWidth = v_ipad ? width : width - 465;
			setContentWidth(defaultWidth);
		}
	}, [])

	return (
		<>
			<Card style={{ position: "relative", overflow: 'visible' }}>
				<CardContent className={classes.routes} style={v_ipad ? { flexDirection: "column" } : null}>
					<div className={classes.filter}>
						<TextField
							variant="outlined"
							select
							label={"Sort Order"}
							SelectProps={{ native: true }}
							value={statusRoutes || ""}
							onClick={e => e.stopPropagation()}
							onChange={event => {
								setStatusRoutes(event.target.value);
							}}
						>
							{status.map(st => (
								<option key={`${st.id}`} value={st.id}>
									{st.name}
								</option>
							))}
						</TextField>
					</div>
					<div className={classes.routeDate}>
						<IconButton style={{ width: "53px" }} onClick={previousDate}>
							<NavigateBeforeRoundedIcon />
						</IconButton>
						<RouteKeyboardDate
							value={date}
							onChange={day => day && history.push(day)}
						/>
						<IconButton style={{ width: "53px" }} onClick={nextDate}>
							<NavigateNextRoundedIcon />
						</IconButton>
					</div>
					<div className={classes.actionsRoute}>
						<Button
							variant={"contained"}
							disabled={!routes || routes.length <= 0}
							className={classes.btn}
							style={{ whiteSpace: "nowrap", height: "36px", marginRight: "6px" }}
						>
							<Link
								style={{ color: 'white', textDecoration: 'none' }}
								to={"/expanded-routes/pdf/" + date}
								target="_blank"
							>
								Print Expanded
							</Link>
						</Button>
						<Button
							variant={"contained"}
							disabled={!routes || routes.length <= 0}
							className={classes.btn}
							style={{ whiteSpace: "nowrap", height: "36px" }}
						>
							<Link
								style={{ color: 'white', textDecoration: 'none' }}
								to={"/all-routes/pdf/" + date}
								target="_blank"
							>
								Print All Routes
							</Link>
						</Button>
						{/* IF UN-COMMENT WILL ENABLE BUTTON DOWNLOAD PDF */}
						{/* {!loadingPdfRoutes
							? <Button
									onClick={() => { getDataPdfRoutesAll(date, "Routes_"+moment(date).format("MM_DD_YYYY")+".pdf") }}
									variant={"contained"}
									disabled={!routes || routes.length <= 0}
									className={classes.btn}
									style={{ whiteSpace: "nowrap", height: "36px" }}
								>
									Print All Routes
								</Button>
							: <Button
								variant={"contained"}
								disabled={loadingPdfRoutes}
								className={classes.btn}
								style={{ whiteSpace: "nowrap", height: "36px" }}
							>
								Loading
							</Button>
						} */}
						{/* END IF UN-COMMENT WILL ENABLE BUTTON DOWNLOAD PDF */}
						<MuiThemeProvider theme={customThemeTooltip}>
							<Tooltip arrow title={"Add Route"}>
								<IconButton
									className={classes.addBox}
									disabled={isUpdate}
									onClick={onAddRoute}
								>
									<AddBoxIcon />
								</IconButton>
							</Tooltip>
						</MuiThemeProvider>
					</div>
				</CardContent>
				<Divider />
				{loading ? (
					<div style={{ textAlign: "center", padding: 14 }}>
						<CircularProgress size={24} />
					</div>
				) : (
					<CardContent innerRef={widthRef} style={{ position: "relative" }}>
						{isUpdate && (
							<div className={classes.actionBox}>
								<CircularProgress size={32} />
							</div>
						)}
						{!routesFilter ||
							(routesFilter.length === 0 && (
								<Typography className={classes.noData}>
									No route found.
								</Typography>
							))}
						{Array.isArray(routesFilter) &&
							routesFilter.length > 0 &&
							routesFilter.map((route, index) => {
								return (
									<div key={route.id} className={classes.routesOver}>
										<div key={route.id} className={classes.routesContent}>
											{!v_ipad && (
												<div className={classes.routesContentLeft}>
													<div className={classes.routeTitleLeft}>
														<Typography className={classes.routeTitle}>
															Route
															<strong className={classes.routeTitleOrder}>{route.routeOrder}</strong>
														</Typography>
														{route.distance !== undefined && route.routeJobs.length > 0 &&
														<Typography style={{fontSize: '12px'}}>
															<span>Closest Job</span>
															<br />
															<span>{`${Math.round(route.distance)} Miles`}</span>
														</Typography>
														}
														<Typography style={{ display: 'flex' }}>
															{route.routeFull &&
																<Tooltip title="Route Full" arrow>
																	<img id={'full' + route.routeOrder}
																		className={classes.routeIcon}
																		src={calendarAlt}
																		alt='calendar'
																	/>
																</Tooltip>
															}
															{
																!route.routeFull &&
																<Tooltip title="Route Not Full" arrow>
																	<img id={'full' + route.routeOrder}
																		className={classes.routeIcon}
																		src={calendar}
																		alt='calendar'
																	/>
																</Tooltip>
															}
															{route.recurring &&
																<Tooltip title="Recurring Route" arrow>
																	<span className="fa-stack fa-1x" style={{ height: '1.4em', width: '1.5em', verticalAlign: 'center', fontSize: 21, color: '#000' }}>
																		<i style={{ fontSize: '1.4em' }} className="fal fa-calendar fa-stack-1x" />
																		<i className="fal fa-redo-alt fa-stack-1x"
																			style={{ fontSize: '1.4em', width: '0.45m', height: '0.4em', bottom: '-0.3em' }} />
																	</span>
																</Tooltip>
															}
														</Typography>

													</div>
													<RouteTitle route={route} />
												</div>
											)}
											<div
												ref={el => (itemsRef.current[index] = el)}
												className={classes.routesContentRight}
												style={v_ipad ? { width: "100%" } : null}
											>
												<div className={classes.overlayBox}>
													<Typography className={classes.times}>
														{times.map((time, index) => (
															<span
																style={{
																	background: routeHoverId
																		? timeOff[index] && route.id === routeId
																			? "#cccccc"
																			: "#ffffff"
																		: timeOff[index]
																			? "#cccccc"
																			: "#ffffff"
																}}
																key={`times-${index}`}
															>
																{time}
															</span>
														))}
													</Typography>
													<RouteContent
														route={route}
														routeWidth={contentWidth}
														error={handleErrorOfRoute}
													/>
												</div>
											</div>
											{!v_ipad && (
												<div className={classes.routesContentAction}>
													<Typography className={classes.routeActionsTop}>
														<Button variant="contained" className={route.isCommercial ? classes.btnPurple : classes.btn}
															onClick={() => updateCommercialRoute(route)}>
															Commercial
														</Button>
														<Button variant={"contained"}
															style={{ fontSize: "12px" }}
															className={classes.btn}
															onClick={() => onRemoveRoute(route.id)}
															disabled={
																route.routeEmployees.length > 0 ||
																route.routeJobs.length > 0 ||
																route.routeEquipment.length > 0
															}
														>
															Remove
														</Button>
													</Typography>
													<RouteAction
														loadingPdfRoute={loadingPdfRoute}
														date={date}
														action={onAction}
														router={route}
														handleCompletion={handleCompletion}
													/>
												</div>
											)}
										</div>
										{v_ipad && <ActionsMenu
											loadingPdfRoute={loadingPdfRoute}
											date={date}
											action={onAction}
											router={route}
											handleCompletion={handleCompletion}
											onRemove={() => onRemoveRoute(route.id)}
										/>}
									</div>
								);
							})}
					</CardContent>
				)}
			</Card>

			<CloneModal
				router={selectedRoute}
				open={openModal}
				onClose={() => setOpenModal(false)}
				addCloneRoute={onCloneRoute}
			/>

			<RecurModal
				router={selectedRoute}
				open={openModalRecur}
				onClose={() => setOpenModalRecur(false)}
				addCloneRoute={onRecurRoute}
			/>

			<RouteNoteModal
				open={openModalRouteNote}
				onClose={() => setOpenModalRouteNote(false)}
				router={selectedRoute}
			/>

			<Snackbar
				open={openSnackbar}
				onClose={() => setOpenSnackbar(false)}
				autoHideDuration={2000}
				anchorOrigin={{ vertical: "top", horizontal: "center" }}
			>
				<Alert
					elevation={6}
					variant="filled"
					severity={isStatus.failed ? "error" : "success"}
				>
					<Typography color="inherit" variant="h6">
						{isStatus.msg}
					</Typography>
				</Alert>
			</Snackbar>
		</>
	);
};

export default RoutesBox;
