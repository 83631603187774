import React from 'react'
import PropTypes from 'prop-types'
import {
    Modal,
    Card,
    CardContent,
    CardActions,
    Button,
    colors,
    Typography,
    Divider,
    CardHeader,
    CircularProgress
} from '@material-ui/core'
import { makeStyles } from '@material-ui/styles'

const useStyles = makeStyles(theme => ({
    styleModal: {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        outline: 'none',
        boxShadow: theme.shadows[20],
        width: 600,
        maxHeight: '100%',
        overflowY: 'auto',
        maxWidth: '100%',
        [theme.breakpoints.down('sm')]: {
            width: 500
        },
    },
    conButton: {
        padding: '16px',
        justifyContent: 'flex-end'
    },
    button: {
        flexShrink: '0',
        backgroundColor: colors.green[600],
        '&:hover': {
            backgroundColor: colors.green[900]
        },
    },
}))

const ConfirmModalFullTime = props => {
    const classes = useStyles();

    const { title, isOk, isProgress, openConfirm, closeConfirm, onConfirm } = props;

    return (
        <Modal
            open={openConfirm}
            onClose={closeConfirm}
        >
            <Card className={classes.styleModal}>
                <CardContent style={{ padding: '20px 16px', textAlign: 'center' }}>
                    <Typography style={{ fontSize: '16px' }}>Please choose an end date for the currently open schedule</Typography>
                </CardContent>
                <CardActions className={classes.conButton}>
                    <Button
                        variant="contained"
                        size="large"
                        onClick={closeConfirm}>
                        Cancel
                    </Button>
                </CardActions>
            </Card>
        </Modal>
    )
}

ConfirmModalFullTime.propTypes = {
    title: PropTypes.string,
    isOk: PropTypes.bool,
    isProgress: PropTypes.bool,
    openConfirm: PropTypes.bool.isRequired,
    closeConfirm: PropTypes.func.isRequired,
    onConfirm: PropTypes.func.isRequired
}

ConfirmModalFullTime.defaultProps = {
    title: 'Are you sure you want to delete this item?',
    isOk: true,
    isProgress: false,
    closeConfirm: () => { }
}

export default ConfirmModalFullTime;
