import React, { useEffect, useState, useRef } from 'react'
import { Link, Redirect, useHistory } from 'react-router-dom'
import { makeStyles } from '@material-ui/styles'
import moment from 'moment-timezone'
import NavigateBeforeRoundedIcon from '@material-ui/icons/NavigateBeforeRounded';
import NavigateNextRoundedIcon from '@material-ui/icons/NavigateNextRounded';
import { Alert } from '@material-ui/lab'
import {
    Card, CardContent, CardHeader,
    Divider, Grid, IconButton, Snackbar, Typography
} from '@material-ui/core'
import { KeyboardDatePicker, Page } from 'components';
import { ScheduledEmployees, RemainingEmployees } from './components';
import { ConfirmModal } from "components";
import apiConfig from 'apiConfig'
import axios from 'utils/axios'
import localStorage from 'utils/localStorage'
import { AccessControl } from "components/AccessControl/AccessControl";
import { PERMISSIONS } from "common/permissions";
import { errorMessages } from 'common/constants'
import ExistsTime from "./components/ExistsTime";
import ConfirmOvernightModal from "./components/ConfirmOvernightModal";
import {useSelector} from "react-redux";

const useStyles = makeStyles(theme => ({
    root: {
        padding: theme.spacing(5)
    },
    link: {
        fontSize: theme.spacing(2),
        marginBottom: theme.spacing(2),
        '& a': {
            color: '#263238',
            '&:hover': {
                textDecoration: 'underline'
            }
        }
    },
    dateSelected: {
        display: 'flex',
        alignItems: 'flex-start',
        margin: 'auto'
    },
}));

const EmployeeLogHours = ({ match }) => {
    const { select_date } = match.params;
    const classes = useStyles();

    const date = select_date ? select_date.replaceAll('-', '/') : moment(new Date().toLocaleString()).format('YYYY/MM/DD');
    const readOnly = localStorage.getUserRole() === PERMISSIONS.FIELD_TECHNICIAN;

    const [success, setSuccess] = useState({ failed: false, msg: '' })
    const [scheduledLogHours, setScheduledLogHours] = useState([]);
    const [remainingLogHours, setRemainingLogHours] = useState([]);
    const [openSnack, setOpenSnack] = useState(false);
    const [scheduledLoadingIndex, setScheduledLoadingIndex] = useState();
    const [clearScheduledIndex, setClearScheduledIndex] = useState();
    const [clearRemainingIndex, setClearRemainingIndex] = useState();
    const [remainingLoadingIndex, setRemainingLoadingIndex] = useState();
    const [scheduledLoadingAll, setScheduledLoadingAll] = useState();
    const [remainingLoadingAll, setRemainingLoadingAll] = useState();
    const [status, setStatus] = useState([]);
    const [loading, setLoading] = useState(false);
    const [checkError, setCheckError] = useState(false);
    const [checkErrorScheduled, setCheckErrorScheduled] = useState(false);
    const [openConfirm, setOpenConfirm] = useState(false);
    const [openConfirmOvernight, setOpenConfirmOvernight] = useState({ open: false, saveAll: false, data: {} });
    const [deletedEntry, setDeletedEntry] = useState();
    const [isDelete, setIsDelete] = useState(false);
    const [openModalExists, setOpenModalExists] = useState(false);
    const [typeInput, setTypeInput] = useState('');
    const history = useHistory();

    const { reloadLogHour } = useSelector(state => state.AppReducer);

    const previousDate = () => {
        const currDate = select_date ? moment(select_date).toDate() : new Date();
        const prevDate = currDate.setDate(currDate.getDate() - 1);
        history.push(moment(prevDate).format("YYYY-MM-DD"));
    }
    const nextDate = () => {
        const currDate = select_date ? moment(select_date).toDate() : new Date();
        const nextDate = currDate.setDate(currDate.getDate() + 1);
        history.push(moment(nextDate).format("YYYY-MM-DD"));
    }

    // logHour action
    const onChangeLog = (type, event, index, indexEmployees) => {
        const logHours = type === 'scheduled' ? [...scheduledLogHours] : [...remainingLogHours];
        const logEmps = type === 'scheduled' ? [...scheduledLogHours[indexEmployees].entries] : [...remainingLogHours[indexEmployees].entries];
        const { name, value } = event.target;
        switch (name) {
            case 'appliedHourlyRate':
                // logEmps[index].appliedHourlyRate = !logEmps[index].appliedHourlyRate;
                logEmps[index].appliedHourlyRate = logEmps[index].appliedHourlyRate ? !logEmps[index].appliedHourlyRate : true;
                logEmps[index]['changed'] = true;
                logHours[indexEmployees].entries = logEmps;
                type === 'scheduled' ? setScheduledLogHours(logHours) : setRemainingLogHours(logHours);
                break;
            case 'hoursLogEmployeeStatusId':
                if (value.length === 36) {
                    logEmps[index].hoursLogEmployeeStatusId = 2;
                    logEmps[index].jobSplitId = value;
                } else {
                    logEmps[index].hoursLogEmployeeStatusId = value;
                    logEmps[index].jobSplitId = null;
                }
                logEmps[index]['changed'] = true;
                logHours[indexEmployees].entries = logEmps;
                type === 'scheduled' ? setScheduledLogHours(logHours) : setRemainingLogHours(logHours);
                break;
            default:
                break;
        }
    }

    const onChangeTime = (type, name, time, index, indexEmployees) => {
        const date = select_date || moment(new Date()).format('YYYY-MM-DD');
        let inTime = time ? moment(`${date} ${time}`, 'YYYY-MM-DD h:mm A') : null;
        const logHours = type === 'scheduled' ? [...scheduledLogHours] : [...remainingLogHours];
        const logHour = logHours[indexEmployees];
        const logEmps = [...logHour.entries]
        for (let i = 0; i < logEmps.length; i++) {
            if (index != i) {
                if (
                    logEmps[i].workStart && logEmps[i].workEnd && new Date(logEmps[i].workStart) < new Date(inTime.toISOString()) && new Date(logEmps[i].workEnd) > new Date(inTime.toISOString()) ||
                    (name == 'workStart' && logEmps[index].workEnd && new Date(logEmps[i].workStart) > new Date(inTime.toISOString()) && new Date(logEmps[i].workEnd) < new Date(logEmps[index].workEnd)) ||
                    (name == 'workEnd' && logEmps[index].workStart && new Date(logEmps[i].workStart) > new Date(logEmps[index].workStart) && new Date(logEmps[i].workEnd) < new Date(inTime.toISOString()))
                ) {
                    inTime = null;
                    setTypeInput(name);
                    if (type === 'scheduled') {
                        setClearScheduledIndex(`${indexEmployees}${index}`);
                    }else {
                        setClearRemainingIndex(`${indexEmployees}${index}`);
                    }
                    setOpenModalExists(true)
                    break;
                }
            }
        }

        logEmps[index] = { ...logEmps[index], [name]: inTime ? inTime.toISOString() : null, changed: true };
        logHours[indexEmployees].entries = logEmps;

        type === 'scheduled' ? setScheduledLogHours(logHours) : setRemainingLogHours(logHours);
    }

    const onAdditional = (type, index, indexEmployees) => {
        const logHours = type === 'scheduled' ? [...scheduledLogHours] : [...remainingLogHours];
        const logEmps = [...logHours[indexEmployees].entries]
        const log = logEmps[index];
        const new_log = {
            ...log,
            id: null,
            workStart: null,
            workEnd: null,
            hoursLogEmployeeStatusId: '',
            jobSplitId: null
        }
        logEmps.splice(index, 1, log, new_log);
        logHours[indexEmployees].entries = logEmps;
        type === 'scheduled' ? setScheduledLogHours(logHours) : setRemainingLogHours(logHours);
    }

    const setRemoveLogHour = (type, id, employeeId, index, indexEmployees) => {
        setDeletedEntry({ type, employeeId, indexEmployees, id, index });
        setOpenConfirm(true);
    }

    const onRemove = () => {
        const logHours = deletedEntry.type === 'scheduled' ? [...scheduledLogHours] : [...remainingLogHours];
        setIsDelete(true);
        if (deletedEntry.id) {
            axios.delete(apiConfig.url.BASE_URL + apiConfig.url.EMPLOYEE_GET_LOGHOURS_REMOVE.replace('{EmployeeId}', deletedEntry.employeeId).replace('{id}', deletedEntry.id))
                .then(res => {
                    setOpenSnack(true)
                    setSuccess({ failed: false, msg: 'Record Removed' });

                    const logHour = logHours[deletedEntry.indexEmployees]
                    const logEmps = [...logHour.entries];
                    logEmps.splice(deletedEntry.index, 1);
                    logHour.entries = logEmps;
                    if (deletedEntry.type === 'scheduled') {
                        setScheduledLogHours(logHours);
                    } else {
                        setRemainingLogHours(logHours);
                    }
                }).catch(() => {
                }).finally(() => {
                    deletedEntry.type === 'scheduled' ? setScheduledLoadingIndex(null) : setRemainingLoadingIndex(null);
                    setOpenConfirm(false)
                    setIsDelete(false)
                })
        } else {
            const logHour = logHours[deletedEntry.indexEmployees];
            const logEmps = [...logHour.entries];
            logEmps.splice(deletedEntry.index, 1);
            logHour.entries = logEmps;
            deletedEntry.type === 'scheduled' ? setScheduledLogHours(logHours) : setRemainingLogHours(logHours);
            setOpenConfirm(false)
            setIsDelete(false)
        }
    }

    const confirmUpdate = (type, entry, employeeId, index, indexEmployees) => {
        if (
            !entry.workStart ||
            !entry.workEnd
        ) {
            setOpenSnack(true)
            setSuccess({ failed: true, msg: 'Please fill up all the row of the employee.' });
            return
        }
        const logHours = type === 'scheduled' ? scheduledLogHours : remainingLogHours;
        const logHour = logHours[indexEmployees];
        if (new Date(entry.workStart) > new Date(entry.workEnd)) {
            const workEnd = new Date(entry.workEnd);
            entry = {
                ...entry,
                workEnd: workEnd.setDate(workEnd.getDate() + 1) && workEnd.toISOString()
            };
            setOpenConfirmOvernight({
                open: true,
                saveAll: false,
                data: {
                    employee: `${logHour.lastName || ''}, ${logHour.firstName || ''}`,
                    workStart: entry.workStart,
                    workEnd: entry.workEnd,
                    updateData: {
                        type,
                        entry,
                        employeeId,
                        index,
                        indexEmployees
                    }
                }
            });
            return;
        }

        update(type, entry, employeeId, index, indexEmployees);
    }

    const update = (type, entry, employeeId, index, indexEmployees) => {
        const logHours = type === 'scheduled' ? [...scheduledLogHours] : [...remainingLogHours];
        const logHour = logHours[indexEmployees];
        const logEmps = [...logHour.entries];
        (type === 'scheduled' ? setScheduledLoadingIndex : setRemainingLoadingIndex)(`${indexEmployees}${index}`);
        const data = {
            ...entry,
            employeeId: employeeId,
            date: date,
            modifiedById: employeeId,
            createdById: employeeId
        }

        if (entry.id) {
            axios.put(apiConfig.url.BASE_URL + apiConfig.url.EMPLOYEE_GET_LOGHOURS_UPDATE + `${employeeId}/${entry.id}`, data)
                .then(res => {
                    if (res.data.isSuccess) {
                        setOpenSnack(true)
                        setSuccess({ failed: false, msg: 'Record Updated' });
                        setOpenConfirmOvernight({ open: false });
                        logEmps[index] = { ...logEmps[index], changed: false }
                        logHour.entries = logEmps;
                        type === 'scheduled' ? setScheduledLogHours(logHours) : setRemainingLogHours(logHours);
                        fetchLogHours();
                    } else {
                        setOpenSnack(true)
                        setSuccess({ failed: true, msg: 'Payroll has not been enabled yet. Please contact the administrator.' });
                    }
                }).catch(() => {
                    setOpenSnack(true)
                    setSuccess({ failed: true, msg: 'Update failed.' });
                }).finally(() => {
                    type === 'scheduled' ? setScheduledLoadingIndex(null) : setRemainingLoadingIndex(null);
                })
        } else {
            axios.post(apiConfig.url.BASE_URL + apiConfig.url.EMPLOYEE_GET_LOGHOURS_ADD + employeeId, data)
                .then(res => {
                    if (res.data.isSuccess) {
                        setOpenSnack(true)
                        setSuccess({ failed: false, msg: 'Add successfully.' });
                        setOpenConfirmOvernight({ open: false });
                        logEmps[index] = { ...logEmps[index], id: res.data.id, changed: false }
                        logHour.entries = logEmps;
                        type === 'scheduled' ? setScheduledLogHours(logHours) : setRemainingLogHours(logHours);
                        fetchLogHours();
                    } else {
                        setOpenSnack(true)
                        setSuccess({ failed: true, msg: 'Payroll has not been enabled yet. Please contact the administrator.' });
                    }
                }).catch(() => {
                    setOpenSnack(true)
                    setSuccess({ failed: true, msg: 'Add failed.' });
                }).finally(() => {
                    type === 'scheduled' ? setScheduledLoadingIndex(null) : setRemainingLoadingIndex(null);
                })
        }
    }

    const checkValidSaveAll = (type) => {
        const entries = [];
        const logHours = type === 'scheduled' ? [...scheduledLogHours] : [...remainingLogHours];
        // get logHour that have entries changed
        const logHoursChanged = logHours.map(lg => {
            const entriesChanged = lg.entries.reduce((filtered, e, i) => {
                e.entryIndex = i;
                if (e.changed) {
                    const workEnd = new Date(e.workEnd);
                    if (new Date(e.workStart) > workEnd) workEnd.setDate(workEnd.getDate() + 1);
                    entries.push({
                        employeeId: lg.employeeId,
                        employeeName: `${lg.lastName || ''}, ${lg.firstName || ''}`,
                        entryIndex: i,
                        workStart: e.workStart,
                        workEnd:  workEnd.toISOString(),
                        confirmed: new Date(e.workStart) <= new Date(e.workEnd)
                    })
                    return filtered.concat({ ...e, workEnd: workEnd.toISOString() });
                }

                return filtered;
            }, []);
            if (entriesChanged.length > 0) return { ...lg, entries: entriesChanged }
        }).filter(lg => lg);

        // check valid logHour
        const invalid = logHoursChanged.find(l => !!l.entries.find(e =>
            !e.workStart && e.workEnd || e.workStart && !e.workEnd
        ))
        if (invalid) {
            type === 'scheduled' ? setCheckErrorScheduled(!checkErrorScheduled) : setCheckError(!checkError);
            setOpenSnack(true)
            setSuccess({ failed: true, msg: errorMessages.FIELD_CHECK });
            return;
        }

        confirmSaveAll(type, logHoursChanged, entries);
    }

    const confirmSaveAll = (type, logHoursChanged, logHoursChangedEntries) => {
        const notConfirmed = logHoursChangedEntries.find(lg => !lg.confirmed);
        if (notConfirmed) {
            notConfirmed.confirmed = true;
            setOpenConfirmOvernight({
                open: true,
                saveAll: true,
                data: {
                    employee: notConfirmed.employeeName,
                    workStart: notConfirmed.workStart,
                    workEnd: notConfirmed.workEnd,
                    saveAllData: {
                        type,
                        logHoursChanged,
                        logHoursChangedEntries,
                        employeeId: notConfirmed.employeeId,
                        entryIndex: notConfirmed.entryIndex
                    }
                }
            });
            return;
        }

        setOpenConfirmOvernight({ open: false });
        saveAll(type, logHoursChanged, logHoursChangedEntries);
    }

    const saveAll = (type, logHoursChanged, logHoursChangedEntries) => {
        logHoursChanged = logHoursChanged.filter(lg => lg.entries.length > 0);
        const logHours = type === 'scheduled' ? [...scheduledLogHours] : [...remainingLogHours];

        type === 'scheduled' ? setScheduledLoadingAll(true) : setRemainingLoadingAll(true);
        const data = type === 'scheduled' ? { scheduledEmployees: logHoursChanged } : { remainingEmployees: logHoursChanged };
        axios.put(apiConfig.url.BASE_URL + apiConfig.url.EMPLOYEE_GET_LOGHOURS_ALL, data)
            .then(res => {
                if (res.data.isSuccess) {
                    setSuccess({ failed: false, msg: 'Record Updated' });
                    setOpenSnack(true);
                    // update into the list logHours
                    for (let index = 0; index < logHoursChangedEntries.length; index++) {
                        const entry = logHoursChangedEntries[index];
                        const logHour = logHours.find(lh => lh.employeeId === entry.employeeId);
                        const logEntries = [...logHour.entries];
                        logEntries[entry.entryIndex] = {
                            ...logEntries[entry.entryIndex],
                            id: res.data.ids[index],
                            changed: false
                        }
                        logHour.entries = logEntries
                    }

                    type === 'scheduled' ? setScheduledLogHours(logHours) : setRemainingLogHours(logHours);
                    fetchLogHours();
                } else {
                    setOpenSnack(true)
                    setSuccess({ failed: true, msg: 'Payroll has not been enabled yet. Please contact the administrator.' });
                }

            }).finally(() => {
                type === 'scheduled' ? setScheduledLoadingAll(false) : setRemainingLoadingAll(false);
            })
    }

    const getEntries = (logHour) => {
        if (logHour.entries && logHour.entries.length > 0) {
            return logHour.entries.map(e => {
                var dateTmp = new Date(date).toISOString();
                e.overnightBefore = moment(e.workStart) < moment(dateTmp);
                e.overnightAfter = moment(e.workEnd) > moment(dateTmp).add(1, 'days');
                return e;
            });
        } else {
            return [
                {
                    workStart: null,
                    workEnd: null,
                    hoursLogEmployeeStatusId: '',
                    appliedHourlyRate: logHour.appliedHourlyRate,
                }
            ];
        }
    }

    const fetchStatus = () => {
        axios.get(apiConfig.url.BASE_URL + apiConfig.url.EMPLOYEE_GET_LOGHOURS_STATUS)
            .then(res => {
                setStatus(res.data);
            });
    }

    const fetchLogHours = () => {
        setLoading(true);
        axios.get(apiConfig.url.BASE_URL + apiConfig.url.EMPLOYEE_LOG_HOURS + new Date(date).toISOString())
            .then(res => {
                const scheduledLogHours = res.data.scheduledEmployees.map(em => {
                    const entries = getEntries(em)
                    return { ...em, entries }
                });

                const remainingLogHours = res.data.remainingEmployees.map(em => {
                    const entries = getEntries(em);
                    return { ...em, entries }
                });
                setRemainingLogHours(remainingLogHours);
                setScheduledLogHours(scheduledLogHours);
            }).finally(() => {
                setLoading(false)
            })
    }


    let clearStart = null;
    let clearEnd = null;
    const handClearStart = () => {
        if (clearStart) {
            clearStart();
        }
    };
    const handClearEnd = () => {
        if (clearEnd) {
            clearEnd();
        }
    };
    const bindClearStart = fn => {
        clearStart = fn;
    };
    const bindClearEnd = fn => {
        clearEnd = fn;
    };

    const userRoles = localStorage.getUserRoles();

    useEffect(() => {
        fetchStatus();
        fetchLogHours();
    }, [select_date, reloadLogHour])

    if (!select_date) {
        return <Redirect to={`/employees/log-hours/${(moment(new Date()).format('YYYY-MM-DD'))}`} />;
    }
    let backButton;
    if(userRoles.includes(PERMISSIONS.COMPANY_ADMINISTRATOR) || userRoles.includes(PERMISSIONS.SUPER_ADMIN || userRoles.includes(PERMISSIONS.OFFICE_STAFF))){
        backButton = <Link to='/employees'>Back</Link>;
    }

    return (
        <AccessControl requiredPermission={PERMISSIONS.page.logHours} redirect>
            <Page title='Employee - Log hours'>
                <div className={classes.root}>
                    <Typography className={classes.link}>

                    {backButton}

                    </Typography>
                    <Card>
                        <CardHeader title="Employee Log Hours" />
                        <Divider />
                        <CardContent style={{ paddingBottom: 16 }}>
                            <Grid container>
                                <Grid item xs={12} sm={8} md={4} className={classes.dateSelected}>
                                    <IconButton
                                        style={{ width: '53px' }}
                                        onClick={previousDate}>
                                        <NavigateBeforeRoundedIcon />
                                    </IconButton>
                                    <KeyboardDatePicker
                                        value={date}
                                        onChange={date => {
                                            history.push(moment(new Date(date)).format("YYYY-MM-DD"));
                                        }}
                                    />
                                    <IconButton
                                        disabled={date === moment(new Date()).format('YYYY/MM/DD')}
                                        style={{ width: '53px' }}
                                        onClick={nextDate}>
                                        <NavigateNextRoundedIcon />
                                    </IconButton>
                                </Grid>
                            </Grid>
                        </CardContent>
                    </Card>

                    <ScheduledEmployees
                        clearScheduledIndex={clearScheduledIndex}
                        bindClearStart={bindClearStart}
                        bindClearEnd={bindClearEnd}
                        readOnly={readOnly}
                        loading={loading}
                        loadingAll={scheduledLoadingAll}
                        scheduledLogHours={scheduledLogHours}
                        loadingIndex={scheduledLoadingIndex}
                        status={status}
                        update={confirmUpdate}
                        onChangeLog={onChangeLog}
                        onChangeTime={onChangeTime}
                        onAdditional={onAdditional}
                        onRemove={setRemoveLogHour}
                        saveAll={checkValidSaveAll}
                        checkError={checkErrorScheduled}
                    />

                    <RemainingEmployees
                        clearRemainingIndex={clearRemainingIndex}
                        bindClearStart={bindClearStart}
                        bindClearEnd={bindClearEnd}
                        readOnly={readOnly}
                        loading={loading}
                        loadingAll={remainingLoadingAll}
                        remainingLogHours={remainingLogHours}
                        loadingIndex={remainingLoadingIndex}
                        status={status}
                        update={confirmUpdate}
                        onChangeLog={onChangeLog}
                        onChangeTime={onChangeTime}
                        onAdditional={onAdditional}
                        onRemove={setRemoveLogHour}
                        saveAll={checkValidSaveAll}
                        checkError={checkError}
                    />

                    <Snackbar
                        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
                        open={openSnack}
                        autoHideDuration={2000}
                        onClose={() => setOpenSnack(false)}>
                        <Alert
                            elevation={6} variant="filled" severity={success.failed ? 'error' : 'success'}>
                            <Typography
                                color="inherit"
                                variant="h6">
                                {success.msg}
                            </Typography>
                        </Alert>
                    </Snackbar>
                    <ExistsTime
                        openConfirm={openModalExists}
                        closeConfirm={() => {
                            setOpenModalExists(false);
                            if (typeInput == 'workStart') {
                                handClearStart();
                            } else {
                                handClearEnd();
                            }
                        }}
                    >

                    </ExistsTime>

                    <ConfirmModal
                        openConfirm={openConfirm}
                        closeConfirm={() => setOpenConfirm(false)}
                        onConfirm={onRemove}
                        isProgress={isDelete}
                    />

                    <ConfirmOvernightModal
                        openConfirm={openConfirmOvernight.open}
                        data={openConfirmOvernight.data}
                        closeConfirm={() => {
                            const { saveAll, data: { saveAllData } } = openConfirmOvernight;
                            setOpenConfirmOvernight({ open: false });
                            if (saveAll && saveAllData) {
                                const logHours = saveAllData.logHoursChanged.find(lg => lg.employeeId == saveAllData.employeeId);
                                const idx = logHours.entries.findIndex(e => e.entryIndex == saveAllData.entryIndex);
                                logHours.entries.splice(idx, 1);
                                confirmSaveAll(saveAllData.type, saveAllData.logHoursChanged, saveAllData.logHoursChangedEntries);
                            }
                        }}
                        onConfirm={openConfirmOvernight.saveAll ? confirmSaveAll : update}
                        isProgress={!!scheduledLoadingIndex || !!remainingLoadingIndex}
                    />
                </div>
            </Page>
        </AccessControl>
    )
}

export default EmployeeLogHours;
