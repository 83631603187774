import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import PropTypes from 'prop-types'
import { Typography, colors } from '@material-ui/core'
import { makeStyles } from '@material-ui/styles'
import moment from 'moment'
import DropBox from './DropBox'
import { DraggableBox } from 'components'
import { useDrop } from 'react-dnd'
import { useSelector, useDispatch } from 'react-redux'
import apiConfig from 'apiConfig'
import axios from 'utils/axios'
import { formatTwoPDecimal } from "utils/formatNumber";

const useStyles = makeStyles(theme => ({
    route: {
        width: '100%',
        minHeight: '180px',
        borderBottom: '1px solid #c7c7c7',
        '&:last-child': {
            borderBottom: 'none'
        }
    },
    routeJob: {
        width: '100%',
        display: 'flex',
        borderBottom: '1px solid #c7c7c7'
    },
    routeDetail: {
        display: 'inline-block',
        paddingLeft: '10px',
        height: '40px',
        lineHeight: '40px',
        position: '-webkit-sticky',
        position: 'sticky',
        left: '0'
    },
    jobBox: {
        display: 'flex',
        alignItems: 'center',
        width: '100%',
        height: '60px',
        position: 'relative',
        borderRight: '1px solid #c7c7c7'
    },
    job: {
        display: 'inline',
        position: 'absolute'
    },
    empeqp: {
        alignItems: 'center',
        color: theme.palette.white,
        padding: '2px 8px',
        borderRadius: '4px',
        marginRight: '5px'
    },
    bgGreen: {
        backgroundColor: colors.green[500],
    },
    bgPurple: {
        backgroundColor: '#8a2be2',
    },
    jobEquipments: {
        display: 'inline-flex',
        backgroundColor: '#bcbcbc',
        padding: '2px 8px',
        borderRadius: '4px',
        lineHeight: '22px',
        marginRight: 5
    }
}));

const times = [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23];

const RouteContent = props => {

    const classes = useStyles();
    const dispatch = useDispatch();
    const { date } = useParams();

    const { route, routeWidth, error } = props;

    const { routes } = useSelector(state => state.RoutesReducer)
    const { routeEmployees } = useSelector(state => state.EmployeesReducer);
    const { routeEquipment } = useSelector(state => state.EquipmentsReducer);

    const [jobEquipments, setJobEquipments] = useState('');

    const [{ isOverEmployee }, dropEmployee] = useDrop({
        accept: 'Employee',
        drop: item => onDropEmployee(item, route.id),
        collect: (monitor) => ({
            isOverEmployee: monitor.isOver()
        })
    });
    const [{ isOverEquipment }, dropEquipment] = useDrop({
        accept: 'Equipment',
        drop: item => onDropEquipment(item, route.id),
        collect: (monitor) => ({
            isOverEquipment: monitor.isOver()
        })
    });

    const [topJobId, setTopJobId] = useState('');

    const onDropEmployee = ({ id, oldRouteId }, routeId) => {
        const route = routes.find(route => route.id === routeId);
        if (oldRouteId === undefined) {
            const ep = routeEmployees.find(emp => emp.id === id);
            let employee = { ...ep, employeeId: ep.id, phoneNumber: ep.phones && ep.phones[0] ? ep.phones[0].formattedPhoneNumber : '' }

            const routeJob = route.routeJobs;
            routeJob.map(job => {
                const localEndDate = new Date(job.jobStart);
                job.estimatedHours = formatTwoPDecimal(job.job.estimatedHours * ((route.routeEmployees.length === 0 ? 1 : route.routeEmployees.length) / (route.routeEmployees.length + 1)));

                // job.estimatedHours = formatTwoPDecimal(job.estimatedHours * ( route.routeEmployees.length / (route.routeEmployees.length + 1)));
                const hour = localEndDate.getHours() + Math.round((localEndDate.getMinutes() / 60) * 100) / 100 + (job.estimatedHours);
                localEndDate.setHours(Math.floor(hour));
                localEndDate.setMinutes(Math.round((hour - Math.floor(hour)) * 60));
                // localEndDate.setHours((hour) - Math.floor(hour) > 0.5 ? Math.floor(hour) + 1 : Math.floor(hour));
                // localEndDate.setMinutes((hour) - Math.floor(hour) > 0 && (hour) - Math.floor(hour) <= 0.5 ? 30 : 0);
                job.jobEnd = new Date(localEndDate).toISOString();
            })
            const newRouteData = {
                ...route,
                routeEquipment: !route.routeEquipment ? [] : [...route.routeEquipment],
                routeEmployees: route.routeEmployees ? [...route.routeEmployees, employee] : [employee],
                // set routeJobs is null to not update in server side
                routeJobs: null,
            };
            dispatch({ type: 'IS_UPDATE', status: true });
            axios.put(apiConfig.url.BASE_URL + apiConfig.url.ROUTE + `${date}/` + routeId, newRouteData)
                .then(res => {
                    dispatch({ type: 'ADD_EMP_ROUTE', routeId, employee });
                    dispatch({ type: 'REMOVE_EMPLOYEE', employeeId: id })
                    error({ failed: false, msg: 'Update route successfuly.' })
                })
                .catch(() => error({ failed: true, msg: 'Update route failed, please try again later.' }))
                .finally(() => dispatch({ type: 'IS_UPDATE', status: false }))
        } else if (oldRouteId !== routeId) {
            const oldRoute = routes.find(route => route.id === oldRouteId);
            const route = routes.find(route => route.id === routeId);
            const employee = oldRoute.routeEmployees.find(emp => emp.employeeId === id);

            // get old routes
            const routeJobOld = oldRoute.routeJobs;
            routeJobOld.map(job => {
                const localEndDate = new Date(job.jobStart);
                job.estimatedHours = formatTwoPDecimal(job.estimatedHours * (oldRoute.routeEmployees.length === 0 ? 1 : oldRoute.routeEmployees.length / (oldRoute.routeEmployees.length - 1 <= 0 ? 1 : oldRoute.routeEmployees.length - 1)));
                const hour = localEndDate.getHours() + Math.round((localEndDate.getMinutes() / 60) * 100) / 100 + (job.estimatedHours);
                localEndDate.setHours(Math.floor(hour));
                localEndDate.setMinutes(Math.round((hour - Math.floor(hour)) * 60));
                job.jobEnd = new Date(localEndDate).toISOString();
            })

            const oldRouteData = {
                ...oldRoute,
                routeEquipment: !oldRoute.routeEquipment ? [] : [...oldRoute.routeEquipment],
                routeEmployees: oldRoute.routeEmployees.filter(emp => emp.employeeId !== id),
                routeJobs: !route.routeJobs ? [] : [...routeJobOld]
            };

            // get new routes
            const routeJobNew = route.routeJobs;
            routeJobNew.map(job => {
                const localEndDate = new Date(job.jobStart);
                job.estimatedHours = formatTwoPDecimal(job.estimatedHours * (route.routeEmployees.length === 0 ? 1 : route.routeEmployees.length / (route.routeEmployees.length + 1)));
                const hour = localEndDate.getHours() + Math.round((localEndDate.getMinutes() / 60) * 100) / 100 + (job.estimatedHours);
                localEndDate.setHours(Math.floor(hour));
                localEndDate.setMinutes(Math.round((hour - Math.floor(hour)) * 60));
                job.jobEnd = new Date(localEndDate).toISOString();
            })

            const newRouteData = {
                ...route,
                routeEquipment: !route.routeEquipment ? [] : [...route.routeEquipment],
                routeEmployees: route.routeEmployees ? [...route.routeEmployees, employee] : [employee],
                routeJobs: !route.routeJobs ? [] : [...routeJobNew]
            };
            const requestForOldRoute = axios.put(apiConfig.url.BASE_URL + apiConfig.url.ROUTE + `${date}/` + oldRouteId, oldRouteData);
            const requestForNewRoute = axios.put(apiConfig.url.BASE_URL + apiConfig.url.ROUTE + `${date}/` + routeId, newRouteData);
            dispatch({ type: 'IS_UPDATE', status: true });
            Promise.all([requestForOldRoute, requestForNewRoute])
                .then(values => {
                    dispatch({ type: 'CHANGE_EMP_ROUTE', oldRouteId, routeId, employee });
                    error({ failed: false, msg: 'Update route successfuly.' })
                })
                .catch(() => error({ failed: true, msg: 'Update route failed, please try again later.' }))
                .finally(() => dispatch({ type: 'IS_UPDATE', status: false }))
        }
    }
    const onDropEquipment = ({ id, oldRouteId }, routeId) => {
        const route = routes.find(route => route.id === routeId);
        if (oldRouteId === undefined) {
            const equipment = routeEquipment.find(eqp => eqp.id === id);
            const newRouteData = {
                ...route,
                routeEquipment: route.routeEquipment ? [...route.routeEquipment, equipment] : [equipment],
                routeEmployees: !route.routeEmployees ? [] : [...route.routeEmployees],
                // set routeJobs is null to not update in server side
                routeJobs: null,
            };
            dispatch({ type: 'IS_UPDATE', status: true });
            axios.put(apiConfig.url.BASE_URL + apiConfig.url.ROUTE + `${date}/` + routeId, newRouteData)
                .then(res => {
                    dispatch({ type: 'ADD_EQP_ROUTE', routeId, equipment });
                    dispatch({ type: 'REMOVE_EQUIPMENT', routeId, equipment });
                    error({ failed: false, msg: 'Update route successfuly.' })
                })
                .catch(() => error({ failed: true, msg: 'Update route failed, please try again later.' }))
                .finally(() => dispatch({ type: 'IS_UPDATE', status: false }))
        } else if (oldRouteId !== routeId) {
            const oldRoute = routes.find(route => route.id === oldRouteId);
            const equipment = oldRoute.routeEquipment.find(eqp => eqp.id === id);
            const oldRouteData = {
                ...oldRoute,
                // routeEmployees: !route.routeEmployees ? [] : [...route.routeEmployees],
                // routeJobs: !route.routeJobs ? [] : [...route.routeJobs],
                routeEquipment: oldRoute.routeEquipment.filter(eqp => eqp.id !== id)
            };
            const newRouteData = {
                ...route,
                // routeEmployees: !route.routeEmployees ? [] : [...route.routeEmployees],
                // routeJobs: !route.routeJobs ? [] : [...route.routeJobs],
                routeEquipment: route.routeEquipment ? [...route.routeEquipment, equipment] : [equipment]
            };
            const requestForOldRoute = axios.put(apiConfig.url.BASE_URL + apiConfig.url.ROUTE + `${date}/` + oldRouteId, oldRouteData);
            const requestForNewRoute = axios.put(apiConfig.url.BASE_URL + apiConfig.url.ROUTE + `${date}/` + routeId, newRouteData);
            dispatch({ type: 'IS_UPDATE', status: true });
            Promise.all([requestForOldRoute, requestForNewRoute])
                .then(values => {
                    dispatch({ type: 'CHANGE_EQP_ROUTE', oldRouteId, routeId, equipment });
                    error({ failed: false, msg: 'Update route successfuly.' })
                })
                .catch(() => error({ failed: true, msg: 'Update route failed, please try again later.' }))
                .finally(() => dispatch({ type: 'IS_UPDATE', status: false }))
        }
    }

    const getFullName = employee => {
        if (employee) {
            let days = [];
            employee.dayOff.forEach(day => {
                days.push(`${moment(day.start).format('hh:mm A')} - ${moment(day.end).format('hh:mm A')}`)
            })
            const dayString = days.length > 0 ? ` | Unavailable: ${days.join(', ')}` : '';
            return `${employee.firstName || ''}${employee.lastName ? ` ${employee.lastName}` : ' '} ${dayString}`.replace('N/A', '');
        } else {
            return '';
        }

    }

    const getTooltip = (employee) => {
        let days = [];
        employee.dayOff.forEach(day => {
            days.push(`${moment(day.start).format('hh:mm A')} - ${moment(day.end).format('hh:mm A')}`)
        })
        return (
            <>
                {
                    days.length > 0 &&
                    <Typography>
                        <em>Unavailable: </em> {days.join(', ')}
                    </Typography>
                }
                <Typography>
                    <em>Phone number: </em> {employee.phoneNumber}
                </Typography>
            </>
        )
    }

    useEffect(() => {
        // get job equipment string
        let equipments = route.routeJobs && route.routeJobs.map(job => job.job.equipment);
        equipments = equipments.join(';').replaceAll(',', '').replaceAll(';', ', ');
        equipments = equipments.trim();
        setJobEquipments(equipments[0] === ',' ? equipments.replace(',', '') : equipments);
    }, [route.routeJobs])

    useEffect(() => {
        const hideDistance = () => {
            setTimeout(() => {
                dispatch({ type: "CLEAR_DISTANCE" })
            }, 0)
        }
        document.addEventListener("click", hideDistance);
        return (() => {
            document.removeEventListener("click", hideDistance);
        })
    }, [])

    return (
        <div className={classes.route}>
            <div className={classes.routeJob}>
                {times.map(time => (
                    <React.Fragment key={`time-${time}`}>
                        <DropBox
                            className={classes.jobBox}
                            routeId={route.id}
                            startTime={time}
                            error={error}
                            canDrag={!route.routeFull}
                            topJobId={topJobId}
                            changeTopJob={(id) => setTopJobId(id)}
                        />
                        <DropBox
                            className={classes.jobBox}
                            routeId={route.id}
                            startTime={time + 0.5}
                            error={error}
                            canDrag={!route.routeFull}
                            topJobId={topJobId}
                            changeTopJob={(id) => setTopJobId(id)}
                        />
                    </React.Fragment>
                ))}
            </div>
            <Typography
                ref={route.routeFull ? null : dropEmployee}
                style={{
                    minWidth: routeWidth ? routeWidth + 'px' : 'auto',
                    backgroundColor: isOverEmployee ? '#c5c5c5' : '#ffffff'
                }}
                className={classes.routeDetail}>
                {route.routeEmployees && route.routeEmployees.map((emp) => (
                    <DraggableBox
                        key={emp ? emp.employeeId : null}
                        className={`${classes.empeqp} ${route.isCommercial ? classes.bgPurple : classes.bgGreen}`}
                        data={getFullName(emp)}
                        employees={emp}
                        source={{ id: emp ? emp.employeeId : null, type: 'Employee', oldRouteId: route.id }}
                        canDrag={!route.routeFull}
                        dragType={'employee'}
                        tooltip={getTooltip(emp)}
                    />
                ))}
            </Typography>
            <Typography />
            <Typography
                ref={route.routeFull ? null : dropEquipment}
                style={{
                    minWidth: routeWidth ? routeWidth + 'px' : 'auto',
                    backgroundColor: isOverEquipment ? '#c5c5c5' : '#ffffff'
                }}
                className={classes.routeDetail}>
                {jobEquipments && (
                    <span className={classes.jobEquipments}>
                        {jobEquipments}
                    </span>
                )}
                {route.routeEquipment && route.routeEquipment.map((eqp) => (
                    <DraggableBox
                        key={eqp ? eqp.id : null}
                        className={`${classes.empeqp} ${route.isCommercial ? classes.bgPurple : classes.bgGreen}`}
                        data={eqp.assetTag}
                        source={{ id: eqp ? eqp.id : null, type: 'Equipment', oldRouteId: route.id }}
                        canDrag={!route.routeFull}
                        dragType={'equipment'}
                    />
                ))}
            </Typography>
            <Typography style={{ borderBottom: '1px solid #b8b8b8' }} />
            <Typography className={classes.routeDetail}
                style={{
                    minWidth: routeWidth ? routeWidth + 'px' : 'auto',
                }}>
                {route.routeNote || ''}
            </Typography>
        </div>
    )
}

RouteContent.propTypes = {
    route: PropTypes.object.isRequired,
    routeWidth: PropTypes.number,
    error: PropTypes.func
}

export default RouteContent;
