import React, { useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import {
  Button,
  Card,
  CardActions,
  CardContent,
  CardHeader,
  CircularProgress,
  colors,
  Divider,
  List,
  ListItem,
  ListItemSecondaryAction,
  ListItemText,
  Modal,
  Snackbar,
  Typography,
} from '@material-ui/core';
import { Alert } from '@material-ui/lab';
import { KeyboardDateTimePicker } from '@material-ui/pickers';
import { makeStyles } from '@material-ui/styles';
import PropTypes from 'prop-types';
import moment from 'moment';

const useStyles = makeStyles(theme => ({
  styleModal: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    outline: 'none',
    boxShadow: theme.shadows[20],
    width: 700,
    maxHeight: '90%',
    overflowY: 'auto',
    maxWidth: '100%',
    [theme.breakpoints.down('sm')]: {
      width: 500
    }
  },
  cardContent: {
    maxHeight: 'calc(100vh - 200px)',
    overflow: 'auto'
  },
  actions: {
    justifyContent: 'end'
  },
  button: {
    color: theme.palette.white,
    backgroundColor: colors.green[600],
    '&:hover': {
      backgroundColor: colors.green[900]
    }
  },
  listItem: {
    paddingRight: 262
  }
}));

const SchedulePinsModal = ({ jobSplitIds, open, isProgress, onClose, onSchedule }) => {
  const { date } = useParams();
  const classes = useStyles();

  const { routeJobs } = useSelector(state => state.JobsReducer);

  const [scheduleTimes, setScheduleTimes] = useState({});
  const [isStatus, setStatus] = useState({ failed: false, msg: '' });
  const [openSnackbar, setOpenSnackbar] = useState(false);

  const onCloseModal = () => {
    onClose();
  };

  const onOk = () => {
    if (isJobOverflow) {
      setStatus({ failed: true, msg: 'Cannot schedule because the schedule goes passed midnight.' });
      setOpenSnackbar(true);
      return;
    }

    const jobs = routeJobs.filter(job => jobSplitIds.includes(job.jobSplitId));
    const routeJobsData = jobs.map(rj => {
      const job = {
        ...rj,
        jobStart: scheduleTimes[rj.jobSplitId]?.toISOString(),
        jobEnd: moment(scheduleTimes[rj.jobSplitId]).add(rj.estimatedHours, 'hours').toISOString(),
        job: {
          estimatedHours: rj.estimatedHours,
          price: rj.price,
          jobAddressId: rj.jobAddressId,
          address1: rj.address1 || '',
          city: rj.city || '',
          state: rj.state || '',
          zipCode: rj.zipCode || '',
          latitude: rj.latitude,
          longitude: rj.longitude,
          jobId: rj.jobId,
          jobSplitId: rj.jobSplitId,
          customerId: rj.customerId,
          customerName: rj.customerName,
          equipment: rj.equipment,
          description: rj.description,
          totalSplitJobs: rj.totalSplitJobs
        }
      };

      return job;
    });

    onSchedule(routeJobsData);
  };

  const isJobOverflow = useMemo(() => {
    const times = Object.values(scheduleTimes);
    if (times.length === 0) return false;
    return times.some(time => time >= moment(date).add(1, 'days').startOf('day'));
  }, [date, scheduleTimes]);

  const listJobs = useMemo(() => {
    if (open) {
      return (routeJobs ?? []).filter(j => jobSplitIds.includes(j.jobSplitId)).map(job => (
        <ListItem className={classes.listItem} key={job.jobSplitId}>
          <ListItemText
            primary={`${job.customerName}, ${job.job.estimatedHours} hours, $${job.price ? job.price.toFixed(2) : '0.00'}`}
            secondary={`${job.address1 || ''}, ${job.city || ''}, ${job.state || ''} ${job.zipCode || ''}`}
          />
          <ListItemSecondaryAction>
            <KeyboardDateTimePicker
              label="Schedule time"
              inputVariant="outlined"
              format="MM/DD/YYYY h:mm A"
              value={scheduleTimes[job.jobSplitId]}
              disabled={isProgress}
              onChange={date => setScheduleTimes(times => ({ ...times, [job.jobSplitId]: date }))}
            />
          </ListItemSecondaryAction>
        </ListItem>
      ));
    }
    
    return <></>;
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [open, routeJobs, jobSplitIds, scheduleTimes, isProgress]);

  useEffect(() => {
    if (open) {
      // Init scheudle times
      let startTime = moment(date).hours(8).startOf('hour');
      setScheduleTimes(
        times => (routeJobs ?? [])
          .filter(j => jobSplitIds.includes(j.jobSplitId))
          .reduce(
            (acc, j) => {
              acc[j.jobSplitId] = startTime;
              startTime = moment(startTime).add(j.estimatedHours + 0.5, 'hours');
              return acc;
            },
            {}
          )
      );
      
    }
  }, [date, open, routeJobs, jobSplitIds]);

  return (
    <>
      <Modal open={open} onClose={onCloseModal}>
        <Card className={classes.styleModal}>
          <CardHeader title="You are about to create a route with the following jobs:" />
          <Divider />
          <CardContent className={classes.cardContent}>
            <List>{listJobs}</List>
          </CardContent>
          <Divider />
          <CardActions className={classes.actions}>
            <Button variant="contained" disabled={isProgress} onClick={onCloseModal}>Cancel</Button>
            <Button
              className={classes.button}
              variant="contained"
              color="primary"
              disabled={isProgress}
              onClick={onOk}
            >
              OK
            </Button>
            {isProgress && <CircularProgress size={24} />}
          </CardActions>
        </Card>
      </Modal>

      <Snackbar open={openSnackbar}
        onClose={() => setOpenSnackbar(false)}
        autoHideDuration={5000}
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}>
        <Alert elevation={6} variant="filled" severity={isStatus.failed ? 'error' : 'success'}>
          <Typography color="inherit" variant="h6">
            {isStatus.msg}
          </Typography>
        </Alert>
      </Snackbar>
    </>
  );
};

SchedulePinsModal.propTypes = {
  jobSplitIds: PropTypes.array,
  open: PropTypes.bool,
  isProgress: PropTypes.bool,
  onClose: PropTypes.func,
  onSchedule: PropTypes.func
}

export default SchedulePinsModal;
