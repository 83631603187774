import { Tooltip, colors } from '@material-ui/core';
import { Room } from '@material-ui/icons';
import { makeStyles } from '@material-ui/styles';
import clsx from 'clsx';
import React from 'react';

const useStyles = makeStyles(theme => ({
  addressLink: {
    display: 'flex',
    alignItems: 'center',
    fontSize: 16
  },
  addressInline: {
    display: 'inline-flex',
    '& > div': {
      display: 'flex'
    }
  },
  addressName: {
    'color': '#263238',
    'fontSize': 14,
    'fontFamily': '"Roboto", "Helvetica", "Arial", sans-serif',
    'fontWeight': '400',
    'lineHeight': '21px',
    'letterSpacing': '-0.05px',
  },
  addressLinkIcon: {
    marginLeft: '0.5rem',
    color: '#263238',
    background: 'transparent',
    '& i': {
      color: colors.green[500]
    }
  }
}));

const AddressLink = ({ address, className, inline }) => {

  const classes = useStyles();

  const { address1, address2, city, state, zipCode } = address;

  return (
    <div className={clsx(classes.addressLink, inline ? classes.addressInline : null)}>
      <div>
        <div className={clsx(classes.addressName, className)}>
          {address1 || ''}
          {inline && <>,&nbsp;</>}
        </div>
        {address2 && (
          <div className={clsx(classes.addressName, className)}>
            {address2}
            {inline && <>,&nbsp;</>}
          </div>
        )}
        <div className={clsx(classes.addressName, className)}>
          {(city && `${city}, `) || ''}
          {`${state?.name || state?.code || ''} `}
          {zipCode || ''}
        </div>
      </div>
      <a className={clsx(classes.addressLinkIcon, className)}
        rel="noopener noreferrer"
        target="_blank"
        href={`https://www.google.com/maps/search/${(address1 ? address1 + ', ' : '')}${(address2 ? address2 + ', ' : '')}${(city ? city + ', ' : '')}${(state.name || state.code || '')} ${(zipCode || '')}`}>
        <Tooltip arrow placement="right" title="Open in Google Maps">
          <Room style={{ fontSize: 24 }} />
        </Tooltip>
      </a>
    </div>
  );
};

export default AddressLink;
