import React from 'react';
import {ApplicationInsights} from '@microsoft/applicationinsights-web';
import {ReactPlugin, withAITracking} from '@microsoft/applicationinsights-react-js';
import {createBrowserHistory} from "history";

const browserHistory = createBrowserHistory({basename: ''});
var reactPlugin = new ReactPlugin();
export const getConnectionString = () => {
    switch (process.env.REACT_APP_ENV) {
        case "dev": {
            return 'InstrumentationKey=6e10ba80-dd67-435f-bbf1-7f22c5caa245;IngestionEndpoint=https://centralus-2.in.applicationinsights.azure.com/;LiveEndpoint=https://centralus.livediagnostics.monitor.azure.com/;ApplicationId=64971909-18bd-448c-83fd-ab6d9da57d4d';
        }
        case "demo": {
            return 'InstrumentationKey=aa94041b-1cbb-4661-b056-d2923f8888f2;IngestionEndpoint=https://centralus-2.in.applicationinsights.azure.com/;LiveEndpoint=https://centralus.livediagnostics.monitor.azure.com';
        }
        case "prod": {
            return 'InstrumentationKey=70e83872-e658-4739-b1df-620726002bfa;IngestionEndpoint=https://centralus-2.in.applicationinsights.azure.com/;LiveEndpoint=https://centralus.livediagnostics.monitor.azure.com/;ApplicationId=e7c4d596-6555-4ec8-ae16-db6821d2ca90';
        }
        case "local": {
            return 'InstrumentationKey=6e10ba80-dd67-435f-bbf1-7f22c5caa245;IngestionEndpoint=https://centralus-2.in.applicationinsights.azure.com/;LiveEndpoint=https://centralus.livediagnostics.monitor.azure.com/;ApplicationId=64971909-18bd-448c-83fd-ab6d9da57d4d';
        }
        default:
            break;
    }
};
var appInsights = new ApplicationInsights({
    config: {
        connectionString: getConnectionString(),
        disableAjaxTracking: false,
        disableFetchTracking: false,
        enableAutoRouteTracking: true,
        extensions: [reactPlugin],
        extensionConfig: {
            [reactPlugin.identifier]: {history: browserHistory}
        }
    }
});
appInsights.loadAppInsights();
appInsights.trackPageView();
export {reactPlugin, appInsights}
