import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import NumberFormat from 'react-number-format'
import {
    Table,
    TableHead,
    TableBody,
    TableRow,
    TableCell,
    IconButton,
    TextField,
    colors,
    TableContainer, Grid, Button
} from '@material-ui/core'
import DeleteIcon from '@material-ui/icons/Delete';
import AddBoxIcon from '@material-ui/icons/AddBox';
import { makeStyles } from '@material-ui/styles'
import moment from "moment";
import { useAskTheSeal } from '../../Commissions/useAskTheSeal'
import ATSSnackBar from '../../Commissions/ATSSnackBar'
import { isPromise } from 'validate.js';
import localStorage from "utils/localStorage";
import {ArrowDownward, ArrowUpward} from "@material-ui/icons";

const useStyles = makeStyles(theme => ({
    thead: {
        '& tr th': {
            borderBottom: '1px solid #888888',
            '&:last-child': {
                width: '10%',
                textAlign: 'center'
            }
        }
    },
    tbody: {
        borderBottom: '1px solid #888888',
        '& tr td': {
            borderBottom: 'none'
        }
    },
    addStyle: {
        padding: '0',
        '& span svg': {
            color: colors.green[600],
            '&:hover': {
                color: colors.green[900]
            }
        }
    },
    splitField: {
        '& label': {
            left: '-5px'
        },
        '& div fieldset legend span': {
            paddingLeft: 0,
            paddingRight: 0
        }
    },
    button: {
        marginLeft: theme.spacing(1),
        color: theme.palette.white,
        backgroundColor: colors.green[600],
        '&:hover': {
            backgroundColor: colors.green[900]
        }
    },
    btnDis: {
        whiteSpace: 'nowrap',
        color: 'rgba(0, 0, 0, 0.26)',
        backgroundColor: 'rgb(217 217 217)',
        pointerEvents: 'none'
    },
    customTableCell: {
        borderBottom: '1px solid #969696',
        cursor: 'pointer',
        '$ span': {
            display: 'flex'
        }
    },
    iconSort: {
        marginBottom: '-5px',
        marginLeft: '10px'
    }
}))

// ToBid=1,BidCompleted=2,ToBeScheduled=3,Scheduled=4,JobCompleted=5,Cancelled=6,CustomerService=7
const stages = ['None', 'To Bid', 'Bid Completed', 'To be Scheduled', 'Scheduled', 'Job Completed', 'Cancelled', 'Customer Service'];
const stageDateFields = ['', 'dateCreated', 'bidCompleted', 'toBeScheduled', 'scheduledDate', 'completedDate', 'scheduledDate', 'scheduledDate']

const SplitTable = props => {
    const { information, disabled, splits, add, update, remove, isEqual, sortBy, sortType, loadDocument } = props;
    const classes = useStyles();
    const { postAskTheSealReminder, isPrimaryContactSet, getAtsId } = useAskTheSeal()
    const [ATSModalState, setATSModalState] = useState({
        isOpen: false,
        type: '',
    })
    const [primaryContactSet, setPrimaryContactSet] = useState(false)
    const [askTheSealReminderSuccess, setAskTheSealReminderSuccess] = useState(false)
    const [splitsLoaded, setSplitsLoaded] = useState(false)
    const [atsId, setAtsId] = useState(null)

    const companyId = localStorage.getCompanyId();

    const getSortIcon = (type) => {
        return (
            sortBy == type &&
            (
                sortType == 'ASC' ?
                    <ArrowUpward className={classes.iconSort}/>
                    :
                    <ArrowDownward className={classes.iconSort}/>
            )
        )
    }
    useEffect(() => {
        // TODO: Find way to limit how many times this is triggered
        getAtsId(companyId).then((res) => setAtsId(res.data))
        if (props.splits.length > 1) {
            !splitsLoaded && setPrimaryContactSet(isPrimaryContactSet(props.splits[0].jobId))
            setSplitsLoaded(() => true)
        }
    }, [props.splits])

    return (
        <TableContainer>
            <ATSSnackBar setATSModalState={setATSModalState} isOpen={ATSModalState.isOpen} type={ATSModalState.type} />
            <Table style={{ minWidth: '800px' }}>
                <TableHead className={classes.thead}>
                    <TableRow>
                        <TableCell onClick={() => loadDocument('SplitDescription')} className={classes.customTableCell} align='center' style={{ minWidth: 200 }}><span>Split Description{ getSortIcon('SplitDescription')}</span></TableCell>
                        <TableCell onClick={() => loadDocument('Stage')} className={classes.customTableCell} align='center' style={{ width: 150, minWidth: 150 }}><span>Stage{ getSortIcon('Stage')}</span></TableCell>
                        <TableCell onClick={() => loadDocument('ScheduledDate')} className={classes.customTableCell} align='center' style={{ width: 150, minWidth: 150 }}><span>Scheduled Date{ getSortIcon('ScheduledDate')}</span></TableCell>
                        <TableCell onClick={() => loadDocument('Completed')} className={classes.customTableCell} align='center' style={{ width: 150, minWidth: 150 }}><span>Completed{ getSortIcon('Completed')}</span></TableCell>
                        <TableCell onClick={() => loadDocument('Total')} className={classes.customTableCell} align='center' style={{ width: 155, minWidth: 155 }}><span>Total{ getSortIcon('Total')}</span></TableCell>
                        {atsId && (
                            <TableCell align='center' style={{ width: 230 }}>Ask The Seal</TableCell>
                        )}
                        <TableCell style={{ width: '80px' }}>
                            <IconButton className={disabled ? classes.btnDis : classes.addStyle} onClick={add}
                                style={disabled ? { backgroundColor: '#fff' } : null}>
                                <AddBoxIcon fontSize='large' />
                            </IconButton>
                        </TableCell>
                    </TableRow>
                </TableHead>
                <TableBody className={classes.tbody}>
                    {splits.map((split, index) => {
                        split.bidCompleted = information.bidCompleted;
                        split.toBeScheduled = '';
                        return <TableRow key={index}>
                            <TableCell>
                                <TextField
                                    fullWidth
                                    className={classes.splitField}
                                    disabled={disabled}
                                    name='description'
                                    variant='outlined'
                                    value={split.description || ''}
                                    onChange={e => update(e, index)}
                                />
                            </TableCell>
                            <TableCell>
                                <TextField
                                    fullWidth
                                    disabled
                                    className={classes.splitField}
                                    name='bidStageId'
                                    variant='outlined'
                                    value={stages[split.bidStageId]}
                                />
                            </TableCell>
                            <TableCell>
                                <TextField
                                    fullWidth
                                    disabled
                                    className={classes.splitField}
                                    //label='Scheduled'
                                    name='scheduleDate'
                                    variant='outlined'
                                    value={split.scheduledDate && moment(split.scheduledDate).format('MM/DD/YYYY') || ''}
                                    onChange={e => update(e, index)}
                                />
                            </TableCell>
                            <TableCell>
                                <TextField
                                    fullWidth
                                    disabled
                                    className={classes.splitField}
                                    //label='Completed'
                                    name='completedDate'
                                    variant='outlined'
                                    value={split.completedDate && moment(split.completedDate).format('MM/DD/YYYY') || ''}
                                    onChange={e => update(e, index)}
                                />
                            </TableCell>
                            <TableCell>
                                <NumberFormat
                                    disabled={disabled || isEqual}
                                    customInput={TextField}
                                    allowNegative={false}
                                    prefix='$'
                                    thousandSeparator
                                    fullWidth
                                    className={classes.splitField}
                                    //label='Total'
                                    name='total'
                                    variant='outlined'
                                    value={parseFloat(split.total).toFixed(2) || ''}
                                    onChange={e => update(e, index)}
                                />
                            </TableCell>
                            {atsId && (
                                <TableCell align='center'>
                                    <Button onClick={async () => {
                                        const res = await postAskTheSealReminder(split.jobId, split.id)
                                        if (res) {
                                            setATSModalState({ type: 'Reminder', isOpen: true })
                                        } else {
                                            setATSModalState({ type: 'Error', isOpen: true })
                                        }
                                    }} className={(disabled || !isPrimaryContactSet || !split.scheduledDate) ? classes.btnDis : classes.button}>
                                        Ask The Seal Reminder
                                    </Button>
                                </TableCell>
                            )}
                            <TableCell align='center'>
                                {splits.length > 1 && !split.scheduled && !split.isPrimaryJob &&
                                    <IconButton onClick={() => remove(index)} disabled={disabled}>
                                        <DeleteIcon />
                                    </IconButton>
                                }
                            </TableCell>
                        </TableRow>
                    })}
                </TableBody>
            </Table>
        </TableContainer>
    )
}

SplitTable.propTypes = {
    disabled: PropTypes.bool,
    splits: PropTypes.array.isRequired,
    add: PropTypes.func,
    update: PropTypes.func,
    remove: PropTypes.func
}
SplitTable.defaultProps = {
    add: () => { },
    update: () => { },
    remove: () => { }
}

export default SplitTable;
