import React from "react";
import { AzureMapsProvider } from "react-azure-maps";

import MapController from "./MapController";

const Map = (props) => {
  return (
    <AzureMapsProvider>
      <MapController {...props} />
    </AzureMapsProvider>
  );
};

export default Map;
