import React, { useState } from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { makeStyles, useTheme } from '@material-ui/styles';
import { useMediaQuery } from '@material-ui/core';

import { Sidebar, Topbar } from './components';
import localStorage from "utils/localStorage";

const useStyles = makeStyles(theme => ({
  root: {
    scrollBehavior: 'smooth',
    paddingTop: 56,
    height: '100%',
    transition: theme.transitions.create('padding-left', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
    [theme.breakpoints.up('sm')]: {
      paddingTop: 64
    }
  },
  shiftContent: {
    paddingLeft: 265
  },
  collapsed: {
    paddingLeft: 77
  },
  content: {
    height: '100%',
    overflowY: 'scroll'
  }
}));

const Main = props => {
  const { children } = props;

  const classes = useStyles();
  const theme = useTheme();
  const isDesktop = useMediaQuery(theme.breakpoints.up('lg'), {
    defaultMatches: true
  });

  const [openSidebar, setOpenSidebar] = useState(JSON.parse(localStorage.getItem('openSidebar')) ?? true);

  const handleSidebarOpen = () => {
    setOpenSidebar(true);
    localStorage.set('openSidebar', true);
  };

  const handleSidebarClose = () => {
    setOpenSidebar(false);
    localStorage.set('openSidebar', false);
  };

  return (
    <div
      className={clsx({
        [classes.root]: true,
        [classes.shiftContent]: isDesktop && openSidebar,
        [classes.collapsed]: isDesktop && !openSidebar
      })}
    >
      <Topbar onSidebarOpen={handleSidebarOpen} />
      <Sidebar
        onOpen={handleSidebarOpen}
        onClose={handleSidebarClose}
        open={openSidebar}
        variant={isDesktop ? 'permanent' : 'temporary'}
      />
      <main id='content' className={classes.content}>
        {children}   
      </main>
    </div>
  );
};

Main.propTypes = {
  children: PropTypes.node
};

export default Main;
