import React, { useEffect, useState } from 'react'
import {
    Card, CardHeader, CardContent, CardActions, Divider,
    Typography, Button, IconButton, TextField, colors, Tooltip,
    Grid, CircularProgress, Snackbar, FormControlLabel
} from '@material-ui/core'
import { AddBox, KeyboardArrowDown, TrendingUpRounded } from '@material-ui/icons'
import { makeStyles } from '@material-ui/styles'
import clsx from "clsx";
import { Page, KeyboardDatePicker, GreenRadio } from "components";
import { createTheme, MuiThemeProvider } from "@material-ui/core/styles";
import { AccessControl } from 'components/AccessControl/AccessControl'
import { PERMISSIONS } from 'common/permissions'
import apiConfig from 'apiConfig';
import axios from 'utils/axios'
import { Formik } from 'formik';
import * as Yup from 'yup';
import NumberFormat from 'react-number-format';
import { Alert } from '@material-ui/lab';
import constants from 'constants.js';
import * as _ from 'underscore';

const useStyles = makeStyles(theme => ({
    root: {
        padding: '32px'
    },
    btn: {
        color: theme.palette.white,
        backgroundColor: colors.green[600],
        '&:hover': {
            backgroundColor: colors.green[900]
        }
    },
    cardContent: {
        paddingLeft: theme.spacing(4),
        paddingRight: theme.spacing(4),
        display: 'grid',
        gridTemplateColumns: '1fr auto 1fr',
        gap: theme.spacing(2),
        [theme.breakpoints.down('xs')]: {
            gridTemplateColumns: '1fr'
        }
    },
    cardActions: {
        paddingLeft: theme.spacing(4),
        paddingRight: theme.spacing(4),
        justifyContent: 'end'
    },
    user: {
        paddingLeft: '3px',
        cursor: 'pointer'
    },
    box: {
        padding: '16px 8px',
        border: '1px solid #b3b3b3',
        borderRadius: '4px',
        position: 'relative',
        height: 'max-content',
        [theme.breakpoints.down('xs')]: {
            width: '100%'
        }
    },
    btnDis: {
        pointerEvents: 'none',
        opacity: 0.7,
        color: 'rgba(0, 0, 0, 0.26) !important',
        backgroundColor: 'rgb(217 217 217) !important'
    },
    userActions: {
        textAlign: 'center',
        alignSelf: 'center',
        [theme.breakpoints.down('xs')]: {
            width: '100%',
            textAlign: 'left',
            display: 'flex',
            alignItems: 'center',
        }
    },
    btnCreate: {
        display: 'inline-flex',
        [theme.breakpoints.down(768)]: {
            display: 'none'
        },
        [theme.breakpoints.down('xs')]: {
            display: 'inline-flex',
        }
    },
    addIcon: {
        display: 'none',
        padding: '0',
        '& span svg': {
            fontSize: '60px',
            color: colors.green[600],
            '&:hover': {
                color: colors.green[900]
            }
        },
        [theme.breakpoints.down(768)]: {
            display: 'inline-flex'
        },
        [theme.breakpoints.down('xs')]: {
            display: 'none'
        }
    },
    overBox: {
        width: '100%',
        height: '185px',
        marginTop: '10px',
        overflow: 'hidden',
        display: 'flex',
        flexDirection: 'column'
    },
    overBoxEmployees: {
        [theme.breakpoints.between(599.95, 960)]: {
            height: 288
        }
    },
    listItems: {
        overflowY: 'auto',
        '&::-webkit-scrollbar': {
            width: '5px'
        },
        '&::-webkit-scrollbar-track': {
            backgroundColor: '#e4e4e4'
        },
        '&::-webkit-scrollbar-thumb': {
            backgroundColor: '#9c9c9c',
            borderRadius: '60px'
        }
    },
    filterUsers: {
        marginBottom: '5px',
        '& .MuiInput-input': {
            paddingLeft: '4px'
        }
    },
    boxLabel: {
        position: 'absolute',
        top: '-10px',
        left: '10px',
        backgroundColor: '#ffffff',
        padding: '0 3px',
        fontWeight: 500
    },
    transferActions: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'space-around',
        [theme.breakpoints.down('xs')]: {
            flexDirection: 'row'
        },
        '& p': {
            width: '50%',
            textAlign: 'center',
            borderRadius: '4px',
            padding: '6px 0',
            backgroundColor: colors.green[600],
            color: '#ffffff',
            fontSize: '20px',
            [theme.breakpoints.down('xs')]: {
                width: '20%',
            },
            '&:hover': {
                cursor: 'pointer',
                backgroundColor: colors.green[900]
            }
        }
    },
    blank: {
        [theme.breakpoints.down('xs')]: {
            display: 'none'
        }
    },
    selectRole: {
        position: 'relative',
    },
    loadingRole: {
        position: 'absolute',
        right: 32,
        top: 12
    },
    snackBar: {
        width: '100%',
        '& > * + *': {
            marginTop: theme.spacing(2),
        },
    },
    txtInput: {
        paddingBottom: '25.5px !important',
    },
    labelRoles: {
        position: 'absolute',
        top: '-10px',
        left: '10px',
        backgroundColor: '#ffffff',
        padding: '0 3px',
        fontWeight: 500
    },
    rolesContainer: {
        width: '100%',
        border: '1px solid #b3b3b3',
        borderRadius: '4px',
        position: 'relative',
        [theme.breakpoints.down('xs')]: {
            width: '100%'
        }
    }
}));
const customTooltipTheme = createTheme({
    overrides: {
        MuiTooltip: {
            tooltip: {
                fontSize: '14px',
                maxWidth: 'none'
            }
        }
    }
})

const initialValues = {
    id: '',
    firstName: '',
    lastName: '',
    phoneTypeId: '',
    phoneNumber: '',
    email: '',
    password: '',
    roleId: '',
    termDate: null,
    rolesMultiple: null
};

const validationSchema = Yup.object().shape({
    id: Yup.string().nullable(),
    firstName: Yup.string().nullable().required('This field is required.'),
    lastName: Yup.string().nullable().required('This field is required.'),
    phoneTypeId: Yup.string().nullable(),
    phoneNumber: Yup.string().nullable()//.required('This field is required.')
        .matches(/\W\d{3}\W\s\w{3}\W\w{4}/, 'Phone number must contain 10 number.'),
    email: Yup.string().required('This field is required.')
        .email('Email is invalid.'),
    password: Yup.string()
        .when('id', {
            is: val => !val,
            then: Yup.string().required('This field is required.')
        }),
    roleId: Yup.string(),
    termDate: Yup.date().nullable(),
})

const MultiCompanyUsers = props => {

    const classes = useStyles();

    const [users, setUsers] = useState({ items: [], loading: true });
    const [filterUser, setFilterUser] = useState('');
    const [filterUsers, setFilterUsers] = useState([]);
    const [selectedUser, setSelectedUser] = useState({ user: null, companies: [] });
    
    const [companies, setCompanies] = useState({ from: [], selected: [], loadingFrom: true, loadingSelected: false });
    const [filterCompany, setFilterCompany] = useState({ from: '', selected: '' });
    const [filterCompanies, setFilterCompanies] = useState({ from: [], selected: [] });
    const [selectedCompanies, setSelectedCompanies] = useState({ from: [], selected: [] });

    const [roles, setRoles] = useState({ items: [], loading: false });

    const [formValues, setFormValues] = useState(initialValues);
    const [companiesChanging, setCompaniesChanging] = useState(false);
    const [saving, setSaving] = useState(false);
    const [openSnackbar, setOpenSnackbar] = useState(false);
    const [isStatus, setIsStatus] = useState({ failed: false, msg: '' });

    const transferOne = (source, dest, values) => {
        let filterComs = [...filterCompanies[source]];
        let coms = [...companies[source]];

        const selectedCompaniesNew = selectedCompanies[source];

        filterComs = filterComs.filter(x => !selectedCompaniesNew.includes(x));
        coms = coms.filter(x => !selectedCompaniesNew.includes(x));

        const filterComsDest = [...filterCompanies[dest], ...selectedCompaniesNew];
        filterComsDest.sort((a, b) => a.name?.localeCompare(b.name));
        const comsDest = [...companies[dest], ...selectedCompaniesNew];
        comsDest.sort((a, b) => a.name?.localeCompare(b.name));

        setSelectedCompanies({ [source]: [], [dest]: [...selectedCompaniesNew] });
        setFilterCompanies({ [source]: filterComs, [dest]: filterComsDest });
        setCompanies({ [source]: coms, [dest]: comsDest });
        setCompaniesChanging(true);

        if (source === "selected" && values.rolesMultiple && selectedCompaniesNew.length === 1) {
            const indexCompany = values.rolesMultiple.map(function(x) {return x.id; }).indexOf(selectedCompaniesNew[0].id);
            if (indexCompany > -1) {
                values.rolesMultiple.splice(indexCompany, 1);
                setFormValues({ ...formValues, rolesMultiple: values.rolesMultiple });
            }
        }
    }
    const transferMultiple = (source, dest, predicate) => {
        const filterComs = [...filterCompanies[source]];
        const coms = [...companies[source]];

        const filterComsDest = filterCompanies[dest].concat(filterComs.filter(predicate));
        const filterComsSource = filterComs.filter((...params) => !predicate(...params));
        const comsDest = companies[dest].concat(coms.filter(predicate));
        const comsSource = coms.filter((...params) => !predicate(...params));

        filterComsDest.sort((a, b) => a.name?.localeCompare(b.name));
        comsDest.sort((a, b) => a.name?.localeCompare(b.name));

        setFilterCompanies({ [source]: [...filterComsSource], [dest]: filterComsDest });
        setCompanies({ [source]: [...comsSource], [dest]: comsDest, loadingSelected: false });
        setCompaniesChanging(false);
    }
    const transferAll = (source, dest) => {
        const coms = [...companies[source]];
        const filterComs = coms.filter(com => com.name && com.name.toLowerCase().indexOf(filterCompany[dest].toLowerCase()) > -1);

        const filterComsDest = companies[dest].concat(filterComs);
        const comsDest = companies[dest].concat(filterComs);

        filterComsDest.sort((a, b) => a.name?.localeCompare(b.name));
        comsDest.sort((a, b) => a.name?.localeCompare(b.name));

        setFilterCompanies({ [source]: [], [dest]: filterComsDest });
        setCompanies({ [source]: [], [dest]: comsDest });
        setCompaniesChanging(true);

        if(source == "selected") {
            setFormValues({ ...formValues, rolesMultiple: [] });
        }
    }
    // filter companies
    const onChangeFilterCompany = event => {
        const { name, value } = event.target;
        const filterComs = companies[name].filter(com => com.name && com.name.toLowerCase().indexOf(value.toLowerCase()) > -1);
        setFilterCompany({ ...filterCompany, [name]: value });
        setFilterCompanies({ ...filterCompanies, [name]: filterComs });
    }
    const onChangeFilterUser = event => {
        const { value } = event.target;
        const filterUser = users.items.filter(com => com.fullName && com.fullName.toLowerCase().indexOf(value.toLowerCase()) > -1);
        setFilterUser(value);
        setFilterUsers(filterUser);
    }

    const onSelectCompany = (source, company) => {
        const companies = selectedCompanies[source];
        if (companies.includes(company)) {
            companies.splice(companies.indexOf(company), 1);
        } else {
            companies.push(company);
        }
        setSelectedCompanies({
            ...selectedCompanies,
            [source]: companies
        });
    }

    const fetchUserCompanies = userId => {
        axios.get(apiConfig.url.BASE_URL + apiConfig.url.COMPANY_MULTI.replace("{userId}", userId))
            .then(res => {
                const data = res.data ?? [];
                setSelectedUser({ ...selectedUser, companies: data });
                transferMultiple('from', 'selected', c => data.map(d => d.id).indexOf(c.id) > -1);
                setFormValues({
                    ...selectedUser.user,
                    phoneTypeId: selectedUser.user?.phones && selectedUser.user?.phones[0]?.typeId,
                    phoneNumber: selectedUser.user?.phones && selectedUser.user?.phones[0]?.phoneNumber,
                    rolesMultiple: data
                });
            });
    }

    const fetchUser = userId => {
        return userId
            ? axios.get(apiConfig.url.USER_URL + apiConfig.url.EMPLOYEE_INFO + userId)
            : axios.get(apiConfig.url.USER_URL + apiConfig.url.EMPLOYEE_ADD_GET);
    };

    const saveUser = user => {
        return user.id
            ? axios.post(apiConfig.url.USER_URL + apiConfig.url.EMPLOYEE_UPDATE, user)
            : axios.put(apiConfig.url.USER_URL + apiConfig.url.EMPLOYEE_ADD, user);
    }

    const savePassword = data => {
        return axios.post(apiConfig.url.AUTH_URL + apiConfig.url.EMPLOYEE_UPDATE_PASSWORD, data);
    }

    const saveRole = data => {
        return axios.post(apiConfig.url.AUTH_URL + apiConfig.url.EMPLOYEE_ROLE_UPDATE, data);
    }

    const addMultiCompanies = (userId, data) => {
        return axios.post(apiConfig.url.BASE_URL + apiConfig.url.COMPANY_MULTI.replace('{userId}', userId), data);
    }

    const onSubmit = values => {

        var roleIdsParams = [];
        values && values.rolesMultiple && values.rolesMultiple.map(function (item, index) {
            var roleIdsParamsChild = [];
            item.roles.map(function (itemChild, index) {
                itemChild.isSelected == true && roleIdsParamsChild.push(itemChild.id)
            });
            roleIdsParams.push({companyId: item.id, roleIds: roleIdsParamsChild});
        });
        if(roleIdsParams && roleIdsParams.length > 0 && roleIdsParams.filter(function(item) { return item.roleIds.length == 0 }).length > 0) {
            setIsStatus({ failed: true, msg: "You must specify at least one role" });
            setOpenSnackbar(true);
        } else {
            setSaving(true);
            fetchUser(values.id)
                .then(res => {
                    if (values.id) {
                        const promises = [];
                        const user = res.data;
                        let hasChanged = false;
                        if (user.firstName != values.firstName) {
                            user.firstName = values.firstName;
                            hasChanged = true;
                        }
                        if (user.lastName != values.lastName) {
                            user.lastName = values.lastName;
                            hasChanged = true;
                        }
                        if (user.email != values.email) {
                            user.email = values.email;
                            hasChanged = true;
                        }
                        if ((values.phones && values.phones[0]?.phoneNumber != values.phoneNumber)) {
                            user.phones = [
                                {
                                    id: values.phones[0]?.id || '',
                                    phoneNumber: values.phoneNumber,
                                    typeId: values.phoneTypeId || res.data?.phoneTypes[0]?.id || '',
                                },
                                ...(values.phones ? values.phones.slice(1) : []),
                            ];
                            hasChanged = true;
                        }
                        if (user.termDate != values.termDate) {
                            user.termDate = values.termDate;
                            hasChanged = true;
                        }
                        if (hasChanged) promises.push(saveUser(user));

                        if (values.password) {
                            const pwdPostData = {
                                password: values.password,
                                userId: values.id
                            };
                            promises.push(savePassword(pwdPostData));
                        }

                        const data = roleIdsParams;
                        promises.push(addMultiCompanies(user.id, data));

                        return Promise.all(promises);
                    } else {
                        const user = {
                            firstName: values.firstName,
                            lastName: values.lastName,
                            email: values.email,
                            phones: [
                                {
                                    phoneNumber: values.phoneNumber,
                                    typeId: res.data?.phoneTypeOptions && res.data?.phoneTypeOptions[0]?.id || '',
                                },
                            ],
                            address: {},
                            security: {
                                roleIds: [roleIdsParams[0].roleIds[0]],
                                password: values.password,
                                companyId: companies.selected[0].id
                            }
                        };

                        const companyIds = companies.selected.map(c => c.id);
                        return saveUser(user).then(res => {
                            user.id = res.data.id;
                            user.fullName = `${user.firstName || ''} ${user.lastName || ''}`;
                            setFilterUsers([user, ...filterUsers]);
                            setUsers({ ...users, items: [user, ...users.items] });
                            setSelectedUser({ user, companies: companies.selected });
                            setFormValues({
                                ...selectedUser.user,
                                id: res.data.id
                            });

                            const data = roleIdsParams;
                            return addMultiCompanies(res.data.id, data);
                        });
                    }
                })
                .then(res => {
                    setFormValues({...values});
                    setIsStatus({ failed: false, msg: constants.msg.SAVED_SUCCESS });
                    setOpenSnackbar(true);
                })
                .catch(err => {
                    setIsStatus({ failed: true, msg: constants.msg.GENERAL_ERROR });
                    setOpenSnackbar(true);
                })
                .finally(() => setSaving(false));
        }
    }

    useEffect(() => {
        // mounted.current = true;
        axios.get(apiConfig.url.USER_URL + apiConfig.url.EMPLOYEE_LIST_ALL)
            .then(res => {
                setUsers({ items: res.data.users, loading: false });
                setFilterUsers(res.data.users);
            });
        axios.get(apiConfig.url.BASE_URL + apiConfig.url.COMPANY_ACTIVE)
            .then(res => {
                res.data.sort((a, b) => a.name?.localeCompare(b.name));
                setCompanies({ ...companies, from: res.data, loadingFrom: false });
                setFilterCompanies({ ...filterCompanies, from: res.data });
            });
        axios.get(apiConfig.url.AUTH_URL + apiConfig.url.EMPLOYEE_ROLES).then(res => {
                setRoles({ items: res.data.roles, loading: false });
            });
    }, []);

    useEffect(() => {
        if (selectedUser.user && selectedUser.user.id) {
            setCompanies({ ...companies, loadingSelected: true });
            setSelectedCompanies({ from: [], selected: [] });
            fetchUserCompanies(selectedUser.user.id);
        } else {
            setFormValues(initialValues);
        }
    }, [selectedUser.user]);

    useEffect(() => {
        if (selectedCompanies.selected.length === 1 &&
            selectedUser.companies.findIndex(c => c.id === selectedCompanies.selected[0].id) === -1) {
            const roleIdsByCompany = {
                id: selectedCompanies.selected[0].id,
                roles: roles.items
            }
            var preFormValues = formValues.rolesMultiple ?? [];
            preFormValues.push(roleIdsByCompany);
            setFormValues({ ...formValues, rolesMultiple: preFormValues });
        } 
    }, [selectedCompanies.selected[0]]);

    const onChangeRoles = (event, setFieldValue, values) => {
        if (selectedCompanies.selected.length !== 1) return;
        const { name, value, type, id } = event.target;
        const companySelected = values.rolesMultiple.find(c => c.id === selectedCompanies.selected[0].id);
        if(!event.target.checked) {
            if(companySelected.roles.filter(function(item) { return item.isSelected }).length == 1) {
                setIsStatus({ failed: true, msg: 'You must specify at least one role!' });
                setOpenSnackbar(true);
            } else {
                var draftDataCompanyRoles = values.rolesMultiple;
                const indexCompany = draftDataCompanyRoles.map(function(x) {return x.id; }).indexOf(selectedCompanies.selected[0].id);
                const index = draftDataCompanyRoles[indexCompany].roles.map(function(x) {return x.name; }).indexOf(name);
                draftDataCompanyRoles[indexCompany].roles[index].isSelected = false;
                setFormValues({ ...formValues, rolesMultiple: draftDataCompanyRoles });
            }
        } else {
            var draftDataCompanyRoles = values.rolesMultiple;
            const indexCompany = draftDataCompanyRoles.map(function(x) {return x.id; }).indexOf(selectedCompanies.selected[0].id);
            draftDataCompanyRoles[indexCompany].roles.forEach(role => role.isSelected = role.name === name);
            setFormValues({ ...formValues, rolesMultiple: draftDataCompanyRoles });
        }
    }

    const onFirstNameChange = (e, setFieldValue, values) => {
        setFieldValue('firstName', e.target.value)
        setFormValues({ ...formValues, firstName: e.target.value });
    }

    const onLastNameChange = (e, setFieldValue, values) => {
        setFieldValue('lastName', e.target.value)
        setFormValues({ ...formValues, lastName: e.target.value });
    }

    const onPhoneNumberChange = (e, setFieldValue, values) => {
        setFieldValue('phoneNumber', e.target.value)
        setFormValues({ ...formValues, phoneNumber: e.target.value });
    }

    const onEmailChange = (e, setFieldValue, values) => {
        setFieldValue('email', e.target.value)
        setFormValues({ ...formValues, email: e.target.value });
    }

    const onPasswordChange = (e, setFieldValue, values) => {
        setFieldValue('password', e.target.value)
        setFormValues({ ...formValues, password: e.target.value });
    }

    return (
        <AccessControl requiredPermission={PERMISSIONS.page.superAdmin} redirect>
            <Page title={'Super admin - Multi-Company Users'}>
                <div className={classes.root}>
                    <Formik
                        enableReinitialize
                        initialValues={formValues}
                        validationSchema={validationSchema}
                        onSubmit={onSubmit}
                    >
                        {({
                            values,
                            errors,
                            touched,
                            setFieldValue,
                            setFieldTouched,
                            handleChange,
                            handleBlur,
                            handleSubmit,
                        }) => (
                            <form onSubmit={handleSubmit}>
                                <input type='password' style={{ display: 'none' }} /> {/* for remove autofill */}
                                <Card>
                                    <CardHeader title='Multi-Company Users' />
                                    <Divider />
                                    <CardContent className={classes.cardContent}>
                                        <div className={classes.box}>
                                        <Typography className={classes.boxLabel}>Select Employee</Typography>
                                            <div className={clsx(classes.overBox, classes.overBoxEmployees)}>
                                                <TextField
                                                    fullWidth
                                                    className={classes.filterUsers}
                                                    placeholder='Search employees...'
                                                    name='from'
                                                    variant='standard'
                                                    value={filterUser.from}
                                                    onChange={onChangeFilterUser}
                                                />
                                                {users.loading &&
                                                    <Grid
                                                        container
                                                        spacing={0}
                                                        align="center"
                                                        justifyContent="center"
                                                        direction="column">
                                                        <Grid item>
                                                            <CircularProgress size={50} />
                                                        </Grid>
                                                    </Grid>
                                                }
                                                <div className={classes.listItems}>
                                                    {filterUsers && filterUsers.map((user, index) => (
                                                        <Typography className={classes.user} key={index}
                                                            style={user === selectedUser.user ? {
                                                                backgroundColor: '#43a047',
                                                                color: '#ffffff'
                                                            } : null}
                                                            onClick={() => {
                                                                transferAll('selected', 'from');
                                                                if (selectedUser.user !== user) {
                                                                    setSelectedUser({ user, companies: [] });
                                                                } else {
                                                                    setSelectedUser({ user: null, companies: [] });
                                                                }
                                                            }}>
                                                            {user.fullName}{user.termDate ? ' (Terminated)' : ''}
                                                        </Typography>
                                                    ))}
                                                </div>
                                            </div>
                                        </div>
                                        <div className={classes.userActions}>
                                            <div>OR</div>
                                            <div>&emsp;</div>
                                            <Button
                                                className={clsx(classes.btn, classes.btnCreate)}
                                                onClick={() => {
                                                transferAll('selected', 'from');
                                                setSelectedUser({ user: {}, companies: [] });
                                            }}>
                                                Create New User
                                            </Button>
                                            <MuiThemeProvider theme={customTooltipTheme}>
                                                <Tooltip title={'Create New User'} placement='bottom' arrow>
                                                    <IconButton className={classes.addIcon}>
                                                        <AddBox />
                                                    </IconButton>
                                                </Tooltip>
                                            </MuiThemeProvider>
                                            <div>&emsp;</div>
                                            <div>&emsp;</div>
                                            <div>&emsp;</div>
                                            <div>&emsp;</div>
                                        </div>
                                        <div>
                                            <Grid container spacing={2}>
                                                <Grid item xs={12} md={6} className={classes.txtInput}>
                                                    <TextField
                                                        fullWidth
                                                        error={errors.firstName && touched.firstName}
                                                        helperText={touched.firstName && errors.firstName}
                                                        disabled={!selectedUser.user}
                                                        name='firstName'
                                                        label='First Name'
                                                        variant='outlined'
                                                        value={values.firstName || ''}
                                                        onChange={(event) => onFirstNameChange(event, setFieldValue, values)}
                                                        onBlur={handleBlur}
                                                    />
                                                </Grid>
                                                <Grid item xs={12} md={6} className={classes.txtInput}>
                                                    <TextField
                                                        fullWidth
                                                        error={errors.lastName && touched.lastName}
                                                        helperText={touched.lastName && errors.lastName}
                                                        disabled={!selectedUser.user}
                                                        name='lastName'
                                                        label='Last Name'
                                                        variant='outlined'
                                                        value={values?.lastName || ''}
                                                        onChange={(event) => onLastNameChange(event, setFieldValue, values)}
                                                        onBlur={handleBlur}
                                                    />
                                                </Grid>
                                                <Grid item xs={12} md={6} className={classes.txtInput}>
                                                    <NumberFormat
                                                        customInput={TextField}
                                                        format='(###) ###-####x######'
                                                        fullWidth
                                                        error={errors.phoneNumber && touched.phoneNumber}
                                                        helperText={touched.phoneNumber && errors.phoneNumber}
                                                        disabled={!selectedUser.user}
                                                        label='Phone Number'
                                                        name='phoneNumber'
                                                        variant='outlined'
                                                        value={values?.phoneNumber || ''}
                                                        onChange={(event) => onPhoneNumberChange(event, setFieldValue, values)}
                                                        onBlur={handleBlur}
                                                    />
                                                </Grid>
                                                <Grid item xs={12} md={6} className={classes.txtInput}>
                                                    <TextField
                                                        fullWidth
                                                        error={errors.email && touched.email}
                                                        helperText={touched.email && errors.email}
                                                        disabled={!selectedUser.user}
                                                        name='email'
                                                        label='Email'
                                                        variant='outlined'
                                                        value={values?.email || ''}
                                                        onChange={(event) => onEmailChange(event, setFieldValue, values)}
                                                        onBlur={handleBlur}
                                                        autoComplete='no'
                                                    />
                                                </Grid>
                                                <Grid item xs={12} md={6} className={classes.txtInput}>
                                                    <TextField
                                                        fullWidth
                                                        error={errors.password && touched.password}
                                                        helperText={touched.password && errors.password}
                                                        disabled={!selectedUser.user}
                                                        name='password'
                                                        type='password'
                                                        label='Password'
                                                        variant='outlined'
                                                        value={values?.password || ''}
                                                        onChange={(event) => onPasswordChange(event, setFieldValue, values)}
                                                        onBlur={handleBlur}
                                                        autoComplete='no'
                                                    />
                                                </Grid>
                                                <Grid item xs={12} md={6} className={classes.txtInput}>
                                                    <KeyboardDatePicker
                                                        disabled={!selectedUser.user}
                                                        label='Terminated Date'
                                                        name='termDate'
                                                        value={values?.termDate}
                                                        onChange={value => {
                                                            setFieldValue('termDate', value);
                                                            setFieldTouched('termDate');
                                                        }}
                                                        onBlur={() => setFieldTouched('termDate')}
                                                    />
                                                </Grid>
                                            </Grid>
                                        </div>

                                        <div className={classes.box}>
                                            <Typography className={classes.boxLabel}>All Companies</Typography>
                                            <div className={classes.overBox}>
                                                <TextField
                                                    fullWidth
                                                    className={classes.filterUsers}
                                                    placeholder='Search companies...'
                                                    name='from'
                                                    variant='standard'
                                                    value={filterCompany.from}
                                                    onChange={onChangeFilterCompany}
                                                />
                                                {companies.loadingFrom &&
                                                    <Grid
                                                        container
                                                        spacing={0}
                                                        align="center"
                                                        justifyContent="center"
                                                        direction="column">
                                                        <Grid item>
                                                            <CircularProgress size={50} />
                                                        </Grid>
                                                    </Grid>
                                                }
                                                <div className={classes.listItems}>
                                                    {filterCompanies.from.map((cp, index) => (
                                                        <Typography className={classes.user} key={index}
                                                            style={selectedCompanies.from.includes(cp) ? {
                                                                backgroundColor: '#43a047',
                                                                color: '#ffffff'
                                                            } : null}
                                                            onClick={() => onSelectCompany('from', cp)}>
                                                            {cp.name}{cp.legalName && ` (${cp.legalName})`}
                                                        </Typography>
                                                    ))}
                                                </div>
                                            </div>
                                        </div>
                                        <div className={classes.transferActions}>
                                            <Typography
                                                className={selectedCompanies.from.length === 0 || !selectedUser.user ? classes.btnDis : null}
                                                onClick={() => transferOne('from', 'selected', values)}>
                                                {'>'}
                                            </Typography>
                                            <Typography
                                                className={selectedCompanies.selected.length === 0 ? classes.btnDis : null}
                                                onClick={() => transferOne('selected', 'from', values)}>
                                                {'<'}
                                            </Typography>
                                            <Typography
                                                className={filterCompanies.from.length === 0 || !selectedUser.user ? classes.btnDis : null}
                                                onClick={() => transferAll('from', 'selected')}>
                                                {'>>'}
                                            </Typography>
                                            <Typography
                                                className={filterCompanies.selected.length === 0 ? classes.btnDis : null}
                                                onClick={() => transferAll('selected', 'from')}>
                                                {'<<'}
                                            </Typography>
                                        </div>
                                        <div className={classes.box}>
                                            <Typography className={classes.boxLabel}>Selected Companies</Typography>
                                            <div className={classes.overBox}>
                                                <TextField
                                                    fullWidth
                                                    className={classes.filterUsers}
                                                    placeholder='Search companies...'
                                                    name='selected'
                                                    variant='standard'
                                                    value={filterCompany.selected}
                                                    onChange={onChangeFilterCompany}
                                                />
                                                {companies.loadingSelected &&
                                                    <Grid
                                                        container
                                                        spacing={0}
                                                        align="center"
                                                        justifyContent="center"
                                                        direction="column">
                                                        <Grid item>
                                                            <CircularProgress size={50} />
                                                        </Grid>
                                                    </Grid>
                                                }
                                                <div className={classes.listItems}>
                                                    {filterCompanies.selected.map((cp, index) => (
                                                        <Typography className={classes.user} key={index}
                                                            style={selectedCompanies.selected.includes(cp) ? {
                                                                backgroundColor: '#43a047',
                                                                color: '#ffffff'
                                                            } : null}
                                                            onClick={() => onSelectCompany('selected', cp)}>
                                                            {cp.name}{cp.legalName && ` (${cp.legalName})`}
                                                        </Typography>
                                                    ))}
                                                </div>
                                            </div>
                                        </div>
                                        <div className={classes.blank}></div>
                                        <div className={classes.blank}></div>
                                        <div className={classes.selectRole}>
                                            {roles.loading && <CircularProgress size={30} className={classes.loadingRole} />}
                                            <div className={classes.rolesContainer}>
                                                <Typography className={classes.labelRoles}>Select Role</Typography>
                                                <div className={classes.overBox} style={{ padding: "0px 12px", height: '210px' }}>
                                                    {values.rolesMultiple && values.rolesMultiple.length > 0 && selectedCompanies.selected.length === 1
                                                        ? 
                                                            values.rolesMultiple.find(x => x.id == selectedCompanies.selected[0].id) 
                                                                && values.rolesMultiple.find(x => x.id == selectedCompanies.selected[0].id).roles.sort((a, b) => a.name?.localeCompare(b.name)).map((role, index) => (
                                                                <Grid className={classes.defaultCompany} item xs={12} key={role.id}>
                                                                    <FormControlLabel
                                                                        disabled={roles.loading || !selectedUser.user || selectedCompanies.selected[0] == null}
                                                                        control={<GreenRadio />}
                                                                        label={role.name}
                                                                        name={role.name}
                                                                        checked={values.rolesMultiple.find(x => x.id == selectedCompanies.selected[0].id).roles[index].isSelected || false}
                                                                        onChange={(event) => onChangeRoles(event, setFieldValue, values)}
                                                                        onBlur={handleBlur}
                                                                    />
                                                                </Grid>
                                                            ))
                                                        : roles && roles.items && roles.items.length > 0 && roles.items.sort((a, b) => a.name?.localeCompare(b.name)).map((role, index) => (
                                                            <Grid className={classes.defaultCompany} item xs={12} key={role.id}>
                                                                <FormControlLabel
                                                                    disabled={true}
                                                                    control={<GreenRadio />}
                                                                    label={role.name}
                                                                    name={role.name}
                                                                    checked={false}
                                                                />
                                                            </Grid>
                                                        ))
                                                    }
                                                </div>
                                            </div>
                                        </div>
                                    </CardContent>
                                    <Divider />
                                    <CardActions className={classes.cardActions}>
                                        <Button
                                            className={!saving && selectedUser.user && ((!companies.loadingSelected) || Object.values(touched).some(v => v))
                                                ? classes.btn
                                                : classes.btnDis}
                                            type="submit"
                                        >
                                            Save Changes
                                        </Button>
                                        {saving && <CircularProgress size={30} />}
                                    </CardActions>
                                </Card>
                            </form>
                        )}
                    </Formik>
                </div>
                <div className={classes.snackBar}>
                    <Snackbar anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
                        open={openSnackbar}
                        autoHideDuration={3000}
                        onClose={() =>setOpenSnackbar(false)}>
                        <Alert elevation={6} variant="filled" severity={isStatus.failed ? 'error' : 'success'}>
                            <Typography
                                color="inherit"
                                variant="h6">
                                {isStatus.msg}
                            </Typography>
                        </Alert>
                    </Snackbar>
                </div>
            </Page>
        </AccessControl>
    )
}

export default MultiCompanyUsers;
