import React from 'react'
import moment from 'moment'
import { Document, Page, Text, View, StyleSheet, PDFDownloadLink, Font, Image } from '@react-pdf/renderer';
import axios from "utils/axios";
import apiConfig from "apiConfig";
import { formatNumber } from "utils/formatNumber";

const RoutesPdf = (props) => {

    Font.register({
        family: 'Roboto',
        fonts: [
            {
                src: `https://cdn.jsdelivr.net/npm/roboto-fontface@0.10.0/fonts/roboto/Roboto-Regular.woff`,
                fontWeight: 'medium'
            },
            {
                src: `https://cdn.jsdelivr.net/npm/roboto-fontface@0.10.0/fonts/roboto/Roboto-Bold.woff`,
                fontWeight: 'bold'
            }
        ]
    })

    return (
        <>
            <Document>
                <Page size="A4" style={{ padding: 20, paddingTop: 20 }}>
                    {/* header */}
                    <View fixed>
                        <View wrap={false} style={{ width: '100%', flexDirection: 'row' }}>
                            <View style={{ width: '10%', height: 'auto', alignItems: 'center' }}>
                                <Text style={{ fontFamily: 'Roboto', fontWeight: 'medium', fontSize: 10 }}>
                                    {moment().format('MM/DD/YYYY')}
                                </Text>
                            </View>
                            <View style={{ width: '1%' }} />
                            <View style={{ width: '80%', height: 'auto', alignItems: 'center' }}>
                                <Text style={{ fontFamily: 'Roboto', fontWeight: 'bold', fontSize: 13 }}>
                                    Squeegee Squad
                                </Text>
                            </View>
                            <View style={{ width: '1%' }} />
                            <View style={{ width: '10%', height: 'auto', alignItems: 'center' }}>
                                <Text style={{ fontFamily: 'Roboto', fontWeight: 'medium', fontSize: 10 }} render={({ pageNumber }) => "Page: " + pageNumber} fixed />
                            </View>
                        </View>
                        <View wrap={false} style={{ width: '100%', flexDirection: 'row' }}>
                            <View style={{ width: '10%', height: 'auto', alignItems: 'center' }} />
                            <View style={{ width: '1%' }} />
                            <View style={{ width: '80%', height: 'auto', alignItems: 'center' }}>
                                <Text style={{ fontFamily: 'Roboto', fontWeight: 'bold', fontSize: 14 }}>
                                    Route Report for {moment(props.dataPdfRoutes.routeDate).format('MM/DD/YYYY')}
                                </Text>
                            </View>
                            <View style={{ width: '1%' }} />
                            <View style={{ width: '10%', height: 'auto', alignItems: 'center' }} />
                        </View>
                    </View>
                    {/* end header */}
                    {props.dataPdfRoutes && props.dataPdfRoutes.routes && props.dataPdfRoutes.routes.length > 0 && props.dataPdfRoutes.routes.map((data, index) => {
                        return (
                            <View wrap={false} key={index}>
                                <View style={{ width: '100%', flexDirection: 'row', marginTop: 5, borderBottomWidth: 1 }}>
                                    <Text style={{ fontFamily: 'Roboto', fontWeight: 'medium', fontSize: 10 }}>
                                        {data.cities.join(", ")}
                                        {" "}
                                        ${data.total.toFixed(2)}
                                    </Text>
                                </View>
                                <View style={{ width: '100%', paddingLeft: 10, paddingRight: 10 }}>
                                    {data && data.jobs && data.jobs.length > 0
                                        ?
                                        <View>
                                            <View>
                                                <View style={{ width: '100%', height: 'auto', marginTop: 3 }}>
                                                    <Text style={{ fontFamily: 'Roboto', fontWeight: 'medium', fontSize: 10, textDecoration: 'underline' }}>
                                                        Jobs
                                                    </Text>
                                                </View>
                                                {data && data.jobs && data.jobs.length > 0 && data.jobs.map((data, index) => {
                                                    return (
                                                        <View key={index} style={{ width: '100%', height: 'auto', marginTop: 3 }}>
                                                            <Text style={{ fontFamily: 'Roboto', fontWeight: 'medium', fontSize: 10 }}>
                                                                {data.total.toFixed(2)}
                                                                ({data.jobAddress.city || ''} {data.taxPercent}% ){data.flexibleScheduling ? " Anytime Today/Flexible": ""} - {data.jobStage} - {data.description} - {data.jobAddress.primaryContact && data.jobAddress.primaryContact.name}{", "}{data.jobAddress.address1 || ''}{", "}{data.jobAddress.city || ''}{", "}{data.jobAddress.state && (data.jobAddress.state.name || '')}{" "}{data.jobAddress.zipCode || ''}
                                                            </Text>
                                                        </View>
                                                    )
                                                })}
                                            </View>
                                        </View>
                                        : null}

                                    {/* employees */}
                                    {data && data.employees && data.employees.length > 0
                                        ? <View>
                                            <View style={{ width: '100%', height: 'auto', marginTop: 3 }}>
                                                <Text style={{ fontFamily: 'Roboto', fontWeight: 'medium', fontSize: 10, textDecoration: 'underline' }}>
                                                    Cleaners
                                                </Text>
                                            </View>
                                            {data && data.employees && data.employees.length > 0 && data.employees.map((data, index) => {
                                                return (
                                                    <View key={index} style={{ width: '100%', height: 'auto', marginTop: 3 }}>
                                                        <Text style={{ fontFamily: 'Roboto', fontWeight: 'medium', fontSize: 10 }}>
                                                            sub {data.firstName || ''}{" "}{data.lastName || ''}
                                                        </Text>
                                                    </View>
                                                )
                                            })}
                                        </View>
                                        : null}
                                    {/* end employees */}

                                    {/* equipment */}
                                    {data && data.equipment && data.equipment.length > 0
                                        ? <View>
                                            <View style={{ width: '100%', height: 'auto', marginTop: 3 }}>
                                                <Text style={{ fontFamily: 'Roboto', fontWeight: 'medium', fontSize: 10, textDecoration: 'underline' }}>
                                                    Equipment
                                                </Text>
                                            </View>
                                            {data && data.equipment && data.equipment.length > 0 && data.equipment.map((data, index) => {
                                                return (
                                                    <View key={index} style={{ width: '100%', height: 'auto', marginTop: 3 }}>
                                                        <Text style={{ fontFamily: 'Roboto', fontWeight: 'medium', fontSize: 10 }}>
                                                            {data}
                                                        </Text>
                                                    </View>
                                                )
                                            })}
                                        </View>
                                        : null}
                                    {/* end equipment */}

                                    {/* jobSiteNotes */}
                                    {data && ((data.jobSiteNotes && data.jobSiteNotes.length > 0) || data.routeNote)
                                        ? <View>
                                            <View style={{ width: '100%', height: 'auto', marginTop: 3 }}>
                                                <Text style={{ fontFamily: 'Roboto', fontWeight: 'medium', fontSize: 10, textDecoration: 'underline' }}>
                                                    Notes
                                                </Text>
                                            </View>
                                            {data.routeNote && <View style={{ width: '100%', height: 'auto', marginTop: 3 }}>
                                                <Text style={{ fontFamily: 'Roboto', fontWeight: 'medium', fontSize: 10 }}>
                                                    {data.routeNote}
                                                </Text>
                                            </View>}
                                            {data && data.jobSiteNotes && data.jobSiteNotes.length > 0 && data.jobSiteNotes.map((data, index) => {
                                                return (
                                                    <View key={index} style={{ width: '100%', height: 'auto', marginTop: 3 }}>
                                                        <Text style={{ fontFamily: 'Roboto', fontWeight: 'medium', fontSize: 10 }}>
                                                            {data}
                                                        </Text>
                                                    </View>
                                                )
                                            })}
                                        </View>
                                        : null}
                                    {/* end jobSiteNotes */}
                                </View>
                            </View>
                        )
                    })}
                </Page>
            </Document>
        </>
    )
}

RoutesPdf.propTypes = {

}

export default RoutesPdf;
