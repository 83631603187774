import React from 'react'
import { withStyles } from '@material-ui/styles'
import { colors, Radio } from '@material-ui/core'

const GreenRadio = withStyles({
    root: {
        color: colors.green[600]
    },
    checked: {},
})(props => <Radio color="default" {...props} />);

export default GreenRadio;