import React, { useState, useEffect } from 'react'
import { makeStyles } from '@material-ui/styles'
import {
    Card, CardHeader, CardContent,
    Divider, Grid, TextField, colors,
    Tooltip, Typography, Collapse
} from '@material-ui/core'
import { KeyboardArrowDown } from '@material-ui/icons'
import { useSelector, useDispatch } from 'react-redux'
import axios from 'utils/axios'
import apiConfig from 'apiConfig'
import { useParams } from 'react-router-dom'
import { Commission, SalesCommission, Salesperson, UpsalesCommission } from './components'
import { AddressLink } from 'components'

const useStyles = makeStyles(theme => ({
    sticky: {
        marginBottom: theme.spacing(2),
        position: 'sticky',
        zIndex: 3
    },
    stickyHead: {
        position: 'relative',
        cursor: 'pointer'
    },
    address: {
        fontWeight: 500
    },
    btnAction: {
        color: theme.palette.white,
        backgroundColor: colors.green[600],
        '&:hover': {
            backgroundColor: colors.green[900]
        }
    },
    addressLink: {
        textDecoration: 'underline',
        color:'#0000EE'
    }
}));

let mounted = false;
const Commissions = props => {
    const { states, disabled, information, setOpenModal, fuData, isCustomeService, zIndexCustom = 3, offsetTop } = props
    const classes = useStyles();
    const dispatch = useDispatch();
    const { expanded } = useSelector(state => state.JobsReducer);
    const { id, jobId } = useParams()
    const [employees, setEmployees] = useState([]);
    const [employeesAll, setEmployeesAll] = useState([]);
    const [reloadSalesperson, setReloadSalesperson] = useState(true);

    const toggleExpanded = () => {
        dispatch({ type: 'CHANGE_EXPANDED' })
    }

    const getEmployees = () => {
        axios.get(apiConfig.url.USER_URL + apiConfig.url.EMPLOYEE_LIST_ACTIVE_IN_COMPANY)
            .then(value => {
                setEmployees(value.data.users);
            }).finally(() => {
            })
    }
    const getEmployeesAll = () => {
        axios.get(apiConfig.url.USER_URL + apiConfig.url.EMPLOYEE_LIST_ACTIVE)
            .then(value => {
                setEmployeesAll(value.data.users);
            }).finally(() => {
        })
    }

    useEffect(() => {
        getEmployees();
        getEmployeesAll();
    }, [])

    return (
        <>
            <Grid container spacing={3} className={classes.sticky}>
                {/*<Grid className={classes.btnBox} item xs={12}>*/}
                {/*    <Button*/}
                {/*        disabled={!information.id}*/}
                {/*        variant={'contained'}*/}
                {/*        style={{ marginRight: '10px' }} className={classes.btnAction}*/}
                {/*        onClick={() => {*/}
                {/*        }}>*/}
                {/*        Print*/}
                {/*    </Button>*/}
                {/*    <Button*/}
                {/*        disabled={!information.id}*/}
                {/*        variant={'contained'}*/}
                {/*        style={{ marginRight: '10px' }} className={classes.btnAction}*/}
                {/*        onClick={() => {*/}
                {/*        }}>*/}
                {/*        Email*/}
                {/*    </Button>*/}
                {/*    <Button*/}
                {/*        disabled={!information.id}*/}
                {/*        variant={'contained'}*/}
                {/*        style={{ marginRight: '10px' }} className={classes.btnAction}*/}
                {/*        onClick={() => {*/}
                {/*        }}>*/}
                {/*        Copy*/}
                {/*    </Button>*/}
                {/*    <Button*/}
                {/*        variant={'contained'}*/}
                {/*        disabled={!information.id}*/}
                {/*        className={classes.btnAction}*/}
                {/*        onClick={setOpenModal}>*/}
                {/*        {fuData ? 'View Follow Up' : 'Schedule Follow Up'}*/}
                {/*    </Button>*/}
                {/*</Grid>*/}
            </Grid>
            <Card style={{ marginTop: '-18px', zIndex: zIndexCustom, top: offsetTop }} className={classes.sticky}>
                <CardHeader title={isCustomeService ? <> <span>Job Details</span><span style={{ color: 'red' }}> - Customer Service</span> </> : 'Job Details'}
                    className={classes.stickyHead}
                    action={<KeyboardArrowDown />}
                    onClick={toggleExpanded} />
                <Divider />
                <Collapse in={expanded} timeout='auto' unmountOnExit>
                    <CardContent>
                        <Grid container spacing={3}>
                            <Grid item xs={12} sm={4}>
                                <Card style={{ height: '100%' }}>
                                    <CardHeader title='Job Address' />
                                    <Divider />
                                    <CardContent style={{ padding: '16px' }}>
                                        {
                                            information && information.jobAddress &&
                                            (
                                                <>
                                                    <Typography className={classes.address}>{information.jobAddress.name || ''}</Typography>
                                                    <AddressLink
                                                        address={{
                                                            ...information.jobAddress,
                                                            state: { name: states?.find(x => x.id === information.jobAddress.stateId)?.name }
                                                        }}
                                                        className={classes.address}
                                                    />
                                                </>
                                            )
                                        }
                                    </CardContent>
                                </Card>
                            </Grid>
                            <Grid item xs={12} sm={8}>
                                {/*<MuiThemeProvider theme={customThemeTooltip}>*/}
                                <Tooltip
                                    disableFocusListener
                                    arrow
                                    title={
                                        <Typography style={{ color: 'white' }}>
                                            Job Description is only editable in tabs with a save button.
                                        </Typography>
                                    }>
                                    <TextField
                                        fullWidth
                                        disabled
                                        multiline
                                        rows={7}
                                        label='Job Description'
                                        name='description'
                                        variant='outlined'
                                        value={information.description || ''}
                                    />
                                </Tooltip>
                                {/*</MuiThemeProvider>*/}
                            </Grid>
                        </Grid>
                    </CardContent>
                </Collapse>
            </Card>
            <Salesperson information={information} disabled={disabled}  onSaved={() => setReloadSalesperson(!reloadSalesperson)} />
            <Commission id={id} jobId={jobId} tip={information.tip} tipDate={information.tipDate} employees={employees} employeesAll={employeesAll} disabled={disabled} />
            <SalesCommission id={id} jobId={jobId} information={information} employees={employees} employeesAll={employeesAll} splitType={information?.commissionSalesSplitType} jobItems={information.jobItems} disabled={disabled} reload={reloadSalesperson} />
            <UpsalesCommission id={id} jobId={jobId} information={information} employees={employees} employeesAll={employeesAll} splitType={information?.commissionUpsalesSplitType} jobItems={information.jobItems} disabled={disabled} />

            <div style={{ height: '1px', marginTop: '20px' }}></div>
        </>
    )
}

export default Commissions;
