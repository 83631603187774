import React from 'react';
import { SortableElement } from 'react-sortable-hoc';
import PriceLine from './PriceLine';

const SortableItem = SortableElement(({ dataIndex, item, onChangeItem, onChangeDesc, setChangeType, onCopyItem, onRemoveItem, onDirty, ...props }) => (
  <PriceLine
    indexItem={dataIndex}
    index={dataIndex}
    key={dataIndex}
    item={item}
    onChangeItem={(e) => {
      onChangeItem(e, dataIndex);
      setChangeType('discountPercent');
    }}
    onCopyItem={() => onCopyItem(item, dataIndex)}
    onRemoveItem={() => onRemoveItem(dataIndex)}
    onChangeDesc={(value, price, taxable, companyPriceItemId) => onChangeDesc(value, dataIndex, price, taxable, companyPriceItemId)}
    onDirty={(dirty) => onDirty(dirty, dataIndex)}
    {...props}
  />
));

export default SortableItem;
