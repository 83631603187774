import React from 'react';
import PropTypes from 'prop-types';
import { Helmet } from 'react-helmet';
import { makeStyles } from '@material-ui/styles'

const useStyles = makeStyles(theme => ({
    readOnly: {
        pointerEvents: 'none'
    }
}));

const Page = props => {
  const classes = useStyles();
  const { title, readOnly, children, ...rest } = props;

  return (
    <div className={readOnly ? classes.readOnly : null} {...rest}>
      <Helmet>
        <title>{title}</title>
      </Helmet>
      {children}
    </div>
  );
};

Page.propTypes = {
  title: PropTypes.string,
  readOnly: PropTypes.bool
}

export default Page;
