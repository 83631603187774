import React, { useState, useEffect, useRef } from 'react'
import PropTypes from 'prop-types'
import MonthPicker from './components/MonthPicker'
import YearPicker from './components/YearPicker'
import ActionsPicker from './components/ActionsPicker'
import { getYearList, getDaysInMonth, isEqual } from 'utils/date-time'

import styles from './DateRangePicker.module.scss'
import moment from "moment";

let mounted = false;
const DateRangePicker = ({ payPeriodList, startDate, endDate, onChangeRange, open, close }) => {

    const datepickerRef = useRef();
    const years = getYearList();

    const [pay, setPay] = useState();
    const [start, setStart] = useState(startDate);
    const [end, setEnd] = useState(endDate);
    const [showYears, setShowYears] = useState(false);

    const year = start.getFullYear();
    const month = start.getMonth();

    const [days, setDays] = useState([]);
    const [calendar, setCalendar] = useState({ year, month });

    const s = start ? start.toDateString().split(' ') : null;
    const e = end ? end.toDateString().split(' ') : null;

    const changePrevMonth = () => {
        const { year, month } = calendar;
        setCalendar({
            year: month - 1 < 0 ? year - 1 : year,
            month: month - 1 < 0 ? 11 : month - 1
        })
    }
    const changeNextMonth = () => {
        const { year, month } = calendar;
        setCalendar({
            year: month + 1 > 11 ? year + 1 : year,
            month: month + 1 > 11 ? 0 : month + 1
        })
    }
    const goToDate = (date) => {
        setCalendar({
            year: date.getFullYear(),
            month: date.getMonth()
        })
    }
    const onChangeDate = (date) => {
        const pay = payPeriodList.find(p => {
            const start = new Date(p.startDate);
            return isEqual(date, start);
        });
        setPay(pay);
        setStart(new Date(moment(pay.startDate).utc().format('YYYY-MM-DDTHH:mm:ss')));
        setEnd(new Date(moment(pay.endDate).utc().format('YYYY-MM-DDTHH:mm:ss')));
    }
    const handleChangeYear = (year) => {
        setCalendar({ ...calendar, year });
        setShowYears(false)
    }
    const handleOk = () => {
        onChangeRange(pay);
        setShowYears(false);
        close();
    }
    const handleCancel = () => {
        setStart(startDate);
        setEnd(endDate);
        setShowYears(false);
        close();
    }

    useEffect(() => {
        mounted = true;

        if (mounted) {
            setDays(getDaysInMonth(calendar.year, calendar.month));
        }

        return () => mounted = false;
    }, [calendar])

    useEffect(() => {
        const clickOutSideDatePicker = (e) => {
            if (datepickerRef && !datepickerRef.current.contains(e.target) && open) {
                setStart(startDate);
                setEnd(endDate);
                setShowYears(false);
                close();
            }
        }

        document.addEventListener('click', clickOutSideDatePicker);

        return () => document.removeEventListener('click', clickOutSideDatePicker);
    }, [open])

    return (
        <div className={`${styles.datepicker_modal} ${open ? styles.open : styles.close}`}>
            <div ref={datepickerRef} className={styles.datepicker}>
                <div className={styles.overboxPicker}>
                    <div className={styles.datenow}>
                        <p className={styles.year} onClick={() => setShowYears(true)}>{calendar.year}</p>
                        <div className={styles.dateRange}>
                            {s && (
                                <p className={styles.date_string} onClick={() => goToDate(start)}>
                                    {s[1] + ' ' + parseInt(s[2])}
                                </p>
                            )}
                            {s && e && <span className={styles.seprateDate}>-</span>}
                            {e && (
                                <p className={styles.date_string} onClick={() => goToDate(end)}>
                                    {e[1] + ' ' + parseInt(e[2])}
                                </p>
                            )}
                        </div>
                    </div>
                    <div className={styles.calendar}>
                        <MonthPicker
                            showYears={showYears}
                            days={days}
                            payPeriodList={payPeriodList}
                            start={start}
                            end={end}
                            calendar={calendar}
                            onChangeDate={onChangeDate}
                            prevMonth={changePrevMonth}
                            nextMonth={changeNextMonth}
                        />
                        <YearPicker
                            showYears={showYears}
                            years={years}
                            selectedYear={calendar.year}
                            handleYear={handleChangeYear}
                        />
                    </div>
                </div>
                <ActionsPicker handleCancel={handleCancel} handleOk={handleOk} />
            </div>
        </div>
    )
}

DateRangePicker.propTypes = {
    payPeriodList: PropTypes.arrayOf(Object),
    startDate: PropTypes.instanceOf(Date),
    endDate: PropTypes.instanceOf(Date),
    onChangeRange: PropTypes.func.isRequired,
    open: PropTypes.bool,
    close: PropTypes.func.isRequired
}

DateRangePicker.defaultProps = {
    payPeriodList: [],
    startDate: new Date(),
    endDate: new Date(),
    onChangeRange: () => { },
    open: false,
    close: () => { }
}

export default DateRangePicker;
