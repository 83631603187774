import React, { useEffect } from 'react';
import { Redirect } from 'react-router-dom';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/styles';
import { Tabs, Tab, Divider, colors, Typography } from '@material-ui/core';
import { Page } from 'components';
import {
  Header,
  Information,
  Security,
  Compensation,
  Attachments,
  Availability,
  TimeOff
} from './components';
import localStorage from "utils/localStorage";
import { PERMISSIONS } from "common/permissions";


const useStyles = makeStyles(theme => ({
  root: {
    height: '100%',
    padding: theme.spacing(4),
    paddingTop: 24
  },
  tabs: {
    
  },
  divider: {
    backgroundColor: colors.grey[300]
  },
  content: {
    paddingBottom: '16px',
    marginTop: theme.spacing(3)
  }
}));

const EmployeeDetails = props => {

  const { match, history } = props;
  const classes = useStyles();
  const { id, tab } = match.params;
  const name = decodeURIComponent(match.params.name);
  const account = localStorage.getFullInfo();
  const isOfficeStaff = localStorage.getUserRole() === PERMISSIONS.OFFICE_STAFF;
  const handleTabsChange = (event, value) => {
    history.push(value);
  };

  let tabs = [
    { value: 'information', label: 'Information' },
    { value: 'attachments', label: 'Attachments' },
    { value: 'schedule', label: 'Schedule' },
    { value: 'timeoff', label: 'Time Off' },
    { value: 'salary', label: 'Compensation' },
    { value: 'security', label: 'Security' },
  ];

  if (!tab) {
    //return <Redirect to={`/users/${id}`} />;
    return <Redirect to={`/users/${id}`} />;
  }

  if (!tabs.find(t => t.value === tab)) {
    return <Redirect to="/errors/error-404" />;
  }

  if(isOfficeStaff && id != account.nameid){
    tabs = tabs.filter(x=>x.value !== 'salary');
  }

  return (
    <Page
      className={classes.root}
      title={name === ' ' ? 'Employee Detail' : `${name} - Employee Detail`}
    >
      <Typography style={{ display: 'inline' }}>{name ? `Employee - ${name}` : 'Employee'}</Typography>
      <Tabs
        className={classes.tabs}
        onChange={handleTabsChange}
        scrollButtons="auto"
        value={tab}
        variant="scrollable"
      >
        {tabs.map(tab => (
          <Tab
            key={tab.value}
            label={tab.label}
            value={tab.value}
          />
        ))}
      </Tabs>
      <Divider className={classes.divider} />
      <div className={classes.content}>
        {tab === 'information' && <Information userId={id} />}
        {tab === 'attachments' && <Attachments userId={id} />}
        {tab === 'schedule' && <Availability userId={id} />}
        {tab === 'timeoff' && <TimeOff userId={id} />}
        {tab === 'security' && <Security userId={id} />}
        {tab === 'salary' && <Compensation userId={id} />}
      </div>
    </Page>
  );
};

EmployeeDetails.propTypes = {
  history: PropTypes.object.isRequired,
  match: PropTypes.object.isRequired
};

export default EmployeeDetails;
