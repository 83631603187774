
const initState = {
    routeEquipment: null,
    loadingEquipment: false
}

const EquipmentsReducer = (state = initState, action) => {
    let equipments = state.routeEquipment ? [...state.routeEquipment] : [];

    switch (action.type) {
        case 'INIT_EQUIPMENT':
            return {
                ...state,
                routeEquipment: action.routeEquipment
            }
        case 'LOAD_MORE_EQUIPMENT':
            return {
                ...state,
                routeEquipment: [...equipments, ...action.routeEquipment]
            }
        case 'LOADING_EQUIPMENT':
            return {
                ...state,
                loadingEquipment: action.loadingEquipment
            }
        case 'ADD_EQUIPMENT':
            equipments.push(action.addEquipment)
            return {
                ...state,
                routeEquipment: equipments
            }
        case 'REMOVE_EQUIPMENT':
            equipments = equipments.filter(x => !action.equipment.map(e => e.id).includes(x.id))
            return {
                ...state,
                routeEquipment: equipments
            }
        default:
            return state;
    }
}

export default EquipmentsReducer;
