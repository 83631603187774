import React from 'react'
import PropTypes from 'prop-types'
import { Typography } from '@material-ui/core'
import { Clear } from '@material-ui/icons'
import { makeStyles } from '@material-ui/styles'

const useStyles = makeStyles(theme => ({
    selected: {
        position: 'relative',
        padding: '0 5px',
        paddingRight: '20px',
        border: '1px solid gray',
        borderRadius: '4px',
        marginRight: '5px'
    },
    iconDel: {
        position: 'absolute',
        top: 0,
        right: 0,
        fontSize: '20px'
    },
}));

const Selected = ({ value, remove }) => {
    const classes = useStyles();

    const removeSelected = (event, value) => {
        event.stopPropagation();
        remove(value)
    }

    return (
        <Typography className={classes.selected}>
            {value}
            <Clear className={classes.iconDel} onClick={event => removeSelected(event, value)} />
        </Typography>
    )
}

Selected.propTypes = {
    value: PropTypes.string,
    remove: PropTypes.func
}

export default Selected;