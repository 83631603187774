import React, { useRef, useEffect } from 'react'
import PropTypes from 'prop-types'
import styles from '../DateRangePicker.module.scss'

const YearPicker = ({ years, selectedYear, handleYear, showYears }) => {

    const yearRef = useRef();
    const yearsRef = useRef();

    useEffect(() => {
        if (yearRef && yearRef.current && yearsRef && yearsRef.current) {
            const offsetTop = yearRef.current.offsetTop;
            yearsRef.current.scrollTop = offsetTop - 324;
        }
    }, [selectedYear, showYears])

    return (
        <div className={styles.yearsBox} style={{ display: showYears ? 'block' : 'none' }}>
            <div className={styles.yearPicker} ref={yearsRef}>
                {years.map((y, i) => (
                    <p key={y} ref={selectedYear == y ? yearRef : null}
                        className={selectedYear == y ? styles.selectedYear : ''}
                        onClick={() => handleYear(parseInt(y))}>
                        {y}
                    </p>
                ))}
            </div>
        </div>
    )
}

YearPicker.propTypes = {
    years: PropTypes.array.isRequired,
    selectedYear: PropTypes.number,
    handleYear: PropTypes.func.isRequired
}

export default YearPicker;