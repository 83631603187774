import React, { useEffect, useState } from 'react'
import {
    CardContent, CircularProgress
} from '@material-ui/core'
import { useParams } from 'react-router-dom'
import axios from "../../../utils/axios";
import apiConfig from 'apiConfig'
import JobMultilpleQuotePdf from 'views/CustomerDetails/components/JobAddresses/components/JobMultilpleQuotePdf';
import { useTheme } from "@material-ui/core/styles";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import { pdf } from '@react-pdf/renderer';
import * as PDFMerger from 'pdf-merger-js';

var qs = require('qs');


const MutilpleQuote = props => {
    const [loadingData, setLoadingData] = useState(true);
    const [salesPerson, setSalesPerson] = useState(null);
    const [estimator, setEstimator] = useState(null);
    const [bidCompleted, setBidCompleted] = useState(null);
    const [mergedPdfUrl, setMergedPdfUrl] = useState();
    const { id } = useParams();
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('xs'));

    useEffect(() => {
        const attachmentsStr = localStorage.getItem('attachments');
        localStorage.removeItem('attachments');
        const attachments = (attachmentsStr && JSON.parse(attachmentsStr)) || [];
        axios.get(apiConfig.url.BASE_URL + apiConfig.url.MULTILPLE_QUOTE_PDF, 
            {
                params: {
                    jobIds: id.split(",")
                },
                paramsSerializer: params => {
                    return qs.stringify(params)
                }
            }
        )
            .then(async res => {
                if(res.data){
                    var salesPersonFilter = res.data.quotes
                        .filter(el => {
                            return (
                                el.job.salespersons.length > 0
                            );
                        });
                    if(salesPersonFilter && salesPersonFilter.length > 0)
                        setSalesPerson(salesPersonFilter[0].job.salespersons[0]);

                    var estimatorFilter = res.data.quotes
                        .filter(el => {
                            return (
                                el.job.estimator 
                                && (el.job.estimator.firstName != null || el.job.estimator.lastName != null) 
                                && salesPersonFilter && salesPersonFilter.length > 0 ? (salesPersonFilter[0].job.salespersons[0].firstName != el.job.estimator.firstName && salesPersonFilter[0].job.salespersons[0].lastName != el.job.estimator.lastName) : true
                            );
                        });
                    if(estimatorFilter && estimatorFilter.length > 0)
                        setEstimator(estimatorFilter[0].job.estimator);

                    if(salesPersonFilter && salesPersonFilter.length == 0) {
                        if(estimatorFilter && estimatorFilter.length > 1) {
                            setSalesPerson(estimatorFilter[1].job.estimator);
                        }
                    }

                    if(estimatorFilter && estimatorFilter.length == 0) {
                        if(salesPersonFilter && salesPersonFilter.length > 1) {
                            setEstimator(salesPersonFilter[1].job.salespersons[0]);
                        }
                    }

                    var bidCompletedFilter = new Date(Math.max.apply(null, res.data.quotes.map(function(e) {
                        return new Date(e.job.bidCompleted);
                    })));
                    setBidCompleted(bidCompletedFilter);

                    const quotePdf = await pdf(<JobMultilpleQuotePdf jobInfoPdf={res.data} salesPerson={salesPerson} estimator={estimator} bidCompleted={bidCompleted} />).toBlob();

                    let merger = new PDFMerger();

                    let hasPrimary = attachments.some(a => a.isPrimary);
                    if (!hasPrimary) await merger.add(quotePdf);

                    for (const attachment of attachments) {
                        if (attachment.isPrimary) {
                            await merger.add(quotePdf);
                            continue;
                        }
                        if (attachment.contentType !== 'application/pdf') continue;

                        try {
                            await merger.add(attachment.path);
                        } catch (e) {
                            console.error(e);
                            continue;
                        }
                    }

                    const mergedPdf = await merger.saveAsBlob();
                    const url = URL.createObjectURL(mergedPdf);
                    setMergedPdfUrl(url);
                }
            })
            .catch(err => {
                console.log("err", err);
            })
            .finally(() => setLoadingData(false));
    }, [])

    useEffect(() => {
        if (mergedPdfUrl && isMobile) {
            window.location.href = mergedPdfUrl;
        }
    }, [mergedPdfUrl, isMobile]);

    return (
        <>
            {
                !loadingData 
                    ? <iframe
                        src={mergedPdfUrl}
                        title="pdf-viewer"
                        style={{ position: 'absolute', border: 0, height: '100vh', width: '100%' }}
                    ></iframe>
                    : <CardContent><CircularProgress size={24}/></CardContent>
            }
        </>
    )
}

export default MutilpleQuote;
