import React, { useEffect, useState, useRef } from 'react'
import {
    TableContainer, Table, TableBody, TableRow, TableCell, colors, Typography, FormControlLabel, Link, CardContent, CircularProgress
} from '@material-ui/core'
import { DragHandle } from '@material-ui/icons'
import { makeStyles } from '@material-ui/styles'
import { SortableContainer, SortableElement, SortableHandle } from 'react-sortable-hoc'
import arrayMove from 'array-move'
import OpenInNew from "@material-ui/icons/OpenInNew";
import {GreenCheckbox} from 'components'
import { Document, Page, Text, View, StyleSheet, PDFViewer } from '@react-pdf/renderer';
import { useParams } from 'react-router-dom'
import axios from "../../../utils/axios";
import apiConfig from 'apiConfig'
import RoutesPdf from "views/Routes/components/RoutesBox/components/RoutesPdf";

const styles = StyleSheet.create({
    page: {
      flexDirection: 'row',
      backgroundColor: '#E4E4E4'
    },
    section: {
      margin: 10,
      padding: 10,
      flexGrow: 1
    }
});

const useStyles = makeStyles(theme => ({
    
}));

const AllRoutes = props => {
    const classes = useStyles();
    const [dataPdfRoutes, setDataPdfRoutes] = useState([]);
    const [loadingData, setLoadingData] = useState(false);
    const { date } = useParams()

    useEffect(() => {
        setLoadingData(true);
        axios
			.get(apiConfig.url.BASE_URL + apiConfig.url.ROUTE_PRINT + `${date}`)
			.then(res => {
				setDataPdfRoutes(res.data);
			})
            .catch(err => {
                console.log("err", err);
            })
            .finally(() => setLoadingData(false));
    }, [])

    return (
        <>
            {!loadingData 
                ? <PDFViewer width={'100%'} height={'100%'}>
                    <RoutesPdf dataPdfRoutes={dataPdfRoutes} />
                </PDFViewer>
                : <CardContent><CircularProgress size={24}/></CardContent>
            }
        </>
    )
}

export default AllRoutes;
