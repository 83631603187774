import { combineReducers } from 'redux'
import ScheduleReducer from './ScheduleReducer'
import DayoffReducer from './DayoffReducer'
import CountReducer from './CountReducer'
import EmployeesReducer from './EmployeesReducer'
import EquipmentsReducer from './EquipmentsReducer'
import JobsReducer from './JobsReducer'
import RoutesReducer from './RoutesReducer'
import SchedulesReducer from './SchedulesReducer'
import ReloadReducer from './ReloadReducer'
// import RolesReducer from "./RolesReducer";
import AppReducer from "./AppReducer";

const allReducers = combineReducers({
    ScheduleReducer,
    DayoffReducer,
    CountReducer,
    EmployeesReducer,
    EquipmentsReducer,
    JobsReducer,
    RoutesReducer,
    SchedulesReducer,
    ReloadReducer,
    // RolesReducer,
    AppReducer,
})

export default allReducers;
