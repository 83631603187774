import React, { useEffect, useState, useRef } from 'react'
import {
    Card, CardHeader, CardContent, CardActions, Divider, Collapse,
    TableContainer, Table, TableHead, TableBody, TableRow, TableCell,
    TextField, Button, Grid, CircularProgress, IconButton, colors, Typography, Switch, FormControlLabel
} from '@material-ui/core'
import {ArrowDownward, ArrowUpward, KeyboardArrowDown} from '@material-ui/icons'
import { AddBox, Delete } from '@material-ui/icons'
import { makeStyles } from '@material-ui/styles'
import { CeleroModal, QuickbooksModal } from './components'
import { v4 as uuidv4 } from 'uuid'
import uuid from "uuid/v1";
import axios from "utils/axios";
import apiConfig from "apiConfig";
import { Formik } from "formik";
import {ConfirmModal, FormErrorCallback, GreenRadio, Pagination} from "components";
import * as yup from "yup";
import Snackbar from "@material-ui/core/Snackbar";
import Alert from "@material-ui/lab/Alert";
import * as Yup from "yup";
import { errorMessages } from 'common/constants'
import useMediaQuery from '@material-ui/core/useMediaQuery'
import LogConnector from "./components/LogConnector";
import localStorage from "../../../../utils/localStorage";
import { PERMISSIONS } from "../../../../common/permissions";
import * as moment from "moment";

const useStyles = makeStyles(theme => ({
    cardHeader: {
        cursor: 'pointer'
    },
    btn: {
        whiteSpace: 'nowrap',
        color: theme.palette.white,
        backgroundColor: colors.green[600],
        '&:hover': {
            backgroundColor: colors.green[900]
        }
    },
    btnDis: {
        whiteSpace: 'nowrap',
        color: 'rgba(0, 0, 0, 0.26)',
        backgroundColor: 'rgb(217 217 217)',
        pointerEvents: 'none',
        border: 'none'
    },
    addIcon: {
        padding: '0',
        '& span svg': {
            fontSize: '40px',
            color: colors.green[600],
            '&:hover': {
                color: colors.green[900]
            }
        }
    },
    headRow: {
        '& th': {
            padding: '8px 16px',
            borderBottom: '1px solid #888888',
            fontSize: '16px',
        }
    },
    bodyRow: {
        '& td': {
            padding: '8px 16px'
        }
    },
    iconSort: {
        marginBottom: '-5px',
        marginLeft: '10px'
    },
    customTableCell: {
        borderBottom: '1px solid #969696',
        cursor: 'pointer',
        '& span': {
            display: 'flex',
            alignItems: 'center',
        }
    },
    paginate: {
        marginTop: theme.spacing(3),
        display: 'flex',
        justifyContent: 'center'
    }
}));

const idSchema = yup.object().shape({
    asTheSealId: yup.number().typeError('Please enter valid number').min(0, 'Min value 0')
        .nullable().required('This field is required.'),
});

const apiSchema = yup.object().shape({
    siteName: yup.string()
        .nullable().required('This field is required.'),
    siteUrl: yup.string()
        .nullable().required('This field is required.'),
    apiKey: yup.string()
        .nullable().required('This field is required.'),
});

const apiSchemaAll = Yup.object().shape({
    apiAccess: Yup.array()
        .of(
            apiSchema
        )
});

const sourceSchema = yup.object().shape({
    description: yup.string()
        .nullable().required('This field is required.')
});
const sourceSchemaAll = Yup.object().shape({
    sources: Yup.array()
        .of(
            sourceSchema
        )
});

const initLog = [
    {
        date: '01/01/2020',
        message: 'log 01log 01log 01log 01log 01log 01log 01log 01log 01log 01log 01log 01'
    },
    {
        date: '02/01/2020',
        message: 'log 2log 2log 2log 2log 2log 2log 2log 2log 2log 2log 2log 2log 2log 2log 2'
    }
];
let pageNumber = 1;
let forcePage = 0;

let pageNumberSource = 1;
let forcePageSource = 0;

const Integrations = ({ adminId }) => {

    const isMounted = useRef(false);
    const classes = useStyles();

    const sm = useMediaQuery('(max-width: 415px)');
    const ipPlus = useMediaQuery('(max-width: 769px)');
    const mobile = useMediaQuery('(max-width: 960px)');

    const [saveIntegration, setSaveIntegration] = useState(false);
    const [saveSource, setSaveSource] = useState(false);
    const [saveAPI, setSaveAPI] = useState();
    const [openCelero, setOpenCelero] = useState(false);
    const [openQuickbooks, setOpenQuickbooks] = useState(false);
    const [quickBooks, setQuickBooks] = useState();
    const [apiAccess, setAPIAccess] = useState([]);
    const [sources, setSources] = useState([]);
    const [reload, setReload] = useState(false);
    const [reloadAsTheSeal, setReloadAsTheSeal] = useState(false);
    const [reloadSource, setReloadSource] = useState(false);
    const [loading, setLoading] = useState(true);
    const [isStatus, setIsStatus] = useState({ failed: false, msg: '' });
    const [isSaving, setIsSaving] = useState(false);
    const [openSnackbar, setOpenSnackBar] = useState(false);
    const [openConfirm, setOpenConfirm] = useState(false);
    const [isProgress, setIsProgress] = useState(false);
    const [isProgressQBOImport, setIsProgressQBOImport] = useState(false);
    const [locateFile, setLocateFile] = useState();
    const [errorApiAccess, setErrorApiAccess] = useState({})
    const [touchedApiAccess, setTouchedApiAccess] = useState({})
    const [errorSource, setErrorSource] = useState({})
    const [touchedSource, setTouchedSource] = useState({})
    const [expandedIntegrations, setExpandedIntegrations] = useState(true);
    const [expandedCelero, setExpandedCelero] = useState(true);
    const [expandedQuickbooks, setExpandedQuickbooks] = useState(true);
    const [expandedAPI, setExpandedAPI] = useState(true);
    const [expandedSources, setExpandedSources] = useState(true);
    const [openLogConnector, setOpenLogConnector] = useState(false);
    const [openLogCelero, setOpenLogCelero] = useState(false);
    const [sortBy, setSortBy] = useState('SiteName');
    const [sortType, setSortType] = useState('ASC');
    const [pageCount, setPageCount] = useState(0);
    const [checkQBO, setCheckQBO] = useState(false);
    const [qboActiveToken, setQBOActiveToken] = useState();

    const [sortBySource, setSortBySource] = useState('SourceId');
    const [sortTypeSource, setSortTypeSource] = useState('ASC');
    const [pageCountSource, setPageCountSource] = useState(0);
    const APIAccess = useRef(null)
    const userRole = localStorage.getUserRoles();
    // API Access
    const addAPI = () => {
        const api = { siteName: '', siteURL: '', apiKey: '', add: true, touched: {}, companyId: adminId };
        setAPIAccess([api, ...apiAccess])
    }
    const onChangeAPI = (event, index) => {
        const { name, value } = event.target;
        const apis = [...apiAccess];
        apis[index] = { ...apis[index], [name]: value, change: true };
        setAPIAccess(apis)
    }
    const onGenerateKey = index => {
        const apis = [...apiAccess];
        apis[index] = { ...apis[index], apiKey: uuidv4(), change: true }
        setAPIAccess(apis)
    }

    const removeAPI = index => {
        const apis = [...apiAccess];
        apis.splice(index, 1);
        setAPIAccess(apis)
    }

    // Sources
    const addSource = () => {
        setSources([{ description: '', add: true, touched: {}, companyId: adminId }, ...sources])
    }
    const onChangeSource = (event, index) => {
        const { name, value } = event.target;
        const src = [...sources];
        src[index] = { ...src[index], [name]: value, change: true };
        setSources(src)
    }
    const removeSource = index => {
        const src = [...sources];
        src.splice(index, 1);
        setSources(src)
    }

    const onBlueApi = (key, index) => {
        const touched = { ...touchedApiAccess };
        touched[`apiAccess[${index}].${key}`] = true;
        setTouchedApiAccess(touched);
    }

    const onBlueSource = (key, index) => {
        const touched = { ...touchedSource };
        touched[`sources[${index}].${key}`] = true;
        setTouchedSource(touched);
    }

    const onDeleteApi = () => {
        if (locateFile && locateFile.add) {
            removeAPI(locateFile.index)
            setOpenConfirm(false);
        } else {
            setIsProgress(true);
            axios.delete(apiConfig.url.BASE_URL + apiConfig.url.COMPANY_INTEGRATIONS_API_DELETE.replace('{companyId}', adminId).replace('{siteAccessId}', locateFile.id))
                .then(() => {
                    setIsStatus({ failed: false, msg: 'API Integration Removed' });
                    setOpenSnackBar(true);
                    removeAPI(locateFile.index)
                })
                .catch(() => {
                    setIsStatus({ failed: true, msg: 'Remove api failed, please try again later' });
                    setOpenSnackBar(true);
                })
                .finally(() => {
                    setIsProgress(false);
                    setOpenConfirm(false);
                })
        }
    }

    const onDeleteSource = () => {
        if (locateFile && locateFile.add) {
            removeSource(locateFile.index);
            setOpenConfirm(false);
        } else {
            setIsProgress(true);
            axios.delete(apiConfig.url.BASE_URL + apiConfig.url.COMPANY_INTEGRATIONS_SOURCE_DELETE.replace('{companyId}', adminId).replace('{adSourceId}', locateFile.id))
                .then(() => {
                    setIsStatus({ failed: false, msg: 'Source Removed' });
                    setOpenSnackBar(true);
                    removeSource(locateFile.index)
                })
                .catch(() => {
                    setIsStatus({ failed: true, msg: 'Remove source failed, please try again later' });
                    setOpenSnackBar(true);
                })
                .finally(() => {
                    setIsProgress(false);
                    setOpenConfirm(false);
                })
        }
    }

    const validateApiAccess = (api, index, submit = false) => {
        apiSchema.validate(api, { abortEarly: false }).then(() => {
            setErrorApiAccess({});
            if (submit) {
                setSaveIntegration(true);
                if (api.siteAccessId) {
                    axios.put(apiConfig.url.BASE_URL + apiConfig.url.COMPANY_INTEGRATIONS_API.replace('{companyId}', adminId), api)
                        .then(res => {
                            setIsStatus({ failed: false, msg: 'Record Saved' });
                            setOpenSnackBar(true);
                            api.change = false
                            // setReload(!reload)
                        })
                        .catch(err => {
                            setIsStatus({ failed: true, msg: 'An error occurred, please try again later' });
                            setOpenSnackBar(true);
                        })
                        .finally(e => setSaveIntegration(false));
                } else {
                    api.siteAccessId = uuid();
                    axios.post(apiConfig.url.BASE_URL + apiConfig.url.COMPANY_INTEGRATIONS_API.replace('{companyId}', adminId), api)
                        .then(res => {
                            setIsStatus({ failed: false, msg: 'Record Saved' });
                            setOpenSnackBar(true);
                            const apis = [...apiAccess];
                            apis[index] = res.data;
                            setAPIAccess(apis);
                            // setReload(!reload)
                            api.change = false
                        })
                        .catch(err => {
                            setIsStatus({ failed: true, msg: 'An error occurred, please try again later' });
                            setOpenSnackBar(true);
                        })
                        .finally(e => setSaveIntegration(false));
                }
            }
        })
            .catch(function (err) {
                let listError = {};
                let listTouched = {};
                err.inner.forEach((e, i) => {
                    listTouched[`apiAccess[${index}].${e.path}`] = true;
                    listError[`apiAccess[${index}].${e.path}`] = e.message;
                });
                if (submit) {
                    setTouchedApiAccess(listTouched);
                }
                setErrorApiAccess(listError)
            });
    }
    const validateSource = (source, index, submit = false) => {
        sourceSchema.validate(source, { abortEarly: false }).then(() => {
            setErrorSource({});
            if (submit) {
                setSaveSource(true);
                if (source.id) {
                    axios.put(apiConfig.url.BASE_URL + apiConfig.url.COMPANY_INTEGRATIONS_SOURCE.replace('{companyId}', adminId), source)
                        .then(res => {
                            setIsStatus({ failed: false, msg: 'Record Saved' });
                            setOpenSnackBar(true);
                            source.change = false
                            // setReloadSource(!reloadSource)
                        })
                        .catch(err => {
                            setIsStatus({ failed: true, msg: 'An error occurred, please try again later' });
                            setOpenSnackBar(true);
                        })
                        .finally(e => setSaveSource(false));
                } else {
                    axios.post(apiConfig.url.BASE_URL + apiConfig.url.COMPANY_INTEGRATIONS_SOURCE.replace('{companyId}', adminId), source)
                        .then(res => {
                            setIsStatus({ failed: false, msg: 'Record Saved' });
                            setOpenSnackBar(true);
                            source.change = false
                            const sr = [...sources];
                            sr[index] = res.data;
                            setSources(sr);
                            setReloadSource(!reloadSource)
                        })
                        .catch(err => {
                            setIsStatus({ failed: true, msg: 'An error occurred, please try again later' });
                            setOpenSnackBar(true);
                        })
                        .finally(e => setSaveSource(false));
                }
            }
        })
            .catch(function (err) {
                let listError = {};
                let listTouched = {};
                err.inner.forEach((e, i) => {
                    listTouched[`sources[${index}].${e.path}`] = true;
                    listError[`sources[${index}].${e.path}`] = e.message;
                });
                setTouchedSource(listTouched);
                setErrorSource(listError)
            });
    }

    const saveAllApiAccess = () => {
        apiSchemaAll.validate({ apiAccess: apiAccess }, { abortEarly: false }).then(() => {
            setErrorApiAccess([])
            setSaveIntegration(true)

            axios.put(apiConfig.url.BASE_URL + apiConfig.url.COMPANY_INTEGRATIONS_API_ALL.replace('{companyId}', adminId), apiAccess)
                .then(res => {
                    setIsStatus({ failed: false, msg: 'Record Saved' });
                    setOpenSnackBar(true);
                    setReload(!reload)
                })
                .catch(err => {
                    setIsStatus({ failed: true, msg: 'An error occurred, please try again later' });
                    setOpenSnackBar(true);
                })
                .finally(e => setSaveIntegration(false));
        })
            .catch(function (err) {
                let listError = {};
                let listTouched = {};
                err.inner.forEach((e, index) => {
                    listTouched[e.path] = true;
                    listError[e.path] = e.message;
                });
                setTouchedApiAccess(listTouched);
                setErrorApiAccess(listError);
            });
    }

    const saveAllSource = () => {
        sourceSchemaAll.validate({ sources: sources }, { abortEarly: false }).then(() => {
            setErrorSource([])
            setSaveSource(true)
            axios.put(apiConfig.url.BASE_URL + apiConfig.url.COMPANY_INTEGRATIONS_SOURCE_ALL.replace('{companyId}', adminId), sources)
                .then(res => {
                    setIsStatus({ failed: false, msg: 'Record Saved' });
                    setOpenSnackBar(true);
                    setReloadSource(!reloadSource)
                })
                .catch(err => {
                    setIsStatus({ failed: true, msg: 'An error occurred, please try again later' });
                    setOpenSnackBar(true);
                })
                .finally(e => setSaveSource(false));
        })
            .catch(function (err) {
                let listError = {};
                let listTouched = {};
                err.inner.forEach((e, index) => {
                    listTouched[e.path] = true;
                    listError[e.path] = e.message;
                });
                setTouchedSource(listTouched);
                setErrorSource(listError);
            });
    }

    const updateQuickBooks = (qbs) => {
        setQuickBooks(qbs)
    }

    const loadDocumentSource = (type) => {
        setSortBySource(type)
        setSortTypeSource(type == sortBySource ? (sortTypeSource === 'ASC' ? 'DESC' : 'ASC') : 'ASC');
        pageNumberSource = 1;
        forcePageSource = 0;
    }

    const getSortIconSource = (type) => {
        return (
            sortBySource == type &&
            (
                sortTypeSource == 'ASC' ?
                    <ArrowUpward className={classes.iconSort}/>
                    :
                    <ArrowDownward className={classes.iconSort}/>
            )
        )
    }

    const onPageChangeSource = (page) => {
        pageNumberSource = page.selected + 1;
        forcePageSource = page.selected;
        getSource();
    };


    const getQBOToken = () => {
        axios.get(apiConfig.url.BASE_URL + apiConfig.url.QBO_GET_ACTIVE_TOKEN)
            .then(res => {
                setQBOActiveToken(res.data);
            })
    }

    useEffect(() => {
        isMounted.current = true;
        setLoading(true);
        axios.get(apiConfig.url.BASE_URL + apiConfig.url.COMPANY_INTEGRATIONS.replace('{id}', adminId))
            .then(res => {
                if (isMounted.current) {
                    setSaveAPI({ asTheSealId: res.data });
                    setLoading(false);
                }
            })
        return () => isMounted.current = false;
    }, [reloadAsTheSeal])

    const loadDocument = (type) => {
        setSortBy(type)
        setSortType(type == sortBy ? (sortType === 'ASC' ? 'DESC' : 'ASC') : 'ASC');
        pageNumber = 1;
        forcePage = 0;
    }
    const getSortIcon = (type) => {
        return (
            sortBy == type &&
            (
                sortType == 'ASC' ?
                    <ArrowUpward className={classes.iconSort}/>
                    :
                    <ArrowDownward className={classes.iconSort}/>
            )
        )
    }
    const onPageChange = (page) => {
        pageNumber = page.selected + 1;
        forcePage = page.selected;
        APIAccess.current.scrollIntoView()
        getAPIAccess();
    };
    const getAPIAccess = () => {
        setLoading(true);
        axios.get(apiConfig.url.BASE_URL + apiConfig.url.COMPANY_INTEGRATIONS_API.replace('{companyId}', adminId),{
            params: {
                sortBy: sortBy,
                sortType: sortType,
                limit: 0,
                pageSize: 24,
                pageNumber: pageNumber,
            }
        })
            .then(res => {
                if (isMounted.current) {
                    res.data.data.map((item) => {
                        item.touched = {}
                    })
                    setPageCount(res.data.pageCount);
                    setAPIAccess(res.data.data);
                    setLoading(false);
                }

            })
    }

    useEffect(() => {
        getAPIAccess();
    }, [sortBy, sortType])

    useEffect(() => {
        isMounted.current = true;
        getAPIAccess()
        return () => isMounted.current = false;
    }, [reload])

    const getSource = () => {
        setLoading(true);
        axios.get(apiConfig.url.BASE_URL + apiConfig.url.COMPANY_INTEGRATIONS_SOURCE.replace('{companyId}', adminId),{
            params:
                {
                    sortBy: sortBySource,
                    sortType: sortTypeSource,
                    limit: 0,
                    pageSize: 24,
                    pageNumber: pageNumberSource,
                }
        })
            .then(res => {
                if (isMounted.current) {
                    res.data.data.map((item) => {
                        item.touched = {}
                    })
                    setSources(res.data.data);
                    setPageCountSource(res.data.pageCount);
                    setLoading(false);
                }
            })
    }

    useEffect(() => {
        isMounted.current = true;
        getSource();
        return () => isMounted.current;
    }, [reloadSource])

    useEffect(() => {
        isMounted.current = true;
        axios.get(apiConfig.url.USER_URL + apiConfig.url.QUICK_BOOKS)
            .then(res => setQuickBooks(res.data))
            .catch(() => setQuickBooks({}))

        getQBOToken();

        return () => isMounted.current = false;
    }, [])

    useEffect(() => {
        getSource();
    }, [sortBySource, sortTypeSource])

    const switchHandlerQBO = (e) => {
        const useQBO = e.target.name === 'online';
        axios.put(apiConfig.url.BASE_URL + apiConfig.url.UPDATE_QUICK_BOOKS_ONLINE.replace('{companyId}', adminId) + '?useQuickbooksOnline='+useQBO)
        .then(res => {
            setCheckQBO(useQBO)
        })
        .catch(err => {
            setCheckQBO(false)
            setIsStatus({ failed: true, msg: 'An error occurred, please try again later' });
            setOpenSnackBar(true);
        });
    }

    useEffect(() => {
        isMounted.current = true;
        axios.get(apiConfig.url.BASE_URL + apiConfig.url.COMPANY_INFO + adminId)
            .then(res => {
                if (isMounted.current) {
                    setCheckQBO(res.data.useQuickbooksOnline)
                }
            })
        return () => isMounted.current = false;
    }, [])

    const handleAuthorizeConnect = () => {
        axios.get(apiConfig.url.BASE_URL + apiConfig.url.AUTHORIZE_CONNECTION)
        .then(res => {
            connectAuthorizeConnect(res.data);
        })
        .catch(err => {
            setIsStatus({ failed: true, msg: 'An error occurred, please try again later' });
            setOpenSnackBar(true);
        });
    }

    const connectAuthorizeConnect = (data) =>{
        if(data !== ''){
            var left, top;
                left = window.screen.width / 2 - 275;
                top = window.screen.height / 10;
            const url = apiConfig.url.BASE_URL + apiConfig.url.CONNECTION_AUTHORIZE_CONNECTION+'?requestId='+encodeURIComponent(data);
            const customWindow = 'toolbar=no, menubar=no, resizable=yes,top='+top+', left='+left+', width=600, height=750';
            const connectWindow = window.open(url, 'window', customWindow);

            var timer = setInterval(function() {
                if(connectWindow.closed) {
                    clearInterval(timer);
                    getQBOToken();
                }
            }, 100);
        }else{
            setIsStatus({ failed: true, msg: 'An error occurred, please try again later' });
            setOpenSnackBar(true);
        }
    }

    const handleSystemImport = () => {
        setIsProgressQBOImport(true);
        axios.post(apiConfig.url.BASE_URL + apiConfig.url.QBO_SYSTEM_IMPORT)
            .then(res => {
                if (res && res.data) {
                    setIsStatus({ failed: false, msg: 'System Import Request Sent to Quickbooks Online' });
                } else {
                    setIsStatus({ failed: true, msg: 'An error occurred while sending the request' });
                }
                setOpenSnackBar(true);
            })
            .catch(err => {
                setIsStatus({ failed: true, msg: 'An error occurred while sending the request' });
                setOpenSnackBar(true);
            }).finally(() => setIsProgressQBOImport(false));
    };

    if (!saveAPI || !apiAccess || !sources || !quickBooks) {
        return (
            <Grid
                container
                spacing={0}
                align="center"
                justifyContent="center"
                direction="column">
                <Grid item>
                    <CircularProgress className={classes.circularProgress} size={50} />
                </Grid>
            </Grid>
        )
    }
    return (
        <>
            <Formik
                initialValues={saveAPI}
                validationSchema={idSchema}
                onSubmit={values => {
                    setIsSaving(true);
                    axios.put(apiConfig.url.BASE_URL + apiConfig.url.COMPANY_INTEGRATIONS.replace('{id}', adminId), values)
                        .then(res => {
                            setIsStatus({ failed: false, msg: 'Record Saved' });
                            setOpenSnackBar(true);
                            setReloadAsTheSeal(!reloadAsTheSeal)
                        })
                        .catch(err => {
                            setIsStatus({ failed: true, msg: 'An error occurred, please try again later' });
                            setOpenSnackBar(true);
                        })
                        .finally(e => setIsSaving(false));
                }}
            >
                {({
                    values,
                    errors,
                    touched,
                    submitCount,
                    isValid,
                    setFieldValue,
                    setFieldTouched,
                    handleSubmit,
                    submitForm
                }) => (
                    <form onSubmit={handleSubmit}>
                        <FormErrorCallback
                            submitCount={submitCount}
                            isValid={isValid}
                            onSubmissionError={() => {
                                setIsStatus({ failed: true, msg: errorMessages.FIELD_CHECK });
                                setOpenSnackBar(true);
                            }}
                        />Record Saved
                        <Card>
                            <CardHeader className={classes.cardHeader}
                                title='Ask The Seal'
                                action={<KeyboardArrowDown />}
                                onClick={() => setExpandedIntegrations(!expandedIntegrations)}
                            />
                            <Divider />
                            <Collapse in={expandedIntegrations} timeout='auto' unmountOnExit>
                                <CardContent style={{ paddingBottom: 0 }}>
                                    <TextField
                                        inputProps={{ maxLength: 9 }}
                                        fullWidth
                                        label='Ask The Seal ID'
                                        name='asTheSealId'
                                        variant='outlined'
                                        error={errors.asTheSealId && touched.asTheSealId}
                                        helperText={(errors.asTheSealId && touched.asTheSealId) && errors.asTheSealId}
                                        onBlur={() => setFieldTouched('asTheSealId')}
                                        onChange={event => setFieldValue('asTheSealId', event.target.value)}
                                        value={values.asTheSealId || ''}
                                    />
                                </CardContent>
                                <CardActions style={{ paddingLeft: '16px' }}>
                                    <Button
                                        className={isSaving ? classes.btnDis : classes.btn}
                                        onClick={() => {
                                            submitForm().then(r => {
                                            });
                                        }}
                                    >
                                        Save
                                    </Button>
                                    {isSaving && <CircularProgress size={24} />}
                                </CardActions>
                            </Collapse>
                        </Card>
                    </form>
                )}
            </Formik>

            <Card style={{ marginTop: '24px' }}>
                <CardHeader className={classes.cardHeader}
                    title='Celero'
                    action={<KeyboardArrowDown />}
                    onClick={() => setExpandedCelero(!expandedCelero)}
                />
                <Divider />
                <Collapse in={expandedCelero} timeout='auto' unmountOnExit>
                    <CardActions style={{ justifyContent: 'space-between', paddingLeft: '16px' }}>
                        <Grid container spacing={2} style={{ alignItems: 'center' }}>
                            <Grid item xs={12} md={12} sm={12} >
                                <Button className={classes.btn}
                                    style={{ marginRight: '4px', width: 'auto' }}
                                    onClick={() => setOpenCelero(true)}>
                                    Configure Celero
                                </Button>
                                {userRole.includes(PERMISSIONS.SUPER_ADMIN) &&
                                <Button className={classes.btn}
                                        style={{marginLeft: '4px', width: 'auto', display: "inline-block"}}
                                        onClick={() => setOpenLogCelero(true)}>
                                    View Celero Transaction Logs
                                </Button>
                                }
                            </Grid>
                        </Grid>
                    </CardActions>
                </Collapse>
            </Card>

            <Card style={{ marginTop: '24px' }}>
                <CardHeader className={classes.cardHeader}
                    title='Quickbooks'
                    action={<KeyboardArrowDown />}
                    onClick={() => setExpandedQuickbooks(!expandedQuickbooks)}
                />
                <Divider />
                <Collapse in={expandedQuickbooks} timeout='auto' unmountOnExit>
                    <CardActions style={{ justifyContent: 'space-between', paddingLeft: '16px' }}>
                        <Grid container spacing={2} style={{ alignItems: 'center' }}>
                            <Grid item xs={12}>
                                <FormControlLabel
                                    style={{ width: '105px' }}
                                    checked={!checkQBO}
                                    label={<strong>Desktop:</strong>}
                                    name="desktop"
                                    onChange={switchHandlerQBO}
                                    control={
                                        <GreenRadio />
                                    }
                                />
                            </Grid>
                            <Grid item xs={12} md={12} sm={12} >
                                <Button variant="contained"
                                    className={!checkQBO ? classes.btn : ''}
                                    style={{ marginRight: '4px', width: 'auto' }}
                                    onClick={() => setOpenQuickbooks(true)}
                                    disabled={checkQBO}>
                                    Configure Web Connector
                                </Button>
                                {(userRole.includes(PERMISSIONS.COMPANY_ADMINISTRATOR) || userRole.includes(PERMISSIONS.SUPER_ADMIN)) &&
                                <Button variant="contained"
                                        className={!checkQBO ? classes.btn : ''}
                                        style={{marginLeft: '4px', width: 'auto', display: "inline-block"}}
                                        onClick={() => setOpenLogConnector(true)}
                                        disabled={checkQBO}>
                                    view web connector logs
                                </Button>
                                }
                            </Grid>
                            <Grid item xs={12}>
                                <FormControlLabel
                                    style={{ width: '105px' }}
                                    checked={checkQBO}
                                    label={<strong>Online:</strong>}
                                    name="online"
                                    onChange={switchHandlerQBO}
                                    control={
                                        <GreenRadio />
                                    }
                                />
                            </Grid>
                            <Grid item xs={12} style={{ display: 'flex', alignItems: 'center' }}>
                                <Button
                                    variant="contained"
                                    className={(checkQBO) ? classes.btn : ""}
                                    style={{ marginRight: '4px', width: 'auto' }}
                                    onClick={handleAuthorizeConnect}
                                    disabled={!checkQBO}
                                >
                                    Authorize Connection
                                </Button>
                                <Button
                                    variant="contained"
                                    className={(checkQBO) ? classes.btn : ""}
                                    style={{ marginLeft: '4px', width: 'auto', display: "inline-block" }}
                                    onClick={() => setOpenLogConnector(true)}
                                    disabled={!checkQBO}
                                >
                                    View Sync Logs
                                </Button>
                                <Button
                                    variant="contained"
                                    className={(checkQBO) ? classes.btn : ""}
                                    style={{ marginLeft: '4px', width: 'auto', display: "inline-block" }}
                                    onClick={handleSystemImport}
                                    disabled={!checkQBO || isProgressQBOImport}
                                >
                                    System Import
                                </Button>
                                {isProgressQBOImport && <CircularProgress size={24} style={{ marginLeft: '4px' }} />}
                            </Grid>
                            {qboActiveToken && <Grid item xs={12}>
                                <Typography>Last Authorized: {moment.utc(qboActiveToken.dateCreated).local().format('MM-DD-YYYY HH:mm')}</Typography>
                                <Typography>Token Expires: {moment.utc(qboActiveToken.refreshToken_Expires).local().format('MM-DD-YYYY HH:mm')}</Typography>
                            </Grid>}
                        </Grid>
                    </CardActions>
                </Collapse>
            </Card>

            <Card style={{ marginTop: '24px' }}>
                <CardHeader ref={APIAccess} className={classes.cardHeader}
                    title='API Access'
                    action={<KeyboardArrowDown />}
                    onClick={() => setExpandedAPI(!expandedAPI)}
                />
                <Divider />
                <Collapse in={expandedAPI} timeout='auto' unmountOnExit>
                    <CardContent>
                        <TableContainer>
                            <Table style={{ minWidth: '900px' }}>
                                <TableHead>
                                    <TableRow className={classes.headRow}>
                                        <TableCell onClick={() => loadDocument('SiteName')} className={classes.customTableCell} align='center'><span>Site Name{ getSortIcon('SiteName')}</span></TableCell>
                                        <TableCell onClick={() => loadDocument('SiteURL')} className={classes.customTableCell} align='center'><span>Site URL{ getSortIcon('SiteURL')}</span></TableCell>
                                        <TableCell onClick={() => loadDocument('APIKey')} className={classes.customTableCell} align='center'><span>API Key{ getSortIcon('APIKey')}</span></TableCell>
                                        <TableCell style={{ width: '1px' }}></TableCell>
                                        <TableCell align='center' style={{ width: '1px' }}>
                                            <CardActions>
                                                <Button className={(!apiAccess.find(api => api.change) || (saveIntegration && !locateFile)) ? classes.btnDis : classes.btn}
                                                    style={{ float: 'right' }}
                                                    onClick={() => {
                                                        setLocateFile(null)
                                                        saveAllApiAccess()
                                                    }}>
                                                    Save All
                                                </Button>
                                                {saveIntegration && !locateFile && <CircularProgress size={24} />}
                                            </CardActions>
                                        </TableCell>
                                        <TableCell align='center' style={{ width: '5%' }}>
                                            <IconButton className={classes.addIcon} onClick={addAPI}>
                                                <AddBox />
                                            </IconButton>
                                        </TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {apiAccess.map((api, index) => (
                                        <TableRow key={index} className={classes.bodyRow}>
                                            <TableCell>
                                                <TextField
                                                    fullWidth
                                                    name='siteName'
                                                    variant='outlined'
                                                    value={api.siteName || ''}
                                                    onBlur={() => {
                                                        onBlueApi('siteName', index)
                                                        validateApiAccess(api, index)
                                                    }}
                                                    onChange={e => onChangeAPI(e, index)}
                                                    error={errorApiAccess && errorApiAccess[`apiAccess[${index}].siteName`] && touchedApiAccess[`apiAccess[${index}].siteName`]}
                                                    helperText={errorApiAccess && touchedApiAccess[`apiAccess[${index}].siteName`] && errorApiAccess[`apiAccess[${index}].siteName`]}
                                                />
                                            </TableCell>
                                            <TableCell>
                                                <TextField
                                                    fullWidth
                                                    name='siteUrl'
                                                    variant='outlined'
                                                    value={api.siteUrl || ''}
                                                    onChange={e => onChangeAPI(e, index)}
                                                    error={errorApiAccess && errorApiAccess[`apiAccess[${index}].siteUrl`] && touchedApiAccess[`apiAccess[${index}].siteUrl`]}
                                                    helperText={errorApiAccess && touchedApiAccess[`apiAccess[${index}].siteUrl`] && errorApiAccess[`apiAccess[${index}].siteUrl`]}
                                                    onBlur={() => {
                                                        onBlueApi('siteUrl', index)
                                                        validateApiAccess(api, index)
                                                    }}
                                                />
                                            </TableCell>
                                            <TableCell>
                                                <TextField
                                                    fullWidth
                                                    name='apiKey'
                                                    variant='outlined'
                                                    value={api.apiKey || ''}
                                                    onChange={e => onChangeAPI(e, index)}
                                                    error={errorApiAccess && errorApiAccess[`apiAccess[${index}].apiKey`] && touchedApiAccess[`apiAccess[${index}].apiKey`]}
                                                    helperText={errorApiAccess && touchedApiAccess[`apiAccess[${index}].apiKey`] && errorApiAccess[`apiAccess[${index}].apiKey`]}
                                                    onBlur={() => {
                                                        onBlueApi('apiKey', index)
                                                        validateApiAccess(api, index)
                                                    }}
                                                />
                                            </TableCell>
                                            <TableCell>
                                                <Button className={classes.btn} style={{ whiteSpace: 'nowrap' }}
                                                    onClick={() => onGenerateKey(index)}>
                                                    Generate Key
                                                </Button>
                                            </TableCell>
                                            <TableCell>
                                                <CardActions>
                                                    <Button
                                                        className={(!api.change || (saveIntegration && locateFile && locateFile.index === index)) ? classes.btnDis : classes.btn}
                                                        onClick={() => {
                                                            setLocateFile({
                                                                index: index,
                                                                id: api.siteAccessId,
                                                                add: api.add
                                                            });
                                                            validateApiAccess(api, index, true);
                                                        }}
                                                        variant={'outlined'}>
                                                        Save
                                                    </Button>
                                                    {saveIntegration && locateFile && locateFile.index === index && <CircularProgress size={24} />}
                                                </CardActions>
                                            </TableCell>
                                            <TableCell>
                                                <IconButton onClick={() => {
                                                    setLocateFile({
                                                        index: index,
                                                        id: api.siteAccessId,
                                                        add: api.add,
                                                        type: 'api'
                                                    });
                                                    setOpenConfirm(true)
                                                }}>
                                                    <Delete />
                                                </IconButton>
                                            </TableCell>
                                        </TableRow>
                                    ))}

                                </TableBody>
                            </Table>
                            {
                                <div style={pageCount === 0 ? { display: 'none' } : null} className={classes.paginate}>
                                    <Pagination pageCount={pageCount} forcePage={forcePage} onPageChange={onPageChange} />
                                </div>
                            }

                        </TableContainer>
                    </CardContent>
                </Collapse>
            </Card>

            <Card style={{ marginTop: '24px' }}>
                <CardHeader className={classes.cardHeader}
                    title='Sources'
                    action={<KeyboardArrowDown />}
                    onClick={() => setExpandedSources(!expandedSources)}
                />
                <Divider />
                <Collapse in={expandedSources} timeout='auto' unmountOnExit>
                    <CardContent>
                        <TableContainer>
                            <Table style={{ minWidth: '900px' }}>
                                <TableHead>
                                    <TableRow className={classes.headRow}>
                                        <TableCell className={classes.customTableCell} onClick={() => loadDocumentSource('SourceId')}  align='center' style={{ width: 1 }}><span>Source ID{ getSortIconSource('SourceId')}</span></TableCell>
                                        <TableCell className={classes.customTableCell} onClick={() => loadDocumentSource('Source')}  align='center'><span>File Name{ getSortIconSource('Source')}</span></TableCell>
                                        <TableCell align='center' style={{ width: '1px' }}>
                                            <CardActions>
                                                <Button className={(!sources.find(src => src.change) || (saveSource && !locateFile)) ? classes.btnDis : classes.btn}
                                                    onClick={() => {
                                                        setLocateFile(null);
                                                        saveAllSource();
                                                    }}>
                                                    Save All
                                                </Button>
                                                {saveSource && !locateFile && <CircularProgress size={24} />}
                                            </CardActions>
                                        </TableCell>
                                        <TableCell align='center' style={{ width: '1px' }}>
                                            <IconButton className={classes.addIcon} onClick={addSource}>
                                                <AddBox />
                                            </IconButton>
                                        </TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {sources.map((src, index) => (
                                        <TableRow key={index} className={classes.bodyRow}>
                                            <TableCell align='center'>
                                                <Typography>{src.referenceId || ''}</Typography>
                                            </TableCell>
                                            <TableCell>
                                                <TextField
                                                    disabled={!src.companyId}
                                                    fullWidth
                                                    name='description'
                                                    variant='outlined'
                                                    value={src.description || ''}
                                                    onChange={e => onChangeSource(e, index)}
                                                    onBlur={() => {
                                                        onBlueSource('description', index)
                                                        validateSource(src, index)
                                                    }}
                                                    error={errorSource && errorSource[`sources[${index}].description`] && touchedSource[`sources[${index}].description`]}
                                                    helperText={errorSource && touchedSource[`sources[${index}].description`] && errorSource[`sources[${index}].description`]}
                                                />
                                            </TableCell>
                                            <TableCell>
                                                <CardActions>
                                                    <Button
                                                        className={(!src.change || (saveSource && locateFile && locateFile.index === index)) ? classes.btnDis : classes.btn}
                                                        disabled={!src.companyId}
                                                        onClick={() => {
                                                            setLocateFile({
                                                                index: index,
                                                                id: src.id,
                                                                add: src.add
                                                            });
                                                            validateSource(src, index, true);
                                                        }}
                                                        variant={'outlined'}
                                                    >
                                                        Save
                                                    </Button>
                                                    {saveSource && locateFile && locateFile.index === index &&
                                                        <CircularProgress size={24} />}
                                                </CardActions>
                                            </TableCell>
                                            <TableCell>
                                                {src.companyId &&
                                                <CardActions style={{padding: '4px'}}>
                                                    <IconButton
                                                        style={{float: 'right'}}
                                                        onClick={() => {
                                                            setLocateFile({
                                                                index: index,
                                                                id: src.id,
                                                                add: src.add,
                                                                type: 'source'
                                                            });
                                                            setOpenConfirm(true)
                                                        }}
                                                    >
                                                        <Delete/>
                                                    </IconButton>
                                                </CardActions>
                                                }
                                            </TableCell>
                                        </TableRow>
                                    ))}
                                </TableBody>
                            </Table>
                            {
                                <div style={pageCountSource === 0 ? { display: 'none' } : null} className={classes.paginate}>
                                    <Pagination pageCount={pageCountSource} forcePage={forcePageSource} onPageChange={onPageChangeSource} />
                                </div>
                            }
                        </TableContainer>
                    </CardContent>
                </Collapse>
            </Card>
            <ConfirmModal
                openConfirm={openConfirm}
                closeConfirm={() => setOpenConfirm(false)}
                onConfirm={locateFile && (locateFile.type === 'api' ? onDeleteApi : onDeleteSource)}
                isProgress={isProgress}
            />
            <CeleroModal
                open={openCelero}
                onClose={() => setOpenCelero(false)}
            />
            <QuickbooksModal
                quickBooks={quickBooks}
                updateQuickBooks={updateQuickBooks}
                open={openQuickbooks}
                onClose={() => setOpenQuickbooks(false)}
            />
                <LogConnector
                open={openLogConnector}
                onClose={() => setOpenLogConnector(false)}
                />
            <Snackbar anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
                open={openSnackbar}
                autoHideDuration={3000}
                onClose={() => setOpenSnackBar(false)}>
                <Alert elevation={6} variant="filled" severity={isStatus.failed ? 'error' : 'success'}>
                    <Typography
                        color="inherit"
                        variant="h6">
                        {isStatus.msg}
                    </Typography>
                </Alert>
            </Snackbar>
            <div style={{ height: 1, marginTop: 20 }}></div>
        </>
    )
}

export default Integrations;
