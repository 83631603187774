import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { CompensationHistory } from './components';
import { AddCompensation } from './components';
import { CircularProgress, Grid } from '@material-ui/core'
import { makeStyles } from '@material-ui/styles';
import Fab from '@material-ui/core/Fab';
import AddIcon from '@material-ui/icons/Add';
import localStorage from 'utils/localStorage'
import { PERMISSIONS } from "common/permissions";

import axios from 'utils/axios';
import apiConfig from 'apiConfig';

const useStyles = makeStyles(theme => ({
    cardReadOnly: {
        pointerEvents: 'none'
    },
    fab: {
        position: 'fixed',
        bottom: theme.spacing(4),
        right: theme.spacing(4),
    },
    circularProgress: {
        marginTop: theme.spacing(3)
    }
}));

const Compensation = props => {
    const { userId } = props;
    const classes = useStyles();

    const readOnly = localStorage.getUserRole() === PERMISSIONS.FIELD_TECHNICIAN;

    const [openEdit, setOpenEdit] = useState(false);
    const [salaryHistory, setSalaryHistory] = useState();
    const [loading, setLoading] = useState();
    const [editItem, setEditItem] = useState();

    const handleEditOpen = () => {
        setOpenEdit(true);
        setEditItem()

    };

    const fetchSalaryHistory = () => {
        setLoading(true);
        axios.get(apiConfig.url.USER_URL + apiConfig.url.EMPLOYEE_COMPENSATION_HISTORY + userId)
            .then(res => {
                if (res.data.salaries) {
                    const hasEndDate = res.data.salaries && res.data.salaries.filter(x => x.endDate).sort((a, b) => {
                        return new Date(b.endDate) - new Date(a.endDate)
                    });
                    const noEndDate = res.data.salaries && res.data.salaries.filter(x => !x.endDate).sort((a, b) => {
                        return new Date(b.startDate) - new Date(a.startDate)
                    });

                    setSalaryHistory([...noEndDate, ...hasEndDate]);

                } else {
                    setSalaryHistory([])
                }
            })
            .finally(e => setLoading(false))
    };
    const editHistory = (item) => {
        setOpenEdit(true);
        setEditItem(item);
    }

    useEffect(() => {
        fetchSalaryHistory();
    }, []);

    if (loading) {
        return (
            <Grid
                container
                spacing={0}
                align="center"
                justifyContent="center"
                direction="column">
                <Grid item>
                    <CircularProgress className={classes.circularProgress} size={50} />
                </Grid>
            </Grid>
        )
    }

    return (
        <div className={readOnly ? classes.cardReadOnly : null}>

            <CompensationHistory
                salaryHistory={salaryHistory}
                userId={userId}
                reload={fetchSalaryHistory}
                onEdit={editHistory}
                handleEditOpen ={handleEditOpen}
            />


            <AddCompensation
                salaryHistory={salaryHistory}
                userId={userId}
                salary={editItem}
                onUpdateCompensation={fetchSalaryHistory}
                onClose={() => setOpenEdit(false)}
                open={openEdit}

            />
        </div>
    );
};

Compensation.propTypes = {
    className: PropTypes.string,
    userId: PropTypes.string.isRequired
};

export default Compensation;
