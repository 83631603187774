import React from 'react'
import PropTypes from 'prop-types'
import { Button } from '@material-ui/core'
import styles from '../DateRangePicker.module.scss'

const ActionsPicker = ({ handleCancel, handleOk }) => {
    return (
        <div className={styles.actionsPicker}>
            <Button onClick={handleCancel}>Cancel</Button>
            <Button onClick={handleOk}>Ok</Button>
        </div>
    )
}

ActionsPicker.propTypes = {
    handleCancel: PropTypes.func.isRequired,
    handleOk: PropTypes.func.isRequired
}

ActionsPicker.defaultProps = {
    handleCancel: () => {},
    handleOk: () => {}
}

export default ActionsPicker;