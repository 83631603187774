import React, {useEffect, useState} from 'react'
import PropTypes from 'prop-types'
import moment from 'moment'
import {
    TableRow, TableCell,
    FormControlLabel, IconButton
} from '@material-ui/core'
import { useParams, Link } from 'react-router-dom'
import { Delete } from '@material-ui/icons'
import { makeStyles } from '@material-ui/styles'
import { GreenCheckbox } from 'components'
import {formatNumber} from "../../../../../utils/formatNumber";

const useStyles = makeStyles(theme => ({
    jobRow: {
        cursor: 'pointer',
        '&:hover': {
            backgroundColor: '#fafafa',
            textDecoration: 'none'
        }
    },
    jobCell: {
        padding: 0
    },
    combine: {
        margin: 0,
        '& span': {
            padding: '0'
        }
    },
    delete: {
        padding: '0'
    },
    jobLink: {
        color: 'inherit',
        display: 'block',
        width: '100%',
        height: '100%',
        padding: 16
    }
}));

const stages = ['None', 'To Bid', 'Bid Completed', 'To Be Scheduled', 'Scheduled', 'Job Completed', 'Cancelled', 'Customer Service']

const JobCard = ({ job, remove, onCheckCombine, data, selected }) => {
    sessionStorage.setItem('sw-address', data);
    const classes = useStyles();
    let { name, id } = useParams();
    name = decodeURIComponent(name);
    return (
        <>
        <TableRow className={classes.jobRow} key={job.id}>
            <TableCell className={classes.jobCell}>
                <Link className={classes.jobLink} to={`/customers/${encodeURIComponent(name)}/${id}/jobs/${job.id}/information`}>
                    {stages[job.bidStageId] || 'Unknow'}
                </Link>
            </TableCell>
            <TableCell className={classes.jobCell}>
                <Link className={classes.jobLink} to={`/customers/${encodeURIComponent(name)}/${id}/jobs/${job.id}/information`}>
                    {job.date && moment(job.date).format('MM/DD/YYYY') || ''}
                </Link>
            </TableCell>
            <TableCell className={classes.jobCell}>
                <Link className={classes.jobLink} to={`/customers/${encodeURIComponent(name)}/${id}/jobs/${job.id}/information`}>
                    {job.dateCreated && moment(job.dateCreated).format('MM/DD/YYYY') || ''}
                </Link>
            </TableCell>
            <TableCell className={classes.jobCell}>
                <Link className={classes.jobLink} to={`/customers/${encodeURIComponent(name)}/${id}/jobs/${job.id}/information`}>
                    {job.invoiceNumber || ''}
                </Link>
            </TableCell>
            <TableCell className={classes.jobCell}>
                <Link className={classes.jobLink} to={`/customers/${encodeURIComponent(name)}/${id}/jobs/${job.id}/information`}>
                    {job.purchaseOrder || '0'}
                </Link>
            </TableCell>
            <TableCell className={classes.jobCell}>
                <Link className={classes.jobLink} to={`/customers/${encodeURIComponent(name)}/${id}/jobs/${job.id}/information`}>
                    {job.description || ''}
                </Link>
            </TableCell>
            <TableCell className={classes.jobCell} style={{textAlign:'right'}}>
                <Link className={classes.jobLink} to={`/customers/${encodeURIComponent(name)}/${id}/jobs/${job.id}/information`}>
                    {(job.cost && `$${formatNumber(job.cost, 2)}`) || '$0.00'}
                </Link>
            </TableCell>
            <TableCell align='center'>
                <FormControlLabel
                    checked={selected.find(x => x.id === job.id)}
                    className={classes.combine}
                    onChange={onCheckCombine}
                    control={<GreenCheckbox />}
                />
            </TableCell>
            <TableCell>
                <IconButton className={classes.delete} onClick={remove}>
                    <Delete />
                </IconButton>
            </TableCell>
        </TableRow>
            </>
    )
}

JobCard.propTypes = {
    job: PropTypes.object,
    remove: PropTypes.func,
    onCheckCombine: PropTypes.func
}

export default JobCard;
