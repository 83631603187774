import React from "react";
import {
    Button, Card, CardActions,
    CardHeader, colors,
    Divider, Modal
} from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";

const useStyles = makeStyles(theme => ({
    styleModal: {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        outline: 'none',
        boxShadow: theme.shadows[20],
        width: "500px",
        maxHeight: '100%',
        overflowY: 'auto',
        maxWidth: '100%',
        [theme.breakpoints.down('sm')]: {
            width: "100%"
        },
    },
    conButton: {
        padding: '16px',
        justifyContent: 'flex-end'
    },
    buttonSave: {
        flexShrink: '0',
        backgroundColor: colors.green[600],
        '&:hover': {
            backgroundColor: colors.green[900]
        },
    },
    timeCheckbox: {
        marginLeft: '16px',
        width: '100%'
    },
    time: {
        width: '100%',
        marginRight: '16px'
    },
    btn: {
        fontSize: 12,
        color: theme.palette.white,
        backgroundColor: colors.green[600],
        whiteSpace: 'nowrap',
        marginLeft: 8,
        '&:hover': {
            backgroundColor: colors.green[900]
        }
    },
}))


const ModalCombine = props => {
    const classes = useStyles();

    const { openConfirm, closeConfirm, onConfirm } = props;

    return (
        <Modal open={openConfirm}
            style={{ minWidth: '500px' }}>
            <Card className={classes.styleModal}>
                <CardHeader style={{ padding: '28px' }} title='The selected jobs have been combined.' />
                <Divider />
                <CardActions className={classes.conButton}>
                    <div style={{ float: 'right' }}>
                        <Button
                            variant="contained"
                            size="large"
                            onClick={() => {
                                closeConfirm()
                            }}>
                            Close
                        </Button>
                    </div>
                    <div >
                        <Button
                            className={classes.btn}
                            variant="outlined"
                            size="large"
                            onClick={onConfirm}
                            style={{

                            }}>
                            <i style={{
                                fontSize: '26px',
                                margin: 'auto',
                                top: '0',
                                bottom: '0',
                                right: '0'
                            }} className="fas fa-external-link" />
                        </Button>
                    </div>
                </CardActions>
            </Card>
        </Modal>
    )
}
export default ModalCombine
