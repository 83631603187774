import React, { useEffect, useState, useRef } from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/styles';
import clsx from 'clsx';
import { Formik } from 'formik'
import * as Yup from 'yup'
import { AvatarEditor, ConfirmModal, FormErrorCallback, GreenCheckbox, Page, AddressInput } from 'components';
import {
    Avatar, Button, Card, CardActions, CardContent, CardHeader,
    colors, Divider, FormControlLabel, Grid, IconButton, TextField, Tooltip,
    Typography
} from '@material-ui/core'
import useMediaQuery from '@material-ui/core/useMediaQuery'
import NumberFormat from 'react-number-format'
import axios from 'utils/axios';
import apiConfig from 'apiConfig';
import Snackbar from '@material-ui/core/Snackbar';
import Alert from '@material-ui/lab/Alert';
import CircularProgress from '@material-ui/core/CircularProgress';
import DeleteIcon from "@material-ui/icons/Delete";
import { createTheme, MuiThemeProvider } from "@material-ui/core/styles";
import { AddBox } from "@material-ui/icons";
import ModalNotice from "../CustomerDetails/components/Information/components/ModalNotice";
import { useHistory } from 'react-router-dom'
import localStorage from "utils/localStorage";
import { errorMessages } from 'common/constants'

let _avatarFormData;
let customerAvatar = null;
let customerUploadFile = null;

const useStyles = makeStyles(theme => ({
    root: {
        alignItems: 'flex-start',

    },
    pageRoot: {
        height: '100%',
        overflowY: 'auto',
        padding: theme.spacing(4)
    },
    cardProfile: {
        width: '100%'
    },
    profileName: {
        fontSize: '20px',
        fontWeight: 500,
        textAlign: 'center',
        marginTop: '20px'
    },
    hoverAvatar: {
        '&:hover': {
            cursor: 'pointer'
        }
    },
    bigAvatar: {
        width: theme.spacing(20),
        height: theme.spacing(20),
        margin: 'auto'
    },
    alignRightAvatar: {
        display: 'flex',
        justifyContent: 'flex-end'
    },
    marginTopOfProgress: {
        marginTop: theme.spacing(2)
    },
    colorUploadBtn: {
        color: theme.palette.purple
    },
    saveBtn: {
        color: theme.palette.white,
        backgroundColor: colors.green[600],
        '&:hover': {
            backgroundColor: colors.green[900]
        }
    },
    snackBar: {
        width: '100%',
        '& > * + *': {
            marginTop: theme.spacing(2),
        },
    },
    circularProgress: {
        marginTop: '30px',
    },
    groupDatePicker: {
        position: 'relative'
    },
    iconDatePicker: {
        position: 'absolute',
        right: '16px',
        top: theme.spacing(2),
    },
    borderCell: {
        borderBottom: '1px solid #b9b9b9'
    },
    stickyBtn: {
        marginBottom: 0,
        position: '-webkit-sticky',
        position: 'sticky',
        backgroundColor: '#f4f6f8',
        top: '-35px',
        zIndex: 2,
    },
    phoneRow: {
        display: 'flex',
        alignItems: 'center'
    },
    checkbox: {
        margin: 0,
        '& .MuiIconButton-root': {
            padding: 0
        },
        '& .MuiTypography-root': {
            whiteSpace: 'nowrap'
        }
    },
    addIcon: {
        padding: '0',
        '& span svg': {
            fontSize: '40px',
            color: colors.green[600],
            '&:hover': {
                color: colors.green[900]
            }
        }
    },
    DeleteIcon: {
        cursor: 'pointer'
    },
    postQuickBooks: {
        margin: '-12px auto'
    },
    btnBox: {
        display: 'flex',
        alignItems: "center",
        justifyContent: 'flex-end'
    },
}));

const customThemeTooltip = createTheme({
    overrides: {
        MuiTooltip: {
            tooltip: {
                fontSize: '14px',
                maxWidth: 'none'
            }
        }
    }
})
const customerSchema = Yup.object().shape({
    companyName: Yup.string().required('This field is required.'),
    // firstName: Yup.string()
    //     .nullable().required('This field is required.'),
    // lastName: Yup.string()
    //     .nullable().required('This field is required.'),
    contacts: Yup.array().nullable()
        .of(Yup.object({
            firstName: Yup.string()
                .nullable(),
            lastName: Yup.string()
                .nullable(),
            email: Yup.string()
                .nullable().email('Email is invalid.'),
            phones: Yup.array().of(Yup.object({
                phoneNumber: Yup.string()
                    .matches(/\W\d{3}\W\s\w{3}\W\w{4}$|\W\d{3}\W\s\w{3}\W\w{4}x\d{1,6}|\W\d{3}\W\s\w{3}\W\w{4}x/, 'Invalid phone number.')
            }))
        })),
    // email: Yup.string()
    //     .nullable().required('This field is required.').email('Email is invalid.'),
    address: Yup.object({
        name: Yup.string()
            .nullable(),
        address1: Yup.string()
            .nullable().required('This field is required.'),
        city: Yup.string()
            .nullable().required('This field is required.'),
        state: Yup.object().nullable(),
        zipCode: Yup.string()
            .nullable()
            .required('This field is required.')
            .matches(/(^\d{5}$)|(^\d{5}-\d{4}$)/, 'Zip code is invalid.')
    }),
})

const initValue = {
    "showNoteOnOpen": false,
    "customerNote": "",
    "routeNote": "",
    "contacts": [{
        "firstName": "",
        "lastName": "",
        "email": "",
        "isPrimary": true,
        "phones": [{
            "isSendText": true,
            "id": "00000000-0000-0000-0000-000000000000",
            "formattedPhoneNumber": "",
            "phoneNumber": "",
            "type": null,
            "typeId": ""
        }]
    }],
    "id": "00000000-0000-0000-0000-000000000000",
    "firstName": "",
    "middleName": "",
    "lastName": "",
    "fullName": "",
    "address": {
        "id": "00000000-0000-0000-0000-000000000000",
        "name": "",
        "address1": "",
        "address2": "",
        "address3": "",
        "city": "",
        "state": null,
        "country": null,
        "zipCode": "",
        "addressType": 1,
        "taxGroupId": null,
        "displayAddress": ""
    },
    "phones": null,
    "email": "",
    "avatar": "",
    "companyName": "",
    "customerType": "Residential",
    "adSourceId": null,
    "error": null,
    "states": []
}

const Information = props => {

    const isMounted = useRef(false);
    const history = useHistory();
    const classes = useStyles();

    const ipPlus = useMediaQuery('(max-width: 769px)');
    const mobile = useMediaQuery('(max-width: 960px)');

    const { customerId, className, ...rest } = props;

    const addressInputRef = useRef();

    const [openAvatarModal, setOpenAvatarModal] = useState(false);
    const [openConfirm, setOpenConfirm] = useState(false);
    const [openSnackbar, setOpenSnackBar] = useState(false);
    const [isSaving, setIsSaving] = useState(false);
    const [isStatus, setIsStatus] = useState({ failed: false, msg: '' });
    const [customer, setCustomer] = useState(initValue);
    const [avatar, setAvatar] = useState(null);
    const [states, setStates] = useState([]);
    const [sources, setSources] = useState();

    const cId = localStorage.getCompanyId();
    /// upload profile
    const onUploadAvatar = () => {
        setOpenAvatarModal(true);
    }
    const submitAvatar = (customerId) => {
        return new Promise((resolve, reject) => {
            if (customerAvatar) {
                customerAvatar.getImageScaledToCanvas().toBlob((blob) => {

                    _avatarFormData = new FormData()
                    _avatarFormData.append("file", blob, customerUploadFile.name);
                    _avatarFormData.append('id', customerId);
                    _avatarFormData.append('isNew', true);

                    axios.put(apiConfig.url.CUSTOMER_URL + apiConfig.url.CUSTOMERS_ADD_IMAGE, _avatarFormData)
                        .then(res => {
                            onRemoveAvatar();
                            resolve(true);
                        })
                        .catch(err => {
                            reject(false)
                            console.log(err);
                        });
                }, customerUploadFile.type, 100)
            } else {
                resolve(false);
            }
        })

    }

    const onGetAvatar = (avatar, image) => {
        // debugger
        const img = avatar.getImageScaledToCanvas().toDataURL();
        setAvatar(img);
        customerAvatar = avatar;
        customerUploadFile = image;
    }

    const onRemoveAvatar = () => {
        setAvatar(null);
        customerAvatar = null;
        customerUploadFile = null;
        if (_avatarFormData && _avatarFormData.has('file')) {
            _avatarFormData.delete('file')
        }
    }

    const updateCloseModal = () => {
        const data = { ...customer, showNoteOnOpen: false }
        const payload = {
            id: customer.id,
            customerNote: customer.customerNote,
            routeNote: customer.routeNote,
            showNoteOnOpen: false
        }
        axios.post(apiConfig.url.CUSTOMER_URL + apiConfig.url.CUSTOMERS_UPDATE_SHOW_NOTE, payload)
            .then(res => {
                setCustomer(data)
            })
            .catch(err => {
            })
            .finally();
    }

    // phones
    const addPhone = (values, index, setFieldValue) => {
        const phone = {
            id: "00000000-0000-0000-0000-000000000000",
            formattedPhoneNumber: "",
            phoneNumber: "",
            type: null,
            typeId: customer.phoneTypes[0].id,
            isSendText: values.contacts[index].phones.length === 0 ? true : false
        }

        const contacts = [...values.contacts]
        contacts[index].phones.push(phone);
        setFieldValue('contacts', contacts);
    };
    const handlePhoneChange = (event, values, setFieldValue, index, indexContact) => {
        const { name, value } = event.target;
        const phones = [...values.contacts[indexContact].phones];
        event.target.type === 'checkbox' ?
            phones[index] = { ...phones[index], [name]: event.target.checked }
            :
            phones[index] = { ...phones[index], [name]: value }
        const contact = JSON.parse(JSON.stringify(values.contacts));
        contact[indexContact].phones = phones
        setFieldValue('contacts', contact);
    }
    const removePhone = (values, indexContact, index, setFieldValue) => {
        const contacts = [...values.contacts]
        contacts[indexContact].phones.splice(index, 1);
        setFieldValue('contacts', contacts);
    }

    const closeSnackbar = () => {
        setOpenSnackBar(false);
    };

    const addCustomer = values => {
        return axios.put(apiConfig.url.CUSTOMER_URL + apiConfig.url.CUSTOMERS_ADD, values)
            .then(res => {
                submitAvatar(res.data.id).then(() => {
                    const fullName = `${values.firstName} ${values.lastName}`;
                    history.push(`/customers/${encodeURIComponent(values.companyName || fullName)}/${res.data.id}/information`)
                });
            })
            .catch(err => {
                setIsStatus({ failed: true, msg: 'An error occurred, please try again later.' });
                setOpenSnackBar(true);
            })
            .finally(e => setIsSaving(false));
    }

    useEffect(() => {
        isMounted.current = true;

        axios.get(apiConfig.url.BASE_URL + apiConfig.url.EMPLOYEE_STATES_LIST)
            .then(res => {
                if (isMounted.current) {
                    res.data = res.data.sort((a, b) => a.name.localeCompare(b.name))
                    setStates(res.data);
                }
            });

        axios.get(apiConfig.url.CUSTOMER_URL + apiConfig.url.CUSTOMERS_GET_ENROLLMENTS)
            .then(res => {
                if (isMounted.current) {
                    const contact = [...customer.contacts];
                    contact[0].phones[0].typeId = res.data.phoneTypeOptions ? res.data.phoneTypeOptions[0].id : null;
                    setCustomer({
                        ...customer,
                        customerTypes: res.data.customerTypeOptions,
                        states: res.data.stateOptions,
                        phoneTypes: res.data.phoneTypeOptions,
                        adSources: res.data.adSourceOptions,
                        taxGroups: res.data.taxGroupOptions,
                        contacts: contact
                    })
                }
            });

        axios.get(apiConfig.url.BASE_URL + apiConfig.url.COMPANY_INTEGRATIONS_SOURCE.replace('{companyId}', cId))
            .then(res => {
                if (isMounted.current) {
                    res.data.data.map((item) => {
                        item.touched = {}
                    })
                    res.data.data = res.data.data.sort((a, b) => a.description.localeCompare(b.description))
                    setSources(res.data.data);
                }
            })

        return () => isMounted.current = false;
    }, []);

    return (
        <>
            <Page
                className={classes.pageRoot}
            >

                <Formik
                    initialValues={customer}
                    validationSchema={customerSchema}
                    enableReinitialize
                    onSubmit={values => {
                        const address = { ...values.address, AddressType: 1 }
                        if (!address?.state?.id) return;
                        values = { ...values, address }
                        setIsSaving(true);
                        const { coordinate, isValid, forceSave, verifyFn } = addressInputRef.current;
                        if (isValid || forceSave) {
                            values = {
                                ...values,
                                address: {
                                    ...values.address,
                                    latitude: coordinate?.latitude,
                                    longitude: coordinate?.longitude
                                }
                            };
                            addCustomer(values)
                        } else {
                            verifyFn(true).then(data => data ? addCustomer(data) : setIsSaving(false))
                        }
                    }}
                >
                    {({
                        values,
                        errors,
                        touched,
                        submitCount,
                        isValid,
                        setFieldValue,
                        setFieldTouched,
                        handleSubmit
                    }) => (
                        <form onSubmit={handleSubmit} autoComplete="off">
                            <FormErrorCallback
                                submitCount={submitCount}
                                isValid={isValid && !!values.address?.state?.id}
                                onSubmissionError={(ee) => {

                                    console.log(errors);
                                    setIsStatus({ failed: true, msg: errorMessages.FIELD_CHECK });
                                    setOpenSnackBar(true);
                                }}
                            />
                            <Grid className={classes.stickyBtn} container spacing={4}>
                                <Grid item xs={12}>
                                    <Button type='submit'
                                        className={classes.saveBtn}
                                        disabled={isSaving}
                                        variant="contained">
                                        Save
                                    </Button>
                                    {isSaving && <CircularProgress size={24} />}
                                </Grid>
                            </Grid>

                            <Grid container className={clsx(classes.root, className)} spacing={4}>
                                <Grid container item md={12} lg={3}>
                                    <Card className={classes.cardProfile}>
                                        <CardContent>
                                            <div className={classes.hoverAvatar} onClick={onUploadAvatar}>
                                                <Avatar src={avatar} alt='avatar' className={classes.bigAvatar} />
                                            </div>
                                            <Typography className={classes.profileName}>
                                                {customer.companyName}
                                            </Typography>
                                        </CardContent>
                                        <Divider />
                                        <CardActions>
                                            <Button style={{ width: '100%' }} onClick={avatar ? onRemoveAvatar : onUploadAvatar}>
                                                {avatar ? 'remove avatar' : 'upload avatar'}
                                            </Button>
                                        </CardActions>
                                    </Card>
                                </Grid>
                                <Grid container item md={12} lg={9}>
                                    <Card>
                                        <CardHeader title="Information" />
                                        <Divider />
                                        <CardContent style={{ paddingBottom: 0 }}>
                                            <Grid
                                                container
                                                spacing={3}>
                                                <Grid item md={4} xs={12}>
                                                    <TextField
                                                        fullWidth
                                                        label="Customer Type"
                                                        name="customerTypes"
                                                        onChange={event => {
                                                            setFieldValue('customerType', event.target.value)
                                                        }}
                                                        select
                                                        value={values.customerType}
                                                        SelectProps={{ native: true }}
                                                        variant="outlined">
                                                        <option value='Residential'>Residential</option>
                                                        <option value='Commercial'>Commercial</option>
                                                    </TextField>
                                                </Grid>
                                                <Grid item md={8} xs={12}>
                                                    <TextField
                                                        fullWidth
                                                        error={errors.companyName && touched.companyName}
                                                        helperText={(errors.companyName && touched.companyName) && errors.companyName}
                                                        label={values.customerType === 'Commercial' ? 'Company Name' : 'Customer Name'}
                                                        name="companyName"
                                                        onBlur={(event) => {
                                                            setFieldTouched('companyName')
                                                            if(values.customerType === 'Residential'){
                                                                let contact = values.contacts;
                                                                let fullName =  event.target.value;
                                                                contact[0].firstName = fullName.indexOf(' ')  > 0 ? fullName.substring(0, fullName.indexOf(' ')) : fullName;
                                                                contact[0].lastName = fullName.substring(fullName.indexOf(' ') + 1);
                                                                setFieldValue('contacts', contact)
                                                            }
                                                        }}
                                                        onChange={event => {
                                                            setFieldValue('companyName', event.target.value)
                                                            const address = {
                                                                ...values.address,
                                                                name: event.target.value
                                                            };
                                                            setFieldValue('address', address)
                                                        }}
                                                        value={values.companyName || ''}
                                                        variant="outlined" />
                                                </Grid>
                                            </Grid>
                                            <Grid container spacing={3} style={{ marginBottom: '16px' }}>
                                                <Grid item xs={12}>
                                                    <Typography style={{ fontWeight: 500, paddingLeft: '8px' }}>Billing
                                                        Address</Typography>
                                                </Grid>
                                                <Grid item xs={12}>
                                                    <TextField
                                                        error={
                                                            errors.address &&
                                                            errors.address.name &&
                                                            touched.address &&
                                                            touched.address.name
                                                        }
                                                        helperText={(
                                                            errors.address &&
                                                            errors.address.name &&
                                                            touched.address &&
                                                            touched.address.name
                                                        ) && errors.address.name}
                                                        fullWidth
                                                        label={'Addressee Name'}
                                                        name='name'
                                                        variant='outlined'
                                                        onBlur={() => {
                                                            const address = touched.address ? { ...touched.address } : {};
                                                            address.name = true;
                                                            setFieldTouched('address', address);
                                                        }}
                                                        onChange={event => {
                                                            const address = {
                                                                ...values.address,
                                                                name: event.target.value
                                                            };
                                                            setFieldValue('address', address)
                                                        }}
                                                        value={values.address && values.address.name || ''}
                                                    />
                                                </Grid>
                                                <Grid item xs={12}>
                                                    <AddressInput
                                                        ref={addressInputRef}
                                                        errors={errors}
                                                        values={values}
                                                        touched={touched}
                                                        setFieldValue={setFieldValue}
                                                        setFieldTouched={setFieldTouched}
                                                    />
                                                </Grid>
                                            </Grid>
                                        </CardContent>
                                    </Card>

                                    <Card style={{ marginTop: '16px', width: '100%' }}>
                                        {
                                            values.contacts.map((item, indexContact) => (
                                                <>
                                                    <Divider />
                                                    <CardHeader
                                                        title={item.isPrimary ? 'Primary Contact' : indexContact === 1 ? 'Additional Contacts' : ''}
                                                    />
                                                    <CardContent style={{ position: 'relative' }}>
                                                        <Grid container spacing={3}>
                                                            <Grid item md={4} xs={12}>
                                                                <TextField
                                                                    fullWidth
                                                                    error={
                                                                        errors.contacts &&
                                                                        errors.contacts[indexContact] &&
                                                                        errors.contacts[indexContact].firstName &&
                                                                        touched.contacts &&
                                                                        touched.contacts[indexContact]
                                                                    }
                                                                    helperText={
                                                                        (
                                                                            errors.contacts &&
                                                                            errors.contacts[indexContact] &&
                                                                            errors.contacts[indexContact].firstName &&
                                                                            touched.contacts &&
                                                                            touched.contacts[indexContact]
                                                                        )
                                                                        &&
                                                                        errors.contacts[indexContact].firstName
                                                                    }
                                                                    label="First Name"
                                                                    name="firstName"
                                                                    onBlur={() => setFieldTouched(`contacts.[${indexContact}].firstName`)}
                                                                    onChange={event => {
                                                                        values.contacts[indexContact] = {
                                                                            ...values.contacts[indexContact],
                                                                            firstName: event.target.value
                                                                        }
                                                                        setFieldValue('contacts', values.contacts)
                                                                    }}
                                                                    value={item.firstName || ''}
                                                                    variant="outlined" />
                                                            </Grid>
                                                            <Grid item md={4} xs={12}>
                                                                <TextField
                                                                    fullWidth
                                                                    error={
                                                                        errors.contacts &&
                                                                        errors.contacts[indexContact] &&
                                                                        errors.contacts[indexContact].lastName &&
                                                                        touched.contacts &&
                                                                        touched.contacts[indexContact]
                                                                    }
                                                                    helperText={
                                                                        (
                                                                            errors.contacts &&
                                                                            errors.contacts[indexContact] &&
                                                                            errors.contacts[indexContact].lastName &&
                                                                            touched.contacts &&
                                                                            touched.contacts[indexContact]
                                                                        )
                                                                        &&
                                                                        errors.contacts[indexContact].lastName
                                                                    }
                                                                    label="Last Name"
                                                                    name="lastName"
                                                                    onBlur={() => setFieldTouched(`contacts.[${indexContact}].lastName`)}
                                                                    onChange={event => {
                                                                        values.contacts[indexContact] = {
                                                                            ...values.contacts[indexContact],
                                                                            lastName: event.target.value
                                                                        }
                                                                        setFieldValue('contacts', values.contacts)
                                                                    }}
                                                                    value={item.lastName || ''}
                                                                    variant="outlined" />
                                                            </Grid>
                                                            <Grid item md={4} xs={12}>
                                                                <TextField
                                                                    fullWidth
                                                                    error={
                                                                        errors.contacts &&
                                                                        errors.contacts[indexContact] &&
                                                                        errors.contacts[indexContact].email &&
                                                                        touched.contacts &&
                                                                        touched.contacts[indexContact]
                                                                    }
                                                                    helperText={
                                                                        (
                                                                            errors.contacts &&
                                                                            errors.contacts[indexContact] &&
                                                                            errors.contacts[indexContact].email &&
                                                                            touched.contacts &&
                                                                            touched.contacts[indexContact]
                                                                        )
                                                                        &&
                                                                        errors.contacts[indexContact].email
                                                                    }
                                                                    label="Email"
                                                                    name="email"
                                                                    onBlur={() => setFieldTouched(`contacts.[${indexContact}].email`)}
                                                                    onChange={event => {
                                                                        values.contacts[indexContact] = {
                                                                            ...values.contacts[indexContact],
                                                                            email: event.target.value
                                                                        }
                                                                        setFieldValue('contacts', values.contacts)
                                                                    }}
                                                                    value={item.email || ''}
                                                                    variant="outlined" />
                                                            </Grid>
                                                            {item.phones.map((phone, index) => (
                                                                <React.Fragment>
                                                                    <Grid item xs={ipPlus ? 3 : 1}>
                                                                        <FormControlLabel
                                                                            className={classes.checkbox}
                                                                            name='isSendText'
                                                                            labelPlacement="top"
                                                                            control={<GreenCheckbox />}
                                                                            label="Send Text"
                                                                            checked={phone.isSendText}
                                                                            onChange={e => handlePhoneChange(e, values, setFieldValue, index, indexContact,)}
                                                                        />
                                                                    </Grid>
                                                                    <Grid item xs={ipPlus ? 5 : 3}>
                                                                        {customer.phoneTypes &&
                                                                            <TextField
                                                                                fullWidth
                                                                                label="Phone Type"
                                                                                name="typeId"
                                                                                select
                                                                                SelectProps={{ native: true }}
                                                                                variant="outlined"
                                                                                value={phone.typeId || ''}
                                                                                onChange={e => handlePhoneChange(e, values, setFieldValue, index, indexContact)}>
                                                                                {customer.phoneTypes.map(pt => (
                                                                                    <option key={pt.id} value={pt.id}>
                                                                                        {pt.type}
                                                                                    </option>
                                                                                ))}
                                                                            </TextField>
                                                                        }
                                                                    </Grid>
                                                                    <Grid item xs={ipPlus ? 8 : 4}>
                                                                        <NumberFormat
                                                                            customInput={TextField}
                                                                            format="(###) ###-####x######"
                                                                            fullWidth
                                                                            error={
                                                                                errors.contacts &&
                                                                                errors.contacts[indexContact] &&
                                                                                errors.contacts[indexContact].phones &&
                                                                                errors.contacts[indexContact].phones[index] &&
                                                                                errors.contacts[indexContact].phones[index].phoneNumber &&
                                                                                touched.contacts &&
                                                                                touched.contacts[indexContact] &&
                                                                                touched.contacts[indexContact].phones &&
                                                                                touched.contacts[indexContact].phones[index] &&
                                                                                touched.contacts[indexContact].phones[index].phoneNumber
                                                                            }
                                                                            helperText={(
                                                                                errors.contacts &&
                                                                                errors.contacts[indexContact] &&
                                                                                errors.contacts[indexContact].phones &&
                                                                                errors.contacts[indexContact].phones[index] &&
                                                                                errors.contacts[indexContact].phones[index].phoneNumber &&
                                                                                touched.contacts &&
                                                                                touched.contacts[indexContact] &&
                                                                                touched.contacts[indexContact].phones &&
                                                                                touched.contacts[indexContact].phones[index] &&
                                                                                touched.contacts[indexContact].phones[index].phoneNumber
                                                                            ) &&
                                                                                errors.contacts[indexContact].phones[index].phoneNumber
                                                                            }
                                                                            label="Phone Number"
                                                                            name="phoneNumber"
                                                                            onBlur={() => setFieldTouched(`contacts.[${indexContact}].phones[${index}].phoneNumber`)}
                                                                            // onBlur={() => {
                                                                            //     const phones = touched.phones ? [...touched.phones] : [];
                                                                            //     phones[index] = {phoneNumber: true};
                                                                            //     setFieldTouched('phones', phones);
                                                                            // }}
                                                                            variant="outlined"
                                                                            value={phone.phoneNumber || ''}
                                                                            onChange={e => handlePhoneChange(e, values, setFieldValue, index, indexContact)}
                                                                        />
                                                                    </Grid>
                                                                    <Grid item xs={4}>
                                                                        <CardActions className={classes.conButton}>


                                                                            <MuiThemeProvider theme={customThemeTooltip}>
                                                                                {
                                                                                    index === item.phones.length - 1 &&
                                                                                    <Tooltip arrow title={'Add Phone'}>
                                                                                        <IconButton
                                                                                            disabled={!customer.phoneTypes}
                                                                                            className={classes.addIcon}
                                                                                            onClick={() => addPhone(values, indexContact, setFieldValue)}
                                                                                        >
                                                                                            <AddBox />
                                                                                        </IconButton>
                                                                                    </Tooltip>
                                                                                }
                                                                                {
                                                                                    index > 0 &&
                                                                                    <Tooltip arrow title={'Remove Phone'}>
                                                                                        <DeleteIcon
                                                                                            className={classes.DeleteIcon}
                                                                                            onClick={() => removePhone(values, indexContact, index, setFieldValue)}
                                                                                        >
                                                                                        </DeleteIcon>
                                                                                    </Tooltip>
                                                                                }
                                                                            </MuiThemeProvider>


                                                                        </CardActions>
                                                                    </Grid>
                                                                </React.Fragment>
                                                            ))}
                                                        </Grid>
                                                    </CardContent>
                                                </>
                                            ))
                                        }
                                    </Card>


                                    <Card style={{ marginTop: '16px', width: '100%' }}>
                                        <CardHeader title='Details' />
                                        <Divider />
                                        <CardContent>
                                            <Grid container spacing={3}>
                                                <Grid item xs={12}>
                                                    <TextField
                                                        fullWidth
                                                        label="How did you hear about us ?"
                                                        name="adSource"
                                                        select
                                                        SelectProps={{ native: true }}
                                                        variant="outlined"
                                                        value={values.adSourceId || ''}
                                                        onChange={event => setFieldValue('adSourceId', event.target.value)}>
                                                            <option value=''></option>
                                                        {sources && sources.map(source => (
                                                            <option key={source.id} value={source.id}>
                                                                {source.description}
                                                            </option>
                                                        ))}
                                                    </TextField>

                                                </Grid>

                                                <Grid item xs={12}>
                                                    <TextField
                                                        fullWidth
                                                        label="Tax Group"
                                                        name="taxGroup"
                                                        onChange={event => {
                                                            const address = {
                                                                ...values.address,
                                                                taxGroupId: event.target.value
                                                            }
                                                            setFieldValue('address', address);
                                                        }}
                                                        select
                                                        SelectProps={{ native: true }}
                                                        variant="outlined"
                                                        value={values.address.taxGroupId || ''}>
                                                            <option value=''></option>
                                                        {customer.taxGroups && customer.taxGroups.map(tax => (
                                                            <option
                                                                key={tax.id}
                                                                value={tax.id}>
                                                                {tax.name}
                                                            </option>
                                                        ))}
                                                    </TextField>

                                                </Grid>

                                                {/* <Grid item md={12} xs={12}>
                                                    <TextField
                                                        disabled
                                                        style={{ width: mobile ? "100%" : "calc(100% - 172px)" }}
                                                        fullWidth
                                                        label="QuickBooks Name"
                                                        name="quickbookName"
                                                        // onChange={event => setFieldValue('quickbookName', event.target.value)}
                                                        // value={values.quickbookName || ''}
                                                        variant="outlined" />
                                                    <Button
                                                        style={{
                                                            width: mobile ? "100%" : "160px",
                                                            marginLeft: mobile ? '0' : "12px",
                                                            marginTop: '8px',
                                                            whiteSpace: 'nowrap'
                                                        }}
                                                        className={classes.saveBtn}
                                                        variant="contained">
                                                        Generate New Name
                                                    </Button>
                                                </Grid> */}

                                                <Grid item xs={12}>
                                                    <FormControlLabel
                                                        className={classes.postQuickBooks}
                                                        control={
                                                            <GreenCheckbox
                                                                // checked={values.postToQuickbook}
                                                                // onChange={e => setFieldValue('postToQuickbook', e.target.checked)}
                                                                value="post"
                                                            />
                                                        }
                                                        label='Post to QuickBooks'
                                                    />
                                                </Grid>
                                            </Grid>
                                        </CardContent>
                                    </Card>
                                </Grid>
                            </Grid>
                        </form>
                    )}
                </Formik>

                <AvatarEditor
                    open={openAvatarModal}
                    onClose={() => setOpenAvatarModal(false)}
                    onGetAvatar={onGetAvatar}
                    type="new-avatar"
                />

                <ConfirmModal
                    openConfirm={openConfirm}
                    closeConfirm={() => setOpenConfirm(false)}
                    onConfirm={onRemoveAvatar}
                />

                <ModalNotice
                    openModal={customer.showNoteOnOpen}
                    updateCloseModal={updateCloseModal}
                    note={customer.routeNote}
                >

                </ModalNotice>

                <div className={classes.snackBar}>
                    <Snackbar anchorOrigin={{
                        vertical: 'top',
                        horizontal: 'center'
                    }} open={openSnackbar}
                        autoHideDuration={3000}
                        onClose={closeSnackbar}
                    >
                        {isStatus.failed
                            ?
                            <Alert
                                elevation={6} variant="filled" severity="error">
                                <Typography
                                    color="inherit"
                                    variant="h6">
                                    {isStatus.msg}
                                </Typography>
                            </Alert>
                            :
                            <Alert
                                elevation={6} variant="filled" severity="success">
                                <Typography
                                    color="inherit"
                                    variant="h6">
                                    {isStatus.msg}
                                </Typography>
                            </Alert>
                        }
                    </Snackbar>
                </div>
            </Page>
        </>
    );
};

Information.propTypes = {
    className: PropTypes.string
}

export default Information;
