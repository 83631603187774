import React, {useEffect, useState} from "react";
import PropTypes from "prop-types";
import { Link, Link as RouterLink } from "react-router-dom";
import clsx from "clsx";
import {makeStyles} from "@material-ui/styles";
import {
  AppBar,
  Badge,
  CircularProgress, ClickAwayListener,
  FormControl,
  Hidden,
  IconButton, ListItem, ListItemIcon, ListItemText,
  Menu,
  MenuItem,
  Select,
  Toolbar,
  Tooltip,
  Typography
} from "@material-ui/core";
import MenuIcon from "@material-ui/icons/Menu";
import axios from "utils/axios";
import apiConfig, {baseUrl} from "apiConfig";
import localStorage from "utils/localStorage";
import jwt_decode from "jwt-decode";
import Snackbar from "@material-ui/core/Snackbar";
import Alert from "@material-ui/lab/Alert";
import {PERMISSIONS} from "common/permissions";
import Fab from "@material-ui/core/Fab";
import AddIcon from "@material-ui/icons/Add";
import CustomersModal from "./CustomersModal";
import AddressModal from "./AddressModal";
import List from '@material-ui/core/List';
import { checkFeature, FeatureFlags } from "FeatureService";
import { NotificationsNone } from "@material-ui/icons";
import { useSelector } from "react-redux";

const useStyles = makeStyles(theme => ({
  root: {
    boxShadow: "none"
  },
  flexGrow: {
    flexGrow: 1
  },
  signOutButton: {
    marginLeft: theme.spacing(1)
  },
  logoText: {
    color: "#fff"
  },
  select: {
    backgroundColor: "#ffffff",
    fontSize: theme.spacing(2),
    "& div": {
      paddingTop: "10px",
      paddingBottom: "10px",
      width: "auto",
      minWith: "90px"
    }
  },
  menuProps: {
    marginTop: "34px",
    marginLeft: "1px",
    minWidth: "134px !important"
  },
  companyName: {
    color: "white",
    fontSize: "17px",
    marginRight:'24px',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    display: '-webkit-box',
    WebkitLineClamp: 2,
    WebkitBoxOrient: 'vertical',
    [theme.breakpoints.down('xs')]: {
      marginRight: '0px', 
    }
  },
  selectCompany: {
    '& .MuiInputBase-root': {
      width: '100%'
    }
  },
  fab: {
    // marginRight: '10px',
    width: '38px',
    borderRadius: '20px',
    height: '30px',
    backgroundColor: '#43a047',
    '& p': {
      display: 'none'
    },
    // '&:hover': {
    //   width: '128px',
    //   backgroundColor: '#1b5e20',
    //   '& p': {
    //     display: 'block'
    //   }
    // },
  },
  menuItem: {
    borderRadius: '4px',
    color: 'white',
    borderBottom: '1px solid white',
    paddingBottom: '4px',
    backgroundColor: '#43a047',
    '&:hover': {
      backgroundColor: '#1b5e20',
    },
    '& a': {
      color: 'white',
      '& span': {
        display: 'flex',
        alignItem: 'center'
      }
    }
  },
  '.MuiTooltip-tooltipPlacementBottom': {
    paddingTop: '0px'
  },
  toolbar: {
    [theme.breakpoints.down('xs')]: {
      paddingRight: 0
    }
  },
  quickAction: {
    display: 'flex', 
    justifyContent: 'flex-end',
    marginLeft: 8,
    marginRight: 8,
    [theme.breakpoints.up('sm')]: {
      width: 128,
      marginRight: 20,
    }
  },
  alerts: {
    position: 'absolute',
    top: 64,
    width: '100%',
    [theme.breakpoints.down('xs')]: {
      top: 54
    }
  }
}));

const Topbar = props => {
  const { className, onSidebarOpen, ...rest } = props;
  const user = localStorage.getFullInfo();

  const cId = localStorage.getCompanyId();
  // const { companyId } = useSelector(state => state.AppReducer);
  const { reloadTaskSideBar } = useSelector((state) => state.AppReducer);
  const classes = useStyles();
  const [listCompany, setListCompany] = useState(localStorage.getListCompany());
  const [company, setCompany] = useState(cId ? cId : user?.cid);
  const [tooltipIsOpen, setTooltipIsOpen] = useState(false);
  const [disabled, setDisabled] = useState(false);
  const [loading, setLoading] = useState(false);
  const [isStatus, setIsStatus] = useState({ failed: false, msg: "" });
  const [openSnackbar, setOpenSnackBar] = useState(false);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [openModalCustomers, setOpenModalCustomers] = React.useState(false);
  const [openModalAddress, setOpenModalAddress] = React.useState(false);
  const [selectedCustomer, setSelectedCustomer] = React.useState();
  const [open, setOpen] = React.useState(false);
  const [countsTask, setCountsTask] = useState(null);
  const [alerts, setAlerts] = useState(localStorage.getAlerts());

  const [isEnableTasks, setIsEnableTasks] = useState(false);
  checkFeature(FeatureFlags.TASKS).then(setIsEnableTasks);

  const roles = localStorage.getUserRoles();
  const hasTechnician = roles.includes(PERMISSIONS.FIELD_TECHNICIAN) && roles.length === 1;
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const useStylesBootstrap = makeStyles((theme) => ({
    arrow: {
      color: '#43a047',
    },
    tooltip: {
      backgroundColor: 'transparent',
      color: '#ffffff',
    },
  }));

  function ListItemLink(props) {
    return <ListItem button component="a" {...props} />;
  }
  function BootstrapTooltip(props) {
    const classes = useStylesBootstrap();

    return <Tooltip arrow classes={classes} {...props} />;
  }
  const handleTooltipClose = () => {
    setOpen(false);
  };

  const handleTooltipOpen = () => {
      setOpen(true);
  };


  const updateCompany = id => {
    setLoading(true);
    axios
      .post(
        apiConfig.url.AUTH_URL +
          apiConfig.url.COMPANY_CHANGE.replace("{companyId}", id)
      )
      .then(response => {
        const token = {
          token: response.data.token,
          expiresOn: response.data.expiresOn
        };
        const usr = {
          displayName: response.data.displayName,
          avatar: response.data.avatar
        };
        const info = jwt_decode(token.token);
        localStorage.set("companyId", info.cid);
        localStorage.set("sw-role", JSON.stringify(info.role));
        localStorage.set("sw-token", JSON.stringify(token));
        localStorage.set("sw-usr", JSON.stringify(usr));
        setIsStatus({ failed: false, msg: "Company has been changed." });
        setOpenSnackBar(true);

        const defaultPage = info.role.includes(PERMISSIONS.FIELD_TECHNICIAN)
          ? "employees/today-route"
          : "dashboard";
        window.location.href = baseUrl() + defaultPage;
      })
      .catch(e => {
        setCompany(cId);
        setIsStatus({
          failed: true,
          msg: "Change failed, please try again later"
        });
        setOpenSnackBar(true);
      })
      .finally(() => setLoading(false));
  };

  const fetchCountsTask = () => {
    axios
      .get(apiConfig.url.BASE_URL + apiConfig.url.EMPLOYEE_COUNT_TASK, { params: { employeeId: user.nameid } })
      .then((res) => {
        setCountsTask(res.data);
      })
      .catch((err) => console.log(err));
  };


  const clearAlert = index => {
    setAlerts(value => {
      value.splice(index, 1);
      localStorage.set("sw-alerts", JSON.stringify(value));
      return [...value];
    });
  }

  // useEffect(() => {
  //     debugger
  //     setCompany(companyId)
  // }, [companyId])

  useEffect(() => {
    fetchCountsTask();
  }, [reloadTaskSideBar]);

  useEffect(() => {
    if (disabled) {
      setTimeout(() => {
        setTooltipIsOpen(false);
      }, 0);
    }
  }, [disabled]);
  useEffect(() => {
    if (!listCompany) {
      axios
        .get(
          apiConfig.url.BASE_URL +
            apiConfig.url.COMPANY_MULTI.replace("{userId}", user.nameid)
        )
        .then(res => {
          setListCompany(res.data);
          localStorage.set("listCompany", JSON.stringify(res.data));
        });
    }
  }, []);
  return (
    <AppBar {...rest} className={clsx(classes.root, className)}>
      <Toolbar className={classes.toolbar}>
        <RouterLink to="/">
          <Tooltip
            arrow
            title={
              <>
                <Typography color="inherit">Dashboard</Typography>
              </>
            }
          >
            <Typography variant="h4" className={classes.logoText}>
              SQUEEGEE SQUAD
            </Typography>
          </Tooltip>
        </RouterLink>
        <div className={classes.flexGrow} />
        <Link to="/employees/tasks">
          <Tooltip title={countsTask ? `Tasks Due Today (${countsTask})` : 'Tasks'}>
            <Fab style={{ backgroundColor: '#43a047', marginRight: 12 }} className={classes.fab} color="secondary">
              <Badge badgeContent={countsTask} color="error">
                <NotificationsNone />
              </Badge>
            </Fab>
          </Tooltip>
        </Link>
        
        {
          !hasTechnician &&
          <ClickAwayListener onClickAway={handleTooltipClose} >
            <div style={{paddingTop: 0}}>
              <BootstrapTooltip
                  style={{paddingTop: 0}}
                  PopperProps={{
                    disablePortal: true,
                    style: {paddingTop: 0}
                  }}
                  onClose={handleTooltipClose}
                  open={open}
                  disableFocusListener
                  disableHoverListener
                  disableTouchListener
                  title={
                    <React.Fragment>
                      <List onMouseEnter={handleTooltipOpen} onMouseLeave={handleTooltipClose} style={{pointerEvents: 'all', paddingTop: 0 }} component="nav" aria-label="main mailbox folders">
                        <ListItem onClick={() => {
                          handleTooltipClose();
                          setOpenModalCustomers(true);
                        }} style={{
                          cursor: 'pointer',
                          color: '#ffffff',
                          backgroundColor:'#43a047',
                          marginBottom: '4px',
                          borderRadius: '4px',
                          display: 'flex',
                          justifyContent: 'center'
                        }}>
                          <ListItemIcon style={{color: '#ffffff', minWidth: 0}}>
                            <AddIcon />
                          </ListItemIcon>
                          <ListItemText style={{flex: 'initial'}} primaryTypographyProps={{style: {color: '#ffffff'}}} primary="JOB" />
                        </ListItem>
                        <ListItem onClick={handleTooltipClose} style={{cursor: 'pointer',color: '#ffffff', backgroundColor:'#43a047', marginBottom: '4px', borderRadius: '4px'}} button>
                          <Link to="/customers/add-customer" style={{display: 'flex',
                            justifyContent: 'center'
                          }}>
                            <ListItemIcon style={{color: '#ffffff',  minWidth: 0}}>
                              <AddIcon />
                            </ListItemIcon>
                            <ListItemText style={{flex: 'initial'}} primaryTypographyProps={{style: {color: '#ffffff', display: 'inline'}}}  primary="CUSTOMER" />
                          </Link>
                        </ListItem>

                        {
                          isEnableTasks && (
                            <ListItem onClick={handleTooltipClose} style={{cursor: 'pointer',color: '#ffffff', backgroundColor:'#43a047', marginBottom: '4px', borderRadius: '4px'}} button>
                              <Link to="/employees/tasks?modal=true" style={{display: 'flex',
                                justifyContent: 'center',
                                width: '100%'
                              }}>
                                <ListItemIcon style={{color: '#ffffff',  minWidth: 0}}>
                                  <AddIcon />
                                </ListItemIcon>
                                <ListItemText style={{flex: 'initial'}} primaryTypographyProps={{style: {color: '#ffffff', display: 'inline'}}}  primary="TASK" />
                              </Link>
                            </ListItem>
                          )
                        }

                      </List>
                    </React.Fragment>
                  }>
                <div className={classes.quickAction}>
                <Fab
                    style={open ? {width: '128px', backgroundColor: '#43a047'}: {}}
                    onMouseEnter={() => {
                  handleTooltipOpen();
                }} onClick={handleTooltipOpen} className={classes.fab}
                     color="secondary">
                  <AddIcon/>
                  {open &&
                  <p style={open ? {display: 'block', alignItem: 'center',} : {
                    display: 'initial',
                    alignItem: 'initial'
                    }}>ACTIONS</p>
                  }
                </Fab>
                </div>
              </BootstrapTooltip>

            </div>
          </ClickAwayListener>
        }

        {listCompany && listCompany.length === 1 && (
          <Typography variant="h4" className={classes.companyName}>
            {listCompany && listCompany.length > 0 && listCompany[0].name}
          </Typography>
        )}
        {listCompany && listCompany.length > 1 && (
          <FormControl
            style={{ flexDirection: "initial" }}
            className={`${classes.formControl} ${classes.selectCompany}`}
          >
            <Tooltip
              disableHoverListener={disabled}
              disableTouchListener={true}
              disableFocusListener={true}
              open={tooltipIsOpen}
              onOpen={() => setTooltipIsOpen(true)}
              onClose={() => setTooltipIsOpen(false)}
              arrow
              title={
                <>
                  <Typography color="inherit">Change Location</Typography>
                </>
              }
            >
              <Select
                onOpen={() => {
                  setDisabled(true);
                }}
                onClose={() => {
                  setDisabled(true);
                  setTimeout(() => {
                    setDisabled(false);
                  }, 0);
                  setTooltipIsOpen(false);
                  // setTooltipIsOpen(undefined)
                }}
                defaultValue={company}
                value={company}
                variant="outlined"
                id="grouped-select"
                className={classes.select}
                MenuProps={{ classes: { paper: classes.menuProps } }}
                onChange={e => {
                  setCompany(e.target.value);
                  updateCompany(e.target.value);
                }}
              >
                {listCompany &&
                  listCompany.map(item => (
                    <MenuItem key={item.id} value={item.id}>
                      {item.name}
                    </MenuItem>
                  ))}
              </Select>
            </Tooltip>
            {loading && (
              <CircularProgress
                style={{
                  color: "white",
                  display: "inline-flex",
                  marginLeft: "10px",
                  marginTop: "6px"
                }}
                className={classes.circularProgress}
                size={24}
              />
            )}
          </FormControl>
        )}
        {/*<Hidden mdDown>*/}
        {/*  /!*<IconButton color="inherit">*!/*/}
        {/*  /!*  <Badge*!/*/}
        {/*  /!*    badgeContent={notifications.length}*!/*/}
        {/*  /!*    color="primary"*!/*/}
        {/*  /!*    variant="dot"*!/*/}
        {/*  /!*  >*!/*/}
        {/*  /!*    <NotificationsIcon />*!/*/}
        {/*  /!*  </Badge>*!/*/}
        {/*  /!*</IconButton>*!/*/}
        {/*  <Tooltip*/}
        {/*    arrow*/}
        {/*    title={*/}
        {/*      <>*/}
        {/*        <Typography color="inherit">Logout</Typography>*/}
        {/*      </>*/}
        {/*    }*/}
        {/*  >*/}
        {/*    <IconButton*/}
        {/*      className={classes.signOutButton}*/}
        {/*      color="inherit"*/}
        {/*      onClick={logout}*/}
        {/*    >*/}
        {/*      <InputIcon />*/}
        {/*    </IconButton>*/}
        {/*  </Tooltip>*/}
        {/*</Hidden>*/}
        {onSidebarOpen && <Hidden lgUp>
          <IconButton color="inherit" onClick={onSidebarOpen}>
            <MenuIcon />
          </IconButton>
        </Hidden>}
      </Toolbar>

      {!hasTechnician && (
        <>
          <CustomersModal
            setSelectedCustomer={setSelectedCustomer}
            openModalAddress={setOpenModalAddress}
            open={openModalCustomers}
            onClose={() => setOpenModalCustomers(false)}
          >
          </CustomersModal>

          <AddressModal
              selectedCustomer={selectedCustomer}
              open={openModalAddress}
              onClose={() => {
                setOpenModalAddress(false);
                setOpenModalCustomers(false)
              }}
          >
          </AddressModal>
        </>
      )}

      <Snackbar
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
        open={openSnackbar}
        autoHideDuration={3000}
        onClose={() => setOpenSnackBar(false)}
      >
        <Alert
          elevation={6}
          variant="filled"
          severity={isStatus.failed ? "error" : "success"}
        >
          <Typography color="inherit" variant="h6">
            {isStatus.msg}
          </Typography>
        </Alert>
      </Snackbar>
      <div className={classes.alerts}>
        {alerts.map((alert, index) =>
          <Alert elevation={6} variant="filled" severity={alert.severity} onClose={() => clearAlert(index)}>
            <Typography color="inherit" variant="h6">
              {alert.msg}
            </Typography>
          </Alert>
        )}
      </div>
    </AppBar>
  );
};

Topbar.propTypes = {
  className: PropTypes.string,
  onSidebarOpen: PropTypes.func
};

export default Topbar;
