import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { DatePicker } from '@material-ui/pickers'
import { KeyboardDatePicker, InputTime } from 'components'
import {
    Card, CardHeader, CardContent, Divider,
    Grid, colors, useMediaQuery, Collapse,

} from '@material-ui/core'
import { makeStyles } from "@material-ui/styles";
import axios from "utils/axios";
import apiConfig from "apiConfig";

import moment from "moment";
import { KeyboardArrowDown, KeyboardArrowUp } from '@material-ui/icons'

const useStyles = makeStyles(theme => ({
    grid: {
        [theme.breakpoints.up(1600)]: {
            '& > .MuiGrid-item': {
                flexGrow: 0,
                maxWidth: 'calc(100% / 3)',
                flexBasis: 'calc(100% / 3)'
            }
        }
    },
}))

const Status = props => {
    const { status, onChange, disabled } = props;
    const classes = useStyles();

    const [stages, setStages] = useState([]);

    const {bidAppointment, bidCompleted, jobSplits } = status;

    const jobSplit = jobSplits && jobSplits.find(x => x.isPrimaryJob);

    const sm = useMediaQuery(theme => theme.breakpoints.down('sm'));
    const [expand, setExpand] = useState(true);
    const [openPicker, setOpenPicker] = useState(false);
    const [typeDate, setTypeDate] = useState();

    const onShowDatePicker = type => {
        setTypeDate(type);
        setOpenPicker(true)
    }
    const onHandleChangeDate = date => {
        onChange(new Date(date).toISOString(), typeDate)
    }

    return (
        <>
            <Card style={{ height: '100%' }}>
                <CardHeader title='Status'
                    action={sm && (expand ? <KeyboardArrowUp /> : <KeyboardArrowDown />)}
                    onClick={() => sm && setExpand(!expand)} />
                <Divider />
                <Collapse in={!sm || expand} timeout='auto' unmountOnExit>
                    <CardContent>
                        <Grid container spacing={2} className={classes.grid}>
                            {/* <Grid item xs={12}>
                                {
                                    stages && stages.length > 0 &&
                                    <TextField
                                        disabled={disabled}
                                        fullWidth
                                        label='Stage'
                                        name='jobStage'
                                        variant='outlined'
                                        select
                                        SelectProps={{ native: true }}
                                        value={jobStage || ''}
                                        onChange={e => onChange(e.target.value, 'jobStage')}
                                    >
                                        <option key="" value=""></option>
                                        {stages.map(stage => (
                                            <option disabled={stage.order == 4 || stage.order === 5}
                                                key={stage.id} value={stage.id}>
                                                {stage.description}
                                            </option>
                                        ))}

                                    </TextField>
                                }
                            </Grid>

                            <Grid item xs={12} md={6}>
                                <Grid container spacing={2} alignItems='center'>
                                    <Grid item style={{ flex: 1 }}>
                                        <KeyboardDatePicker
                                            disabled
                                            label='Scheduled Date'
                                            name='scheduleDate'
                                            value={jobSplit && jobSplit.scheduleDate && moment.utc(jobSplit.scheduleDate+'.000Z').format("MM/DD/YYYY") || null}
                                        />
                                    </Grid>
                                    <Grid item xs={'auto'}>
                                        <MuiThemeProvider theme={customThemeTooltip}>
                                            <Tooltip arrow title={'View On Schedules'}>
                                                <a
                                                    disabled={!jobSplit?.scheduleDate}
                                                    className={classes.scheduleLink}
                                                    href={jobSplit?.scheduleDate && `/schedules/routes/${moment.utc(jobSplit.scheduleDate+'.000Z').format('YYYY-MM-DD')}`}>
                                                    <OpenInNewIcon style={{ fontSize: 20 }} />
                                                </a>
                                            </Tooltip>
                                        </MuiThemeProvider>
                                    </Grid>
                                </Grid>
                            </Grid> */}
                            <Grid item xs={12} md={6}>
                                <InputTime
                                    disabled
                                    label='Scheduled Time'
                                    value={jobSplit && jobSplit.scheduleDate && moment.utc(jobSplit.scheduleDate+'.000Z') || ''}
                                    inputProps={
                                        { readOnly: true, }
                                    }
                                />
                            </Grid>

                            <Grid item xs={12} md={6}>
                                <KeyboardDatePicker
                                    disabled={disabled}
                                    label='Bid Appointment Date'
                                    name='bidAppointmentDate'
                                    value={bidAppointment || null}
                                    onShowDatePicker={() => onShowDatePicker('bidAppointmentDate')}
                                    onChange={date => onChange(date, 'bidAppointmentDate')}
                                />
                            </Grid>
                            <Grid item xs={12} md={6}>
                                <InputTime
                                    disabled={disabled || !bidAppointment}
                                    label='Bid Appointment Time'
                                    value={bidAppointment || ''}
                                    onChange={time => onChange(time, 'bidTime')}
                                />
                            </Grid>
                            <Grid item xs={12} md={6}>
                                <KeyboardDatePicker
                                    disabled={disabled}
                                    label='Bid Completed On'
                                    name='bidCompleted'
                                    value={bidCompleted || null}
                                    onShowDatePicker={() => onShowDatePicker('bidCompleted')}
                                    onChange={date => onChange(date, 'bidCompleted')}
                                />
                            </Grid>
                            <Grid item xs={12} md={6}>
                                <KeyboardDatePicker
                                    disabled
                                    label='Completed Date'
                                    name='completedDate'
                                    value={jobSplit && jobSplit.completedDate && moment.utc(jobSplit.completedDate+'.000Z') || ''}
                                />
                            </Grid>
                            <Grid item xs={12} md={4}
                                style={{ justifyContent: 'center', display: 'flex', alignItems: 'center' }}>
                                {/*<Button*/}
                                {/*    disabled={!status.id}*/}
                                {/*    className={classes.button}*/}
                                {/*    onClick={() => setOpenModal(true)}>*/}
                                {/*    {fuData ? 'View Follow Up' : 'Schedule Follow Up'}*/}
                                {/*</Button>*/}
                            </Grid>
                        </Grid>
                    </CardContent>
                </Collapse>
            </Card>

            <DatePicker
                style={{ display: 'none' }}
                open={openPicker}
                onClose={() => setOpenPicker(false)}
                format="MM/DD/YYYY"
                onChange={onHandleChangeDate}
            />

        </>
    )
}

Status.propTypes = {
    status: PropTypes.object.isRequired,
    update: PropTypes.func,
    onChangeDate: PropTypes.func
}

Status.defaultProps = {
    status: {},
    update: () => {
    },
    onChangeDate: () => {
    }
}

export default Status;
