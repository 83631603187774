import React, { useState } from 'react'
import PropTypes from 'prop-types'
import {
    Card, CardHeader, CardContent, Divider,
    Modal, Button, CardActions, Typography, colors
} from '@material-ui/core'
import { makeStyles } from '@material-ui/styles'

const useStyles = makeStyles(theme => ({
    styleModal: {
        maxWidth: '100%',
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        outline: 'none',
        boxShadow: theme.shadows[20],
        width: 500,
    },
    cardActions: {
        justifyContent: 'flex-end',
    },
    addresses: {
        border: '1px solid #c0c0c0',
        borderRadius: 5,
        height: 237,
        overflowY: 'auto',
        '&::-webkit-scrollbar': {
            width: 10
        },
        '&::-webkit-scrollbar-track': {
            backgroundColor: '#dadada',
            borderRadius: 99
        },
        '&::-webkit-scrollbar-thumb': {
            backgroundColor: '#858585',
            borderRadius: 99
        },
    },
    address: {
        display: 'flex',
        flexDirection: 'column',
        padding: 8,
        borderBottom: '1px solid #c0c0c0',
        '&:hover': {
            cursor: 'pointer',
            color: theme.palette.white,
            backgroundColor: colors.green[600],
        }
    },
    selected: {
        color: theme.palette.white,
        backgroundColor: colors.green[700],
    },
    btn: {
        color: theme.palette.white,
        backgroundColor: colors.green[600],
        '&:hover': {
            backgroundColor: colors.green[900]
        }
    }
}));

const AddressSuggestedModal = ({ open, onClose, addresses, states, handleSelect }) => {
    const classes = useStyles();

    const [addressSelected, setAddressSelected] = useState({ index: null });

    const handleClose = () => {
        setAddressSelected({ index: null });
        onClose();
    }

    return (
        <Modal open={open} onClose={handleClose}>
            <Card className={classes.styleModal}>
                <CardHeader title='Address Suggestions' />
                <Divider />
                <CardContent>
                    <div className={classes.addresses}>
                        {Array.isArray(addresses) && addresses.map((addr, i) => {
                            const state = states.find(s => s.id === addr.stateId) || '';
                            return (
                                <Typography key={`address-suggested-${i}`}
                                    className={`${classes.address} ${addressSelected.index === i ? classes.selected : ''}`}
                                    onClick={() => setAddressSelected({ address: addr, index: i })}>
                                    <span>{addr.address1 || ''}</span>
                                    <span>
                                        {addr.city || ''}
                                        {state ? `, ${state.name}` : ''}
                                        {addr.zipCode ? ` ${addr.zipCode}` : ''}
                                    </span>
                                </Typography>
                            )
                        })}
                    </div>
                </CardContent>
                <Divider />
                <CardActions className={classes.cardActions}>
                    <Button variant='contained' onClick={handleClose}>Cancel</Button>
                    <Button variant='contained'
                        disabled={!addressSelected.address}
                        className={classes.btn}
                        onClick={() => {
                            handleSelect(addressSelected.address);
                            handleClose();
                        }}>
                        Select
                    </Button>
                </CardActions>
            </Card>
        </Modal>
    )
}

AddressSuggestedModal.propTypes = {
    open: PropTypes.bool,
    onClose: PropTypes.func.isRequired,
    addresses: PropTypes.arrayOf(Object),
    states: PropTypes.arrayOf(Object),
    handleSelect: PropTypes.func.isRequired
}

AddressSuggestedModal.defaultProps = {
    open: false,
    onClose: () => { },
    handleSelect: () => { }
}

export default AddressSuggestedModal;
