import React, {useEffect, useState} from 'react'
import moment from 'moment'
import { makeStyles } from '@material-ui/styles'
import { Document, Page, Text, View, StyleSheet, PDFDownloadLink, Font, Image } from '@react-pdf/renderer';
import axios from "../../../../../utils/axios";
import apiConfig from "../../../../../apiConfig";
import { formatPhoneNumber } from 'utils/formatNumber';
import {PERMISSIONS} from "common/permissions";
import localStorage from 'utils/localStorage'
const useStyles = makeStyles(theme => ({
    
}));

const styles = StyleSheet.create({
    invoiceSection: {
        fontFamily: 'Roboto', fontWeight: 'bold', fontSize: 20
    },
    sectionOne: {
        flexDirection: "row",
        justifyContent: 'space-between',
        width: '100%',
        alignItems: 'center'
    },
    textBold: {
        fontFamily: 'Roboto', fontWeight: 'bold', fontSize: '10px'
    },
    sectionDateAndInvoice: {
        width: '50%', height: 'auto', flexDirection: 'row'
    },
    sectionChildDateAndInvoice: {
        fontFamily: 'Roboto', fontWeight: 'medium', fontSize: 10, width: '50%', border: 1, textAlign: 'center', padding: 6
    },
    sectionTwo: {
        marginTop: 50, 
        flexDirection: "row",
        justifyContent: 'space-between',
        width: '100%',
        alignItems: 'center'
    },
    sectionThree: {
        marginTop: 10, 
        flexDirection: "row",
        justifyContent: 'space-between',
        width: '100%',
        alignItems: 'center'
    },
    dueDate: {
        fontFamily: 'Roboto', fontWeight: 'medium', fontSize: 10, width: '100%', border: 1, textAlign: 'center', padding: 6
    },
    sectionTable: {
        width: '100%', height: 'auto', flexDirection: 'row'
    },
    fieldSet:{
        padding: 8,
        borderRadius: 3,
        borderWidth: 1,
        borderColor: '#000',
    },
    legend:{
        position: 'absolute',
        top: -6,
        left: 10,
        fontWeight: 'bold',
        backgroundColor: '#FFFFFF',
        fontFamily: 'Roboto', fontWeight: 'medium', fontSize: 10,
        paddingRight: 5,
        paddingLeft: 5
    }
});

const RoutePdf = (props) => {
    const classes = useStyles();
    const [data, setData] = useState([]);
    const userRoles = localStorage.getUserRoles();
    const isFieldTechnician = !userRoles || (userRoles.length === 1 && userRoles[0] === PERMISSIONS.FIELD_TECHNICIAN);
    useEffect(() => {
        if(props.routeInfoPdf) {
            setData(props.routeInfoPdf);
        }
    }, [props])

    Font.register({
        family: 'Roboto',
        fonts: [
            {
                src: `https://cdn.jsdelivr.net/npm/roboto-fontface@0.10.0/fonts/roboto/Roboto-Regular.woff`,
                fontWeight: 'medium'
            },
            {
                src: `https://cdn.jsdelivr.net/npm/roboto-fontface@0.10.0/fonts/roboto/Roboto-Bold.woff`,
                fontWeight: 'bold'
            }
        ]
    })

    return (
        <>
            <Document>
                {props.routeInfoPdf && props.routeInfoPdf.map((data, index) => {
                    return (
                        <Page size="A4" style={{ padding: 20, paddingTop: 20 }} key={index}>
                            {/* header */}
                            <View fixed style={{ paddingLeft: 10, paddingRight: 10 }}>
                                <View style={{ width: '100%', flexDirection: 'row' }}>
                                    <View style={{ width: '10%', height: 'auto', alignItems: 'center' }}>
                                        <Text style={{ fontFamily: 'Roboto', fontWeight: 'medium', fontSize: 10 }}>
                                            {moment().format('MM/DD/YYYY')}
                                        </Text>
                                    </View>
                                    <View style={{ width: '1%' }} />
                                    <View style={{ width: '80%', height: 'auto', alignItems: 'center' }}>
                                        <Text style={{ fontFamily: 'Roboto', fontWeight: 'bold', fontSize: 13 }}>
                                            Squeegee Squad
                                        </Text>
                                    </View>
                                    <View style={{ width: '1%' }} />
                                    <View style={{ width: '10%', height: 'auto', alignItems: 'center' }}>
                                        <Text style={{ fontFamily: 'Roboto', fontWeight: 'medium', fontSize: 10 }} render={({ pageNumber, totalPages}) => "Page " + pageNumber +" of "+totalPages} fixed />
                                    </View>
                                </View>
                                <View style={{ width: '100%', flexDirection: 'row' }}>
                                    <View style={{ width: '10%', height: 'auto', alignItems: 'center' }} />
                                    <View style={{ width: '1%' }} />
                                    <View style={{ width: '80%', height: 'auto', alignItems: 'center' }}>
                                        <Text style={{ fontFamily: 'Roboto', fontWeight: 'bold', fontSize: 14 }}>
                                            Route Report for {moment(data.routeDate).format('MM/DD/YYYY')}
                                        </Text>
                                    </View>
                                    <View style={{ width: '1%' }} />
                                    <View style={{ width: '10%', height: 'auto', alignItems: 'center' }} />
                                </View>
                            </View>
                            
                            
                            <View fixed style={{ paddingLeft: 10, paddingRight: 10, marginTop: 40 }}>
                                <View style={{ width: '100%', flexDirection: 'row' }}>
                                    <View style={{ width: '20%', height: 'auto' }}>
                                        <Text style={{ fontFamily: 'Roboto', fontWeight: 'bold', fontSize: 11 }}>
                                            Date
                                        </Text>
                                    </View>
                                    <View style={{ width: '1%' }} />
                                    <View style={{ width: '79%', height: 'auto' }}>
                                        <Text style={{ fontFamily: 'Roboto', fontWeight: 'bold', fontSize: 11 }}>
                                            Date Route Description and Summary
                                        </Text>
                                    </View>
                                </View>
                                <View style={{ width: '100%', flexDirection: 'row', marginTop: 10 }}>
                                    <View style={{ width: '20%', height: 'auto' }}>
                                        <Text style={{ fontFamily: 'Roboto', fontWeight: 'medium', fontSize: 10 }}>
                                            {moment(data.routeDate).format('MM/DD/YYYY')}
                                        </Text>
                                    </View>
                                    <View style={{ width: '1%' }} />
                                    <View style={{ width: '79%', height: 'auto' }}>
                                    <Text style={{ fontFamily: 'Roboto', fontWeight: 'medium', fontSize: 10 }}>
                                            {data.route && data.route.cities.join(", ")}
                                            {" "}
                                            {!(data.route.jobs.filter(x=>x.hidePriceLineItemsFromFieldTechs == true).length > 0 && isFieldTechnician) ? "$" + data.route && data.route.total.toFixed(2) : ""}
                                        </Text>
                                    </View>
                                </View>
                            </View>

                            {/* end header */}

                            <View style={{ borderBottomWidth: 4, borderBottomColor: 'black', marginTop: 5 }} />

                            <View style={{ paddingLeft: 10, paddingRight: 10, marginTop: 10, flexDirection: 'row' }}>
                                <View>
                                    <View style={{ width: '100%', flexDirection: 'row' }}>
                                        <View style={{ width: '100%', height: 'auto' }}>
                                            <Text style={{ fontFamily: 'Roboto', fontWeight: 'bold', fontSize: 11 }}>
                                                Route Notes:
                                            </Text>
                                        </View>
                                    </View>
                                    <View style={{ width: '100%', flexDirection: 'row' }}>
                                        <View style={{ width: '100%', height: 'auto' }}>
                                            <Text style={{ fontFamily: 'Roboto', fontWeight: 'medium', fontSize: 10 }}>
                                                {data && data.route && data.route.routeNote}
                                            </Text>
                                        </View>
                                    </View>
                                </View>
                                <View>
                                    <View style={{ width: '100%', flexDirection: 'row' }}>
                                        <View style={{ width: '100%', height: 'auto' }}>
                                            <Text style={{ fontFamily: 'Roboto', fontWeight: 'bold', fontSize: 11 }}>
                                                Route Equipment:
                                            </Text>
                                        </View>
                                    </View>
                                    <View style={{ width: '100%', flexDirection: 'row' }}>
                                        <View style={{ width: '100%', height: 'auto' }}>
                                            <Text style={{ fontFamily: 'Roboto', fontWeight: 'medium', fontSize: 10 }}>
                                                {data && data.route && data.route.routeEquipment.join(", ")}
                                            </Text>
                                            <Text style={{ fontFamily: 'Roboto', fontWeight: 'medium', fontSize: 10 }}>
                                                {data && data.route && data.route.jobEquipment.join(", ")}
                                            </Text>
                                        </View>
                                    </View>
                                </View>
                            </View>

                            <View style={{ paddingLeft: 5, paddingRight: 5, marginTop: 5 }}>
                                <View style={{ width: '50%', flexDirection: 'row' }}>
                                    <View style={{ width: '100%', height: 'auto', borderWidth: 1, borderColor: 'black', padding: 10, borderBottomWidth: 0 }}>
                                        <Text style={{ fontFamily: 'Roboto', fontWeight: 'bold', fontSize: 11, textAlign: 'center' }}>
                                            Cleaners
                                        </Text>
                                        {data.route && data.route.employees.map((data, index) => {
                                            return (
                                                <View key={index} style={{ width: '100%', height: 'auto', marginTop: 3 }}>
                                                    <Text style={{ fontFamily: 'Roboto', fontWeight: 'medium', fontSize: 10 }}>
                                                        {data.firstName || ''}{" "}{data.lastName || ''}{" "}{formatPhoneNumber(data.phoneNumber) || ''}
                                                    </Text>
                                                </View>
                                            )
                                        })}
                                    </View>
                                </View>
                            </View>
                            
                            <View style={{ paddingLeft: 5, paddingRight: 5 }}>
                                <View style={{  }}>
                                    <View style={{ width: '100%', padding: 10, paddingTop: 5, paddingBottom: 0, borderWidth: 1, borderColor: 'black', borderBottomWidth: (data.route && data.route.jobs.length > 0 ? 0 : 1) }}>
                                        <Text style={{ fontFamily: 'Roboto', fontWeight: 'bold', fontSize: 11 }}>
                                            Jobs
                                        </Text>
                                    </View>
                                    {data.route && data.route.jobs.length > 0 && data.route.jobs.map((d, index) => {
                                        return (
                                            <View wrap={d.customerNote?.length > 1000} break={index > 0 && d.customerNote?.length > 1000} key={index} style={{ borderWidth: 1, borderColor: 'black', borderTopWidth: (index == 0 ? 0 : 1) }}>
                                                <View wrap={false} style={{ flexDirection: 'row' }}>
                                                    <View style={{ width: '65%', alignItems: 'center' }}>
                                                        <View style={{ width: '100%', height: 'auto', padding: 10, paddingTop: 5, paddingBottom: 5 }}>
                                                            <Text style={{ fontFamily: 'Roboto', fontWeight: 'medium', fontSize: 10 }}>
                                                                {moment.utc(d.jobStart).local().format('MM/DD/YYYY')}{" "}{moment.utc(d.jobStart).local().format('hh:mm A')}{" - "}{moment.utc(d.jobEnd).local().format('hh:mm A')}
                                                                {
                                                                    d.flexibleScheduling ? `  Anytime Today/Flexible` : ""
                                                                }
                                                            </Text>
                                                        </View>
                                                        <View style={{ width: '100%', height: 'auto', padding: 10, paddingTop: 5, paddingBottom: 5 }}>
                                                            <Text style={{ fontFamily: 'Roboto', fontWeight: 'bold', fontSize: 11 }}>
                                                                Address
                                                            </Text>
                                                            <Text style={{ fontFamily: 'Roboto', fontWeight: 'medium', fontSize: 10 }}>
                                                                {d.jobAddress ? d.jobAddress.name : ""}
                                                            </Text>
                                                            <Text style={{ fontFamily: 'Roboto', fontWeight: 'medium', fontSize: 10 }}>
                                                                {d.jobAddress ? d.jobAddress.address1 : ""}
                                                            </Text>
                                                            <Text style={{ fontFamily: 'Roboto', fontWeight: 'medium', fontSize: 10 }}>
                                                                {d.jobAddress ? d.jobAddress.address2 : ""}
                                                            </Text>
                                                            <Text style={{ fontFamily: 'Roboto', fontWeight: 'medium', fontSize: 10 }}>
                                                                {d.jobAddress ? d.jobAddress.city+", " : ""}
                                                                {d.jobAddress ? d.jobAddress.state.name+" " : ""}
                                                                {d.jobAddress ? d.jobAddress.zipCode : ""}
                                                            </Text>
                                                        </View>
                                                    </View>
                                                    <View style={{ width: '35%', alignItems: 'center' }}>
                                                        <View style={{ width: '100%', height: 'auto', padding: 10, paddingTop: 5, paddingBottom: 5 }}>
                                                            <Text style={{ fontFamily: 'Roboto', fontWeight: 'medium', fontSize: 10 }}>
                                                                {d.jobAddress && d.jobAddress.primaryContact ? d.jobAddress.primaryContact.name : ""}
                                                            </Text>
                                                            {/* <Text style={{ fontFamily: 'Roboto', fontWeight: 'medium', fontSize: 10 }}>
                                                                Home - (763) 757-9528
                                                            </Text> */}
                                                            <Text style={{ fontFamily: 'Roboto', fontWeight: 'medium', fontSize: 10 }}>
                                                                {d.jobAddress && d.jobAddress.primaryContact && d.jobAddress.primaryContact.phone != "" ? "Cell - "+formatPhoneNumber(d.jobAddress.primaryContact.phone) : ""}
                                                            </Text>
                                                        </View>
                                                    </View>
                                                </View>
                                                <View wrap={false} style={{ flexDirection: 'row' }}>
                                                    <View style={{ width: '15%', height: 'auto', padding: 10, paddingTop: 5, paddingBottom: 5 }}>
                                                        <Text style={{ fontFamily: 'Roboto', fontWeight: 'bold', fontSize: 11 }}>
                                                            Description:
                                                        </Text>
                                                    </View>
                                                    <View style={{ width: '85%', height: 'auto', padding: 10, paddingTop: 5, paddingBottom: 5 }}>
                                                        {d.description && d.splitDescription && d.description != d.splitDescription
                                                            ? <View>
                                                                <Text style={{ fontFamily: 'Roboto', fontWeight: 'medium', fontSize: 10 }}>
                                                                    {d.description ? d.description : ""}
                                                                </Text>
                                                                <Text style={{ fontFamily: 'Roboto', fontWeight: 'medium', fontSize: 10 }}>
                                                                    {d.splitDescription ? d.splitDescription : ""}
                                                                </Text>
                                                            </View>
                                                            : <View>
                                                                <Text style={{ fontFamily: 'Roboto', fontWeight: 'medium', fontSize: 10 }}>
                                                                    {d.description ? d.description : ""}
                                                                </Text>
                                                            </View>
                                                            }
                                                    </View>
                                                </View>
                                                {<View>
                                                {d.jobItems && d.jobItems.length > 0 && d.jobItems.map(function (value, index, array) {
                                                    return <View wrap={false} style={{ flexDirection: 'row' }} key={index}>
                                                                <View style={{ width: '15%', height: 'auto', padding: 10, paddingTop: 5, paddingBottom: 5 }}>
                                                                    <Text style={{ fontFamily: 'Roboto', fontWeight: 'medium', fontSize: 10, textAlign: 'right' }}>
                                                                        {value.quantity}
                                                                    </Text>
                                                                </View>
                                                                <View style={{ width: '55%', height: 'auto', padding: 10, paddingTop: 5, paddingBottom: 5 }}>
                                                                    <Text style={{ fontFamily: 'Roboto', fontWeight: 'medium', fontSize: 10 }}>
                                                                        {value.description}
                                                                    </Text>
                                                                </View>
                                                                {!(d.hidePriceLineItemsFromFieldTechs && isFieldTechnician) && <View style={{ width: '15%', height: 'auto', padding: 10, paddingTop: 5, paddingBottom: 5 }}>
                                                                    <Text style={{ fontFamily: 'Roboto', fontWeight: 'medium', fontSize: 10 }}>
                                                                        {(value.price && `$${value.price.toFixed(2)}`) || '$0.00'}
                                                                    </Text>
                                                                </View>
                                                                }
                                                                {!(d.hidePriceLineItemsFromFieldTechs && isFieldTechnician) && <View style={{ width: '15%', height: 'auto', padding: 10, paddingTop: 5, paddingBottom: 5 }}>
                                                                    <Text style={{ fontFamily: 'Roboto', fontWeight: 'medium', fontSize: 10 }}>
                                                                        {(value.total && `$${value.total.toFixed(2)}`) || '$0.00'}
                                                                    </Text>
                                                                </View>
                                                                }
                                                            </View>
                                                })}

                                                <View wrap={false} style={{ padding: 10, paddingTop: 5, paddingBottom: 5 }}>
                                                    <View style={{ borderBottomWidth: 1, borderBottomColor: 'black', width: '100%' }} />
                                                </View>

                                                {!(d.hidePriceLineItemsFromFieldTechs && isFieldTechnician) && <View wrap={false} style={{ flexDirection: 'row', paddingLeft: 10, paddingRight: 10 }}>
                                                    <View style={{ height: 'auto', paddingTop: 5, paddingBottom: 5, flexDirection: 'row', width: '44%' }}>
                                                        <View style={[styles.fieldSet, { width: '100%' }]}>
                                                            {d.discountType ? <Text style={styles.legend}>Discount Type</Text> : null}
                                                            <Text style={{ fontFamily: 'Roboto', fontWeight: 'medium', fontSize: 10, }}>
                                                                {(d.discountType && d.discountType) || 'Discount Type'}
                                                            </Text>
                                                        </View>
                                                    </View>
                                                    <View style={{ width: '1%' }}/>
                                                    <View style={{ height: 'auto', paddingTop: 5, paddingBottom: 5, flexDirection: 'row', width: '23%' }}>
                                                        <View style={[styles.fieldSet, { width: '100%' }]}>
                                                            {d.discountPercent ? <Text style={styles.legend}>Discount %</Text> : null}
                                                            <Text style={{ fontFamily: 'Roboto', fontWeight: 'medium', fontSize: 10, }}>
                                                                {(d.discountPercent && d.discountPercent) || 'Discount %'}
                                                            </Text>
                                                        </View>
                                                    </View>
                                                    <View style={{ width: '1%' }}/>
                                                    <View style={{ height: 'auto', paddingTop: 5, paddingBottom: 5, flexDirection: 'row', width: '15%' }}>
                                                        <View style={[styles.fieldSet, { width: '100%' }]}>
                                                            {d.discountDollars ? <Text style={styles.legend}>Discount $</Text> : null}
                                                            <Text style={{ fontFamily: 'Roboto', fontWeight: 'medium', fontSize: 10, }}>
                                                                {(d.discountDollars && d.discountDollars && `$${d.discountDollars.toFixed(2)}`) || 'Discount $'}
                                                            </Text>
                                                        </View>
                                                    </View>
                                                    <View style={{ width: '1%' }}/>
                                                    <View style={{ height: 'auto', paddingTop: 5, paddingBottom: 5, flexDirection: 'row', width: '15%' }}>
                                                        <View style={[styles.fieldSet, { width: '100%' }]}>
                                                            {d.splitAmount ? <Text style={styles.legend}>Split Total</Text> : null}
                                                            <Text style={{ fontFamily: 'Roboto', fontWeight: 'medium', fontSize: 10, }}>
                                                                {(d.splitAmount && d.splitAmount && `$${d.splitAmount.toFixed(2)}`) || 'Split Total'}
                                                            </Text>
                                                        </View>
                                                    </View>
                                                </View>
                                                }
                                                
                                                {!(d.hidePriceLineItemsFromFieldTechs && isFieldTechnician) && <View wrap={false} style={{ flexDirection: 'row', paddingLeft: 10, paddingRight: 10, marginTop: 5 }}>
                                                    <View style={{ height: 'auto', paddingTop: 5, paddingBottom: 5, flexDirection: 'row', width: '26%' }}>
                                                        <View style={[styles.fieldSet, { width: '100%' }]}>
                                                            {d.total ? <Text style={styles.legend}>Total Price</Text> : null}
                                                            <Text style={{ fontFamily: 'Roboto', fontWeight: 'medium', fontSize: 10, }}>
                                                                {(d.total && d.total && `$${d.total.toFixed(2)}`) || 'Total Price'}
                                                            </Text>
                                                        </View>
                                                    </View>
                                                    <View style={{ width: '1%' }}/>
                                                    <View style={{ height: 'auto', paddingTop: 5, paddingBottom: 5, flexDirection: 'row', width: '25%' }}>
                                                        <View style={[styles.fieldSet, { width: '100%' }]}>
                                                            {d.subtotal ? <Text style={styles.legend}>Subtotal</Text> : null}
                                                            <Text style={{ fontFamily: 'Roboto', fontWeight: 'medium', fontSize: 10, }}>
                                                                {(d.subtotal && d.subtotal && `$${d.subtotal.toFixed(2)}`) || 'Subtotal'}
                                                            </Text>
                                                        </View>
                                                    </View>
                                                    <View style={{ width: '1%' }}/>
                                                    <View style={{ height: 'auto', paddingTop: 5, paddingBottom: 5, flexDirection: 'row', width: '15%' }}>
                                                        <View style={[styles.fieldSet, { width: '100%' }]}>
                                                            {d.taxPercent ? <Text style={styles.legend}>Tax</Text> : null}
                                                            <Text style={{ fontFamily: 'Roboto', fontWeight: 'medium', fontSize: 10, }}>
                                                                {(d.taxPercent && d.taxPercent) || 'Tax'}
                                                            </Text>
                                                        </View>
                                                    </View>
                                                    <View style={{ width: '1%' }}/>
                                                    <View style={{ height: 'auto', paddingTop: 5, paddingBottom: 5, flexDirection: 'row', width: '15%' }}>
                                                        <View style={[styles.fieldSet, { width: '100%' }]}>
                                                            {d.tip ? <Text style={styles.legend}>Tip</Text> : null}
                                                            <Text style={{ fontFamily: 'Roboto', fontWeight: 'medium', fontSize: 10, }}>
                                                                {(d.tip && `$${d.tip.toFixed(2)}`) || 'Tip'}
                                                            </Text>
                                                        </View>
                                                    </View>
                                                    <View style={{ width: '1%' }}/>
                                                    <View style={{ height: 'auto', paddingTop: 5, paddingBottom: 5, flexDirection: 'row', width: '15%' }}>
                                                        <View style={[styles.fieldSet, { width: '100%' }]}>
                                                            {d.totalDue ? <Text style={styles.legend}>Total Due</Text> : null}
                                                            <Text style={{ fontFamily: 'Roboto', fontWeight: 'medium', fontSize: 10, }}>
                                                                {(d.totalDue && `$${d.totalDue.toFixed(2)}`) || 'Total Due'}
                                                            </Text>
                                                        </View>
                                                    </View>
                                                </View>
                                                }
                                            </View>
                                            }
                                                <View style={{ flexDirection: 'row', justifyContent: 'space-between' }}>
                                                    <View style={{ width: '100%', height: 'auto', padding: 10, paddingTop: 5, paddingBottom: 5 }}>
                                                        <Text style={{ fontFamily: 'Roboto', fontWeight: 'bold', fontSize: 11 }}>
                                                            Job Notes:
                                                        </Text>
                                                        <Text style={{ fontFamily: 'Roboto', fontWeight: 'medium', fontSize: 10, marginTop: 5 }}>
                                                            {d.jobNote ? d.jobNote : ""}
                                                        </Text>
                                                        <Text style={{ fontFamily: 'Roboto', fontWeight: 'medium', fontSize: 10, marginTop: 5 }}>
                                                            {d.jobSiteNote ? d.jobSiteNote : ""}
                                                        </Text>
                                                        <Text style={{ fontFamily: 'Roboto', fontWeight: 'medium', fontSize: 10, marginTop: 5 }}>
                                                            {data.route && data.route.jobEquipment.join(", ")}
                                                        </Text>
                                                    </View>
                                                    <View style={{ width: '100%', height: 'auto', padding: 10, paddingTop: 5, paddingBottom: 5 }}>
                                                        <Text style={{ fontFamily: 'Roboto', fontWeight: 'bold', fontSize: 11 }}>
                                                            Customer Notes:
                                                        </Text>
                                                        <Text style={{ fontFamily: 'Roboto', fontWeight: 'medium', fontSize: 10, marginTop: 5 }}>
                                                            {d.customerNote ? d.customerNote : ""}
                                                        </Text>
                                                    </View>
                                                </View>

                                                {/* <View wrap={false} style={{ padding: 5, paddingTop: 2.5, paddingBottom: 2.5 }}>
                                                    <View style={{ borderBottomWidth: 2, borderBottomColor: 'black', width: '100%' }} />
                                                </View> */}
                                            </View>
                                            )
                                        })}
                                </View>
                            </View>
                        </Page>
                    )
                })}
            </Document>
        </>
    )
}

RoutePdf.propTypes = {
    
}

export default RoutePdf;
