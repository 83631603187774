import React, { useContext, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { Snackbar, Typography } from '@material-ui/core';
import { Alert } from '@material-ui/lab';
import { AzureMapsContext } from 'react-azure-maps';
import { data, Shape } from 'azure-maps-control';
import { control, drawing } from 'azure-maps-drawing-tools';
import 'azure-maps-drawing-tools/dist/atlas-drawing.min.css';
import * as turf from '@turf/turf';

import { ConfirmModal } from 'components';
import { route } from 'mockup/Routes';
import axios from 'utils/axios';
import apiConfig from 'apiConfig';
import MapComponent from './MapComponent';
import SchedulePinsModal from '../Modals/SchedulePinsModal';

const { Feature, FeatureCollection, Point, Polygon } = data;

let drawingManager = null;
let points;
let isFirstTimeCoordinatesChanges = true;

const MapController = props => {
  const { coordinates, drawingManagerOptions } = props;
  const { date } = useParams();
  const { mapRef, isMapReady } = useContext(AzureMapsContext);

  const dispatch = useDispatch();
  const { routeJobs } = useSelector(state => state.JobsReducer);
  const { routes } = useSelector(state => state.RoutesReducer);

  const [pointsWithin, setPointsWithin] = useState();
  const [jobSplitIdsWithin, setJobSplitIdsWithin] = useState([]);
  const [openSchedulePinsModal, setOpenSchedulePinsModal] = useState(false);
  const [openConfirmModal, setOpenConfirmModal] = useState(false);
  const [okConfirmButtonLabel, setOkConfirmButtonLabel] = useState('Yes');
  const [removeJobsProgress, setRemoveJobsProgress] = useState(false);
  const [scheduleProgress, setScheduleProgress] = useState(false);
  const [isStatus, setStatus] = useState({ failed: false, msg: '' });
  const [openSnackbar, setOpenSnackbar] = useState(false);

  const searchPolygon = searchArea => {
    //Exit drawing mode.
    drawingManager && drawingManager.setOptions({ mode: 'idle' });

    //If the search area is a circle, create a polygon from its circle coordinates.
    if (searchArea.isCircle()) {
        searchArea = new Shape(new Polygon([searchArea.getCircleCoordinates()]));
    }

    const poly = searchArea.toJson();

    //Optionally, retrieve the individual points that are in the search area.

    //Calculate all points that are within the polygon area.
    const ptsWithin = turf.pointsWithinPolygon(points, poly);
    setPointsWithin(ptsWithin);
    setJobSplitIdsWithin(ptsWithin.features.map(f => f.properties.jobSplitId));

    if (ptsWithin.features.length === 0) return;
    const scheduledPts = ptsWithin.features.filter(f => f.properties.routeId != null);
    if (scheduledPts.length > 0) {
      setOpenConfirmModal(true);
    } else {
      setOpenSchedulePinsModal(true);
    }
  };

  const drawingModeChanged = eventType => {
    if (drawingManager && eventType === 'erase-geometry') {
      drawingManager.getSource().clear();
      setTimeout(() => {
        drawingManager.setOptions({ mode: 'idle' });
      });
    }
  };

  const onCloseConfirm = () => {
    setOpenConfirmModal(false);
    const jobSplitIds = pointsWithin.features
      .filter(f => f.properties.routeId == null)
      .map(f => f.properties.jobSplitId);

    if (jobSplitIds.length === 0) return;
    setJobSplitIdsWithin(jobSplitIds);
    setOpenSchedulePinsModal(true);
  };

  const onOkConfirm = () => {
    setOkConfirmButtonLabel('Removing jobs from assigned route(s)');
    setRemoveJobsProgress(true);
    const jobsScheduledWithin = pointsWithin.features.filter(f => f.properties.routeId != null);
    const requests = Object.entries(
      // Group by routeId
      jobsScheduledWithin.reduce((acc, job) => {
        acc[job.properties.routeId] = [...(acc[job.properties.routeId] ?? []), job.properties.jobSplitId];
        return acc;
      }, {})
    ).map(([routeId, jobSplitIds]) => {
        // Then make a request for each routeId
        const route = routes.find(r => r.id === routeId);
        const routeData = {
          ...route,
          routeEquipment: !route.routeEquipment ? [] : [...route.routeEquipment],
          routeEmployees: !route.routeEmployees ? [] : [...route.routeEmployees],
          routeJobs: route.routeJobs.filter(rj => !jobSplitIds.includes(rj.jobSplitId))
        }

        return axios.put(apiConfig.url.BASE_URL + apiConfig.url.ROUTE + date + '/' + route.id, routeData);
      });

    Promise.all(requests)
      .then(values => {
        jobsScheduledWithin.forEach(job => {
          const route = routes.find(r => r.id === job.properties.routeId);
          const routeJobRemove = route?.routeJobs.find(rj => rj.jobSplitId === job.properties.jobSplitId);
          if (routeJobRemove) {
            routeJobRemove.job.job = {
              city: routeJobRemove.job.city,
              estimatedHours: routeJobRemove.job.estimatedHours,
              price: routeJobRemove.job.estimatedHours
            };
            dispatch({ type: 'ADD_JOB', jobSplitId: job.properties.jobSplitId, routeRemove: routeJobRemove.job });
          }

          dispatch({ type: 'REMOVE_JOB_ROUTE', ...job.properties });
          dispatch({ type: 'CHANGE_JOB_STAGE', jobSplitId: job.properties.jobSplitId, jobStage: 3 });
        });

        setOpenConfirmModal(false);
        setOpenSchedulePinsModal(true);
      })
      .catch(() => {
        setStatus({ failed: true, msg: 'Remove failed, please try again later.' });
        setOpenSnackbar(true);
      })
      .finally(() => setRemoveJobsProgress(false));
  };

  const onSchedule = routeJobsData => {
    setScheduleProgress(true);
    const routeData = { ...route };
    routeData.routeJobs = routeJobsData;
    axios.post(apiConfig.url.BASE_URL + apiConfig.url.ROUTE + date, routeData)
      .then(res => {
        dispatch({
          type: 'ADD_ROUTE',
          route: { ...res.data, index: routes.length }
        });
        res.data.routeJobs && res.data.routeJobs.forEach(rj => dispatch({ type: 'REMOVE_JOB', jobSplitId: rj.jobSplitId }));
        setOpenSchedulePinsModal(false);
      })
      .catch(() => {
        setStatus({ failed: true, msg: 'Add failed, please try again later.' });
        setOpenSnackbar(true);
      })
      .finally(() => setScheduleProgress(false));
  };

  useEffect(() => {
    if (isFirstTimeCoordinatesChanges && mapRef && isMapReady && coordinates.length > 0) {
      isFirstTimeCoordinatesChanges = false;
      const positions = coordinates.filter(x => x.latitude != null && x.longitude != null)
        .reduce((res, coord) => [...res, [coord.longitude, coord.latitude]], []);
      const bbox = data.BoundingBox.fromPositions(positions);
      
      if (bbox[0] === bbox[2] && bbox[1] === bbox[3]) {
        mapRef.setCamera({
          center: [bbox[0], bbox[1]],
          zoom: 10
        });
      } else {
        mapRef.setCamera({
          bounds: bbox,
          padding: 50
        });
      }
    }

  }, [mapRef, isMapReady, coordinates]);

  useEffect(() => () => isFirstTimeCoordinatesChanges = true, []);

  useEffect(() => {
    points = new FeatureCollection(
      (routeJobs ?? [])
        .concat(coordinates ?? [])
        .filter(job => job.latitude != null && job.longitude != null)
        .map(
          job => new Feature(
            new Point([job.longitude, job.latitude]),
            {
              jobSplitId: job.jobSplitId,
              routeId: job.routeId
            },
            job.jobSplitId
          )
        )
    )
  }, [routeJobs, coordinates]);

  useEffect(() => {
    if (!drawingManagerOptions || !drawingManager) return;
    const { showToolbar, ...restOptions } = drawingManagerOptions;
    drawingManager.setOptions({
      toolbar: showToolbar
        ? new control.DrawingToolbar({
          style: 'light',
          buttons: ['draw-polygon', 'draw-rectangle', 'draw-circle', 'erase-geometry']
        })
        : null,
      ...restOptions
    });
  }, [drawingManagerOptions]);

  useEffect(() => {
    if (mapRef && isMapReady) {
      //Create an instance of the drawing manager and set drawing mode.
      drawingManager = new drawing.DrawingManager(mapRef, {
        toolbar: drawingManagerOptions?.showToolbar
          ? new control.DrawingToolbar({
            style: 'light',
            buttons: ['draw-polygon', 'draw-rectangle', 'draw-circle', 'erase-geometry']
          })
          : null,

        //Specifies how the drawing action works. Can be 'click', 'freehand' or 'hybrid'. Default: 'hybrid'
        interactionType: drawingManagerOptions?.interactionType ?? 'hybrid',
        //Specifies the minimum pixel distance the mouse must move before a new position is added to the shape when drawing freehand. Default: 3
        //The smaller the value, the more detailed the drawing, but less performant.
        freehandInterval: drawingManagerOptions?.freehandInterval ?? 3,
        shapeDraggingEnabled: drawingManagerOptions?.shapeDraggingEnabled ?? false,
        shapeRotationEnabled: drawingManagerOptions?.shapeRotationEnabled ?? false,
      });

      mapRef.events.add('drawingcomplete', drawingManager, searchPolygon);
      mapRef.events.add('drawingmodechanged', drawingManager, drawingModeChanged);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isMapReady]);
  
  return (
    <>
      <MapComponent {...props} />
      <SchedulePinsModal
        jobSplitIds={jobSplitIdsWithin}
        open={openSchedulePinsModal}
        isProgress={scheduleProgress}
        onClose={() => setOpenSchedulePinsModal(false)}
        onSchedule={onSchedule}
      />
      <ConfirmModal
        openConfirm={openConfirmModal}
        title="One or more jobs in the boundary is already assigned to some routes, do you want to move it to the new route?"
        cancelButtonText="No"
        okButtonText={okConfirmButtonLabel}
        isProgress={removeJobsProgress}
        closeConfirm={onCloseConfirm}
        onConfirm={onOkConfirm}
      />
      <Snackbar open={openSnackbar}
        onClose={() => setOpenSnackbar(false)}
        autoHideDuration={2000}
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}>
        <Alert elevation={6} variant="filled" severity={isStatus.failed ? 'error' : 'success'}>
          <Typography color="inherit" variant="h6">
            {isStatus.msg}
          </Typography>
        </Alert>
      </Snackbar>
    </>
  );
};

export default MapController;
