import {Grid, Snackbar, Typography} from '@material-ui/core';
import React, {useEffect, useState} from 'react';
import axios from 'utils/axios';
import apiConfig from 'apiConfig';
import {Alert} from '@material-ui/lab';
import {ConfirmModal} from 'components';
import CustomFormCard from "./CustomFormCard";
import CustomFormAdd from "./CustomFormAdd";


let pageNumber = 1;
let forcePage = 0;
let sortBy= 'TemplateName';
let sortType= 'ASC';

const CustomForm = props => {
    const {adminId} = props;
    const [isSuccess, setIsSuccess] = useState({ failed: false, msg: '' });
    const [openSnack, setOpenSnack] = useState(false);
    const [openConfirm, setOpenConfirm] = useState(false);
    const [isProgress, setIsProgress] = useState(false);
    const [lstCustomForm, setLstCustomForm] = useState([]);
    const [pageCount, setPageCount] = useState(0);
    const [idSelected, setIdSelected] = useState();
    const [activeSelected, setActiveSelected] = useState();
    const [openConfirmDelete, setOpenConfirmDelete] = useState(false);
    const [reload, setReload] = useState(false);
    const [openAddForm, setOpenAddForm] = useState(false);
    const [selectedId, setSelectedId] = useState();
    const [loading, setLoading] = useState(true);

    const fetchCustomFormCompany = () => {
        setLoading(true);
        axios.get(apiConfig.url.BASE_URL + apiConfig.url.GET_CUSTOM_FORM_TEMPLATES.replace('{id}', adminId),
        {
            params:
                {
                    sortBy: sortBy,
                    sortType: sortType,
                    limit: 0,
                    pageSize: 24,
                    pageNumber: pageNumber,
                }
        }).then(res => {
                setLstCustomForm(res.data.data);
                setPageCount(res.data.pageCount)
            })
            .catch(err => {

            })
            .finally(()=>{
                setLoading(false);
            });
    };

    const onDelete = () => {
        setIsProgress(true);
        axios.delete(apiConfig.url.BASE_URL + apiConfig.url.CUSTOM_FORM_TEMPLATES.replace('{id}', idSelected))
            .then(() => {
                setIsSuccess({ failed: false, msg: 'Remove success.' });
                setOpenSnack(true);
                setOpenAddForm(false);
            })
            .catch(() => {
                setIsSuccess({ failed: true, msg: 'Remove file failed, please try again later.' });
                setOpenSnack(true);
            })
            .finally(() => {
                setOpenConfirmDelete(false);
                setIsProgress(false);
                setOpenConfirm(false);
                setReload(!reload);
            })
    }

    const onPageChange = (page) => {
        pageNumber = page.selected + 1;
        forcePage = page.selected;
        fetchCustomFormCompany();
    };

    const onSortChange = (by,type) => {
        sortBy = by;
        sortType = type;
        pageNumber = 1;
        forcePage = 0;
        fetchCustomFormCompany();
    };

    const onActive = () => {
        setIsProgress(true);
        axios.put(apiConfig.url.BASE_URL + apiConfig.url.ACTIVE_CUSTOM_FORM_TEMPLATES.replace('{id}', idSelected))
            .then(res => {
                setIsSuccess({failed: false, msg: 'Update success.'});
                setOpenSnack(true);
            })
            .catch(() => {
                setIsSuccess({failed: true, msg: 'Update failed, please try again later.'});
                setOpenSnack(true);
            }).finally(() => {
                setOpenConfirm(false);
                setReload(!reload);
                setIsProgress(false)
        });
    }

    const openEditModal = (id, index) => {
        setSelectedId(id);
        setOpenAddForm(true);
    }

    useEffect(() => {
        fetchCustomFormCompany()
    },[reload])

    return (
        <>
            {openAddForm ?
                <CustomFormAdd
                    setOpenSnackBar={setOpenSnack}
                    setIsStatus={setIsSuccess}
                    setReload={() => setReload(!reload)}
                    selectedId={selectedId}
                    redirectToList={() => setOpenAddForm(false)}
                    onActive={(id, active) => {
                        console.log(id, active)
                        setOpenConfirm(true);
                        setIdSelected(id);
                        setActiveSelected(active);
                    }}
                    onDelete={(id) => {
                        setOpenConfirmDelete(true);
                        setIdSelected(id);
                    }}
                />
                :
                <Grid container spacing={3} direction='column'>
                    <Grid item>
                        <CustomFormCard
                            title='Customer Attachments'
                            data={lstCustomForm}
                            onDelete={(id) => {
                                setOpenConfirmDelete(true);
                                setIdSelected(id);
                            }}
                            onPageChange={onPageChange}
                            pageCount={pageCount}
                            loading={loading}
                            onActive={(id, active) => {
                                setOpenConfirm(true);
                                setIdSelected(id);
                                setActiveSelected(active);
                            }}
                            onOpenModalAdd={() => {
                                setSelectedId('');
                                setOpenAddForm(true);
                            }}
                            onSortChange={onSortChange}
                            openEditModal={openEditModal}
                        />
                    </Grid>
                </Grid>
            }
            <ConfirmModal
                title={activeSelected ? 'Are you sure that you want to deactivate this form?' : 'Are you sure that you want to active this form?'}
                openConfirm={openConfirm}
                closeConfirm={() => setOpenConfirm(false)}
                onConfirm={onActive}
                isProgress={isProgress}
            />

            <ConfirmModal
                title={'Are you sure that you want to delete this form?'}
                openConfirm={openConfirmDelete}
                closeConfirm={() => setOpenConfirmDelete(false)}
                onConfirm={onDelete}
                isProgress={isProgress}
            />

            <Snackbar
                open={openSnack}
                anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
                autoHideDuration={2000}
                onClose={() => setOpenSnack(false)}>
                <Alert
                    elevation={6} variant="filled" severity={isSuccess.failed ? 'error' : 'success'}>
                    <Typography
                        color="inherit"
                        variant="h6">
                        {isSuccess.msg}
                    </Typography>
                </Alert>
            </Snackbar>
        </>
    );
};

export default CustomForm;
