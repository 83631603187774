import {
    Button,
    Card,
    CardActions,
    CardContent,
    CardHeader,
    CircularProgress,
    colors,
    Divider,
    Modal,
    TextField,
} from "@material-ui/core";
import React, { useRef, useState } from "react";
import {makeStyles} from "@material-ui/styles";
import * as Yup from "yup";
import NumberFormat from "react-number-format";
import { Formik } from "formik";
import axios from "../../../utils/axios";
import apiConfig from "../../../apiConfig";

const useStyles = makeStyles(theme => ({
    styleModal: {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        outline: 'none',
        boxShadow: theme.shadows[20],
        width: "550px",
        maxHeight: '100%',
        overflowY: 'auto',
        maxWidth: '100%'
    },
    conButton: {
        padding: '16px',
        justifyContent: 'flex-end'
    },
    button: {
        flexShrink: '0',
        backgroundColor: colors.green[600],
        '&:hover': {
            backgroundColor: colors.green[900]
        },
    },
    phoneNumber: {
        transform: `translate(${theme.spacing(1.5)}px, -${theme.spacing(3)}px)`,
    }
}))

const phoneNumberSchema = Yup.object().shape({
    phoneNumber: Yup.string().required('This field is required.')
                    .matches(/\W\d{3}\W\s\w{3}\W\w{4}$|\W\d{3}\W\s\w{3}\W\w{4}x\d{1,6}|\W\d{3}\W\s\w{3}\W\w{4}x/, 'Invalid phone number.')
})


const ModalPhoneNumber = props => {
    const classes = useStyles();

    const {openConfirm, closeConfirm, onConfirm, phoneNumber, phoneId, customerId} = props;

    const input = useRef(null);
    const [isSaving, setIsSaving] = useState(false);

    const onSubmit = values => {
        setIsSaving(true);
        const params = {
            customerId,
            phoneId,
            phoneNumber: input.current.state.value
        };
        axios.post(apiConfig.url.CUSTOMER_URL + apiConfig.url.CUSTOMERS_UPDATE_PHONE_NUMBER, params)
        .then(res => {
            onConfirm(params.phoneNumber);
        })
        .catch(err => {
            console.log(err);
        }).finally(() => setIsSaving(false));
    }

    return (
        <Modal open={openConfirm}>
            <Formik
                initialValues={{phoneNumber, phoneId, customerId}}
                validationSchema={phoneNumberSchema}
                enableReinitialize
                onSubmit={onSubmit}
            >
                {({
                    values,
                    errors,
                    handleChange,
                    handleSubmit
                }) => (
                    <form onSubmit={handleSubmit}>
                        <Card className={classes.styleModal}>
                            <CardHeader style={{padding: '28px'}} title='The phone format is invalid.  Please correct the format and try again.'/>
                            <CardContent>
                                <div className={classes.phoneNumber}>{phoneNumber}</div>
                                            <NumberFormat
                                                ref={input}
                                                fullWidth
                                                customInput={TextField}
                                                error={errors.phoneNumber}
                                                helperText={errors.phoneNumber}
                                                label="Suggested Phone Number"
                                                name="phoneNumber"
                                                value={values.phoneNumber}
                                                onChange={handleChange}
                                                variant="outlined"
                                                format="(###) ###-####x######"
                                            />
                            </CardContent>
                            <Divider/>
                            <CardActions className={classes.conButton}>
                                <Button
                                    variant="contained"
                                    size="large"
                                    onClick={closeConfirm}>
                                    Cancel
                                </Button>
                                <Button
                                    disabled={isSaving}
                                    variant="contained"
                                    size="large"
                                    color="primary"
                                    type="submit"
                                    className={classes.button}>
                                    Save
                                </Button>
                                {isSaving && <CircularProgress size={24} />}
                            </CardActions>
                        </Card>
                    </form>
                )}
            </Formik>
        </Modal>
    )
}
export default ModalPhoneNumber
