import React, { useState, useEffect, useRef } from 'react'
import { makeStyles } from '@material-ui/styles'
import { SearchInput, Pagination, Page } from 'components'
import { AccessControl } from 'components/AccessControl/AccessControl'
import { PERMISSIONS } from 'common/permissions'
import CompanyCard from './CompanyCard'
import Fab from '@material-ui/core/Fab';
import AddIcon from '@material-ui/icons/Add';
import CircularProgress from '@material-ui/core/CircularProgress'
import { Button, Grid, Typography,colors } from '@material-ui/core'
import CompanyModal from './CompanyModal'
import axios from 'utils/axios'
import apiConfig from "../../apiConfig";
import { formatPhoneNumber } from 'utils/formatNumber'

const useStyles = makeStyles(theme => ({
    root: {
        padding: '32px'
    },
    fab: {
        position: 'fixed',
        bottom: theme.spacing(4),
        right: theme.spacing(4),
        backgroundColor: '#43a047',
        '&:hover': {
            backgroundColor: '#1b5e20',
        }
    },
    circularProgress: {
        marginTop: '30px',
    },
    customSearch: {
        marginTop: theme.spacing(2),
        marginBottom: theme.spacing(2),
    },
    noData: {
        fontSize: theme.spacing(2),
        fontWeight: 500,
        textAlign: 'center'
    }
}));

let forcePage = 0;
let pageNumber = 1;

const useSearchDelay = (val, delay) => {
    delay = delay || 500;
    const [debounced, setDebounced] = useState(val)

    useEffect(() => {
        const handler = setTimeout(() => {
            setDebounced(val)
        }, delay)

        return () => clearTimeout(handler)
    }, [val, delay])

    return debounced;
};

const Companies = (props) => {

    const classes = useStyles();
    const mounted = useRef(false);

    const [activeEmployees, setActiveEmployees] = useState();
    const [phoneTypes, setPhoneTypes] = useState([]);
    const [companies, setCompanies] = useState();
    const [company, setCompany] = useState(null);
    const [openModal, setOpenModal] = useState(false);
    const [loading, setLoading] = useState(false);
    const [value, setValue] = useState('');
    const [pageCount, setPageCount] = useState(0);
    const [reloadEmployees, setReloadEmployees] = useState(false);
    let searchQuery = useSearchDelay(value);

    const setNewCompany = () => {
        setCompany(null);
        setOpenModal(true)
    }

    const setUpdateCompany = company => {
        setCompany(company);
        setOpenModal(true)
    }

    const fetchUsers = () => {
        let url = apiConfig.url.BASE_URL + apiConfig.url.COMPANY + pageNumber;
        if (searchQuery) {
            url += '&query=' + searchQuery;
        }
        setLoading(true);
        axios.get(url)
            .then(res => {
                if (mounted.current) {
                    setPhoneTypes(res.data.data[0].phoneTypes);
                    res.data.data && res.data.data.forEach(contact => {
                        contact.phones && contact.phones.forEach(p => {
                            p && (p.phoneNumber = formatPhoneNumber(p.phoneNumber));
                        });
                    });
                    setCompanies(res.data.data);
                    setPageCount(res.data.pageCount);
                }
            })
            .finally(() => mounted.current && setLoading(false))
    };

    const onPageChange = (page) => {
        forcePage = page.selected;
        pageNumber = page.selected + 1;
        fetchUsers();
    };

    const onClear = () => {
        forcePage = 0;
        pageNumber = 1;
        setValue('');
    }
    const onChangeText = event => {
        setValue(event.target.value)
    }

    const onSearch = () => {
        forcePage = 0;
        pageNumber = 1;
        fetchUsers();
    };

    useEffect(() => {
        mounted.current = true;
        onSearch();
        return () => mounted.current = false;
    }, [searchQuery]);

    useEffect(() => {
        mounted.current = true;
        axios.get(apiConfig.url.USER_URL + apiConfig.url.EMPLOYEE_LIST_ACTIVE)
            .then(res => setActiveEmployees(res.data.users))
    }, [])

    if (!companies && !activeEmployees) {
        return (
            <Grid
                container
                spacing={0}
                align="center"
                justifyContent="center"
                direction="column">
                <Grid item>
                    <CircularProgress className={classes.circularProgress} size={50} />
                </Grid>
            </Grid>
        )
    }

    return (
        <AccessControl requiredPermission={PERMISSIONS.page.superAdmin} redirect>
            <Page title={'Super admin - Companies'}>
                <div className={classes.root}>
                    <Grid item xs={12} style={{ marginBottom: '16px' }}>
                        <SearchInput
                            onSearch={onSearch}
                            placeholder="Search companies..."
                            value={value}
                            onClear={onClear}
                            onChange={e => {
                                onChangeText(e)
                            }}
                        />
                    </Grid>
                    {loading ?
                        <Grid
                            container
                            spacing={0}
                            align="center"
                            justifyContent="center"
                            direction="column">
                            <Grid item>
                                <CircularProgress className={classes.circularProgress} size={30} />
                            </Grid>
                        </Grid>
                        :
                        (companies.length > 0 ?
                            (
                                <div className={classes.content}>
                                    <Grid container spacing={3}>
                                        {companies.map((company, index) => (
                                            <Grid item key={index} xs={12}>
                                                <CompanyCard company={company} edit={() => setUpdateCompany(company)} />
                                            </Grid>
                                        ))}
                                    </Grid>
                                    <div style={{ marginTop: '16px' }}>
                                        <Pagination pageCount={pageCount} forcePage={forcePage} onPageChange={onPageChange} />
                                    </div>
                                </div>
                            )
                            :
                            <div>
                                <Typography className={classes.noData}>No data found.</Typography>
                            </div>
                        )}

                    <Fab className={classes.fab} color="primary" onClick={setNewCompany}>
                        <AddIcon />
                    </Fab>

                    <CompanyModal
                        open={openModal}
                        onClose={() => {
                            setOpenModal(false);
                            setCompany(null)
                        }}
                        phoneTypes={phoneTypes}
                        company={company}
                        employees={activeEmployees}
                        onUpdated={fetchUsers}
                        reloadEmployees={() => setReloadEmployees(!reloadEmployees)}
                    />
                </div>
            </Page>
        </AccessControl>
    );

};

export default Companies;
