import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { makeStyles } from '@material-ui/styles'
import moment from 'moment';
import { Alert } from '@material-ui/lab'
import {
    Modal, Card, CardHeader, CardContent, CardActions,
    Grid, Typography, TextField, Button, Divider,
    colors, Snackbar
} from '@material-ui/core'
import NumberFormat from 'react-number-format'
import { KeyboardDatePicker } from '@material-ui/pickers'

const useStyles = makeStyles(theme => ({
    styleModal: {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        outline: 'none',
        boxShadow: theme.shadows[20],
        width: 500,
        maxHeight: '100%',
        overflowY: 'auto',
        maxWidth: '100%',
        [theme.breakpoints.down('sm')]: {
            width: 500
        },
    },
    groupDatePicker: {
        position: 'relative'
    },
    iconDatePicker: {
        position: 'absolute',
        right: '11px',
        top: '11px',
        '& span': {
            pointerEvents: 'none'
        }
    },
    conButton: {
        padding: '16px',
        justifyContent: 'flex-end'
    },
    buttonSave: {
        flexShrink: '0',
        backgroundColor: colors.green[600],
        '&:hover': {
            backgroundColor: colors.green[900]
        },
    },
    hidePicker: {
        display: 'none'
    },
}))

const AddPayment = props => {

    const classes = useStyles();
    const { open, save, close } = props;

    const [payment, setPayment] = useState({});
    const [openPicker, setOpenPicker] = useState(false);
    const [openSnack, setOpenSnack] = useState(false);
    const [status, setStatus] = useState({ failed: false, msg: '' })

    const onHandleChangeDate = date => {
        setPayment({ ...payment, date: moment(date).format('MM/DD/YYYY') })
    }
    const onChangePayment = event => {
        const { name, value } = event.target;
        setPayment({ ...payment, [name]: value })
    }
    const resetModal = () => {
        setPayment({});
        close();
    }
    const onSave = () => {
        const { date, type, tip, pay } = payment;
        if (!date || !type || !tip || !pay) {
            setStatus({ failed: true, msg: 'Please fill up the fields.' });
            setOpenSnack(true);
            return;
        }
        if (!moment(date).isValid()) {
            setStatus({ failed: true, msg: 'Please enter valid date.' });
            setOpenSnack(true);
            return;
        }
        save(payment);
        resetModal();
    }

    return (
        <>
            <Modal open={open} onClose={resetModal}>
                <Card className={classes.styleModal}>
                    <CardHeader title='Add Payment' />
                    <Divider />
                    <CardContent>
                        <Grid container spacing={2}>
                            <Grid item xs={12} className={classes.groupDatePicker}>
                                <KeyboardDatePicker
                                    fullWidth
                                    inputVariant="outlined"
                                    minDate={false}
                                    maxDate={false}
                                    label="Date"
                                    format="MM/DD/YYYY"
                                    value={payment.date || null}
                                    InputAdornmentProps={{ position: "end" }}
                                    onChange={(date, value) => { onHandleChangeDate(date) }}
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <TextField
                                    fullWidth
                                    label='Type'
                                    name='type'
                                    variant='outlined'
                                    select
                                    SelectProps={{ native: true }}
                                    value={payment.type || ''}
                                    onChange={onChangePayment}>
                                    <option value=''></option>
                                    <option value='check'>Check</option>
                                    <option value='uncheck'>Uncheck</option>
                                </TextField>
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <NumberFormat
                                    customInput={TextField}
                                    allowNegative={false}
                                    prefix='$'
                                    thousandSeparator
                                    fullWidth
                                    label='Tip'
                                    name='tip'
                                    variant='outlined'
                                    value={payment.tip || ''}
                                    onChange={onChangePayment}
                                />
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <NumberFormat
                                    customInput={TextField}
                                    allowNegative={false}
                                    prefix='$'
                                    thousandSeparator
                                    fullWidth
                                    label='Payment'
                                    name='pay'
                                    variant='outlined'
                                    value={payment.pay || ''}
                                    onChange={onChangePayment}
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <TextField
                                    fullWidth
                                    label='Notes'
                                    name='notes'
                                    variant='outlined'
                                    multiline
                                    rows={3}
                                    value={payment.notes || ''}
                                    onChange={onChangePayment}
                                />
                            </Grid>
                        </Grid>
                    </CardContent>
                    <Divider />
                    <CardActions className={classes.conButton}>
                        <Button
                            variant="contained"
                            size="large"
                            onClick={resetModal}>
                            Close
                        </Button>
                        <div>
                            <Button
                                variant="contained"
                                color="primary"
                                size="large"
                                onClick={onSave}
                                className={classes.buttonSave}>
                                Add
                            </Button>
                        </div>
                    </CardActions>
                </Card>
            </Modal>

            {/*<DatePicker*/}
            {/*    className={classes.hidePicker}*/}
            {/*    open={openPicker}*/}
            {/*    onClose={() => setOpenPicker(false)}*/}
            {/*    format="MM/DD/YYYY"*/}
            {/*    onChange={onHandleChangeDate}*/}
            {/*/>*/}

            <Snackbar
                open={openSnack}
                onClose={() => setOpenSnack(false)}
                anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
                autoHideDuration={3000}>
                <Alert elevation={6} variant='filled' severity={status.failed ? 'error' : 'success'}>
                    <Typography variant='h6' color='inherit'>
                        {status.msg}
                    </Typography>
                </Alert>
            </Snackbar>
        </>
    )
}

AddPayment.propTypes = {
    open: PropTypes.bool,
    save: PropTypes.func,
    close: PropTypes.func
}

export default AddPayment;
