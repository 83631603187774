import React from 'react'
import { IconButton, colors, TableRow, TableCell } from '@material-ui/core'
import moment from 'moment'
import DeleteIcon from '@material-ui/icons/Delete';
import EditIcon from '@material-ui/icons/Edit';
import CheckIcon from '@material-ui/icons/Check';
import { makeStyles } from '@material-ui/styles'
import useMediaQuery from '@material-ui/core/useMediaQuery'

const useStyles = makeStyles(theme => ({
    checkIcon: {
        color: colors.green[500],
    }
}))

const AvailableSchedule = props => {
    const classes = useStyles();
    const matchs = useMediaQuery('(max-width: 1040px)');

    const { schedule, editSchedule, removeSchedule } = props;
    const {
        startDate,
        endDate,
        sunday,
        monday,
        tuesday,
        wednesday,
        thursday,
        friday,
        saturday
    } = schedule;

    const dateString = endDate ? (matchs ?
        `${moment(startDate).format('MM/DD/YYYY')} ${moment(endDate).format('MM/DD/YYYY')}` :
        `${moment(startDate).format('MM/DD/YYYY')} - ${moment(endDate).format('MM/DD/YYYY')}`) :
        `${moment(startDate).format('MM/DD/YYYY')} -`;

    return (
        <TableRow>
            <TableCell>{dateString}</TableCell>
            <TableCell align="center">
                {
                    sunday && sunday.isChecked !== false ? <CheckIcon className={classes.checkIcon} /> : null
                }
            </TableCell>
            <TableCell align="center">
                {
                    monday && monday.isChecked !== false ? <CheckIcon className={classes.checkIcon} /> : null
                }
            </TableCell>
            <TableCell align="center">
                {
                    tuesday && tuesday.isChecked !== false ? <CheckIcon className={classes.checkIcon} /> : null
                }
            </TableCell>
            <TableCell align="center">
                {
                    wednesday && wednesday.isChecked !== false ? <CheckIcon className={classes.checkIcon} /> : null
                }
            </TableCell>
            <TableCell align="center">
                {
                    thursday && thursday.isChecked !== false ? <CheckIcon className={classes.checkIcon} /> : null
                }
            </TableCell>
            <TableCell align="center">
                {
                    friday && friday.isChecked !== false ? <CheckIcon className={classes.checkIcon} /> : null
                }
            </TableCell>
            <TableCell align="center">
                {
                    saturday && saturday.isChecked !== false ? <CheckIcon className={classes.checkIcon} /> : null
                }
            </TableCell>
            <TableCell style={{ whiteSpace: 'nowrap' }}>
                <IconButton onClick={editSchedule}>
                    <EditIcon />
                </IconButton>
                <IconButton onClick={removeSchedule}>
                    <DeleteIcon />
                </IconButton>
            </TableCell>
        </TableRow>
    )
}

export default AvailableSchedule;
