const initState = {
    employees: false,
    customers: false
}

const ReloadReducer = (state = initState, action) => {
    switch (action.type) {
        case 'RELOAD_EMPLOYEES':
            return {
                ...state,
                employees: !state.employees
            }
        case 'RELOAD_CUSTOMERS':
            return {
                ...state,
                customers: !state.customers
            }
        default:
            return state
    }
}

export default ReloadReducer;