export const PERMISSIONS = {
  FIELD_TECHNICIAN: 'Field Technician',
  FIELD_TECHNICIAN_ESTIMATOR: 'Field Technician & Estimator w/ Customer Access',
  SUPER_ADMIN: 'System Admin',
  COMPANY_ADMINISTRATOR: 'Company Administrator',
  OFFICE_STAFF: 'Office Staff',
  page: {
    dashboard: "pageDashboard",
    manageEmployee: "pageManageEmployee",
    logHours: "pageLogHoursEmployee",
    todaysRoute: "pageTodaysRoute",
    payRolls: "pagePayrollEmployees",
    payRoll: "pagePayrollEmployee",
    customers: "pageCustomers",
    schedules: "pageSchedules",
    jobComparison: "pageJobCompletion",
    reports: "pageReports",
    admin: "pageAdmin",
    superAdmin: "pageSuperAdmin",
    help: "pageHelp"
  },
  control : {
    clockInClockOut: 'clockInClockOut',
    showListEmployees: 'showListEmployees',
    enableDatePicker: 'enableDatePicker',
    showEmployeeRole: 'showEmployeeRole',
    showEmployeeEmployementDates: 'showEmployeeEmployementDates'
  }
};

export const PERMISSIONS_BY_ROLE = {
  "Field Technician": [
    PERMISSIONS.page.payRoll,
    PERMISSIONS.page.logHours,
    PERMISSIONS.page.todaysRoute,
    PERMISSIONS.page.customers,
    PERMISSIONS.control.clockInClockOut
  ],

  "Office Staff": [
    PERMISSIONS.page.dashboard,
    PERMISSIONS.page.manageEmployee,
    PERMISSIONS.page.logHours,
    PERMISSIONS.page.todaysRoute,
    PERMISSIONS.page.payRolls,
    PERMISSIONS.page.payRoll,
    PERMISSIONS.page.customers,
    PERMISSIONS.page.schedules,
    PERMISSIONS.page.jobCompletion,
    PERMISSIONS.page.reports,
    PERMISSIONS.page.admin,
    PERMISSIONS.page.help,
    PERMISSIONS.control.clockInClockOut,
    PERMISSIONS.control.showListEmployees,
    PERMISSIONS.control.enableDatePicker,
    PERMISSIONS.control.showEmployeeEmployementDates,
  ],


  "Field Technician & Estimator w/ Customer Access": [
    PERMISSIONS.page.payRoll,
    PERMISSIONS.page.logHours,
    PERMISSIONS.page.todaysRoute,
    PERMISSIONS.page.customers,
    PERMISSIONS.control.clockInClockOut,
    PERMISSIONS.control.enableDatePicker,
    PERMISSIONS.control.showEmployeeEmployementDates,
  ],

  "Company Administrator": [
    PERMISSIONS.page.dashboard,
    PERMISSIONS.page.manageEmployee,
    PERMISSIONS.page.logHours,
    PERMISSIONS.page.todaysRoute,
    PERMISSIONS.page.payRolls,
    PERMISSIONS.page.payRoll,
    PERMISSIONS.page.customers,
    PERMISSIONS.page.schedules,
    PERMISSIONS.page.jobCompletion,
    PERMISSIONS.page.reports,
    PERMISSIONS.page.admin,
    PERMISSIONS.page.help,
    PERMISSIONS.control.clockInClockOut,
    PERMISSIONS.control.showListEmployees,
    PERMISSIONS.control.enableDatePicker,
    PERMISSIONS.control.showEmployeeRole,
    PERMISSIONS.control.showEmployeeEmployementDates,
  ],

  "System Admin": [
    PERMISSIONS.page.dashboard,
    PERMISSIONS.page.manageEmployee,
    PERMISSIONS.page.logHours,
    PERMISSIONS.page.todaysRoute,
    PERMISSIONS.page.payRolls,
    PERMISSIONS.page.payRoll,
    PERMISSIONS.page.customers,
    PERMISSIONS.page.schedules,
    PERMISSIONS.page.jobCompletion,
    PERMISSIONS.page.reports,
    PERMISSIONS.page.admin,
    PERMISSIONS.page.superAdmin,
    PERMISSIONS.page.help,
    PERMISSIONS.control.clockInClockOut,
    PERMISSIONS.control.showListEmployees,
    PERMISSIONS.control.enableDatePicker,
    PERMISSIONS.control.showEmployeeRole,
    PERMISSIONS.control.showEmployeeEmployementDates,
  ]
};

export const checkRoles = (userRole, requiredPermission) => {
  if (
    PERMISSIONS_BY_ROLE[userRole] &&
    PERMISSIONS_BY_ROLE[userRole].includes(requiredPermission)
  ) {
    return true;
  }

  return false;
};

export const checkMultiRoles = (userRoles, requiredPermission) => {
  if(typeof userRoles == "string") return  checkRoles(userRoles, requiredPermission);
  if(Array.isArray(userRoles)){
    for(let i = 0; i < userRoles.length; i ++){
      if  (
          PERMISSIONS_BY_ROLE[userRoles[i]] &&
          PERMISSIONS_BY_ROLE[userRoles[i]].includes(requiredPermission)
      ) {
        return true;
      }
    }
  }
  return false;
};
