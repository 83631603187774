import { AuthenticationType, ControlPosition } from 'azure-maps-control';

export const RouteColors = [
  '#43a047',
  '#6b83ff',
  '#fec56b',
  '#ff6cfe',
  '#37ddad',
  '#c7d648',
  '#ab98f0',
  '#f69dbc',
  '#57e2e7',
  '#b9b384',
  '#fe3434',
  '#ba36db',
  '#f5529c',
  '#92c7e6',
  '#01a4ef',
  '#55ce84',
  '#e5ed1d',
  '#4c47f0',
  '#a9d668',
  '#eb8a36',
  '#78abfe',
  '#3b9493',
  '#ddc298',
  '#af5574',
  '#85a666',
  '#ad8b34',
  '#7773a8',
  '#2faa72',
  '#9ed69d',
  '#a75137',
];

export const DefaultAzureMapOptions = {
  authOptions: {
    authType: AuthenticationType.subscriptionKey,
    subscriptionKey: '',
  },
  center: [-95, 35],
  zoom: 2.75,
  view: 'Auto',
};

export const DefaultAzureMapControls = [
  {
    controlName: 'ZoomControl',
    options: { position: ControlPosition.TopRight },
  },
  {
    controlName: 'CompassControl',
    controlOptions: { rotationDegreesDelta: 10 },
    options: { position: ControlPosition.TopRight },
  },
  {
    controlName: 'PitchControl',
    controlOptions: { pitchDegreesDelta: 5 },
    options: { position: ControlPosition.TopRight },
  },
  {
    controlName: 'TrafficControl',
    controlOptions: { incidents: true },
    options: { position: ControlPosition.TopLeft },
  },
  {
    controlName: 'TrafficLegendControl',
    controlOptions: {},
    options: { position: ControlPosition.BottomLeft },
  },
];
