import React, { useState, useEffect } from "react";
import { withRouter } from "react-router-dom";
import PropTypes from "prop-types";
import validate from "validate.js";
import { makeStyles } from "@material-ui/styles";
import { Button, TextField, Card, CardHeader, CardContent, Divider, CircularProgress, Typography } from "@material-ui/core";
import axios from "utils/axios";
import apiConfig from "apiConfig";
import Alert from "@material-ui/lab/Alert";
import Snackbar from "@material-ui/core/Snackbar";
import { baseUrl } from "apiConfig";

const schema = {
  username: {
    presence: { allowEmpty: false, message: "is required" },
    length: {
      minimum: 3,
    },
  },
};

const useStyles = makeStyles((theme) => ({
  root: {
    height: "100%",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    padding: theme.spacing(1, 2),
  },
  grid: {
    height: "100%",
  },

  form: {
    width: "500px",
    maxWidth: "100%",
    paddingLeft: 50,
    paddingRight: 50,
    paddingBottom: 50,
    flexBasis: 700,
    [theme.breakpoints.down("sm")]: {
      paddingLeft: theme.spacing(2),
      paddingRight: theme.spacing(2),
    },
  },
  title: {
    marginTop: theme.spacing(3),
  },

  textField: {
    marginTop: theme.spacing(2),
  },
  signInButton: {
    margin: theme.spacing(2, 0),
  },
  cardHeaderTitle: {
    textAlign: "center",
  },
}));

const ForgotPassword = (props) => {
  const classes = useStyles();

  const [sentSuccessfully, setSentSuccess] = useState();
  const [isStatus, setIsStatus] = useState({ failed: false, msg: "" });
  const [openSnackbar, setOpenSnackBar] = useState(false);
  const [formState, setFormState] = useState({
    isValid: false,
    values: {
      username: "",
      password: "",
    },
    touched: {},
    errors: {},
  });
  const [isProgress, setIsProgress] = useState(false);

  useEffect(() => {
    const errors = validate(formState.values, schema);

    setFormState((formState) => ({
      ...formState,
      isValid: errors ? false : true,
      errors: errors || {},
    }));
  }, [formState.values]);

  const handleChange = (event) => {
    event.persist();

    setFormState((formState) => ({
      ...formState,
      values: {
        ...formState.values,
        [event.target.name]: event.target.value,
      },
      touched: {
        ...formState.touched,
        [event.target.name]: true,
      },
    }));
  };

  const sendEmail = (event) => {
    event.preventDefault();

    //needed to send as form-data the api to accept the data
    let postData = {
      Email: formState.values.username,
      CallbackUrl: baseUrl() + "sign-in/reset-password",
    };
    setIsProgress(true);
    axios
      .post(apiConfig.url.AUTH_URL + apiConfig.url.FORGOT_PASSWORD, postData)
      .then((response) => {
        if (response.data.isSent !== null && response.data.isSent === true) {
          setSentSuccess(true);
        } else if (response.data.isSent !== null && response.data.isSent === false && response.data.message.includes("Unable to determine the user account")) {
          setSentSuccess(false);
          setIsStatus({ failed: true, msg: "There was an error retrieving this email address. Please contact support" });
          setOpenSnackBar(true);
        } else {
          setSentSuccess(false);
          setIsStatus({ failed: true, msg: response.data.message });
          setOpenSnackBar(true);
        }
        setIsProgress(false);
      })
      .catch((ex) => {
        setIsStatus({
          failed: true,
          msg: "Error sending password reset email: " + ex.message,
        });
        setIsProgress(false);
        setSentSuccess(false);
        setOpenSnackBar(true);
      });
  };

  return (
    <div className={classes.root}>
      <Card>
        <CardHeader
          className={classes.cardHeaderTitle}
          classes={{
            title: classes.cardHeaderTitle,
          }}
          title={sentSuccessfully ? "Confirm email" : "Forgot Password?"}
        />
        <Divider />
        <CardContent>
          <div className={classes.content}>
            <div className={classes.contentBody}>
              <form className={classes.form} onSubmit={sendEmail}>
                {sentSuccessfully ? (
                  <p>Please check your email to reset your password. </p>
                ) : (
                  <div style={{ textAlign: "center" }}>
                    <TextField className={classes.textField} fullWidth label="Email" name="username" onChange={handleChange} type="text" value={formState.values.username || ""} variant="outlined" />
                    <Button className={classes.signInButton} color="primary" disabled={!formState.isValid || isProgress} fullWidth size="large" type="submit" variant="contained">
                      Email Link
                    </Button>
                  </div>
                )}
                <div style={{ textAlign: "center" }}>{isProgress && <CircularProgress size={24} />}</div>
              </form>
            </div>
          </div>
        </CardContent>
      </Card>
      <Snackbar anchorOrigin={{ vertical: "top", horizontal: "center" }} open={openSnackbar} autoHideDuration={5000} onClose={() => setOpenSnackBar(false)}>
        <Alert elevation={6} variant="filled" severity={isStatus.failed ? "error" : "success"}>
          <Typography color="inherit" variant="h6">
            {isStatus.msg}
          </Typography>
        </Alert>
      </Snackbar>
    </div>
  );
};

ForgotPassword.propTypes = {
  history: PropTypes.object,
};

export default withRouter(ForgotPassword);
