import React, {useEffect, useState} from 'react'
import PropTypes from 'prop-types'
import {Card, CardContent, Grid, Typography, Button, colors, CircularProgress, Tooltip} from '@material-ui/core'
import useMediaQuery from '@material-ui/core/useMediaQuery'
import { makeStyles } from '@material-ui/styles'
import { Link } from 'react-router-dom'
import moment from "moment";
import axios from "../../../../../utils/axios";
import apiConfig from "../../../../../apiConfig";
import {DatePicker} from "@material-ui/pickers";
import Snackbar from "@material-ui/core/Snackbar";
import Alert from "@material-ui/lab/Alert";
import {createTheme, MuiThemeProvider} from "@material-ui/core/styles";
import ScheduleFollowUpModal from "../../../../CustomerJobs/components/Information/components/ScheduleFollowUpModal";
import { AddressLink } from 'components'

const useStyles = makeStyles(theme => ({
    sale: {
        position: "relative",
        borderBottom: '1px solid #eeeeee',
        '&:last-child': {
            borderBottom: 'none'
        }
    },
    actionBtn: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-evenly',
        '& button': {
            fontSize: "12px"
        }
    },
    button: {
        padding: '5px 10px',
        color: theme.palette.white,
        backgroundColor: colors.green[600],
        '&:hover': {
            backgroundColor: colors.green[900]
        }
    },
    actionBox: {
        width: '100%',
        height: '100%',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        position: 'absolute',
        top: 0,
        left: 0,
        zIndex: 2,
        backgroundColor: '#0000001f'
    },
    addressLink: {
        textDecoration: 'underline',
    }
}));
const customThemeTooltip = createTheme({
    overrides: {
        MuiTooltip: {
            tooltip: {
                fontSize: '14px',
                maxWidth: 'none'
            }
        }
    }
});
const SaleFollowUpsCard = props => {

    const classes = useStyles();
    const sm_down = useMediaQuery('(max-width: 959px)');
    const [isSaving, setIsSaving] = useState({});
    const [isStatus, setIsStatus] = useState({failed: false, msg: ''});
    const [openSnackbar, setOpenSnackBar] = useState(false);
    const [selected, setSelected] = useState({})
    const [openPicker, setOpenPicker] = useState(false);
    const [date, setDate] = useState('');
    const [openModal, setOpenModal] = useState(false);
    const [load, setLoad] = useState(false);
    const [fuData, setFuData] = useState();
    const [information, setInformation] = useState({});
    const [loading, setLoading] = useState(false);

    const {customer, index, onReload } = props;

    const updateSFU = (date) => {
        setIsSaving({[selected]: true})
        const data = {
            id: selected,
            dateCompleted: new Date(date).toISOString()
        }
        axios.patch(apiConfig.url.BASE_URL + apiConfig.url.DASHBOARD_FOLLOW_UP_CONTACT_MARK_COMPLETE, data)
            .then(res => {
                setIsStatus({failed: false, msg: 'Marked complete.'});
                setOpenSnackBar(true)
            })
            .catch(err => {
                setIsStatus({failed: true, msg: 'Update failed, please try again later'});
                setOpenSnackBar(true);
            }).finally(() => {
                setIsSaving({[selected]: false})
                onReload()
        })
    }

    const getCustomerJob = () => {
        axios
            .get(
              apiConfig.url.BASE_URL +
                apiConfig.url.CUSTOMER_JOB_FOLLOW_UP.replace("{jobId}", customer.jobId)
            )
            .then(res => {
              if (res.data[0]) {
                setFuData(res.data[0]);
              }
            })
            .finally(() => {
                setLoading(false);
                setOpenModal(true);
            });
    }

    const openEdit = () => {
        setLoading(true);
        getCustomerJob();
    }

    return (
        <>
        <Card >
            <CardContent className={classes.sale}>
                { isSaving[customer.id] && <div className={classes.actionBox}><CircularProgress size={32}/></div>}

                <Grid container spacing={3}>
                    <Grid item xs={12} sm={4} md={4}>
                        <Typography gutterBottom variant='h4'>{customer.companyName}
                            <MuiThemeProvider theme={customThemeTooltip}>
                                <Tooltip arrow title={'View Customer'}>
                                    <a
                                        href={`/customers/${encodeURIComponent(customer.companyName)}/${customer.customerId}/information`}
                                        target='_blank'>
                                        <i style={{
                                            marginLeft: '10px',
                                            fontSize: '18px',
                                            color: '#263238'
                                        }} className="fas fa-external-link" />
                                    </a>
                                </Tooltip>
                            </MuiThemeProvider>
                        </Typography>
                        <Typography gutterBottom>
                            <AddressLink
                                address={{
                                    ...customer,
                                    state: { name: customer.state }
                                }}
                                inline
                            />
                        </Typography>
                        <Typography gutterBottom>
                            {customer.jobsiteContactPhone}
                        </Typography>
                        <Typography gutterBottom>
                            <strong> Status: </strong>{customer.status}
                        </Typography>

                    </Grid>
                    <Grid item xs={12} sm={4} md={4}>
                        <Typography gutterBottom variant='h4'>
                            Job
                            <MuiThemeProvider theme={customThemeTooltip}>
                                <Tooltip arrow title={'View Job'}>
                                    <a
                                        href={`/customers/${encodeURIComponent(customer.companyName)}/${customer.customerId}/jobs/${customer.jobId}/information`}
                                        target='_blank'>
                                        <i style={{
                                            marginLeft: '10px',
                                            fontSize: '18px',
                                            color: '#263238'
                                        }} className="fas fa-external-link" />
                                    </a>
                                </Tooltip>
                            </MuiThemeProvider>
                        </Typography>
                        <Typography gutterBottom>
                            <strong>Job Bid Stage: </strong>{customer.bidStage}
                        </Typography>
                        <Typography gutterBottom>
                            <strong>Note: </strong>{customer.followUpNote}
                        </Typography>
                        <Typography gutterBottom>
                            <strong>Job Description: </strong>{customer.jobDescription}
                        </Typography>

                    </Grid>
                    <Grid item xs={12} sm={4} md={4}>
                        <Typography gutterBottom>
                            <strong>Follow Up
                                Date: </strong>{customer.followUpDate ? moment(moment.utc(customer.followUpDate).toDate()).format('MM/DD/YYYY') : ''}
                        </Typography>
                        <Typography gutterBottom>
                            <strong>Last Job
                                Date: </strong>{customer.lastJobDate ? moment(customer.lastJobDate).format('MM/DD/YYYY') : ''}
                        </Typography>
                        <Typography gutterBottom>
                            <strong>Job Created Date: </strong>{customer.jobCreatedDate ? moment(customer.jobCreatedDate).format('MM/DD/YYYY') : ''}
                        </Typography>
                        <Typography gutterBottom>
                            <strong>Created By: </strong>{customer.createdBy}
                        </Typography>
                        <div className={classes.actionBtn}>
                            <Grid container spacing={2}>
                                <Grid item xs={12} md={12}>
                                    <Button style={{marginBottom: '10px', marginRight: '10px'}} className={classes.button}
                                            onClick={openEdit}
                                            disabled={!customer.jobId}
                                    >
                                        Edit Follow Up
                                    </Button>
                                    {loading && <CircularProgress size={24} style={{ marginRight: '10px' }} />}
                                    <Button style={{marginBottom: '10px', marginRight: '10px'}} className={classes.button}
                                            onClick={() => {
                                                setSelected(customer.id);
                                                setOpenPicker(true);
                                            }}
                                    >
                                        Mark Complete
                                    </Button>
                                    
                                </Grid>
                                {/*<Grid item xs={12} md={6}>*/}
                                {/*    <Link target='_blank'*/}
                                {/*          to={`/customers/${customer.companyName}/${customer.customerId}/jobs/${customer.jobId}/information`}>*/}
                                {/*        <Button*/}
                                {/*            style={{width: '100%'}}*/}
                                {/*            className={classes.button}*/}
                                {/*            variant="contained">*/}
                                {/*            View*/}
                                {/*        </Button>*/}
                                {/*    </Link>*/}
                                {/*</Grid>*/}
                            </Grid>

                            {/*<Link target='_blank' to={`/customers/${customer.customer}/155067a5-15b6-4725-bc42-d7b2d681c338/information`}>*/}

                        </div>
                    </Grid>
                </Grid>
            </CardContent>
        </Card>

        <DatePicker
            style={{display: 'none'}}
            open={openPicker}
            onClose={() => setOpenPicker(false)}
            onChange={date => updateSFU(date)}
            variant='dialog'
            value={date ? date : new Date()}
        />
        <Snackbar anchorOrigin={{vertical: 'top', horizontal: 'center'}} open={openSnackbar}
                    autoHideDuration={3000}
                    onClose={() => setOpenSnackBar(false)}>
            <Alert
                elevation={6} variant="filled" severity={isStatus.failed ? 'error' : 'success'}>
                <Typography
                    color="inherit"
                    variant="h6">
                    {isStatus.msg}
                </Typography>
            </Alert>
        </Snackbar>

        <ScheduleFollowUpModal
            fuData={fuData}
            status={{
                id: customer.jobId,
                description: customer.jobDescription
            }}
            onCloseModal={() => {
                setOpenModal(false);
            }}
            open={openModal}
            onload={() => {
                onReload();
            }}
        />

        </>
    )
}

SaleFollowUpsCard.propTypes = {
    customer: PropTypes.object.isRequired
}

export default SaleFollowUpsCard;
