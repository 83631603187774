import React from 'react'
import PropTypes from 'prop-types'
import { Grid, TextField, Button, IconButton } from '@material-ui/core'
import { makeStyles } from '@material-ui/styles'
import BackspaceIcon from '@material-ui/icons/Backspace';
import NumberFormat from 'react-number-format';
import useMediaQuery from '@material-ui/core/useMediaQuery';

const useStyles = makeStyles(theme => ({
    root: {},
    removeButton: {
        marginTop: theme.spacing(1),
        [theme.breakpoints.down('xs')]: {
            display: 'none'
        }
    },
    removeIcon: {
        display: 'none',
        [theme.breakpoints.down('xs')]: {
            display: 'block'
        }
    }
}))

const Phone = props => {

    const classes = useStyles();
    const down_sm = useMediaQuery('(max-width: 600px)');

    const { index, errors, touched, setFieldTouched, phone, length, phoneTypes, onPhoneRemove, onPhoneUpdate } = props;

    const handleInputChange = event => {
        onPhoneUpdate(event);
    }

    const removeUserPhone = () => {
        onPhoneRemove();
    }

    return (
        <>
            <Grid
                item
                md={4}
                xs={4}>
                <TextField
                    fullWidth
                    label="Select Phone Type"
                    name="typeId"
                    onChange={handleInputChange}
                    select
                    SelectProps={{ native: true }}
                    value={phone.typeId}
                    variant="outlined">
                    {phoneTypes.map(pt => (
                        <option
                            key={pt.id}
                            value={pt.id}
                        >
                            {pt.type}
                        </option>
                    ))}
                </TextField>
            </Grid>

            <Grid
                item
                md={down_sm ? 6 : 4}
                xs={down_sm ? 6 : 4}>
                <NumberFormat
                    customInput={TextField}
                    fullWidth
                    error={errors.phones && errors.phones[index] && touched.phones && touched.phones[index]}
                    helperText={(errors.phones && errors.phones[index] && touched.phones && touched.phones[index]) && errors.phones[index].phoneNumber}
                    label="Phone Number"
                    name="phoneNumber"
                    onBlur={() => {
                        const phones = touched.phones ? [...touched.phones] : [];
                        phones[index] = { phoneNumber: true };
                        setFieldTouched('phones', phones);
                    }}
                    onChange={handleInputChange}
                    value={phone.phoneNumber}
                    variant="outlined"
                    format="(###) ###-####x######"
                />

            </Grid>

            <Grid
                item
                md={2}
                xs={2}>
                {length > 1
                    ?
                    <>
                        <IconButton
                            color="primary"
                            onClick={removeUserPhone}
                            className={classes.removeIcon}>
                            <BackspaceIcon />
                        </IconButton>
                        <Button className={classes.removeButton} onClick={removeUserPhone} color="primary" variant="outlined">Remove</Button>
                    </>
                    : ''
                }
            </Grid>
        </>
    )
}

Phone.propTypes = {
    index: PropTypes.number.isRequired,
    errors: PropTypes.object.isRequired,
    phone: PropTypes.object.isRequired,
    phoneTypes: PropTypes.arrayOf(Object).isRequired,
    length: PropTypes.number.isRequired,
    onPhoneUpdate: PropTypes.func.isRequired,
    onPhoneRemove: PropTypes.func.isRequired
}

Phone.defaultProps = {
    onPhoneUpdate: () => { },
    onPhoneRemove: () => { }
};

export default Phone;
