import React, { useEffect, useState } from 'react'
import {
    TableContainer, Table, TableHead, TableBody, TableRow, TableCell,
    TextField, Button, colors, Typography, CircularProgress, CardActions, Grid,Card, CardHeader, CardContent, Divider,Collapse
} from '@material-ui/core'
import { makeStyles } from '@material-ui/styles'
import NumberFormat from 'react-number-format'
import JobLinePrice from './JobLinePrice'
import apiConfig from 'apiConfig'
import axios from 'utils/axios'
import Snackbar from "@material-ui/core/Snackbar";
import Alert from "@material-ui/lab/Alert";
import { ConfirmModal } from "../../../components";
import localStorage from "../../../utils/localStorage";
import {ArrowDownward, ArrowUpward, ExpandMore} from '@material-ui/icons'
import moment from 'moment'

const useStyles = makeStyles(theme => ({
    root: {
        position: 'relative'
    },
    thead: {
        '& tr th': {
            borderBottom: '1px solid #888888'
        }
    },
    tbody: {
        '& tr td': {
            borderBottom: 'none'
        }
    },
    discounts: {
        marginTop: 14,
        marginLeft: 'auto',
        marginRight: 'auto'
    },
    gridItem: {
        padding: 7
    },
    btn: {
        whiteSpace: 'nowrap',
        color: theme.palette.white,
        backgroundColor: colors.green[600],
        '&:hover': {
            backgroundColor: colors.green[900]
        }
    },
    btnDis: {
        display: 'block',
        color: 'rgba(0, 0, 0, 0.26)',
        backgroundColor: 'rgb(217 217 217)',
        pointerEvents: 'none',
    },
    customTableCell: {
        cursor: 'pointer'
    },
    iconSort: {
        marginBottom: '-5px',
        marginLeft: '10px'
    },
    tipDate: {
        fontSize: '.75em',
        marginTop: 4
    },
}))

const JobPrice = ({ job,isEnableTodayRoute }) => {
    const classes = useStyles();

    const [prices, setPrices] = useState([{}]);
    const [upsales, setUpsales] = useState([]);
    const [taxAndDiscount, setTaxAndDiscount] = useState({});
    const [isStatus, setIsStatus] = useState({ failed: false, msg: '' });
    const [openSnackbar, setOpenSnackBar] = useState(false);
    const [saveItems, setSaveItems] = useState(false);
    const [openConfirm, setOpenConfirm] = useState(false);
    const [locateFile, setLocateFile] = useState();
    const [isProgress, setIsProgress] = useState(false);
    const [lineItem, setLineItem] = useState([]);
    const [defaultTax, setDefaultTax] = useState(false);
    const [saveTip, setSaveTip] = useState(false);
    const [tip, setTip] = useState('');
    const [tipDate, setTipDate] = useState();
    const [totalAmount, setTotalAmount] = useState(0);
    const [_priceDiscount, setPriceDiscount] = useState(0);
    const [_total, setTotal] = useState(0);
    const [_subtotal, setSubtotal] = useState(0);
    const [sortBy, setSortBy] = useState('FileName');
    const [sortType, setSortType] = useState('ASC');

    const fullInfo = localStorage.getFullInfo();
    const [expand, setExpand] = useState(false);

    const onAddUpsale = () => {
        setUpsales([...upsales, { quantity: '', description: '', price: '', taxable: defaultTax, upsale: false }])
    }
    const onChangeUpsale = (event, index) => {
        const { name, value } = event.target;
        const _upsales = [...upsales];
        _upsales[index] = {
            ..._upsales[index],
            [name]: name === 'taxable' || name === 'upsale' ?
                event.target.checked : value.replace(/[$,]/g, '')
        };
        setUpsales(_upsales)
    }

    const onChangeDesc = (desc, index, price, taxable, companyPriceItemId) => {
        const _upsales = [...upsales];
        _upsales[index] = {
            ..._upsales[index],
            description: desc,
            price: price,
            taxable: taxable,
            companyPriceItemId: companyPriceItemId,
        };
        setUpsales(_upsales)
    }

    const updateTip = () => {
        const params = {
            id: job.jobId,
            tip: tip ? parseFloat(tip.toString().replace('$', '')) : 0
        }
        setSaveTip(true);
        axios.patch(apiConfig.url.BASE_URL + apiConfig.url.CUSTOMER_JOB_UPDATE, params).then(() => {
            setIsStatus({ failed: false, msg: 'Record Saved' });
            setOpenSnackBar(true);
        }).catch(() => {
            setIsStatus({ failed: true, msg: 'An error occurred, please try again later' });
            setOpenSnackBar(true);
        }).finally(() => {
            setSaveTip(false);
        })
    }

    const saveAll = () => {
        let valid = true;
        const data = upsales.map(item => {
            if (!item.quantity || !item.description || !item.price) {
                valid = false;
                return
            }
            const params = {
                ...item, jobId: job.jobId, companyPriceItemId: "", addedOnLocation: true
            }
            return params;
        })
        if (!valid) {
            setIsStatus({ failed: true, msg: 'Please complete all fields' });
            setOpenSnackBar(true);
            return
        }
        setSaveItems(true);
        axios.post(apiConfig.url.BASE_URL + apiConfig.url.TODAY_ROUTE_PRICE_SAVE_ALL.replace('{jobId}', job.jobId), data)
            .then(() => {
                setIsStatus({ failed: false, msg: 'Record Saved' });
                setOpenSnackBar(true);
            }).catch(ex => {
                setIsStatus({ failed: true, msg: 'An error occurred, please try again later' });
                setOpenSnackBar(true);
            }).finally(e => {
                setSaveItems(false)
                fetchJob(job.jobId);
            });
    }

    const onRemoveUpsale = () => {
        if (locateFile && locateFile.data.id) {
            setIsProgress(true)
            axios.delete(apiConfig.url.BASE_URL + apiConfig.url.TODAY_ROUTE_PRICE_DELETE.replace('{jobId}', job.jobId).replace('{itemId}', locateFile.data.id))
                .then(res => {
                    setIsStatus({ failed: false, msg: 'Record Deleted' });
                    setOpenSnackBar(true);
                    const _upsales = [...upsales];
                    _upsales.splice(locateFile.index, 1);
                    setUpsales(_upsales)
                })
                .catch(err => {
                    setIsStatus({ failed: true, msg: 'An error occurred, please try again later' });
                    setOpenSnackBar(true);
                })
                .finally(e => {
                    setSaveItems(false)
                    setIsProgress(false)
                    setOpenConfirm(false)
                });
        } else {
            const _upsales = [...upsales];
            _upsales.splice(locateFile.index, 1);
            setUpsales(_upsales)
            setOpenConfirm(false)
        }
    }

    const loadDocument = (type) => {
        setSortBy(type)
        setSortType(type == sortBy ? (sortType === 'ASC' ? 'DESC' : 'ASC') : 'ASC');
    }
    const getSortIcon = (type) => {
        return (
            sortBy == type &&
            (
                sortType == 'ASC' ?
                    <ArrowUpward className={classes.iconSort}/>
                    :
                    <ArrowDownward className={classes.iconSort}/>
            )
        )
    }

    useEffect(() => {
        fetchJob(job.jobId);
    }, [sortBy, sortType])

    useEffect(() => {
        let total = 0;
        let taxTotal = 0;
        let priceDiscount = 0;
        let subtotal = 0;
        let _total = 0;
        const discountPercent = taxAndDiscount.discountPercent || 0;
        const taxPercent = taxAndDiscount.taxPercent || 0;
        const _tip = !!taxAndDiscount.tip ? taxAndDiscount.tip.toString().replace('$', '') : 0;
        setTip(_tip);
        setTipDate(taxAndDiscount.tipDate);
        prices.map(upsale => {
            const { quantity, price, taxable } = upsale;
            const totalPrice = quantity * price;
            const discountDollar = discountPercent ? totalPrice * discountPercent / 100 : 0;

            total += totalPrice;
            taxTotal += (totalPrice - discountDollar) * taxable * (taxPercent || 0) / 100;
        });
        upsales.map(upsale => {
            const { quantity, price, taxable } = upsale;
            const totalPrice = quantity * price;
            const discountDollar = discountPercent ? totalPrice * discountPercent / 100 : 0;

            total += totalPrice;
            taxTotal += (totalPrice - discountDollar) * taxable * (taxPercent || 0) / 100;
        });
        setTotal(total);
        priceDiscount = discountPercent ? parseFloat(total * discountPercent / 100).toFixed(2) : '0.00';
        setPriceDiscount(priceDiscount);
        subtotal = parseFloat(total - priceDiscount).toFixed(2);
        setSubtotal(subtotal);
        _total = (Math.round((parseFloat(subtotal) + taxTotal + parseFloat(_tip)) * 100) / 100).toFixed(2);
        setTotalAmount(_total);
    }, [taxAndDiscount, prices, upsales])

    const fetchJob = (id) => {
        axios.get(apiConfig.url.BASE_URL + apiConfig.url.TODAY_ROUTE_PRICE_ITEM.replace('{jobId}', id), {
            params: {
                sortBy: sortBy,
                sortType: sortType,
            }
        })
            .then(res => {
                setPrices(res.data.priceLineItems.filter(x => !x.addedOnLocation));
                setUpsales(res.data.priceLineItems.filter(x => !!x.addedOnLocation));
                setTaxAndDiscount(res.data.taxAndDiscount);
            })
    }

    useEffect(() => {
        if (tip) {
            setTaxAndDiscount({ ...taxAndDiscount, tip: tip });
        }
    }, [tip])

    useEffect(() => {
        axios.get(apiConfig.url.BASE_URL + apiConfig.url.COMPANY_PRICES_ITEM.replace('{companyId}', fullInfo.cid), {
            params: { pageSize: 9999 }
        })
            .then(value => {
                let data = [];
                value.data.data.map(item => {
                    if (item.active) {
                        data.push({
                            price: item.price,
                            title: item.description,
                            taxable: item.taxable,
                            id: item.id,
                            companyPriceItemId: item.companyPriceItemId,
                        })
                    }
                })
                setLineItem(data)
            })
        axios.get(apiConfig.url.BASE_URL + apiConfig.url.COMPANY_PRICE_TAX.replace('{companyId}', fullInfo.cid))
            .then(res => {
                setDefaultTax(res.data)
            })
    }, [])
    useEffect(() => {
        fetchJob(job.jobId);
    }, [job])

    const disabled = job && job.completedDate ? true : false

    return (
        <>
        {
           isEnableTodayRoute != null && isEnableTodayRoute ?
           <>
             <Card className={classes.root}>
                <CardHeader title='Line Items' className={classes.expandHead} action={<ExpandMore />} onClick={() => setExpand(!expand)} />
                <Divider />
                <Collapse in={expand} timeout='auto' unmountOnExit>
                <CardContent>
                    <TableContainer>
                        <Table style={{ minWidth: 800 }}>
                            <TableHead className={classes.thead}>
                                <TableRow>
                                    <TableCell onClick={() => loadDocument('Quantity')} className={classes.customTableCell} align='center' style={{ width: '15%' }}><span>Quantity{ getSortIcon('Quantity')}</span></TableCell>
                                    <TableCell onClick={() => loadDocument('Description')} className={classes.customTableCell} align='center'><span>Description{ getSortIcon('Description')}</span></TableCell>
                                    <TableCell onClick={() => loadDocument('Price')} className={classes.customTableCell} align='center' style={{ width: '15%' }}><span>Price{ getSortIcon('Price')}</span></TableCell>
                                    <TableCell onClick={() => loadDocument('Total')} className={classes.customTableCell} align='center' style={{ width: '15%' }}><span>Total{ getSortIcon('Total')}</span></TableCell>
                                    <TableCell onClick={() => loadDocument('Taxable')} className={classes.customTableCell} align='center' style={{ width: '1%', minWidth: '115px' }}><span>Taxable{ getSortIcon('Taxable')}</span></TableCell>
                                    <TableCell onClick={() => loadDocument('Upsale')} className={classes.customTableCell} align='center' style={{ width: '1%', minWidth: '115px' }}><span>Upsale{ getSortIcon('Upsale')}</span></TableCell>
                                    <TableCell className={classes.customTableCell} align='center' style={{ width: 1 }}></TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody className={classes.tbody}>
                                {prices && prices.map((price, index) => (
                                    <JobLinePrice
                                        key={`price-${index}`}
                                        disabled={disabled}
                                        style={index === prices.length - 1 ? { borderBottom: '1px solid #888888' } : null}
                                        isAdd={index === prices.length - 1}
                                        addUpsale={onAddUpsale}
                                        item={price}
                                        lineItem={lineItem}
                                        onChangeDesc={(value, price, taxable, companyPriceItemId) => onChangeDesc(value, index, price, taxable, companyPriceItemId)}
                                        defaultTax={defaultTax}
                                    />
                                ))}
                                {upsales.map((upscale, index) => (
                                    <JobLinePrice
                                        key={`price-${index}`}
                                        item={upscale}
                                        isUpsale
                                        disabled={disabled}
                                        onChangeUpsale={event => onChangeUpsale(event, index)}
                                        removeUpsale={() => {
                                            setOpenConfirm(true);
                                            setLocateFile({
                                                data: upscale,
                                                index: index
                                            })
                                        }}
                                        lineItem={lineItem}
                                        onChangeDesc={(value, price, taxable, companyPriceItemId) => onChangeDesc(value, index, price, taxable, companyPriceItemId)}
                                        defaultTax={defaultTax}
                                    />
                                ))}
                                {upsales.length > 0 && (
                                    <TableRow style={{ borderBottom: '1px solid #888888' }}>
                                        <TableCell align='right' colSpan={7} style={{ paddingTop: 0 }}>
                                            <div>
                                                <Button onClick={() => saveAll()} className={(disabled || saveItems) ? classes.btnDis : classes.btn}>
                                                    Save
                                                </Button>
                                                {saveItems &&
                                                    <CircularProgress size={24} />}
                                            </div>
                                        </TableCell>
                                    </TableRow>
                                )}
                            </TableBody>
                        </Table>
                    </TableContainer>
                    <Grid className={classes.discounts} item xs={12} md={7} container spacing={2}>
                        <Grid item xs={12} sm={3}>
                            <NumberFormat
                                customInput={TextField}
                                allowNegative={false}
                                prefix='$'
                                thousandSeparator
                                fullWidth
                                disabled
                                label='Total Price'
                                name='totalPrice'
                                variant='outlined'
                                value={parseFloat(_total).toFixed(2)}
                            />
                        </Grid>
                        <Grid item xs={12} sm={3}>
                            <TextField
                                fullWidth
                                disabled
                                label='Discount Type'
                                name='discountType'
                                select
                                SelectProps={{ native: true }}
                                variant='outlined'
                                value={taxAndDiscount.discountTypeId || ''}
                            >
                                <option value=''></option>
                                <option value='1'>1</option>
                                <option value='2'>2</option>
                            </TextField>
                        </Grid>
                        <Grid item xs={12} sm={3}>
                            <NumberFormat
                                customInput={TextField}
                                allowNegative={false}
                                suffix='%'
                                isAllowed={values => {
                                    return !values.floatValue || values.floatValue <= 100
                                }}
                                fullWidth
                                disabled
                                label='Discount %'
                                name='discountPercent'
                                variant='outlined'
                                value={taxAndDiscount.discountPercent || ''}
                            />
                        </Grid>
                        <Grid item xs={12} sm={3}>
                            <NumberFormat
                                customInput={TextField}
                                allowNegative={false}
                                prefix='$'
                                thousandSeparator
                                fullWidth
                                disabled
                                label='Discount $'
                                name='priceDiscount'
                                variant='outlined'
                                value={_priceDiscount}
                            />
                        </Grid>
                       
                        <Grid item xs={12} sm={3}>
                            <NumberFormat
                                customInput={TextField}
                                allowNegative={false}
                                prefix='$'
                                thousandSeparator
                                fullWidth
                                disabled
                                label='Subtotal'
                                name='subtotal'
                                variant='outlined'
                                value={_subtotal}
                            />
                        </Grid>
                        <Grid item xs={12} sm={3}>
                            <NumberFormat
                                customInput={TextField}
                                allowNegative={false}
                                suffix='%'
                                isAllowed={values => {
                                    return !values.floatValue || values.floatValue <= 100
                                }}
                                fullWidth
                                disabled
                                label='Tax'
                                name='taxPercent'
                                variant='outlined'
                                value={taxAndDiscount.taxPercent || ''}
                            />
                        </Grid>
                        <Grid item xs={12} sm={3}>
                            <NumberFormat
                                customInput={TextField}
                                allowNegative={false}
                                prefix='$'
                                thousandSeparator
                                fullWidth
                                disabled={disabled}
                                label='Tip'
                                name='tip'
                                variant='outlined'
                                value={tip}
                                onChange={(e) => {
                                    setTip(e.target.value)
                                }}
                            />
                            {tipDate && (
                                <div className={classes.tipDate}>
                                    Date Added/Applied: {moment(tipDate).format('MM/DD/YY')}
                                </div>
                            )}
                        </Grid>
                        <Grid item xs={12} sm={3}>
                            <NumberFormat
                                customInput={TextField}
                                allowNegative={false}
                                prefix='$'
                                thousandSeparator
                                fullWidth
                                disabled
                                label='Total Due'
                                name='total'
                                variant='outlined'
                                value={totalAmount}
                            />
                        </Grid>
                    </Grid>
                    <div style={{ position: 'absolute', right: '32px', bottom: '111px' }}>
                        <CardActions>
                            <Button onClick={() => updateTip()} className={disabled ? classes.btnDis : classes.btn}>
                                Save
                            </Button>
                            {saveTip &&
                                <CircularProgress size={24} />}
                        </CardActions>
                    </div>
                    </CardContent>
                </Collapse>
                </Card>
           </>
           :
           <>
           <TableContainer>
                <Table style={{ minWidth: 800 }}>
                    <TableHead className={classes.thead}>
                        <TableRow>
                            <TableCell align='center' style={{ width: '15%' }}>Quantity</TableCell>
                            <TableCell align='center'>Description</TableCell>
                            <TableCell align='center' style={{ width: '15%' }}>Price</TableCell>
                            <TableCell align='center' style={{ width: '15%' }}>Total</TableCell>
                            <TableCell align='center' style={{ width: 1 }}>Taxable</TableCell>
                            <TableCell align='center' style={{ width: 1 }}>Upsale</TableCell>
                            <TableCell align='center' style={{ width: 1 }}></TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody className={classes.tbody}>
                        {prices && prices.map((price, index) => (
                            <JobLinePrice
                                key={`price-${index}`}
                                disabled={disabled}
                                style={index === prices.length - 1 ? { borderBottom: '1px solid #888888' } : null}
                                isAdd={index === prices.length - 1}
                                addUpsale={onAddUpsale}
                                item={price}
                                lineItem={lineItem}
                                onChangeDesc={(value, price, taxable, companyPriceItemId) => onChangeDesc(value, index, price, taxable, companyPriceItemId)}
                                defaultTax={defaultTax}
                            />
                        ))}
                        {upsales.map((upscale, index) => (
                            <JobLinePrice
                                key={`price-${index}`}
                                item={upscale}
                                isUpsale
                                disabled={disabled}
                                onChangeUpsale={event => onChangeUpsale(event, index)}
                                removeUpsale={() => {
                                    setOpenConfirm(true);
                                    setLocateFile({
                                        data: upscale,
                                        index: index
                                    })
                                }}
                                lineItem={lineItem}
                                onChangeDesc={(value, price, taxable, companyPriceItemId) => onChangeDesc(value, index, price, taxable, companyPriceItemId)}
                                defaultTax={defaultTax}
                            />
                        ))}
                        {upsales.length > 0 && (
                            <TableRow style={{ borderBottom: '1px solid #888888' }}>
                                <TableCell align='right' colSpan={7} style={{ paddingTop: 0 }}>
                                    <div>
                                        <Button onClick={() => saveAll()} className={(disabled || saveItems) ? classes.btnDis : classes.btn}>
                                            Save
                                        </Button>
                                        {saveItems &&
                                            <CircularProgress size={24} />}
                                    </div>
                                </TableCell>
                            </TableRow>
                        )}
                    </TableBody>
                </Table>
            </TableContainer>
            <Grid className={classes.discounts} item xs={12} md={7} container spacing={2}>
                <Grid item xs={12} sm={6}>
                    <TextField
                        fullWidth
                        disabled
                        label='Discount Type'
                        name='discountType'
                        select
                        SelectProps={{ native: true }}
                        variant='outlined'
                        value={taxAndDiscount.discountTypeId || ''}
                    >
                        <option value=''></option>
                        <option value='1'>1</option>
                        <option value='2'>2</option>
                    </TextField>
                </Grid>
                <Grid item xs={12} sm={3}>
                    <NumberFormat
                        customInput={TextField}
                        allowNegative={false}
                        suffix='%'
                        isAllowed={values => {
                            return !values.floatValue || values.floatValue <= 100
                        }}
                        fullWidth
                        disabled
                        label='Discount %'
                        name='discountPercent'
                        variant='outlined'
                        value={taxAndDiscount.discountPercent || ''}
                    />
                </Grid>
                <Grid item xs={12} sm={3}>
                    <NumberFormat
                        customInput={TextField}
                        allowNegative={false}
                        prefix='$'
                        thousandSeparator
                        fullWidth
                        disabled
                        label='Discount $'
                        name='priceDiscount'
                        variant='outlined'
                        value={_priceDiscount}
                    />
                </Grid>
                <Grid item xs={12} sm={3}>
                    <NumberFormat
                        customInput={TextField}
                        allowNegative={false}
                        prefix='$'
                        thousandSeparator
                        fullWidth
                        disabled
                        label='Total Price'
                        name='totalPrice'
                        variant='outlined'
                        value={parseFloat(_total).toFixed(2)}
                    />
                </Grid>
                <Grid item xs={12} sm={3}>
                    <NumberFormat
                        customInput={TextField}
                        allowNegative={false}
                        prefix='$'
                        thousandSeparator
                        fullWidth
                        disabled
                        label='Subtotal'
                        name='subtotal'
                        variant='outlined'
                        value={_subtotal}
                    />
                </Grid>
                <Grid item xs={12} sm={2}>
                    <NumberFormat
                        customInput={TextField}
                        allowNegative={false}
                        suffix='%'
                        isAllowed={values => {
                            return !values.floatValue || values.floatValue <= 100
                        }}
                        fullWidth
                        disabled
                        label='Tax'
                        name='taxPercent'
                        variant='outlined'
                        value={taxAndDiscount.taxPercent || ''}
                    />
                </Grid>
                <Grid item xs={12} sm={2}>
                    <NumberFormat
                        customInput={TextField}
                        allowNegative={false}
                        prefix='$'
                        thousandSeparator
                        fullWidth
                        disabled={disabled}
                        label='Tip'
                        name='tip'
                        variant='outlined'
                        value={tip}
                        onChange={(e) => {
                            setTip(e.target.value)
                        }}
                    />
                </Grid>
                <Grid item xs={12} sm={2}>
                    <NumberFormat
                        customInput={TextField}
                        allowNegative={false}
                        prefix='$'
                        thousandSeparator
                        fullWidth
                        disabled
                        label='Total'
                        name='total'
                        variant='outlined'
                        value={totalAmount}
                    />
                </Grid>
            </Grid>
            <div style={{ position: 'absolute', right: '32px', bottom: '111px' }}>
                <CardActions>
                    <Button onClick={() => updateTip()} className={disabled ? classes.btnDis : classes.btn}>
                        Save
                    </Button>
                    {saveTip &&
                        <CircularProgress size={24} />}
                </CardActions>
            </div>
           </>
        }
            
            <ConfirmModal
                openConfirm={openConfirm}
                closeConfirm={() => setOpenConfirm(false)}
                onConfirm={onRemoveUpsale}
                isProgress={isProgress}
            />
            <Snackbar anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
                open={openSnackbar}
                autoHideDuration={3000}
                onClose={() => setOpenSnackBar(false)}>
                <Alert elevation={6} variant="filled" severity={isStatus.failed ? 'error' : 'success'}>
                    <Typography
                        color="inherit"
                        variant="h6">
                        {isStatus.msg}
                    </Typography>
                </Alert>
            </Snackbar>
        </>
    )
}

export default JobPrice;
