import React from 'react';
import { Redirect } from 'react-router-dom';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/styles';
import { Tabs, Tab, Divider, colors, Typography } from '@material-ui/core';

import { Page } from 'components';
import { Header, Information, JobAddresses, Notes, Attachments } from './components';


const useStyles = makeStyles(theme => ({
    root: {
        // height: '100%',
        padding: theme.spacing(4),
        paddingTop: 24
    },
    tabs: {
        
    },
    divider: {
        backgroundColor: colors.grey[300]
    },
    content: {
        marginTop: theme.spacing(3)
    }
}));

const CustomerDetails = props => {
    const classes = useStyles();

    const { match, history } = props;
    const { id, tab } = match.params;
    const name = decodeURIComponent(match.params.name);

    const handleTabsChange = (event, value) => {
        history.push(value);
    };

    const tabs = [
        { value: 'information', label: 'Information' },
        { value: 'notes', label: 'Notes' },
        { value: 'attachments', label: 'Attachments' },
        { value: 'job-addresses', label: 'Job Addresses' }
    ];

    if (!tabs.find(t => t.value === tab)) {
        return <Redirect to="/errors/error-404" />;
    }

    return (
        <Page
            className={classes.root}
            title={name === ' ' ? 'Customer Detail' : `${name} - Customer Detail`}
        >
            <Typography style={{ display: 'inline' }}>{name === ' ' ? 'Customer' : `Customer - ${name}`}</Typography>
            <Tabs
                className={classes.tabs}
                onChange={handleTabsChange}
                scrollButtons="auto"
                value={tab}
                variant="scrollable"
            >
                {tabs.map(tab => (
                    <Tab
                        key={tab.value}
                        label={tab.label}
                        value={tab.value}
                    />
                ))}
            </Tabs>
            <Divider className={classes.divider} />
            <div className={classes.content}>

                {tab === 'information' && <Information customerId={id} />}
                {tab === 'notes' && <Notes customerId={id} />}
                {tab === 'attachments' && <Attachments customerId={id} />}
                {tab === 'job-addresses' && <JobAddresses customerName={name} customerId={id} />}
            </div>
        </Page>
    );
};

CustomerDetails.propTypes = {
    history: PropTypes.object.isRequired,
    match: PropTypes.object.isRequired
};

export default CustomerDetails;
