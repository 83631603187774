import React from 'react';
import PropTypes from 'prop-types';
import { Navigation } from 'components';


const SidebarNav = props => {
  const { pages, openDrawer, onClose } = props;
  
  return (
    <>
      {pages && pages.map((list, index) => (
        <Navigation
          component="div"
          key={index}
          pages={list.pages}
          title={list.title}
          openDrawer={openDrawer}
          onClose={onClose}
        />
      ))}
    </>
  )

};

SidebarNav.propTypes = {
  pages: PropTypes.array.isRequired
};

export default SidebarNav;
